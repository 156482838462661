import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'show2daParteResaltada'
})

 export class Show2daParteResaltadaPipe implements PipeTransform {
    transform(
        value      : any,
        separador  : string,
        ocurrencia : number,
        rows       : number
    ): string | null {
        // console.log("Show2RowsPipe",value,separador,ocurrencia,rows);
        let rta='';
        if(value==null || value===undefined) {
          //console.log("fechaPipe Null");
          rta='';

        } else { 

          let posSeparador;
          let antesSeparador;
          let despuesSeparador;
          let cadenaSin1erSeparador;

          if(ocurrencia==1) {
              posSeparador = value.indexOf(separador);

          } else if(ocurrencia==2) {
              posSeparador              = value.indexOf(separador);
              antesSeparador            = value.substr( 0, posSeparador );
              despuesSeparador          = value.substr( posSeparador+1, 1000 );
              let cadenaSin1erSeparador = antesSeparador+'Ñ'+despuesSeparador;
              posSeparador              = cadenaSin1erSeparador.indexOf(separador);
          }

          antesSeparador   = value.substr( 0, posSeparador );
          despuesSeparador = value.substr( posSeparador+1, 1000 );

          if(rows==1) {
              rta="<span style='font-size:11px'>"+antesSeparador+"</span>"+
                  "<span style='font-size:14px'><b>"+separador+"</b>"+despuesSeparador+"</span>";            
          } else {
              rta="<span style='font-size:11px'>"+antesSeparador+"</span><br>"+
                  "<span style='font-size:14px'><b>"+separador+"</b>"+despuesSeparador+"</span>";                        
          }
          // console.log("Show2RowsPipe",rta)    
        }

        return rta;
    }
}