export const PAISES: any[] = [
    { key   : 'AF', nombre: 'Afganistán', OrganizadoEn: 'Provincias' },
    { key   : 'AL', nombre: 'Albania', OrganizadoEn: 'Prefecturas' },
    { key   : 'DZ', nombre: 'Argelia', OrganizadoEn: 'Provincias' },
    { key   : 'AD', nombre: 'Andorra', OrganizadoEn: 'Parroquias' },
    { key   : 'AO', nombre: 'Angola', OrganizadoEn: 'Provincias' },
    { key   : 'AG', nombre: 'Antigua y Barbuda', OrganizadoEn: 'Parroquias' },
    { key   : 'AR', nombre: 'Argentina', OrganizadoEn: 'Provincias' },
    { key   : 'AM', nombre: 'Armenia', OrganizadoEn: 'Provincias' },
    { key   : 'AU', nombre: 'Australia', OrganizadoEn: 'Estados' },
    { key   : 'AT', nombre: 'Austria', OrganizadoEn: 'Estados' },
    { key   : 'AZ', nombre: 'Azerbaiyán', OrganizadoEn: 'Rayones' },
    { key   : 'BS', nombre: 'Bahamas', OrganizadoEn: 'Distritos' },
    { key   : 'BH', nombre: 'Baréin', OrganizadoEn: 'Gobernaciones' },
    { key   : 'BD', nombre: 'Bangladés', OrganizadoEn: 'Divisiones' },
    { key   : 'BB', nombre: 'Barbados', OrganizadoEn: 'Parroquias' },
    { key   : 'BY', nombre: 'Belarús', OrganizadoEn: 'Óblasts' },
    { key   : 'BE', nombre: 'Bélgica', OrganizadoEn: 'Regiones' },
    { key   : 'BZ', nombre: 'Belice', OrganizadoEn: 'Distritos' },
    { key   : 'BJ', nombre: 'Benin', OrganizadoEn: 'Departamentos' },
    { key   : 'BT', nombre: 'Bután', OrganizadoEn: 'Distritos' },
    { key   : 'BO', nombre: 'Bolivia', OrganizadoEn: 'Departamentos' },
    { key   : 'BA', nombre: 'Bosnia y Herzegovina', OrganizadoEn: 'Cantones' },
    { key   : 'BW', nombre: 'Botswana', OrganizadoEn: 'Distritos' },
    { key   : 'BR', nombre: 'Brasil', OrganizadoEn: 'Estados' },
    { key   : 'BN', nombre: 'Brunéi Darussalam', OrganizadoEn: 'Distritos' },
    { key   : 'BG', nombre: 'Bulgaria', OrganizadoEn: 'Provincias' },
    { key   : 'BF', nombre: 'Burkina Faso', OrganizadoEn: 'Regiones' },
    { key   : 'BI', nombre: 'Burundi', OrganizadoEn: 'Provincias' },
    { key   : 'KH', nombre: 'Camboya', OrganizadoEn: 'Provincias' },
    { key   : 'CM', nombre: 'Camerún', OrganizadoEn: 'Regiones' },
    { key   : 'CA', nombre: 'Canadá', OrganizadoEn: 'Provincias' },
    { key   : 'CV', nombre: 'Cabo Verde', OrganizadoEn: 'Concelhos' },
    { key   : 'CF', nombre: 'República Centroafricana', OrganizadoEn: 'Prefecturas' },
    { key   : 'CL', nombre: 'Chile', OrganizadoEn: 'Regiones' },
    { key   : 'CN', nombre: 'China', OrganizadoEn: 'Provincias' },
    { key   : 'CY', nombre: 'Chipre', OrganizadoEn: 'Distritos' },
    { key   : 'CO', nombre: 'Colombia', OrganizadoEn: 'Departamentos' },
    { key   : 'KM', nombre: 'Comoras', OrganizadoEn: 'Regiones' },
    { key   : 'CG', nombre: 'Congo', OrganizadoEn: 'Departamentos' },
    { key   : 'CD', nombre: 'República Democrática del Congo', OrganizadoEn: 'Provincias' },
    { key   : 'CR', nombre: 'Costa Rica', OrganizadoEn: 'Provincias' },
    { key   : 'CI', nombre: 'Costa de Marfil', OrganizadoEn: 'Distritos' },
    { key   : 'HR', nombre: 'Croacia', OrganizadoEn: 'Condados' },
    { key   : 'CU', nombre: 'Cuba', OrganizadoEn: 'Provincias' },
    { key   : 'CY', nombre: 'Chipre', OrganizadoEn: 'Distritos' },
    { key   : 'CZ', nombre: 'República Checa', OrganizadoEn: 'Regiones' },
    { key   : 'DK', nombre: 'Dinamarca', OrganizadoEn: 'Regiones' },
    { key   : 'DJ', nombre: 'Yibuti', OrganizadoEn: 'Regiones' },
    { key   : 'DM', nombre: 'Dominica', OrganizadoEn: 'Parroquias' },
    { key   : 'DO', nombre: 'República Dominicana', OrganizadoEn: 'Provincias' },
    { key   : 'EC', nombre: 'Ecuador', OrganizadoEn: 'Provincias' },
    { key   : 'EG', nombre: 'Egipto', OrganizadoEn: 'Gobernaciones' },
    { key   : 'SV', nombre: 'El Salvador', OrganizadoEn: 'Departamentos' },
    { key   : 'GQ', nombre: 'Guinea Ecuatorial', OrganizadoEn: 'Provincias' },
    { key   : 'ER', nombre: 'Eritrea', OrganizadoEn: 'Zobas' },
    { key   : 'EE', nombre: 'Estonia', OrganizadoEn: 'Condados' },
    { key   : 'ET', nombre: 'Etiopía', OrganizadoEn: 'Regiones' },
    { key   : 'FJ', nombre: 'Fiyi', OrganizadoEn: 'Divisiones' },
    { key   : 'FI', nombre: 'Finlandia', OrganizadoEn: 'Regiones' },
    { key   : 'FR', nombre: 'Francia', OrganizadoEn: 'Regiones' },
    { key   : "GA", nombre: "Gabón", OrganizadoEn: "Provincias" },
    { key   : "GM", nombre: "Gambia", OrganizadoEn: "Divisiones" },
    { key   : "GE", nombre: "Georgia", OrganizadoEn: "Regiones" },
    { key   : "DE", nombre: "Alemania", OrganizadoEn: "Länder" },
    { key   : "GH", nombre: "Ghana", OrganizadoEn: "Regiones" },
    { key   : "GI", nombre: "Gibraltar", OrganizadoEn: "Áreas" },
    { key   : "GR", nombre: "Grecia", OrganizadoEn: "Periferias" },
    { key   : "GL", nombre: "Groenlandia", OrganizadoEn: "Municipios" },
    { key   : "GD", nombre: "Granada", OrganizadoEn: "Parroquias" },
    { key   : "GP", nombre: "Guadalupe", OrganizadoEn: "Departamentos" },
    { key   : "GU", nombre: "Guam", OrganizadoEn: "Municipios" },
    { key   : "GT", nombre: "Guatemala", OrganizadoEn: "Departamentos" },
    { key   : "GG", nombre: "Guernsey", OrganizadoEn: "Parroquias" },
    { key   : "GN", nombre: "Guinea", OrganizadoEn: "Regiones" },
    { key   : "GW", nombre: "Guinea-Bissau", OrganizadoEn: "Regiones" },
    { key   : "GY", nombre: "Guyana", OrganizadoEn: "Regiones" },
    { key   : "HT", nombre: "Haití", OrganizadoEn: "Departamentos" },
    { key   : "HN", nombre: "Honduras", OrganizadoEn: "Departamentos" },
    { key   : "HK", nombre: "Hong Kong", OrganizadoEn: "Distritos" },
    { key   : "HU", nombre: "Hungría", OrganizadoEn: "Condados" },
    { key   : "IS", nombre: "Islandia", OrganizadoEn: "Regiones" },
    { key   : "IN", nombre: "India", OrganizadoEn: "Estados" },
    { key   : "ID", nombre: "Indonesia", OrganizadoEn: "Provincias" },
    { key   : "IR", nombre: "Irán", OrganizadoEn: "Provincias" },
    { key   : "IQ", nombre: "Iraq", OrganizadoEn: "Gobernaciones" },
    { key   : "IE", nombre: "Irlanda", OrganizadoEn: "Condados" },
    { key   : "IM", nombre: "Isla de Man", OrganizadoEn: "Parroquias" },
    { key   : "IL", nombre: "Israel", OrganizadoEn: "Distritos" },
    { key   : "IT", nombre: "Italia", OrganizadoEn: "Regiones" },
    { key   : 'IT', nombre: 'Italia', OrganizadoEn: 'Regiones' },
    { key   : 'JE', nombre: 'Jersey', OrganizadoEn: 'Parroquias' },
    { key   : 'JM', nombre: 'Jamaica', OrganizadoEn: 'Parroquias' },
    { key   : 'JO', nombre: 'Jordania', OrganizadoEn: 'Gobernaciones' },
    { key   : 'JP', nombre: 'Japón', OrganizadoEn: 'Prefecturas' },
    { key   : 'KE', nombre: 'Kenia', OrganizadoEn: 'Condados' },
    { key   : 'KG', nombre: 'Kirguistán', OrganizadoEn: 'Provincias' },
    { key   : 'KH', nombre: 'Camboya', OrganizadoEn: 'Provincias' },
    { key   : 'KI', nombre: 'Kiribati', OrganizadoEn: 'Islas' },
    { key   : 'KM', nombre: 'Comoras', OrganizadoEn: 'Islas' },
    { key   : 'KN', nombre: 'San Cristóbal y Nieves', OrganizadoEn: 'Parroquias' },
    { key   : 'KP', nombre: 'Corea del Norte', OrganizadoEn: 'Provincias' },
    { key   : 'KR', nombre: 'Corea del Sur', OrganizadoEn: 'Provincias' },
    { key   : 'KW', nombre: 'Kuwait', OrganizadoEn: 'Gobernaciones' },
    { key   : 'KY', nombre: 'Islas Caimán', OrganizadoEn: 'Distritos' },
    { key   : 'KZ', nombre: 'Kazajistán', OrganizadoEn: 'Regiones' },
    { key   : 'LA', nombre: 'Laos', OrganizadoEn: 'Provincias' },
    { key   : 'LB', nombre: 'Líbano', OrganizadoEn: 'Gobernaciones' },
    { key   : 'LC', nombre: 'Santa Lucía', OrganizadoEn: 'Distritos' },
    { key   : 'LI', nombre: 'Liechtenstein', OrganizadoEn: 'Municipios' },
    { key   : 'LK', nombre: 'Sri Lanka', OrganizadoEn: 'Provincias' },
    { key   : 'LR', nombre: 'Liberia', OrganizadoEn: 'Condados' },
    { key   : 'LS', nombre: 'Lesoto', OrganizadoEn: 'Distritos' },
    { key   : 'LT', nombre: 'Lituania', OrganizadoEn: 'Distritos' },
    { key   : 'LU', nombre: 'Luxemburgo', OrganizadoEn: 'Distritos' },
    { key   : 'LV', nombre: 'Letonia', OrganizadoEn: 'Distritos' },
    { key   : 'LY', nombre: 'Libia', OrganizadoEn: 'Distritos' },
    { key   : 'MA', nombre: 'Marruecos', OrganizadoEn: 'Regiones' },
    { key   : 'MC', nombre: 'Mónaco', OrganizadoEn: 'Barrios' },
    { key   : 'MD', nombre: 'Moldavia', OrganizadoEn: 'Distritos' },
    { key   : 'ME', nombre: 'Montenegro', OrganizadoEn: 'Municipios' },
    { key   : "MF", nombre: "San Martín (Francia)", OrganizadoEn: "Arrondissements" },
    { key   : "MG", nombre: "Madagascar", OrganizadoEn: "Regiones" },
    { key   : "MH", nombre: "Islas Marshall", OrganizadoEn: "Municipios y Aldeas" },
    { key   : "MK", nombre: "Macedonia del Norte", OrganizadoEn: "Municipios" },
    { key   : "ML", nombre: "Mali", OrganizadoEn: "Regiones" },
    { key   : "MM", nombre: "Myanmar", OrganizadoEn: "Regiones" },
    { key   : "MN", nombre: "Mongolia", OrganizadoEn: "Provincias" },
    { key   : "MO", nombre: "Macao", OrganizadoEn: "Parroquias" },
    { key   : "MP", nombre: "Islas Marianas del Norte", OrganizadoEn: "Municipios" },
    { key   : "MQ", nombre: "Martinica", OrganizadoEn: "Comunas" },
    { key   : "MR", nombre: "Mauritania", OrganizadoEn: "Regiones" },
    { key   : "MS", nombre: "Montserrat", OrganizadoEn: "Parroquias" },
    { key   : "MT", nombre: "Malta", OrganizadoEn: "Distritos" },
    { key   : "MU", nombre: "Mauricio", OrganizadoEn: "Distritos" },
    { key   : "MV", nombre: "Maldivas", OrganizadoEn: "Divisiones administrativas" },
    { key   : "MW", nombre: "Malawi", OrganizadoEn: "Regiones" },
    { key   : "MX", nombre: "México", OrganizadoEn: "Estados" },
    { key   : "MY", nombre: "Malasia", OrganizadoEn: "Estados" },
    { key   : "MZ", nombre: "Mozambique", OrganizadoEn: "Provincias" },
    { key   : "NA", nombre: "Namibia", OrganizadoEn: "Regiones" },
    { key   : "NC", nombre: "Nueva Caledonia", OrganizadoEn: "Provincias" },
    { key   : "NE", nombre: "Níger", OrganizadoEn: "Departamentos" },
    { key   : "NF", nombre: "Isla Norfolk", OrganizadoEn: "Áreas de la isla" },
    { key   : "NG", nombre: "Nigeria", OrganizadoEn: "Estados" },
    { key   : "NI", nombre: "Nicaragua", OrganizadoEn: "Departamentos" },
    { key   : "NL", nombre: "Países Bajos", OrganizadoEn: "Provincias" },
    { key   : "NO", nombre: "Noruega", OrganizadoEn: "Condados" },
    { key   : "NP", nombre: "Nepal", OrganizadoEn: "Zonas" },
    { key   : 'NR', nombre: 'Nauru', OrganizadoEn: 'Distritos' },
    { key   : 'NP', nombre: 'Nepal', OrganizadoEn: 'Provincias' },
    { key   : 'NI', nombre: 'Nicaragua', OrganizadoEn: 'Departamentos' },
    { key   : 'NE', nombre: 'Níger', OrganizadoEn: 'Regiones' },
    { key   : 'NG', nombre: 'Nigeria', OrganizadoEn: 'Estados' },
    { key   : 'NU', nombre: 'Niue', OrganizadoEn: 'Territorios' },
    { key   : 'NF', nombre: 'Isla Norfolk', OrganizadoEn: 'Regiones' },
    { key   : 'MP', nombre: 'Islas Marianas del Norte', OrganizadoEn: 'Municipios' },
    { key   : 'NO', nombre: 'Noruega', OrganizadoEn: 'Condados' },
    { key   : 'OM', nombre: 'Omán', OrganizadoEn: 'Gobernaciones' },
    { key   : 'PK', nombre: 'Pakistán', OrganizadoEn: 'Provincias' },
    { key   : 'PW', nombre: 'Palau', OrganizadoEn: 'Estados' },
    { key   : 'PA', nombre: 'Panamá', OrganizadoEn: 'Provincias' },
    { key   : 'PG', nombre: 'Papúa Nueva Guinea', OrganizadoEn: 'Provincias' },
    { key   : 'PY', nombre: 'Paraguay', OrganizadoEn: 'Departamentos' },
    { key   : 'PE', nombre: 'Perú', OrganizadoEn: 'Departamentos' },
    { key   : 'PH', nombre: 'Filipinas', OrganizadoEn: 'Regiones' },
    { key   : 'PL', nombre: 'Polonia', OrganizadoEn: 'Voivodatos' },
    { key   : 'PT', nombre: 'Portugal', OrganizadoEn: 'Regiones' },
    { key   : 'PR', nombre: 'Puerto Rico', OrganizadoEn: 'Municipios' },
    { key   : 'QA', nombre: 'Qatar', OrganizadoEn: 'Municipios' },
    { key   : 'GB', nombre: 'Reino Unido', OrganizadoEn: 'Regiones' },
    { key   : 'CF', nombre: 'República Centroafricana', OrganizadoEn: 'Prefecturas' },
    { key   : 'CZ', nombre: 'República Checa', OrganizadoEn: 'Regiones' },
    { key   : 'CD', nombre: 'República Democrática del Congo', OrganizadoEn: 'Provincias' },
    { key   : 'DO', nombre: 'República Dominicana', OrganizadoEn: 'Provincias' },
    { key   : 'RE', nombre: 'Reunión', OrganizadoEn: 'Comunas' },
    { key   : 'RW', nombre: 'Ruanda', OrganizadoEn: 'Provincias' },
    { key   : 'RO', nombre: 'Rumania', OrganizadoEn: 'Condados' },
    { key   : 'RU', nombre: 'Rusia', OrganizadoEn: 'Sujetos federales' },
    { key   : 'RU', nombre: 'Rusia', OrganizadoEn: 'Oblasts' },
    { key   : 'RW', nombre: 'Ruanda', OrganizadoEn: 'Provincias' },
    { key   : 'KN', nombre: 'Saint Kitts y Nevis', OrganizadoEn: 'Parroquias' },
    { key   : 'LC', nombre: 'Santa Lucía', OrganizadoEn: 'Distritos' },
    { key   : 'VC', nombre: 'San Vicente y las Granadinas', OrganizadoEn: 'Parroquias' },
    { key   : 'WS', nombre: 'Samoa', OrganizadoEn: 'Distritos' },
    { key   : 'AS', nombre: 'Samoa Americana', OrganizadoEn: 'Distritos' },
    { key   : 'SM', nombre: 'San Marino', OrganizadoEn: 'Municipalities' },
    { key   : 'ST', nombre: 'Santo Tomé y Príncipe', OrganizadoEn: 'Distritos' },
    { key   : 'SA', nombre: 'Arabia Saudita', OrganizadoEn: 'Regiones' },
    { key   : 'SN', nombre: 'Senegal', OrganizadoEn: 'Regiones' },
    { key   : 'RS', nombre: 'Serbia', OrganizadoEn: 'Distritos' },
    { key   : 'SC', nombre: 'Seychelles', OrganizadoEn: 'Distritos' },
    { key   : 'SL', nombre: 'Sierra Leona', OrganizadoEn: 'Distritos' },
    { key   : 'SG', nombre: 'Singapur', OrganizadoEn: 'Regiones' },
    { key   : 'SX', nombre: 'Sint Maarten', OrganizadoEn: 'Distritos' },
    { key   : 'SK', nombre: 'Eslovaquia', OrganizadoEn: 'Regiones' },
    { key   : 'SI', nombre: 'Eslovenia', OrganizadoEn: 'Municipalities' },
    { key   : 'SB', nombre: 'Islas Salomón', OrganizadoEn: 'Provincias' },
    { key   : 'SO', nombre: 'Somalia', OrganizadoEn: 'Regiones' },
    { key   : 'ZA', nombre: 'Sudáfrica', OrganizadoEn: 'Provincias' },
    { key   : 'SS', nombre: 'Sudán del Sur', OrganizadoEn: 'Estados' },
    { key   : 'ES', nombre: 'España', OrganizadoEn: 'Comunidades Autónomas' },
    { key   : 'LK', nombre: 'Sri Lanka', OrganizadoEn: 'Provincias' },
    { key   : 'SD', nombre: 'Sudán', OrganizadoEn: 'Estados' },
    { key   : 'SR', nombre: 'Surinam', OrganizadoEn: 'Distritos' },
    { key   : 'SE', nombre: 'Suecia', OrganizadoEn: 'Condados' },
    { key   : 'CH', nombre: 'Suiza', OrganizadoEn: 'Cantones' },
    { key   : 'SY', nombre: 'Siria', OrganizadoEn: 'Gobernorados' },
    { key   : 'TW', nombre: 'Taiwán', OrganizadoEn: 'Municipios' },
    {key    : 'TJ', nombre: 'Tayikistán', OrganizadoEn: 'Provincias'},
    {key    : 'TZ', nombre: 'Tanzania', OrganizadoEn: 'Regiones'},
    {key    : 'TH', nombre: 'Tailandia', OrganizadoEn: 'Provincias'},
    {key    : 'TL', nombre: 'Timor Oriental', OrganizadoEn: 'Distritos'},
    {key    : 'TG', nombre: 'Togo', OrganizadoEn: 'Regiones'},
    {key    : 'TK', nombre: 'Tokelau', OrganizadoEn: 'Atolones'},
    {key    : 'TO', nombre: 'Tonga', OrganizadoEn: 'Divisiones'},
    {key    : 'TT', nombre: 'Trinidad y Tobago', OrganizadoEn: 'Regiones'},
    {key    : 'TN', nombre: 'Túnez', OrganizadoEn: 'Gobernorados'},
    {key    : 'TR', nombre: 'Turquía', OrganizadoEn: 'Provincias'},
    {key    : 'TM', nombre: 'Turkmenistán', OrganizadoEn: 'Provincias'},
    {key    : 'TC', nombre: 'Islas Turcas y Caicos', OrganizadoEn: 'Distritos'},
    {key    : 'TV', nombre: 'Tuvalu', OrganizadoEn: 'Islas'},
    {key    : 'UG', nombre: 'Uganda', OrganizadoEn: 'Regiones'},
    {key    : 'UA', nombre: 'Ucrania', OrganizadoEn: 'Óblast'},
    {key    : 'AE', nombre: 'Emiratos Árabes Unidos', OrganizadoEn: 'Emiratos'},
    {key    : 'GB', nombre: 'Reino Unido', OrganizadoEn: 'Condados'},
    {key    : 'US', nombre: 'Estados Unidos', OrganizadoEn: 'Estados'},
    {key    : 'UY', nombre: 'Uruguay', OrganizadoEn: 'Departamentos'},
    {key    : 'UZ', nombre: 'Uzbekistán', OrganizadoEn: 'Regiones'},
    {key    : 'VU', nombre: 'Vanuatu', OrganizadoEn: 'Provincias'},
    {key    : 'VE', nombre: 'Venezuela', OrganizadoEn: 'Estados'},
    {key    : 'VN', nombre: 'Vietnam', OrganizadoEn: 'Provincias'},
    {key    : 'WF', nombre: 'Wallis y Futuna', OrganizadoEn: 'Circunscripciones'},
    {key    : 'YE', nombre: 'Yemen', OrganizadoEn: 'Gobernorados'},
    {key    : 'ZM', nombre: 'Zambia', OrganizadoEn: 'Provincias'},
    {key    : 'ZW', nombre: 'Zimbabue', OrganizadoEn: 'Provincias'},
]


export const PROVINCIAS:any = [

];