import { MenuService } from '@maq-servicios/menu/menu.service';
import { log, logIf, logTable, values } from '@maq-console';

import { Injectable } from '@angular/core';

import { Menu } from '@maq-models/menu/menu.model';
import { FuncionesService }  from '@maq-funciones';
import { MensajesService }  from '@maq-servicios/mensajes/mensajes.service';

import { Observable, ReplaySubject, Subscription, merge } from 'rxjs';
import { AppSettingsService } from '@settings/app.settings';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { environment }      from '../../../../environments/environment';
import { HttpClient }       from '@angular/common/http';
@Injectable()
export class MenuColeccionService {

  public menuItemsSubject: ReplaySubject<any> = new ReplaySubject<any>(1);
  public menuItemsSubjectObservable: Observable<any> = this.menuItemsSubject.asObservable();
  public refSubscripcion=null;
  public fechaHoraUltimaActualizacionPerfil=null;

  constructor(public fn:FuncionesService,
              private msg:MensajesService,
              public appSettings:AppSettingsService, 
              private http: HttpClient
             ){ 

      this.obtenerMenueDelPerfil();
  } 

  public obtenerMenueDelPerfil() {

      this.refSubscripcion=this.msg.getPerfil().subscribe(usuario=>{
          // console.log('yy obtenerMenueDelPerfil usuario',usuario);
          if(usuario) {

              let actualizarMenu=true;
            
              // todo: preguntar a Santiago, porque se usa este settings en menu?
              this.fechaHoraUltimaActualizacionPerfil = this.fn.timestampToDate( usuario.perfilUsuario.settings.fechaHoraModificacion );
              // console.log('yy fechaHoraUltimaActualizacionPerfil',this.fechaHoraUltimaActualizacionPerfil);

              actualizarMenu=false;

              // Leo LocalStorage
              let cacheMenu = JSON.parse(localStorage.getItem("Menues"+this.appSettings.settings2.app.name+usuario.email));
              if(cacheMenu) {
                  let fechaHoraCacheMenu = new Date(cacheMenu.fechaHora);
                  // console.log('yy fechaHoraCacheMenu',fechaHoraCacheMenu);

                  let comparacion = this.fn.compareFechas(fechaHoraCacheMenu, this.fechaHoraUltimaActualizacionPerfil);
                  // console.log("yy comparacion",comparacion);
                  if(comparacion=='1menor2') {
                      actualizarMenu=true;
                  }
              } else {
                  actualizarMenu=true;
              }

              if(actualizarMenu==false && environment.tipoServidor.baseDatos=='firebase' ) {
                  log(...values("info","actualizó Menú del Cache"));
                  let menuItems = cacheMenu.menu;
                  this.menuItemsSubject.next(menuItems);
                  return;
              }

              if(!usuario) {
                  this.menuItemsSubject.complete();
                  return;
              }

              this.getPromesaMenu().then(datos=>{
                    let menuItems = datos; 
                    let menuPermitidos:any[]=[];
                    if (!sessionStorage.getItem('menuesPermitidos') || JSON.parse(sessionStorage.getItem('menuesPermitidos')).length==0){
                        let listaPermisos:any[]=this.getArrayPermisos(usuario);
                        menuPermitidos=this.getArrayMenuesPermitidos(listaPermisos,menuItems);
                        sessionStorage.setItem('menuesPermitidos', JSON.stringify(menuPermitidos)); 
                    }
                    else{
                        JSON.parse(sessionStorage.getItem('menuesPermitidos')).forEach((menuItem:Menu) => {
                            let menuNuevo = new Menu(menuItem);
                            menuPermitidos.push(menuNuevo);
                        });  
                    }
                    
                    // Grabo LocalStorage
                    let cache = {
                      fechaHora: this.fechaHoraUltimaActualizacionPerfil,
                      menu     : menuPermitidos                      
                    }
                    localStorage.setItem('Menues'+this.appSettings.settings2.app.name+usuario.email, JSON.stringify(cache));
                    
                    //sessionStorage.setItem('menuesPermitidos', JSON.stringify(menuItems));
                    
                    log(...values("info","actualizó Menú del Firestore"));
                    this.menuItemsSubject.next(menuPermitidos);
              });
              
           }  // fin if(usuario)   

      });

  }

  public desubscribeMenuService() {
    if(this.refSubscripcion) {
      this.refSubscripcion.unsubscribe();
    }
  }  

  public getPromesaMenu():Promise<any> {
    return new Promise((resolve, reject)=> {
        var listado=[]; 
        
        if(environment.tipoServidor.baseDatos=='mssql'){
         
            this.http.get(environment.serviciosExternos.sql.apiURL+'api/menu').subscribe(data=>{
                resolve(data);
            },error=>{
                reject(error);
            });

        }else {

           
            var docRef = firebase.firestore().collection('Menues');
            docRef.orderBy('orden', 'asc');
            docRef.get().then(respuesta=> {
                // console.log("getPromesaMenu menuItems",respuesta.docs[0]);
                if (respuesta.docs.length>0) {
                    for(let i=0; i<respuesta.docs.length; i++) {
                    // console.log("getPromesaMenu menuItems",i,respuesta.docs[i].data());
                        let opcion=respuesta.docs[i].data();
                        listado.push(opcion);                   
                    }  
                    resolve(listado);
        
                } else {
                    // console.log("No se encontraron datos para la colección Menúes");
                    resolve(listado)
                }
            }).catch(function(error) {
                // console.log("Error al acceer a Menúes", error);
                reject("Error al acceer a Menúes");
            });
        
    
        }

    });

  }
    getArrayPermisos(usuario:any):any[]{
        let listaPermisos =[];
        if(usuario.perfilUsuario.permisosMenu){
            listaPermisos=usuario.perfilUsuario.permisosMenu.sort((a,b)=>{
                return a.orden-b.orden
            })
        }
        return listaPermisos;
    }

    getArrayMenuesPermitidos(listaPermisos:any[], menuItems:any[]):Menu[]{
        let listaMenuPermitido:Menu[]=[];
        for (var i in listaPermisos) {
            let permisosMenu =listaPermisos[i]; //obtengo cada elemento del array
            if(permisosMenu.check){
                  for(let j=0; j<menuItems.length; j++) {
                    if(menuItems[j].id == permisosMenu.id) {
                        let menu:Menu = new Menu(menuItems[j]);
                        menu.eliminaFaIcon();
                        listaMenuPermitido.push(menu);    
                    }
                  }
            }
        };  // fin for
        return listaMenuPermitido;
    }

}


