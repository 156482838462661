import { Routes, RouterModule, PreloadAllModules  } from '@angular/router';
import { UsuarioNoAutorizadoComponent } from './maqueta/panel/componentes/usuario-no-autorizado/usuario-no-autorizado.page';
import { PrincipalComponent }           from './maqueta/panel/componentes/principal/principal.component';

/* Guards */
import { CanActivateViaAuthGuard } from '@maq-autorizacion/guards/can-activate-via-auth-guard';
import { CanActivateViaNoAuthGuard } from '@maq-autorizacion/guards/can-activate-via-no-auth-guard';
import { CanActivateChildViaAuthGuard } from '@maq-autorizacion/guards/can-activate-child-via-auth-guard';
import { CanActivateChildViaNoAuthGuard } from '@maq-autorizacion/guards/can-activate-child-via-no-auth-guard';

export const childrenPublicAdmin = [   

      /* Modulos de maqueta */   
      { path: 'configuracionAuxiliares', loadChildren: () => import('@maq-modules/configuracion-auxiliares/configuracion-auxiliares.module').then(m => m.ConfiguracionAuxiliaresModule), data: { breadcrumb: 'breadcrumb.auxiliares' } ,  canActivate: [CanActivateViaAuthGuard]},
      { path: 'auxiliares'             , loadChildren: () => import('@maq-modules/auxiliares/auxiliares.module').then(m => m.AuxiliaresModule), data: { breadcrumb: 'breadcrumb.auxiliares' } ,  canActivate: [CanActivateViaAuthGuard]},
      { path: 'usuarios'               , loadChildren: () => import('@maq-modules/usuarios/usuarios.module').then(m => m.UsuariosModule), data: { breadcrumb: 'breadcrumb.usuarios' } ,  canActivate: [CanActivateViaAuthGuard]},
      { path: 'perfiles'               , loadChildren: () => import('@maq-modules/perfiles/perfiles.module').then(m => m.PerfilesModule), data: { breadcrumb: 'breadcrumb.listadoPerfiles' },  canActivate: [CanActivateViaAuthGuard] },
      { path: 'tickets'                , loadChildren: () => import('@maq-modules/tickets/tickets.module')  .then(m => m.TicketsModule ), data: { breadcrumb: 'breadcrumb.tickets' },  canActivate: [CanActivateViaAuthGuard] },
      { path: 'logsAuditoria'          , loadChildren: () => import('@maq-modules/logs-auditoria/log-auditoria.module')  .then(m => m.LogAuditoriaModule ), data: { breadcrumb: 'breadcrumb.logsAuditoria' } ,  canActivate: [CanActivateViaAuthGuard]},
      { path: 'menues'                 , loadChildren: () => import('@maq-modules/menues/menues.module')    .then(m => m.MenuesModule ), data: { breadcrumb: 'breadcrumb.menues' } ,  canActivate: [CanActivateViaAuthGuard]},
      { path: 'totalesApis'            , loadChildren: () => import('@maq-modules/totales-apis/totales-apis.module')  .then(m => m.TotalesApisModule ), data: { breadcrumb: 'breadcrumb.totalesApis' } ,  canActivate: [CanActivateViaAuthGuard]},
      { path: 'totalesSqlApis'         , loadChildren: () => import('@maq-modules/totales-sql-apis/totales-sql-apis.module')  .then(m => m.TotalesSqlApisModule ), data: { breadcrumb: 'breadcrumb.totalesSqlApis' } ,  canActivate: [CanActivateViaAuthGuard]},
      { path: 'banners'                , loadChildren: () => import('@maq-modules/banners/banners.module')  .then(m => m.BannersModule ), data: { breadcrumb: 'moduloDesarrolladores.banners' } ,  canActivate: [CanActivateViaAuthGuard]},
      { path: 'articulos'              , loadChildren: () => import('@maq-modules/articulos/articulos.module')  .then(m => m.ArticulosModule ), data: { breadcrumb: 'moduloArticulos.articulos' } ,  canActivate: [CanActivateViaAuthGuard]},
      { path: 'secciones'              , loadChildren: () => import('@maq-modules/secciones/secciones.module')  .then(m => m.SeccionesModule ), data: { breadcrumb: 'moduloSecciones.secciones' } ,  canActivate: [CanActivateViaAuthGuard]},
      { path: 'galerias'               , loadChildren: () => import('@maq-modules/galerias/galerias.module')  .then(m => m.GaleriasModule ), data: { breadcrumb: 'moduloSecciones.galeriasFotos' } ,  canActivate: [CanActivateViaAuthGuard]},
      { path: 'manipulacion-json'       ,loadChildren: () => import('@maq-modules/manipulacion-json/manipulacion-json.module').then(m => m.ManipulacionJsonModule), data: { breadcrumb: 'breadcrumb.compararJson' },  canActivate: [CanActivateViaAuthGuard]  },                
      
      { path: 'usuarioNoAutorizado', component: UsuarioNoAutorizadoComponent, data: { breadcrumb: 'breadcrumb.usuarioNoAutorizado' } },

];

export const childrenPublicWeb:Routes = [
   
//   {   path: 'usuarios', component: PrincipalComponent,     
//        children:[     
//         { path: '' , loadChildren: () => import('@maq-modules/usuarios/usuarios.module').then(m => m.UsuariosModule), data: { breadcrumb: 'breadcrumb.usuarios' } },        
//       ]
//   }
   
   {
      path: 'configuracionAuxiliares', component: PrincipalComponent,     
      children:[     
         { path: '', loadChildren: () => import('@maq-modules/configuracion-auxiliares/configuracion-auxiliares.module').then(m => m.ConfiguracionAuxiliaresModule), data: { breadcrumb: 'breadcrumb.auxiliares' } ,  canActivate: [CanActivateViaAuthGuard]},
      ]
   },{
      path: 'auxiliares', component: PrincipalComponent,     
      children:[     
         { path: '', loadChildren: () => import('@maq-modules/auxiliares/auxiliares.module').then(m => m.AuxiliaresModule), data: { breadcrumb: 'breadcrumb.auxiliares' } ,  canActivate: [CanActivateViaAuthGuard]},
      ]
   },{
      path: 'usuarios', component: PrincipalComponent,     
      children:[     
       { path: '' , loadChildren: () => import('@maq-modules/usuarios/usuarios.module').then(m => m.UsuariosModule), data: { breadcrumb: 'breadcrumb.usuarios' } },        
      ]
   },{
      path: 'perfiles', component: PrincipalComponent,     
      children:[     
         { path: '', loadChildren: () => import('@maq-modules/perfiles/perfiles.module').then(m => m.PerfilesModule), data: { breadcrumb: 'breadcrumb.listadoPerfiles' },  canActivate: [CanActivateViaAuthGuard] },
      ]
   },{
      path: 'tickets', component: PrincipalComponent,     
      children:[     
         { path: '', loadChildren: () => import('@maq-modules/tickets/tickets.module')  .then(m => m.TicketsModule ), data: { breadcrumb: 'breadcrumb.tickets' },  canActivate: [CanActivateViaAuthGuard] },
      ]
   },{
      path: 'logsAuditoria', component: PrincipalComponent,     
      children:[     
         { path: '', loadChildren: () => import('@maq-modules/logs-auditoria/log-auditoria.module')  .then(m => m.LogAuditoriaModule ), data: { breadcrumb: 'breadcrumb.logsAuditoria' } ,  canActivate: [CanActivateViaAuthGuard]},
      ]
   },{
      path: 'menues', component: PrincipalComponent,     
      children:[     
         { path: '', loadChildren: () => import('@maq-modules/menues/menues.module')    .then(m => m.MenuesModule ), data: { breadcrumb: 'breadcrumb.menues' } ,  canActivate: [CanActivateViaAuthGuard]},
      ]
   },{
      path: 'totalesApis', component: PrincipalComponent,     
      children:[     
         { path: '', loadChildren: () => import('@maq-modules/totales-apis/totales-apis.module')  .then(m => m.TotalesApisModule ), data: { breadcrumb: 'breadcrumb.logsAuditoria' } ,  canActivate: [CanActivateViaAuthGuard]},
      ]
   },{
      path: 'banners', component: PrincipalComponent,     
      children:[     
         { path: '', loadChildren: () => import('@maq-modules/banners/banners.module')  .then(m => m.BannersModule ), data: { breadcrumb: 'moduloDesarrolladores.banners' } ,  canActivate: [CanActivateViaAuthGuard]},
      ]
   },{
      path: 'articulos', component: PrincipalComponent,     
      children:[     
         { path: '', loadChildren: () => import('@maq-modules/articulos/articulos.module')  .then(m => m.ArticulosModule ), data: { breadcrumb: 'moduloDesarrolladores.banners' } ,  canActivate: [CanActivateViaAuthGuard]},
      ]
   },{
      path: 'secciones', component: PrincipalComponent,     
      children:[     
         { path: '', loadChildren: () => import('@maq-modules/secciones/secciones.module')  .then(m => m.SeccionesModule ), data: { breadcrumb: 'moduloSecciones.secciones' } ,  canActivate: [CanActivateViaAuthGuard]},
      ]
   },{
      path: 'galerias', component: PrincipalComponent,     
      children:[     
         { path: '', loadChildren: () => import('@maq-modules/galerias/galerias.module')  .then(m => m.GaleriasModule ), data: { breadcrumb: 'moduloSecciones.galeriasFotos' } ,  canActivate: [CanActivateViaAuthGuard]},
      ]
   },   
   
   
   {
      path: 'usuarioNoAutorizado', component: PrincipalComponent,     
      children:[     
         { path: '', component: UsuarioNoAutorizadoComponent, data: { breadcrumb: 'breadcrumb.usuarioNoAutorizado' } },
      ]
   }  
   
   
   

];
