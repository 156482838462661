import { log, logIf, logTable, values } from '@maq-console';

import { Component, OnInit, OnDestroy, ViewEncapsulation, ChangeDetectorRef,ViewChild,ElementRef } from '@angular/core';
import { Injectable, Renderer2 } from '@angular/core';

import { settingsWebsite }    from '@settings/app.settings.website';
import { AppSettingsService } from '@settings/app.settings';
import { AppSettings2 }       from '@maq-models/appSettings/appSettings.model';

import { BDService }          from '@maq-bd/bd.service';
import { FuncionesService }   from '@maq-funciones';
import { TranslateService }   from '@ngx-translate/core';
import { MenuWebSiteService } from '@maq-servicios/menu/menu-webSite.service';

import { Router, NavigationEnd } from '@angular/router';

import { map, take     } from 'rxjs/operators';
import {LISTA_DE_TIPOS_OPCION_MENU} from '@settings/maqueta/mocks/varios/varios';

declare let $: any;
declare let jQuery: any;

@Component({
  selector: 'app-header-web',
  templateUrl: './header-web.html',
  styleUrls: [
    './header-web.scss'
  ],
  encapsulation: ViewEncapsulation.None
})

export class HeaderWebComponent implements OnInit, OnDestroy {
 
  constructor ( private translate: TranslateService, 
                private router:Router,
                public bdService : BDService,
                public fn : FuncionesService,
                public appSettings : AppSettingsService,
                private renderer2:Renderer2,
                public menuWebSiteService: MenuWebSiteService) {                   
                  
      this.navigationSubscription = router.events.subscribe(event => {
          this.pathSeccion   = this.router.url;
          // console.log("zzz pathSeccion",this.pathSeccion);;
          if (event instanceof NavigationEnd) {
              const tree = router.parseUrl(router.url);
              // console.log("zzz tree.fragment",tree.fragment);;
              if (tree.fragment) {
                  setTimeout(function () {
                
                      const element:any = document.querySelector("#" + tree.fragment);
                      if (element) {                   
                              //element.scrollIntoView({behavior: "smooth", block: "end", inline: "start"}); 

                              document.getElementById(tree.fragment).scrollIntoView();
                              
                              var position = $(element).offset();
                              var scrollTop = position.top;
                              // console.log("zzz scrollTop scrollTop",scrollTop);
                              $(window).scrollTop(scrollTop-130);

                              // let scrollTop= $("#" + tree.fragment).scrollTop();
                              // console.log("scrollTop",scrollTop);
                              // $("#" + tree.fragment).scrollTop(scrollTop+500);
                              // console.log("scrollTop",scrollTop);
                              
                              // $('body').scrollTo('#target'); // Scroll screen to target element
                              // $('body').scrollTo(500); // Scroll screen 500 pixels down                        
                              // $('#scrollable').scrollTo(100); // Scroll individual element 100 pixels down                        
                              
                              //console.log("scrollTop",$(window).scrollTop());
                              // console.log("scroll",$(window));
                              // console.log("scroll",$(window).scrollY);
                              // var top  = window.pageYOffset || document.documentElement.scrollTop;
                              // console.log("scroll",top);
                              // console.log("scroll",window);
                              
                              //$(window).scrollTop(500);
                              
                      } // fin if(element)   
                      
                  }, 500);             
                  
                  // var el = document.getElementById(tree.fragment);
                  // el.scrollIntoView(true);                  
                  // document.getElementById(tree.fragment).scrollTop += 10;
                  
              } // fin if(tree.fragment)
              
          } // fin if (event instanceof NavigationEnd) {
      });                  
  } 

  public logComponente = log(...values('componente', 'HeaderWebComponent'));  
  public subscripcionMenu;

  public listadoBannersCabezal=[];
  
  public listadoMenuWeb=[];
  public listadoNivel1=[];
  public menuListaTrue=[];
  
  public settings2:AppSettings2;
  public activeLang:any='es';
  public idiomaDefault:any='es';
  public idiomasHabilitados:any[]=[];
  
  public pathSeccion:string=null;
  
  public navigationSubscription=null;  
  
  public settingsWebsite=settingsWebsite;

  ngOnDestroy() {

    log(...values('funcionComponente','ngOnDestroy HeaderWebComponent'));
    
    //if(this.navigationSubscription) this.navigationSubscription.unsubscribe();
    
  }  
  
  ngOnInit() {

     log(...values('funcionComponente', 'ngOnInit HeaderWebComponent'));
     
     console.log("settingsWebsite",settingsWebsite);

     this.pathSeccion   = this.router.url;
     log(...values("valores","pathSeccion:",this.pathSeccion));
     
     let self=this;

     setTimeout(function () {
          //element.scrollIntoView({behavior: "smooth", block: "end", inline: "start"}); 
          this.pathSeccion   = self.router.url;
          const tree = self.router.parseUrl(self.router.url);
          
          console.log("scrollTop scrollTop a",tree.fragment);
          if (tree.fragment) {
              const element:any = document.querySelector("#" + tree.fragment);
              console.log("scrollTop scrollTop a",element);
              if (element) {                   

                    document.getElementById(tree.fragment).scrollIntoView();
                    
                    var position = $(element).offset();
                    var scrollTop = position.top;
                    console.log("scrollTop scrollTop",scrollTop);
                    $(window).scrollTop(scrollTop-130);
              }
          }
     }, 2500);                  

     // Listener de Cambio de Idioma
     this.inicializarVariablesTraducibles();
     this.translate.onLangChange.subscribe(() => {
          this.inicializarVariablesTraducibles();
     });
     
     this.settings2 = this.appSettings.settings2;  
     this.idiomaDefault = this.appSettings.settings2.app.idiomaDefault;
     this.idiomasHabilitados = this.appSettings.settings2.app.idiomasHabilitados;

     // Ejecuta el Servicio del Menú de Website
     this.menuWebSiteService.getMenuWebSiteCache().pipe(take(1)).subscribe(data=>{
        this.listadoMenuWeb = this.menuWebSiteService.cacheMenuWebSite;
        //console.log("Ejecutó this.menuWebSiteService.getMenuWebSiteCache");
        log(...values("valores", "this.listadoMenuWeb:",this.listadoMenuWeb));
        
     },(error:any)=>{
        log(...values("error al Ejecutar this.menuWebSiteService.getMenuWebSiteCache",error));
     }); 

     $(document).on('click', '.nav-menu a, .mobile-nav a, .scrollto', function(e) {
      if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
        e.preventDefault();
        var target = $(this.hash);
        if (target.length) {
          var scrollto = target.offset().top;
          var scrolled = 20;
          if ($('#header').length) {
            console.log('barra','paso2');
            scrollto -= $('#header').outerHeight()
            if (!$('#header').hasClass('header-scrolled')) {
              scrollto += scrolled;
            }
          }
          if ($(this).attr("href") == '#header') {
            scrollto = 0;
          }
          $('html, body').animate({
            scrollTop: scrollto
          }, 1500, 'easeInOutExpo');
          if ($(this).parents('.nav-menu, .mobile-nav').length) {
            $('.nav-menu .active, .mobile-nav .active').removeClass('active');
            $(this).closest('li').addClass('active');
          }
          if ($('body').hasClass('mobile-nav-active')) {
            console.log('barra','paso6');
            $('body').removeClass('mobile-nav-active');
            $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
            $('.mobile-nav-overly').fadeOut();
          }
          return false;
        }
      }
    });
  
    // Mobile Navigation
    if ($('.nav-menu').length) {
      var $mobile_nav = $('.nav-menu').clone().prop({
        class: 'mobile-nav d-lg-none'
      });
      $('body').append($mobile_nav);
      $('body').prepend('<button type="button" class="mobile-nav-toggle d-lg-none"><i class="icofont-navigation-menu"></i></button>');
      $('body').append('<div class="mobile-nav-overly"></div>');
      $(document).on('click', '.mobile-nav-toggle', function(e) {
        $('body').toggleClass('mobile-nav-active');
        $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
        $('.mobile-nav-overly').toggle();
      });
      $(document).on('click', '.mobile-nav .drop-down > a', function(e) {
        e.preventDefault();
        $(this).next().slideToggle(300);
        $(this).parent().toggleClass('active');
      });
      $(document).click(function(e) {
        console.log('barra','paso10');
        var container = $(".mobile-nav, .mobile-nav-toggle");
        if (!container.is(e.target) && container.has(e.target).length === 0) {
          if ($('body').hasClass('mobile-nav-active')) {
            $('body').removeClass('mobile-nav-active');
            $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
            $('.mobile-nav-overly').fadeOut();
          }
        }
      });
    } else if ($(".mobile-nav, .mobile-nav-toggle").length) {
      $(".mobile-nav, .mobile-nav-toggle").hide();
    }
  
    // Stick the header at top on scroll
    $("#header").sticky({
      topSpacing: 0,
      zIndex: '2000'
    });

     
  }

  inicializarVariablesTraducibles()  {
    this.activeLang=this.translate.store.currentLang;
    console.log("this.activeLang",this.activeLang);                      
  }

  showHome(opcion) {
    if(this.settingsWebsite.homeHomeShow==false) {
        if( this.getTitleMenu(opcion)=='home' || this.getTitleMenu(opcion)=='Home') {
          return false;
        } else {
          return true;
        }
    } else {
      return true;
    }
  }

  cerrarMenu(){
      console.log("cerrarMenu");
      $("#mybuttonCollapse").attr("aria-expended", false);      
      $("#mybuttonCollapse").addClass("collapsed");
      $("#navbarNav").removeClass("show");
  }  
   
  linkRoute(ruta:string,anchor:string){
      //this.cerrarMenu();
      if(anchor) {
          this.router.navigate([ruta], { fragment: anchor });  
      } else {
          this.router.navigate([ruta]);  
      }
  }
  
  getTitleMenu(opcion) {
    return opcion['title_'+this.activeLang];    
  }
  
  getSubmenu(opcionId:string):any[] {
      let listado=[];
      for(let i=0; i<this.listadoMenuWeb.length; i++) {
          if(this.listadoMenuWeb[i].parentId==opcionId) {
            listado.push(this.listadoMenuWeb[i]);
          }
      }
      return listado;
  }
  
  getClassMenu(opcion:any):string {
      let rta;
      if(opcion.nivel==2 && opcion.hasSubMenu==true && opcion.anchors==false) {
        rta='nav-item dropdown';
      } else { 
        rta='nav-item';
      }        
      // if(opcion.routerLink==this.pathSeccion) {
      //   rta += ' active';
      //   // console.log("activa !!!!!!!!!!!!!!!");
      // }
      return rta;
  }
  
  getStyleMenuPrincial(routerLink, pathSeccion) {
      let rta;
      if(routerLink==pathSeccion) {
        rta = {
          'font-weight':'bold',
          'color'     : this.settingsWebsite.header.colorFontActive
        }
      } else {
        rta = {
          'font-weight':'none',
          'color'      : this.settingsWebsite.header.colorFont
        }        
      }  
      //console.log("getStyleMenuPrincial",pathSeccion, rta);
      return rta;
  }
  

}