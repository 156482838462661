<i class="fa fa-eyedropper" (click)="abrirFormulario()"></i>

<ng-template #modalContent>

    <div class="modal-header state modal-primary">

        <h4 class="modal-title" id="modal-primary-label"><i class="fa fa-eyedropper"></i>{{traduccion.navegabilidad.seleccionar}}&nbsp;{{traduccion.claseFicha.icono}}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()"><span aria-hidden="true" style="color:white; border:hidden;">×</span></button>
    </div>

    <div class="modal-body bg-white">

        <table class="table table-bordered">
            <tbody>
                <tr>
                    <td>
                        <input [(ngModel)]="searchText" id="searchText" class="form-control">
                    </td>
                    <td>
                        <select class="form-control selectpicker" 
                                id="libreriaIconos" [(ngModel)]="libreriaIconos">
                            <ng-container *ngFor="let libreria of LISTA_DE_LIBRERIAS_ICONOS">
                                <option [ngValue]="libreria.key"
                                        *ngIf="libreria.activo">{{libreria.nombre }}</option>        
                            </ng-container>
                        </select>
                    </td>
                </tr>
            </tbody>
        </table>                


        <ng-container *ngFor="let icono of LISTA_DE_ICONOS; let index=index">

            <button type="button btn btn-link" 
                    [ngStyle]="hover[index] ? { 'border':'3px solid black'} : {} "
                    (mouseover)="hover[index]=true" 
                    (mouseout)="hover[index]=false"
                    style="margin:7px; width:70px; height:70px;"
                    *ngIf="icono.libreria==libreriaIconos && icono.key.indexOf(searchText)!=-1"
                    title="{{icono.key}}"
                    (click)="seleccionarIcono(icono.key)"><i [ngClass]="icono.key" class="mdi-48px" style="font-size:48px;" aria-hidden="true"></i></button>                       

        </ng-container>

        <button type="button btn btn-secondary" class="close" data-dismiss="modal" aria-label="Close" 
                (click)="closeModal()">Terminar</button>

    </div>

</ng-template>
