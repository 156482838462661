import { Injectable } from '@angular/core';
import { log, logIf, logTable, values } from '@maq-console';

// import firebase from 'firebase/app';
// import 'firebase/firestore';
import * as firebase from 'firebase';

import { AngularFirestore  } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { Query, QueryFn } from '@angular/fire/firestore';

import { FuncionesService }  from '@maq-funciones';
import { ApisService }       from '@maq-apis';
import { AppSettingsService } from '@settings/app.settings';
import { updateColeccionModel, postGETProcesaListadoModel, updateDocumentoModel , updateColeccionDataBatch,updateColeccionUserKeysBatch  } from '@maq-models/bd/bd.model';

import { getBDSQL, DatosUpdateBase,} from '@maq-models/bd/bd.model';
import { ArgumentosLlamarBaseDeDatos, updateColeccionDataBatchBase} from '@maq-models/bd/bdBase.model';
import { WhereInterface , OPERADOR_ORDEN_BY,OrdeByInterface, OPERADOR_WHERE, TIPO_BASE_DATOS,OPERACIONES_BASE_DATOS}  from '@maq-models/bd/bdDefinicionesGenerales.model';
import { KANE,KNAI,KN} from '@maq-models/typesKN/typesKN.model';


// Helpers
import { igualarDocumentoConForm} from '@maq-helpers/formHelp';
import { acomodaSettingsEnDocumento} from '@maq-helpers/documentosHelp';
import { formatNumber }                                       from '@angular/common'

import { take, first } from 'rxjs/operators';

import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';

// creo una variable local para pasar el servicio a una libreria.
import { environment } from '../../../../environments/environment';
import {differenceInCalendarQuarters} from 'date-fns';
import { ACCION_FORM } from '@maq-helpers/actionFormHelp';
import { PaginadoFirebase } from '@maq-models/grilla/grillaBase.model';


@Injectable({
  providedIn: 'root'
})
export class BdBaseService { 

  public environment:any={};



  URL_SERVER:String='httpDelServidorDondeEstaLaBase'; // direccion donde está la base de datos

  distribuidorKN   :KN      = null;
  organizacionKNAI :KNAI   = null;
  usuarioKANE      :KANE    = null;
  
  private uploadImagen:any=null;
  public task: AngularFireUploadTask;
  public percentageUploadImagen:number=null;
  public downloadURL: string=null;
  public bytesUpload: number=0;


 

  constructor(public fn:FuncionesService,
              public apis:ApisService,
              public settingService:AppSettingsService,
              public af: AngularFirestore,
              private storage: AngularFireStorage,
              private _http: HttpClient
   ) {
    this.environment=environment;
    // this.environment['tipoServidor']={};
    this.environment['tipoServidor']['negocio']="firestore";
    

  }

  setUrlServer(urlServer:String):void{
    // console.log('setURLServer',urlServer);
    this.URL_SERVER=urlServer;
  }
  
  
  setDistribuidorKN(distribuidorKN:KN):void{
    this.distribuidorKN=distribuidorKN;
  }

  setOrganizacionKNAI(organizacionKNAI:KNAI):void{
    this.organizacionKNAI=organizacionKNAI;
  }

  setUsuarioKANE(usuarioKANE:KANE):void{
   this.usuarioKANE= usuarioKANE;
  }

  getDistribuidorKN():KN{
    return this.distribuidorKN;
  }

  getOrganizacionKNAI():KNAI{
    return this.organizacionKNAI;
  }

  getUsuarioKANE():KANE{
    return this.usuarioKANE;
  }

  

    getStorageURLDownload(path:string): Observable<any> {
        const ref = this.storage.ref(path);
        return ref.getDownloadURL();
    }        
    
  // https://firebase.google.com/docs/storage/web/upload-files?hl=es-419
  uploadFirestoreImage(fieldImage:string, fieldLink:string, path:string, image:any): Promise<any> {
      return new Promise( (resolve,reject)=>{

          let bytes=null;
          let downloadURL=null;

          log(...values('funcionGoPromesa','bdService.uploadFirestoreImage: '+fieldLink));

          const ref = this.storage.ref(path);
          let task: AngularFireUploadTask;

          // var metadata = "base64";
          // var metadata = "base64url";
          var metadata = "data_url";
          task =this.storage.ref(path).putString(image, metadata);

          task.then(async function(snapshot) {

                log(...values('funcionEnd','bdService.uploadFirestoreImage'));

                // log(...values("valores","snapshot:", snapshot));

                bytes = snapshot.totalBytes

                // log(...values('valores','fieldLink: '+fieldLink));
                // log(...values('valores','path: '+path));
                // log(...values("valores","byes:", bytes));
                log(...values("imagen","image:", image));

                downloadURL = await ref.getDownloadURL().toPromise();

                log(...values("link","downloadURL:", downloadURL));

                resolve({
                    fieldImage  : fieldImage,
                    fieldLink   : fieldLink,
                    downloadURL : downloadURL,
                    bytes       :  bytes
                });

          // }).catch(error=> {
          }).catch(function(error) {
                log(...values("error","error Upload",error));

                reject(error);
          });

      });
  }

  updateColeccion(argumentos:updateColeccionModel): Promise<any> {
    let operacion        = argumentos.operacion;
    let nombreColeccion  = argumentos.nombreColeccion;
    let documento        = argumentos.documento;


      if(documento.settings===undefined) {
        documento.settings={};
      }
      
      if(operacion=='agregar') {
         if(documento.key==null) documento.key=this.af.createId();
         documento.settings.fechaHoraCreacion=this.timestamp();


         if(documento.autoIncrement!==undefined) {
           documento.autoIncrement=null;
         }

         documento.settings.isActivo            = true;
         documento.settings.isBorrado           = false;
         documento.settings.borradoFisico       = false;
         documento.settings.sizeOfDocumento     = 0;
         documento.settings.documentosAsociados = 0;

      } else if(operacion=="modificar") {
          delete documento.settings.documentosAsociados;

      } else if(operacion=="borradoLogico") {
          documento.settings.isBorrado=true;
          documento.settings.borradoFisico=false;

      } else if(operacion=="borradoFisico") {
          documento.settings.isBorrado=true;
          documento.settings.borradoFisico=true;
      }
      
      if(typeof documento.key==='number') {
          documento.key = documento.key.toString();
      }

      documento.settings.triggersIgnorar       = false;
      documento.settings.triggersProcesarTarde = false;
 
    
      documento.settings.sizeOfDocumento = this.fn.sizeOfFirestoreDocument(nombreColeccion, documento);

      documento.settings.usuarioKANE = this.usuarioKANE;
      documento.settings.fechaHoraModificacion=this.timestamp();


      return new Promise((resolve, reject) => {
        if(this.settingService.settings2.getIsOnLine()) {
            this.af.doc(nombreColeccion+'/'+documento.key).set(documento,{ merge: true })
                .then((respuesta) => {
                    // // console.log("respuesta",respuesta);

                    this.apis.LogApiFuncion({
                         eventoQueDisparo : 'bdService.updateColeccion operacion:'+operacion,
                         apiFuncionKey    : 'FirestoreDocumentWrite',
                         organizacionKNAI : this.organizacionKNAI,
                         usuarioKANE      : this.usuarioKANE,
                         nombreColeccion  : nombreColeccion,
                         cloudFunction    : null,
                         cantidad         : 1,
                    });

                    if(operacion=="borradoFisico") {
                        this.apis.LogApiFuncion({
                             eventoQueDisparo : 'bdService.updateColeccion operacion:'+operacion,
                             apiFuncionKey    : 'FirestoreDocumentDelete',
                             organizacionKNAI : this.organizacionKNAI,
                             usuarioKANE      : this.usuarioKANE,
                             nombreColeccion  : nombreColeccion,
                             cloudFunction    : null,
                             cantidad         : 1,
                        });
                    }

                    if(operacion=="agregar" || operacion=="modificar") {
                      resolve(documento.key+'|mensajes.grabacionOk');
                    } else if(operacion=="borradoLogico") {
                      resolve('mensajes.eliminacionOk');
                    } else if(operacion=="borradoFisico") {
                      resolve('mensajes.eliminacionFisicaOk');
                    }
                })
                .catch((error)=>{
                   log(...values("error","error",error));
                   reject(error);
                 });
          } else {
              this.af.doc(nombreColeccion+'/'+documento.key).set(documento,{ merge: true });
              // refColeccion.doc(documento.key).set(documento);
              resolve('mensajes.operacionOffLine');
          }
      });
  }


    updateBase(datosUpdateBase:DatosUpdateBase): Promise<any> {
   
        log(...values('funcionGoPromesa','bdService.updateColeccion2: '+datosUpdateBase.nombreColeccion));

        log(...values("valores","argumentos: ",datosUpdateBase));
        
        let operacionFormulario:ACCION_FORM  = datosUpdateBase.operacionFormulario;

        
        
        let nombreColeccion:string     = datosUpdateBase.nombreColeccion;
        let documento :any             = datosUpdateBase.documento;

        let usuarioKANE:KANE           = this.usuarioKANE;
        let usaSettings:boolean        = datosUpdateBase.usaSettings;

     


        if(usaSettings) {
            documento=acomodaSettingsEnDocumento(usaSettings,operacionFormulario,nombreColeccion,usuarioKANE,documento, this.af);
        }


        if(datosUpdateBase.tipoBaseDeDatos== TIPO_BASE_DATOS.FIRESTORE ) {

            return this.DatosUpdateBaseFirestore(nombreColeccion,operacionFormulario,documento);      

        } else if(datosUpdateBase.tipoBaseDeDatos== TIPO_BASE_DATOS.SQL_SERVER) {

            // console.log("ejecuto datosUpdateBaseSQL");
            return  this.datosUpdateBaseSQL(datosUpdateBase);

        } else {

            return new Promise(null);

        }

     
 }


 DatosUpdateBaseFirestore(nombreColeccion:string,operacionFormulario:ACCION_FORM ,documento:any):Promise<any>{      

    return new Promise((resolve, reject) => {
        if(this.settingService.settings2.getIsOnLine()) {
           this.af.doc(nombreColeccion+'/'+documento.key).set(documento,{ merge: true })
               .then((respuesta) => {
                   // // console.log("respuesta",respuesta);

                   this.apis.LogApiFuncion({
                        eventoQueDisparo : 'bdService.updateColeccion operacion:'+operacionFormulario,
                        apiFuncionKey    : 'FirestoreDocumentWrite',
                        organizacionKNAI : this.organizacionKNAI,
                        usuarioKANE      : this.usuarioKANE,
                        nombreColeccion  : nombreColeccion,
                        cloudFunction    : null,
                        cantidad         : 1,
                   });

                   if(operacionFormulario=="borradoFisico") {
                       this.apis.LogApiFuncion({
                            eventoQueDisparo : 'bdService.updateColeccion operacion:'+operacionFormulario,
                            apiFuncionKey    : 'FirestoreDocumentDelete',
                            organizacionKNAI : this.organizacionKNAI,
                            usuarioKANE      : this.usuarioKANE,
                            nombreColeccion  : nombreColeccion,
                            cloudFunction    : null,
                            cantidad         : 1,
                       });
                   }

                   if(operacionFormulario==ACCION_FORM.AGREGAR|| operacionFormulario==ACCION_FORM.MODIFICAR) {
                     resolve(documento.key+'|mensajes.grabacionOk');
                   } else if(operacionFormulario==ACCION_FORM.BORRADO_LOGICO) {
                     resolve('mensajes.eliminacionOk');
                   } else if(operacionFormulario==ACCION_FORM.BORRADO_FISICO) {
                     resolve('mensajes.eliminacionFisicaOk');
                   }
               })
               .catch((error)=>{
                  log(...values("error","error",error));
                  reject(error);
                });
        } else {
             this.af.doc(nombreColeccion+'/'+documento.key).set(documento,{ merge: true });
             // refColeccion.doc(documento.key).set(documento);
             resolve('mensajes.operacionOffLine');
         }
        });

   
    }    

datosUpdateBaseSQL(datosUpdateBase:DatosUpdateBase): Promise<any>{
    
    let documento=datosUpdateBase.documento;

    const operacionFormulario= datosUpdateBase.operacionFormulario;
    let campoClaveArray :string[]  =datosUpdateBase.campoClave.split('.');      

    return new Promise((resolve, reject) => {

        let urlBaseDatos:string=this.URL_SERVER+datosUpdateBase.urlBaseDatos;   
        let nombreColeccion:string=datosUpdateBase.nombreColeccion;   

        
        if(datosUpdateBase.usaSettings){
            documento.settings=JSON.stringify(documento.settings);
        };
        // delete documento.key;                         

            
        if(operacionFormulario==ACCION_FORM.AGREGAR) {

            // elimino campos claves que se generan automáticamente
                delete documento[campoClaveArray[0]];
            // console.log('dataJJ Agregar urlBaseDatos',urlBaseDatos);
            this._http.post(urlBaseDatos,this.addUserInDocumentForLogSQL(documento)).subscribe((data:any)=> {
                    // console.log('dataJJ agregar',data);
                    resolve( data );
                },(error:any)=>{
                    // console.log('dataJJ nombreColeccion error',error);
                    reject('no pudo agregar a Colección '+nombreColeccion +": "+ error);
                    
            });


        } else if(operacionFormulario==ACCION_FORM.MODIFICAR || operacionFormulario==ACCION_FORM.BORRADO_LOGICO) {

            urlBaseDatos=urlBaseDatos+documento[campoClaveArray[0]];
            // console.log('dataJJ MODIFICAR urlBaseDatos',urlBaseDatos);
            this._http.put(urlBaseDatos,this.addUserInDocumentForLogSQL(documento)).subscribe((data:any)=> {
                // console.log('dataJJ modificar borradoLogico',data);
                resolve( data );
            },(error:any)=>{
                // console.log('dataJJ error modificar borradoLogico',error);
                reject('no pudo agregar a Colección '+nombreColeccion);
            });

        } else if(operacionFormulario==ACCION_FORM.BORRADO_FISICO) {      
            // let campoClave:String = documento[campoClaveArray[0]].replaceAll(':','').replaceAll('-',''); 
            let valorClave:String = documento[campoClaveArray[0]]; 

            urlBaseDatos=urlBaseDatos+valorClave+this.addUserInUrlForLogSQL();
            this._http.delete(urlBaseDatos).subscribe((data:any)=> {
                // console.log('data borradoFisico',data);
                resolve( data );
            },(HttpErrorResponse:any)=>{
                reject(HttpErrorResponse.error);
            });

        }

    });

}

    addUserInUrlForLogSQL(): string {
        return '/' + this.usuarioKANE.key;
    }

    addUserInDocumentForLogSQL(documento:any): any {
        let respuesta = documento;
        respuesta['usuarioId'] = this.usuarioKANE.key;
        return respuesta;
    }
 
    //  Borra todos los registros de una tabla
    deleteColeccionBase(nombreTabla: string) {

        return new Promise((resolve, reject) => {

            let url: string = '';
            url = this.URL_SERVER + nombreTabla + '/ALL';
            this._http.delete(url).subscribe((data: any) => {
                // console.log('data borradoFisico', data);
                resolve(data);
            }, (error: any) => {
                // console.log('error borradoFisico', error);
                reject('no pudo borrar a Colección ' + nombreTabla);
            })
        })

    } 

 
    updateColeccionBatchBase(userKeys:updateColeccionUserKeysBatch,datos:[updateColeccionDataBatchBase]): Promise<any> {
 
    
    log(...values("valores","userKeys: "   ,userKeys));
    log(...values("valores","datos[]: "      ,datos));


    let nomeroTransacciones=0;
    let nomeroTransaccionesMaximas=250;
    let vectorPromesas=[];
    let batch:any=[];
    let indexBatch:number=0;
    
    batch[0] = firebase.firestore().batch();
    for (let index = 0; index < datos.length; index++) {
        
        const element = datos[index];
             
        let operacion:OPERACIONES_BASE_DATOS  = element.operacion;
        let nombreColeccion  = element.nombreColeccion;
        let documento        = element.documento;
        let incluyeSettings  = element.incluyeSettings;
      

        if(incluyeSettings){

               
            if(documento.settings===undefined) {
                documento.settings={};
            }
            
            switch (operacion) {
                case OPERACIONES_BASE_DATOS.AGREGAR:
                    if(documento.key==null) documento.key=this.af.createId();
                    documento.settings.fechaHoraCreacion=this.timestamp();
                    documento.settings.isActivo            = true;
                    documento.settings.isBorrado           = false;
                    documento.settings.borradoFisico       = false;
                    documento.settings.sizeOfDocumento     = 0;
                    documento.settings.documentosAsociados = 0;
                    
                    break;
                case OPERACIONES_BASE_DATOS.MODIFICAR:
                    delete documento.settings.documentosAsociados;
                    break;
                case OPERACIONES_BASE_DATOS.BORRADO_LOGICO:
                    documento.settings.isBorrado=true;
                    documento.settings.borradoFisico=false;
                    
                    break;
                case OPERACIONES_BASE_DATOS.BORRADO_FISICO:
                    documento.settings.isBorrado=true;
                    documento.settings.borradoFisico=true;
                    
                    break;
                case OPERACIONES_BASE_DATOS.AGREGAR:
                    if(documento.key==null) documento.key=this.af.createId();
                    documento.settings = {
                        fechaHoraCreacion       : this.timestamp(),
                        fechaHoraModificacion   : this.timestamp(),
                        usuarioKANE             : this.usuarioKANE,
                        isActivo                : true,
                        isBorrado               : false,
                        borradoFisico           : false,
                        sizeOfDocumento         : this.fn.sizeOfFirestoreDocument(nombreColeccion, documento),
                        documentosAsociados     : firebase.firestore.FieldValue.increment(0)
                    }
                                      
                    break;
            
                default:
                    break;
            }

            documento.settings.triggersIgnorar       = false;
            documento.settings.triggersProcesarTarde = false;
    
            documento.settings.sizeOfDocumento = this.fn.sizeOfFirestoreDocument(nombreColeccion, documento);
    
            documento.settings.usuarioKANE = this.usuarioKANE;
            documento.settings.fechaHoraModificacion=this.timestamp();
        
        // No lleva settings    
        } else{
                if(documento.settings){
                    delete documento.settings;
                }
        }      
        
        if(typeof documento.key==='number') {
            documento.key = documento.key.toString();
        }
        
        
        if(nomeroTransacciones<=nomeroTransaccionesMaximas ){
            const refcoleccion=  firebase.firestore().collection(nombreColeccion).doc(documento.key);
            //// console.log("documento",index, documento);
            try {
                batch[indexBatch].set(refcoleccion, documento,{merge: true});    
            } catch (error) {
                // console.log("error",error);
            }    
            
            nomeroTransacciones++
        } 
        //// console.log("indexBatch","nomeroTransacciones",indexBatch,nomeroTransacciones);
        
        if(nomeroTransacciones==nomeroTransaccionesMaximas ){
            nomeroTransacciones=0;
            vectorPromesas.push(batch[indexBatch].commit());
            indexBatch++;
            batch[indexBatch]=firebase.firestore().batch();
        }
        
        
        //llegamos al final del array
         // si nomeroTransacciones=0 no es necesario el push, se realizó en el else anterior

        if(index==datos.length-1 && nomeroTransacciones>0) {
            // // console.log('vectorPromesas index',index,datos.length-1);
            vectorPromesas.push(batch[indexBatch].commit());
        }
        
    } 
    // // console.log('vectorPromesas',vectorPromesas);
     
     return new Promise((resolve, reject) => {
        if(this.settingService.settings2.getIsOnLine()) {
           Promise.all(vectorPromesas) 
               .then((respuesta) => {
                   // console.log("respuesta",respuesta);

                   this.apiLogupdateColeccionBatchBase(datos);
                   resolve('mensajes.grabacionOk'+' BatchBase');
               })
               .catch((error)=>{
                  log(...values("error","error",error));
                  reject(error);
                });
         } else {
             // Si no hay internet no se puede realizar
             reject('mensajes.operacionOffLine');
         }
     });
 }

 
   getBDSubscripcion(argumentos:ArgumentosLlamarBaseDeDatos): Observable<any> {
 

    log(...values('funcionGoPromesa','bdService.getBDSubscripcion: '+argumentos));


    return new Observable((observer) => {
          this.af.collection<any>(argumentos.nombreColeccion,
                ref =>{
                    let query: Query= argumentos.getFirbaseQuery(ref) ;
                    return query

                }).valueChanges().subscribe((data:any)=>{

                    log(...values('valores','nombreColeccion:',argumentos.nombreColeccion));
                    log(...values('valores','data:',data));

                    let listado=[];    
                    for(let i=0; i<data.length; i++) {
                        let documento=this.fn.copiarObjetoNew( data[i] );     
                        // Convierto valores timestamp a Date
                        documento=this.fn.corrigeTimestampDocumento(documento);  
                        listado.push(documento);
                    }    
                    // console.log("corrigeTimestampDocumento5.5",listado );
 
                    observer.next(listado);

                },(error:any)=>{
                    // observer.error('no pudo acceder a Colección '+nombreColeccion);
                    observer.error(error);
                })
          });

  }
  
 
  getPromesaBase<T>(argumentos:ArgumentosLlamarBaseDeDatos): Promise<any> {

    // log(...values('getPromesaBase','argumentos: '+argumentos));
    // log(...values('getPromesaBase','argumentos: '+argumentos.getWhereSQL));
    // log(...values('getPromesaBase','argumentos: '+argumentos.urlBaseSQL));
    // log(...values('getPromesaBase','argumentos: '+argumentos.tipoBaseDatos));


    if(argumentos.tipoBaseDatos == TIPO_BASE_DATOS.FIRESTORE) {
      
      return this.getPromesaFirebaseBase(argumentos)
          
    } else {  // BD RELACIONAL

      return this.getPromesaSQLServerBase(argumentos)

    }
  }  

  getPromesaFirebaseBase<T>(argumentos:ArgumentosLlamarBaseDeDatos): Promise<any> {

    log(...values('getPromesaFirebaseBase','argumentos: '+argumentos.nombreColeccion));


    return new Promise((resolve, reject) => {
          this.af.collection<any>(argumentos.nombreColeccion,
            ref =>{
                console.log('getPromesaFirebaseBase ref',ref);
                let query: Query = argumentos.getFirbaseQuery(ref);
                console.log('getPromesaFirebaseBase query',query);
              
                return query;

                }).valueChanges({ idField: 'id' }).pipe(first()).toPromise().then((data:any)=>{
                    
                
                    let coleccionTotalSizeOf: number=0;
                    let cantDocumentos: number=data.length;
                    let listadoFormateado=[];
                    for(let i=0; i<data.length; i++) {
                       let documento=data[i];
                       // Convierto valores timestamp a Date
                       documento=this.fn.corrigeTimestampDocumento(documento);

                       // Calculo de ser necesario el tamaño en bytes del documento
                       documento.settings.sizeOfDocumento =
                           this.fn.sizeOfIfNotCalculado({ sizeOfActual  : documento.settings.sizeOfDocumento,
                                                          pathColeccion : argumentos.nombreColeccion+documento.key,
                                                          object        : documento });

                       coleccionTotalSizeOf += documento.settings.sizeOfDocumento;

                       listadoFormateado.push(documento);

                    }

                    this.apiLogGetPromesaFirebaseBase(argumentos.nombreColeccion,cantDocumentos,coleccionTotalSizeOf);

                   
                    resolve(listadoFormateado);
                }).catch((error:any)=>{
                    // console.log(' getColeccion error',error);
                    log(...values("error","error",error));
                    reject('no pudo acceder a Colección '+argumentos.nombreColeccion);
                });
      })

    }

    getPromesaSQLServerBase(argumentos:ArgumentosLlamarBaseDeDatos):Promise<any>{


      
        return new Promise((resolve, reject) => { 
        
            if(this.URL_SERVER==null){ 
                // console.log('getPromesaSQLServerBase this.URL_SERVER null',this.URL_SERVER);

                reject('no pudo acceder a Colección  this.URL_SERVER==null'+argumentos);
            } else {
                let url:string=this.URL_SERVER+argumentos.getBaseDatosURL();
                console.log('getPromesaSQLServerBase this.URL_SERVER',this.URL_SERVER);
                console.log('getPromesaSQLServerBase argumentos.getBaseDatosURL()',argumentos.getBaseDatosURL());
                console.log('getPromesaSQLServerBase url',url);
                this._http.get(url).subscribe((data:any)=> {
                    // console.log('getPromesaSQLServerBase data',data);
                        let coleccionTotalSizeOf=0;
                        let listadoFormateado=[];
                        for(let i=0; i<data.length; i++) {
                        let documento=data[i];
                        documento=this.fn.corrigeTimestampDocumento(documento);

                        // Transformo setting de string a Json
                        if(documento.settings){
                            documento.settings=JSON.parse(documento.settings);
                            // console.log('resp_sql json',documento);
                        }

                        listadoFormateado.push(documento);
                        }
                        resolve( listadoFormateado );
                },(error:any)=>{
                        reject('no pudo acceder a Colección '+argumentos);
                })

            }


            });
        
    }

  getBDSQL<T>(argumentos:getBDSQL): Promise<any> {
    /*
     * Recibe
     * argumentos {
     *     servicioSQL      : string,
     *     nombreColeccion  : string,
     *     campoClave       : string, (tiene el código del campo clave. Si son mas de uno se utilizan puntos para separarlos)
     *     where            : WhereInterface[],   [{key:'codigo',operador:OPERADOR_WHERE.Igual,value:'miCodigo'}],
     *     orderBy          : OrdeByInterface[],  [{key:'codigo',ascdesc:OPERADOR_ORDEN_BY.Ascendente}],
     *     paginadoCantidad : number,
     *     paginaActualNro  : number,
     *     organizacionKNAI : KNAI,
     *     usuarioKANE      : KANE
     * }
     */

    log(...values('funcionGoPromesa','bdService.getBDSQL: '+argumentos.nombreColeccion));

    let servicioSQL       : string            = argumentos.servicioSQL;    
    let nombreColeccion   : string            = argumentos.nombreColeccion;
    let where             : WhereInterface[]  = argumentos.where;
    let orderBy           : OrdeByInterface[] = argumentos.orderBy;
    let paginadoCantidad  : number            = argumentos.paginadoCantidad;
    let paginaActualNro   : number            = argumentos.paginaActualNro==null ? 1 : argumentos.paginaActualNro;

    let organizacionKNAI  : KNAI              = argumentos.organizacionKNAI;
    let usuarioKANE       : KANE              = argumentos.usuarioKANE;

    // console.log('argumentos',argumentos);
   


      return new Promise((resolve, reject) => {

        // console.log('getBDSQL sql')
        // console.log('getBDSQL this.environment',this.environment);

        // para hacer: get con parametros en el get que se sacan del array where

          let parametros='';

          for (let index = 0; index < argumentos.orderBy.length; index++) {
            const element:OrdeByInterface = argumentos.orderBy[index];
            let orden = 'order_'+element.key+'='+element.ascDesc;
            
            if(index==0){ //primero
              parametros=parametros+'?'+orden;
            } else{      // el resto 
              parametros=parametros+'&'+orden;   
            }
          }
        
          for (let index = 0; index < argumentos.where.length; index++) {
              const element:WhereInterface = argumentos.where[index];
              let filtro = 'filtro';
              if(element.operador=='==') {
                  filtro+='igual_';
              } else if(element.operador=='>=') {
                filtro+='mayoroigual_';
              } else if(element.operador=='<=') {
                filtro+='menoroigual_';
              } else if(element.operador=='<') {
                    filtro+='menor_';
              } else if(element.operador=='>') {
                    filtro+='mayor_';
              } else if(element.operador=='contiene') {
                    filtro+='contiene_';
              }    
              filtro+=element.key + '=' + element.value;
                
              parametros=parametros+'&'+filtro;   
          }
          
          let offset =  (paginaActualNro-1) * paginadoCantidad;                    
          
          if(parametros!='') {
              parametros+='&offset='+offset.toString() + '&limit='+paginadoCantidad.toString();    
          } else {
              parametros+='?offset='+offset.toString() + '&limit='+paginadoCantidad.toString();    
          }
          
          
          // console.log("zzz parametros", parametros);
          
          let url:string='';

           url=this.environment.serviciosExternos.sql.apiURL+servicioSQL+parametros;    
          

          // console.log('getBDSQL url',url);

          this._http.get(url).subscribe((data:any)=> {
            // console.log('getBDSQL data',data);
                let coleccionTotalSizeOf=0;
                let listadoFormateado=[];
                for(let i=0; i<data.length; i++) {
                   let documento=data[i];
                //    // console.log('resp_sql',documento);

                   // Convierto valores timestamp a Date
                   documento=this.fn.corrigeTimestampDocumento(documento);

                  // Transformo setting de string a Json
                //   // console.log('resp_sql settings',documento.settings);
                  if(documento.settings){
                      documento.settings=JSON.parse(documento.settings);
                      // console.log('resp_sql json',documento);
                  }



                   // Calculo de ser necesario el tamaño en bytes del documento
                //    documento.settings.sizeOfDocumento =
                //        this.fn.sizeOfIfNotCalculado({ sizeOfActual  : documento.settings.sizeOfDocumento,
                //                                       pathColeccion : nombreColeccion+documento.key,
                //                                       object        : documento });

                //    coleccionTotalSizeOf += documento.settings.sizeOfDocumento;

                   listadoFormateado.push(documento);
                }
                resolve( listadoFormateado );
          },(error:any)=>{
                reject('no pudo acceder a Colección '+nombreColeccion);
          })

      });

  }  
  
  getBDActualizacionesSubscripcion(): Observable<any> {

    // log(...values('funcionGoPromesa','xx bdService.getBDActualizacionesSubscripcion'));

    return new Observable((observer) => {

        this.af.collection<any>('Actualizaciones').doc('Actualizaciones')
        .valueChanges().subscribe((data:any)=>{

            let documento=data;
            // log(...values("valores","xx getBDActualizacionesSubscripcion OK!!! documento:",documento));

            observer.next(documento);
        },(error:any)=>{
            log(...values("error","getBDActualizacionesSubscripcion - error:",error));
            observer.error('no pudo obtener el documento {Auxiliar} de Colecciones');
        })

    });

  }

  getBDCantDocumentosPromesa(nombreColeccion:string): Promise<any> {

    log(...values('funcionGoPromesa','bdService.getBDCantDocumentosPromesa: '+nombreColeccion));

    if(this.environment.tipoServidor.baseDatos=="firestore") {

        return new Promise((resolve, reject) => {

            // this.af.collection<any>('Colecciones/'+nombreColeccion)
            this.af.collection<any>('Colecciones').doc(nombreColeccion)
            .valueChanges().pipe(first()).toPromise().then((data:any)=>{

            // this.af.collection('Colecciones').doc(nombreColeccion).get()
            // .pipe(first()).toPromise().then((data:any)=>{
                let documento=data;
                log(...values("valores","getBDCantDocumentosPromesa OK!!! documento:",documento));

                let objCant = {
                  cantidadDocumentosLogicos : documento.cantidadDocumentosLogicos,
                  cantidadDocumentosFisicos : documento.cantidadDocumentosFisicos,
                }

                resolve(objCant);
            })
            .catch((error:any)=>{
                log(...values("error","getBDCantDocumentosPromesa - error:",error));
                reject('no pudo obtener el Total de laColección '+nombreColeccion);
            });

        });


    } else {  // BD RELACIONAL

      return new Promise((resolve, reject) => {

          const url:string='https://www.lincolnonline.com.ar/ionic/http_firestore.php';

          this._http.get(url).subscribe((data:any)=> {
                let objCant = {
                  cantidadDocumentosLogicos : 1,
                  cantidadDocumentosFisicos : 1,
                }
                resolve( objCant );
          },(error:any)=>{
                log(...values("error","getBDCantDocumentosPromesa - error:",error));
                reject('no pudo obtener el Total de laColección '+nombreColeccion);
          })

      })


    }

  }

  postGETProcesaListado(argumentos:postGETProcesaListadoModel):any {
       /* argumentos = {
        *   listado                  : [],
        *   form                     : any,
        *   nombreCampoThumb         : string,
        *   vecImagenesDownloadeadas : []
        *   organizacionKNAI         : KNAI
        *   usuarioKANE              : KANE,
        *   nombreColeccion          : string,
        * }
        */

        log(...values('funcionGo','bdService.postGETProcesaListado'));

        let rta = {
            listado                  : argumentos.listado,
            vecImagenesDownloadeadas : argumentos.vecImagenesDownloadeadas,
            totalDownloadIconos      : 0,
            totalCantIconos          : 0,
        }

        let coleccionTotalSizeOf=0;

        for(let i=0; i<rta.listado.length; i++) {
           let documento=rta.listado[i];

           // Igual estructura del registro con la definida en el Formulario
        //    documento = igualarDocumentoConForm(argumentos.form, documento, 'destinoListado');

           // Calculo la cantidad de Bytes del Documento
        //    documento.settings.sizeOfDocumento =
        //        this.fn.sizeOfIfNotCalculado({ sizeOfActual  : documento.settings.sizeOfDocumento,
        //                                    pathColeccion : argumentos.nombreColeccion+documento.key,
        //                                    object        : documento });

        //    coleccionTotalSizeOf += documento.settings.sizeOfDocumento;

           rta.listado[i] = documento;

           // Calculo el peso de los Iconos a mostrar en el Listado
           if(argumentos.nombreCampoThumb && documento[argumentos.nombreCampoThumb]) {
               let imagen    = documento[argumentos.nombreCampoThumb];
               let linkThumb = (imagen!=null) ? documento[argumentos.nombreCampoThumb].linkThumb : null;

               if( imagen!=null && rta.vecImagenesDownloadeadas.indexOf(linkThumb)==-1 ) {
                   rta.totalDownloadIconos += this.fn.sizeImage(imagen, 'Thumb');
                   rta.totalCantIconos++;

                   rta.vecImagenesDownloadeadas.push(linkThumb);
               }
           }
           
        }
        log(...values("valores",argumentos.nombreColeccion+" - coleccionTotalSizeOf Total:",coleccionTotalSizeOf));

        if(rta.listado.length > 0) {   // OJO, SIEMPRE CUENTA AL MENOS UNA LECTURA

            this.apis.LogApiFuncion({
                 eventoQueDisparo : 'bdService.postGETProcesaListado',
                 apiFuncionKey    : 'FirestoreDocumentRead',
                 organizacionKNAI : argumentos.organizacionKNAI,
                 usuarioKANE      : argumentos.usuarioKANE,
                 nombreColeccion  : argumentos.nombreColeccion,
                 cloudFunction    : null,
                 cantidad         : rta.listado.length,
            });

            this.apis.LogApiFuncion({
                 eventoQueDisparo : 'bdService.postGETProcesaListado',
                 apiFuncionKey    : 'FirestoreTransferencia',
                 organizacionKNAI : argumentos.organizacionKNAI,
                 usuarioKANE      : argumentos.usuarioKANE,
                 nombreColeccion  : argumentos.nombreColeccion,
                 cloudFunction    : null,
                 cantidad         : coleccionTotalSizeOf,
            });

            this.apis.LogApiFuncion({
                 eventoQueDisparo : 'bdService.postGETProcesaListado - Bytes Iconos Grilla',
                 apiFuncionKey    : 'FirebaseStorageDownloaded',
                 organizacionKNAI : argumentos.organizacionKNAI,
                 usuarioKANE      : argumentos.usuarioKANE,
                 nombreColeccion  : argumentos.nombreColeccion,
                 cloudFunction    : null,
                 cantidad         : rta.totalDownloadIconos,
            });

            this.apis.LogApiFuncion({
              eventoQueDisparo : 'bdService.postGETProcesaListado - Cant Iconos Grilla',
              apiFuncionKey    : 'FirebaseStorageOperationDownload',
              organizacionKNAI : argumentos.organizacionKNAI,
              usuarioKANE      : argumentos.usuarioKANE,
              nombreColeccion  : argumentos.nombreColeccion,
              cloudFunction    : null,
              cantidad         : rta.totalCantIconos,
            });
        }

        return rta;
    }

    /// Firebase Server Timestamp
    timestamp() {
        if(this.environment.tipoServidor.baseDatos=="firestore") {
            return  firebase.firestore.Timestamp.now().toDate();
        } else{
            return new Date();
        }
        
    }

    getNewKey(){
       return this.af.createId();
    }

   deleteDocumento(coleccion:string,key:string):Promise<any> {
        return this.af.collection(coleccion).doc(key).delete();

   }

   deleteDocumentoField(coleccion:string,key:string,field:string):Promise<any> {

    const ref = this.af.collection(coleccion).doc(key);
    let doc = {};
    doc[field]=firebase.firestore.FieldValue.delete();  
    return ref.update(doc);

    }

    apiLogupdateColeccionBatchBase(datos:[updateColeccionDataBatchBase]){
                   
        for (let index = 0; index < datos.length; index++) {
                const element = datos[index];

            let operacion        = element.operacion;
            let nombreColeccion  = element.nombreColeccion;
            let documento        = element.documento;
            let incluyeSettings  = element.incluyeSettings;

            this.apis.LogApiFuncion({
                    eventoQueDisparo : 'bdService.updateColeccion operacion:'+operacion,
                    apiFuncionKey    : 'FirestoreDocumentWrite',
                    organizacionKNAI : this.organizacionKNAI,
                    usuarioKANE      : this.usuarioKANE,
                    nombreColeccion  : nombreColeccion,
                    cloudFunction    : null,
                    cantidad         : 1,
            });

            if(operacion=="borradoFisico") {
                this.apis.LogApiFuncion({
                        eventoQueDisparo : 'bdService.updateColeccion operacion:'+operacion,
                        apiFuncionKey    : 'FirestoreDocumentDelete',
                        organizacionKNAI : this.organizacionKNAI,
                        usuarioKANE      : this.usuarioKANE,
                        nombreColeccion  : nombreColeccion,
                        cloudFunction    : null,
                        cantidad         : 1,
                });
            }

            
        }
    } 

    apiLogGetPromesaFirebaseBase(nombreColeccion,cantDocumentos:number,coleccionTotalSizeOf:number){
                     

        this.apis.LogApiFuncion({
                eventoQueDisparo : 'bdServiceBase.getBDPromesaBase',
                apiFuncionKey    : 'FirestoreDocumentRead',
                organizacionKNAI : this.getOrganizacionKNAI(),
                usuarioKANE      : this.getUsuarioKANE(),
                nombreColeccion  : nombreColeccion,
                cloudFunction    : null,
                cantidad         : cantDocumentos,
        });

        this.apis.LogApiFuncion({
                eventoQueDisparo : 'bdServiceBase.getBDPromesaBase',
                apiFuncionKey    : 'FirestoreTransferencia',
                organizacionKNAI : this.getOrganizacionKNAI(),
                usuarioKANE      : this.getUsuarioKANE(),
                nombreColeccion  : nombreColeccion,
                cloudFunction    : null,
                cantidad         : coleccionTotalSizeOf,
        });
    }
}

