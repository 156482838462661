import {Component, ViewEncapsulation, ViewChild, HostListener, Input, ElementRef} from '@angular/core';
declare let $: any;
declare let jQuery: any;
@Component({
  selector: 'lib-shared-dos-columnas-ajustables',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./dos-columnas-ajustables.scss'],
  templateUrl: './dos-columnas-ajustables.html',
  
})
export class DosColumnasAjustablesComponent {

  
  private isDragging = false;
  private startX: number;
  private initialWidthcolDerecha: number;
  private initialWidthcolIzquierda: number;

  onMouseDown(event: MouseEvent) {
    console.log('mouse','down',event);
    if (event.button === 0) {
      this.isDragging = true;
      this.startX = event.clientX;
      this.initialWidthcolDerecha = this.getWidthOfElement('.colDerecha');
      this.initialWidthcolIzquierda = this.getWidthOfElement('.colIzquierda');
    }
  }

  @HostListener('mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    // console.log('mouse','move',event);
    if (this.isDragging) {
      const deltaX = event.clientX - this.startX;
      this.adjustWidth('.colDerecha', this.initialWidthcolDerecha - deltaX);
      this.adjustWidth('.colIzquierda', this.initialWidthcolIzquierda + deltaX);
    }
  }

  @HostListener('mouseup')
   onMouseUp(event: MouseEvent) {
    console.log('mouse','onMouseUp',event);
    this.isDragging = false;
  }

  private getWidthOfElement(elementSelector: string): number {
    const element = document.querySelector(elementSelector);
    return element ? element.clientWidth : 0;
  }

  private adjustWidth(elementSelector: string, newWidth: number) {
    const element :any= document.querySelector(elementSelector);
    if (element) {
      element.style.width = `${newWidth}px`;
    }
  }


}


