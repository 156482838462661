export enum Colors{
    '#48810a', // verde  	   	    
    '#000000', // black  	   	         	        
    '#8A2BE2', // blueviolet  	   	
    '#A52A2A', // brown  	   	        
    '#DEB887', // burlywood  	   	    
    '#5F9EA0', // cadetblue  	   	    
    '#7FFF00', // chartreuse  	   	
    '#D2691E', // chocolate  	   	    
    '#FF7F50', // coral  	   	        
    '#6495ED', // cornflowerblue  	
    '#FFF8DC', // cornsilk  	   	    
    '#DC143C', // crimson  	   	  
    '#00008B', // darkblue  	   	    
    '#008B8B', // darkcyan  	   	    
    '#B8860B', // darkgoldenrod  	   	
    '#A9A9A9', // darkgray  	   	    
    '#006400', // darkgreen  	   	    
    '#BDB76B', // darkkhaki  	   	    
    '#8B008B', // darkmagenta  	   	
    '#556B2F', // darkolivegreen  	
    '#FF8C00', // darkorange  	   	
    '#9932CC', // darkorchid  	   	
    '#8B0000', // darkred  	   	    
    '#E9967A', // darksalmon  	   		
    '#2F4F4F', // darkslategray  	   	
    '#00CED1', // darkturquoise  	   	
    '#9400D3', // darkviolet  	   	
    '#FF1493', // deeppink  	   	    
    '#00BFFF', // deepskyblue  	   	
    '#696969', // dimgray  	   	    
    '#1E90FF', // dodgerblue  	   	
    '#B22222', // firebrick  	   	     	
    '#228B22', // forestgreen   	
    '#DCDCDC', // gainsboro  	   	    	   	
    '#FFD700', // gold  	   	        
    '#DAA520', // goldenrod  	   	    
    '#808080', // gray  	   	        
    '#008000', // green  	   	        
    '#ADFF2F', // greenyellow  	   	 	    
    '#FF69B4', // hotpink  	   	    
    '#CD5C5C', // indianred  	   	    
    '#4B0082', // indigo  	   	       	        
    '#F0E68C', // khaki  	   	           	
    '#7CFC00', // lawngreen  	   	       
    '#F08080', // lightcoral  	   	   	    
    '#90EE90', // lightgreen  	   	   	    
 '#20B2AA', // lightseagreen  
 '#87CEFA', // lightskyblue  
 '#778899', // lightslategray  
 '#00FF00', // lime  
 '#32CD32', // limegreen  
 '#FF00FF', // magenta  
 '#800000', // maroon  
 '#66CDAA', // mediumaquamarine  
 '#0000CD', // mediumblue  
 '#BA55D3', // mediumorchid  
 '#9370DB', // mediumpurple  
 '#3CB371', // mediumseagreen  
 '#7B68EE', // mediumslateblue  
 '#00FA9A', // mediumspringgreen  
 '#48D1CC', // mediumturquoise  
 '#C71585', // mediumvioletred  
 '#191970', // midnightblue  
 '#FFE4B5', // moccasin  
 '#000080', // navy  
 '#808000', // olive  
 '#6B8E23', // olivedrab  
 '#FFA500', // orange  
 '#FF4500', // orangered  
 '#DA70D6', // orchid  
 '#EEE8AA', // palegoldenrod  
 '#DB7093', // palevioletred  
 '#CD853F', // peru  
 '#FFC0CB', // pink  
 '#DDA0DD', // plum  
 '#B0E0E6', // powderblue  
 '#800080', // purple  
 '#FF0000', // red  
 '#BC8F8F', // rosybrown  
 '#4169E1', // royalblue  
 '#8B4513', // saddlebrown  
 '#FA8072', // salmon  
 '#FAA460', // sandybrown  
 '#2E8B57', // seagreen  
 '#A0522D', // sienna  
 '#C0C0C0', // silver  
 '#6A5ACD', // slateblue  
 '#708090', // slategray  
 '#00FF7F', // springgreen  
 '#4682B4', // steelblue  
 '#D2B48C', // tan  
 '#008080', // teal  
 '#D8BFD8', // thistle  
 '#FF6347', // tomato  
 '#40E0D0', // turquoise  
 '#EE82EE', // violet  
 '#F5DEB3', // wheat  
 '#F5F5F5', // whitesmoke  
 '#9ACD32', // yellowgreen  	,
 '#FF0020', // Red   	 
}
export const ColorsAndHover:any[]=[
    { color: '#48810a', hover: '#66aa33' },
    { color: '#000000', hover: '#222222' },
    { color: '#8A2BE2', hover: '#a93de9' },
    { color: '#A52A2A', hover: '#c94343' },
    { color: '#DEB887', hover: '#f1d9b3' },
    { color: '#5F9EA0', hover: '#7bb8bb' },
    { color: '#7FFF00', hover: '#9eff33' },
    { color: '#D2691E', hover: '#e37b2e' },
    { color: '#FF7F50', hover: '#ff9f80' },
    { color: '#6495ED', hover: '#7fa6ff' },
    { color: '#FFF8DC', hover: '#fffff0' },
    { color: '#DC143C', hover: '#ed2755' },
    { color: '#00008B', hover: '#0000cc' },
    { color: '#008B8B', hover: '#009d9d' },
    { color: '#B8860B', hover: '#d39c2e' },
    { color: '#A9A9A9', hover: '#bcbcbc' },
    { color: '#006400', hover: '#007b00' },
    { color: '#BDB76B', hover: '#d6cb8f' },
    { color: '#8B008B', hover: '#a03da0' },
    { color: '#556B2F', hover: '#6f8d57' },
    { color: '#FF8C00', hover: '#ffad33' },
    { color: '#9932CC', hover: '#b55ee0' },
    { color: '#8B0000', hover: '#a10000' },
    { color: '#E9967A', hover: '#f4b39d' },
    { color: '#2F4F4F', hover: '#475c5c' },
    { color: '#00CED1', hover: '#1ae3e3' },
    { color: '#9400D3', hover: '#aa1aff' },
    { color: '#FF1493', hover: '#ff4dbb' },
    { color: '#00BFFF', hover: '#1cc2ff' },
    { color: '#696969', hover: '#7f7f7f' },
    { color: '#1E90FF', hover: '#369bff' },
    { color: '#B22222', hover: '#d03434' },
    { color: '#228B22', hover: '#359f35' },
    { color: '#DCDCDC', hover: '#f2f2f2' },
    { color: '#FFD700', hover: '#ffd833' },
    { color: '#DAA520', hover: '#edc64e' },
    { color: '#808080', hover: '#999999' },
    { color: '#008000', hover: '#009900' },
    { color: '#ADFF2F', hover: '#c3ff4d' },
    { color: '#FF69B4', hover: '#ff8bbf' },
    { color: '#CD5C5C', hover: '#e57d7d' },
    { color: '#4B0082', hover: '#5e0e91' },
    { color: '#F0E68C', hover: '#f8f17d' },
    { color: '#7CFC00', hover: '#94ff33' },
    { color: '#F08080', hover: '#f29b9b' },
    { color: '#90EE90', hover: '#a4ffa4' },
    { color: '#20B2AA', hover: '#37ccbb' },
    { color: '#87CEFA', hover: '#a0e7ff' },
    { color: '#778899', hover: '#8f9ead' },
    { color: '#00FF00', hover: '#33ff33' },
    { color: '#32CD32', hover: '#4be04b' },
    { color: '#FF00FF', hover: '#ff33ff' },
    { color: '#800000', hover: '#990000' },
    { color: '#66CDAA', hover: '#7ae3c0' },
    { color: '#0000CD', hover: '#1a1aff' },
    { color: '#BA55D3', hover: '#d773e7' },
    { color: '#9370DB', hover: '#a685f0' },
    { color: '#3CB371', hover: '#56cc85' },
    { color: '#7B68EE', hover: '#967aff' },
    { color: '#00FA9A', hover: '#1effb8' },
    { color: '#48D1CC', hover: '#66e6e6' },
    { color: '#C71585', hover: '#e032a3' },
    { color: '#191970', hover: '#2b2baf' },
    { color: '#FFE4B5', hover: '#fffbdd' },
    { color: '#000080', hover: '#0000b3' },
    { color: '#808000', hover: '#999900' },
    { color: '#6B8E23', hover: '#87a935' },
    { color: '#FFA500', hover: '#ffbf33' },
    { color: '#FF4500', hover: '#ff5c33' },
    { color: '#DA70D6', hover: '#e986e0' },
    { color: '#EEE8AA', hover: '#f7f2c3' },
    { color: '#DB7093', hover: '#e58797' },
    { color: '#CD853F', hover: '#e0744e' },
    { color: '#FFC0CB', hover: '#ffb8c2' },
    { color: '#DDA0DD', hover: '#e7b7e7' },
    { color: '#B0E0E6', hover: '#c4eff3' },
    { color: '#800080', hover: '#990099' },
    { color: '#FF0000', hover: '#ff3333' },
    { color: '#BC8F8F', hover: '#d7a3a3' },
    { color: '#4169E1', hover: '#537be9' },
    { color: '#8B4513', hover: '#a45b37' },
    { color: '#FA8072', hover: '#fc9584' },
    { color: '#FAA460', hover: '#ffbb77' },
    { color: '#2E8B57', hover: '#419f6d' },
    { color: '#A0522D', hover: '#b36744' },
    { color: '#C0C0C0', hover: '#d9d9d9' },
    { color: '#6A5ACD', hover: '#7b6eda' },
    { color: '#708090', hover: '#828c99' },
    { color: '#00FF7F', hover: '#33ff99' },
    { color: '#4682B4', hover: '#5f9fd2' },
    { color: '#D2B48C', hover: '#e2c7a1' },
    { color: '#008080', hover: '#009999' },
    { color: '#D8BFD8', hover: '#e5d5e5' },
    { color: '#FF6347', hover: '#ff7e66' },
    { color: '#40E0D0', hover: '#56f1e6' },
    { color: '#EE82EE', hover: '#f09ef0' },
    { color: '#F5DEB3', hover: '#f8ebb8' },
    { color: '#F5F5F5', hover: '#fafafa' },
    { color: '#9ACD32', hover: '#b2e047' },
    { color: '#FF0020', hover: '#ff3355' },
]



// export enum Colors {
//     '#FF0000', // Red
//     '#FFA500', // Orange
//     '#FFFF00', // Yellow
//     '#008000', // Green
//     '#00FFFF', // Cyan
//     '#0000FF', // Blue
//     '#800080', // Purple
//     '#FFC0CB', // Pink
//     '#F08080', // Light Coral
//     '#FA8072', // Salmon
//     '#FF6347', // Tomato
//     '#FF7F50', // Coral
//     '#FF8C00', // Dark Orange
//     '#FFA07A', // Light Salmon
//     '#FFB6C1', // Light Pink
//     '#FFDAB9', // Peach Puff
//     '#FFE4B5', // Moccasin
//     '#FFE4E1', // Misty Rose
//     '#FFDEAD', // Navajo White
//     '#FFF0F5', // Lavender Blush
//     '#FFF5EE', // Seashell
//     '#FFF8DC', // Cornsilk
//     '#FFFACD', // Lemon Chiffon
//     '#F0E68C', // Khaki
//     '#BDB76B', // Dark Khaki
//     '#E6E6FA', // Lavender
//     '#D8BFD8', // Thistle
//     '#DDA0DD', // Plum
//     '#DA70D6', // Orchid
//     '#C71585', // Medium Violet Red
//     '#8B008B', // Dark Magenta
//     '#4B0082', // Indigo
//     '#9400D3', // Dark Violet
//     '#7B68EE', // Medium Slate Blue
//     '#6A5ACD', // Slate Blue
//     '#483D8B', // Dark Slate Blue
//     '#00CED1', // Dark Turquoise
//     '#008080', // Teal
//     '#2F4F4F', // Dark Slate Gray
//     '#708090', // Slate Gray
//     '#778899', // Light Slate Gray
//     '#FAEBD7', // Antique White
//     '#F5DEB3', // Wheat
//     '#FFEBCD', // Blanched Almond
//     '#F5F5DC', // Beige
//     '#DEB887', // Burly Wood
//     '#D2B48C', // Tan
//     '#BC8F8F', // Rosy Brown
//     '#F4A460', // Sandy Brown
//     '#A0522D', // Sienna
//     '#8B4513', // Saddle Brown
//     '#654321', // Dark Goldenrod
//     '#CD853F', // Peru
//     '#F0FFFF', // Azure
//     '#87CEEB', // Sky Blue
//     '#ADD8E6', // Light Blue
//     '#B0C4DE', // Light Steel Blue
//     '#6495ED', // Cornflower Blue
//     '#00BFFF', // Deep Sky Blue
//     '#1E90FF', // Dodger Blue
//     '#87CEFA', // Light Sky Blue
//     '#4169E1', // Royal Blue
//     '#000080', // Navy
//     '#00008B', // Dark Blue
//     '#0000CD', // Medium Blue
//     '#191970', // Midnight Blue
//     '#7FFFD4', // Aquamarine
//     '#40E0D0', // Turquoise
//     '#00FA9A', // Medium
// }