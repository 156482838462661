import { log, logIf, logTable, values } from '@maq-console';
import { FormGroup, FormControl, FormBuilder, Validators, FormsModule } from '@angular/forms';
import { ConfigComponenteBaseInterface} from '@maq-models/pageBase/configComponenteBase.interface'
import { TIPO_BASE_DATOS,TIPO_ACTUALIZACION_DATOS, WhereInterface, OrdeByInterface } from '@maq-models/bd/bdDefinicionesGenerales.model';
import { GRILLA_PAGINADO_TIPO } from '@maq-models/grilla/grillaBase.model';
import { WhereObjet,OrderObjet }   from '@maq-models/bd/bdDefinicionesGenerales.model';
import { ArgumentosGetListadoCacheBase }   from '@maq-models/mensajes/mensajesBase.model';

export class ConfigBase implements ConfigComponenteBaseInterface {

   public grilla                       : any;
   public tipoBaseDatoListadoPrincipal : TIPO_BASE_DATOS; //TIPO_BASE_DATOS.FIRESTORE usar el enum
   public tipoActualizacionDatosListadoPrincipal : TIPO_ACTUALIZACION_DATOS; //TIPO_BASE_DATOS.FIRESTORE usar el enum
   public urlBaseDatos                 : string;
   public nombreColeccion              : string;
   public campoClave                   : string;
   public usaSettings                  : boolean;
   public columnasAdicionalesLogTable  : any[];
   public configListadosCache          : ArgumentosGetListadoCacheBase[]=[];
   public arrayFiles                   : any[]; 
   public form                         : any;
   public t                            : any;// variable para comparar el modelo con el formulario
   public mostrarDiferenciaModeloFomulario : boolean=true;// muestra las diferencas entre el modelo y el formulario
   public listadoCamposNoDeseados      : string[]=[];// Listado de campos no desados
   
   
   public campoModeloUsadoEnKeywords   : string   = null       //en general se usa 'nombre'
   public camposDecimal                : string[] = [];  
   public whereListadoSecundarios      : Map<string,WhereInterface>;
   public orderByListadoSecundarios    : Map<string,OrdeByInterface>;


   public instanciarT                  : boolean = false;

   public setAccionFormInGrabar_coleccion : boolean = true; //si esta en true: setea el accionForm luego del submit en la page base
   
   constructor( public fb:any, whereListadoSecundarios?:Map<string,WhereInterface>, orderByListadoSecundarios?:Map<string,OrdeByInterface>) {

      if (whereListadoSecundarios) this.setWhereListadoSecundarios(whereListadoSecundarios);
      if (orderByListadoSecundarios) this.setOrderByListadoSecundarios(orderByListadoSecundarios);
      this.configuracionDelComponente();            
      this.configuracionGrilla();
      this.configuracionListadosAuxiliares();
      this.configuracionFormulario();
      this.configuracionArrayArchivos();
      
     
         
   } 

   configuracionDelComponente(){
    

   }

   configuracionGrilla():void{
     
   }

   configuracionListadosAuxiliares():void{


   }

   configuracionFormulario():void{
      
      
   }

   configuracionArrayArchivos():void{
      this.arrayFiles=[]; 
   }

   public hasTipoBaseDatosListdoPrincipalSQL():boolean{
      return this.tipoBaseDatoListadoPrincipal==TIPO_BASE_DATOS.SQL_SERVER
   }

   public hasTipoBaseDatosListdoPrincipalFirestore():boolean{
      return this.tipoBaseDatoListadoPrincipal==TIPO_BASE_DATOS.FIRESTORE

   }

   public hasUsaSettings():boolean{
      return this.usaSettings;
   }
   public hasTipoActualizacionDatosCorrecta():boolean{


      let respuesta:boolean=false;
      if (this.tipoBaseDatoListadoPrincipal==TIPO_BASE_DATOS.SQL_SERVER){
         if( this.tipoActualizacionDatosListadoPrincipal==TIPO_ACTUALIZACION_DATOS.PROMESA){ 
            respuesta=true;  
         } else{
            respuesta=false;  
         }
      } else{
         respuesta=true;  
      }
      
      return respuesta;
   }


   public setWhereListadoSecundarios(whereMap: Map<string,WhereInterface>){
      this.whereListadoSecundarios = whereMap;
   }

   public getWhereListadoSecundarios():Map<string,WhereInterface>{
      return this.whereListadoSecundarios;
   }

   
   public setOrderByListadoSecundarios(orderByMap: Map<string,OrdeByInterface>){
      this.orderByListadoSecundarios = orderByMap;
   }

   public getOrderByListadoSecundarios():Map<string,OrdeByInterface>{
      return this.orderByListadoSecundarios;
   }
}