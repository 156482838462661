import { Component, OnInit,  OnDestroy, DoCheck, Input, Output, ViewEncapsulation, EventEmitter } from '@angular/core';
import { combineLatest } from 'rxjs'
import { ToastrService, GlobalConfig }                  from 'ngx-toastr';
import { ConfirmService }                               from '@maq-servicios/confirm/confirm-service'
import { map } from 'rxjs/operators';
import { ExcelService }                                 from '@maq-servicios/excel/excel.service';
import { TransferenciaArchivos,TransferenciaBaseDatos } from '@maq-models/transferenciaArchivos/transferenciaArchivos.model';
import { ValidaTraduceCreaDocumentoService }            from '@maq-servicios/validacionArchivos/valida-traduce-crea-documento.service';
import { Observable, Subscription }                     from 'rxjs';
import { BDService }                                    from '@maq-bd/bd.service';
import {updateColeccionDataBatch,updateColeccionUserKeysBatch } from '@maq-models/bd/bd.model';
import { FuncionesService }                             from '@maq-funciones'; 

@Component({
  selector: 'lib-shared-transferencia-archivos',
  templateUrl: './transferencia-archivos.component.html',
  styleUrls: ['./transferencia-archivos.component.scss'], 
  encapsulation: ViewEncapsulation.None
})
export class TransferenciaArchivosComponent implements OnInit, OnDestroy, DoCheck {

 @Input() public iniciarTransferencia   : boolean                 = false;
 @Input() public criteriosTransferencia : TransferenciaArchivos[] = [];
 @Input() public transferenciaBaseDatos : TransferenciaBaseDatos  = null
 @Input() public tipoDeVerificaciones   : string                  = '';//'SoloTraducir'
 @Input() public archivoExcel           : any                     = null;

 @Output() resetFileInput               = new EventEmitter<string>();

//  public archivoExcel: any=null;
 public excelProgressNews               : Subscription;
 public comenzoTransferencia            : boolean=false;

 public excelProgress={
    cantidadPreparada                   : 0,
    cantidadCargada                     : 0,
    cantidadError                       : 0,
    cantidadTotal                       : 0,
    percentage                          : 0,
    cargandoExcell                      : false,
    estadosCarga                        : null,
    repetidos:{
      cantidadTotal                     : 0,
      cantidadOK                        : 0,
      cantidadRepetidos                 : 0,
      campoEnAnalisis                   : '',
    },
    validandoDatos:{
      cantidadTotal                     : 0,
      cantidadOK                        : 0,
      cantidadErrores                   : 0,
      canitidadEnProceso                : 0,
    },
    verificandoEnBaseDatos:{
      cantidadTotal                     : 0,
      cantidadOK                        : 0,
      cantidadErrores                   : 0,
      canitidadEnProceso                : 0,
    },
    grabandoDatos:{
      cantidadTotal                     : 0,
      cantidadOK                        : 0,
      cantidadErrores                   : 0,
      canitidadEnProceso                : 0,
    }

  }; 	
  public errorAnalisisExcell            : boolean=false;
  public errorExitenDatosEnBase         : boolean=false;
  public lineasSinErrores               : any[]=null;
  public lineasRechazadas               : any[]=null;
  public lineasRepetidas                : any[]=null;
  public faltanColumnas                 : boolean=false;
  public listaColumnasFaltantes         :string='';
  constructor(  private excel                 : ExcelService,
                private toastrService         : ToastrService,
				        private confirmService        : ConfirmService,
                private vtcdService           : ValidaTraduceCreaDocumentoService,
                private readonly bdService    : BDService,
                public fn:                    FuncionesService,
          ) {
  console.log('TransferenciaArchivosComponent'); }

  ngOnInit() {
  	console.log('TransferenciaArchivosComponent init');
    this.excelProgressNews= this.vtcdService.getEstadoTransferencia().subscribe(data=>{
      // console.log(data);
      this.excelProgress=data;
    });

  }
  
  ngOnDestroy(){
    console.log('TransferenciaArchivosComponent init');
    this.excelProgressNews.unsubscribe();
    this.vtcdService.setCriteriosDeTransferencia(null);
    this.vtcdService.setTransferenciaBaseDatos(null);  
  }
  
  ngDoCheck() {
    // console.log("ngDocheck this.comenzoTransferencia", this.comenzoTransferencia);
    // console.log("ngDocheck this.iniciarTransferencia", this.iniciarTransferencia);
    if(this.iniciarTransferencia && this.comenzoTransferencia==false) {
        this.comenzoTransferencia=true;
        this.procesarExcel();
    }
  }
  resetTransferenciaArchivos(){
    console.log('resetTransferenciaArchivos');
    this.resetFileInput.emit();
    this.comenzoTransferencia=false;
    this.iniciarTransferencia=false;
  }
  

  importExcel(evt: any){
      this.archivoExcel=evt;
      console.log("importExcel archivoExcel:",this.archivoExcel);
  }

  cerrarExcel():void {
    console.log("cerrarExcel");

    this.excelProgress.cargandoExcell=false;
    this.excelProgress.cargandoExcell=false;
 }

  procesarExcel(){
    console.log('procesarExcel',this.archivoExcel);

    let evt:any = this.archivoExcel;
    this.errorAnalisisExcell=false;
    this.errorExitenDatosEnBase=false;


    this.excelProgress={
      cantidadPreparada       : 0,
      cantidadCargada         : 0,
      cantidadError           : 0,
      cantidadTotal           : 0,
      percentage              : 0,
      cargandoExcell          : true,
      estadosCarga            : null,
      
      repetidos:{
        cantidadTotal         : 0,
        cantidadOK            : 0,
        cantidadRepetidos     : 0,
        campoEnAnalisis       : '',
      },
      
      validandoDatos:{
        cantidadTotal         : 0,
        cantidadOK            : 0,
        cantidadErrores       : 0,
        canitidadEnProceso    : 0,
      },
      verificandoEnBaseDatos:{
        cantidadTotal         : 0,
        cantidadOK            : 0,
        cantidadErrores       : 0,
        canitidadEnProceso    : 0
      },
       grabandoDatos:{
        cantidadTotal         : 0,
        cantidadOK            : 0,
        cantidadErrores       : 0,
        canitidadEnProceso    : 0,
      }
      
    };

    this.lineasSinErrores   = [];
    this.lineasRechazadas   = [];
    this.lineasRepetidas    = [];
    console.log("archivo leído", evt);
   
    this.excelProgress.estadosCarga="Archivo seleccionado, porcesando...";
    let nombreDeHojaExell=null;

    this.excel.convertExcelToJson(evt, null)
    .then(
        data=>{ 
          console.log(data);
          this.excelProgress.estadosCarga="Leyendo Datos";  
        
         // retorna el nombre de la primer hoja del excel 
          Object.keys(data).forEach(function(key) {
            console.log('key',key);
            nombreDeHojaExell=key;
            return;
          });

          // Verifico que existan todas las columnas que necesito
          this.faltanColumnas=false;
          this.listaColumnasFaltantes='';
          for(let i=0;i<this.criteriosTransferencia.length;i++){
            console.log('Repetidos')
            let nombreColumnaExcel=this.criteriosTransferencia[i].nombreColumnaExcel
            let tipoValidacion=this.criteriosTransferencia[i].tipoValidacion
            console.log('Repetidos nombreColumnaExcel',nombreColumnaExcel);
            console.log('Repetidos data[nombreDeHojaExell]',data[nombreDeHojaExell][0]);
            console.log('Repetidos data[nombreDeHojaExell][nombreColumnaExcel]',data[nombreDeHojaExell][0][nombreColumnaExcel]);
            if(nombreColumnaExcel!='' && tipoValidacion!='Calculado' && (data[nombreDeHojaExell][0][nombreColumnaExcel]==null||data[nombreDeHojaExell][0][nombreColumnaExcel]==undefined)){
              this.faltanColumnas=true;
              console.log('Repetidos faltanColumnas',this.faltanColumnas);
              this.listaColumnasFaltantes=this.listaColumnasFaltantes+nombreColumnaExcel+', ';
            }

          }
          if(this.faltanColumnas){
            return;

          }
        
         console.log('nombreDeHojaExell',nombreDeHojaExell);
         console.log('value transferenciaBaseDatos',this.transferenciaBaseDatos);
         console.log('value criteriosTransferencia',this.criteriosTransferencia);


         console.log('value',data[nombreDeHojaExell]);
        this.vtcdService.resetContadores();
        this.excelProgress.cantidadTotal=data[nombreDeHojaExell].length;

         //cargamos los datos para validar en la base de datos.
         this.vtcdService.setTransferenciaBaseDatos(this.transferenciaBaseDatos);
        
        //cargamos los datos para validar el excell
        this.vtcdService.setCriteriosDeTransferencia(this.criteriosTransferencia);

       
        // ****************************************************************    
        // Solo TRADUCIR    
        // ****************************************************************

        if(this.tipoDeVerificaciones=='SoloTraducir')  {

          let listaPromesas:Promise<any>[]=[];

          /* valido las condiciones solcitadas en el criteriosTransferencia
          // analizaCriteriosDeTransferencia-> retorna promesas con el resultado de la validación 
          // y un documento para grabar en la base*/
        
          listaPromesas= data[nombreDeHojaExell].map((lineaExcell:any, index:any)=>{
                this.excelProgress.cantidadPreparada++;
                console.log(this.excelProgress.cantidadPreparada);
                this.excelProgress.estadosCarga="Analizando ";
              
                
                return this.vtcdService.nuevoDocumentoFromLineaExcel(lineaExcell);
          });
          
       
          // valido Si existen repeticiones en los campos macados con true en clave única
          listaPromesas.push(this.vtcdService.analisisCamposClavesRepetidos(data[nombreDeHojaExell]));
    
          // proceso el resultado de todas las promesas  
          Promise
            .all(listaPromesas)
            .then(resultado=>{
              console.log('resultado externo ok',resultado);
              let listaDocumentos:any=[];
              let listaPromesasParaGrabar:Promise<any>[]=[];
              this.errorAnalisisExcell=false;
              for (var i = 0; i < resultado.length; i++) {
                if(resultado[i]['hayRepetidas']){ // si la respuesta en noHayRepetidas lo ignoro.
                  this.errorAnalisisExcell=true;
                  this.lineasRepetidas= resultado[i]['hayRepetidas']['lineaExcel'];
                }
                if(resultado[i]['error']){
                  this.errorAnalisisExcell=true;
                  this.lineasRechazadas.push(resultado[i]['error']);
                }
                if(resultado[i]['correcto']){
                  this.lineasSinErrores.push(resultado[i]['correcto']['lineaExcell']);
                  // si el documento tiene key, la graba con esa.
                  // si el documento, no tiene Key, le crea una
                  let d: updateColeccionDataBatch={  
                    operacion        : 'agregar',
                    nombreColeccion  : this.transferenciaBaseDatos.nombreColeccion,
                    documento        : this.fn.seteaCamposKN(resultado[i]['correcto']['documento']),
                    incluyeSettings  : true
                  }
                  listaDocumentos.push(d);
                  this.excelProgress.grabandoDatos.cantidadOK+=1;
                  
                  // listaPromesasParaGrabar.push(this.vtcdService.grabaDocumentoEnBases(resultado[i]['correcto']['documento']))
                }
              }

              let userKeys:updateColeccionUserKeysBatch={
                    distribuidorKN   :this.transferenciaBaseDatos.distribuidorKN,
                    organizacionKNAI : this.transferenciaBaseDatos.organizacionKNAI,
                    usuarioKANE      : this.transferenciaBaseDatos.usuarioKANE
                };
              
                listaPromesasParaGrabar.push(this.bdService.updateColeccionBatch(userKeys,listaDocumentos));
    
              if(!this.errorAnalisisExcell) {
                this.lineasSinErrores   = [];
                this.lineasRechazadas   = [];
                this.lineasRepetidas    = []; 
              }

              Promise
                .all(listaPromesasParaGrabar)
                .then(respuestas=>{ 
                  console.log('Escritura correcta: ',respuestas);
                  this.resetTransferenciaArchivos();
                  
                })  
                .catch(errorEscritura=>{ 
                  console.log('Escritura erronea: ',errorEscritura);
                  this.resetTransferenciaArchivos();
                })
                

            })

          // ****************************************************************    
          // FIN Solo TRADUCIR    
          // ****************************************************************


        } else{

       

       let listaPromesas:Promise<any>[]=[];

      /* valido las condiciones solcitadas en el criteriosTransferencia
      // analizaCriteriosDeTransferencia-> retorna promesas con el resultado de la validación 
      // y un documento para grabar en la base*/
      
      listaPromesas= data[nombreDeHojaExell].map((lineaExcell:any, index:any)=>{
               this.excelProgress.cantidadPreparada++;
               console.log(this.excelProgress.cantidadPreparada);
               this.excelProgress.estadosCarga="Analizando ";
                         
               return this.vtcdService.nuevoDocumentoFromLineaExcel(lineaExcell)
          }
        );
       
      // valido Si existen repeticiones en los campos macados con true en clave única
      listaPromesas.push(this.vtcdService.analisisCamposClavesRepetidos(data[nombreDeHojaExell]));

      // proceso el resultado de todas las promesas  
      Promise
        .all(listaPromesas)
        .then(resultado=>{
          console.log('resultado externo ok',resultado);
          this.resetFileInput.emit();
          // this.vtcdService.setCriteriosDeTransferencia(null);
          this.errorAnalisisExcell=false;
            for (var i = 0; i < resultado.length; i++) {
              if(resultado[i]['hayRepetidas']){ // si la respuesta en noHayRepetidas lo ignoro.
                this.errorAnalisisExcell=true;
                this.lineasRepetidas= resultado[i]['hayRepetidas']['lineaExcel'];
              }
              if(resultado[i]['error']){
                this.errorAnalisisExcell=true;
                this.lineasRechazadas.push(resultado[i]['error']);
              }
              if(resultado[i]['correcto']){
                this.lineasSinErrores.push(resultado[i]['correcto']['lineaExcell']);
              }
            }

            if(!this.errorAnalisisExcell) {
              this.lineasSinErrores=[];
              this.lineasRechazadas=[];
              this.lineasRepetidas=[];
              // this.excelProgress.estadosCarga="Verificando datos existentes ";
              let litaPromesasParaVerificarEnLaBase:Promise<any>[]=[];

              if(this.transferenciaBaseDatos.camposaVerificarEnBase.length==0){//no verificamos en la base de datos

               
              }

              

              resultado.map((resultado:any, indexResultado:any)=>{
                console.log('resultado', resultado);
                this.excelProgress.cantidadPreparada++;
                console.log('campoVerificarEnBase',this.transferenciaBaseDatos.camposaVerificarEnBase);

                
                for (var i in this.transferenciaBaseDatos.camposaVerificarEnBase) {
                  if(resultado['correcto']){                
                    console.log("1Excel",resultado,i);
                    litaPromesasParaVerificarEnLaBase.push(
                    // Retorna promesas para validar con el resultado de validar en la base de datos. 
                      this.vtcdService.buscarDatosExistentes(
                        resultado['correcto'],
                        this.transferenciaBaseDatos.nombreColeccion, 
                        // this.transferenciaBaseDatos.nombreClaseColeccion,
                        this.transferenciaBaseDatos.camposaVerificarEnBase[i],
                        this.transferenciaBaseDatos.camposClaveExcelParaVerificarEnBase[i] 
                      )
                    )
                  }
                }
              });  
              Promise
                .all(litaPromesasParaVerificarEnLaBase)
                .then(respuestas=>{
                  console.log('1excel res',respuestas);
                  let sePuedeHacerUpdate=true;
                  for (var respuesta  of respuestas) {
                    console.log('respuesta',respuesta);
                    if(respuesta['error']){
                      this.lineasRechazadas.push(respuesta['error']['lineaExcell']);
                      sePuedeHacerUpdate=false; 
                      this.errorExitenDatosEnBase=true;
                      this.vtcdService.verificarSiPuedeHacerUpdate(respuesta);
                    } else {
                      this.lineasSinErrores.push(respuesta['ok']['lineaExcell']); 
                    }
                  }
                  if(sePuedeHacerUpdate){
                    let listaPromesasEscritura=resultado.map((result:any, index:any)=>
                      { console.log('result',result);
                        return this.vtcdService.grabaDocumentoEnBases(result['correcto']['documento'])
                      });

                    Promise
                      .all(litaPromesasParaVerificarEnLaBase)
                      .then(respuestas=>{ console.log('errorEscritura',respuestas);})  
                      .catch(errorEscritura=>{ console.log('errorEscritura',errorEscritura);});
                  }
          
            })
                        .catch(err=>{
                        console.log('err',err);
                        });

                    }// fin no hay error analisis excell
                
         }) // fin de then lista de promesas 
          .catch(error=>{
            console.log(error);
            this.toastrService
                .error( '', 'analizando Datos. Error al analizar datos!',{
                        timeOut: 3000,positionClass:'toast-bottom-center'});
          })
      }
    
    }) 
    .catch(
      error=>{
        console.log(error);
        this.toastrService.error( '', 'cargar Datos. Verifique el archivo!',{
                   timeOut: 3000,positionClass:'toast-bottom-center'});
        this.resetTransferenciaArchivos();           
    });

      
  }

  exportAsXLSX():void {
     console.log(this.lineasSinErrores);
     console.log(this.lineasRechazadas);
     console.log(this.lineasRepetidas);
    
     let resultado={'lineasSinErrores':this.lineasSinErrores,'lineasRechazadas':this.lineasRechazadas,'lineasRepetidas':this.lineasRepetidas};
     
     
     this.excel.exportJsonAsExcelFile(resultado, 'resultado');
  }



  // getColeccionPrincipal(nombreColeccion,where, orderBy,limit,paginado,organizacionKNAI, usuarioKANE){
  //   let subscripcionColeccion=this.bdService.getBDSubscripcion({
  //       nombreColeccion  : nombreColeccion,
  //       where            : where,
  //       orderBy          : orderBy,
  //       limit            : limit,
  //       paginado         : paginado,
  //       organizacionKNAI : organizacionKNAI,
  //       usuarioKANE      : usuarioKANE                
  //     }).subscribe((data:any)=>{
  //       console.log('valores','data:',data); 
  //      },(error:any)=>{
  //       console.log("error",error);
  //     });         

  // }



}
