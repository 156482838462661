import { KN, KANE, KNAI }   from '../typesKN/typesKN.model';
import { ACCION_FORM, }      from '@maq-helpers/actionFormHelp';
import { GrillaBase, PaginadoFirebase,ItemsAndCurrentPage ,ONCHANGE_GRILLA, GRILLA_PAGINADO_TIPO,GRILLA_PAGINA_REQUERIDA}     from '@maq-models/grilla/grillaBase.model';
import { WhereInterface , OPERADOR_ORDEN_BY,OrdeByInterface, OPERADOR_WHERE, TIPO_BASE_DATOS,OPERACIONES_BASE_DATOS}  from '@maq-models/bd/bdDefinicionesGenerales.model';
import { ArgumentosGetListadoCacheBase}  from '@maq-models/mensajes/mensajesBase.model';

import { Query, QueryFn, } from '@angular/fire/firestore';

export class ArgumentosLlamarBaseDeDatos {
  
  tipoBaseDatos    : TIPO_BASE_DATOS  = null;// TIPO_BASE_DATOS.FIRESTORE usar el enum
 
  nombreColeccion  : string = null;         // se usa solo para Firebase
  where            : WhereInterface[]	 = []; // igual para ambas bases de datos
  orderBy          : OrdeByInterface[]  = []; // igual para ambas bases de datos
  itemsPorPagina   : number = null; 

  paginadoFirebase : PaginadoFirebase    = null; 
      
  paginaActualNro  : number = null;  
 
  urlBaseSQL       : string  = null;      // se usa solo para sql
 
  // public campoKeywords                : boolean  = true;     // false (resuelve con array keywords), true busca en el campo nombre. Indica si el filtro Nombre es sensitive (diferencia mayúsculas) o no. A menos que la tabla tenga definido el array keywords viene con true

  constructor(init:Partial<ArgumentosLlamarBaseDeDatos>) {
    Object.assign(this, init);
    
    this.verficarInconsistencias();
    
  }

  verficarInconsistencias(){
    if (this.tipoBaseDatos== TIPO_BASE_DATOS.FIRESTORE && this.nombreColeccion==null ){
      console.error('debe Proporcionar el dato nombreColeccion para acceder a Firebase');
    }
    if (this.tipoBaseDatos== TIPO_BASE_DATOS.FIRESTORE && this.urlBaseSQL!=null ){
      console.error('no se usar el campo urlBaseSQL para acceder a Firestore');
    }
    if (this.tipoBaseDatos== TIPO_BASE_DATOS.SQL_SERVER && this.urlBaseSQL==null ){
      console.error('no se usar el campo urlBaseSQL para acceder a SQL');
    }
    
    if (this.tipoBaseDatos== TIPO_BASE_DATOS.SQL_SERVER && this.nombreColeccion==null ){
      console.error('debe Proporcionar el dato nombreColeccion para accerder a la tabla SQL');
  
    }
  }

  public static fromArgumentosGetListadoCacheBase(argumentoGLCB:ArgumentosGetListadoCacheBase){

    const cls = new ArgumentosLlamarBaseDeDatos({});
        cls.tipoBaseDatos = argumentoGLCB.tipoBaseDatos; 
        cls.nombreColeccion = argumentoGLCB.nombreColeccion;
        cls.where = argumentoGLCB.where;
        cls.orderBy = argumentoGLCB.orderBy;
        cls.itemsPorPagina = null;  // trae toda la base
        cls.paginadoFirebase = null;  // trae toda la base
        cls.urlBaseSQL = argumentoGLCB.urlBaseSQL;  // trae toda la base


        return cls;

  }




  setCacheSQL(where:WhereInterface[], orderBy: OrdeByInterface[],urlBaseSQL : string ){
    
    this.where            = where;
    this.orderBy          = orderBy;
    
    this.itemsPorPagina   = null; 
    this.paginaActualNro  = null;  
   
    this.urlBaseSQL       = urlBaseSQL;
    this.tipoBaseDatos    =  TIPO_BASE_DATOS.SQL_SERVER

  }
  setCacheFirbase(){

  }

  getBaseDatosURL():string{
    // para hacer: get con parametros en el get que se sacan del array where

    


    let parametros='';

    let where:string= this.getWhereSQL();

    let orderby:string = this.getOrderBySQL();

    if(where!=''|| orderby!=''){
      parametros='?'+ where + orderby
    } 
    
    
    let offset = this.getOffsetSQL();                  
    let limit = this.getItemsPorPaginaSQL();                  
    
    if(offset!='' && limit!='') {
      if(parametros!='') {
          parametros+='&offset='+offset + '&limit='+limit;    
      } else {
          parametros+='?offset='+offset + '&limit='+limit;    
      }
    }
    
    
    // console.log("zzz parametros", parametros);
    


    let url:string='';

    if(this.where.length=0){
      url=this.urlBaseSQL;    
    } else {
      url=this.urlBaseSQL+parametros;    
    }
    

    // console.log('getBaseDatosURL url',url);

    return url;
  }

  getItemsPorPaginaSQL():string{
    let pagiandoCantidad='';
    // console.log('getBaseDatosUR LgetitemsPorPagina',this.itemsPorPagina);
    if(this.itemsPorPagina){
      pagiandoCantidad=this.itemsPorPagina.toString()
    }
    
    return pagiandoCantidad;

  }

  getOffsetSQL():string{
    // console.log('getBaseDatosUR getOffsetSQL paginaActualNro',this.paginaActualNro);
    // console.log('getBaseDatosUR getOffsetSQL  this.itemsPorPagina', this.itemsPorPagina);
    let offset =  (this.paginaActualNro-1) * this.itemsPorPagina;  
    return offset ? offset.toString(): '';

  }

  getWhereSQL():string{
   
    let parametros='';

    for (let index = 0; index < this.where.length; index++) {
      const element:WhereInterface = this.where[index];
      let filtro = 'filtro';
      if(element.operador==OPERADOR_WHERE.Igual) {
          filtro+='igual_';
      } else if(element.operador==OPERADOR_WHERE.MayorIgual) {
        filtro+='mayoroigual_';
      } else if(element.operador==OPERADOR_WHERE.MenorIgual) {
        filtro+='menoroigual_';
      } else if(element.operador==OPERADOR_WHERE.Menor) {
            filtro+='menor_';
      } else if(element.operador==OPERADOR_WHERE.Mayor) {
            filtro+='mayor_';
      } else if(element.operador==OPERADOR_WHERE.Contiene) {
            filtro+='contiene_';
      }    
      filtro+=element.key + '=' + element.value;
        
      parametros=parametros+'&'+filtro;   
    }

    return parametros;

  }

  getOrderBySQL():string{
    let parametros='';

    
    for (let index = 0; index < this.orderBy.length; index++) {
      const element:OrdeByInterface = this.orderBy[index];
      let orden = 'order_'+element.key+'='+element.ascDesc;
      parametros=parametros+'&'+orden;   
      
    }

    return parametros;
  }


  getFirbaseQuery(ref): Query{
      let query: Query = ref;
      
      query=this.getFirebaseWhere(query);
      query=this.getFirebaseOrderBy(query);
      query=this.getFirebaseStartkey(query);
      query=this.getFirebaseLimit(query);
      
      console.log('query',query);
      return query; 
  }
  
  getFirebaseWhere(query:Query):Query{

    // console.log('getPromesaFirebaseBase query1', query);
    if(this.where.length > 0) {
      for (var i = 0; i < this.where.length; i++) {
      
          // console.log('getPromesaFirebaseBase','agrego where:',this.where[i].key, this.where[i].operador, this.where[i].value);
          let operadorFirestore:any=this.getOperadorFirestore(this.where[i].operador);
          query = query.where(this.where[i].key, operadorFirestore, this.where[i].value);
          console.log('query'+i,query);
     
      }
     
     
    }
    // console.log('getPromesaFirebaseBase query2', query);
    return query; 
  }

  getOperadorFirestore(where:OPERADOR_WHERE):any{

    let operadorFirestore:string='';

      switch (where) {
        case OPERADOR_WHERE.Igual:
          operadorFirestore='==';
          break;
        case OPERADOR_WHERE.Mayor:
          operadorFirestore='>';
          break;
        case OPERADOR_WHERE.MayorIgual:
          operadorFirestore='>=';
          break;
        case OPERADOR_WHERE.Menor:
          operadorFirestore='<';
          break;
        case OPERADOR_WHERE.MayorIgual:
          operadorFirestore='<=';
          break;
      
        case OPERADOR_WHERE.IN:
          operadorFirestore='in';
          break;

          case OPERADOR_WHERE.Contiene:
          operadorFirestore='array-contains';
          break;  
         
        default:
          break;
      }

      return operadorFirestore;

  }

  getFirebaseOrderBy(query:Query):Query{
    if(this.orderBy?.length > 0) {
      for (var i = 0; i < this.orderBy.length; i++) {
         let key   :string  = this.orderBy[i].key;
         let ascDesc:any = this.orderBy[i].ascDesc == OPERADOR_ORDEN_BY.Ascendente ? 'asc' : 'desc' ;
         query = query.orderBy(key, ascDesc);
         // console.log('getPromesaFirebaseBase orden:',key+':'+ascDesc);
      }
    }
    return query;

  }

  getFirebaseStartkey(query:Query):Query{

    if(this.paginadoFirebase){

      switch (this.paginadoFirebase?.paginaRequerida) {
        case GRILLA_PAGINA_REQUERIDA.PRIMERA:
          // nada adicional
          break;
        case GRILLA_PAGINA_REQUERIDA.SIGUIENTE:
          query = query.startAfter(this.paginadoFirebase?.resultKeyN);

          break;
        case GRILLA_PAGINA_REQUERIDA.ANTERIOR:
          query = query.startAfter(this.paginadoFirebase?.resultKey1);

          break;
        case GRILLA_PAGINA_REQUERIDA.ANTERIOR_OVER_FLOW:
          query = query.startAfter(this.paginadoFirebase?.resultKey1);

          break;
        case GRILLA_PAGINA_REQUERIDA.TODAS:
          // nada adicional

          break;
      
        default:
          break;
      } 

     
    }
    // console.log('getPromesaFirebaseBase getFirebaseStartkey: this.paginadoFirebase', this.paginadoFirebase);
    return query;
    
  }

  getFirebaseLimit(query:Query):Query{
    // console.log('getPromesaFirebaseBase getFirebaseLimit: this.itemsPorPagina', this.itemsPorPagina);

    if(this.itemsPorPagina ){
      query = query.limit(this.itemsPorPagina);
    }
    return query;
  }


}


export class updateColeccionDataBatchBase {

  operacion        : OPERACIONES_BASE_DATOS  = null;  
  nombreColeccion  : string  = null; 
  documento        : any     = null;
  incluyeSettings  : boolean = true;
  

  constructor(init?:Partial<updateColeccionDataBatchBase>) {
    
    Object.assign(this, init);
    
  }

}