import {Component, ViewEncapsulation, ViewChild, HostListener, Input, ElementRef} from '@angular/core';
declare let $: any;
declare let jQuery: any;
@Component({
  selector: 'lib-shared-indicador-porcentaje',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./indicador-porcentaje.scss'],
  templateUrl: './indicador-porcentaje.html',
  
})
export class InidicadorPorcentajeComponent {

  @Input() public titulo: string;
  @Input() public porcentaje: number;
  @Input() public color: string;
  @Input() public bgColor: string;

  public  rotacion:string=' 0deg ';
  public  colorHex:string='000000';
  public  bgColorHex:string='000000';

   // Obtén el elemento de estilo
  //  private elemento:any = document.querySelector('.html');
    
  //  // Cambia el valor de la variable
   

   constructor( ) {

   }

  ngOnInit() {
    this.colorHex=this.colorNameToHex(this.color);
    this.bgColorHex=this.colorNameToHex(this.bgColor);
    // this.elemento.style.backgroundColor = 'blue';
    // this.elemento.style.templateUrl1=this.titulo;

    this.rotacion = this.getRotacion();

  }

  getRotacion():string{
    return (this.porcentaje/100*180)+'deg'; // agregar deg para usarlo directamnete

  }
  
  getDivPrincipal(){
    return {
      'text-align': 'center',
      'color': this.colorHex,
      'width': '12rem',
      'height': '12rem',
      'border-radius': '50%',
      'margin': 'auto',
      'background': 'linear-gradient(0deg, '+this.colorHex+' 50%,  rgba('+this.getColorCompletarMarcador()+' , .2) 50%)',
      'position': 'relative',
      'transform': 'rotate('+this.rotacion+')'
    }
  }
  getCentro(){
    return {
    
      'top': '0',
      'left': '0',
      'width': '12rem',
      'height': '12rem',
      'position': 'absolute',
      'background': this.bgColorHex+' content-box',
      'border-radius': '50%',
      'padding': '.5em', // ancho del arco
      'box-sizing': 'border-box',
      'font-size': '2rem',
      'color': this.colorHex,
      'text-align': 'center',
      'line-height': '8rem',
      'transform': 'rotate(-'+this.rotacion+')'
    };
  }

  getSubtitulo(){
    return {
      'top': '0',
      'left': '0',
      'width': '12rem',
      'height': '12rem',
      'position': 'absolute',
      'text-align': 'center',
      'color': this.colorHex,
      'background': 'linear-gradient(transparent 50%, '+this.bgColorHex +' 50%)',
      'transform': 'scale(1.1)  rotate(-'+this.rotacion+')',
      'font-size': '3rem',
      'line-height': '10rem'
      };
  }
  
  getColorCompletarMarcador():string{
    let colorHex=this.colorNameToHex(this.color);
    return this.hexToRGBAsString(colorHex);
  }

  colorNameToHex(colorName: string): string | null {
    const ctx = document.createElement("canvas").getContext("2d");
    if (!ctx) {
      return null; // No se puede crear un contexto de lienzo
    }
    
    // Establecer el color en el contexto de lienzo
    ctx.fillStyle = colorName;
    
    // Dibujar un píxel en el contexto de lienzo
    ctx.fillRect(0, 0, 1, 1);
    
    // Obtener el valor hexadecimal del color del píxel
    const hexColor = ctx.getImageData(0, 0, 1, 1).data.slice(0, 3).reduce((hex, value) => {
      return hex + ("0" + value.toString(16)).slice(-2);
    }, "#");
    
    return hexColor;
  }

  hexToRGBAsString(hex: string): string | null {
    // Expresión regular para verificar el formato hexadecimal válido
    const hexRegex = /^#?([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
  
    // Comprobar si la cadena cumple con el formato hexadecimal válido
    if (!hexRegex.test(hex)) {
      console.error('El formato del color hexadecimal no es válido.');
      return null;
    }
  
    // Eliminar el carácter '#' si está presente
    hex = hex.replace(/^#/, '');
  
    // Dividir el valor hexadecimal en componentes r, g, b
    let r, g, b;
    if (hex.length === 3) {
      // Convertir formato corto (por ejemplo, #abc) a formato largo (por ejemplo, #aabbcc)
      r = parseInt(hex.charAt(0) + hex.charAt(0), 16);
      g = parseInt(hex.charAt(1) + hex.charAt(1), 16);
      b = parseInt(hex.charAt(2) + hex.charAt(2), 16);
    } else {
      r = parseInt(hex.substr(0, 2), 16);
      g = parseInt(hex.substr(2, 2), 16);
      b = parseInt(hex.substr(4, 2), 16);
    }
  
    return  r+' , ' + g+ ' , '+ b ;
  }

}

