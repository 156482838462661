import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { AuthService } from '@maq-autorizacion/services/auth.service';; 
import { MensajesService }  from '@maq-servicios/mensajes/mensajes.service';
import { FuncionesService }  from '@maq-funciones';
import { environment }       from '../../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { MensajesServiceBase } from '@settings/maqueta/servicios/mensajes/mensajes.serviceBase';
@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'], 
  encapsulation: ViewEncapsulation.None
})
export class UserMenuComponent implements OnInit, OnDestroy {

  public fotoUsuario:string=null;
  public fechaHoraCreacion:any=null;
  public nombrePerfil:any=null;
  public refSubcripcion=null;
  public version: string=environment.version;
  public utc:string = ''; 

  constructor(public authService:AuthService,
              public fn:FuncionesService,
  			      private msg:MensajesService,
              private msgBase:MensajesServiceBase,

              public translate: TranslateService) { 

                
                
              }

  ngOnInit() {
  	// console.log(this.authService)	;
    this.refSubcripcion=this.msg.getPerfil().subscribe(usuario=>{
        // console.log('Profile usuario',usuario);
        if(!usuario) return;

        this.utc = usuario.getUTC();
        if(usuario.datosPersonales.fotoIMG && usuario.datosPersonales.fotoIMG.linkThumb!==undefined) {
          this.fotoUsuario = usuario.datosPersonales.fotoIMG.linkThumb;
        } else {
          this.fotoUsuario = null
        }  
        this.fechaHoraCreacion=this.fn.timestampToDate(usuario.settings.fechaHoraCreacion);
        this.nombrePerfil=usuario.perfilUsuario.tipo;
    });

  }

  ngOnDestroy(){

    if(this.refSubcripcion) {
       this.refSubcripcion.unsubscribe();  
    }
    
  }
  
  logOut(){
    // console.log('logout');
    this.authService.signOut();
  } 

}
