import { Component, ViewEncapsulation, OnInit,ChangeDetectorRef } from '@angular/core';
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';
import { TranslateService } from '@ngx-translate/core';
import { MensajesService }  from '@maq-servicios/mensajes/mensajes.service';
import { MenuColeccionService } from '@maq-servicios/menu/menu-coleccion.service';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Usuario } from '@maq-models/usuarios/usuarios.model';


@Component({
  selector: 'lib-shared-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ MenuColeccionService ]
})
export class FavoritesComponent implements OnInit {

    public favoriteSettings: IMultiSelectSettings = {
        enableSearch: true,
        checkedStyle: 'fontawesome',
        buttonClasses: 'btn btn-secondary btn-block',
        dynamicTitleMaxItems: 0,
        displayAllSelectedText: true
    };
    public favoriteTexts: IMultiSelectTexts = {
        checkAll: 'Select allx',
        uncheckAll: 'Unselect allx',
        checked: 'menu item selectedx',
        checkedPlural: 'menu items selectedx',
        searchPlaceholder: 'Find menu item...x',
        defaultTitle: 'Select favorite menu itemsx',
        allSelected: 'All selectedx',
    };

    public refMenuItemsSubjectObservable : any;
    public menuItems:Array<any>;                        // Menúe Completeo

    // public favoriteOptions: IMultiSelectOption[] = [];  // Menú Completo a mostrar en el desplegable 
    public favoriteOptions: Array<any>= [];  // Menú Completo a mostrar en el desplegable 
    public favoriteOptionsLoaded: boolean=false;

    public favoriteModel: number[] = [];   // Opciones Seleccionadas del Despleglable

    public favorites: any[] = [];      // Opciones a Mostrar Arriba

    public toggle:boolean;

    private collectionUsuarios: AngularFirestoreCollection<any>;
    private usuario:Usuario;


    variblesATraducir:string[]=
                        ['multiSelectTexts.checkAll', 
                         'multiSelectTexts.uncheckAll',
                         'multiSelectTexts.checked',
                         'multiSelectTexts.checkedPlural',
                         'multiSelectTexts.searchPlaceholder',
                         'multiSelectTexts.defaultTitle',
                         'multiSelectTexts.allSelected'
       ]

    constructor(public menuColeccionService:MenuColeccionService,
                private translate: TranslateService,
                private readonly af: AngularFirestore,
                private msg:MensajesService,
                private cd: ChangeDetectorRef,)  { 
     
      this.inicializarVariablesTraducibles();

    }


    inicializarVariablesTraducibles()  {

      this.translate.get(this.variblesATraducir).subscribe((res) => {
           this.cargaVariablesTraducibles(res);
              
      });
    
      this.translate.onLangChange.subscribe(() => {
          // console.log('langChange');
          this.translate.get(this.variblesATraducir).subscribe((res) => {
             // console.log("tradujo mensajes");
             this.cargaVariablesTraducibles(res);
          });
      });
    }

    cargaVariablesTraducibles(res:any){
      // console.log("currentLang",this.translate.store.currentLang);  
      this.favoriteTexts = {
                checkAll: res['multiSelectTexts.checkAll'],
                uncheckAll: res['multiSelectTexts.uncheckAll'],
                checked: res['multiSelectTexts.checked'],
                checkedPlural: res['multiSelectTexts.checkedPlural'],
                searchPlaceholder:res['multiSelectTexts.searchPlaceholder'],
                defaultTitle: res['multiSelectTexts.defaultTitle'],
                allSelected: res['multiSelectTexts.allSelected'],
      };

      let i=0;
      this.favoriteOptions.forEach(favorite => {
        // console.log("xx favorite",favorite);
        this.favoriteOptions[i].name = this.translate.instant(favorite.title);  // refresco xq al grabar en favoritos x ahí estaba en otro idoma
        i++;
      })        

      i=0;
      this.favorites.forEach(favorite => {
        // console.log("favorite",favorite);
        this.favorites[i].name = this.translate.instant(favorite.title);  // refresco xq al grabar en favoritos x ahí estaba en otro idoma
        i++;
      })        

    }

    public onDropdownOpened(){
      this.toggle = true;
    }
    public onDropdownClosed(){
      this.toggle = false;
    }

    public onChange() {

      if(!this.favoriteOptionsLoaded) {
        return;
      }

      this.favorites = [];
      this.favoriteModel.forEach(i => {
          console.log("i",i);
          let favorite = this.favoriteOptions.find(mail => mail.id === +i);
          this.favorites.push(favorite);
      });      
      console.log("favorites",this.favorites);  // Iconos actuales de Favoritos

      console.log("xxxx",this.favorites);
      
      this.af.collection('Usuarios').doc(this.usuario.key).set({'favoritosMenues':this.favorites},{merge:true}).then((respuesta) => {
      //this.collectionUsuarios.doc(this.usuario.key).set({'favoritosMenues':this.favorites}).then((respuesta) => {
                 console.log("respuesta",respuesta);
              }).catch((error)=>{
                 console.log("error",error);
             });  

      //sessionStorage.setItem("favorites", JSON.stringify(this.favorites));  
    }

    ngOnInit() {

      this.refMenuItemsSubjectObservable = this.menuColeccionService.menuItemsSubjectObservable.subscribe(datos=>{
          //console.log("menuItemsSubjectObservable menuItems",datos);
          this.menuItems = datos;

          this.configuroFavoritos();
      });

    }

    configuroFavoritos() {

      this.favoriteOptions=[];
        this.menuItems.filter(menu => menu.routerLink != null || menu.href !=null)
        .forEach(item=>{
            let menu = { 
                id: item.id,                 
                title: item.title,
                name: this.translate.instant(item.title),                 
                routerLink: item.routerLink, 
                href: item.href,
                icon: item.icon,
                target: item.target
              }
            //console.log("menu",menu);
            this.favoriteOptions.push(menu);
        })
        // console.log("this.favoriteOptions",this.favoriteOptions);  // Opciones del Desplegable

        // ---- Cargo Favoritos Grabados -----
        let obsUsuario=null;
         obsUsuario =this.msg.getPerfil().subscribe(usuario=>{
            // console.log('mensaje',usuario);
            this.usuario=usuario;
            let favoritesAux = null;
            if(usuario.favoritosMenues!=null && usuario.favoritosMenues!=undefined) {

              favoritesAux=usuario.favoritosMenues;                  
            } else {
              this.favorites=[];
            }
            this.favoriteModel=[];

            // Traduzco Favoritos grabados del usuario
            this.favorites=[];
            this.favoriteModel=[];
            for (var i in favoritesAux) {

                let favorite=favoritesAux[i];

                // console.log("favorite",favorite);
                favorite.name = this.translate.instant(favorite.title);  // refresco xq al grabar en favoritos x ahí estaba en otro idoma
                favorite.id   = parseInt(favorite.id);
                this.favorites.push(favorite);
                this.favoriteModel.push( favorite.id );
            }

            // console.log("favoriteOptions",this.favoriteOptions);  // Totalidad de Opciones del Desplegable
            // console.log("favoriteModel",this.favoriteModel);      // Opciones seleccionadas del Desplegable
            // console.log("favorites",this.favorites);              // Iconos actuales de Favoritos          

            if(obsUsuario){
                obsUsuario.unsubscribe();
            }

            if (!this.cd['destroyed']) {
               this.cd.detectChanges();
            }      

            this.favoriteOptionsLoaded=true;

        });

    }

}
