import { log, logIf, logTable, values } from '@maq-console';

import { AppSettings2 } from '@maq-models/appSettings/appSettings.model';
import { AppSettingsService } from './../../../app.settings';

import { Injectable } from '@angular/core';
import { Observable, throwError, of, Subject } from 'rxjs';

import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Query, QueryFn } from '@angular/fire/firestore';

import { ApisService }       from '@maq-apis';
import { FuncionesService }  from '@maq-funciones';

declare let jQuery: any;

@Injectable({
  providedIn: 'root'
})

export class MenuWebSiteService {

  public settings2: AppSettings2;
  
  public subscripcionMenuWebSite;
  
  public cacheMenuWebSite:any[] = [];
  public cacheMenuWebSiteObs :Subject<any>=new Subject<any>();  

  public cacheBloques:any[] = [];
  public cacheBloquesObs :Subject<any>=new Subject<any>();  
  
  public menuListaTrue:any[]=[];
  
  constructor(public appSettings:AppSettingsService, 
              public fn:FuncionesService,
              public apis:ApisService,
              private af: AngularFirestore) { 
                 
      this.settings2 = this.appSettings.settings2;  
      
      this.obtenerMenuWebSiteBD();
      this.obtenerBloquesBD();
     
  }
  
  getMenuWebSiteCache():Observable<any>{

   if(!this.settings2.webSite.aplicacionKey) {
      return of (true);
   }
 
   if(this.cacheMenuWebSite.length>0){
       log(...values("funcionEnd","getMenuWebSiteCache, ya estaba en memoria "));
       log(...values("valores",'this.cacheMenuWebSite:',this.cacheMenuWebSite));
    
       return of (true);

   } else {
     //   if(!this.cacheMenuWebSiteObs) {
     //        this.cacheMenuWebSiteObs=new Subject<any>();
     //   }

       //this.obtenerMenuWebSiteBD();
       return this.cacheMenuWebSiteObs.asObservable();                      
   }

 }

 obtenerMenuWebSiteBD() {
     log(...values("funcionGoPromesa","obtenerMenuWebSiteBD"));
     
     this.af.collection<any>('Menues',
       ref =>{
             let query: Query = ref;
             query = query.where('aplicacion', '==', this.settings2.webSite.aplicacionKey);
             query = query.orderBy('orden', 'asc');
             log(...values("valores",'menu query:',query));
             return query;

     }).valueChanges().subscribe((data:any)=>{
       
          let coleccionTotalSizeOf=0;
          let listado=[];
          for(let i=0; i<data.length; i++) {
              let documento=this.fn.copiarObjetoNew(data[i]);
              
              // Convierto valores timestamp a Date 
              documento=this.fn.corrigeTimestampDocumento(documento);

              documento.settings.sizeOfDocumento = 
              this.fn.sizeOfIfNotCalculado({ sizeOfActual  : 0, 
                                          pathColeccion : 'Menues'+documento.key,   
                                          object        : documento });

              coleccionTotalSizeOf += documento.settings.sizeOfDocumento;    

              if(documento.settings && (documento.settings.isActivo===undefined || documento.settings.isActivo!=false)) {
                  listado.push( documento );
              }   
          }  
          console.log("menu listado", listado);
          
          //this.menuListaTrue=data;
          this.menuListaTrue=listado;          
          this.ordenarMenues();

          this.cacheMenuWebSite=this.menuListaTrue;

          // Disponibilizo Listado en Memoria
          this.cacheMenuWebSiteObs.next( true );  
          
          log(...values("funcionEnd","getMenuWebSite, obtuvo de Firestore "));
          log(...values("valores",'this.cacheMenuWebSite:',this.cacheMenuWebSite));
          
          let organizacionKNAI=null;
          let usuarioKANE = {
            key            : 'santiagocavalie@gmail.com',
            apellidoNombre : 'santiagocavalie@gmail.com',                                
            email          : 'santiagocavalie@gmail.com',                                
          };
 
         //  log(...values("warning","Disparó FirestoreDocumentRead desde mensaje: "+nombreColeccion));
          this.apis.LogApiFuncion({
               eventoQueDisparo : 'obtenerMenuWebSiteBD',
               apiFuncionKey    : 'FirestoreDocumentRead', 
               organizacionKNAI : organizacionKNAI,
               usuarioKANE      : usuarioKANE,
               nombreColeccion  : 'Menues',
               cloudFunction    : null,
               cantidad         : data.length, 
          });

         //  log(...values("warning","Disparó FirestoreTransferencia desde mensaje: "+nombreColeccion));
          this.apis.LogApiFuncion({
               eventoQueDisparo : 'obtenerMenuWebSiteBD',
               apiFuncionKey    : 'FirestoreTransferencia', 
               organizacionKNAI : organizacionKNAI,
               usuarioKANE      : usuarioKANE,
               nombreColeccion  : 'Menues',
               cloudFunction    : null,
               cantidad         : coleccionTotalSizeOf, 
          });

     })   

  }

  
  getBloquesCache():Observable<any>{

   if(!this.settings2.webSite.aplicacionKey) {
      return of (true);
   }
 
   if(this.cacheBloques.length>0){
       log(...values("funcionEnd","getBloquesCache, ya estaba en memoria "));
       log(...values("valores",'this.cacheBloques:',this.cacheBloques));
    
       return of (true);

   } else {
     //   if(!this.cacheBloquesObs) {
     //        this.cacheBloquesObs=new Subject<any>();
     //   }

     //   this.obtenerBloquesBD();
       return this.cacheBloquesObs.asObservable();                      
   }

 }

 obtenerBloquesBD() {
     log(...values("funcionGoPromesa","obtenerBloquesBD"));
     
     this.af.collection<any>('BloquesSecciones',
       ref =>{
             let query: Query = ref;
             //query = query.where('aplicacion', '==', this.settings2.webSite.aplicacionKey);
             query = query.orderBy('orden', 'asc');
             return query;

     }).valueChanges().subscribe((data:any)=>{
       
          let coleccionTotalSizeOf=0;
          let listado=[];
          for(let i=0; i<data.length; i++) {
              let documento=data[i];
              
              // Reemplazo hipervínculos internos tipo <a href x routerLink
              documento=this.corrigeHipervinculos(documento);
              
              // Convierto valores timestamp a Date 
              documento=this.fn.corrigeTimestampDocumento(documento);

              documento.settings.sizeOfDocumento = 
              this.fn.sizeOfIfNotCalculado({ sizeOfActual  : 0, 
                                          pathColeccion : 'BloquesSecciones'+documento.key,   
                                          object        : documento });

              coleccionTotalSizeOf += documento.settings.sizeOfDocumento;    

              if(documento.settings && (documento.settings.isActivo===undefined || documento.settings.isActivo!=false)) {
                  listado.push( documento );
              }    
          }  

          this.cacheBloques=listado;

          // Disponibilizo Listado en Memoria
          this.cacheBloquesObs.next( true );  
          
          log(...values("funcionEnd","getBloques, obtuvo de Firestore "));
          log(...values("valores",'this.cacheBloques:',this.cacheBloques));
          
          let organizacionKNAI=null;
          let usuarioKANE = {
            key            : 'santiagocavalie@gmail.com',
            apellidoNombre : 'santiagocavalie@gmail.com',                                
            email          : 'santiagocavalie@gmail.com',                                
          };
 
         //  log(...values("warning","Disparó FirestoreDocumentRead desde mensaje: "+nombreColeccion));
          this.apis.LogApiFuncion({
               eventoQueDisparo : 'obtenerBloquesBD',
               apiFuncionKey    : 'FirestoreDocumentRead', 
               organizacionKNAI : organizacionKNAI,
               usuarioKANE      : usuarioKANE,
               nombreColeccion  : 'BloquesSecciones',
               cloudFunction    : null,
               cantidad         : data.length, 
          });

         //  log(...values("warning","Disparó FirestoreTransferencia desde mensaje: "+nombreColeccion));
          this.apis.LogApiFuncion({
               eventoQueDisparo : 'obtenerBloquesBD',
               apiFuncionKey    : 'FirestoreTransferencia', 
               organizacionKNAI : organizacionKNAI,
               usuarioKANE      : usuarioKANE,
               nombreColeccion  : 'BloquesSecciones',
               cloudFunction    : null,
               cantidad         : coleccionTotalSizeOf, 
          });

     })   

  }
  
  corrigeHipervinculos(documento:any):any {

      // Reemplazo <a href="/secion/*" x <a href="javascript:;" routerLink="*"
      if(documento.texto_es) documento.texto_es = this.reemplaza_href_x_routerLink(documento.texto_es);
      if(documento.texto_en) documento.texto_en = this.reemplaza_href_x_routerLink(documento.texto_en);
      if(documento.texto_pt) documento.texto_pt = this.reemplaza_href_x_routerLink(documento.texto_pt);
    
      // Agrego espacio, para que no quede pegado al renglón de abajo
      // if(documento.texto_es) documento.texto_es = documento.texto_es.replace('<a href','<br><a href');
      // if(documento.texto_en) documento.texto_en = documento.texto_en.replace('<a href','<br><a href');
      // if(documento.texto_pt) documento.texto_pt = documento.texto_pt.replace('<a href','<br><a href');
      return documento;
  }
  
  reemplaza_href_x_routerLink(value:any):any {
      value = this.fn.str_replace('<a href="/seccion','<a href="zzz/seccion',value);
      return value;
    
      let posHref = value.indexOf('<a href="/seccion');
      //console.log("zzz posHref",value, posHref);
      let cantReemplazos=0; // Por las dudas pare evitar loop infinito en caso de error
      while(posHref!=-1 && cantReemplazos<10) {
          let subcadena=value.substr(posHref,1000000);
          let posHrefFin = subcadena.indexOf('>');
          let stringAReemplazar = subcadena.substr(0, posHrefFin+1);
          let seccionDestino = stringAReemplazar.replace('<a href="/seccion/','');
          seccionDestino = seccionDestino.replace('">','');
          //console.log(value, "stringAReemplazar",stringAReemplazar, seccionDestino);
          
          let anchorName=null;
          if(seccionDestino.includes('#')) {
              let aux=seccionDestino.split('#');
              seccionDestino=aux[0];
              anchorName    =aux[1];            
              value = this.fn.str_replace(stringAReemplazar,'<a href="javascript:;" routerLink="/seccion/'+seccionDestino+'" fragment="'+anchorName+'">',value);
              console.log("reemplazo ",stringAReemplazar,'<a href="javascript:;" routerLink="/seccion/'+seccionDestino+'" fragment="'+anchorName+'">');
          } else {
              //console.log("reemplazo pos", value.indexOf(stringAReemplazar) );
              //console.log("reemplazo ",stringAReemplazar,'<a href="javascript:;" routerLink="/seccion/'+seccionDestino+'">');
              
              value = this.fn.str_replace(stringAReemplazar,'<a href="javascript:;" routerLink="/seccion/'+seccionDestino+'">',value);
              //value = this.fn.str_replace(stringAReemplazar,'<a href="javascript:gol();">',value);
          }
          
          cantReemplazos++;
          posHref = value.indexOf('<a href="/seccion');
      }
      if(cantReemplazos>0) {
          //console.log("reemplazo final",value);
      }
      return value;
    
  }  
  
  
  public ordenarMenues() {
    let multiplicador=10000;
    let listadoNivel1=[];

    // Genero array con Opciones de Nivel 1 y le agrego atributo ordenGlobal
    
    for(let i=0; i<this.menuListaTrue.length; i++) {
       let opcion=this.menuListaTrue[i];
       if(opcion.parentId==0) {
             listadoNivel1.push(opcion);  
             this.menuListaTrue[i].ordenGlobal=opcion.orden * 10000 + i;
             this.menuListaTrue[i].nivel=1;
       }
    }  

    // Ordeno Array de Nivel 1 por campo orden
    listadoNivel1.sort(this.fn.ordenarXAtributo('orden','asc',false));

    // Recorro SubOpciones de Nivel 2,3,4 y le agrego atributo ordenGlobal
    for(let i=0; i<listadoNivel1.length; i++) {
       let opcion=listadoNivel1[i];
       if(opcion.hasSubMenu) {
           this.obtenerSubOpciones(opcion.id, 2, opcion.ordenGlobal);  
       }
    }  

    // Ordeno por Atributo ordenGlobal
    this.menuListaTrue.sort(this.fn.ordenarXAtributo('ordenGlobal','asc',false));
    console.log("this.menuListaTrue",this.menuListaTrue);

  }    


  obtenerSubOpciones(id, nivel, ordenPadre):any {

      let multiplicador;
      if(nivel==2) multiplicador = 10000;
      if(nivel==3) multiplicador =  1000;
      if(nivel==4) multiplicador =   100;

      let proximoNivel=nivel+1;
      //for(let i=0; i<this.menuListaTrue.length; i++) {
      for (var i in this.menuListaTrue) {    

           let subOpcion=this.menuListaTrue[i];
           if(subOpcion.parentId==id) {

              subOpcion.ordenGlobal = ordenPadre + subOpcion.orden*multiplicador;
              this.menuListaTrue[i].ordenGlobal = subOpcion.ordenGlobal;
              this.menuListaTrue[i].nivel       = nivel;

              if(subOpcion.hasSubMenu) {
                this.obtenerSubOpciones(subOpcion.id, proximoNivel, subOpcion.ordenGlobal);
              } 
              
           }
      }   
  }       

  
}
