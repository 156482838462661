import { KN, KANE, KNAI }   from '../typesKN/typesKN.model';
import { ACCION_FORM }      from '@maq-helpers/actionFormHelp';
import { GrillaBase, PaginadoFirebase,ItemsAndCurrentPage ,ONCHANGE_GRILLA, GRILLA_PAGINADO_TIPO,GRILLA_PAGINA_REQUERIDA}     from '@maq-models/grilla/grillaBase.model';







export interface WhereInterface{
  key         : string;
  operador    : any;// pongo any en lugar de string para que lo acepte firestore
  value       : Date | string | boolean|string[];
}

export enum OPERADOR_WHERE { //FUNCIONA PARA SQL Y FIREBASE CON BD SERVICE BASE
  Igual         = " == ",
  Mayor         = " > ",
  MayorIgual    = " >= ",
  Menor         = " < ",
  MenorIgual    = " <= ",
  IN            = "in",
  Contiene      = "contiene"  //'array-contains en firestore verificar!!!
  
}

export enum OPERADOR_WHERE_FIREBASE { //FUNCIONA SOLO PARA FIREBASE CON BD SERVICE
  Igual         = "==",
  Mayor         = ">",
  MayorIgual    = ">=",
  Menor         = "<",
  MenorIgual    = "<=",
  IN            = "in",
  Contiene      = "array-contains",  
}

export interface OrdeByInterface{
  key         : string;
  ascDesc     : OPERADOR_ORDEN_BY;
  
}

export enum OPERADOR_ORDEN_BY {
  Ascendente    = " ASC ",
  Descendente   = " DESC ",
  
}

export enum TIPO_BASE_DATOS {
  SQL_SERVER  = "sql",
  FIRESTORE   = "firestore"
}

export enum TIPO_ACTUALIZACION_DATOS {
  PROMESA        = "promesa",
  SUBSCRIPCION   = "subscripcion"
}

export enum OPERACIONES_BASE_DATOS {
  BORRADO_FISICO      = "borradoFisico",
  BORRADO_LOGICO      = "borradoLogico",
  AGREGAR             = "agregar",
  MODIFICAR           = "modificar",
  IMPORTAR            = "importar"
  
}




export interface WhereInterface{
  key         : string;
  operador    : any;// pongo any en lugar de string para que lo acepte firestore
  value       : Date | string | boolean|string[];
}


export class WhereObjet {

  key:      string=null;
  operador: any=null;
  value:    string=null
  constructor(init?:Partial<WhereObjet>) {
    
    Object.assign(this, init);    
  }
}

 
export class OrderObjet {

  key:      string=null;
  ascDesc:  any=null;

  constructor(init?:Partial<OrderObjet>) {
    
    Object.assign(this, init);    
  }
}

 
