
    <div >
          <angular2-multiselect [data]="datos" [(ngModel)]="datosSeleccionados" 
            [settings]="dropdownSettings" 
            (onSelect)="onItemSelect($event)" 
            (onDeSelect)="OnItemDeSelect($event)"
            (onSelectAll)="onSelectAll($event)"
            (onDeSelectAll)="onDeSelectAll($event)"></angular2-multiselect>
    </div>        

