// https://developer.here.com/documentation/examples/rest/geocoding_suggestions
// https://autocomplete.geocoder.ls.hereapi.com/6.2/suggest.json?query=argentina&beginHighlight=<mark>&endHighlight=</mark>&maxresults=5&apikey=H6XyiCT0w1t9GgTjqhRXxDMrVj9h78ya3NuxlwM7XUs-I4W8bPrET2LY9Q

import { log, logIf, logTable, values } from '@maq-console';

import { Component, ElementRef, OnInit, AfterViewInit, ViewChild, EventEmitter, Output, Input, DoCheck  } from '@angular/core';
import { FormGroup, FormBuilder} from '@angular/forms';
import { ChangeDetectorRef }      from '@angular/core';
import {PAISES}             from '@maq-mocks/paises/paises';
import { MapsAPILoader }          from '@agm/core';

import { MensajesService }        from '@maq-servicios/mensajes/mensajes.service';
import { FuncionesService }       from '@maq-funciones';
import { TranslateService }       from '@ngx-translate/core';
import { ApisService }            from '@maq-apis';
import { HttpClient }             from '@angular/common/http';

import { GeoPoint }               from '@maq-models/geopoint/geopoint.model';
import { Direccion }              from '@maq-models/direccion/direccion.model';

import { environment }            from '@environments/environment';

import { take } from 'rxjs/operators';

declare var H: any;  

declare var google:any;

// jQuery
declare let $: any;
declare let jQuery: any;

@Component({
  selector: 'lib-shared-here-map-autocomplete',
  templateUrl: './here-map-autocomplete.component.html',
  styleUrls: [ './here-map-autocomplete.component.css']
})

export class HereMapAutocompleteComponent implements DoCheck, OnInit, AfterViewInit {

  @ViewChild("mapContainer")  public mapElement: ElementRef;
  @ViewChild("search")        public searchElementRef: ElementRef;

  @Input() geoPointFormulario:GeoPoint;
  @Input() accionForm:  string;
  
  @Input() organizacionKNAI : any;
  @Input() usuarioKANE      : any;
  @Input() nombreColeccion  : any;

  @Output() escribioDireccion= new EventEmitter<Direccion>();
  
  // Variables Here
  public map                        : any;
  public viewBounds                 : any;
  public geocoderRequest            : any;
  public zoom                       : number = 15;
  public ubicacion                  : Direccion;
  public form                       : FormGroup;
  public placeHolderAutocomplete    : string;
  public cantAutocomplete           : number = 0;
  public dif                        : string = Math.floor((99999) * Math.random()).toString();

  // Colecciones Secundarias
  public subscripcionCache                            : any[]=[];
  public configListadosCache                          : any[]=[];
  public cantidadSubscripcionesSecundariasLanzadas    : number=0;
  public finalizoGETSecundarias                       : boolean=false;
  
  constructor(private changeDetectorRef   : ChangeDetectorRef,
              private translate           : TranslateService,
              public fn                   : FuncionesService,
              public msg       : MensajesService,
              public apis                 : ApisService,
              public fb                   : FormBuilder,
              public _http               : HttpClient) {

      this.ubicacion = new Direccion();
      
      this.cargaVariablesTraducibles();

  }  

  ngAfterViewInit() {

    let self=this;
    
    // Si el mapa aún no tiene dirección, obtengo la del navegante
    // console.log("paso1");
    if(!this.geoPointFormulario){
        this.setCurrentPosition(); 
    } else{
      this.ubicacion.geoPoint.latitud = this.geoPointFormulario.latitud;
      this.ubicacion.geoPoint.longitud =  this.geoPointFormulario.longitud;  
    }
    
		// Check whether the environment should use hi-res maps
    var hidpi = ('devicePixelRatio' in window && devicePixelRatio > 1);
    // console.log('jjHere hidpi',hidpi);
		
		// check if the site was loaded via secure connection
		var secure = (location.protocol === 'https:') ? true : false;
    // console.log('jjHere secure',secure);
    // Create a platform object to communicate with the HERE REST APIs
		var platform = new H.service.Platform({
      useCIT    : true,
      apikey    : environment.apis.hereMaps.apiKey,
			useHTTPS  : secure
    }),    
		// maptypes = platform.createDefaultLayers(hidpi ? 512 : 256, hidpi ? 320 : null),
		maptypes = platform.createDefaultLayers({pois: true}),
    geocoder = platform.getGeocodingService();
    
    // console.log('jjHere platform',platform);
    // console.log('jjHere maptypes',maptypes);
    // console.log('jjHere geocoder',geocoder);
 

    // Instantiate a map in the 'map' div, set the base map to normal
    // console.log("mapElement2",document.getElementById('mapContainer'+this.dif));
    this.map = new H.Map(document.getElementById('mapContainer'+this.dif), 
      maptypes.vector.normal.map, {
      zoom: this.zoom,
      center: { 
        lat: this.geoPointFormulario.latitud, 
        lng: this.geoPointFormulario.longitud
      },
      pixelRatio: hidpi ? 2 : 1
    });
    
    // Add the locations group to the map
    var group;
    if (group) {
      this.map.removeObject(group);
    }
    group = new H.map.Group();  
    let positionMarker = {
        lat: this.geoPointFormulario.latitud, 
        lng: this.geoPointFormulario.longitud
    };
    let marker = new H.map.Marker(positionMarker);
    
    group.addObject(marker);
    this.map.addObject(group);
    
		// Enable the map event system
		var mapevents = new H.mapevents.MapEvents(this.map);

		// Enable map interaction (pan, zoom, pinch-to-zoom)
		var behavior = new H.mapevents.Behavior(mapevents);

		// Enable the default UI
		let ui = H.ui.UI.createDefault(this.map, maptypes);

		// setup the Streetlevel imagery
		//platform.configure(H.map.render.panorama.RenderEngine);  // Me tira error

    // if the window is resized, we need to resize the viewport
    window.addEventListener('resize', function() { self.map.getViewPort().resize(); });

    // Totalizo la visualización del Mapa al iniciar el componente
    this.apis.LogApiFuncion({
      eventoQueDisparo : '<here-map-autocomplete> - Visualización Inicial del Mapa',
      apiFuncionKey    : 'HereMapsView', 
      organizacionKNAI : this.organizacionKNAI,
      usuarioKANE      : this.usuarioKANE,
      nombreColeccion  : this.nombreColeccion,
      cloudFunction    : null,
      cantidad         : 1, 
    });    
		
		// register widget for the autocomplete box http://api.jqueryui.com/jQuery.widget/#jQuery-Widget2
		$.widget("custom.autocompleteHighlight", $.ui.autocomplete, {
			_renderItem : function (ul, item) {
        
          // console.log("item",item.label);
          
          // var re = new RegExp("^" + this.term) ;
          // var t = item.label.replace(re,"<span style='font-weight:bold;color:Blue;'>" + 
          //         this.term + 
          //         "</span>");
          var t=item.label;        
          var html = "<a>" + t + "</a>";
          //console.log("html:",html);
                    
          return $( "<li></li>" )
              .data( "item.autocomplete", item )
              .append( html )
              .appendTo( ul );          
              
          // return $( "<li></li>" )
          //     .data( "item.autocomplete", item )
          //     .append( "<a class='widgetSanti'>" + t + "</a>" )
          //     .appendTo( ul );          
              
          //return $('<li>' + item.label + '</li>').appendTo(ul);
          
          // return $( "<li>" )
          // .attr( "data-value", item.value )
          // .append( item.label )
          // .appendTo( ul );              
      }
      
      
		});
		
		// https://api.jquery.com/jquery.deferred/
		function getReady() {
			var deferredReady = $.Deferred();
			$(document).ready(function()
			{
				deferredReady.resolve();
			});
			return deferredReady.promise();
		}

		// url to be used for Autocomplete API calls
		var autoCompelteUrl='http://autocomplete.geocoder.cit.api.here.com/6.2/suggest.json';
		if(secure) {
			autoCompelteUrl='https://autocomplete.geocoder.cit.api.here.com/6.2/suggest.json';
		}

		// define the autocomplete component
		$("#search"+this.dif).autocompleteHighlight({
			source : function (request, response) {
          // var viewBounds = this.map.getViewBounds();  // map undefined
          // var viewBounds = self.map.getViewBounds();  // getViewBounds is not a funcion
  				
  				// setting request params for Geocoder Autocomplete API
  				// https://developer.here.com/documentation/geocoder-autocomplete/topics/resource-suggest.html
  				this.geocoderRequest = $.ajax({
  					url                 : autoCompelteUrl,
  					dataType            : "json",
  					data : {
              language          : "es",
  						maxresults        : 5,
  						query             : request.term,
              beginHighlight    : '<b>',
              endHighlight      : '</b>',
              app_id            : environment.apis.hereMaps.app_id,
              app_code          : environment.apis.hereMaps.app_code
  					}
  				});

          // country : "",
          // language : "en",
          // mapview : "",          
          // beginHighlight : '<mark>',
          // endHighlight : '</mark>',
          // beginHighlight    : '<b>',
          // endHighlight      : '</b>',
  
  				$.when( getReady(), this.geocoderRequest).done( function( readyResponse, geocoderResponse, placesResponse ) {
              // console.log("when.getReady geocoderResponse",geocoderResponse);    
              this.cantAutocomplete++;

              self.apis.LogApiFuncion({
                eventoQueDisparo : '<here-map-autocomplete>.when(getReady()) - Obtención de sugerencias por Autocomplete',
                apiFuncionKey    : 'HereAutocomplete', 
                organizacionKNAI : self.organizacionKNAI,
                usuarioKANE      : self.usuarioKANE,
                nombreColeccion  : self.nombreColeccion,
                cloudFunction    : null,
                cantidad         : 1, 
              });
                    
    					var g = $.map(geocoderResponse[0].suggestions, function (item) {
                    var label = item.label;
    								// replace style class used for highlight
    								let value = label.replace(/(<b>|<\/b>)/gm, ''); 
    								// let value = label.replace(/(<mark>|<\/mark>)/gm, ''); 
                    
                    // item: {label: " Leandro N. <b>Alem</b> <b>1385</b>, <b>Lincoln</b>,  <b>Lincoln</b>, Argentina", 
                    //        value: "Argentina, Lincoln, Lincoln, Leandro N. Alem 1385"}
                    // label = value;
                    // label = label.replace(' <b>','<b>'); 
                    // label = label.replace(' </b>','</b>'); 
                    
                    label = label.replace('  ',' '); 
                    let opciones=label.split(',');
                    
                    label = '';
                    for(let i=opciones.length-1;i>=0; i--) {
                      label+=','+opciones[i];
                    }
                    label=label.substr(1,1000);
                    label = label.replace('  ',' '); 
                    // console.log("label",label);
    								return {
    									label : label,
    									value : value 
    								}
    							});
            
              console.log("when.getReady items formateados select",g);    
  					  response(g);
  				});
			}, 
			minLength : 2,
			select : function (event, ui) {
          //console.log("select html input",event);        
          console.log("select opcion seleccionada ui",ui);        
          let valorSeleccionado='';  
          if(ui.item!==undefined && ui.item!==null && ui.item.value!==undefined) {
              let opciones=ui.item.value.split(',');  
              for(let i=opciones.length-1;i>=0; i--) {
                valorSeleccionado+=', '+opciones[i];
              }
              valorSeleccionado=valorSeleccionado.substr(2,1000);
              valorSeleccionado = valorSeleccionado.replace('  ',' '); 
          }
          console.log("select opcion seleccionada value",valorSeleccionado);        
          event.preventDefault();
          $("#search"+self.dif).val(valorSeleccionado);
  				if (ui.item) {
  					geocode(valorSeleccionado, 10);
  				}
  				return true;
			},
			
		});

		// If enter pressed without any selection, 
		// geocode the provided text
		$("#search"+this.dif).keypress(function (e) {
			if (e.which == 13) {
				$(".ui-menu-item").hide();
				geocode($("#search"+this.dif).val(), 10);
			}
		});
		
		// Gecode the provided text
		function geocode(addr, maxResults) {
      // console.log("geocode",addr);
      
			let geocodingParameters = {
				searchText : addr,
				maxresults : maxResults
			};

			geocoder.geocode( geocodingParameters, onSuccess, onError	);
		}
		
		// callback when geocoder request is completed
		function onSuccess(result) {
      console.log("onSuccess",result);
			var position, mapview, topLeft,	bottomRight;

			if (group) {
				// self.map.removeObject(group);
			}

			group = new H.map.Group();
      
      // console.log("result",result);
      if(result.Response.View.length==0) {
        return;
      }
      
      var locations = result.Response.View[0].Result;
      // console.log( "locations",locations);
      
      if(locations.length>0) {
          matcheaResultado(self._http, locations[0].Location);
      } else {
          matcheaResultado(self._http, null);
      }    

      // if (locations.length>0) {
      //     let location=locations[0];
      //     let topLeft = location.Location.MapView.TopLeft;
      //     let bottomRight = location.Location.MapView.BottomRight;
      
      //     var bbox = new H.geo.Rect(topLeft.Latitude, topLeft.Longitude, bottomRight.Latitude, bottomRight.Longitude);
      //     self.map.getViewModel().setLookAtData({
      //       bounds: bbox
      //     });
      // }
        
			// Add a marker for each location found
			for (let i=0; i< locations.length; i++) {
  				position = {
  					lat : locations[i].Location.DisplayPosition.Latitude,
  					lng : locations[i].Location.DisplayPosition.Longitude
  				};
  				topLeft = locations[0].Location.MapView.TopLeft;
  				bottomRight = locations[0].Location.MapView.BottomRight;
  				mapview = new H.geo.Rect(topLeft.Latitude, topLeft.Longitude, bottomRight.Latitude, bottomRight.Longitude);
  				let marker = new H.map.Marker(position);
  				marker.label = i;
  				group.addObject(marker);
      }
      
      // Add the locations group to the map
      // console.log('jj map', self.map)

      let arrayObj=self.map.getObjects();
      for (let index = 0; index < arrayObj.length; index++) {
        const element = arrayObj[index];
        self.map.removeObject(element);  
        
      } 
      
      self.map.addObject(group);
      
      if (locations.length>0) {
          let location=locations[0];
          topLeft = location.Location.MapView.TopLeft;
          bottomRight = location.Location.MapView.BottomRight;
      
          var bbox = new H.geo.Rect(topLeft.Latitude, topLeft.Longitude, bottomRight.Latitude, bottomRight.Longitude);
          self.map.getViewModel().setLookAtData({
            bounds: bbox
          });
      }    
      
      // console.log("self.map",self.map);
			// if(group.getChildCount() > 1) {
			// 	self.map.setViewBounds(group.getBounds());
			// } else {
			// 	self.map.setViewBounds(mapview);
      // }
    }

    function matcheaResultado(httpService, place) {
      
        // console.log("matcheaResultado place",place);
        if (place==null ||
            place.Address === undefined || place.DisplayPosition === undefined || 
            place.Address === null || place.DisplayPosition === null) {
          return;
        }
        
        self.apis.LogApiFuncion({
          eventoQueDisparo : '<here-map-autocomplete>.matcheaResultado() - Obtención de la dirección previsualizada por Autocomplete',
          apiFuncionKey    : 'HerePlaces', 
          organizacionKNAI : self.organizacionKNAI,
          usuarioKANE      : self.usuarioKANE,
          nombreColeccion  : self.nombreColeccion,
          cloudFunction    : null,
          cantidad         : 1, 
        });

        // Hay que analizar si la actualización de la dirección en el mapa que ya se está visualizando cuenta como un nuevo View del mapa        
        self.apis.LogApiFuncion({
          eventoQueDisparo : '<here-map-autocomplete>matcheaResultado() - Refrescar Mapa con la dirección Geolocalizada',
          apiFuncionKey    : 'HereMapsView', 
          organizacionKNAI : self.organizacionKNAI,
          usuarioKANE      : self.usuarioKANE,
          nombreColeccion  : self.nombreColeccion,
          cloudFunction    : null,
          cantidad         : 1, 
        });

        //set latitud, longitud 
        self.ubicacion.geoPoint.latitud  = place.DisplayPosition.Latitude;
        self.ubicacion.geoPoint.longitud = place.DisplayPosition.Longitude;
        // console.log("self.ubicacion.geoPoint",self.ubicacion.geoPoint);
        //self.asignarGeoPoint(self.ubicacion);

        // set calle, numero, codigo postal, ciudad/barrio, partido/comuna, provinca, pais y region geográfica
        let pais             = (place.Address.Country     !==undefined) ? place.Address.Country     : null;
        let provincia        = (place.Address.State       !==undefined) ? place.Address.State       : null;
        let ciudad           = (place.Address.City        !==undefined) ? place.Address.City        : null;
        let partido          = (place.Address.County      !==undefined) ? place.Address.County      : null;
        let distrito         = (place.Address.District    !==undefined) ? place.Address.District    : null;
        let calle            = (place.Address.Street      !==undefined) ? place.Address.Street      : null;
        let numero           = (place.Address.HouseNumber !==undefined) ? place.Address.HouseNumber : null;
        let codigo_postal    = (place.Address.PostalCode  !==undefined) ? place.Address.PostalCode  : null;
        
        // Codificación de Paises Here
        // https://developer.here.com/documentation/geocoder/dev_guide/topics/coverage-geocoder.html        
        if(pais=='ARG') pais='Argentina';
        if(pais=='BRA') pais='Brasil';
        if(pais=='GBR') pais='Inglaterra';        
        if(pais=='BOL') pais='Bolivia';
        if(pais=='CHL') pais='Chile';
        if(pais=='COL') pais='Colombia';
        if(pais=='ECU') pais='Ecuador';
        if(pais=='FRA') pais='Francia';
        if(pais=='MEX') pais='México';
        if(pais=='PRY') pais='Paraguay';
        if(pais=='PER') pais='Perú';
        if(pais=='ESP') pais='España';
        if(pais=='URY') pais='Uruguay';
        if(pais=='USA') pais='Estados Unidos';
        if(pais=='VEN') pais='Venezuela';

        // Determio Calle, Nro y Código Postal
        self.ubicacion.calle        = calle;
        self.ubicacion.numero       = numero;
        self.ubicacion.codigoPostal = codigo_postal;  // a veces no viene

        // Quito letras al Código Postal
        if(self.ubicacion.codigoPostal!='' && self.ubicacion.codigoPostal!=null) {
            self.ubicacion.codigoPostal= self.ubicacion.codigoPostal.replace(/[^0-9\\.]+/g, '');
        } else {
            // console.log("no se obtuvo codigo postal");                    
        }    
        
        // Determino País 
        self.ubicacion.paisKN      = self.fn.getAtributoFromListado( PAISES, pais, 'nombre', 'KN');

        // Determino Configuración Regional
        if(self.fn.mostrarKN(self.ubicacion.paisKN,'nombre')=='Argentina') {
          self.ubicacion.timeZone='America/Argentina/Buenos_Aires';
          
        } else if(self.fn.mostrarKN(self.ubicacion.paisKN,'nombre')=='Brasil') {
          self.ubicacion.timeZone='America/Sao_Paulo';
          
        } else if(self.fn.mostrarKN(self.ubicacion.paisKN,'nombre')=='Chile') {
          self.ubicacion.timeZone='America/Santiago';
          
        } else if(self.fn.mostrarKN(self.ubicacion.paisKN,'nombre')=='Bolivia') {
          self.ubicacion.timeZone='America/La_Paz';
          
        } else if(self.fn.mostrarKN(self.ubicacion.paisKN,'nombre')=='Uruguay') {
          self.ubicacion.timeZone='America/Montevideo';

        } else if(self.fn.mostrarKN(self.ubicacion.paisKN,'nombre')=='Paraguay') {
          self.ubicacion.timeZone='America/Asuncion';
          
        }  
        
        // Determino Provincia
        if(provincia=="Ciudad Autónoma de Buenos Aires") provincia="CABA";
        self.ubicacion.provinciaKN = self.fn.getAtributoFromListado( self.msg.cacheColecciones['AuxProvincias'], provincia, 'nombre', 'KN');
        if(self.ubicacion.provinciaKN==null && self.ubicacion.paisKN) {
            let codigoProvincia = self.ubicacion.paisKN.key+'-'+provincia;
            self.ubicacion.provinciaKN = self.fn.getAtributoFromListado( self.msg.cacheColecciones['AuxProvincias'], codigoProvincia, 'key', 'KN');
        }

        // Determino Partido y Localidad
        if(provincia!='CABA') {
          
            self.ubicacion.ciudad        = ciudad;    // Roberts
            self.ubicacion.partido       = (distrito) ? distrito : partido;  // Lincoln        

            // console.log("self.ubicacion",self.ubicacion);
            self.escribioDireccion.emit(self.ubicacion);
                
        } else { // Capital Federal
                    
            // self.ubicacion.ciudad      = distrito;  // Barrio (Ej. Palermo)
            // self.ubicacion.partido     = null;      // Comunica, calcula con el de la ciudad
            
            let partido_comuna_buscar   = 'CIUDAD DE BS AS';
            let localidad_barrio_buscar = distrito;

            // Obtengo Barrio y Comuna de usig.buenosaires.gob.ar
            var urlParams = {
                'x' : place.DisplayPosition.Latitude,
                'y' : place.DisplayPosition.Longitude
            };
            //console.log("ejecuto http://ws.usig.buenosaires.gob.ar/datos_utiles");

            // httpService.get({
            //   url: "http://ws.usig.buenosaires.gob.ar/datos_utiles",
            //   method: "GET",
            //   params: urlParams
          
            let url="https://ws.usig.buenosaires.gob.ar/datos_utiles?x="+urlParams.y+"&y="+urlParams.x;
            // console.log("url",url);
            httpService.get(url).toPromise().then(function success(response) {
              
                // console.log("http://ws.usig.buenosaires.gob.ar/datos_utiles Success - response: ",response);

                self.ubicacion.ciudad         = response.barrio;  // Palermo
                self.ubicacion.partido        = response.comuna;  // Comuna 14                
                if(!self.ubicacion.codigoPostal) {
                    self.ubicacion.codigoPostal   = codigo_postal;  // a veces no viene  
                }
                
                // Quito letras al Código Postal
                if(self.ubicacion.codigoPostal!='' && self.ubicacion.codigoPostal!=null) {
                    self.ubicacion.codigoPostal= self.ubicacion.codigoPostal.replace(/[^0-9\\.]+/g, '');
                } else {
                    // console.log("no se obtuvo codigo postal");                    
                }    
                
                // console.log("self.ubicacion",self.ubicacion);
                self.escribioDireccion.emit(self.ubicacion);
                
            }, function error(response) {
                console.log("http://ws.usig.buenosaires.gob.ar/datos_utiles Error:",response);
            });

        }
      
    }
  
    
		function onError(error) {
      console.log("error",error);
			alert('Ooops!');
		}    
  }


  ngDoCheck() {
      // if(!this.geoPointFormulario){
      //     this.setCurrentPosition(); 
      // } else{
      //   this.ubicacion.geoPoint.latitud = this.geoPointFormulario.latitud;
      //   this.ubicacion.geoPoint.longitud =  this.geoPointFormulario.longitud;  
      // }

      if(this.accionForm=='consultar') {
        this.form.disable();
      } else {
        this.form.enable();
      }         
  }
  
  cargaVariablesTraducibles(){
      this.placeHolderAutocomplete = this.translate.instant('claseDireccion.placeHolderAutocomplete');
  }    

  ngOnInit() {

    // console.log("ngOnInit")

    this.translate.onLangChange.subscribe(() => {
        // console.log('langChange');
        this.cargaVariablesTraducibles();
    });

    this.form = this.fb.group({
        inputAutoComplete:null
    });
    
    // Colecciones Auxiliares
    this.configListadosCache=[];    

    this.configListadosCache.push({ 
        nombreListado   : 'listadoAuxProvincias',
        nombreColeccion : 'AuxProvincias',
        orderBy         : [{key:'nombre',ascDesc:'asc'}]      
    });     

    this.configListadosCache.push({ 
        nombreListado   : 'listadoAuxTimeZones',
        nombreColeccion : 'AuxTimeZones',
        orderBy         : [{key:'nombre',ascDesc:'asc'}]          
    });     
  
    this.getSubscripcionSecundarias();
  
  }
  
  private setCurrentPosition() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.ubicacion.geoPoint.latitud = position.coords.latitude;
        this.ubicacion.geoPoint.longitud = position.coords.longitude;
      });
    }
  }

  private asignarGeoPoint(ubicacion) {
    // console.log("asignarGeoPoint ubicacion",ubicacion);
    this.ubicacion.geoPoint.latitud = ubicacion.geoPoint.latitud;
    this.ubicacion.geoPoint.longitud =  ubicacion.geoPoint.longitud; 
    this.changeDetectorRef.detectChanges();
  }
  
  getSubscripcionSecundarias() {

      log(...values('funcionComponente','getSubscripcionSecundarias'));

      /* Subscripción a Colecciones Secundarias */
      this.cantidadSubscripcionesSecundariasLanzadas=0;
      this.finalizoGETSecundarias=false;              
      
      this.cantidadSubscripcionesSecundariasLanzadas=this.configListadosCache.length;
      
      for(let i=0; i<this.configListadosCache.length; i++) {
          
          this.subscripcionCache.push( 
              this.msg.getListadoCache({

                    nombreListado                       : this.configListadosCache[i].nombreListado,
                    nombreColeccion                     : this.configListadosCache[i].nombreColeccion,
                    where                               : [],
                    orderBy                             : this.configListadosCache[i].orderBy,
                    limit                               : null,
                    grabaLocalStorage                   : true,
                    ignoraValoresMemoria                : false,
                    datosPorOrganizacion                : false,
                    organizacionKNAI                    : this.organizacionKNAI,
                    usuarioKANE                         : this.usuarioKANE,
                    nombreColeccionSolicitante          : this.configListadosCache[i].nombreColeccion,
                    limpiaSettingUsuarioOrganizacion    : false,
 
              }).pipe(take(1)).subscribe(data=>{
                  //console.log("data",data);

                  log(...values("valores",
                            "msg.cacheColecciones[" + this.configListadosCache[i].nombreColeccion + "]:", 
                            this.msg.cacheColecciones[this.configListadosCache[i].nombreColeccion]));
                  
                  // logTable(...values( this.msg.cacheColecciones[configListadosCache[i]],configListadosCache[i].nombreListado) );              
                  
                  this.cantidadSubscripcionesSecundariasLanzadas--;
                  
                  // console.log("cantidadSubscripcionesSecundariasLanzadas",this.cantidadSubscripcionesSecundariasLanzadas);
                  if(this.cantidadSubscripcionesSecundariasLanzadas==0) {  // Devolvió resultados la última subscripción
                        this.finalizoGETSecundarias=true;              
                  }

              },(error:any)=>{
                  log(...values("error",error));
              }) 
          );    
      }
  }
  

}


