<div class="row px-3">
     <div class="col-12">                
    
        <button *ngIf="accionForm=='consultar' && (permisos.modificacionesAutorizadas || permisos.modificacionesBasicasAutorizadas)" 
                class="btn btn-primary" style="float:right;"
                (click)="setAccionForm('modificar')">
                <span >{{traduccion.navegabilidad.editar}}</span> 
        </button>

        <button *ngIf="(accionForm=='agregar' && permisos.altasAutorizadas) || 
                       (accionForm=='modificar' && (permisos.modificacionesAutorizadas || permisos.modificacionesBasicasAutorizadas))" 
                type="button" class="btn btn-danger" style="float:right;"
                (click)="(accionForm=='agregar') ? setAccionForm('listado') : setAccionForm('consultar')"
                >{{traduccion.navegabilidad.cancelar}}</button>
              
        <button *ngIf="accionForm=='agregar' && permisos.altasAutorizadas"
                class="btn btn-success" style="float:right; margin-right:10px;"
                type="button" (click)="componentSubmit()">
                <span >{{traduccion.navegabilidad.agregar}}</span>
        </button>

        <button *ngIf="accionForm=='modificar' && (permisos.modificacionesAutorizadas || permisos.modificacionesBasicasAutorizadas) 
                       && permisos.deshabilitarGrabar==false" 
                class="btn btn-success" style="float:right; margin-right:10px;"
                type="button" (click)="componentSubmit()">
                <span >{{traduccion.navegabilidad.grabar}}</span>
        </button>

        <button *ngIf="accionForm=='modificar' && (permisos.modificacionesAutorizadas || permisos.modificacionesBasicasAutorizadas) 
                       && permisos.deshabilitarGrabar==true" 
                class="btn btn-secondary" style="float:right; margin-right:10px;"
                type="button">
                <span >{{traduccion.navegabilidad.grabar}}</span>
        </button>

    </div>
</div>
