import { Injectable } from '@angular/core';
import { log, logIf, logTable, values } from '@maq-console';

// import firebase from 'firebase/app';
// import 'firebase/firestore';
import * as firebase from 'firebase';

import { AngularFirestore  } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { Query, QueryFn } from '@angular/fire/firestore';

import { FuncionesService }  from '@maq-funciones';
import { ApisService }       from '@maq-apis';
import { AppSettingsService } from '@settings/app.settings';
import { getBDModel, updateColeccionModel, postGETProcesaListadoModel, updateDocumentoModel,updateColeccionDataBatch,updateColeccionUserKeysBatch } from '@maq-models/bd/bd.model';
import { getBDModel2, getBDSQL, updateColeccion2Model } from '@maq-models/bd/bd.model';
import { WhereInterface ,OPERADOR_WHERE ,OrdeByInterface,OPERADOR_ORDEN_BY} from '@maq-models/bd/bdDefinicionesGenerales.model';
import { KANE,KNAI,KN} from '@maq-models/typesKN/typesKN.model';

import { take, first } from 'rxjs/operators';

import { Observable, of ,Subject} from 'rxjs';
import { HttpClient } from '@angular/common/http';

// creo una variable local para pasar el servicio a una libreria.
import { environment } from '../../../../environments/environment';
import {differenceInCalendarQuarters} from 'date-fns';



@Injectable({
  providedIn: 'root'
})
export class BDService { 

  public environment:any={};



  URL_SERVER:string='httpDelServidorDondeEstaLaBase'; // direccion donde está la base de datos

  private uploadImagen:any=null;
  public task: AngularFireUploadTask;
  public percentageUploadImagen:number=null;
  public downloadURL: string=null;
  public bytesUpload: number=0;
  public cantidadTransaccionesLongFiles : Subject<number> = new Subject<number>();
  public procesadasTransaccionesLongFiles : Subject<number> = new Subject<number>();
  public comentarioTransaccionesLongFiles : Subject<string> = new Subject<string>();

  constructor(public fn:FuncionesService,
              public apis:ApisService,
              public settingService:AppSettingsService,
              public af: AngularFirestore,
              private storage: AngularFireStorage,
              private _http: HttpClient
   ) {
    this.environment=environment;
    // this.environment['tipoServidor']={};
    this.environment['tipoServidor']['negocio']="firestore";
    

  }





  // ********************************************************************************************
  // ********************************************************************************************

    seteaDocumentoParaGrabar(documento:any, usuario:any):any {
          //log(...values('funcionGo','bdService.seteaDocumentoParaGrabar'));
          //log(...values("valores","recibió input documento:",documento, "usuario:", usuario));

          /* Me Aseguro que la estructura settings, exista y con todos los campos */
          if(documento.settings===undefined) documento.settings = {};
          if(documento.settings.isActivo===undefined || documento.settings.isActivo==null) {
              documento.settings.isActivo=true;
          }
          if(documento.settings.isBorrado===undefined || documento.settings.isBorrado==null) {
              documento.settings.isBorrado=false;
          }
          if(documento.settings.borradoFisico===undefined || documento.settings.borradoFisico==null) {
              documento.settings.borradoFisico=false;
          }

          if(documento.settings.triggersIgnorar===undefined || documento.settings.triggersIgnorar==null) {
              documento.settings.triggersIgnorar=false;
          }
          if(documento.settings.triggersProcesarTarde===undefined || documento.settings.triggersProcesarTarde==null) {
              documento.settings.triggersProcesarTarde=false;
          }
          if(documento.settings.sizeOfDocumento===undefined || documento.settings.sizeOfDocumento==null) {
              documento.settings.sizeOfDocumento=0;
          }
          if(documento.settings.documentosAsociados===undefined || documento.settings.documentosAsociados==null) {
              documento.settings.documentosAsociados=0;
          }
          documento.settings.usuarioKANE = this.fn.setearKANE(usuario);

          /* Seteo el campo organizacionKN en caso de que la tabla lo utilice */
          // if(documento.organizacionKNAI==null && usuario.organizacion) {
          //     documento.organizacionKNAI = this.fn.setearOrganizacionKNAI(usuario.organizacion);
          // }

          /* Seteo el type de los campos de Goelocalización */
          if(documento.direccion!==undefined && documento.direccion.geoPoint!==undefined) {
            documento.direccion.geoPoint = this.fn.setearGeoPoint(documento.direccion);
          }

          return documento;
    }

    getStorageURLDownload(path:string): Observable<any> {
        const ref = this.storage.ref(path);
        return ref.getDownloadURL();
    }        
    
  // https://firebase.google.com/docs/storage/web/upload-files?hl=es-419
  uploadFirestoreImage(fieldImage:string, fieldLink:string, path:string, image:any): Promise<any> {
      return new Promise( (resolve,reject)=>{

          let bytes=null;
          let downloadURL=null;

          //log(...values('funcionGoPromesa','bdService.uploadFirestoreImage: '+fieldLink));

          const ref = this.storage.ref(path);
          let task: AngularFireUploadTask;

          // var metadata = "base64";
          // var metadata = "base64url";
          var metadata = "data_url";
          task =this.storage.ref(path).putString(image, metadata);

          task.then(async function(snapshot) {

                //log(...values('funcionEnd','bdService.uploadFirestoreImage'));

                // //log(...values("valores","snapshot:", snapshot));

                bytes = snapshot.totalBytes

                // //log(...values('valores','fieldLink: '+fieldLink));
                // //log(...values('valores','path: '+path));
                // //log(...values("valores","byes:", bytes));
                //log(...values("imagen","image:", image));

                downloadURL = await ref.getDownloadURL().toPromise();

                //log(...values("link","downloadURL:", downloadURL));

                resolve({
                    fieldImage  : fieldImage,
                    fieldLink   : fieldLink,
                    downloadURL : downloadURL,
                    bytes       :  bytes
                });

          // }).catch(error=> {
          }).catch(function(error) {
                //log(...values("error","error Upload",error));

                reject(error);
          });

      });
  }


  updateColeccion(argumentos:updateColeccionModel): Promise<any> {
     /*
      * Recibe
      * argumentos {
      *     operacion        : string,
      *     nombreColeccion  : any,
      *     documento        : any,
      *     distribuidorKN   : KNAI,
      *     organizacionKNAI : KNAI,
      *     usuarioKANE      : KANE,
      * }
      */

      //log(...values('funcionGoPromesa','bdService.updateColeccion: '+argumentos.nombreColeccion));

      //log(...values("valores","argumentos: ",argumentos));
      
      let operacion        = argumentos.operacion;
      let nombreColeccion  = argumentos.nombreColeccion;
      let documento        = argumentos.documento;
      let distribuidorKN   = argumentos.distribuidorKN;
      let organizacionKNAI = argumentos.organizacionKNAI;
      let usuarioKANE      = argumentos.usuarioKANE;

      if(documento.settings===undefined) {
        documento.settings={};
      }
      
      if(operacion=='agregar') {
         if(documento.key==null) documento.key=this.af.createId();
         documento.settings.fechaHoraCreacion=this.timestamp();


         if(documento.autoIncrement!==undefined) {
           documento.autoIncrement=null;
         }

         documento.settings.isActivo            = true;
         documento.settings.isBorrado           = false;
         documento.settings.borradoFisico       = false;
         documento.settings.sizeOfDocumento     = 0;
         documento.settings.documentosAsociados = 0;

      } else if(operacion=="modificar") {
          delete documento.settings.documentosAsociados;

      } else if(operacion=="borradoLogico") {
          documento.settings.isBorrado=true;
          documento.settings.borradoFisico=false;

      } else if(operacion=="borradoFisico") {
          documento.settings.isBorrado=true;
          documento.settings.borradoFisico=true;
      }
      
      if(typeof documento.key==='number') {
          documento.key = documento.key.toString();
      }

      documento.settings.triggersIgnorar       = false;
      documento.settings.triggersProcesarTarde = false;
       
      if(nombreColeccion=='ProductosPrecios' || nombreColeccion=='ProductosClientes' ){
        documento.settings.triggersIgnorar       = false;
        documento.settings.triggersProcesarTarde = true;
      } 
    
      documento.settings.sizeOfDocumento = this.fn.sizeOfFirestoreDocument(nombreColeccion, documento);

      documento.settings.usuarioKANE = usuarioKANE;
      documento.settings.fechaHoraModificacion=this.timestamp();

      // console.log('updateColeccion operacion',operacion);
      // console.log('updateColeccion nombreColeccion',nombreColeccion);
      // console.log('updateColeccion documento',documento);

      // Promise.resolve('xxx');

      // let refColeccion = firebase.firestore().collection(nombreColeccion);

      return new Promise((resolve, reject) => {
        if(this.settingService.settings2.getIsOnLine()) {
            this.af.doc(nombreColeccion+'/'+documento.key).set(documento,{ merge: true })
                .then((respuesta) => {
                    // console.log("respuesta",respuesta);

                    this.apis.LogApiFuncion({
                         eventoQueDisparo : 'bdService.updateColeccion operacion:'+operacion,
                         apiFuncionKey    : 'FirestoreDocumentWrite',
                         organizacionKNAI : organizacionKNAI,
                         usuarioKANE      : usuarioKANE,
                         nombreColeccion  : nombreColeccion,
                         cloudFunction    : null,
                         cantidad         : 1,
                    });

                    if(operacion=="borradoFisico") {
                        this.apis.LogApiFuncion({
                             eventoQueDisparo : 'bdService.updateColeccion operacion:'+operacion,
                             apiFuncionKey    : 'FirestoreDocumentDelete',
                             organizacionKNAI : organizacionKNAI,
                             usuarioKANE      : usuarioKANE,
                             nombreColeccion  : nombreColeccion,
                             cloudFunction    : null,
                             cantidad         : 1,
                        });
                    }

                    if(operacion=="agregar" || operacion=="modificar") {
                      resolve(documento.key+'|mensajes.grabacionOk');
                    } else if(operacion=="borradoLogico") {
                      resolve('mensajes.eliminacionOk');
                    } else if(operacion=="borradoFisico") {
                      resolve('mensajes.eliminacionFisicaOk');
                    }
                })
                .catch((error)=>{
                   //log(...values("error","error",error));
                   reject(error);
                 });
          } else {
              this.af.doc(nombreColeccion+'/'+documento.key).set(documento,{ merge: true });
              // refColeccion.doc(documento.key).set(documento);
              resolve('mensajes.operacionOffLine');
          }
      });
  }


  updateColeccion2(argumentos:updateColeccion2Model): Promise<any> {
    /*
     * Recibe
     * argumentos {
     * operacion        : string = null;  
     * nombreColeccion  : string = null; 
     * campoClave       : string = null; //campos claves para el update separados por puntos cuando son varios.
     * documento        : any    = null;
     * distribuidorKN   : KN     = null;
     * organizacionKNAI : KNAI   = null;                             
     * usuarioKANE      : KANE   = null;
     * }
     */

     //log(...values('funcionGoPromesa','bdService.updateColeccion2: '+argumentos.nombreColeccion));

     //log(...values("valores","argumentos: ",argumentos));
     
     let operacion:string           = argumentos.operacion;
     let campoClaveArray :string[]  =argumentos.campoClave.split('.');       
       
     let nombreColeccion:string     = argumentos.nombreColeccion;
     let documento :any             = argumentos.documento;
     let distribuidorKN:KN          = argumentos.distribuidorKN;
     let organizacionKNAI:KNAI      = argumentos.organizacionKNAI;
     let usuarioKANE:KANE           = argumentos.usuarioKANE;
     let usaSettings:boolean        = argumentos.usaSettings;

     if(usaSettings && documento.settings===undefined) {
       documento.settings={};
     }
     
     if(operacion=='agregar' ) {
        if(documento.key==null) documento.key=this.af.createId();
        


        if(documento.autoIncrement!==undefined) {
          documento.autoIncrement=null;
        }

        if(usaSettings){
            documento.settings.fechaHoraCreacion=this.timestamp();
            documento.settings.isActivo            = true;
            documento.settings.isBorrado           = false;
            documento.settings.borradoFisico       = false;
            documento.settings.sizeOfDocumento     = 0;
            documento.settings.documentosAsociados = 0;
        }    

     } else if(usaSettings && operacion=="modificar") {
        //  delete documento.settings.documentosAsociados;

     } else if(usaSettings &&  operacion=="borradoLogico") {
         documento.settings.isBorrado=true;
         documento.settings.borradoFisico=false;

     } else if(usaSettings &&  operacion=="borradoFisico") {
         documento.settings.isBorrado=true;
         documento.settings.borradoFisico=true;
     }
     
     if(typeof documento.key==='number') {
         documento.key = documento.key.toString();
     }

     if(usaSettings ){
        documento.settings.triggersIgnorar       = false;
        documento.settings.triggersProcesarTarde = false;
        
        
        documento.settings.sizeOfDocumento = this.fn.sizeOfFirestoreDocument(nombreColeccion, documento);

        documento.settings.usuarioKANE = usuarioKANE;
        documento.settings.fechaHoraModificacion=this.timestamp();

     }   

    if(this.environment.tipoServidor.baseDatos=="firestore") {

     return new Promise((resolve, reject) => {
        if(this.settingService.settings2.getIsOnLine()) {
           this.af.doc(nombreColeccion+'/'+documento.key).set(documento,{ merge: true })
               .then((respuesta) => {
                   // console.log("respuesta",respuesta);

                   this.apis.LogApiFuncion({
                        eventoQueDisparo : 'bdService.updateColeccion operacion:'+operacion,
                        apiFuncionKey    : 'FirestoreDocumentWrite',
                        organizacionKNAI : organizacionKNAI,
                        usuarioKANE      : usuarioKANE,
                        nombreColeccion  : nombreColeccion,
                        cloudFunction    : null,
                        cantidad         : 1,
                   });

                   if(operacion=="borradoFisico") {
                       this.apis.LogApiFuncion({
                            eventoQueDisparo : 'bdService.updateColeccion operacion:'+operacion,
                            apiFuncionKey    : 'FirestoreDocumentDelete',
                            organizacionKNAI : organizacionKNAI,
                            usuarioKANE      : usuarioKANE,
                            nombreColeccion  : nombreColeccion,
                            cloudFunction    : null,
                            cantidad         : 1,
                       });
                   }

                   if(operacion=="agregar" || operacion=="modificar") {
                     resolve(documento.key+'|mensajes.grabacionOk');
                   } else if(operacion=="borradoLogico") {
                     resolve('mensajes.eliminacionOk');
                   } else if(operacion=="borradoFisico") {
                     resolve('mensajes.eliminacionFisicaOk');
                   }
               })
               .catch((error)=>{
                  //log(...values("error","error",error));
                  reject(error);
                });
         } else {
             this.af.doc(nombreColeccion+'/'+documento.key).set(documento,{ merge: true });
             // refColeccion.doc(documento.key).set(documento);
             resolve('mensajes.operacionOffLine');
         }
     });
    }
        else {
            return new Promise((resolve, reject) => {

                let url:string='';
                url=this.environment.serviciosExternos.sql.apiURL+'api/'+nombreColeccion+'/';   

                documento.settings=JSON.stringify(documento.settings);
                delete documento.key;                         

                    
                if(operacion=='agregar') {

                    // elimino campos claves que se generan automáticamente
                        delete documento[campoClaveArray[0]];
                    
                    this._http.post(url,documento).subscribe((data:any)=> {
                            console.log('data agregar',data);
                            resolve( data );
                        },(error:any)=>{
                            console.log('nombreColeccion error',error);
                            reject('no pudo agregar a Colección '+nombreColeccion +": "+ error);
                            
                    });
     

                } else if(operacion=="modificar" || operacion=="borradoLogico") {

                    url=url+documento[campoClaveArray[0]];
                    this._http.put(url,documento).subscribe((data:any)=> {
                        console.log('data modificar borradoLogico',data);
                        resolve( data );
                    },(error:any)=>{
                        console.log('error modificar borradoLogico',error);
                        reject('no pudo agregar a Colección '+nombreColeccion);
                    });
     
                } else if(operacion=="borradoFisico") {

                    url=url+documento[campoClaveArray[0]];
                    this._http.delete(url).subscribe((data:any)=> {
                        console.log('data borradoFisico',data);
                        resolve( data );
                    },(error:any)=>{
                        console.log('error borradoFisico',error);
                        reject('no pudo borrar a Colección '+nombreColeccion);
                    });
     
                }

            });
    } 
     
 }

    //  Borra todos los registros de una tabla
    deleteColeccion2(nombreTabla: string) {

        return new Promise((resolve, reject) => {

            let url: string = '';
            url = this.environment.serviciosExternos.sql.apiURL + 'api/' + nombreTabla + '/ALL';
            this._http.delete(url).subscribe((data: any) => {
                console.log('data borradoFisico', data);
                resolve(data);
            }, (error: any) => {
                console.log('error borradoFisico', error);
                reject('no pudo borrar a Colección ' + nombreTabla);
            })
        })

    } 

  async updateColeccionBatch(userKeys:updateColeccionUserKeysBatch,datos:[updateColeccionDataBatch]): Promise<any> {
    /*
     * Recibe
     *  userKeys{
     *     distribuidorKN   : KNAI,
     *     organizacionKNAI : KNAI,
     *     usuarioKANE      : KANE,
     *  }
     * 
     *  datos {
     *     operacion        : string,
     *     nombreColeccion  : any,
     *     documento        : any,
     *     incluyeSettings  : boolean     
     *  }
     */
    
    //log(...values("valores","userKeys: "   ,userKeys));
    log(...values("valores","datos[]: "      ,datos));

    console.log("updateColeccionBatch datos ",datos);


    let distribuidorKN   = userKeys.distribuidorKN;
    let organizacionKNAI = userKeys.organizacionKNAI;
    let usuarioKANE      = userKeys.usuarioKANE;

    let numeroTransacciones=0;
    let numeroTransaccionesMaximas=240;
    let vectorPromesas=[];
    let batch:any=[];
    let indexBatch:number=0;

    let tamanioMaximoInbytes = 1000000; //1000000

    
    let tamanioInBytOneDocument =0;
    let tamanioInBytesDocuments =0;
    let tamnioInBtyesAllDocuments = 0;
    
    // sizeOfFirestoreDocument
    const delayBetweenBatches = 2000; // 2 segundo de retardo entre lotes

    
    batch[0] = firebase.firestore().batch();
    for (let index = 0; index < datos.length; index++) {

        const element = datos[index];
             
        let operacion        = element.operacion;
        let nombreColeccion  = element.nombreColeccion;
        let documento        = element.documento;
        let incluyeSettings  = element.incluyeSettings;
      

        if(incluyeSettings){

            if(documento.settings===undefined) {
                documento.settings={};
            }
            
            if(operacion=='agregar') {
                if(documento.key==null) documento.key=this.af.createId();
                documento.settings.fechaHoraCreacion=this.timestamp();


                if(documento.autoIncrement!==undefined) {
                    documento.autoIncrement=null;
                }

                documento.settings.isActivo            = true;
                documento.settings.isBorrado           = false;
                documento.settings.borradoFisico       = false;
                documento.settings.sizeOfDocumento     = 0;
                documento.settings.documentosAsociados = 0;

            } else if(operacion=="modificar") {
                delete documento.settings.documentosAsociados;

            } else if(operacion=="borradoLogico") {
                documento.settings.isBorrado=true;
                documento.settings.borradoFisico=false;

            } else if(operacion=="borradoFisico") {
                documento.settings.isBorrado=true;
                documento.settings.borradoFisico=true;

            } else if(operacion=="importar") {                
                
                if(documento.key==null) documento.key=this.af.createId();
                
                documento.settings = {
                    fechaHoraCreacion       : this.timestamp(),
                    fechaHoraModificacion   : this.timestamp(),
                    usuarioKANE             : usuarioKANE,
                    isActivo                : true,
                    isBorrado               : false,
                    borradoFisico           : false,
                    sizeOfDocumento         : this.fn.sizeOfFirestoreDocument(nombreColeccion, documento),
                    documentosAsociados     : firebase.firestore.FieldValue.increment(0)
                }
            }

            documento.settings.triggersIgnorar       = false;
            documento.settings.triggersProcesarTarde = false;
    
            documento.settings.sizeOfDocumento = this.fn.sizeOfFirestoreDocument(nombreColeccion, documento);
    
            documento.settings.usuarioKANE = usuarioKANE;
            documento.settings.fechaHoraModificacion=this.timestamp();
        
        // No lleva settings    
        } else{
                if(documento.settings){
                    delete documento.settings;
                }
        }      
        
        if(typeof documento.key==='number') {
            documento.key = documento.key.toString();
        }

        tamanioInBytOneDocument = this.fn.sizeOfFirestoreDocument(nombreColeccion, documento);
        
        
        if(numeroTransacciones < numeroTransaccionesMaximas && tamanioInBytOneDocument + tamanioInBytesDocuments < tamanioMaximoInbytes){
            console.log("updateColeccionBatch numeroTransacciones ", numeroTransacciones, ' tamanioInBytOneDocument ', tamanioInBytOneDocument ,' tamanioInBytesDocuments ', tamanioInBytesDocuments);
            const refcoleccion=  firebase.firestore().collection(nombreColeccion).doc(documento.key);
            console.log("updateColeccionBatch numeroTransacciones",numeroTransacciones, documento);
            try {
                batch[indexBatch].set(refcoleccion, documento,{merge: true});    
            } catch (error) {
                console.log("error",error);
            }    
            
            numeroTransacciones++
        } 
        //console.log("indexBatch","numeroTransacciones",indexBatch,numeroTransacciones);
        
        if(numeroTransacciones==numeroTransaccionesMaximas || tamanioInBytOneDocument + tamanioInBytesDocuments >= tamanioMaximoInbytes){
            console.log("updateColeccionBatch numeroTransacciones ", numeroTransacciones, ' tamanioInBytOneDocument ', tamanioInBytOneDocument ,' tamanioInBytesDocuments ', tamanioInBytesDocuments);
            numeroTransacciones=1;
            vectorPromesas.push(batch[indexBatch].commit());

            if(tamanioInBytOneDocument + tamanioInBytesDocuments >= tamanioMaximoInbytes) tamanioInBytesDocuments = 0;
            indexBatch = indexBatch+1;


            //ESPERO 10 segundos y creo el nuevo batch para la proxima transacción
            await new Promise(resolve => setTimeout(resolve, delayBetweenBatches)).then(() => {
                batch[indexBatch]=firebase.firestore().batch(); //creo el nuevo BATCH para la proxima transacción
            });

            //AGREGO EL DOCUMENTO AL NUEVO BATCH
            const refcoleccion = firebase.firestore().collection(nombreColeccion).doc(documento.key);
            // console.log("updateColeccionBatch numeroTransacciones",numeroTransacciones, documento);
            try {
                batch[indexBatch].set(refcoleccion, documento,{merge: true});    
            } catch (error) {
                console.log("error",error);
            }
            numeroTransacciones++;

        }
        


        //llegamos al final del array
         // si numeroTransacciones=0 no es necesario el push, se realizó en el else anterior
        //  console.log('vectorPromesas index',index);
        //  console.log('vectorPromesas indatosdex',datos.length-1);
        //  console.log('vectorPromesas numeroTransacciones',numeroTransacciones);
        if(index==datos.length-1 && numeroTransacciones>0) {
            console.log("updateColeccionBatch numeroTransacciones ", numeroTransacciones, ' tamanioInBytOneDocument ', tamanioInBytOneDocument ,' tamanioInBytesDocuments ', tamanioInBytesDocuments);

            // console.log('vectorPromesas index',index,datos.length-1);
            vectorPromesas.push(batch[indexBatch].commit());


            //ESPERO 10 segundos

            // await new Promise(resolve => setTimeout(resolve, delayBetweenBatches));
        }
        tamanioInBytesDocuments += tamanioInBytOneDocument;
        tamnioInBtyesAllDocuments += tamanioInBytesDocuments;
        
    } 
    // return new Promise((resolve) => {
    //     resolve('mensajes.grabacionOk'+' Batch');
    // }).catch((error) => {
    //     return Promise.reject(error);
    // })
    // console.log('vectorPromesas',vectorPromesas);
    // return  Promise.all(vectorPromesas).then((vecPromiseAllRespuesta) => {
    //     console.log("updateColeccionBatch respuesta ",vecPromiseAllRespuesta);

    //     return Promise.resolve('mensajes.grabacionOk'+' Batch');
    // }).catch((errorObtenerParadasVehiculosIntegrantesDeLaRuta) => {
    //     return Promise.reject(errorObtenerParadasVehiculosIntegrantesDeLaRuta);
    // });
    console.log('updateColeccionBatch tamnioInBtyesAllDocuments ', tamnioInBtyesAllDocuments);


    return new Promise((resolve, reject) => {
        try{
            if(this.settingService.settings2.getIsOnLine()) {
                Promise.all(vectorPromesas) 
                .then((respuesta) => {
                    
                    console.log("updateColeccionBatch respuesta ",respuesta);

                        // for (let index = 0; index < datos.length; index++) {
                        //      const element = datos[index];
                    
                        //     let operacion        = element.operacion;
                        //     let nombreColeccion  = element.nombreColeccion;
                        //     let documento        = element.documento;
                        //     let incluyeSettings  = element.incluyeSettings;

                        //     this.apis.LogApiFuncion({
                        //             eventoQueDisparo : 'bdService.updateColeccion operacion:'+operacion,
                        //             apiFuncionKey    : 'FirestoreDocumentWrite',
                        //             organizacionKNAI : organizacionKNAI,
                        //             usuarioKANE      : usuarioKANE,
                        //             nombreColeccion  : nombreColeccion,
                        //             cloudFunction    : null,
                        //             cantidad         : 1,
                        //     });

                        //     if(operacion=="borradoFisico") {
                        //         this.apis.LogApiFuncion({
                        //                 eventoQueDisparo : 'bdService.updateColeccion operacion:'+operacion,
                        //                 apiFuncionKey    : 'FirestoreDocumentDelete',
                        //                 organizacionKNAI : organizacionKNAI,
                        //                 usuarioKANE      : usuarioKANE,
                        //                 nombreColeccion  : nombreColeccion,
                        //                 cloudFunction    : null,
                        //                 cantidad         : 1,
                        //         });
                        //     }

                        
                        // }
                        
                        resolve('mensajes.grabacionOk'+' Batch');
                }).catch((error)=>{
                    log(...values("error","error",error));
                    console.log("updateColeccionBatch error ",error);
                    reject(error);
                    });
            } else {
                // Si no hay internet no se puede realizar
                reject('mensajes.operacionOffLine');
            }
        }catch(error){
            reject(error); 
        }
     });
 }
 async updateColeccionBatchLargeFiles2(userKeys:updateColeccionUserKeysBatch,datos:[updateColeccionDataBatch]): Promise<any> {
    /*
     * Recibe
     *  userKeys{
     *     distribuidorKN   : KNAI,
     *     organizacionKNAI : KNAI,
     *     usuarioKANE      : KANE,
     *  }
     * 
     *  datos {
     *     operacion        : string,
     *     nombreColeccion  : any,
     *     documento        : any,
     *     incluyeSettings  : boolean     
     *  }
     */
    
    //log(...values("valores","userKeys: "   ,userKeys));
    log(...values("valores","datos[]: "      ,datos));

    let distribuidorKN   = userKeys.distribuidorKN;
    let organizacionKNAI = userKeys.organizacionKNAI;
    let usuarioKANE      = userKeys.usuarioKANE;

    let nomeroTransacciones=0;
    let nomeroTransaccionesMaximas=25;
    let vectorPromesas=[];
    // let batch:any=[];
    // let indexBatch:number=0;
    
    let batch = firebase.firestore().batch();
    this.resetTransaccionesLongFile();
    this.cantidadTransaccionesLongFiles.next(datos.length);
    try {
        
   
        for (let index = 0; index < datos.length; index++) {
            
            const element = datos[index];
                
            let operacion        = element.operacion;
            let nombreColeccion  = element.nombreColeccion;
            let documento        = element.documento;
            let incluyeSettings  = element.incluyeSettings;
        

            if(incluyeSettings){

                if(documento.settings===undefined) {
                    documento.settings={};
                }
                
                if(operacion=='agregar') {
                    if(documento.key==null) documento.key=this.af.createId();
                    documento.settings.fechaHoraCreacion=this.timestamp();


                    if(documento.autoIncrement!==undefined) {
                        documento.autoIncrement=null;
                    }

                    documento.settings.isActivo            = true;
                    documento.settings.isBorrado           = false;
                    documento.settings.borradoFisico       = false;
                    documento.settings.sizeOfDocumento     = 0;
                    documento.settings.documentosAsociados = 0;

                } else if(operacion=="modificar") {
                    delete documento.settings.documentosAsociados;

                } else if(operacion=="borradoLogico") {
                    documento.settings.isBorrado=true;
                    documento.settings.borradoFisico=false;

                } else if(operacion=="borradoFisico") {
                    documento.settings.isBorrado=true;
                    documento.settings.borradoFisico=true;

                } else if(operacion=="importar") {                
                    
                    if(documento.key==null) documento.key=this.af.createId();
                    
                    documento.settings = {
                        fechaHoraCreacion       : this.timestamp(),
                        fechaHoraModificacion   : this.timestamp(),
                        usuarioKANE             : usuarioKANE,
                        isActivo                : true,
                        isBorrado               : false,
                        borradoFisico           : false,
                        sizeOfDocumento         : this.fn.sizeOfFirestoreDocument(nombreColeccion, documento),
                        documentosAsociados     : firebase.firestore.FieldValue.increment(0)
                    }
                }

                documento.settings.triggersIgnorar       = false;
                documento.settings.triggersProcesarTarde = false;
        
                documento.settings.sizeOfDocumento = this.fn.sizeOfFirestoreDocument(nombreColeccion, documento);
        
                documento.settings.usuarioKANE = usuarioKANE;
                documento.settings.fechaHoraModificacion=this.timestamp();
            
            // No lleva settings    
            } else{
                    if(documento.settings){
                        delete documento.settings;
                    }
            }      
            
            if(typeof documento.key==='number') {
                documento.key = documento.key.toString();
            }
            
            
            this.procesadasTransaccionesLongFiles.next(index+1);
            this.comentarioTransaccionesLongFiles.next("procesando");

            if(nomeroTransacciones< nomeroTransaccionesMaximas ){
                const refcoleccion=  firebase.firestore().collection(nombreColeccion).doc(documento.key);
                console.log("nomeroTransacciones",nomeroTransacciones, documento);
                try {
                    batch.set(refcoleccion, documento,{merge: true});    
                } catch (error) {
                    console.log("error",error);
                }    
                
                nomeroTransacciones++
            } 
            //console.log("indexBatch","nomeroTransacciones",indexBatch,nomeroTransacciones);
            
            if(nomeroTransacciones==nomeroTransaccionesMaximas ){
                console.log("nomeroTransacciones",nomeroTransacciones, documento);
                this.comentarioTransaccionesLongFiles.next("grabando");
                nomeroTransacciones=0;
                await batch.commit();
                console.log("nomeroTransacciones committed",nomeroTransacciones, documento);
               
                batch=firebase.firestore().batch();
            }
            
            
            //llegamos al final del array
            // si nomeroTransacciones=0 no es necesario el push, se realizó en el else anterior
            //  console.log('vectorPromesas index',index);
            //  console.log('vectorPromesas indatosdex',datos.length-1);
            //  console.log('vectorPromesas nomeroTransacciones',nomeroTransacciones);
            if(index==datos.length-1 && nomeroTransacciones>0) {
                // console.log('vectorPromesas index',index,datos.length-1);
                this.comentarioTransaccionesLongFiles.next("grabando");
                await  batch.commit();
                
                
                
            }

           
        
        } 
        console.log("nomeroTransacciones fin for");
        this.resetTransaccionesLongFile();
        return Promise.resolve("ok");
    } catch (error) {
        console.log("nomeroTransacciones error",error);
        this.resetTransaccionesLongFile();
        return Promise.reject(error);
     
    }
}
 
 updateColeccionBatchLargeFiles(userKeys:updateColeccionUserKeysBatch,datos:[updateColeccionDataBatch]): Promise<any> {
    /*
     * Recibe
     *  userKeys{
     *     distribuidorKN   : KNAI,
     *     organizacionKNAI : KNAI,
     *     usuarioKANE      : KANE,
     *  }
     * 
     *  datos {
     *     operacion        : string,
     *     nombreColeccion  : any,
     *     documento        : any,
     *     incluyeSettings  : boolean     
     *  }
     */
    
    //log(...values("valores","userKeys: "   ,userKeys));
    log(...values("valores","datos[]: "      ,datos));

    let distribuidorKN   = userKeys.distribuidorKN;
    let organizacionKNAI = userKeys.organizacionKNAI;
    let usuarioKANE      = userKeys.usuarioKANE;

    let nomeroTransacciones=0;
    let nomeroTransaccionesMaximas=10;
    let vectorPromesas=[];
    let batch:any=[];
    let indexBatch:number=0;
    
    batch[0] = firebase.firestore().batch();
    for (let index = 0; index < datos.length; index++) {
        
        const element = datos[index];
             
        let operacion        = element.operacion;
        let nombreColeccion  = element.nombreColeccion;
        let documento        = element.documento;
        let incluyeSettings  = element.incluyeSettings;
      

        if(incluyeSettings){

            if(documento.settings===undefined) {
                documento.settings={};
            }
            
            if(operacion=='agregar') {
                if(documento.key==null) documento.key=this.af.createId();
                documento.settings.fechaHoraCreacion=this.timestamp();


                if(documento.autoIncrement!==undefined) {
                    documento.autoIncrement=null;
                }

                documento.settings.isActivo            = true;
                documento.settings.isBorrado           = false;
                documento.settings.borradoFisico       = false;
                documento.settings.sizeOfDocumento     = 0;
                documento.settings.documentosAsociados = 0;

            } else if(operacion=="modificar") {
                delete documento.settings.documentosAsociados;

            } else if(operacion=="borradoLogico") {
                documento.settings.isBorrado=true;
                documento.settings.borradoFisico=false;

            } else if(operacion=="borradoFisico") {
                documento.settings.isBorrado=true;
                documento.settings.borradoFisico=true;

            } else if(operacion=="importar") {                
                
                if(documento.key==null) documento.key=this.af.createId();
                
                documento.settings = {
                    fechaHoraCreacion       : this.timestamp(),
                    fechaHoraModificacion   : this.timestamp(),
                    usuarioKANE             : usuarioKANE,
                    isActivo                : true,
                    isBorrado               : false,
                    borradoFisico           : false,
                    sizeOfDocumento         : this.fn.sizeOfFirestoreDocument(nombreColeccion, documento),
                    documentosAsociados     : firebase.firestore.FieldValue.increment(0)
                }
            }

            documento.settings.triggersIgnorar       = false;
            documento.settings.triggersProcesarTarde = false;
    
            documento.settings.sizeOfDocumento = this.fn.sizeOfFirestoreDocument(nombreColeccion, documento);
    
            documento.settings.usuarioKANE = usuarioKANE;
            documento.settings.fechaHoraModificacion=this.timestamp();
        
        // No lleva settings    
        } else{
                if(documento.settings){
                    delete documento.settings;
                }
        }      
        
        if(typeof documento.key==='number') {
            documento.key = documento.key.toString();
        }
        
        
        if(nomeroTransacciones< nomeroTransaccionesMaximas ){
            const refcoleccion=  firebase.firestore().collection(nombreColeccion).doc(documento.key);
            console.log("nomeroTransacciones",nomeroTransacciones, documento);
            try {
                batch[indexBatch].set(refcoleccion, documento,{merge: true});    
            } catch (error) {
                console.log("error",error);
            }    
            
            nomeroTransacciones++
        } 
        //console.log("indexBatch","nomeroTransacciones",indexBatch,nomeroTransacciones);
        
        if(nomeroTransacciones==nomeroTransaccionesMaximas ){
            console.log("nomeroTransacciones",nomeroTransacciones, documento);
            nomeroTransacciones=0;
            vectorPromesas.push(batch[indexBatch].commit());
            indexBatch++;
            batch[indexBatch]=firebase.firestore().batch();
        }
        
        
        //llegamos al final del array
         // si nomeroTransacciones=0 no es necesario el push, se realizó en el else anterior
        //  console.log('vectorPromesas index',index);
        //  console.log('vectorPromesas indatosdex',datos.length-1);
        //  console.log('vectorPromesas nomeroTransacciones',nomeroTransacciones);
        if(index==datos.length-1 && nomeroTransacciones>0) {
            // console.log('vectorPromesas index',index,datos.length-1);
            vectorPromesas.push(batch[indexBatch].commit());
        }
        
    } 
    // console.log('vectorPromesas',vectorPromesas);

    return this.ejecutarPromesasEnSerie (vectorPromesas);

  
     
     return new Promise((resolve, reject) => {
        if(this.settingService.settings2.getIsOnLine()) {
        


        //    Promise.all(vectorPromesas) 
        //        .then((respuesta) => {
                
                    
        //             resolve('mensajes.grabacionOk'+' Batch');
        //        })
        //        .catch((error)=>{
        //           log(...values("error","error",error));
        //           reject(error);
        //         });
        //  } else {

        //      // Si no hay internet no se puede realizar
        //      reject('mensajes.operacionOffLine');
         }
     }  );
 }

 resetTransaccionesLongFile(){
    this.procesadasTransaccionesLongFiles.next(0);
    this.cantidadTransaccionesLongFiles.next(0);
 } 
 async  ejecutarPromesasEnSerie(vectorPromesas) {
    return new Promise(async (resolve, reject) => {
            console.log('ejecutarPromesasEnSerie long',vectorPromesas.length);
            this.cantidadTransaccionesLongFiles.next(vectorPromesas.length)
            try {
                const resultados = [];
                for (let i = 0; i < vectorPromesas.length; i++) {
                    console.log('ejecutarPromesasEnSerie pre',i);
                    const resultado = await vectorPromesas[i];
                    console.log('ejecutarPromesasEnSerie done',i);
                    console.log('ejecutarPromesasEnSerie resultado',resultado);
                    resultados.push(resultado);
                    this.procesadasTransaccionesLongFiles.next(i+1);
                // Puedes hacer algo después de que la promesa se haya resuelto aquí
                }   
                resolve(resultados);
                console.log('ejecutarPromesasEnSerie fin');
                this.resetTransaccionesLongFile();
            } catch (error) {
                console.log('ejecutarPromesasEnSerie',error);
                reject(error);
                this.resetTransaccionesLongFile();
            }
        
        
        });
    }


  getBDSubscripcion(argumentos:getBDModel): Observable<any> {
    /*
     * Recibe
     * argumentos {
     *     nombreColeccion  : string,
     *     where            : any[],
     *     orderBy          : any[],
     *     limit            : number,
     *     paginado         : any,
     *     organizacionKNAI : KNAI,
     *     usuarioKANE      : KANE
     * }
     *
     * paginaRequerida, puede tener los siguientes valores
     *
     * grilla.paginadoTipo = 'local'
     * 'todas'
     *
     * grilla.paginadoTipo = 'servidor'
     * 'primera'
     * 'siguiente'
     * 'anterior'
     * 'anteriorOverflow' --> cuando se vuelve de la página excedida luego de pasarnos de largo de la última página
     */

    //log(...values('funcionGoPromesa','bdService.getBDSubscripcion: '+argumentos));

    let nombreColeccion  = argumentos.nombreColeccion;
    let where            = argumentos.where;
    let orderBy          = argumentos.orderBy;
    let limit            = argumentos.limit;
    let paginado         = argumentos.paginado;
    let organizacionKNAI = argumentos.organizacionKNAI;
    let usuarioKANE      = argumentos.usuarioKANE;

    let paginaRequerida = (argumentos.paginado==null) ? 'todas' : argumentos.paginado.paginaRequerida;
    let resultKey1      = (argumentos.paginado==null) ? null    : argumentos.paginado.resultKey1;
    let resultKeyN      = (argumentos.paginado==null) ? null    : argumentos.paginado.resultKeyN;

    // log(...values('valores','where:',where));
    // log(...values('valores','orderBy:',orderBy));
    // log(...values('valores','limit:',limit));
    // log(...values('valores','paginaRequerida:',paginaRequerida));
    // log(...values('valores','resultKey1:',resultKey1));
    // log(...values('valores','resultKeyN:',resultKeyN));
    // console.log('whereJJ',where);
    // console.log('orderByJJ',orderBy);
    // console.log('nombreColeccionJJ',nombreColeccion);

    if(this.environment.tipoServidor.negocio=="firestore") {

      return new Observable((observer) => {
          this.af.collection<any>(nombreColeccion,
                ref =>{
                    let query: Query = ref;

                    let filtrosAplicados=[];
                    if(where.length > 0) {
                      for (var i = 0; i < where.length; i++) {
                        // console.log('whereJJ'+i,where[i].key, where[i].operador, where[i].value);
                          //log(...values('warning','agrego where:',where[i].key, where[i].operador, where[i].value));
                          query = query.where(where[i].key, where[i].operador, where[i].value);
                          filtrosAplicados.push(where[i].key);
                      }
                    }

                    if(orderBy.length > 0) {
                       for (var i = 0; i < orderBy.length; i++) {
                          let key     = orderBy[i].key;
                          let ascDesc = orderBy[i].ascDesc;
                        //   console.log('orderByJJ'+i,key,ascDesc);

                          // verifico que el campo orden no está incluido entre alguno de los filtros
                          if(filtrosAplicados.indexOf(key)!=-1) {
                              continue; // No aplico el Orden
                          }

                          if(paginaRequerida=='anterior') {  // Invierto el Orden
                             ascDesc = (ascDesc=='asc') ? 'desc' : 'asc';
                          }
                          query = query.orderBy(key, ascDesc);
                          //log(...values('warning','agrego orden:',key+':'+ascDesc));
                       }
                    }

                    if(paginaRequerida=='primera') {
                        // nada adicional

                    } else if(paginaRequerida=='siguiente') {
                        //log(...values('warning','agrego startAfter:',resultKeyN));

                        // query = query.startAt(resultKeyN);
                        query = query.startAfter(resultKeyN);

                    } else if(paginaRequerida=='anterior') {
                        //log(...values('warning','agrego startAfter:',resultKey1));
                        query = query.startAfter(resultKey1);

                    } else if(paginaRequerida=='anteriorOverflow') {
                        //log(...values('warning','agrego startAt:',resultKey1));
                        query = query.startAt(resultKey1);

                    } else if(paginaRequerida=='todas') {
                        // nada adicional
                    }

                    if(paginaRequerida!='todas') {
                        //log(...values('warning','agrego limit:',limit));
                        query = query.limit(limit);
                    }

                    //log(...values('valores','query:',query));

                    return query;

                }).valueChanges().subscribe((data:any)=>{
                    // console.log('DATA_JJ',data);
                    // log(...values('valores','nombreColeccion:',nombreColeccion));
                    // log(...values('valores','data:',data));

                    let listado=[];    
                    for(let i=0; i<data.length; i++) {
                        let documento=this.fn.copiarObjetoNew( data[i] );
     
                        // Convierto valores timestamp a Date
                        documento=this.fn.corrigeTimestampDocumento(documento);  
                        //console.log("corrigeTimestampDocumento5----------------" );
                        //console.log("corrigeTimestampDocumento5",documento );
                         
                        listado.push(documento);
                    }    
                    // console.log("corrigeTimestampDocumento5.5",listado );
                    //return;
                    observer.next(listado);

                },(error:any)=>{
                    // observer.error('no pudo acceder a Colección '+nombreColeccion);
                    observer.error(error);
                })
          });

    } else {  // BD RELACIONAL

      return new Observable((observer) => {

          const url:string='https://www.lincolnonline.com.ar/ionic/http_firestore.php';

          this._http.get(url).subscribe((data:any)=> {
                let coleccionTotalSizeOf=0;
                let listadoFormateado=[];
                for(let i=0; i<data.length; i++) {
                   let documento=data[i];

                   // Convierto valores timestamp a Date
                   documento=this.fn.corrigeTimestampDocumento(documento);  

                   // Calculo de ser necesario el tamaño en bytes del documento
                   documento.settings.sizeOfDocumento =
                       this.fn.sizeOfIfNotCalculado({ sizeOfActual  : documento.settings.sizeOfDocumento,
                                                      pathColeccion : nombreColeccion+documento.key,
                                                      object        : documento });

                   coleccionTotalSizeOf += documento.settings.sizeOfDocumento;

                   listadoFormateado.push(documento);
                }
                observer.next( listadoFormateado );
                observer.complete();
          },(error:any)=>{
                    observer.error('no pudo acceder a Colección '+nombreColeccion);
          })

      })


    }

  }
  
  getUTCofTimeZone(organizacionKNAI, usuarioKANE, direccion:any): Promise<any> {
    console.log("getUTCofTimeZone",direccion);  
    return new Promise((resolve, reject) => {
        
          if(direccion===undefined || direccion==null || direccion.timeZone===undefined || direccion.timeZone==null) {
              reject(null);
              return;
          }
          
          let timeZone = direccion.timeZone.replace('/','|');  // Sólo reemplaza el primero
          timeZone = timeZone.replace('/','|');
          timeZone = timeZone.replace('/','|'); 
      
          let argumentos:any={
            nombreColeccion  : 'AuxTimeZones',
            where            : [{
                'key'      : 'key', 
                'operador' : '==',
                'value'    : timeZone
            }],    
            orderBy          : [],
            limit            : null,
            paginado         : null,
            offset           : null,
            organizacionKNAI : organizacionKNAI,
            usuarioKANE      : usuarioKANE
          };
          
          this.getBDPromesa(argumentos) 
            .then((resultado:any[])=>{
                console.log('bdService.getUTCofTimeZone ok', direccion, resultado);
                resolve(resultado[0].utc1);

            }).catch((error:any)=>{
                console.log('bdService.getUTCofTimeZone error', direccion, error);
                reject(null);
            });  
    });        
 }


  getBDPromesa(argumentos:getBDModel): Promise<any> {
    /*
     * Recibe
     * argumentos {
     *     nombreColeccion  : any,
     *     where            : any[],
     *     orderBy          : any[],
     *     limit            : number,
     *     offset           : number,
     *     organizacionKNAI : KNAI,
     *     usuarioKANE      : KANE
     * }
     */

    //log(...values('funcionGoPromesa','bdService.getBDPromesa: '+argumentos.nombreColeccion));

    let nombreColeccion  = argumentos.nombreColeccion;
    let where            = argumentos.where;
    let orderBy          = argumentos.orderBy;
    let limit            = argumentos.limit;
    let paginado         = argumentos.paginado;
    let organizacionKNAI = argumentos.organizacionKNAI;
    let usuarioKANE      = argumentos.usuarioKANE;

    let paginaRequerida = (argumentos.paginado==null) ? 'todas' : argumentos.paginado.paginaRequerida;
    let resultKey1      = (argumentos.paginado==null) ? null    : argumentos.paginado.resultKey1;
    let resultKeyN      = (argumentos.paginado==null) ? null    : argumentos.paginado.resultKeyN;


    // console.log('getColeccion',argumentos);

    if(this.environment.tipoServidor.negocio=="firestore") {

      return new Promise((resolve, reject) => {
          this.af.collection<any>(nombreColeccion,
            ref =>{
                let query: Query = ref;

                let filtrosAplicados=[];
                if(where.length > 0) {
                  for (var i = 0; i < where.length; i++) {
                      //log(...values('warning','agrego where:',where[i].key, where[i].operador, where[i].value));
                      query = query.where(where[i].key, where[i].operador, where[i].value);
                      filtrosAplicados.push(where[i].key);
                  }
                }

                if(orderBy.length > 0) {
                   for (var i = 0; i < orderBy.length; i++) {
                      let key     = orderBy[i].key;
                      let ascDesc = orderBy[i].ascDesc;

                      // verifico que el campo orden no está incluido entre alguno de los filtros
                      if(filtrosAplicados.indexOf(key)!=-1) {
                          continue; // No aplico el Orden
                      }

                      if(paginaRequerida=='anterior') {  // Invierto el Orden
                         ascDesc = (ascDesc=='asc') ? 'desc' : 'asc';
                      }
                      query = query.orderBy(key, ascDesc);
                      //log(...values('warning','agrego orden:',key+':'+ascDesc));
                   }
                }

                if(paginaRequerida=='primera') {
                    // nada adicional

                } else if(paginaRequerida=='siguiente') {
                    //log(...values('warning','agrego startAfter:',resultKeyN));

                    // query = query.startAt(resultKeyN);
                    query = query.startAfter(resultKeyN);

                } else if(paginaRequerida=='anterior') {
                    //log(...values('warning','agrego startAfter:',resultKey1));
                    query = query.startAfter(resultKey1);

                } else if(paginaRequerida=='anteriorOverflow') {
                    //log(...values('warning','agrego startAt:',resultKey1));
                    query = query.startAt(resultKey1);

                } else if(paginaRequerida=='todas') {
                    // nada adicional
                }

                if(paginaRequerida!='todas') {
                    //log(...values('warning','agrego limit:',limit));
                    query = query.limit(limit);
                }

                //log(...values('valores','query:',query));

                return query;

                }).valueChanges({ idField: 'id' }).pipe(first()).toPromise().then((data:any)=>{
                    // console.log('xxjj1',data);
                    let coleccionTotalSizeOf=0;
                    let cantDocumentos=data.length;
                    let listadoFormateado=[];
                    for(let i=0; i<data.length; i++) {
                       let documento=data[i];

                       // Convierto valores timestamp a Date
                       documento=this.fn.corrigeTimestampDocumento(documento);

                       // Calculo de ser necesario el tamaño en bytes del documento
                       documento.settings.sizeOfDocumento =
                           this.fn.sizeOfIfNotCalculado({ sizeOfActual  : documento.settings.sizeOfDocumento,
                                                          pathColeccion : nombreColeccion+documento.key,
                                                          object        : documento });

                       coleccionTotalSizeOf += documento.settings.sizeOfDocumento;

                       listadoFormateado.push(documento);

                    }

                    this.apis.LogApiFuncion({
                         eventoQueDisparo : 'bdService.getBDPromesa',
                         apiFuncionKey    : 'FirestoreDocumentRead',
                         organizacionKNAI : organizacionKNAI,
                         usuarioKANE      : usuarioKANE,
                         nombreColeccion  : nombreColeccion,
                         cloudFunction    : null,
                         cantidad         : cantDocumentos,
                    });

                    this.apis.LogApiFuncion({
                         eventoQueDisparo : 'bdService.getBDPromesa',
                         apiFuncionKey    : 'FirestoreTransferencia',
                         organizacionKNAI : organizacionKNAI,
                         usuarioKANE      : usuarioKANE,
                         nombreColeccion  : nombreColeccion,
                         cloudFunction    : null,
                         cantidad         : coleccionTotalSizeOf,
                    });

                    resolve(listadoFormateado);
                }).catch((error:any)=>{
                    console.log(' getColeccion error',error);
                    //log(...values("error","error",error));
                    reject('no pudo acceder a Colección '+nombreColeccion);
                });
      })

    } else {  // BD RELACIONAL

      return new Promise((resolve, reject) => {

          const url:string='https://www.lincolnonline.com.ar/ionic/http_firestore.php';

          this._http.get(url).subscribe((data:any)=> {
                let coleccionTotalSizeOf=0;
                let listadoFormateado=[];
                for(let i=0; i<data.length; i++) {
                   let documento=data[i];

                   // Convierto valores timestamp a Date
                   documento=this.fn.corrigeTimestampDocumento(documento);

                   // Calculo de ser necesario el tamaño en bytes del documento
                   documento.settings.sizeOfDocumento =
                       this.fn.sizeOfIfNotCalculado({ sizeOfActual  : documento.settings.sizeOfDocumento,
                                                      pathColeccion : nombreColeccion+documento.key,
                                                      object        : documento });

                   coleccionTotalSizeOf += documento.settings.sizeOfDocumento;

                   listadoFormateado.push(documento);
                }
                resolve( listadoFormateado );
          },(error:any)=>{
                reject('no pudo acceder a Colección '+nombreColeccion);
          })

      });


    }
  }  
 
  getBDPromesa2<T>(argumentos:getBDModel2): Promise<any> {
    /*
     * Recibe
     * argumentos {
     *     nombreColeccion  : string,
     *     campoClave       : string, (tiene el código del campo clave. Si son mas de uno se utilizan puntos para separarlos)
     *     where            : WhereInterface[],   [{key:'codigo',operador:OPERADOR_WHERE.Igual,value:'miCodigo'}],
     *     orderBy          : OrdeByInterface[],  [{key:'codigo',ascdesc:OPERADOR_ORDEN_BY.Ascendente}],
     *     limit            : number,
     *     offset           : number,
     *     organizacionKNAI : KNAI,
     *     usuarioKANE      : KANE
     * }
     */

    //log(...values('funcionGoPromesa','bdService.getBDPromesa2: '+argumentos.nombreColeccion));

    let nombreColeccion   : string            = argumentos.nombreColeccion;
    let where             : WhereInterface[]  = argumentos.where;
    let orderBy           : OrdeByInterface[] = argumentos.orderBy;
    let limit             : number            = argumentos.limit;
    let paginado          : number            = argumentos.paginado;
    let organizacionKNAI  : KNAI              = argumentos.organizacionKNAI;
    let usuarioKANE       : KANE              = argumentos.usuarioKANE;

    let paginaRequerida = (argumentos.paginado==null) ? 'todas' : argumentos.paginado.paginaRequerida;
    let resultKey1      = (argumentos.paginado==null) ? null    : argumentos.paginado.resultKey1;
    let resultKeyN      = (argumentos.paginado==null) ? null    : argumentos.paginado.resultKeyN;


    console.log('getBDPromesa2',argumentos);
    console.log('getBDPromesa2',argumentos);


    if(this.environment.tipoServidor.baseDatos=="firestore") {
        console.log('getBDPromesa2 firestore')

      return new Promise((resolve, reject) => {
          this.af.collection<any>(nombreColeccion,
            ref =>{
                let query: Query = ref;

                let filtrosAplicados=[];
                if(where.length > 0) {
                  for (var i = 0; i < where.length; i++) {
                      //log(...values('warning','agrego where:',where[i].key, where[i].operador, where[i].value));
                      query = query.where(where[i].key, where[i].operador, where[i].value);
                      filtrosAplicados.push(where[i].key);
                  }
                }

                if(orderBy.length > 0) {
                   for (var i = 0; i < orderBy.length; i++) {
                      let key     = orderBy[i].key;
                      let ascDesc: any = orderBy[i].ascDesc;

                      // verifico que el campo orden no está incluido entre alguno de los filtros
                      if(filtrosAplicados.indexOf(key)!=-1) {
                          continue; // No aplico el Orden
                      }

                      if(paginaRequerida=='anterior') {  // Invierto el Orden
                         ascDesc = (ascDesc==OPERADOR_ORDEN_BY.Ascendente) ? OPERADOR_ORDEN_BY.Descendente : OPERADOR_ORDEN_BY.Ascendente;
                      }
                      query = query.orderBy(key, ascDesc);
                      //log(...values('warning','agrego orden:',key+':'+ascDesc));
                   }
                }

                if(paginaRequerida=='primera') {
                    // nada adicional

                } else if(paginaRequerida=='siguiente') {
                    //log(...values('warning','agrego startAfter:',resultKeyN));

                    // query = query.startAt(resultKeyN);
                    query = query.startAfter(resultKeyN);

                } else if(paginaRequerida=='anterior') {
                    //log(...values('warning','agrego startAfter:',resultKey1));
                    query = query.startAfter(resultKey1);

                } else if(paginaRequerida=='anteriorOverflow') {
                    //log(...values('warning','agrego startAt:',resultKey1));
                    query = query.startAt(resultKey1);

                } else if(paginaRequerida=='todas') {
                    // nada adicional
                }

                if(paginaRequerida!='todas') {
                    //log(...values('warning','agrego limit:',limit));
                    query = query.limit(limit);
                }

                //log(...values('valores','query:',query));

                return query;

                }).valueChanges({ idField: 'id' }).pipe(first()).toPromise().then((data:any)=>{
                    // console.log('xxjj1',data);
                    let coleccionTotalSizeOf=0;
                    let cantDocumentos=data.length;
                    let listadoFormateado=[];
                    for(let i=0; i<data.length; i++) {
                       let documento=data[i];

                       // Convierto valores timestamp a Date
                       documento=this.fn.corrigeTimestampDocumento(documento);

                       // Calculo de ser necesario el tamaño en bytes del documento
                       documento.settings.sizeOfDocumento =
                           this.fn.sizeOfIfNotCalculado({ sizeOfActual  : documento.settings.sizeOfDocumento,
                                                          pathColeccion : nombreColeccion+documento.key,
                                                          object        : documento });

                       coleccionTotalSizeOf += documento.settings.sizeOfDocumento;

                       listadoFormateado.push(documento);

                    }

                    this.apis.LogApiFuncion({
                         eventoQueDisparo : 'bdService.getBDPromesa',
                         apiFuncionKey    : 'FirestoreDocumentRead',
                         organizacionKNAI : organizacionKNAI,
                         usuarioKANE      : usuarioKANE,
                         nombreColeccion  : nombreColeccion,
                         cloudFunction    : null,
                         cantidad         : cantDocumentos,
                    });

                    this.apis.LogApiFuncion({
                         eventoQueDisparo : 'bdService.getBDPromesa',
                         apiFuncionKey    : 'FirestoreTransferencia',
                         organizacionKNAI : organizacionKNAI,
                         usuarioKANE      : usuarioKANE,
                         nombreColeccion  : nombreColeccion,
                         cloudFunction    : null,
                         cantidad         : coleccionTotalSizeOf,
                    });

                    resolve(listadoFormateado);
                }).catch((error:any)=>{
                    console.log(' getColeccion error',error);
                    //log(...values("error","error",error));
                    reject('no pudo acceder a Colección '+nombreColeccion);
                });
      })

    } else {  // BD RELACIONAL

      return new Promise((resolve, reject) => {

        console.log('getBDPromesa2 sql')
        console.log('getBDPromesa2 this.environment',this.environment);

        // para hacer: get con parametros en el get que se sacan del array where

          let parametros='';

          for (let index = 0; index < argumentos.where.length; index++) {
              const element:WhereInterface = argumentos.where[index];
              if(index==0){ //primero
                parametros=parametros+'?'+element.key+element.operador+element.value;
              } else{      // el resto 
                parametros=parametros+'&'+element.key+element.operador+element.value;   
              }
              
              
          }


          let url:string='';

          if(argumentos.where.length>0){
            url=this.environment.serviciosExternos.sql.apiURL+'api/'+nombreColeccion+'/';    
          } else {
            url=this.environment.serviciosExternos.sql.apiURL+'api/'+nombreColeccion+parametros;    
          }
          

          console.log('getBDPromesa2 url',url);

          this._http.get(url).subscribe((data:any)=> {
            console.log('getBDPromesa2 data',data);
                let coleccionTotalSizeOf=0;
                let listadoFormateado=[];
                for(let i=0; i<data.length; i++) {
                   let documento=data[i];
                //    console.log('resp_sql',documento);

                   // Convierto valores timestamp a Date
                   documento=this.fn.corrigeTimestampDocumento(documento);

                  // Transformo setting de string a Json
                //   console.log('resp_sql settings',documento.settings);
                  if(documento.settings){
                      documento.settings=JSON.parse(documento.settings);
                      console.log('resp_sql json',documento);
                  }



                   // Calculo de ser necesario el tamaño en bytes del documento
                //    documento.settings.sizeOfDocumento =
                //        this.fn.sizeOfIfNotCalculado({ sizeOfActual  : documento.settings.sizeOfDocumento,
                //                                       pathColeccion : nombreColeccion+documento.key,
                //                                       object        : documento });

                //    coleccionTotalSizeOf += documento.settings.sizeOfDocumento;

                   listadoFormateado.push(documento);
                }
                resolve( listadoFormateado );
          },(error:any)=>{
                reject('no pudo acceder a Colección '+nombreColeccion);
          })

      });


    }
  }  

  getBDSQL<T>(argumentos:getBDSQL): Promise<any> {
    /*
     * Recibe
     * argumentos {
     *     servicioSQL      : string,
     *     nombreColeccion  : string,
     *     campoClave       : string, (tiene el código del campo clave. Si son mas de uno se utilizan puntos para separarlos)
     *     where            : WhereInterface[],   [{key:'codigo',operador:OPERADOR_WHERE.Igual,value:'miCodigo'}],
     *     orderBy          : OrdeByInterface[],  [{key:'codigo',ascdesc:OPERADOR_ORDEN_BY.Ascendente}],
     *     paginadoCantidad : number,
     *     paginaActualNro  : number,
     *     organizacionKNAI : KNAI,
     *     usuarioKANE      : KANE
     * }
     */

    //log(...values('funcionGoPromesa','bdService.getBDSQL: '+argumentos.nombreColeccion));

    let servicioSQL       : string            = argumentos.servicioSQL;    
    let nombreColeccion   : string            = argumentos.nombreColeccion;
    let where             : WhereInterface[]  = argumentos.where;
    let orderBy           : OrdeByInterface[] = argumentos.orderBy;
    let paginadoCantidad  : number            = argumentos.paginadoCantidad;
    let paginaActualNro   : number            = argumentos.paginaActualNro==null ? 1 : argumentos.paginaActualNro;

    let organizacionKNAI  : KNAI              = argumentos.organizacionKNAI;
    let usuarioKANE       : KANE              = argumentos.usuarioKANE;

    console.log('getBDPromesa2',argumentos);
    console.log('getBDPromesa2',argumentos);


      return new Promise((resolve, reject) => {

        console.log('getBDPromesa2 sql')
        console.log('getBDPromesa2 this.environment',this.environment);

        // para hacer: get con parametros en el get que se sacan del array where

          let parametros='';

          for (let index = 0; index < argumentos.orderBy.length; index++) {
            const element:OrdeByInterface = argumentos.orderBy[index];
            let orden = 'order_'+element.key+'='+element.ascDesc;
            
            if(index==0){ //primero
              parametros=parametros+'?'+orden;
            } else{      // el resto 
              parametros=parametros+'&'+orden;   
            }
          }
        
          for (let index = 0; index < argumentos.where.length; index++) {
              const element:WhereInterface = argumentos.where[index];
              let filtro = 'filtro';
              if(element.operador=='==') {
                  filtro+='igual_';
              } else if(element.operador=='>=') {
                filtro+='mayoroigual_';
              } else if(element.operador=='<=') {
                filtro+='menoroigual_';
              } else if(element.operador=='<') {
                    filtro+='menor_';
              } else if(element.operador=='>') {
                    filtro+='mayor_';
              } else if(element.operador=='contiene') {
                    filtro+='contiene_';
              }    
              filtro+=element.key + '=' + element.value;
                
              parametros=parametros+'&'+filtro;   
          }
          
          if(paginadoCantidad != null && paginadoCantidad != undefined){
            let offset =  (paginaActualNro-1) * paginadoCantidad;                    
          
            if(parametros!='') {
                parametros+='&offset='+offset.toString() + '&limit='+paginadoCantidad.toString();    
            } else {
                parametros+='?offset='+offset.toString() + '&limit='+paginadoCantidad.toString();    
            }
          }
          
          
          
          console.log("zzz parametros", parametros);
          
          let url:string='';

          url=this.environment.serviciosExternos.sql.apiURL+servicioSQL+parametros;    


          console.log('getBDPromesa2 url',url);

          this._http.get(url).subscribe((data:any)=> {
            console.log('getBDPromesa2 data',data);
                let coleccionTotalSizeOf=0;
                let listadoFormateado=[];
                for(let i=0; i<data.length; i++) {
                   let documento=data[i];
                //    console.log('resp_sql',documento);

                   // Convierto valores timestamp a Date
                   documento=this.fn.corrigeTimestampDocumento(documento);

                  // Transformo setting de string a Json
                //   console.log('resp_sql settings',documento.settings);
                  if(documento.settings){
                      documento.settings=JSON.parse(documento.settings);
                      console.log('resp_sql json',documento);
                  }



                   // Calculo de ser necesario el tamaño en bytes del documento
                //    documento.settings.sizeOfDocumento =
                //        this.fn.sizeOfIfNotCalculado({ sizeOfActual  : documento.settings.sizeOfDocumento,
                //                                       pathColeccion : nombreColeccion+documento.key,
                //                                       object        : documento });

                //    coleccionTotalSizeOf += documento.settings.sizeOfDocumento;

                   listadoFormateado.push(documento);
                }
                resolve( listadoFormateado );
          },(error:any)=>{
                reject('no pudo acceder a Colección '+nombreColeccion);
          })

      });

  }  
  
  getBDActualizacionesSubscripcion(): Observable<any> {

    // //log(...values('funcionGoPromesa','xx bdService.getBDActualizacionesSubscripcion'));

    return new Observable((observer) => {

        this.af.collection<any>('Actualizaciones').doc('Actualizaciones')
        .valueChanges().subscribe((data:any)=>{

            let documento=data;
            // //log(...values("valores","xx getBDActualizacionesSubscripcion OK!!! documento:",documento));

            observer.next(documento);
        },(error:any)=>{
            //log(...values("error","getBDActualizacionesSubscripcion - error:",error));
            observer.error('no pudo obtener el documento {Auxiliar} de Colecciones');
        })

    });

  }

  getBDCantDocumentosPromesa(nombreColeccion:string): Promise<any> {

    //log(...values('funcionGoPromesa','bdService.getBDCantDocumentosPromesa: '+nombreColeccion));

    if(this.environment.tipoServidor.baseDatos=="firestore") {

        return new Promise((resolve, reject) => {

            // this.af.collection<any>('Colecciones/'+nombreColeccion)
            this.af.collection<any>('Colecciones').doc(nombreColeccion)
            .valueChanges().pipe(first()).toPromise().then((data:any)=>{

            // this.af.collection('Colecciones').doc(nombreColeccion).get()
            // .pipe(first()).toPromise().then((data:any)=>{
                let documento=data;
                //log(...values("valores","getBDCantDocumentosPromesa OK!!! documento:",documento));

                let objCant = {
                  cantidadDocumentosLogicos : documento.cantidadDocumentosLogicos,
                  cantidadDocumentosFisicos : documento.cantidadDocumentosFisicos,
                }

                resolve(objCant);
            })
            .catch((error:any)=>{
                //log(...values("error","getBDCantDocumentosPromesa - error:",error));
                reject('no pudo obtener el Total de laColección '+nombreColeccion);
            });

        });


    } else {  // BD RELACIONAL

      return new Promise((resolve, reject) => {

          const url:string='https://www.lincolnonline.com.ar/ionic/http_firestore.php';

          this._http.get(url).subscribe((data:any)=> {
                let objCant = {
                  cantidadDocumentosLogicos : 1,
                  cantidadDocumentosFisicos : 1,
                }
                resolve( objCant );
          },(error:any)=>{
                //log(...values("error","getBDCantDocumentosPromesa - error:",error));
                reject('no pudo obtener el Total de laColección '+nombreColeccion);
          })

      })


    }

  }

  postGETProcesaListado(argumentos:postGETProcesaListadoModel):any {
       /* argumentos = {
        *   listado                  : [],
        *   form                     : any,
        *   nombreCampoThumb         : string,
        *   vecImagenesDownloadeadas : []
        *   organizacionKNAI         : KNAI
        *   usuarioKANE              : KANE,
        *   nombreColeccion          : string,
        * }
        */

        //log(...values('funcionGo','bdService.postGETProcesaListado'));

        let rta = {
            listado                  : argumentos.listado,
            vecImagenesDownloadeadas : argumentos.vecImagenesDownloadeadas,
            totalDownloadIconos      : 0,
            totalCantIconos          : 0,
        }

        let coleccionTotalSizeOf=0;

        for(let i=0; i<rta.listado.length; i++) {
           let documento=rta.listado[i];

           // Igual estructura del registro con la definida en el Formulario
           documento = this.fn.igualarDocumentoConForm(argumentos.form, documento, 'destinoListado');

           // Calculo la cantidad de Bytes del Documento
           documento.settings.sizeOfDocumento =
               this.fn.sizeOfIfNotCalculado({ sizeOfActual  : documento.settings.sizeOfDocumento,
                                           pathColeccion : argumentos.nombreColeccion+documento.key,
                                           object        : documento });

           coleccionTotalSizeOf += documento.settings.sizeOfDocumento;

           rta.listado[i] = documento;

           // Calculo el peso de los Iconos a mostrar en el Listado
           if(argumentos.nombreCampoThumb && documento[argumentos.nombreCampoThumb]) {
               let imagen    = documento[argumentos.nombreCampoThumb];
               let linkThumb = (imagen!=null) ? documento[argumentos.nombreCampoThumb].linkThumb : null;

               if( imagen!=null && rta.vecImagenesDownloadeadas.indexOf(linkThumb)==-1 ) {
                   rta.totalDownloadIconos += this.fn.sizeImage(imagen, 'Thumb');
                   rta.totalCantIconos++;

                   rta.vecImagenesDownloadeadas.push(linkThumb);
               }
           }
           
        }
        //log(...values("valores",argumentos.nombreColeccion+" - coleccionTotalSizeOf Total:",coleccionTotalSizeOf));

        if(rta.listado.length > 0) {   // OJO, SIEMPRE CUENTA AL MENOS UNA LECTURA

            this.apis.LogApiFuncion({
                 eventoQueDisparo : 'bdService.postGETProcesaListado',
                 apiFuncionKey    : 'FirestoreDocumentRead',
                 organizacionKNAI : argumentos.organizacionKNAI,
                 usuarioKANE      : argumentos.usuarioKANE,
                 nombreColeccion  : argumentos.nombreColeccion,
                 cloudFunction    : null,
                 cantidad         : rta.listado.length,
            });

            this.apis.LogApiFuncion({
                 eventoQueDisparo : 'bdService.postGETProcesaListado',
                 apiFuncionKey    : 'FirestoreTransferencia',
                 organizacionKNAI : argumentos.organizacionKNAI,
                 usuarioKANE      : argumentos.usuarioKANE,
                 nombreColeccion  : argumentos.nombreColeccion,
                 cloudFunction    : null,
                 cantidad         : coleccionTotalSizeOf,
            });

            this.apis.LogApiFuncion({
                 eventoQueDisparo : 'bdService.postGETProcesaListado - Bytes Iconos Grilla',
                 apiFuncionKey    : 'FirebaseStorageDownloaded',
                 organizacionKNAI : argumentos.organizacionKNAI,
                 usuarioKANE      : argumentos.usuarioKANE,
                 nombreColeccion  : argumentos.nombreColeccion,
                 cloudFunction    : null,
                 cantidad         : rta.totalDownloadIconos,
            });

            this.apis.LogApiFuncion({
              eventoQueDisparo : 'bdService.postGETProcesaListado - Cant Iconos Grilla',
              apiFuncionKey    : 'FirebaseStorageOperationDownload',
              organizacionKNAI : argumentos.organizacionKNAI,
              usuarioKANE      : argumentos.usuarioKANE,
              nombreColeccion  : argumentos.nombreColeccion,
              cloudFunction    : null,
              cantidad         : rta.totalCantIconos,
            });
        }

        return rta;
    }

    /// Firebase Server Timestamp
    timestamp() {
        if(this.environment.tipoServidor.baseDatos=="firestore") {
            return  firebase.firestore.Timestamp.now().toDate();
        } else{
            return new Date();
        }
        
    }

    getNewKey(){
       return this.af.createId();
    }

   deleteDocumento(coleccion:string,key:string):Promise<any> {
        return this.af.collection(coleccion).doc(key).delete();

   }

   deleteDocumentoField(coleccion:string,key:string,field:string):Promise<any> {

    const ref = this.af.collection(coleccion).doc(key);
    let doc = {};
    doc[field]=firebase.firestore.FieldValue.delete();  
    return ref.update(doc);

    }

    getListDocumentosMaxTenRows(coleccion:string,whereOne:WhereInterface, whereIn:WhereInterface):Promise<any>{
        return new Promise((resolve) => {
            this.af.collection(coleccion,ref=>{                
                if(whereOne.key != null){
                    return ref.where(whereOne.key, whereOne.operador, whereOne.value)
                              .where(whereIn.key, whereIn.operador, whereIn.value)
                }else{
                    return ref.where(whereIn.key, whereIn.operador, whereIn.value)
                }
            }).valueChanges().subscribe((data:any)=>{
                resolve(data);
            });
        });
    }

    //   en caso de que no encuentre algun registro devuelve undefined en el resolve
    getListadosOr(coleccion:string,arrayIn:string[], keyWhereIn:string, whereOne:WhereInterface):Promise<any>{
        return new Promise((resolve, reject) => {
        
            let vecPromise:any[] = [];
            
            for (let i = 0; i < arrayIn.length; i += 10){   
                let arrayDe10 = arrayIn.slice(i, i + 10);   //agarro un array de a 10   
                let whereIn:WhereInterface = {key:keyWhereIn, operador:'in', value:arrayDe10}
                vecPromise.push(this.getListDocumentosMaxTenRows(coleccion, whereOne, whereIn));
            }
    
            Promise.all(vecPromise).then((vecPromiseRespuesta)=>{
                console.log('getListadosOr vecPromiseRespuesta ', vecPromiseRespuesta);
    
                let arrayRespuesta:any[] = [];
                //CONVERITR LOS ARRAYS DE ARRAYS EN UN SOLO ARRAY y en caso de que no encuentre algun registro devuelve undefined
                let containsUndefinedInArray:boolean = false;
                // if(vecPromiseRespuesta && vecPromiseRespuesta.length > 0) {
                for (let j = 0; j < vecPromiseRespuesta.length; j++) {
                    if(containsUndefinedInArray) break;
                    for (let i = 0; i < vecPromiseRespuesta[j].length; i++) {
                        if(vecPromiseRespuesta[j][i]===undefined){
                            containsUndefinedInArray = true;
                            // console.log('getListadosOr vecPromiseRespuesta containsUndefinedInArray', containsUndefinedInArray);
                            break;
                        }
                        arrayRespuesta.push(vecPromiseRespuesta[j][i]);
                    }
                    // console.log('getListadosOr vecPromiseRespuesta arrayRespuesta', arrayRespuesta);
                }
                
    
                if(containsUndefinedInArray==true){
                    resolve(undefined);
                }else{
                    resolve(arrayRespuesta);
                } 
            }).catch((error)=>{
                console.error('getListadosOr error', error)
                reject(error);
            });
        })
    }

}

