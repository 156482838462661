<div class="dropdown d-inline-block" *ngIf="appSettings.settings2.panel.funcionalidadesDisponibles.cotizaciones">
    <a class="dropdown-toggle no-caret pl-2 pr-2" id="flags-menu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
     
        <span  title="claseDinero.cotizacion | translate" 
               style="font-weight: bold;" 
               *ngIf="appSettings.settings2.cotizaciones.dolarOficial.api && appSettings.settings2.cotizaciones.dolarDivisa.api">
               {{cotizacionMercado?.dolarVenta| currency: 'USD'}} - {{cotizacionMercado?.dolarDivisaVenta| currency: 'U$sD'}}
        </span>

        <span  title="claseDinero.cotizacion | translate" 
               style="font-weight: bold;" 
               *ngIf="appSettings.settings2.cotizaciones.dolarOficial.api && appSettings.settings2.cotizaciones.dolarBlue.api">
               {{cotizacionMercado?.dolarVenta| currency: 'USD'}} - {{cotizacionMercado?.dolarBlueVenta| currency: 'U$sB'}}
        </span>
        
    </a>
    <a> <button type="button" 
                (click)='definirDolarVigente()'
                title="actualizar Cotización"
                class=" btn  btn-xs">
                <i class="fa fa-refresh" aria-hidden="true"></i>
        </button>
    </a>

    <div class="dropdown-menu dropdown-menu-right rounded-0 mt-3 p-0 box-shadow" aria-labelledby="flags-menu"
                style="width: 300px;">
        <div class="flags-menu">    
            <!-- Dolar Oficial -->
            <div class="row" 
                *ngIf="appSettings.settings2.cotizaciones.dolarOficial.api && msg.cacheColecciones['CotizacionesMonedasDolares']?.length>0"
                title="Último valor Grabado">
                
                <div class="col-4" style="padding-left: 5px;" >
                    <a class="dropdown-item" 
                        href="javascript:void(0);">
                        <span style="color:#444;">{{fn.mostrarKMONEY(msg.cacheColecciones['CotizacionesMonedasDolares'][0]['moneda1KMONEY'],'nombre')}}</span>
                    </a>
                    
                </div>
                <div class="col-2" style="padding-left: 5px;" >
                    <a class="dropdown-item" 
                        href="javascript:void(0);">
                        <span style="color:#444;">{{msg.cacheColecciones['CotizacionesMonedasDolares'][0].cotizacion  | number : '1.2-2' }}</span>
                    </a>
                </div>
                <div class="col-4" >
                    <a class="dropdown-item" style="padding-left: 5px;" 
                        href="javascript:void(0);">
                        <span style="color:#444;">{{msg.cacheColecciones['CotizacionesMonedasDolares'][0]['fechaHora']| date:"DD/MM/AAAA HH:MM":translate.currentLang:usuario.getUTC()}}</span>
                    </a>
                   
                </div>

            </div>
            
            <!-- Dolar Divisa -->
            <div class="row"
                *ngIf="appSettings.settings2.cotizaciones.dolarDivisa.api && msg.cacheColecciones['CotizacionesMonedasDolaresDivisa']?.length>0"
                title="Ultimo Valor Grabado">
            
                <div class="col-4" style="padding-left: 5px;" >
                    <a class="dropdown-item" 
                        href="javascript:void(0);">
                        <span style="color:#444;">{{fn.mostrarKMONEY(msg.cacheColecciones['CotizacionesMonedasDolaresDivisa'][0]['moneda1KMONEY'],'nombre')}}</span>
                    </a>
                    
                </div>
                <div class="col-2" style="padding-left: 5px;" >
                    <a class="dropdown-item" 
                        href="javascript:void(0);">
                        <span style="color:#444;">{{msg.cacheColecciones['CotizacionesMonedasDolaresDivisa'][0].cotizacion  | number : '1.2-2' }}</span>
                    </a>
                </div>
                <div class="col-4" >
                    <a class="dropdown-item"  style="padding-left: 5px;" 
                        href="javascript:void(0);">
                        <span style="color:#444;">{{msg.cacheColecciones['CotizacionesMonedasDolaresDivisa'][0]['fechaHora']| date:"DD/MM/AAAA HH:MM":translate.currentLang:usuario.getUTC()}}</span>
                    </a>
                </div>
            </div>

            <!-- Dolar Blue -->
            <div class="row"
                *ngIf="appSettings.settings2.cotizaciones.dolarBlue.api && msg.cacheColecciones['CotizacionesMonedasDolaresBlue']?.length"
                title="Ultimo Valor Grabado">
            
                <div class="col-4" style="padding-left: 5px;" >
                    <a class="dropdown-item" 
                        href="javascript:void(0);">
                        <span style="color:#444;">{{fn.mostrarKMONEY(msg.cacheColecciones['CotizacionesMonedasDolaresBlue'][0]['moneda1KMONEY'],'nombre')}}</span>
                    </a>
                    
                </div>
                <div class="col-2" style="padding-left: 5px;" >
                    <a class="dropdown-item" 
                        href="javascript:void(0);">
                        <span style="color:#444;">{{msg.cacheColecciones['CotizacionesMonedasDolaresBlue'][0].cotizacion  | number : '1.2-2' }}</span>
                    </a>
                </div>
                <div class="col-4" >
                    <a class="dropdown-item"  style="padding-left: 5px;" 
                        href="javascript:void(0);" >
                        <span style="color:#444;">{{msg.cacheColecciones['CotizacionesMonedasDolaresBlue'][0]['fechaHora']| date:"DD/MM/AAAA HH:MM":translate.currentLang:usuario.getUTC()}}</span>
                    </a>
                </div>
            </div>
            
        </div>
    </div>
</div>

