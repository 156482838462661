import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';

import { AppSettings2,  } from '@maq-models/appSettings/appSettings.model';
import { AppSettingsService } from '@settings/app.settings';
// environmet
import { environment }             from '../../../../../environments/environment';
@Component({
  selector: 'lib-shared-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit {

  public settings: AppSettings2=null;
  public version: string=environment.version;

 
  constructor(public appSettings:AppSettingsService,  ) {    
  }

  ngOnInit() {
    this.settings=this.appSettings.settings2;
  }

}
