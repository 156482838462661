import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivateChild } from '@angular/router';
import { AuthService } from '@maq-autorizacion/services/auth.service'
import { MensajesService }  from '@maq-servicios/mensajes/mensajes.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CanActivateChildViaAuthGuard  implements CanActivateChild{

  
    constructor(private authService: AuthService, private router: Router,private msg:MensajesService,) { }

    canActivateChild():Observable<boolean>|boolean { 
        // If the user is not logged in we'll send them back to the home page
        if (!this.authService.authenticated) {
            console.log('No estás logueado');
            this.router.navigate(['/login/login']);
            return false;
        }else {

     return this.msg.getPerfil().pipe(map(p=>{
              console.log('mensaje',p);
              return true;

         }));
    }



        
    }
}
