import { emojiAsustado } from './../../servicios/console/console';
export class Menu {

    
                public id                           : number;
                public title                        : null;
                public title_es                     : null;
                public title_en                     : null;
                public title_pt                     : null;
                public routerLink                   : string;
                public href                         : string;
                public icon                         : string;
                public target                       : string;
                public hasSubMenu                   : boolean;
                public parentId                     : number;
                public palabrasClaves               : null;
                public orden                        : number;
                public tipoOpcion                   : null;
                public anchors                      : null;
                public anchorName                   : null;
                public seccionPorcentajeColumnas    : string;
                public seccionColorFondo            : string;
                public seccionMostrarTitulo         : boolean;
                public aplicacion                   : string
    /*
                constructor(
                public id                           : number,
                public title                        : null,
                public title_es                     : null,
                public title_en                     : null,
                public title_pt                     : null,
                public routerLink                   : string,
                public href                         : string,
                public icon                         : string,
                public target                       : string,
                public hasSubMenu                   : boolean,
                public parentId                     : number,
                public palabrasClaves               : null,
                public orden                        : number,
                public tipoOpcion                   : null,
                public anchors                      : null,
                public anchorName                   : null,
                public seccionPorcentajeColumnas    : string,
                public seccionColorFondo            : string,
                public seccionMostrarTitulo         : boolean,
                public aplicacion                   : string
                )
                */
                constructor(init?:Partial<Menu>) {
                    Object.assign(this, init);
                }
    
    public eliminaFaIcon(){
        this.icon=this.icon.replace("fa fa-","");
    }
                

    public isNodeParent():boolean{
        return (this.parentId==0 ? true:false);
    }
    

} 
export interface MenuInterface {
    id                          : number;
    title                       : null;
    title_en                    : null;
    title_es                    : null;
    title_pt                    : null;
    routerLink                  : string;
    href                        : string;
    icon                        : string;
    target                      : string;
    hasSubMenu                  : boolean;
    parentId                    : number;
    palabrasClaves              : null;
    orden                       : number;
    tipoOpcion                  : null;
    anchors                     : null;
    anchorName                  : null;
    seccionPorcentajeColumnas   : string;
    seccionColorFondo           : string;
    seccionMostrarTitulo        : boolean;
    aplicacion                  : string;
    idTabla?                   : number;

                      
} 
