export class GeoPoint {  
  latitud:number;
  longitud:number;

  constructor(latitud? :number, longitud?:number) {
    this.latitud = latitud;
    this.longitud = longitud;    
  }

  public getLatitud():number {
    return this.latitud;
  }
  public getLongitud():number {
    return this.longitud;
  }

  getJson():object{
    return {latitud:this.latitud,longitud:this.longitud}
  }

  // isGeoPointValido():boolean{
  //   if(this.latitud==null || this.longitud==null ){
  //     return false;
  //   } else{
  //     return true;
  //   }
    

  // }

}
