import { log, logIf, logTable, values } from '@maq-console';

import { Component, OnInit, DoCheck, Input, Output, ViewEncapsulation, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-shared-paginado-servidor',
  templateUrl: './paginado-servidor.component.html',
  styleUrls: [ './paginado-servidor.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class PaginadoServidorComponent implements OnInit, DoCheck {

    @Input() public grilla: any; 

    @Output() clickPaginado= new EventEmitter<string>();

    public traduccion:any={};

    public hayPaginaAnterior  = false;
    public hayPaginaSiguiente = false;

    constructor(private translate: TranslateService) { 
    }

    ngOnInit() {
       // log(...values("valores","paginado-servidor ngOnInit - grilla:",this.grilla));
        // log(...values("valores","form-buttons - permisos:",this.permisos));
        this.inicializarVariablesTraducibles();
        this.translate.onLangChange.subscribe(() => {
            // console.log('cambio idioma en libreriaB');
             this.inicializarVariablesTraducibles();
        });

    }  

    ngDoCheck() {
        // log(...values("valores","paginado-servidor ngDoCheck - grilla:",this.grilla));

        this.hayPaginaAnterior  = false;
        this.hayPaginaSiguiente = false;
        if(this.grilla.paginaActualNro>1) {
            this.hayPaginaAnterior  = true;
        }
        if(this.grilla.paginadoCantidad == this.grilla.resultCantidad) {
            if(this.grilla.coleccionCantidadDocs) {
                let cantDocumentosMostrados = (this.grilla.paginaActualNro-1) * this.grilla.paginadoCantidad 
                                              + this.grilla.resultCantidad;
                // log(...values("valores","grilla.resultCantidad:",this.grilla.resultCantidad));                              
                // log(...values("valores","documentosMostrados:",cantDocumentosMostrados,"grilla.coleccionCantidadDocs:",this.grilla.coleccionCantidadDocs));
                if(cantDocumentosMostrados < this.grilla.coleccionCantidadDocs) {
                    this.hayPaginaSiguiente  = true;        
                }                         

            } else {
                this.hayPaginaSiguiente  = true;    
            }            
        }

    }

    onClickPaginado(paginaRequerida:string):any {
        if(paginaRequerida=='anterior' && this.grilla.paginaOverflow) {
            paginaRequerida='anteriorOverflow';
        }
        this.clickPaginado.emit(paginaRequerida);
    }

    inicializarVariablesTraducibles(){
        
        this.traduccion['componentePaginado']={
            anterior:       this.translate.instant('componente-paginado.anterior'),
            siguiente:      this.translate.instant('componente-paginado.siguiente'),
            cantDocumentos: this.translate.instant('componente-paginado.cantDocumentos'),
                   
        }
        // console.log('cambio idioma en libreria B, variable Traducibles', this.traduccion);
    }      

}  
  
