import { Menu } from './../../../models/menu/menu.model';
import { Component, OnInit, ViewEncapsulation, HostListener } from '@angular/core';
import { trigger,  state,  style, transition, animate } from '@angular/animations';
import { AppSettingsService } from '@settings/app.settings';


import { MenuService } from '@maq-servicios/menu/menu.service';
import { AuthService } from '@maq-autorizacion/services/auth.service';
import { LicenciasService }  from '@maq-servicios/licencias/licencias.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ MenuService ],
  animations: [
    trigger('showInfo', [
      state('1' , style({ transform: 'rotate(180deg)' })),
      state('0', style({ transform: 'rotate(0deg)' })),
      transition('1 => 0', animate('400ms')),
      transition('0 => 1', animate('400ms'))
    ])
  ]
})
export class HeaderComponent implements OnInit {
  public BuscadorSearchPlaceHolder = 'multiSelectTexts.searchPlaceholder';
  public showHorizontalMenu:boolean = true; 
  public showInfoContent:boolean = false;
  public seSuperoLimiteLicenciaPermitidasDuranteElMes:boolean=false;


  public menuItems:Array<Menu>;
  constructor(public appSettings:AppSettingsService, 
              public menuService:MenuService,
              public authService:AuthService,
              public licencias:LicenciasService
             
              ) {
       
     
    
      // this.menuItems = this.menuService.getHorizontalMenuItems();
  }
  
  ngOnInit() {
    if(window.innerWidth <= 768) {
     this.showHorizontalMenu = false;
    }
    this.seSuperoLimiteLicenciaPermitidasDuranteElMes=this.licencias.seSuperoLimiteLicenciaPermitidasDuranteElMes();
  }

 

  public closeSubMenus(){
    let menu = document.querySelector("#menu0");
     
    if(menu){
      for (let i = 0; i < menu.children.length; i++) {
          let child = menu.children[i].children[1];
          if(child){          
              if(child.classList.contains('show')){            
                child.classList.remove('show');
                menu.children[i].children[0].classList.add('collapsed'); 
              }             
          }
      }
    }
  }

  @HostListener('window:resize')
  public onWindowResize():void {
     if(window.innerWidth <= 768){
        this.showHorizontalMenu = false;
     }      
      else{
        this.showHorizontalMenu = true;
      }
  }
  
 logOut(){
   console.log('logout');
   this.authService.signOut();
 } 


}
