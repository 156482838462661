import { KN } from "@settings/maqueta/models/typesKN/typesKN.model";

export const LISTA_DE_APLICACIONES: any[]=[
      {id:  null,               val: ' No Indicado',        },
      {id: 'pkMobilityWeb',     val: 'PK Mobility Web',     },
      {id: 'pkMobilityApp',     val: 'PK Mobility APP',     },
  ];

export function getAplicacion(id:string, cual:string):string {
  for(let i=0; i<LISTA_DE_APLICACIONES.length; i++) {
    if(LISTA_DE_APLICACIONES[i].id==id) {
      if(cual=='nombre') {  
        return LISTA_DE_APLICACIONES[i].val;
      }  
    }
  }
  return '';
};

export const LISTA_DE_MODULOS:any[]=[
      {id:  null,               val: ' No Indicado',         aplicaciones:['pkMobilityWeb','pkMobilityApp'],  colecciones: [] },
      {id: 'Login',             val: 'menu.login',           aplicaciones:['pkMobilityWeb','pkMobilityApp'],  colecciones: ['Usuarios'] },
      {id: 'Usuarios',          val: 'menu.usuarios',        aplicaciones:['pkMobilityWeb'],                  colecciones: ['Usuarios'] },
      {id: 'Perfiles',          val: 'menu.perfiles',        aplicaciones:['pkMobilityWeb'],                  colecciones: ['Perfiles'] },
      {id: 'Distribuidores',    val: 'menu.distribuidores',  aplicaciones:['pkMobilityWeb'],                  colecciones: ['Distribuidores'] },
      {id: 'Organizaciones',    val: 'menu.organizaciones',  aplicaciones:['pkMobilityWeb'],                  colecciones: ['Organizaciones'] },
      {id: 'Sucursales',        val: 'menu.sucursales',      aplicaciones:['pkMobilityWeb'],                  colecciones: ['Sucursales'] },
      {id: 'Ubicaciones',       val: 'menu.ubicaciones',     aplicaciones:['pkMobilityWeb'],                  colecciones: ['Ubicaciones'] },
      {id: 'AreasNegocio',      val: 'menu.areasNegocio',    aplicaciones:['pkMobilityWeb'],                  colecciones: ['AreasNegocio'] },
      {id: 'Rutas',             val: 'menu.rutas',           aplicaciones:['pkMobilityWeb'],                  colecciones: ['Rutas'] },
      {id: 'Tickets',           val: 'menu.tickets',         aplicaciones:['pkMobilityWeb'],                  colecciones: ['Tickets'] },
      {id: 'LogsAuditoria',     val: 'menu.logsAuditoria',   aplicaciones:['pkMobilityWeb'],                  colecciones: ['LogsAuditoria'] },
      {id: 'conducirRuta',      val: 'menu.conducirRuta',    aplicaciones:['pkMobilityApp'],                  colecciones: ['Rutas'] },
  ];

// Lo agrego a mano en el módulo de Tickets con push
// {id: 'Aplicacion',        val: ' Aplicación',          aplicaciones:['pkMobilityWeb','pkMobilityApp'],  colecciones: [] },

export function getModulosPorAplicacion(aplicacion:string):any {
  let listadoModulos=[];
  for(let i=0; i<LISTA_DE_MODULOS.length; i++) {
    //console.log("LISTA_DE_MODULOS[i].val",LISTA_DE_MODULOS[i].val);
    if(LISTA_DE_MODULOS[i].id==null ||
       LISTA_DE_MODULOS[i].aplicaciones.indexOf(aplicacion)!=-1) {

        listadoModulos.push(LISTA_DE_MODULOS[i]);
    }    
  }
  return listadoModulos;
};

export function getModulo(id:string, cual:string):string {
  for(let i=0; i<LISTA_DE_MODULOS.length; i++) {
    //console.log("getModulo",i,LISTA_DE_MODULOS[i].id,id,LISTA_DE_MODULOS[i].id==id);
    if(LISTA_DE_MODULOS[i].id==id) {
      if(cual=='nombre') {  
        return LISTA_DE_MODULOS[i].val;
      } else if(cual=='colecciones') {  
        return LISTA_DE_MODULOS[i].colecciones;
      }  
    }
  }
  if(id=='Aplicacion') {  // Parche módulo de Tickets, se agrega este valor además de los de la lista como opción
    return (cual=='nombre') ? ' Aplicación' : 'Aplicacion';
  }
  return '';
};

export const LISTA_DE_BATCH_SI_NO:any[]=[
      {id: null,      val:'' },
      {id:'firestore',val:'Batch' },
      {id:'usuario',  val:'Usuario'},
  ];

export const LISTA_DE_PAISES:any[]=[
      {id: null,       val:' No Indicado','husosHorarios': null },
      {id:'Argentina', val:'Argentina',  'husosHorarios': ['UTC-3'] },
      {id:'Chile',     val:'Chile',      'husosHorarios': ['UTC-3','UTC-4'] },  /* En verano se adelanta a UTC-3 */
      {id:'Brasil',    val:'Brasil',     'husosHorarios': ['UTC-3','UTC-4'] },
      {id:'Colombia',  val:'Colombia',   'husosHorarios': ['UTC-5'] },
  ];

export function husoHorarioDisponibleXPais(husoHorario:string, pais:string):boolean {
  //console.log("husoHorarioDisponibleXPais",husoHorario,pais);
  for (var i = 0; i < LISTA_DE_PAISES.length; i++) {
     if(LISTA_DE_PAISES[i].val==pais) {
       if(LISTA_DE_PAISES[i].husosHorarios.indexOf(husoHorario)!=-1) {
         return true;
       }
     }  
  }  
  return false; 
}

export const LISTA_DE_PROVINCIAS:any[]=[
      { id:null, nombre:' No Indicada', idPais:null },

      { id:'CABA',         nombre:'CABA',         idPais:'Argentina' },
      { id:'Buenos Aires', nombre:'Buenos Aires', idPais:'Argentina' },
      { id:'Córdoba',      nombre:'Córdoba',      idPais:'Argentina' },
      { id:'Santa Fe',     nombre:'Santa Fe',     idPais:'Argentina' },
      { id:'La Pampa',     nombre:'La Pampa',     idPais:'Argentina' },

      { id:'Santa Catarina',     nombre:'Santa Catarina',     idPais:'Brasil' },
      { id:'San Pablo',          nombre:'San Pablo',          idPais:'Brasil' },
      { id:'Río Grande do Sul',  nombre:'Río Grande do Sul',  idPais:'Brasil' },
      { id:'Paraná',             nombre:'Paraná',             idPais:'Brasil' },
      { id:'Mato Grosso do Sul', nombre:'Mato Grosso do Sul', idPais:'Brasil' },
      { id:'Río de Janeiro',     nombre:'Río de Janeiro',     idPais:'Brasil' },
  ];

export function getProvinciasPorPais(pais:string):any {
  console.log("getProvinciasPorPais",pais)
  let rta=[];
  if(pais==null || pais==undefined || pais=='') {
    for(let i=0; i<LISTA_DE_PROVINCIAS.length; i++) {
      if(LISTA_DE_PROVINCIAS[i].idPais!=null) {
        rta.push({
          'id'     : LISTA_DE_PROVINCIAS[i].id,
          'nombre' : LISTA_DE_PROVINCIAS[i].idPais + ' - ' + LISTA_DE_PROVINCIAS[i].nombre,
          'idPais' : LISTA_DE_PROVINCIAS[i].idPais,
        });  
      } else {
        rta.push(LISTA_DE_PROVINCIAS[i]);
      } 
    }
  } else {
    for(let i=0; i<LISTA_DE_PROVINCIAS.length; i++) {
      if(LISTA_DE_PROVINCIAS[i].idPais==pais || LISTA_DE_PROVINCIAS[i].idPais==null) {
        rta.push(LISTA_DE_PROVINCIAS[i]);
      }
    }
  }  
  console.log("rta",rta);
  return rta;
};

export function getProvinciasPorPaisCombinadas():any {
  let anterior:'';
  let rta=[];
  for(let i=0; i<LISTA_DE_PROVINCIAS.length; i++) {
    let pais = LISTA_DE_PROVINCIAS[i].idPais;
    if(pais!=null) {
        if(pais!=anterior) {
          anterior=pais;
          rta.push({
            'id'     : pais,            
            'name'   : pais,            
            'isLabel': true
          });
        }
        rta.push({
          'id'      : LISTA_DE_PROVINCIAS[i].id,            
          'name'    : LISTA_DE_PROVINCIAS[i].nombre,            
          'parentId': pais
        });
    }  
  }
  console.log("rta",rta);
  return rta;
};

export const LISTA_DE_TIPOS_DE_TELEFONOS:any[]=[
      {id:null,val:' No Indicado' },
      {id:'celular',val:'Celular' },
      {id:'particular',val:'Particular'},
      {id:'laboral',val:'Laboral'},
      {id:'contacto',val:'Persona de Contacto'}
  ];


export const LISTA_DE_HUSOS_HORARIOS:any[]=[
      {id:null    ,val:' No Indicado'},
      {id:'UTC+12',val:'UTC +12'},
      {id:'UTC+11',val:'UTC +11'},
      {id:'UTC+10',val:'UTC +10'},
      {id:'UTC+9' ,val:'UTC +09'},
      {id:'UTC+8' ,val:'UTC +08'},
      {id:'UTC+7' ,val:'UTC +07'},
      {id:'UTC+6' ,val:'UTC +06'},
      {id:'UTC+5' ,val:'UTC +05'},
      {id:'UTC+4' ,val:'UTC +04'},
      {id:'UTC+3' ,val:'UTC +03'},
      {id:'UTC+2' ,val:'UTC +02'},
      {id:'UTC+1' ,val:'UTC +01'},
      {id:'UTC+0' ,val:'UTC 0'  },
      {id:'UTC-1' ,val:'UTC -01'},
      {id:'UTC-2' ,val:'UTC -02'},
      {id:'UTC-3' ,val:'UTC -03'},
      {id:'UTC-4' ,val:'UTC -04'},
      {id:'UTC-5' ,val:'UTC -05'},
      {id:'UTC-6' ,val:'UTC -06'},
      {id:'UTC-7' ,val:'UTC -07'},
      {id:'UTC-8' ,val:'UTC -08'},
      {id:'UTC-9' ,val:'UTC -09'},
      {id:'UTC-10',val:'UTC -10'},
      {id:'UTC-11',val:'UTC -11'},
      {id:'UTC-12',val:'UTC -12'},      
  ];

export const LISTA_TIPOS_DE_UBICACION:KN[]=[
      {key:'DPT',nombre:'moduloRutas.tipoUbicacionDPT' },
      {key:'SIT',nombre:'moduloRutas.tipoUbicacionSIT'},
      {key:'LD', nombre:'moduloRutas.tipoUbicacionLD' },
      {key:'PER',nombre:'moduloRutas.tipoUbicacionPER'},
  ];

export function getTipoUbicacion(key:string):string {
  for(let i=0; i<LISTA_TIPOS_DE_UBICACION.length; i++) {
    if(LISTA_TIPOS_DE_UBICACION[i].key==key) {
      return LISTA_TIPOS_DE_UBICACION[i].nombre;
    }
  }
  return '';
};

export const LISTA_RUBROS_DE_ORGANIZACIONES:any[]=[
      {id:null,val:' No Indicado' },
      {id:'transporte',val:'Transporte' },
      {id:'gastronomico',val:'Gastronómico'},
      {id:'seguridad',val:'Seguridad'},
      {id:'agricolaGanadera',val:'Agrícola Ganadera'},
  ];

export function getRubroEmpresa(id:string):string {
  for(let i=0; i<LISTA_RUBROS_DE_ORGANIZACIONES.length; i++) {
    if(LISTA_RUBROS_DE_ORGANIZACIONES[i].id==id) {
      return LISTA_RUBROS_DE_ORGANIZACIONES[i].val;
    }
  }
  return '';
};

