<nav logAuthclass="navbar box-shadow app-header" [class.fixed-top]="appSettings.settings2.panel.navbarIsFixed" [class.logo-visible]="showInfoContent">
    <div class="d-flex flex-column w-100">
        <div id="info-content" class="panel-collapse collapse">
            <a class="close" data-toggle="collapse" href="#info-content" (click)="showInfoContent = !showInfoContent" [class.show]="showInfoContent">
                <span aria-hidden="true">&times;</span>
            </a>
            <div class="row align-items-center">
                <div class="col-md-5">
                    <div class="d-flex justify-content-center align-items-center pt-md-0 pt-3 info">
                        <!-- <img src="assets/img/users/user.jpg" class="rounded-circle user-img">  -->

            
                <img *ngIf="authService.hayImage" src="{{authService.currentUserPhotoURL}}" alt="user-img" class="user-img rounded-circle"> 
                    <!-- </div>                -->
                        
                        <div class="d-flex flex-column pl-3">
                            <!-- <h5 class="mb-0">Emilio Verdines <i class="fa fa-check-circle fs-13 text-success ml-2"></i></h5> -->
                            <h5 class="mb-0"> {{authService.currentUserDisplayName}} <i class="fa fa-check-circle fs-13 text-success ml-2"></i></h5>
                            <span>Project manager</span>
                            <div class="pt-3">
                                <a routerLink="/profile" class="btn btn-success mr-1"translate>claseFicha.perfil </a> 

                                <a routerLink="/users/user-info" class="btn btn-gray" translate>navegabilidad.editar</a> 
                            
                            </div>                            
                        </div>
                    </div>
                </div>

                <!-- Mensaje central -->

                <div class="col-md-7">
                    <div class="row py-4">                        
                        <div class="col-lg-7 col-sm-8 col-12 left-border">
                            <div class="info w-280p mx-auto text-center">
                                <form>
                                    <div class="form-group">
                                        <input type="text" class="form-control form-control-sm" placeholder="Recipients">
                                    </div>
                                    <div class="form-group">
                                        <input type="text" class="form-control form-control-sm" placeholder="Subject">
                                    </div>
                                    <div class="form-group">
                                        <textarea class="form-control form-control-sm" placeholder="Message" rows="3"></textarea>
                                    </div>
                                    <button type="submit" class="btn btn-sm btn-gray"><i class="fa fa-paper-plane mr-2"></i>Send</button>
                                </form>
                            </div>                           
                        </div>


                    <!-- Opciones derecha -->

                        <div class="col-lg-5 col-sm-4 left-border d-none d-sm-block">
                            <div class="info w-100p mx-auto">
                                <div class="custom-control custom-checkbox mb-1">
                                    <input type="checkbox" class="custom-control-input" id="checkboxNotifications" checked>
                                    <label class="custom-control-label" for="checkboxNotifications">Notifications</label>
                                </div>
                                <div class="custom-control custom-checkbox mb-1">
                                    <input type="checkbox" class="custom-control-input" id="checkboxTasks" checked>
                                    <label class="custom-control-label" for="checkboxTasks">Tasks</label>
                                </div>
                                <div class="custom-control custom-checkbox mb-1">
                                    <input type="checkbox" class="custom-control-input" id="checkboxEvents">
                                    <label class="custom-control-label" for="checkboxEvents">Events</label>
                                </div>
                                <div class="custom-control custom-checkbox mb-1">
                                    <input type="checkbox" class="custom-control-input" id="checkboxDownloads" checked>
                                    <label class="custom-control-label" for="checkboxDownloads">Downloads</label>
                                </div>
                                <div class="custom-control custom-checkbox mb-1">
                                    <input type="checkbox" class="custom-control-input" id="checkboxMessages" checked>
                                    <label class="custom-control-label" for="checkboxMessages">Messages</label>
                                </div>
                                <div class="custom-control custom-checkbox mb-1">
                                    <input type="checkbox" class="custom-control-input" id="checkboxUpdates">
                                    <label class="custom-control-label" for="checkboxUpdates">Updates</label>
                                </div>
                                <div class="custom-control custom-checkbox mb-1">
                                    <input type="checkbox" class="custom-control-input" id="checkboxSettings" checked>
                                    <label class="custom-control-label" for="checkboxSettings">Settings</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- Borde Inferior (subMenú)-->

        <div class="d-flex justify-content-start align-items-center top-navbar">                  
            <!-- Logo -->
            <a class="logo" routerLink="/" (click)="closeSubMenus()">
<!--            <span class="start">start</span><span class="ng">NG</span> -->
                <!-- <span class="start">aaa{{appSettings.settings2.panel.headerTitle}}</span><span class="ng"></span> -->
                <!-- <span class="start" *ngIf="appSettings.settings2.panel.headerTitle=='PKMOVIL22'">Prueba</span><span class="ng"></span> -->
                <!-- <span class="start" *ngIf="appSettings.settings2.panel.headerTitle!='PKMOVIL' && appSettings.settings2.panel.headerTitle!='HYDEAL'">&nbsp;{{appSettings.settings2.panel.headerTitle}}</span><span class="ng"></span>                         -->
                <img [src]="appSettings.settings2.cliente.logos.panelHeader" style="height:45px;"
                     *ngIf="appSettings.settings2.panel.headerTitle=='kipbip'">
                <img [src]="appSettings.settings2.cliente.logos.panelHeader" style="height:45px;"
                     *ngIf="appSettings.settings2.panel.headerTitle=='PKMOVIL'">
                <img [src]="appSettings.settings2.cliente.logos.panelHeader" style="height:45px;"
                     *ngIf="appSettings.settings2.panel.headerTitle=='HYDEAL'">
            </a>

            <span class="navbar-text menu-icon transition ml-3 mr-3" 
                [ngClass]="{'open': appSettings.settings2.panel.showMenu}"
                (click)="appSettings.settings2.panel.showMenu = !appSettings.settings2.panel.showMenu">
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
            </span>

            <!-- SE OCULTÓ PORQUE NO SE USA -->

            <!-- <div class="ml-2 mr-2 d-none d-sm-block">
                 <a id="info-content-icon" data-toggle="collapse" href="#info-content" (click)="showInfoContent = !showInfoContent" class="d-flex" [class.show]="showInfoContent">
                    <div [@showInfo]="showInfoContent" class="triangle"></div>
                </a>
            </div> -->

           
            <!-- SE OCULTÓ PORQUE NO SE USA -->
            
            <!-- <form class="app-search d-none d-md-inline-block ml-3">
                <i class="fa fa-search"></i>
                <input type="text" placeholder="{{BuscadorSearchPlaceHolder | translate}}" class="form-control">
            </form> -->

            <lib-shared-favorites class="d-none d-lg-inline-block"></lib-shared-favorites>
            <div *ngIf="seSuperoLimiteLicenciaPermitidasDuranteElMes" class="licenciasExcedidas" translate>moduloRutas.alertaLicenciasmensaje</div>
           
            
            <div class="top-rigth-icons ml-auto">
                <span class="d-none d-sm-inline-block" *ngIf="appSettings.settings2.panel.funcionalidadesDisponibles.mostrarOnLine">
                    <lib-shared-is-on-line></lib-shared-is-on-line>
                </span>  
                <span class="d-none d-sm-inline-block" *ngIf="appSettings.settings2.panel.funcionalidadesDisponibles.cotizaciones">
                    <lib-shared-cotizaciones></lib-shared-cotizaciones>
                </span> 
                <span class="d-none d-sm-inline-block" *ngIf="appSettings.settings2.panel.funcionalidadesDisponibles.traductions">
                    <lib-shared-flags-menu></lib-shared-flags-menu>
                </span> 
                <span class="d-none d-sm-inline-block" *ngIf="appSettings.settings2.panel.funcionalidadesDisponibles.fullscreen" >
                    <lib-shared-fullscreen></lib-shared-fullscreen>
                </span> 
                <!--
                <span class="d-none d-sm-inline-block" *ngIf="appSettings.settings2.panel.funcionalidadesDisponibles.applications">
                    <lib-shared-applications></lib-shared-applications>                    
                </span> 
                <span class="d-none d-sm-inline-block" *ngIf="appSettings.settings2.panel.funcionalidadesDisponibles.messages">
                    <lib-shared-messages></lib-shared-messages>
                </span>               
                <span class="d-none d-sm-inline-block pl-2 pr-2" (click)="appSettings.settings2.panel.showSideChat = !appSettings.settings2.panel.showSideChat" *ngIf="appSettings.settings2.panel.funcionalidadesDisponibles.sideChat">
                    <i class="fa fa-commenting-o" aria-hidden="true"></i>
                </span> 
                -->
                <span class="d-none d-sm-inline-block" *ngIf="appSettings.settings2.panel.funcionalidadesDisponibles.userMenu">
                    <app-user-menu></app-user-menu>
                </span> 
                <span class="pl-2 pr-2" (click)="logOut()">
                    <a >
                    <!-- <a routerLink="/login"> -->
                        <i class="fa fa-sign-out" aria-hidden="true"></i>
                    </a>                   
                </span> 
            </div> 

        </div>

        <div class="horizontal-menu-outer" *ngIf="appSettings.settings2.panel.menu == 'horizontal'">                   
            <lib-shared-horizontal-menu [menuItems]="menuItems"></lib-shared-horizontal-menu>
        </div>

    </div>
</nav>