export enum TIPO_AGRUPADOR {
    DIA =    '1',
    SEMANA = '2',
    MES =    '3',
};

export enum TABLERO_CONTROL{
  UNIVERSO_RUTAS =        'Universo Rutas',
  UNIVERSO_CHOFERES =     'Universo Choferes',
  UNIVERSO_UBICACIONES =  'Universo Ubicaciones',
};


export const AGRUPADORES_FECHAS: any[]=[
  {key:TIPO_AGRUPADOR.DIA,    nombre: 'agrupadorPeriodos.dia'},
  {key:TIPO_AGRUPADOR.SEMANA, nombre:'agrupadorPeriodos.semana'}, 
  {key:TIPO_AGRUPADOR.MES,    nombre:'agrupadorPeriodos.mes'}
];

export enum RANGO_FECHAS {
  HOY = 'Hoy',
  AYER = 'Ayer',
  // ESTA_SEMANA = 'EstaSemana',
  MES_ACTUAL = 'MesActual',
  MES_PASADO = 'MesPasado',
  ULTIMOS_7_DIAS = 'Ultimos7Dias',
  ULTIMOS_10_DIAS = 'Ultimos10Dias',
  ULTIMOS_15_DIAS = 'Ultimos15Dias',
  ULTIMOS_30_DIAS = 'Ultimos30Dias',
  ULTIMOS_90_DIAS = 'Ultimos90Dias',
  ULTIMOS_12_MESES = 'Ultimos12Meses',
  ESTE_ANIO = 'EsteAnio',
  EL_ANIO_PASADO = 'ElAnioPasado',
  PERSONALIZADO  = 'Personalizado',
};



export enum  ORDER_GRAFICO{
  SIN_ORDENAR = 'Sin Ordenar',
  MAYOR_A_MENOR = 'Mayor A Menor',
  MENOR_A_MAYOR = 'Menor A Mayor',
};

export const ORDER_GRAFICOS: any[]=[
  {key:ORDER_GRAFICO.SIN_ORDENAR, nombre:'sinOrdenar'},
  {key:ORDER_GRAFICO.MAYOR_A_MENOR, nombre:'mayorAMenor'},
  {key:ORDER_GRAFICO.MENOR_A_MAYOR, nombre:'menorAMayor'},
];

export enum  TIPO_GRAFICO{
  VALORES = 'Valores',
  PORCENTAJE = 'Porcentaje',
  AGRUPADO_POR_CHOFER = 'Agrupado por Chofer',
  AGRUPADO_POR_UBICACION = 'Agrupado por Ubicacion',
  TODOS = 'Todos',
};

export const TIPOS_GRAFICOS: any[]=[
  {key:TIPO_GRAFICO.TODOS, nombre:'moduloGraficos.tipoGraficoTodos'},
  {key:TIPO_GRAFICO.VALORES, nombre:'moduloGraficos.tipoGraficoValores'},
  {key:TIPO_GRAFICO.PORCENTAJE, nombre:'moduloGraficos.tipoGraficoPorcentaje'},
  {key:TIPO_GRAFICO.AGRUPADO_POR_CHOFER, nombre:'moduloGraficos.tipoGraficoAgrupadoPorChoferes'},
];

export enum NOMBRE_COLUMNAS_PORCENTAJES{
  PORCENTAJE_RUTAS_INICIADAS = 'porcentajeRutasIniciadas',
  PORCENTAJE_PARADAS_INICIADAS = 'porcentajeParadasIniciadas',
  // TRADUCCIONES A AGREGAR | CORREGIR
  PORCENTAJE_ENTREGADO_UNIDAD_1 = 'porcentajeEntregadoUnidad1',
  PORCENTAJE_ENTREGADO_UNIDAD_2 = 'porcentajeEntregadoUnidad2',
  PORCENTAJE_ENTREGADO_UNIDAD_3 = 'porcentajeEntregadoUnidad3',
  PORCENTAJE_RETIRADO_UNIDAD_1 = 'porcentajeRetiradoUnidad1',
  PORCENTAJE_RETIRADO_UNIDAD_2 = 'porcentajeRetiradoUnidad2',
  PORCENTAJE_RETIRADO_UNIDAD_3 = 'porcentajeRetiradoUnidad3',
  PORCENTAJE_DISTANCIA = 'porcentajeDistancia',
  PORCENTAJE_PARADAS_ENTREGADAS_EN_TIEMPO = 'porcentajeParadasEntregadasEnTiempo',
  PORCENTAJE_ESTADOS_PARADAS = 'porcentajeEstadosParadas',
};

export enum NUMERO_ASOCIADO_NOMBRE_COLUMNA_PORCENTAJE{
  PORCENTAJE_RUTAS_INICIADAS = 0,
  PORCENTAJE_PARADAS_INICIADAS = 1,
  PORCENTAJE_DISTANCIA = 2,
  PORCENTAJE_PARADAS_ENTREGADAS_EN_TIEMPO = 3,
  PORCENTAJE_ENTREGADO_UNIDAD_1 = 4,
  PORCENTAJE_ENTREGADO_UNIDAD_2 = 5,
  PORCENTAJE_ENTREGADO_UNIDAD_3 = 6,
  PORCENTAJE_RETIRADO_UNIDAD_1 = 7,
  PORCENTAJE_RETIRADO_UNIDAD_2 = 8,
  PORCENTAJE_RETIRADO_UNIDAD_3 = 9,
  PORCENTAJE_ESTADOS_PARADAS = 10
};

export enum NUMERO_ASOCIADO_TIPO_GRAFICO{
  VALORES_PLAN = 0,
  VALORES_REAL = 1,
  PORCENTAJE = 2,
};

export enum NUMERO_ASOCIADO_NOMBRE_GRAFICO{
  RUTAS = 0,
  PARADAS = 1,
  PARADAS_PROCESADAS = 2,
  ENTREGAR_UNIDAD1 = 3,
  ENTREGAR_UNIDAD2 = 4,
  ENTREGAR_UNIDAD3 = 5,
  RETIRAR_UNIDAD1 = 6,
  RETIRAR_UNIDAD2 = 7,
  RETIRAR_UNIDAD3 = 8,
  DISTANCIA = 9,
};

export enum MODULO_TRADUCCION {
  MODULO_GRAFICOS = 'moduloGraficos',
  MODULO_INDICADOR_PORCENTAJE = 'moduloIndicadorPorcentaje',
  ESTADO_PARADA = 'estadoParada',
  MODULO_RUTAS = 'moduloRutas',
};

export enum NUMERO_ASOCIADO_ESTADOS_PARADAS{
  CANCELADA = 0,
  RECHAZADA = 1,
  PARADA_PENDIENTE = 2,
  ENTREGA_COMPLETA = 3,
  ENTREGA_PARCIAL = 4,
  PARADA_EN_CURSO = 5,
  REPLANIFICADA = 6,
}

export enum NOMBRE_TRADUCCION {
  NO_EXISTEN_MOTIVOS = "noExistenMotivos",
  RUTAS = 'rutas',
  UNIDADES_ENTREGADAS = 'unidadesEntregadas',
  UNIDADES_RETIRADAS = 'unidadesRetiradas',
  PARADAS = 'paradas',
  RECHAZADA = 'rechazada',
  PARADA_PENDIENTE = 'paradaPendiente',
  CANCELADA = 'cancelada',
  PARADA_EN_CURSO = 'paradaEnCurso',
  ENTREGA_PARCIAL = 'entregaParcial',
  ENTREGA_COMPLETA = 'entregaCompleta',
  REPLANIFICADA = 'replanificada',
  CHOFERES = 'tipoGraficoAgrupadoPorChoferes',
  CLIENTES = 'tipoGraficoAgrupadoPorClientes',
  VALORES = 'tipoGraficoValores',
  PORCENTAJE = 'tipoGraficoPorcentaje',
  AMBOS = "ambos",
  RUTAS_INICIADAS_REAL = 'rutasIniciadasReal',
  RUTAS_INICIADAS_PLAN = 'rutasIniciadasPlan',
  RUTAS_REAL_VS_PLAN = 'rutasRealvsPlan',
  RUTAS_INICIADAS_PORCENTAJE = 'rutasIniciadasPorcentaje',
  RUTAS_PORCENTAJE_REAL_VS_PLAN = 'rutasPorcentajeRealvsPlan',
  PARADAS_INICIADAS_REAL = 'paradasIniciadasReal',
  PARADAS_INICIADAS_PLAN = 'paradasIniciadasPlan',
  PARADAS_REAL_VS_PLAN = 'paradasRealvsPlan',
  PARADAS_INICIADAS_PORCENTAJE = 'paradasIniciadasPorcentaje',
  PARADAS_PORCENTAJE_REAL_VS_PLAN = 'paradasPorcentajeRealvsPlan',
  //TRADUCCIONES A CORREGIR
  // UNIDAD1_REAL = 'unidad1Real',
  // UNIDAD1_PLAN = 'unidad1Plan',
  // UNIDAD1_REAL_VS_PLAN = 'unidad1RealvsPlan',
  // UNIDAD1_PORCENTAJE = 'unidad1Porcentaje',
  // UNIDAD1_PORCENTAJE_REAL_VS_PLAN = 'unidad1PorcentajeRealvsPlan',
  // UNIDAD2_REAL = 'unidad2Real',
  // UNIDAD2_PLAN = 'unidad2Plan',
  // UNIDAD2_REAL_VS_PLAN = 'unidad2RealvsPlan',
  // UNIDAD2_PORCENTAJE = 'unidad2Porcentaje',
  // UNIDAD2_PORCENTAJE_REAL_VS_PLAN = 'unidad2PorcentajeRealvsPlan',
  // UNIDAD3_REAL = 'unidad3Real',
  // UNIDAD3_PLAN = 'unidad3Plan',
  // UNIDAD3_REAL_VS_PLAN = 'unidad3RealvsPlan',
  // UNIDAD3_PORCENTAJE = 'unidad3Porcentaje',
  // UNIDAD3_PORCENTAJE_REAL_VS_PLAN = 'unidad3PorcentajeRealvsPlan',

  // AGREGAR ESTAS TRADUCCIONES
  ENTREGADO_UNIDAD1_REAL = 'entregadoUnidad1Real',
  ENTREGADO_UNIDAD1_PLAN = 'entregadoUnidad1Plan',
  ENTREGADO_UNIDAD1_REAL_VS_PLAN = 'entregadoUnidad1RealvsPlan',
  ENTREGADO_UNIDAD1_PORCENTAJE = 'entregadoUnidad1Porcentaje',
  ENTREGADO_UNIDAD1_PORCENTAJE_REAL_VS_PLAN = 'entregadoUnidad1PorcentajeRealvsPlan',
  ENTREGADO_UNIDAD2_REAL = 'entregadoUnidad2Real',
  ENTREGADO_UNIDAD2_PLAN = 'entregadoUnidad2Plan',
  ENTREGADO_UNIDAD2_REAL_VS_PLAN = 'entregadoUnidad2RealvsPlan',
  ENTREGADO_UNIDAD2_PORCENTAJE = 'entregadoUnidad2Porcentaje',
  ENTREGADO_UNIDAD2_PORCENTAJE_REAL_VS_PLAN = 'entregadoUnidad2PorcentajeRealvsPlan',
  ENTREGADO_UNIDAD3_REAL = 'entregadoUnidad3Real',
  ENTREGADO_UNIDAD3_PLAN = 'entregadoUnidad3Plan',
  ENTREGADO_UNIDAD3_REAL_VS_PLAN = 'entregadoUnidad3RealvsPlan',
  ENTREGADO_UNIDAD3_PORCENTAJE = 'entregadoUnidad3Porcentaje',
  ENTREGADO_UNIDAD3_PORCENTAJE_REAL_VS_PLAN = 'entregadoUnidad3PorcentajeRealvsPlan',
  RETIRADO_UNIDAD1_REAL = 'retiradoUnidad1Real',
  RETIRADO_UNIDAD1_PLAN = 'retiradoUnidad1Plan',
  RETIRADO_UNIDAD1_REAL_VS_PLAN = 'retiradoUnidad1RealvsPlan',
  RETIRADO_UNIDAD1_PORCENTAJE = 'retiradoUnidad1Porcentaje',
  RETIRADO_UNIDAD1_PORCENTAJE_REAL_VS_PLAN = 'retiradoUnidad1PorcentajeRealvsPlan',
  RETIRADO_UNIDAD2_REAL = 'retiradoUnidad2Real',
  RETIRADO_UNIDAD2_PLAN = 'retiradoUnidad2Plan',
  RETIRADO_UNIDAD2_REAL_VS_PLAN = 'retiradoUnidad2RealvsPlan',
  RETIRADO_UNIDAD2_PORCENTAJE = 'retiradoUnidad2Porcentaje',
  RETIRADO_UNIDAD2_PORCENTAJE_REAL_VS_PLAN = 'retiradoUnidad2PorcentajeRealvsPlan',
  RETIRADO_UNIDAD3_REAL = 'retiradoUnidad3Real',
  RETIRADO_UNIDAD3_PLAN = 'retiradoUnidad3Plan',
  RETIRADO_UNIDAD3_REAL_VS_PLAN = 'retiradoUnidad3RealvsPlan',
  RETIRADO_UNIDAD3_PORCENTAJE = 'retiradoUnidad3Porcentaje',
  RETIRADO_UNIDAD3_PORCENTAJE_REAL_VS_PLAN = 'retiradoUnidad3PorcentajeRealvsPlan',
  DISTANCIA_REAL = 'distanciaReal',
  DISTANCIA_PLAN = 'distanciaPlan',
  DISTANCIA_REAL_VS_PLAN = 'distanciaRealvsPlan',
  DISTANCIA_PORCENTAJE = 'distanciaPorcentaje',
  DISTANCIA_PORCENTAJE_REAL_VS_PLAN = 'distanciaPorcentajeRealvsPlan',
  PARADAS_PROCESADAS_EN_TIEMPO = 'paradasProcesadasEnTiempo',
  PARADAS_REAL_VS_PROCESADAS_EN_TIEMPO = 'paradasRealvsProcesadasEnTiempo',
  PORCENTAJE_PARADAS_PROCESADAS_EN_TIEMPO = 'porcentajeParadasProcesadasEnTiempo',
  PORCENTAJE_PARADAS_REAL_VS_PROCESADAS_EN_TIEMPO = 'porcentajeParadasRealvsProcesadasEnTiempo',
  /*modulo de indicadores con porcentaje es decir las medias ruedas que estan por encima */
  RUTAS_INICIADAS = 'rutasIniciadas',
  RUTAS_INICIADAS_ABREVIADO = 'rutasIniciadasAbreviado',
  PARADAS_INICIADAS = 'paradasIniciadas',
  PARADAS_INICIADAS_ABREVIADO = 'paradasIniciadasAbreviado',
  ENTREGADO_UNIDAD_1 = 'entregadoUnidad1',
  ENTREGADO_UNIDAD_1_ABREVIADO = 'entregadoUnidad1Abreviado',
  ENTREGADO_UNIDAD_2 = 'entregadoUnidad2',
  ENTREGADO_UNIDAD_2_ABREVIADO = 'entregadoUnidad2Abreviado',
  ENTREGADO_UNIDAD_3 = 'entregadoUnidad3',
  ENTREGADO_UNIDAD_3_ABREVIADO = 'entregadoUnidad3Abreviado',
  RETIRADO_UNIDAD_1 = 'retiradoUnidad1',
  RETIRADO_UNIDAD_1_ABREVIADO = 'retiradoUnidad1Abreviado',
  RETIRADO_UNIDAD_2 = 'retiradoUnidad2',
  RETIRADO_UNIDAD_2_ABREVIADO = 'retiradoUnidad2Abreviado',
  RETIRADO_UNIDAD_3 = 'retiradoUnidad3',
  RETIRADO_UNIDAD_3_ABREVIADO = 'retiradoUnidad3Abreviado',
  PARADAS_ENTREGADAS_EN_TIEMPO = 'paradasEntregadasEnTiempo',
  PARADAS_ENTREGADAS_EN_TIEMPO_ABREVIADO = 'paradasEntregadasEnTiempoAbreviado',
  DISTANCIA_RECORRIDA = 'distanciaRecorrida',
  DISTANCIA_RECORRIDA_ABREVIADO = 'distanciaRecorridaAbreviado',
  ESTADOS_PARADAS = 'estadosParadas',
  MOTIVOS_PARADAS = 'motivosParadas',
  ESTADOS_PARADAS_AGRUPADO_POR_CHOFER = 'estadosParadasAgrupadoPorChofer',
  MOTIVOS_PARADAS_AGRUPADO_POR_CHOFER = 'motivosParadasAgrupadoPorChofer',
  ESTADOS_PARADAS_AGRUPADO_POR_UBICACION = 'estadosParadasAgrupadoPorUbicacion',
  MOTIVOS_PARADAS_AGRUPADO_POR_UBICACION = 'motivosParadasAgrupadoPorUbicacion',
};