import { TranslateService } from "@ngx-translate/core";
import { DatePipe } from "@settings/maqueta/shared/pipes/date/date.pipe";
import { FILTRO, FORMATO } from "@settings/proyecto/mocks/columnaReporte/columnaReporte.mocks";

export class ColumnaReporte{
    private key                     : string = '';
    // private value         : string = ''; //tengo que sacar este campo de la clase
    private nombreTraduccion        : string = '';
    private formato                 : FORMATO = null;
    private filtro                  : FILTRO = null;
    private nombreFiltro            : string = '';
    private arraySelect             : string = '';
    private nombreSelect            : string = '';
    private valueSelect             : string = '';
    
    constructor(obj?: Partial<ColumnaReporte>) {    
        Object.assign(this, obj);
    }
    
    createColumnaReporte(key: string, nombreTraduccion: string, formato: FORMATO, filtro: FILTRO, nombreFiltro:string, arraySelect?: string,nombreSelect?: string, valueSelect?: string){
        this.key = key;
        // this.value = value;
        this.nombreTraduccion = nombreTraduccion;
        this.formato = formato;
        this.filtro = filtro;
        this.nombreFiltro = nombreFiltro;
        this.arraySelect = arraySelect == undefined || arraySelect == null? '':arraySelect;
        this.nombreSelect = nombreSelect == undefined || nombreSelect == null? '': nombreSelect;
        this.valueSelect = valueSelect== undefined || valueSelect == null? '': valueSelect;
    }

    getJson():object{
        let json={ 
            "key"                                     : this.key,
            // "value"                : this.value,
            "nombreTraduccion"                        : this.nombreTraduccion,
            "formato"                                 : this.formato,
            "filtro"                                  : this.filtro,
            "nombreFiltro"                            : this.nombreFiltro,
            "arraySelect"                             : this.arraySelect,
            "nombreSelect"                            : this.nombreSelect,
            "valueSelect"                             : this.valueSelect
        };
        return json;
    }

    public titleFiltroPeriodo(translate:TranslateService, arraySelectFiltroFechaDesdeHasta:any[], activeLang:string, organizacionUTC:string):string{
        let retorno:string = translate.instant(this.nombreTraduccion);
        let fechaDesde:string = '';
        let fechaHasta:string = '';
        let datePipe =new DatePipe();
        let formato = this.formato == 'datetime' ? 'DD/MM/AAAA HH:MM hs' : 'DD/MM/AAAA';
    
        if(arraySelectFiltroFechaDesdeHasta[this.key]?.length > 0){
          fechaDesde =datePipe.transform(arraySelectFiltroFechaDesdeHasta[this.key][0],formato, activeLang, organizacionUTC);
          fechaHasta =datePipe.transform(arraySelectFiltroFechaDesdeHasta[this.key][1],formato,activeLang, organizacionUTC);
          retorno = fechaDesde + ' ~ ' + fechaHasta;
        }
        return retorno;
    }

    // seleccionFiltroFecha(selectedMoment:any[]):object | null{
    //     if(selectedMoment && selectedMoment?.length==0) return null;
    //     let rango:object = {};
    

    //     let formatoString = '';
    //     if(this.getFormato() == FORMATO.DATETIME){
    //         formatoString = 'DD-MM-AAAA HH:MM:SS.ssss'
    //     }else if(this.getFormato() == FORMATO.DATE){
    //         formatoString = 'MM/DD/AAAA';
    //     }else if(this.getFormato() == FORMATO.TIME){
    //         formatoString = 'HH:MM:SS.ssss'
    //     }else{
    //         return;
    //     }

    //     let fechaDesde:Date = new Date(selectedMoment[0]);
    //     let fechaHasta:Date = new Date(selectedMoment[1]);
    
    //     let navegadorDesplazamientoUTC:number = fechaDesde.getTimezoneOffset();
    
    //     fechaDesde.setMinutes(fechaDesde.getMinutes() + navegadorDesplazamientoUTC);
    //     fechaHasta.setMinutes(fechaHasta.getMinutes() + navegadorDesplazamientoUTC);
    
    //     rango['desde'] = formatDateToString(fechaDesde,formatoString);
    //     rango['hasta'] = formatDateToString(fechaHasta,formatoString);
    
    //     return rango;
    //   }

    public getKey(): string {
        return this.key;
    }
    public setKey(value: string) {
        this.key = value;
    }

    // public getValue(): string {
    //     return this.value;
    // }
    // public setValue(value: string) {
    //     this.value = value;
    // }

    public getNombreTraduccion(): string {
        return this.nombreTraduccion;
    }
    public setNombreTraduccion(value: string) {
        this.nombreTraduccion = value;
    }

    public getFormato(): FORMATO {
        return this.formato;
    }
    public setFormato(value: FORMATO) {
        this.formato = value;
    }

    public getValueSelect(): string {
        return this.valueSelect;
    }
    public setValueSelect(value: string) {
        this.valueSelect = value;
    }

    public getArraySelect(): string {
        return this.arraySelect;
    }
    public setArraySelect(value: string) {
        this.arraySelect = value;
    }
    
    public getNombreSelect(): string {
        return this.nombreSelect;
    }
    public setNombreSelect(value: string) {
        this.nombreSelect = value;
    }

    public getFiltro(): string {
        return this.filtro;
    }
    public setFiltro(value: FILTRO) {
        this.filtro = value;
    }

    public getNombreFiltro(): string {
        return this.nombreFiltro;
    }
    public setNombreFiltro(value: string) {
        this.nombreFiltro = value;
    }

}

