<form [formGroup]="form">
    <div class="form-group" style="margin-bottom:0px; padding-bottom:0px;">
        <input formControlName="videoUrl" class="form-control validation-field" type="text"
              (change)="changeIdVideoYoutube()">
    </div>
    <div style="width:100%;" *ngIf="videoYoutubeId!=null">
        <youtube-player
           [videoId]="videoYoutubeId"
           (ready)="savePlayer($event)"
           (change)="onStateChange($event)"></youtube-player>                                                                                                              
    </div>  
</form>