<div class="dropdown d-inline-block">
    <a class="dropdown-toggle no-caret pl-2 pr-2" id="applications" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i class="fa fa-th" aria-hidden="true"></i>
    </a>

    <div class="dropdown-menu dropdown-menu-right rounded-0 mt-3 box-shadow" aria-labelledby="applications"
         style="width:270px;">
        <div class="applications text-center pt-1" perfectScrollbar>
            <h5>Applications</h5>
            <hr>
            <div class="row">
                <div class="col-4 p-1 mb-3 item">
                    <a href="javascript:void(0);">
                        <i class="fa fa-user text-success" aria-hidden="true"></i>
                        <span class="d-block">My account</span>
                    </a>
                </div>
                <div class="col-4 p-1 mb-3 item transition">
                    <a href="javascript:void(0);">
                        <i class="fa fa-search text-info" aria-hidden="true"></i>
                        <span class="d-block">Search</span>
                    </a>
                </div>
                <div class="col-4 p-1 mb-3 item transition">
                    <a href="javascript:void(0);">
                        <i class="fa fa-youtube-play text-danger" aria-hidden="true"></i>
                        <span class="d-block">Youtube</span>
                    </a>
                </div>
                <div class="col-4 p-1 mb-3 item transition">
                    <a href="javascript:void(0);">
                        <i class="fa fa-wrench text-primary" aria-hidden="true"></i>
                        <span class="d-block">Settings</span>
                    </a>
                </div>
                <div class="col-4 p-1 mb-3 item transition">
                    <a href="javascript:void(0);">
                        <i class="fa fa-calendar text-warning" aria-hidden="true"></i>
                        <span class="d-block">Calendar</span>
                    </a>
                </div>
                <div class="col-4 p-1 mb-3 item transition">
                    <a href="javascript:void(0);">
                        <i class="fa fa-picture-o text-success" aria-hidden="true"></i>
                        <span class="d-block">Gallery</span>
                    </a>
                </div>
                <div class="col-4 p-1 item transition">
                    <a href="javascript:void(0);">
                        <i class="fa fa-file-text-o text-danger" aria-hidden="true"></i>
                        <span class="d-block">Documents</span>
                    </a>
                </div>
                <div class="col-4 p-1 item transition">
                    <a href="javascript:void(0);">
                        <i class="fa fa-envelope-o text-info" aria-hidden="true"></i>
                        <span class="d-block">Mail</span>
                    </a>
                </div>
                <div class="col-4 p-1 item transition">
                    <a href="javascript:void(0);">
                        <i class="fa fa-globe text-primary" aria-hidden="true"></i>
                        <span class="d-block">Maps</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>