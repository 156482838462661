<form [formGroup]="form" (change)="onChangeField()">
    
    <div widget class="card border-0 box-shadow">
        <div class="card-header transparent border-0 text-muted">
            <h5 class="mb-0">{{etiquetaField | translate}}</h5>
            <div class="widget-controls"> 
                <a data-widgster="expand" href="#" class="transition"><i class="fa fa-chevron-down"></i></a>
                <a data-widgster="collapse" href="#" class="transition"><i class="fa fa-chevron-up"></i></a>
                <a data-widgster="close" href="#" class="transition"><i class="fa fa-times"></i></a>
            </div>        
        </div>
        <div class="card-body pt-0 widget-body" formGroupName="direccion"> 
            
            <lib-shared-here-map-autocomplete *ngIf="settings2.app.tipoMapa=='hereMaps'"  
                [geoPointFormulario]='form.value.direccion.geoPoint'
                [accionForm]='accionFormComponente'
                [organizacionKNAI]="this.organizacionKNAI"
                [usuarioKANE]="usuarioKANE"
                [nombreColeccion]="nombreColeccion"
                (escribioDireccion)='onEscribioDireccion($event)'></lib-shared-here-map-autocomplete>
            
            <lib-shared-google-map-autocomplete *ngIf="settings2.app.tipoMapa=='googleMaps'"  
                [geoPointFormulario]='form.value.direccion.geoPoint'
                [accionForm]='accionFormComponente'
                [organizacionKNAI]="this.organizacionKNAI"
                [usuarioKANE]="usuarioKANE"
                [nombreColeccion]="nombreColeccion"
                (escribioDireccion)='onEscribioDireccion($event)'></lib-shared-google-map-autocomplete>

            <div style="margin-top:10px;" formGroupName="geoPoint">
                <div class="row">
                    <div class="col-6">

                         <div class="form-group">
                            <label class="text-gray" translate>claseGeoPoint.latitud<span class="required">*</span></label>
                            <input formControlName="latitud" class="form-control validation-field" type="number">
                           <small class="text-danger" 
                                *ngIf="form.get('direccion').get('geoPoint').get('latitud').touched && 
                                       form.get('direccion').get('geoPoint').get('latitud').errors?.required"><span translate>claseGeoPoint.latitud</span>&nbsp;<span translate>claseValidaciones.esRequerido</span></small>

                        </div>
                    </div>    
                    <div class="col-6">
                        <div class="form-group">
                            <label class="text-gray" translate>claseGeoPoint.longitud<span class="required">*</span></label>
                            <input formControlName="longitud" class="form-control validation-field" type="number">
                           <small class="text-danger" 
                                 *ngIf="form.get('direccion').get('geoPoint').get('longitud').touched && 
                                        form.get('direccion').get('geoPoint').get('longitud').errors?.required"><span translate>claseGeoPoint.longitud</span>&nbsp;<span translate>claseValidaciones.esRequerido</span></small>
                         </div>                           
                    </div>    
                </div>

            </div>

            <div class="form-group">
                <label class="text-gray" translate>claseDireccion.calle<span class="required">*</span></label>
                <input formControlName="calle" class="form-control validation-field" type="text">
               <small class="text-danger" 
                    *ngIf="form.get('direccion').get('calle').touched && 
                           form.get('direccion').get('calle').errors?.required"><span translate>claseDireccion.calle</span>&nbsp;<span translate>claseValidaciones.esRequerido</span></small>                              
            </div>

            <div class="row">
                <div class="col-3">
                    <div class="form-group">
                        <label class="text-gray" translate>claseDireccion.numero</label>
                        <input formControlName="numero" class="form-control" type="text">
                    </div>
                </div>    
                <div class="col-3">
                    <div class="form-group">
                        <label class="text-gray" translate>claseDireccion.bloque</label>
                        <input formControlName="bloque" class="form-control" type="text">
                    </div>
                </div>    
                <div class="col-3">
                    <div class="form-group">
                        <label class="text-gray" translate>claseDireccion.piso</label>
                        <input formControlName="piso" class="form-control" type="text">
                    </div>
                </div>    
                <div class="col-3">
                    <div class="form-group">
                        <label class="text-gray" translate>claseDireccion.departamento</label>
                        <input formControlName="departamento" class="form-control" type="text">
                    </div>
                </div>
            </div>        

            <div class="row">
                <div class="col-9">
                    <div class="form-group">
                        <label *ngIf="fn.mostrarKN(form.value.direccion.provinciaKN,'nombre')=='CABA'" class="text-gray" translate>claseDireccion.barrio<span class="required" *ngIf="form.controls['direccion']['controls']['ciudad'].validator">*</span></label>
                        <label *ngIf="fn.mostrarKN(form.value.direccion.provinciaKN,'nombre')!='CABA'" class="text-gray" translate>claseDireccion.ciudad<span class="required" *ngIf="form.controls['direccion']['controls']['ciudad'].validator">*</span></label>
                        <input formControlName="ciudad" class="form-control validation-field" type="text">
                        <small class="text-danger" 
                            *ngIf="form.get('direccion').get('ciudad').touched && 
                                   form.get('direccion').get('ciudad').errors?.required">
                                   <span *ngIf="fn.mostrarKN(form.value.direccion.provinciaKN,'nombre')=='CABA'" translate>claseDireccion.barrio</span>&nbsp;<span translate>claseValidaciones.esRequerido</span>
                                   <span *ngIf="fn.mostrarKN(form.value.direccion.provinciaKN,'nombre')!='CABA'" translate>claseDireccion.ciudad</span>&nbsp;<span translate>claseValidaciones.esRequerido</span>
                        </small>
                    </div>
                </div>    
                <div class="col-3">
                    <div class="form-group">
                        <label class="text-gray" translate>claseDireccion.codigoPostal</label>
                        <input formControlName="codigoPostal" class="form-control" type="text">
                    </div>
                </div>    
            </div>

            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <label *ngIf="fn.mostrarKN(form.value.direccion.provinciaKN,'nombre')=='CABA'" class="text-gray" translate>claseDireccion.comuna<span class="required" *ngIf="form.controls['direccion']['controls']['partido'].validator">*</span></label>
                        <label *ngIf="fn.mostrarKN(form.value.direccion.provinciaKN,'nombre')!='CABA'" class="text-gray" translate>claseDireccion.partido<span class="required" *ngIf="form.controls['direccion']['controls']['partido'].validator">*</span></label>
                        <input formControlName="partido" class="form-control" type="text">
                        <small class="text-danger" 
                            *ngIf="form.get('direccion').get('partido').touched && 
                                   form.get('direccion').get('partido').errors?.required"><span translate>claseDireccion.partido</span>&nbsp;<span translate>claseValidaciones.esRequerido</span></small>                      
                    </div>
                </div>    
                <div class="col-6">
                    <div class="form-group">
                        <label class="text-gray" translate>claseDireccion.provincia<span class="required" *ngIf="form.controls['direccion']['controls']['provinciaKN'].validator">*</span></label>
                        <select class="form-control selectpicker validation-field" [compareWith]="compareFn"
                                formControlName="provinciaKN" id="provinciaKN">
                            <option [ngValue]="null">{{'No Indicada' | translate}}</option>                                  
                            <ng-container *ngFor="let option of msg.cacheColecciones['AuxProvincias']">
                                <option *ngIf="filtrarXPais(option.paisKN, form.value.direccion.paisKN)"
                                    [ngValue]="option">{{option.nombre | translate}}</option>
                            </ng-container>
                        </select>

                       <small class="text-danger" 
                            *ngIf="form.get('direccion').get('provinciaKN').touched && 
                                   form.get('direccion').get('provinciaKN').errors?.required"><span translate>claseDireccion.provincia</span>&nbsp;<span translate>claseValidaciones.esRequerido</span></small>                      
                    </div>
                </div>    
            </div>
            
            <div class="row">
               
                <div class="col-6">
                    <div class="form-group">
                        <label class="text-gray" translate>claseDireccion.pais<span class="required">*</span></label>
                        <!--<input formControlName="pais" class="form-control" type="text" readonly>-->
                      <select class="form-control selectpicker validation-field" [compareWith]="compareFn"
                              formControlName="paisKN" id="paisKN">  <!-- (change)="changePais(form.value.direccion.pais)"> -->
                      
                          <option [ngValue]="null">{{'No Indicado' | translate}}</option>                                  
                          <ng-container *ngFor="let option of PAISES">
                              <option 
                                  [ngValue]="option">{{option.nombre | translate}}</option>
                          </ng-container>
                      </select>

                       <small class="text-danger" 
                            *ngIf="form.get('direccion').get('paisKN').touched && 
                                   form.get('direccion').get('paisKN').errors?.required"><span translate>claseDireccion.pais</span>&nbsp;<span translate>claseValidaciones.esRequerido</span></small>                      
                    </div>
                </div>    
                <div class="col-6">
                  
                    <div class="form-group">
                        <label class="text-gray" translate>claseDireccion.zonaHoraria<span class="required">*</span></label>

                        <select class="form-control selectpicker validation-field" 
                                formControlName="timeZone" id="timeZone">
                                
                            <option [ngValue]="null">{{'No Indicada' | translate}}</option>                                  
                            <ng-container *ngFor="let option of msg.cacheColecciones['AuxTimeZones']">
                                <option *ngIf="filtrarXPais(option.paisKN, form.value.direccion.paisKN)"
                                    [ngValue]="option.nombre">{{option.nombre | translate}}</option>
                            </ng-container>
                        </select>
                       <small class="text-danger" 
                            *ngIf="form.get('direccion').get('timeZone').touched && 
                                   form.get('direccion').get('timeZone').errors?.required"><span translate>claseDireccion.zonaHoraria</span>&nbsp;<span translate>claseValidaciones.esRequerido</span></small>                      
                    </div>
                </div>    
            </div>

        </div> <!-- FIN card-body -->
    </div> <!-- FIN widget -->
    
</form>   
