import { log, logIf, logTable, values } from '@maq-console';
import { Component, OnInit, DoCheck, Input, Output, ViewEncapsulation, EventEmitter } from '@angular/core';

import { FuncionesService } from '@maq-funciones';
import { TranslateService } from '@ngx-translate/core';
import { ChangeDetectorRef } from '@angular/core';

import { FormGroup, FormControl, FormBuilder, Validators, FormsModule} from '@angular/forms';

@Component({
  selector: 'lib-shared-form-horario-atencion',
  templateUrl: './form-horario-atencion.component.html',
  styleUrls: [ './form-horario-atencion.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class FormHorarioAtencionComponent implements OnInit, DoCheck {

    @Input() public nameField: string;
    @Input() public controlsField: any;
    @Input() public value: any;
    @Input() public formInvalid: boolean;
    @Input() public accionForm: string;

    @Output() cargoSubEstructura= new EventEmitter<any>();

    public form:FormGroup;
    public traduccion:any={};
    public horarioCopiado:any=null;
    public errorRangoHorario:boolean=false;

    constructor(public fn:FuncionesService,
                private translate: TranslateService,
                public fb:FormBuilder, 
                protected changeDetectorRef: ChangeDetectorRef) {

    }

  ngOnInit() {

    log(...values("valores","form-horario-atencion nameField:",this.nameField));
    log(...values("valores","form-horario-atencion controlsField:",this.controlsField));
    log(...values("valores","form-horario-atencion value:",this.value));

    this.inicializarVariablesTraducibles();
    this.translate.onLangChange.subscribe(() => {
       this.inicializarVariablesTraducibles();
    });

    // Defino Formulario
    this.form = this.fb.group({
      
      horarioAtencion:  this.fb.group({
            lunes:  this.fb.group({
                horaDesde1   : null,
                horaHasta1   : null,
                horaDesde2   : null,
                horaHasta2   : null,
                horaDesde3   : null,
                horaHasta3   : null,
            }),              
            martes:  this.fb.group({
                horaDesde1   : null,
                horaHasta1   : null,
                horaDesde2   : null,
                horaHasta2   : null,
                horaDesde3   : null,
                horaHasta3   : null,
            }),              
            miercoles:  this.fb.group({
                horaDesde1   : null,
                horaHasta1   : null,
                horaDesde2   : null,
                horaHasta2   : null,
                horaDesde3   : null,
                horaHasta3   : null,
            }),              
            jueves:  this.fb.group({
                horaDesde1   : null,
                horaHasta1   : null,
                horaDesde2   : null,
                horaHasta2   : null,
                horaDesde3   : null,
                horaHasta3   : null,
            }),              
            viernes:  this.fb.group({
                horaDesde1   : null,
                horaHasta1   : null,
                horaDesde2   : null,
                horaHasta2   : null,
                horaDesde3   : null,
                horaHasta3   : null,
            }),              
            sabado:  this.fb.group({
                horaDesde1   : null,
                horaHasta1   : null,
                horaDesde2   : null,
                horaHasta2   : null,
                horaDesde3   : null,
                horaHasta3   : null,
            }),              
            domingo:  this.fb.group({
                horaDesde1   : null,
                horaHasta1   : null,
                horaDesde2   : null,
                horaHasta2   : null,
                horaDesde3   : null,
                horaHasta3   : null,
            }),              
            visperaFeriado:  this.fb.group({
                horaDesde1   : null,
                horaHasta1   : null,
                horaDesde2   : null,
                horaHasta2   : null,
                horaDesde3   : null,
                horaHasta3   : null,
            }),              
            feriado:  this.fb.group({
                horaDesde1   : null,
                horaHasta1   : null,
                horaDesde2   : null,
                horaHasta2   : null,
                horaDesde3   : null,
                horaHasta3   : null,
            }),              
        }),              

    });

    this.form.controls['horarioAtencion'].setValue(this.value);

  }

  inicializarVariablesTraducibles(){
    
      this.traduccion['claseDateTime']={
        horarioAtencion: this.translate.instant('claseDateTime.horarioAtencion'),
      }
  }

  ngDoCheck() {

      // log(...values("valores","form-horario-atencion ngDocheck() nameField:",this.nameField));
      // log(...values("valores","form-horario-atencion ngDocheck() controlsField:",this.controlsField));
      // log(...values("valores","form-horario-atencion ngDocheck() value:",this.value));
      
      //return;
    
      if(this.accionForm=='consultar') {
        this.form.disable();
        
      } else {
        
        if(this.controlsField['status']!='DISABLED') {        
            if(this.errorRangoHorario==false) {
                this.form.enable();
            }  
            
        } else {
            this.form.disable();
        } 
      } 

      if(this.formInvalid) {
        this.form.markAllAsTouched();
      }
  }

  onChangeField() {

      log(...values('valores','onChangeField - event:',event));

      let valueSubestructura = this.form.controls['horarioAtencion'].value;
      console.log("this.form.controls['horarioAtencion'].value",this.form.controls['horarioAtencion'].value);

      this.cargoSubEstructura.emit({
        nameField : this.nameField,
        value    :  valueSubestructura
      });

  }

  copyHorario(cual) {
    console.log("copyHorario", cual);
    
    if(this.form.controls['horarioAtencion'].value===undefined || this.form.controls['horarioAtencion'].value===null) {
  
       return ''; 
    
     } else {
        let horarioAtencion = this.form.controls['horarioAtencion'].value;
        
        let horaDesde1 = this.fn.getDocField(horarioAtencion,cual+'.horaDesde1');
        let horaHasta1 = this.fn.getDocField(horarioAtencion,cual+'.horaHasta1');
        let horaDesde2 = this.fn.getDocField(horarioAtencion,cual+'.horaDesde2');
        let horaHasta2 = this.fn.getDocField(horarioAtencion,cual+'.horaHasta2');
        let horaDesde3 = this.fn.getDocField(horarioAtencion,cual+'.horaDesde3');
        let horaHasta3 = this.fn.getDocField(horarioAtencion,cual+'.horaHasta3');
        
        this.horarioCopiado = {
          'horaDesde1' : horaDesde1,
          'horaHasta1' : horaHasta1,
          'horaDesde2' : horaDesde2,
          'horaHasta2' : horaHasta2,
          'horaDesde3' : horaDesde3,
          'horaHasta3' : horaHasta3,
        }
        
     }   
  }   
    
  pasteHorario(cual) {
    console.log("pasteHorario",cual,this.horarioCopiado);
    this.form.get('horarioAtencion.'+cual).setValue(this.horarioCopiado);
  }
  
  getClassHorarioAtencion(cual) {

    // console.log("form-horario-atencion this.form.controls['horarioAtencion'].value", this.form.controls['horarioAtencion'].value);
    
    if(this.form.controls['horarioAtencion'].value===undefined || this.form.controls['horarioAtencion'].value===null) {
  
       return ''; 
    
     } else {
        let horarioAtencion = this.form.controls['horarioAtencion'].value;
        let value = this.fn.getDocField(horarioAtencion,cual);
        if(value) {
          return 'ngb-timepicker-complete';
        } else  {
          return 'ngb-timepicker-empty';
        }    
     }
    
   }

   
   changeTimePicker(cual) {  
  
    console.log("changeTimePicker this.form.controls['horarioAtencion'].value",this.form.controls['horarioAtencion'].value);
    if(this.form.controls['horarioAtencion'].value===undefined || this.form.controls['horarioAtencion'].value===null) {
  
       return ''; 
    
    } else {
        let horarioAtencion = this.form.controls['horarioAtencion'].value;      
        let value = this.fn.getDocField(horarioAtencion,cual);
        
        console.log("changeTimePicker", cual, value);
        
        // value --> lunes.horaDesde1
        let aux = cual.split('.');
        let diaSemana = aux[0];
        let hora = aux[1];
        
        let horaDesde=null;
        let horaHasta=null;
        let nroHora='';
        let diaSemanaHora='';
        
        console.log("changeTimePicker hora", hora);
    
        if(hora.includes('horaDesde')) {
            nroHora = hora.replace('horaDesde','');
            horaDesde = value;
            diaSemanaHora = diaSemana+'.horaHasta'+nroHora;
            horaHasta = this.fn.getDocField(horarioAtencion,diaSemanaHora);
            
        } else if(hora.includes('horaHasta')) {
            nroHora = hora.replace('horaHasta','');
            diaSemanaHora = diaSemana+'.horaDesde'+nroHora;
            horaDesde = this.fn.getDocField(horarioAtencion,diaSemanaHora);
            horaHasta = value;
        }  
        console.log("horaDesde",horaDesde);
        console.log("horaHasta",horaHasta);
        this.form.controls['horarioAtencion']['controls'][diaSemana]['controls'][hora].setErrors({'errorRangoHorario': false});
        this.errorRangoHorario=false;
        
        if(horaDesde!=null && horaHasta!=null) {
            if(horaDesde.hour > horaHasta.hour || 
              (horaDesde.hour == horaHasta.hour && horaDesde.minute > horaHasta.minute) ||
              (horaDesde.hour == horaHasta.hour && horaDesde.minute == horaHasta.minute && horaDesde.second > horaHasta.second)) {
  
                // console.log("error en",diaSemana, hora);
                this.form.controls['horarioAtencion']['controls'][diaSemana]['controls'][hora].setErrors({'errorRangoHorario': true});
                this.form.controls['horarioAtencion']['controls'][diaSemana]['controls'][hora].markAsTouched();               
                this.errorRangoHorario=true;
            }
        }
        // console.log("this.form",this.form);
        // console.log("this.form.controls['horarioAtencion']['controls'][diaSemana]['controls'][hora]",this.form.controls['horarioAtencion']['controls'][diaSemana]['controls'][hora]);
    }   
     
   }
     
}

