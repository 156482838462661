import { formatDate } from '@angular/common';
import {str0} from '@maq-helpers/stringsHelp'
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { FORMATO } from '@settings/proyecto/mocks/columnaReporte/columnaReporte.mocks';
import { RANGO_FECHAS } from '@settings/proyecto/mocks/tableroControl/tableroControl';

export function convertirNumeroToHoraMinutosSegundos(minutos: number): object {
  if (minutos < 0) {
    throw new Error("Error en Funcion convertirNumeroToHoraMinutosSegundos: El número de minutos debe ser mayor o igual a cero");
  }

  if(typeof(minutos) !=='number'){
    throw new Error("Error en Funcion convertirNumeroToHoraMinutosSegundos: El número de minutos debe ser un numero");
  }

  const horas = Math.floor(minutos / 60);
  const minutosRestantes = minutos % 60;
  const segundos = Math.round((minutosRestantes - Math.floor(minutosRestantes)) * 60);

  let time = {
    hour: horas,
    minute: Math.floor(minutosRestantes),
    second : segundos
  };

  return time;
}

export function isTimestamp(timestamp: any): boolean {
    //console.log("isTimestamp",timestamp);

    let rta;

    if(timestamp==null) {
        rta = false;

    } else if(timestamp===undefined) {
        console.error("isTimestamp recibió undefined",timestamp);
        rta = false;

    } else if(timestamp['seconds']!==undefined && timestamp['nanoseconds']!==undefined) {
        //console.error("isTimestamp formato seconds / nanoseconds");
        rta = true;

    } else if(timestamp && typeof timestamp.toMillis == "function") {
          rta = true;

    } else {

        let isEmptyJson = (Object.entries(timestamp).length === 0 && timestamp.constructor === Object);
        if(isEmptyJson) {

            //console.error("isTimestamp recibió {}",timestamp);
            rta = false;

        } else {

            let typeMillis = typeof timestamp.toMillis;
            if (typeMillis == "function") {
               rta = true;
            } else {
               rta = false;
            }
        }
    }

    //console.log("isTimestamp rta",timestamp, rta);
    return rta;
  }


export function diferenciasFechasEnDias(fechaDesde:Date, fechaHasta:Date):number{
  const milisegundosEnUnDia = 24 * 60 * 60 * 1000; // Un día en milisegundos

  let diaActual = Math.floor(fechaDesde.getTime() / milisegundosEnUnDia);
  let diaSiguiente = Math.floor(fechaHasta.getTime() / milisegundosEnUnDia);

  let diferenciaDias = diaSiguiente - diaActual;
  return diferenciaDias;
}

export function   diferenciaFechas(date1: any, date2: any): string {
/**
 * diferencia de milisegundos entre dos fechas en formato timestamp o date
 * retorna: valor negativo o positivo
 * null si alguna de las dos fechas es nula
 */

    // console.log("diferenciaFechas date1", date1);
    // console.log("diferenciaFechas date2", date2);

    // console.log("dif-fecha1",date1)
    // console.log("dif-fecha2",date2)
    if(date1==null || date1===undefined || date1=='' ||
        date2==null || date2===undefined || date2=='') {
    return null;
    }

    let rta = null;

    let d1 = date1;
    let d2 = date2;

    let typeMillis;
    let formatoFechaHora;
    let isTimestamp;
    let milisegundos;

    typeMillis = typeof date1.toMillis;
    isTimestamp = (typeMillis == "function");
    // if(isTimestamp) { // // Timestamp { seconds: 1580936640, nanoseconds: 0 }
    //     let milisegundos = date1.toMillis();
    //     d1 = new Date(milisegundos);
    // }

    formatoFechaHora=Object.prototype.toString.call(date1);

    if(formatoFechaHora=="[object String]") { // Wed Feb 05 2020 17:32:13 GMT-0300 (hora estándar de Argentina)
        d1=d1.toDate(date1);

    } else if(isTimestamp) { // // Timestamp { seconds: 1580936640, nanoseconds: 0 }
        // console.error("compareFechas recibió timestamp date1 !!!",date1, typeMillis);
        let milisegundos = date1.toMillis();
        d1 = new Date(milisegundos);

    } else if(formatoFechaHora=="[object Date]") {     // Ya vino en formato fecha
        d1=date1;
    }


    typeMillis = typeof date2.toMillis;
    isTimestamp = (typeMillis == "function");
    // if(isTimestamp) { // // Timestamp { seconds: 1580936640, nanoseconds: 0 }
    //     let milisegundos = date2.toMillis();
    //     d2 = new Date(milisegundos);
    // }

    formatoFechaHora=Object.prototype.toString.call(date2);

    if(formatoFechaHora=="[object String]") { // Wed Feb 05 2020 17:32:13 GMT-0300 (hora estándar de Argentina)
        d2=d2.toDate(date2);

    } else if(isTimestamp) { // // Timestamp { seconds: 1580936640, nanoseconds: 0 }
        console.error("compareFechas recibió timestamp date1 !!!",date2, typeMillis);
        let milisegundos = date2.toMillis();
        d2 = new Date(milisegundos);

    } else if(formatoFechaHora=="[object Date]") {     // Ya vino en formato fecha
        d2=date2;
    }

    // Calculo diferencia entre dos fechas
    rta = d1.getTime() - d2.getTime();
    return rta;

}


export function formatDateToString(fecha:Date, formato:string):string {
    // console.log('url_jj-inicioRuta', fecha,formato,formato=='DD-MM-AAAATHH:MM:SS');
    let anio         = str0( fecha.getFullYear()    ,4);
    let mes          = str0( fecha.getMonth()+1     ,2);
    let dia          = str0( fecha.getDate()        ,2);

    let hora         = str0( fecha.getHours()       ,2);
    let minutos      = str0( fecha.getMinutes()     ,2);
    let segundos     = str0( fecha.getSeconds()     ,2);
    let milisegundos = str0(fecha.getMilliseconds() ,4);
    const zonaHoraria =  fecha.getTimezoneOffset(); // Obtener la diferencia de minutos con respecto a UTC
    // console.log('formatDateToString hora ', hora);
    let rta = dia +'/'+ mes +'/' + anio;
    if(formato=='MM/DD/AAAA') rta = mes  + '/' + dia  + '/' + anio;
    if(formato=='DD/MM/AAAA') rta = dia +'/'+ mes +'/' + anio;
    if(formato=='DD-MM-AAAA') rta = dia +'-'+ mes +'-' + anio;
    if(formato=='DD/MM/AAAA HH:MM') rta = dia +'/'+ mes +'/' + anio+' '+hora+':'+minutos;
    if(formato=='DD/MM/AAAA HH:MM:SS') rta = dia +'/'+ mes +'/' + anio+' '+hora+':'+minutos+':'+segundos;
    if(formato=='MM/DD/AAAA HH:MM:SS') rta =  mes +'/' + dia +'/'+ + anio+' '+hora+':'+minutos+':'+segundos;
    if(formato=='DD-MM-AAAA-HH:MM:SS') rta = dia + "-" + mes + "-" + anio + "-" + hora + ":" + minutos + ":" + segundos;
    if(formato=='DD-MM-AAAA HH:MM:SS') rta = dia + "-" + mes + "-" + anio + " " + hora + ":" + minutos + ":" + segundos;
    if(formato=='DD-MM-AAAA HH:MM:SS.ssss') rta = dia + "-" + mes + "-" + anio + " " + hora + ":" + minutos + ":" + segundos + "." + milisegundos; 
    if(formato=='MM-DD-AAAA HH:MM:SS.ssss') rta = mes + "-" +  dia  + "-" + anio + " " + hora + ":" + minutos + ":" + segundos + "." + milisegundos;
    if(formato=='DD-MM-AAAA') rta = dia + "-" + mes + "-" + anio; 
    if(formato=='HH:MM:SS.ssss') rta = hora + ":" + minutos + ":" + segundos + "." + milisegundos; 
    if(formato=='HH:MM:SS') rta = hora + ":" + minutos + ":" + segundos; 
    if(formato=='AAAA-MM-DD') rta= anio+'-'+mes+'-'+dia;
    if(formato=='AAAA-MM-DD HH:MM:SS.ssss') rta= anio+'-'+mes+'-'+dia +" " + hora + ":" + minutos + ":" + segundos + "." + milisegundos;
    if(formato=='AAAA-DD-MM HH:MM:SS.ssss') rta= anio+'-'+dia+'-'+mes +" " + hora + ":" + minutos + ":" + segundos + "." + milisegundos;
    if(formato=='AAAA-MM-DD HH:MM:SS') rta= anio+'-'+mes+'-'+dia +" " + hora + ":" + minutos + ":" + segundos;
    if(formato=='AAAA-DD-MM HH:MM:SS') rta= anio+'-'+dia+'-'+mes +" " + hora + ":" + minutos + ":" + segundos;
    if(formato=='AAAA/MM/DD') rta= anio+'/'+mes+'/'+dia;
    if(formato=='AAAAMMDD')   rta= anio+mes+dia;
    if(formato=='AAAAMM')     rta= anio+mes;
    if(formato=='DD')         rta= dia;
    if(formato=='DD-MM-AAAATHH:MM:SS')  rta=  `${anio}-${mes.toString().padStart(2, '0')}-${dia.toString().padStart(2, '0')}T${hora.toString().padStart(2, '0')}:${minutos.toString().padStart(2, '0')}:${segundos.toString().padStart(2, '0')}${getTimezoneOffsetString(zonaHoraria)}`;
    
    return rta;
}

export function getTimezoneOffsetString(offsetMinutos:number):string {
  

  var signo = offsetMinutos > 0 ? '-' : '+';
  var horas = Math.abs(Math.floor(offsetMinutos / 60));
  var minutos = Math.abs(offsetMinutos % 60);

  // Asegúrate de que las horas y los minutos tengan dos dígitos
  var horasString = horas < 10 ? '0' + horas : horas;
  var minutosString = minutos < 10 ? '0' + minutos : minutos;

  var offsetString = signo + horasString + ':' + minutosString;
  return offsetString;
}

export function formatoFechaUTC(fecha: Date, formato:string, utc:string=null): string {
  // Función para formatear la fecha en el formato deseado


  let anio = fecha.getUTCFullYear();
  let mes = twoDigitFormat(fecha.getUTCMonth() + 1);
  let dia = twoDigitFormat(fecha.getUTCDate());
  let hora = twoDigitFormat(fecha.getUTCHours());
  let minutos = twoDigitFormat(fecha.getUTCMinutes());
  let segundos = twoDigitFormat(fecha.getUTCSeconds());
  let milliseconds = threeDigitFormat(fecha.getUTCMilliseconds());
  

  let rta:string = '';
  if(formato=='DD-MM-AAAA') rta = dia +'-'+ mes +'-' + anio;
  if(formato=='DD-MM-AAAA HH:MM:SS.ssss') rta = dia + "-" + mes + "-" + anio + " " + hora + ":" + minutos + ":" + segundos + "." + milliseconds; 

  return rta;
}


//determina si el año es bisiesto
function isLeapYear(year: number): boolean {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}

//determina el numero de dias del mes dependiendo del anio si es bisiesto o no
function getDaysInMonth(year: number, month: number): number {
  const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  if (month === 1 && isLeapYear(year)) {
    return 29; // Febrero en año bisiesto
  } else {
    return daysInMonth[month];
  }
}


//Formato de time -> 03:00:00
export function subtractTimeZoneFromDateWithTime(time:string,timezoneInNumber: number){
  let partes = time.split(':');
  let hora = parseInt(partes[0], 10);
  hora = hora - timezoneInNumber;
  let horaStr = hora.toString().padStart(2, '0'); //para asegurarme que tenga dos digitos 
  return horaStr + ':' + partes[1] + ':' + partes[2];
}

//convierte las fechas de formato string a Date
// Ejemplo: fechaParam = 01/30/2023 03:00:00.000, activeLang='en', dateHasTime=true -> fechaRetorno = 30/01/2023 03:00:00.000
// Ejemplo: fecha = 30/10/2023 03:00:00.000, activeLang='es', dateHasTime=false -> fechaRetorno = 30/01/2023 00:00:00.000 
export function convertDateFromStringInDate(fechaParam:string, activeLang:string, dateHasTime:boolean):Date | null {
  // Dividir la cadena en partes usando espacios como separadores
  
  
  let partes = fechaParam.split(' ');

  let separadorFecha:number = fechaParam.indexOf('-');
  
  let fechaPartes:string[] = [];
  // Dividir la parte de la fecha en partes usando "-" como separador
  if(separadorFecha !=-1){
    fechaPartes = partes[0].split('-');
  }else{   // Dividir la parte de la fecha en partes usando "/" como separador
    fechaPartes = partes[0].split('/');
  }
  
  let dia:string;
  let mes:string;
  // Obtener el mes, el día y el año
  if(activeLang=='es'){
    dia = fechaPartes[0];
    mes = fechaPartes[1];
  }else{
    mes = fechaPartes[0];
    dia = fechaPartes[1];
  }
  let ano = fechaPartes[2];

  // Valida el rango del año (por ejemplo, de 1900 a 2099)
  if (Number(ano) < 1900 || Number(ano) > 2099) {
    throw new Error('Año fuera de rango válido');
  }
  // Valida el rango del mes (de 1 a 12)
  if (Number(mes)-1 < 0 || Number(mes) -1 > 11) {
    throw new Error('Mes fuera de rango válido');
  }
  // Determina el número máximo de días en el mes dado (teniendo en cuenta los años bisiestos)
  const diasEnElMes = getDaysInMonth(Number(ano), Number(mes) -1);
  // Valida el rango del día
  if (Number(dia) < 1 || Number(dia) > diasEnElMes) {
    throw new Error('Día fuera de rango válido');
  }

  let fechaRetorno:Date;
  // Obtener la parte de la hora
  if(dateHasTime && partes.length > 1){
    let hora = partes[1];
    try{
      fechaRetorno = new Date(`${mes}/${dia}/${ano} ${hora}`);
    }catch (error) {
        if (error instanceof Error && error.message === "Invalid Date") {
          // Maneja el error Invalid Date aquí
          throw new Error("La fecha proporcionada es inválida.");
        } else {
          // Maneja otros errores
          throw new Error("Ocurrió un error inesperado al convertir la fecha en " + `${mes}/${dia}/${ano} ${hora}`);
        }
      }
  }else{
    fechaRetorno = new Date(Number(ano), Number(mes) -1, Number(dia));
  }
  
  if(esDate(fechaRetorno)){
    return fechaRetorno;
  }else{
    throw new Error('Error al convertir la fecha en convertDateFromStringInDate');
    // return null;
  }
}

export function twoDigitFormat(value: number): string {
  // Función para asegurarse de que los valores tengan dos dígitos (por ejemplo, '01' en lugar de '1')
  return value.toString().padStart(2, '0');
}

export function threeDigitFormat(value: number): string {
  // Función para asegurarse de que los valores tengan tres dígitos (por ejemplo, '001' en lugar de '1')
  return value.toString().padStart(3, '0');
}
  
export function timestampToDate(timestamp: any): any {
    //console.log("timestampToDate",timestamp);

    let rta;

    if(timestamp==null || timestamp===undefined) {
        rta = null;
        // no convierto dejo tal cual (null)
        
    } else if(timestamp['seconds']!==undefined && timestamp['nanoseconds']!==undefined) {
        rta = new Date( (timestamp['seconds'] + timestamp['nanoseconds'] * 10 ** -9) * 1000);

    } else {
       let isEmptyJson = (Object.entries(timestamp).length === 0 && timestamp.constructor === Object);
       if(isEmptyJson) {

         console.error("timestampToDate recibió {}",timestamp);
         rta = null;

       } else {

         let typeMillis = typeof timestamp.toMillis;
         if (typeMillis == "function") {
            let milisegundos = timestamp.toMillis();
            rta = new Date(milisegundos);
         } else {
            // console.log("timestampToDate toMills!=function", typeMillis);
            rta = null;
         }
       }
    }

    let formatoFechaHora=Object.prototype.toString.call(timestamp);
    // console.log("timestampToDate type, rta",formatoFechaHora, rta);

    return rta;

  }

  export function adicionarDiferenciasEntreFechas(fecha1:Date, fecha2:Date, adicionarFecha:Date): Date{
    let diferenciasEntreFechas:string;
    diferenciasEntreFechas = diferenciaFechas(fecha1,fecha2);
    return new Date(adicionarFecha.getTime() + diferenciasEntreFechas)
  }

  interface FechaHoraTimeStamp {
    _nanoseconds: number;
    _seconds: number;
  }
  
  export function esFechaHoraTimeStamp(obj: any): obj is FechaHoraTimeStamp {
    return (
      typeof obj === "object" &&
      "_nanoseconds" in obj &&
      "_seconds" in obj &&
      typeof obj._nanoseconds === "number" &&
      typeof obj._seconds === "number"
    );
  }
  
   export function esStringFechaValida(fecha: string): boolean {
    let date = new Date(fecha);
    return !isNaN(date.getTime());
  }
  
  export function esDate(obj: any): boolean {
    return obj instanceof Date;
  }
  export function getDateFromData(fechaData:any):Date{
  
    let fechaDate:Date=new Date();
    if (esDate(fechaData)) {fechaDate=fechaData} 
    else if(esStringFechaValida(fechaData)){fechaDate= new Date(fechaData);} 
    else if(esFechaHoraTimeStamp(fechaData)){fechaDate= new Date(fechaData._seconds * 1000);} 

    return fechaDate
  }

  



  export function getFromAndToWithReturnOfAnObject(selectedMoment:Date[], formatoColumna:FORMATO, activeLang:string, timezone: string):object | null{
    if(selectedMoment == undefined || selectedMoment?.length==0 || selectedMoment?.length==1 || selectedMoment[0] == undefined || selectedMoment[1] == undefined || selectedMoment[0] == null || selectedMoment[1] == null) return null;
    let rango:object = {};
    
    let formatoString = '';
    if(formatoColumna == FORMATO.DATETIME){
      if(activeLang=='en') {
        formatoString = 'mm/dd/yyyy HH:mm:ss';
      } else {
        formatoString = 'dd/MM/yyyy HH:mm:ss';
      }
    }else if(formatoColumna == FORMATO.DATE){
      if(activeLang == 'en'){
        formatoString = 'mm/dd/yyyy';
      }else{
          formatoString = 'dd/MM/yyyy';
      }
    }else if(formatoColumna == FORMATO.TIME){
        formatoString = 'HH:mm:ss';
    }else{
        return;
    }

    // console.log('zzz getFromAndToWithReturnOfAnObject selectedMoment 0 ', selectedMoment[0])
    // console.log('zzz getFromAndToWithReturnOfAnObject selectedMoment 1 ', selectedMoment[1])

    let fechaDesde:Date = selectedMoment[0];
    let fechaHasta:Date = selectedMoment[1];

    // console.log('zzz getFromAndToWithReturnOfAnObject formatoString ', formatoString, ' activeLang ', activeLang, ' timezone ', timezone, ' formatoColumna == FORMATO.DATETIME ', formatoColumna == FORMATO.DATETIME);


    rango['desde'] =  formatDate(fechaDesde, formatoString, activeLang, timezone);
    rango['hasta'] =  formatDate(fechaHasta, formatoString, activeLang, timezone);
    
    
    return rango;
  }

export function getFiltroDateSQL(valueFiltro:object,formatoFecha:FORMATO, activeLang:string, timezoneInNumber: number):object{
  let fechaDesde:string = '';
  let fechaHasta:string = '';

  // console.log('zzz getFiltroDateSQL valueFiltro ', valueFiltro, ' formatoFecha ', formatoFecha, ' activeLang ', activeLang, ' timezoneInNumber ', timezoneInNumber);

  let fechaDesdeDate:Date;
  let fechaHastaDate:Date;
  let dateHasTime:boolean = formatoFecha == FORMATO.DATETIME ? true: false;
  
  if(formatoFecha!=FORMATO.TIME){
    fechaDesdeDate = convertDateFromStringInDate(valueFiltro['desde'], activeLang, dateHasTime);
    fechaHastaDate = convertDateFromStringInDate(valueFiltro['hasta'], activeLang, dateHasTime);
  }


  if(formatoFecha==FORMATO.DATETIME) {
    //LE RESTO LA ZONA HORARIA PARA ENVIAR LOS FILTROS AL SQL EN LA ZONA 00:00
    fechaDesdeDate.setHours(fechaDesdeDate.getHours() - timezoneInNumber);
    fechaHastaDate.setHours(fechaHastaDate.getHours() - timezoneInNumber);
    fechaDesde = formatDateToString(fechaDesdeDate, 'AAAA-MM-DD HH:MM:SS');
    fechaHasta = formatDateToString(fechaHastaDate, 'AAAA-MM-DD HH:MM:SS');
  }else if(formatoFecha==FORMATO.DATE) {
    fechaDesde = formatDateToString(fechaDesdeDate, 'AAAA-MM-DD') + ' 00:00:00';
    fechaHasta = formatDateToString(fechaHastaDate, 'AAAA-MM-DD') + ' 23:59:59';
  }else if(formatoFecha==FORMATO.TIME) {
    fechaDesde = subtractTimeZoneFromDateWithTime(valueFiltro['desde'], timezoneInNumber);
    fechaHasta = subtractTimeZoneFromDateWithTime(valueFiltro['hasta'], timezoneInNumber);
  }else{ //por defecto
    console.warn("ADVERTENCIA: getFiltroDateSQL no esta definido el formatoFecha: ", formatoFecha);
    fechaDesde = formatDateToString(fechaDesdeDate, 'AAAA-MM-DD') + ' 00:00:00';
    fechaHasta = formatDateToString(fechaHastaDate, 'AAAA-MM-DD') + ' 23:59:59';
  }
  valueFiltro['desde'] = fechaDesde;
  valueFiltro['hasta'] = fechaHasta;
  return valueFiltro;
}

//le saca las horas de la zona horaria pasada por parametro a la fecha
export function restoreUTCInDate(fecha:Date,utc:number):Date{
  fecha.setHours(fecha.getHours()-utc);
  return fecha;
}

//devuelve el desplazamiento de la zona horaria en formato numerico
export function getOffsetUTCHours():number{
  let fecha: Date = new Date();
  let offsetMinutes = fecha.getTimezoneOffset();
  let offsetHours = Math.floor(offsetMinutes / 60); // Obtener las horas
  return offsetHours;
}

//devuelve el desplazamiento de la zona horaria en formato numerico teniendo en cuenta el desplazamiento de la zona horaria pasado por parametro
export function getOffsetUTCHoursWithOffsetUTC(offsetUTC:string):number{
  console.log("getOffsetUTCHoursWithOffsetUTC", offsetUTC);
  const parts = offsetUTC.split(':');
  let offsetHours:number = 0;
  if (parts.length === 2) {
      const hours = parseInt(parts[0], 10);
      const minutes = parseInt(parts[1], 10);
      // Calcular el desplazamiento en horas
      offsetHours = hours + minutes / 60;
  } else {
      console.log("Formato de cadena incorrecto.");
  }
  return offsetHours;
}

//devuelve el desplazamiento de la zona horaria en formato hh:mm
export function getOffsetUTC():string{
  let fecha: Date = new Date();
  let offsetMinutes = fecha.getTimezoneOffset();
  let offsetHours = Math.floor(offsetMinutes / 60); // Obtener las horas
  let offsetMinutesRemainder = Math.abs(offsetMinutes % 60); // Obtener los minutos
  let offsetSign = offsetHours >= 0 ? '-' : '+';
  let formattedOffsetHours = offsetHours < 10 ? `0${Math.abs(offsetHours)}` : `${Math.abs(offsetHours)}`;
  let formattedOffsetMinutes = offsetMinutesRemainder < 10 ? `0${offsetMinutesRemainder}` : `${offsetMinutesRemainder}`;
  let offsetUTC = `${offsetSign}${formattedOffsetHours}:${formattedOffsetMinutes}`;
  return offsetUTC;
}