export function str0(str:any, cant:any):string {
    if(str==null || str===undefined) return '';
    let str2;
    if(typeof str==='number') {
      str2=str.toString();
    } else {
      str2=str;
    }
    let ceros='000000000000000000000';
    let rta=ceros+str2;
    return rta.substr(rta.length-cant,rta.length);
}

export function reemplazaAcentos(cadena:string):string {

  let aux='';
  for (var i2 = 0; i2 < cadena.length; i2++) {
      //console.log(cadena.substr(i2,1), cadena.charCodeAt(i2));
      let letra = cadena.substr(i2,1)
      let asc   = cadena.charCodeAt(i2);
      if(asc>=192 && asc<=197) { aux+='A'; }
      else if(asc>=200 && asc<=203) { aux+='E'; }
      else if(asc>=204 && asc<=207) { aux+='I'; }
      else if(asc>=210 && asc<=214) { aux+='O'; }
      else if(asc>=217 && asc<=220) { aux+='U'; }
      else if(asc>=224 && asc<=229) { aux+='a'; }
      else if(asc>=232 && asc<=235) { aux+='e'; }
      else if(asc>=236 && asc<=239) { aux+='i'; }
      else if(asc>=242 && asc<=246) { aux+='o'; }
      else if(asc>=249 && asc<=252) { aux+='u'; }
      else if(asc==209) { aux+='N'; }
      else if(asc==241) { aux+='n'; }
      else { aux+=letra; }
  }
  cadena = aux;

  cadena = cadena.replace(/á/gi, 'a');
  cadena = cadena.replace(/é/gi, 'e');
  cadena = cadena.replace(/í/gi, 'i');
  cadena = cadena.replace(/ó/gi, 'o');
  cadena = cadena.replace(/ú/gi, 'u');
  cadena = cadena.replace(/ñ/gi, 'n');

  return cadena;
}

