import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'lib-shared-bind-translate',
  template: `<span >{{htmlTextTraducido}}</span>`,  
  providers: []
}) 

export class BindTranslateComponent implements OnInit {

  @Input() htmlText: string;
  htmlTextTraducido:string;

  constructor(public translate: TranslateService) {  }



  ngOnInit() {
 
    console.log("bind-translate paso",this.htmlText);
    this.inicializarVariablesTraducibles();
    this.translate.onLangChange.subscribe(() => {
      console.log('cambio idioma en libreriaB');
      this.inicializarVariablesTraducibles();
    });
  } 

  inicializarVariablesTraducibles(){
          
    this.htmlTextTraducido=this.translate.instant('navegabilidad.editar');

  }
}

