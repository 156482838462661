import { KN } from "@maq-models/typesKN/typesKN.model";


export enum  TIPOS_PARADAS{
  DEPOSITO="Deposito",
  PARADA="Parada",
  ESPERA="Espera",
  ESCALA_CLIENTE="Escala-Cliente",
  ESCALA_HOTEL="Escala-Hotel",
  DESCANSO="Descanso",
}


export const TIPOS_RUTA:any=
{
  GestionParadasConRelevamiento  : {key:'GestionParadasConRelevamiento',      nombre:'tipoRuta.gestionParadasConRelevamiento',  esGestionParadas: true},
  GestionParadasSinRelevamiento  : {key:'GestionParadasSinRelevamiento',      nombre:'tipoRuta.gestionParadasSinRelevamiento',  esGestionParadas: true},
  GestionParadassoloRelevamiento : {key:'GestionParadasSoloRelevamiento',     nombre:'tipoRuta.gestionParadasSoloRelevamiento', esGestionParadas: true},
  HojaRutaConRelevamiento        : {key:'HojaRutaConRelevamiento',            nombre:'tipoRuta.hojaRutaConRelevamiento',        esGestionParadas: false},
  HojaRutaSinRelevamiento        : {key:'HojaRutaSinRelevamiento',            nombre:'tipoRuta.hojaRutaSinRelevamiento',        esGestionParadas: false},
  HojaRutaSoloRelevamiento       : {key:'HojaRutaSoloRelevamiento',           nombre:'tipoRuta.hojaRutaSoloRelevamiento',       esGestionParadas: false},
};  

export const TIPOS_PARADAS_ARRAY:any[]=[
  {key:TIPOS_PARADAS.DEPOSITO, nombre:'tipoParada.deposito', entregaMercaderia:true},
  {key:TIPOS_PARADAS.PARADA, nombre:'tipoParada.parada', entregaMercaderia:true},
  {key:TIPOS_PARADAS.ESPERA, nombre:'tipoParada.espera', entregaMercaderia:null},
  {key:TIPOS_PARADAS.ESCALA_CLIENTE, nombre:'tipoParada.escalaEnCliente', entregaMercaderia:null},
  {key:TIPOS_PARADAS.ESCALA_HOTEL, nombre:'tipoParada.escalaEnHotel', entregaMercaderia:null},
  {key:TIPOS_PARADAS.DESCANSO, nombre:'tipoParada.descanso', entregaMercaderia:null},
]

export const TIPOS_RUTA2:any[]=[
 {key   : 'GestionParadasConRelevamiento',      nombre:'tipoRuta.gestionParadasConRelevamiento',  esGestionParadas: true, conRelevamiento:true},
 {key   : 'GestionParadasSinRelevamiento',      nombre:'tipoRuta.gestionParadasSinRelevamiento',  esGestionParadas: true, conRelevamiento:false},
 {key   : 'GestionParadasSoloRelevamiento',     nombre:'tipoRuta.gestionParadasSoloRelevamiento', esGestionParadas: true, conRelevamiento:true},
 {key   : 'HojaRutaConRelevamiento',            nombre:'tipoRuta.hojaRutaConRelevamiento',        esGestionParadas: false, conRelevamiento:true},
 {key   : 'HojaRutaSinRelevamiento',            nombre:'tipoRuta.hojaRutaSinRelevamiento',        esGestionParadas: false, conRelevamiento:false},
 {key   : 'HojaRutaSoloRelevamiento',           nombre:'tipoRuta.hojaRutaSoloRelevamiento',       esGestionParadas: false, conRelevamiento:true}
];  

export const ESTADOS_RUTA:any=
{
  Creacion        : {key:'Creacion',      nombre:'estadoRuta.creacion'},
  NoIniciada      : {key:'NoIniciada',    nombre:'estadoRuta.noIniciada'},
  Iniciada        : {key:'Iniciada',      nombre:'estadoRuta.iniciada'},
  RutaEnCurso     : {key:'RutaEnCurso',   nombre:'estadoRuta.rutaEnCurso'},
  ParadaEnCurso   : {key:'ParadaEnCurso', nombre:'estadoRuta.paradaEnCurso'},
  EscalaEnCurso   : {key:'EscalaEnCurso', nombre:'estadoRuta.escalaEnCurso'},
  LlegoAlDestino  : {key:'LlegoAlDestino',nombre:'estadoRuta.llegoAlDestino'}, 
  Concluida       : {key:'Concluida',     nombre:'estadoRuta.concluida'}
};

export const ESTADOS_RUTA_ARRAY:KN[]=[
  {key:'Creacion',      nombre:'estadoRuta.creacion'},
  {key:'NoIniciada',    nombre:'estadoRuta.noIniciada'},
  {key:'Iniciada',      nombre:'estadoRuta.iniciada'},
  {key:'RutaEnCurso',   nombre:'estadoRuta.rutaEnCurso'},
  {key:'ParadaEnCurso', nombre:'estadoRuta.paradaEnCurso'},
  {key:'EscalaEnCurso', nombre:'estadoRuta.escalaEnCurso'},
  {key:'LlegoAlDestino',nombre:'estadoRuta.llegoAlDestino'}, 
  {key:'Concluida',     nombre:'estadoRuta.concluida'}
]

export const ESTADOS_PARADAS :any=
{

  Cancelada                 : {key:'Cancelada',               nombre:'estadoParada.cancelada',               color:'#FF0000'},
  Rechazada                 : {key:'Rechazada',               nombre:'estadoParada.rechazada',               color:'#f1641e'},
  ParadaPendiente           : {key:'ParadaPendiente',         nombre:'estadoParada.paradaPendiente',         color:'#0095FE'},
  EntregaCompleta           : {key:'EntregaCompleta',         nombre:'estadoParada.entregaCompleta',         color:'#008240'},
  EntregaParcial            : {key:'EntregaParcial',          nombre:'estadoParada.entregaParcial',          color:'#00d066'},
  ParadaEnCurso             : {key:'ParadaEnCurso',           nombre:'estadoParada.paradaEnCurso',           color:'#cfc90a'},
  Replanificada             : {key:'Replanificada',           nombre:'estadoParada.replanificada',           color:'#FFA401'},
  
  //Hoja de Ruta
  IndicacionesSolicitadas   : {key:'IndicacionesSolicitadas', nombre:'estadoParada.indicacionesSolicitadas', color:'#00D066' },
  EntregaNoRealizada        : {key:'EntregaNoRealizada',      nombre:'estadoParada.entregaNoRealizada',      color:'#FF0000'},
  ParadaRealizada           : {key:'ParadaRealizada'        , nombre:'estadoParada.paradaRealizada',         color:'#304B88'}
}

export const ESTADOS_PARADAS_ARRAY :any[]=[
  {key:'Cancelada',               nombre:'estadoParada.cancelada',               color:'#FF0000'},
  {key:'Rechazada',               nombre:'estadoParada.rechazada',               color:'#f1641e'},
  {key:'ParadaPendiente',         nombre:'estadoParada.paradaPendiente',         color:'#0095FE'},
  {key:'EntregaCompleta',         nombre:'estadoParada.entregaCompleta',         color:'#008240'},
  {key:'EntregaParcial',          nombre:'estadoParada.entregaParcial',          color:'#00d066'},
  {key:'ParadaEnCurso',           nombre:'estadoParada.paradaEnCurso',           color:'#cfc90a'},
  {key:'Replanificada',           nombre:'estadoParada.replanificada',           color:'#FFA401'},
  
  //Hoja de Ruta
  {key:'IndicacionesSolicitadas', nombre:'estadoParada.indicacionesSolicitadas', color:'#00D066' },
  {key:'EntregaNoRealizada',      nombre:'estadoParada.entregaNoRealizada',      color:'#FF0000'},
  {key:'ParadaRealizada'        , nombre:'estadoParada.paradaRealizada',         color:'#304B88'}
] 

export const ESTADOS_PARADAS_ARRAY_HOJA_RUTA :any[]=[
  
  //Hoja de Ruta
  {key:'IndicacionesSolicitadas', nombre:'estadoParada.indicacionesSolicitadas', color:'#00D066' },
  {key:'EntregaNoRealizada',      nombre:'estadoParada.entregaNoRealizada',      color:'#FF0000'},
  {key:'ParadaRealizada'        , nombre:'estadoParada.paradaRealizada',         color:'#304B88'}
] 

export const ESTADOS_PARADAS_ARRAY_GESTION_PARADAS :any[]=[
  {key:'Cancelada',               nombre:'estadoParada.cancelada',               color:'#FF0000'},
  {key:'Rechazada',               nombre:'estadoParada.rechazada',               color:'#f1641e'},
  {key:'ParadaPendiente',         nombre:'estadoParada.paradaPendiente',         color:'#0095FE'},
  {key:'EntregaCompleta',         nombre:'estadoParada.entregaCompleta',         color:'#008240'},
  {key:'EntregaParcial',          nombre:'estadoParada.entregaParcial',          color:'#00d066'},
  {key:'ParadaEnCurso',           nombre:'estadoParada.paradaEnCurso',           color:'#cfc90a'},
  {key:'Replanificada',           nombre:'estadoParada.replanificada',           color:'#FFA401'},
    
] 


export enum ESTADOS_PARADAS_TEXT_COLOR 
{
  ParadaPendiente           = '#FFFFFF',
  ParadaEnCurso             = '#FFFFFF',
  EntregaCompleta           = '#FFFFFF',
  EntregaParcial            = '#FFFFFF',
  EntregaNoRealizada        = '#FFFFFF',
  Cancelada                 = '#FFFFFF',
  Rechazada                 = '#FFFFFF',
  Replanificada             = '#FFFFFF',
  IndicacionesSolicitadas   = '#FFFFFF',
  ParadaRealizada           = '#FFFFFF'
}


export enum RUTA_BACKGROUND_COLOR
{
  NoIniciada        = '#C8E8FF',
  Iniciada          = '#00C0FF',
  EnCurso           = '#0094FE',
  ParadaEnCurso     = '#0094FE',
  EscalaEnCurso     = '#0094FE',
  LLegoAlDestino    = '#314B88',
  Concluida         = '#314B88',

}

export enum RUTA_TEXT_COLOR
{
  NoIniciada        = '#44B1FE',
  Iniciada          = '#FFFFFF',
  EnCurso           = '#FFFFFF',
  ParadaEnCurso     = '#FFFFFF',
  EscalaEnCurso     = '#FFFFFF',
  LLegoAlDestino    = '#FFFFFF',
  Concluida         = '#FFFFFF',

}

export enum ESTADO_VEHICULO {
  SIN_CONEXION     = "sin_conexion",
  CONECTADO        = "conectado",
  PARADO           = "parado",
  EN_MOVIMIENTO    = "en_movimiento",
  APAGADO          = "apagado"
  
}

export enum COLOR_ESTADO_CAMION{
  SIN_CONEXION     = '#fc0505', // red
  CONECTADO        = '#008000', // dark green
  PARADO           = '#d7e350', // yellow
  EN_MOVIMIENTO    = '#61e01c', // ligth green 
  APAGADO          = '#000000' // black
  
}
