import {isDate} from 'date-fns';

export function     copiarObjetoNew(obj:any):any {
    if(obj==null || obj===undefined) {
        return obj;
    }
    let objNew = {};
    for (var field in obj) {
      
        let valueIsArray = (Array.isArray(obj[field]));
        
        //if(field.includes('fecha')) console.log("copiarObjetoNew isDate",field,obj[field],isDate(obj[field]));
        if (obj[field] && isDate(obj[field]) ) {
           objNew[field] = obj[field];
        } else if (typeof obj[field] == "object" && !valueIsArray && obj[field] !== null && !this.isTimestamp(obj[field]) ) {
           objNew[field] = this.copiarObjetoNew(obj[field]);
        } else {
           objNew[field] = obj[field];
        }    
    }
    //console.log("copiarObjetoNew",obj, objNew);
    return objNew;
}

export function deepCopy(obj) {
    var copy;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || "object" != typeof obj) return obj;

    // Handle Date
    if (obj instanceof Date) {
        return new Date(obj.getTime());
    }

    // Handle Array
    if (obj instanceof Array) {
        copy = [];
        for (var i = 0, len = obj.length; i < len; i++) {
            copy[i] = deepCopy(obj[i]);
        }
        return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
        copy = {};
        for (var attr in obj) {
            if (obj.hasOwnProperty(attr)) copy[attr] = deepCopy(obj[attr]);
        }
        return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
}