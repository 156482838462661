import { log, logIf, logTable, values } from '@maq-console';

import { Component, OnInit, DoCheck, Input, Output, ViewEncapsulation, EventEmitter } from '@angular/core';

import { MensajesService }   from '@maq-servicios/mensajes/mensajes.service';
import { FuncionesService } from '@maq-funciones';
import { ApisService }       from '@maq-apis';
import { TranslateService } from '@ngx-translate/core';

import { FormGroup, FormControl, FormBuilder, Validators, FormsModule} from '@angular/forms';
import { Direccion } from '@maq-models/direccion/direccion.model';
import { GeoPoint } from '@maq-models/geopoint/geopoint.model';

import { AppSettings2 } from '@maq-models/appSettings/appSettings.model';
import { AppSettingsService } from '@settings/app.settings';
import {PAISES} from '@maq-mocks/paises/paises';
import { take } from 'rxjs/operators';

@Component({
  selector: 'lib-shared-form-direccion',
  templateUrl: './form-direccion.component.html',
  styleUrls: [ './form-direccion.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class FormDireccionComponent implements OnInit, DoCheck {

    @Input() public nameField: string; 
    @Input() public etiquetaField: string; 
    @Input() public controlsField: any; 
    @Input() public value: any; 
    @Input() public accionForm: string; 
    @Input() public formInvalid: boolean; 
    
    @Input() organizacionKNAI : any;
    @Input() usuarioKANE      : any;
    @Input() nombreColeccion  : any;

    @Output() cargoSubEstructura= new EventEmitter<any>();

    public form:FormGroup; 
    public accionFormComponente: string;
    
    // Colecciones Secundarias
    public subscripcionCache:any[]=[];
    public configListadosCache:any[]=[];
    public cantidadSubscripcionesSecundariasLanzadas:number=0;
    public finalizoGETSecundarias:boolean=false;
    
    public settings2: AppSettings2;

    public PAISES = PAISES;
    
    constructor(public appSettings:AppSettingsService, 
                public fn:FuncionesService,
                public apis:ApisService,
                public msg:MensajesService,
                private translate: TranslateService,
                public fb:FormBuilder, ) { 

    }

  ngOnInit() {

    log(...values("valores","form-direccion nameField:",this.nameField));
    log(...values("valores","form-direccion controlsField:",this.controlsField));
    log(...values("valores","form-direccion value:",this.value));
    log(...values("valores","form-direccion formInvalid:",this.formInvalid));
    
    this.accionFormComponente = this.accionForm;
    console.log("this.accionFormComponente",this.accionFormComponente);

    // Leo seteos para determinar el tipo de mapa a utilizar (Google o Here)
    this.settings2 = this.appSettings.settings2;  
    
    // Colecciones Auxiliares
    this.configListadosCache=[];    

    this.configListadosCache.push({ 
        nombreListado   : 'listadoAuxProvincias',
        nombreColeccion : 'AuxProvincias',
        orderBy         : [{key:'nombre',ascDesc:'asc'}]
               
    });     

    this.configListadosCache.push({ 
        nombreListado   : 'listadoAuxTimeZones',
        nombreColeccion : 'AuxTimeZones',
        orderBy         : [{key:'nombre',ascDesc:'asc'}]            
    });     
  
    this.getSubscripcionSecundarias();
    

    // Defino Formulario
    this.form = this.fb.group({

        direccion: this.fb.group({
            calle           : [null, Validators.compose([Validators.required])],
            numero          : null,
            bloque          : null,
            piso            : null,
            departamento    : null,
            codigoPostal    : null,
            ciudad          : [null, Validators.compose([Validators.required])],
            partido         : null,
            provinciaKN     : [null, Validators.compose([Validators.required])],
            paisKN          : [null, Validators.compose([Validators.required])],
            geoPoint:  this.fb.group({
                latitud     : [null, Validators.compose([Validators.required])],
                longitud    : [null, Validators.compose([Validators.required])],
            }),              
            timeZone        : [null, Validators.compose([Validators.required])],
            idiomaPais      : null,
        }),

    });

    this.form.controls['direccion'].setValue(this.value);    
    
    // Ojo, hay que investigar si no se vuelve a ejecutar cada vez que se dispara ngDocheck !!!!
    this.apis.LogApiFuncion({
      eventoQueDisparo : 'form-direccion.ngOnInit - Visualización de Mapa',
      apiFuncionKey    : 'GoogleMapsViewDynamic', 
      organizacionKNAI : this.organizacionKNAI,
      usuarioKANE      : this.usuarioKANE,
      nombreColeccion  : this.nombreColeccion,
      cloudFunction    : null,
      cantidad         : 1, 
    });
    
  }

  ngDoCheck() {

      if(this.accionForm=='consultar') {
        this.form.disable();
        this.accionFormComponente='consultar';
        
      } else {
        
        if(this.controlsField['status']!='DISABLED') {        
            this.form.enable();
            this.accionFormComponente='modificar';
            
            this.form.controls['direccion']['controls']['ciudad'].setValidators(null);
            this.form.controls['direccion']['controls']['partido'].setValidators(null);
            this.form.controls['direccion']['controls']['provinciaKN'].setValidators(null);

            if(this.controlsField['controls']['ciudad']['validator'])      this.form.controls['direccion']['controls']['ciudad'].setValidators([Validators.required]);  
            if(this.controlsField['controls']['partido']['validator'])     this.form.controls['direccion']['controls']['partido'].setValidators([Validators.required]);  
            if(this.controlsField['controls']['provinciaKN']['validator']) this.form.controls['direccion']['controls']['provinciaKN'].setValidators([Validators.required]);  
            
        } else {
            this.form.disable();
            this.accionFormComponente='consultar';
        } 
      } 
      
      if(this.formInvalid) {
        this.form.markAllAsTouched();
      }
  }
  
  onEscribioDireccion(direccion:Direccion){
      log(...values('funcionComponente','onEscribioDireccion'));
      log(...values("valores","EscribioDireccion en Google Map Autocomplete:",direccion));
      
      direccion.idiomaPais = this.fn.getIdiomaPaisFromTimeZone( this.msg.cacheColecciones['AuxTimeZones'], direccion.timeZone );

      this.form.get('direccion').patchValue(direccion);
      
      this.onChangeField();

  }
  
  onChangeField() {

      log(...values('valores','onChangeField - event:',event));
      
      let valueSubestructura = this.form.get('direccion').value;
      log(...values('valores','valueSubestructura:',valueSubestructura));
  
      this.cargoSubEstructura.emit({
        nameField : this.nameField, 
        value    :  valueSubestructura
      });

  } 


  filtrarXPais(selectPaisKN:any, formPaisKN)  {
      let keySelect = this.fn.mostrarKN(selectPaisKN,"key");
      let keyForm   = this.fn.mostrarKN(formPaisKN,"key");
      
      // console.log(selectPaisKN, keySelect, formPaisKN, keySelect==keyForm);
      return keySelect==keyForm;    
  }

  compareFn(c1: any, c2:any): boolean {     
    // console.log("compareFn c1", c1);
    // console.log("compareFn c2", c2);
      return c1 && c2 ? c1.key === c2.key : c1 === c2; 
  }
  
  getSubscripcionSecundarias() {

      log(...values('funcionComponente','getSubscripcionSecundarias'));

      /* Subscripción a Colecciones Secundarias */
      this.cantidadSubscripcionesSecundariasLanzadas=0;
      this.finalizoGETSecundarias=false;              
      
      this.cantidadSubscripcionesSecundariasLanzadas=this.configListadosCache.length;
      
      for(let i=0; i<this.configListadosCache.length; i++) {
          //console.log("this.configListadosCache",this.configListadosCache);
          
          this.subscripcionCache.push( 
              this.msg.getListadoCache({

                nombreListado              : this.configListadosCache[i].nombreListado,
                nombreColeccion            : this.configListadosCache[i].nombreColeccion,
                where                      : [],
                orderBy                    : this.configListadosCache[i].orderBy,
                limit                      : null,
                grabaLocalStorage          : true,   
                ignoraValoresMemoria       : false, 
                datosPorOrganizacion       : false,
                organizacionKNAI           : this.organizacionKNAI,                           
                usuarioKANE                : this.usuarioKANE,
                nombreColeccionSolicitante : this.configListadosCache[i].nombreColeccion,
                limpiaSettingUsuarioOrganizacion:false      

     
              }).pipe(take(1)).subscribe(data=>{
                  //console.log("data",data);

                  log(...values("valores",
                            "msg.cacheColecciones[" + this.configListadosCache[i].nombreColeccion + "]:", 
                            this.msg.cacheColecciones[this.configListadosCache[i].nombreColeccion]));
                

                  // logTable(...values( this.msg.cacheColecciones[configListadosCache[i]],configListadosCache[i].nombreListado) );              
                  
                  this.cantidadSubscripcionesSecundariasLanzadas--;
                  
                  // console.log("cantidadSubscripcionesSecundariasLanzadas",this.cantidadSubscripcionesSecundariasLanzadas);
                  if(this.cantidadSubscripcionesSecundariasLanzadas==0) {  // Devolvió resultados la última subscripción
                        this.finalizoGETSecundarias=true;              
                  }
                  // console.log('msg.cacheColecciones',this.msg.cacheColecciones);
                  
              },(error:any)=>{
                  log(...values("error",error));
              }) 
          );    
      }
  }

}  
  
