 <!-- {{excelProgress|json}} -->
<div class="row mb-1"  *ngIf="excelProgress.cargandoExcell">    <div class="col">
    <div widget class="card border-0 box-shadow mb-3"  >

        <div class="card-header transparent border-0 text-muted">
          <h5 class="mb-0">Transferencia de Datos</h5>

          <div class="widget-controls">

                  <a data-widgster="expand" href="#" class="transition"><i class="fa fa-chevron-down"></i></a>
                  <a data-widgster="collapse" href="#" class="transition"><i class="fa fa-chevron-up"></i></a>
          </div>   
        <div>

         <div class="card-body pt-0 widget-body"> 
           <h2 *ngIf="faltanColumnas" translate>listaColumnasFaltantes</h2>
           <p *ngIf="faltanColumnas">{{listaColumnasFaltantes}}</p>
        
           <br>

          <!-- analisis Repetidos -->
          <div style="margin-top:10px;">
            <div class="text-center" *ngIf="excelProgress.repetidos.cantidadTotal>0">
              <span>Verificando Repetidos</span>
              <!--<span> {{excelProgress.repetidos.cantidadRepetidos}}    </span>-->
              <i *ngIf="(excelProgress.repetidos.cantidadOK+excelProgress.repetidos.cantidadRepetidos)/excelProgress.repetidos.cantidadTotal*100<100" class="fa fa-spin fa-spinner"></i> 
                <div class="progress"style="height: 20px;">
                  <div class="progress-bar bg-info" 
                    role="progressbar"  
                    [style.width.%]="(excelProgress.repetidos.cantidadOK)/excelProgress.repetidos.cantidadTotal*100" 
                    aria-valuenow="(excelProgress.repetidos.cantidadOK)/excelProgress.repetidos.cantidadTotal*100"
                    aria-valuemin="0" 
                    aria-valuemax="100">
                    ok:{{(excelProgress.repetidos.cantidadOK)/excelProgress.repetidos.cantidadTotal*100| number:'2.0-1'}} %
                  </div>
                  <div class="progress-bar bg-warning" 
                    role="progressbar"  
                    [style.width.%]="(excelProgress.repetidos.cantidadRepetidos)/excelProgress.repetidos.cantidadTotal*100" 
                    aria-valuenow="(excelProgress.repetidos.cantidadRepetidos)/excelProgress.repetidos.cantidadTotal*100"
                    aria-valuemin="0" 
                    aria-valuemax="100">
                    repetidos:{{(excelProgress.repetidos.cantidadRepetidos)/excelProgress.repetidos.cantidadTotal*100| number:'2.0-1'}} %
                  </div>
                </div>  
          </div>
          <!--<p>{{excelProgress.repetidos.cantidadOK}} - {{excelProgress.repetidos.cantidadRepetidos}} - {{excelProgress.repetidos.cantidadTotal}}</p>-->

           <!-- analisis validandoDatos -->
          <div style="margin-top:10px;">
            <div class="text-center"  *ngIf="excelProgress.validandoDatos.canitidadEnProceso>0">
               <span>Verificando Datos del Excel</span>
               <!--<span> {{excelProgress.validandoDatos.cantidadOK}} / {{excelProgress.validandoDatos.cantidadTotal}}   </span>-->
            
              <i class="fa fa-spin fa-spinner" 
              *ngIf="(excelProgress.validandoDatos.cantidadOK+excelProgress.validandoDatos.cantidadRepetidos)/excelProgress.validandoDatos.cantidadTotal*100<100"></i> 
                <div class="progress" style="height: 20px;">
                  <div class="progress-bar bg-info" 
                    role="progressbar"  
                    [style.width.%]="(excelProgress.validandoDatos.cantidadOK+excelProgress.validandoDatos.cantidadErrores)/excelProgress.validandoDatos.cantidadTotal*100" 
                    aria-valuenow="(excelProgress.validandoDatos.cantidadOK+excelProgress.validandoDatos.cantidadErrores)/excelProgress.validandoDatos.cantidadTotal*100"
                    aria-valuemin="0" 
                    aria-valuemax="100">
                    ok: {{(excelProgress.validandoDatos.cantidadOK+excelProgress.validandoDatos.cantidadErrores)/excelProgress.validandoDatos.cantidadTotal*100| number:'2.0-1'}} %
                  </div>
                  <div class="progress-bar bg-info" 
                    role="progressbar"  
                    [style.width.%]="(excelProgress.validandoDatos.cantidadOK+excelProgress.validandoDatos.cantidadErrores)/excelProgress.validandoDatos.cantidadTotal*100" 
                    aria-valuenow="(excelProgress.validandoDatos.cantidadOK+excelProgress.validandoDatos.cantidadErrores)/excelProgress.validandoDatos.cantidadTotal*100"
                    aria-valuemin="0" 
                    aria-valuemax="100">
                    repetidos: {{(excelProgress.validandoDatos.cantidadOK+excelProgress.validandoDatos.cantidadErrores)/excelProgress.validandoDatos.cantidadTotal*100| number:'2.0-1'}} %
                </div>
              </div>  
          </div>
          <!--<p>{{excelProgress.validandoDatos.cantidadOK}} - {{excelProgress.validandoDatos.cantidadErrores}} - {{excelProgress.validandoDatos.cantidadTotal}}</p>-->

          <!-- analisis verificandoEnBaseDatos -->
          <div style="margin-top:10px;">
            <div class="text-center" *ngIf="excelProgress.verificandoEnBaseDatos.canitidadEnProceso>0">
               <span>Verificando Preexistencias en Base de Datos</span>
               <!--<span> {{excelProgress.verificandoEnBaseDatos.cantidadOK}} / {{excelProgress.verificandoEnBaseDatos.cantidadTotal}}   </span>-->
            
              <i *ngIf="(excelProgress.verificandoEnBaseDatos.cantidadOK+excelProgress.verificandoEnBaseDatos.cantidadErrores)/excelProgress.verificandoEnBaseDatos.cantidadTotal*100<100" class="fa fa-spin fa-spinner"></i> 
                <div class="progress" style="height: 20px;">
                  <div class="progress-bar bg-info" 
                      role="progressbar"  
                      [style.width.%]="(excelProgress.verificandoEnBaseDatos.cantidadOK+excelProgress.verificandoEnBaseDatos.cantidadErrores)/excelProgress.verificandoEnBaseDatos.cantidadTotal*100"  
                      aria-valuenow="(excelProgress.verificandoEnBaseDatos.cantidadOK+excelProgress.verificandoEnBaseDatos.cantidadErrores)/excelProgress.verificandoEnBaseDatos.cantidadTotal*100"
                      aria-valuemin="0" 
                      aria-valuemax="100">
                     ok: {{(excelProgress.verificandoEnBaseDatos.cantidadOK+excelProgress.verificandoEnBaseDatos.cantidadErrores)/excelProgress.verificandoEnBaseDatos.cantidadTotal*100| number:'2.0-1'}} %
                  </div>
                  <div class="progress-bar bg-info" 
                      role="progressbar"  
                      [style.width.%]="(excelProgress.verificandoEnBaseDatos.cantidadOK+excelProgress.verificandoEnBaseDatos.cantidadErrores)/excelProgress.verificandoEnBaseDatos.cantidadTotal*100"  
                      aria-valuenow="(excelProgress.verificandoEnBaseDatos.cantidadOK+excelProgress.verificandoEnBaseDatos.cantidadErrores)/excelProgress.verificandoEnBaseDatos.cantidadTotal*100"
                      aria-valuemin="0" 
                      aria-valuemax="100">
                    {{(excelProgress.verificandoEnBaseDatos.cantidadOK+excelProgress.verificandoEnBaseDatos.cantidadErrores)/excelProgress.verificandoEnBaseDatos.cantidadTotal*100| number:'2.0-1'}} %
                  </div>
                </div>   
          </div>
          <!--<p>{{excelProgress.verificandoEnBaseDatos.cantidadOK}} - {{excelProgress.verificandoEnBaseDatos.cantidadErrores}} - {{excelProgress.verificandoEnBaseDatos.cantidadTotal}}</p>-->


          <!-- analisis grabandoDatos -->
              <div style="margin-top:10px;">
                <div class="text-center" *ngIf="excelProgress.grabandoDatos.cantidadTotal>0">
                  <span>Grabando en Base de Datos</span>
                  <!--<span> {{excelProgress.grabandoDatos.cantidadOK}} / {{excelProgress.grabandoDatos.canitidadEnProceso}}   </span>-->
            
                  <i *ngIf="(excelProgress.grabandoDatos.cantidadOK+excelProgress.grabandoDatos.cantidadErrores)/excelProgress.grabandoDatos.canitidadEnProceso*100<100" class="fa fa-spin fa-spinner"></i> 
                  <div class="progress" style="height: 20px;">
                    <div class="progress-bar bg-success" 
                        role="progressbar"  
                        [style.width.%]="(excelProgress.grabandoDatos.cantidadOK)/excelProgress.grabandoDatos.cantidadTotal*100"  
                        aria-valuenow="(excelProgress.grabandoDatos.cantidadOK)/excelProgress.grabandoDatos.cantidadTotal*100"                      
                        aria-valuemin="0" 
                        aria-valuemax="100">
                       ok: {{(excelProgress.grabandoDatos.cantidadOK)/excelProgress.grabandoDatos.cantidadTotal*100| number:'2.0-1'}} %
                    </div>
                    <div class="progress-bar bg-danger" 
                        role="progressbar"  
                        [style.width.%]="(excelProgress.validandoDatos.cantidadErrores)/excelProgress.grabandoDatos.cantidadTotal*100"  
                        aria-valuenow="(excelProgress.validandoDatos.cantidadErrores)/excelProgress.grabandoDatos.cantidadTotal*100"                      
                        aria-valuemin="0" 
                        aria-valuemax="100">
                       errores: {{(excelProgress.validandoDatos.cantidadErrores)/excelProgress.grabandoDatos.cantidadTotal*100| number:'2.0-1'}} %
                    </div>
                  </div>  
                </div>

          <!--<p>{{excelProgress.grabandoDatos.cantidadOK}} - {{excelProgress.grabandoDatos.cantidadErrores}} - {{excelProgress.grabandoDatos.cantidadTotal}}</p>-->

              <div class="row" style="margin-top:20px;">
                <div class="col col-6 text-right">                
                  <button type="button" style="margin-right:0px;" class="btn btn-dark"
                          *ngIf="errorAnalisisExcell || errorExitenDatosEnBase" (click)="exportAsXLSX()" 
                          >Archivo de Errores</button>
                </div>          
                <div class="col col-6 text-left">                
                  <button type="button" style="margin-right:0px;" class="btn btn-warning"
                          (click)="cerrarExcel()" translate>navegabilidad.cerrar</button>
              </div>

            </div>
          </div>
        </div>
      </div> 
    </div> 
  </div> 
 </div> 