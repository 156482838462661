<div class="dropdown d-inline-block">
    <a class="dropdown-toggle no-caret pl-2 pr-2" id="flags-menu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
     
         <span class="{{claseIconoHeader}}"></span>
    </a>

    <div class="dropdown-menu dropdown-menu-right rounded-0 mt-3 p-0 box-shadow" aria-labelledby="flags-menu">
        <div class="flags-menu">    
             
            <!-- <a class="dropdown-item" href="/en/"><span class="flag-icon flag-icon-gb mr-2"></span> English</a>  -->
         <!--    <a class="dropdown-item" href="javascript:void(0);"><span class="flag-icon flag-icon-de mr-2"></span> German</a>        
            <a class="dropdown-item" href="javascript:void(0);"><span class="flag-icon flag-icon-fr mr-2"></span> French</a>
            <a class="dropdown-item" href="javascript:void(0);"><span class="flag-icon flag-icon-ru mr-2"></span> Russian</a>                
            <a class="dropdown-item" href="javascript:void(0);"><span class="flag-icon flag-icon-tr mr-2"></span> Turkish</a> -->
            <!-- <a class="dropdown-item" href="/es/"><span  class="flag-icon flag-icon-ar mr-2"></span> Español</a> -->

             <a class="dropdown-item" href="javascript:void(0);" (click)="cambiarLenguaje('es')"><span class="flag-icon flag-icon-es mr-2"></span> <span style="color:#444;" >{{traduccion.idioma.espanol}}</span></a>
            <a class="dropdown-item" href="javascript:void(0);" (click)="cambiarLenguaje('pt')"><span  class="flag-icon flag-icon-br mr-2"></span> <span style="color:#444;" >{{traduccion.idioma.portugues}}</span></a>
            <a class="dropdown-item" href="javascript:void(0);" (click)="cambiarLenguaje('en')"><span  class="flag-icon flag-icon-gb mr-2"></span> <span style="color:#444;" >{{traduccion.idioma.ingles}}</span></a>
        </div>
    </div>
</div>

