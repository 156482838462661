import { log, logIf, logTable, values } from '@maq-console';

import { Component, OnInit, OnDestroy, ViewEncapsulation    } from '@angular/core';
import { ChangeDetectorRef, Injector, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute }                             from "@angular/router";
import { DOCUMENT }                                           from '@angular/common';
import { formatNumber }                                       from '@angular/common'

// Reactive Forms
import { FormGroup, FormControl, FormBuilder, Validators, FormsModule } from '@angular/forms';
import { AbstractControl, FormArray } from '@angular/forms';

// Servicios
import { ApisService }       from '@maq-apis';
import { AuthService }       from '@maq-autorizacion/services/auth.service';
import { BDService }         from '@maq-bd/bd.service';
import { ConfirmService }    from '@maq-servicios/confirm/confirm-service';
import { AlertService }      from '@maq-servicios/alert/alert-service';
import { FuncionesService }  from '@maq-funciones';
import { MensajesService }   from '@maq-servicios/mensajes/mensajes.service';
import { AppSettingsService } from './../../../app.settings';
import { AppSettings2 }       from '@maq-models/appSettings/appSettings.model';
import { SoapService }        from '@proyecto/soap/soap.service';
import { PAISES } from '@maq-mocks/paises/paises';
// environmet
import { environment }        from '../../../../environments/environment';

// Firebase
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';

// jQuery
declare let $: any;
declare let jQuery: any;

// Varios
import { Observable    } from 'rxjs';
import { map, take     } from 'rxjs/operators';
import { finalize, tap } from 'rxjs/operators';

// InputMask
import Inputmask from 'inputmask';
import moment from 'moment';
import format from 'date-fns/format'

// Toast
import { ToastrService, GlobalConfig } from 'ngx-toastr';

// Spinner
import { NgxSpinnerService } from "ngx-spinner";

// Ordenamiento en Memoria Grillas
import { OrderPipe } from 'ngx-order-pipe';

// Translate
import { TranslateService }  from '@ngx-translate/core';

// Pipes de Formato de Monedas
import { formatCurrency, getCurrencySymbol } from '@angular/common'

// SOAP 
import { HttpClient, HttpHeaders  } from '@angular/common/http';

// ng-pick-datetime
// import { DateTimeAdapter } from 'ng-pick-datetime';
// import { OwlDateTimeIntl } from 'ng-pick-datetime';

// Models
import { Usuario, Distribuidor, Organizacion }          from '@maq-models/usuarios/usuarios.model';
import { DatosPersonales }                              from '@maq-models/datosPersonales/datosPersonales.model';
import { Telefono }                                     from '@maq-models/telefono/telefono.model';
import { RedSocial }                                    from '@maq-models/redSocial/redSocial.model';
import { Cotizacion }                                   from '@maq-models/monedasImportes/monedasImportes.model';
import { Settings }                                     from '@maq-models/settings/settings.model';
import { getListadoCacheModel }                         from '@maq-models/mensajes/mensajes.model';
import { WhereObjet,OrderObjet }                        from '@maq-models/bd/bdDefinicionesGenerales.model';
import { KN, KANE, KNAI }                               from '@maq-models/typesKN/typesKN.model';
import { Grilla }                                       from '@maq-models/grilla/grilla.model';
import { Soap }                                         from '@maq-models/soap/soap.model';
import { HorarioAtencion, VentanaAtencion }                              from '@maq-models/horariosAtencion/horariosAtencion.model';

// Mocks y Funciones
import { LISTA_DE_FILTROS_SI_NO, getListadoSiNo } from '@maq-mocks/varios/varios';
import { LISTA_DE_GENEROS }                       from '@maq-mocks/varios/varios';
import { LISTA_DE_PAISES }                        from '@maq-mocks/usuarios/usuarios';

// Importador de Archivos
import { Importador, ConfigExcel }    from '@maq-models/importador/importador.model';
import { GeneradorImportadores }      from '@proyecto/importador/generadorImportadores';

export enum TIPO_IMPORTACION {
    Soap        = "soap",
    Importacion = "importar",    
}
@Component({
    template: ''
  })
export class SoapGenerica implements Importador, OnInit, OnDestroy  {
  @ViewChild("modalContent") modalContent: any;
  @ViewChild('titulo') viewChildTitulo: ElementRef;
  
  // Settings
  public settings2: AppSettings2;

  // Colección Principal
  public nombreColeccion                : string;
  public listadoPrincipal               : any[]=[];
  public columnasAdicionalesLogTable    : string[]=[];
  public finalizoGETPrincipal           : boolean=false;
  public configComponente               : any=null;
  public documentoOriginal              : any;    

  // Colecciones Secundarias
  public subscripcionCache:any[]=[];
  public configListadosCache:any[]=[];
  public cantidadSubscripcionesSecundariasLanzadas:number=0;
  public finalizoGETSecundarias:boolean=false;
  public listadoSiNoActivo:any[] = getListadoSiNo('Estado');

  // Formulario y Grilla
  public modalidadOpcion          : string='listado';
  public grilla                   : Grilla;
  public searchDateRango          : any[]=[];
  public form                     : FormGroup;
  public formInvalid              : boolean = false;
  public accionForm               : string = 'listado';
  public errorMensaje             : string;
  public pagePaginado             : any;
  public pipePaginado             : any;
  
  // Cotización Monedas
  public cotizaciones: Cotizacion={ Dolares         : null,
                                    DolaresBlue     : null,
                                    DolaresDivisa   : null,
                                    Euros           : null,
                                    Reales          : null,
                                    fechaHora       : null
                                  };

  // Variables Globales Usario Logeado y Organización asociada
  public usuario              : Usuario;
  public usuarioKANE          : KANE=null;
  public tipoPerfilUsuario    : string=null;
  public organizacionKNAI     : KNAI=null;
  public distribuidorKN       : KN=null;
  public organizacionUTC      : string=null;  

  // Permisos del Usuario Logeado
  public permisos={
    altasAutorizadas                    : true,
    bajasAutorizadas                    : true,
    modificacionesAutorizadas           : true,
    modificacionesBasicasAutorizadas    : true,
    deshabilitarGrabar                  : false,
  };

  // Manejo de Imágenes
  public vecImagenesDownloadeadas   = [];
  public arrayFILES                 : any[] = [];
  public arrayUpload                : any[] = [];
  
  // Mocks y Funciones Importadas  
  public LISTA_DE_FILTROS_SI_NO = LISTA_DE_FILTROS_SI_NO;
  public LISTA_DE_GENEROS       = LISTA_DE_GENEROS;
  public LISTA_DE_PAISES        = LISTA_DE_PAISES;
  public getListadoSiNo         = getListadoSiNo;
    
  // Traducción
  public activeLang:string;
  
  // Servicios
  public appSettings      : AppSettingsService;
  public fn               : FuncionesService;
  public bdService        : BDService;
  public apis             : ApisService;
  public msg              : MensajesService;
  public authService      : AuthService;
  public toastrService    : ToastrService;
  public confirmService   : ConfirmService;
  public alertService     : AlertService;
  public router           : Router;
  public activatedRoute   : ActivatedRoute;
  public af               : AngularFirestore;
  public fb               : FormBuilder;
  public translate        : TranslateService;
  public spinner          : NgxSpinnerService;
  public orderPipe        : OrderPipe;
  public http             : HttpClient;
  public soapService      : SoapService;
  public document         : any;  
  
  // Variables Adicionales de soap-generica
  public existeImportadorExcel          : boolean=false;
  public existeSOAP_RoadNet             : boolean=false;
  public configExcel                    : ConfigExcel=null;
  public generadorImportadores          : GeneradorImportadores;
  public soap                           : Soap=null;
  public ignorarSoapUsuario             : boolean=false;
  
  public importarTodos                  : boolean=false;
  public vecEjecucionesSoap             : any[]=[];
  public cantSeleccionados              : number=0;
  public cantSeleccionadosOk            : number=0;
  
  public organizacionSoap               : any=null;
  public listadoSucursales              : any[]=[];  
  public subscripcionSucursales         : any=null;
  public listadoAreasNegocio            : any[]=[];
  public subscripcionAreasNegocio       : any=null;  
  public filtroSoapSucursalKey          : any=null;
  public filtroSoapAreaNegocio          : any=null;
  public filtroSoapCodigo               : any=null;  
  public filtroSoapDescripcion          : any=null;
  public filtroSoapConector             : any=null;
  public listadoSeleccionarTodos        : any[]=[];
  public finalizoSoapComponente         : boolean=false;
  public finalizoDownloadFiles          : boolean[]=[];
  public keyImportacion                 : string=null;
  public sucursalCodigo                 : string=null;
  public hideFiltrosListado             : boolean=true;
  public listadoSoapTiposUbicacion      : any[]=[];  
  public listadoSoapTiposCuenta         : any[]=[];  
  public listadoSoapTiposVehiculos      : any[]=[];  
  public cualSpinner                    : string='buscando'; 
  public idSesionSeleccionada           : string=null;  
  public nombreSesionSeleccionada       : string=null;  
  public fechaSesionSeleccionada        : Date=null;  
  public listadoConectoresSoap          : any[]=[];  
  public busquedasResueltas             : any[]=[];
  public tipoImportacion                : string=TIPO_IMPORTACION.Soap;
  
  public listadosXOrganizacion          : any[]=[];
  public coleccionesXOrganizacion       : any[]=[];
  public cantXOrganizacionPendientes    : number=0;
  public subscripcionXOrganizacion      : any[]=[];
  
  @ViewChild('fileInput1') fileInput1       : ElementRef;  

  
  constructor(protected changeDetectorRef: ChangeDetectorRef) {    

        const injector = AppInjectorSoap.getInjector();
        
        // console.log('AppInjectorSoap injector',injector); 
        
        // AppInjectorSoap.setInjector();
        this.appSettings      = injector.get(AppSettingsService); 
        this.fn               = injector.get(FuncionesService);
        this.bdService        = injector.get(BDService);
        this.apis             = injector.get(ApisService);
        this.msg              = injector.get(MensajesService);
        this.authService      = injector.get(AuthService);
        this.toastrService    = injector.get(ToastrService);
        this.confirmService   = injector.get(ConfirmService);
        this.alertService     = injector.get(AlertService);
        this.router           = injector.get(Router);
        this.activatedRoute   = injector.get(ActivatedRoute);
        this.af               = injector.get(AngularFirestore);
        this.fb               = injector.get(FormBuilder);
        this.translate        = injector.get(TranslateService);
        this.spinner          = injector.get(NgxSpinnerService);
        this.orderPipe        = injector.get(OrderPipe);
        this.http             = injector.get(HttpClient);
        this.soapService      = injector.get(SoapService);
        this.document         = injector.get(DOCUMENT);
        
        // @Inject(DOCUMENT) document
        // dateTimeAdapter: DateTimeAdapter<any> 
        // private owlDateTimeIntl: OwlDateTimeIntl       
        
    //   console.clear();

      // log(...values('funcionComponente','constructor soapGenerica'));

  }

  setGrilla(valores:any) {
    //   console.log("setGrilla",valores);

      let objValores = {};
      if(valores.paginadoTipo!==undefined)          objValores['paginadoTipo']          = valores.paginadoTipo;
      if(valores.orderField!==undefined)            objValores['orderField']            = valores.orderField;
      if(valores.orderReverse!==undefined)          objValores['orderReverse']          = valores.orderReverse;
      if(valores.orderServer!==undefined)           objValores['orderServer']           = valores.orderServer;
      if(valores.filtroNombre!==undefined)          objValores['filtroNombre']          = valores.filtroNombre;
      if(valores.filtrosServer!==undefined)         objValores['filtrosServer']         = valores.filtrosServer;
      if(valores.filtrosGrupos!==undefined)         objValores['filtrosGrupos']         = valores.filtrosGrupos;
      if(valores.filtrosInicial!==undefined)        objValores['filtrosInicial']        = valores.filtrosInicial;
      if(valores.campoKeywords!==undefined)         objValores['campoKeywords']         = valores.campoKeywords;      
      if(valores.paginadoCantidad!==undefined)      objValores['paginadoCantidad']      = valores.paginadoCantidad;
      if(valores.paginadoAutoHide!==undefined)      objValores['paginadoAutoHide']      = valores.paginadoAutoHide;
      if(valores.verColumnaKey!==undefined)         objValores['verColumnaKey']         = valores.verColumnaKey;
      if(valores.whereArray !==undefined)           objValores['whereOriginal']         = valores.whereArray;
      if(valores.whereArray !==undefined)           objValores['whereArray']            = valores.whereArray;
      if(valores.camposDecimal!==undefined)         objValores['camposDecimal']         = valores.camposDecimal;
      if(valores.cotizacionesActivas!==undefined)   objValores['cotizacionesActivas']   = valores.cotizacionesActivas;

      if(valores.paginadoTipo=='local') {
          objValores['paginaRequerida'] = 'todas';
          this.pipePaginado = {  
            paginate: { 
              itemsPerPage: 20, 
              currentPage: this.pagePaginado 
            }
          }
      } else {
          objValores['paginaRequerida'] = 'primera';
      }    
      
      //this.grilla = new Grilla(objValores);
      this.grilla.setValue(this.configComponente.nombreColeccion, objValores);
 
      // Inicializo Filtros
      for(let i=0; i<this.grilla.filtrosServer.length; i++) {
        let nombreFiltro=this.grilla.filtrosServer[i];
        this.grilla.filtros[nombreFiltro] = null;
      } 

  }    

  setArrayFILES(fieldName:string, valores:any) {
      this.arrayFILES.push(fieldName);
      this.arrayFILES[fieldName] = valores;
  }

  ngOnDestroy() {
    // log(...values('funcionComponente','ngOnDestroy soapGenerica'));
    
    if(this.subscripcionSucursales)   this.subscripcionSucursales.unsubscribe();
    if(this.subscripcionAreasNegocio) this.subscripcionAreasNegocio.unsubscribe();

    for (var i=0; i<this.subscripcionCache.length; i++) {
        if(this.subscripcionCache[i]) {
          this.subscripcionCache[i].unsubscribe();
        //   console.log("desubscripcion",i);
        }
    }

  }  
  
  ngOnInit() {

    // log(...values('funcionComponente','ngOnInit soapGenerica'));

    this.settings2 = this.appSettings.settings2;  
    // console.log("this.settings2",this.settings2);
    
    // Inicializo Cotizaciones
    this.cotizaciones['Dolares']          = null;
    this.cotizaciones['DolaresBlue']      = null;
    this.cotizaciones['DolaresDivisa']    = null;
    this.cotizaciones['Euros']            = null;
    this.cotizaciones['Reales']           = null;
    this.cotizaciones['fechaHora']        = null;
    
    this.grilla = new Grilla({});

    this.msg.getPerfil().subscribe(usuario=>{
        // log(...values('valores','usuario:',usuario));

        this.usuario=usuario;
        this.usuarioKANE              = this.fn.setearKANE(this.usuario);
        this.tipoPerfilUsuario        = this.usuario.perfilUsuario.tipo;
        this.grilla.tipoPerfilUsuario = this.tipoPerfilUsuario;
        this.permisos                 = this.fn.setPermisosEdicion(usuario.perfilUsuario.permisosMenu, this.router.url, this.permisos);
        this.organizacionKNAI         = this.fn.setearOrganizacionKNAI(this.usuario.organizacion);
        this.distribuidorKN           = this.fn.setearKN(this.usuario.distribuidor);
        
        if(this.ignorarSoapUsuario==false) {
            this.organizacionSoap         = this.usuario.organizacion;
            this.soap                     = (this.organizacionSoap && this.organizacionSoap.soap!==undefined) ? this.organizacionSoap.soap : null;
        }
        
        // log(...values("valores","this.organizacionSoap:",this.organizacionSoap));
        // log(...values("valores","this.organizacionKNAI:",this.organizacionKNAI));
        // log(...values("valores","this.distribuidorKN:",this.distribuidorKN));
        // log(...values("valores"," this.permisos:", this.permisos));
        // log(...values("valores"," this.soap:", this.soap));
        
        this.configuracionComponente();  
        this.configuracionFormulario();  

        // log(...values("valores","nombreColeccion:",this.nombreColeccion));

        if(this.organizacionSoap) {
            if(this.tipoImportacion==TIPO_IMPORTACION.Soap) {            
                if(this.nombreColeccion!='Rutas') {
                    this.getListadoConectoresSoap();        
                    this.getListadoSucursales();                        
                }
                
            } else {                                
                this.setListadosXOrganizacion();                    
            }                
            
        }
        
        // --------------------------------------------------------------
        // Subscripción a Tablas Auxiliares
        // --------------------------------------------------------------
        this.getSubscripcionSecundarias();

    }); 
    
    // Listener de Cambio de Idioma
    this.inicializarVariablesTraduciblesPageGenerica();
    this.translate.onLangChange.subscribe(() => {
        this.inicializarVariablesTraduciblesPageGenerica();
    });

  }
  
  configuracionComponente() {
      
    // log(...values('funcionComponente','configuracionComponente'));      
      
        // Seteo Grilla
        this.setGrilla(this.configComponente.grilla);
        console.log("this.grilla",this.grilla);

        // Colección Principal
        this.nombreColeccion             = this.configComponente.nombreColeccion;
        this.columnasAdicionalesLogTable = this.configComponente.columnasAdicionalesLogTable;

        // Colecciones Auxiliares
        this.configListadosCache=this.configComponente.configListadosCache;
        console.log("this.configListadosCache",this.configListadosCache);
      
  }  
  
  cloneAbstractControl<T extends AbstractControl>(control: T): T {
        let newControl: T;
      
        if (control instanceof FormGroup) {
          const formGroup = new FormGroup({}, control.validator, control.asyncValidator);
          const controls = control.controls;
      
          Object.keys(controls).forEach(key => {
            formGroup.addControl(key, this.cloneAbstractControl(controls[key]));
          })
      
          newControl = formGroup as any;
          
        } else if (control instanceof FormArray) {
          const formArray = new FormArray([], control.validator, control.asyncValidator);
      
          control.controls.forEach(formControl => formArray.push(this.cloneAbstractControl(formControl)))
      
          newControl = formArray as any;
          
        } else if (control instanceof FormControl) {
            newControl = new FormControl(control.value, control.validator, control.asyncValidator) as any;

        } else {
          throw new Error('Error: unexpected control value');
        }
      
        if (control.disabled) newControl.disable({emitEvent: false});
      
        return newControl;
  }  
  
  configuracionFormulario() {    
        // log(...values('funcionComponente','configuracionFormulario'));      

        // Configuro el Formulario   
        //this.form = this.configComponente.form;    // original
        this.form = this.cloneAbstractControl(this.configComponente.form);  // ok
        
        // console.log("this.configComponente.form",this.configComponente.form);
        // console.log("this.form",this.form);
        
        // Configuro FILES gestionados por el formulario   
        this.arrayFILES=[];
        for(let i=0; i<this.configComponente.arrayFiles.length; i++) {
          let objFile = this.configComponente.arrayFiles[i];
          this.setArrayFILES( objFile.nameField, {
              type             : objFile.type,
              link             : null,
              linkThumb        : null,
              nombreArchivo    : null,
              extensionArchivo : null,
              borrar           : false
          });              
        }
        // console.log("this.arrayFILES",this.arrayFILES);
        
  }  

  resetFormulario() {
    this.configuracionFormulario();
  }
  
  inicializarVariablesTraduciblesPageGenerica(){
      // // log(...values('funcionComponente','inicializarVariablesTraduciblesPageGenerica'));
      // // log(...values("valores","this.translate:",this.translate));  
      // // log(...values("valores","this.translate.store.currentLang:",this.translate.store.currentLang));  
      
      this.activeLang=this.translate.store.currentLang

      this.listadoSeleccionarTodos=[
        { key: 'seleccionarTodos', nombre: this.translate.instant('soap.seleccionarTodos') },
        { key: 'seleccionarAunNoImportados', nombre: this.translate.instant('soap.seleccionarAunNoImportados') },
        { key: 'desSeleccionarTodos', nombre: this.translate.instant('soap.desSeleccionarTodos') }];
        
  }
  
  postGETActualizaValores(listado:any):any {

      if(this.grilla.paginadoTipo == 'local') {
        // salgo, esta función se usa solamente para paginadoTipo 'servidor'
        return listado;
      }

      let ascDesc = (this.grilla.orderReverse==true) ? 'desc' : 'asc';

      if(this.grilla.paginaRequerida=='anterior') {  // Vino en Orden Invertido
          listado.sort(this.fn.ordenarXAtributo(this.grilla.orderField, ascDesc, false));
      }

      // Actualizo nro de página actual
      if(this.grilla.paginaRequerida==this.grilla.paginaRequeridaAnterior) {
         if(this.grilla.paginaRequerida=='siguiente') {
              this.grilla.paginaActualNro++;
          } else {
              this.grilla.paginaActualNro--;
          }
      } else {
          if(this.grilla.paginaRequerida=='primera')          this.grilla.paginaActualNro = 1;
          if(this.grilla.paginaRequerida=='siguiente')        this.grilla.paginaActualNro++;
          if(this.grilla.paginaRequerida=='anterior')         this.grilla.paginaActualNro--;
          if(this.grilla.paginaRequerida=='anteriorOverflow') this.grilla.paginaActualNro--;
      }
      if(this.grilla.paginaRequerida=='primera') {
         this.grilla.paginaRequeridaAnterior = 'siguiente';
      } else {
         this.grilla.paginaRequeridaAnterior = this.grilla.paginaRequerida;
      }

      // Actualizo Cantidad de Resultados
      this.grilla.resultCantidad = listado.length;

      // Actualizo resultKey1, resultKeyN
      if(this.grilla.paginaActualNro>1 && this.grilla.resultCantidad==0) {
          // Avanzó luego de la última página con resultados y no le trajo nada obviamente
          this.grilla.paginaOverflow = true;
          this.grilla.resultKey1     = this.grilla.resultKey1;
          this.grilla.resultKeyN     = null;
      } else {
          this.grilla.paginaOverflow = false;
          this.grilla.resultKey1     = null;
          this.grilla.resultKeyN     = null;
          for(let i=0; i<listado.length; i++) {
             let documento=listado[i];
            //  if(i==0)                this.grilla.resultKey1 = documento[this.grilla.orderField];
            //  if(i==listado.length-1) this.grilla.resultKeyN = documento[this.grilla.orderField];

             if(i==0)                this.grilla.resultKey1 = this.fn.getDocField(documento,this.grilla.orderField);
             if(i==listado.length-1) this.grilla.resultKeyN = this.fn.getDocField(documento,this.grilla.orderField);
             
          }
      }

      return listado;
  }

  onChangeGrilla(cual, value:string) {

      // Hizo click en alguna de las columnas para cambiar el orden
      if(cual=='order') {
          if(this.grilla.paginadoTipo=='servidor') { 
              if(this.grilla.orderServer.indexOf(value)!=-1) {  
                  // Sólo ejecuto el ordenamiento en modo servidor
                  // si la columana está incluída entre las disponibles para ordenar
                  this.grilla.setOrder(value);
                  this.grilla.paginaRequerida = 'primera';
                  this.getSubscripcionPrincipal();
              }    
          } else {
              this.grilla.setOrder(value);
          }           
      }

      // Hizo click en el dropDown de cantidad de elementos a mostrar en el paginado
      if(cual=='paginadoCantidad') {
          if(this.grilla.paginadoTipo=='servidor') { 
              this.grilla.paginaRequerida = 'primera';
              this.getSubscripcionPrincipal();
          }  
      }  
  }  
  
  resetFiltros(vecFiltrosNoResetar?:any) {
      // Inicializo Filtros
      for(let i=0; i<this.grilla.filtrosServer.length; i++) {
        let nombreFiltro=this.grilla.filtrosServer[i];
        
        if(vecFiltrosNoResetar===undefined) {
            vecFiltrosNoResetar=[];
        }
        
        if(vecFiltrosNoResetar.indexOf( nombreFiltro )==-1) {
            // console.log("xx vecFiltrosNoResetar",vecFiltrosNoResetar);
            // console.log("nombreFiltro",nombreFiltro);
            // console.log("xx this.grilla.filtros[nombreFiltro]",this.grilla.filtros[nombreFiltro]);
            
            if(this.grilla.filtros[nombreFiltro]!==undefined && this.grilla.filtros[nombreFiltro]!==null && 
               this.grilla.filtros[nombreFiltro].desde !== undefined) {
                // Filtro por Rango de Fechas o por Fecha
                let fieldSearch:any = "filtro_"+nombreFiltro;
                $("#"+fieldSearch)[0].value=this.translate.instant('claseDateTime.periodo');
                $("#"+fieldSearch).removeClass('filtroConValor');
                $("#"+fieldSearch).addClass('filtroSinValor');                    
            }
            this.grilla.filtros[nombreFiltro] = null;
        }    
      } 
      
      this.filtrarGrilla();
  }   
  
  colorFilaBody(documento) {      
    if(documento.yaImportado) {
        return { 'color': '#aaa' }
    } else {
        return { }
    }
  } 

  filtrarGrilla(nameFiltro?:string) { 
      
        // log(...values('funcionGo','filtrarGrilla',nameFiltro));
        
        if(this.grilla.paginadoTipo=='local') {
            
            this.setAccionForm('listado');  
            this.spinner.hide();                    
            return;
        }    
        
        this.grilla.whereArray = this.fn.copiarArray( this.grilla.whereOriginal );
        
        // Verifico si está dentro de un grupo de filtros, y reseteo el resto si el grupo asi lo define
        if(nameFiltro!==undefined) {
            for(let i=0; i< this.grilla.filtrosGrupos.length; i++) {
                let agrupamiento = this.grilla.filtrosGrupos[i];
                // console.log("filtrarGrilla agrupamiento",agrupamiento, nameFiltro);
                if(agrupamiento.filtros.indexOf(nameFiltro)!=-1) {
                    // console.log("filtrarGrilla agrupamiento ok",agrupamiento);
                    if(agrupamiento.resetResto) {
                       this.resetFiltros(agrupamiento.filtros);
                    }    
                    
                    let cantFiltrosAgrupamiento  = agrupamiento.filtros.length;
                    let cantFiltrosSeleccionados = 0;
                    for(let j=0; j< agrupamiento.filtros.length; j++) {
                        let nombreFiltroGrupo = agrupamiento.filtros[j];
                        if(this.grilla.filtros[nombreFiltroGrupo]) {
                            cantFiltrosSeleccionados++;
                        }
                    }
                    if(cantFiltrosSeleccionados < cantFiltrosAgrupamiento) {
                        let filtrosIncompletos = '';
                        let cantIncompletos    = 0;
                        let mensajeError       = '';
                        for(let j=0; j< agrupamiento.filtros.length; j++) {
                            let nombreFiltroGrupo = agrupamiento.filtros[j];
                            if(nombreFiltroGrupo!=nameFiltro && !this.grilla.filtros[nombreFiltroGrupo]) {
                                filtrosIncompletos+=", "+nombreFiltroGrupo;
                                cantIncompletos++;
                            }
                        }
                        if(cantIncompletos>0) {
                            filtrosIncompletos=filtrosIncompletos.substr(2,1000);
                            if(cantIncompletos==1) {
                                mensajeError = this.translate.instant('mensajes.grupoFiltrosIncompleto1', {
                                                    nameFiltro         : nameFiltro,
                                                    filtrosIncompletos : filtrosIncompletos
                                                });
                            } else {
                                mensajeError = this.translate.instant('mensajes.grupoFiltrosIncompleto1', {
                                                    nameFiltro         : nameFiltro,
                                                    filtrosIncompletos : filtrosIncompletos
                                                });
                            }
                            console.log("filtrarGrilla mensajeError:",mensajeError);
                            //alert(mensajeError);
                            
                            // Coloreo Campos del Grupo
                            for(let j=0; j< agrupamiento.filtros.length; j++) {
                                let nombreFiltroGrupo = agrupamiento.filtros[j];
                                
                                this.grilla.filtrosStyle[nombreFiltroGrupo]={ 'border':'2px solid #444' }
                            }
                            for(let j=0; j< agrupamiento.filtros.length; j++) {
                                let nombreFiltroGrupo = agrupamiento.filtros[j];
                                if(nombreFiltroGrupo!=nameFiltro && !this.grilla.filtros[nombreFiltroGrupo]) {
                                    this.grilla.filtrosStyle[nombreFiltroGrupo]={ 'border':'2px solid #444', 'color':'red'}
                                }
                            }
                            
                            return;    
                        }
                    }   
                    break;
                }
            }    
        }    
        
        // Recorro todos los filtros definidos para el modo servidor
        let cant=this.grilla.whereOriginal.length;
        for(let i=0; i< this.grilla.filtrosServer.length; i++) {
            let nombreFiltro = this.grilla.filtrosServer[i];
            let valueFiltro  = this.grilla.filtros[ nombreFiltro ];
        
            // Quito estilo resaltado a un grupo que haya dado error en ejecución previa
            this.grilla.filtrosStyle[nombreFiltro]={}
            
            // Me fijo si el input/select de la grilla tiene un valor asignado (null o undefined significa que no fue seleccionado)
            //console.log("typeof valueFiltro",nombreFiltro, valueFiltro, typeof valueFiltro);
            if(valueFiltro!==undefined && valueFiltro!=null && (typeof valueFiltro!=='string' || valueFiltro!='')) {
                
                let keyWhere = nombreFiltro
                let operador;
                let valueWhere = valueFiltro;
                
                //console.log(nombreFiltro," == ",this.grilla.filtroNombre);
                if(nombreFiltro == this.grilla.filtroNombre) {
                    console.log('test valueWhere ',valueWhere);
                    console.log('test typeof valueWhere ',typeof valueWhere);
                    if(this.grilla.campoKeywords==false) {
                        if(this.grilla['camposDecimal'].indexOf(keyWhere)!=-1){
                            valueWhere= parseInt(valueWhere);
                        }
                        operador = '==';
                    } else {
                        keyWhere = 'keywords';
                        operador = 'array-contains';  
                        
                        valueWhere = this.fn.reemplazaAcentos(valueWhere);
                        // valueWhere = this.fn.quitaCaracteresEspeciales(valueWhere);
                        valueWhere = valueWhere.toLowerCase();                                      
                    }
                } else if(nombreFiltro.includes('ARRAY')) {
                        operador = 'array-contains';  

                } else if(typeof valueFiltro==='string') {
                    operador = '==';
                    
                } else if(typeof valueFiltro==='object') {
                    let fechaDesde = valueFiltro.desde.substr(6,4)+'-'+valueFiltro.desde.substr(3,2)+'-'+valueFiltro.desde.substr(0,2)+ ' 00:00:00';
                    operador = '>=';
                    valueWhere = new Date(fechaDesde);
                } else {
                    operador = '==';                    
                }
                
                let newWhere = { 
                    key      : keyWhere, 
                    operador : operador, 
                    value    : valueWhere 
                }
                
                this.grilla.whereArray[cant]=newWhere;
                cant++;
                
                if(operador=='>=') {
                    let newWhere2:any
                    if(typeof valueFiltro==='object') {                    
                        let fechaHasta = valueFiltro.hasta.substr(6,4)+'-'+valueFiltro.hasta.substr(3,2)+'-'+valueFiltro.hasta.substr(0,2) + ' 23:59:59';
                        newWhere2 = { 
                            key      : nombreFiltro, 
                            operador : '<=', 
                            value    : new Date(fechaHasta)
                        }
                    } else {
                        newWhere2 = { 
                            key: nombreFiltro, 
                            operador: '<=', 
                            value: valueFiltro+'zzzzzz' 
                        }                                                
                    }    
                    this.grilla.whereArray[cant]=newWhere2;
                    cant++;
                }      
                          
            } // fin for      
            
        }
        
        this.grilla.paginaRequerida = 'primera';
        // log(...values("valores","this.grilla:",this.grilla));
        this.getSubscripcionPrincipal();            
        
  }  
  
  closeFilterRangoFechas(field:string, fieldSearch) {
    // console.log("closeFilterRangoFechas field",field);
    // console.log("closeFilterRangoFechas fieldSearch",fieldSearch);
    // console.log("closeDateTimePicker fechaHora.selecteds[0]", fechaHora.selecteds[0]);
    // console.log("closeDateTimePicker fechaHora.selecteds[1]", fechaHora.selecteds[1]);
    // console.log("closeFilterRangoFechas jQuery value",$("#"+fieldSearch)[0].value);

    let rango = $("#"+fieldSearch)[0].value;
    // 11/2/2020 ~ 27/2/2020

    let fechas = rango.split('~')
    let fecha1 = this.fn.formatFecha2DigitosDiaMes( fechas[0] );
    let fecha2 = this.fn.formatFecha2DigitosDiaMes( fechas[1] );

    // Parche para detectar que luego de indicar filtros, abrió nuevamente el modal y canceló sin elegir nuevos valores
    // en ese caso, el rango va a mantener los valores viejos, formateados sin años dd/mm  ~ dd/mm con 5 dígitos de extensión
    if(fecha1!='' && fecha1.length==5) fecha1='';
    if(fecha2!='' && fecha2.length==5) fecha2='';

    if(this.searchDateRango.indexOf(field)==-1) {
      this.searchDateRango.push(field);
    }    
    this.searchDateRango[field]={
      desde: fecha1,
      hasta: fecha2,
    };    
    this.grilla.filtros[field]={ 
      desde: fecha1,
      hasta: fecha2,
    }
    console.log(field, this.grilla.filtros[field]);
    this.filtrarGrilla(field);

    let rangoFormateado = '';
    if(fecha1!='' && fecha2!='') {
      rangoFormateado = this.fn.quitaAnio(fecha1) + ' ~ ' + this.fn.quitaAnio(fecha2);
    }  

    // console.log("closeFilterRangoFechas rangoFormateado",rangoFormateado);
    
    $("#"+fieldSearch)[0].value=rangoFormateado;
    $("#"+fieldSearch).removeClass('filtroSinValor');
    $("#"+fieldSearch).addClass('filtroConValor');

    if (!this.changeDetectorRef['destroyed']) {
       this.changeDetectorRef.detectChanges();
    }                

  }
    
  closeDateTimePicker(cual, fechaHora) {
    // console.log("closeDateTimePicker cual",cual);
    // console.log("closeDateTimePicker fechaHora", fechaHora);

  }
  
  
  compareFn(c1: any, c2:any): boolean {     
    // console.log("compareFn c1", c1);
    // console.log("compareFn c2", c2);
      return c1 && c2 ? c1.key === c2.key : c1 === c2; 
  }
  
  isIdiomaHabilitado(idiomaKey:string): boolean {
      if(this.settings2.app.idiomasHabilitados.indexOf(idiomaKey)!=-1) {
          return true;
      } else {
          return false;
      }
  }

  getCantidadDocumentosColeccion() {

      // log(...values('funcionComponente','getCantidadDocumentosColeccion'));

      let subscripcionCantidad;
      subscripcionCantidad=this.bdService  
          .getBDCantDocumentosPromesa(this.nombreColeccion)
          .then(data=>{

              // log(...values('funcionEnd','bdService.getBDCantDocumentosPromesa')); 
              // log(...values('valores','data:',data)); 

              this.grilla.setColeccionCantidadDocs(data.cantidadDocumentosFisicos, 
                                                   data.cantidadDocumentosLogicos, 
                                                   this.tipoPerfilUsuario);

          },(error:any)=>{
              // log(...values("error",error));
          });         
  }

  getSubscripcionPrincipal() {

      // log(...values('funcionComponente','getSubscripcionPrincipal'));

  }
  
  getSubscripcionSecundarias() {

      // log(...values('funcionComponente','getSubscripcionSecundarias'));

      /* Subscripción a Colecciones Secundarias */
      this.cantidadSubscripcionesSecundariasLanzadas=0;
      this.finalizoGETSecundarias=false;              
      
      if(this.grilla.cotizacionesActivas) {
          
          // Dolar Oficial
          this.configListadosCache.push({ 
                nombreListado   : 'listadoCotizacionesMonedasDolares',
                nombreColeccion : 'CotizacionesMonedas',
                orderBy         : [{key:'fechaHora',ascDesc:'desc'}],  
                where           : [
                  {key:'moneda1KMONEY.key',operador:'==',value:'Dolares'},
                  {key:'moneda2KMONEY.key',operador:'==',value:'Pesos'}
                ],
                limit           : 1,
                grabaLocalStorage:false        
          });

          // Dolar Oficial
          this.configListadosCache.push({ 
                nombreListado   : 'listadoCotizacionesMonedasDolaresBlue',
                nombreColeccion : 'CotizacionesMonedas',
                orderBy         : [{key:'fechaHora',ascDesc:'desc'}],  
                where           : [
                  {key:'moneda1KMONEY.key',operador:'==',value:'DolaresBlue'},
                  {key:'moneda2KMONEY.key',operador:'==',value:'Pesos'}
                ],
                limit           : 1,
                grabaLocalStorage:false        
          });
      
          // Dolar Divisa
          this.configListadosCache.push({ 
                nombreListado   : 'listadoCotizacionesMonedasDolaresDivisa',
                nombreColeccion : 'CotizacionesMonedas',
                orderBy         : [{key:'fechaHora',ascDesc:'desc'}],  
                where           : [
                  {key:'moneda1KMONEY.key',operador:'==',value:'DolaresDivisa'},
                  {key:'moneda2KMONEY.key',operador:'==',value:'Pesos'}
                ],
                limit           : 1,
                grabaLocalStorage:false        
          });     
      }
      
      this.cantidadSubscripcionesSecundariasLanzadas=this.configListadosCache.length;
      //console.log("this.configListadosCache ",this.configListadosCache);
      
      for(let i=0; i<this.configListadosCache.length; i++) {
          
          this.subscripcionCache.push( 
              this.msg.getListadoCache({
                  nombreListado                       : this.configListadosCache[i].nombreListado,
                  nombreColeccion                     : this.configListadosCache[i].nombreColeccion,
                  where                               : this.configListadosCache[i].where  !== undefined               ? this.configListadosCache[i].where  :[],
                  orderBy                             : this.configListadosCache[i].orderBy !== undefined              ? this.configListadosCache[i].orderBy:[],
                  limit                               : this.configListadosCache[i].limit !== undefined                ? this.configListadosCache[i].limit  :null,
                  grabaLocalStorage                   : this.configListadosCache[i].grabaLocalStorage!== undefined     ? this.configListadosCache[i].grabaLocalStorage:true,
                  ignoraValoresMemoria                : this.configListadosCache[i].ignoraValoresMemoria!== undefined  ? this.configListadosCache[i].ignoraValoresMemoria:false,
                  datosPorOrganizacion                : this.configListadosCache[i].datosPorOrganizacion!== undefined  ? this.configListadosCache[i].datosPorOrganizacion:false,
                  organizacionKNAI                    : this.organizacionKNAI,
                  usuarioKANE                         : this.usuarioKANE,
                  nombreColeccionSolicitante          : this.nombreColeccion,
                  limpiaSettingUsuarioOrganizacion    : false,
              }).pipe(take(1)).subscribe(data=>{
                  // console.log("this.configListadosCache[i]",this.configListadosCache[i]);
                  let nombreListado =this.configListadosCache[i].nombreListado.replace('listado','').replace('Listado','')

                  // log(...values("valores", "msg.cacheColecciones[" + nombreListado + "]:",  this.msg.cacheColecciones[nombreListado]));
                  
                  this.cantidadSubscripcionesSecundariasLanzadas--;
                  
                //   console.log("cantidadSubscripcionesSecundariasLanzadas",this.cantidadSubscripcionesSecundariasLanzadas, nombreListado);
                  if(this.cantidadSubscripcionesSecundariasLanzadas==0) {  // Devolvió resultados la última subscripción
                        this.finalizoGETSecundarias=true;
                        // cuando están todas las respestas me desiscribo
                        // for (var i=0; i<this.subscripcionCache.length; i++) {
                        //     if(this.subscripcionCache[i]) {
                        //       this.subscripcionCache[i].unsubscribe();
                            
                        //     }
                        // }              
                        this.onResultGetSubscripcionSecundarias();    
                  }

              },(error:any)=>{
                  // log(...values("error",error));
                  this.onResultGetSubscripcionSecundarias();
              }) 
          );    
      }
      
      if(this.configListadosCache.length==0) {
            this.onResultGetSubscripcionSecundarias();    
      }  
      
  }

  onResultGetSubscripcionSecundarias() {
    // log(...values('funcionComponente','soapGenerica.onResultGetSubscripcionSecundarias'));
    // console.log('this.msg.cacheColecciones',this.msg.cacheColecciones)

    if(this.grilla.cotizacionesActivas) {    
        
        // Dolar Oficial
        if(this.msg.cacheColecciones['CotizacionesMonedasDolares']){
            this.cotizaciones['Dolares']=this.msg.cacheColecciones['CotizacionesMonedasDolares'][0].cotizacion;
            this.cotizaciones['fechaHora']=this.msg.cacheColecciones['CotizacionesMonedasDolares'][0].fechaHora;
        } else{
            this.cotizaciones['Dolares']=0;
        }

        // Dolar Blue
        if(this.msg.cacheColecciones['CotizacionesMonedasDolaresBlue']){
            this.cotizaciones['DolaresBlue']=this.msg.cacheColecciones['CotizacionesMonedasDolaresBlue'][0].cotizacion;
            this.cotizaciones['fechaHora']=this.msg.cacheColecciones['CotizacionesMonedasDolaresBlue'][0].fechaHora;
        } else{
            this.cotizaciones['DolaresBlue']=0;
        }
        
        // Dolar Divisa
        if(this.msg.cacheColecciones['CotizacionesMonedasDolaresDivisa']){
            this.cotizaciones['DolaresDivisa']=this.msg.cacheColecciones['CotizacionesMonedasDolaresDivisa'][0].cotizacion;
            this.cotizaciones['fechaHora']=this.msg.cacheColecciones['CotizacionesMonedasDolaresDivisa'][0].fechaHora;
        } else{
            this.cotizaciones['DolaresDivisa']=0;
        }
        
        console.log("this.cotizaciones", this.cotizaciones);
        
    }
    
    this.existeTransferenciaExcell();
    
  }    

  // -------------- Emits de Componentes Incluídos ---------------------------------------

  emitClickPaginado(paginaRequerida:string):void{
     // log(...values('valores','emitClickPaginado paginaRequerida:',paginaRequerida));
     this.grilla.paginaRequerida = paginaRequerida;
     this.getSubscripcionPrincipal();
  }    
  
  EmitCargoSearchAutocompleteFiltro(fieldFiltro:string, bdFieldName:string, documento:any) {
      // console.log("EmitCargoSearchAutocompleteFiltro - fieldFiltro:",fieldFiltro,"bdFieldName:",bdFieldName,"documento:",documento);  

      this.grilla.filtros[fieldFiltro]= this.fn.getDocField(documento,bdFieldName);    
      // console.log("this.fn.getDocField(documento,"+bdFieldName+")",this.fn.getDocField(documento,bdFieldName));

      this.filtrarGrilla();
  }

  EmitCargoSearchAutocompleteForm(fieldForm:string, bdFieldName:string, documento:any) {
        // console.log("EmitCargoSearchAutocompleteForm - fieldForm:",fieldForm,"bdFieldName:",bdFieldName,"documento:",documento);  

        if(bdFieldName=='KNAI') {
            this.form.get(fieldForm).setValue( this.fn.setearOrganizacionKNAI(documento) );
        } else if(bdFieldName=='KANE') {
            this.form.get(fieldForm).setValue( this.fn.setearKANE(documento) );
        } else if(bdFieldName=='KN') {
            this.form.get(fieldForm).setValue( this.fn.setearKN(documento) );
        } else if(bdFieldName=='OBJ') {
            // if(documento.keywords!==undefined)         delete documento.keywords;
            // if(documento.settings!==undefined)         delete documento.settings;
            // if(documento.organizacionKNAI!==undefined) delete documento.organizacionKNAI;
            this.form.get(fieldForm).setValue( documento );
        } else {        
            this.form.get(fieldForm).setValue( this.fn.getDocField(documento,bdFieldName) );
        }
        // console.log("this.form.get("+fieldForm+").value",this.form.get(fieldForm).value);
        
        // En caso de que el formulario tenga seteado distribuidorKN, y venga el campo distribuidor lo seteo
        // Se utiliza en el módulo de Rutas, donde en caso de perfil Desarrollador, Supervisor, Distribuidor se ingresa a mano
        console.log("this.form",this.form);
        if(this.form.controls.distribuidorKN!==undefined && documento && documento['distribuidor']!=undefined) {
            this.distribuidorKN = this.fn.setearKN(documento['distribuidor']);            
            this.form.get('distribuidorKN').setValue( this.fn.setearKN(documento['distribuidor']) );            
        }
        
  }

  EmitCargoSearchAutocompleteOrganizacionSoap(documento:any) {
        console.log("EmitCargoSearchAutocompleteOrganizacionSoap:","documento:",documento);  

        this.organizacionSoap = documento;
        
        this.filtroSoapConector    = null;
        this.filtroSoapSucursalKey = null;

        this.soap = (this.organizacionSoap && this.organizacionSoap.soap!==undefined) ? this.organizacionSoap.soap : null;
        
        if(this.tipoImportacion==TIPO_IMPORTACION.Soap) {            
            this.getListadoConectoresSoap();        
            this.getListadoSucursales();
            
        } else {
            this.setListadosXOrganizacion();                
        }
  }
  
  emitCargoSubEstructura(subestructura){
     // log(...values('valores','emitCargoSubestructura subestructura:',subestructura));
     this.form.get(subestructura.nameField).setValue(subestructura.value);      
  }

  emitCargoZona(zona:any){
    // log(...values('valores','emitCargoZona zona:',zona));
    
    this.form.get('paisesDeOperacion').setValue(zona.paisesDeOperacion);      
    this.form.get('provinciasDeOperacion').setValue(zona.provinciasDeOperacion);      
    
  }

  emitDesabilitarGrabar(estado:boolean):void{
     // log(...values('valores','emitDesabilitarGrabar estado:',estado));
     this.permisos.deshabilitarGrabar = estado;  
  }    

  emitCargoArchivo(imagenes:any){
     // log(...values('valores','emitCargoArchivo imagenes:',imagenes));
     // this.uploadImagen=imagen;

     let fieldName = imagenes[0];
     this.arrayFILES[ fieldName ].nombreArchivo    = imagenes[1];
     this.arrayFILES[ fieldName ].extensionArchivo = imagenes[2];
     if(fieldName.includes('IMG')) {
        this.arrayFILES[ fieldName ].link          = imagenes[3];
        this.arrayFILES[ fieldName ].linkThumb     = imagenes[4];
     } else {
        this.arrayFILES[ fieldName ].link          = imagenes[3];
     }
     this.arrayFILES[ fieldName ].borrar           = false;

     if(fieldName.includes(".")) {
        let aux=fieldName.split('.') 
        this.form.value[ aux[0] ][ aux[1] ].link = this.arrayFILES[ fieldName ].link;    
     } else {
        this.form.value[fieldName].link = this.arrayFILES[ fieldName ].link;    
     }

     // log(...values('valores',`arrayFiles[${fieldName}]`),this.arrayFILES[ fieldName ]);

  }

  emitBorroArchivo(fieldName:any){
     // log(...values('valores','emitBorroArchivo fieldName:',fieldName));

     this.arrayFILES[ fieldName ].nombreArchivo    = null;
     this.arrayFILES[ fieldName ].extensionArchivo = null;
     this.arrayFILES[ fieldName ].link             = null;
     this.arrayFILES[ fieldName ].linkThumb        = null;
     this.arrayFILES[ fieldName ].borrar           = true;

     // log(...values('valores',`arrayFILES[${fieldName}]`),this.arrayFILES[ fieldName ]);

  }
  
  ValidaDocumentoIsActivo(opcionKey:string, documentoIsActivo:boolean, value:any):boolean {
      //console.log("ValidaDocumentoIsActivo",opcionKey,documentoIsActivo, value)
      if(this.accionForm=='agregar') { 
          return documentoIsActivo;
      } else if(this.accionForm=='consultar') {           
          return true;
      } else if(this.accionForm=='modificar') { 
           let valueKey:any=null;
          if(value===undefined || value==null) {
              valueKey = documentoIsActivo;   
          } else {
              valueKey = ( value['key']===undefined) ? value : value['key'];
          }    
          if(documentoIsActivo==true) {
              return true;
          } else if(value==null) {
              return false;
          } else if( valueKey==opcionKey) {
              return true;
          } else {
              return false;
          }
      }    
      
  }  
  
  setAccionForm(accion) {
    // log(...values('funcionComponente','setAccionForm soapGenerica', accion));

    this.accionForm=accion;
    
  }

  setListadosXOrganizacion() {
    console.log("setListadosXOrganizacion");

    this.listadosXOrganizacion       = [];
    this.cantXOrganizacionPendientes = this.coleccionesXOrganizacion.length;
    
    for(let i=0; i< this.coleccionesXOrganizacion.length; i++) {
        let coleccion = this.coleccionesXOrganizacion[i];
        this.getListadosXOrganizacion(coleccion);
    }
    
  }      

  getListadosXOrganizacion(coleccion) {
        console.log("getListadosXOrganizacion");
        let organizacionKNAI:any=this.fn.setearKNAI(this.organizacionSoap);
        
        let fieldOrganizacion = coleccion=='Usuarios' ? 'organizacion.key' : 'organizacionKNAI.key';
        let queryOrder = coleccion=='Usuarios' ? [] : [{key:'nombre',ascDesc:'asc'}];

        this.listadosXOrganizacion[coleccion]=[];
        
        this.subscripcionXOrganizacion[coleccion]=this.bdService	
        .getBDSubscripcion({
            nombreColeccion  : coleccion,
            where            : [{key:fieldOrganizacion, operador:'==', value:organizacionKNAI.key}],
            orderBy          : queryOrder,
            limit            : 500,
            paginado         : 'primera',
            organizacionKNAI : organizacionKNAI,
            usuarioKANE      : this.usuarioKANE                
        }).subscribe((data:any)=>{
            // log(...values('funcionEnd','bdService.getBDSubscripcion Sucursales')); 
            // log(...values('valores','subscripcion Sucursales data:',data)); 
            
            this.subscripcionXOrganizacion[coleccion].unsubscribe();
            this.cantXOrganizacionPendientes--;
            
            for(let i=0; i<data.length; i++) {
                  let documento=data[i];
                  documento=this.fn.corrigeTimestampDocumento(documento);  
                  this.listadosXOrganizacion[coleccion].push(documento);    
            }             
            // log(...values("valores","listadosXOrganizacion["+coleccion+"]:",this.listadosXOrganizacion[coleccion]));
            
        },(error:any)=>{
            // log(...values("error al listadosXOrganizacion["+coleccion+"]:",error));
        });         
            
  }
  
  getListadoConectoresSoap() {
      this.listadoConectoresSoap=[];
      this.filtroSoapConector = null;
      if(this.organizacionSoap && this.organizacionSoap.soap && 
         this.organizacionSoap.soap.hostRoadNet!==undefined  && this.organizacionSoap.soap.hostRoadNet!=null) {
             
            this.listadoConectoresSoap.push({
               key    : 'RoadNet', 
               host   : this.organizacionSoap.soap.hostRoadNet,
               nombre : 'RoadNet'
            });
      }
      if(this.organizacionSoap && this.organizacionSoap.soap &&
         this.organizacionSoap.soap.hostTerritoryPlanner!==undefined && this.organizacionSoap.soap.hostTerritoryPlanner!=null) {
             
            this.listadoConectoresSoap.push({
               key    : 'TerritoryPlanner', 
               host   : this.organizacionSoap.soap.hostTerritoryPlanner,
               nombre : 'Territory Planner'
            });
      }
      if(this.listadoConectoresSoap.length==1) {
            this.filtroSoapConector = this.listadoConectoresSoap[0];
      }
      console.log("getListadoConectoresSoap organizacionSoap",this.organizacionSoap);
      console.log("getListadoConectoresSoap listadoConectoresSoap",this.listadoConectoresSoap);
      console.log("getListadoConectoresSoap filtroSoapConector",this.filtroSoapConector);
  }
  
  getHostConector() {
      return (this.filtroSoapConector && this.filtroSoapConector.host!=undefined) ? this.filtroSoapConector.host : null
  }

  desSeleccionarTodos() {
      
    console.log("desSeleccionarTodosImportar");
    
    this.importarTodos=false;
    for(let i=0; i<this.listadoPrincipal.length; i++) {
          this.listadoPrincipal[i].importar = false;  
    }  
    
    this.cantSeleccionados   = 0;
    this.cantSeleccionadosOk = 0;
      
  }
  
  seleccionarItem(documentoKey) {
    for(let i=0; i<this.listadoPrincipal.length; i++) {
        if(this.listadoPrincipal[i].key == documentoKey) {
            this.listadoPrincipal[i].importar = !this.listadoPrincipal[i].importar;  
            
            // if(this.listadoPrincipal[i].importar) {
            //   this.onPostSeleccionarItem(this.listadoPrincipal[i],i);
            // } else {
            //   this.contarCantSeleccionados();
            // }  
            
            if(this.listadoPrincipal[i].importar) {
                this.onPostSeleccionarItem(this.listadoPrincipal[i],i);
            }    
            this.contarCantSeleccionados();
        }          
    }    
  }
  
  onPostSeleccionarItem(documento:any, posInListado:number) {
      // No hace nada, cuando hace falta, se define en el componente      
  }  

  aptoImportacion(documento:any, agregarErroresEnDocumento:boolean=false):boolean {
    // No hace nada, se define en el componente 
    return documento.aptoImportar;     
  }  

  contarCantSeleccionados() {
      console.log("contarCantSeleccionados");
      this.cantSeleccionados=0;
      this.cantSeleccionadosOk=0;
      for(let i=0; i<this.listadoPrincipal.length; i++) {
        if(this.listadoPrincipal[i].importar) this.cantSeleccionados++;
        
        // if(this.listadoPrincipal[i].importar && this.aptoImportacion(this.listadoPrincipal[i], false) == true) {
        if(this.listadoPrincipal[i].importar && this.aptoImportacion(this.listadoPrincipal[i], false) == true) {

          this.cantSeleccionadosOk++;
        }
      }  
  }

  contarCantSeleccionadosImportadorGenerico() {
    console.log("contarCantSeleccionados");
    this.cantSeleccionados=0;
    this.cantSeleccionadosOk=0;
    for(let i=0; i<this.listadoPrincipal.length; i++) {
      if(this.listadoPrincipal[i].importar) this.cantSeleccionados++;
      
      // if(this.listadoPrincipal[i].importar && this.aptoImportacion(this.listadoPrincipal[i], false) == true) {
      if(this.listadoPrincipal[i].importar && this.listadoPrincipal[i].aptoImportar) {

        this.cantSeleccionadosOk++;
      }
    }  
}
  
  httpPOSTSoap(method:string, parameters:any): Promise<any> {
      return new Promise( (resolve,reject)=>{

        // let url = 'https://us-central1-pktest-ad982.cloudfunctions.net/soapRoadNet';
        // let url = 'https://southamerica-east1-demokipbip.cloudfunctions.net/soapRoadNet';
        // let url = environment.httpSoapGenerica;
        let url = this.usuario.getUrlBaseDatos() + 'soapRoadNet';

        
        const httpOptions = {
            headers: new HttpHeaders({    
                'Content-Type': 'application/json', 
                'Access-Control-Allow-Origin': '*'                                
            })
        };        
        
        let subscripSoap=this.http.post(url, parameters, httpOptions)
          .subscribe((jsonResponse:any) => {
              console.log(method+ ' - recibió respuesta de Cloud Function soapRoadNet:',jsonResponse);
              subscripSoap.unsubscribe();
              resolve(jsonResponse);
              
          }, error => {
               console.log(method + ' - error al conectarse al Cloud Function soapRoadNet:',error);
               subscripSoap.unsubscribe();
               reject(error);               
          });        

      });
  }

  httpPOSTSoapUbicaciones(method:string, parameters:any): Promise<any> {
      return new Promise( (resolve,reject)=>{

        // let url = 'https://us-central1-pktest-ad982.cloudfunctions.net/soapRoadNetUbicaciones';
        // let url = 'https://southamerica-east1-demokipbip.cloudfunctions.net/soapRoadNet';
        // let url = environment.httpSoapGenerica;
        let url = this.usuario.getUrlBaseDatos() + 'soapRoadNet';

        
        const httpOptions = {
            headers: new HttpHeaders({    
                'Content-Type': 'application/json', 
                'Access-Control-Allow-Origin': '*'                                
            })
        };        
        
        let subscripSoap=this.http.post(url, parameters, httpOptions)
          .subscribe((jsonResponse:any) => {
              console.log(method+ ' - recibió respuesta de Cloud Function soapRoadNet:',jsonResponse);
              subscripSoap.unsubscribe();
              resolve(jsonResponse);
              
          }, error => {
                
               console.log(method + ' - error al conectarse al Cloud Function soapRoadNet:',error);
               subscripSoap.unsubscribe();
               reject(error);               
          });        

      });
  }
  
  getListadoSucursales() {
        console.log("getListadoSucursales - this.organizacionSoap",this.organizacionSoap);
        let organizacionKNAI:any=this.fn.setearKNAI(this.organizacionSoap);

        // GET Sucursales
        this.listadoSucursales=[];
        if(organizacionKNAI) {
                if(this.subscripcionSucursales)   this.subscripcionSucursales.unsubscribe();
                this.subscripcionSucursales=this.bdService	
                    .getBDSubscripcion({
                        nombreColeccion  : 'Sucursales',
                        where            : [{key:'organizacionKNAI.key', operador:'==', value:organizacionKNAI.key}],
                        orderBy          : [{key:'nombre',ascDesc:'asc'}],
                        limit            : 100,
                        paginado         : 'primera',
                        organizacionKNAI : organizacionKNAI,
                        usuarioKANE      : this.usuarioKANE                
                    }).subscribe((data:any)=>{
                        // log(...values('funcionEnd','bdService.getBDSubscripcion Sucursales')); 
                        // log(...values('valores','subscripcion Sucursales data:',data)); 
                        
                        this.subscripcionSucursales.unsubscribe();
                        this.listadoSucursales=[];                        
                        for(let i=0; i<data.length; i++) {
                              let documento=data[i];
                              documento=this.fn.corrigeTimestampDocumento(documento);  
                              // Verifico que el usuario la tenga habilitada
                              if(this.tipoPerfilUsuario=='Organizacion') {
                                   for(let j=0; j<this.usuario.sucursalesAreasNegocio.length;j++) {
                                        let sucursalKey = this.usuario.sucursalesAreasNegocio[j].split('@@@')[0];
                                        if(sucursalKey==documento.key) {
                                            this.listadoSucursales.push(documento);    
                                            break;
                                        }
                                   } 
                              } else {
                                    this.listadoSucursales.push(documento);    
                              }    
                        }             
                        // log(...values("valores","listadoSucursales:",this.listadoSucursales));
                        if(this.listadoSucursales.length==1) {
                            this.filtroSoapSucursalKey = this.listadoSucursales[0].key;
                        }
                        
                        this.getListadoAreasNegocio();
 
                    },(error:any)=>{
                        // log(...values("error al obtener Sucursales",error));
                    });         
            }                  
  }

  getListadoAreasNegocio() {
      
        console.log("getListadoAreasNegocio");
        let organizacionKNAI:any=this.fn.setearKNAI(this.organizacionSoap);
        
        this.listadoPrincipal       = [];
        this.finalizoSoapComponente = false;
        this.idSesionSeleccionada   = null;
        
        let sucursal=null;
        for(let i=0; i<this.listadoSucursales.length;i++) {
            // console.log(this.listadoSucursales[i].key," == ",this.fn.mostrarKN(sucursalKN,'key'));
            if(this.listadoSucursales[i].key == this.filtroSoapSucursalKey) {
                sucursal=this.listadoSucursales[i];
                break;
            }
        } 
        //console.log("sucursal",sucursal);

        // GET AreasNegocio
        this.listadoAreasNegocio=[];
        if(this.filtroSoapSucursalKey) {
                if(this.subscripcionAreasNegocio)   this.subscripcionAreasNegocio.unsubscribe();
                this.subscripcionAreasNegocio=this.bdService	
                    .getBDSubscripcion({
                        nombreColeccion  : 'AreasNegocio',
                        where            : [{key:'organizacionKNAI.key', operador:'==', value:organizacionKNAI.key}],
                        orderBy          : [{key:'nombre',ascDesc:'asc'}],
                        limit            : 100,
                        paginado         : 'primera',
                        organizacionKNAI : organizacionKNAI,
                        usuarioKANE      : this.usuarioKANE                
                    }).subscribe((data:any)=>{
                        // log(...values('funcionEnd','bdService.getBDSubscripcion AreasNegocio')); 
                        // log(...values('valores','subscripcion AreasNegocio data:',data)); 
                        
                        this.listadoAreasNegocio=[];                                                        
                        this.subscripcionAreasNegocio.unsubscribe();
                        
                        for(let i=0; i<data.length; i++) {
                              let documento=data[i];
                              documento=this.fn.corrigeTimestampDocumento(documento);  

                              // Verifico que esté entre las definidas para la sucursal
                              let incluidaEnSucursal=false;
                              for(let j=0; sucursal && j<sucursal.areasNegocio.length;j++) {
                                    let sucursakAreaNegocioKey = sucursal.areasNegocio[j].key;
                                    // console.log(i,j, documento.key,"==",sucursakAreaNegocioKey);
                                    if(documento.key==sucursakAreaNegocioKey) {
                                        // console.log("Includad en Sucursal");
                                        incluidaEnSucursal=true;
                                        break;
                                    }
                              } 
                              
                              // Verifico que esté entre las definidas para el usuario la tenga habilitada
                              if(incluidaEnSucursal) {
                                  if(this.tipoPerfilUsuario=='Organizacion') {
                                       for(let j=0; j<this.usuario.sucursalesAreasNegocio.length;j++) {
                                            let areaNegocioKey = this.usuario.sucursalesAreasNegocio[j].split('@@@')[1];
                                            if(areaNegocioKey==documento.key) {
                                                this.listadoAreasNegocio.push(documento);    
                                                break;
                                            }
                                       } 
                                  } else {
                                        this.listadoAreasNegocio.push(documento);    
                                  }     
                              }     
                        }             
                        // log(...values("valores","listadoAreasNegocio:",this.listadoAreasNegocio, this.listadoAreasNegocio.length));
                        if(this.listadoAreasNegocio.length==1) {
                            this.filtroSoapAreaNegocio= this.listadoAreasNegocio[0];
                        }
                        
                    },(error:any)=>{
                        // log(...values("error al obtener Areas de Negocio",error));
                    });         
            }           
      
   }
    
    ActualizarListadoPrincipalconArchivoImportado(componente, toComplete:boolean=true) {
        console.log("ActualizarListadoPrincipalconArchivoImportado");

        let finalizaDownload=true;
        let cantArray=0;
        for(let key in this.finalizoDownloadFiles) {
            cantArray++;
            let value=this.finalizoDownloadFiles[key];
            if(!value) finalizaDownload=false;
        }
        
        // console.log("ActualizarListadoPrincipalconArchivoImportado finalizoDownloadFiles",this.finalizoDownloadFiles);
        // console.log("ActualizarListadoPrincipalconArchivoImportado",this.finalizoSoapComponente, finalizaDownload, cantArray);
        
        // console.log("zz this.listadoPrincipal",this.listadoPrincipal);

        if(this.finalizoSoapComponente && finalizaDownload && cantArray>0) {

            // console.log("this.soapService.cacheImportados1",this.soapService.cacheImportados, this.keyImportacion);
            // console.log("this.soapService.cacheImportados2",this.soapService.cacheImportados[this.keyImportacion]);
            
            if(componente=='rutas') {
                for(let i=0; i<this.listadoPrincipal.length;i++) {           
                    let documento = this.listadoPrincipal[i];
                    
                    if(documento.idEmpleado!=null) {
                        // Verifico que el Empleado ya haya sido importado previamente                                         
                        let keyImportacionEmpleados = this.fn.setearOrganizacionAI(this.organizacionSoap) + '-empleados-'+this.sucursalCodigo;  
                        if(!this.soapService.cacheImportados[keyImportacionEmpleados][ this.sucursalCodigo+'-'+documento.idEmpleado ]) {
                            this.listadoPrincipal[i].errorEmpleadoNoImportadaAntes=this.translate.instant('mensajeSoap.empleadoAunNoImportado',{ idEmpleado : documento.idEmpleado});
                        } else {
                            this.listadoPrincipal[i].nombreEmpleado = this.soapService.cacheImportados[keyImportacionEmpleados][ this.sucursalCodigo+'-'+documento.idEmpleado ].nombre;
                        }                        
                    }
                    
                    if(documento.idEmpleadoAcompanante1!=null) {
                        // Verifico que el Empleado Acompanante1 ya haya sido importado previamente                                         
                        let keyImportacionEmpleados = this.fn.setearOrganizacionAI(this.organizacionSoap) + '-empleados-'+this.sucursalCodigo;  
                        if(!this.soapService.cacheImportados[keyImportacionEmpleados][ this.sucursalCodigo+'-'+documento.idEmpleadoAcompanante1 ]) {
                            this.listadoPrincipal[i].errorEmpleadoAcompanante1NoImportadaAntes=this.translate.instant('mensajeSoap.empleadoAunNoImportado',{ idEmpleado : documento.idEmpleadoAcompanante1});
                        }                        
                    }
                    
                    //if(documento.idVehiculo!=null) {
                        // Verifico que el Vehículo ya haya sido importado previamente
                        // let keyImportacionVehiculos = this.fn.setearOrganizacionAI(this.organizacionSoap) + '-vehiculos-'+this.sucursalCodigo;  
                        // if(!this.soapService.cacheImportados[keyImportacionVehiculos][ this.sucursalCodigo+'-'+documento.idVehiculo ]) {
                        //     documento.errorVehiculoNoImportadaAntes='El vehículo '+documento.idVehiculo+' aún no ha sido Importado';
                        // } else {
                        //     documento.nombreVehiculo = this.soapService.cacheImportados[keyImportacionVehiculos][ this.sucursalCodigo+'-'+documento.idVehiculo ].nombre;                        
                        // }
                    //}    
                }    
            }    
            
            
            // Agrego Registros ya procesados  
            for (var key in this.soapService.cacheImportados[this.keyImportacion]) {            
                    let documento = this.soapService.cacheImportados[this.keyImportacion][key];
                    for(let i=0; i<this.listadoPrincipal.length;i++) {
                        let keyComparar = 'codigo';
                        if(componente=='empleados') {
                            keyComparar = 'codigo'
                        }
                        if(componente=='rutas') {
                            keyComparar = 'key'
                        }
                        //console.log("zz comparar",keyComparar, this.listadoPrincipal[i][keyComparar],"==",documento[keyComparar] );
                        if(this.listadoPrincipal[i][keyComparar]==documento[keyComparar] ) {
                          
                            if(componente=='empleados' && toComplete) {
                                this.listadoPrincipal[i].email = documento.email;
                            }    
                            this.listadoPrincipal[i].yaImportado = true;
                            break;
                        }
                    }
            }  
        }    
    }      
    
    getValueOfListadoYaImportados(array, value, whereSearch, fieldReturn) {
        if(value==null) {
            return null;
        }
        for(let key in array) {
            let filaArray = array[key];
            if(filaArray[whereSearch]==value) {
                return filaArray[fieldReturn];
            }
        }
        return null;
    }
    getObjectListadoYaImportados(array:any[], value, whereSearch) {
        if(value==null) {
            return null;
        }
        for(let key in array) {
            let filaArray = array[key];
            if(filaArray[whereSearch]==value) {
                return filaArray;
            }
        }
        return null;
    }
    



    onPostImportacion(componente, idSesion) {
        
        
      let keyImportacion;
      if(this.tipoImportacion==TIPO_IMPORTACION.Importacion) {
          keyImportacion = this.fn.setearOrganizacionAI(this.organizacionSoap) + '-'+componente;  
          
      } else {
          if(idSesion!=null) {
              keyImportacion = this.fn.setearOrganizacionAI(this.organizacionSoap) + '-'+componente+'-'+this.sucursalCodigo+'-'+idSesion;  
          } else {
              keyImportacion = this.fn.setearOrganizacionAI(this.organizacionSoap) + '-'+componente+'-'+this.sucursalCodigo;  
          }
      }    
              
      // Marco ya importados en ListadoPrincipal
      let vectorAGrabar={}
      for(let i=0; i<this.listadoPrincipal.length;i++) {
          let documento = this.listadoPrincipal[i];
          if(documento.importar && documento.aptoImportar) {
              documento.yaImportado = true;    
            
              if(componente=='empleados') {
                if(this.tipoImportacion==TIPO_IMPORTACION.Importacion) {
                    vectorAGrabar[documento.email]={
                            codigo          : documento.codigo,
                            email           : documento.email,
                            nombre          : documento.datosPersonales.apellidoNombre
                        }    
                } else {
                    vectorAGrabar[this.sucursalCodigo+'-'+documento.codigo]={
                        codigo          : documento.codigo,
                        email           : documento.email,
                        nombre          : documento.datosPersonales.apellidoNombre
                    }                    
                }        
                
              } else if(componente=='rutas') {
                    if(this.tipoImportacion==TIPO_IMPORTACION.Importacion) {
                        vectorAGrabar[documento.codigo]={
                                    key       : documento.key,
                                    codigo    : documento.codigo,
                                    nombre    : documento.nombre
                                }    
                    } else {
                        vectorAGrabar[this.sucursalCodigo+'-'+documento.codigo]={
                            key       : documento.key,
                            codigo    : documento.codigo,
                            nombre    : documento.nombre
                        }                
                    }            
              } else {
                    if(this.tipoImportacion==TIPO_IMPORTACION.Importacion) {
                        vectorAGrabar[documento.codigo]={
                                    key       : documento.key,
                                    codigo    : documento.codigo,
                                    nombre    : documento.nombre
                                }    
                    } else {              
                        vectorAGrabar[this.sucursalCodigo+'-'+documento.codigo]={
                                key       : documento.key,
                                codigo    : documento.codigo,
                                nombre    : documento.nombre
                            }                                                                  
                    }        
              }
          }    
      }
     
      console.log("vectorAGrabar",vectorAGrabar);
     
      this.soapService.saveImportados(this.usuarioKANE, this.organizacionSoap, keyImportacion,  vectorAGrabar)
      .then((resultado:any)=>{
          console.log("upload log importados resultado",resultado);
          
      }).catch((error:any)=>{
          console.log("upload log importados error",error);
      });  
      
    }  

    soapGetKeyYaImportado(componente, idSesion):string{
        let keyImportacion;
      if(this.tipoImportacion==TIPO_IMPORTACION.Importacion) {
          keyImportacion = this.fn.setearOrganizacionAI(this.organizacionSoap) + '-'+componente;  
          
      } else {
          if(idSesion!=null) {
              keyImportacion = this.fn.setearOrganizacionAI(this.organizacionSoap) + '-'+componente+'-'+this.sucursalCodigo+'-'+idSesion;  
          } else {
              keyImportacion = this.fn.setearOrganizacionAI(this.organizacionSoap) + '-'+componente+'-'+this.sucursalCodigo;  
          }
      }    

      return keyImportacion

    }
        
    soapYaImportadosRuta(documento) :any{
              
      // Marco ya importados en ListadoPrincipal
      let vectorAGrabar={}
      if(this.tipoImportacion==TIPO_IMPORTACION.Importacion) {
            vectorAGrabar[documento.codigo]={
                        key       : documento.key,
                        codigo    : documento.codigo,
                        nombre    : documento.nombre
                    }    
        } else {
            vectorAGrabar[this.sucursalCodigo+'-'+documento.codigo]={
                key       : documento.key,
                codigo    : documento.codigo,
                nombre    : documento.nombre
            }                
        }            
 
      return vectorAGrabar
     
     
      
    }  
        

  setDocumentoUbicacion(json, nombreParada, origenLlamada) { 
        // console.log("setDocumentoUbicacion",json);
      
        let locationId = this.parseJsonSoap(json,'ns1:locationIdentity','ns1:locationID','_text');  // Id Ubicación
        let locationType = this.parseJsonSoap(json,'ns1:locationIdentity','ns1:locationType','_text');  // Tipo de Ubicación
        
        // if(locationId==null || locationType==null) {   // Hay Paradas que no tienen ubicación asociada
        //     console.log("null");
        //     return null;
        // }
        
        let tipoUbicacionKN = this.fn.getAtributoFromListado( this.listadoSoapTiposUbicacion, locationType, 'codigo', 'KN');
        // console.log("setDocumentoUbicacion tipoUbicacionKN",tipoUbicacionKN);
        let acountType = this.parseJsonSoap(json,'ns1:accountType','_text');  // Tipo de Cuenta
        // console.log("setDocumentoUbicacion acountType",acountType);
        if(!acountType && locationType=='DPT') acountType = 'DPT';
        if(!acountType && locationType=='SIT') acountType = 'NoIndicada';
        if(!acountType && locationType==null)  acountType = 'NoIndicada';
        
        let tipoCuentaKN    = this.fn.getAtributoFromListado( this.listadoSoapTiposCuenta, acountType, 'codigo', 'KN');
        console.log("setDocumentoUbicacion tipoCuentaKN",tipoCuentaKN);
        let sucursalName    = this.fn.getAtributoFromListado( this.listadoSucursales, this.filtroSoapSucursalKey, 'key', 'nombre');
        
        let calle;
        if(this.parseJsonSoap(json,'ns1:address','ns1:line1','_text') && this.parseJsonSoap(json,'ns1:address','ns1:line2','_text')) {
            calle = this.parseJsonSoap(json,'ns1:address','ns1:line1','_text')+' '+this.parseJsonSoap(json,'ns1:address','ns1:line2','_text')
        } else {
            calle = this.parseJsonSoap(json,'ns1:address','ns1:line1','_text');
        }
        
        let keyPais = this.parseJsonSoap(json,'ns1:address','ns1:country','_text');
        let nombrePais = this.fn.getAtributoFromListado( PAISES, keyPais, 'key', 'nombre');
        
        let timeZoneRoadNet = this.parseJsonSoap(json,'ns1:timeZone','_text');
        let idiomaPais     = this.fn.getAtributoFromListado( this.msg.cacheColecciones['AuxTimeZones'], timeZoneRoadNet, 'key', 'idiomaPais');
        
        let direccion = {
            calle           : calle,
            numero          : null,
            bloque          : null,
            piso            : null,
            departamento    : null,
            codigoPostal    : this.parseJsonSoap(json,'ns1:address','ns1:postalCode','_text'),
            ciudad          : null,
            partido         : null,
            provinciaKN     : null,
            paisKN          : { key: keyPais, nombre: nombrePais },
            geoPoint: {
                latitud     : this.convertLatLngRoadNet( this.parseJsonSoap(json,'ns1:latitude','_text') ),
                longitud    : this.convertLatLngRoadNet( this.parseJsonSoap(json,'ns1:longitude','_text') ),
            },    
            timeZone        : timeZoneRoadNet,
            idiomaPais      : idiomaPais,
        };    
        
        let auxTelefono = this.parseJsonSoap(json,'ns1:phoneNumber','_text');
        let telefono=null;
        if(auxTelefono) {
            telefono = {
                tipoTelefono1: 'laboral',
                numeroTelefono1: auxTelefono,
                tipoTelefono2: null,
                numeroTelefono2: null,
                tipoTelefono3: null,
                numeroTelefono3: null,
                tipoTelefono4: null,
                numeroTelefono4: null         
            }
        }
        
        let deliveryRadius = this.fn.seguntosToHora( this.parseJsonSoap(json,'ns1:deliveryRadius','_text') ); // '10.044444'
        let radioEntrega   = deliveryRadius ? parseFloat(deliveryRadius) : 50.1;
        
        let tiempoServicio = this.fn.seguntosToHora( this.parseJsonSoap(json,'ns1:serviceTime','_text') ); // '18000'
        
        let codigo:string    = locationId;
        let auxNombre:string = this.parseJsonSoap(json,'ns1:description','_text');
        
        auxNombre = (nombreParada) ? nombreParada : auxNombre;
        let nombre = (auxNombre==null || auxNombre=='') ? codigo : auxNombre;
        
        let email:string = this.parseJsonSoap(json,'ns1:contact','_text')?this.parseJsonSoap(json,'ns1:contact','_text'):null;
        
        let ventanaAtencion = this.setVentanaAtencion(json, tipoCuentaKN);
        
        let documentoUbicacion = {
            key                : this.fn.setearOrganizacionAI(this.organizacionSoap)+'-'+locationId,
            codigo             : locationId,
            nombre             : nombre,
            keywords           : null,
            distribuidorKN     : this.fn.setearKN(this.organizacionSoap.distribuidor),
            organizacionKNAI   : this.fn.setearKNAI(this.organizacionSoap),
            sucursalKN         : { key: this.filtroSoapSucursalKey, nombre: sucursalName },
            areaNegocio        : this.filtroSoapAreaNegocio,
            tipoUbicacionKN    : tipoUbicacionKN,
            tipoCuentaKN       : tipoCuentaKN,
            email              : email,
            direccion          : direccion,
            telefono           : telefono,
            tiempoServicio     : tiempoServicio,
            radioEntrega       : radioEntrega,
            ventanaAtencion    : ventanaAtencion                                                             
        }

        // Campos custom de usuario (sólo vienen cuando se ejecuta desde ubicaciones)
        if(origenLlamada=='ubicaciones') {
            
            let customUsuarioUbicacion = {
                value1    : null,
                value2    : null,
                value3    : null,
                value4    : null,
                value5    : null,
                value6    : null,
             };  
             
             customUsuarioUbicacion.value1 = this.parseJsonSoap(json,'ns1:userDefinedField1','_text');
             customUsuarioUbicacion.value2 = this.parseJsonSoap(json,'ns1:userDefinedField2','_text');
             customUsuarioUbicacion.value3 = this.parseJsonSoap(json,'ns1:userDefinedField3','_text');
             customUsuarioUbicacion.value4 = this.parseJsonSoap(json,'ns1:userDefinedField4','_text');
             customUsuarioUbicacion.value5 = this.parseJsonSoap(json,'ns1:userDefinedField5','_text');
             customUsuarioUbicacion.value6 = this.parseJsonSoap(json,'ns1:userDefinedField6','_text');                      
             
             documentoUbicacion['customUsuarioUbicacion']=customUsuarioUbicacion;
        }
        
        /* ----- Seteo Keywords ------------------------- */      
        let stringKeywords = documentoUbicacion.nombre;
        documentoUbicacion['keywords'] = this.fn.generateKeywords( stringKeywords );
        
        return documentoUbicacion;
  }  

  setVentanaAtencion(json:any, tipoCuentaKN:KN):VentanaAtencion {
      
    let diasSemana:string = this.parseJsonSoap(json,'ns1:internalDeliveryDays','_text'); // "MTWRFSU"
    let openTime:string = this.parseJsonSoap(json,'ns1:openTime','_text');  // "08:00:00.0000000"
    let closeTime:string = this.parseJsonSoap(json,'ns1:closeTime','_text'); // "13:00:00.0000000"

    let lunes = {
        horaDesde1:null,
        horaHasta1:null,
        horaDesde2:null,
        horaHasta2:null,
        horaDesde3:null,
        horaHasta3:null,
    }
    let martes = {
        horaDesde1:null,
        horaHasta1:null,
        horaDesde2:null,
        horaHasta2:null,
        horaDesde3:null,
        horaHasta3:null,
    }
    let miercoles = {
        horaDesde1:null,
        horaHasta1:null,
        horaDesde2:null,
        horaHasta2:null,
        horaDesde3:null,
        horaHasta3:null,
    }
    let jueves = {
        horaDesde1:null,
        horaHasta1:null,
        horaDesde2:null,
        horaHasta2:null,
        horaDesde3:null,
        horaHasta3:null,
    }
    let viernes = {
        horaDesde1:null,
        horaHasta1:null,
        horaDesde2:null,
        horaHasta2:null,
        horaDesde3:null,
        horaHasta3:null,
    }
    let sabado = {
        horaDesde1:null,
        horaHasta1:null,
        horaDesde2:null,
        horaHasta2:null,
        horaDesde3:null,
        horaHasta3:null,
    }
    let domingo = {
        horaDesde1:null,
        horaHasta1:null,
        horaDesde2:null,
        horaHasta2:null,
        horaDesde3:null,
        horaHasta3:null,
    }
    
    if(diasSemana && diasSemana.includes('M')) {   
        lunes.horaDesde1 = this.setHoraMinutosSegundos(openTime);
        lunes.horaHasta1 = this.setHoraMinutosSegundos(closeTime);
    }
    
    if(diasSemana && diasSemana.includes('T')) {
        martes.horaDesde1 = this.setHoraMinutosSegundos(openTime);
        martes.horaHasta1 = this.setHoraMinutosSegundos(closeTime);
    }

    if(diasSemana && diasSemana.includes('W')) {
        miercoles.horaDesde1 = this.setHoraMinutosSegundos(openTime);
        miercoles.horaHasta1 = this.setHoraMinutosSegundos(closeTime);
    }

    if(diasSemana && diasSemana.includes('R')) {
        jueves.horaDesde1 = this.setHoraMinutosSegundos(openTime);
        jueves.horaHasta1 = this.setHoraMinutosSegundos(closeTime);
    }

    if(diasSemana && diasSemana.includes('F')) {
        viernes.horaDesde1 = this.setHoraMinutosSegundos(openTime);
        viernes.horaHasta1 = this.setHoraMinutosSegundos(closeTime);
    }

    if(diasSemana && diasSemana.includes('S')) {
        sabado.horaDesde1 = this.setHoraMinutosSegundos(openTime);
        sabado.horaHasta1 = this.setHoraMinutosSegundos(closeTime);
    }

    if(diasSemana && diasSemana.includes('U')) {
        domingo.horaDesde1 = this.setHoraMinutosSegundos(openTime);
        domingo.horaHasta1 = this.setHoraMinutosSegundos(closeTime);
    }
    
    let horarioAtencion= new HorarioAtencion( {
        lunes             : lunes,
        martes            : martes,
        miercoles         : miercoles,
        jueves            : jueves,
        viernes           : viernes,
        sabado            : sabado,
        domingo           : domingo,
        visperaFeriado    : null,
        feriado           : null
    }    );

    let rta:VentanaAtencion = {    
        key                   : null,
        nombre                : 'Personalizada',
        codigo                : 'Personalizada',
        keyTipoCuenta         : this.fn.mostrarKN(tipoCuentaKN,'key') ,
        horarioAtencion: horarioAtencion.getJson()
    };   
    
    return rta;   
      
  }

  setHoraMinutosSegundos(value) {
    //console.log("setHoraMinutosSegundos",value);
    if(value==null) {
        return null;
    } else {
        return {
                hora: parseInt(value.substr(0,2)),
                minutos: parseInt(value.substr(3,2)),
                segundos: parseInt(value.substr(6,2)),
        }            
    } 
  }
   
  convertLatLngRoadNet(value) {
    if(value==undefined || value==null || value=='') {
        return null;
    }  
    let decimales = value.slice(-6);
    let entero    = value.replace(decimales,'');
    return parseFloat(entero+'.'+decimales);

  }

  verifyJsonIsArray(vector:any):any[] {     
  
      if(vector!==undefined && vector!=null && !Array.isArray(vector) ) {
        let aux = this.fn.copiarObjetoNew(vector);
        vector=[];
        vector.push(aux);
      }
      //console.log("verifyJsonIsArray",vector);
      return vector;
  }

  convertMilisegundosATiempoServicio(value) {
        if(value==undefined || value==null || value=='') {
            return {
                hour: 0,
                minute: 0,
                second: 0
            };
        } else {
            // Falta Calcular, no se sabe si viene '18000' ? milisegundos ? 18:00:0 ?
            return {
                hour: 0,
                minute: 0,
                second: 0
            };            
        }                 
  }
   
  getAtributoFromListadoInteligente( keyResult, listado, value, fieldWhere, fieldReturn) {    
    //   console.log("getAtributoFromListadoInteligente",keyResult, listado, value, fieldWhere, fieldReturn);
      if(this.busquedasResueltas[keyResult+value]!==undefined) {
        // console.log('getAtributoFromListadoInteligente busquedasResueltas',keyResult+value, this.busquedasResueltas[keyResult+value]);
        return this.busquedasResueltas[keyResult+value];
      } else { 
        let rta = this.fn.getAtributoFromListado( listado, value, fieldWhere, fieldReturn);  
        this.busquedasResueltas[keyResult+value]=rta;
        return rta;
      }
  }
    
   /*********************************************************
     *********                                        ********
     ********* Inicio Funciones Transferencias Excel  ********
     *********                                        ********
     *********************************************************/

    parseJsonSoap(json, elem1?:any,elem2?:any,elem3?:any,elem4?:any) {
        
        if(elem1!==undefined && elem2!==undefined && elem3!==undefined && elem4!==undefined) {
            if( json[elem1]!==undefined && 
                json[elem1][elem2]!==undefined &&
                json[elem1][elem2][elem3]!==undefined &&
                json[elem1][elem2][elem3][elem4]!==undefined ) {
                return json[elem1][elem2][elem3][elem4];
            } else {
                return null;
            }
            
        } else if(elem1!==undefined && elem2!==undefined && elem3!==undefined) {
            if( json[elem1]!==undefined && 
                json[elem1][elem2]!==undefined &&
                json[elem1][elem2][elem3]!==undefined) {
                return json[elem1][elem2][elem3];
            } else {
                return null;
            }            
            
        } else if(elem1!==undefined && elem2!==undefined) {
            if( json[elem1]!==undefined && 
                json[elem1][elem2]!==undefined) {
                return json[elem1][elem2];
            } else {
                return null;
            }            

        } else if(elem1!==undefined) {
            if( json[elem1]!==undefined) {
                return json[elem1];
            } else {
                return null;
            }            

        } else {    
          return null;
        }
    }
     
    existeTransferenciaExcell(){

        this.generadorImportadores=  new GeneradorImportadores(this.nombreColeccion);
        //Verifico si existe un importador
        this.existeImportadorExcel = this.generadorImportadores.existeImportador();
        this.existeSOAP_RoadNet    = this.generadorImportadores.existeSOAP_RoadNet();
        
        if (!this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        }                
       
    }
      
    configurarTransferenciaExcel(event:any) {
        // console.log('event',event);
        this.configExcel =null;
        
        let argumentos:any = {
          distribuidorKN:           this.distribuidorKN,  
          organizacionKNAI:         this.organizacionKNAI,
          usuarioKANE:              this.usuarioKANE,
          listadoPrincipalCompleto: null,
          listadosCache:            this.msg.cacheColecciones
          }
        console.log('importadores argumentos', argumentos);

        this.generadorImportadores.getImportador(this.bdService,this.msg,argumentos)
        .then((configExcel:ConfigExcel)=>{
        //   console.log('importadores',configExcel)
          this.configExcel=configExcel;
          this.configExcel['eventArchivoExcel'] = event;
          this.configExcel.iniciarTransferencia = true;
        }).catch(error=>{
          this.configExcel=null;
          console.log('importadores error',error);      
        });
   
  
    }
   
    emit_finalizoTransferenciaExcel() {
      
        // console.log('emit_finalizoTransferenciaExcel');
        // Reset Componente de Transferencia para que permita que se ingres un nuevo archivo luego de procesarlo.
        this.fileInput1.nativeElement.value = "";
    
        
        // Ejecuto proceso posterior en la BD cuando finaliza la importación del Excel
        if(this.configExcel!==undefined) {
             this.configExcel.ejcutarAccionPosterior();    
        }
        
    }

}


export class AppInjectorSoap {

    private static injector: Injector;

    static setInjector(injector: Injector) {
            AppInjectorSoap.injector = injector;
    }

    static getInjector(): Injector {
            return AppInjectorSoap.injector;
    }

}       
