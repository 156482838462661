import { log, logIf, logTable, values } from '@maq-console';

import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class StorageService {

  

  constructor() {

  }
  setItem(clave:string,valor:string){
    localStorage.setItem(clave, valor);
  }
  async getItem(clave:string) : Promise<{ value: string }>{
    return new Promise( (resolve,reject)=>{
     let value=localStorage.getItem(clave);
     resolve( { value: value });
    });
  }

  removeItem(clave:string){
    localStorage.removeItem(clave);
  }
  clear(){
    localStorage.clear();
  }
  localStorage(){
    return localStorage
  }
}
