<form [formGroup]="form" (change)="onChangeField()">

    <div widget class="card border-0 box-shadow" style="margin-top:20px;">
        <div class="card-header transparent border-0 text-muted">
            <h5 class="mb-0">{{traduccion.claseTabs.telefono}}</h5>
            <div class="widget-controls">
                <a data-widgster="expand" href="#" class="transition"><i class="fa fa-chevron-down"></i></a>
                <a data-widgster="collapse" href="#" class="transition"><i class="fa fa-chevron-up"></i></a>
                <a data-widgster="close" href="#" class="transition"><i class="fa fa-times"></i></a>
            </div>
        </div>
        <div class="card-body pt-0 widget-body" formGroupName="telefono">

            <div class="row">
                <div class="col-7">
                    <div class="form-group">
                        <label class="text-gray">{{traduccion.claseTelefono.numeroTelefono}}</label> #1 <span class="required" *ngIf="form.controls['telefono']['controls']['numeroTelefono1'].validator">*</span>
                        <input id="numeroTelefono1" formControlName="numeroTelefono1" class="form-control validation-field" type="text">
                        <small class="text-danger" *ngIf="form.get('telefono').get('numeroTelefono1').touched &&
                                                          form.get('telefono').get('numeroTelefono1').errors?.required"><span >{{traduccion.claseTelefono.numeroTelefono}}</span>&nbsp;<span >{{traduccion.claseValidaciones.esRequerido}}</span></small>
                    </div>
                </div>
                <div class="col-5">
                    <div class="form-group">
                        <label class="text-gray" >{{traduccion.claseTelefono.tipoTelefono}}<span class="required" *ngIf="form.controls['telefono']['controls']['tipoTelefono1'].validator">*</span></label>
                        <select class="form-control selectpicker validation-field"
                                id="tipoTelefono1" formControlName="tipoTelefono1"  >
                            <ng-container *ngFor="let tipo of LISTA_DE_TIPOS_DE_TELEFONOS">
                                    <option [ngValue]="tipo.id">{{fn.traducir(tipo.val)}}</option>
                            </ng-container>
                        </select>
                        <small class="text-danger" *ngIf="form.get('telefono').get('tipoTelefono1').touched &&
                                                          form.get('telefono').get('tipoTelefono1').errors?.required"><span >{{traduccion.claseTelefono.tipoTelefono}}</span>&nbsp;<span >{{fn.traducir('claseValidaciones.esRequerido')}}</span></small>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-7">
                    <div class="form-group">
                        <label class="text-gray">{{traduccion.claseTelefono.numeroTelefono}}</label> #2
                        <input formControlName="numeroTelefono2" class="form-control" type="text">
                    </div>
                </div>
                <div class="col-5">
                    <div class="form-group">
                        <label class="text-gray">{{traduccion.claseTelefono.tipoTelefono}}</label>
                        <select class="form-control selectpicker validation-field"
                                id="tipoTelefono2" formControlName="tipoTelefono2"  >
                            <ng-container *ngFor="let tipo of LISTA_DE_TIPOS_DE_TELEFONOS">
                              <option [ngValue]="tipo.id">{{tipo.val}}</option>
                            </ng-container>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-7">
                    <div class="form-group">
                        <label class="text-gray" >{{traduccion.claseTelefono.numeroTelefono}}</label> #3
                        <input formControlName="numeroTelefono3" class="form-control" type="text">
                    </div>
                </div>
                <div class="col-5">
                    <div class="form-group">
                        <label class="text-gray" >{{traduccion.claseTelefono.tipoTelefono}}</label>
                        <select class="form-control selectpicker validation-field"
                                id="tipoTelefono3" formControlName="tipoTelefono3"  >
                            <ng-container *ngFor="let tipo of LISTA_DE_TIPOS_DE_TELEFONOS">
                              <option [ngValue]="tipo.id">{{fn.traducir(tipo.val)}}</option>
                            </ng-container>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-7">
                    <div class="form-group">
                        <label class="text-gray" >{{traduccion.claseTelefono.numeroTelefono}}</label> #4
                        <input formControlName="numeroTelefono4" class="form-control" type="text">
                    </div>
                </div>
                <div class="col-5">
                    <div class="form-group">
                        <label class="text-gray" >{{traduccion.claseTelefono.tipoTelefono}}</label>
                        <select class="form-control selectpicker validation-field"
                                id="tipoTelefono4" formControlName="tipoTelefono4"  >
                            <ng-container *ngFor="let tipo of LISTA_DE_TIPOS_DE_TELEFONOS">
                              <option [ngValue]="tipo.id">{{fn.traducir(tipo.val)}}</option>
                            </ng-container>
                        </select>
                    </div>
                </div>
            </div>

        </div> <!-- FIN card-body -->
    </div> <!-- FIN widget -->

</form>
