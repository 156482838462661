import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettingsService } from '@settings/app.settings';
@Component({
  selector: 'app-en-construccion',
  templateUrl: './en-construccion.component.html',
  styleUrls: ['./en-construccion.component.scss'],
  encapsulation: ViewEncapsulation.None 
})
export class EnConstruccionComponent {

  router: Router;
    
  constructor(router: Router,   public appSettings:AppSettingsService, ) {
      this.router = router;
  }

  searchResult(): void {
      this.router.navigate(['/search']);
  }

  ngAfterViewInit(){
      document.getElementById('preloader').classList.add('hide');                 
  }

}
