<ng-container *ngIf="cargarHTML">	
	<!-- container input-icons p-0  col-12-->
	<div class="container input-icons p-0">
		<div class="row m-0 align-items-start" [ngClass]="isFiltro ? 'filtroGrilla' : ''"  [ngStyle]="isFiltro ? {height: '29px;'} : {}">
			<!-- [style.width.px]="miWidthInput"  -->

			<div class="col-11 pr-0 pl-0 m-0"
				[ngClass]="isFiltro ? 'p-0 border border-0 filtroGrilla' : ''"
				[ngStyle]="isFiltro ? {height: '29px;'} : {}">
				<input class="form-control filtroGrilla input-field"
					data-toggle="tooltip" data-placement="top" title="{{filtroSeleccionado}}"
					id="{{idDataList}}Input"
					[attr.list]="idDataList"
					placeholder="{{filtroTraduccion | translate}}"
					[ngClass]="filtroSeleccionado ? 'filtroConValor' : 'filtroSinValor'"
					[ngClass]="isFiltro ? 'filtroGrilla' : ''"
					[(ngModel)]="filtroSeleccionado"
					(change)="propagarFiltro()" #inputFilter [disabled]="!activarOrDesactivarComponente()">
			</div>	
			<div class="col-1 pr-0 pl-0 d-flex align-items-center" [ngClass]="isFiltro ? 'filtroGrilla' : ''" [ngStyle]="isFiltro ? {height: '29px;'} : {}">
				<i *ngIf="filtroSeleccionado != null && filtroSeleccionado != '' && activarOrDesactivarComponente()" class="mdi mdi-backspace mr-3 text-primary w-100" style="height: 14px;" (click)="cleanFiltro(inputFilter);"></i>
			</div>
		</div>
	</div>
	<datalist id="{{idDataList}}" [ngClass]="isFiltro ? 'filtroGrilla' : ''" [ngClass]="filtroSeleccionado ? 'filtroConValor' : 'filtroSinValor'">


		<!-- listados de documentos -->
		<ng-container *ngIf="visibleValueSelect==true && isUniqueValue==true && listadoParaFiltrarIsArrayString==false">
			<option *ngFor="let item of listadoParaFiltrar" [value]="valueFiltroSelectOpcion(item, filtroValue)">{{nameFiltroSelectOpcion(item,filtroSelect) | translate}}</option>
		</ng-container>

		<ng-container *ngIf="visibleValueSelect==true && isUniqueValue==false && listadoParaFiltrarIsArrayString==false">
			<option *ngFor="let item of listadoParaFiltrar; let i = index" [value]="valueFiltroSelectOpcion(item, filtroValue)">{{i + 1}}°: {{nameFiltroSelectOpcion(item,filtroSelect) | translate}}</option>
		</ng-container>

		<ng-container *ngIf="visibleValueSelect==false && isUniqueValue==true && listadoParaFiltrarIsArrayString==false">
			<option *ngFor="let item of listadoParaFiltrar" [attr.data-value]="valueFiltroSelectOpcion(item, filtroValue)" value="{{nameFiltroSelectOpcion(item,filtroSelect) | translate}}"></option>
		</ng-container>

		<ng-container *ngIf="visibleValueSelect==false && isUniqueValue==false && listadoParaFiltrarIsArrayString==false">
			<option *ngFor="let item of listadoParaFiltrar; let i = index" [attr.data-value]="valueFiltroSelectOpcion(item, filtroValue)" value="{{i + 1}}°: {{nameFiltroSelectOpcion(item,filtroSelect) | translate}}"></option>
		</ng-container>
		
		<!-- listados de strings -->
		<ng-container *ngIf="visibleValueSelect==true && isUniqueValue==true && listadoParaFiltrarIsArrayString==true">
			<option *ngFor="let item of listadoParaFiltrar" [value]="item">{{item | translate}}</option>
		</ng-container>

		<ng-container *ngIf="visibleValueSelect==true && isUniqueValue==false && listadoParaFiltrarIsArrayString==true">
			<option *ngFor="let item of listadoParaFiltrar; let i = index" [value]="item">{{i + 1}}°: {{item | translate}}</option>
		</ng-container>

		<ng-container *ngIf="visibleValueSelect==false && isUniqueValue==true && listadoParaFiltrarIsArrayString==true">
			<option *ngFor="let item of listadoParaFiltrar" [attr.data-value]="item" value="{{item | translate}}"></option>
		</ng-container>

		<ng-container *ngIf="visibleValueSelect==false && isUniqueValue==false && listadoParaFiltrarIsArrayString==true">
			<option *ngFor="let item of listadoParaFiltrar; let i = index" [attr.data-value]="item" value="{{i + 1}}°: {{item | translate}}"></option>
		</ng-container>

	</datalist>
</ng-container>

