import { Pipe, PipeTransform } from '@angular/core';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'prettyJson'
})

 export class PrettyJsonPipe implements PipeTransform {
   transform(val) {
      if(typeof(val) == "undefined" || typeof(val) == null) return ''; //check value before process it.
      
      // let rta = JSON.stringify(val, null, 2);
      // rta = rta.replace(new RegExp('(^|\\s)(' + needle + ')(\\s|$)','ig'), '$1<b>$2</b>$3');
      
      let rta = Object.entries(val)
      .reduce((acc, [key, value]) => `${acc}
        <span class='json-key'>"${key}": </span>
        <span class='value'>"${value}"</span>,<br/>`,
        `{<br/>`) + `}`;      
      
      console.log("pretyJson",rta);      
      
      return rta;
    }
}