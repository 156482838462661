import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from "@angular/router";
@Component({
  selector: 'app-usuario-no-autorizado',
  templateUrl: './usuario-no-autorizado.page.html',
  styleUrls: ['./usuario-no-autorizado.page.scss']
})
export class UsuarioNoAutorizadoComponent implements OnInit {

  constructor( private router:Router) { }

  ngOnInit() {
  	// para que funcione la traduccion de acciones que no se cargan el la primera
  	 //this.router.navigate(['/usuarios/listadoDistribuidores']);
  }

}
