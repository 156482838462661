<form [formGroup]="form">

    <div widget class="card border-0 box-shadow" style="margin-top:20px;">
        <div class="card-header transparent border-0 text-muted">
            <h5 class="mb-0">{{traduccion.claseRegiones.regionesDondeOpera}} <span class="required">*</span></h5>
            <div class="widget-controls"> 
                <a data-widgster="expand" href="#" class="transition"><i class="fa fa-chevron-down"></i></a>
                <a data-widgster="collapse" href="#" class="transition"><i class="fa fa-chevron-up"></i></a>
                <a data-widgster="close" href="#" class="transition"><i class="fa fa-times"></i></a>
            </div>        
        </div>
        <div class="card-body pt-0 widget-body"> 

                <!-- <<<<<< Listado Provincias Asignadas >>>>> -->
                <table class="table table-hover" style="border-top:hidden;">
                    <thead>
                        <tr> 
                            <td style="padding-bottom:0px;">
                                <label class="text-gray">{{traduccion.claseDireccion.provincia}}</label>
                            </td> 
                            <td style="padding-bottom:0px;">
                                <label *ngIf="accionProvincias=='listado'" class="text-gray">{{traduccion.claseDireccion.pais}}</label>
                            </td> 
                            <td class="align-middle" style="width:50px; padding-bottom:0px;padding-top:0px;padding-bottom:0px;">
                                <button class="btn btn-link btn-lg" 
                                        style="padding-left:0px; padding-right:0px;"
                                        title="{{traduccion.navegabilidad.agregar}}"
                                *ngIf="accionProvincias=='listado' && accionForm!='consultar'"
                                (click)="accionProvincias='agregar'"><i class="fa fa-plus ml-3 text-success"></i></button>
                            </td>
                        </tr>
                    </thead>
                    <tbody *ngIf="accionProvincias=='listado'">
                        
                        <tr *ngFor="let idProvincia of form.value.provinciasDeOperacion 
                                        | orderBy: 'nombre':false:'case-insensitive'">

                            <td class="align-middle">
                                <small class="align-middle">{{idProvincia}}</small>
                            </td>    
                            <td class="align-middle">
                                <small class="align-middle">{{getPaisFromProvincia(idProvincia)}}</small>
                            </td>    
                            <td class="align-middle" style="width:50px;">
                                    <button 
                                    *ngIf="accionForm!='consultar'"
                                    title="{{traduccion.navegabilidad.eliminar}}"
                                    class="btn btn-link btn-lg"  
                                    style="padding-left:0px; padding-right:0px; margin-bottom:0px;padding-top:0px;padding-bottom:0px;"
                                            (click)="deleteProvincia(idProvincia)"><i class="fa fa-trash ml-3 text-danger"></i></button>
                            </td>
                        </tr>
                        <tr *ngIf="form.value.provinciasDeOperacion.length==0"> 
                            <td class="align-middle" colspan="1">
                                <small class="align-middle">{{traduccion.NoIndicada}}</small><small class="align-middle">s</small>
                            </td>    
                            <td class="align-middle" colspan="2">
                                <small class="align-middle">{{traduccion.NoIndicado}}</small><small class="align-middle">s</small>
                            </td>    
                        </tr>
                    </tbody>
                </table>
                <!-- <<<<<< Agregar Provincia >>>>> -->
                <table class="table" style="border-top:hidden;"
                       *ngIf="accionProvincias=='agregar'">
                    <tbody>
                        <tr>
                            <td class="align-middle">
                                  <select class="form-control selectpicker" 
                                          id="idNuevaProvincia">
                                       <ng-container *ngFor="let provincia of LISTA_DE_PROVINCIAS | 
                                                                orderBy:'nombre':false:'case-insensitive'">       
                                        <option *ngIf="provinciaNoIncluida(provincia.id)"
                                           value="{{provincia.id}}">{{provincia.nombre}} ({{provincia.idPais}})</option>
                                       </ng-container>                
                                  </select>
                            </td>      
                            <td class="align-middle" style="width:50px;">
                                <button type="button" class="btn btn-success btn-sm"
                                        (click)="agregarProvincia()">{{traduccion.navegabilidad.agregar}}</button>
                            </td>    
                            <td class="align-middle" style="width:50px;">
                                <button type="button" class="btn btn-danger btn-sm"
                                        (click)="accionProvincias='listado'">{{traduccion.navegabilidad.cancelar}}</button>
                            </td>    
                        </tr>    
                    </tbody>    
                </table>    
                
                <small class="text-danger" 
                        *ngIf="form.value.provinciasDeOperacion.length==0 
                                && formInvalid 
                                && form.get('provinciasDeOperacion').errors?.required
                               ">{{traduccion.claseDireccion.provincia}}&nbsp;{{traduccion.claseValidaciones.esRequerido}}</small>
                               
                                                                              
        </div> <!-- FIN card-body -->
    </div> <!-- FIN widget -->


</form>