import { Component, OnInit, ViewEncapsulation,ChangeDetectorRef, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';

import { FuncionesService } from '@maq-funciones';
import { TranslateService } from '@ngx-translate/core';
import { NgbCalendar, NgbDate, NgbDateStruct, NgbDatepicker, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { KN } from '@settings/maqueta/models/typesKN/typesKN.model';
import { AGRUPADORES_FECHAS, ORDER_GRAFICOS, RANGO_FECHAS, TIPOS_GRAFICOS, TIPO_AGRUPADOR, TIPO_GRAFICO } from '@settings/proyecto/mocks/tableroControl/tableroControl';
import { Subject } from 'rxjs';
import { CustomNgbdDatepickerI18n } from '../ngb-datepicker-i18n/ngb-datepicker-i18n.component';
import { selectDateRangeHelper, selectDateRangeTraduccion } from '@settings/proyecto/helpers/tableroControl/commonTableroHelp';

// jQuery
declare let $: any;
declare let jQuery: any;

@Component({
  selector: 'lib-shared-agrupador-periodo',
  templateUrl: './agrupador-periodo.component.html',
  styleUrls: ['./agrupador-periodo.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [TranslateService, {provide: NgbDatepickerI18n, useClass: CustomNgbdDatepickerI18n}] // define custom NgbDatepickerI18n provider
})

export class AgrupadorPeriodoComponent implements OnInit, OnChanges {

  @ViewChild('dp') datePicker: NgbDatepicker;

  @Output() enviarFiltroFecha = new EventEmitter<any>();
  @Output() enviarAgrupadorFecha = new EventEmitter<any>();
  @Output() enviarShowContent = new EventEmitter<boolean>();
  @Output() enviarShowContentFiltroFecha = new EventEmitter<boolean>();
  @Output() enviarTipoGraphic = new EventEmitter<string>();
  @Output() enviarTipoOrderGraphic = new EventEmitter<string>();


  @Input() public showMenu: boolean = true;

  public fromDate: NgbDate | null =null;
  // @Input() public fromDateDefault: NgbDate | null = null;
  @Input() public fromDateDefault: NgbDate | undefined = undefined;

  private fromDateDefaultCopia: NgbDate | null = null;


	public toDate: NgbDate | null = null;
  // @Input() public toDateDefault: NgbDate | null = null;
  @Input() public toDateDefault: NgbDate | undefined = undefined;

  private toDateDefaultCopia: NgbDate | null = null;


  public agrupadoresDeFechas  = AGRUPADORES_FECHAS;
  @Input() public agrupadorDeFecha : string = this.agrupadoresDeFechas[0]['key'];

  @Input() public arrayTypeCharts = TIPOS_GRAFICOS;
  @Input() public typeChartDefault : string;


  public arrayTypeOrderCharts = ORDER_GRAFICOS;
  @Input() public typeOrderInCharts : string  = this.arrayTypeOrderCharts[0]['key'];


  public showContent: boolean =true;
  public traduccionshowContent : string = 'agrupadorPeriodos.suprimirInformacion';
  public traduccionshowContentFiltroFecha: string = 'agrupadorPeriodos.ocultarFiltroFecha';

  @Input() public selectedDateRange: RANGO_FECHAS = RANGO_FECHAS.AYER;

  public hoveredDate: NgbDate | null = null;

  public traduccionRangeSeleccionado : string = 'agrupadorPeriodos.ayer';

  // public isCollapsedFiltroFechas:boolean = false;


  public maxDate:NgbDate = null;
  
  constructor(protected changeDetectorRef : ChangeDetectorRef,public fn:FuncionesService, public calendar: NgbCalendar, private cdRef: ChangeDetectorRef) { 
    this.maxDate = this.calendar.getPrev(this.calendar.getToday(), 'd', 1);
  }

  ngOnInit() {
    // if(this.typeChartDefault==undefined) 
    this.typeChartDefault = this.arrayTypeCharts[1]['key'];
    if(this.toDateDefault) {
      this.toDate = new NgbDate(this.toDateDefault.year, this.toDateDefault.month, this.toDateDefault.day);
      this.toDateDefaultCopia = new NgbDate(this.toDateDefault.year, this.toDateDefault.month, this.toDateDefault.day);
    }
    if(this.fromDateDefault) {
      this.fromDate = new NgbDate(this.fromDateDefault.year, this.fromDateDefault.month, this.fromDateDefault.day);
      this.fromDateDefaultCopia = new NgbDate(this.fromDateDefault.year, this.fromDateDefault.month, this.fromDateDefault.day);
    }
    this.selectDateRange(this.selectedDateRange);
  }



  ngOnChanges(changes: SimpleChanges) {
    // if (changes.fromDateDefault.currentValue && changes.fromDateDefault.currentValue.year != null && changes.fromDateDefault.currentValue.month!= null && changes.fromDateDefault.currentValue.day != null && this.fromDateDefaultCopia &&  (this.fromDateDefaultCopia.day != changes.fromDateDefault.currentValue.day || this.fromDateDefaultCopia.month != changes.fromDateDefault.currentValue.month || this.fromDateDefaultCopia.year != changes.fromDateDefault.currentValue.year)) {
    //   this.fromDateDefaultCopia = new NgbDate(changes.fromDateDefault.currentValue.year, changes.fromDateDefault.currentValue.month, changes.fromDateDefault.currentValue.day);
    //   this.fromDate = new NgbDate(changes.fromDateDefault.currentValue.year, changes.fromDateDefault.currentValue.month, changes.fromDateDefault.currentValue.day);
    //   this.datePicker.navigateTo(this.fromDate);
    //   this.cdRef.detectChanges(); //aviso cambios en la vista.
    // }

    // if(changes.toDateDefault.currentValue && changes.toDateDefault.currentValue.year != null && changes.toDateDefault.currentValue.month!= null && changes.toDateDefault.currentValue.day != null && this.toDateDefaultCopia && (this.toDateDefaultCopia.day != changes.toDateDefault.currentValue.day || this.toDateDefaultCopia.month != changes.toDateDefault.currentValue.month || this.toDateDefaultCopia.year != changes.toDateDefault.currentValue.year)) { 
    //   this.toDateDefaultCopia = new NgbDate(changes.toDateDefault.currentValue.year, changes.toDateDefault.currentValue.month, changes.toDateDefault.currentValue.day);
    //   this.toDate = new NgbDate(changes.toDateDefault.currentValue.year, changes.toDateDefault.currentValue.month, changes.toDateDefault.currentValue.day);
    //   this.cdRef.detectChanges(); //aviso cambios en la vista.
    // }

    if(changes.fromDateDefault?.currentValue == null){
      this.fromDateDefaultCopia = null;
      this.fromDate = null;
    }else if (changes.fromDateDefault?.currentValue && changes.fromDateDefault?.currentValue.year != null && changes.fromDateDefault?.currentValue.month!= null && changes.fromDateDefault?.currentValue.day != null &&  (this.fromDateDefaultCopia?.day != changes.fromDateDefault?.currentValue?.day || this.fromDateDefaultCopia?.month != changes.fromDateDefault?.currentValue?.month || this.fromDateDefaultCopia?.year != changes.fromDateDefault?.currentValue?.year)) {
        this.fromDateDefaultCopia = new NgbDate(changes.fromDateDefault.currentValue.year, changes.fromDateDefault.currentValue.month, changes.fromDateDefault.currentValue.day);
        this.fromDate = new NgbDate(changes.fromDateDefault.currentValue.year, changes.fromDateDefault.currentValue.month, changes.fromDateDefault.currentValue.day);
        this.datePicker?.navigateTo(this.fromDate);
        this.cdRef.detectChanges(); //aviso cambios en la vista.
    }
    
    if(changes.toDateDefault?.currentValue == null){
      this.toDateDefaultCopia = null;
      this.toDate = null;
    }else if(changes.toDateDefault?.currentValue && changes.toDateDefault?.currentValue?.year != null && changes.toDateDefault?.currentValue?.month!= null && changes.toDateDefault?.currentValue?.day != null && (this.toDateDefaultCopia?.day != changes.toDateDefault?.currentValue?.day || this.toDateDefaultCopia?.month != changes.toDateDefault?.currentValue?.month || this.toDateDefaultCopia?.year != changes.toDateDefault?.currentValue?.year)) { 
      this.toDateDefaultCopia = new NgbDate(changes.toDateDefault.currentValue.year, changes.toDateDefault.currentValue.month, changes.toDateDefault.currentValue.day);
      this.toDate = new NgbDate(changes.toDateDefault.currentValue.year, changes.toDateDefault.currentValue.month, changes.toDateDefault.currentValue.day);
      this.cdRef.detectChanges(); //aviso cambios en la vista.
    }
  }

  isDateDisabled(date: NgbDate): boolean {
    // Compara la fecha dada con maxDate
    return date.after(this.maxDate);
  }
  
  public onChangeAgrupadorFechas(){
    for (let i = 0; i < this.agrupadoresDeFechas.length; i++) {
      if (this.agrupadoresDeFechas[i]['key'] == this.agrupadorDeFecha) {
        this.enviarAgrupadorFecha.emit(this.agrupadoresDeFechas[i]);
        break
      }
    }
  }

  // public ocultarFiltroFechas(){
  //   this.isCollapsedFiltroFechas = !this.isCollapsedFiltroFechas;
  //   this.traduccionshowContentFiltroFecha = this.isCollapsedFiltroFechas ? 'agrupadorPeriodos.mostrarFiltroFecha' : 'agrupadorPeriodos.ocultarFiltroFecha';
  //   this.enviarShowContentFiltroFecha.emit(this.isCollapsedFiltroFechas);
  // }

  public ocultarMostrar(){
    this.showContent = !this.showContent;
    this.traduccionshowContent = this.showContent ? 'agrupadorPeriodos.suprimirInformacion' : 'agrupadorPeriodos.expandirInformacion';
    this.enviarShowContent.emit(this.showContent);
  }
  

  onDateSelection(date: NgbDate) {
		if (!this.fromDate && !this.toDate) {
			this.fromDate = date;
		} else if (this.fromDate && !this.toDate && (date.after(this.fromDate) || date.equals(this.fromDate))) {
			this.toDate = date;
		} else {
			this.toDate = null;
			this.fromDate = date;
		}

    if(this.fromDate && this.toDate){
      this.selectDateRange(RANGO_FECHAS.PERSONALIZADO)
    }
	}

	isHovered(date: NgbDate) {
		return (
			this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
		);
	}

	isInside(date: NgbDate) {
		return this.toDate && this.fromDate && date.after(this.fromDate) && date.before(this.toDate);
	}

	isRange(date: NgbDate) {
		return (
			date.equals(this.fromDate) ||
			(this.toDate && date.equals(this.toDate)) ||
			this.isInside(date) ||
			this.isHovered(date)
		);
	}

  selectDateRange(range: RANGO_FECHAS): void {
    if(range != RANGO_FECHAS.PERSONALIZADO){
      let arrayDateRange:NgbDate[] = selectDateRangeHelper(range, this.calendar);
      this.fromDate = arrayDateRange[0];
      this.toDate = arrayDateRange[1];
    }
    let fechaDesde:Date = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day, 0,0,0,0);
    let fechaHasta:Date = new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day, 23,59,59,999);

    this.traduccionRangeSeleccionado = selectDateRangeTraduccion(range);
    this.selectedDateRange = range;

    this.enviarFiltroFecha.emit(
      [this.selectedDateRange,
        [
          fechaDesde,
          fechaHasta
        ]
      ]
    );
  }
  
  
  limpiarValor(){
    this.selectedDateRange = null;
    this.hoveredDate = null;
    this.fromDate = null;
    this.toDate = null;
    this.enviarFiltroFecha.emit(null);
  }


  // tipoGrafico:TIPO_GRAFICO el parametro deberia ser de este tipo TiposGraficosVisualizados
  public onChangeTypeChart(){
    this.enviarTipoGraphic.emit(this.typeChartDefault);
  }

  public onChangeTypeOrderChart(){
    this.enviarTipoOrderGraphic.emit(this.typeOrderInCharts);
  }
}


export interface TypeChart {
  key:TIPO_GRAFICO,
  nombre: string
}

export class TipoAgrupadorFecha {
  private key:TIPO_AGRUPADOR = TIPO_AGRUPADOR.DIA;
  private nombre: string = "agrupadorPeriodos.dia";

  constructor(obj?: Partial<TipoAgrupadorFecha>) {    
    Object.assign(this, obj);
  }

  getJson():object{
    return {
      "key" : this.key,
      "nombre" : this.nombre
    };
  }

  public getKey():TIPO_AGRUPADOR{
    return this.key;
  }

  public setKey(key:TIPO_AGRUPADOR){
    this.key = key;
  }

  public getNombre():string{
    return this.nombre;
  }

  public setNombre(nombre:string){
    this.nombre = nombre;
  }
} 
  
  

                
                   
                     
  
  


