import { Component, OnInit, ViewEncapsulation, HostListener, Output, Input, EventEmitter } from '@angular/core';
import { AppSettingsService } from '@settings/app.settings';


import { MenuService } from '@maq-servicios/menu/menu.service';
import { MensajesService }  from '@maq-servicios/mensajes/mensajes.service';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ MenuService ]
})
export class SidebarComponent implements OnInit {  



  public menuItems:Array<any>;
  constructor(public appSettings:AppSettingsService, 
              public menuService:MenuService,
              private msg:MensajesService) {
 
      this.menuItems = [];
      // this.menuItems = this.menuService.getVerticalMenuItems();
      // console.log('sidebar this.menuItems',this.menuItems);
  }

  ngOnInit() { 

    this.msg.getPerfil().subscribe(usuario=>{
      // console.log('mensaje',usuario);

    });
  }

  public closeSubMenus(){
    let menu = document.querySelector("#menu0");
    for (let i = 0; i < menu.children.length; i++) {
        let child = menu.children[i].children[1]; 
        if(child){
            if(child.classList.contains('show')){
              child.classList.remove('show');
              menu.children[i].children[0].classList.add('collapsed'); 
            }             
        }
    }
  }


}
