<div class="custom-file" style="margin-bottom:5px;" *ngIf="accionForm!='consultar'">
    <input type="file" class="custom-file-input" id="customFile" 
           accept="image/x-png,image/gif,image/jpeg"
           (change)="fileChangeEvent($event)">
    <label class="custom-file-label" for="customFile" translate>componente-croppie.seleccioneArchivo</label>
</div>

<!-- <br *ngIf="accionForm!='consultar'"> -->

<img [src]="croppedImage" *ngIf="croppedImage"
     style="width:100%;"
     [style.border]="croppedImage ? '1px solid black' : 'none'">

<div class="form-group" *ngIf="croppedImage">
      <div class="input-group">
          <input [value]='nombreArchivo' class="form-control" type="text" readonly 
                 (click)="fn.goToLink(image)">
          <div class="input-group-append" style="background:#e9ecef;">
              <img [src]="extensionIcono" style="height:32px;" 
                 (click)="fn.goToLink(image)"> 
          </div>
      </div>
</div>

<img *ngIf="!croppedImage"
     src="assets/img/app/no-image.png" 
     width="100%"><br *ngIf="!image">     

<i *ngIf="croppedImage && accionForm!='consultar'" 
    class="fa fa-times delete-img" (click)="inputBorrar(imageName)" 
    style="float:right; color:red;"></i>


<ng-template #modalContent>

    <div class="modal-header modal-lg state modal-primary">

        <h4 class="modal-title" id="modal-primary-label"><i class="fa fa-picture-o"></i><span translate>componente-croppie.title</span></h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()"><span aria-hidden="true" style="color:white; border:hidden;">×</span></button>
    </div>

    <div class="modal-body bg-gray">

                <!-- 
                format (png, jpeg, webp, bmp, ico)
                outputType="base64"  
                outputType="file"    
                outputType="both"    
                 -->

            <image-cropper
                [imageChangedEvent]="imageChangedEvent"
                [maintainAspectRatio]="true"
                [containWithinAspectRatio]="containWithinAspectRatio"
                [aspectRatio]="aspectRatio"
                [resizeToWidth]="1024"
                [cropperMinWidth]="300"
                [onlyScaleDown]="true"
                [roundCropper]="false"
                format="jpeg"
                outputType="both" 
                (imageCropped)="imageCropped($event)"
                (imageLoaded)="imageLoaded()"
                (cropperReady)="cropperReady()"
                (loadImageFailed)="loadImageFailed()"
                [style.display]="showCropper ? null : 'none'"
                [alignImage]="'left'"
            ></image-cropper>

            <!--
            <div class="row" style="margin-top:10px;">
                <div class="col-3">
                    <button (click)="imageCropper.rotateLeft()" class="btn btn-primary btn-lg btn-block"><span class="fa fa-rotate-left"></span></button>
                </div>    
                <div class="col-3">
                    <button (click)="rotateRight()" class="btn btn-primary btn-lg btn-block"><span class="fa fa-rotate-right"></span></button>
                </div>    
                <div class="col-3">
                    <button (click)="flipHorizontal()" class="btn btn-primary btn-lg btn-block"><span class="fa fa-arrows-h"></span></button>
                </div>    
                <div class="col-3">
                    <button (click)="flipVertical()" class="btn btn-primary btn-lg btn-block"><span class="fa fa-arrows-v"></span></button>
                </div>    
            </div>
            -->

            <!--
            <br />
            <button (click)="toggleContainWithinAspectRatio()">{{containWithinAspectRatio?'Fill Aspect Ratio':'Contain Within Aspect Ratio'}}</button>
            <button (click)="resetImage()">Reset image</button>
            -->

            <!--<button type="button btn btn-secondary" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">Terminar</button>-->

    </div>

</ng-template>
