<div class="row">
  <div [ngClass]="mostrarBotones() ? 'col-11' : 'col-12'" [hidden]="!finalizoConsultaReportesFavoritos">
      <select [(ngModel)]="selectReport"
        class="form-control selectpicker"
        (change)="changeSelect(content)" [compareWith]="compareFn">                                       
        <ng-container *ngFor="let opcion of listadoFavoritos">    
          <option [ngValue]="opcion"> {{opcion.nombre | translate}}</option>
        </ng-container>            
    </select>   
  </div>
  
  <div [hidden]="!mostrarBotones()" class="col-1">
      <button 
        title="{{'navegabilidad.eliminar' | translate}}"
        class="btn btn-link btn-lg"  style="padding-left:0px; padding-right:0px;"
        (click)="deleteFavorito()"><i class="mdi mdi-delete mr-3 text-danger"></i></button>

        <button
        title="{{'reportes.actualizarReporte' | translate}}"
        class="btn btn-link btn-lg"  style="padding-left:0px; padding-right:0px;"
      (click)="modificar()"><i class="mdi mdi-content-save mr-3 text-dark"></i></button>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{'reportes.nuevoReporteFavorito' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form [formGroup]="formReporteFavorito">

    <div class="modal-body">
            <div class="form-group">
                <label class="text-gray" translate>claseFicha.nombre<span class="required">*</span></label>
                <input id="nombreReporte" formControlName="nombreReporte"
                        class="form-control validation-field" type="text" placeholder="" required>
                    
                <small *ngIf="formReporteFavorito.controls.nombreReporte.touched && 
                        formReporteFavorito.controls.nombreReporte.errors?.required" class="text-danger"><span translate>claseFicha.nombre</span>&nbsp;<span translate>claseValidaciones.esRequerido</span></small>
            </div>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modal.close('cancelar')">{{'navegabilidad.cancelar' | translate}}</button>
        <button [disabled]="(formReporteFavorito.controls.nombreReporte.touched && 
                            formReporteFavorito.controls.nombreReporte.errors?.required)
                            || !formReporteFavorito.value.nombreReporte" 
                type="button" class="btn btn-primary" (click)="modal.close('submit')">
                {{'navegabilidad.grabar' | translate}}
        </button>
    </div>   

</form>
  <!-- <div class="modal-body">

      <input [(ngModel)]="nombre"  
         type="text" class="form-control" 
         placeholder="{{'claseFicha.nombre' | translate}}">

  </div>
  <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="grabar()">{{'navegabilidad.grabar' | translate}}</button>
  </div> -->
</ng-template>

