import { log, logIf, logTable, values } from '@maq-console';

import {ChangeDetectorRef, Component} from '@angular/core';

import { EventEmitter, OnInit, AfterViewInit, OnDestroy, DoCheck, ViewChild, Output, Input, ViewEncapsulation } from '@angular/core';

import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

import { BDService }        from '@maq-bd/bd.service';

import { ConfirmService }    from '@maq-servicios/confirm/confirm-service';
import { TranslateService }  from '@ngx-translate/core';

import { KANE } from '@maq-models/typesKN/typesKN.model';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'lib-shared-reportes-favoritos',
  templateUrl: './reportes-favoritos.html'
})
export class ReportesFavoritosComponent implements OnInit, OnDestroy {
  @Input() public usuarioKANE: KANE;
  @Input() public reporteName: string;
  @Input() public columnasVisibles: any[]; //cambiar por ColumnaReporte
  
  @Output() seleccionoFavorito= new EventEmitter<any[]>();
  
  public listadoFavoritos:any[]=[];
  public subscripcionFavoritos:any=null;
  public nombre:string=null;
  public selectReport:any=null;
  public columnasVisiblesOriginal:any=null;

  public finalizoConsultaReportesFavoritos: boolean = false;
  
  public closeResult: string = '';

  public formReporteFavorito : FormGroup;


  constructor(private modalService: NgbModal,
             public bdService: BDService,
             public confirmService   : ConfirmService,
             public translate        : TranslateService,
             public fb               : FormBuilder, 
             private cdRef: ChangeDetectorRef) {

  }
  
  ngOnInit() {

    this.formReporteFavorito = this.fb.group({
      nombreReporte: [null, Validators.compose([Validators.required])],
    });

    if(this.columnasVisiblesOriginal==null) {
      this.columnasVisiblesOriginal = this.columnasVisibles;
    }  
    
    this.selectReport={
      key             : 'Default',
      nombre          : 'Reporte Estandard',
      columnasVisibles: this.columnasVisiblesOriginal
    };
    
    // GET Reportes
    this.listadoFavoritos=[];                                
    if(this.subscripcionFavoritos)   this.subscripcionFavoritos.unsubscribe();
    this.subscripcionFavoritos=this.bdService	
        .getBDSubscripcion({
            nombreColeccion  : 'ReportesFavoritos',
            where            : [{key:'usuarioKANE.key', operador:'==', value:this.usuarioKANE.key},
                                {key:'reporteName', operador:'==', value:this.reporteName}],
            orderBy          : [{key:'nombre',ascDesc:'asc'}],
            limit            : 10,
            paginado         : 'primera',
            organizacionKNAI : null,
            usuarioKANE      : this.usuarioKANE                
        }).subscribe((data:any)=>{
            log(...values('funcionEnd','bdService.getBDSubscripcion ReportesFavoritos')); 
            log(...values('valores','subscripcion ReportesFavoritos data:',data)); 

            this.listadoFavoritos=[{
              key             : 'Default',
              nombre          : 'Reporte Estandard',
              columnasVisibles: this.columnasVisiblesOriginal
            },{
              key   : 'Nuevo',
              nombre: 'reportes.nuevoReporteFavorito'
            }];                                
                    
            for(let j=0; j<data.length; j++) {
              let documento=data[j];
              this.listadoFavoritos.push(documento);
            }
            this.finalizoConsultaReportesFavoritos = true;             
            log(...values("valores","listadoFavoritos:",this.listadoFavoritos));
        },(error:any)=>{
            this.finalizoConsultaReportesFavoritos = true;
            log(...values("error al obtener ReportesFavoritos",error));
        });         
  }          
  
  ngOnDestroy() {
    if(this.subscripcionFavoritos)             this.subscripcionFavoritos.unsubscribe();
  }
  
  changeSelect(content) { 
    if(this.selectReport.key=='Nuevo') {
      this.open(content).then(result => {
        this.cdRef.detectChanges(); //aviso cambios en la vista.
      });

      // setTimeout(() => {
      //   console.log("changeSelect",this.selectReport)
      // }, 400);
    } else {
      this.seleccionoFavorito.emit(this.selectReport.columnasVisibles);
    }
    this.cdRef.detectChanges(); //aviso cambios en la vista.

  }
  
  mostrarBotones() {
    if(['Default','Nuevo'].indexOf(this.selectReport.key)!=-1) {
      return false;
    } else {
      return true;
    }  
  }
    
  open(content) {
    return this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      if (result =='submit'){
        this.nombre = this.formReporteFavorito.get('nombreReporte').value
        if(this.nombre!='' && this.nombre != null) this.grabar();
      }


      // this.closeResult = `Closed with: ${result}`;
    
    }).catch(error => {
      console.error('error openModal: ', error)
      this.closeResult = `Dismissed ${this.getDismissReason(error)}`;
    });
    // }, (reason) => {
    //   this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    // });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  
  modificar() {
    this.confirmService.confirm({ 
        title:   this.translate.instant('reportes.actualizarReporte'), 
        message: '' })
    .then((resultadoOK) => {
      if ( resultadoOK != 'close'){
        this.nombre=this.selectReport.nombre;
        this.grabar();
      }
    }).catch((error) => {
        
    });     

   
  }
  
  grabar() {
    /*
    console.log("this.usuarioKANE",this.usuarioKANE);
    console.log("this.reporteName",this.reporteName);
    console.log("this.nombre",this.nombre);
    console.log("this.columnasVisibles",this.columnasVisibles);
    */
    
    // Grabo Nuevo Reporte
    let documento={
      key             : this.usuarioKANE.email+'-'+this.reporteName+'-'+this.nombre,
      reporteName     : this.reporteName,
      nombre          : this.nombre,
      usuarioKANE     : this.usuarioKANE,
      columnasVisibles: this.columnasVisibles
    }
    
    this.bdService.updateColeccion2({
          operacion        : 'modificar',
          nombreColeccion  : 'ReportesFavoritos',
          campoClave       : '',
          documento        : documento,
          distribuidorKN   : null,
          organizacionKNAI : null,                             
          usuarioKANE      : this.usuarioKANE, 
          usaSettings      : false      
      }).then(dato=>{
            let keyForm = dato.replace('|mensajes.grabacionOk', '');
            let mensajeServicio = dato.replace(keyForm+'|', '');
            log(...values('success','Grabación OK Nuevo Favorito',mensajeServicio,keyForm));
            this.modalService.dismissAll();

      }).catch(error=>{
            log(...values("error","Error Grabación Nuevo Favorito - error:",error));
            this.modalService.dismissAll();

      });                  
    
  }
  
  deleteFavorito() {
    
    this.confirmService.confirm({ 
        title:   this.translate.instant('reportes.eliminarReporte'), 
        message: '' })
    .then((resultadoOK) => {  
        if(resultadoOK != 'close'){
          this.bdService.deleteDocumento('ReportesFavoritos',this.selectReport.key
            ).then(dato=>{
                console.log("elimino favorito ok",dato);
            }).catch(error=>{
                  log(...values("error","Error al eliminar Favorito - error:",error));
            });   
        }
                       
    }).catch((error) => {
        
    });     
  }
  
  compareFn(c1: any, c2:any): boolean {     
    // console.log("compareFn c1", c1);
    // console.log("compareFn c2", c2);
      return c1 && c2 ? c1.key === c2.key : c1 === c2; 
  }
  
}