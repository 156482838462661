
<form [formGroup]="form" (change)="onChangeField()">

    <div widget class="card border-0 box-shadow" style="margin-top:20px;">
        <div class="card-header transparent border-0 text-muted">
            <h5 class="mb-0">{{traduccion.claseDateTime.horarioAtencion}}</h5>
            <div class="widget-controls">
                <a data-widgster="expand" href="#" class="transition"><i class="fa fa-chevron-down"></i></a>
                <a data-widgster="collapse" href="#" class="transition"><i class="fa fa-chevron-up"></i></a>
                <a data-widgster="close" href="#" class="transition"><i class="fa fa-times"></i></a>
            </div>
        </div>
        <div class="card-body pt-0 widget-body">

            <!-- Horarios de Atención -->
            <table class="table table-sm" style="border:hidden;">
                <thead>
                    <tr>     
                        <td translate>claseDateTime.dia</td>                      
                        <td class="text-center" translate>claseDateTime.horaDesde</td>                      
                        <td style="padding-left:0px; padding-right:0px;"></td>                      
                        <td class="text-center" translate>claseDateTime.horaHasta</td>                      
                        <td style="width:10px;"></td>                      
                        <td class="text-center" translate>claseDateTime.horaDesde</td>              
                        <td style="padding-left:0px; padding-right:0px;"></td>                              
                        <td class="text-center" translate>claseDateTime.horaHasta</td>                      
                        <td style="width:10px;"></td>                      
                        <td class="text-center" translate>claseDateTime.horaDesde</td>              
                        <td style="padding-left:0px; padding-right:0px;"></td>                              
                        <td class="text-center" translate>claseDateTime.horaHasta</td>                      
                        <td></td>
                    </tr>                      
                </thead>    
                <tbody formGroupName="horarioAtencion">
                    <tr formGroupName="lunes">
                        <td class="align-middle"> 
                            <label class="text-gray" translate>claseDateTime.lunes</label>
                        </td>                      
                        <td> 
                            <ngb-timepicker formControlName="horaDesde1" [spinners]="false" size="small" (change)="changeTimePicker('lunes.horaDesde1')"                                         
                                            class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('lunes.horaDesde1')"></ngb-timepicker>
                            <small class="text-danger" 
                            *ngIf="form.get('horarioAtencion').get('lunes').get('horaDesde1').touched && 
                                   form.get('horarioAtencion').get('lunes').get('horaDesde1').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                         
                        </td>                      
                        <td style="padding-left:0px; padding-right:0px;">-</td>                      
                        <td> 
                            <ngb-timepicker formControlName="horaHasta1" [spinners]="false" size="small" (change)="changeTimePicker('lunes.horaHasta1')" 
                                            class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('lunes.horaHasta1')"></ngb-timepicker>
                            <small class="text-danger" 
                                *ngIf="form.get('horarioAtencion').get('lunes').get('horaHasta1').touched && 
                                       form.get('horarioAtencion').get('lunes').get('horaHasta1').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                         
                        </td>                      
                        <td></td>                      
                        <td>
                            <ngb-timepicker formControlName="horaDesde2" [spinners]="false" size="small" (change)="changeTimePicker('lunes.horaDesde2')" 
                                            class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('lunes.horaDesde2')"></ngb-timepicker>
                            <small class="text-danger" 
                                    *ngIf="form.get('horarioAtencion').get('lunes').get('horaDesde2').touched && 
                                           form.get('horarioAtencion').get('lunes').get('horaDesde2').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                         
                        </td>                      
                        <td style="padding-left:0px; padding-right:0px;">-</td>                      
                        <td>
                            <ngb-timepicker formControlName="horaHasta2" [spinners]="false" size="small" (change)="changeTimePicker('lunes.horaHasta2')" 
                                            class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('lunes.horaHasta2')"></ngb-timepicker>
                            <small class="text-danger" 
                                    *ngIf="form.get('horarioAtencion').get('lunes').get('horaHasta2').touched && 
                                           form.get('horarioAtencion').get('lunes').get('horaHasta2').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                                                                                         
                        </td>                      
                        <td></td>                      
                        <td>
                            <ngb-timepicker formControlName="horaDesde3" [spinners]="false" size="small" (change)="changeTimePicker('lunes.horaDesde3')" 
                                            class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('lunes.horaDesde3')"></ngb-timepicker>
                            <small class="text-danger" 
                                   *ngIf="form.get('horarioAtencion').get('lunes').get('horaDesde3').touched && 
                                          form.get('horarioAtencion').get('lunes').get('horaDesde3').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                         
                        </td>                      
                        <td style="padding-left:0px; padding-right:0px;">-</td>                      
                        <td>
                            <ngb-timepicker formControlName="horaHasta3" [spinners]="false" size="small" (change)="changeTimePicker('lunes.horaHasta3')" 
                                            class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('lunes.horaHasta3')"></ngb-timepicker>
                            <small class="text-danger" 
                                *ngIf="form.get('horarioAtencion').get('lunes').get('horaHasta3').touched && 
                                       form.get('horarioAtencion').get('lunes').get('horaHasta3').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                                                                                         
                        </td>                      
                        <td style="padding-top:0px; padding-bottom:0px; width:60px; white-space: nowrap;">
                            <button class="btn btn-link btn-lg" style="padding-left:0px; padding-right:0px; padding-top:0px; padding-bottom:0px;"
                                title="{{'navegabilidad.copiar' | translate}}"
                                (click)="copyHorario('lunes')"><i class="fa fa-copy mr-3 text-primary"></i></button>

                            <button class="btn btn-link btn-lg" style="padding-left:0px; padding-right:0px; padding-top:0px; padding-bottom:0px;"
                                title="{{'navegabilidad.pegar' | translate}}"
                                *ngIf="horarioCopiado"
                                (click)="pasteHorario('lunes')"><i class="fa fa-paste mr-3 text-primary"></i></button>
                        </td>
                    </tr>                      

                    <tr formGroupName="martes">     
                        <td class="align-middle"> 
                            <label class="text-gray" translate>claseDateTime.martes</label>
                        </td>                      
                        <td> 
                            <ngb-timepicker formControlName="horaDesde1" [spinners]="false" size="small"
                                    class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('martes.horaDesde1')"></ngb-timepicker>
                            <small class="text-danger" 
                                    *ngIf="form.get('horarioAtencion').get('martes').get('horaDesde1').touched && 
                                           form.get('horarioAtencion').get('martes').get('horaDesde1').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                         
                        </td>                      
                        <td style="padding-left:0px; padding-right:0px;">-</td>                      
                        <td>
                            <ngb-timepicker formControlName="horaHasta1" [spinners]="false" size="small"
                                        class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('martes.horaHasta1')"></ngb-timepicker>
                            <small class="text-danger" 
                                   *ngIf="form.get('horarioAtencion').get('martes').get('horaHasta1').touched && 
                                          form.get('horarioAtencion').get('martes').get('horaHasta1').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                                                                                         
                        </td>                      
                        <td></td>                      
                        <td>
                            <ngb-timepicker formControlName="horaDesde2" [spinners]="false" size="small" 
                                            class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('martes.horaDesde2')"></ngb-timepicker>
                            <small class="text-danger" 
                                    *ngIf="form.get('horarioAtencion').get('martes').get('horaDesde2').touched && 
                                           form.get('horarioAtencion').get('martes').get('horaDesde2').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                                                                                     
                        </td>                      
                        <td style="padding-left:0px; padding-right:0px;">-</td>                      
                        <td>
                            <ngb-timepicker formControlName="horaHasta2" [spinners]="false" size="small" 
                                            class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('martes.horaHasta2')"></ngb-timepicker>
                            <small class="text-danger" 
                                    *ngIf="form.get('horarioAtencion').get('martes').get('horaHasta2').touched && 
                                           form.get('horarioAtencion').get('martes').get('horaHasta2').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                         
                        </td>                      
                        <td></td>                      
                        <td>
                            <ngb-timepicker formControlName="horaDesde3" [spinners]="false" size="small" 
                                            class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('martes.horaDesde3')"></ngb-timepicker>
                            <small class="text-danger" 
                                    *ngIf="form.get('horarioAtencion').get('martes').get('horaDesde3').touched && 
                                           form.get('horarioAtencion').get('martes').get('horaDesde3').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                                                                                     
                        </td>                      
                        <td style="padding-left:0px; padding-right:0px;">-</td>                      
                        <td>
                            <ngb-timepicker formControlName="horaHasta3" [spinners]="false" size="small" 
                                            class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('martes.horaHasta3')"></ngb-timepicker>
                            <small class="text-danger" 
                                   *ngIf="form.get('horarioAtencion').get('martes').get('horaHasta3').touched && 
                                          form.get('horarioAtencion').get('martes').get('horaHasta3').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                         
                        </td>                      
                        <td style="padding-top:0px; padding-bottom:0px; width:60px; white-space: nowrap;">
                            <button class="btn btn-link btn-lg" style="padding-left:0px; padding-right:0px; padding-top:0px; padding-bottom:0px;"
                                title="{{'navegabilidad.copiar' | translate}}"
                                (click)="copyHorario('martes')"><i class="fa fa-copy mr-3 text-primary"></i></button>

                            <button class="btn btn-link btn-lg" style="padding-left:0px; padding-right:0px; padding-top:0px; padding-bottom:0px;"
                                title="{{'navegabilidad.pegar' | translate}}"
                                *ngIf="horarioCopiado"
                                (click)="pasteHorario('martes')"><i class="fa fa-paste mr-3 text-primary"></i></button>
                        </td>
                    </tr>                      

                    <tr formGroupName="miercoles">     
                        <td class="align-middle"> 
                            <label class="text-gray" translate>claseDateTime.miercoles</label>
                        </td>                      
                        <td> 
                            <ngb-timepicker formControlName="horaDesde1" [spinners]="false" size="small"
                                            class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('miercoles.horaDesde1')"></ngb-timepicker>
                            <small class="text-danger" 
                                *ngIf="form.get('horarioAtencion').get('miercoles').get('horaDesde1').touched && 
                                       form.get('horarioAtencion').get('miercoles').get('horaDesde1').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                         
                        </td>                      
                        <td style="padding-left:0px; padding-right:0px;">-</td>                      
                        <td>
                            <ngb-timepicker formControlName="horaHasta1" [spinners]="false" size="small"
                                            class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('miercoles.horaHasta1')"></ngb-timepicker>
                            <small class="text-danger" 
                                *ngIf="form.get('horarioAtencion').get('miercoles').get('horaHasta1').touched && 
                                       form.get('horarioAtencion').get('miercoles').get('horaHasta1').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                         
                        </td>                      
                        <td></td>                      
                        <td>
                            <ngb-timepicker formControlName="horaDesde2" [spinners]="false" size="small" 
                                            class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('miercoles.horaDesde2')"></ngb-timepicker>
                            <small class="text-danger" 
                                    *ngIf="form.get('horarioAtencion').get('miercoles').get('horaDesde2').touched && 
                                           form.get('horarioAtencion').get('miercoles').get('horaDesde2').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                         
                        </td>                      
                        <td style="padding-left:0px; padding-right:0px;">-</td>                      
                        <td>
                            <ngb-timepicker formControlName="horaHasta2" [spinners]="false" size="small" 
                                            class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('miercoles.horaHasta2')"></ngb-timepicker>
                            <small class="text-danger" 
                                *ngIf="form.get('horarioAtencion').get('miercoles').get('horaHasta2').touched && 
                                       form.get('horarioAtencion').get('miercoles').get('horaHasta2').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                                                                                         
                        </td>                      
                        <td></td>                      
                        <td>
                            <ngb-timepicker formControlName="horaDesde3" [spinners]="false" size="small" 
                                            class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('miercoles.horaDesde3')"></ngb-timepicker>
                            <small class="text-danger" 
                                *ngIf="form.get('horarioAtencion').get('miercoles').get('horaDesde3').touched && 
                                       form.get('horarioAtencion').get('miercoles').get('horaDesde3').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                         
                        </td>                      
                        <td style="padding-left:0px; padding-right:0px;">-</td>                      
                        <td>
                            <ngb-timepicker formControlName="horaHasta3" [spinners]="false" size="small" 
                                            class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('miercoles.horaHasta3')"></ngb-timepicker>
                            <small class="text-danger" 
                                *ngIf="form.get('horarioAtencion').get('miercoles').get('horaHasta3').touched && 
                                       form.get('horarioAtencion').get('miercoles').get('horaHasta3').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                                                                                         
                        </td>                      
                        <td style="padding-top:0px; padding-bottom:0px; width:60px; white-space: nowrap;">
                            <button class="btn btn-link btn-lg" style="padding-left:0px; padding-right:0px; padding-top:0px; padding-bottom:0px;"
                                title="{{'navegabilidad.copiar' | translate}}"
                                (click)="copyHorario('miercoles')"><i class="fa fa-copy mr-3 text-primary"></i></button>

                            <button class="btn btn-link btn-lg" style="padding-left:0px; padding-right:0px; padding-top:0px; padding-bottom:0px;"
                                title="{{'navegabilidad.pegar' | translate}}"
                                *ngIf="horarioCopiado"
                                (click)="pasteHorario('miercoles')"><i class="fa fa-paste mr-3 text-primary"></i></button>
                        </td>
                    </tr>                      

                    <tr formGroupName="jueves">     
                        <td class="align-middle"> 
                            <label class="text-gray" translate>claseDateTime.jueves</label>
                        </td>                      
                        <td> 
                            <ngb-timepicker formControlName="horaDesde1" [spinners]="false" size="small"
                                            class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('jueves.horaDesde1')"></ngb-timepicker>
                            <small class="text-danger" 
                                *ngIf="form.get('horarioAtencion').get('jueves').get('horaDesde1').touched && 
                                       form.get('horarioAtencion').get('jueves').get('horaDesde1').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                         
                        </td>                      
                        <td style="padding-left:0px; padding-right:0px;">-</td>                      
                        <td>
                            <ngb-timepicker formControlName="horaHasta1" [spinners]="false" size="small"
                                            class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('jueves.horaHasta1')"></ngb-timepicker>
                            <small class="text-danger" 
                                *ngIf="form.get('horarioAtencion').get('jueves').get('horaHasta1').touched && 
                                       form.get('horarioAtencion').get('jueves').get('horaHasta1').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                         
                        </td>                      
                        <td></td>                      
                        <td>
                            <ngb-timepicker formControlName="horaDesde2" [spinners]="false" size="small" 
                                            class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('jueves.horaDesde2')"></ngb-timepicker>
                            <small class="text-danger" 
                                *ngIf="form.get('horarioAtencion').get('jueves').get('horaDesde2').touched && 
                                       form.get('horarioAtencion').get('jueves').get('horaDesde2').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                         
                        </td>                      
                        <td style="padding-left:0px; padding-right:0px;">-</td>                      
                        <td>
                            <ngb-timepicker formControlName="horaHasta2" [spinners]="false" size="small" 
                                            class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('jueves.horaHasta2')"></ngb-timepicker>
                            <small class="text-danger" 
                                *ngIf="form.get('horarioAtencion').get('jueves').get('horaHasta2').touched && 
                                       form.get('horarioAtencion').get('jueves').get('horaHasta2').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                         
                        </td>                      
                        <td></td>                      
                        <td>
                            <ngb-timepicker formControlName="horaDesde3" [spinners]="false" size="small" 
                                            class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('jueves.horaDesde3')"></ngb-timepicker>
                            <small class="text-danger" 
                                *ngIf="form.get('horarioAtencion').get('jueves').get('horaDesde3').touched && 
                                       form.get('horarioAtencion').get('jueves').get('horaDesde3').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                         
                        </td>                      
                        <td style="padding-left:0px; padding-right:0px;">-</td>                      
                        <td>
                            <ngb-timepicker formControlName="horaHasta3" [spinners]="false" size="small" 
                                            class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('jueves.horaHasta3')"></ngb-timepicker>
                            <small class="text-danger" 
                                *ngIf="form.get('horarioAtencion').get('jueves').get('horaHasta3').touched && 
                                       form.get('horarioAtencion').get('jueves').get('horaHasta3').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                         
                        </td>                      
                        <td style="padding-top:0px; padding-bottom:0px; width:60px; white-space: nowrap;">
                            <button class="btn btn-link btn-lg" style="padding-left:0px; padding-right:0px; padding-top:0px; padding-bottom:0px;"
                                title="{{'navegabilidad.copiar' | translate}}"
                                (click)="copyHorario('jueves')"><i class="fa fa-copy mr-3 text-primary"></i></button>

                            <button class="btn btn-link btn-lg" style="padding-left:0px; padding-right:0px; padding-top:0px; padding-bottom:0px;"
                                title="{{'navegabilidad.pegar' | translate}}"
                                *ngIf="horarioCopiado"
                                (click)="pasteHorario('jueves')"><i class="fa fa-paste mr-3 text-primary"></i></button>
                        </td>
                    </tr>                      

                    <tr formGroupName="viernes">     
                        <td class="align-middle"> 
                            <label class="text-gray" translate>claseDateTime.viernes</label>
                        </td>                      
                        <td> 
                            <ngb-timepicker formControlName="horaDesde1" [spinners]="false" size="small"
                                            class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('viernes.horaDesde1')"></ngb-timepicker>
                            <small class="text-danger" 
                                *ngIf="form.get('horarioAtencion').get('viernes').get('horaDesde1').touched && 
                                       form.get('horarioAtencion').get('viernes').get('horaDesde1').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                         
                        </td>                      
                        <td style="padding-left:0px; padding-right:0px;">-</td>                      
                        <td>
                            <ngb-timepicker formControlName="horaHasta1" [spinners]="false" size="small"
                                        class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('viernes.horaHasta1')"></ngb-timepicker>
                            <small class="text-danger" 
                                *ngIf="form.get('horarioAtencion').get('viernes').get('horaHasta1').touched && 
                                       form.get('horarioAtencion').get('viernes').get('horaHasta1').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                         
                        </td>                      
                        <td></td>                      
                        <td>
                            <ngb-timepicker formControlName="horaDesde2" [spinners]="false" size="small" 
                                            class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('viernes.horaDesde2')"></ngb-timepicker>
                            <small class="text-danger" 
                                *ngIf="form.get('horarioAtencion').get('viernes').get('horaDesde2').touched && 
                                       form.get('horarioAtencion').get('viernes').get('horaDesde2').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                         
                        </td>                      
                        <td style="padding-left:0px; padding-right:0px;">-</td>                      
                        <td>
                            <ngb-timepicker formControlName="horaHasta2" [spinners]="false" size="small" 
                                    class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('viernes.horaHasta2')"></ngb-timepicker>
                            <small class="text-danger" 
                                *ngIf="form.get('horarioAtencion').get('viernes').get('horaHasta2').touched && 
                                       form.get('horarioAtencion').get('viernes').get('horaHasta2').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                         
                        </td>                      
                        <td></td>                      
                        <td>
                            <ngb-timepicker formControlName="horaDesde3" [spinners]="false" size="small" 
                                    class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('viernes.horaDesde3')"></ngb-timepicker>
                            <small class="text-danger" 
                                    *ngIf="form.get('horarioAtencion').get('viernes').get('horaDesde3').touched && 
                                           form.get('horarioAtencion').get('viernes').get('horaDesde3').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                         
                        </td>                      
                        <td style="padding-left:0px; padding-right:0px;">-</td>                      
                        <td>
                            <ngb-timepicker formControlName="horaHasta3" [spinners]="false" size="small" 
                                   class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('viernes.horaHasta3')"></ngb-timepicker>
                            <small class="text-danger" 
                                   *ngIf="form.get('horarioAtencion').get('viernes').get('horaHasta3').touched && 
                                          form.get('horarioAtencion').get('viernes').get('horaHasta3').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                         
                        </td>                      
                        <td style="padding-top:0px; padding-bottom:0px; width:60px; white-space: nowrap;">
                            <button class="btn btn-link btn-lg" style="padding-left:0px; padding-right:0px; padding-top:0px; padding-bottom:0px;"
                                title="{{'navegabilidad.copiar' | translate}}"
                                (click)="copyHorario('viernes')"><i class="fa fa-copy mr-3 text-primary"></i></button>

                            <button class="btn btn-link btn-lg" style="padding-left:0px; padding-right:0px; padding-top:0px; padding-bottom:0px;"
                                title="{{'navegabilidad.pegar' | translate}}"
                                *ngIf="horarioCopiado"
                                (click)="pasteHorario('viernes')"><i class="fa fa-paste mr-3 text-primary"></i></button>
                        </td>
                    </tr>                      

                    <tr formGroupName="sabado">     
                        <td class="align-middle"> 
                            <label class="text-gray" translate>claseDateTime.sabado</label>
                        </td>                      
                        <td> 
                            <ngb-timepicker formControlName="horaDesde1" [spinners]="false" size="small"
                                    class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('sabado.horaDesde1')"></ngb-timepicker>
                            <small class="text-danger" 
                                    *ngIf="form.get('horarioAtencion').get('sabado').get('horaDesde1').touched && 
                                           form.get('horarioAtencion').get('sabado').get('horaDesde1').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                         
                        </td>                      
                        <td style="padding-left:0px; padding-right:0px;">-</td>                      
                        <td>
                            <ngb-timepicker formControlName="horaHasta1" [spinners]="false" size="small"
                                    class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('sabado.horaHasta1')"></ngb-timepicker>
                            <small class="text-danger" 
                                *ngIf="form.get('horarioAtencion').get('sabado').get('horaHasta1').touched && 
                                       form.get('horarioAtencion').get('sabado').get('horaHasta1').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                         
                        </td>                      
                        <td></td>                      
                        <td>
                            <ngb-timepicker formControlName="horaDesde2" [spinners]="false" size="small" 
                                    class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('sabado.horaDesde2')"></ngb-timepicker>
                            <small class="text-danger" 
                                    *ngIf="form.get('horarioAtencion').get('sabado').get('horaDesde2').touched && 
                                           form.get('horarioAtencion').get('sabado').get('horaDesde2').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                         
                        </td>                      
                        <td style="padding-left:0px; padding-right:0px;">-</td>                      
                        <td>
                            <ngb-timepicker formControlName="horaHasta2" [spinners]="false" size="small" 
                                  class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('sabado.horaHasta2')"></ngb-timepicker>
                            <small class="text-danger" 
                                  *ngIf="form.get('horarioAtencion').get('sabado').get('horaHasta2').touched && 
                                         form.get('horarioAtencion').get('sabado').get('horaHasta2').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                         
                        </td>                      
                        <td></td>                      
                        <td>
                            <ngb-timepicker formControlName="horaDesde3" [spinners]="false" size="small" 
                                    class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('sabado.horaDesde3')"></ngb-timepicker>
                            <small class="text-danger" 
                                *ngIf="form.get('horarioAtencion').get('sabado').get('horaDesde3').touched && 
                                       form.get('horarioAtencion').get('sabado').get('horaDesde3').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                         
                        </td>                      
                        <td style="padding-left:0px; padding-right:0px;">-</td>                      
                        <td>
                            <ngb-timepicker formControlName="horaHasta3" [spinners]="false" size="small" 
                                 class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('sabado.horaHasta3')"></ngb-timepicker>
                            <small class="text-danger" 
                                 *ngIf="form.get('horarioAtencion').get('sabado').get('horaHasta3').touched && 
                                        form.get('horarioAtencion').get('sabado').get('horaHasta3').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                         
                        </td>                      
                        <td style="padding-top:0px; padding-bottom:0px; width:60px; white-space: nowrap;">
                            <button class="btn btn-link btn-lg" style="padding-left:0px; padding-right:0px; padding-top:0px; padding-bottom:0px;"
                                title="{{'navegabilidad.copiar' | translate}}"
                                (click)="copyHorario('sabado')"><i class="fa fa-copy mr-3 text-primary"></i></button>

                            <button class="btn btn-link btn-lg" style="padding-left:0px; padding-right:0px; padding-top:0px; padding-bottom:0px;"
                                title="{{'navegabilidad.pegar' | translate}}"
                                *ngIf="horarioCopiado"
                                (click)="pasteHorario('sabado')"><i class="fa fa-paste mr-3 text-primary"></i></button>
                        </td>
                    </tr>                      

                    <tr formGroupName="domingo">     
                        <td class="align-middle"> 
                            <label class="text-gray" translate>claseDateTime.domingo</label>
                        </td>                      
                        <td> 
                            <ngb-timepicker formControlName="horaDesde1" [spinners]="false" size="small"
                                    class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('domingo.horaDesde1')"></ngb-timepicker>
                            <small class="text-danger" 
                                *ngIf="form.get('horarioAtencion').get('domingo').get('horaDesde1').touched && 
                                       form.get('horarioAtencion').get('domingo').get('horaDesde1').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                         
                        </td>                      
                        <td style="padding-left:0px; padding-right:0px;">-</td>                      
                        <td>
                            <ngb-timepicker formControlName="horaHasta1" [spinners]="false" size="small"
                                    class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('domingo.horaHasta1')"></ngb-timepicker>
                            <small class="text-danger" 
                                *ngIf="form.get('horarioAtencion').get('sabado').get('horaHasta1').touched && 
                                       form.get('horarioAtencion').get('sabado').get('horaHasta1').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                         
                        </td>                      
                        <td></td>                      
                        <td>
                            <ngb-timepicker formControlName="horaDesde2" [spinners]="false" size="small" 
                                    class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('domingo.horaDesde2')"></ngb-timepicker>
                            <small class="text-danger" 
                                *ngIf="form.get('horarioAtencion').get('domingo').get('horaDesde2').touched && 
                                       form.get('horarioAtencion').get('domingo').get('horaDesde2').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                         
                        </td>                      
                        <td style="padding-left:0px; padding-right:0px;">-</td>                      
                        <td>
                            <ngb-timepicker formControlName="horaHasta2" [spinners]="false" size="small" 
                                    class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('domingo.horaHasta2')"></ngb-timepicker>
                            <small class="text-danger" 
                                *ngIf="form.get('horarioAtencion').get('sabado').get('horaHasta2').touched && 
                                       form.get('horarioAtencion').get('sabado').get('horaHasta2').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                         
                        </td>                      
                        <td></td>                      
                        <td>
                            <ngb-timepicker formControlName="horaDesde3" [spinners]="false" size="small" 
                                  class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('domingo.horaDesde3')"></ngb-timepicker>
                            <small class="text-danger" 
                                  *ngIf="form.get('horarioAtencion').get('domingo').get('horaDesde3').touched && 
                                         form.get('horarioAtencion').get('domingo').get('horaDesde3').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                         
                        </td>                      
                        <td style="padding-left:0px; padding-right:0px;">-</td>                      
                        <td>
                            <ngb-timepicker formControlName="horaHasta3" [spinners]="false" size="small" 
                                    class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('domingo.horaHasta3')"></ngb-timepicker>
                            <small class="text-danger" 
                                *ngIf="form.get('horarioAtencion').get('domingo').get('horaHasta3').touched && 
                                       form.get('horarioAtencion').get('domingo').get('horaHasta3').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                         
                        </td>                      
                        <td style="padding-top:0px; padding-bottom:0px; width:60px; white-space: nowrap;">
                            <button class="btn btn-link btn-lg" style="padding-left:0px; padding-right:0px; padding-top:0px; padding-bottom:0px;"
                                title="{{'navegabilidad.copiar' | translate}}"
                                (click)="copyHorario('domingo')"><i class="fa fa-copy mr-3 text-primary"></i></button>

                            <button class="btn btn-link btn-lg" style="padding-left:0px; padding-right:0px; padding-top:0px; padding-bottom:0px;"
                                title="{{'navegabilidad.pegar' | translate}}"
                                *ngIf="horarioCopiado"
                                (click)="pasteHorario('domingo')"><i class="fa fa-paste mr-3 text-primary"></i></button>
                        </td>
                    </tr>                      

                    <tr formGroupName="visperaFeriado">     
                        <td class="align-middle"> 
                            <label class="text-gray" translate>claseDateTime.visperaFeriado</label>
                        </td>                      
                        <td> 
                            <ngb-timepicker formControlName="horaDesde1" [spinners]="false" size="small"
                                    class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('visperaFeriado.horaDesde1')"></ngb-timepicker>
                            <small class="text-danger" 
                                *ngIf="form.get('horarioAtencion').get('visperaFeriado').get('horaDesde1').touched && 
                                       form.get('horarioAtencion').get('visperaFeriado').get('horaDesde1').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                         
                        </td>                      
                        <td style="padding-left:0px; padding-right:0px;">-</td>                      
                        <td>
                            <ngb-timepicker formControlName="horaHasta1" [spinners]="false" size="small"
                                  class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('visperaFeriado.horaHasta1')"></ngb-timepicker>
                            <small class="text-danger" 
                                  *ngIf="form.get('horarioAtencion').get('visperaFeriado').get('horaHasta1').touched && 
                                         form.get('horarioAtencion').get('visperaFeriado').get('horaHasta1').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                         
                        </td>                      
                        <td></td>                      
                        <td>
                            <ngb-timepicker formControlName="horaDesde2" [spinners]="false" size="small" 
                                    class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('visperaFeriado.horaDesde2')"></ngb-timepicker>
                            <small class="text-danger" 
                                *ngIf="form.get('horarioAtencion').get('visperaFeriado').get('horaDesde2').touched && 
                                       form.get('horarioAtencion').get('visperaFeriado').get('horaDesde2').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                         
                        </td>                      
                        <td style="padding-left:0px; padding-right:0px;">-</td>                      
                        <td>
                            <ngb-timepicker formControlName="horaHasta2" [spinners]="false" size="small" 
                                class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('visperaFeriado.horaHasta2')"></ngb-timepicker>
                            <small class="text-danger" 
                                *ngIf="form.get('horarioAtencion').get('visperaFeriado').get('horaHasta2').touched && 
                                       form.get('horarioAtencion').get('visperaFeriado').get('horaHasta2').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                         
                        </td>                      
                        <td></td>                      
                        <td>
                            <ngb-timepicker formControlName="horaDesde3" [spinners]="false" size="small" 
                                class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('visperaFeriado.horaDesde3')"></ngb-timepicker>
                            <small class="text-danger" 
                                *ngIf="form.get('horarioAtencion').get('visperaFeriado').get('horaDesde3').touched && 
                                       form.get('horarioAtencion').get('visperaFeriado').get('horaDesde3').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                         
                        </td>                      
                        <td style="padding-left:0px; padding-right:0px;">-</td>                      
                        <td>
                            <ngb-timepicker formControlName="horaHasta3" [spinners]="false" size="small" 
                                class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('visperaFeriado.horaHasta3')"></ngb-timepicker>
                            <small class="text-danger" 
                                *ngIf="form.get('horarioAtencion').get('visperaFeriado').get('horaHasta3').touched && 
                                       form.get('horarioAtencion').get('visperaFeriado').get('horaHasta3').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                         
                        </td>                      
                        <td style="padding-top:0px; padding-bottom:0px; width:60px; white-space: nowrap;">
                            <button class="btn btn-link btn-lg" style="padding-left:0px; padding-right:0px; padding-top:0px; padding-bottom:0px;"
                                title="{{'navegabilidad.copiar' | translate}}"
                                (click)="copyHorario('visperaFeriado')"><i class="fa fa-copy mr-3 text-primary"></i></button>

                            <button class="btn btn-link btn-lg" style="padding-left:0px; padding-right:0px; padding-top:0px; padding-bottom:0px;"
                                title="{{'navegabilidad.pegar' | translate}}"
                                *ngIf="horarioCopiado"
                                (click)="pasteHorario('visperaFeriado')"><i class="fa fa-paste mr-3 text-primary"></i></button>
                        </td>
                    </tr>                      

                    <tr formGroupName="feriado">     
                        <td class="align-middle"> 
                            <label class="text-gray" translate>claseDateTime.feriado</label>
                        </td>                      
                        <td> 
                            <ngb-timepicker formControlName="horaDesde1" [spinners]="false" size="small"
                                    class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('feriado.horaDesde1')"></ngb-timepicker>
                            <small class="text-danger" 
                                *ngIf="form.get('horarioAtencion').get('feriado').get('horaDesde1').touched && 
                                       form.get('horarioAtencion').get('feriado').get('horaDesde1').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                         
                        </td>                      
                        <td style="padding-left:0px; padding-right:0px;">-</td>                      
                        <td>
                            <ngb-timepicker formControlName="horaHasta1" [spinners]="false" size="small"
                                    class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('feriado.horaHasta1')"></ngb-timepicker>
                            <small class="text-danger" 
                                *ngIf="form.get('horarioAtencion').get('feriado').get('horaHasta1').touched && 
                                       form.get('horarioAtencion').get('feriado').get('horaHasta1').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                         
                        </td>                      
                        <td></td>                      
                        <td>
                            <ngb-timepicker formControlName="horaDesde2" [spinners]="false" size="small" 
                                    class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('feriado.horaDesde2')"></ngb-timepicker>
                            <small class="text-danger" 
                                *ngIf="form.get('horarioAtencion').get('feriado').get('horaDesde2').touched && 
                                       form.get('horarioAtencion').get('feriado').get('horaDesde2').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                         
                        </td>                      
                        <td style="padding-left:0px; padding-right:0px;">-</td>                      
                        <td>
                            <ngb-timepicker formControlName="horaHasta2" [spinners]="false" size="small" 
                                    class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('feriado.horaHasta2')"></ngb-timepicker>
                            <small class="text-danger" 
                                *ngIf="form.get('horarioAtencion').get('feriado').get('horaHasta2').touched && 
                                       form.get('horarioAtencion').get('feriado').get('horaHasta2').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                         
                        </td>                      
                        <td></td>                      
                        <td>
                            <ngb-timepicker formControlName="horaDesde3" [spinners]="false" size="small" 
                                    class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('feriado.horaDesde3')"></ngb-timepicker>
                            <small class="text-danger" 
                                *ngIf="form.get('horarioAtencion').get('feriado').get('horaDesde3').touched && 
                                       form.get('horarioAtencion').get('feriado').get('horaDesde3').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                         
                        </td>                      
                        <td style="padding-left:0px; padding-right:0px;">-</td>                      
                        <td>
                            <ngb-timepicker formControlName="horaHasta3" [spinners]="false" size="small" 
                                    class="ngb-timepicker-complete" [ngClass]="getClassHorarioAtencion('feriado.horaHasta3')"></ngb-timepicker>
                            <small class="text-danger" 
                                *ngIf="form.get('horarioAtencion').get('feriado').get('horaHasta3').touched && 
                                       form.get('horarioAtencion').get('feriado').get('horaHasta3').errors?.errorRangoHorario"><span translate>mensajes.horaHastamenorDesde</span></small>                         
                        </td>                      
                        <td style="padding-top:0px; padding-bottom:0px; width:60px; white-space: nowrap;">
                            <button class="btn btn-link btn-lg" style="padding-left:0px; padding-right:0px; padding-top:0px; padding-bottom:0px;"
                                title="{{'navegabilidad.copiar' | translate}}"
                                (click)="copyHorario('feriado')"><i class="fa fa-copy mr-3 text-primary"></i></button>

                            <button class="btn btn-link btn-lg" style="padding-left:0px; padding-right:0px; padding-top:0px; padding-bottom:0px;"
                                title="{{'navegabilidad.pegar' | translate}}"
                                *ngIf="horarioCopiado"
                                (click)="pasteHorario('feriado')"><i class="fa fa-paste mr-3 text-primary"></i></button>
                        </td>
                    </tr>                      

                </tbody>    
                </table>
                

        </div> <!-- FIN card-body -->
    </div> <!-- FIN widget -->

</form>
