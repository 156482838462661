export class VentanaAtencion {    // ToDo: Hacer sub-formularios de Organización
  key:string;
  nombre:string;  /* supermercado1, supermercado2, custom */
  codigo:string;  /* 'DEF' columna AB del Excel */
  keyTipoCuenta: string;
  horarioAtencion:HorarioAtencion;

  constructor(init?:Partial<VentanaAtencion>) {
    Object.assign(this, init);
    console.log('jj-VA-consturcotor',this);
    console.log('jj-VA-consturcotor horarioAtencion',this.horarioAtencion);
    if(this.horarioAtencion){
        this.horarioAtencion= new HorarioAtencion(this.horarioAtencion);
        console.log('jj-VA-consturcotor horarioAtencionclasse',this.horarioAtencion);
    }
  }
}

export class HorarioAtencion {

    constructor(init?:Partial<HorarioAtencion>) {
        Object.assign(this, init);
        
        if(this.lunes != null) {
            this.lunes = {
                horaDesde1: new HoraMinutosSegundos(this.lunes.horaDesde1),
                horaHasta1: new HoraMinutosSegundos(this.lunes.horaHasta1),
                horaDesde2: new HoraMinutosSegundos(this.lunes.horaDesde2),
                horaHasta2: new HoraMinutosSegundos(this.lunes.horaHasta2),
                horaDesde3: new HoraMinutosSegundos(this.lunes.horaDesde3),
                horaHasta3: new HoraMinutosSegundos(this.lunes.horaHasta3)
                };
        }    
        if(this.martes != null) {
            this.martes= {
                horaDesde1: new HoraMinutosSegundos(this.martes.horaDesde1),
                horaHasta1: new HoraMinutosSegundos(this.martes.horaHasta1),
                horaDesde2: new HoraMinutosSegundos(this.martes.horaDesde2),
                horaHasta2: new HoraMinutosSegundos(this.martes.horaHasta2),
                horaDesde3: new HoraMinutosSegundos(this.martes.horaDesde3),
                horaHasta3: new HoraMinutosSegundos(this.martes.horaHasta3)
            };
        };
        if(this.miercoles != null) {    
            this.miercoles= {
                horaDesde1: new HoraMinutosSegundos(this.miercoles.horaDesde1),
                horaHasta1: new HoraMinutosSegundos(this.miercoles.horaHasta1),
                horaDesde2: new HoraMinutosSegundos(this.miercoles.horaDesde2),
                horaHasta2: new HoraMinutosSegundos(this.miercoles.horaHasta2),
                horaDesde3: new HoraMinutosSegundos(this.miercoles.horaDesde3),
                horaHasta3: new HoraMinutosSegundos(this.miercoles.horaHasta3)
            };
        };    
        if(this.jueves != null) {
            this.jueves= {
                horaDesde1: new HoraMinutosSegundos(this.jueves.horaDesde1),
                horaHasta1: new HoraMinutosSegundos(this.jueves.horaHasta1),
                horaDesde2: new HoraMinutosSegundos(this.jueves.horaDesde2),
                horaHasta2: new HoraMinutosSegundos(this.jueves.horaHasta2),
                horaDesde3: new HoraMinutosSegundos(this.jueves.horaDesde3),
                horaHasta3: new HoraMinutosSegundos(this.jueves.horaHasta3)
            };
        }
        if(this.viernes != null) {    
            this.viernes= {
                horaDesde1: new HoraMinutosSegundos(this.viernes.horaDesde1),
                horaHasta1: new HoraMinutosSegundos(this.viernes.horaHasta1),
                horaDesde2: new HoraMinutosSegundos(this.viernes.horaDesde2),
                horaHasta2: new HoraMinutosSegundos(this.viernes.horaHasta2),
                horaDesde3: new HoraMinutosSegundos(this.viernes.horaDesde3),
                horaHasta3: new HoraMinutosSegundos(this.viernes.horaHasta3)
            };
        };    
        if(this.sabado != null) {
            this.sabado= {
                horaDesde1: new HoraMinutosSegundos(this.sabado.horaDesde1),
                horaHasta1: new HoraMinutosSegundos(this.sabado.horaHasta1),
                horaDesde2: new HoraMinutosSegundos(this.sabado.horaDesde2),
                horaHasta2: new HoraMinutosSegundos(this.sabado.horaHasta2),
                horaDesde3: new HoraMinutosSegundos(this.sabado.horaDesde3),
                horaHasta3: new HoraMinutosSegundos(this.sabado.horaHasta3)
            };
        };
        if(this.domingo != null) {    
            this.domingo= {
                horaDesde1: new HoraMinutosSegundos(this.domingo.horaDesde1),
                horaHasta1: new HoraMinutosSegundos(this.domingo.horaHasta1),
                horaDesde2: new HoraMinutosSegundos(this.domingo.horaDesde2),
                horaHasta2: new HoraMinutosSegundos(this.domingo.horaHasta2),
                horaDesde3: new HoraMinutosSegundos(this.domingo.horaDesde3),
                horaHasta3: new HoraMinutosSegundos(this.domingo.horaHasta3)
            };
        };
        if(this.visperaFeriado != null) {    
            this.visperaFeriado= {
                horaDesde1: new HoraMinutosSegundos(this.visperaFeriado.horaDesde1),
                horaHasta1: new HoraMinutosSegundos(this.visperaFeriado.horaHasta1),
                horaDesde2: new HoraMinutosSegundos(this.visperaFeriado.horaDesde2),
                horaHasta2: new HoraMinutosSegundos(this.visperaFeriado.horaHasta2),
                horaDesde3: new HoraMinutosSegundos(this.visperaFeriado.horaDesde3),
                horaHasta3: new HoraMinutosSegundos(this.visperaFeriado.horaHasta3)
            };
        };
        if(this.feriado != null) {    
            this.feriado= {
                horaDesde1: new HoraMinutosSegundos(this.feriado.horaDesde1),
                horaHasta1: new HoraMinutosSegundos(this.feriado.horaHasta1),
                horaDesde2: new HoraMinutosSegundos(this.feriado.horaDesde2),
                horaHasta2: new HoraMinutosSegundos(this.feriado.horaHasta2),
                horaDesde3: new HoraMinutosSegundos(this.feriado.horaDesde3),
                horaHasta3: new HoraMinutosSegundos(this.feriado.horaHasta3)
            };
        };    

    }
    
    lunes: {
        horaDesde1:HoraMinutosSegundos;
        horaHasta1:HoraMinutosSegundos;
        horaDesde2:HoraMinutosSegundos;
        horaHasta2:HoraMinutosSegundos;
        horaDesde3:HoraMinutosSegundos;
        horaHasta3:HoraMinutosSegundos;
    };
    martes:{
        horaDesde1:HoraMinutosSegundos;
        horaHasta1:HoraMinutosSegundos;
        horaDesde2:HoraMinutosSegundos;
        horaHasta2:HoraMinutosSegundos;
        horaDesde3:HoraMinutosSegundos;
        horaHasta3:HoraMinutosSegundos;
    };
    miercoles:{
        horaDesde1:HoraMinutosSegundos;
        horaHasta1:HoraMinutosSegundos;
        horaDesde2:HoraMinutosSegundos;
        horaHasta2:HoraMinutosSegundos;
        horaDesde3:HoraMinutosSegundos;
        horaHasta3:HoraMinutosSegundos;
    };
    jueves:{
        horaDesde1:HoraMinutosSegundos;
        horaHasta1:HoraMinutosSegundos;
        horaDesde2:HoraMinutosSegundos;
        horaHasta2:HoraMinutosSegundos;
        horaDesde3:HoraMinutosSegundos;
        horaHasta3:HoraMinutosSegundos;
    };
    viernes:{
        horaDesde1:HoraMinutosSegundos;
        horaHasta1:HoraMinutosSegundos;
        horaDesde2:HoraMinutosSegundos;
        horaHasta2:HoraMinutosSegundos;
        horaDesde3:HoraMinutosSegundos;
        horaHasta3:HoraMinutosSegundos;
    };
    sabado:{
        horaDesde1:HoraMinutosSegundos;
        horaHasta1:HoraMinutosSegundos;
        horaDesde2:HoraMinutosSegundos;
        horaHasta2:HoraMinutosSegundos;
        horaDesde3:HoraMinutosSegundos;
        horaHasta3:HoraMinutosSegundos;
    };
    domingo:{
        horaDesde1:HoraMinutosSegundos;
        horaHasta1:HoraMinutosSegundos;
        horaDesde2:HoraMinutosSegundos;
        horaHasta2:HoraMinutosSegundos;
        horaDesde3:HoraMinutosSegundos;
        horaHasta3:HoraMinutosSegundos;
    };
    visperaFeriado:{
        horaDesde1:HoraMinutosSegundos;
        horaHasta1:HoraMinutosSegundos;
        horaDesde2:HoraMinutosSegundos;
        horaHasta2:HoraMinutosSegundos;
        horaDesde3:HoraMinutosSegundos;
        horaHasta3:HoraMinutosSegundos;
    };
    feriado:{
        horaDesde1:HoraMinutosSegundos;
        horaHasta1:HoraMinutosSegundos;
        horaDesde2:HoraMinutosSegundos;
        horaHasta2:HoraMinutosSegundos;
        horaDesde3:HoraMinutosSegundos;
        horaHasta3:HoraMinutosSegundos;
    }

    getJson():any{
       let rta ={
        lunes: {
            horaDesde1:this.lunes.horaDesde1.getJson(),
            horaHasta1:this.lunes.horaHasta1.getJson(),
            horaDesde2:this.lunes.horaDesde2.getJson(),
            horaHasta2:this.lunes.horaHasta2.getJson(),
            horaDesde3:this.lunes.horaDesde3.getJson(),
            horaHasta3:this.lunes.horaHasta3.getJson()
        },
        martes:{
            horaDesde1:this.martes.horaDesde1.getJson(),
            horaHasta1:this.martes.horaHasta1.getJson(),
            horaDesde2:this.martes.horaDesde2.getJson(),
            horaHasta2:this.martes.horaHasta2.getJson(),
            horaDesde3:this.martes.horaDesde3.getJson(),
            horaHasta3:this.martes.horaHasta3.getJson()
        },
        miercoles:{
            horaDesde1:this.miercoles.horaDesde1.getJson(),
            horaHasta1:this.miercoles.horaHasta1.getJson(),
            horaDesde2:this.miercoles.horaDesde2.getJson(),
            horaHasta2:this.miercoles.horaHasta2.getJson(),
            horaDesde3:this.miercoles.horaDesde3.getJson(),
            horaHasta3:this.miercoles.horaHasta3.getJson()
        },
        jueves:{
            horaDesde1:this.jueves.horaDesde1.getJson(),
            horaHasta1:this.jueves.horaHasta1.getJson(),
            horaDesde2:this.jueves.horaDesde2.getJson(),
            horaHasta2:this.jueves.horaHasta2.getJson(),
            horaDesde3:this.jueves.horaDesde3.getJson(),
            horaHasta3:this.jueves.horaHasta3.getJson()
        },
        viernes:{
            horaDesde1:this.viernes.horaDesde1.getJson(),
            horaHasta1:this.viernes.horaHasta1.getJson(),
            horaDesde2:this.viernes.horaDesde2.getJson(),
            horaHasta2:this.viernes.horaHasta2.getJson(),
            horaDesde3:this.viernes.horaDesde3.getJson(),
            horaHasta3:this.viernes.horaHasta3.getJson()
        },
        sabado:{
            horaDesde1:this.sabado.horaDesde1.getJson(),
            horaHasta1:this.sabado.horaHasta1.getJson(),
            horaDesde2:this.sabado.horaDesde2.getJson(),
            horaHasta2:this.sabado.horaHasta2.getJson(),
            horaDesde3:this.sabado.horaDesde3.getJson(),
            horaHasta3:this.sabado.horaHasta3.getJson()
        },
        domingo:{
            horaDesde1:this.domingo.horaDesde1.getJson(),
            horaHasta1:this.domingo.horaHasta1.getJson(),
            horaDesde2:this.domingo.horaDesde2.getJson(),
            horaHasta2:this.domingo.horaHasta2.getJson(),
            horaDesde3:this.domingo.horaDesde3.getJson(),
            horaHasta3:this.domingo.horaHasta3.getJson()
        }
        }

    return rta;    
    }

    // acc:tu06:00:00+02:00|tu20:00:00+02:00;
    getAccForHereSecuence(diaSemana:number,offsetFecha:string):string{
        // diaSemana  Retorna un número del 0 al 6, donde 0 es Domingo y 6 es Sábado
        // %2b representa el signo de suma (+).
        // %2d representa el signo de suma (-).
        // %7c representa la barra vertical o tubería (|).

        let signoOffset=offsetFecha.charAt(0);
        offsetFecha= signoOffset=='+'?'%2b'+offsetFecha.substring(1) :'%2d'+offsetFecha.substring(1)
        
        let acc:string='';
        // let hayVentanaPreviaInformada=false;
        // Lunes  Monday	Mo
        if(diaSemana==1 && this.lunes.horaDesde1.has_hora_minutuos() && this.lunes.horaHasta1.has_hora_minutuos() ){
 
            acc+='mo'+this.lunes.horaDesde1.getHoraMinutosSegundosAsHHMMSS()+offsetFecha+'%7cmo'+this.lunes.horaHasta1.getHoraMinutosSegundosAsHHMMSS()+offsetFecha;
        }
        
        // if(diaSemana==1 && this.lunes.horaDesde2.has_hora_minutuos() && this.lunes.horaHasta2.has_hora_minutuos()){
        //     acc+=hayVentanaPreviaInformada?'|':'';
        //     hayVentanaPreviaInformada=true;
        //     acc+='mo'+this.lunes.horaDesde2.getHoraMinutosSegundosAsHHMMSS()+offsetFecha+'|'+this.lunes.horaHasta2.getHoraMinutosSegundosAsHHMMSS()+offsetFecha;
        // }
        
        // if(diaSemana==1 && this.lunes.horaDesde3.has_hora_minutuos() && this.lunes.horaHasta3.has_hora_minutuos()){
        //     acc+=hayVentanaPreviaInformada?'|':'';
        //     hayVentanaPreviaInformada=true;
        //     acc+='mo'+this.lunes.horaDesde3.getHoraMinutosSegundosAsHHMMSS()+offsetFecha+'|'+this.lunes.horaHasta3.getHoraMinutosSegundosAsHHMMSS()+offsetFecha;
        // }
     
        // martes    Tuesday	Tu
     
        if(diaSemana==2 && this.martes.horaDesde1.has_hora_minutuos() && this.martes.horaHasta1.has_hora_minutuos()){

            acc+='tu'+this.martes.horaDesde1.getHoraMinutosSegundosAsHHMMSS()+offsetFecha+'%7ctu'+this.martes.horaHasta1.getHoraMinutosSegundosAsHHMMSS()+offsetFecha;
        }
        
        // if(diaSemana==2 && this.martes.horaDesde2.has_hora_minutuos() && this.martes.horaHasta2.has_hora_minutuos()){

        //     acc+='tu'+this.martes.horaDesde2.getHoraMinutosSegundosAsHHMMSS()+offsetFecha+'%7c'+this.martes.horaHasta2.getHoraMinutosSegundosAsHHMMSS()+offsetFecha;
        // }
        
        // if(diaSemana==2 && this.martes.horaDesde3.has_hora_minutuos() && this.martes.horaHasta3.has_hora_minutuos()){
        //     acc+=hayVentanaPreviaInformada?'|':'';
        //     hayVentanaPreviaInformada=true;
        //     acc+='tu'+this.martes.horaDesde3.getHoraMinutosSegundosAsHHMMSS()+offsetFecha+'|'+this.martes.horaHasta3.getHoraMinutosSegundosAsHHMMSS()+offsetFecha;
        // }


   
        // miercoles    Wednesday	We
        if(diaSemana==3 && this.miercoles.horaDesde1.has_hora_minutuos() && this.miercoles.horaHasta1.has_hora_minutuos()){

            acc+='we'+this.miercoles.horaDesde1.getHoraMinutosSegundosAsHHMMSS()+offsetFecha+'%7cwe'+this.miercoles.horaHasta1.getHoraMinutosSegundosAsHHMMSS()+offsetFecha;
        }

        // if(diaSemana==3 && this.miercoles.horaDesde2.has_hora_minutuos() && this.miercoles.horaHasta2.has_hora_minutuos()){
        //     acc+=hayVentanaPreviaInformada?'|':'';
        //     hayVentanaPreviaInformada=true;
        //     acc+='we'+this.miercoles.horaDesde2.getHoraMinutosSegundosAsHHMMSS()+offsetFecha+'|'+this.miercoles.horaHasta2.getHoraMinutosSegundosAsHHMMSS()+offsetFecha;
        // }

        // if(diaSemana==3 && this.miercoles.horaDesde3.has_hora_minutuos() && this.miercoles.horaHasta3.has_hora_minutuos()){
        //     acc+=hayVentanaPreviaInformada?'|':'';
        //     hayVentanaPreviaInformada=true;
        //     acc+='we'+this.miercoles.horaDesde3.getHoraMinutosSegundosAsHHMMSS()+offsetFecha+'|'+this.miercoles.horaHasta3.getHoraMinutosSegundosAsHHMMSS()+offsetFecha;
        // }

 
   
        // jueves     Thursday	Th
        if(diaSemana==4 && this.jueves.horaDesde1.has_hora_minutuos() && this.jueves.horaHasta1.has_hora_minutuos()){

            acc+='th'+this.jueves.horaDesde1.getHoraMinutosSegundosAsHHMMSS()+offsetFecha+'%7cth'+this.jueves.horaHasta1.getHoraMinutosSegundosAsHHMMSS()+offsetFecha;
        }

        // if(diaSemana==4 && this.jueves.horaDesde2.has_hora_minutuos() && this.jueves.horaHasta2.has_hora_minutuos()){
        //     acc+=hayVentanaPreviaInformada?'|':'';
        //     hayVentanaPreviaInformada=true;
        //     acc+='th'+this.jueves.horaDesde2.getHoraMinutosSegundosAsHHMMSS()+offsetFecha+'|'+this.jueves.horaHasta2.getHoraMinutosSegundosAsHHMMSS()+offsetFecha;
        // }

        // if(diaSemana==4 && this.jueves.horaDesde3.has_hora_minutuos() && this.jueves.horaHasta3.has_hora_minutuos()){
        //     acc+=hayVentanaPreviaInformada?'|':'';
        //     hayVentanaPreviaInformada=true;
        //     acc+='th'+this.jueves.horaDesde3.getHoraMinutosSegundosAsHHMMSS()+offsetFecha+'|'+this.jueves.horaHasta3.getHoraMinutosSegundosAsHHMMSS()+offsetFecha;
        // }
   

   
        // Viernes      Friday	Fr
        if(diaSemana==5 && this.viernes.horaDesde1.has_hora_minutuos() && this.viernes.horaHasta1.has_hora_minutuos()){

            acc+='fr'+this.viernes.horaDesde1.getHoraMinutosSegundosAsHHMMSS()+offsetFecha+'%7cfr'+this.viernes.horaHasta1.getHoraMinutosSegundosAsHHMMSS()+offsetFecha;
        }

        // if(diaSemana==5 && this.viernes.horaDesde2.has_hora_minutuos() && this.viernes.horaHasta2.has_hora_minutuos()){
        //     acc+=hayVentanaPreviaInformada?'|':'';
        //     hayVentanaPreviaInformada=true;
        //     acc+='fr'+this.viernes.horaDesde2.getHoraMinutosSegundosAsHHMMSS()+offsetFecha+'|'+this.viernes.horaHasta2.getHoraMinutosSegundosAsHHMMSS()+offsetFecha;
        // }

        // if(diaSemana==5 && this.viernes.horaDesde3.has_hora_minutuos() && this.viernes.horaHasta3.has_hora_minutuos()){
        //     acc+=hayVentanaPreviaInformada?'|':'';
        //     hayVentanaPreviaInformada=true;
        //     acc+='fr'+this.viernes.horaDesde3.getHoraMinutosSegundosAsHHMMSS()+offsetFecha+'|'+this.viernes.horaHasta3.getHoraMinutosSegundosAsHHMMSS()+offsetFecha;
        // }

        
        
   
        // Sabado      Saturday	Sa
        if(diaSemana==6 && this.sabado.horaDesde1.has_hora_minutuos() && this.sabado.horaHasta1.has_hora_minutuos()){

            acc+='sa'+this.sabado.horaDesde1.getHoraMinutosSegundosAsHHMMSS()+offsetFecha+'%7csa'+this.sabado.horaHasta1.getHoraMinutosSegundosAsHHMMSS()+offsetFecha;
        }

        // if(diaSemana==6 &&this.sabado.horaDesde2.has_hora_minutuos() && this.sabado.horaHasta2.has_hora_minutuos()){
        //     acc+=hayVentanaPreviaInformada?'|':'';
        //     hayVentanaPreviaInformada=true;
        //     acc+='sa'+this.sabado.horaDesde2.getHoraMinutosSegundosAsHHMMSS()+offsetFecha+'|'+this.sabado.horaHasta2.getHoraMinutosSegundosAsHHMMSS()+offsetFecha;
        // }

        // if(diaSemana==6 && this.sabado.horaDesde3.has_hora_minutuos() && this.sabado.horaHasta3.has_hora_minutuos()){
        //     acc+=hayVentanaPreviaInformada?'|':'';
        //     hayVentanaPreviaInformada=true;
        //     acc+='sa'+this.sabado.horaDesde3.getHoraMinutosSegundosAsHHMMSS()+offsetFecha+'|'+this.sabado.horaHasta3.getHoraMinutosSegundosAsHHMMSS()+offsetFecha;
        // }



        // Domingo      Sunday	Su
        if(diaSemana==0 && this.domingo.horaDesde1.has_hora_minutuos() && this.domingo.horaHasta1.has_hora_minutuos()){

            acc+='su'+this.domingo.horaDesde1.getHoraMinutosSegundosAsHHMMSS()+offsetFecha+'%7csu'+this.domingo.horaHasta1.getHoraMinutosSegundosAsHHMMSS()+offsetFecha;
        }

        // if(diaSemana==0 && this.domingo.horaDesde2.has_hora_minutuos() && this.domingo.horaHasta2.has_hora_minutuos()){
        //     acc+=hayVentanaPreviaInformada?'|':'';
        //     hayVentanaPreviaInformada=true;
        //     acc+='su'+this.domingo.horaDesde2.getHoraMinutosSegundosAsHHMMSS()+offsetFecha+'|'+this.domingo.horaHasta2.getHoraMinutosSegundosAsHHMMSS()+offsetFecha;
        // }

        // if(diaSemana==0 && this.domingo.horaDesde3.has_hora_minutuos() && this.domingo.horaHasta3.has_hora_minutuos()){
        //     acc+=hayVentanaPreviaInformada?'|':'';
        //     hayVentanaPreviaInformada=true;
        //     acc+='su'+this.domingo.horaDesde3.getHoraMinutosSegundosAsHHMMSS()+offsetFecha+'|'+this.domingo.horaHasta3.getHoraMinutosSegundosAsHHMMSS()+offsetFecha;
        // }

        // console.log("url_jj-ventanaAtencion-getACC-acc",acc);
        return acc;

    }
  }
  
  
  export class HoraMinutosSegundos {
      hora:number;
      minutos:number;
      segundos:number;

      constructor(init?:Partial<HoraMinutosSegundos>) {
        Object.assign(this, init);

        }
    
    getJson():any{

        let hora        = this.hora     ? this.hora     : null ;
        let minutos     = this.minutos  ? this.minutos  : null;
        let segundos    = this.segundos ? this.segundos : null;

        let rta = { hora:hora,  minutos: minutos, segundos:segundos};

        
        if(this.has_hora_minutuos_segundos()){
            rta = { hora:hora,  minutos: minutos, segundos: segundos};
        } else if (this.has_hora_minutuos()){
            rta = { hora:hora,  minutos: minutos, segundos:0};
        }
        
        return rta;
    }    

    has_hora_minutuos():boolean{
        return this.hora!=null && this.minutos != null;
    }    

    
    has_hora_minutuos_segundos():boolean{
        return this.hora!=null &&  this.minutos != null && this.segundos != null;
    }    
    getHoraMinutosSegundosAsHHMMSS():string{
        let rta='';
        if(this.hora !=null  && this.minutos !=null  && this.segundos !=null ){
            rta=this.hora.toString().padStart(2, '0')+':'+this.minutos.toString().padStart(2, '0')+':'+this.segundos.toString().padStart(2, '0');
        } else  if (this.hora !=null  && this.minutos !=null ){
            rta=this.hora.toString().padStart(2, '0')+':'+this.minutos.toString().padStart(2, '0')+':00';
        } 

        return rta;
      }

      getHoraMinutosAsHHMM():string{
        let rta='';
        if (this.hora !=null  && this.minutos !=null  ){
            rta=this.hora.toString().padStart(2, '0')+':'+this.minutos.toString().padStart(2, '0');;
        }

        return rta;
      }

  }
    