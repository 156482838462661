<div class="footer-linea">

  <!-- ======= Footer ======= -->
  <footer id="footer">
    <div class="container">
      <div class="copyright">
        &copy; Copyright <strong><span>2020</span></strong>. All Rights Reserved
      </div>
      <div class="credits">
          Powered by <a href="mailto: info@cpsistemas.com.ar">CP Sistemas</a>
      </div>
    </div>
  </footer><!-- End  Footer -->
  
</div>