import {OPERACIONES_BASE_DATOS } from '@maq-models/bd/bdDefinicionesGenerales.model'
import {KANE } from '@maq-models/typesKN/typesKN.model'
import { ACCION_FORM } from '@maq-helpers/actionFormHelp';
import * as firebase from 'firebase';
import { AngularFirestore  } from '@angular/fire/firestore';
import { isNumber } from '../servicios/dateformat/dateformat';

export function acomodaSettingsEnDocumento(usaSettings:Boolean, operacion:ACCION_FORM,nombreColeccion:string,usuarioKANE:KANE, documento:any, af:AngularFirestore):any{
    if(usaSettings && documento.settings===undefined) {
        documento.settings={};
      }
      
      if(operacion == ACCION_FORM.AGREGAR) {
         if(documento.key==null) documento.key=af.createId();
         
         if(documento.autoIncrement!==undefined) {
           documento.autoIncrement=null;
         }
 
         if(usaSettings){
             documento.settings.fechaHoraCreacion=timestamp();
             documento.settings.isActivo            = true;
             documento.settings.isBorrado           = false;
             documento.settings.borradoFisico       = false;
             documento.settings.sizeOfDocumento     = 0;
             documento.settings.documentosAsociados = 0;
         }    
 
      } else if(usaSettings && operacion==ACCION_FORM.MODIFICAR) {
         //  delete documento.settings.documentosAsociados;
 
      } else if(usaSettings &&  operacion==ACCION_FORM.BORRADO_LOGICO) {
          documento.settings.isBorrado=true;
          documento.settings.borradoFisico=false;
 
      } else if(usaSettings &&  operacion==ACCION_FORM.BORRADO_FISICO) {
          documento.settings.isBorrado=true;
          documento.settings.borradoFisico=true;
      }
      
      if(typeof documento.key==='number') {
          documento.key = documento.key.toString();
      }
 
      if(usaSettings ){
         documento.settings.triggersIgnorar       = false;
         documento.settings.triggersProcesarTarde = false;
         
         
         documento.settings.sizeOfDocumento = sizeOfFirestoreDocument(nombreColeccion, documento);
 
         documento.settings.usuarioKANE = usuarioKANE;
         documento.settings.fechaHoraModificacion=timestamp();
 
      }   
    return documento;
}

export function sizeOf(object:any):number{

    // initialise the list of objects and size
    var objects = [object];
    var size   :number = 0;

    // loop over the objects
    for (var index = 0; index < objects.length; index ++){

      // determine the type of the object
      // console.log("sizeOf",objects[index],"typeOf",typeof objects[index]);
      switch (typeof objects[index]){

        // the object is a boolean
        case 'boolean':
            size += 4;
            // console.log("sizeOf",objects[index],"boolean",4);
            break;

        // the object is a number
        case 'number':
            size += 8;
            // console.log("sizeOf",objects[index],"number",8);
            break;

        // the object is a string
        case 'string':
            size += 2 * objects[index].length;
            // console.log("sizeOf",objects[index],"string",2 * objects[index].length);
            break;

        // the object is a generic object
        case 'object':

          // if the object is not an array, add the sizes of the keys
          if (Object.prototype.toString.call(objects[index]) != '[object Array]'){
            for (var key in objects[index]) {
              size += 2 * key.length;
              // console.log("sizeOf",key,"key",key.length);
            }
          }

          // loop over the keys
          for (var key in objects[index]){

            // determine whether the value has already been processed
            var processed = false;
            for (var search = 0; search < objects.length; search ++){
              if (objects[search] === objects[index][key]){
                processed = true;
                break;
              }
            }

            // queue the value to be processed if appropriate
            if (!processed) objects.push(objects[index][key]);

          }

      }

    }

    // return the calculated size
    // console.error("sizeOf",size);
    return size;

  }

export function  sizeOfFirestoreDocument(pathColeccion, object){
/* Returns la cantidad de bytes que ocupa un documento de Firestore
 * Modificación de la librería standar: sizeOf.js
 *
 * Documentación Firestore
 * https://firebase.google.com/docs/firestore/storage-size
 *
 * 1) El tamaño del nombre del documento
 * 2) La suma del tamaño de string de cada nombre de campo
 * 3) La suma del tamaño de cada valor de campo
 * 4) Sumar 32 bytes adicionales
 * Tamaño = 1 + 2 + 3 + 4
 */
    if(pathColeccion==null || pathColeccion===undefined) {
      // console.error("sizeOfFirestoreDocument --> ERROR null documento!!!");
    }
    let sizeDocument=0;
    sizeDocument += pathColeccion.length;
    sizeDocument += sizeOf(object);
    sizeDocument += 32;

    // console.log("sizeOfFirestoreDocument", object, sizeDocument);

    return sizeDocument;
}


export function timestamp(): Date {
  return  firebase.firestore.Timestamp.now().toDate();
}

export function getDocField(documento:any, nameField:string):any {
  let aux=nameField.split('.');
  let rta:any=null;
  // console.log('test aux: ',aux);
  //console.log('getDocField documento: ',nameField);
  if(aux.length==1) {
      if(documento!=null &&
         documento[nameField]!==undefined) {
           
         rta  = documento[nameField];
      }
      
  } else if(aux.length==2) {
      if(documento!=null &&
         documento[ aux[0] ]!==undefined && documento[ aux[0] ]!=null &&
         documento[ aux[0] ][ aux[1] ]!==undefined) {

            rta  = documento[ aux[0] ][ aux[1] ];
      }
      
  } else if(aux.length==3) {
      if(documento!=null &&
         documento[ aux[0] ]!==undefined && documento[ aux[0] ]!=null &&
         documento[ aux[0] ][ aux[1] ]!==undefined && documento[ aux[0] ][ aux[1] ]!=null &&
         documento[ aux[0] ][ aux[1] ][ aux[2] ]!==undefined) {

            rta  = documento[ aux[0] ][ aux[1] ][ aux[2] ];
      }
      
  } else if(aux.length==4) {
      //if(nameField.includes('ubicacion')) console.log("getDocField 4", aux);
      if(documento!=null &&
         documento[ aux[0] ]!==undefined && documento[ aux[0] ]!=null &&
         documento[ aux[0] ][ aux[1] ]!==undefined && documento[ aux[0] ][ aux[1] ]!=null &&
         documento[ aux[0] ][ aux[1] ][ aux[2] ]!==undefined && documento[ aux[0] ][ aux[1] ][ aux[2] ]!=null &&
         documento[ aux[0] ][ aux[1] ][ aux[2] ][ aux[3] ] !==undefined) {

            rta  = documento[ aux[0] ][ aux[1] ][ aux[2] ][ aux[3] ];
      }
  }
  //if(nameField.includes('ubicacion')) console.log("getDocField 5",documento,nameField,rta);
  // console.log("test ",'rta',rta);
  return rta;
}

export function generateKeywords(texto:string): string[] {
      
  if(texto===undefined || texto ==null || texto=='') {
    return [];
  }

  texto = reemplazaAcentos(texto);
  // texto = this.quitaCaracteresEspeciales(texto);
  texto = texto.toLowerCase();

  let vecKeywords: string[] = [];
  let vecPalabras = getPalabras(texto);
  let cadena='';
  for(let i=0; i<vecPalabras.length; i++) {
      cadena=vecPalabras[i];
      // console.log(i,"cadena",cadena);
      for(let j=0; j<vecPalabras.length; j++) {
          if(j!=i) {
              cadena+=' '+vecPalabras[j];
          }
      }
      vecKeywords = vecKeywords.concat(createKeywords(cadena) );

  }
  let bytes=0;
  for(let i=0; i<vecKeywords.length; i++) {
      bytes+= vecKeywords[i].length;
  }
  // console.log("texto bytes",texto.length);
  // console.log("keywords bytes",bytes);
  return vecKeywords;
}

export function getPalabras(texto:string): string[] {
  let vecPalabras=[];
  texto.split(' ').forEach(palabra => {
      vecPalabras.push(palabra);
  });
  return vecPalabras;
}

export function createClonKey(keyActual:String, fechaActualString:string):string{
  let keyRespuesta:string;
  let indice:number;
  if (keyActual?.includes('-clon-')){
    indice = keyActual.indexOf('-clon-');
    keyRespuesta = keyActual.slice(0,indice) + '-clon-' + fechaActualString;
  }
  else{
    keyRespuesta = keyActual + '-clon-' + fechaActualString;
  }
  return keyRespuesta;
}

export function createClonCodigoAndName(texto:String):string{
  let respuesta:string;
  let indice:number;
  let numeroInTexto:Number = 0;
  if (texto?.includes('-c')){
    indice = texto.indexOf('-c');

    if (texto?.substring(indice + 2 , texto.length) == ''){
      numeroInTexto = 0;
    }
    else {
      let isNumberBoolean:boolean = isNumber(texto.substring(indice + 2 , texto.length));
      if(isNumberBoolean){
        numeroInTexto = parseInt(texto.substring(indice + 2 , texto.length)) + 1;
      }
    }
    respuesta = texto?.slice(0 , indice + 2) + numeroInTexto.toString();
  }
  else{
    respuesta = texto + '-c0';
  }
  return respuesta;
}

export function reemplazaAcentos(cadena:string):string {

  let aux='';
  for (var i2 = 0; i2 < cadena.length; i2++) {
      //console.log(cadena.substr(i2,1), cadena.charCodeAt(i2));
      let letra = cadena.substr(i2,1)
      let asc   = cadena.charCodeAt(i2);
      if(asc>=192 && asc<=197) { aux+='A'; }
      else if(asc>=200 && asc<=203) { aux+='E'; }
      else if(asc>=204 && asc<=207) { aux+='I'; }
      else if(asc>=210 && asc<=214) { aux+='O'; }
      else if(asc>=217 && asc<=220) { aux+='U'; }
      else if(asc>=224 && asc<=229) { aux+='a'; }
      else if(asc>=232 && asc<=235) { aux+='e'; }
      else if(asc>=236 && asc<=239) { aux+='i'; }
      else if(asc>=242 && asc<=246) { aux+='o'; }
      else if(asc>=249 && asc<=252) { aux+='u'; }
      else if(asc==209) { aux+='N'; }
      else if(asc==241) { aux+='n'; }
      else { aux+=letra; }
  }
  cadena = aux;

  cadena = cadena.replace(/á/gi, 'a');
  cadena = cadena.replace(/é/gi, 'e');
  cadena = cadena.replace(/í/gi, 'i');
  cadena = cadena.replace(/ó/gi, 'o');
  cadena = cadena.replace(/ú/gi, 'u');
  cadena = cadena.replace(/ñ/gi, 'n');

  return cadena;
}

export function createKeywords(name:string) {
  const arrName = [];
  let curName = '';
  name.split('').forEach(letter => {
     curName += letter;
     arrName.push(curName);
  });
  return arrName;
}

export function setDocField(documento:any, nameField:string, value:any):any {
  let partes=nameField.split('.');

  switch (partes.length) {
    case 1:
      documento[partes[0]]=value;
      break;
    case 2:
      if(documento[partes[0]]==null || documento[partes[0]]==undefined){
        documento[partes[0]]={};
      }
        documento[partes[0]][partes[1]]=value;
      break;	
    case 3:
      if(documento[partes[0]]==null || documento[partes[0]]==undefined){
          documento[partes[0]]={};
      }
      if(documento[partes[0]][partes[1]]==null ||documento[partes[0]][partes[1]]==undefined){
          documento[partes[0]][partes[1]]={};
      }   
      documento[partes[0]][partes[1]][partes[2]]=value;
      break;	
    
    
    default:
        // code...
      break;
  }
  //console.log("setDocField",nameField,value,documento);
  return documento;
}

export function ordenarJSON(docuento: object, ordenClaves: string[]): object {
  let orderedObj: object = {};
  ordenClaves.forEach((key) => {
    if (docuento.hasOwnProperty(key)) {
      orderedObj[key] = docuento[key];
    }else{
      console.error('ordenarJSON no existe la propiedad con la key ', key);
    }
  });
  return orderedObj;
}

// Verifica si un objeto está vacío
export function objetoEstaVacio(obj: any): boolean {
  return Object.keys(obj).length === 0;
}

