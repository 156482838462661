import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'lib-shared-flags-menu',
  templateUrl: './flags-menu.component.html',
  styleUrls: ['./flags-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FlagsMenuComponent implements OnInit {
   languageList = [ // <--- add this
    { code: 'en', label: 'English' },
    { code: 'ru', label: 'Русский' },
    { code: 'es', label: 'Spanish' },
    { code: 'br', label: 'Portugues' }
  ];	
  claseIconoHeader="flag-icon flag-icon-es";
  public traduccion:any={};

  constructor(  private translate: TranslateService
) { }

  ngOnInit() {

    // console.log('jjIdioma cambio ', this.translate.getLangs());
    this.cambiarLenguaje(this.translate.getLangs()[0]);
    this.inicializarVariablesTraducibles();
    this.translate.onLangChange.subscribe((data) => {
    //  console.log('jjIdioma cambio ', data);
     this.cambiarLenguaje(data.lang);
     this.inicializarVariablesTraducibles();
     });
  }
 
 public cambiarLenguaje(lang) {
   
    // console.log('lang',lang);
    this.translate.use(lang);

    // console.log('this.translate',this.translate);
    // console.log('this.translate current',this.translate.store.currentLang);


    switch (lang) {
      case "es":
        this.claseIconoHeader="flag-icon flag-icon-es";
        break;
      case "pt":
        this.claseIconoHeader="flag-icon flag-icon-br";
        break;
      case "en":
        this.claseIconoHeader="flag-icon flag-icon-gb";
        break;  

      default:
          this.claseIconoHeader="flag-icon flag-icon-es";
        break;
    }

     // this.translate.setDefaultLang(lang);
  }

  inicializarVariablesTraducibles(){
        
    this.traduccion['idioma']={
      espanol     : this.translate.instant('idioma.espanol'),
      portugues   : this.translate.instant('idioma.portugues'),
      ingles      : this.translate.instant('idioma.ingles'),
           
       
    }
    // console.log('cambio idioma en libreria B, variable Traducibles', this.traduccion);
}      
}
