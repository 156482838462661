import { log, logIf, logTable, values } from '@maq-console';

import { Component, EventEmitter, OnInit, ViewChild, Output, Input, ViewEncapsulation } from '@angular/core';
import { FormControl } from "@angular/forms";
import { ChangeDetectorRef } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { FuncionesService }  from '@maq-funciones';

// Resize de Imágenes
import { NgxPicaService } from 'ngx-pica';  
import * as EXIF from 'exif-js';
export interface AspectRatioOptions {
    keepAspectRatio: boolean;
    forceDimensions?: boolean;
}
export interface NgxPicaResizeOptionsInterface {
    aspectRatio?: AspectRatioOptions;
    quality?: number;
    alpha?: boolean;
    unsharpAmount?: number;
    unsharpRadius?: number;
    unsharpThreshold?: number;
}
export enum NgxPicaErrorType {
    NO_FILES_RECEIVED = 'NO_FILES_RECEIVED',
    CANVAS_CONTEXT_IDENTIFIER_NOT_SUPPORTED = 'CANVAS_CONTEXT_IDENTIFIER_NOT_SUPPORTED',
    NOT_BE_ABLE_TO_COMPRESS_ENOUGH = 'NOT_BE_ABLE_TO_COMPRESS_ENOUGH'
}

export interface NgxPicaErrorInterface {
    err: NgxPicaErrorType;
    file?: File;
}

@Component({
  selector: 'lib-shared-input-file',
  templateUrl: './input-file.component.html',
  styleUrls: [ './input-file.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class InputFileComponent implements OnInit {
    @Input() public archivoField: string; 
    @Input() public archivo: any; 
    @Input() public accionForm: string; 
    @Input() public thumbWidth: number; 

    @Output() cargoArchivo= new EventEmitter<any[]>();
    @Output() borroArchivo= new EventEmitter<string>();
    @Output() desabilitarGrabar= new EventEmitter<boolean>();

    @ViewChild("modalContent") modalContent: any;

    public nombreArchivo:string=null;
    public extensionArchivo:string=null;
    public extensionIcono:string=null;
    public archivoAnterior:string=null;
    
    // ang-music-player
    public audioList:any[]=[];

    constructor(public fn:FuncionesService,
                private translate: TranslateService,
                private _ngxPicaService: NgxPicaService) { 

    }
    
    ngDoCheck() {      
        if(this.archivo!=this.archivoAnterior) {
          
          log(...values("valores", "input-image - ngDocheck - archivoField:",this.archivoField));
          log(...values("valores", "input-image - ngDocheck - archivo:",this.archivo));        
            
          this.archivoAnterior=this.archivo;

          this.nombreArchivo = this.fn.getFileNameSinVars(this.archivo);
          this.extensionArchivo = this.fn.getExtensionFile( this.nombreArchivo );
          this.extensionIcono = 'assets/iconos/extensiones-archivos64x64/'+this.extensionArchivo+'.png';
          
          this.setAudioList();
        }  
    }

    ngOnInit() {

        // log(...values("valores", "input-file - archivoField:",this.archivoField));
        // log(...values("valores", "input-file - archivo:",this.archivo));

        this.nombreArchivo = this.fn.getFileNameSinVars(this.archivo);
        this.extensionArchivo = this.fn.getExtensionFile( this.nombreArchivo );
        this.extensionIcono = 'assets/iconos/extensiones-archivos64x64/'+this.extensionArchivo+'.png';
        
        // log(...values("valores","input-file - archivo:",this.archivo));
        // log(...values("valores","input-file - nombreArchivo:",this.nombreArchivo));
        // log(...values("valores","input-file - extensionArchivo:",this.extensionArchivo));

        if(this.thumbWidth===undefined) {
            this.thumbWidth=150;
        }     
        
        this.setAudioList();
    }  

    inputBorrar(): void {
      this.archivo          = null;
      this.nombreArchivo    = null;
      this.extensionArchivo = null;
      this.extensionIcono   = null;
      this.borroArchivo.emit(this.archivoField);
    }  

    inputChange(input: any): void {

      // console.clear();

      // log(...values('funcionComponente',`inputChange(${this.archivoField})`));

      if(input.files.length==0) return;  // Presiono Cancelar en la ventana de Selección de Archivos

      const file = input.files[0];
      const typeFile = input.files[0].type;
      // log(...values("valores","input:",input));
      // log(...values("valores","typeFile:",typeFile));

      this.nombreArchivo = this.fn.getFileNameFromArchivo(file);
      this.extensionArchivo = this.fn.getExtensionFile( this.nombreArchivo );
      this.extensionIcono = 'assets/iconos/extensiones-archivos64x64/'+this.extensionArchivo+'.png';

    //   log(...values("valores","input-file - archivo:",this.archivo));
    //   log(...values("valores","input-file - nombreArchivo:",this.nombreArchivo));
    //   log(...values("valores","input-file - extensionArchivo:",this.extensionArchivo));
    
      this.desabilitarGrabar.emit(true);

      var reader = new FileReader(); 
      reader.readAsDataURL(file);           

      reader.onload = async() => {

            let archivoReader = reader.result;

            // log(...values("imagen","archivoReader:",archivoReader));

            this.archivo = archivoReader;

            if(this.fn.isTypeImagen(typeFile)==false) {   // No es Imagen
                this.desabilitarGrabar.emit(false);
                this.cargoArchivo.emit([
                    this.archivoField,
                    this.nombreArchivo,
                    this.extensionArchivo,
                    this.archivo,
                    null]);

                // Seteo Audios
                this.setAudioList();
                    
            } else {                                // Es una Imagen

                  let imageReader = archivoReader;

                  // Obtengo Medidas de la Imagen
                  let imgMedidas:any;
                  imgMedidas = await this.fn.getIMGMedidas( imageReader as string );
                  let width     = imgMedidas.width;
                  let height    = imgMedidas.height;
                  let newWidth  = this.thumbWidth;
                  let newHeight = Math.round( height/width * newWidth );
                  // log(...values("valores","imgMedidas width:",width, "height:", height));
                  // log(...values("valores","imgMedidas newWidth:",newWidth, "newHeight:", newHeight));

                  this._ngxPicaService.resizeImages( [file], newWidth, newHeight)
                  .subscribe((fileResized: File) => {
                          let reader: FileReader = new FileReader();
                          
                          reader.addEventListener('load', async(event: any) => {

                              let imageResized = event.target.result;
                              // log(...values("valores","event.target.result:",event.target.result));
                              // log(...values("imagen","imageResized:",imageResized));
                              this.desabilitarGrabar.emit(false);
                              this.cargoArchivo.emit([
                                this.archivoField,
                                this.nombreArchivo,
                                this.extensionArchivo,                                
                                this.archivo,
                                imageResized]);
                                
                          }, false);
                          
                          reader.readAsDataURL(fileResized);

                          // reader.readAsBinaryString(Blob|File)        -> result blob como cadena binaria
                          // reader.readAsText(Blob|File, opt_encoding)  -> result blob como cadena de texto
                          // reader.readAsDataURL(Blob|File)             -> result URL de datos
                          // reader.readAsArrayBuffer(Blob|File)         -> result objeto ArrayBuffer 
                          
                  }, (error: NgxPicaErrorInterface) => {
                      log(...values("error","Error al generarar Thumb de "+ this.archivoField, error));
                      this.desabilitarGrabar.emit(false);
                      throw error.err;
                  });
            }      

      }    

    }
    
    setAudioList() {
        console.log("setAudioList");
        
        this.audioList = [];
        
        if(this.extensionArchivo=='mp3') {
        //if(this.archivo && this.archivo.includes('.mp3')) {
            this.audioList = [{
              url: this.archivo,
              title: 'Audio',
              cover: null
            }]        
        }
    
        console.log("this.audioList",this.audioList);
        
      }
        

}  
  
