import { log, logIf, logTable, values } from '@maq-console';

import { Injectable } from '@angular/core';
import { Usuario}          from '@maq-models/usuarios/usuarios.model';

import { FuncionesService }  from '@maq-funciones';
import { ApisService }       from '@maq-apis';

import { AngularFirestore,  Query }                 from '@angular/fire/firestore';
import { BDService }                                from '@maq-bd/bd.service';

import { MensajesService }                          from '@maq-servicios/mensajes/mensajes.service';
import { KN, KANE, KNAI }                           from '@maq-models/typesKN/typesKN.model';
import { RutasActivasDiarias, DiaExcesoLicencias }  from '@maq-models/licencias/licencias.model';

@Injectable({
  providedIn: 'root'
})

export class LicenciasService {

   // Licencias
   public subscRutasActivasDiarias            = null;
   public subsDiaExcesoLicencias              = null;

   
   public usuario              : Usuario;
   public usuarioKANE          : KANE=null;
   public organizacionKNAI     : KNAI=null;
   public distribuidorKN       : KN=null;



   public OrganizacionKey               : string;

   public cantidadExcesosMes            : number=0;
   private rutasActivasDiarias          : RutasActivasDiarias[]=[];
   private diaExcesoLicencias           : DiaExcesoLicencias[]=[];
   private cantidadLicenciasOrganizacion: number=0;
   private porcentajeExcesoPermitido    : number=0;

   
  constructor(public fn           : FuncionesService,
              public apis         : ApisService,
              public msg          : MensajesService,
              public bdService    : BDService,
              private af          : AngularFirestore) { 

                this.msg.getPerfil().subscribe(usuario=>{
                  
                  console.log('licenciasService, usuario',usuario);
                  console.log('licenciasService, usuario',usuario.organizacion.esquemaComercial);

                  this.usuario=usuario;
                  this.usuarioKANE              = this.usuario.getKANE();
                  this.organizacionKNAI         = this.usuario.getOrganizacionKNAI();
                  this.distribuidorKN           = this.usuario.getDistribuidorKN();


                  if(this.usuario && this.usuario.organizacion){
                    this.OrganizacionKey=this.usuario.getOrganizacionKey();
                    
                    this.cantidadLicenciasOrganizacion=this.usuario.getCantidadLicencias();
                    this.porcentajeExcesoPermitido=this.usuario.getPorcentajeExcesoPermitido();
                    this.geRutasActivasDiarias();
                    this.getExcesosMensuales();
                  }
                });

      
  }
  
 
 
  geRutasActivasDiarias(){

              // cantidadRutasActivas    : number;
              // fecha                   : string;
              // key                     : string;
              // organizacionKNAI        : KNAI;
      let fechaActual_AAAAMMDD=this.fn.getFechaActual('AAAAMMDD');
      this.subscRutasActivasDiarias=this.af.collection<RutasActivasDiarias>('RutasActivasDiarias',
      ref => ref.where  ('organizacionKNAI.key'  , '==', this.organizacionKNAI.key)
                .orderBy('fecha', 'asc')
                .startAfter(fechaActual_AAAAMMDD)
      ).valueChanges({ idField: 'id' })
      .subscribe((data:RutasActivasDiarias[])=>{
          console.log('licenciasService subscRutasActivasDiarias',data);
          this.rutasActivasDiarias=data;
          console.log(' licenciasService subscRutasActivasDiarias this.usuario',this.usuario);
          
      });

  }


  seSuperoLimiteLicenciaPermitidas(fechaConsulta:Date):boolean{

    console.log('licenciasService seSuperoLimiteLicenciaPermitidas',fechaConsulta);

    let fecha_AAAAMMDD                    : string=this.fn.formatDateToString(fechaConsulta,'AAAAMMDD');
    console.log('licenciasService fecha_AAAAMMDD',fecha_AAAAMMDD);
    console.log('licenciasService if',this.usuario );
    console.log('licenciasService if',this.usuario.organizacion );
    console.log('licenciasService if',this.usuario.organizacion.esquemaComercial);
    let  superoLimiteLicenciaPermitidas   : boolean=false;

    if(this.usuario && this.usuario.organizacion && this.usuario.organizacion.esquemaComercial){
      console.log('licenciasService cantidad licencias excedidas',this.cantidadLicenciasOrganizacion*(1+this.porcentajeExcesoPermitido/100));
      
   

      let rutaActivaDiariaFechaSeleccionada:RutasActivasDiarias=this.rutasActivasDiarias.find((rutaActDia:RutasActivasDiarias)=>rutaActDia.fecha==fecha_AAAAMMDD)
      let cantidadRutasActivas=rutaActivaDiariaFechaSeleccionada ? rutaActivaDiariaFechaSeleccionada.cantidadRutasActivas:0;

      console.log('licenciasService cantidad licencias cantidadRutasActivas',cantidadRutasActivas);

      if(cantidadRutasActivas>this.cantidadLicenciasOrganizacion*(1+this.porcentajeExcesoPermitido/100)){
        console.log('licenciasService cantidad licencias excedidas');
        
        superoLimiteLicenciaPermitidas=true;
        
        
        let mes=this.fn.formatDateToString(fechaConsulta,'AAAAMMDD');
        let doc:DiaExcesoLicencias= {
            key                     : this.OrganizacionKey+fecha_AAAAMMDD,
            cantidadRutasActivas    : cantidadRutasActivas,
            cantidadRutasExcedidas  : cantidadRutasActivas-this.cantidadLicenciasOrganizacion,
            fecha                   : fecha_AAAAMMDD,
            organizacionKNAI        : this.organizacionKNAI
            };


        this.bdService.updateColeccion({
            operacion        : 'modificar',
            nombreColeccion  : 'DiaExcesoLicencias',
            documento        : doc,
            distribuidorKN   : this.distribuidorKN,
            organizacionKNAI : this.organizacionKNAI,                           
            usuarioKANE      : this.usuarioKANE
        })
      }
    }
    console.log('licenciasService return',superoLimiteLicenciaPermitidas);
    return superoLimiteLicenciaPermitidas;
  }

  seSuperoLimiteLicenciaPermitidasDuranteElMes():boolean{

    

    let fechaHoy                          : Date = new Date();
    let fecha_AAAAMM                      : string=this.fn.formatDateToString(fechaHoy,'AAAAMM');
    let superoLimiteLicenciaPermitidas    : boolean=false;

    if(this.usuario && this.usuario.organizacion && this.usuario.organizacion.esquemaComercial){
      console.log('licenciasService cantidad licencias excedidas',this.cantidadLicenciasOrganizacion*(1+this.porcentajeExcesoPermitido/100));
   
      let rutasActivaDiariaFechaSeleccionada:RutasActivasDiarias[]=
            this.rutasActivasDiarias.filter((rutaActDia:RutasActivasDiarias)=>
              rutaActDia.fecha.substring(0,6)==fecha_AAAAMM && rutaActDia.cantidadRutasActivas>this.cantidadLicenciasOrganizacion);
      

      if(rutasActivaDiariaFechaSeleccionada.length>0){
        console.log('licenciasService cantidad licencias excedidas');
  
        superoLimiteLicenciaPermitidas=true;
      }
    }
    return superoLimiteLicenciaPermitidas;
  }


  getExcesosMensuales(){

    let fechaActual_AAAAMMDD=this.fn.getFechaActual('AAAAMMDD');


    this.subscRutasActivasDiarias=this.af.collection<DiaExcesoLicencias>('RutasActivasDiarias',
      ref => ref.where  ('organizacionKNAI.key', '==', this.organizacionKNAI.key)
                .orderBy('fecha', 'asc')
                .startAfter(fechaActual_AAAAMMDD)
      ).valueChanges({ idField: 'id' })
      .subscribe((data:DiaExcesoLicencias[])=>{

        console.log('licenciasService subsDiaExcesoLicencias',data);
        this.diaExcesoLicencias=data;
     

      });

  }

  bloqueadoPorLicencias(fechaConsulta:Date):boolean{

    let fecha_consultaAAAAMMDD         : string=this.fn.formatDateToString(fechaConsulta,'AAAAMMDD');
    let fecha_consultaAAAAMM           : string=fecha_consultaAAAAMMDD.substring(0,6);
    let  bloqueadoPorLicencias : boolean=false;

    if(this.usuario && this.usuario.organizacion && this.usuario.organizacion.esquemaComercial){

      let diasExcesoEnMesConsulta:DiaExcesoLicencias[]=this.diaExcesoLicencias.filter((diaExcLic:DiaExcesoLicencias)=>diaExcLic.fecha.substring(0,6)==fecha_consultaAAAAMM);
     
      this.cantidadExcesosMes             = diasExcesoEnMesConsulta.length;
      let cantidadVecesExcesoPermitido    = this.usuario.organizacion.esquemaComercial.cantidadVecesExcesoPermitido;
      let licenciasConBloqueo             = this.usuario.organizacion.esquemaComercial.licenciasConBloqueo;
      

      if(licenciasConBloqueo && this.cantidadExcesosMes>cantidadVecesExcesoPermitido){
        bloqueadoPorLicencias=true;
      }
    }

    return bloqueadoPorLicencias;
  }
}
