import { KN, KANE, KNAI } from '../typesKN/typesKN.model';

// definiciones de validaciones del excel
export class TransferenciaArchivos {

    public nombreCampoDocumento: string; 
    public nombreColumnaExcel: string;
    public tipoValidacion: string;          // Obligatorio, Mínima cant caracteres, email válido, estaEnArray
    public datos:any[];			            // Aqui vienen los datos para comprar	
    public datosClaveBusqueda: string;      // Se usa para buscar dentro de la estructura datos (anterior)
    public claveUnica: boolean;             // Admite que este campo se repita (false) o es clave única (true) 
    public mensajeError: string;            // Mensaje que se entrega cuando no se valida correctamente.

    constructor(init?:Partial<TransferenciaArchivos>) {
    
        Object.assign(this, init);
        
    }
}
// datos de docomentos que afecta, coleeciones y nombres de campos a verificar
export class TransferenciaBaseDatos {
 
 public nombreColeccion:                    string;         //Nombre de la colección dende Grabo Datos
 public nombreClaseColeccion:               string;         //Nombre de la clase que genera Documentos de esta colección 
 public camposaVerificarEnBase:             string[]=[];    //Lista de campos a verificar si exisen en la base (van de a pares con el siguiente) 
 public camposClaveExcelParaVerificarEnBase:string[]=[];    //Valores del excel para verificar en los campos de la base definidos en el anterior
 public citerioParaAceptarUnCampoExistente: string[]=[];    //Criterios que se utilizaran para aceptar cuando se encuentre que un un dato a verificar en la base Existe
 public distribuidorKN:                     KN=null;   
 public organizacionKNAI:                   KNAI=null;   
 public usuarioKANE:                        KANE=null;
 
 constructor(init?:Partial<TransferenciaBaseDatos>) {
    
    Object.assign(this, init);
    
 }
   
}

export const CRITERIOS_DE_ACEPTACION:any[]=['merge','todosIguales']; // merge: Da un warnig pero permite el grabar
                                                                     // todosIgulaes: permite grabar si todos los campos a verificar son iguales.   



/********************************************************************************************************/ 
/********************************************************************************************************/ 
/****************************************Tipos de Validacion*********************************************/ 
/********************************************************************************************************/ 
/********************************************************************************************************/
/*


	obligatorio 				-> not null
	minimaCantidadCaracteres 	-> en el primer lugar de datos pones la cantidad Ej. [6] 
	email 						-> verifica que el campo sea un email
	estaEnArray					-> Verifica que el valor de la columna del excell sea igual que el valor del la clave del array
								buscando con la datosClaveBusqueda.



*/ 

 