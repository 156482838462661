<form [formGroup]="form" (change)="onChangeField()">

    <div widget class="card border-0 box-shadow" style="margin-top:20px;">
        <div class="card-header transparent border-0 text-muted">
            <h5 class="mb-0">{{traduccion.claseTabs.settings}}</h5>
            <div class="widget-controls">
                <a data-widgster="expand" href="#" class="transition"><i class="fa fa-chevron-down"></i></a>
                <a data-widgster="collapse" href="#" class="transition"><i class="fa fa-chevron-up"></i></a>
            </div>
        </div>
        <div class="card-body pt-0 widget-body" formGroupName="settings">

            <div class="row" style="margin-top:10px;">
                <div class="col-6">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input checkbox-dark-gray"
                               id="checkboxActive{{dif}}" formControlName="isActivo">
                        <label class="custom-control-label" for="checkboxActive{{dif}}">{{traduccion.claseSettings.activo}}</label>
                    </div>
                </div>
                <div class="col-6">
                    <!--[value]="form.value.isBorrado"   -->
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input checkbox-dark-gray"
                               id="checkboxBorrado{{dif}}" formControlName="isBorrado"
                        (click)="habilitadoParaRevertirBorrado()">
                        <label class="custom-control-label" for="checkboxBorrado{{dif}}">{{traduccion.claseSettings.borrado}}</label>
                    </div>
                </div>
            </div>

            <div class="row" style="margin-top:20px;">
                <div class="col-6">            
                        <div class="form-group">
                            <label class="text-gray">{{traduccion.claseSettings.fechaCreacion}}</label>
                            <input id="fechaHoraCreacion" class="form-control" type="text"
                            value="{{form.value.settings.fechaHoraCreacion | fechaHora:translate.store.currentLang}}" readonly>
                        </div>
                </div>        
                <div class="col-6">                        
                        <div class="form-group">
                            <label class="text-gray">{{traduccion.claseSettings.fechaModificacion}}</label>
                            <input id="fechaHoraModificacion" class="form-control" type="text"
                            value="{{form.value.settings.fechaHoraModificacion | fechaHora:translate.store.currentLang}}" readonly>
                        </div>
                </div>
            </div>            

            <div class="form-group">
                <label class="text-gray">{{traduccion.claseSettings.usuarioUltimaOperacion}}</label>
                <input [value]="fn.mostrarKANE(form.value.settings.usuarioKANE,'apellidoNombre')" class="form-control" type="text" readonly>
            </div>
            
            <div class="row" style="margin-top:10px;">
                <div class="col-6">
                    <div class="form-group">
                        <label class="text-gray">{{traduccion.claseSettings.documentosAsociados}}</label>
                        <input formControlName="documentosAsociados" class="form-control" type="text" readonly>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label class="text-gray">{{traduccion.claseSettings.sizeOfDocumento}}</label>
                        <div class="input-group">
                            <input value="{{convierteToKB(form.value.settings.sizeOfDocumento)}}" class="form-control" type="text" readonly>
                            <div class="input-group-append">
                                <span class="input-group-text">KB</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div> <!-- FIN card-body -->
    </div> <!-- FIN widget -->

</form>
