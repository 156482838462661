import { Menu } from '@maq-models/menu/menu.model';
import { Component, OnInit, OnDestroy, ViewEncapsulation, ElementRef, Input } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AppSettingsService } from '@settings/app.settings';

import { TranslateService } from '@ngx-translate/core';
import { MensajesService }  from '@maq-servicios/mensajes/mensajes.service';

import { MenuService } from '@maq-servicios/menu/menu.service';
import { MenuColeccionService } from '@maq-servicios/menu/menu-coleccion.service'; 

declare let $: any;
declare let jQuery: any;

@Component({
  selector: 'lib-shared-vertical-menu',
  templateUrl: './vertical-menu.component.html',
  styleUrls: ['./vertical-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ MenuService, MenuColeccionService ]
})
export class VerticalMenuComponent implements OnInit {


  public menuItems : Menu[]=[];

  public refMenuItemsSubjectObservable : any;

  constructor(public appSettings:AppSettingsService, 
              private menuService:MenuService, 
              private menuColeccionService:MenuColeccionService, 
              private router: Router, 
              private elementRef:ElementRef,
              private translate: TranslateService,
              private msg:MensajesService) {
  }
 
  ngOnInit() {     


      // console.log('this.menuItems',this.menuItems);

      this.router.events.subscribe(event => {
         // console.log('this.menuItems',this.menuItems);
          if (event instanceof NavigationEnd) {
              window.scrollTo(0, 0);

              let activeLink = this.menuService.getActiveLink(this.menuItems);
              this.menuService.setActiveLink(this.menuItems, activeLink);
              jQuery('.tooltip').tooltip({
                sanitize: false,
                sanitizeFn: function (content) {
                  return null;
                }
              }); 
              jQuery('.tooltip').tooltip('hide');
              if(window.innerWidth <= 768){
                this.appSettings.settings2.panel.showMenu = false; 
              }             
          }                
      }); 


      this.translate.onLangChange.subscribe(() => {
          // console.log('langChange');

          if(this.menuItems===undefined) return;

          this.menuItems.forEach((menuItem) => {

              let obj = document.getElementById('menuTranslateVertical'+menuItem.id);
              if(obj) obj.innerHTML = this.translate.instant(menuItem.title);
          });
      });

      this.refMenuItemsSubjectObservable = this.menuColeccionService.menuItemsSubjectObservable.subscribe(listaDeMenuesPermitidos=>{
            this.menuItems = listaDeMenuesPermitidos;
            this.menuService.generarMenu(this.elementRef.nativeElement.children[0],'vertical',this.menuItems);
            this.menuColeccionService.desubscribeMenuService();
      });

      if(this.appSettings.settings2.panel.menuType == 'mini'){
        jQuery('.menu-item-link').tooltip({
          sanitize: false,
          sanitizeFn: function (content) {
            return null;
          }
        });
        jQuery('.menu-item-link').tooltip();
      }
      
  }

  /*
  ngOnDestroy(){
    this.refMenuItemsSubjectObservable.unsubscribe();    
  }
  */


  ngAfterViewInit(){
    console.log('ngAfterViewInit');
    // this.menuService.showActiveSubMenu(this.menuItems);
    // let activeLink = this.menuService.getActiveLink(this.menuItems);
    // this.menuService.setActiveLink(this.menuItems, activeLink); 
  } 

}
