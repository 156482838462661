import { Pipe, PipeTransform } from '@angular/core';


@Pipe({ name: 'DistribuidorSearchPipe', pure: false })
export class DistribuidorSearchPipe implements PipeTransform {
  transform(value, args?): Array<any> {
    let searchText = new RegExp(args, 'ig');
    if (value) {
      return value.filter(distribuidor => {
        if (distribuidor.nombre) {
          return distribuidor.nombre.search(searchText) !== -1;
        }
        else{
          return distribuidor.nombre.search(searchText) !== -1;
        }
      });
    }
  }
}
