import { log, logIf, logTable, values } from '@maq-console';

import { Component, EventEmitter, OnInit, AfterViewInit, OnDestroy, DoCheck, ViewChild, Output, Input, ViewEncapsulation } from '@angular/core';
import { FormControl } from "@angular/forms";
import { ChangeDetectorRef } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { FuncionesService } from '@maq-funciones';
import { BDService }        from '@maq-bd/bd.service';

import { KN, KANE, KNAI } from '@maq-models/typesKN/typesKN.model';

declare let $: any;
declare let jQuery: any;

// Firebase

import { AngularFirestore  } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { Query, QueryFn } from '@angular/fire/firestore';

// ngx-mat-select-search
import { VERSION } from '@angular/material/esm2015';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { debounceTime, delay, tap, filter, map, take, first, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'lib-shared-search-autocomplete',
  templateUrl: './search-autocomplete.component.html',
  styleUrls: [ './search-autocomplete.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class SearchAutocompleteComponent implements OnInit, OnDestroy, AfterViewInit, DoCheck {
    @Input() public value: any; 
    @Input() public nameField: string; 
    @Input() public nombreColeccion: string; 
    @Input() public inputStyle: any;     
    @Input() public placeholder: string; 
    @Input() public whereArray: any[]; 
    @Input() public orderByArray: any[]; 
    @Input() public bdFieldName: string; 
    @Input() public organizacionKNAI: KNAI; 
    @Input() public usuarioKANE: KANE; 
    @Input() public tipoPerfilUsuario:string;
    @Input() public accionForm: string;

    @Output() cargoSearchAutocomplete= new EventEmitter<any[]>();

    @ViewChild("modalContent") modalContent: any;

    public traduccion:any={};
    public documentoSeleccionado:any=null;
    public searchText:    FormControl = new FormControl();    
    public onKeyUpSearch: FormControl = new FormControl();
    public searching: boolean = false;
    public listadoResultadosBD: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    protected _onDestroy = new Subject<void>();
    public inputColorStyle:any={};
    public valueAnterior:any=null;
    
    public seleccionoValor=false;
    public disabledSearch:boolean=false;

    constructor(public fn:FuncionesService,
                public bdService: BDService,
                public translate: TranslateService,
                public af: AngularFirestore) {                     
    }
    
    ngOnInit() {
      
        // //log(...values("valores", "search-autocompleteinput - nombreColeccion:",this.nombreColeccion));
        // //log(...values("valores", "search-autocompleteinput - nameField:",this.nameField));
        ////log(...values("valores", "search-autocompleteinput - value:",this.value));
        // //log(...values("valores", "search-autocompleteinput - inputStyle:",this.inputStyle));
        // //log(...values("valores", "search-autocompleteinput - placeholder:",this.placeholder));
        // //log(...values("valores", "search-autocompleteinput - whereArray:",this.whereArray));
        // //log(...values("valores", "search-autocompleteinput - orderArray:",this.orderByArray));
        // //log(...values("valores", "search-autocompleteinput - bdFieldName:",this.bdFieldName));
        // //log(...values("valores", "search-autocompleteinput - organizacionKNAI:",this.organizacionKNAI));
        // //log(...values("valores", "search-autocompleteinput - usuarioKANE:",this.usuarioKANE));
        // //log(...values("valores", "search-autocompleteinput - tipoPerfilUsuario:",this.tipoPerfilUsuario));
        
        this.inputColorStyle = this.styleIncludeRed(this.inputStyle) ? { color:'red' } : {};

        this.documentoSeleccionado={};
        if(this.value && this.value.nombre !==undefined) {
            this.documentoSeleccionado[this.nameField]=this.value.nombre;
        } else if(this.value && this.value.apellidoNombre !==undefined) {
            this.documentoSeleccionado[this.nameField]=this.value.apellidoNombre;
        } else if(this.value) {
            this.documentoSeleccionado[this.nameField]=this.value;
        } else {  
            this.documentoSeleccionado[this.nameField]=this.placeholder;
        }  
        
        this.valueAnterior=this.value;
      
        this.inicializarVariablesTraducibles();
           this.translate.onLangChange.subscribe(() => {
            this.inicializarVariablesTraducibles();
        });
        
    }
  
    inicializarVariablesTraducibles(){        
      this.traduccion['multiSelectTexts']={
          noEncontroResultados: this.translate.instant('multiSelectTexts.noEncontroResultados'),
          typeText: this.translate.instant('multiSelectTexts.typeText'),
      }
      
    }  
    
    traducirSeleccionado(value) {
        // console.log('autocompleteValue',value);
        if(value!==undefined && value!=null && Object.keys(value).length !== 0) {
            return this.translate.instant(value);      
        } else {
            return null;
        }     
    }
    
    styleIncludeRed(json:any) {
        if(json===undefined || json==null) {
            return false;
        }
        for(let propiedad in json) {
            if(propiedad=='color' && json[propiedad]=='red') {
                return true;
            }
        }
        return false;
    }
    
    ngDoCheck() {
        //console.log("ngDoCheck ",this.seleccionoValor,this.value);
        
        if(this.accionForm=='consultar') {
            this.disabledSearch=true;
        } else {
            this.disabledSearch=false;
        }
        
        this.inputColorStyle = this.styleIncludeRed(this.inputStyle) ? { color:'red' } : {};
        
        if(this.seleccionoValor && this.value==null)   {
            this.documentoSeleccionado={};
            this.documentoSeleccionado[this.nameField]=this.placeholder;
            this.seleccionoValor=false;
        }
        
        if(this.valueAnterior!=this.value && this.value==null) {
            this.documentoSeleccionado={};
            this.documentoSeleccionado[this.nameField]=this.placeholder;
            this.seleccionoValor=false;
            this.valueAnterior=null;
        }
    }
    
    ngAfterViewInit() {
        // console.log("ngAfterViewInit");

        // listen tipeo una letra en el search
        this.onKeyUpSearch.valueChanges
          .pipe(
                filter(search => !!search),
                tap(() => this.searching = true),
                takeUntil(this._onDestroy),
                debounceTime(200),
                map(search => {
                   this.busquedaFirestore(search);
                })
        ).subscribe();

        // Detecta el Escape
        $(document).keyup((e:any)=> {
            //console.log( "keyup() called:",e);
            if(e.key === "Escape" || e.which == 27) {
                this.documentoSeleccionado={};
                this.documentoSeleccionado[this.nameField]=this.placeholder;                        
            }                        
        });               
        
    }    
  
    ngOnDestroy() {
      this._onDestroy.next();
      this._onDestroy.complete();
    }
  
    seleccionoDocumento(documento:any) {
    //   console.log("seleccionoDocumento",documento);
      
      this.onKeyUpSearch.setValue(null);
        
      this.documentoSeleccionado={};
      
      this.documentoSeleccionado[this.nameField]=this.fn.getDocField(documento,this.bdFieldName);
    
      this.seleccionoValor=true;
      this.cargoSearchAutocomplete.emit(documento);      
      // console.log("this.documentoSeleccionado",this.documentoSeleccionado[this.bdFieldName]);
    }
  
    borroDocumentoSeleccionado() {    
        // console.log("borroDocumentoSeleccionado");  
        this.documentoSeleccionado={};
        this.documentoSeleccionado[this.nameField]=null;

        this.searchText = new FormControl();    
        setTimeout(function(){ jQuery('#textSearch').trigger('click')  }, 100);
    }  
    
    corrigeValor() {
      // console.log("corrige valor");

      if(this.documentoSeleccionado[this.nameField]==null) {
          this.documentoSeleccionado[this.nameField]=(this.value) ? (this.value) : this.placeholder;
      }        
      return true;
    }
    
    getOptionValue(documento:any):string {
      return this.fn.getDocField(documento,this.bdFieldName);
    }

    busquedaFirestore(search:string) {
        
        let where=this.whereArray;
        let orderBy=this.orderByArray;
 
        
         this.af.collection<any>(this.nombreColeccion,
            ref =>{
                let query : Query = ref;
                
                // Filtro los que no están Activos
                query = query.where('settings.isActivo', '==', true);
                
                if(where.length > 0) {
                    for (var i = 0; i < where.length; i++) {
                        //log(...values('warning','agrego where:',where[i].key, where[i].operador, where[i].value));
                        query = query.where(where[i].key, where[i].operador, where[i].value);
                    }
                }
                
                search = this.fn.reemplazaAcentos(search);
                search = search.toLowerCase();                                                              
                query = query.where( 'keywords', 'array-contains', search);                   
                // query = query.orderBy( 'nombre',     'desc');                   
                query = query.limit( 10 );                   
                
                //log(...values("valoresDebug","query:",query));                  
                return query;
                
        }).valueChanges().pipe(first()).toPromise().then((data:any)=>{
          
                // //log(...values("valores","cantResultados:",data.length));
                ////log(...values("valores","data:",data));
                
                // Elimino los repetidos
                let anterior:any=null;
                let listado:any[]=[];
                for(let i=0; i<data.length;i++) {
                  
                  let value:any;
                  // if(this.bdFieldName!='datosPersonales.apellidoNombre') {
                  //     value = data[i][this.bdFieldName];
                  // } else {
                  //     if(data.datosPersonales!==undefined) {
                  //        value = data[i].datosPersonales.apellidoNombre;
                  //     } else {
                  //        value = 'error';
                  //     }   
                  // }          
                  value = this.fn.getDocField(data[i],this.bdFieldName);
                  if(value!=anterior) {
                      listado.push(data[i]);
                      anterior=value;
                  }
                }
          
                this.searching = false;
                this.listadoResultadosBD.next(listado);
              
          }).catch((error:any)=>{
                console.error("error", "Error al obtener listadoComparar", error);
                this.searching = false;
                this.listadoResultadosBD.next([]);
          });
    }        

}  
