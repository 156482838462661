import { log, logIf, logTable, values } from '@maq-console';

import {ChangeDetectorRef, Component, Renderer2} from '@angular/core';

import { EventEmitter, OnInit, AfterViewInit, OnDestroy, DoCheck, ViewChild, Output, Input, ViewEncapsulation } from '@angular/core';

import {NgbModal} from '@ng-bootstrap/ng-bootstrap';


import { KN } from '@maq-models/typesKN/typesKN.model';


import { ReporteFavoritoSQL } from '@settings/proyecto/models/reportes/reporteFavoritoSQL.model';
import { OPERADOR_ORDEN_BY, OPERADOR_WHERE, TIPO_BASE_DATOS, WhereInterface } from '@settings/maqueta/models/bd/bdDefinicionesGenerales.model';
import { ConfiguracionReporteSQL } from '@settings/proyecto/models/reportes/configuracionReporteSQL.model';
import { ArgumentosLlamarBaseDeDatos } from '@settings/maqueta/models/bd/bdBase.model';
import { ColumnaReporte } from '@settings/proyecto/models/reportes/columnasReporte';
import { ACCION_FORM } from '@settings/maqueta/helpers/actionFormHelp';
import { TranslateService } from '@ngx-translate/core';
import { PageBase } from '@settings/maqueta/base/page-base.page';
import { ConfigComponente } from './reportes-favoritos.page.config';
import { DropdownSettings } from 'angular2-multiselect-dropdown/lib/multiselect.interface';
import { NavigationExtras, UrlTree } from '@angular/router';
import { NOMBRES_TIPOS_REPORTES } from '@settings/proyecto/mocks/columnaReporte/columnaReporte.mocks';

@Component({
  selector: 'app-reportes-favoritos',
  templateUrl: './reportes-favoritos.page.html'
})
export class ReportesFavoritosSQLComponent extends PageBase<ReporteFavoritoSQL> implements OnInit, OnDestroy {

  // @Input() public usuarioKN: KN;
  @Input() public tipoReporteKey: NOMBRES_TIPOS_REPORTES;
  @Input() public columnasSeleccionadas: ColumnaReporte[];


  @Output() seleccionoFavorito= new EventEmitter<ColumnaReporte[]>();
  
  public finalizoConsultaReportesFavoritos: boolean = false;
  public listadoConfiguracionReporte:ConfiguracionReporteSQL[] =[];


  public selectReportFavorito: ReporteFavoritoSQL = null;
  public reportFavoritoCargadoPreviamente: ReporteFavoritoSQL = null;


  public dropDownSettings:DropdownSettings = null;
  public columnasVisiblesObject : any[]= [];

  public reporteDefault:ConfiguracionReporteSQL = null;
  public hasReporteDefault:boolean = false;

  constructor(public changeDetectorRef: ChangeDetectorRef,private modalService: NgbModal, private renderer2: Renderer2) {
    super(changeDetectorRef);
  }
  
  ngOnInit() {
    this.inicializarVariablesTraducibles();
    this.translate.onLangChange.subscribe(() => {
      this.inicializarVariablesTraducibles();
    });

    this.setearDropDownSettings();

    super.ngOnInit();
  }          
  
  ngOnDestroy() {
    super.ngOnDestroy();
  }

  configuracionComponente() {
    
    // --------------------------------------------------------------
    // Configuración del Componente
    // --------------------------------------------------------------  
    if(this.profileNotRequiredSelectingOrganization() && this.organizacionKNAI?.key) {
      this.whereListadoPrincipal.push({key:'organizacionKey',operador:OPERADOR_WHERE.Igual, value: this.organizacionKNAI.key});
    }else{
      this.whereListadoPrincipal = [];
    }

    if(this.usuario?.getKN()){
      this.whereListadoPrincipal.push({key:'usuarioKey',operador:OPERADOR_WHERE.Igual, value: this.usuario.getKN().key});
    }

    if(this.tipoReporteKey){
      this.whereListadoPrincipal.push({key:'tipoReporteKey',operador:OPERADOR_WHERE.Igual, value: this.tipoReporteKey.toString()});
    }
    
    this.orderByListadoPrincipal=[{key:'descripcionConfiguracionReporte',ascDesc: OPERADOR_ORDEN_BY.Descendente}]
    
    this.configComponente = new ConfigComponente(this.fb);

    super.configuracionComponente();
      
  }

  inicializarVariablesTraducibles(){
    // log(...values('funcionComponente','inicializarVariablesTraducibles'));
    // log(...values("valores","this.translate:",this.translate));  
    // log(...values("valores","this.translate.store.currentLang:",this.translate.store.currentLang));  
  }
    
  configuracionFormulario(){
    super.configuracionFormulario();
    this.form.controls['esFavorito'].setValue(true);
  }
    
  onResultGetListadoPrincipal() {
    log(...values('funcionComponente','componente.onResultGetListadoPrincipal'));

    // Acciones especificas sobre ListadoPrincipal del Componente
    this.listadoPrincipal = this.listadoPrincipal.map(reporteFav => new ReporteFavoritoSQL(reporteFav));

    this.getListadoConfiguracionesReportes().then((listadoConfiguracionesReportes:ConfiguracionReporteSQL[])=>{
      
      if(listadoConfiguracionesReportes?.length==0){
        //redirigir al formulario de configuración de reporte, por ende no deberia existir un reporte favorito

        this.alertService.confirm({ 
          title:   this.translate.instant('mensajes.atencion') + ': ' + this.translate.instant('mensajesReporte.faltaConfiguracionDefaultEnReporte'), 
          message:  this.translate.instant('mensajesReporte.debeRealizarConfiguracionDefaultDelReporte'), 
        }).then(data=>{
          this.router.navigate(['/reportes/configuracion']);
        }).catch(error=>{  
          this.router.navigate(['/reportes/configuracion']);
        });
      }else{

        this.listadoConfiguracionReporte = listadoConfiguracionesReportes;
        let configuracionReporteDefault:ConfiguracionReporteSQL = this.listadoConfiguracionReporte.find(configuracionReporte => configuracionReporte.getEsDefault()==true);

        // console.log('<<<< onResultGetListadoPrincipal configuracionReporteDefault ', configuracionReporteDefault);
        // console.log('<<<< onResultGetListadoPrincipal this.listadoPrincipal.length ', this.listadoPrincipal.length);
        // console.log('<<<< onResultGetListadoPrincipal this.listadoConfiguracionReporte.length ', this.listadoConfiguracionReporte);


        //agrego el reporte default, sino tengo ningun reporte favorito
        if(configuracionReporteDefault && this.listadoPrincipal.length==0) {
          let reporteDefault:ReporteFavoritoSQL = new ReporteFavoritoSQL();
          /*seteo todos los valores de la configuracion en el reporte default, para mostrarlo en el favorito */
          reporteDefault.setIdConfiguracionReporte(configuracionReporteDefault.getIdConfiguracionReporte());
          reporteDefault.setDescripcionConfiguracionReporte(configuracionReporteDefault.getDescripcionConfiguracionReporte());
          reporteDefault.setUsuarioKey(this.usuario.key);
          reporteDefault.setUsuarioNombre(this.usuario.datosPersonales.apellidoNombre);
          reporteDefault.setColumnasSeleccionadas(configuracionReporteDefault.getColumnasSeleccionadasTraducidas(this.translate));
          reporteDefault.setEsFavorito(false);
          reporteDefault.setOrganizacionKey(configuracionReporteDefault.getOrganizacionKey());
          reporteDefault.setOrganizacionNombre(configuracionReporteDefault.getOrganizacionNombre());
          reporteDefault.setSucursalKey(configuracionReporteDefault.getSucursalKey());
          reporteDefault.setSucursalNombre(configuracionReporteDefault.getSucursalNombre());
          reporteDefault.setAreaNegocioKey(configuracionReporteDefault.getAreaNegocioKey());
          reporteDefault.setAreaNegocioNombre(configuracionReporteDefault.getAreaNegocioNombre());

          this.listadoPrincipal.push(reporteDefault);

          this.selectReportFavorito = reporteDefault;
          this.onChangeReporte(this.selectReportFavorito);

          this.hasReporteDefault = true;
          
          //agrego las demas opciones de configuraciones de reporte
          this.addConfiguracionReportes();


        }else if(!configuracionReporteDefault && this.listadoPrincipal.length==0){ //no hay default ni favoritos, mostrar el formulario de configuración de reporte
            //redirigir al formulario de configuración de reporte
            this.alertService.confirm({ 
              title:   this.translate.instant('mensajes.atencion') + ': ' + this.translate.instant('mensajesReporte.faltaConfiguracionDefaultEnReporte'), 
              message:  this.translate.instant('mensajesReporte.debeRealizarConfiguracionDefaultDelReporte'), 
            }).then(data=>{
              this.router.navigate(['/reportes/configuracion']);
            }).catch(error=>{  
              this.router.navigate(['/reportes/configuracion']);              
            });
        }else if(this.listadoPrincipal.length==1){
          this.selectReportFavorito = new ReporteFavoritoSQL(this.listadoPrincipal[0]);
          this.reportFavoritoCargadoPreviamente = new ReporteFavoritoSQL(this.listadoPrincipal[0]);
          this.onChangeReporte(this.selectReportFavorito);
          
          // //agrego las demas opciones de configuraciones de reporte
          this.addConfiguracionReportes();
        }else{
          this.alertService.confirm({ 
            title:   this.translate.instant('mensajes.atencion') + ': ' + this.translate.instant('mensajesReporte.faltaConfiguracionDefaultEnReporte'), 
            message:  this.translate.instant('mensajesReporte.debeRealizarConfiguracionDefaultDelReporte'), 
          }).then(data=>{
            this.router.navigate(['/reportes/configuracion']);
          }).catch(error=>{  
            this.router.navigate(['/reportes/configuracion']);              
          });
        }

      }
      this.finalizoConsultaReportesFavoritos= true;
    }).catch((error)=>{
      console.error('Error al obtener el Listado de Configuraciones de Reportes',error);
      this.finalizoConsultaReportesFavoritos= true;
    });

    super.onResultGetListadoPrincipal();


  }

  addConfiguracionReportes(){
    let demasOpciones: ConfiguracionReporteSQL[] = this.listadoConfiguracionReporte.filter(configReporte => configReporte.getIdConfiguracionReporte()!=this.selectReportFavorito.getIdConfiguracionReporte());
    //agrego las demas opciones de configuraciones de reporte
    for (let index = 0; index < demasOpciones?.length; index++) {
      let opcionReporteFavorito:ReporteFavoritoSQL = new ReporteFavoritoSQL();
      let configuracionReporte:ConfiguracionReporteSQL = demasOpciones[index];
      /*seteo todos los valores de la configuracion en el reporte default, para mostrarlo en el favorito */
      opcionReporteFavorito.setIdConfiguracionReporte(configuracionReporte.getIdConfiguracionReporte());
      opcionReporteFavorito.setDescripcionConfiguracionReporte(configuracionReporte.getDescripcionConfiguracionReporte());
      opcionReporteFavorito.setUsuarioKey(this.usuario.key);
      opcionReporteFavorito.setUsuarioNombre(this.usuario.datosPersonales.apellidoNombre);
      opcionReporteFavorito.setColumnasSeleccionadas(configuracionReporte.getColumnasSeleccionadasTraducidas(this.translate));
      opcionReporteFavorito.setOrganizacionKey(configuracionReporte.getOrganizacionKey());
      opcionReporteFavorito.setOrganizacionNombre(configuracionReporte.getOrganizacionNombre());
      opcionReporteFavorito.setSucursalKey(configuracionReporte.getSucursalKey());
      opcionReporteFavorito.setSucursalNombre(configuracionReporte.getSucursalNombre());
      opcionReporteFavorito.setAreaNegocioKey(configuracionReporte.getAreaNegocioKey());
      opcionReporteFavorito.setAreaNegocioNombre(configuracionReporte.getAreaNegocioNombre());
      this.listadoPrincipal.push(opcionReporteFavorito);
    }
  }
  
  getListadoConfiguracionesReportes():Promise<ConfiguracionReporteSQL[]>{
    let where:WhereInterface[] = [];
    if(this.profileNotRequiredSelectingOrganization() && this.organizacionKNAI?.key) {
      where.push({key:'organizacionKey',operador:OPERADOR_WHERE.Igual, value: this.organizacionKNAI.key});
    }
    
    if(this.tipoReporteKey){
      where.push({key:'tipoReporteKey',operador:OPERADOR_WHERE.Igual, value: this.tipoReporteKey.toString()});
    }

    let listadoConfiguracionesReportes: ConfiguracionReporteSQL[] = [];

    return new Promise((resolve, reject)=>{
      let argumentoBase:ArgumentosLlamarBaseDeDatos= new ArgumentosLlamarBaseDeDatos({
        nombreColeccion  :  "configuracionReporte",
        where            :  where,
        orderBy          :  [{key:'descripcionConfiguracionReporte',ascDesc: OPERADOR_ORDEN_BY.Descendente}],
        paginaActualNro  :  null,
        urlBaseSQL       :  'configuracionReporte/',
        tipoBaseDatos    :  TIPO_BASE_DATOS.SQL_SERVER// "firestore", "SQL"
      });
      this.bdBaseService.getPromesaBase(argumentoBase).then((configuracionesReportes: ConfiguracionReporteSQL[]) => {
        console.log('getListadoConfiguracionesReportes configuracionesReportes', configuracionesReportes);

        for (let i = 0; i < configuracionesReportes?.length; i++) {
          let configuracionReporte:ConfiguracionReporteSQL = new ConfiguracionReporteSQL(configuracionesReportes[i]);

          if(this.profileRequiredSelectingOrganization()){
            listadoConfiguracionesReportes.push(configuracionReporte);
          }else if(configuracionReporte.getEsPublico()==true){
            //si tienen sucursal y/o area de negocio o ambas me fijo que coincidan con las que pertence el usuario.
            let arraySucursales:string[] = [];
            let arrayAreasNegocio:string[] = [];

            // console.log('<<< this.usuario?.sucursalesAreasNegocio >>>', this.usuario?.sucursalesAreasNegocio);
            // console.log('<<< configuracionReporte.getJson() >>>', configuracionReporte.getJson());

            if(this.usuario?.sucursalesAreasNegocio?.length){
              for(let i=0; i<this.usuario.sucursalesAreasNegocio.length; i++){
                let sucursalKey:string = this.usuario.sucursalesAreasNegocio[i].split("@@@")[0];
                let areaNegocioKey:string = this.usuario.sucursalesAreasNegocio[i].split("@@@")[1];
                arraySucursales.push(sucursalKey);
                arrayAreasNegocio.push(areaNegocioKey);
              }
            }

            if(configuracionReporte.getSucursalKey() && configuracionReporte.getAreaNegocioKey() && arraySucursales.indexOf(configuracionReporte.getSucursalKey())!=-1 && arrayAreasNegocio.indexOf(configuracionReporte.getAreaNegocioKey())!=-1){
              listadoConfiguracionesReportes.push(configuracionReporte);
            }else if(configuracionReporte.getSucursalKey() && arraySucursales.indexOf(configuracionReporte.getSucursalKey())!=-1){
              listadoConfiguracionesReportes.push(configuracionReporte);
            }else if(configuracionReporte.getSucursalKey()==null && configuracionReporte.getAreaNegocioKey()==null){ //no tiene ni sucursal y ni area de negocio, es decir, es publico para toda la org. 
              listadoConfiguracionesReportes.push(configuracionReporte);
            }
          }else if(configuracionReporte.getUsuarioKey() == this.usuario?.key){
            listadoConfiguracionesReportes.push(configuracionReporte);
          }
        }

        resolve(listadoConfiguracionesReportes);
      }).catch(error => {
        console.error("error al obtener Configuraciones de los Reportes ", error);
        reject(error);
      });
    });
  }

  onChangeReporte(selectReportFavorito: ReporteFavoritoSQL){
    // this.selectReportFavorito = new ReporteFavoritoSQL(selectReportFavorito);
    this.columnasSeleccionadas = new ReporteFavoritoSQL(selectReportFavorito).getColumnasSeleccionadasTraducidas(this.translate);
    this.seleccionoFavorito.emit(new ReporteFavoritoSQL(selectReportFavorito).getColumnasSeleccionadasTraducidas(this.translate));
  }
  
  modificarFavorito(content:any) { 
    if(this.selectReportFavorito) {
      this.form.controls['idConfiguracionReporte'].setValue(this.selectReportFavorito.getIdConfiguracionReporte());
      this.form.controls['usuarioKey'].setValue(this.selectReportFavorito.getUsuarioKey());
      this.form.controls['usuarioNombre'].setValue(this.selectReportFavorito.getUsuarioNombre());
      this.form.controls['descripcionConfiguracionReporte'].setValue(this.selectReportFavorito.getDescripcionConfiguracionReporte());
      this.form.controls['tipoReporteKey'].setValue(this.selectReportFavorito.getTipoReporteKey());
      this.form.controls['columnasSeleccionadas'].setValue(this.selectReportFavorito.getColumnasSeleccionadas());
      this.form.controls['organizacionKey'].setValue(this.selectReportFavorito.getOrganizacionKey()); 
      this.form.controls['organizacionNombre'].setValue(this.selectReportFavorito.getOrganizacionNombre()); //si existen, estos datos los deberia mostrar tambien...
      this.form.controls['sucursalKey'].setValue(this.selectReportFavorito.getSucursalKey());
      this.form.controls['sucursalNombre'].setValue(this.selectReportFavorito.getSucursalNombre()); //si existen, estos datos los deberia mostrar tambien...
      this.form.controls['areaNegocioKey'].setValue(this.selectReportFavorito.getAreaNegocioKey());
      this.form.controls['areaNegocioNombre'].setValue(this.selectReportFavorito.getAreaNegocioNombre()); //si existen, estos datos los deberia mostrar tambien...
      this.form.controls['esFavorito'].setValue(true);
      this.form.controls['organizacionNombre'].disable();
      this.form.controls['sucursalNombre'].disable();
      this.form.controls['areaNegocioNombre'].disable();
      this.form.controls['idConfiguracionReporte'].disable();// lo deshabilito para no permitir al usuario que lo modifique, luego habilito para impactar en la bd cuando se modifique
      this.form.controls['esFavorito'].disable();// lo deshabilito para no permitir al usuario que lo modifique, luego habilito para impactar en la bd cuando se modifique
      this.openModal(content);


    }
    this.changeDetectorRef.detectChanges(); //aviso cambios en la vista.
  }

  openModal(content:any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
      (result) => {
        this.changeDetectorRef.detectChanges(); //aviso cambios en la vista.
        if (result =='submit'){
          //borro el anterior reporte favorito y agrego el nuevo
          this.form.controls['idConfiguracionReporte'].enable(); //habilito para impactar en la bd
          this.form.controls['esFavorito'].enable(); // habilito para impactar en la bd
          this.form.controls['organizacionNombre'].enable();
          this.form.controls['sucursalNombre'].enable();
          this.form.controls['areaNegocioNombre'].enable();

          if(this.reportFavoritoCargadoPreviamente){
            this.borrarReporteFavoritoAnterior().then(()=>{
              this.modificarReporteFavorito(this.form.value);
            }).catch(error => {
              console.log('error borrarReporteFavoritoAnterior: ', error);
              // this.spinner.hide();
            });
          }else{
            this.modificarReporteFavorito(this.form.value);
          }
          
        }
      }
    ).catch(error => {
      console.log('error openModal: ', error)
      this.changeDetectorRef.detectChanges(); //aviso cambios en la vista.
    });
  }

  borrarReporteFavoritoAnterior():Promise<any>{
    return this.bdBaseService.updateBase({
      operacionFormulario        : ACCION_FORM.BORRADO_FISICO,
      campoClave                 : 'idReporteFavorito',
      nombreColeccion            : 'ReporteFavorito',
      documento                  : this.reportFavoritoCargadoPreviamente.getJson(),
      usaSettings                : false,
      tipoBaseDeDatos            : TIPO_BASE_DATOS.SQL_SERVER,
      urlBaseDatos               : 'reporteFavorito/'
    }).then(respuesta=>{
      this.listadoPrincipal.map(x=>{
        if(x.getIdConfiguracionReporte() == this.reportFavoritoCargadoPreviamente.getIdConfiguracionReporte()){
          x.setEsFavorito(false);
          x.setIdReporteFavorito(null);
        }
      });

      console.log("BorrarReporteFavoritoAnterior finalizo correctamente, borrado de documento: ", this.reportFavoritoCargadoPreviamente.getJson(), ' respuesta ', respuesta);
    }).catch(error=>{
      console.error("Error borrarReporteFavoritoAnterior documento: ", this.reportFavoritoCargadoPreviamente.getJson(), ' error: ', error);
    });
  }

  modificarReporteFavorito(documento:object):Promise<any>{
    return this.bdBaseService.updateBase({
      operacionFormulario        : ACCION_FORM.AGREGAR,
      campoClave                 : 'idReporteFavorito',
      nombreColeccion            : 'ReporteFavorito',
      documento                  : new ReporteFavoritoSQL(documento).getJson(),
      usaSettings                : false,
      tipoBaseDeDatos            : TIPO_BASE_DATOS.SQL_SERVER,
      urlBaseDatos               : 'reporteFavorito/'
      }).then(respuesta=>{

        // console.log('<<< respuesta ', respuesta);
        let mensajeServicio = 'grabacion ok';
        this.toastrService.success('', this.translate.instant(mensajeServicio),{
            timeOut: 2000, positionClass:'toast-top-center'});

        let nuevoReporteFavorito = new ReporteFavoritoSQL(documento);
        
        for (let index = 0; index < this.listadoPrincipal.length; index++) {
          let reporteFavorito:ReporteFavoritoSQL = this.listadoPrincipal[index];
          if(reporteFavorito.getIdConfiguracionReporte() == nuevoReporteFavorito.getIdConfiguracionReporte()){
            reporteFavorito.setEsFavorito(true);
            reporteFavorito.setIdReporteFavorito(respuesta['insertedId']);
            this.selectReportFavorito = reporteFavorito;
            this.reportFavoritoCargadoPreviamente = reporteFavorito;
            this.onChangeReporte(nuevoReporteFavorito);
            break;
          }
        }

      }).catch(error=>{
        console.error("Error modificarReporteFavorito documento: ",documento, ' error: ', error);
        this.toastrService.error('', this.translate.instant('mensajes.errorGrabar'),{
                    timeOut: 2000, positionClass:'toast-top-center'});
      });
  }

  public navigateConfiguracionReporte(event:any, idElementHTML:string, url:string){
    let arrayId:string[] = idElementHTML.split('/');
    let id = arrayId[0];

    let parametros = {id: id};
    let navigationExtras: NavigationExtras = { queryParams: parametros};
    this.navigateClick(event, idElementHTML, url, navigationExtras);
  }

  private navigateClick(event:any, idElementHTML:string, url:string, navigationExtras: NavigationExtras){
    if (event.ctrlKey==true){
      if(localStorage.getItem('urlCtrlClickPresionado')=='true'){
        this.toastrService.error(this.translate.instant('ctrlClick.esperarTitulo'), this.translate.instant('ctrlClick.esperarMensaje'),{
          timeOut: 2000,positionClass:'toast-center-center'});    
      }else{
        let urlHref:string = this.getUrlNavigate(url, navigationExtras);
        localStorage.setItem('urlCtrlClick', url);
        localStorage.setItem('urlCtrlClickPresionado', 'true')
        this.renderer2.setAttribute(document.getElementById(idElementHTML), 'href', urlHref);
        setTimeout(() => {  //saco el atributo por si luego se presiona click sobre el elemento a del HTML y no recargue la pagina de 0, sino solo la vista a traves del router.
          this.renderer2.removeAttribute(document.getElementById(idElementHTML), 'href');
        }, 1000); 
      }

    }else{
      this.navigateURL(url,navigationExtras);
    }

  }

  private navigateURL(url:string,navigationExtras: NavigationExtras){
    this.router.navigate([url], navigationExtras);
  }

  //crea la url para el href con sus respectivo parametros
  private getUrlNavigate(url:string, navigationExtras: NavigationExtras):string{
    let urlTree:UrlTree = this.router.createUrlTree([url], navigationExtras);
    let returnUrl:string = this.router.serializeUrl(urlTree);
    return returnUrl;
  }

  setearDropDownSettings(){
    let text:string = this.translate.instant('multiSelectTexts.searchPlaceholder')
    let selectAllText:string = this.translate.instant('multiSelectTexts.checkAll');
    let unSelectAllText :string = this.translate.instant('multiSelectTexts.uncheckAll');
    let noDataLabel:string = this.translate.instant('multiSelectTexts.noEncontroResultados');
    let searchPlaceholderText:string = this.translate.instant('navegabilidad.buscar');

    this.dropDownSettings = { 
        singleSelection       : false,
        enableSearchFilter    : true,
        addNewItemOnFilter    : true,
        escapeToClose         : true,
        disabled              : true,
        classes               : 'myclass custom-class-example',
        primaryKey            : 'key',
        labelKey              : 'nombreTraduccion',
        text                  : text,
        selectAllText         : selectAllText,
        unSelectAllText       : unSelectAllText,
        noDataLabel           : noDataLabel,
        searchPlaceholderText : searchPlaceholderText,
        enableCheckAll        : true,
        filterSelectAllText   : selectAllText,
        filterUnSelectAllText : unSelectAllText,
        enableFilterSelectAll : true,
        badgeShowLimit        : 10,
        position              : 'bottom',
        maxHeight             : 300,
        searchBy              : ['key', 'nombreTraduccion'],
    };
}
  // mostrarBotones() {
  //   // if(['Default','Nuevo'].indexOf(this.selectReport.key)!=-1) {
  //   //   return false;
  //   // } else {
  //   //   return true;
  //   // }  
  // }
}