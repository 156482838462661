import { url } from 'inspector';
import { log, logIf, logTable, values } from '@maq-console';

// referencia
// https://gist.github.com/codediodeio/5e02b605f2ab015f2fb1e60497bd46bf

import { Injectable }             from '@angular/core';
import { AngularFireAuth }        from '@angular/fire/auth';
import { Router,ActivatedRoute, NavigationExtras, Params }  from "@angular/router";
import firebase from 'firebase/app';
import 'firebase/auth';

import { Observable, of , Observer} from 'rxjs';
import { AngularFirestore, AngularFirestoreDocument ,AngularFirestoreCollection } from '@angular/fire/firestore';
import { MensajesService }      from '@maq-servicios/mensajes/mensajes.service';
import { MensajesServiceBase }  from '@maq-servicios/mensajes/mensajes.serviceBase';
import { TranslateService }     from '@ngx-translate/core';
import { StorageService }       from '@maq-servicios/storageService/storage.service';
import { AlertService }         from '@maq-servicios/alert/alert-service';
import {Usuario}                from '@maq-models/usuarios/usuarios.model'
import { KN } from '@settings/maqueta/models/typesKN/typesKN.model';
@Injectable({
  providedIn: 'root'
}) 
export class AuthService {
  authState: any = null;
  usuarioLogueado: Usuario = null;
  hayImage:boolean=false;
  public configLoginOffline: boolean = false;

  private navigationExtrasAnterior: NavigationExtras | null = null;
  private navigationExtrasAnteriorSaveInSession: boolean = false;

  public errorMensaje:string=null;

  constructor(private afAuth: AngularFireAuth,
              private  fs: AngularFirestore,
              private msg:MensajesService,
              private msgBase:MensajesServiceBase,
              private router:Router,
              private translate: TranslateService,
              private storageService: StorageService,
              private alertService: AlertService) {

                  // console.log('authenticated user authService start',JSON.parse(localStorage.getItem('usuarioLogueadoUltimaSession')));// utilziar sessionStorage 

                      this.afAuth.authState.subscribe((auth) => {
                        log(...values('funcionPromesa','afAuth.authState'));
                        log(...values("valoresDebug","auth:",auth));

                        this.updateUserData(auth);
                      
                    });
  }


  public getNavigationExtrasAnteriorSaveInSession():boolean{
    return this.navigationExtrasAnteriorSaveInSession;
  }

  public setNavigationExtrasAnteriorSaveInSession(navigationExtrasAnteriorSaveInSession:boolean):void{
    this.navigationExtrasAnteriorSaveInSession = navigationExtrasAnteriorSaveInSession;
  }

  public getNavigationExtrasAnterior(): NavigationExtras | null {
    return this.navigationExtrasAnterior;
  }

  public setNavigationExtrasAnterior(navigationExtras?:NavigationExtras) {
    if(navigationExtras){
      this.navigationExtrasAnterior = navigationExtras;
    }else{
      this.navigationExtrasAnterior = null;
    }
  }

  autorizoLogginOfLine(autorizo: boolean) {
    this.configLoginOffline = autorizo;
  }

  public getEstaAutorizadoLogginOffLine(): boolean {
    return this.configLoginOffline;
  }

   private updateUserData(auth): void {
    // console.log('updateUserData auth',auth);

    // if (auth==null && JSON.parse(localStorage.getItem('usuarioLogueadoUltimaSession')) !== null){
    //   console.log('updateUserData auth entro al if auth: ',auth);
    //   console.log('updateUserData auth entro al if usuarioLogueadoUltimaSession: ',JSON.parse(localStorage.getItem('usuarioLogueadoUltimaSession')));
    //   console.log('updateUserData auth entro al if url actual', this.router.url);
    //   if (this.router.url!='/login/login'){
    //     sessionStorage.setItem('urlAnterior', this.router.url);
    //   }
    //   auth= JSON.parse(localStorage.getItem('usuarioLogueadoUltimaSession'));
    // }
    // console.log('updateUserData auth',auth);

    // auth==null ? auth= JSON.parse(localStorage.getItem('usuarioLogueadoUltimaSession')) : auth;
    
    console.log('updateUserData auth',auth);
    if (auth!=null){
      console.log('updateUserData usuario autenticado',auth);
      
      localStorage.setItem('usuarioLogueadoUltimaSession',JSON.stringify(auth));

      this.authState = auth;
      if(this.authState['photoURL'] ){
        this.hayImage=true;
      }
      this.getUsuarioFromColecction(auth?.email).subscribe(usuario=>{
        // console.log("usuario",usuario);

        this.usuarioLogueado=usuario;

        this.usuarioLogueado.getUTCDefault(this.msgBase.bdBaseService);
        // Actualizo Foto
        let foto = this.authState['photoURL'];

        // Gmail:  https://lh3.googleusercontent.com/a-/AAuE7mBKzRZRV8csfPmKAPvfh_beWLe2jQ0UjIRvxgDD8A
        // A mano: https://firebasestorage.googleapis.com/v0/b/formulasnutralmix.appspot.com/o/test%2F1579530732820?alt=media&token=9de1a9ad-9050-4e96-b34d-c3ceee8468fe

        let fotoGrabada=null;
        if(this.usuarioLogueado.datosPersonales.fotoIMG!=null && this.usuarioLogueado.datosPersonales.fotoIMG.linkThumb!=null) {
          fotoGrabada = this.usuarioLogueado.datosPersonales.fotoIMG.linkThumb;
        }

        if(fotoGrabada &&
          ( this.usuarioLogueado.datosPersonales.fotoIMG.linkThumb.indexOf('firebasestorage')!=-1 ||
            this.usuarioLogueado.datosPersonales.fotoIMG.linkThumb.indexOf('nutralmix')!=-1 ||
            this.usuarioLogueado.datosPersonales.fotoIMG.linkThumb.indexOf('testcpsistemas')!=-1 ||
            this.usuarioLogueado.datosPersonales.fotoIMG.linkThumb.indexOf('e-stancias')!=-1 ||
            this.usuarioLogueado.datosPersonales.fotoIMG.linkThumb.indexOf('pkmobility')!=-1 ||
            this.usuarioLogueado.datosPersonales.fotoIMG.linkThumb.indexOf('kipbip')!=-1)) {    // Imagen subida a mano

           // nada, no piso con la del login

        } else if(foto!=null && foto!==undefined && 
                  foto!=fotoGrabada) {  // Si es de Gmail, y es diferente a la subida anteriormente

            this.usuarioLogueado.datosPersonales.fotoIMG = {
                link       : foto,
                bytes      : 0,
                linkThumb  : foto,
                bytesThumb : 0
            }

            this.fs.collection('Usuarios').doc(this.usuarioLogueado.key)
              .set({'datosPersonales': this.usuarioLogueado.datosPersonales },{merge:true});
        }

        this.msg.setPerfilUsuarioObs(usuario);
        this.msgBase.setUsuarioObs(usuario)
        // this.authState = auth;
        // console.log('onInit',this.route.snapshot);
        // console.log('onIni userKey',this.route.snapshot.params.userKey);
        // console.log('onIni url',this.router.url);
        // console.log('onIni router',this.router);
        // this.router.events.forEach((event) => {
        //   console.log('onIni event', event);
        // });

        this.seleccionPaginaInicio(usuario);
        
      });

    }else {
        // el usuario no está autorizado
        console.log('el usuario no está autorizado',auth);
        this.router.navigate(['/login/login']);
    }
  }

  // Returns true if user is logged in
  get authenticated(): boolean {
    // console.log('authenticated this.authState !== null ',this.authState);
    // console.log('authenticated user',JSON.parse(localStorage.getItem('usuarioLogueadoUltimaSession')));
    // console.log('authenticated return',this.authState !== null || localStorage.getItem('usuarioLogueadoUltimaSession') !== null);
    return this.authState !== null || JSON.parse(localStorage.getItem('usuarioLogueadoUltimaSession')) !== null;; 
  }

  // Returns current user data
  get currentUser(): any {
    return this.authenticated ? this.authState : null;
  }

  // Returns fecha de creacion del ususario
  get getFechaCreacion(): string {
    // console.log('creationTime',this.authState['metadata']['creationTime'] ? this.authState['metadata']['creationTime'] : false);
    if(this.authState !== null){
    return this.authState['metadata']['creationTime'] ? this.authState['metadata']['creationTime'] : '';
  }else {return ''}
  }

  // Returns
  get currentUserObservable(): any {
    return this.afAuth.authState
  }

  // Returns current user UID
  get currentUserId(): string {
    return this.authenticated ? this.authState.uid : '';
  }

  // Anonymous User
  get currentUserAnonymous(): boolean {
    return this.authenticated ? this.authState.isAnonymous : false
  }

  // Returns current user display name or Guest
  get currentUserDisplayName(): string {
    if (!this.authState) { return 'Guest' }
    else if (this.currentUserAnonymous) { return 'Anonymous' }
    else if (this.authState['displayName']==null) { return this.authState['email'] }
    else { return this.authState['displayName'] || 'User without a Name' }
  }

// Returns current user display name or Guest
  get currentUserPhotoURL(): string {
    //console.log("this.authState",this.authState);
    return this.authState['photoURL'] ? this.authState['photoURL'] : false

  }

  //// Social Auth ////

  githubLogin() {
    const provider = new firebase.auth.GithubAuthProvider()
    return this.socialSignIn(provider);
  }

  googleLogin() {
    log(...values("funcionGo","googleLogin"));
    let onlineOffline: boolean = navigator.onLine;
    let userAnterior = JSON.parse(sessionStorage.getItem('puedeIngrearOffLine_user'));

    if (!onlineOffline && this.getEstaAutorizadoLogginOffLine() && userAnterior != null) {
      log(...values("info",'userAnterior ingreso offLine'));
      
      this.updateUserData(userAnterior.user)

      // resolve("email sent");
      return new Promise(null);
    }
    const provider = new firebase.auth.GoogleAuthProvider();
    return this.socialSignIn(provider);
  }

  facebookLogin() {
    const provider = new firebase.auth.FacebookAuthProvider()
    return this.socialSignIn(provider);
  }

  twitterLogin(){
    const provider = new firebase.auth.TwitterAuthProvider()
    return this.socialSignIn(provider);
  }

  private socialSignIn(provider) {
    log(...values("funcionGo","socialSignIn"));
    return this.afAuth.signInWithPopup(provider)
      .then((credential) =>  {
            //console.log('authService signInWithPopup',credential);
            sessionStorage.setItem('puedeIngrearOffLine_user', JSON.stringify(credential.user));
 
          // this.authState = credential.user
          this.updateUserData(credential.user);
           // this.router.navigate(['/'])
      })
      .catch(error => console.log(error));
  }


  //// Anonymous Auth ////

  anonymousLogin() {
    return this.afAuth.signInAnonymously()
    .then((user) => {
      // this.authState = user
      this.updateUserData(user);
       this.router.navigate(['/'])
    })
    .catch(error => console.log(error));
  }

  //// Email/Password Auth ////

  emailSignUp(email: string, password: string): Promise<any> {
    log(...values("funcionGo","emailSignUp"));
    
    return new Promise((resolve, reject) => {

      this.afAuth.createUserWithEmailAndPassword(email, password)
        .then((user) => {
          console.log(user);
          sessionStorage.setItem('puedeIngrearOffLine_user', JSON.stringify(user));
          resolve(user);
        })
        .catch(error => {
          console.log(error);
          reject(error);
          // {code: "auth/email-already-in-use", message: "Thrown if there already exists an account with the given email address."}
          // {code: "auth/invalid-email", message: "Thrown if the email address is not valid."}
          // {code: "auth/operation-not-allowed", message: "Thrown if email/password accounts are not enabled. Enable email/password accounts in the Firebase Console, under the Auth tab."}
          // {code: "auth/weak-password", message: "Password should be at least 6 characters Thrown if the password is not strong enough."}
        })
    });

  };


  emailLogin(email: string, password: string): Promise<any> {
    log(...values("funcionGo","emailLogin"));
    return new Promise((resolve, reject) => {

      let onlineOffline: boolean = navigator.onLine;
      //console.log('onlineOffline', onlineOffline);
      let usuerAnterior = JSON.parse(sessionStorage.getItem('puedeIngrearOffLine_user'));
      //console.log('usuerAnterior', usuerAnterior);
     

      this.afAuth.signInWithEmailAndPassword(email, password)
        .then((user) => {
          //console.log(user);
          // this.authState = user.user
          sessionStorage.setItem('puedeIngrearOffLine_user', JSON.stringify(user));

          resolve("email sent");
        })
        .catch(error => {
          console.log(error);
          
          reject(error);
          // code: "auth/wrong-password"
          // message: "The password is invalid or the user does not have a password."

          // code: "auth/user-not-found"
          // message: "There is no user record corresponding to this identifier. The user may have been deleted."

          // code: "auth/network-request-failed"
          // message: "A network error (such as timeout, interrupted connection or unreachable host) has occurred."

          // code: "auth/too-many-requests"
          // message: "Too many unsuccessful login attempts.  Please include reCaptcha verification or try again later"

        });
    });
  }

  // Sends email allowing user to reset password
  resetPassword(email: string): Promise<any> {

    return new Promise((resolve, reject) => {

      var auth = firebase.auth();


      auth.sendPasswordResetEmail(email)
        .then(() => {
          console.log();
          resolve("email sent");

        })
        .catch((error) => {
          console.log(error);
          reject(error);

        });
    });
  };

  //// Sign Out ////

  signOut(): void {
    log(...values("funcionGo","signOut"));
    
    if(!this.msg.perfilUsuarioSeleccionado.organizacion) {  // Para usuarios perfil Administrador, Supervisor, Distribuidor, borro la cache de Auxiliares
      this.msg.clearListadoCache();  
      
    }
    if(!this.msgBase.usuario.isPerfilOrganizacion()){
      this.msgBase.clearListadoCache();  
    }
    
    this.afAuth.signOut();
    this.usuarioLogueado=null;
    this.msg.setPerfilUsuarioObs(null);
    this.msgBase.setUsuarioObs(null);
    this.authState=null;
    sessionStorage.setItem('urlAnterior', '/');
    // localStorage.setItem('usuarioLogueadoUltimaSession', null);
    this.router.navigate(['/login/login'])
    localStorage.clear();
    sessionStorage.clear();
    this.storageService.setItem('mantenermeLogueado', JSON.stringify(false));
  }



  setPersistencia(mantenermeLogueado:boolean){
    log(...values("funcionGo","setPersistencia"));
    let persitencia:any=null;

    if(mantenermeLogueado){
      persitencia=firebase.auth.Auth.Persistence.LOCAL;
    }else{
      persitencia=firebase.auth.Auth.Persistence.NONE;
    }

    this.afAuth.setPersistence(persitencia)
      .then(function() {
        console.log('log loginAutomatico setPersistencia', persitencia);
      })
      .catch(function(error) {
        // Handle Errors here.
        // console.log('log loginAutomatico setPersistencia error', error);
    
        var errorCode = error.code;
        var errorMessage = error.message;
      });
  }

  //// Helpers ////




  get getPerfilUsuario(): Usuario {
    return this.usuarioLogueado;
  }

// Get perfil retorna un observable para que se pueda subscribir
// hace la consulta del Usuario
// cuando recibe la informacion la publica para que el suscriptor la pueda ver

getUsuarioFromColecction(email:string): Observable<Usuario> {
  
  // console.log('getUser');
  return new Observable((observer) => {

      this.fs.collection('Usuarios',ref => ref.where('email', '==' ,email))
          .snapshotChanges()
        
          .subscribe(datosDeUsuario=>{
              // console.log(datosDeUsuario);
              if(datosDeUsuario.length>0){
                
                let usuario:Usuario= new Usuario(datosDeUsuario[0].payload.doc.data());
                this.setIdiomaTraductor(usuario);
                observer.next(usuario);
                observer.complete();// Para cerrar la susripcion.
              } else {
                //console.log("ususario loguedo, pero sin autorizar (autorizo google pero no esta en tabla usuario, absurdo) ");
                //  console.log(datosDeUsuario);
                 this.router.navigate(['/login']);
              }   
      }, err => {
          //console.log(`Encountered error: ${err}`);
          log(...values("error",`Encountered error: ${err}`));
      });

  });
}

setIdiomaTraductor(usuario):void{
  //sELECCIÓN DEL IDIOMA DEL USUARIO SEGÚN DATIS DE SU FICHA .

  let idomaUtilizado=localStorage.getItem("IdiomaUtilizado");
  let idiomaUsuario= usuario.getIdioma();
  if(idomaUtilizado==idiomaUsuario){
    //no cambio el Idioma con el que arranqué.
  } else if(idiomaUsuario){
    this.translate.use(idiomaUsuario);
  }else{
    this.translate.use('es');
    
  }
}  

getUseLocalStorage():boolean{
  let retorno:boolean =false;
  let urlCtrlClick:string = localStorage.getItem("urlCtrlClick");
  if (urlCtrlClick!=null && urlCtrlClick!=undefined) retorno=true;
  return retorno;
}

getRutaDefault():string{
  let urlRespuesta: string;

  let urlCtrlClick:string = localStorage.getItem("urlCtrlClick");

  let urlAnterior:string = sessionStorage.getItem("urlAnterior") ? sessionStorage.getItem("urlAnterior") : '/';

  if (this.getUseLocalStorage()){
    urlRespuesta = urlCtrlClick;
    this.navigationExtrasAnteriorSaveInSession = false;
    this.setNavigationExtrasAnterior(JSON.parse(localStorage.getItem("navigationExtrasAnterior")));
  }
  else{
    urlRespuesta = urlAnterior;
    this.navigationExtrasAnteriorSaveInSession = true;
    this.setNavigationExtrasAnterior(JSON.parse(sessionStorage.getItem("navigationExtrasAnterior")));
  }

  return urlRespuesta;
}

  

 

  seleccionPaginaInicio(usuarioLogueado){
    log(...values("black","selecciono Página de Inicio",usuarioLogueado.perfilUsuario.tipo));
    // console.log("selecciono Página de Inicio authState",JSON.stringify(this.authState));

    if(usuarioLogueado.perfilUsuario!=null && usuarioLogueado.perfilUsuario.tipo!==undefined){
      
      let rutaDefault =this.getRutaDefault();

      localStorage.setItem('urlCtrlClickPresionado', 'false')


      switch (usuarioLogueado.perfilUsuario.tipo) {
        case "Desarrollador":
            if(this.authState && this.authState.email){
              let mail:String=this.authState.email;
              
              if(mail.toLocaleLowerCase().includes('usuariopanel')){
                let numeroPanel=mail.replace('usuariopanel','');
                numeroPanel=numeroPanel.replace('@gmail.com','');
                let url = '/panel/panelSesion/'+numeroPanel;
                this.navegarUrl(url);
              }
              else{
                this.navegarUrl(rutaDefault);
              }
            }
            else{
              this.navegarUrl(rutaDefault);
            }
            break;

        case "Supervisor":
            this.navegarUrl(rutaDefault);
            break;

        case "Distribuidor":
            this.navegarUrl(rutaDefault);
            break;

        case "Organizacion":
            this.navegarUrl('/rutas/listado');
            break;


        default:
          
          this.alertService.confirm({ 
            title:   this.translate.instant('log.perfilAsignadoSinNadaParaMostrar'), 
            message:  this.translate.instant('log.perfilAsignadoSinNadaMensaje'), 
         
          }).then(data=>{
            console.log("Ok Perfil Asignado sin nada para mostrar");
            setTimeout(() => {  
                this.signOut();
            }, 1000);             
                
            
          }).catch(error=>{  
            console.log("Error Perfil Asignado sin nada para mostrar",error);
            
          });

          break;
          
        }  
    this.storageService.removeItem("urlCtrlClick");
    this.storageService.removeItem("urlParamsAnterior");      
    } else {
      console.log('usuario sin perfil asignado');
      this.navegarUrl('/login');
    }

  }
  navegarUrl(url:string) {
    //si hay parametros en la url anterior los cargo a la url
    if(this.getNavigationExtrasAnterior() && this.getNavigationExtrasAnterior()?.queryParams){
      this.router.navigate([url],this.getNavigationExtrasAnterior()); 
    }else{
      this.router.navigate([url]); 
    }
  }


}
