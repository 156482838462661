import { OverlayKeyboardDispatcher } from '@angular/cdk/overlay';
import { AbstractControl, FormArray } from '@angular/forms';
import { FormGroup, FormControl, FormBuilder, Validators, FormsModule } from '@angular/forms';

export function verificarModeloConForm(form: any,t:any, listadoCamposNoDeseados:string[] ):VerificarModeloConFormResult{

      let showConsole=true;
      let result: VerificarModeloConFormResult;
  
      if(showConsole) console.log('funcionGo','verificarModeloConForm A');
      if(showConsole) console.log('valoresDebug','form.value A0:',form.value);
      if(showConsole) console.log('valoresDebug',' A0 t:',t);

      if(!form || form?.value===undefined){
        result ={propiedadesFaltantesEnForm:"Formumario sin Datos", propiedadesEnFormNoEsModelo:''};
      } else{
        result=verificarModeloConFormValues(form.value,t, listadoCamposNoDeseados);
      }
     
      // getFormKeys(form);
      return result;
}

export function verificarModeloConFormValues(formValues: any,t:any, listadoCamposNoDeseados:string[] ):VerificarModeloConFormResult{
        
        let showConsole=true;
  
        if(showConsole) console.log('funcionGo','verificarModeloConForm A');
        if(showConsole) console.log('valoresDebug','formValues:',formValues);
        if(showConsole) console.log('valoresDebug',' A0 t:',t);

       
        let propiedadesModelo             : string[]= [];
        if(t){
          propiedadesModelo= Object.getOwnPropertyNames(t);
        }
       
        //console.log('propiedadesMopropiedadesModelodelo',t);
        //console.log('propiedadesMopropiedadesModelodelo',propiedadesModelo);

        let propiedadesFaltantesEnForm    : string='';
        let propiedadesEnFormNoEsModelo   : string='';

        // Verifico que los NoDesados no esté en el modelo.
        listadoCamposNoDeseados.map(nodeseado=>{
          //console.log('noDesado',nodeseado);
          let noDesadosEnModelo=propiedadesModelo.find(prop=>prop==nodeseado);
          //console.log('noDesadosEnModelo',noDesadosEnModelo);
          if (noDesadosEnModelo){
            propiedadesEnFormNoEsModelo+=noDesadosEnModelo+ ' (no Desado es parte del modelo)\n'
          }
        });
        

        // le sumo al modelo los campos no seados para asegurarme que esté en 
        // el fomulario.
        propiedadesModelo=propiedadesModelo.concat(listadoCamposNoDeseados);
        console.log('noDesadosEnModelo propiedadesModelo',propiedadesModelo);

        for (let index = 0; index < propiedadesModelo.length; index++) {
          const propiedad = propiedadesModelo[index];
//          console.log('noDesadosEnModelo propiedad',propiedad);
//          console.log('noDesadosEnModelo form.value[propiedad]',formValues[propiedad]);
//          console.log('noDesadosEnModelo t[propiedad]',t[propiedad]);
        
          if(!formValues || formValues[propiedad]===undefined){
            propiedadesFaltantesEnForm += propiedad +' ';            
            
          }else if(typeof t[propiedad]=='object' &&  formValues[propiedad] !== null && t[propiedad]!==null ){
            // console.log('<<<  formValues[propiedad] ',formValues[propiedad]);
            // console.log('<<<  typeof formValues[propiedad] ',typeof formValues[propiedad]);
            // console.log('<<<  propiedad ', propiedad);
            // console.log('<<<  t[propiedad] ', t[propiedad]);
            let respuesta=this.verificarModeloConFormValues(formValues[propiedad],t[propiedad],[]);
            if(respuesta.propiedadesFaltantesEnForm){
              propiedadesFaltantesEnForm+= propiedad+' ( '+respuesta.propiedadesFaltantesEnForm+ ')';
            }
            

          }else{
            continue;
          }
          
        }

        if(showConsole) console.log('propiedadesFaltantesEnForm',propiedadesFaltantesEnForm);
        
        if(showConsole) console.log('propiedadesFaltantesEnForm   form.value 0', formValues );

        for (var clave in formValues){
          let p=propiedadesModelo.filter(propiedad=>propiedad==clave);
          // if(showConsole) console.log('buesqueda  clave',clave );
          // if(showConsole) console.log('buesqueda   p', p );
          // if(showConsole) console.log('buesqueda  formValues a',formValues);
          // if(showConsole) console.log('buesqueda  t[clave] a',t[clave]);
          if(Object.prototype.toString.call(formValues[clave])=="[object Object]"){
        
            // if(showConsole) console.log('buesqueda   formValues b', formValues[clave]);
            
 
            if(t[clave]!==undefined ){
              // console.log('<<<  formValues[clave] ',formValues[clave]);
              // console.log('<<<  typeof formValues[clave] ',typeof formValues[clave]);
              // console.log('<<<  clave ', clave);
              // console.log('<<<  t[clave] ', t[clave]);
              
              let respuesta=this.verificarModeloConFormValues(formValues[clave],t[clave],[]);
              if(respuesta.propiedadesEnFormNoEsModelo){
                propiedadesEnFormNoEsModelo+= clave+' ( '+respuesta.propiedadesEnFormNoEsModelo+ ')';
              }
            } else {
              propiedadesEnFormNoEsModelo+= clave+' (no existe)';
            }

          }else if(p.length==1){
            continue;
          }else{
            propiedadesEnFormNoEsModelo +=clave +' ';
          }
        }
 
        // if(showConsole) console.log('propiedadesEnFormNoEsModelo',propiedadesEnFormNoEsModelo);
        let result: VerificarModeloConFormResult={propiedadesFaltantesEnForm:propiedadesFaltantesEnForm, propiedadesEnFormNoEsModelo:propiedadesEnFormNoEsModelo};

       
        return result;
   
}

export function getFormKeys(form:any):String[]{
  console.log('claveFrom form',form);  
  let arrayPath:String[]=[];
  for (var clave in form.value){
    //console.log('claveFrom clave',clave);  
    //console.log('claveFrom form.value[clave]',form.value[clave]);  
    let path: string='';
    //console.log('claveFrom Object',Object.prototype.toString.call(form.value[clave]));  
    if(Object.prototype.toString.call(form.value[clave])=="[object Object]"){
      const auxForm=form.value[clave];
      let keyPathArray=getFormKeyValue(auxForm);
      //console.log('claveFrom keyPathArray',keyPathArray);    
      keyPathArray.map(keyPath=> {
        path=clave+'.'+keyPath;
        arrayPath.push(path);
      });
   
    } else {
      path=clave;
      arrayPath.push(path);
      //console.log('claveFrom arrayPath 1',clave,arrayPath);  
    }
     
  }

  //console.log('claveFrom arrayPath final',arrayPath);  
  return arrayPath;
}

export function getFormKeyValue(form:any):string[]{
  console.log('claveFrom getFormKey',form);  
  let arrayPath:string[]=[];
  for (var clave in form){
    let keyPath: string='';
    if(Object.prototype.toString.call(form[clave])=="[object Object]"){
      const auxForm=form[clave];
      let respuesta=getFormKeyValue(auxForm);
      console.log('claveFrom',respuesta);     
      keyPath=clave+'.'+respuesta;
    }  else {
      keyPath=clave;
    }
    arrayPath.push(keyPath);
  }
  return arrayPath;
}


export interface  VerificarModeloConFormResult{
    propiedadesFaltantesEnForm:string;
    propiedadesEnFormNoEsModelo:string;
}

export function   cloneAbstractControl<T extends AbstractControl>(control: T): T {
  let newControl: T;

  if (control instanceof FormGroup) {
    const formGroup = new FormGroup({}, control.validator, control.asyncValidator);
    const controls = control.controls;

    Object.keys(controls).forEach(key => {
      formGroup.addControl(key, cloneAbstractControl(controls[key]));
    })

    newControl = formGroup as any;
    
  } else if (control instanceof FormArray) {
    const formArray = new FormArray([], control.validator, control.asyncValidator);

    control.controls.forEach(formControl => formArray.push(cloneAbstractControl(formControl)))

    newControl = formArray as any;
    
  } else if (control instanceof FormControl) {
      newControl = new FormControl(control.value, control.validator, control.asyncValidator) as any;

  } else {
    throw new Error('Error: unexpected control value');
  }

  if (control.disabled) newControl.disable({emitEvent: false});

  return newControl;
} ;


export function igualarDocumentoConForm(form: any, json:any, destino?:string):any{
  /* destino =
   * 'destinoForm'
   * 'destinoListado'
   */
  let showConsole=false;

  if(showConsole) console.log('funcionGo','fn.igualarDocumentoConForm');
  if(showConsole) console.log('valoresDebug','form.value:',form.value);
  if(showConsole) console.log('valoresDebug','documento:',json);

  destino = (destino===undefined) ? 'destinoForm' : destino;
  if(showConsole) console.log('valoresDebug','destino:',destino);

  let jsonResultado:any={};

  //if(showConsole) console.log("Patch form.value",form.value);

  for (var clave in form.value){
    jsonResultado[clave]=null;

    if(showConsole) console.log('valoresDebug',
        'clave:', clave,
        `form.value[${clave}]:`,form.value[clave],
        `typeof form.value[${clave}]:`,typeof form.value[clave]);

    let subestructura=false;
    let value = form.value[clave];
    let formato=Object.prototype.toString.call(value);
    //let isArrayForm=this.isArray(value); 
    let isArrayForm=(value=='Array') ? true : false;
    
    if(showConsole) console.log('valoresDebug','formato:',formato);
    if(showConsole) console.log('valoresDebug','isArrayForm:',isArrayForm);

    if(value!=null) {
      if(value.constructor === Object) {
        if(Object.entries(value).length === 0) {
            subestructura=true;
        }

      }
    }

    if (typeof form.value[clave] == "object" && 
        form.value[clave] !== null && 
        formato != "[object Date]" && !isArrayForm) {

       if(showConsole) console.log('valoresDebug','Detectó Subestructura en Form:',`form.value[${clave}]`);

       if(destino=='destinoListado' && json[clave]===undefined) {
           jsonResultado[clave] = null;
           if(showConsole) console.log('valoresDebug',`No existe en Documento, pisó ${clave} con:`,null);

       } else if(destino=='destinoListado' && json[clave]==null) {
           jsonResultado[clave] = null;
           if(showConsole) console.log('valoresDebug',`Pisó ${clave} con:`,null);

       } else if(destino=='destinoForm' && (json[clave]===undefined || json[clave]==null)) {
           jsonResultado[clave] = form.value[clave];
           if(showConsole) console.log('valoresDebug',`No existe en Documento, pisó ${clave} con:`,`form.value[${clave}]`,form.value[clave]);

       } else {
         
           jsonResultado[clave]={};
           const auxForm={};
           auxForm['value'] = form.value[clave];
           jsonResultado[clave] = this.igualarDocumentoConForm(auxForm, json[clave] );
       }

    } else if (json && isArrayForm){
      
        let isArrayJson=Array.isArray(json[clave]);                 
        if(showConsole) console.log('valoresDebug',`isArrayJson`,isArrayJson);
        if(isArrayJson) {
            jsonResultado[clave] = json[clave];
            if(showConsole) console.log('valoresDebug',`Pisó ${clave} con el valor:`,json[clave]);                                
        } else { 
            jsonResultado[clave] = [];
            if(showConsole) console.log('valoresDebug',`Es array y vino un null (not array) piso ${clave} con []`);
        }                

    } else if (json && !isArrayForm){
        
        if(showConsole) console.log('valoresDebug',`if( json.hasOwnProperty(${clave}) ):`,json.hasOwnProperty(clave));
        if (json.hasOwnProperty(clave)) {
            jsonResultado[clave] = json[clave];
            if(showConsole) console.log('valoresDebug',`Pisó ${clave} con el valor:`,json[clave]);                    
        } else {
            //jsonResultado[clave]=null;
            jsonResultado[clave] = form.value[clave];
            if(showConsole) console.log('valoresDebug',`>>>>>> No existe clave ${clave} y el  json y se completa  con `,`form.value[${clave}]`,form.value[clave]);
        }
    } else {
          //jsonResultado[clave]=null;
          jsonResultado[clave] = form.value[clave];
          if(showConsole) console.log('valoresDebug',`Pisó ${clave} con:`,`form.value[${clave}]`,form.value[clave]);
    }
  }

  if(showConsole) console.log('valoresDebug',`jsonResultado`,jsonResultado);
  return jsonResultado;
}