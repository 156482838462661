<div class="custom-file" style="margin-bottom:5px;" *ngIf="accionForm!='consultar'">

    <input type="file" class="custom-file-input"
           *ngIf="accionForm!='consultar'"
           id="imageFile" #customInput
           accept="image/x-png,image/gif,image/jpeg"
           (change)="inputChange(customInput)">
    <label class="custom-file-label" for="customFile">{{fn.traducir('componente-croppie.seleccioneArchivo')}}</label>
</div>

<img *ngIf="image"
     [src]="image" id="image"
     width="100%" style="border:1px solid black;">

<div class="form-group" *ngIf="image">
      <div class="input-group">
          <input [value]='nombreArchivo' class="form-control" type="text" readonly
                 (click)="fn.goToLink(image)">
          <div class="input-group-append" style="background:#e9ecef;">
              <img [src]="extensionIcono" style="height:32px;"
                 (click)="fn.goToLink(image)">
          </div>
      </div>
</div>

<img *ngIf="!image"
     src="assets/img/app/no-image.png"
     width="100%"><br *ngIf="!image">

<i *ngIf="image && accionForm!='consultar'"
    class="fa fa-times delete-img" (click)="inputBorrar()"
    style="float:right; color:red;"></i>

<br>
