import { Component, ViewEncapsulation, Input, Output, OnInit, AfterContentChecked, DoCheck, AfterViewInit, AfterContentInit, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FuncionesService }  from '@maq-funciones';


@Component({
  selector: 'lib-shared-multiselect-base',
  templateUrl: './multiselect-base.component.html',
  styleUrls: ['./multiselect-base.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MultiSelectBaseComponent implements OnInit, AfterContentChecked, DoCheck {

    @Input() public datos: any[]=[];
    @Input() public placeHolder: string=null;
    @Input() public nameField: string;

    @Input() public datosSeleccionados: any[]=[];

    @Output() onChangeSelection= new EventEmitter<any[]>();

    dropdownList = [];
    dropdownSettings:any = {};    
    multiSelectTexts:any = {};
    textos:any={
        "multiSelectTexts.allSelected"            : "Seleccionar todo",
        "multiSelectTexts.checkAll"               : "Seleccionar todo",
        "multiSelectTexts.checked"                : "Item seleccionado",
        "multiSelectTexts.checkedPlural"          : "ítems seleccionados",
        "multiSelectTexts.defaultTitle"           : "Seleccione ítem",
        "multiSelectTexts.noEncontroResultados"   : "No se encontraron resultados",
        "multiSelectTexts.searchPlaceholder"      : "Buscar ítem...",
        "multiSelectTexts.uncheckAll"             : "Cancelar Selección",
        "navegabilidad.agregar"                   : "Agregar",
        "navegabilidad.buscar"                    : "Buscar"
    };

    public toggle:boolean;
    
    public disabledDropDown=false;
    public finalizoCarga=true;

    variblesATraducir:string[]=
                        ['multiSelectTexts.checkAll', 
                         'multiSelectTexts.uncheckAll',
                         'multiSelectTexts.checked',
                         'multiSelectTexts.checkedPlural',
                         'multiSelectTexts.searchPlaceholder',
                         'multiSelectTexts.defaultTitle',
                         'multiSelectTexts.allSelected',
                         'multiSelectTexts.noEncontroResultados',
                         'navegabilidad.agregar',
                         'navegabilidad.buscar'
       ]

    constructor(private translate: TranslateService,
                public fn: FuncionesService,
                private changeDetectorRef: ChangeDetectorRef)  { 
                  
      this.inicializarVariablesTraducibles();

    }

    // ngAfterContentChecked() {
    // ngAfterContentInit() {
    // ngAfterViewInit() { 
       
    ngAfterContentChecked() { 
        //console.log("multiselectBase ngAfterContentChecked");
        
        if (!this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        }                
        
    }
      
    inicializarVariablesTraducibles()  {

      this.translate.get(this.variblesATraducir).subscribe((textos) => {
           this.textos=textos;
           this.cargaVariablesTraducibles();
      });
    
      this.translate.onLangChange.subscribe(() => {
          // console.log('langChange');
          this.translate.get(this.variblesATraducir).subscribe((textos) => {
             this.textos=textos;
             this.cargaVariablesTraducibles();
          });
      });
    }
    
    cargaVariablesTraducibles(){
      console.log("multiselectBase cargaVariablesTraducibles currentLang",this.translate.store.currentLang);  
      
      this.setearPlugin();
      
      this.setearDropdownList();

    }
    
    ngDoCheck() {
        
        this.setearPlugin();
    }
    
    
    ngOnInit() {


      this.setearPlugin();
      
      this.setearDropdownList();
      
    }
    
    setearDropdownList() {
      
        this.dropdownList=[];
        if(Array.isArray( this.datos ) && this.datos) {
          
            for(let i=0; i<this.datos.length; i++) {            
                let obj={};
                for(let key in this.datos[i]) {
                    if(key!='settings' && key!='organizacionKNAI' && key!='distribuidorKN') {
                        obj[key] = this.datos[i][key];
                    }
                    if(key=='nombre') {
                        obj[key] = this.translate.instant( obj[key] );      
                    }
                }
                this.dropdownList.push(obj);                
            }  
        }
        console.log("multiselectBase this.datos",this.datos);
        console.log("multiselectBase this.dropdownList",this.dropdownList);        
    }
    
    setearPlugin() {
        this.dropdownSettings = { 
            singleSelection       : false,
            enableSearchFilter    : true,
            addNewItemOnFilter    : true,
            escapeToClose         : true,
            disabled              : this.disabledDropDown,
            classes               : 'myclass custom-class-example',
            primaryKey            : 'key',
            labelKey              : 'nombre',
            
            text                  : this.textos['multiSelectTexts.searchPlaceholder'],
            selectAllText         : this.textos['multiSelectTexts.checkAll'],
            unSelectAllText       : this.textos['multiSelectTexts.uncheckAll'],
            noDataLabel           : this.textos['multiSelectTexts.noEncontroResultados'],
            searchPlaceholderText : this.textos['navegabilidad.buscar'],
            addNewButtonText      : this.textos['navegabilidad.agregar']
        };       
        
        //console.log("multiselectBase textos",this.textos);

        if(this.placeHolder!=null || this.placeHolder!=undefined){
            this.dropdownSettings['text']=this.translate.instant( this.placeHolder );    
        }
    }
    
    onItemSelect(item:any){
      console.log("multiselectBase onItemSelect item",item);
      console.log("multiselectBase onItemSelect datosSeleccionados",this.datosSeleccionados);
    //   this.datosSeleccionados.push(item);
      
      this.emitValue();      
    }
    
    OnItemDeSelect(item:any){
        console.log("multiselectBase onItemDeSelect item",item);
        console.log("multiselectBase onItemDeSelect datosSeleccionados",this.datosSeleccionados);
        
        this.emitValue();
    }
    
    onSelectAll(items: any){
        //console.log("multiselectBase onSelectAll",items);
        console.log("multiselectBase onSelectAll datosSeleccionados",this.datosSeleccionados);
        
        this.emitValue();
    }
    
    onDeSelectAll(items: any){
        //console.log("multiselectBase onSelectAll",items);
        console.log("multiselectBase onSelectAll datosSeleccionados",this.datosSeleccionados);
        
        this.emitValue();
    }    
    
    emitValue() {

      let datosParaEmitir:any[]=[];
      if(Array.isArray( this.datosSeleccionados )) {
        
          for(let i=0; i<this.datosSeleccionados.length; i++) {  
              let element=this.datosSeleccionados[i];
              element['nombre']=element['nombre']?this.translate.instant(element.nombre):null;
              datosParaEmitir.push(element);                
          }  
      }
      console.log("multiselectBase datosParaEmitir",datosParaEmitir);        
    
      this.onChangeSelection.emit(datosParaEmitir);        
          
    }

    onCloseSelection(selectedItems: any[]) {
        console.log("multiselectBase onCloseSelection",  selectedItems);
    }

}

