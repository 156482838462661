import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';

@Pipe({
  name: 'fecha'
})

 export class FechaPipe implements PipeTransform {
    transform(
        value: any,
        language: string,  /* es, en */
    ): string | null {
        //console.log("fechaPipe",value, language);
        let rta='';
        if(value==null || value===undefined) {
          //console.log("fechaPipe Null");
          rta='';
        } else { 

          let isEmptyJson = (Object.entries(value).length === 0 && value.constructor === Object);

          if(isEmptyJson) {
              console.error("fechaPipe {} !!!", value);
              rta='';
          } else {

             let fecha;

             let typeMillis = typeof value.toMillis;
             let isTimestamp = (typeMillis == "function");

              let formatoFechaHora=Object.prototype.toString.call(value);
              //console.log("fechaPipe type",formatoFechaHora, value);

              if(formatoFechaHora=="[object String]") { // Wed Feb 05 2020 17:32:13 GMT-0300 (hora estándar de Argentina)
                  fecha=new Date(value);

              // } else if(formatoFechaHora=='[object Object]') { // // Timestamp { seconds: 1580936640, nanoseconds: 0 }
              } else if(isTimestamp) { // // Timestamp { seconds: 1580936640, nanoseconds: 0 }
                  console.error("fechaPipe recibió timestamp !!!",value, typeMillis);
                  let milisegundos = value.toMillis();
                  fecha = new Date(milisegundos);

              } else if(formatoFechaHora=="[object Date]") {     // Ya vino en formato fecha
                  fecha=value;

              } else {
                  // otro formato
                  fecha=value;
              }

              if(language=='en') {
                rta=formatDate(fecha, 'mm/dd/yyyy', 'en');
              } else {
                rta=formatDate(fecha, 'dd/MM/yyyy', 'es');
              }  
          }    
        }
        return rta;
    }
}