<input type="text" 
    [owlDateTime]="filtro_periodo"
    [owlDateTimeTrigger]="filtro_periodo" 
    id="filtro_periodo{{nameField}}" 
    class="form-control filtroGrilla" 
    placeholder="{{'claseDateTime.periodo' | translate}}" 
    style="background:white;" 
    [value]="value"
    [selectMode]="'range'" READONLY>  
<owl-date-time #filtro_periodo
    pickerType="calendar"
    (afterPickerClosed)="closeFiltroPeriodo($event)"></owl-date-time>                                    
      
