<div class="dropdown d-inline-block">
    <a class="dropdown-toggle no-caret pl-2 pr-2" id="user-menu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i class="fa fa-user-o" aria-hidden="true"></i>
    </a>

    <div class="dropdown-menu dropdown-menu-right rounded-0 p-0 mt-3 box-shadow" aria-labelledby="user-menu"  
         style="width:240px;">
        <div class="user-menu">
            <div class="user-info text-center p-3">

                <!-- <img src="assets/img/users/user.jpg" alt="user-img" class="user-img rounded-circle"> 
                                    -->
                <div *ngIf="authService">
                    <img *ngIf="fotoUsuario" src="{{fotoUsuario}}" alt="user-img" class="user-img rounded-circle">
                </div>               
                <p class="mt-1 mb-0"> 
                    <B>{{authService.currentUserDisplayName}}</B><br>
                    <small>{{'claseDateTime.desde' | translate}} {{fechaHoraCreacion | date:'DD/MM/AAAA':translate.currentLang:utc}}</small><br>
                    <small><b>{{nombrePerfil}}</b></small>
                </p>
            </div>
            <div class="text-center">
                <!-- <a class="dropdown-item" routerLink="/usuarios/detalle"><i class="fa fa-user mr-2"></i>{{'claseFicha.perfil' | translate}}</a> -->
                <a class="dropdown-item" routerLink="/usuarios/detalle"><i class="fa fa-user mr-2"></i>{{'claseFicha.perfil' | translate}}</a>
    <!--             <a class="dropdown-item" routerLink="/profile"><i class="fa fa-user mr-2"></i>Profile</a>
                <a class="dropdown-item" routerLink="/"><i class="fa fa-cog mr-2"></i>Settings</a>
     -->           <!-- <a class="dropdown-item" routerLink="/"><i class="fa fa-lock mr-2"></i>Lock screen</a> -->
                <!-- <a class="dropdown-item mb-1" (click)="logOut()"><i class="fa fa-power-off mr-2"></i>Log out</a> -->
                <a class="dropdown-item mb-1">Ver {{version}}</a>
                
            </div>
           
        </div>
    </div>
</div>
