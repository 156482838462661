import { ColorsAndHover } from '@settings/maqueta/mocks/colors/colors';

export function getColorMotivoParada(indexColor:number = 0):object{
  if(indexColor >100){
      indexColor=indexColor % 100; //elijo un color de 100
  }
  return ColorsAndHover[indexColor];
}

export const propertysIgnoreInMotivosParadas: string[]=[
    'idOrganizacion', 'organizacionKey', 'organizacionNombre',
    'idSucursal', 'sucursalKey', 'sucursalNombre',
    'idAreaNegocio', 'areaNegocioKey', 'areaNegocioNombre',
    'fechaHoraInicioPlaneada', 'fechaHoraInicioPlaneadaString',
    'fechaHoraInicioPlaneadaStringDesde', 'fechaHoraInicioPlaneadaStringHasta',
    'estadoParadaKeyWithMotivoAsociado', 'mesFechaPlaneada', 'anioFechaPlaneada',
    'integranteChoferNombre', 'integranteChoferKey',
    'ubicacionNombre', 'ubicacionKey'
];

export function completeDocumentMotivosParadasWithKeys(document:any,tipoParada:string):any{
    let documentReturn = {};

    let abreviacionTipoParada:string =  getAbreviacionTipoParada(tipoParada);
    Object.keys(document).forEach(propiedad => {
      if(propertysIgnoreInMotivosParadas.indexOf(propiedad)==-1){
        if(tipoParada == document['estadoParadaKeyWithMotivoAsociado'] && propiedad.includes(abreviacionTipoParada)){
            documentReturn[propiedad] = 0;
        }else{
            documentReturn[propiedad] = null;
        }
      }else{
        documentReturn[propiedad] = document[propiedad];
      }
  });
  return documentReturn;
}


export function getAbreviacionTipoParada(tipoParada:string):string{
    let returnAbreviacion:string = '';
    switch (tipoParada) {
      case 'Rechazada':
        returnAbreviacion = '(Rec)';
      break;
      case 'ParadaPendiente':
        returnAbreviacion = '(P.P.)';
      break;
      case 'Cancelada':
        returnAbreviacion = '(Can)';
      break;
      case 'ParadaEnCurso':
        returnAbreviacion = '(P.C.)';
      break;
      case 'EntregaParcial':
        returnAbreviacion = '(E.P.)';
      break;
      case 'EntregaCompleta':
        returnAbreviacion = '(E.C.)';
      break;
      case 'Replanificada':
        returnAbreviacion = '(Rep)';
      break;
     
      default:
        // Lógica predeterminada si no coincide con ninguna opción
      break;
    }
    return returnAbreviacion;
}
  