<div class="custom-file" style="margin-bottom:5px;" *ngIf="accionForm!='consultar'">

    <input type="file" class="custom-file-input" 
           *ngIf="accionForm!='consultar'"
           id="archivoFile" #customInput
           (change)="inputChange(customInput)">
    <label class="custom-file-label" for="customFile" translate>componente-croppie.seleccioneArchivo</label>
</div>

<div class="form-group" *ngIf="archivo">
      <div class="input-group">
          <input [value]='nombreArchivo' class="form-control" type="text" readonly 
                 (click)="fn.goToLink(archivo)">
          <div class="input-group-append" style="background:#e9ecef;">
              <img [src]="extensionIcono" style="height:32px;" 
                 (click)="fn.goToLink(archivo)"> 
          </div>
      </div>
</div>

<i *ngIf="archivo && accionForm!='consultar'" 
    class="fa fa-times delete-img" (click)="inputBorrar(archivoName)" 
    style="float:right; color:red;"></i>

<ang-music-player *ngIf="archivo && archivo.includes('.mp3')" 
    [audioList] = audioList
    width = "350px"></ang-music-player>
<br>

