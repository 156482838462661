import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule ,ReactiveFormsModule} from '@angular/forms';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { NgxPaginationModule } from 'ngx-pagination';

import { NgxMaskModule, IConfig } from 'ngx-mask' // mascaras para inputs  https://www.npmjs.com/package/ngx-mask
export const options: Partial<IConfig> | (() => Partial<IConfig>)={};
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Routes, RouterModule, PreloadAllModules  } from '@angular/router';

// Carousel del Cabezal
import { OwlModule } from 'ngx-owl-carousel';

// import { Ng2SmartTableModule } from 'ng2-smart-table';
import { SharedModule }                   from '@maq-shared/shared.module';

import { HttpClientModule }               from '@angular/common/http';
import { TranslateModule }                from '@ngx-translate/core';

import { PanelWebPage }                   from './panel-web-page/panel-web.page';
import { HeaderWebComponent }             from './headerWeb/header-web';
import { FooterWebComponent }             from './footerWeb/footer-web';


import {Injectable, Renderer2} from '@angular/core';

// const routes: Routes = [
//   // { path: '', redirectTo: 'BienvenidaComponent', pathMatch: 'full'},

//   { path: '', component: PanelWebPage, data: { breadcrumb: 'public.Bienvenida' } },
 
// ];

@NgModule({
  // schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  declarations: [
    PanelWebPage,
    HeaderWebComponent,
    FooterWebComponent,
  	],
  imports: [
    RouterModule,
    CommonModule,
    SharedModule,
    TranslateModule,
    OwlModule
  ]
})
export class panelWebModule { }

