<!-- 
<div class="dropdown-container">

<div class></div> -->
    <angular2-multiselect [data]="dropDownList" [(ngModel)]="value == null || value == undefined ? columnasVisiblesMultiSelect : value" 
        [settings]="dropDownSettings" 
        (onSelect)="onItemSelect($event)" 
        (onDeSelect)="OnItemDeSelect($event)"
        (onSelectAll)="onSelectAll($event)"
        (onDeSelectAll)="onDeSelectAll($event)">
    </angular2-multiselect>
<!-- </div>     -->
