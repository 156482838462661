

export enum TIPO_PERFIL {
    Desarrollador   = "Desarrollador",
    Supervisor      = "Supervisor",
    Distribuidor    = "Distribuidor",
    Organizacion    = "Organizacion",
    UsuarioMovil    = "UsuarioMovil"
};

export const LISTADO_TIPOS_PERFIL=[TIPO_PERFIL.Desarrollador, TIPO_PERFIL.Supervisor, TIPO_PERFIL.Distribuidor, TIPO_PERFIL.Organizacion, TIPO_PERFIL.UsuarioMovil];

export const PERFIL_DESARROLLADOR_SUPERV_DISTRIBUIDOR= [TIPO_PERFIL.Desarrollador,TIPO_PERFIL.Supervisor,TIPO_PERFIL.Distribuidor];
// export const LISTADO_TIPOS_JERARQUIA=['Desarrollador (20)','Supervisor (15)','Distribuidor (10)', 'Organizacion (5)', 'usuario móvil (0)'];
export const LISTADO_JERARQUIA=[20,19,18,17,16,15,14,13,12,11,10,9,8, 7, 6,5,4,3,2,1,0]; 
                        