export class AppSettings2 {

         public equipo: Equipo;
    
         public app: {
              name: string,
              title: string,  
              descripcion: string,
              keywords: string,
              author: string,          
              manejaDistribuidores: boolean,
              manejaOrganizaciones: boolean,
              tipoMapa: string,
              idiomasHabilitados:  string[],
              idiomaDefault: string,
              regionDefault: string,
              modulos: {
                autorizacion: {
                   habilitado:         boolean,  
                   loginOffLine:       boolean, 
                   tipoRegistracion:   string,
                   logDisponibles:LogDisponibles
                }   
              },
              smtp: {
                 host       : string,
                 port       : string,
                 email      : string,
                 password   : string
                 tls        : boolean,
                 tlsCifrado : string,
                 debug      : boolean,
                 logger     : boolean
              }
         };

         public organizacion: {
            utilizaDistribuidores   : boolean,
            utilizaOrganizaciones   : boolean,
            utilizaSucursales       : boolean,
            utilizaAreasNegocio     : boolean,
            utilizaUbicaciones      : boolean,
            utilizaGaleriaFotos     : boolean,
            utilizaTextoDescripcion : boolean,  
            utilizaTextoHistoria    : boolean,  
            utilizaCantidadLicencias: boolean,
            utilizaAtributosKipBip  : boolean,
            utilizaTrackeo          : boolean,
            utilizaWazeGoogle       : boolean,   
            utilizaIdiomas          : boolean,
            utilizaMonedas          : boolean,
            utilizaHorarioAtencion  : boolean,
            utilizaFtp              : boolean,                          
         };
     
         public panel: {
                aplicacionKey:               string,              
                headerTitle:                 string,
                footerTitle:                 string,                
                menu:                        string,
                menuType:                    string,
                showMenu:                    boolean,
                navbarIsFixed:               boolean,
                footerIsFixed:               boolean,
                sidebarIsFixed:              boolean,
                showSideChat:                boolean,
                sideChatIsHoverable:         boolean,
                showSkin:                    boolean,
                skin:                        string,
                funcionalidadesDisponibles:  FuncionalidadesDisponibles,
         };
           
         public cotizaciones: {
               dolarOficial: {
                  api: string,
                  cual: string,        
                  pesosRestados: number,
               }, 
               dolarDivisa: {
                  api: string,
                  cual: string,        
                  pesosRestados: number,
               }, 
               dolarBlue: {
                  api: string,
                  cual: string,        
                  pesosRestados: number,
               }, 
         };
              
           
         public appCelular: {         
               aplicacionKey: string,
         };
           
         public webSite: {              
               aplicacionKey: string,
               fontFamily: string,
         };
           
         public cliente: {
             nombre:           string,
             web:              string,
             email:            string,
             email2:           string,
             copyrightYear:    string,
             favicon:          string,
             logo:             string,
             logoMetatags:     string,  
             logos: {
               landing       : string,
               panelHeader   : string,
               celularHeader : string,
               login         : string,
               loading       : string,
               metatags      : string 
             },            
             direccion: {
                calle:         string,
                numero:        string,
                bloque:        string,  
                piso:          string,
                departamento:  string,
                codigoPostal:  string,
                ciudad:        string,
                partido:       string,
                provinciaKN: {
                   key:        string,
                   nombre:     string,
                },   
                paisKN: {
                   key:        string,
                   nombre:     string,
                },   
                geoPoint: {
                   latitud:    string,
                   longitud:   null
                },   
                timeZone:      string,
                idiomaPais:    string,
             },
             
             telefono: {
                tipoTelefono1:    string,
                numeroTelefono1:  string,
                tipoTelefono2:    string,
                numeroTelefono2:  string,
                tipoTelefono3:    string,
                numeroTelefono3:  string,
                tipoTelefono4:    string,
                numeroTelefono4:  string,
             },
           
             redSocial: RedSocial,
         };
        
      constructor(init?:Partial<AppSettings2>) {    
         Object.assign(this, init);    
      }

    
   setVersionSO(versionSO:string):void{
      this.equipo.versionSO=versionSO;
   }

   getVersionSO():string{
     return this.equipo.versionSO;
   }

   setPlataforma(plataforma:string){
      this.equipo.plataforma=plataforma;
   }

   getPlataforma():string{
     return this.equipo.plataforma;
   }

   setIsEmulador(isEmulador:boolean){
      this.equipo.isEmulador=isEmulador;
   }

   getIsEmulador():boolean{
     return this.equipo.isEmulador;
   }

   setScreenWidth(screenWidth:number){
      this.equipo.screenWidth=screenWidth;
   }

   getScreenWidth():number{
     return this.equipo.screenWidth;
   }


   setScreenHeigth(screenHeigth:number){
      this.equipo.screenHeigth=screenHeigth;
   }

   getScreenHeigth():number{
     return this.equipo.screenHeigth;
   }

   setFabricante(fabricante:string){
      this.equipo.fabricante=fabricante;
   }

   getFabricante():string{
     return this.equipo.fabricante;
   }


   setModelo(modelo:string){
      this.equipo.modelo=modelo;
   }

   getmodelo():string{
     return this.equipo.modelo;
   }


   setBatteryLevel(batteryLevel:number){
      this.equipo.batteryLevel=batteryLevel;
   }

   getBatteryLevel():number{
     return this.equipo.batteryLevel;
   }

   setEstado(estado:string){
      this.equipo.estado=estado;
   }

   getEstado():string{
     return this.equipo.estado;
   }

   getEquipo():any{
     return this.equipo;
   } 
   
   setIsOnLine(isOnLine:boolean){
      this.equipo.isOnLine=isOnLine;
   }

   getIsOnLine():boolean{
      return   this.equipo.isOnLine;
   }   
        
}
    

export class RedSocial {
        facebook:    string | null;
        twitter:     string | null;
        google:      string | null;
        instagram:   string | null;
        pinterest:   string | null;
        linkedin:    string | null;
        }

export class Cliente {
        nombreCliente   : string;
        webClineteURL   : string;
        mailCliente     : string;
        copyrightYear   : string;
}

//Funcionalidades habilitadas en 
export class FuncionalidadesDisponibles {
        traductions   : boolean;
        fullscreen    : boolean;
        applications  : boolean;
        sideChat      : boolean;
        messages      : boolean;
        userMenu      : boolean;
        cotizaciones  : boolean;
        mostrarOnLine : boolean;

}

// seleccion de tipos de validaciones de usuarios disponibles en LoginPage
export class LogDisponibles {
        google    : boolean;
        facebook  : boolean;
        twitter   : boolean;
       

}      


export class Equipo {

   versionSO:string;                 // Version del sistema operativo (windows 10, android 5, 6, etc)
   plataforma:string;                // web-ios-android
   isEmulador:boolean;               // es un emulador o un caso real
   screenWidth:number;               // ancho de la pantalla
   screenHeigth:number;              // alto de la pantalla
   fabricante:string;                // alto de la pantalla
   modelo:string;                    // alto de la pantalla
   batteryLevel:number;              // alto de la pantalla
   estado:string;                    // Activo-Background-Kill
   isOnLine              : boolean;
   tieneServicioDatos    : boolean;
   lenguaje              : string
   }
   


//  --------------------

     

 
