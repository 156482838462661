<form [formGroup]="form" (change)="onChangeField()">
    
    <div widget class="card border-0 box-shadow" style="margin-top:20px;">
        <div class="card-header transparent border-0 text-muted">
            <h5 class="mb-0" >{{traduccion.claseTabs.redSocial}}</h5>
            <div class="widget-controls"> 
                <a data-widgster="expand" href="#" class="transition"><i class="fa fa-chevron-down"></i></a>
                <a data-widgster="collapse" href="#" class="transition"><i class="fa fa-chevron-up"></i></a>                                     
            </div>        
        </div>
        <div class="card-body pt-0 widget-body" formGroupName="redSocial"> 

                <div class="form-group">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fa fa-facebook" title="Facebook"></i></span>
                        </div>
                        <input formControlName="facebook" class="form-control validation-field" type="text">
                    </div>
                </div>
                <div class="form-group">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fa fa-twitter" title="Twitter"></i></span>
                        </div>
                        <input formControlName="twitter" class="form-control validation-field" type="text">
                    </div>
                </div>
                <div class="form-group">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fa fa-google-plus" title="Google +"></i></span>
                        </div>
                        <input formControlName="google" class="form-control validation-field" type="text">
                    </div>
                </div>


                <div class="form-group">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fa fa-instagram" title="Instagram"></i></span>
                        </div>
                        <input formControlName="instagram" class="form-control validation-field" type="text">
                    </div>
                </div>

                <div class="form-group">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fa fa-linkedin" title="LinkedIn"></i></span>
                        </div>
                        <input formControlName="linkedin" class="form-control validation-field" type="text">
                    </div>
                </div>

        </div> <!-- FIN card-body -->
    </div> <!-- FIN widget -->

</form>   
