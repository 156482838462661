import { Pipe, PipeTransform } from '@angular/core';
import { GRILLA_PAGINADO_TIPO } from '@maq-models/grilla/grillaBase.model';
import { Observable, Observer,of } from 'rxjs';

import { log, logIf, logTable, values } from '@maq-console';



@Pipe({
  name: 'searchFiltroBase',
  pure: false
})
export class SearchFiltroBase<T> implements PipeTransform {
  // constructor(){}
  // paginadoTipo:GRILLA_PAGINADO_TIPO
  
  transform(listado:T[], paginadoTipo:string, claveDeBusqueda:string, textoABuscar:any, tipoDeBusqueda:TIPO_BUSQUEDA,pipeObserver: Observer<any>): T[] {
      // log(...values("valoresDebug","SearchFiltroPipe", listado, paginadoTipo, claveDeBusqueda, textoABuscar,tipoDeBusqueda));
      // log(...values("valoresDebug","SearchFiltroPipe-Observer1", pipeObserver));
      // log(...values("valoresDebug","SearchFiltroPipe-listado", listado));
      // log(...values("valoresDebug","SearchFiltroPipe-listado-TipoPaginado", paginadoTipo));
      // log(...values("valoresDebug","SearchFiltroPipe-listado-fileName", claveDeBusqueda));
      // log(...values("valoresDebug","SearchFiltroPipe-listado-textoABuscar", textoABuscar));
      // log(...values("valoresDebug","SearchFiltroPipe-listado-tipoDeBusqueda",  tipoDeBusqueda));

      if(paginadoTipo==GRILLA_PAGINADO_TIPO.SERVIDOR) {
        let respuetaPipe={"hayCambio":false,
            "listadoFiltrado":  listado
          }
          pipeObserver? pipeObserver.next(respuetaPipe):'';
          return listado;
      }

      if (listado) {
         let listadoFiltrado= listado.filter(documento => {

          let valorGrabado:any=getDatoDocumentoAlmacendadoEnCampoBusqueda(documento,claveDeBusqueda);

            let respuesta:boolean=true;
            switch (tipoDeBusqueda) {
              case TIPO_BUSQUEDA.ARRAY:
                respuesta = buscarEnArray(valorGrabado, textoABuscar)
                break;

              case TIPO_BUSQUEDA.ARRAY_NOMBRE:
                respuesta = buscarEnDocumentosArrayNombre(valorGrabado, textoABuscar);
              
                break;
              case TIPO_BUSQUEDA.ARRAY_KEY:
                respuesta = buscarEnDocumentosArraykey(valorGrabado, textoABuscar);
              
                break;
              case TIPO_BUSQUEDA.TIME:
                respuesta =  buscarTime(valorGrabado, textoABuscar);
              
                break;
              case TIPO_BUSQUEDA.STRING_CONTIENE:
                respuesta =  buequedaStringContiene(valorGrabado, textoABuscar);
                break;

              case TIPO_BUSQUEDA.STRING_EXACTO:
                respuesta = buequedaStringExacto(valorGrabado, textoABuscar);
                break;
              case TIPO_BUSQUEDA.BUSCAR_TEXTO_EN_ARRAY_KEY:
                respuesta = busquedaTextoEnArrayKey(valorGrabado, textoABuscar);
                break;
            
              default:
                respuesta = true;
                break;
            }
          

            return respuesta;
       
             
          
          });

          let rtaPipe={"hayCambio":listado.length!=listadoFiltrado.length,
            "listadoFiltrado":  listadoFiltrado
          }
          pipeObserver ? pipeObserver.next(rtaPipe):'';
          return listadoFiltrado;
    }

    
  }
}

export function buscarEnArray(valorGrabado:string[], textoABuscar:string):boolean{
  let rta:boolean=false;
  if(textoABuscar==null || textoABuscar==undefined) {
    rta= true;
  } else {
    //console.log("searchFiltro select1 array textoABuscar,valorGrabado",textoABuscar,valorGrabado);
   
    if(valorGrabado==null)  rta=false;
    if(valorGrabado!=null && valorGrabado.indexOf(textoABuscar)!=-1) {
      rta=true;
    } else {
      rta=false;
    }
    return rta;
  }
}

export function buscarEnDocumentosArraykey(valorGrabado:any[], textoABuscar:string):boolean{
  let rta:boolean=false;

  if(textoABuscar==null || textoABuscar==undefined ) {
    rta=true
  } else {
      //console.log("pipe array textoABuscar,valorGrabado",textoABuscar,valorGrabado);
      if(valorGrabado!=null && valorGrabado!=undefined) {
        for(let i=0; i<valorGrabado?.length; i++) {
          //console.log("pipe array valorGrabado[i]==textoABuscar",valorGrabado[i],textoABuscar);
          //console.log("pipe array valorGrabado[i].nombre==textoABuscar.nombre",valorGrabado[i].key,textoABuscar.key);
           if(valorGrabado[i].key!=undefined && valorGrabado[i].key==textoABuscar) {
            rta=true; break;
          }
        }
      }
      //console.log("pipe array rta",rta);
      return rta;
    }
  }  

  export function buscarEnDocumentosArrayNombre(valorGrabado:any[], textoABuscar:string):boolean{
    let rta:boolean=false;
  
    if(textoABuscar==null || textoABuscar==undefined ) {
      rta=true
    } else {
      //console.log("pipe array textoABuscar,valorGrabado",textoABuscar,valorGrabado);
      if(valorGrabado!=null && valorGrabado!=undefined) {
        for(let i=0; i<valorGrabado?.length; i++) {
          //console.log("pipe array valorGrabado[i]==textoABuscar",valorGrabado[i],textoABuscar);
          //console.log("pipe array valorGrabado[i].nombre==textoABuscar.nombre",valorGrabado[i].key,textoABuscar.key);
            if(valorGrabado[i].nombre!=undefined && valorGrabado[i].nombre==textoABuscar) {
            rta=true; break;
          }
        }
      }
      //console.log("pipe array rta",rta);
      return rta;
    }
  }
  export function busquedaTextoEnArrayKey(valorGrabado:string, textoABuscar:any[]):boolean{
    let rta:boolean=false;
  
    if(textoABuscar==null || textoABuscar==undefined || !Array.isArray( textoABuscar) ) {
      rta=true;
    } else {
      //console.log("pipe array textoABuscar,valorGrabado",textoABuscar,valorGrabado);
      if(valorGrabado!=null && valorGrabado!=undefined) {
        
        let index= textoABuscar.findIndex(element=>element.key==valorGrabado);
        rta= index>=0? true:false
      }
      //console.log("pipe array rta",rta);
      
    }
    return rta;
  }
  
  export function buscarTime(valorGrabado:any, textoABuscar:string):boolean{
    let searchText = new RegExp(textoABuscar, 'ig');
    let rta:boolean=false;
    // console.log("time searchFiltro", listado, claveDeBusqueda, textoABuscar, tipoDeBusqueda);
    if(textoABuscar==null || textoABuscar==undefined) {
      //console.log("boolean true",true);
      rta= true;
    } else {
      // console.log("time searchFiltro,valorGrabado",textoABuscar,valorGrabado);
      rta=false;
      if (valorGrabado) {
        let hora=valorGrabado.hour.toString()+':'+valorGrabado.minute.toString()+':'+valorGrabado.second.toString();
        //console.log("time searchFiltro hora", hora);
        rta= hora.search(searchText) !== -1;
      } else {
        rta= true;
      }
    }

    return rta;
}

  export function buscarBoolean(valorGrabado:boolean, textoABuscar:boolean){
  //console.log("boolean nombre",valor['datosPersonales']['nombre']);
  //console.log("boolean searchFiltro", listado, claveDeBusqueda, textoABuscar, type);
  let rta:boolean=false;
  if(textoABuscar==null || textoABuscar==undefined) {
    //console.log("boolean true",true);
    rta= true;
  } else {
    //console.log("boolean textoABuscar,valorGrabado",textoABuscar,valorGrabado);
    rta=false;
    if(textoABuscar==false && valorGrabado==null)  rta=true;
    if(textoABuscar==false && valorGrabado==false) rta=true;
    if(textoABuscar==true  && valorGrabado)        rta=true;
    //console.log("boolean rta",rta);
    return rta;
  }
}

export function buequedaStringExacto(valorGrabado:String, textoABuscar:String){
  let rta:boolean=false;

   let searchText = textoABuscar;

  if(textoABuscar==null) return true;
  if(typeof valorGrabado!=='string' && valorGrabado) valorGrabado=valorGrabado.toString();
  if (valorGrabado!=null) {
    rta = valorGrabado?.trim() == searchText?.trim();
  } 

  return rta;
}

export function buequedaStringContiene(valorGrabado:any, textoABuscar:string){
  let rta:boolean=false;
  let searchText = new RegExp(textoABuscar, 'ig');
  if(textoABuscar==null) return true;
  if(typeof valorGrabado!=='string' && valorGrabado) valorGrabado=valorGrabado.toString();
  if (valorGrabado) {
    //console.log("valorGrabado.search(searchText) !== -1",valorGrabado, textoABuscar);
    rta = valorGrabado.search(searchText) !== -1;
  } else {
     rta=(textoABuscar) ? false : true;
  }

  return   rta;

}


export function getDatoDocumentoAlmacendadoEnCampoBusqueda(documento:any, claveDeBusqueda:string):any {

    let valorGrabado:any;
 
    if(claveDeBusqueda.indexOf('.')==-1) {
      valorGrabado=documento[claveDeBusqueda];
    } else {

      let partes = claveDeBusqueda.split('.');
      if((documento[ partes[0] ]!==undefined && documento[ partes[0] ]) && 
        (documento[ partes[0] ][ partes[1] ]!==undefined && documento[ partes[0] ][ partes[1] ]) &&
        (documento[ partes[0] ][ partes[1] ][ partes[2] ]!==undefined && documento[ partes[0] ][ partes[1] ][ partes[2] ]) ) {
          
        valorGrabado=documento[ partes[0] ][ partes[1] ][ partes[2] ];
        
      } else if((documento[ partes[0] ]!==undefined && documento[ partes[0] ]) && 
        (documento[ partes[0] ][ partes[1] ]!==undefined && documento[ partes[0] ][ partes[1] ])  ) {
          
        valorGrabado=documento[ partes[0] ][ partes[1] ];
      } else {
        valorGrabado=null;
      }
    }
    //console.log("searchFiltro valor",valorGrabado, valor);

    return valorGrabado;
}

            

export enum TIPO_BUSQUEDA{
  "ARRAY"="array",
  "ARRAY_NOMBRE"="array_nombre",
  "ARRAY_KEY"="array_key",
  "BUSCAR_TEXTO_EN_ARRAY_KEY"="buscar_texto_en_array_key",
  "STRING_CONTIENE"="string",
  "STRING_EXACTO"="string_exacto",
  "TIME"="time",
  "BOOLEAN"="boolean",

}
