import { log, logIf, logTable, values } from '@maq-console';

import { Component, OnInit, OnDestroy, ViewEncapsulation    } from '@angular/core';
import { ChangeDetectorRef, Injector, ViewChild, ElementRef } from '@angular/core';
import { Router }                                             from '@angular/router';
import { DOCUMENT }                                           from '@angular/common';


// SOAP Interceptor
import { HttpClient, HttpHeaders  } from '@angular/common/http';

// Reactive Forms
import { FormGroup, FormControl, FormBuilder, Validators, FormsModule } from '@angular/forms';
import {cloneAbstractControl}   from '@maq-helpers/formHelp'


// Servicios
import { ApisService }          from '@maq-apis';
import { AuthService }          from '@maq-autorizacion/services/auth.service';

import { BdBaseService }        from '@maq-bd/bd.serviceBase';
import { ConfirmService }       from '@maq-servicios/confirm/confirm-service';
import { AlertService }         from '@maq-servicios/alert/alert-service';
import { FuncionesService }     from '@maq-funciones';
import { MensajesServiceBase }  from '@maq-servicios/mensajes/mensajes.serviceBase';
import { AppSettingsService }   from '../../app.settings';
import { AppSettings2 }         from '@maq-models/appSettings/appSettings.model';
import { ArgumentosLlamarBaseDeDatos}  from '@maq-models/bd/bdBase.model';
import { WhereInterface , OPERADOR_ORDEN_BY,OPERADOR_WHERE, TIPO_BASE_DATOS, TIPO_ACTUALIZACION_DATOS, OrdeByInterface}  from '@maq-models/bd/bdDefinicionesGenerales.model';

import { verificarModeloConForm,VerificarModeloConFormResult}  from '@maq-helpers/formHelp';
import { copiarObjetoNew}        from '@maq-helpers/objetosHelp';

// environmet
import { environment }        from '../../../environments/environment';

import { formatNumber }                                       from '@angular/common';
import { getDocField, setDocField } from '@maq-helpers/documentosHelp';
// Firebase
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';

// jQuery
declare let $: any;
declare let jQuery: any;

// Varios
import { Observable    } from 'rxjs';
import { map, take     } from 'rxjs/operators';
import { finalize, tap } from 'rxjs/operators';

// InputMask
import Inputmask from 'inputmask';
import moment from 'moment';
import format from 'date-fns/format'

// Toast
import { ToastrService, GlobalConfig } from 'ngx-toastr';

// Spinner
import { NgxSpinnerService } from "ngx-spinner";

// Ordenamiento en Memoria Grillas
import { OrderPipe } from 'ngx-order-pipe';

// Translate
import { TranslateService }  from '@ngx-translate/core';

// Pipes de Formato de Monedas
import { formatCurrency, getCurrencySymbol } from '@angular/common'

// ng-pick-datetime
// import { DateTimeAdapter } from 'ng-pick-datetime';
// import { OwlDateTimeIntl } from 'ng-pick-datetime';

// Models
import { Usuario, PermisosInterface}                    from '@maq-models/usuarios/usuarios.model';
import { DatosPersonales }                              from '@maq-models/datosPersonales/datosPersonales.model';
import { Telefono }                                     from '@maq-models/telefono/telefono.model';
import { RedSocial }                                    from '@maq-models/redSocial/redSocial.model';
import { Settings }                                     from '@maq-models/settings/settings.model';
import { getListadoCacheModel }                         from '@maq-models/mensajes/mensajes.model';
import { WhereObjet,OrderObjet }                        from '@maq-models/bd/bdDefinicionesGenerales.model';
import { KN, KANE, KNAI }                               from '@maq-models/typesKN/typesKN.model';
import { GrillaBase, PaginadoFirebase,ItemsAndCurrentPage ,ONCHANGE_GRILLA, GRILLA_PAGINADO_TIPO,GRILLA_PAGINA_REQUERIDA}     from '@maq-models/grilla/grillaBase.model';
import { ArgumentosGetListadoCacheBase }                from '@maq-models/mensajes/mensajesBase.model';
import { Soap }                                         from '@maq-models/soap/soap.model';

// Mocks y Funciones
import { LISTA_DE_FILTROS_SI_NO, LISTA_DE_FORM_SI_NO, getListadoSiNo } from '@maq-mocks/varios/varios';
import { LISTA_DE_GENEROS }                       from '@maq-mocks/varios/varios';
import { LISTA_DE_PAISES }                        from '@maq-mocks/usuarios/usuarios';

import { ConfigComponenteBaseInterface} from '@maq-models/pageBase/configComponenteBase.interface'
import { FilesDataInterfase } from '@maq-helpers/fileUploadHelp';
import { ACCION_FORM} from '@maq-helpers/actionFormHelp'
import { ConfigBase} from './configBase'
import { formatDateToString, getFromAndToWithReturnOfAnObject, getOffsetUTC, getOffsetUTCHours, getOffsetUTCHoursWithOffsetUTC } from '../helpers/fechaHoraHelp';
import { DatePipe } from '../shared/pipes/date/date.pipe';
import { ColumnaReporte } from '@settings/proyecto/models/reportes/columnasReporte';
import { FORMATO } from '@settings/proyecto/mocks/columnaReporte/columnaReporte.mocks';

@Component({ template: '' })
export class PageBase<T> implements  OnInit, OnDestroy  {
  @ViewChild("modalContent") modalContent: any;
  @ViewChild('titulo') viewChildTitulo: ElementRef;
  
  // Settings
  public settings2: AppSettings2;

  // Colección Principal
  public urlBaseDatos                   : string;
  public nombreColeccion                : string;
  public campoClave                     : string;
  public subscripcionColeccionPrincipal : any;
  public listadoPrincipal               : T[]=[];
  public columnasAdicionalesLogTable    : string[]=[];
  public finalizoGETPrincipal           : boolean=false;
  public configComponente               : ConfigBase=null;
  public documentoOriginal              : any;    

  // Colecciones Secundarias
  public subscripcionCache                            : any[]=[];
  public configListadosCache                          : ArgumentosGetListadoCacheBase[]=[];
  public cantidadSubscripcionesSecundariasLanzadas    : number=0;
  public finalizoGETSecundarias                       : boolean=false;
  public listadoSiNoActivo                            : any[] = getListadoSiNo('Estado');

  public listadoDetalle                               : any[]=[];

  // Formulario y Grilla

  public grilla                   : GrillaBase;
  public searchDateRango          : any[]=[];
  public form                     : FormGroup;
  public formInvalid              : boolean = false;
  public accionForm               : ACCION_FORM = ACCION_FORM.LISTADO;
  public errorMensaje             : string;



  public listadoCamposNoDeseados  : string[]=[];

  


  // Variables Globales Usario Logeado y Organización asociada
  public usuario              : Usuario;
  public usuarioKANE          : KANE=null;
  public tipoPerfilUsuario    : string=null;
  public organizacionKNAI     : KNAI=null;
  public distribuidorKN       : KN=null;
  public organizacionUTC      : string=null;  

  public ACCION_FORM =ACCION_FORM;
  public ONCHANGE_GRILLA =ONCHANGE_GRILLA;
  // Permisos del Usuario Logeado
  public permisos:PermisosInterface={
    altasAutorizadas                    : true,
    bajasAutorizadas                    : true,
    modificacionesAutorizadas           : true,
    modificacionesBasicasAutorizadas    : true,
    deshabilitarGrabar                  : false,
  };

  // Manejo de Imágenes
  public vecImagenesDownloadeadas   = [];
  public arrayFILES                 : any[] = [];
  public arrayUpload                : any[] = [];
  
  // Mocks y Funciones Importadas  
  public LISTA_DE_FILTROS_SI_NO = LISTA_DE_FILTROS_SI_NO;
  public LISTA_DE_FORM_SI_NO    = LISTA_DE_FORM_SI_NO;
  public LISTA_DE_GENEROS       = LISTA_DE_GENEROS;
  public LISTA_DE_PAISES        = LISTA_DE_PAISES;
  public getListadoSiNo         = getListadoSiNo;
    
  // Traducción
  public activeLang:string;

//   public utcOrganizacion:string = '+0000';
//   public utcOrganizacionNumber:number = 0;

  
  // Servicios
  public appSettings      : AppSettingsService;
  public fn               : FuncionesService;
  public bdBaseService    : BdBaseService;
  public apis             : ApisService;
  public msgBase          : MensajesServiceBase;
  public alertService     : AlertService;
  public authService      : AuthService;
  public toastrService    : ToastrService;
  public confirmService   : ConfirmService;
  public router           : Router;
  public af               : AngularFirestore;
  public fb               : FormBuilder;
  public translate        : TranslateService;
  public spinner          : NgxSpinnerService;
  public orderPipe        : OrderPipe;
  public document         : any;
  public http             : HttpClient
  
  public soap             : Soap=null;

  public campoModeloUsadoEnKeywords   : string= '';  
  public camposDecimal                : string[] = [];       // Indica que campos del formulario son de tipo decimal o currency

  public whereListadoPrincipal:WhereInterface[]=[];
  public orderByListadoPrincipal:OrdeByInterface[]

  public arraySelectFiltroFechaDesdeHasta:any[]=[]; //se guarda en la posicion 0 la fecha desde y la fecha hasta en la posicion 1


  constructor(protected changeDetectorRef: ChangeDetectorRef) {    

        const injector = InjectorBase.getInjector();
        
        this.appSettings      = injector.get(AppSettingsService); 
        this.fn               = injector.get(FuncionesService);
        this.bdBaseService    = injector.get(BdBaseService);
        this.apis             = injector.get(ApisService);
        this.msgBase          = injector.get(MensajesServiceBase);
        this.authService      = injector.get(AuthService);
        this.toastrService    = injector.get(ToastrService);
        this.confirmService   = injector.get(ConfirmService);
        this.alertService     = injector.get(AlertService);
        this.router           = injector.get(Router);
        this.af               = injector.get(AngularFirestore);
        this.fb               = injector.get(FormBuilder);
        this.translate        = injector.get(TranslateService);
        this.spinner          = injector.get(NgxSpinnerService);
        // this.orderPipe        = injector.get(OrderPipe);
        this.document         = injector.get(DOCUMENT);
        this.http             = injector.get(HttpClient);
        
      log(...values('funcionComponente','constructor page-base'));


  }    



  ngOnDestroy() {
    log(...values('funcionComponente','ngOnDestroy page-base'));
    this.liberarSuscripciones();
    

  }  
  
  liberarSuscripciones(){
    if(this.subscripcionColeccionPrincipal) this.subscripcionColeccionPrincipal.unsubscribe();

    for (var i=0; i<this.subscripcionCache.length; i++) {
        if(this.subscripcionCache[i]) {
          this.subscripcionCache[i].unsubscribe();
        }
    }
  }

  ngOnInit() {

  

    log(...values('funcionComponente','ngOnInit page-base'));

 

    
    this.grilla= new GrillaBase({});

    // Listener de Cambio de Idioma
    this.inicializarVariablesTraduciblesPageGenerica();
    this.translate.onLangChange.subscribe(() => {
        this.inicializarVariablesTraduciblesPageGenerica();
    });

    this.msgBase.getUsuario().subscribe((usuario:Usuario)=>{
  

        this.setVariablesGlobales(usuario);
        
        this.setBdBaseData();
        this.configuracionComponente();  
        this.configuracionFormulario();  
        this.chequearTipoActualizacionDatosListadoPrincipal();
      

        log(...values("valores","nombreColeccion:",this.nombreColeccion));

        
        
        // --------------------------------------------------------------
        // geListadoPrincipal  Principal
        // --------------------------------------------------------------
        this.geListadoPrincipal(); 
        //todo: setcantidad documentos en grilla
       
        

        // --------------------------------------------------------------
        // Subscripción a Tablas Auxiliares
        // --------------------------------------------------------------
        this.getSubscripcionSecundarias();

        this.setUTCInUser();
        // .then(utc=>{
        //   this.utcOrganizacion=utc;
        //   this.utcOrganizacionNumber = getOffsetUTCHoursWithOffsetUTC(utc);
        // });

    });

  }

  setVariablesGlobales(usuario:Usuario){
    log(...values('valores','usuarioJJ:',usuario));
    this.usuario                  = usuario;
    this.usuarioKANE              = this.usuario.getKANE();
    this.tipoPerfilUsuario        = this.usuario.getTipoPerfil();
   
    // todo: pasar a usario getPemisosParaUrl(this.router.url)
    this.permisos                 = this.usuario.getPermisosEdicion(this.router.url, this.permisos);
    this.organizacionKNAI         = this.usuario.getOrganizacionKNAI();
    this.distribuidorKN           = this.usuario.getDistribuidorKN();
    
    this.soap                     = this.usuario.getOrganizacionSoap();

    log(...values("valores","this.organizacionKNAI:",this.organizacionKNAI));
    log(...values("valores","this.distribuidorKN:",this.distribuidorKN));
    log(...values("valores"," this.permisos:", this.permisos));
    log(...values("valores"," this.soap:", this.soap));
    log(...values("valores"," this.getUrlBaseDatos:", this.usuario.getUrlBaseDatos()));
  }
  
  configuracionComponente() {
    log(...values('funcionComponente','page-base.configuracionComponente'));      
      
        // Seteo Grilla
        this.grilla.configGrilla(this.configComponente.grilla);
        this.grilla.setGrillaFiltosFromForm(this.configComponente.form);
     

     
        // Colección Principal
        this.nombreColeccion                = this.configComponente.nombreColeccion;
        this.campoClave                     = this.configComponente.campoClave;
        this.columnasAdicionalesLogTable    = this.configComponente.columnasAdicionalesLogTable;
        this.campoModeloUsadoEnKeywords     = this.configComponente.campoModeloUsadoEnKeywords ;
        this.camposDecimal                  = this.configComponente.camposDecimal ;

        // Colecciones Auxiliares 
        this.configListadosCache            = this.configComponente.configListadosCache;

        //Lista de Campos No Deseados
        this.listadoCamposNoDeseados        = (this.configComponente.listadoCamposNoDeseados!==undefined) ? this.configComponente.listadoCamposNoDeseados : [];
        this.urlBaseDatos                   = this.configComponente.urlBaseDatos;



        console.log("this.configComponente",this.configComponente);
      
  }  
  

  
  configuracionFormulario() {    
        log(...values('configuracionFormulario','configuracionFormulario',this.configComponente.form));      


      
        this.form = cloneAbstractControl(this.configComponente.form);  // ok
        
        
        // console.log("this.configComponente.form",this.configComponente.form);
        // console.log("this.form",this.form);
        
        this.inicializaArrayFiles();
        this.chequearFormulario_Modelo();
 
   
    
  }

  inicializaArrayFiles(){
        // Configuro FILES gestionados por el formulario   
        this.arrayFILES=[];
        for(let i=0; i<this.configComponente.arrayFiles.length; i++) {
          let objFile = this.configComponente.arrayFiles[i];
          this.setArrayFILES( objFile.nameField, {
              type             : objFile.type,
              link             : null,
              linkThumb        : null,
              nombreArchivo    : null,
              extensionArchivo : null,
              borrar           : false
          });              
        }
    

  }
  
  chequearTipoActualizacionDatosListadoPrincipal(){

    if(!environment.production ){
        if(this.configComponente.hasTipoActualizacionDatosCorrecta()==false){

            let mensaje='La base de datos no soporta el tipo de actualización de datos Configurado'
            
            
            this.alertService.confirm({ 
                title:   this.translate.instant('CONFIG-BASE'), 
                message: mensaje
             
            }).then(data=>{});
        }
       

    }

  }
  chequearFormulario_Modelo(){

      // --------------------------------------------------------------
      // En Desarrollo Verifico que el fomulario tenga los mismos datos que el modelo
      // --------------------------------------------------------------
      // En desarrollo, verifico que el formulario tenga todos los campos de la clase que administra
      // Comparo la clase con cada uno de los campos del formulario.
    
      if(!environment.production && this.configComponente.mostrarDiferenciaModeloFomulario){
      
        
        console.log('propiedad this.configComponente.t',this.configComponente.t);
        
        let diferencias:VerificarModeloConFormResult=verificarModeloConForm(this.form, this.configComponente.t, this.listadoCamposNoDeseados );

        console.log('propiedad diferencias',diferencias); 
        
        if(diferencias.propiedadesFaltantesEnForm!='' || diferencias.propiedadesEnFormNoEsModelo!=''){
            
            let mensaje=diferencias.propiedadesFaltantesEnForm ? 'Falta en el Fomulario: '+diferencias.propiedadesFaltantesEnForm +'\n\n' :'';
            mensaje+=diferencias.propiedadesEnFormNoEsModelo ? 'Falta en el modelo: '+ diferencias.propiedadesEnFormNoEsModelo:'';
            
            this.alertService.confirm({ 
                title:   this.translate.instant('Diferencias entre Formularios y Modelo'), 
                message: mensaje
             
            }).then(data=>{});
        }    

    }

  }

  resetFormulario() {
    this.configuracionFormulario();
  }
  
  inicializarVariablesTraduciblesPageGenerica(){
      // log(...values('funcionComponente','inicializarVariablesTraduciblesPageGenerica'));
      // log(...values("valores","this.translate:",this.translate));  
      // log(...values("valores","this.translate.store.currentLang:",this.translate.store.currentLang));  
      
      this.activeLang=this.translate.store.currentLang;
  }
  
  postGETActualizaValores(listado:any):any {

      if(this.grilla.hasPaginadoLocal()) {
        // salgo, esta función se usa solamente para paginadoTipo 'servidor'
        return listado;
      }

      let ascDesc = this.grilla.ordenAscDesc();

      if(this.grilla.requierePaginaAnterior()) {  // Vino en Orden Invertido
          listado.sort(this.fn.ordenarXAtributo(this.grilla.itemSeleccionadoParaOrdenar, ascDesc, false));
      }

      // Actualizo nro de página actual
      this.grilla.actualizarNroPaginaActual();

      // Actualizo Cantidad de Resultados
      this.grilla.setResultCantidad(listado.length);
      
      // Actualizo resultKey1, resultKeyN
      this.grilla.setResultKey1_KeyN(listado,this.fn);
 
      return listado;
  }

  onChangeGrilla(cual:ONCHANGE_GRILLA, itemSeleccionadoParaOrdenar:string) {
    console.log('onChangeGrilla',cual,itemSeleccionadoParaOrdenar);

    // Hizo click en alguna de las columnas para cambiar el orden
    if(cual==ONCHANGE_GRILLA.ORDER) {
       this.onChangeGrillaOrden(itemSeleccionadoParaOrdenar);
    }

    // Hizo click en el dropDown de cantidad de elementos a mostrar en el paginado
    if(cual==ONCHANGE_GRILLA.PAGINADO_CANTIDAD) {

        this.onChangeGrillaPaginado();
    }  
  }  

  onChangeGrillaPaginado(){
    console.log('onChangeGrillaPaginado');
    if(this.grilla.isPaginadoServidor()) { 
        // this.grilla.paginaRequerida = GRILLA_PAGINA_REQUERIDA.PRIMERA;
        this.grilla.paginaActualNro=1;
        this.geListadoPrincipal();
    }  
  }

  onChangeGrillaOrden(itemSeleccionadoParaOrdenar:string){
    console.log('onChangeGrillaOrden',itemSeleccionadoParaOrdenar);
   
 
    if(this.grilla.isPaginadoServidor()) { 
        this.grilla.onChangeGrillaOrdenServidor(itemSeleccionadoParaOrdenar,this.configComponente.hasTipoBaseDatosListdoPrincipalSQL())
        this.geListadoPrincipal();
         
    } else {
        this.grilla.setOrder(itemSeleccionadoParaOrdenar);
    }           

  }

    filtrarGrilla(formato?:FORMATO) { 
        log(...values('funcionGo','filtrarGrilla formato',formato));
    
        if(this.grilla.hasPaginadoLocal()) { 
            this.setAccionForm(ACCION_FORM.LISTADO);  
            this.spinner.hide();                    
            return;
        }
        
    
        if(this.configComponente.hasTipoBaseDatosListdoPrincipalSQL()){
            this.grilla.filtrarGrillaSQL(formato, this.activeLang,this.usuario.getUTCNumber());
            this.geListadoPrincipal();   
            return;
        }
        

        this.grilla.setWhereArray(this.hasKeyWords(),this.campoModeloUsadoEnKeywords);
        this.grilla.paginaRequerida = GRILLA_PAGINA_REQUERIDA.PRIMERA;
        log(...values("valores","this.grilla:",this.grilla));

        this.geListadoPrincipal();            
   
    }
 
    resetFiltros(vecFiltrosNoResetar?:any) {
        // Inicializo Filtros
        this.grilla.resetFiltros(vecFiltrosNoResetar);
        this.grilla.resetOrden();

        this.filtrarGrilla();
    }


    //SACAR DE ACA y pasarlo a la clase
    titleFiltroPeriodo(columna:ColumnaReporte):string{
        let retorno:string = this.translate.instant(columna.getNombreTraduccion());
        let fechaDesde:string = '';
        let fechaHasta:string = '';
        let datePipe =new DatePipe();
        let formato = '';
    
        if(columna.getFormato() == 'datetime'){
            formato = 'DD/MM/AAAA HH:MM:SS'
        }else if(columna.getFormato() == 'date' ){
            formato = 'DD/MM/AAAA';
        }else if(columna.getFormato() == 'time' ){
            formato = 'HH:MM:SS'
        }
    
        if(this.arraySelectFiltroFechaDesdeHasta[columna.getNombreFiltro()]?.length == 2){
          fechaDesde =datePipe.transform(this.arraySelectFiltroFechaDesdeHasta[columna.getNombreFiltro()][0],formato, this.activeLang, this.usuario.getUTC());
          fechaHasta =datePipe.transform(this.arraySelectFiltroFechaDesdeHasta[columna.getNombreFiltro()][1],formato,this.activeLang, this.usuario.getUTC());
          retorno = fechaDesde + ' ~ ' + fechaHasta;
        }
        return retorno;
    }

     //TODO pasarlo a la grilla base 
    clearFiltroPeriodo(nombreFiltro:string){
        this.grilla.filtros[nombreFiltro] = null;
        this.arraySelectFiltroFechaDesdeHasta[nombreFiltro] = null;
        this.grilla.whereArray = this.grilla.whereArray.filter(where => where.key!=nombreFiltro);
        this.resetFiltros(this.grilla.whereArray.map(where => where.key));
    }

    //TODO crear un helper que devuelva el rango de fechas.
    seleccionFiltroFecha(selectedMoment:Date[], key:string, formatoColumna:FORMATO, filtrarGrilla:boolean){
        // console.log('zzz seleccionFiltroFecha selectedMoment ', selectedMoment, ' key ', key, ' formatoColumna ', formatoColumna, ' filtrarGrilla ', filtrarGrilla);
        this.grilla.filtros[key] = getFromAndToWithReturnOfAnObject(selectedMoment, formatoColumna, this.activeLang, this.usuario.getUTC());
        
        if(filtrarGrilla){
            this.filtrarGrilla(formatoColumna);
        }
    }
     
    //TODO pasar a la clase organizacion
    setUTCInUser(){
        this.usuario.getUTCDefault(this.bdBaseService);
        // return new Promise((resolve, reject) => {
        //     if(this.usuario?.organizacion?.useUtcFromLocation == false || this.usuario?.organizacion == undefined){
        //         console.error("El usuario " + this.usuario?.datosPersonales?.apellidoNombre +" no tiene organización ");
        //         // this.utcOrganizacionNumber = getOffsetUTCHours();
        //         resolve(getOffsetUTC());
        //     }else{
        //         this.usuario.organizacion.getUTC(this.bdBaseService).then(utc=>{
        //             resolve(utc);
        //         })
        //     }
          
        // });
    }
  
  closeFilterRangoFechas(field:string, fieldSearch) {
    // console.log("closeFilterRangoFechas field",field);
    // console.log("closeFilterRangoFechas fieldSearch",fieldSearch);
    // console.log("closeDateTimePicker fechaHora.selecteds[0]", fechaHora.selecteds[0]);
    // console.log("closeDateTimePicker fechaHora.selecteds[1]", fechaHora.selecteds[1]);
    // console.log("closeFilterRangoFechas jQuery value",$("#"+fieldSearch)[0].value);

    let rango = $("#"+fieldSearch)[0].value;
    // 11/2/2020 ~ 27/2/2020

    let fechas = rango.split('~')
    let fecha1 = this.fn.formatFecha2DigitosDiaMes( fechas[0] );
    let fecha2 = this.fn.formatFecha2DigitosDiaMes( fechas[1] );

    // Parche para detectar que luego de indicar filtros, abrió nuevamente el modal y canceló sin elegir nuevos valores
    // en ese caso, el rango va a mantener los valores viejos, formateados sin años dd/mm  ~ dd/mm con 5 dígitos de extensión
    if(fecha1!='' && fecha1.length==5) fecha1='';
    if(fecha2!='' && fecha2.length==5) fecha2='';

    if(this.searchDateRango.indexOf(field)==-1) {
      this.searchDateRango.push(field);
    }    
    this.searchDateRango[field]={
      desde: fecha1,
      hasta: fecha2,
    };    
    this.grilla.filtros[field]={ 
      desde: fecha1,
      hasta: fecha2,
    }
    this.filtrarGrilla();

    let rangoFormateado = '';
    if(fecha1!='' && fecha2!='') {
      rangoFormateado = this.fn.quitaAnio(fecha1) + ' ~ ' + this.fn.quitaAnio(fecha2);
    }  

    // console.log("closeFilterRangoFechas rangoFormateado",rangoFormateado);
    
    $("#"+fieldSearch)[0].value=rangoFormateado;
    $("#"+fieldSearch).removeClass('filtroSinValor');
    $("#"+fieldSearch).addClass('filtroConValor');

    if (!this.changeDetectorRef['destroyed']) {
       this.changeDetectorRef.detectChanges();
    }                

  }
    
  closeDateTimePicker(cual, fechaHora) {
    // console.log("closeDateTimePicker cual",cual);
    // console.log("closeDateTimePicker fechaHora", fechaHora);

  }
  
  
  compareFn(c1: any, c2:any): boolean {     
    // console.log("compareFn c1", c1);
    // console.log("compareFn c2", c2);
      return c1 && c2 ? c1.key === c2.key : c1 === c2; 
  }
  
   

  geListadoPrincipal() {
    switch (this.configComponente.tipoActualizacionDatosListadoPrincipal) {
        case TIPO_ACTUALIZACION_DATOS.PROMESA:
            this.getPromesaListadoPrincipal();
            break;
        case TIPO_ACTUALIZACION_DATOS.SUBSCRIPCION:
            this.getSubscripcionPrincipal();
            break;
    
        default:
            break;
    }  
  }

  getPromesaListadoPrincipal(){
    log(...values('funcionComponente','page-base.getPromesaListadoPrincipal'));

    let where       : WhereInterface[] = this.seleccionaWhere();
    let orderBy     : OrdeByInterface[]= this.seleccionaOrderBy();

    let paginadoFirebase    : PaginadoFirebase = this.grilla.getPaginadoFirebase();
    

    
    this.finalizoGETPrincipal=false;    
    
   
    console.log('getPromesaListadoPrincipal-orderBy',orderBy);
    console.log('getPromesaListadoPrincipal-where', JSON.stringify(where));
    console.log('getPromesaListadoPrincipal-this.urlBaseDatos',this.urlBaseDatos);




    let argumentoBase:ArgumentosLlamarBaseDeDatos= new ArgumentosLlamarBaseDeDatos({
      nombreColeccion  :  this.nombreColeccion,
      where            :  where,
      orderBy          :  orderBy,
      itemsPorPagina   :  this.grilla.itemsPorPagina,
      paginaActualNro  :  this.grilla.paginaActualNro,
      paginadoFirebase :  paginadoFirebase,   
      urlBaseSQL       :  this.urlBaseDatos,
      tipoBaseDatos    :  TIPO_BASE_DATOS.SQL_SERVER// "firestore", "SQL"
     } );

    this.bdBaseService.getPromesaBase(argumentoBase).then(data=>{
        console.log('getPromesaListadoPrincipal data',data);
        this.grilla.resultCantidad     = (data.length==0) ? 0 : data[0]?.rows;
        // this.grilla.actualizarNroPaginaActual(); 
        this.listadoPrincipal=data;
        this.finalizoGETPrincipal=true;              
        this.onResultGetListadoPrincipal();
    }).catch(error=>{
        console.log('getPromesaListadoPrincipal error',error);
        this.onResultGetListadoPrincipal();
    })

  }
  
  getSubscripcionPrincipal(){
    
    log(...values('funcionComponente','page-base.getSubscripcionPrincipal'));

    if(this.subscripcionColeccionPrincipal) this.subscripcionColeccionPrincipal.unsubscribe();

    log(...values("valores","this.grilla.whereArray:",this.grilla.whereArray)); 
    // let where:WhereInterface[] = this.grilla.whereArray;
    
    let where       : WhereInterface[] = this.seleccionaWhere();

    let filtroname:string=this.grilla.itemSeleccionadoParaOrdenar;
    let filtroFechaHoraName=null;
    if(this.grilla.itemSeleccionadoParaOrdenarIncluyeHechaHora()) {
        for(let i=0; i<this.grilla.whereArray.length;i++) {
           if(this.grilla.whereArray[i].key.includes('fechaHora')) {
              filtroname =this.grilla.whereArray[i].key;
           } 
        }
    }    

    let orderBy=[];
 

    let paginado:PaginadoFirebase = this.grilla.getPaginadoFirebase();
    
    log(...values('getBaseDatosUR',' getBaseDatosUR where:',where, 'orderBy:',orderBy, 'paginado:',paginado));
    log(...values('getBaseDatosUR','getBaseDatosUR this.grilla.paginaActualNro:',this.grilla.paginaActualNro) );

    this.finalizoGETPrincipal=false;    
    
    let argumentoBase:ArgumentosLlamarBaseDeDatos= new ArgumentosLlamarBaseDeDatos({
        nombreColeccion  :  this.nombreColeccion,
        where            :  where,
        orderBy          :  orderBy,
        itemsPorPagina   :  this.grilla.itemsPorPagina,
        paginaActualNro  :  this.grilla.paginaActualNro,
        paginadoFirebase :  this.grilla.getPaginadoFirebase(),   
        urlBaseSQL       :  this.urlBaseDatos,
        tipoBaseDatos    :  TIPO_BASE_DATOS.FIRESTORE// "firestore", "SQL"
       } );
 
    
    this.subscripcionColeccionPrincipal=this.bdBaseService	
        .getBDSubscripcion(argumentoBase).subscribe((data:any)=>{
            this.grilla.resultCantidad = data.length;
            this.listadoPrincipal = data;
            this.finalizoGETPrincipal=true;              
            this.onResultGetListadoPrincipal();
        },(error:any)=>{
            log(...values("error",error));
            this.onResultGetListadoPrincipal();
        });
  }
  
  getSubscripcionSecundarias() {

      log(...values('funcionComponente','page-base.getSubscripcionSecundarias'));

      /* Subscripción a Colecciones Secundarias */
      this.cantidadSubscripcionesSecundariasLanzadas=0;
      this.finalizoGETSecundarias=false;              
      
      
      
      
      this.cantidadSubscripcionesSecundariasLanzadas=this.configListadosCache.length;
      console.log("this.getListadoCacheBase ",this.configListadosCache);
      
      for(let i=0; i<this.configListadosCache.length; i++) {

         let argumentoCache= this.configListadosCache[i];
          
          this.subscripcionCache.push( 
              this.msgBase.getListadoCacheBase(argumentoCache).pipe(take(1)).subscribe(data=>{
                  console.log("getListadoCacheBase"+i,this.configListadosCache[i]);
                  console.log("getListadoCacheBase data",data);
                  let nombreListado =this.configListadosCache[i].getNombreListado();
                  console.log("getListadoCacheBase nombreListado",nombreListado);
                  log(...values("valores",
                            " getListadoCacheBase  msg.cacheColecciones[" + nombreListado + "]:", 
                            this.msgBase.cacheColecciones[nombreListado]));
                  
                  this.cantidadSubscripcionesSecundariasLanzadas--;
                  
                //   console.log("cantidadSubscripcionesSecundariasLanzadas",this.cantidadSubscripcionesSecundariasLanzadas, nombreListado);
                  if(this.cantidadSubscripcionesSecundariasLanzadas==0) {  // Devolvió resultados la última subscripción
                        this.finalizoGETSecundarias=true;
                        // cuando están todas las respestas me desiscribo
                        // for (var i=0; i<this.subscripcionCache.length; i++) {
                        //     if(this.subscripcionCache[i]) {
                        //       this.subscripcionCache[i].unsubscribe();
                            
                        //     }
                        // }              
                        this.onResultGetListadosSecundarios();    
                  }

              },(error:any)=>{
                  log(...values("getListadoCacheBase error",error));
                  this.onResultGetListadosSecundarios();
              }) 
          );    
      }
      
      if(this.configListadosCache.length==0) {
        this.onResultGetListadosSecundarios();    
      }  
  
  }

  onResultGetListadoPrincipal() {
    log(...values('funcionComponente','page-base.onResultGetSubscripcionPrincipal'));
    

    if(this.finalizoGETPrincipal && this.finalizoGETSecundarias) {
        this.onResultGetListadoPrincipalYSecundarias();
    }

  }  

  onResultGetListadosSecundarios() {
    log(...values('funcionComponente','page-base.onResultGetSubscripcionSecundarias'));

    if(this.finalizoGETPrincipal && this.finalizoGETSecundarias) {
        this.onResultGetListadoPrincipalYSecundarias();
    }
  }    

  onResultGetListadoPrincipalYSecundarias() {
    log(...values('funcionComponente','page-base.onResultGetSubscripcionPrincipalYSecundarias'));
   
  }    
  
  // -------------- Emits de Componentes Incluídos ---------------------------------------

  emitClickPaginado(paginaRequerida:GRILLA_PAGINA_REQUERIDA):void{
    console.log('jj paginaRequerida',paginaRequerida);
     log(...values('valores','emitClickPaginado paginaRequerida:',paginaRequerida));
     this.grilla.setPaginaRequerida(paginaRequerida);
     this.geListadoPrincipal();
  }    

  emitClickPaginadoSQL(paginaRequerida:number):void{
    console.log('jj paginaRequerida2',paginaRequerida);
    log(...values('valores','emitClickPaginado paginaRequerida:',paginaRequerida));
    this.grilla.paginaActualNro = paginaRequerida;
    this.geListadoPrincipal();
    }  
    
  EmitCargoSearchAutocompleteFiltro(fieldFiltro:string, bdFieldName:string, documento:any) {
      // console.log("EmitCargoSearchAutocompleteFiltro - fieldFiltro:",fieldFiltro,"bdFieldName:",bdFieldName,"documento:",documento);  

      this.grilla.filtros[fieldFiltro]= this.fn.getDocField(documento,bdFieldName);    
      // console.log("this.fn.getDocField(documento,"+bdFieldName+")",this.fn.getDocField(documento,bdFieldName));

      this.filtrarGrilla();
  }

  EmitCargoSearchAutocompleteForm(fieldForm:string, bdFieldName:string, documento:any) {
        // console.log("EmitCargoSearchAutocompleteForm - fieldForm:",fieldForm,"bdFieldName:",bdFieldName,"documento:",documento);  

        if(bdFieldName=='KNAI') {
            this.form.get(fieldForm).setValue( this.fn.setearOrganizacionKNAI(documento) );
        } else if(bdFieldName=='KANE') {
            this.form.get(fieldForm).setValue( this.fn.setearKANE(documento) );
        } else if(bdFieldName=='KN') {
            this.form.get(fieldForm).setValue( this.fn.setearKN(documento) );
        } else if(bdFieldName=='OBJ') {
            // if(documento.keywords!==undefined)         delete documento.keywords;
            // if(documento.settings!==undefined)         delete documento.settings;
            // if(documento.organizacionKNAI!==undefined) delete documento.organizacionKNAI;
            this.form.get(fieldForm).setValue( documento );
        } else {        
            this.form.get(fieldForm).setValue( this.fn.getDocField(documento,bdFieldName) );
        }
        // console.log("this.form.get("+fieldForm+").value",this.form.get(fieldForm).value);
        
        // En caso de que el formulario tenga seteado distribuidorKN, y venga el campo distribuidor lo seteo
        // Se utiliza en el módulo de Rutas, donde en caso de perfil Desarrollador, Supervisor, Distribuidor se ingresa a mano
        console.log("this.form",this.form);
        if(this.form.controls.distribuidorKN!==undefined && documento && documento['distribuidor']!=undefined) {
            this.distribuidorKN = this.fn.setearKN(documento['distribuidor']);            
            this.form.get('distribuidorKN').setValue( this.fn.setearKN(documento['distribuidor']) );            
        }
        
  }

  emitCargoSubEstructura(subestructura){
     log(...values('valores','emitCargoSubestructura subestructura:',subestructura));
     this.form.get(subestructura.nameField).setValue(subestructura.value);      
  }


  emitDesabilitarGrabar(estado:boolean):void{
     log(...values('valores','emitDesabilitarGrabar estado:',estado));
     this.permisos.deshabilitarGrabar = estado;  
  }    

  emitCargoArchivo(imagenes:any){
     log(...values('valores','emitCargoArchivo imagenes:',imagenes));
     // this.uploadImagen=imagen;

     let fieldName = imagenes[0];
     this.arrayFILES[ fieldName ].nombreArchivo    = imagenes[1];
     this.arrayFILES[ fieldName ].extensionArchivo = imagenes[2];
     if(fieldName.includes('IMG')) {
        this.arrayFILES[ fieldName ].link          = imagenes[3];
        this.arrayFILES[ fieldName ].linkThumb     = imagenes[4];
     } else {
        this.arrayFILES[ fieldName ].link          = imagenes[3];
     }
     this.arrayFILES[ fieldName ].borrar           = false;

     if(fieldName.includes(".")) {
        let aux=fieldName.split('.') 
        this.form.value[ aux[0] ][ aux[1] ].link = this.arrayFILES[ fieldName ].link;    
     } else {
        this.form.value[fieldName].link = this.arrayFILES[ fieldName ].link;    
     }

     log(...values('valores',`arrayFiles[${fieldName}]`),this.arrayFILES[ fieldName ]);

  }

  emitBorroArchivo(fieldName:any){
     log(...values('valores','emitBorroArchivo fieldName:',fieldName));

     this.arrayFILES[ fieldName ].nombreArchivo    = null;
     this.arrayFILES[ fieldName ].extensionArchivo = null;
     this.arrayFILES[ fieldName ].link             = null;
     this.arrayFILES[ fieldName ].linkThumb        = null;
     this.arrayFILES[ fieldName ].borrar           = true;

     log(...values('valores',`arrayFILES[${fieldName}]`),this.arrayFILES[ fieldName ]);

  }
  

  abrirFormulario(documentoOriginal:T) {    
    log(...values('funcionComponente','page-base.abrirFormulario'));
    //let documento=documentoOriginal ? {...documentoOriginal} : null;
    let documento=copiarObjetoNew(documentoOriginal);
    
    if(documento){
        // log("valores","keywords:", this.generateKeywords(documento.nombre) );
        log(...values('valores','documento:',documento));
        log(...values('valores','form:',this.form));

        // Formateo Campos Decimales
        
        documento=this.acomodaCamposDecimalesEnDocumento(documento);
        
        log(...values('valores','documento:',documento));
        
        this.resetFormulario();
        this.form.setValue( this.fn.igualarDocumentoConForm(this.form,documento,'destinoForm'));
        log(...values('valores','form:',this.form));

        this.setAccionForm(ACCION_FORM.CONSULTAR);
    } else { 
        this.setAccionForm(ACCION_FORM.AGREGAR);
    }   

  }

  setAccionForm(accion:ACCION_FORM) {
    log(...values('funcionComponente','page-base.setAccionForm', accion));

    this.accionForm=accion;
    
    if(accion==ACCION_FORM.LISTADO) {
        this.resetFormulario();

    } else if(accion==ACCION_FORM.CONSULTAR) {
        this.form.disable();
        
    } else { // agregar o modificar
        
        this.form.enable();
        this.formInvalid=false;

        if(accion==ACCION_FORM.AGREGAR) {
            this.resetFormulario();
            
            // Reemplazo 'Array' x []
            this.form.setValue( this.fn.inicializaArrays(this.form.value) );
            log(...values('valores','form:',this.form.value));
            
            // Inicializo Campos tipo IMG/FILE
            this.arrayFILES.forEach(fieldName => {
                // log(...values('valores','agregar arrayFiles fieldName:',fieldName));
                // log(...values('valores','agregar arrayFiles[fieldName]:',this.arrayFILES[fieldName]));
                // log(...values('valores','agregar form[fieldName]:',this.form.get(fieldName).value));
                this.form.get(fieldName).setValue({
                    link: null,
                    linkThumb: null,
                    bytes: 0,
                    bytesThumb: 0                
                });
                this.arrayFILES[fieldName] = {
                  input  : null,
                  borrar : null
                }  
            });  
        }  
        if(accion==ACCION_FORM.MODIFICAR && this.form.get('key') ) {
            this.form.get('key').disable();
            
            // if(this.form.get('settings.isBorrado').value==true) {
            //     this.form.disable();
            //     this.form.get('settings.isBorrado').enable();            
            // }    
        }    
    } 
    
    if(accion==ACCION_FORM.CONSULTAR) {
        
        log(...values("valores:","vecImagenesDownloadeadas",this.vecImagenesDownloadeadas));
        
        this.arrayFILES.forEach(fieldName => {
            
            let linkImage  =  (this.form.get(fieldName).value!=null) ? this.form.get(fieldName+'.link').value : null;
            let bytesImage =  (this.form.get(fieldName).value!=null) ? this.form.get(fieldName+'.bytes').value : 0;
            
            log(...values("valores","linkImage:",linkImage));
            log(...values("valores","this.arrayFILES[fieldName].type:",this.arrayFILES[fieldName].type));

            if(this.arrayFILES[fieldName].type=='IMG' && linkImage !=null &&
               this.vecImagenesDownloadeadas.indexOf(linkImage)==-1) {

                this.vecImagenesDownloadeadas.push( linkImage );
                

                this.apis.LogApiFuncion({
                    eventoQueDisparo : 'page-base.setAccionForm - Consulta Documento - Imágen/es del Formulario',
                    apiFuncionKey    : 'FirebaseStorageOperationDownload', 
                    organizacionKNAI : this.organizacionKNAI,
                    usuarioKANE      : this.usuarioKANE,
                    nombreColeccion  : this.nombreColeccion,
                    cloudFunction    : null,
                    cantidad         : 1, 
                });

                this.apis.LogApiFuncion({
                    eventoQueDisparo : 'page-base.setAccionForm - Consulta Documento - Imágen/es del Formulario',
                    apiFuncionKey    : 'FirebaseStorageDownloaded', 
                    organizacionKNAI : this.organizacionKNAI,
                    usuarioKANE      : this.usuarioKANE,
                    nombreColeccion  : this.nombreColeccion,
                    cloudFunction    : null,
                    cantidad         : bytesImage,
                });
            }
        }); // fin forEach           
    }
    
  }
  
  onSubmit(documento:any):void {

      log(...values('funcionComponente','page-base.onSubmit'));
      log(...values('valores','form:',this.form));      
      
      let controls=this.form.controls;  
  
      // Agrego campos eliminados de value por los disable()
      documento = this.fn.agregarDisabledFields(documento,controls);

      if( JSON.stringify(this.documentoOriginal) != JSON.stringify(documento) ) {
            log(...values('valores','controls:',controls));      
            log(...values('valores','formatearDocumentoPostSubmit documento:',documento));
            this.documentoOriginal = documento;
      }

      this.spinner.show();

      if (this.form.invalid ) {
          
          for(let atributo in this.form.controls) {
            if(this.form.controls[atributo].status=="INVALID") {
                log(...values('error',"Error de validación en "+atributo));      
            }          
          }          

          this.formInvalid=true;
          this.form.markAllAsTouched();

          this.spinner.hide();
          this.toastrService.error( '', this.translate.instant('componente-listado.erroresValidacion'),{
               timeOut: 2000,positionClass:'toast-center-center'});

      } else {
          
        // console.log("this.arrayFILES",this.arrayFILES);   

        this.arrayUpload = [];

        this.arrayFILES.forEach(fieldName => {

            log(...values("valores","fieldName:",fieldName));
            log(...values("valores",`arrayFiLES[${fieldName}]:`,this.arrayFILES[fieldName]));

            let estructura       = null;
            let campoEstructura  = null;
            let campoSave        = fieldName;                  
            
            if(fieldName.includes('.')) {
                estructura      = fieldName.split('.')[0];
                campoEstructura = fieldName.split('.')[1];
                campoSave       = campoEstructura;
            }  

            if(this.arrayFILES[fieldName].link !=null) {

                log(...values("valores","documento[fieldName]:",documento[fieldName]));
                log(...values("valores","this.arrayFILES[fieldName]:",this.arrayFILES[fieldName]));
                if(fieldName.includes('.')) {
                    documento[estructura][campoEstructura].link = this.arrayFILES[fieldName].link;
                } else {
                    documento[fieldName].link = this.arrayFILES[fieldName].link;
                }  

                // log(...values("imagen",`${fieldName}.link:`,documento[fieldName].link));
                var path = this.fn.rutaUpload({
                    extension        : this.arrayFILES[fieldName].extensionArchivo,
                    nombreColeccion  : this.nombreColeccion,
                    organizacionKNAI : this.organizacionKNAI,
                    adicionalNombre  : campoSave
                });

                this.arrayUpload.push({
                    fieldName : fieldName,
                    linkName  : 'link',
                    path      : path,
                    image     : this.arrayFILES[fieldName].link
                });

            }    
                  
            // log(...values("lineaVariables"));

            if(this.arrayFILES[fieldName].linkThumb !=null) {

                if(fieldName.includes('.')) {
                    documento[estructura][campoEstructura].linkThumb = this.arrayFILES[fieldName].linkThumb;
                } else {
                    documento[fieldName].linkThumb = this.arrayFILES[fieldName].linkThumb;
                }  
                  
                // log(...values("imagen",`${fieldName}.linkThumb:`,documento[fieldName].linkThumb));
                var pathThumb = this.fn.rutaUpload({
                    extension        : this.arrayFILES[fieldName].extensionArchivo,
                    nombreColeccion  : this.nombreColeccion,
                    organizacionKNAI : this.organizacionKNAI,
                    adicionalNombre  : campoSave+'Thumb'
                });

                this.arrayUpload.push({
                    fieldName : fieldName,
                    linkName  : 'linkThumb',
                    path      : pathThumb,
                    image     : this.arrayFILES[fieldName].linkThumb
                });

            }    

        }); // fin For

          log(...values("valores","arrayUpload:",this.arrayUpload));

          if(this.arrayUpload.length > 0) {

              let vecPromesas = [];
              for(let j=0; j<this.arrayUpload.length; j++) {
                  vecPromesas.push(
                    this.bdBaseService.uploadFirestoreImage(
                      this.arrayUpload[j].fieldName,
                      this.arrayUpload[j].linkName,
                      this.arrayUpload[j].path,
                      this.arrayUpload[j].image
                    )
                  );
              }

              Promise.all( vecPromesas).then(vecUploadResult=>{
                  log(...values('funcionEnd','bdBaseService.uploadFirestoreImage'));
                  log(...values('valores','vecUploadResult:',vecUploadResult));

                  for(let i=0; i<vecUploadResult.length; i++) {
                      let uploadResult = vecUploadResult[i];
                      //console.log("i",i,vecUploadResult[i]);

                      if(uploadResult.fieldImage.includes('.')) {
                            let estructura2      = uploadResult.fieldImage.split('.')[0];
                            let campoEstructura2 = uploadResult.fieldImage.split('.')[1];                        
                            documento[estructura2][campoEstructura2][uploadResult.fieldLink] = uploadResult.downloadURL;                        
                      } else {                               
                            documento[uploadResult.fieldImage][uploadResult.fieldLink] = uploadResult.downloadURL;
                      }      

                      let nameBytes = uploadResult.fieldLink.replace('link','bytes');
                      if(uploadResult.fieldImage.includes('.')) {                      
                            let estructura3      = uploadResult.fieldImage.split('.')[0];
                            let campoEstructura3 = uploadResult.fieldImage.split('.')[1];                        
                            documento[estructura3][campoEstructura3][nameBytes] = uploadResult.bytes;
                      } else {  
                            documento[uploadResult.fieldImage][nameBytes] = uploadResult.bytes;
                      }      
                      
                      this.apis.LogApiFuncion({
                            eventoQueDisparo : 'page-base.onSubmit - Upload File',
                            apiFuncionKey    : 'FirebaseStorageUploadOperation', 
                            organizacionKNAI : this.organizacionKNAI,
                            usuarioKANE      : this.usuarioKANE,
                            nombreColeccion  : this.nombreColeccion,
                            cloudFunction    : null,
                            cantidad         : 1, 
                        });
        
                        this.apis.LogApiFuncion({
                            eventoQueDisparo : 'page-base.onSubmit - Upload File',
                            apiFuncionKey    : 'FirebaseStorageStoredData', 
                            organizacionKNAI : this.organizacionKNAI,
                            usuarioKANE      : this.usuarioKANE,
                            nombreColeccion  : this.nombreColeccion,
                            cloudFunction    : null,
                            cantidad         : uploadResult.bytes,
                        });
                          
                  }
                  // log(...values('valores','documento:',documento));

                  this.grabar_coleccion(documento);

              }).catch(error=>{
                  log(...values("error","Error de Upload:",error));
              });

          } else {
              this.grabar_coleccion(documento);
          }    

      } // fin if form.invalid  
  } 
  
  grabar_coleccion(documento) {
      
        log(...values("valores","grabar colección"));
      

        
        // Formateo campos de tipo decimal
        for(let i=0; i<this.camposDecimal.length;i++) {
              let fieldName = this.camposDecimal[i];
              let value = this.fn.getDocField(documento,fieldName);
              //console.log("xx1 fieldName, value1",fieldName, value);
              if(value!=null) {              
                  let valueFloat = this.fn.convertMaskDecimalelToFloat( value, 2);    
                  documento = this.fn.setDocField(documento, fieldName, valueFloat);
              }          
        }

        /* ----- Seteo Keywords ------------------------- */      
        if(this.hasKeyWords()) {
            let stringKeywords = documento[ this.campoModeloUsadoEnKeywords ];
           
            documento['keywords'] = this.fn.generateKeywords( stringKeywords );
            log(...values('valores',"documento['keywords']:", documento['keywords']));                    
        }

        /* ----- Seteo Archivos para Grabar ------------------------- */      
        this.arrayFILES.forEach(fieldName => {

            if(this.arrayFILES[fieldName].borrar==true) {
                // Lo blanqueo para que se borre en la base de Datos
                documento[fieldName]=null;       

            } else if(this.arrayFILES[fieldName].link !=null) {
                // documento[fieldName] va a pisar el valor grabado anteriormente

            } else if(documento[fieldName]!=null) {
                // Quito el campo del objeto, para que no se borre al grabar con set({merge:true})
                delete documento[fieldName];    
            } 

        });
            
        // Seteo el Usuario que realiza la operación
        console.log(documento);
        // documento.settings.usuarioKANE = this.usuarioKANE; // esto se hace en updateColeccion

        /* ------ Seteo el campo organizacionKN en caso de que la tabla lo utilice ---------- */
        let argumentoOrganizacionKNAI = this.organizacionKNAI;
        if(documento.organizacionKNAI!==undefined) {
            argumentoOrganizacionKNAI = documento.organizacionKNAI;
        }    

        // Eliminar campos no desados del formulario

        for (let i=0; i< this.listadoCamposNoDeseados.length; i++) {
            let campo=this.listadoCamposNoDeseados[i];
            // if(documento[campo] ){
                delete documento[campo]
            // }

        }

        this.form.disable();  // Deshabilito el Formulario, para que no explote cuando asigno centinela a settings.FechaHoraModificacion
        console.log('this.acctionForm',this.accionForm);
      
        this.bdBaseService.updateBase({
            operacionFormulario        : this.accionForm,
            campoClave                 : this.campoClave,
            nombreColeccion            : this.nombreColeccion,
            documento                  : documento,
            usaSettings                : this.configComponente.hasUsaSettings(),
            tipoBaseDeDatos            : this.configComponente.tipoBaseDatoListadoPrincipal,
            urlBaseDatos               : this.urlBaseDatos
        }).then(respuesta=>{

               console.log(respuesta)

                // let keyForm = dato.replace('|mensajes.grabacionOk', '');
                // let mensajeServicio = dato.replace(keyForm+'|', '');
                let mensajeServicio = 'grabacion ok';

                log(...values('success','resupuesta Promesa ok',mensajeServicio));

                this.toastrService.success('', this.translate.instant(mensajeServicio),{
                    timeOut: 2000, positionClass:'toast-top-center'});

                if(this.configComponente.tipoBaseDatoListadoPrincipal!=TIPO_BASE_DATOS.FIRESTORE) {
                   this.geListadoPrincipal();    
                }
                    
                this.postGrabarColeccion(documento);    

                if(this.accionForm==ACCION_FORM.AGREGAR) {
                    // Asigno el key obtenido al Formulario
                    if(this.configComponente.hasTipoBaseDatosListdoPrincipalFirestore()) {
                        let keyForm = respuesta.replace('|mensajes.grabacionOk', '');
                        let mensajeServicio = respuesta.replace(keyForm+'|', '');
                        this.form.get('key').setValue(keyForm);
                    }

                    this.setFitrosDespuesDeGrabar(documento,this.campoModeloUsadoEnKeywords);
                      
                    this.filtrarGrilla();                    
                    
                    this.setAccionForm(ACCION_FORM.LISTADO);  
                    this.spinner.hide();                          
                    
                } else {
                    this.setAccionForm(ACCION_FORM.LISTADO);  
                    this.spinner.hide();                          
                }   
                
          

          })
          .catch(error=>{
                log(...values("error","Error Promesa Update Coleccion:",error));

                this.spinner.hide();
                this.toastrService.error('', this.translate.instant('mensajes.errorGrabar'),{
                           timeOut: 2000, positionClass:'toast-top-center'});
          });


  }
  
  setFitrosDespuesDeGrabar(documento:any,item:string){
    // Fuerzo el Filtro por el Nombre del Documento que se acaba de agregar
    this.grilla.filtros[item ] = documento[ item ];  

  }

    postGrabarColeccion(documento:any)  {
        log(...values('funcionComponente','page-base.postGrabarColeccion',documento));
        this.geListadoPrincipal();
        // Nada, la actividad se realiza en el componente de ser necesaria
    }  

    deleteDocumento(operacion: ACCION_FORM, documento:any){
        log(...values('funcionComponente','page-base.deleteDocumento',operacion));
        this.confirmService.confirm({ 
            title:   this.translate.instant('navegabilidad.eliminar'), 
            message: this.translate.instant('mensajes.confirmaEliminar') })
        .then((resultado) => {
            if (resultado == 'close') return;

            this.spinner.show();

            this.bdBaseService.updateBase({
                operacionFormulario         : operacion,
                nombreColeccion             : this.nombreColeccion,
                campoClave                  : this.campoClave,
                documento                   : documento,
                usaSettings                 : this.configComponente.hasUsaSettings(),
                tipoBaseDeDatos             :this.configComponente.tipoBaseDatoListadoPrincipal,
                urlBaseDatos                : this.urlBaseDatos

            }).then(dato=>{
                    log(...values("success","OK Promesa Update Coleccion2:", dato));
                    this.spinner.hide();
                    this.toastrService.success('', this.translate.instant('mensajes.eliminacionOk'),{
                                timeOut: 2000, positionClass:'toast-top-center'});
                                
                    this.postGrabarColeccion(documento);                
            })
            .catch(error=>{
                    this.spinner.hide();
                    
                    this.confirmService.confirm({ 
                        title:   this.translate.instant('mensajes.errorEliminar'), 
                        message: error })
            });

        }).catch(error=>{
            console.log(error);
        }); 

    }
   
  
    setBdBaseData(){

        this.bdBaseService.setUrlServer(this.usuario.getUrlBaseDatos());
        this.bdBaseService.setDistribuidorKN(this.distribuidorKN);
        this.bdBaseService.setOrganizacionKNAI(this.organizacionKNAI);
        this.bdBaseService.setUsuarioKANE(this.usuarioKANE);
        
    }
   
    seleccionaWhere():WhereInterface[]{
       return  this.whereListadoPrincipal.concat(this.grilla.whereArray);
    }

    seleccionaOrderBy():OrdeByInterface[]{
        let orderBy:OrdeByInterface[]=[];
        if(this.grilla.getGrillaOrden().length>0){
            orderBy = this.grilla.getGrillaOrden()
        } else {
            orderBy = this.orderByListadoPrincipal;
        }
        return orderBy;
    }

    setArrayFILES(fieldName:string, valores:FilesDataInterfase) {
        this.arrayFILES.push(fieldName);
        this.arrayFILES[fieldName] = valores;
    }

   
    hasKeyWords():Boolean{
        return this.campoModeloUsadoEnKeywords!=null;
    }

    acomodaCamposDecimalesEnDocumento(documento:any):any{

        for(let i=0; i<this.camposDecimal.length; i++) {
            let fieldName=this.camposDecimal[i];
            let value = getDocField(documento,fieldName);
            //console.log("xx2 fieldName, value1",fieldName, value);
            if(value!=null) {
                let valueFormatNumber = formatNumber( value, 'es-Ar',"1.2-2");    
                documento = setDocField(documento, fieldName, valueFormatNumber);
            }
        }
    
        return documento;
    
    }

    
  isCampoDecimalEsteCampo(value:string):boolean{
    return this.camposDecimal.indexOf(value)!=-1
  }

  public profileRequiredSelectingOrganization():boolean{
    return ['Desarrollador','Supervisor','Distribuidor'].indexOf(this.tipoPerfilUsuario)!=-1;
  }

  public profileNotRequiredSelectingOrganization():boolean{
    return (!this.profileRequiredSelectingOrganization() && this.tipoPerfilUsuario=='Organizacion' && this.organizacionKNAI!=null && this.organizacionKNAI!=undefined);
  }

}


export class InjectorBase {

    private static injector: Injector;

    static setInjector(injector: Injector) {
            InjectorBase.injector = injector;
    }

    static getInjector(): Injector {
            return InjectorBase.injector;
    }

}
