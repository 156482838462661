import { DatosPersonales } from './../../models/datosPersonales/datosPersonales.model';
import { log, logIf, logTable, values } from '@maq-console';

import { Meta, BrowserModule, Title } from '@angular/platform-browser';

import { Injectable } from '@angular/core';

import { TranslateService }   from '@ngx-translate/core';
import { AppSettingsService } from '@settings/app.settings';

import { FormGroup, FormControl, FormBuilder, FormArray, Validators, FormsModule } from '@angular/forms';
// import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { atan2, chain, derivative, e, evaluate,  pi, pow, round, sqrt} from 'mathjs'

import { PermisosInterface           } from '@maq-models/usuarios/usuarios.model';
import { PerfilUsuario, PermisosMenu } from '@maq-models/perfiles/perfiles.model';
import { Direccion }                   from '@maq-models/direccion/direccion.model';
import { KN, KANE, KNAI }              from '@maq-models/typesKN/typesKN.model';
import { Cotizacion }                  from '@maq-models/monedasImportes/monedasImportes.model';

import * as $ from 'jquery'
import {doesNotThrow} from 'assert';
import { pl } from 'date-fns/locale';
import {isDate} from 'date-fns';


@Injectable({
  providedIn: 'root'
})

export class FuncionesService {

    constructor(private translate: TranslateService,
                public appSettings:AppSettingsService,
                private meta: Meta,
                private title: Title) { }

    /* ------------------------------------------------------------------------
    FUNCIONES CLASICAS DE JAVASCRIPT
    ------------------------------------------------------------------------ */
    
    seguntosToHora(segundos:string):any {
      if(segundos===undefined || segundos==null || segundos=='') {
          return null;
      }
      
      var sec_num = parseInt(segundos, 10); // don't forget the second param
      var hours   = Math.floor(sec_num / 3600);
      var minute = Math.floor((sec_num - (hours * 3600)) / 60);
      var second = sec_num - (hours * 3600) - (minute * 60);
      
      return {
        hour: hours,
        minute: minute,
        second : second
      }   
    }
      
    textToBoolean(texto) {
      if(texto==null || texto==undefined) {
        return false;
        
      } else if(texto=="true") {
        return true;
        
      } else {
        return false;
      }
    }
    
    remueveTagP(texto) {
      texto=texto.replace("<p>","");
      texto=texto.replace("<P>","");
      texto=texto.replace("</p>","");
      texto=texto.replace("</P>","");
      return texto;      
    }
      
    decodeHTMLEntities(text) {
      var textArea = document.createElement('textarea');
      textArea.innerHTML = text;
      let rta=textArea.value;
      rta = rta.replace(/\u00a0/g, " ");
      rta = rta.replace("<br/>","");
      rta = rta.replace("<br />","");
      return rta;
    }
    
    getRouterLinkPath(routerLink:string) {
        let aux=routerLink.split('#');
        return aux[0];
    }
      
    getRouterLinkFragment(routerLink:string) {
        let aux=routerLink.split('#');
        return aux[1];    
    }    
    
    url_amigable(url:string):string {

      if(url=='' || url==undefined) return url;
            
      var encodedUrl=url;
    
      // Eliminamos algunos caracteres especiales NO HIZO FALTA, LOS FILTRA MAS ABAJO
      /*
      var specialChars = "!@#$^&%*()+=[]{}?";
      for (var i = 0; i < specialChars.length; i++) {
          encodedUrl= encodedUrl.replace(new RegExp("\\" + specialChars[i], 'gi'), '');
      }   
    
      // Reemplazamos algunos caracteres especiales
      var specialChars = "/|:,.";
      for (var i = 0; i < specialChars.length; i++) {
          encodedUrl= encodedUrl.replace(new RegExp("\\" + specialChars[i], 'gi'), '');
      } 
    
      // Reemplazo caracteres extraÃ¹os que vienen como dos ASC
      //encodedUrl = str_replace( String.fromCharCode(97,45),"-",encodedUrl);  // Â°
    
      */  
               
      // make the url lowercase         
      var encodedUrl:string = url.toString().toLowerCase(); 
    
      // reemplaza acentos y Ñ por su letra
      encodedUrl = this.quita_acentos(encodedUrl); 
    
      // replace & with and           
      encodedUrl = encodedUrl.split(/\&+/).join("-y-")
    
      // remove invalid characters 
      encodedUrl = encodedUrl.split(/[^a-z0-9]/).join("-");       
    
      // remove duplicates 
      encodedUrl = encodedUrl.split(/-+/).join("-");
    
      // trim leading & trailing characters 
      //encodedUrl = encodedUrl.trim('-'); 
    
      // elimino - de la primera posición
      if(encodedUrl.substr(0,1)=='-') {
         encodedUrl = encodedUrl.substr(1, 1000);
      }
      
      // elimino - de la última posición
      if(encodedUrl.substr(encodedUrl.length-1)=='-') {
         encodedUrl = encodedUrl.substr(0, encodedUrl.length-1);
      }
    
      return encodedUrl; 
    }
    
    quita_acentos(cadena:string):string {

      let aux=''; 
      for (var i2 = 0; i2 < cadena.length; i2++) {
          //console.log(cadena.substr(i2,1), cadena.charCodeAt(i2));
          let letra = cadena.substr(i2,1)
          let asc   = cadena.charCodeAt(i2);
          if(asc>=192 && asc<=197) { aux+='A'; } 
          else if(asc>=200 && asc<=203) { aux+='E'; }
          else if(asc>=204 && asc<=207) { aux+='I'; }
          else if(asc>=210 && asc<=214) { aux+='O'; }
          else if(asc>=217 && asc<=220) { aux+='U'; }
          else if(asc>=224 && asc<=229) { aux+='a'; }
          else if(asc>=232 && asc<=235) { aux+='e'; }
          else if(asc>=236 && asc<=239) { aux+='i'; }
          else if(asc>=242 && asc<=246) { aux+='o'; }
          else if(asc>=249 && asc<=252) { aux+='u'; }
          else if(asc==209) { aux+='N'; } 
          else if(asc==241) { aux+='n'; } 
          else { aux+=letra; }
      }
      cadena = aux;  
      
      cadena = cadena.replace(/Ã¥/gi, 'a');
      cadena = cadena.replace(/ÃŠ/gi, 'e');
      cadena = cadena.replace(/Ã­/gi, 'i');
      cadena = cadena.replace(/Ã³/gi, 'o');
      cadena = cadena.replace(/Ãº/gi, 'u');  
      cadena = cadena.replace(/Ã¹/gi, 'n');
      
      return cadena;    
    }
        
        
    addMinutosToDatetime(fecha,minutos) {
        //console.log("addMinutosToDatetime fecha, minutos",fecha,minutos);
        return new Date(fecha.getTime() + minutos*60000);
    }

    addSegundosToDatetime(fecha,segundos) {
        //console.log("addMinutosToDatetime fecha, minutos",fecha,minutos);
        return new Date(fecha.getTime() + segundos*1000);
    }
    
    distancia_geopoints(lat1:any, lon1:any, lat2:any, lon2:any, unit:string):number {
      /*
      lat1, lon1 = Latitude and Longitude of point 1 (in decimal degrees) 
      lat2, lon2 = Latitude and Longitude of point 2 (in decimal degrees) 
      unit = the unit you desire for results                              
           where: 'miles' is statute miles (default)                         
                  'km' is kilometers                                      
                  'nauticalmiles' is nautical miles              
     */
      if ((lat1 == lat2) && (lon1 == lon2)) {
        return 0;
      } else {
        if(typeof lat1==='string') lat1=parseFloat(lat1);
        if(typeof lon1==='string') lon1=parseFloat(lon1);
        if(typeof lat2==='string') lat2=parseFloat(lat2);
        if(typeof lon2==='string') lon2=parseFloat(lon2);
        
        var radlat1 = Math.PI * lat1/180;
        var radlat2 = Math.PI * lat2/180;
        var theta = lon1-lon2;
        var radtheta = Math.PI * theta/180;
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
          dist = 1;
        }
        dist = Math.acos(dist);
        dist = dist * 180/Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit=="km")            { dist = dist * 1.609344 }
        if (unit=="nauticalmiles") { dist = dist * 0.8684 }
        return dist;
      }
    }
        
    aplicarCotizacionMoneda(importeMonedaOrigen:number, monedaOrigenKey, monedaDestinoKey, cotizaciones:Cotizacion) {
        //console.log("aplicarCotizacionMoneda", importeMonedaOrigen, monedaOrigenKey, monedaDestinoKey, cotizaciones);

        if(importeMonedaOrigen===null || monedaOrigenKey==null || monedaDestinoKey==null || cotizaciones==null) {
            return 0.0001;
        } else {
              
           let conversion:number=null;
            
            // Convierto a Pesos
            if(monedaDestinoKey=='Pesos') {
                if(monedaOrigenKey=='Pesos') {
                    conversion = importeMonedaOrigen;
                    
                } else if(monedaOrigenKey=='Dolares') {
                    conversion = importeMonedaOrigen * cotizaciones['Dolares'];
                    
                } else if(monedaOrigenKey=='DolaresDivisa') {
                    conversion = importeMonedaOrigen * cotizaciones['DolaresDivisa'];
                }  
                
            } else if(monedaDestinoKey=='Dolares') {
                if(monedaOrigenKey=='Dolares') {
                    conversion = importeMonedaOrigen;
                    
                } else if(monedaOrigenKey=='Pesos') {
                    conversion = importeMonedaOrigen / cotizaciones['Dolares'];
                    
                } else if(monedaOrigenKey=='DolaresDivisa') {
                    
                    conversion = importeMonedaOrigen * cotizaciones['DolaresDivisa'] / cotizaciones['Dolares'];
                }  

            } else if(monedaDestinoKey=='DolaresDivisa') {
                if(monedaOrigenKey=='Divisa') {
                    conversion = importeMonedaOrigen;
                    
                } else if(monedaOrigenKey=='Pesos') {
                    conversion = importeMonedaOrigen / cotizaciones['DolaresDivisa'];
                    
                } else if(monedaOrigenKey=='Dolares') {
                    
                    conversion = importeMonedaOrigen * cotizaciones['Dolares'] / cotizaciones['DolaresDivisa'];
                }                  
            }
            
            return conversion;
                
            
        } // fin if==null
      
    }
      
    convertMaskDecimalelToFloat(value:any, cantDecimales:number):number | null {
        let rta:number;

        if(typeof value ==='string' && value.includes(',')) {
          value = value.replace('.','');
          value = value.replace(',','.');
        }

        rta = parseFloat(value);

        if (Number.isNaN(rta) == true) {
          rta = null;
        }
        else{
          if(cantDecimales==0) rta = Math.round(rta);
          if(cantDecimales==1) rta = Math.round(rta*10)/10;
          if(cantDecimales==2) rta = Math.round(rta*100)/100;
          if(cantDecimales==3) rta = Math.round(rta*1000)/1000;
          if(cantDecimales==4) rta = Math.round(rta*10000)/10000;
        }
        return rta;  
    }
    
    formatStringAsNumber(value:string, cantDecimales:number) {
        console.log("XX ",'value1',value);
        let entero;
        let decimales;
        value = value.replace('.',',');
        console.log("XX ",'value2',value);
        
        if(value.indexOf(',')!=-1) {
            entero=value.split(',')[0];  
            decimales=value.split(',')[1];  
        } else {
            entero=value;  
            decimales='0';        
        }
        
        console.log("XX ",'entero',entero);
        console.log("XX ",'decimales',decimales);
        
        let enteroConPunto='';         
        let cant=0;
        for(let i=entero.length-1; i>=0; i--) {
            cant++;
            if(cant>3) {
              enteroConPunto='.'+enteroConPunto;
              console.log("XX ",'enteroConPunto1',enteroConPunto);
              cant=1;
            }
            
            let caracter=entero[i];
            enteroConPunto=caracter+enteroConPunto;        
            console.log("XX ",'enteroConPunto2',enteroConPunto);
        }
        console.log("XX ",'enteroConPunto3',enteroConPunto);
        
        console.log("XX ','enteroConPunto+decimales",enteroConPunto+','+decimales);
        
        if(cantDecimales==0) {
            console.log("XX ",'rta',enteroConPunto);
            return enteroConPunto;
        } else {
            decimales=decimales+'000000';
            decimales=decimales.substr(0,cantDecimales);
            console.log("XX ",'rta',enteroConPunto+','+decimales);
            return enteroConPunto+','+decimales;  
        }
      
    }
    
    getDocField(documento:any, nameField:string):any {
          let aux=nameField.split('.');
          let rta:any=null;
          // console.log('test aux: ',aux);
          //console.log('getDocField documento: ',nameField);
          if(aux.length==1) {
              if(documento!=null &&
                 documento[nameField]!==undefined) {
                   
                 rta  = documento[nameField];
              }
              
          } else if(aux.length==2) {
              if(documento!=null &&
                 documento[ aux[0] ]!==undefined && documento[ aux[0] ]!=null &&
                 documento[ aux[0] ][ aux[1] ]!==undefined) {

                    rta  = documento[ aux[0] ][ aux[1] ];
              }
              
          } else if(aux.length==3) {
              if(documento!=null &&
                 documento[ aux[0] ]!==undefined && documento[ aux[0] ]!=null &&
                 documento[ aux[0] ][ aux[1] ]!==undefined && documento[ aux[0] ][ aux[1] ]!=null &&
                 documento[ aux[0] ][ aux[1] ][ aux[2] ]!==undefined) {

                    rta  = documento[ aux[0] ][ aux[1] ][ aux[2] ];
              }
              
          } else if(aux.length==4) {
              //if(nameField.includes('ubicacion')) console.log("getDocField 4", aux);
              if(documento!=null &&
                 documento[ aux[0] ]!==undefined && documento[ aux[0] ]!=null &&
                 documento[ aux[0] ][ aux[1] ]!==undefined && documento[ aux[0] ][ aux[1] ]!=null &&
                 documento[ aux[0] ][ aux[1] ][ aux[2] ]!==undefined && documento[ aux[0] ][ aux[1] ][ aux[2] ]!=null &&
                 documento[ aux[0] ][ aux[1] ][ aux[2] ][ aux[3] ] !==undefined) {

                    rta  = documento[ aux[0] ][ aux[1] ][ aux[2] ][ aux[3] ];
              }
          }
          //if(nameField.includes('ubicacion')) console.log("getDocField 5",documento,nameField,rta);
          // console.log("test ",'rta ',rta);
          return rta;
    }
    
    setDocField(documento:any, nameField:string, value:any):any {
          let partes=nameField.split('.');

          switch (partes.length) {
            case 1:
              documento[partes[0]]=value;
              break;
            case 2:
              if(documento[partes[0]]==null || documento[partes[0]]==undefined){
                documento[partes[0]]={};
              }
                documento[partes[0]][partes[1]]=value;
              break;	
            case 3:
              if(documento[partes[0]]==null || documento[partes[0]]==undefined){
                  documento[partes[0]]={};
              }
              if(documento[partes[0]][partes[1]]==null ||documento[partes[0]][partes[1]]==undefined){
                  documento[partes[0]][partes[1]]={};
              }   
              documento[partes[0]][partes[1]][partes[2]]=value;
              break;	
            
            
            default:
                // code...
              break;
          }
          //console.log("setDocField",nameField,value,documento);
          return documento;
    }



    traducir(etiqueta: string){
        return this.translate.instant(etiqueta)
    }

    goToLink(url: string){
        window.open(url, "_blank");
    }

    getPeriodoActual(formato){

        var fechaHora = new Date();
        var anio      = fechaHora.getFullYear().toString();
        var anioCorto = anio.substr(2,2);
        var mes       = (fechaHora.getMonth()+1).toString();

        if(mes.length==1) mes='0'+mes;

        if(formato=='AAAA-MM') return anio+'-'+mes;
        if(formato=='AA-MM')   return anioCorto+'-'+mes;
        if(formato=='AAAA/MM') return anio+'/'+mes;
        if(formato=='AA/MM')   return anioCorto+'/'+mes;
        if(formato=='AAAAMM')  return anio+mes;
        if(formato=='AAMM')    return anioCorto+mes;

        return anio+'-'+mes;
    }

    getFechaActual(formato){

        var fechaHora = new Date();
        var anio      = fechaHora.getFullYear().toString();
        var anioCorto = anio.substr(2,2);
        var mes       = (fechaHora.getMonth()+1).toString();
        var dia       = fechaHora.getDate().toString();

        let hora         = this.str0( fechaHora.getHours()       ,2);
        let minutos      = this.str0( fechaHora.getMinutes()     ,2);
        let segundos     = this.str0( fechaHora.getSeconds()     ,2);
        let milisegundos = this.str0( fechaHora.getMilliseconds() ,4);

        if(mes.length==1) mes='0'+mes;
        if(dia.length==1) dia='0'+dia;

        if(formato=='AAAAMMDDHHMMSSMS') return anio+mes+dia+hora+minutos+segundos+milisegundos;        
        if(formato=='AAAA-MM-DD')       return anio+'-'+mes+'-'+dia;
        if(formato=='AA-MM-DD')         return anioCorto+'-'+mes+'-'+dia;
        if(formato=='AAAA/MM/DD')       return anio+'/'+mes+'/'+dia;
        if(formato=='AA/MM/DD')         return anioCorto+'/'+mes+'/'+dia;
        if(formato=='AAAAMMDD')         return anio+mes+dia;
        if(formato=='AAMMDD')           return anioCorto+mes+dia;
        if(formato=='DD')               return dia;

        return anio+'-'+mes;
    }

    getHoraActual(formato){
      var fechaHora = new Date();
      let hora         = this.str0( fechaHora.getHours()        ,2);
      let minutos      = this.str0( fechaHora.getMinutes()      ,2);
      let segundos     = this.str0( fechaHora.getSeconds()      ,2);
      let milisegundos = this.str0( fechaHora.getMilliseconds() ,4);

      if(formato=='HH:MM:SS') return hora+':'+minutos+':'+segundos;
      if(formato=='HH:MM')    return hora+':'+minutos;
      if(formato=='HH:MM hs') return hora+':'+minutos+' hs';
      if(formato=='HHMMSS')   return hora+minutos+segundos;
      if(formato=='HHMM')     return hora+minutos;


    }

    numeroRedoneado(numero:any, posDecimales:number):number {
       return numero.toFixed(posDecimales);
    }
    
    copiarArray(array:any):any {
        if(!Array.isArray( array) ) {
          return array;
        } else {
          let array2=[];
          for(let i=0; i<array.length;i++) {
              let obj = this.copiarObjetoNew(array[i]);
              array2.push(obj);
          }
          return array2;
        }
    }

    copiarArraySimple(array:any):any {
        // a=['papa','manzana']
        if(!Array.isArray( array) ) {
          return array;
        } else {
          let array2=[];
          for(let i=0; i<array.length;i++) {
              let elemento = array[i];
              array2.push(elemento);
          }
          return array2;
        }
    }
  
    // copiarObjeto2(obj:any):any {
    //     return JSON.parse(JSON.stringify(obj))
    // }
  
    copiarObjeto(obj:any):any {
        let objNew = {};
        for (var field in obj) {
            if(typeof obj[field]=='object') {
               objNew[field] = {...obj[field]};
            } else {
               objNew[field] = obj[field];
            }    
        }
        return objNew;
    }
    
    copiarObjetoNew(obj:any):any {
      if(obj==null || obj===undefined) {
          return obj;
      }
      let objNew = {};
      for (var field in obj) {
        
          let valueIsArray = (Array.isArray(obj[field]));
          
          //if(field.includes('fecha')) console.log("copiarObjetoNew isDate",field,obj[field],isDate(obj[field]));
          if (obj[field] && isDate(obj[field]) ) {
             objNew[field] = obj[field];
          } else if (typeof obj[field] == "object" && !valueIsArray && obj[field] !== null && !this.isTimestamp(obj[field]) ) {
             objNew[field] = this.copiarObjetoNew(obj[field]);
          } else {
             objNew[field] = obj[field];
          }    
      }
      //console.log("copiarObjetoNew",obj, objNew);
      return objNew;
  }
  // copiarObjetoNew(obj:any):any {
    //   let conversion = JSON.parse(JSON.stringify(obj));
    //   for (var field in conversion) {
    //     // console.log("typeof conversion[field]",field, typeof conversion[field]);
    //     if(this.checkStringFecha(conversion[field])) {
    //       conversion[field] = new Date(conversion[field]);
    //     } 
    //   }
    //   console.log("copiarObjetoNew",obj, conversion);
  
    //   return conversion;
    // }  
    
    checkStringFecha(campo:any): boolean {
      if(campo==null || campo===undefined) return false;

      let rta=false;      
      if(typeof campo=='string') {
      
          let anio = campo.substr(0,4);
          let mes = campo.substr(5,2);
          let dia = campo.substr(8,2);
          let primerguion  = campo.substr(4,1);
          let segundoguion = campo.substr(7,1);
          
          // console.log("checkStringFecha","anio",anio);
          // console.log("checkStringFecha","mes",mes);
          // console.log("checkStringFecha","dia",dia);
          // console.log("checkStringFecha","primerguion",primerguion);
          // console.log("checkStringFecha","segundoguion",segundoguion);
          
          if(anio>='0001' && anio<='9999' && mes>='01' && mes<='99' && dia>='01' && dia<='99' 
              && (primerguion=='/' || primerguion=='-')
              && (segundoguion=='/' || segundoguion=='-') ) {
                
              rta=true;  
                
          }
      }    
      return rta;
      
    }

    // copiarObjeto(obj:any):any {
    //     return Object.assign({}, obj);
    // }

    // jQueryValue(field):any {

    //     // console.log("jQueryValue field",field);
    //     console.log("jQueryValue value",$("#"+field)[0].value );
    //     return $("#"+field)[0].value;

    // }

    // asignarjQueryValue(field:string, valor:any) {
    //   // console.log("asignarPick",field,valor);
    //   // console.log("asignarPick jQuery value",$("#"+field)[0].value );
    //   $("#"+field)[0].value = valor;
    // }

    escapeRegExp(string:string) {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }

    str_replace(find:string, replace:string, str:string) {
        return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
        // return str.replace(new RegExp(find, 'g'), replace);
    }

    str_replace_fecha(buscar, reemplazarX, str) {

        if(str=='' || str==null || str===undefined) {
          return str;
        }

        let str2=str;

        str2 = str2.replace( buscar, reemplazarX);
        str2 = str2.replace( buscar, reemplazarX);
        str2 = str2.replace( buscar, reemplazarX);

        // console.log("str_replace_fecha",str, buscar, reemplazarX, str2);
        return str2;
    }

    stringToDate(date,format,delimiter, hh,mm,ss) {
        // stringToDate("17/9/2014","dd/MM/yyyy","/");
        // stringToDate("9/17/2014","mm/dd/yyyy","/")
        // stringToDate("9-17-2014","mm-dd-yyyy","-")

        if(date=='' || date==null || date===undefined) {
          return date;
        }

        var formatLowerCase=format.toLowerCase();
        var formatItems=formatLowerCase.split(delimiter);
        var dateItems=date.split(delimiter);
        for(let i=0;i<dateItems.length;i++) {
          dateItems[i]=parseInt(dateItems[i]).toString();
        }
        var monthIndex=formatItems.indexOf("mm");
        var dayIndex=formatItems.indexOf("dd");
        var yearIndex=formatItems.indexOf("yyyy");
        var month=parseInt(dateItems[monthIndex]);
        month-=1;

        // console.log("stringToDate formatItems",formatItems);
        // console.log("stringToDate dateItems",dateItems);
        // console.log("stringToDate monthIndex",monthIndex);
        // console.log("stringToDate dayIndex",dayIndex);
        // console.log("stringToDate yearIndex",yearIndex);

        if(hh!==undefined) {
            var formatedDate = new Date(dateItems[yearIndex],month,dateItems[dayIndex], hh, mm, ss);
        } else {
            var formatedDate = new Date(dateItems[yearIndex],month,dateItems[dayIndex]);
        }

        //console.log("stringToDate", date, formatedDate);

        return formatedDate;
    }

    str_replace_array_x_string(find, replace, str) {
      for (var i = 0; i < find.length; i++) {
        str = this.str_replace(find[i],replace, str);
      }
      return str;
    };

    reemplazaAcentos(cadena:string):string {

      let aux='';
      for (var i2 = 0; i2 < cadena.length; i2++) {
          //console.log(cadena.substr(i2,1), cadena.charCodeAt(i2));
          let letra = cadena.substr(i2,1)
          let asc   = cadena.charCodeAt(i2);
          if(asc>=192 && asc<=197) { aux+='A'; }
          else if(asc>=200 && asc<=203) { aux+='E'; }
          else if(asc>=204 && asc<=207) { aux+='I'; }
          else if(asc>=210 && asc<=214) { aux+='O'; }
          else if(asc>=217 && asc<=220) { aux+='U'; }
          else if(asc>=224 && asc<=229) { aux+='a'; }
          else if(asc>=232 && asc<=235) { aux+='e'; }
          else if(asc>=236 && asc<=239) { aux+='i'; }
          else if(asc>=242 && asc<=246) { aux+='o'; }
          else if(asc>=249 && asc<=252) { aux+='u'; }
          else if(asc==209) { aux+='N'; }
          else if(asc==241) { aux+='n'; }
          else { aux+=letra; }
      }
      cadena = aux;

      cadena = cadena.replace(/á/gi, 'a');
      cadena = cadena.replace(/é/gi, 'e');
      cadena = cadena.replace(/í/gi, 'i');
      cadena = cadena.replace(/ó/gi, 'o');
      cadena = cadena.replace(/ú/gi, 'u');
      cadena = cadena.replace(/ñ/gi, 'n');

      return cadena;
    }

    getPalabras(texto:any): string[] {
          let vecPalabras=[];
          texto.split(' ').forEach(palabra => {
              vecPalabras.push(palabra);
          });
          return vecPalabras;
    }

    quitaCaracteresEspeciales(string:string):string {

      // Eliminamos algunos caracteres especiales NO HIZO FALTA, LOS FILTRA MAS ABAJO
      var specialChars = "!@#$^&%*()+=[]{}?";
      for (var i = 0; i < specialChars.length; i++) {
          string= string.replace(new RegExp("\\" + specialChars[i], 'gi'), '');
      }

      // Eliminamos comillas
      let find = ["'","´",'"','<','>'];
      string = this.str_replace_array_x_string(find, '', string);

      // Reemplazamos algunos caracteres especiales
      var specialChars = "/|:,.";
      for (var i = 0; i < specialChars.length; i++) {
          string= string.replace(new RegExp("\\" + specialChars[i], 'gi'), '');
      }

      // Reemplazo caracteres extraños que vienen como dos ASC
      string = string.replace( String.fromCharCode(97,45),"");

      return string;
    }

    generateKeywords(texto:any) {
      
          if(texto===undefined || texto ==null || texto=='') {
            return [];
          }

          texto = this.reemplazaAcentos(texto);
          // texto = this.quitaCaracteresEspeciales(texto);
          texto = texto.toLowerCase();

          let vecKeywords = [];
          let vecPalabras = this.getPalabras(texto);
          let cadena='';
          for(let i=0; i<vecPalabras.length; i++) {
              cadena=vecPalabras[i];
              // console.log(i,"cadena",cadena);
              for(let j=0; j<vecPalabras.length; j++) {
                  if(j!=i) {
                      cadena+=' '+vecPalabras[j];
                  }
              }
              vecKeywords = vecKeywords.concat( this.createKeywords(cadena) );

          }
          let bytes=0;
          for(let i=0; i<vecKeywords.length; i++) {
              bytes+= vecKeywords[i].length;
          }
          // console.log("texto bytes",texto.length);
          // console.log("keywords bytes",bytes);
          return vecKeywords;
    }


    createKeywords(name:any) {
          const arrName = [];
          let curName = '';
          name.split('').forEach(letter => {
             curName += letter;
             arrName.push(curName);
          });
          return arrName;
    }


    formatFecha2DigitosDiaMes(fecha:string):string {
        // Recibe fecha 1/1/2019, devuelve 01/03/2010

        if(fecha=='' || fecha==null || fecha===undefined) {
          return '';
        }

        let rta=fecha;

        rta = this.str_replace(' ','', rta);

        for(let i=0; i<rta.length; i++) {
          let caracter = rta[i];
          //console.log(caracter,"asc ii",caracter.charCodeAt(0));
        }

        rta = '/' + rta;
        //console.log("format rta1",'['+rta+']');

        rta = this.str_replace_fecha(/\/1\//ig,'/01/',rta);
        rta = this.str_replace_fecha(/\/2\//ig,'/02/',rta);
        rta = this.str_replace_fecha(/\/3\//ig,'/03/',rta);
        rta = this.str_replace_fecha(/\/4\//ig,'/04/',rta);
        rta = this.str_replace_fecha(/\/5\//ig,'/05/',rta);
        rta = this.str_replace_fecha(/\/6\//ig,'/06/',rta);
        rta = this.str_replace_fecha(/\/7\//ig,'/07/',rta);
        rta = this.str_replace_fecha(/\/8\//ig,'/08/',rta);
        rta = this.str_replace_fecha(/\/9\//ig,'/09/',rta);

        rta = rta.substr(1,1000);

        //console.log("formatFecha2DigitosDiaMes rta2",fecha, rta);

        return rta;
    }

    quitaAnio(fecha:string):string {
        // Recibe fecha 1/1/2019, devuelve 01/03

        if(fecha=='' || fecha==null || fecha===undefined) {
          return '';
        }

        let rta=fecha;
        for(let i=1900; i<2100; i++) {
          let anio=i.toString();
          rta=rta.replace('/'+anio,'');
        }

        return rta;
    }

    ordenarXAtributo(key, order = 'asc', translateValue) {
      /*
       * Sirve para Ordena un Array de Objetos
       *
       * Ejemplo
       * this.listadoDistribuidores.sort(this.fn.ordenarXAtributo(orderBy[0].key, orderBy[0].ascDesc,false));
       *
       * const LISTADO_DE_PAISES = [
       *   {id:'Argentina', val:'Argentina',  'husosHorarios': ['UTC-3'] },
       *   {id:'Chile',     val:'Chile',      'husosHorarios': ['UTC-3','UTC-4'] },
       *   {id:'Brasil',    val:'Brasil',     'husosHorarios': ['UTC-3','UTC-4'] },
       *   {id:'Colombia',  val:'Colombia',   'husosHorarios': ['UTC-5'] },
       * ];
       *
       * LISTADO_DE_PAISES.sort(ordenarXAtributo('id'));
       * LISTADO_DE_PAISES.sort(ordenarXAtributo('val'));
       * LISTADO_DE_PAISES.sort(ordenarXAtributo('val', 'desc'));
       */
      
      let self=this; 

      return function innerSort(a, b) {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
          // property doesn't exist on either object
          return 0;
        }
        
        //console.log("sort",key, a[key],Object.prototype.toString.call(a[key]));

        if(translateValue) {
          //console.log("translate a[key]",a[key]);
          //console.log("translate b[key]",b[key]);
          var varA = (typeof a[key] === 'string') ? self.translate.instant(a[key]) : a[key];
          var varB = (typeof b[key] === 'string') ? self.translate.instant(b[key]) : b[key];

        } else if( Object.prototype.toString.call(a[key]) === "[object Date]") {
          var varA = a[key];
          var varB = b[key];
                    
        } else if((typeof a[key] === 'string')) {
          var varA = a[key] ? a[key].toUpperCase() : '';
          var varB = b[key] ? b[key].toUpperCase() : '';
          
        } else {
          var varA = a[key];
          var varB = b[key];
        }
      
        let comparison = 0;        
        if( Object.prototype.toString.call(a[key]) === "[object Date]") {

            let comparacion=self.compareFechas(varA, varB);
            //console.log(varA,varB,comparacion);
            if (comparacion=='1mayor2') {
                comparison = 1;
            } else if(comparacion=='1menor2' || comparacion=='iguales') {
                comparison = -1;
            }
        } else {
            if (varA > varB) {
              comparison = 1;
            } else if (varA < varB) {
              comparison = -1;
            }
        }
        return (
          (order === 'desc') ? (comparison * -1) : comparison
        );
      };
    }

    showApellido1raLetraNombre(apellidoNombre:string):string {
        // Espera recibir un string con el formato "Apellido, Nombre" (espacio luego de la ,)
        if(apellidoNombre==null || apellidoNombre===undefined) {
          return apellidoNombre;
        }
        let posComa = apellidoNombre.indexOf(',');
        let rta=apellidoNombre;
        if(posComa!=-1) {
          rta=apellidoNombre.substr(0, posComa+3);
        }
        //console.log("showApellido1raLetraNombre",apellidoNombre,rta);
        return rta;
    }

    capitalize(str:any):string {
        //console.log("capitalize",str);
        if(str===undefined || str==null) return '';
        str=str.toLowerCase();
        return str.replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase());
    }

    completaLongitud(str:any, cant:any, caracter:string):string {
        if(str==null || str===undefined) return '';
        let str2;
        if(typeof str==='number') {
          str2=str.toString();
        } else {
          str2=str;
        }
        let repetido='';
        for(let i=0; i<100; i++) {
          repetido+=caracter;
        }
        let rta=str2+repetido;
        return rta.substr(0, cant);
        console.log("completaLongitud", str, cant, caracter, rta);
    }

    redondeo(valor:any, decimales:number):any {
      let rta:any;
      if(decimales==0) rta = Math.trunc( Math.round(valor) );
      if(decimales==1) rta = Math.round(valor * 10)      / 10;
      if(decimales==2) rta = Math.round(valor * 100)     / 100;
      if(decimales==3) rta = Math.round(valor * 1000)    / 1000;
      if(decimales==4) rta = Math.round(valor * 10000)   / 10000;
      if(decimales==5) rta = Math.round(valor * 100000)  / 100000;
      if(decimales==6) rta = Math.round(valor * 1000000) / 1000000;

      return rta;
    }

    str0(str:any, cant:any):string {
        if(str==null || str===undefined) return '';
        let str2;
        if(typeof str==='number') {
          str2=str.toString();
        } else {
          str2=str;
        }
        let ceros='000000000000000000000';
        let rta=ceros+str2;
        return rta.substr(rta.length-cant,rta.length);
    }

    alertMensaje(mensaje: string, value:string):any {

        let mensajeTraducido = this.translate.instant(mensaje);
        if(value) {
          mensajeTraducido = mensajeTraducido.replace('{{value}}',value);
        }
        alert(mensajeTraducido);
    }

    titleMensaje(mensaje: string, value:string):string {

        let mensajeTraducido = this.translate.instant(mensaje);
        if(value) {
          mensajeTraducido = mensajeTraducido.replace('{{value}}',value);
        }
        return mensajeTraducido;
    }

    isTimestamp(timestamp: any): boolean {
      //console.log("isTimestamp",timestamp);

      let rta;

      if(timestamp==null) {
          rta = false;

      } else if(timestamp===undefined) {
          console.error("isTimestamp recibió undefined",timestamp);
          rta = false;

      } else if(timestamp['seconds']!==undefined && timestamp['nanoseconds']!==undefined) {
          //console.error("isTimestamp formato seconds / nanoseconds");
          rta = true;

      } else if(timestamp && typeof timestamp.toMillis == "function") {
            rta = true;

      } else {

          let isEmptyJson = (Object.entries(timestamp).length === 0 && timestamp.constructor === Object);
          if(isEmptyJson) {

              //console.error("isTimestamp recibió {}",timestamp);
              rta = false;

          } else {

              let typeMillis = typeof timestamp.toMillis;
              if (typeMillis == "function") {
                 rta = true;
              } else {
                 rta = false;
              }
          }
      }

      //console.log("isTimestamp rta",timestamp, rta);
      return rta;
    }

    timestampToDate(timestamp: any): any {
      //console.log("timestampToDate",timestamp);

      let rta;

      if(timestamp==null || timestamp===undefined) {
          rta = null;
          // no convierto dejo tal cual (null)
          
      } else if(timestamp['seconds']!==undefined && timestamp['nanoseconds']!==undefined) {
          rta = new Date( (timestamp['seconds'] + timestamp['nanoseconds'] * 10 ** -9) * 1000);

      } else {
         let isEmptyJson = (Object.entries(timestamp).length === 0 && timestamp.constructor === Object);
         if(isEmptyJson) {

           console.error("timestampToDate recibió {}",timestamp);
           rta = null;

         } else {

           let typeMillis = typeof timestamp.toMillis;
           if (typeMillis == "function") {
              let milisegundos = timestamp.toMillis();
              rta = new Date(milisegundos);
           } else {
              // console.log("timestampToDate toMills!=function", typeMillis);
              rta = null;
           }
         }
      }

      let formatoFechaHora=Object.prototype.toString.call(timestamp);
      // console.log("timestampToDate type, rta",formatoFechaHora, rta);

      return rta;

    }

    diferenciaFechas(date1: any, date2: any): string {
    /**
     * diferencia de milisegundos entre dos fechas en formato timestamp o date
     * retorna: valor negativo o positivo
     * null si alguna de las dos fechas es nula
     */

      // console.log("diferenciaFechas date1", date1);
      // console.log("diferenciaFechas date2", date2);

      if(date1==null || date1===undefined || date1=='' ||
         date2==null || date2===undefined || date2=='') {
        return null;
      }

      let rta = null;

      let d1 = date1;
      let d2 = date2;

      let typeMillis;
      let formatoFechaHora;
      let isTimestamp;
      let milisegundos;

      typeMillis = typeof date1.toMillis;
      isTimestamp = (typeMillis == "function");
      // if(isTimestamp) { // // Timestamp { seconds: 1580936640, nanoseconds: 0 }
      //     let milisegundos = date1.toMillis();
      //     d1 = new Date(milisegundos);
      // }

      formatoFechaHora=Object.prototype.toString.call(date1);

      if(formatoFechaHora=="[object String]") { // Wed Feb 05 2020 17:32:13 GMT-0300 (hora estándar de Argentina)
          d1=d1.toDate(date1);

      } else if(isTimestamp) { // // Timestamp { seconds: 1580936640, nanoseconds: 0 }
          // console.error("compareFechas recibió timestamp date1 !!!",date1, typeMillis);
          let milisegundos = date1.toMillis();
          d1 = new Date(milisegundos);

      } else if(formatoFechaHora=="[object Date]") {     // Ya vino en formato fecha
          d1=date1;
      }


      typeMillis = typeof date2.toMillis;
      isTimestamp = (typeMillis == "function");
      // if(isTimestamp) { // // Timestamp { seconds: 1580936640, nanoseconds: 0 }
      //     let milisegundos = date2.toMillis();
      //     d2 = new Date(milisegundos);
      // }

      formatoFechaHora=Object.prototype.toString.call(date2);

      if(formatoFechaHora=="[object String]") { // Wed Feb 05 2020 17:32:13 GMT-0300 (hora estándar de Argentina)
          d2=d2.toDate(date2);

      } else if(isTimestamp) { // // Timestamp { seconds: 1580936640, nanoseconds: 0 }
          console.error("compareFechas recibió timestamp date1 !!!",date2, typeMillis);
          let milisegundos = date2.toMillis();
          d2 = new Date(milisegundos);

      } else if(formatoFechaHora=="[object Date]") {     // Ya vino en formato fecha
          d2=date2;
      }

      // Calculo diferencia entre dos fechas
      rta = d1.getTime() - d2.getTime();
      return rta;

    }

    compareFechas(date1: any, date2: any): string {
    /**
     * Compares dos fechas en formato timestamp o date
     * retorna:
     * 'iguales' si son iguales.
     * '1mayor2' si primera mayor que segunda.
     * '1menor2' si primera menor que segunda.
     * 'error'   si alguna de las dos fechas es nula
     */

      // console.log("compareFechas date1", date1);
      // console.log("compareFechas date2", date2);

      if(date1==null || date1===undefined || date1=='' ||
         date2==null || date2===undefined || date2=='') {
        return 'error';
      }

      let rta = 'error';

      let d1 = date1;
      let d2 = date2;

      let typeMillis;
      let formatoFechaHora;
      let isTimestamp;
      let milisegundos;

      typeMillis = typeof date1.toMillis;
      isTimestamp = (typeMillis == "function");
      // if(isTimestamp) { // // Timestamp { seconds: 1580936640, nanoseconds: 0 }
      //     let milisegundos = date1.toMillis();
      //     d1 = new Date(milisegundos);
      // }

      formatoFechaHora=Object.prototype.toString.call(date1);

      if(formatoFechaHora=="[object String]") { // Wed Feb 05 2020 17:32:13 GMT-0300 (hora estándar de Argentina)
          d1=d1.toDate(date1);

      } else if(isTimestamp) { // // Timestamp { seconds: 1580936640, nanoseconds: 0 }
          // console.error("compareFechas recibió timestamp date1 !!!",date1, typeMillis);
          let milisegundos = date1.toMillis();
          d1 = new Date(milisegundos);

      } else if(formatoFechaHora=="[object Date]") {     // Ya vino en formato fecha
          d1=date1;
      }


      typeMillis = typeof date2.toMillis;
      isTimestamp = (typeMillis == "function");
      // if(isTimestamp) { // // Timestamp { seconds: 1580936640, nanoseconds: 0 }
      //     let milisegundos = date2.toMillis();
      //     d2 = new Date(milisegundos);
      // }

      formatoFechaHora=Object.prototype.toString.call(date2);

      if(formatoFechaHora=="[object String]") { // Wed Feb 05 2020 17:32:13 GMT-0300 (hora estándar de Argentina)
          d2=d2.toDate(date2);

      } else if(isTimestamp) { // // Timestamp { seconds: 1580936640, nanoseconds: 0 }
          console.error("compareFechas recibió timestamp date1 !!!",date2, typeMillis);
          let milisegundos = date2.toMillis();
          d2 = new Date(milisegundos);

      } else if(formatoFechaHora=="[object Date]") {     // Ya vino en formato fecha
          d2=date2;
      }

      // Check if the dates are equal
      let same = d1.getTime() === d2.getTime();
      //console.log("compareTimeStamp", date1, date2, 'iguales');
      if (same) rta = 'iguales';

      // Check if the first is greater than second
      //console.log("compareTimeStamp", date1, date2, 'imayor2');
      if (d1 > d2) rta = '1mayor2';

      // Check if the first is less than second
      //console.log("compareTimeStamp", date1, date2, 'imenor2');
      if (d1 < d2) rta = '1menor2';

      // console.log("compareFechas rta", rta);
      return rta;

    }

    toFloat(value: any) {
      // console.log("toFloat",value);
      if (value!=undefined && value!=null && value!='') {
        if(this.esNumero(value)) value=value.toString();
        return parseFloat(value);
      } else {
        return null;
      }
    }

    isArray(obj) { return this.isArray(obj); }

    toInteger(value: any): number {
      return parseInt(`${value}`, 10);
    }

    esNumero(value: any): Boolean {
      // console.log(typeof value);
      return  typeof value === 'number';
    }

    isNumber(value: any): value is number {
      return !isNaN(this.toInteger(value));
    }

    padNumber(value: number) {
      if (this.isNumber(value)) {
        return `0${value}`.slice(-2);
      } else {
        return '';
      }
    }

    emailValidator(control: FormControl):  { [key: string]: boolean } | null {
      var emailRegexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (control.value && !emailRegexp.test(control.value)) {
          return {invalidEmail: true};
      }
    }

    isFloatDosDecimales(control: FormControl):  { [key: string]: boolean } | null {
      var formatoNumero = /[0-9.,]$/;
      if (control.value && !formatoNumero.test(control.value)) {
          return {invalidFloat: true};
      }
    }


    // JJ NOTA: COMENTO ESTA FUNCION PORQUE LA USA BOOSTRAP Y NO SE PUEDE INSTALAR EN IONIC.
    //  VERIFIQUE QUE NINGUN PROYECTO LA USA
    
    // Función que valida si un campo fecha de un reactiveform es valida,
    // retorna en
    // isThisDateValid retorna un objeto que tiene palabra clave para buscar el error y el reusltado
    // con esta palabra clave mostramos el mensaje en el html.
    // fechaValidator( control: FormControl):  { [key: string]: boolean } | null {/
    //       console.log(control.value);
    //       console.log(typeof control.value );

    //        let dateToValidate=control.value ;
    //       var Fecha:NgbDateStruct
    //       if (!dateToValidate) {
    //          console.log('no valida');
    //             // return { 'mensaje':'dateInvalid', 'invalidDate':false };
    //             return { 'invalidDate':true };
    //         } else


    //       if (typeof dateToValidate === 'string' || dateToValidate instanceof String){
    //           const dateParts = dateToValidate.toString().trim().split('/');
    //           if (dateParts.length === 1 && this.isNumber(dateParts[0])) {
    //             Fecha= {day: this.toInteger(dateParts[0]), month: null, year: null};
    //           } else if (dateParts.length === 2 && this.isNumber(dateParts[0]) && this.isNumber(dateParts[1])) {
    //             Fecha= {day: this.toInteger(dateParts[0]), month: this.toInteger(dateParts[1]), year: null};
    //           } else if (dateParts.length === 3 && this.isNumber(dateParts[0]) && this.isNumber(dateParts[1]) && this.isNumber(dateParts[2])) {
    //             Fecha= {day: this.toInteger(dateParts[0]), month: this.toInteger(dateParts[1]), year: this.toInteger(dateParts[2])};
    //           }
    //       } else {
    //         Fecha=dateToValidate;
    //       }

    //       var month = Fecha.month;
    //       var day = Fecha.day;
    //       var year = Fecha.year;

    //       var currentTaxYear = Number(new Date().getFullYear() - 1);

    //       if (isNaN(month) || isNaN(day) || isNaN(year)) {
    //           console.log('no mumeros');
    //           // return { 'mensaje':'dateInvalid', 'invalidDate':false };
    //           return { 'noNumbers':true };
    //       }
    //       month = Number(month);
    //       day = Number(day);
    //       year = Number(year);
    //       if (month < 1 || month > 12) { // check month range
    //          console.log('mes incorrecto');
    //           // return { 'mensaje':'monthInvalid', 'invalidDate':false };
    //           return { 'monthInvalid':true };
    //       }
    //       if (day < 1 || day > 31) {
    //          console.log('dayInvalid incorrecto');
    //           // return { 'mensaje':'dayInvalid', 'invalidDate':false };
    //               return { 'dayInvalid':true };
    //       }
    //       if ((month === 4 || month === 6 || month === 9 || month === 11) && day === 31) {
    //          console.log('mes30dias incorrecto');
    //           // return { 'mensaje':'mes30dias', 'invalidDate':false };
    //               return { 'mes30dias':true };
    //       }
    //       if (month == 2) { // check for february 29th
    //           var isleap = (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0));
    //           if (day > 29 || (day === 29 && !isleap)) {
    //               console.log('febrero29 incorrecto');
    //               // return { 'mensaje':'febrero29', 'invalidDate':false };
    //                   return { 'febrero29':true };
    //           }
    //       } else{
    //         // if (year !== currentTaxYear) {
    //         //     return { 'dateYearGreaterThanTaxYear': true };
    //         // }

    //         // return { 'mensaje':'fechaCorrecta', 'invalidDate':true };
    //         return null;
    //      }
    //   }

      sortDate = (direction: any, a: any, b: any): number => {
        console.log(direction);
        console.log(a);
        console.log(b);
        let first= new Date(a.month+'/'+a.day+'/'+a.year).getTime();
        let second= new Date(b.month+'/'+b.day+'/'+b.year).getTime();


            if (first < second) {
                return -1 * direction;
            }
            if (first > second) {
                return direction;
            }
            return 0;
      }


      getIMGMedidas(image):Promise<any>{
          // console.log("getIMGMedidas", image)
          return new Promise((resolve, reject) => {
              let img = new Image()
              img.onload = () => resolve(img)
              img.onerror = () => reject('error de carga')
              img.src = image
          })
      }

      isFile(file:any) {
        // log(...values('valoresDebug','isFile urlFile:',file));

        // log(...values('valoresDebug','rta:',file && file['name']!==undefined && file['type']!==undefined && file['size']!==undefined));
        // if(file && file['name']!==undefined && file['type']!==undefined && file['size']!==undefined) {

        // log(...values('valoresDebug','rta:',file && file['type']!==undefined && file['size']!==undefined));
        if(file['type']!==undefined && file['size']!==undefined) {
          return true;
        } else {
          return false;
        }
      }

      convertBase64ToFile(dataurl, filename) {
            var arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);

            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }

            return new File([u8arr], filename, {type:mime});
      }

      convertBlobToFile = ( imageBlob: Blob, fileName:string): File => {
            log(...values("valoresDebug","convertBlobToFile - fileName:",fileName));
            log(...values("valoresDebug","convertBlobToFile - imageBlob:",imageBlob));

            let typeBlob = this.getTypeFromImage(imageBlob);

            var file = new File( [imageBlob], fileName, {
                lastModified: Date.now(),
                type: typeBlob
            });

            return file;
      }

      convertBlobToBase64 = (theBlob: Blob): any => {

          return URL.createObjectURL (theBlob);
      }

      convertBlobToFile2 = (theBlob: Blob, fileName:string): File => {
            var b: any = theBlob;
            //A Blob() is almost a File() - it's just missing the two properties below which we will add
            b.lastModifiedDate = new Date();
            b.name = fileName;

            //Cast to a File() type
            return <File>theBlob;
      }

      convertFileReaderToFile( fileReaderResult ){
          console.log("convertFileReaderToFile",fileReaderResult);
          let metadata = {
            type: 'image/jpeg'
          };
          let file = new File([fileReaderResult], "test.jpg", metadata);
          return file;
      }

      convertFileToBase64(file:any):Promise<any>{
        console.log("convertFileToBase64", file)
        return new Promise((resolve, reject) => {
            var readerBASE64 = new FileReader();
            readerBASE64.readAsBinaryString(file);
            // readerBASE64.readAsDataURL(file);
            readerBASE64.onloadend = function() {
              resolve(readerBASE64.result);
            }
            readerBASE64.onerror = () => reject('error de carga')
        })
      }


      piEXIFremove = function (jpeg) {
              var b64 = false;
              if (jpeg.slice(0, 2) == "\xff\xd8") {
              } else if (jpeg.slice(0, 23) == "data:image/jpeg;base64," || jpeg.slice(0, 22) == "data:image/jpg;base64,") {
                  jpeg = atob(jpeg.split(",")[1]);
                  b64 = true;
              } else {
                  throw ("Given data is not jpeg.");
              }

              var segments = this.splitIntoSegments(jpeg);
              var newSegments = segments.filter(function(seg){
                return  !(seg.slice(0, 2) == "\xff\xe1" &&
                         seg.slice(4, 10) == "Exif\x00\x00");
              });

              var new_data = newSegments.join("");
              if (b64) {
                  new_data = "data:image/jpeg;base64," + btoa(new_data);
              }

              let imageFile = this.convertBase64ToFile(new_data, 'piExif.jpg');
              return imageFile;

      }

      splitIntoSegments(data) {
              if (data.slice(0, 2) != "\xff\xd8") {
                  throw ("Given data isn't JPEG.");
              }

              var head = 2;
              var segments = ["\xff\xd8"];
              while (true) {
                  if (data.slice(head, head + 2) == "\xff\xda") {
                      segments.push(data.slice(head));
                      break;
                  } else {
                      var length = this.unpack(">H", data.slice(head + 2, head + 4))[0];
                      var endPoint = head + length + 2;
                      segments.push(data.slice(head, endPoint));
                      head = endPoint;
                  }

                  if (head >= data.length) {
                      throw ("Wrong JPEG data.");
                  }
              }
              return segments;
      }

      unpack(mark, str) {
              if (typeof (str) != "string") {
                  throw ("'unpack' error. Got invalid type argument.");
              }
              var l = 0;
              for (var markPointer = 1; markPointer < mark.length; markPointer++) {
                  if (mark[markPointer].toLowerCase() == "b") {
                      l += 1;
                  } else if (mark[markPointer].toLowerCase() == "h") {
                      l += 2;
                  } else if (mark[markPointer].toLowerCase() == "l") {
                      l += 4;
                  } else {
                      throw ("'unpack' error. Got invalid mark.");
                  }
              }

              if (l != str.length) {
                  throw ("'unpack' error. Mismatch between symbol and string length. " + l + ":" + str.length);
              }

              var littleEndian;
              if (mark[0] == "<") {
                  littleEndian = true;
              } else if (mark[0] == ">") {
                  littleEndian = false;
              } else {
                  throw ("'unpack' error.");
              }
              var unpacked = [];
              var strPointer = 0;
              var p = 1;
              var val = null;
              var c = null;
              var length = null;
              var sliced = "";

              while (c = mark[p]) {
                  if (c.toLowerCase() == "b") {
                      length = 1;
                      sliced = str.slice(strPointer, strPointer + length);
                      val = sliced.charCodeAt(0);
                      if ((c == "b") && (val >= 0x80)) {
                          val -= 0x100;
                      }
                  } else if (c == "H") {
                      length = 2;
                      sliced = str.slice(strPointer, strPointer + length);
                      if (littleEndian) {
                          sliced = sliced.split("").reverse().join("");
                      }
                      val = sliced.charCodeAt(0) * 0x100 +
                          sliced.charCodeAt(1);
                  } else if (c.toLowerCase() == "l") {
                      length = 4;
                      sliced = str.slice(strPointer, strPointer + length);
                      if (littleEndian) {
                          sliced = sliced.split("").reverse().join("");
                      }
                      val = sliced.charCodeAt(0) * 0x1000000 +
                          sliced.charCodeAt(1) * 0x10000 +
                          sliced.charCodeAt(2) * 0x100 +
                          sliced.charCodeAt(3);
                      if ((c == "l") && (val >= 0x80000000)) {
                          val -= 0x100000000;
                      }
                  } else {
                      throw ("'unpack' error. " + c);
                  }

                  unpacked.push(val);
                  strPointer += length;
                  p += 1;
              }

              return unpacked;
      }


      quitarEXIF(imagenConEXIF):File {
            var dv = new DataView(imagenConEXIF);
            var offset = 0, recess = 0;
            var pieces = [];
            var i = 0;
            if (dv.getUint16(offset) == 0xffd8){
                offset += 2;
                var app1 = dv.getUint16(offset);
                offset += 2;
                while (offset < dv.byteLength){
                    if (app1 == 0xffe1){

                        pieces[i] = {recess:recess,offset:offset-2};
                        recess = offset + dv.getUint16(offset);
                        i++;
                    }
                    else if (app1 == 0xffda){
                        break;
                    }
                    offset += dv.getUint16(offset);
                    var app1 = dv.getUint16(offset);
                    offset += 2;
                }
                if (pieces.length > 0){
                    var newPieces = [];
                    pieces.forEach(function(v){
                        newPieces.push(imagenConEXIF.slice(v.recess, v.offset));
                    }, this);
                    newPieces.push(imagenConEXIF.slice(recess));
                    var imageBlob = new Blob(newPieces, {type: 'image/jpeg'});
                    var imageSinEXIF =  this.convertBlobToFile(imageBlob, "sinEXIF.jpg");
                    return imageSinEXIF;
                    // return URL.createObjectURL(imageSinEXIF);
                }
            }

            let formatoBlob = this.convertFileReaderToFile(imagenConEXIF);
            return formatoBlob;
      }




  /* ------------------------------------------------------------------------------
     FUNCIONES PARA EL MANEJO DE DOCUMENTOS 
     ------------------------------------------------------------------------------------- */

      setPermisosEdicion(listaPermisos:PermisosMenu[], url:string, permisos:any): any {
         log(...values('funcionGo','setPermisosEdicion'));
         // console.log('this.router.url',this.router.url);
         // console.log('listaPermisos',listaPermisos);
         // console.log("this.permisos A",this.permisos);
         let partesURL = url.split('?');
         // Obtener la URL sin los parámetros
         let urlSinParametros = partesURL[0];

        //  for (var i = listaPermisos.length - 1; i >= 0; i--) {
         for (var i in listaPermisos) {
           if(listaPermisos[i].routerLink==urlSinParametros){
              permisos.altasAutorizadas                   = listaPermisos[i].alta;
              permisos.bajasAutorizadas                   = listaPermisos[i].baja;
              permisos.modificacionesAutorizadas          = listaPermisos[i].modificacion;
              permisos.modificacionesBasicasAutorizadas   = listaPermisos[i].modificacionBasica;
              //permisos.modificacionesBasicasAutorizadas=(listaPermisos[i].modificacion!==undefined) ? listaPermisos[i].modificacion : false;
              log(...values("valores","permisos:",permisos));

              return permisos;
              break;
           }
         }

      }
  /* ------------------------------------------------------------------------------
     FUNCIONES PARA EL MANEJO DE DOCUMENTOS (se diferencia de porque pide interfase de Permisos)
     ------------------------------------------------------------------------------------- */

      setPermisosEdicion2(listaPermisos:PermisosMenu[], url:string, permisos:PermisosInterface): PermisosInterface {
         log(...values('funcionGo','setPermisosEdicion2'));
         // console.log('this.router.url',this.router.url);
         // console.log('listaPermisos',listaPermisos);
         // console.log("this.permisos A",this.permisos);

        //  for (var i = listaPermisos.length - 1; i >= 0; i--) {
         for (var i in listaPermisos) {
           if(listaPermisos[i].routerLink==url){
              permisos.altasAutorizadas                   = listaPermisos[i].alta;
              permisos.bajasAutorizadas                   = listaPermisos[i].baja;
              permisos.modificacionesAutorizadas          = listaPermisos[i].modificacion;
              permisos.modificacionesBasicasAutorizadas   = listaPermisos[i].modificacionBasica;
              //permisos.modificacionesBasicasAutorizadas=(listaPermisos[i].modificacion!==undefined) ? listaPermisos[i].modificacion : false;
              log(...values("valores","permisos:",permisos));

             
              break;
           }
         }

         return permisos;

      }

    verificarModeloConForm(form: any,t:any, listadoCamposNoDeseados:string[] ):any{
        
        let showConsole=true;
  
        if(showConsole) log(...values('funcionGo','fn.verificarModeloConForm'));
        if(showConsole) log(...values('valoresDebug','form.value:',form.value));
        if(showConsole) log(...values('valoresDebug','t:',t));

       
        let propiedadesModelo             : string[]= [];
        if(t){
          propiedadesModelo= Object.getOwnPropertyNames(t);
        }
       


      
        let propiedadesFaltantesEnForm    : string='';
        let propiedadesEnFormNoEsModelo   : string='';

        // Verifico que los NoDesados no esté en el modelo.
        listadoCamposNoDeseados.map(nodeseado=>{
          console.log('noDesado',nodeseado);
          let noDesadosEnModelo=propiedadesModelo.find(prop=>prop==nodeseado);
          console.log('noDesadosEnModelo',noDesadosEnModelo);
          if (noDesadosEnModelo){
            propiedadesEnFormNoEsModelo+=noDesadosEnModelo+ ' (no Desado es parte del modelo)\n'
          }
        });
        

        // le sumo al modelo los campos no seados para asegurarme que esté en 
        // el fomulario.
        propiedadesModelo=propiedadesModelo.concat(listadoCamposNoDeseados);
        console.log('noDesadosEnModelo propiedadesModelo',propiedadesModelo);

        for (let index = 0; index < propiedadesModelo.length; index++) {
          const propiedad = propiedadesModelo[index];
        
          if(!form || form.value[propiedad]===undefined){
            propiedadesFaltantesEnForm += propiedad +' ';            
            
          }else if(typeof t[propiedad]=='object' &&  form.value[propiedad] !== null && t[propiedad]!==null ){
            
            const auxForm={};
            auxForm['value'] = form.value[propiedad];
            let respuesta=this.verificarModeloConForm(auxForm,t[propiedad],[]);
            if(respuesta.propiedadesFaltantesEnForm){
              propiedadesFaltantesEnForm+= propiedad+' ( '+respuesta.propiedadesFaltantesEnForm+ ')';
            }
            

          }else{
            continue;
          }
          
        }

        if(showConsole) console.log('propiedadesFaltantesEnForm',propiedadesFaltantesEnForm);


        for (var clave in form.value){
          let p=propiedadesModelo.filter(propiedad=>propiedad==clave);

          if(Object.prototype.toString.call(form.value[clave])=="[object Object]"){
            const auxForm={};
            auxForm['value'] = form.value[clave];
            if(t[clave]!==undefined ||  t[clave]!==null){
              let respuesta=this.verificarModeloConForm(auxForm,t[clave],[]);
              if(respuesta.propiedadesEnFormNoEsModelo){
                propiedadesEnFormNoEsModelo+= clave+' ( '+respuesta.propiedadesEnFormNoEsModelo+ ')';
              }
            } else {
              propiedadesEnFormNoEsModelo+= clave+' (no existe)';
            }

          }else if(p.length==1){
            continue;
          }else{
            propiedadesEnFormNoEsModelo +=clave +' ';
          }
        }
 
        if(showConsole) console.log('propiedadesEnFormNoEsModelo',propiedadesEnFormNoEsModelo);

        return {propiedadesFaltantesEnForm:propiedadesFaltantesEnForm, propiedadesEnFormNoEsModelo:propiedadesEnFormNoEsModelo}

        
    }
  


    igualarDocumentoConForm(form: any, json:any, destino?:string):any{
      /* destino =
       * 'destinoForm'
       * 'destinoListado'
       */
      let showConsole=false;

      if(showConsole) log(...values('funcionGo','fn.igualarDocumentoConForm'));
      if(showConsole) log(...values('valoresDebug','form.value:',form.value));
      if(showConsole) log(...values('valoresDebug','documento:',json));

      destino = (destino===undefined) ? 'destinoForm' : destino;
      if(showConsole) log(...values('valoresDebug','destino:',destino));

      let jsonResultado:any={};

      //if(showConsole) console.log("Patch form.value",form.value);

      for (var clave in form.value){
        jsonResultado[clave]=null;

        if(showConsole) log(...values('valoresDebug',
            'clave:', clave,
            `form.value[${clave}]:`,form.value[clave],
            `typeof form.value[${clave}]:`,typeof form.value[clave]));

        let subestructura=false;
        let value = form.value[clave];
        let formato=Object.prototype.toString.call(value);
        //let isArrayForm=this.isArray(value); 
        let isArrayForm=(value=='Array') ? true : false;
        
        if(showConsole) log(...values('valoresDebug','formato:',formato));
        if(showConsole) log(...values('valoresDebug','isArrayForm:',isArrayForm));

        if(value!=null) {
          if(value.constructor === Object) {
            if(Object.entries(value).length === 0) {
                subestructura=true;
            }

          }
        }

        if (typeof form.value[clave] == "object" && 
            form.value[clave] !== null && 
            formato != "[object Date]" && !isArrayForm) {

           if(showConsole) log(...values('valoresDebug','Detectó Subestructura en Form:',`form.value[${clave}]`));

           if(destino=='destinoListado' && json[clave]===undefined) {
               jsonResultado[clave] = null;
               if(showConsole) log(...values('valoresDebug',`No existe en Documento, pisó ${clave} con:`,null));

           } else if(destino=='destinoListado' && json[clave]==null) {
               jsonResultado[clave] = null;
               if(showConsole) log(...values('valoresDebug',`Pisó ${clave} con:`,null));

           } else if(destino=='destinoForm' && (json[clave]===undefined || json[clave]==null)) {
               jsonResultado[clave] = form.value[clave];
               if(showConsole) log(...values('valoresDebug',`No existe en Documento, pisó ${clave} con:`,`form.value[${clave}]`,form.value[clave]));

           } else {
             
               jsonResultado[clave]={};
               const auxForm={};
               auxForm['value'] = form.value[clave];
               jsonResultado[clave] = this.igualarDocumentoConForm(auxForm, json[clave] );
           }

        } else if (json && isArrayForm){
          
            let isArrayJson=Array.isArray(json[clave]);                 
            if(showConsole) log(...values('valoresDebug',`isArrayJson`,isArrayJson));
            if(isArrayJson) {
                jsonResultado[clave] = json[clave];
                if(showConsole) log(...values('valoresDebug',`Pisó ${clave} con el valor:`,json[clave]));                                
            } else { 
                jsonResultado[clave] = [];
                if(showConsole) log(...values('valoresDebug',`Es array y vino un null (not array) piso ${clave} con []`));
            }                

        } else if (json && !isArrayForm){
            
            if(showConsole) log(...values('valoresDebug',`if( json.hasOwnProperty(${clave}) ):`,json.hasOwnProperty(clave)));
            if (json.hasOwnProperty(clave)) {
                jsonResultado[clave] = json[clave];
                if(showConsole) log(...values('valoresDebug',`Pisó ${clave} con el valor:`,json[clave]));                    
            } else {
                //jsonResultado[clave]=null;
                jsonResultado[clave] = form.value[clave];
                if(showConsole) log(...values('valoresDebug',`>>>>>> No existe clave ${clave} y el  json y se completa  con `,`form.value[${clave}]`,form.value[clave]));
            }
        } else {
              //jsonResultado[clave]=null;
              jsonResultado[clave] = form.value[clave];
              if(showConsole) log(...values('valoresDebug',`Pisó ${clave} con:`,`form.value[${clave}]`,form.value[clave]));
        }
      }

      if(showConsole) log(...values('valoresDebug',`jsonResultado`,jsonResultado));
      return jsonResultado;
    }

    inicializaArrays = function(obj) {
        // Examine this.form, y a todos los campos que tienen asignado 'Array', les asigna []        
        for (var prop in obj) {            
            // console.log("inicializaArrays - obj[prop]",prop,obj[prop] );
            let valueIsArray = (Array.isArray(obj[prop]));
            if (typeof obj[prop] == "object" && !valueIsArray && obj[prop] !== null && !this.isTimestamp(obj[prop]) ) {
                obj[prop]=this.corrigeTimestampDocumento(obj[prop]);
                // console.log("inicializaArrays - 1");
            } else {
                // console.log("inicializaArrays - 2");
                if(obj[prop]=='Array') {
                    obj[prop] = [];
                }
            }
        }
        return obj;
    }
  
  
    seteaCamposKN(obj:any):any {
      //console.log("seteaCamposKN obj", obj);
      for (var prop in obj) {            
          // console.log("seteaCamposKN", prop, obj[prop]);

          if(prop.includes('KNAI')) {
              obj[prop] = this.setearKNAI( obj[prop] );
              
          } else if(prop.includes('KANE')) {
              obj[prop] = this.setearKANE( obj[prop] );
              
          } else if(prop.includes('KN')) {
              obj[prop] = this.setearKN( obj[prop] );

          } else if(prop.includes('KMONEY')) {
            obj[prop] = this.setearKMONEY( obj[prop] );

          }
           else if(prop.includes('OBJ')) {    
              let aux = obj[prop];
              if(aux && aux.settings!==undefined) {
                delete aux.settings;
              };
              if(aux && aux.organizacionKNAI!==undefined) {
                delete aux.organizacionKNAI;
              }
             
              obj[prop] = aux;
              
          }  else {            
              let valueIsArray = (Array.isArray(obj[prop]));
              if (typeof obj[prop] == "object" && !valueIsArray && obj[prop] !== null && !this.isTimestamp(obj[prop]) ) {
                  obj[prop]=this.seteaCamposKN(obj[prop]);
              }
          }    
      }
      return obj;
    }    
    
    corrigeTimestampDocumento = function(obj) {
        // Examine un objeto JSON, y a todos los campos de formato timestamp los convienrte Javascript Date
        //console.log("corrigeTimestampDocumento0",obj );
        for (var prop in obj) {            
            //if(prop.includes('fecha')) console.log("prop",prop, obj[prop]);
            // if(prop=='fechaHoraCreacion') console.log("corrigeTimestampDocumento1",prop,obj[prop], "isTimestamp",this.isTimestamp(obj[prop]));
            //if(prop.includes('fecha')) console.log("xxx corrigeTimestampDocumento prop1", prop, this.isTimestamp(obj[prop]), obj[prop]);
            let valueIsArray = (Array.isArray(obj[prop]));
            if (typeof obj[prop] == "object" && !valueIsArray && obj[prop] !== null && !this.isTimestamp(obj[prop]) ) {
                obj[prop]=this.corrigeTimestampDocumento(obj[prop]);
                 // if(prop=='fechaHoraCreacion') console.log("corrigeTimestampDocumento2",obj[prop]);

            } else if(obj[prop] && obj[prop]['seconds']!==undefined && obj[prop]['nanoseconds']!==undefined) {
                //console.log("corrigeTimestampDocumento corrigio formato nanoseconds ", prop, obj[prop]);
                obj[prop] = new Date( (obj[prop]['seconds'] + obj[prop]['nanoseconds'] * 10 ** -9) * 1000);
  
            } else {
                if(this.isTimestamp(obj[prop])) {
                    //if(prop=='fechaHoraCreacion') console.log("corrigeTimestampDocumento timestampToDate1",prop, obj[prop]);
                    obj[prop] = this.timestampToDate( obj[prop] );
                    //if(prop=='fechaHoraCreacion') console.log("corrigeTimestampDocumento timestampToDate2",prop, obj[prop]);
                }
            }
            //if(prop.includes('fecha')) console.log("xxx corrigeTimestampDocumento prop2", prop, this.isTimestamp(obj[prop]), obj[prop]);
            //if(prop.includes('fecha')) console.log("xxx corrigeTimestampDocumento typeof", prop, typeof obj[prop],this.isTimestamp(obj[prop]));
            //if(prop.includes('fecha') && this.isTimestamp(obj[prop])) console.error("xxx corrigeTimestampDocumento NOOOOOOOOO!!!",prop, obj[prop]);
   
            //if(prop.includes('fecha')) console.log("prop",prop, obj[prop]);
            //if(prop.includes('fecha') && !isDate(obj[prop])) console.error("corrigeTimestampDocumento no es fecha", prop, obj[prop])

            /*
            console.error("xxx corrigeTimestampDocumento NO ES FECHA",prop, obj[prop], this.isTimestamp(obj[prop]));
            if(prop.includes('fecha') && !isDate(obj[prop])) {
                 if(obj[prop]['seconds']!==undefined && obj[prop]['nanoseconds']!==undefined) {
                    let fechajaja = new Date( (obj[prop]['seconds'] + obj[prop]['nanoseconds'] * 10 ** -9) * 1000);
                    console.log("fechajaja",fechajaja);
                 }
            } 
            */ 
            
        }
        return obj;
    }

    /* ----------------------------------------------------
     * Funciones para el Manejo de Imagenes
     * ---------------------------------------------------- */
    urlImage(fieldImage:any, cual:string) {
        let rta;

        if(fieldImage==null || fieldImage===undefined) {
            rta=null;
        } else  {
            if(cual=="Grande") {
              rta= (fieldImage && fieldImage.link) ? fieldImage.link : null;
            } else if(cual=='Thumb') {
              rta= (fieldImage && fieldImage.linkThumb) ? fieldImage.linkThumb : null;
            }

        }
        // console.log("urlImage", fieldImage, rta);
        return rta;
    }

    sizeImage(fieldImage:any, cual:string):number {
        let rta;

        if(fieldImage==null || fieldImage===undefined) {
            rta=0;

        } else {
            let totGrande = (fieldImage.bytes      && fieldImage.bytes!=null)      ? fieldImage.bytes      : 0;
            let totThumb  = (fieldImage.bytesThumb && fieldImage.bytesThumb!=null) ? fieldImage.bytesThumb : 0;

            if(cual=='Grande') {
                rta = totGrande;

            } else if(cual=='Thumb') {
                rta = totThumb;

            } else if(cual=='Total') {
                rta = totGrande + totThumb;
            }
        }
        // console.log("sizeImage", fieldImage, cual, rta);

        return rta;
    }

     getFileNameFromUrlFile(urlFile:string):string {
        // log(...values('valoresDebug','getFileNameFromUrlFile urlFile:',urlFile));
        if(urlFile==null) return null;
        urlFile = decodeURIComponent(urlFile);
        if(urlFile.substr(0,10)=='data:image') {          
          let posPosterior = urlFile.indexOf(';base64');
          let aux = urlFile.substr(0,posPosterior);
          let extension = aux.replace( 'data:image/','')
          var fileName = 'image.'+extension;  
        } else {
          var fileName = urlFile.substring(urlFile.lastIndexOf('/')+1);  
        }
        // log(...values('valoresDebug','getFileNameFromUrlFile fileName:',fileName));
        return fileName;
    }

    getFolderFromUrlFile(urlFile:string, ignoreLeft:boolean):string {
        // log(...values('valoresDebug','getFolderFromUrlFile urlFile:',urlFile));
        if(urlFile==null) return null;
        let fileName = this.getFileNameFromUrlFile(urlFile);
        let pos = urlFile.indexOf(fileName);
        let folder = urlFile.substr(0, pos);
        if(ignoreLeft) {
            folder = folder.replace('https://firebasestorage.googleapis.com/v0/b/formulasnutralmix.appspot.com/o/','');
        }
        // log(...values('valoresDebug','getFolderFromUrlFile folder:',folder));
        return folder;
    }

    getVarsFromUrlFile(urlFile:string):string {
        // log(...values('valoresDebug','getVarsFromUrlFile urlFile:',urlFile));
        if(urlFile==null) return null;
        let fileName = this.getFileNameFromUrlFile(urlFile);
        let pos = fileName.indexOf('?');
        let vars = fileName.substr(pos, 1000);
        // log(...values('valoresDebug','getFolderFromUrlFile vars:',vars));
        return vars;
    }

    getFileNameSinVars(urlFile:string):string {
        // log(...values('valoresDebug','getFileNameSinVars urlFile:',urlFile));
        if(urlFile==null) return null;
        let fileNamesinVars;
        let fileName = this.getFileNameFromUrlFile(urlFile);
        let pos = fileName.indexOf('?');        
        if(pos!=-1) {
            fileNamesinVars = fileName.substr(0, pos);    
        } else {
            fileNamesinVars = fileName;    
        }
        
        //log(...values('valoresDebug','getFileNameSinVars fileNamesinVars:',fileNamesinVars));
        return fileNamesinVars;
    }

    linkThumb(fieldImage:any) {
        console.log("linkThumb fieldImage",fieldImage);

        let linkThumb;
        let link      = this.urlImage(fieldImage,"Thumb");

        console.log("linkThumb",fieldImage,link);

        if(this.sizeImage(fieldImage, 'Thumb')==0) {  // Aún no se generó el Thumb
          linkThumb = link;

        } else {
          let link = this.urlImage(fieldImage,"Thumb");

          let fileName = this.getFileNameFromUrlFile( link );

          let fileNameExtension    = this.getExtensionFile( fileName );
          console.log("linkThumb fileNameExtension",fileNameExtension);
          let fileNameSinExtension = this.getNameSinExtensionFile ( fileName );
          console.log("linkThumb fileNameSinExtension",fileNameSinExtension);

          let linkThumb = link.replace(fileName, fileNameSinExtension+'_thumb.'+fileNameExtension);

          // const thumbName = `${fileNameSinExtension}_thumb${fileNameExtension}`;
        }
        return linkThumb;
    }

    rutaUpload(argumentos:any):string {
        /*
         * argumentos: {
         *    extension        : string,
         *    nombreColeccion  : string
         *    organizacionKNAI : any
         *    adicionalNombre  : string
         * }
         */

        if(argumentos.adicionalNombre!='') {
            var path = 'upload/'
                          + this.setearOrganizacionAI( argumentos.organizacionKNAI ) + '/'
                          + argumentos.nombreColeccion + '/'
                          + this.UrlFileFecha()+'_'+argumentos.adicionalNombre+'.'+argumentos.extension;
        } else {
            var path = 'upload/'
                          + this.setearOrganizacionAI( argumentos.organizacionKNAI ) + '/'
                          + argumentos.nombreColeccion + '/'
                          + this.UrlFileFecha()+'.'+argumentos.extension;
        }

        // console.log("rutaUpload",path);
        return path;
    }
    
    prettyJson(json, tabulacion, espacios) {
      if(typeof(json) == "undefined" || typeof(json) == null) return ''; //check value before process it.
      
      if(tabulacion==null) tabulacion='';
      if(espacios==null) espacios='&nbsp;';
      
      let tabulacion_guion = tabulacion + '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-';
      if(espacios=='&nbsp;') {
        tabulacion_guion = tabulacion + '-';
      }    
      tabulacion+=espacios;
      let rta='<br/>';
      for(let key in json) {
          let value=json[key];
          let formato=Object.prototype.toString.call(value);
          let claseValue:string='json-value-string';
          //console.log("prettyJson", key, value, formato);
          if(formato=='[object Object]') {
              rta+= tabulacion_guion +"<span class='json-key'>"+key+": </span>{"+
              this.prettyJson(value,tabulacion,'&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;')+
              tabulacion+'&nbsp;&nbsp;&nbsp;}<br/>';
          
          } else {  
              if(formato=='[object Number]')        claseValue='json-value-number';          
              if(formato=='[object Boolean]')       claseValue='json-value-boolean';          
              if(formato=='[object Date]')        { claseValue='json-value-date';   value=this.formatDateToString(value, 'DD/MM/AAAA HH:MM'); }       
              if(formato=='[object String]')      { claseValue='json-value-string'; value='"'+value+'"'; }         
              if(formato=='[object Array]')       { claseValue='json-value-array';  value='['+value+']'; }         
              if(value==null || value===undefined)  claseValue='json-value-null';          
            
              rta+=tabulacion+"<span class='json-key'>"+key+": </span>"+            
                   "<span class='"+claseValue+"'>"+value+"</span>,<br/>";
          }
      }
      //console.log("pretyJson replaceJson",rta);      
      
      return rta;
    }
    
    formatDateToString(fecha:Date, formato:string):string {

      let anio         = this.str0( fecha.getFullYear()    ,4);
      let mes          = this.str0( fecha.getMonth()+1     ,2);
      let dia          = this.str0( fecha.getDate()        ,2);

      let hora         = this.str0( fecha.getHours()       ,2);
      let minutos      = this.str0( fecha.getMinutes()     ,2);
      let segundos     = this.str0( fecha.getSeconds()     ,2);
      let milisegundos = this.str0(fecha.getMilliseconds() ,4);

      let rta = dia +'/'+ mes +'/' + anio;
      if(formato=='DD/MM/AAAA') rta = dia +'/'+ mes +'/' + anio;
      if(formato=='DD/MM/AAAA HH:MM') rta = dia +'/'+ mes +'/' + anio+' '+hora+':'+minutos;
      if(formato=='AAAA-MM-DD') rta= anio+'-'+mes+'-'+dia;
      if(formato=='AAAA/MM/DD') rta= anio+'/'+mes+'/'+dia;
      if(formato=='AAAAMMDD')   rta= anio+mes+dia;
      if(formato=='AAAAMM')     rta= anio+mes;
      if(formato=='DD')         rta= dia;
      
      return rta;
    }

    UrlFileFecha():string {

        let fecha = new Date();
        let anio         = this.str0( fecha.getFullYear()    ,4);
        let mes          = this.str0( fecha.getMonth()+1     ,2);
        let dia          = this.str0( fecha.getDate()        ,2);

        let hora         = this.str0( fecha.getHours()       ,2);
        let minutos      = this.str0( fecha.getMinutes()     ,2);
        let segundos     = this.str0( fecha.getSeconds()     ,2);
        let milisegundos = this.str0(fecha.getMilliseconds() ,4);

        let nombreArchivo  = anio+mes+dia+'_'+hora+minutos+segundos+'_'+milisegundos;

        let rta = anio +'/'+ mes +'/' + dia +'/' + nombreArchivo;
        // log(...values("valores","URlFileFecha:",rta));
        return rta;
    }

    
   newDateOfTimeZone(formato:string, idiomaPais:string, timeZone:string, fechaInput:Date):string {
    // Ej: idiomaPais = 'es-AR'
    // Ej: timeZone   = 'America/Argentina/Buenos_Aires'
    if( fechaInput===undefined || fechaInput==null ){
      return null;
    }
    
    var options:any = { 
      timeZone: timeZone, 
      hour12: false,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit"
    };
    let fecha = fechaInput.toLocaleString(idiomaPais, options);
    
    let rta:string=fecha;
    
    if(formato=='ALL')                 rta = fecha;
    if(formato=='AAAA-MM-DD HH:MM:SS') rta = fecha.substr(0,10)+' '+ fecha.substr(11,8);
    if(formato=='AAAA-MM-DD HH:MM')    rta = fecha.substr(0,10)+' '+ fecha.substr(11,5);
    if(formato=='HH:MM:SS')            rta = fecha.substr(11,8);
    if(formato=='HH:MM')               rta = fecha.substr(11,5);
    if(formato=='AAAA')                rta = fecha.substr(0,4);
    if(formato=='MM')                  rta = fecha.substr(5,2);
    if(formato=='DD')                  rta = fecha.substr(8,2);
    if(formato=='hh')                  rta = fecha.substr(11,2);
    if(formato=='mm')                  rta = fecha.substr(13,2);
    if(formato=='ss')                  rta = fecha.substr(15,2);
    
    return rta;    
}

    getSesionId():string {
        return this.UrlFileFecha();
    }

    getAtributoArchivo(archivo, cual) {
      
        let nombreArchivo = this.getFileNameSinVars(archivo);
        let extensionArchivo = this.getExtensionFile(nombreArchivo );
        let extensionIcono = 'assets/iconos/extensiones-archivos64x64/'+extensionArchivo+'.png';
        if(cual=='nombreArchivo') return nombreArchivo;
        if(cual=='extensionIcono') return extensionIcono;
    }  
    
    getNameSinExtensionFile(fileName:string):string {
        let fileNameExtension    = this.getExtensionFile( fileName );

        let fileNameSinExtension = fileName.replace(fileNameExtension,'');
        fileNameSinExtension = fileNameSinExtension.substr(0, fileNameSinExtension.length-1);

        return fileNameSinExtension;
    }


    getExtensionFile(fileName:string):string {
        let rta;
        if(fileName==null || fileName.indexOf('.')==-1) {
          rta = 'error';
        } else {
          rta = fileName.substring(fileName.lastIndexOf(".")+1);
        }
        return rta;
    }

    getExtensionArchivo(image:any):string {
        // log(...values("valoresDebug","funcion:","getExtensionArchivo"));
        // log(...values("valoresDebug","image:",image));

        let rta='error';
        if(image==null) return rta;

        if(this.isFile(image)) {    // File
            if(image.name!==undefined) rta = image.type;
            rta = rta.replace('image/','');

        } else {                    // Blob

            image=image.toLowerCase();

            if(image.indexOf('image/png')!=-1 ||
               image.indexOf('.png')!=-1) {

                rta='png';

            } else if(image.indexOf('image/jpg')!=-1  ||
                      image.indexOf('image/jpeg')!=-1 ||
                      image.indexOf('.jpg')!=-1       ||
                      image.indexOf('.jpeg')!=-1) {

                rta='jpg';

            } else if(image.indexOf('image/gif')!=-1 ||
                      image.indexOf('.gif')!=-1) {

                rta='gif';

            } else if(image.indexOf('text/plain')!=-1 ||
                      image.indexOf('.txt')!=-1) {

                rta='txt';

            } else if(image.indexOf('application/msword')!=-1 ||
                      image.indexOf('.doc')!=-1) {

                rta='doc';

            } else if(image.indexOf('application/vnd.openxmlformats-officedocument.wordprocessingml.document')!=-1 ||
                      image.indexOf('.docx')!=-1) {

                rta='docx';

            } else if(image.indexOf('application/vnd.ms-excel')!=-1 ||
                      image.indexOf('.xls')!=-1) {

                rta='xls';

            } else if(image.indexOf('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')!=-1 ||
                      image.indexOf('.xlsx')!=-1) {

                rta='xlsx';

            }
        }
        // log(...values("valoresDebug","rta:",rta));

        return rta;

    }

    getImagenCortaFromImage(image:any):string {
        // log(...values("valoresDebug","funcion:","getImagenCortaFromImage"));

        let rta=null;
        if(this.isFile(image)) {  // File
            if(image.name!==undefined) rta = image.name;

        } else {  // blob
            rta = image.substr(0,80)+'...';
        }
        // log(...values("valoresDebug","rta:",rta));

        return rta;
    }

    getBytesFromImage(image:any):string {
        // log(...values("valoresDebug","funcion:","getBytesFromImage"));

        let rta=null;
        if(this.isFile(image)) {  // File
            if(image.size!==undefined) rta = image.size.toString();

        } else {  // blob
            rta = image.length.toString();
        }
        // log(...values("valoresDebug","rta:",rta));

        return rta;
    }

    isTypeImagen(type:string):boolean {
        let rta=false;
        if(type.includes('png'))  rta=true;
        if(type.includes('jpg'))  rta=true;
        if(type.includes('jpeg')) rta=true;
        if(type.includes('gif'))  rta=true;

        return rta;
    }

    getTypeFromImage(image:any):string {
        // log(...values("valoresDebug","funcion:","getTypeFromImage"));

        let rta=null;

        if(image==null) return rta;

        if(this.isFile(image)) {  // File
            if(image.type!==undefined) rta = image.type;

        } else {            // Blob
            image=image.toLowerCase();

            if(image.includes('image/png'))  rta='image/png';
            if(image.includes('image/jpg'))  rta='image/jpg';
            if(image.includes('image/jpeg')) rta='image/jpeg';   // No se si existe
            if(image.includes('image/gif'))  rta='image/gif';
        }
        // log(...values("valoresDebug","rta:",rta));

        return rta;
    }

    getFileNameFromArchivo(archivo:any):string {
        // log(...values('valoresDebug','getFileNameFromImage archivo:',archivo));

        let rta=null;

        if(archivo==null) return null;

        if(this.isFile(archivo)) {  // File
            if(archivo.name!==undefined) rta = archivo.name;

        } else {            // Blob
            archivo = decodeURIComponent(archivo);
            rta = archivo.substring(archivo.lastIndexOf('/')+1);
        }
        // log(...values('valoresDebug','getFileNameFromUrlFile fileName:',fileName));
        return rta;
    }



    sizeOfFirestoreDocument(pathColeccion, object){
    /* Returns la cantidad de bytes que ocupa un documento de Firestore
     * Modificación de la librería standar: sizeOf.js
     *
     * Documentación Firestore
     * https://firebase.google.com/docs/firestore/storage-size
     *
     * 1) El tamaño del nombre del documento
     * 2) La suma del tamaño de string de cada nombre de campo
     * 3) La suma del tamaño de cada valor de campo
     * 4) Sumar 32 bytes adicionales
     * Tamaño = 1 + 2 + 3 + 4
     */
        if(pathColeccion==null || pathColeccion===undefined) {
          // console.error("sizeOfFirestoreDocument --> ERROR null documento!!!");
        }
        let sizeDocument=0;
        sizeDocument += pathColeccion.length;
        sizeDocument += this.sizeOf(object);
        sizeDocument += 32;

        // console.log("sizeOfFirestoreDocument", object, sizeDocument);

        return sizeDocument;
    }

    sizeOfIfNotCalculado(argumentos:any) {
        /*
         * argumentos = {
         *     sizeOfActual  : number,
         *     pathColeccion : string,
         *     object        : string
         * }
         *
         * pathColeccion hace referencia a la ruta completa utilizada para acceder a la colección o subcolección
         * ej colección:    Organizaciones/Wa4gyt45kdsFk45K
         * ej subColección: Organizaciones/Wa4gyt45kdsFk45K/Rutas/4kdrjiHdiD3r2
         */

         let sizeOfActual  = argumentos.sizeOfActual;
         let pathColeccion = argumentos.pathColeccion;
         let object        = argumentos.object;

        if(pathColeccion==null || pathColeccion===undefined) {
            // console.log("this.sizeOfIfNotCalculado -> Valor Null !!! ");;
            return 0;
        }
        if(sizeOfActual!=0) {
            // console.log("this.sizeOfIfNotCalculado -> Ya calculado !!! ");;
            return sizeOfActual;
        } else {
            // console.error("this.sizeOfIfNotCalculado -> Recalcular !!!");;
            return this.sizeOfFirestoreDocument(pathColeccion, object);
        }
    }

    sizeOf(object){

      // initialise the list of objects and size
      var objects = [object];
      var size    = 0;

      // loop over the objects
      for (var index = 0; index < objects.length; index ++){

        // determine the type of the object
        // console.log("sizeOf",objects[index],"typeOf",typeof objects[index]);
        switch (typeof objects[index]){

          // the object is a boolean
          case 'boolean':
              size += 4;
              // console.log("sizeOf",objects[index],"boolean",4);
              break;

          // the object is a number
          case 'number':
              size += 8;
              // console.log("sizeOf",objects[index],"number",8);
              break;

          // the object is a string
          case 'string':
              size += 2 * objects[index].length;
              // console.log("sizeOf",objects[index],"string",2 * objects[index].length);
              break;

          // the object is a generic object
          case 'object':

            // if the object is not an array, add the sizes of the keys
            if (Object.prototype.toString.call(objects[index]) != '[object Array]'){
              for (var key in objects[index]) {
                size += 2 * key.length;
                // console.log("sizeOf",key,"key",key.length);
              }
            }

            // loop over the keys
            for (var key in objects[index]){

              // determine whether the value has already been processed
              var processed = false;
              for (var search = 0; search < objects.length; search ++){
                if (objects[search] === objects[index][key]){
                  processed = true;
                  break;
                }
              }

              // queue the value to be processed if appropriate
              if (!processed) objects.push(objects[index][key]);

            }

        }

      }

      // return the calculated size
      // console.error("sizeOf",size);
      return size;

    }

    getSettings():any  {

        return {
            isActivo                : true,
            isBorrado               : false,
            borradoFisico           : false,
            triggersIgnorar         : false,
            triggersProcesarTarde   : false,
            documentosAsociados     : 0,
            sizeOfDocumento         : 0,
            fechaHoraCreacion       : null,
            fechaHoraModificacion   : null,
            usuarioKANE             : null,
        };
    }

    eliminarIMGLinkThumb(documento:any):any {
        for (var field in documento) {
            //console.log("field:", field, documento.hasOwnProperty(field) );
            if(field.indexOf('IMG')!=-1) {
              if(documento[field].linkThmb !==undefined) {
                delete documento[field].linkThmb;
              }
            }
        }
        //console.log("eliminarIMGLinkThumb documento corregido",documento);
        return documento;
    }

    agregarDisabledFields(documento:any, controls:any):any {
        let i=0;
        for (var field in controls) {
            i++;
            if( !documento.hasOwnProperty(field) ) {
               let value = (controls[field].value!==undefined) ? controls[field].value : null;
               documento[field]=value;
            }
        }
        //console.log("documento corregido",documento);
        return documento;
    }

    buscarNombreEnSelect(key, listado) {
      //console.log("listadoSelect buscarNombreEnSelect",key,listado);
      for(let i=0; i<listado.length; i++) {
        if(listado[i].key==key) {
          return listado[i].nombre;
        }
      }
      return '';
    }

    getKey(elemento:any):string {
      //console.log("getKeyKN",elemento);
      if(elemento===undefined || elemento===null || elemento.key===undefined) {
        return null;
      } else {
          return elemento.key;
      }
    }
    
    mostrarKMONEY(elemento:any, cual:string):string {
      //console.log("mostrarKMONEY",elemento);
      if(elemento===undefined || elemento==null) {
        return '';
      } else {
        if(cual=='key') {
          return elemento.key;
        } else if(cual=='nombre') {
          return elemento.nombre;
        } else if(cual=='simbolo') {
          return elemento.simbolo;
        } else if(cual=='codigoDivisa') {
          return elemento.codigoDivisa;
        } else {
          return 'ERROR';
        }
      }
    }

    mostrarKIMP(elemento:any, cual:string):string {
      //console.log("mostrarKIMP",elemento);
      if(elemento===undefined || elemento==null) {
        return '';
      } else {
        if(cual=='key') {
          return elemento.key;
        } else if(cual=='nombre') {
          return elemento.nombre;
        } else if(cual=='simbolo') {
          return elemento.simbolo;
        } else if(cual=='codigoDivisa') {
          return elemento.codigoDivisa;
        } else if(cual=='importe') {
          return elemento.codigoDivisa;
        } else {
          return 'ERROR';
        }
      }
    }

    mostrarOBJ(elemento:any, cual:string):string {
      //console.log("mostrarKN",elemento);
      if(elemento===undefined || elemento==null || elemento[cual]==undefined) {
        return '';
      } else {
        return elemento[cual];
      }
    }
    
    mostrarKN(elemento:any, cual:string):string {
      //console.log("mostrarKN",elemento);
      if(elemento===undefined || elemento==null) {
        return null;
      } else {
        if(cual=='key') {
          return elemento.key;
        } else if(cual=='nombre') {
          return elemento.nombre;
        } else {
          return 'ERROR';
        }
      }
    }

    mostrarKNAI(elemento:any, cual:string):string {
      //console.log("mostrarKN",elemento);
      if(elemento===undefined || elemento==null) {
        return null;
      } else {
        if(cual=='key') {
          return elemento.key;
        } else if(cual=='nombre') {
          return elemento.nombre;
        } else if(cual=='autoIncrement') {
          return elemento.autoIncrement;
        } else {
          return 'ERROR';
        }
      }
    }
    
    mostrarKANE(elemento:any, cual:string):string {
      //console.log("mostrarKANE",elemento);
      if(elemento===undefined || elemento==null) {
        return null;
      } else {
        if(cual=='key') {
          return elemento.key;
        } else if(cual=='apellidoNombre') {
          return elemento.apellidoNombre;
        } else if(cual=='email') {
          return elemento.email;
        } else {
          return 'ERROR';
        }
      }
    }

    setearOrganizacionKNAI(elemento:any):KNAI {
      // console.log("setearOrganizacionKNAI",elemento);

          let rta:KNAI=null;
          if(elemento!=null) {
              rta={
                  key           : elemento.key    ? elemento.key : null,
                  nombre        : elemento.nombre ? elemento.nombre : null,
                  // autoIncrement : (elemento.autoIncrement===undefined) ? 'MAQUE' : this.str0(elemento.autoIncrement,5),
                  autoIncrement : (elemento.autoIncrement===undefined) ? 0 : elemento.autoIncrement,
              };
          }
          return rta;
    }

    setearOrganizacionAI(elemento:any):string {
        if(elemento===undefined || elemento==null || elemento.autoIncrement===undefined) {
          return 'MAQUE';
        } else {
          return this.str0(elemento.autoIncrement,5)
        }
    }

    setearAI(elemento:any):any {
        if(elemento===undefined || elemento==null || elemento.autoIncrement===undefined) {
          return null;
        } else {
          return this.str0(elemento.autoIncrement,5)
        }
    }

    setearKN(elemento:any):any {
          let rta=null;
          if(elemento!=null) {

              rta={
                key    : elemento.key    ? elemento.key : null,
                nombre : null
              };

              if(elemento.nombre!==undefined && elemento.nombre!==null) {
                rta.nombre = elemento.nombre;

              } else if(elemento.title!==undefined && elemento.title!==null) {
                rta.nombre = elemento.title;

              } else if(elemento.descripcion!==undefined && elemento.descripcion!==null) {
                rta.nombre = elemento.descripcion;

              } else if(elemento.razonSocial!==undefined && elemento.razonSocial!==null) {
                rta.nombre = elemento.razonSocial;
                
              } else if(elemento.apellidoNombre!==undefined && elemento.apellidoNombre!==null) {
                rta.nombre = elemento.apellidoNombre;

              } else if(elemento.apellido!==undefined && elemento.apellido!==null) {
                rta.nombre = elemento.apellido +', '+ elemento.apellido.nombre;

              } else if(elemento?.datosPersonales?.apellido!==undefined && elemento?.datosPersonales?.apellido!==null) {
                rta.nombre = elemento.datosPersonales.apellido+', '+elemento.datosPersonales.nombre;
              }
          }
          return rta;
    }

    setearKNAI(elemento:any):KNAI {
          // console.log("setearKN",elemento);

          let rta:KNAI=null;
          if(elemento!=null) {
              rta={
                key           : elemento.key    ? elemento.key : null,
                nombre        : elemento.nombre ? elemento.nombre : null,
                autoIncrement : (elemento.autoIncrement===undefined) ? 0 : elemento.autoIncrement,
              };
          }
          return rta;
    }

    setearKMONEY(elemento:any):any {
      // console.log("setearKMONEY",elemento);


      let rta=null;
      if(elemento!=null) {
          rta={
            key            : elemento.key    ? elemento.key : null,
            nombre         : elemento.nombre ? elemento.nombre : null,
            simbolo        : elemento.simbolo ? elemento.simbolo : null,
            codigoDivisa   : elemento.codigoDivisa ? elemento.codigoDivisa : null,
            
          };
      }
      return rta;
    }

    setearKANE(elemento:any):any {
          // console.log("setearKANE elemento",elemento);
          let rta=null;

          if(elemento!=null) {

              let apellidoNombre='';
              if(elemento?.apellidoNombre!==undefined && elemento?.apellidoNombre!==null) {
                apellidoNombre = elemento.apellidoNombre;
              } else if(elemento?.datosPersonales?.apellido!==undefined && elemento?.datosPersonales?.apellido!==null) {
                apellidoNombre = elemento.datosPersonales.apellido+', '+elemento.datosPersonales.nombre;
              } else if(elemento?.apellido!==undefined && elemento?.apellido!==null) {
                apellidoNombre = elemento.apellido+', '+elemento.nombre;
              }  else {
                // console.log("setearKANE nombre sin definir");
              }

              rta={
                key            : elemento.key,
                email          : (elemento.email===undefined) ? null : elemento.email,
                apellidoNombre : apellidoNombre,
              };
          }
          // console.log("setearKANE rta",rta);
          return rta;
    }

    setearKANEAI(elemento:any):any {
          // console.log("setearKANE elemento",elemento);
          let rta=null;

          if(elemento!=null) {

              let apellidoNombre='';
              if(elemento.apellidoNombre!==undefined) {
                apellidoNombre = elemento.apellidoNombre;
              } else if(elemento.datosPersonales.apellido!==undefined) {
                apellidoNombre = elemento.datosPersonales.apellido+', '+elemento.datosPersonales.nombre;
              } else if(elemento.apellido!==undefined) {
                apellidoNombre = elemento.apellido+', '+elemento.nombre;
              }  else {
                console.log("setearKANE nombre sin definir");
              }

              rta={
                key            : elemento.key,
                email          : (elemento.email===undefined) ? null : elemento.email,
                apellidoNombre : apellidoNombre,
                autoIncrement  : (elemento.autoIncrement===undefined) ? null : elemento.autoIncrement,
              };
          }
          console.log("setearKANE rta",rta);
          return rta;
    }

    setearGeoPoint(elemento:any):any {
        let rta;
        if(elemento.geoPoint===undefined || elemento.geoPoint==null) {
          rta = null;
        } else {
          rta = {
            latitutd : this.toFloat(elemento.geoPoint.latitud),
            longitud : this.toFloat(elemento.geoPoint.latitud),
          }
        }
        return rta;
    }


    verificarIsActivo(documento:any):boolean {

          if(documento.settings===undefined || documento.settings==null) {
            return false;
          } else if(documento.settings.isActivo===undefined) {
            return false;
          } else if(documento.settings.isActivo) {
            return true;
          } else {
            return false;
          }
    }

    MatEvaluate(x:string):number{
      return evaluate(x);
    }

    getAtributoFromListado( listado:any[], valorABuscar:string, cualComparar:string, cualResponde:string):any {
      // console.log("getAtributoFromListado ",listado, valorABuscar, cualComparar, cualResponde);
      for(let i=0; i<listado?.length;i++) {
          let valorAComparar = (cualComparar=='key') ? listado[i].key : listado[i][cualComparar];
          if(valorAComparar==valorABuscar) {
            
              if(cualResponde=='key') {
                  return listado[i].key;
              
              } else if(cualResponde=='nombre') {
                  return listado[i].nombre;

              }else if(cualResponde=='codigo') {
                return listado[i].codigo;

              } else if(cualResponde=='OBJ') {                
                  return listado[i];
                   
              } else if(cualResponde=='KN') {
                
                 return {
                    key               : listado[i].key,
                    nombre            : listado[i].nombre
                  }
                  
              } else if(cualResponde=='KANE') {
                
                 return {
                    key               : listado[i].key,
                    apellidoNombre    : listado[i].apellidoNombre,
                    email             : listado[i].email,
                 }   
                
              } else {
                  return listado[i][cualResponde];
              }
              
          }
      }
      return null;
    }    
    getIdiomaPaisFromTimeZone( listadoTimeZones:any[], timeZone:string ):string {
        for(let i=0; i<listadoTimeZones.length;i++) {
            if(listadoTimeZones[i]==timeZone) {
              return listadoTimeZones[i].idiomaPais;
            }
        }
        return null;
    }    
    
    getDireccionTextArea(direccion:Direccion, cual):string {
      /*
       * cual 
       * - direccion
       * - ciudad
       * - provincia
       * - pais
       * - direccion+ciudad
       * - direccion+ciudad+provincia
       */
     
      if(direccion==null || direccion===undefined) {
        return '';
      } else {
          let rta_direccion:string='';
          let rta_ciudad:string='';
          let rta_provincia:string='';
          let rta_pais:string='';
          if(direccion.calle)         rta_direccion += direccion.calle;
          if(direccion.numero)        rta_direccion += ' '+direccion.numero;
          if(direccion.piso)          rta_direccion += ' '+direccion.piso;
          if(direccion.departamento)  rta_direccion += ' '+direccion.departamento;

          if(direccion.ciudad)        rta_ciudad += direccion.ciudad;
          if(direccion.partido)       rta_ciudad += ' / '+direccion.partido;
          
          if(direccion.provinciaKN && direccion.provinciaKN.nombre) rta_provincia = ' '+direccion.provinciaKN.nombre;
          if(direccion.paisKN && direccion.paisKN.nombre) rta_provincia = ' '+direccion.paisKN.nombre;
        
          if(cual=='direccion') {
              return rta_direccion;
              
          } else if(cual=='ciudad') {
              return rta_ciudad;
              
          } else if(cual=='provincia') {
              return rta_provincia;

          } else if(cual=='provincia') {
              return rta_pais;

          } else if(cual=='direccion+ciudad') {
              return rta_direccion+'\n'+rta_ciudad;

          } else if(cual=='direccion+ciudad+provincia') {
              return rta_direccion+'\n'+rta_ciudad+' / '+rta_provincia;
          }  
      }
      
    }
    
    getMinutosServicio(tiempoServicio:any):number {
        //console.log("minutosServicio - tiempoServicio",tiempoServicio);            
        let minutosServicio = 0;
        if(tiempoServicio && tiempoServicio.hour!==undefined && tiempoServicio.minute!==undefined && tiempoServicio.second!==undefined) {
            minutosServicio = tiempoServicio.hour*60 + tiempoServicio.minute + tiempoServicio.second/60;
        }              
        //console.log("minutosServicio - rta",minutosServicio);            
        return minutosServicio;
    }
    
   // ---- function forzar_title_description_keywords() ----   
   setMetatag(argumentos:any) {   
     /* recibe
      * argumentos {
           title
           description',
           keywords,
           type,
           url,            
           image
        }
      */     

      // https://metatags.io/   --> generador
      // https://www.heymeta.com/ --> check
      // https://dev.to/andreilm/dynamic-social-media-tags-with-angular-7-3a5
      // https://www.positronx.io/angular-seo-set-dynamic-page-title-meta-tags-in-universal-app/
      // https://www.contentking.es/academia/open-graph/
      // https://cards-dev.twitter.com/validator 
      
      let title         = (argumentos.title)       ? argumentos.title +' | '+this.appSettings.settings2.app.title : this.appSettings.settings2.app.title;
      let description   = (argumentos.description) ? this.remueveTagP(argumentos.description)                     : this.appSettings.settings2.app.descripcion;
      let keywords      = (argumentos.keywords)    ? argumentos.keywords                                          : this.appSettings.settings2.app.keywords;
      let type          = argumentos.type;
      let url           = (argumentos.url)         ? argumentos.url                                               : this.appSettings.settings2.cliente.web;
      let image         = (argumentos.image)       ? argumentos.image                                             : this.appSettings.settings2.cliente.logoMetatags;

      let image_type='';
      if(image.indexOf('.png')!=-1)  image_type = "image/png";
      if(image.indexOf('.jpg')!=-1)  image_type = "image/jpeg";
      if(image.indexOf('.jpeg')!=-1) image_type = "image/jpeg";
      if(image.indexOf('.gif')!=-1)  image_type = "image/gif";

      this.title.setTitle( title );
      
      this.meta.updateTag({ name: 'description' ,       content: description });
      this.meta.updateTag({ name: 'keywords' ,          content: keywords });
      this.meta.updateTag({ name: 'news_keywords' ,     content: keywords });

      this.meta.updateTag({ property: 'og:title' ,      content: title });
      this.meta.updateTag({ property: 'og:description', content: description });
      this.meta.updateTag({ property: 'og:type' ,       content: type });
      this.meta.updateTag({ property: 'og:url' ,        content: url });
      
      this.meta.updateTag({ property: 'og:image' ,      content: image });
      this.meta.updateTag({ property: 'og:image:type',  content: image_type });
      this.meta.updateTag({ property: 'og:image:alt',   content: description });
      this.meta.updateTag({ property: 'og:image:width', content: '600' });
      this.meta.updateTag({ property: 'og:image:height',content: '400' });
      
      this.meta.updateTag({ property: 'article:section',content: description });

      this.meta.updateTag({ name: 'twitter:title' ,     content: title });
      this.meta.updateTag({ name: 'twitter:description',content: description });
      this.meta.updateTag({ name: 'twitter:image' ,     content: image });
  }    
  
  newDateUTC(stringDate:string, utc:string):Date {
    
    if(stringDate.includes("Z")) {
      stringDate = stringDate.replace("Z",utc);
    }
    let rta = new Date(stringDate);
    console.log("newDateUTC",stringDate, utc, rta);
    return rta;
  }

  isString(myVar){
      return (typeof myVar === 'string' || myVar instanceof String)
  }
  
  filtroXEntidad(documentoKey:any, valueKey:any):boolean {
    if(documentoKey==null || documentoKey===undefined || valueKey==null || valueKey===undefined) {
      return true;
    } else {    
      return documentoKey==valueKey;
    }  
}   

  
}

export function isArray(obj){
       return !!obj && obj.constructor === Array;
}
