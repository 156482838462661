import { log, logIf, logTable, values } from '@maq-console';

import { Component, ViewEncapsulation,OnInit, AfterViewInit, AfterViewChecked, OnDestroy, } from '@angular/core';

// import { Router, NavigationStart } from '@angular/router';
import { AppSettings2 }         from '@maq-models/appSettings/appSettings.model';
import { AppSettingsService }   from './app.settings';
import { TranslateService}      from '@ngx-translate/core';
import { BDService }            from '@maq-bd/bd.service';
import { MensajesService }      from '@maq-servicios/mensajes/mensajes.service';
import { FuncionesService }     from '@maq-funciones';
import { StorageService } from '@maq-servicios/storageService/storage.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
  
   public logComponente = log(...values('componente', 'App'));

   public activeLang = 'es';
   public settings2: AppSettings2;

   public subscripcionActualizaciones;
   public subscripcionGetListadoCache;
   public cantSubscripcionListadoCache:number;   

   constructor(public appSettings:AppSettingsService, 
            //    private router:Router,
               public fn:FuncionesService,
               public bdService: BDService,
               public mensageService:MensajesService,
               public storageService:StorageService,
               private translate: TranslateService){
     
        log(...values('funcionComponente','constructor App'));
        
        this.settings2 = this.appSettings.settings2;  
        
        //log(...values("valoresDebug","this.translate:",this.translate));
        //log(...values("valoresDebug","this.translate.store.currentLang:",this.translate.store.currentLang));
    
        // this. translate.addLangs(['en']);
        // this.translate.setDefaultLang('en');
        // this.translate.use('en');

        // const browserLang = translate.getBrowserLang();
        // translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');

        //this.translate.setDefaultLang('xx');
        //this.translate.setDefaultLang('es');
        //this.translate.use('xx');
        
        this.activeLang = this.appSettings.settings2.app.idiomaDefault;
        
        let idomaUtilizado=localStorage.getItem("IdiomaUtilizado");
        if(idomaUtilizado){

        }else{
            idomaUtilizado=this.appSettings.settings2.app.idiomaDefault;            
        }
        this.translate.setDefaultLang(idomaUtilizado);
        this.translate.use(idomaUtilizado);
        
        console.log("idomaUtilizado",idomaUtilizado);

        this.translate.onLangChange.subscribe((data:any) => {
            log(...values("listener","App.translate.onLangChange"));
            log(...values("valoresDebug","this.translate:",this.translate, "data:",data, "data.lang:",data.lang))
        });
        
        this.subscripcionActualizaciones=this.bdService.getBDActualizacionesSubscripcion()
            .subscribe(documento=>{
              
                // Mantengo esta suscripción hasta el final, porque quiero enterarme si se ha modificado alguna de las colecciones Auxiliares.

                let actualizacionesBD=documento;
    
                // log(...values('funcionEnd','bdService.getBDActualizacionesPromesa')); 
                // log(...values('valores','xx coleccionActualizada documento:',actualizacionesBD)); 
                
                console.log("getBDActualizacionesSubscripcion", documento);
                  
                // Comparo Fechas de Actualización de la BD con las internas del this.storageService
                // log(...values('info','xx Comparo Fechas de Actualización de la BD con las internas del this.storageService')); 
                
                this.cantSubscripcionListadoCache = 0;
                this.subscripcionGetListadoCache=[];

                for(let coleccionActualizada in actualizacionesBD) {
                    
                        let fechaHoraGrabacionLocal=localStorage.getItem("Coleccion_"+coleccionActualizada);  
                        
                        //console.log("getBDActualizacionesSubscripcion", coleccionActualizada);
                           
                        let fechaHoraCache=null;
                        if(!fechaHoraGrabacionLocal ) {    
                          //  console.log("xx coleccionActualizada Coleccion_"+coleccionActualizada,fechaHoraGrabacionLocal,'continue') ;
                          // sigo con el siguiente, esta colección no se ha utilizado                           
                          continue;         
                        } else {
                             fechaHoraCache = new Date(fechaHoraGrabacionLocal);
                        }
                        
                        let fechaHoraUltimaActualizacionBase = this.fn.timestampToDate(actualizacionesBD[coleccionActualizada].fechaHoraModificacion );
                        
                        let comparacion = this.fn.compareFechas(fechaHoraCache, fechaHoraUltimaActualizacionBase);
                        
                        //console.log("getBDActualizacionesSubscripcion",coleccionActualizada, fechaHoraCache, fechaHoraUltimaActualizacionBase, comparacion);
                        
                        // console.log("xx coleccionActualizada comparacion Coleccion_"+coleccionActualizada,comparacion);

                        if( comparacion=='1menor2') {
                            // console.log("xx coleccionActualizada Coleccion_"+coleccionActualizada,fechaHoraGrabacionLocal,'actualizo') ;   
                          
                            // Al borrar las this.storageService  getListadoCache y el valor almacenado en el mensaje actualizará los datos 
                            this.storageService.removeItem("Coleccion_"+coleccionActualizada);
                            this.storageService.removeItem("Subscripcion_"+coleccionActualizada);
                            this.mensageService.cacheColecciones[coleccionActualizada]=null;
                      
                            let organizacionKNAI=null;
                            let usuarioKANE = {
                              key            : 'santiagocavalie@gmail.com',
                              apellidoNombre : 'santiagocavalie@gmail.com',                                
                              email          : 'santiagocavalie@gmail.com',                                
                            };
                            
                            this.cantSubscripcionListadoCache++;
                          
                            this.subscripcionGetListadoCache.push(
                                this.mensageService.getListadoCache({
                                    nombreListado              : coleccionActualizada,   
                                    nombreColeccion            : coleccionActualizada,
                                    where                      : [],
                                    orderBy                    : [],
                                    limit                      : null,                             
                                    organizacionKNAI           : organizacionKNAI,                           
                                    usuarioKANE                : usuarioKANE,
                                    nombreColeccionSolicitante : coleccionActualizada, 
                                    grabaLocalStorage          : true,
                                    ignoraValoresMemoria       : false, 
                                    datosPorOrganizacion       : false,   
                                    limpiaSettingUsuarioOrganizacion:false        
                                }).subscribe(data=>{
                                  // console.log("xxcoleccionActualizada data",data);
                                  this.cantSubscripcionListadoCache--;
                                  if(this.cantSubscripcionListadoCache==0) {
                                    
                                       // Guardo Fechas de Actualización en Cache en el servicio getListadoCache                 
                                                                              
                                       // Destruyo subscripciones para tener los datos 
                                       for (var i=0; i<this.subscripcionGetListadoCache.length; i++) {
                                            if(this.subscripcionGetListadoCache[i]) {
                                                this.subscripcionGetListadoCache[i].unsubscribe();
                                                // console.log("desubscripcion",i);
                                            }
                                       }                                       
                                  }
                                
                              })  
                            )    
                        }else{
                            // no es necesario actualizar.
                            // console.log("xxcoleccionActualizada Coleccion_"+coleccionActualizada,fechaHoraGrabacionLocal,'no es necesario actualizar') ;   
                        }
                
                } // fin for

            },(error:any)=>{
                log(...values("error",error));
            });         
          
  }    
  
  ngOnDestroy() {
    log(...values('funcionComponente','ngOnDestroy AppComponente'));

    if(this.subscripcionActualizaciones) this.subscripcionActualizaciones.unsubscribe();
    // Boorro las colecceciones almacenadas en localStograge que
    // no comienzan con Aux porque no se actualiza la fecha de última modificación en la colección Actualizaciones.
    // solo se guarda las fecha de las tablas auxiliares.

   
  }  
  

  cambiarLenguaje(lang) {
    this.activeLang = lang;
    this.translate.use(lang);
  }

  ngOnInit() {
      
    this.configuroVariablesEntorno();

    // Actualización de Tablas almacenadas en this.storageService
    for (var key in this.storageService.localStorage()){
        
        // Elimino las Colecciones almacengadas por getListadoCache que no se actualizan
        if(key.substr(0,13)=='Subscripcion_' && key.substr(0,16)!='Subscripcion_Aux'){
            console.log('remove',key);
            this.storageService.removeItem(key);
            let aux=key.replace('Subscripcion_','');
            if(this.mensageService.cacheColecciones[aux]){
                this.mensageService.cacheColecciones[aux]=null;
            }
        }
        // Elimino las Colecciones almacengadas por getListadoCache que no se actualizan
        if(key.substr(0,10)=='Coleccion_' && key.substr(0,13)!='Coleccion_Aux'){
            console.log('remove',key);
            this.storageService.removeItem(key);
        }
     }

  }
   
  ngAfterViewInit(){
    document.getElementById('preloader').classList.add('hide');
  }

  ngAfterViewChecked(){
      //  this.translate.use('es');
      // console.log('this.translate',this.translate);
      // console.log('this.translate current',this.translate.store.currentLang);
  }

  configuroVariablesEntorno(){
      var OSName="Unknown OS"; 
      if (navigator.appVersion.indexOf("Win")!=-1) OSName="Windows"; 
      if (navigator.appVersion.indexOf("Mac")!=-1) OSName="MacOS"; 
      if (navigator.appVersion.indexOf("X11")!=-1) OSName="UNIX"; 
      if (navigator.appVersion.indexOf("Linux")!=-1) OSName="Linux"; 
      
      this.appSettings.settings2.equipo={
          versionSO:OSName,
          plataforma:navigator.appVersion,
          isEmulador:false,             
          screenWidth:window.innerWidth,
          screenHeigth:window.innerHeight, 
          fabricante:null,             
          modelo:null,                 
          batteryLevel:null,           
          estado:null,
          isOnLine:navigator.onLine,
          tieneServicioDatos:navigator.onLine,
          lenguaje:   navigator.language               
     }; 
     

    }

  
  
}
