import { log, logIf, logTable, values } from '@maq-console';

import { Component, EventEmitter, OnInit, ViewChild, Output, Input, ViewEncapsulation } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormsModule} from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { FuncionesService }  from '@maq-funciones';


@Component({
  selector: 'lib-shared-input-url-youtube',
  templateUrl: './input-url-youtube.component.html',
  styleUrls: [ './input-url-youtube.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class InputUrlYoutubeComponent implements OnInit {
    @Input() public nameField: string; 
    @Input() public value: string; 
    @Input() public controlsField: any;
    @Input() public formInvalid: boolean;
    @Input() public accionForm: string; 

    @Output() cargoVideoUrl= new EventEmitter<any>();

    @ViewChild("modalContent") modalContent: any;

    public form:FormGroup;
    public valueAnterior:string=null;

    // ngx-youtube-player
    public playerYoutube: any;  // YT.Player;
    public videoYoutubeId: string = null; 
    
    constructor(public fn:FuncionesService,
                public fb:FormBuilder,
                private translate: TranslateService) { 

    }

    ngOnInit() {

        log(...values("valores", "input-url-youtube - ngOnInit - nameField:",this.nameField));
        log(...values("valores", "input-url-youtube - ngOnInit - value:",this.value));
        log(...values("valores", "input-url-youtube - ngOnInit - controlsField:",this.controlsField));
        
        // Defino Formulario
        this.form = this.fb.group({
            videoUrl : [null, Validators.compose([Validators.required])],
        });

        this.form.controls['videoUrl'].setValue(this.value);
        
        let self=this;
        setTimeout(function () {
            self.changeIdVideoYoutube();      
        }, 3000);              

    }  
    
    ngDoCheck() {

        // log(...values("valores", "input-url-youtube - ngDocheck - nameField:",this.nameField));
        // log(...values("valores", "input-url-youtube - ngDocheck - value:",this.value));
    
        if(this.valueAnterior!=this.value) {
            this.valueAnterior=this.value;
            this.form.controls['videoUrl'].setValue(this.value);  
            
            let self=this;
            setTimeout(function () {
                self.changeIdVideoYoutube();      
            }, 3000);              
                
        }
          
        if(this.accionForm=='consultar') {
          this.form.disable();
          
        } else {
          
          if(this.controlsField['status']!='DISABLED') {        
              this.form.enable();
              
              this.form.controls['videoUrl'].setValidators(null);
  
              //console.log("input-url-youtube this.controlsField['controls']",this.controlsField['controls']);
              if(this.controlsField['validator'])  this.form.controls['videoUrl'].setValidators([Validators.required]);  
              
          } else {
              this.form.disable();
          } 
        } 
  
        if(this.formInvalid) {
          this.form.markAllAsTouched();
        }
  }

  // ngx-youtube-player
  savePlayer(player) {
    this.playerYoutube = player;
    console.log('input-url-youtube savePlayer player', player);
  }
  
  onStateChange(event) {
    console.log('input-url-youtube onStateChange event', event, event.data);
  }  
  
  playVideo() {
    console.log('input-url-youtube playVideo');    
    this.playerYoutube.playVideo();
  }
  
  pauseVideo() {
    console.log('input-url-youtube pauseVideo');    
    this.playerYoutube.pauseVideo();
  }
  
  changeIdVideoYoutube() {
    
    let value = this.form.get('videoUrl').value;  
    log(...values('funcionComponente',`input-url-youtube changeIdVideoYoutube(${value})`));

    this.cargoVideoUrl.emit({
      nameField : this.nameField,
      value    :  value
    });
  
    if(value) {
        this.videoYoutubeId = value.replace('https://www.youtube.com/watch?v=','');
        console.log('input-url-youtube changeIdVideoYoutube', value, this.videoYoutubeId);
        console.log('input-url-youtube this.playerYoutube', this.playerYoutube);
      
        if(this.playerYoutube!=null) {
            this.playerYoutube.loadVideoById( this.videoYoutubeId );  
        }
        
        let self=this;
        setTimeout(function () {
            self.pauseVideo();
        }, 3000);              
    }    

  }    

}  
  
