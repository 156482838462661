import { KN, KNAI, KANE }         from '../typesKN/typesKN.model';
import { IMG }              from '../typesIMG/typesIMG.model';
import { DatosPersonales }  from '../datosPersonales/datosPersonales.model';
import { Direccion }        from '../direccion/direccion.model';
import { Telefono }         from '../telefono/telefono.model';
import { RedSocial }        from '../redSocial/redSocial.model';
import { HorarioAtencion }  from '../horariosAtencion/horariosAtencion.model';
import { PerfilUsuario }    from '../perfiles/perfiles.model';
import { Settings }         from '../settings/settings.model';
import { Soap }             from '../soap/soap.model';
import { str0 }             from '../../helpers/stringsHelp';
import { OPERADOR_WHERE, TIPO_BASE_DATOS, WhereInterface } from '../bd/bdDefinicionesGenerales.model';
import { ArgumentosLlamarBaseDeDatos } from '../bd/bdBase.model';
import { BdBaseService } from '@settings/maqueta/servicios/bd/bd.serviceBase';
import { getOffsetUTC, getOffsetUTCHoursWithOffsetUTC } from '@settings/maqueta/helpers/fechaHoraHelp';
import { re } from 'mathjs';

export class Usuario {    
  key                       : string;
  userKey                   : string;    // Uid de auth firestore
  codigo                    : string;
  email                     : string;
  emailRecuperacion         : string;
  keywords                  : string[];
  datosPersonales           : DatosPersonales;
  direccion                 : Direccion;
  telefono                  : Telefono ;

  perfilUsuario             : PerfilUsuario;
  urlBaseDatos              : String ; // se usa acá si el perfil es Desarrollador o Distribuidor

  distribuidor              : Distribuidor;
  organizacion              : Organizacion;
  sucursalesAreasNegocio    : string[];   // ['keySucursal@@@keyAreaNegocio','keySucursal@@@keyAreaNegocio','keySucursal@@@keyAreaNegocio']
  
  settings                  : Settings;
  favoritosMenues           : any[];
  vehiculos                 : any[]; 
  
  
  preferencias              : {
    navegacionMovil         : string;   // 'Waze-Web','Waze-App', 'GoogleMap-Web','GoogleMap-App'


  
    
  }

  datosImportacion          : any;
  timeStampCreaciom         : string;

  public utc:string = '+0000';
  public utcNumber:number = 0;
  
  // constructor(){
  //   this.key                      = null;
  //   this.userKey                  = null;
  //   this.codigo                   = null;
  //   this.email                    = null;
  //   this.datosPersonales          = new DatosPersonales();
  //   this.direccion                = new Direccion();
  //   this.telefono                 = new Telefono();

  //   this.perfilUsuario            = null;
  //   this.distribuidor             = null;
  //   this.organizacion             = null;
  //   this.sucursalesAreasNegocio   = [];

  //   this.settings                 = new Settings();

  //   this.favoritosMenues          = [];

  //   this.datosImportacion         = null;
  //   this.timeStampCreaciom        = null;
  // }

  constructor(init?:Partial<Usuario>) {
    Object.assign(this, init);
    this.datosPersonales    = new DatosPersonales(this.datosPersonales);
    this.distribuidor       = new Distribuidor(this.distribuidor);
    this.organizacion       = new Organizacion(this.organizacion);
    this.perfilUsuario      = new PerfilUsuario(this.perfilUsuario);
  }
  getVehiculosKey():String[]{
    // console.log('getVehiculosKey',this.vehiculos.map(vehiculo=>vehiculo.key));
    return this.vehiculos?.map(vehiculo=>vehiculo.key);
  }

  isSupervisorTransportista():boolean{
    return this.perfilUsuario.key=='supervisorTransportista';
  }

  getVehiculos():any[]{
    // console.log('getVehiculosKey',this.vehiculos.map(vehiculo=>vehiculo.key));
    return this.vehiculos;
  }
  getKANE():KANE{
      
    let rta:KANE={
      key           : this.key    ? this.key : null,
      apellidoNombre : this.datosPersonales.apellidoNombre ? this.datosPersonales.apellidoNombre : null,
      email:this.datosPersonales.apellidoNombre ? this.datosPersonales.apellidoNombre : null,
  
    };

    return rta;

  }

  getKN():KN{
      
    let rta:KN={
      key    : this.key    ? this.key : null,
      nombre : this.datosPersonales.apellidoNombre ? this.datosPersonales.apellidoNombre : null,
    };

    return rta;

  }

  getUTC():string{
    return this.utc
  }

  setUTC(utc:string){
    this.utc = utc
  }

  getUTCNumber():number{
    return this.utcNumber
  }

  setUTCNumber(utcNumber:number){
    this.utcNumber = utcNumber
  }

  getUTCDefault(bdBaseService : BdBaseService) {  
    if(this?.organizacion == undefined || this?.organizacion?.key == undefined || this?.organizacion?.useUtcFromLocation == false){
        console.warn("El usuario " + this.datosPersonales?.apellidoNombre +" no tiene organización ");
        // this.utcOrganizacionNumber = getOffsetUTCHours();
        this.setUTC(getOffsetUTC());
        this.setUTCNumber(getOffsetUTCHoursWithOffsetUTC(this.getUTC()));
    }else{
        new Organizacion(this.organizacion).getUTC(bdBaseService).then(utc=>{
          console.log('utc Ok', utc);
          this.setUTC(utc);
          this.setUTCNumber(getOffsetUTCHoursWithOffsetUTC(this.getUTC()));
        }).catch(error=>{
          console.error('Error en getUTC', error);
        })
    }
  }

  hasGestionaParadas():boolean{
    return this.organizacion?.esquemaComercial?.gestionaParadas
  }
   
  hasHojaDeRutas():boolean{
    return this.organizacion?.esquemaComercial?.hojaDeRutas
  }

  hasRelevamiento():boolean{
    return this.organizacion?.esquemaComercial?.relevamientoParadas
  }

  hasEncuestas():boolean{
    return this.organizacion?.esquemaComercial?.habilitaEncuestasAlCliente;
  }

  showEncuestasEnRutas():boolean{

    return this.hasOrganizacion()==false || ( this.hasOrganizacion()==true && this.hasEncuestas()==true ) ;
  }

  showSecuenciamientoHereEnRutas():boolean{

    return this.hasOrganizacion()==false 
    // todo: Agregar el resto de la validación cuando se agregue la posibilidad de secuenciar en el esquema comercial
    // || ( this.hasOrganizacion()==true && this.hasSecuenciamientoHere()==true ) ;
  }

  hasOrganizacion():boolean{
    return this.organizacion !=null && this.organizacion !== undefined && Object.keys(this.organizacion).length > 0;; 
  }

  hasEventos():boolean{
    let rta:boolean=true;
    if(this.organizacion!=null){
      rta=this.organizacion?.esquemaComercial?.habilitaEventos;
    }
    return rta
  }

  hasEncuestasAlCliente():boolean{
    let rta:boolean=true;
    if(this.organizacion!=null){
      rta= this.organizacion?.esquemaComercial.habilitaEncuestasAlCliente;
    }
    return rta
  }
  hasFotos():boolean{
    let rta:boolean=true;
    if(this.organizacion!=null){
      rta= this.organizacion?.esquemaComercial.habilitaFotos;
    }
    return rta
  }


  isPerfilOrganizacion():boolean{
    return this.perfilUsuario.tipo=='Organizacion' && this.organizacion.getKANI()!= null;
  }

  getOrganizacionKNAI():KNAI{
    return this.organizacion.getKANI();
  }

  getOrganizacionKey():string{
    return this.organizacion.getKey();
  }

  getOrganizacionSoap():Soap|null{
    return this.organizacion && this.organizacion.soap!==undefined ? this.organizacion.soap : null;
  }

  getDistribuidorKN():KN{
    return this.distribuidor.getKN();
  }

  getTipoPerfil():string{
    return this.perfilUsuario.tipo;
  }


  isDesarrollador():boolean{
    return this.getTipoPerfil()=="Desarrollador";
  }

  

  isSupervisor():boolean{
    return this.getTipoPerfil()=="Supervisor";
  }

  isDistribuidor():boolean{
    return this.getTipoPerfil()=="Distribuidor";
  }

  getArrayAreasNegocio():string[]{
    let areasNegocioIn:string[]=[];

    let sucursalesAreasNegocio = this.sucursalesAreasNegocio;
          for(let i=0; i<sucursalesAreasNegocio.length; i++) {
              let aux = sucursalesAreasNegocio[i].split('@@@');
              let areaNegocio = aux[1];
              areasNegocioIn.push(areaNegocio);
          }
    return  areasNegocioIn;  
  }

  hasAreaNegocioHabilitada(sucursalAreaNegocio:string):boolean{
    let habilitada:boolean=false;
    for(let j=0; j<this.sucursalesAreasNegocio.length;j++) {
      let sucursalAreaNegocioUsuario = this.sucursalesAreasNegocio[j];
      if(sucursalAreaNegocio==sucursalAreaNegocioUsuario) {
          habilitada=true;
          break;
      }
    }
    return habilitada;
  }
  


    /* ------------------------------------------------------------------------------
     FUNCIONES PARA EL MANEJO DE DOCUMENTOS (se diferencia de porque pide interfase de Permisos)
     ------------------------------------------------------------------------------------- */

    getPermisosEdicion( url:string, permisos:PermisosInterface): PermisosInterface {
      console.log('funcionGo','setPermisosEdicion2');
    
      let partesURL = url.split('?');
      // Obtener la URL sin los parámetros
      let urlSinParametros = partesURL[0];

      for (var i in this.perfilUsuario.permisosMenu) {
          let permi=this.perfilUsuario.permisosMenu[i];


        if(permi.routerLink==urlSinParametros){
           permisos.altasAutorizadas                   = permi.alta;
           permisos.bajasAutorizadas                   = permi.baja;
           permisos.modificacionesAutorizadas          = permi.modificacion;
           permisos.modificacionesBasicasAutorizadas   = permi.modificacionBasica;
           
           console.log("permisos:",permisos);

          
           break;
        }
      }

      return permisos;

  }
  
  getUrlBaseDatos():String{
    let url:String='';
    if(['Desarrollador','Distribuidor', 'Supervisor','NoIndicado'].indexOf(this.perfilUsuario.tipo)!=-1){
      // if(PERFIL_DESARROLLADOR_SUPERV_DISTRIBUIDOR.indexOf(this.perfilUsuario.tipo)!=-1){
      url=this.urlBaseDatos.trimLeft().trimRight();
    } else{
      url=this.organizacion.urlBaseDatos.trimLeft().trimRight();
    }  
    
    return url;
  }

  getCantidadLicencias():number{
    return this.organizacion?.esquemaComercial?.cantidadLicencias;
  }

  getPorcentajeExcesoPermitido():number{
    return this.organizacion?.esquemaComercial?.porcentajeExcesoPermitido;
  }


  getIdioma():string{
    return this.datosPersonales?.idioma;
  }
 
}

export class Distribuidor {     
  key                 : string;  
  nombre              : string;
  keywords            : string[];
  email               : string;
  web                 : string;
  idioma              : string ;
  personaDeContacto   : string;

  logoIMG             : IMG;

  direccion           : Direccion;
  telefono            : Telefono ;
  redSocial           : RedSocial;
  settings            : Settings;
  fechaHoraCreacion   : string;
  usuarioCreacion     : string;

  constructor(init?:Partial<Distribuidor>) {
    Object.assign(this, init);
  }
  getKN():KN{
    
    let rta:KN={
      key           : this.key    ? this.key : null,
      nombre        : this.nombre ? this.nombre : null,
  
    };

    return rta;
  }
}

export class EsquemaComercial{
  cantidadLicencias           : number;
  porcentajeExcesoPermitido   : number;  //% de exceso de licencias permitidas
  cantidadVecesExcesoPermitido: number;  //Nro de veces en el mes que puedo excederme de la cantidad de licencias
  licenciasConBloqueo         : boolean; //Bloqueo (Si/NO)

  logAuditoria                : boolean;
  logEstadosCelular           : boolean;  /* Graba o no la tabla RutaEstadosCelulares */
  trackeaRutas                : boolean;  /* Graba o no las tablas RutaMonitorEquipos y RutaTrackeo (Activa o no el GPS) */
  relevamientoParadas         : boolean;  /* Relevamiento de Paradas */
  gestionaParadas             : boolean;  /* Marca cambios de estado de paradas, carga cantidades entregadas, etc */  
  hojaDeRutas                 : boolean;  /* Permite marcar las paradas como IndicacionesSolicitadas, Cancelada, Realizada y mostrar el goggle o waze para ver indicaciones. */  
  trackeaInBackgroundAppClose : boolean;  /* true = trackea, aunque la aplicación se ha cerrado, false=al cerrar la aplicación deja de trackear */
  navegadorWazeGoogle         : boolean;  /* Habilita la posibilidad de abrir estas aplicaciones */
  habilitaFotos               : boolean;  /* Habilita la posibilidad de sacar fotos asociadas a la Parada */
  cantidadMinimaFotoParada    : number;  /* Cantidad Minima obligatoria de fotos a tomar en la parada */ 
  habilitaFirma               : boolean;  /* Habilita la posibilidad de obtener firma en la Parada */
  notificacionEnViaje         : boolean;  /* Habilita la posibilidad de enviar mensaje a cliente indicando que se está en camino */
  notificacionCancelacion     : string;   /* Indica al cliente que se canceló el viaje:  'SS' Sí y pide confirmación al Chofer, 'SN' Sí y envía sin solicitar confirmación, 'N' No Envía */
  estadoEntregaHojaDeRuta     : boolean;  /* Habilita la pregunta en hoja de ruta */
  estimaHoraLlegada           : boolean;  /* La App estima la hora de llegada a la parada */
  habilitaEventos             : boolean;  /* Habilita la posibilidad de los eventos en la organizacion */
  habilitaEncuestasAlCliente  : boolean;  /* Permite realizar encuestas a los clientes de la organizacion */ 
  habilitaLiquidacionBasica   : boolean;  /* Permite realizar liquidacion basica a los clientes de la organizacion */ 
  reportaParametrosDelTelefono: boolean;  /* Reporta Parámetros del teléfono a Firebase */ 
  completarManualmenteTodasLasParadas: boolean;  /* No permite cancelar todas las paradas pendientes de una ruta en forma automática */ 
};

export class Organizacion { 
  key                     : string;
  autoIncrement           : number;
  keywords                : string[];
  nombre                  : string;
  
  nombre_es               : string;
  nombre_en               : string;
  nombre_pt               : string;
  
  email                   : string;
  husoHorario             : string;
  logoIMG                 : IMG;
  personaDeContacto       : string;
  rubro                   : string;
  fechaHoraCreacion       : string;
  usuarioCreacion         : string;
  
  horarioAtencion         : HorarioAtencion;
  
  idiomasHabilitados      : any[];
  idioma                  : string ;

  monedadHabilitadas      : any[];
  monedaDefault           : string;
  
  textoDescripcion_es     : string;
  textoDescripcion_en     : string;
  textoDescripcion_pt     : string;
  
  textoHistoria_es        : string;
  textoHistoria_en        : string;
  textoHistoria_pt        : string;
  
  fotosGaleria            : string[];
  
  ftp: {
    host                  : string;
    port                  : string;
    usuario               : string;
    password              : string;
    modoTransferencia     : string;
  };
  
  esquemaComercial        : EsquemaComercial;
  urlBaseDatos            : string;
  soap                    : Soap;
  distribuidor            : Distribuidor;
  direccion               : Direccion;
  telefono                : Telefono ;
  redSocial               : RedSocial;
  settings                : Settings;

  useUtcFromLocation      : boolean;

  constructor(init?:Partial<Organizacion>) {
    Object.assign(this, init);
  }

  getKANI():KNAI{
    let rta:KNAI={
            key           : this.key    ? this.key : null,
            nombre        : this.nombre ? this.nombre : null,
            // autoIncrement : this.autoIncrement===undefined ? 'MAQUE' : str0(this.autoIncrement,5)
            autoIncrement : this.autoIncrement
        };
   
    return rta;
  }

  getKN():KN{
    let rta:KN={
            key           : this.key    ? this.key : null,
            nombre        : this.nombre ? this.nombre : null,
        };
    return rta;
  }

  getKey():string{
    return this.key;
  }

  getUTC(bdBaseService : BdBaseService):Promise<string> {
    return new Promise((resolve, reject) => {
      
      let where: WhereInterface[] = [{
          key : 'key',
          value : this.key,
          operador : OPERADOR_WHERE.Igual
      }];
      
      let argumentos:ArgumentosLlamarBaseDeDatos = new ArgumentosLlamarBaseDeDatos({
          tipoBaseDatos : TIPO_BASE_DATOS.FIRESTORE,
          nombreColeccion : 'Organizaciones',
          where : where,
          orderBy : null,
          paginadoFirebase : null,
          paginaActualNro : null,
          urlBaseSQL : null
      });
  
      let timeZoneOrganizacion:string = '';
    
      bdBaseService.getPromesaBase(argumentos).then(organizaciones=>{
          timeZoneOrganizacion = organizaciones[0]?.direccion?.timeZone.replace('/', '|').replace('/', '|');
          this.getUTCOrganizacionWithZone(timeZoneOrganizacion,bdBaseService).then(utc=>{
            resolve(utc);
          }).catch(error=>{
            console.error('Error getUTCOrganizacionWithZone ',error);
            reject(error);
          });
      }).catch(error=>{
          console.error('Error al obtener el time zone de la organizacion en getTimeZoneOrganizacion ',error);
          reject(error);
      });
    });
}

//TODO pasar a la clase organizacion
getUTCOrganizacionWithZone(timeZone:string,bdBaseService : BdBaseService):Promise<string> {
    return new Promise((resolve, reject) => {
        let utcOrganizacion:string = '+00:00';
    
        let where: WhereInterface[] = [{
            key : 'key',
            value : timeZone,
            operador : OPERADOR_WHERE.Igual
        }];
        
        let argumentos:ArgumentosLlamarBaseDeDatos = new ArgumentosLlamarBaseDeDatos({
            tipoBaseDatos : TIPO_BASE_DATOS.FIRESTORE,
            nombreColeccion : 'AuxTimeZones',
            where : where,
            orderBy : null,
            paginadoFirebase : null,
            paginaActualNro : null,
            urlBaseSQL : null
        })
        return bdBaseService.getPromesaBase(argumentos).then(timesZone=>{
            // utcOrganizacion =timesZone[0]?.utc1?.replace(':','').replace(':','');
            utcOrganizacion =timesZone[0]?.utc1;
            resolve(utcOrganizacion);
        }).catch(error=>{
            console.error('Error al obtener el utc de la organizacion en getUTCOrganizacion ',error);
            reject(error);
        })
    });
}

  

}

export class OrganizacionesFotos { 
  key                 : string;
  organizacionKey     : string;

  fotoIMG             : IMG;
  tipoFotoKN          : KN;
  orden               : number;  
  
  descripcion_es       : string;
  descripcion_en       : string;
  descripcion_pt       : string;

  fechaHoraDesde      : Date;
  fechaHoraHasta      : Date;

  settings            : Settings;

  constructor(init?:Partial<OrganizacionesFotos>) {
    Object.assign(this, init);
  }
}

export class Sucursal {   
  key                 : string;
  codigo              : string;
  nombre              : string;
  keywords            : string[];
  email               : string;
  direccion           : Direccion;
  telefono            : Telefono;
  organizacionKNAI    : KNAI;
  areasNegocio        : AreaNegocio[];  
  settings            : Settings;
  constructor(init?:Partial<Sucursal>) {
    Object.assign(this, init);
  }

  getKN():KN{
    
    let rta:KN={
      key           : this.key    ? this.key : null,
      nombre        : this.nombre ? this.nombre : null,
    };

    return rta;
  }
}

export class AreaNegocio {   
  key                   : string;
  codigo                : string;
  nombre                : string;
  keywords              : string[];
  organizacionKNAI      : KN;
  iconoRutas            : string;
  nombresUnidadesMedida :{
    unidad1             : string;
    unidad2             : string;
    unidad3             : string;
  };
  customUsuarioUbicacion :{
    label1              : string;
    label2              : string;
    label3              : string;
    label4              : string;
    label5              : string;
    label6              : string;
  };  
  customUsuarioOrden :{
    label1              : string;
    label2              : string;
    label3              : string;
    label4              : string;
    label5              : string;
    label6              : string;
  };  

  transportType: string;  // export enum TRANSPORT_TYPE {   'car', 'truck', 'bicycle', 'scooter', 'taxi', 'bus' , 'pedestrian'};
  
//  Transport types are shortest and fastest.
  transportOptimitionRoute: string; //  export enum TRANSPORT_OPTIMIZATION_ROUTE { 'shortest' , 'fastest' };
  
  //  TrafficMode can be enabled or disabled.
  traficMode:string;   //  export enum TRAFFIC_MODE { 'enabled' , 'disabled' };
  
  routeImpoveFor :string;   //  IMPROVE_FOR    export enum IMPROVE_FOR { 'distance' , 'time' };


  settings              : Settings;
  constructor(init?:Partial<AreaNegocio>) {
    Object.assign(this, init);
  }
  getKN():KN{
    
    let rta:KN={
      key           : this.key    ? this.key : null,
      nombre        : this.nombre ? this.nombre : null,
    };

    return rta;
  }

  getTrasportType():string{
    return this.transportType;
  }

  getTransportOptimitionRoute():string{
    return this.transportOptimitionRoute;
  }

  getTraficMode():string{
    return this.traficMode;
  }

  getRouteImpoveFor():string{
    return this.routeImpoveFor;
  }

}

export class SucursalesAreasNegocio {
  sucursal          : Sucursal;
  areaNegocio       : AreaNegocio;
  organizacionKNAI  : KNAI;
  constructor(init?:Partial<SucursalesAreasNegocio>) {
    Object.assign(this, init);
  }
}

export interface PermisosInterface{
  altasAutorizadas                    : boolean;
  bajasAutorizadas                    : boolean;
  modificacionesAutorizadas           : boolean;
  modificacionesBasicasAutorizadas    : boolean;
  deshabilitarGrabar                  : boolean;

}