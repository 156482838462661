import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';
import {IfStmt} from '@angular/compiler';
import { typeOf } from 'mathjs';
import { convertDateFromStringInDate } from '@settings/maqueta/helpers/fechaHoraHelp';

@Pipe({
  name: 'date'
})

 export class DatePipe implements PipeTransform {
    transform(
        value: any,
        mask:string,       /* DD/MM/AAAA, DD/MM/AAAA HH:MM, DD/MM/AAAA HH:MM hs, HH:MM, HH:MM hs, ... */
        language: string,  /* es, en */
        timezone: string /* +0000, -0600*/
    ): string | null {
        // console.log("zzz fechaHoraPipe",value, language);
        let rta='';
        if(value==null || value===undefined) {
          //console.log("fechaHoraPipe Null");
          rta='';
        } else {

          if(mask===undefined)     mask='DD/MM/AAAA';
          if(language===undefined) language='es';

          let isEmptyJson = (Object.entries(value).length === 0 && value.constructor === Object);

          if(isEmptyJson) {
              // console.error("fechaHoraPipe {} !!!", value);
              rta='';
          } else {

              let fecha;

              let typeMillis = typeof value.toMillis;
              let isTimestamp = (typeMillis == "function");

              // console.log("zzz fechaHoraPipe value " , value);

              let formatoFechaHora=Object.prototype.toString.call(value);
              // console.log("zzz fechaHoraPipe type ",formatoFechaHora);
              // console.log("zzz fechaHoraPipe value.length  ",value.length);

              // 2024-01-04T09:00:00.000Z
              if(value=='12/29/2023'){
                console.log("zzz language ",language);
              }
              if(formatoFechaHora=="[object String]") { // Wed Feb 05 2020 17:32:13 GMT-0300 (hora estándar de Argentina) || 12:00:00:0000 || 12:00:00 || 08/02/2023

                  if((value.length==12 || value.length==8) && (value.split(':').length==3 || value.split(':').length==4)) { // 12:00:00:0000 || 12:00:00
                    fecha = new Date();
                    const [horas, minutos, segundos] = value.split(":").map(Number);
                    fecha.setHours(horas);
                    fecha.setMinutes(minutos);
                    fecha.setSeconds(segundos);
                    let desplazamiento:number = fecha.getTimezoneOffset();
                    fecha.setMinutes(fecha.getMinutes() - desplazamiento); //saco el desplazamiento
                  }else if((value.length==24 && value.split(' ').length > 1)) { //este formato tambien tiene un length de 24 2023-10-22T16:01:09.000Z por eso hago el split para asegurarme que venga una fecha con este formato '01/30/2023 00:00:00'
                    fecha = convertDateFromStringInDate(value, language, true);
                  }else if((value.length==10 && value.split('/').length == 3)) { //este formato tambien tiene un length de 10 Por ejemplo: 2023/10/22
                    fecha = convertDateFromStringInDate(value, language, true);
                  }else{
                    fecha=new Date(value);
                  }
              } else if(isTimestamp) { // // Timestamp { seconds: 1580936640, nanoseconds: 0 }
                  // console.error("fechahoraPipe recibió timestamp !!!",value, typeMillis);
                  let milisegundos = value.toMillis();
                  fecha = new Date(milisegundos);
              } else if(formatoFechaHora=="[object Date]") {     // Ya vino en formato fecha
                  fecha=value;
              } else {
                  // otro formato
                  fecha=value;
              }

              /* DD/MM/AAAA, DD/MM/AAAA HH:MM, DD/MM/AAAA HH:MM hs, HH:MM, HH:MM hs, ... */
              if(mask=='DD/MM/AAAA HH:MM hs') {
                  if(language=='en') {
                    rta=formatDate(fecha, 'mm/dd/yyyy HH:mm', 'en', timezone)+" hs";
                  } else {
                    rta=formatDate(fecha, 'dd/MM/yyyy HH:mm', 'es',timezone)+" hs";
                  }
              } else if(mask=='DD/MM/AAAA HH:MM') {
                  if(language=='en') {
                    rta=formatDate(fecha, 'mm/dd/yyyy HH:mm', 'en', timezone);
                  } else {
                    rta=formatDate(fecha, 'dd/MM/yyyy HH:mm', 'es', timezone);
                  }
              } else if(mask=='DD/MM/AAAA HH:MM:SS') {
                    if(language=='en') {
                      rta=formatDate(fecha, 'mm/dd/yyyy HH:mm:ss', 'en', timezone);
                    } else {
                      rta=formatDate(fecha, 'dd/MM/yyyy HH:mm:ss', 'es', timezone);
                    }
              } else if(mask=='DD/MM/AAAA') {
                  if(language=='en') {
                    rta=formatDate(fecha, 'mm/dd/yyyy', 'en', timezone);
                  } else {
                    rta=formatDate(fecha, 'dd/MM/yyyy', 'es', timezone);
                  }
              } else if(mask=='HH:MM hs') {
                  rta=formatDate(fecha, 'HH:mm', language, timezone)+" hs";
                 
              } else if(mask=='HH:MM') {
                  rta=formatDate(fecha, 'HH:mm', language, timezone);
              } else if(mask=='HH:MM:SS') {
                  rta=formatDate(fecha, 'HH:mm:ss', language,timezone);
              }
          }
        }
        return rta;
    }
}
