import { log, logIf, logTable, values } from '@maq-console';

import { Component, ElementRef, Inject, OnInit, DoCheck, Input, Output, ViewEncapsulation, EventEmitter } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { FuncionesService } from '@maq-funciones';
import { TranslateService } from '@ngx-translate/core';

import { FormGroup, FormControl, FormBuilder, Validators, FormsModule} from '@angular/forms';
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';

import { LISTA_DE_PAISES } from '@maq-mocks/usuarios/usuarios';
import { LISTA_DE_PROVINCIAS, getProvinciasPorPais, getProvinciasPorPaisCombinadas } from '@maq-mocks/usuarios/usuarios';

@Component({
  selector: 'lib-shared-form-zonas',
  templateUrl: './form-zonas.component.html',
  styleUrls: [ './form-zonas.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class FormZonasComponent implements OnInit, DoCheck {
  
    @Input() public paisesDeOperacion: string; 
    @Input() public provinciasDeOperacion: string; 
    @Input() public accionForm: string; 
    @Input() public formInvalid: boolean; 

    @Output() cargoZona= new EventEmitter<any>();

    public traduccion:any={};
    public form:FormGroup; 
    public accionProvincias:string='listado';

    public LISTA_DE_PAISES = LISTA_DE_PAISES;  
    public LISTA_DE_PROVINCIAS = LISTA_DE_PROVINCIAS;
    public getProvinciasPorPais = getProvinciasPorPais;
    public LISTA_DE_PROVINCIAS2 = getProvinciasPorPais(null);
    public LISTA_DE_PROVINCIAS_PAISES = getProvinciasPorPaisCombinadas();
   
    constructor(public fn:FuncionesService,
                private translate: TranslateService,
                public fb:FormBuilder, 
                @Inject(DOCUMENT) document) { 

    }

  ngOnInit() {

    this.inicializarVariablesTraducibles();

    this.translate.onLangChange.subscribe(() => {
        this.inicializarVariablesTraducibles();
    });

    // log(...values("valores","form-zonas paisesDeOperacion:",this.paisesDeOperacion));
    // log(...values("valores","form-zonas provinciasDeOperacion:",this.provinciasDeOperacion));
    // log(...values("valores","form-zonas accionForm:",this.accionForm));

    // Defino Formulario
    this.form = this.fb.group({
            paisesDeOperacion     : 'Array',
            provinciasDeOperacion : ['Array', Validators.compose([Validators.required])], 
    });

    this.form.controls['paisesDeOperacion'].setValue(this.paisesDeOperacion);
    this.form.controls['provinciasDeOperacion'].setValue(this.provinciasDeOperacion);

  }

  ngDoCheck() {

      if(this.accionForm=='consultar') {
        this.form.disable();
      } else {
        this.form.enable();
      }         
      
      if(this.formInvalid) {
        this.form.markAllAsTouched();
      }
        
  }
  
  inicializarVariablesTraducibles(){

      this.traduccion['claseRegiones']={
          regionesDondeOpera:    this.translate.instant('claseRegiones.regionesDondeOpera')
      }   
      this.traduccion['claseDireccion']={
        provincia:               this.translate.instant('claseDireccion.provincia'),
        pais:                    this.translate.instant('claseDireccion.pais'),
      }  
      this.traduccion['claseValidaciones']={
        esRequerido:    this.translate.instant('claseValidaciones.esRequerido')
      }   
            
      this.traduccion['NoIndicada']=this.translate.instant('No Indicada');
      this.traduccion['NoIndicado']=this.translate.instant('No Indicado');

      this.traduccion['navegabilidad']={
        editar:                 this.translate.instant('navegabilidad.editar'),
        cancelar:               this.translate.instant('navegabilidad.cancelar'),
        agregar:                this.translate.instant('navegabilidad.agregar'),
        grabar:                 this.translate.instant('navegabilidad.grabar')
      }
  }
  
  provinciaNoIncluida(idProvincia:string) {
    let vecProvincias =  this.form.get('provinciasDeOperacion').value;
    for(let i=0;i<vecProvincias.length;i++) {
       if(vecProvincias[i]==idProvincia) {
          return false;
       }
    }
    return true;   

  }
  
  getPaisFromProvincia(idProvincia:string) {
      for(let z=0; z<this.LISTA_DE_PROVINCIAS.length; z++) {
          if(idProvincia==this.LISTA_DE_PROVINCIAS[z].id) {
              return this.LISTA_DE_PROVINCIAS[z].idPais;
          }
      }  
      return '';
  }  
  
  agregarProvincia() {
      let idNuevaProvincia=(<HTMLSelectElement>document.getElementById("idNuevaProvincia")).value;
      console.log("agregarProvincia idNuevaProvincia",idNuevaProvincia);

      let vecProvincias =  this.form.get('provinciasDeOperacion').value;
      if(vecProvincias==null) vecProvincias=[];
      if(vecProvincias.indexOf(idNuevaProvincia)==-1) {
        vecProvincias.push(idNuevaProvincia);
      }
      this.form.get("provinciasDeOperacion").setValue(vecProvincias);
      console.log("agregarProvincia form.get(provinciasDeOperacion).value",this.form.get('provinciasDeOperacion').value);
      this.accionProvincias='listado';
      this.actualizoVecPaises();
  }

  deleteProvincia(idProvincia:string) {
      let vecProvincias = this.form.get('provinciasDeOperacion').value;
      let pos=vecProvincias.indexOf(idProvincia);
      vecProvincias.splice(pos, 1);
      this.form.get("provinciasDeOperacion").setValue(vecProvincias);
      console.log("deleteProvincia",this.form.get('provinciasDeOperacion').value);
      this.actualizoVecPaises();
  }
  
  actualizoVecPaises() {
      let vecPaisesDeOperacion = [];
      let vecProvincias =  this.form.get('provinciasDeOperacion').value;
      if(vecProvincias==null) vecProvincias=[];
      for(let i=0; i<vecProvincias.length; i++) {
          let idProvincia=vecProvincias[i];
          for(let z=0; z<this.LISTA_DE_PROVINCIAS.length; z++) {
              if(idProvincia==this.LISTA_DE_PROVINCIAS[z].id) {
                 let idPais=this.LISTA_DE_PROVINCIAS[z].idPais;
                 if(vecPaisesDeOperacion.indexOf(idPais)==-1) {
                    vecPaisesDeOperacion.push(idPais);
                 }
              }
          }  
      }    
      this.form.get("paisesDeOperacion").setValue(vecPaisesDeOperacion);
      console.log("actualizoVecPaises",this.form.get('paisesDeOperacion').value);
      
      this.cargoZona.emit({
        paisesDeOperacion: this.form.get('paisesDeOperacion').value,
        provinciasDeOperacion: this.form.get('provinciasDeOperacion').value,
      });    
    
  }

}  
  
