import { log, logIf, logTable, values } from '@maq-console';
import { Component, OnInit, DoCheck, Input, Output, ViewEncapsulation, EventEmitter } from '@angular/core';

import { FuncionesService } from '@maq-funciones';
import { TranslateService } from '@ngx-translate/core';

import { FormGroup, FormControl, FormBuilder, Validators, FormsModule} from '@angular/forms';

import { LISTA_DE_TIPOS_DE_TELEFONOS } from '@maq-mocks/usuarios/usuarios';

@Component({
  selector: 'lib-shared-form-telefono',
  templateUrl: './form-telefono.component.html',
  styleUrls: [ './form-telefono.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class FormTelefonoComponent implements OnInit, DoCheck {

    @Input() public nameField: string;
    @Input() public controlsField: any;
    @Input() public value: any;
    @Input() public formInvalid: boolean;
    @Input() public accionForm: string;

    @Output() cargoSubEstructura= new EventEmitter<any>();

    public form:FormGroup;
    public traduccion:any={};

    public LISTA_DE_TIPOS_DE_TELEFONOS = LISTA_DE_TIPOS_DE_TELEFONOS;

    constructor(public fn:FuncionesService,
                private translate: TranslateService,
                public fb:FormBuilder, ) {

    }

  ngOnInit() {

    log(...values("valores","form-telefono nameField:",this.nameField));
    log(...values("valores","form-telefono controlsField:",this.controlsField));
    log(...values("valores","form-telefono value:",this.value));

    this.inicializarVariablesTraducibles();
    this.translate.onLangChange.subscribe(() => {
       this.inicializarVariablesTraducibles();
    });

    // Defino Formulario
    this.form = this.fb.group({
        telefono: this.fb.group({
            tipoTelefono1   : new FormControl(null, Validators.compose([Validators.required])),
            numeroTelefono1 : [null, Validators.compose([Validators.required])],
            tipoTelefono2   : null,
            numeroTelefono2 : null,
            tipoTelefono3   : null,
            numeroTelefono3 : null,
            tipoTelefono4   : null,
            numeroTelefono4 : null,
        }),
    });

    this.form.controls['telefono'].setValue(this.value);

  }

  inicializarVariablesTraducibles(){

      this.traduccion['claseValidaciones']={
        esRequerido:             this.translate.instant('claseValidaciones.esRequerido'),
      }
      this.traduccion['claseTabs']={
        telefono:                 this.translate.instant('claseTabs.telefono'),
      }
      this.traduccion['claseTelefono']={
        tipoTelefono:             this.translate.instant('claseTelefono.tipoTelefono'),
        numeroTelefono:           this.translate.instant('claseTelefono.numeroTelefono'),
      }


      for(let i=0; i<this.LISTA_DE_TIPOS_DE_TELEFONOS.length; i++) {
          this.LISTA_DE_TIPOS_DE_TELEFONOS[i].val = this.translate.instant( this.LISTA_DE_TIPOS_DE_TELEFONOS[i].val );
      }
  }

  ngDoCheck() {

      if(this.accionForm=='consultar') {
        this.form.disable();
        
      } else {
        
        if(this.controlsField['status']!='DISABLED') {        
            this.form.enable();
            
            this.form.controls['telefono']['controls']['tipoTelefono1'].setValidators(null);
            this.form.controls['telefono']['controls']['numeroTelefono1'].setValidators(null);

            if(this.controlsField['controls']['tipoTelefono1']['validator'])      this.form.controls['telefono']['controls']['tipoTelefono1'].setValidators([Validators.required]);  
            if(this.controlsField['controls']['numeroTelefono1']['validator'])     this.form.controls['telefono']['controls']['numeroTelefono1'].setValidators([Validators.required]);  
            
        } else {
            this.form.disable();
        } 
      } 
      

      if(this.formInvalid) {
        this.form.markAllAsTouched();
      }
  }

  onChangeField() {

      log(...values('valores','onChangeField - event:',event));

      let valueSubestructura = this.form.get('telefono').value;

      this.cargoSubEstructura.emit({
        nameField : this.nameField,
        value    :  valueSubestructura
      });

  }

}

