import { Pipe, PipeTransform } from '@angular/core';
import { FuncionesService }  from '@maq-funciones';

@Pipe({ 
  name: 'RangoFechasSearchPipeBase', 
  pure: false 
})
export class RangoFechasSearchPipeBase implements PipeTransform {
    constructor(public fn:FuncionesService) { 
    }   
    transform(values, fieldName, filtroFechas:Date[]): Array<any> {

        let showConsole=false;
        if(showConsole) console.log("RangoFechasSearchPipeBase", values, fieldName, filtroFechas);

        let valorGrabado:Date;


        if (values) {
            
            return values.filter(valor => {

                // existe el array para comprar
                if(!filtroFechas || filtroFechas?.length!=2 ) {

                    return true;
                } else {    
                    if(fieldName.indexOf('.')==-1) {
                    valorGrabado=valor[fieldName];  
                    } else {
                    let partes = fieldName.split('.');
                    // console.log("valor",valor);
                    if((valor[ partes[0] ]!==undefined && valor[ partes[0] ]) && 
                        (valor[ partes[0] ][ partes[1] ]!==undefined && valor[ partes[0] ][ partes[1] ]) &&
                        (valor[ partes[0] ][ partes[1] ][ partes[2] ]!==undefined && valor[ partes[0] ][ partes[1] ][ partes[2] ]) ) {
                            
                        valorGrabado=valor[ partes[0] ][ partes[1] ][ partes[2] ];
                        
                    } else if((valor[ partes[0] ]!==undefined && valor[ partes[0] ]) && 
                        (valor[ partes[0] ][ partes[1] ]!==undefined && valor[ partes[0] ][ partes[1] ])  ) {
                            
                        valorGrabado=valor[ partes[0] ][ partes[1] ];
                    } else {
                        valorGrabado=null;
                    }
                    }

                    // el valor a evaluar NO es una fecha
                    if (!(valorGrabado instanceof Date))  return false ;
                    let desde:Date=filtroFechas[0];
                    let hasta:Date=filtroFechas[1];
                    if(showConsole) console.log("RangoFechasSearchPipeBase desde",desde);
                    if(showConsole) console.log("RangoFechasSearchPipeBase hasta",hasta);
                    if(valorGrabado >= desde && valorGrabado <= hasta) {
                        if(showConsole) console.log("RangoFechasSearchPipeBase !filtroFechas","c");
                        return true;
                    } else{
                        if(showConsole) console.log("RangoFechasSearchPipeBase !filtroFechas","d");
                        return false;
                    }
                    
                }    

            });
        }
    }
}