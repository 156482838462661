import { Pipe, PipeTransform } from '@angular/core';
import { GRILLA_PAGINADO_TIPO } from '@maq-models/grilla/grillaBase.model';
import { Observable, Observer,of } from 'rxjs';

import { log, logIf, logTable, values } from '@maq-console';



@Pipe({
  name: 'notificacionesFiltroBase',
  pure: false
})
export class NotificacionesFiltroBase<T> implements PipeTransform {
  // constructor(){}
  // paginadoTipo:GRILLA_PAGINADO_TIPO

  listadoReferencia:T[]=[];
  
  transform(listado:T[],claveDeBusqueda:string,pipeObserver: Observer<any>): T[] {

    let agregados: T[]=getElementosDeArray1NoEstanArray2(listado,this.listadoReferencia,claveDeBusqueda);
    let eliminados: T[] =getElementosDeArray1NoEstanArray2(this.listadoReferencia,listado,claveDeBusqueda);

    agregados.map(elementoAgregado=>{
      this.listadoReferencia.push(elementoAgregado);
      let rtaPipe:NotificacionFiltro={accion:NOTIFICACION_FILTRO.AGREGADO, elemento:  elementoAgregado };
      pipeObserver ? pipeObserver.next(rtaPipe):'';
    })
  

    eliminados.map(elementoEliminado=>{
      for(let i=this.listadoReferencia.length-1; i>=0; i--) {
        let elementoReferencia    = this.listadoReferencia[i];
        let datoElementoEliminado = getDatoAlmacendadoEnCampoBusqueda1(elementoEliminado,claveDeBusqueda);
        let datoReferencia        = getDatoAlmacendadoEnCampoBusqueda1(elementoReferencia,claveDeBusqueda);
        
        if(datoElementoEliminado==datoReferencia) {
            this.listadoReferencia.splice(i,1);
            let rtaPipe:NotificacionFiltro   = {accion:NOTIFICACION_FILTRO.ELIMINADO, elemento:  elementoEliminado };
            pipeObserver ? pipeObserver.next(rtaPipe)   : '';
        }
      }
  
    });
   
    return listado;
  }
}

export function getElementosDeArray1NoEstanArray2(array1, array2,claveDeBusqueda:string) {
  return array1.filter(object1 => {
    return !array2.some(object2 => {
      let dato1=getDatoAlmacendadoEnCampoBusqueda1(object1,claveDeBusqueda);
      let dato2=getDatoAlmacendadoEnCampoBusqueda1(object2,claveDeBusqueda);
      return dato1 == dato2;
    });
  })
} 

export function getDatoAlmacendadoEnCampoBusqueda1(documento:any, claveDeBusqueda:string):any {

  let valorGrabado:any;

  if(claveDeBusqueda.indexOf('.')==-1) {
    valorGrabado=documento[claveDeBusqueda];
  } else {

    let partes = claveDeBusqueda.split('.');
    if((documento[ partes[0] ]!==undefined && documento[ partes[0] ]) && 
      (documento[ partes[0] ][ partes[1] ]!==undefined && documento[ partes[0] ][ partes[1] ]) &&
      (documento[ partes[0] ][ partes[1] ][ partes[2] ]!==undefined && documento[ partes[0] ][ partes[1] ][ partes[2] ]) ) {
        
      valorGrabado=documento[ partes[0] ][ partes[1] ][ partes[2] ];
      
    } else if((documento[ partes[0] ]!==undefined && documento[ partes[0] ]) && 
      (documento[ partes[0] ][ partes[1] ]!==undefined && documento[ partes[0] ][ partes[1] ])  ) {
        
      valorGrabado=documento[ partes[0] ][ partes[1] ];
    } else {
      valorGrabado=null;
    }
  }
  //console.log("searchFiltro valor",valorGrabado, valor);

  return valorGrabado;
}

export enum NOTIFICACION_FILTRO {
  "AGREGADO"  =  "AGREGADO",
  "ELIMINADO" = "ELIMINADO"
}

export interface NotificacionFiltro {accion:NOTIFICACION_FILTRO; elemento:  any;}