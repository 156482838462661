import { AngularFireAuth } from '@angular/fire/auth';
import { take } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { Injectable, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { Menu } from '@maq-models/menu/menu.model';
// import { verticalMenuItems } from '@maq-mocks/menu/menu';
// import { horizontalMenuItems } from '@maq-mocks/menu/menu';

import { TranslateService } from '@ngx-translate/core';
import { MensajesService }  from '@maq-servicios/mensajes/mensajes.service';
import { trimTrailingNulls } from '@angular/compiler/src/render3/view/util';
import { type } from 'os';
//import {}

import { createElementHTML,crearElementoA,crearElementoI } from '@maq-helpers/menuHelp';
import { AlertService } from '../alert/alert-service';
import { ToastrService } from 'ngx-toastr';



@Injectable()
export class MenuService {

  private arrayMenuItems: Array<Menu>=[];

  constructor(private location:Location, 
              private renderer2:Renderer2,
              private router:Router,
              private translate: TranslateService,
              private toastrService: ToastrService,
              ){ 

  } 

  public getArrayMenuItems():Array<Menu> {
     return this.arrayMenuItems;
  }

  public createMenu(listadoDeMenuPermitidos:Array<Menu>, nativeElement, type:String){    
    //setRenderer2(this.renderer2);
    if(type=='vertical'){ 
       this.createVerticalMenu(listadoDeMenuPermitidos, nativeElement);
     }
     if(type=='horizontal'){
       this.createHorizontalMenu(listadoDeMenuPermitidos, nativeElement);
     }
  }

  public createVerticalMenu(listadoDeMenuPermitidos:Array<Menu>, nativeElement){  
    //console.log('createVerticalMenu',menu) ; 
    let div0 = this.renderer2.createElement('div');
    this.renderer2.setAttribute(div0, 'id', 'menu0');
    listadoDeMenuPermitidos.forEach((menuItem:Menu) => {
        if(menuItem.isNodeParent()==true){ //si es un menu padre => creo los menu hijos
          let subMenu = this.createVerticalMenuItem(listadoDeMenuPermitidos, menuItem);
          this.renderer2.appendChild(div0, subMenu);
        }
    });
    this.renderer2.appendChild(nativeElement, div0);       
  }

  public createHorizontalMenu(menu:Array<Menu>, nativeElement){

    let div = this.renderer2.createElement('div');
    this.renderer2.setAttribute(div, 'id', 'navigation');
    let ul = this.renderer2.createElement('ul');
    this.renderer2.addClass(ul, 'menu');
    this.renderer2.appendChild(div, ul);
    menu.forEach((menuItem:Menu) => {
        if(menuItem.isNodeParent()==true){
          let subMenu = this.createHorizontalMenuItem(menu, menuItem);
          this.renderer2.appendChild(ul, subMenu);
        }
    });
    this.renderer2.appendChild(nativeElement, div); 
  }
  
  public generarMenu(menu_wrapper:any, menuType:string, listaDeMenuesPermitidos:Menu[]){
    this.createMenu(listaDeMenuesPermitidos, menu_wrapper, menuType);
    this.showActiveSubMenu(listaDeMenuesPermitidos);
    let activeLink1 = this.getActiveLink(listaDeMenuesPermitidos);
    this.setActiveLink(listaDeMenuesPermitidos, activeLink1); 
  }

  public createVerticalMenuItem(menu:Array<Menu>, menuItem:Menu):any{
    // console.log('createVerticalMenuItem',menu);

    // Código html EN REEMPLAZO de renderer2
    let div = createElementHTML(this.renderer2,'div','card','menu'+menuItem.id);
    let elementoA = crearElementoA(this.renderer2,menuItem,'vertical');
    let icon= crearElementoI(this.renderer2,menuItem,'vertical');
    
    let span = createElementHTML(this.renderer2,'span', 'menu-title','menuTranslateVertical'+menuItem.id)
    let tituloTraducido = this.translate.instant(menuItem.title);
    let menuText = this.renderer2.createText(tituloTraducido);
  
    this.renderer2.appendChild(elementoA, icon);
    this.renderer2.appendChild(elementoA, span);
    this.renderer2.appendChild(span, menuText);
    this.renderer2.appendChild(div, elementoA);

    if(menuItem.routerLink){             
      this.renderer2.listen(elementoA, "click", (event: any) => { 
        //si presiono el ctrl + click abro una nueva ventana
        if (event.ctrlKey==true){
          if(localStorage.getItem('urlCtrlClickPresionado')=='true'){
            this.toastrService.error(this.translate.instant('ctrlClick.esperarTitulo'), this.translate.instant('ctrlClick.esperarMensaje'),{
              timeOut: 2000,positionClass:'toast-center-center'});    
          }else{
            this.renderer2.setAttribute(elementoA, 'href', menuItem.routerLink);
            localStorage.setItem('urlCtrlClick', menuItem.routerLink);
            localStorage.setItem('urlCtrlClickPresionado', 'true')
            setTimeout(() => {  //saco el atributo por si luego se presiona click sobre el elementoA del HTML y no recargue la pagina de 0, sino solo la vista a traves del router.
              this.renderer2.removeAttribute(elementoA, 'href')
            }, 1000); 
          }
        }
        else{
          this.router.navigate([menuItem.routerLink]);
          this.setActiveLink(menu,elementoA);
        }
        this.closeOtherSubMenus(div);
      });
    }
    
    if(menuItem.hasSubMenu){
      let bold = createElementHTML(this.renderer2,'b', 'fa')
      this.renderer2.addClass(bold, 'fa-angle-up');
      this.renderer2.appendChild(elementoA, bold);

      let divCollapse = createElementHTML(this.renderer2,'div', 'collapse', 'collapse'+menuItem.id)
      this.renderer2.setAttribute(divCollapse, 'data-parent', '#menu'+menuItem.parentId);
      this.renderer2.appendChild(div, divCollapse);          
      // console.log("render2",this.renderer2);
      this.createSubMenu(menu, menuItem.id, divCollapse, 'vertical');
    }
    // console.log('menu',div);
    return div;
  }

  //hoy en dia se utilza para crear los SUB menu (o menu hijos) del menu horizontal
  public createHorizontalMenuItem(menu:Array<Menu>, menuItem):any{
      let li = createElementHTML(this.renderer2,'li','menu-item','menuHorizontal'+menuItem.id);

      let elementoA = crearElementoA(this.renderer2,menuItem,'horizontal');
      let icon = crearElementoI(this.renderer2,menuItem,'horizontal');
      let span = createElementHTML(this.renderer2,'span','menu-title','menuTranslateHorizontal'+menuItem.id)
      let tituloTraducido = this.translate.instant(menuItem.title);
      let menuText = this.renderer2.createText(tituloTraducido);

      this.renderer2.appendChild(elementoA, icon);
      this.renderer2.appendChild(elementoA, span);
      this.renderer2.appendChild(span, menuText);
      this.renderer2.appendChild(li, elementoA);

      if(menuItem.routerLink){             
        this.renderer2.listen(elementoA, "click", (event: any) => { 
            //si presiono el ctrl + click abro una nueva ventana
            if (event.ctrlKey==true){
              if(localStorage.getItem('urlCtrlClickPresionado')=='true'){
                this.toastrService.error(this.translate.instant('ctrlClick.esperarTitulo'), this.translate.instant('ctrlClick.esperarMensaje'),{
                  timeOut: 2000,positionClass:'toast-center-center'});    
              }else{
                this.renderer2.setAttribute(elementoA, 'href', menuItem.routerLink);
                localStorage.setItem('urlCtrlClick', menuItem.routerLink);
                localStorage.setItem('urlCtrlClickPresionado', 'true')
                setTimeout(() => {  //saco el atributo por si luego se presiona click sobre el elementoA del HTML y no recargue la pagina de 0, sino solo la vista a traves del router.
                  this.renderer2.removeAttribute(elementoA, 'href')
                }, 1000); 
              }
            }
            else{
              this.router.navigate([menuItem.routerLink]);
              this.setActiveLink(menu,elementoA);
            }
            this.closeOtherSubMenus(li);
        });
      }
      
      if(menuItem.hasSubMenu){
        this.renderer2.addClass(li, 'menu-item-has-children');
        let subMenu = this.renderer2.createElement('ul');
        this.renderer2.addClass(subMenu, 'sub-menu');
        this.renderer2.appendChild(li, subMenu);
        this.createSubMenu(menu, menuItem.id, subMenu, 'horizontal');
      }
      return li;
  }

  private createSubMenu(menu:Array<Menu>, menuItemId, parentElement, type){
      let menus = menu.filter(item => item.parentId === menuItemId); //obtengo los menu HIJOS
      menus.forEach((menuItem) => { //por cada hijo creo un submenu
        let subMenu = null;
        if(type=='vertical'){
           subMenu = this.createVerticalMenuItem(menu, menuItem);
        }
        if(type=='horizontal'){
           subMenu = this.createHorizontalMenuItem(menu, menuItem);
        }      
        this.renderer2.appendChild(parentElement, subMenu);
      });
  } 

  private closeOtherSubMenus(elem){
      let children = (this.renderer2.parentNode(elem)).children;
      for (let i = 0; i < children.length; i++) {
          let child = this.renderer2.nextSibling(children[i].children[0]);
          if(child){
              this.renderer2.addClass(children[i].children[0], 'collapsed');   
              this.renderer2.removeClass(child, 'show');               
          }
      }
  }

  public getActiveLink(menu:Array<Menu>){

      if(menu===undefined) return;

      let url = this.location.path();
      let routerLink = (url) ? url : '/';  // url.substring(1, url.length);
      let activeMenuItem = menu.filter(item => item.routerLink === routerLink);
      if(activeMenuItem[0]){
        let activeLink = document.querySelector("#link"+activeMenuItem[0].id);
        return activeLink;
      }
      return false;
  }

  public setActiveLink(menu:Array<Menu>, link){
      if(link){
          menu.forEach((menuItem) => {
            let activeLink = document.querySelector("#link"+menuItem.id);
            if(activeLink){
              if(activeLink.classList.contains('active-link')){
                activeLink.classList.remove('active-link');
              }
            }       
          });
          this.renderer2.addClass(link, 'active-link'); 
      }    
  } 

  public showActiveSubMenu(menu:Array<Menu>){
      // console.log("showActiveSubMenu",menu);

      if(menu===undefined) return;

      let url = this.location.path();
      let routerLink = url; //url.substring(1, url.length);
      let activeMenuItem = menu.filter(item => item.routerLink === routerLink);
      // console.log('activeLink activeMenuItem',activeMenuItem);
      if(activeMenuItem[0]){
          let activeLink = document.querySelector("#link"+activeMenuItem[0].id); 
          // console.log('activeLink',activeLink)     ;
          // console.log('activeLink render2',this.renderer2)     ;
          let parent = this.renderer2.parentNode(activeLink);
          while (this.renderer2.parentNode(parent)){         
              parent = this.renderer2.parentNode(parent);
              if(parent.className == 'collapse'){
                let parentMenu = menu.filter(item => item.id === activeMenuItem[0].parentId);           
                let activeParentLink = document.querySelector("#link"+parentMenu[0].id);
                this.renderer2.removeClass(activeParentLink, 'collapsed');
                this.renderer2.addClass(parent, 'show');                
              }
              if(parent.classList.contains('menu-wrapper')){
                break;
              }
          }
      }     
  }

  public addNewMenuItem(menu:Array<Menu>, newMenuItem:Menu, type){
      let newMenu:Menu = new Menu(newMenuItem);
      //menu.push(newMenuItem);
      menu.push(newMenu);
      if(newMenuItem.parentId != 0){
        let parentMenu =  menu.filter(item => item.id === newMenuItem.parentId);
        if(parentMenu.length){
            if(!parentMenu[0].hasSubMenu){
              parentMenu[0].hasSubMenu = true;
            // parentMenu[0].routerLink = null;
          } 
        }                
      }
      let menu_wrapper = null;
      if(type=='vertical'){
        menu_wrapper = document.getElementById('vertical-menu');
        console.log ('addNewMenuItem log de ',menu_wrapper===newMenu);
      }
      if(type=='horizontal'){
        console.log ('addNewMenuItem log de ',menu_wrapper===newMenu);
        menu_wrapper = document.getElementById('horizontal-menu');
      }
      while (menu_wrapper.firstChild) {
          menu_wrapper.removeChild(menu_wrapper.firstChild);
      }      
      this.createMenu(menu, menu_wrapper, type);
  } 
 
}