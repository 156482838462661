import { Renderer2 } from '@angular/core';
import { Menu } from '@maq-models/menu/menu.model';
import { defineLocale } from 'moment';

/*var renderer2MenuHelp:Renderer2;

export function setRenderer2(paramRenderer2:Renderer2){
    renderer2MenuHelp= paramRenderer2;
}*/

export function createElementHTML(renderer2MenuHelp:Renderer2,nameElement:string, clase?:string, id?:string, ):any{
    let elementHTML = renderer2MenuHelp.createElement(nameElement);
    if (typeof clase !== 'undefined' && clase.trim() != "") {
      renderer2MenuHelp.addClass(elementHTML, clase); 
    }  
    if (typeof id !== 'undefined' && id.trim() != "") {
      renderer2MenuHelp.setAttribute(elementHTML, 'id', id);
    }
    return elementHTML;
  }


  export function crearElementoA(renderer2MenuHelp:Renderer2,menuItem:Menu, typeMenu:string):any{
    let elementoA = createElementHTML(renderer2MenuHelp,'a','menu-item-link','link'+menuItem.id);
    renderer2MenuHelp.setAttribute(elementoA, 'data-toggle', 'tooltip');
    renderer2MenuHelp.setAttribute(elementoA, 'data-animation', 'false');
    renderer2MenuHelp.setAttribute(elementoA, 'data-original-title', menuItem.title);
    renderer2MenuHelp.addClass(elementoA, 'transition');
    if (typeMenu=='horizontal'){
      renderer2MenuHelp.setAttribute(elementoA, 'data-container', '.horizontal-menu-tooltip-place');
      renderer2MenuHelp.setAttribute(elementoA, 'data-placement', 'top');
    }
    else if (typeMenu=='vertical'){
      renderer2MenuHelp.setAttribute(elementoA, 'data-placement', 'right'); 
      renderer2MenuHelp.setAttribute(elementoA, 'data-container', '.vertical-menu-tooltip-place');  
    }

    if(menuItem.href){
      renderer2MenuHelp.setAttribute(elementoA, 'href', menuItem.href);
    }

    if(menuItem.target){
      renderer2MenuHelp.setAttribute(elementoA, 'target', menuItem.target);
    }
    if(menuItem.hasSubMenu){
      renderer2MenuHelp.addClass(elementoA, 'collapsed'); 
      renderer2MenuHelp.setAttribute(elementoA, 'data-toggle', 'collapse');      
      renderer2MenuHelp.setAttribute(elementoA, 'href', '#collapse'+menuItem.id);
    }       
    return elementoA;
  }

  export function crearElementoI(renderer2MenuHelp:Renderer2,menuItem:Menu, typeMenu:string):any{
    let icon = createElementHTML(renderer2MenuHelp,'i');
    if(menuItem.icon.startsWith('icono')){
      /*<i class='MenuIcono menuItem.icon'> </i> y le agrego la clase, los demas atributos */
      renderer2MenuHelp.addClass(icon, 'MenuIcono');
      renderer2MenuHelp.addClass(icon, menuItem.icon);
    } 
    else if(menuItem.icon.includes('mdi') && typeMenu=='vertical'){
      let Auxicono=menuItem.icon.replace("mdi ","");
      //console.log("Auxicono",Auxicono);
      /*<i class='mdi Auxicono mdi-18px menu-mdi'> </i> y le agrego la clase, los demas atributos */
      renderer2MenuHelp.addClass(icon, 'mdi');
      renderer2MenuHelp.addClass(icon, Auxicono);
      renderer2MenuHelp.addClass(icon, 'mdi-18px');
      renderer2MenuHelp.addClass(icon, 'menu-mdi');      
    } 
    else {
      /*<i class='fa fa-menuItem.icon'> </i> y le agrego la clase, los demas atributos */
      renderer2MenuHelp.addClass(icon, 'fa');
      renderer2MenuHelp.addClass(icon, 'fa-'+menuItem.icon);
    }
    return icon;
  }
