// import { log, logIf, logTable, values } from '@maq-shared/util/console';

import { Component, OnInit, DoCheck, Input, Output, ViewEncapsulation, EventEmitter } from '@angular/core';


import { TranslateService } from '@ngx-translate/core';

import { FormGroup, FormControl, FormBuilder, Validators, FormsModule} from '@angular/forms';

@Component({
  selector: 'lib-shared-form-redSocial',
  templateUrl: './form-redSocial.component.html',
  styleUrls: [ './form-redSocial.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class FormRedSocialComponent implements OnInit, DoCheck {

    @Input() public nameField: string;
    @Input() public etiquetaField: string;
    @Input() public controlsField: any;
    @Input() public value: any;
    @Input() public formInvalid: boolean;
    @Input() public accionForm: string;

    @Output() cargoSubEstructura= new EventEmitter<any>();

    public form:FormGroup;
    public traduccion:any={};

        constructor(
                private translate: TranslateService,
                public fb:FormBuilder, ) {

    }

  ngOnInit() {

    // log(...values("valores","form-redSocial nameField:",this.nameField));
    // log(...values("valores","form-redSocial controlsField:",this.controlsField));
    // log(...values("valores","form-redSocial value:",this.value));

    this.inicializarVariablesTraducibles();
    this.translate.onLangChange.subscribe(() => {
        this.inicializarVariablesTraducibles();
    });

    // Defino Formulario
    this.form = this.fb.group({

        redSocial: this.fb.group({
          facebook    : null,
          twitter     : null,
          google      : null,
          instagram   : null,
          linkedin    : null
        }),

    });

    this.form.controls['redSocial'].setValue(this.value);

  }

  ngDoCheck() {

      if(this.accionForm=='consultar') {
        this.form.disable();
      } else {
        this.form.enable();
      }

      if(this.formInvalid) {
        this.form.markAllAsTouched();
      }
  }

  onChangeField() {

      // log(...values('valores','onChangeField - event:',event));

      let valueSubestructura = this.form.get('redSocial').value;

      this.cargoSubEstructura.emit({
        nameField : this.nameField,
        value    :  valueSubestructura
      });

  }

  inicializarVariablesTraducibles(){
      this.traduccion['claseTabs']={
        redSocial: this.translate.instant('claseTabs.redSocial'),
      };
  }

}
