import { NgModule } from '@angular/core';

import { BDService } from './bd.service';
import { BdBaseService } from './bd.serviceBase';




@NgModule({
  declarations: [],
  providers:[BDService,BdBaseService],
  exports: [],
  
})
export class LibBdModule { }
