import { Settings }         from '../settings/settings.model';

export class PerfilUsuario {
  key: string; 
  nombre: string;       //Nombre Perfil  
                        // desarrollador
                        // supervisor-dueño, supervisor-comercial, supervisor-administrador, supervisor-auditor
                        // distribuidor-dueño, distribuidor-comercial, distribuidor-administrador, distribuidor-auditor
                        // organizacion-dueño, organizacion-planificador, organizacion-auditor, organizacion-chofer, organizacion-acompañante
  
  tipo:string;            //Desarrollador, Supervisor, Distribuidor, Organizacion, usuario móvil (chofer/acompanate)
  jerarquia:number;       // Desarrollador (20) Supervisor (15) Distribuidor (10), Organizacion (5), usuario móvil (0)                         
  perfilesAdministrados:string;  // valores posibles para selecionar los tipos de perfiles que puede administrar
                                 // '<=' que su jerarquia, '<' que su jerarquia o blanco no administra otro perfiles

  permisosMenu:any; 
  isChofer:Boolean;
  isAcompanante:Boolean;
  permiteLoginComo:Boolean;
  clavesParaCargaArchivos:string[];
  settings:Settings; // todo: preguntar a Santiago, porque se usa este settings en menu?

  constructor(init?:Partial<PerfilUsuario>) {
    Object.assign(this, init);
  }

}

export class PermisosMenu{        //// ToDo: hacer el mock de permisos

  key:          string;
  aplicacion:   string;

  id:           number;
  title:        string;
  icon:         string
  orden:        number;
  tipoOpcion:   string;
  hasSubMenu:   boolean;
  parentId:     number;
  routerLink:   string
  href:         string
  target:       string

  check:              boolean;
  alta:               boolean;
  baja:               boolean;
  modificacion:       boolean;
  modificacionBasica: boolean;
  consulta:           boolean;
}

