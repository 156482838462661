import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateText'
})
export class TruncateTextPipe implements PipeTransform {

  transform(value: string, limit: number): string {
    // console.log("TruncateTextPipe",value,limit);

    let trail  = '…'
    let result = value || '';

    if (value) {
      // console.log("TruncateTextPipe",value,limit);
      if (value.length > Math.abs(limit)) {
          result = value.substr(0, limit) + trail;
      } else {
          result = value;
      }
      // console.log("TruncateTextPipe result",result);
    }

    return result;
  }
}

