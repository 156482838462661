import { KN, KANE, KNAI }   from '../typesKN/typesKN.model';
import { ACCION_FORM }      from '@maq-helpers/actionFormHelp';
import { GrillaBase, PaginadoFirebase,ItemsAndCurrentPage ,ONCHANGE_GRILLA, GRILLA_PAGINADO_TIPO,GRILLA_PAGINA_REQUERIDA}     from '@maq-models/grilla/grillaBase.model';
import { WhereInterface , OPERADOR_ORDEN_BY,OrdeByInterface, OPERADOR_WHERE, TIPO_BASE_DATOS,}  from '@maq-models/bd/bdDefinicionesGenerales.model';

export class postGETProcesaListadoModel {
  listado                  : any[]    = [];
  form                     : any      = null;
  nombreCampoThumb         : string   = null;
  vecImagenesDownloadeadas : any[]    = [];
  organizacionKNAI         : KNAI     = null;  
  usuarioKANE              : KANE     = null;  
  nombreColeccion          : string   = null;

  constructor(init?:Partial<postGETProcesaListadoModel>) {
    
    Object.assign(this, init);
    
  }

}

export class updateDocumentoModel {

  operacion        : string = null;  
  nombreColeccion  : any    = null; 
  documento        : any    = null;
  organizacionKNAI : KNAI   = null;                           
  usuario          : any    = null;
  spinner          : any    = null;
  keyGenerada      : string = null;

  constructor(init?:Partial<updateDocumentoModel>) {
    
    Object.assign(this, init);
    
  }

}

export class updateColeccionModel {

  operacion        : string = null;  
  nombreColeccion  : any    = null; 
  documento        : any    = null;
  distribuidorKN   : KN     = null;
  organizacionKNAI : KNAI   = null;                             
  usuarioKANE      : KANE   = null;

  constructor(init?:Partial<updateColeccionModel>) {
    
    Object.assign(this, init);
    
  }

}

export class updateColeccionDataBatch {

  operacion        : string  = null;  
  nombreColeccion  : string  = null; 
  documento        : any     = null;
  incluyeSettings  : boolean = true;
  

  constructor(init?:Partial<updateColeccionDataBatch>) {
    
    Object.assign(this, init);
    
  }

}

export class updateColeccionUserKeysBatch {

  distribuidorKN   : KN     = null;
  organizacionKNAI : KNAI   = null;                             
  usuarioKANE      : KANE   = null;

  constructor(init?:Partial<updateColeccionUserKeysBatch>) {
    
    Object.assign(this, init);
    
  }

}


export class getBDModel {

       nombreColeccion  : string = null;       
       where            : any[]	 = [];
       orderBy          : any[]  = [];
       limit            : number = null; 
       paginado         : any    = null;   
       organizacionKNAI : KNAI   = null;                           
       usuarioKANE      : KANE   = null;

  constructor(init?:Partial<getBDModel>) {
    
    Object.assign(this, init);
    
  }

}


export class getBDModel2 {

  nombreColeccion  : string = null;       
  campoClave       : string = null;       
  where            : WhereInterface[]	 = [];
  orderBy          : OrdeByInterface[]  = [];
  limit            : number = null; 
  paginado         : any    = null;   
  organizacionKNAI : KNAI   = null;                           
  usuarioKANE      : KANE   = null;

  constructor(init?:Partial<getBDModel2>) {
    Object.assign(this, init);
  }

}


export class getBDSQL {

  servicioSQL      : string = null;
  nombreColeccion  : string = null;       
  campoClave       : string = null;       
  where            : WhereInterface[]	 = [];
  orderBy          : OrdeByInterface[]  = [];
  paginadoCantidad : number = null; 
  paginaActualNro  : any    = null;   
  organizacionKNAI : KNAI   = null;                           
  usuarioKANE      : KANE   = null;

  constructor(init?:Partial<getBDModel>) {
    Object.assign(this, init);
  }

}

export class updateColeccion2Model {

  operacion        : string = null;  
  nombreColeccion  : string = null; 
  campoClave       : string = null; //campos claves para el update separados por puntos cuando son varios.
  documento        : any    = null;
  distribuidorKN   : KN     = null;
  organizacionKNAI : KNAI   = null;                             
  usuarioKANE      : KANE   = null;
  usaSettings      : boolean= null;

  constructor(init?:Partial<updateColeccionModel>) {
    
    Object.assign(this, init);
    
  }

}

export class DatosUpdateBase {

  operacionFormulario : ACCION_FORM  = null;  
  nombreColeccion     : string  = null; 
  campoClave          : string  = null; //campos claves para el update separados por puntos cuando son varios.
  documento           : any     = null;
  usaSettings         : boolean = null;
  tipoBaseDeDatos     : TIPO_BASE_DATOS ;
  urlBaseDatos        : string  =null ;

  constructor(init?:Partial<DatosUpdateBase>) {
    
    Object.assign(this, init);
    
  }

  // getUsaSettings():boolean{
  //   return this.usaSettings
  // }

}
