import {Component, ViewEncapsulation, ViewChild, HostListener, Input, ElementRef} from '@angular/core';
import { IndicadorPorcentaje } from '@settings/proyecto/models/graficos/indicadorPorcentaje.model';
declare let $: any;
declare let jQuery: any;
@Component({
  selector: 'lib-shared-indicadores-porcentajes',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./indicadores-porcentajes.scss'],
  templateUrl: './indicadores-porcentajes.html',
  
})
export class InidicadoresPorcentajesComponent {

  @Input() public listadoIndicadorPorcentajes: IndicadorPorcentaje[] =[];;

   constructor( ) {

   }

  ngOnInit() {
    console.log('zzz listadoIndicadorPorcentajes ', this.listadoIndicadorPorcentajes);
  }

}

