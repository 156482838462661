import { TIPO_GRAFICO } from "@settings/proyecto/mocks/tableroControl/tableroControl";
import { Chart, ChartColor, ChartDataSets, ChartOptions, ChartPoint, ChartScales, ChartType, LinearScale, LogarithmicScale, Scriptable, TimeScale } from "chart.js";

export class Graphic{
    private key: string = '';
    // private sharedKeyWithValueAndPercentageGraph: string = '';
    private valuesAxisX:string[] = [];
    private typePoint:TIPO_GRAFICO = TIPO_GRAFICO.VALORES;
    private typePointTraduccion: string = 'moduloGraficos.tipoGraficoValores';

    private visualizar:boolean=false;
    private titulo:string = 'prueba';
    private showTypeChart:boolean = true;


    private scales: ChartScales | LinearScale | LogarithmicScale | TimeScale = {};
  
    private chartOptions:ChartOptions = {
      responsive: true,
      title : { //configuro el titulo
        display: false,
        text: this.titulo,
        fontSize: 18
      },
      legend: {
        labels: {
            // This more specific font property overrides the global property
            fontSize : 16
          }
      },
      scales : this.scales,
      onClick: this.onChartClick.bind(this), // Configura el evento de clic
    };
  
    private chartData:ChartDataSets[] = [];
  
    private labels:string [] = [];

    private chartType:string='';
    private chartLegend:boolean=false;

    private barPercentage:number;
    private backgroundColor:ChartColor[]=[];
    private hoverBackgroundColor:ChartColor[]=[];
    private borderColor:ChartColor[]=[];
    private pointBackgroundColor:ChartColor[]=[];
    private pointBorderColor:ChartColor[]=[];
    private hoverBorderColor:ChartColor[]=[];
    private pointHoverBackgroundColor:ChartColor[]=[];
    private pointHoverBorderColor:ChartColor[]=[];

    // private chart:Chart = new Chart(this.key,{
    //   type: this.chartType,
    //   data: {
    //     labels: this.labels,
    //     datasets: this.chartData
    //   },
    //   options: this.chartOptions
    // });

    constructor(init?:Partial<Graphic>) {
      Object.assign(this, init);
    }

    setChartDataValuesBarAndBarHorizontal(axisY: Array<number | null | undefined | number[]> | ChartPoint[] | undefined,type:ChartType | string, etiqueta:string | undefined, anchoColumna:number,backgroundColor:string, hoverColor:string){
      let chartDataSets:ChartDataSets = {
        barPercentage : anchoColumna,
        backgroundColor : backgroundColor,
        hoverBackgroundColor : hoverColor,
        type : type,
        data : axisY,
        label : etiqueta
      }

      this.barPercentage = anchoColumna;
      if(this.backgroundColor.indexOf(backgroundColor) == -1) this.backgroundColor.push(backgroundColor);
      if(this.hoverBackgroundColor.indexOf(hoverColor) == -1) this.hoverBackgroundColor.push(hoverColor);
      if(this.labels.indexOf(etiqueta) == -1) this.labels.push(etiqueta);

      this.chartData.push(chartDataSets);
    }

    updateChartDataValuesBarAndBarHorizontal(axisY: Array<number | null | undefined | number[]> | ChartPoint[] | undefined,type:ChartType | string, etiqueta:string | undefined, anchoColumna:number,backgroundColor:string, hoverColor:string){
      let chartDataSets:ChartDataSets = {
        barPercentage : anchoColumna,
        backgroundColor : backgroundColor,
        hoverBackgroundColor : hoverColor,
        type : type,
        data : axisY,
        label : etiqueta
      }
      this.chartData.push(chartDataSets);
    }

    setChartDataValuesLine(axisY: Array<number | null | undefined | number[]> | ChartPoint[] | undefined,type:ChartType | string, etiqueta:string | undefined,backgroundColor:string, hoverColor:string){
      let chartDataSets:ChartDataSets = {
        backgroundColor : undefined,
        borderColor : backgroundColor,
        pointBackgroundColor : backgroundColor,
        pointBorderColor : backgroundColor,
        hoverBorderColor : hoverColor,
        pointHoverBackgroundColor : hoverColor,
        pointHoverBorderColor : hoverColor,
        hoverBackgroundColor : hoverColor,
        type : type,
        data : axisY,
        label : etiqueta
      }

      if(this.borderColor.indexOf(backgroundColor) == -1) this.borderColor.push(backgroundColor);
      if(this.pointBackgroundColor.indexOf(backgroundColor) == -1) this.pointBackgroundColor.push(backgroundColor);
      if(this.pointBorderColor.indexOf(backgroundColor) == -1) this.pointBorderColor.push(backgroundColor);
      if(this.hoverBorderColor.indexOf(hoverColor) == -1) this.hoverBorderColor.push(hoverColor);
      if(this.pointHoverBackgroundColor.indexOf(hoverColor) == -1) this.pointHoverBackgroundColor.push(hoverColor);
      if(this.pointHoverBorderColor.indexOf(hoverColor) == -1) this.pointHoverBorderColor.push(hoverColor);
      if(this.hoverBackgroundColor.indexOf(hoverColor) == -1) this.hoverBackgroundColor.push(hoverColor);
      if(this.labels.indexOf(etiqueta) == -1) this.labels.push(etiqueta);
      
      this.chartData.push(chartDataSets);
    }

    updateChartDataValuesLine(axisY: Array<number | null | undefined | number[]> | ChartPoint[] | undefined,type:ChartType | string, etiqueta:string | undefined,backgroundColor:string, hoverColor:string){
      let chartDataSets:ChartDataSets = {
        backgroundColor : undefined,
        borderColor : backgroundColor,
        pointBackgroundColor : backgroundColor,
        pointBorderColor : backgroundColor,
        hoverBorderColor : hoverColor,
        pointHoverBackgroundColor : hoverColor,
        pointHoverBorderColor : hoverColor,
        hoverBackgroundColor : hoverColor,
        type : type,
        data : axisY,
        label : etiqueta
      }
      this.chartData.push(chartDataSets);
    }

    sortDataByPorcentaje(arrayValuesOrderByPorcentaje: ChartPoint[]){
      let dataSetOrdenadoPlan: ChartPoint[] = [];
      let dataSetOrdenadoReal: ChartPoint[] = [];

      this.valuesAxisX = [];
      for (let i = 0; i < arrayValuesOrderByPorcentaje.length; i++) {
        let labelOrdenado:String = arrayValuesOrderByPorcentaje[i].t as String;
        this.valuesAxisX.push(labelOrdenado.toString().trim());
        //recorro el resto de los registros de chart data, excepto el de porcentaje
        for (let i = 0; i < this.chartData.length -1; i++){
          //entro a cada data set y lo ordeno en base al orden de los porcentajes
          for (let j = 0; j < this.chartData[i].data.length; j++){
            let chartPoint:ChartPoint = this.chartData[i].data[j] as ChartPoint;
            if(labelOrdenado == chartPoint.t){
              if(i==0){
                dataSetOrdenadoPlan.push(chartPoint);
              }else{
                dataSetOrdenadoReal.push(chartPoint);
              }
            }
          }
        }
      }
      this.chartData[0].data = dataSetOrdenadoPlan;
      this.chartData[1].data = dataSetOrdenadoReal;
    }

    sortDataInGraphicPorcentajeFromLargestToSmallest() {
      if(this.typePoint == TIPO_GRAFICO.AGRUPADO_POR_CHOFER){
        //ordeno el chart data de porcentaje
        let arrayValuesOrderByPorcentaje = this.chartData[this.chartData.length -1].data.sort((a, b) => {
            let aChartPoint= a as ChartPoint;
            let bChartPoint= b as ChartPoint;
            return Number(bChartPoint.x) - Number(aChartPoint.x);
        }) as ChartPoint[];

        this.sortDataByPorcentaje(arrayValuesOrderByPorcentaje);
      }
    }

    sortDataInGraphicPorcentajeFromSmallestToLargest() {
      if(this.typePoint == TIPO_GRAFICO.AGRUPADO_POR_CHOFER){
        //ordeno el chart data de porcentaje
        let arrayValuesOrderByPorcentaje = this.chartData[this.chartData.length -1].data.sort((a, b) => {
          let aChartPoint= a as ChartPoint;
          let bChartPoint= b as ChartPoint;
          return Number(aChartPoint.x) - Number(bChartPoint.x);
        }) as ChartPoint[];
      
        this.sortDataByPorcentaje(arrayValuesOrderByPorcentaje);
      }
    }

    clonar():Graphic{
      return new Graphic(this);
    }

    setKey(key:string){
      this.key = key;
    }

    getKey():string{
      return this.key;
    }

    // setSharedKeyWithValueAndPercentageGraph(key:string){
    //   this.sharedKeyWithValueAndPercentageGraph = key;
    // }

    // getSharedKeyWithValueAndPercentageGraph():string{
    //   return this.sharedKeyWithValueAndPercentageGraph;
    // }
  
    getValuesAxisX():string[]{
      return this.valuesAxisX;
    }
  
    setValuesAxisX(valuesAxisX:string[]){
      this.valuesAxisX = valuesAxisX;
    }
  
    getTypePoint():TIPO_GRAFICO{
      return this.typePoint;
    }
  
    setTypePoint(typePoint:TIPO_GRAFICO){
      this.typePoint = typePoint;
      switch (typePoint) {
        case TIPO_GRAFICO.VALORES:
          this.setTypePointTraduccion('moduloGraficos.tipoGraficoValores');
        break;
        case TIPO_GRAFICO.PORCENTAJE:
          this.setTypePointTraduccion('moduloGraficos.tipoGraficoPorcentaje');
        break;
        case TIPO_GRAFICO.TODOS:
          this.setTypePointTraduccion('moduloGraficos.tipoGraficoTodos');
        break;
        case TIPO_GRAFICO.AGRUPADO_POR_CHOFER:
          this.setTypePointTraduccion('moduloGraficos.tipoGraficoAgrupadoPorChoferes');
        break;
        // default:
        //   this.setTypePointTraduccion('moduloGraficos.tipoGraficoTodos');
        //   break;
      }
    }

    getTypePointTraduccion():string{
      return this.typePointTraduccion;
    }
  
    setTypePointTraduccion(typePointTraduccion:string){
      this.typePointTraduccion = typePointTraduccion;
    }
  
    getChartType():string{
      return this.chartType;
    }
  
    setChartType(chartType:string){
      this.chartType = chartType;
    }
  
    getChartLegend():boolean{
      return this.chartLegend;
    }
  
    setChartLegend(chartLegend:boolean){
      this.chartLegend = chartLegend;
    }
  
    getVisualizar():boolean{
      return this.visualizar;
    }
  
    setVisualizar(visualizar:boolean){
      this.visualizar = visualizar;
    }
  
    getChartOptions():ChartOptions{
      return this.chartOptions;
    }
  
    setChartOptions(chartOptions:ChartOptions){
      this.chartOptions = chartOptions;
    }
  
    getChartData():ChartDataSets[]{
      return this.chartData;
    }
  
    setChartData(chartData:ChartDataSets[]){
      this.chartData = chartData;
    }

    getLabels():string[]{
      return this.labels;
    }

    setLabels(labels:string[]){
      this.labels = labels;
    }

    getBackgroundColor(): ChartColor[] {
      return this.backgroundColor;
    }

    setBackgroundColor(backgroundColor: ChartColor[] ){
      this.backgroundColor = backgroundColor;
    }

    getBorderColor(): ChartColor[] {
      return this.borderColor;
    }

    setBorderColor(borderColor: ChartColor[] ){
      this.borderColor = borderColor;
    }

    getPointBackgroundColor(): ChartColor[] {
      return this.pointBackgroundColor;
    }

    setPointBackgroundColor(pointBackgroundColor: ChartColor[] ){
      this.pointBackgroundColor = pointBackgroundColor;
    }

    getPointBorderColor(): ChartColor[] {
      return this.pointBorderColor;
    }

    setPointBorderColor(pointBorderColor: ChartColor[] ){
      this.pointBorderColor = pointBorderColor;
    }

    getPointHoverBackgroundColor(): ChartColor[] {
      return this.pointHoverBackgroundColor;
    }

    setPointHoverBackgroundColor(pointHoverBackgroundColor: ChartColor[] ){
      this.pointHoverBackgroundColor = pointHoverBackgroundColor;
    }

    getPointHoverBorderColor(): ChartColor[] {
      return this.pointHoverBorderColor;
    }

    setPointHoverBorderColor(pointHoverBorderColor: ChartColor[] ){
      this.pointHoverBorderColor = pointHoverBorderColor;
    }

    getHoverBackgroundColor(): ChartColor[] {
      return this.hoverBackgroundColor;
    }

    setHoverBackgroundColor(hoverBackgroundColor: ChartColor[] ){
      this.hoverBackgroundColor = hoverBackgroundColor;
    }

    getHoverBorderColor(): ChartColor[] {
      return this.hoverBorderColor;
    }

    setHoverBorderColor(hoverBorderColor: ChartColor[] ){
      this.hoverBorderColor = hoverBorderColor;
    }

    getBarPercentage():number{
      return this.barPercentage;
    }

    setBarPercentage(barPercentage:number){
      this.barPercentage = barPercentage;
    }
  
    setTitulo(titulo:string){
      this.titulo = titulo;
      this.chartOptions.title.text = titulo;
    }
  
    getTitulo():string{
      return this.titulo;
    }
  
    getShowTypeChart():boolean{
      return this.showTypeChart;
    }
  
    setShowTypeChart(showTypeChart:boolean){
      this.showTypeChart = showTypeChart
    }
  
    setScale(scales: ChartScales | LinearScale | LogarithmicScale | TimeScale){
      this.scales = scales;
      this.chartOptions.scales = scales;
    }
  
    getScale():ChartScales | LinearScale | LogarithmicScale | TimeScale{
      return this.scales;
    }
  
    onChartClick(event, activeElements){
      if (activeElements.length > 0) {
        let grafico = activeElements[0]._chart.getElementAtEvent(event); //obtengo el grafico 
        let indiceGraphico = grafico[0]._index;
        let indiceInChartData = grafico[0]._datasetIndex;
        let clickedLabel = this.valuesAxisX[indiceGraphico];
        let typeGraphico = this.chartData[indiceInChartData].type;
        let clickedValue = this.chartData[indiceInChartData].data[indiceGraphico];
        // Realiza acciones basadas en el conjunto de datos y el tipo de gráfico
        if (typeGraphico === 'bar') {
          // Acciones específicas para el gráfico de barras
          console.log(`Clic en el grafico de tipo ${typeGraphico} en label ${clickedLabel} y value ${clickedValue}`);
        } else if (typeGraphico === 'line') {
          // Acciones específicas para el gráfico de líneas
          console.log(`Clic en el grafico de tipo ${typeGraphico} en label ${clickedLabel} y value ${clickedValue}`);
        }else if (typeGraphico === 'horizontalBar') {
          // Acciones específicas para el gráfico de barras horizontales
          console.log(`Clic en el grafico de tipo ${typeGraphico} en label ${clickedLabel} y value ${clickedValue}`);
        }
      }
    }
  }