export function getApisFunciones():any {

    let listadoApisFunciones=[];
    let objectApiFuncion = {};


    /* --- Cloud Functions de Firebase -------------------------------------------------------*/
    objectApiFuncion['CloudFunctionCPUxSegundo']={ }
    objectApiFuncion['CloudFunctionCPUxSegundo']={
      key: 'CloudFunctionCPUxSegundo',
      nombre: 'Cloud Functions - GHz por Segundo de CPU',
      apiKN: { key: 'FirestoreFunctions', nombre: 'Firestore Funciones' },
      unidadApiKN: { key: 'Milisegundos', nombre: 'Milisegundos' },
      periodoMedicionKN: { key: 'mes', nombre: 'Mes' },
      costoImporte : 0.01,
      unidadesGratuitasCantidad: '40000',
      costoFormulaUnidadInformacion : 'Segundo',
      costoExplicacion : 'U$s 0.01 cada 1.000  segundos',
      nombreColeccion: 'AuxCloudFunctions',
     }

     listadoApisFunciones.push(objectApiFuncion['CloudFunctionCPUxSegundo']);
 

    // listadoApisFunciones.push('CloudFunctionCPUxSegundo');  
    // listadoApisFunciones['CloudFunctionCPUxSegundo']={ apiKN: null, unidadApiKN: null }
    // listadoApisFunciones['CloudFunctionCPUxSegundo']={
    //   key: 'CloudFunctionCPUxSegundo',
    //   nombre: 'Cloud Functions - GHz por Segundo de CPU',
    //   apiKN: { key: 'FirestoreFunctions', nombre: 'Firestore Funciones' },
    //   unidadApiKN: { key: 'Milisegundos', nombre: 'Milisegundos' },
    //   periodoMedicionKN: { key: 'mes', nombre: 'Mes' },
    //   costoImporte : 0.01,
    //   unidadesGratuitasCantidad: '40000',
    //   costoFormulaUnidadInformacion : 'Segundo',
    //   costoExplicacion : 'U$s 0.01 cada 1.000  segundos',
    //   nombreColeccion: 'AuxCloudFunctions',
    // };

    objectApiFuncion['CloudFunctionGBxSegundo']={ }
    objectApiFuncion['CloudFunctionGBxSegundo']={
      key: 'CloudFunctionGBxSegundo',
      nombre: 'Cloud Functions - GB por Segundo de Memoria',
      apiKN: { key: 'FirestoreFunctions', nombre: 'Firestore Funciones' },
      unidadApiKN: { key: 'Milisegundos', nombre: 'Milisegundos' },
      periodoMedicionKN: { key: 'mes', nombre: 'Mes' },
      costoImporte : 0.0025,
      unidadesGratuitasCantidad: '40000',
      costoFormulaUnidadInformacion : 'Segundo',
      costoExplicacion : 'U$s 0.0025 cada 1.000 segundos',
      nombreColeccion: 'AuxCloudFunctions',
     }

     listadoApisFunciones.push(objectApiFuncion['CloudFunctionGBxSegundo']);

    // listadoApisFunciones.push('CloudFunctionGBxSegundo');  
    // listadoApisFunciones['CloudFunctionGBxSegundo']={ apiKN: null, unidadApiKN: null }
    // listadoApisFunciones['CloudFunctionGBxSegundo']={
    //   key: 'CloudFunctionGBxSegundo',
    //   nombre: 'Cloud Functions - GB por Segundo de Memoria',
    //   apiKN: { key: 'FirestoreFunctions', nombre: 'Firestore Funciones' },
    //   unidadApiKN: { key: 'Milisegundos', nombre: 'Milisegundos' },
    //   periodoMedicionKN: { key: 'mes', nombre: 'Mes' },
    //   costoImporte : 0.0025,
    //   unidadesGratuitasCantidad: '40000',
    //   costoFormulaUnidadInformacion : 'Segundo',
    //   costoExplicacion : 'U$s 0.0025 cada 1.000 segundos',
    //   nombreColeccion: 'AuxCloudFunctions',
    // };

    objectApiFuncion['CloudFunctionInvocacion']={ }
    objectApiFuncion['CloudFunctionInvocacion']={
      key: 'CloudFunctionInvocacion',
      nombre: 'Cloud Functions - Invocaciones de Trigger',
      apiKN: { key: 'FirestoreFunctions', nombre: 'Firestore Funciones' },
      unidadApiKN: { key: 'Ejecuciones', nombre: 'Ejecuciones' },
      periodoMedicionKN: { key: 'mes', nombre: 'Mes' },
      costoImporte : 0.40,
      unidadesGratuitasCantidad: '125000',
      costoFormulaUnidadInformacion : 'Unidad',
      costoExplicacion : 'U$s 0.40 por millón',
      nombreColeccion: 'AuxCloudFunctions',
     }

     listadoApisFunciones.push(objectApiFuncion['CloudFunctionInvocacion']);

    // listadoApisFunciones.push('CloudFunctionInvocacion');  
    // listadoApisFunciones['CloudFunctionInvocacion']={ apiKN: null, unidadApiKN: null }
    // listadoApisFunciones['CloudFunctionInvocacion']={
    //   key: 'CloudFunctionInvocacion',
    //   nombre: 'Cloud Functions - Invocaciones de Trigger',
    //   apiKN: { key: 'FirestoreFunctions', nombre: 'Firestore Funciones' },
    //   unidadApiKN: { key: 'Ejecuciones', nombre: 'Ejecuciones' },
    //   periodoMedicionKN: { key: 'mes', nombre: 'Mes' },
    //   costoImporte : 0.40,
    //   unidadesGratuitasCantidad: '125000',
    //   costoFormulaUnidadInformacion : 'Unidad',
    //   costoExplicacion : 'U$s 0.40 por millón',
    //   nombreColeccion: 'AuxCloudFunctions',
    // };
    
    /* --- Autorizaciones de Login -------------------------------------------------------*/


    objectApiFuncion['FirebaseAuth']={ }
    objectApiFuncion['FirebaseAuth']={
      key: 'FirebaseAuth',
      nombre: 'Firebase Autenticación Login',
      apiKN: { key: 'FirebaseAuth', nombre: 'Firebase Autenticación' },
      unidadApiKN: { key: 'Ejecuciones', nombre: 'Ejecuciones' },
      periodoMedicionKN: { key: 'vidaApp', nombre: 'Vida' },
      costoImporte : 0,
      unidadesGratuitasCantidad: '0',
      costoFormulaUnidadInformacion : 'Unidad',
      costoExplicacion : 'U$s 0',
      nombreColeccion: 'Usuarios',   
     }

     listadoApisFunciones.push(objectApiFuncion['FirebaseAuth']);

    // listadoApisFunciones.push('FirebaseAuth');
    // listadoApisFunciones['FirebaseAuth']={ apiKN: null, unidadApiKN: null };
    // listadoApisFunciones['FirebaseAuth']={
    //   key: 'FirebaseAuth',
    //   nombre: 'Firebase Autenticación Login',
    //   apiKN: { key: 'FirebaseAuth', nombre: 'Firebase Autenticación' },
    //   unidadApiKN: { key: 'Ejecuciones', nombre: 'Ejecuciones' },
    //   periodoMedicionKN: { key: 'vidaApp', nombre: 'Vida' },
    //   costoImporte : 0,
    //   unidadesGratuitasCantidad: '0',
    //   costoFormulaUnidadInformacion : 'Unidad',
    //   costoExplicacion : 'U$s 0',
    //   nombreColeccion: 'Usuarios',   
    // };

    /* --- Hosting de Firebase -------------------------------------------------------*/

    objectApiFuncion['FirebaseHostingStoredData']={ }
    objectApiFuncion['FirebaseHostingStoredData']={
      key: 'FirebaseHostingStoredData',
      nombre: 'Firebase Hosting - Espacio en Disco',
      apiKN: { key: 'FirebaseHosting', nombre: 'Firebase Hosting' },  
      unidadApiKN: { key: 'Bytes', nombre: 'Bytes' },
      periodoMedicionKN: { key: 'vidaApp', nombre: 'Vida' },
      costoImporte : 0.026,
      unidadesGratuitasCantidad: '1',
      costoFormulaUnidadInformacion : 'GB',
      costoExplicacion : 'U$s 0.026 por GB',
      nombreColeccion: 'AuxColecciones', 
     }

     listadoApisFunciones.push(objectApiFuncion['FirebaseHostingStoredData']);

    // listadoApisFunciones.push('FirebaseHostingStoredData');
    // listadoApisFunciones['FirebaseHostingStoredData']={ apiKN: null, unidadApiKN: null }
    // listadoApisFunciones['FirebaseHostingStoredData']={
    //   key: 'FirebaseHostingStoredData',
    //   nombre: 'Firebase Hosting - Espacio en Disco',
    //   apiKN: { key: 'FirebaseHosting', nombre: 'Firebase Hosting' },  
    //   unidadApiKN: { key: 'Bytes', nombre: 'Bytes' },
    //   periodoMedicionKN: { key: 'vidaApp', nombre: 'Vida' },
    //   costoImporte : 0.026,
    //   unidadesGratuitasCantidad: '1',
    //   costoFormulaUnidadInformacion : 'GB',
    //   costoExplicacion : 'U$s 0.026 por GB',
    //   nombreColeccion: 'AuxColecciones',
    // };

    objectApiFuncion['FirebaseHostingTransferencia']={ }
    objectApiFuncion['FirebaseHostingTransferencia']={
      key: 'FirebaseHostingTransferencia',
      nombre: 'Firebase Hosting - Transferencia de Datos',
      apiKN: { key: 'FirebaseHosting', nombre: 'Firebase Hosting' },
      unidadApiKN: { key: 'Bytes', nombre: 'Bytes' },
      periodoMedicionKN: { key: 'mes', nombre: 'Mes' },
      costoImporte : 0.15,
      unidadesGratuitasCantidad: '10',
      costoFormulaUnidadInformacion : 'GB',
      costoExplicacion : 'U$s 0.15 por GB',
      nombreColeccion: 'AuxColecciones',
     }
     
     listadoApisFunciones.push(objectApiFuncion['FirebaseHostingTransferencia']);

    // listadoApisFunciones.push('FirebaseHostingTransferencia');
    // listadoApisFunciones['FirebaseHostingTransferencia']={ apiKN: null, unidadApiKN: null }
    // listadoApisFunciones['FirebaseHostingTransferencia']={
    //   key: 'FirebaseHostingTransferencia',
    //   nombre: 'Firebase Hosting - Transferencia de Datos',
    //   apiKN: { key: 'FirebaseHosting', nombre: 'Firebase Hosting' },
    //   unidadApiKN: { key: 'Bytes', nombre: 'Bytes' },
    //   periodoMedicionKN: { key: 'mes', nombre: 'Mes' },
    //   costoImporte : 0.15,
    //   unidadesGratuitasCantidad: '10',
    //   costoFormulaUnidadInformacion : 'GB',
    //   costoExplicacion : 'U$s 0.15 por GB',
    //   nombreColeccion: 'AuxColecciones',
    // };
    
    /* --- Notificaciones Push -------------------------------------------------------*/

    objectApiFuncion['FirebasePushNotification']={ }
    objectApiFuncion['FirebasePushNotification']={
      key: 'FirebasePushNotification',
      nombre: 'Firebase - Notificación Push',
      apiKN: { key: 'FirebaseFCM', nombre: 'Firebase Notificaciones Push (FCM)' },
      unidadApiKN: { key: 'Ejecuciones', nombre: 'Ejecuciones' },
      periodoMedicionKN: { key: 'vidaApp', nombre: 'Vida' },
      costoImporte : 0,
      unidadesGratuitasCantidad: '0',
      costoFormulaUnidadInformacion : 'Unidad',
      costoExplicacion : 'U$s 0',
      nombreColeccion: 'PushPromociones',
     }

     listadoApisFunciones.push(objectApiFuncion['FirebasePushNotification']);

    // listadoApisFunciones.push('FirebasePushNotification');
    // listadoApisFunciones['FirebasePushNotification']={ apiKN: null, unidadApiKN: null }
    // listadoApisFunciones['FirebasePushNotification']={
    //   key: 'FirebasePushNotification',
    //   nombre: 'Firebase - Notificación Push',
    //   apiKN: { key: 'FirebaseFCM', nombre: 'Firebase Notificaciones Push (FCM)' },
    //   unidadApiKN: { key: 'Ejecuciones', nombre: 'Ejecuciones' },
    //   periodoMedicionKN: { key: 'vidaApp', nombre: 'Vida' },
    //   costoImporte : 0,
    //   unidadesGratuitasCantidad: '0',
    //   costoFormulaUnidadInformacion : 'Unidad',
    //   costoExplicacion : 'U$s 0',
    //   nombreColeccion: 'PushPromociones',
    // };
    
    /* --- Cloud Storage de Firebase -------------------------------------------------------*/

    objectApiFuncion['FirebaseStorageDownloaded']={ }
    objectApiFuncion['FirebaseStorageDownloaded']={
      key: 'FirebaseStorageDownloaded',
      nombre: 'Firebase Storage - Transferencia Downloads',
      apiKN: { key: 'FirebaseStorage', nombre: 'Firebase Storage' },
      unidadApiKN: { key: 'Bytes', nombre: 'Bytes' },
      periodoMedicionKN: { key: 'mes', nombre: 'Mes' },
      costoImporte : 0.12,
      unidadesGratuitasCantidad: '1',
      costoFormulaUnidadInformacion : 'GB',
      costoExplicacion : 'U$s 0.12 por GB',
      nombreColeccion: 'AuxColecciones',
     }

     listadoApisFunciones.push(objectApiFuncion['FirebaseStorageDownloaded']);

    // listadoApisFunciones.push('FirebaseStorageDownloaded');
    // listadoApisFunciones['FirebaseStorageDownloaded']={ apiKN: null, unidadApiKN: null }
    // listadoApisFunciones['FirebaseStorageDownloaded']={
    //   key: 'FirebaseStorageDownloaded',
    //   nombre: 'Firebase Storage - Transferencia Downloads',
    //   apiKN: { key: 'FirebaseStorage', nombre: 'Firebase Storage' },
    //   unidadApiKN: { key: 'Bytes', nombre: 'Bytes' },
    //   periodoMedicionKN: { key: 'mes', nombre: 'Mes' },
    //   costoImporte : 0.12,
    //   unidadesGratuitasCantidad: '1',
    //   costoFormulaUnidadInformacion : 'GB',
    //   costoExplicacion : 'U$s 0.12 por GB',
    //   nombreColeccion: 'AuxColecciones',
    // };

    objectApiFuncion['FirebaseStorageOperationDownload']={ }
    objectApiFuncion['FirebaseStorageOperationDownload']={
      key: 'FirebaseStorageOperationDownload',
      nombre: 'Firebase Storage - Operación de Download',
      apiKN: { key: 'FirebaseStorage', nombre: 'Firebase Storage' },
      unidadApiKN: { key: 'Ejecuciones', nombre: 'Ejecuciones' },
      periodoMedicionKN: { key: 'dia', nombre: 'Día' },
      costoImporte : 0.004,
      unidadesGratuitasCantidad: '50000',
      costoFormulaUnidadInformacion : 'Unidad',
      costoExplicacion : 'U$s 0.004 cada 10.000',
      nombreColeccion: 'AuxColecciones',
     }

     listadoApisFunciones.push(objectApiFuncion['FirebaseStorageOperationDownload']);

    // listadoApisFunciones.push('FirebaseStorageOperationDownload');
    // listadoApisFunciones['FirebaseStorageOperationDownload']={ apiKN: null, unidadApiKN: null }
    // listadoApisFunciones['FirebaseStorageOperationDownload']={
    //   key: 'FirebaseStorageOperationDownload',
    //   nombre: 'Firebase Storage - Operación de Download',
    //   apiKN: { key: 'FirebaseStorage', nombre: 'Firebase Storage' },
    //   unidadApiKN: { key: 'Ejecuciones', nombre: 'Ejecuciones' },
    //   periodoMedicionKN: { key: 'dia', nombre: 'Día' },
    //   costoImporte : 0.004,
    //   unidadesGratuitasCantidad: '50000',
    //   costoFormulaUnidadInformacion : 'Unidad',
    //   costoExplicacion : 'U$s 0.004 cada 10.000',
    //   nombreColeccion: 'AuxColecciones',
    // };

    objectApiFuncion['FirebaseStorageStoredData']={ }
    objectApiFuncion['FirebaseStorageStoredData']={
      key: 'FirebaseStorageStoredData',
      nombre: 'Firebase Storage - Espacio en Disco',
      apiKN: { key: 'FirebaseStorage', nombre: 'Firebase Storage' },
      unidadApiKN: { key: 'Bytes', nombre: 'Bytes' },
      periodoMedicionKN: { key: 'vidaApp', nombre: 'Vida' },
      costoImporte : 0.0026,
      unidadesGratuitasCantidad: '5',
      costoFormulaUnidadInformacion : 'GB',
      costoExplicacion : 'U$s 0.026 por GB',
      nombreColeccion: 'AuxColecciones',
     }

     listadoApisFunciones.push(objectApiFuncion['FirebaseStorageStoredData']);

    // listadoApisFunciones.push('FirebaseStorageStoredData');
    // listadoApisFunciones['FirebaseStorageStoredData']={ apiKN: null, unidadApiKN: null }
    // listadoApisFunciones['FirebaseStorageStoredData']={
    //   key: 'FirebaseStorageStoredData',
    //   nombre: 'Firebase Storage - Espacio en Disco',
    //   apiKN: { key: 'FirebaseStorage', nombre: 'Firebase Storage' },
    //   unidadApiKN: { key: 'Bytes', nombre: 'Bytes' },
    //   periodoMedicionKN: { key: 'vidaApp', nombre: 'Vida' },
    //   costoImporte : 0.0026,
    //   unidadesGratuitasCantidad: '5',
    //   costoFormulaUnidadInformacion : 'GB',
    //   costoExplicacion : 'U$s 0.026 por GB',
    //   nombreColeccion: 'AuxColecciones',
    // };

    objectApiFuncion['FirebaseStorageUploadOperation']={ }
    objectApiFuncion['FirebaseStorageUploadOperation']={
      key: 'FirebaseStorageUploadOperation',
      nombre: 'Firebase Storage - Operación de Upload',
      apiKN: { key: 'FirebaseStorage', nombre: 'Firebase Storage' },
      unidadApiKN: { key: 'Ejecuciones', nombre: 'Ejecuciones' },
      periodoMedicionKN: { key: 'dia', nombre: 'Día' },
      costoImporte : 0.05,
      unidadesGratuitasCantidad: '20000',
      costoFormulaUnidadInformacion : 'Unidad',
      costoExplicacion : 'U$s 0.05 cada 10.000',
      nombreColeccion: 'AuxColecciones',
     };

     listadoApisFunciones.push(objectApiFuncion['FirebaseStorageUploadOperation']);

    // listadoApisFunciones.push('FirebaseStorageUploadOperation');
    // listadoApisFunciones['FirebaseStorageUploadOperation']={ apiKN: null, unidadApiKN: null }
    // listadoApisFunciones['FirebaseStorageUploadOperation']={
    //   key: 'FirebaseStorageUploadOperation',
    //   nombre: 'Firebase Storage - Operación de Upload',
    //   apiKN: { key: 'FirebaseStorage', nombre: 'Firebase Storage' },
    //   unidadApiKN: { key: 'Ejecuciones', nombre: 'Ejecuciones' },
    //   periodoMedicionKN: { key: 'dia', nombre: 'Día' },
    //   costoImporte : 0.05,
    //   unidadesGratuitasCantidad: '20000',
    //   costoFormulaUnidadInformacion : 'Unidad',
    //   costoExplicacion : 'U$s 0.05 cada 10.000',
    //   nombreColeccion: 'AuxColecciones',
    // };

    /* --- BD Firestore -------------------------------------------------------*/


    objectApiFuncion['FirestoreBDStoredData']={ }
    objectApiFuncion['FirestoreBDStoredData']={
      key: 'FirestoreBDStoredData',
      nombre: 'Firestore BD - Espacio en Disco',
      apiKN: { key: 'FirestoreBD', nombre: 'Firestore Base de Datos' },
      unidadApiKN: { key: 'Bytes', nombre: 'Bytes' },
      periodoMedicionKN: { key: 'vidaApp', nombre: 'Vida' },
      costoImporte : 0.18,
      unidadesGratuitasCantidad: '1',
      costoFormulaUnidadInformacion : 'GiB',
      costoExplicacion : 'U$s 0.18 cada GB',
      nombreColeccion: 'AuxColecciones',
     };

     listadoApisFunciones.push(objectApiFuncion['FirestoreBDStoredData']);


    // listadoApisFunciones.push('FirestoreBDStoredData');
    // listadoApisFunciones['FirestoreBDStoredData']={ apiKN: null, unidadApiKN: null }
    // listadoApisFunciones['FirestoreBDStoredData']={
    //   key: 'FirestoreBDStoredData',
    //   nombre: 'Firestore BD - Espacio en Disco',
    //   apiKN: { key: 'FirestoreBD', nombre: 'Firestore Base de Datos' },
    //   unidadApiKN: { key: 'Bytes', nombre: 'Bytes' },
    //   periodoMedicionKN: { key: 'vidaApp', nombre: 'Vida' },
    //   costoImporte : 0.18,
    //   unidadesGratuitasCantidad: '1',
    //   costoFormulaUnidadInformacion : 'GiB',
    //   costoExplicacion : 'U$s 0.18 cada GB',
    //   nombreColeccion: 'AuxColecciones',
    // };
    
    objectApiFuncion['FirestoreDocumentDelete']={ }
    objectApiFuncion['FirestoreDocumentDelete']={
      key: 'FirestoreDocumentDelete',
      nombre: 'Firestore - Eliminación de Documento',
      apiKN: { key: 'FirestoreBD', nombre: 'Firestore Base de Datos' },
      unidadApiKN: { key: 'Ejecuciones', nombre: 'Ejecuciones' },
      periodoMedicionKN: { key: 'dia', nombre: 'Día' },
      costoImporte : 0.02,
      unidadesGratuitasCantidad: '20000',
      costoFormulaUnidadInformacion : 'Unidad',
      costoExplicacion : 'U$s 0.02 cada 100.000',
      nombreColeccion: 'AuxColecciones',
     };

     listadoApisFunciones.push(objectApiFuncion['FirestoreDocumentDelete']);

    // listadoApisFunciones.push('FirestoreDocumentDelete');
    // listadoApisFunciones['FirestoreDocumentDelete']={ apiKN: null, unidadApiKN: null }
    // listadoApisFunciones['FirestoreDocumentDelete']={
    //   key: 'FirestoreDocumentDelete',
    //   nombre: 'Firestore - Eliminación de Documento',
    //   apiKN: { key: 'FirestoreBD', nombre: 'Firestore Base de Datos' },
    //   unidadApiKN: { key: 'Ejecuciones', nombre: 'Ejecuciones' },
    //   periodoMedicionKN: { key: 'dia', nombre: 'Día' },
    //   costoImporte : 0.02,
    //   unidadesGratuitasCantidad: '20000',
    //   costoFormulaUnidadInformacion : 'Unidad',
    //   costoExplicacion : 'U$s 0.02 cada 100.000',
    //   nombreColeccion: 'AuxColecciones',
    // };

    objectApiFuncion['FirestoreDocumentRead']={ }
    objectApiFuncion['FirestoreDocumentRead']={
      key: 'FirestoreDocumentRead',
      nombre: 'Firestore - Lectura de Documento',
      apiKN: { key: 'FirestoreBD', nombre: 'Firestore Base de Datos' },
      unidadApiKN: { key: 'Ejecuciones', nombre: 'Ejecuciones' },
      periodoMedicionKN: { key: 'dia', nombre: 'Día' },
      costoImporte : 0.06,
      unidadesGratuitasCantidad: '50000',
      costoFormulaUnidadInformacion : 'Unidad',
      costoExplicacion : 'U$s 0.06 cada 100.000',
      nombreColeccion: 'AuxColecciones',
     };

     listadoApisFunciones.push(objectApiFuncion['FirestoreDocumentRead']);

    // listadoApisFunciones.push('FirestoreDocumentRead');
    // listadoApisFunciones['FirestoreDocumentRead']={ apiKN: null, unidadApiKN: null }
    // listadoApisFunciones['FirestoreDocumentRead']={
    //   key: 'FirestoreDocumentRead',
    //   nombre: 'Firestore - Lectura de Documento',
    //   apiKN: { key: 'FirestoreBD', nombre: 'Firestore Base de Datos' },
    //   unidadApiKN: { key: 'Ejecuciones', nombre: 'Ejecuciones' },
    //   periodoMedicionKN: { key: 'dia', nombre: 'Día' },
    //   costoImporte : 0.06,
    //   unidadesGratuitasCantidad: '50000',
    //   costoFormulaUnidadInformacion : 'Unidad',
    //   costoExplicacion : 'U$s 0.06 cada 100.000',
    //   nombreColeccion: 'AuxColecciones',
    // };

    objectApiFuncion['FirestoreDocumentWrite']={ }
    objectApiFuncion['FirestoreDocumentWrite']={
      key: 'FirestoreDocumentWrite',
      nombre: 'Firestore - Grabación de Documento',
      apiKN: { key: 'FirestoreBD', nombre: 'Firestore Base de Datos' },
      unidadApiKN: { key: 'Ejecuciones', nombre: 'Ejecuciones' },
      periodoMedicionKN: { key: 'dia', nombre: 'Día' },
      costoImporte : 0.18,
      unidadesGratuitasCantidad: '20000',
      costoFormulaUnidadInformacion : 'Unidad',
      costoExplicacion : 'U$s 0.018 cada 100.000',
      nombreColeccion: 'AuxColecciones',
     };

     listadoApisFunciones.push(objectApiFuncion['FirestoreDocumentWrite']);

    // listadoApisFunciones.push('FirestoreDocumentWrite');
    // listadoApisFunciones['FirestoreDocumentWrite']={ apiKN: null, unidadApiKN: null }
    // listadoApisFunciones['FirestoreDocumentWrite']={
    //   key: 'FirestoreDocumentWrite',
    //   nombre: 'Firestore - Grabación de Documento',
    //   apiKN: { key: 'FirestoreBD', nombre: 'Firestore Base de Datos' },
    //   unidadApiKN: { key: 'Ejecuciones', nombre: 'Ejecuciones' },
    //   periodoMedicionKN: { key: 'dia', nombre: 'Día' },
    //   costoImporte : 0.18,
    //   unidadesGratuitasCantidad: '20000',
    //   costoFormulaUnidadInformacion : 'Unidad',
    //   costoExplicacion : 'U$s 0.018 cada 100.000',
    //   nombreColeccion: 'AuxColecciones',
    // };

    objectApiFuncion['FirestoreTransferencia']={ }
    objectApiFuncion['FirestoreTransferencia']={
      key: 'FirestoreTransferencia',
      nombre: 'Firestore BD - Hosting',
      apiKN: { key: 'FirestoreBD', nombre: 'Firestore Base de Datos' },
      unidadApiKN: { key: 'Bytes', nombre: 'Bytes' },
      periodoMedicionKN: { key: 'mes', nombre: 'Mes' },
      costoImporte : 0.10,
      unidadesGratuitasCantidad: '10',
      costoFormulaUnidadInformacion : 'GiB',
      costoExplicacion : 'U$s 0.10 cada GiB',
      nombreColeccion: 'AuxColecciones',
     };

     listadoApisFunciones.push(objectApiFuncion['FirestoreTransferencia']);

    // listadoApisFunciones.push('FirestoreTransferencia'); /* Firestore Network egress */
    // listadoApisFunciones['FirestoreTransferencia']={ apiKN: null, unidadApiKN: null }
    // listadoApisFunciones['FirestoreTransferencia']={
    //   key: 'FirestoreTransferencia',
    //   nombre: 'Firestore BD - Hosting',
    //   apiKN: { key: 'FirestoreBD', nombre: 'Firestore Base de Datos' },
    //   unidadApiKN: { key: 'Bytes', nombre: 'Bytes' },
    //   periodoMedicionKN: { key: 'mes', nombre: 'Mes' },
    //   costoImporte : 0.10,
    //   unidadesGratuitasCantidad: '10',
    //   costoFormulaUnidadInformacion : 'GiB',
    //   costoExplicacion : 'U$s 0.10 cada GiB',
    //   nombreColeccion: 'AuxColecciones',
    // };

    /* --- Google Maps -------------------------------------------------------------------*/

    objectApiFuncion['GoogleMapsAutocomplete']={ }
    objectApiFuncion['GoogleMapsAutocomplete']={
      key: 'GoogleMapsAutocomplete',
      nombre: 'Google Maps - Autocompletar sin Selección',
      apiKN: { key: 'GoogleMaps', nombre: 'Google Mapas' },
      unidadApiKN: { key: 'Ejecuciones', nombre: 'Ejecuciones' },
      periodoMedicionKN: { key: 'mes', nombre: 'Mes' },
      costoImporte : 17,
      unidadesGratuitasCantidad: '0',
      costoFormulaUnidadInformacion : 'Unidad',
      costoExplicacion : 'U$s 17 cada 1.000 Unidades',
      nombreColeccion: 'AuxColecciones',
     };
     listadoApisFunciones.push(objectApiFuncion['GoogleMapsAutocomplete']);


    // listadoApisFunciones.push('GoogleMapsAutocomplete');
    // listadoApisFunciones['GoogleMapsAutocomplete']={ apiKN: null, unidadApiKN: null }
    // listadoApisFunciones['GoogleMapsAutocomplete']={
    //   key: 'GoogleMapsAutocomplete',
    //   nombre: 'Google Maps - Autocompletar sin Selección',
    //   apiKN: { key: 'GoogleMaps', nombre: 'Google Mapas' },
    //   unidadApiKN: { key: 'Ejecuciones', nombre: 'Ejecuciones' },
    //   periodoMedicionKN: { key: 'mes', nombre: 'Mes' },
    //   costoImporte : 17,
    //   unidadesGratuitasCantidad: '0',
    //   costoFormulaUnidadInformacion : 'Unidad',
    //   costoExplicacion : 'U$s 17 cada 1.000 Unidades',
    //   nombreColeccion: 'AuxColecciones',
    // };


    objectApiFuncion['GoogleMapsGeocoding']={ }
    objectApiFuncion['GoogleMapsGeocoding']={
      key: 'GoogleMapsGeocoding',
      nombre: 'Google Maps - Geocodificación',
      apiKN: { key: 'GoogleMaps', nombre: 'Google Mapas' },
      unidadApiKN: { key: 'Ejecuciones', nombre: 'Ejecuciones' },
      periodoMedicionKN: { key: 'mes', nombre: 'Mes' },
      costoImporte : 6,
      unidadesGratuitasCantidad: '200',
      costoFormulaUnidadInformacion : 'Unidad',
      costoExplicacion : 'U$s 6 cada 1.000',
      nombreColeccion: 'AuxColecciones',
     };
     listadoApisFunciones.push(objectApiFuncion['GoogleMapsGeocoding']);

    // listadoApisFunciones.push('GoogleMapsGeocoding');
    // listadoApisFunciones['GoogleMapsGeocoding']={ apiKN: null, unidadApiKN: null }
    // listadoApisFunciones['GoogleMapsGeocoding']={
    //   key: 'GoogleMapsGeocoding',
    //   nombre: 'Google Maps - Geocodificación',
    //   apiKN: { key: 'GoogleMaps', nombre: 'Google Mapas' },
    //   unidadApiKN: { key: 'Ejecuciones', nombre: 'Ejecuciones' },
    //   periodoMedicionKN: { key: 'mes', nombre: 'Mes' },
    //   costoImporte : 6,
    //   unidadesGratuitasCantidad: '200',
    //   costoFormulaUnidadInformacion : 'Unidad',
    //   costoExplicacion : 'U$s 6 cada 1.000',
    //   nombreColeccion: 'AuxColecciones',
    // };

    objectApiFuncion['GoogleMapsPlaces']={ }
    objectApiFuncion['GoogleMapsPlaces']={
      key: 'GoogleMapsPlaces',
      nombre: 'Google Maps - Autocompletar + Selección',
      apiKN: { key: 'GoogleMaps', nombre: 'Google Mapas' },
      unidadApiKN: { key: 'Ejecuciones', nombre: 'Ejecuciones' },
      periodoMedicionKN: { key: 'mes', nombre: 'Mes' },
      costoImporte : 17,
      unidadesGratuitasCantidad: '200',
      costoFormulaUnidadInformacion : 'Unidad',
      costoExplicacion : 'U$s 17 cada 1.000',
      nombreColeccion: 'AuxColecciones',
     };
     listadoApisFunciones.push(objectApiFuncion['GoogleMapsPlaces']);
    
    // listadoApisFunciones.push('GoogleMapsPlaces');
    // listadoApisFunciones['GoogleMapsPlaces']={ apiKN: null, unidadApiKN: null }
    // listadoApisFunciones['GoogleMapsPlaces']={
    //   key: 'GoogleMapsPlaces',
    //   nombre: 'Google Maps - Autocompletar + Selección',
    //   apiKN: { key: 'GoogleMaps', nombre: 'Google Mapas' },
    //   unidadApiKN: { key: 'Ejecuciones', nombre: 'Ejecuciones' },
    //   periodoMedicionKN: { key: 'mes', nombre: 'Mes' },
    //   costoImporte : 17,
    //   unidadesGratuitasCantidad: '200',
    //   costoFormulaUnidadInformacion : 'Unidad',
    //   costoExplicacion : 'U$s 17 cada 1.000',
    //   nombreColeccion: 'AuxColecciones',
    // };

    objectApiFuncion['GoogleMapsViewDynamic']={ }
    objectApiFuncion['GoogleMapsViewDynamic']={
      key: 'GoogleMapsViewDynamic',
      nombre: 'Google Maps - Visualización Mapa Dinámico',
      apiKN: { key: 'GoogleMaps', nombre: 'Google Mapas' },
      unidadApiKN: { key: 'Views', nombre: 'Visualizaciones' },
      periodoMedicionKN: { key: 'mes', nombre: 'Mes' },
      costoImporte : 7,
      unidadesGratuitasCantidad: '200',
      costoFormulaUnidadInformacion : 'Unidad',
      costoExplicacion : 'U$s 7 cada 1.000',
      nombreColeccion: 'AuxColecciones',
     };
     listadoApisFunciones.push(objectApiFuncion['GoogleMapsViewDynamic']);

    // listadoApisFunciones.push('GoogleMapsViewDynamic');
    // listadoApisFunciones['GoogleMapsViewDynamic']={ apiKN: null, unidadApiKN: null }
    // listadoApisFunciones['GoogleMapsViewDynamic']={
    //   key: 'GoogleMapsViewDynamic',
    //   nombre: 'Google Maps - Visualización Mapa Dinámico',
    //   apiKN: { key: 'GoogleMaps', nombre: 'Google Mapas' },
    //   unidadApiKN: { key: 'Views', nombre: 'Visualizaciones' },
    //   periodoMedicionKN: { key: 'mes', nombre: 'Mes' },
    //   costoImporte : 7,
    //   unidadesGratuitasCantidad: '200',
    //   costoFormulaUnidadInformacion : 'Unidad',
    //   costoExplicacion : 'U$s 7 cada 1.000',
    //   nombreColeccion: 'AuxColecciones',
    // };

    objectApiFuncion['GoogleMapsViewStatic']={ }
    objectApiFuncion['GoogleMapsViewStatic']={
      key: 'GoogleMapsViewStatic',
      nombre: 'Google Maps - Visualización Mapa Estático',
      apiKN: { key: 'GoogleMaps', nombre: 'Google Mapas' },
      unidadApiKN: { key: 'Views', nombre: 'Visualizaciones' },
      periodoMedicionKN: { key: 'mes', nombre: 'Mes' },
      costoImporte : 2,
      unidadesGratuitasCantidad: '200',
      costoFormulaUnidadInformacion : 'Unidad',
      costoExplicacion : 'U$s 2 cada 1.000',
      nombreColeccion: 'AuxColecciones',
     };
     listadoApisFunciones.push(objectApiFuncion['GoogleMapsViewStatic']);

    // listadoApisFunciones.push('GoogleMapsViewStatic');
    // listadoApisFunciones['GoogleMapsViewStatic']={ apiKN: null, unidadApiKN: null }
    // listadoApisFunciones['GoogleMapsViewStatic']={
    //   key: 'GoogleMapsViewStatic',
    //   nombre: 'Google Maps - Visualización Mapa Estático',
    //   apiKN: { key: 'GoogleMaps', nombre: 'Google Mapas' },
    //   unidadApiKN: { key: 'Views', nombre: 'Visualizaciones' },
    //   periodoMedicionKN: { key: 'mes', nombre: 'Mes' },
    //   costoImporte : 2,
    //   unidadesGratuitasCantidad: '200',
    //   costoFormulaUnidadInformacion : 'Unidad',
    //   costoExplicacion : 'U$s 2 cada 1.000',
    //   nombreColeccion: 'AuxColecciones',
    // };

    /* --- Here Maps -------------------------------------------------------------------*/

    objectApiFuncion['HereAutocomplete']={ }
    objectApiFuncion['HereAutocomplete']={
      key: 'HereAutocomplete',
      nombre: 'Here - Aurocompletar sin Selección',
      apiKN: { key: 'HereMaps', nombre: 'Here Mapas' },
      unidadApiKN: { key: 'Ejecuciones', nombre: 'Ejecuciones' },
      periodoMedicionKN: { key: 'mes', nombre: 'Mes' },
      costoImporte : 0.5,
      unidadesGratuitasCantidad: '250000',
      costoFormulaUnidadInformacion : 'Unidad',
      costoExplicacion : 'U$s 0.5 cada 1.000 transacciones',
      nombreColeccion: 'AuxColecciones',
     };
     listadoApisFunciones.push(objectApiFuncion['HereAutocomplete']);


    // listadoApisFunciones.push('HereAutocomplete');
    // listadoApisFunciones['HereAutocomplete']={ apiKN: null, unidadApiKN: null }
    // listadoApisFunciones['HereAutocomplete']={
    //   key: 'HereAutocomplete',
    //   nombre: 'Here - Aurocompletar sin Selección',
    //   apiKN: { key: 'HereMaps', nombre: 'Here Mapas' },
    //   unidadApiKN: { key: 'Ejecuciones', nombre: 'Ejecuciones' },
    //   periodoMedicionKN: { key: 'mes', nombre: 'Mes' },
    //   costoImporte : 0.5,
    //   unidadesGratuitasCantidad: '250000',
    //   costoFormulaUnidadInformacion : 'Unidad',
    //   costoExplicacion : 'U$s 0.5 cada 1.000 transacciones',
    //   nombreColeccion: 'AuxColecciones',
    // };

    objectApiFuncion['HereGeocoding']={ }
    objectApiFuncion['HereGeocoding']={
      key: 'HereGeocoding',
      nombre: 'Here - Geocodificación',
      apiKN: { key: 'HereMaps', nombre: 'Here Mapas' },
      unidadApiKN: { key: 'Ejecuciones', nombre: 'Ejecuciones' },
      periodoMedicionKN: { key: 'mes', nombre: 'Mes' },
      costoImporte : 0.5,
      unidadesGratuitasCantidad: '250000',
      costoFormulaUnidadInformacion : 'Unidad',
      costoExplicacion : 'U$s 0.5 cada 1.000 transacciones.',
      nombreColeccion: 'AuxColecciones',
     };
     listadoApisFunciones.push(objectApiFuncion['HereGeocoding']);
    
    // listadoApisFunciones.push('HereGeocoding');
    // listadoApisFunciones['HereGeocoding']={ apiKN: null, unidadApiKN: null }
    // listadoApisFunciones['HereGeocoding']={
    //   key: 'HereGeocoding',
    //   nombre: 'Here - Geocodificación',
    //   apiKN: { key: 'HereMaps', nombre: 'Here Mapas' },
    //   unidadApiKN: { key: 'Ejecuciones', nombre: 'Ejecuciones' },
    //   periodoMedicionKN: { key: 'mes', nombre: 'Mes' },
    //   costoImporte : 0.5,
    //   unidadesGratuitasCantidad: '250000',
    //   costoFormulaUnidadInformacion : 'Unidad',
    //   costoExplicacion : 'U$s 0.5 cada 1.000 transacciones.',
    //   nombreColeccion: 'AuxColecciones',
    // };

    objectApiFuncion['HereMapsView']={ }
    objectApiFuncion['HereMapsView']={
      key: 'HereMapsView',
      nombre: 'Here - Visualización de Mapas',
      apiKN: { key: 'HereMaps', nombre: 'Here Mapas' },
      unidadApiKN: { key: 'Views', nombre: 'Visualizaciones' },
      periodoMedicionKN: { key: 'mes', nombre: 'Mes' },
      costoImporte : 0.5,
      unidadesGratuitasCantidad: '250000',
      costoFormulaUnidadInformacion : 'Unidad',
      costoExplicacion : 'U$s 0.5 por 1 Mil transacciones.',
      nombreColeccion: 'AuxColecciones',
     };
     listadoApisFunciones.push(objectApiFuncion['HereMapsView']);
    
    // listadoApisFunciones.push('HereMapsView');
    // listadoApisFunciones['HereMapsView']={ apiKN: null, unidadApiKN: null }
    // listadoApisFunciones['HereMapsView']={
    //   key: 'HereMapsView',
    //   nombre: 'Here - Visualización de Mapas',
    //   apiKN: { key: 'HereMaps', nombre: 'Here Mapas' },
    //   unidadApiKN: { key: 'Views', nombre: 'Visualizaciones' },
    //   periodoMedicionKN: { key: 'mes', nombre: 'Mes' },
    //   costoImporte : 0.5,
    //   unidadesGratuitasCantidad: '250000',
    //   costoFormulaUnidadInformacion : 'Unidad',
    //   costoExplicacion : 'U$s 0.5 por 1 Mil transacciones.',
    //   nombreColeccion: 'AuxColecciones',
    // };
    
    
    objectApiFuncion['HerePlaces']={ }
    objectApiFuncion['HerePlaces']={
      key: 'HerePlaces',
      nombre: 'Here - Aurocompletar + Selección',
      apiKN: { key: 'HereMaps', nombre: 'Here Mapas' },
      unidadApiKN: { key: 'Ejecuciones', nombre: 'Ejecuciones' },
      periodoMedicionKN: { key: 'mes', nombre: 'Mes' },
      costoImporte : 0.5,
      unidadesGratuitasCantidad: '250000',
      costoFormulaUnidadInformacion : 'Unidad',
      costoExplicacion : 'U$s 0.5 cada 1.000 transacciones.',
      nombreColeccion: 'AuxColecciones',
     };

     listadoApisFunciones.push(objectApiFuncion['HerePlaces'])
    
    // listadoApisFunciones.push('HerePlaces');
    // listadoApisFunciones['HerePlaces']={ apiKN: null, unidadApiKN: null }
    // listadoApisFunciones['HerePlaces']={
    //   key: 'HerePlaces',
    //   nombre: 'Here - Aurocompletar + Selección',
    //   apiKN: { key: 'HereMaps', nombre: 'Here Mapas' },
    //   unidadApiKN: { key: 'Ejecuciones', nombre: 'Ejecuciones' },
    //   periodoMedicionKN: { key: 'mes', nombre: 'Mes' },
    //   costoImporte : 0.5,
    //   unidadesGratuitasCantidad: '250000',
    //   costoFormulaUnidadInformacion : 'Unidad',
    //   costoExplicacion : 'U$s 0.5 cada 1.000 transacciones.',
    //   nombreColeccion: 'AuxColecciones',
    // };


    // console.log('<<< listadoApisFunciones ', listadoApisFunciones);
    return listadoApisFunciones;
    
};

/* --- APIS SQL -------------------------------------------------------*/

export const listadoApiSqlProveedores:any=[
      { id: 1, nombre: 'Here' },
      { id: 2, nombre: 'Google Maps' },
      { id: 3, nombre: 'Google Firebase' }
];

export const listadoApiSqlMetodos=[
      { id: 1, nombre: 'Autocomplete Sugerencias', id_proveedor: 1},
      { id: 2, nombre: 'Geocodificación', id_proveedor:  1},
      { id: 3, nombre: 'Geocodificación Inversa', id_proveedor: 1},
      { id: 4, nombre: 'Geocodificación Batch - Lanzar', id_proveedor: 1},
      { id: 5, nombre: 'Geocodificación Batch - Consultar Estado', id_proveedor: 1},
      { id: 6, nombre: 'Geocodificación Batch - Obtener Resultados', id_proveedor: 1},
      { id: 7, nombre: 'Mostrar Mapa', id_proveedor: 1}
];

export const listadoApiSqlModulos=[
      { id: 1, nombre: 'Geocodificación Individual'},
      { id: 2, nombre: 'Geocodificación Batch'}
];


