import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivateChild } from '@angular/router';
import { AuthService } from '@maq-autorizacion/services/auth.service'

@Injectable({
  providedIn: 'root'
})
export class CanActivateChildViaNoAuthGuard  implements CanActivateChild{ 

  
    constructor(private authService: AuthService, private router: Router) { }

    canActivateChild() { 
        // If the user is not logged in we'll send them back to the home page
        if (this.authService.authenticated) {
            console.log('Estás logueado');
               this.router.navigate(['/rutas/listado']);
            return false;
        }

        return true;
    }
}
