import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'SucursalSearchPipe', pure: false })
export class SucursalSearchPipe implements PipeTransform {
  transform(value, args?): Array<any> {
    let searchText = new RegExp(args, 'ig');
    if (value) {
      return value.filter(sucursal => {
        let fullName=sucursal.nombre;
        // console.log(fullName)  ;
        if (fullName) {
          return fullName.search(searchText) !== -1;
        }
        else{
          return (args) ? false : true;
        }
      });
    }
  }
}