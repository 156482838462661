export const LISTA_DE_LIBRERIAS_ICONOS:any[]=[
	{key: 'Custom Santi',  			nombre:'Custom Santi',     		  activo: true },
	{key: 'Custom Juan',  			nombre:'Custom Juan',     		  activo: true },
	{key: 'Material Design Iconos', nombre:'Material Design Iconos',  activo: true },
	{key: 'Font Awesome 4',  		nombre:'Font Awesome 4',   		  activo: true },
	{key: 'Font Awesome 5',  		nombre:'Font Awesome 5',   		  activo: false },
	{key: 'Bootstrap',     			nombre:'Glifos (Bootstrap 3)',    activo: false },
];


export const LISTA_DE_ICONOS:any[]=[
    {key: '',   nombre: null, libreria:null },

	{key:"fa fa-custom-clip",nombre:"fa fa-custom-clip", libreria:"Custom Santi" },
	{key:"fa fa-custom-carpeta",nombre:"fa fa-custom-carpeta", libreria:"Custom Santi" },
	{key:"fa fa-custom-chupete",nombre:"fa fa-custom-chupete", libreria:"Custom Santi" },
	{key:"fa fa-custom-email",nombre:"fa fa-custom-email", libreria:"Custom Santi" },
	{key:"fa fa-custom-perfil",nombre:"fa fa-custom-perfil", libreria:"Custom Santi" },

	{key:"MenuIcono iconoAve",nombre:"MenuIcono iconoAve", libreria:"Custom Juan" },
	{key:"MenuIcono iconoBovino",nombre:"MenuIcono iconoBovino", libreria:"Custom Juan" },
	{key:"MenuIcono iconoDistance",nombre:"MenuIcono iconoDistance", libreria:"Custom Juan" },
	{key:"MenuIcono iconoTarifas",nombre:"MenuIcono iconoTarifas", libreria:"Custom Juan" },
	{key:"MenuIcono iconoMedicaciones",nombre:"MenuIcono iconoMedicaciones", libreria:"Custom Juan" },
	{key:"MenuIcono iconoCotizacion",nombre:"MenuIcono iconoCotizacion", libreria:"Custom Juan" },
	{key:"MenuIcono iconoJeringa",nombre:"MenuIcono iconoJeringa", libreria:"Custom Juan" },

	{key:"fa fa-500px",nombre:"fa fa-500px", libreria:"Font Awesome 4" },
	{key:"fa fa-address-book",nombre:"fa fa-address-book", libreria:"Font Awesome 4" },
	{key:"fa fa-address-book-o",nombre:"fa fa-address-book-o", libreria:"Font Awesome 4" },
	{key:"fa fa-address-card",nombre:"fa fa-address-card", libreria:"Font Awesome 4" },
	{key:"fa fa-address-card-o",nombre:"fa fa-address-card-o", libreria:"Font Awesome 4" },
	{key:"fa fa-adjust",nombre:"fa fa-adjust", libreria:"Font Awesome 4" },
	{key:"fa fa-adn",nombre:"fa fa-adn", libreria:"Font Awesome 4" },
	{key:"fa fa-align-center",nombre:"fa fa-align-center", libreria:"Font Awesome 4" },
	{key:"fa fa-align-justify",nombre:"fa fa-align-justify", libreria:"Font Awesome 4" },
	{key:"fa fa-align-left",nombre:"fa fa-align-left", libreria:"Font Awesome 4" },
	{key:"fa fa-align-right",nombre:"fa fa-align-right", libreria:"Font Awesome 4" },
	{key:"fa fa-amazon",nombre:"fa fa-amazon", libreria:"Font Awesome 4" },
	{key:"fa fa-ambulance",nombre:"fa fa-ambulance", libreria:"Font Awesome 4" },
	{key:"fa fa-american-sign-language-interpreting",nombre:"fa fa-american-sign-language-interpreting", libreria:"Font Awesome 4" },
	{key:"fa fa-anchor",nombre:"fa fa-anchor", libreria:"Font Awesome 4" },
	{key:"fa fa-android",nombre:"fa fa-android", libreria:"Font Awesome 4" },
	{key:"fa fa-angellist",nombre:"fa fa-angellist", libreria:"Font Awesome 4" },
	{key:"fa fa-angle-double-down",nombre:"fa fa-angle-double-down", libreria:"Font Awesome 4" },
	{key:"fa fa-angle-double-left",nombre:"fa fa-angle-double-left", libreria:"Font Awesome 4" },
	{key:"fa fa-angle-double-right",nombre:"fa fa-angle-double-right", libreria:"Font Awesome 4" },
	{key:"fa fa-angle-double-up",nombre:"fa fa-angle-double-up", libreria:"Font Awesome 4" },
	{key:"fa fa-angle-down",nombre:"fa fa-angle-down", libreria:"Font Awesome 4" },
	{key:"fa fa-angle-left",nombre:"fa fa-angle-left", libreria:"Font Awesome 4" },
	{key:"fa fa-angle-right",nombre:"fa fa-angle-right", libreria:"Font Awesome 4" },
	{key:"fa fa-angle-up",nombre:"fa fa-angle-up", libreria:"Font Awesome 4" },
	{key:"fa fa-apple",nombre:"fa fa-apple", libreria:"Font Awesome 4" },
	{key:"fa fa-archive",nombre:"fa fa-archive", libreria:"Font Awesome 4" },
	{key:"fa fa-area-chart",nombre:"fa fa-area-chart", libreria:"Font Awesome 4" },
	{key:"fa fa-arrow-circle-down",nombre:"fa fa-arrow-circle-down", libreria:"Font Awesome 4" },
	{key:"fa fa-arrow-circle-left",nombre:"fa fa-arrow-circle-left", libreria:"Font Awesome 4" },
	{key:"fa fa-arrow-circle-o-down",nombre:"fa fa-arrow-circle-o-down", libreria:"Font Awesome 4" },
	{key:"fa fa-arrow-circle-o-left",nombre:"fa fa-arrow-circle-o-left", libreria:"Font Awesome 4" },
	{key:"fa fa-arrow-circle-o-right",nombre:"fa fa-arrow-circle-o-right", libreria:"Font Awesome 4" },
	{key:"fa fa-arrow-circle-o-up",nombre:"fa fa-arrow-circle-o-up", libreria:"Font Awesome 4" },
	{key:"fa fa-arrow-circle-right",nombre:"fa fa-arrow-circle-right", libreria:"Font Awesome 4" },
	{key:"fa fa-arrow-circle-up",nombre:"fa fa-arrow-circle-up", libreria:"Font Awesome 4" },
	{key:"fa fa-arrow-down",nombre:"fa fa-arrow-down", libreria:"Font Awesome 4" },
	{key:"fa fa-arrow-left",nombre:"fa fa-arrow-left", libreria:"Font Awesome 4" },
	{key:"fa fa-arrow-right",nombre:"fa fa-arrow-right", libreria:"Font Awesome 4" },
	{key:"fa fa-arrow-up",nombre:"fa fa-arrow-up", libreria:"Font Awesome 4" },
	{key:"fa fa-arrows",nombre:"fa fa-arrows", libreria:"Font Awesome 4" },
	{key:"fa fa-arrows-alt",nombre:"fa fa-arrows-alt", libreria:"Font Awesome 4" },
	{key:"fa fa-arrows-h",nombre:"fa fa-arrows-h", libreria:"Font Awesome 4" },
	{key:"fa fa-arrows-v",nombre:"fa fa-arrows-v", libreria:"Font Awesome 4" },
	{key:"fa fa-asl-interpreting",nombre:"fa fa-asl-interpreting", libreria:"Font Awesome 4" },
	{key:"fa fa-assistive-listening-systems",nombre:"fa fa-assistive-listening-systems", libreria:"Font Awesome 4" },
	{key:"fa fa-asterisk",nombre:"fa fa-asterisk", libreria:"Font Awesome 4" },
	{key:"fa fa-at",nombre:"fa fa-at", libreria:"Font Awesome 4" },
	{key:"fa fa-audio-description",nombre:"fa fa-audio-description", libreria:"Font Awesome 4" },
	{key:"fa fa-automobile",nombre:"fa fa-automobile", libreria:"Font Awesome 4" },
	{key:"fa fa-backward",nombre:"fa fa-backward", libreria:"Font Awesome 4" },
	{key:"fa fa-balance-scale",nombre:"fa fa-balance-scale", libreria:"Font Awesome 4" },
	{key:"fa fa-ban",nombre:"fa fa-ban", libreria:"Font Awesome 4" },
	{key:"fa fa-bandcamp",nombre:"fa fa-bandcamp", libreria:"Font Awesome 4" },
	{key:"fa fa-bank",nombre:"fa fa-bank", libreria:"Font Awesome 4" },
	{key:"fa fa-bar-chart",nombre:"fa fa-bar-chart", libreria:"Font Awesome 4" },
	{key:"fa fa-bar-chart-o",nombre:"fa fa-bar-chart-o", libreria:"Font Awesome 4" },
	{key:"fa fa-barcode",nombre:"fa fa-barcode", libreria:"Font Awesome 4" },
	{key:"fa fa-bars",nombre:"fa fa-bars", libreria:"Font Awesome 4" },
	{key:"fa fa-bath",nombre:"fa fa-bath", libreria:"Font Awesome 4" },
	{key:"fa fa-bathtub",nombre:"fa fa-bathtub", libreria:"Font Awesome 4" },
	{key:"fa fa-battery-0",nombre:"fa fa-battery-0", libreria:"Font Awesome 4" },
	{key:"fa fa-battery-1",nombre:"fa fa-battery-1", libreria:"Font Awesome 4" },
	{key:"fa fa-battery-2",nombre:"fa fa-battery-2", libreria:"Font Awesome 4" },
	{key:"fa fa-battery-3",nombre:"fa fa-battery-3", libreria:"Font Awesome 4" },
	{key:"fa fa-battery-4",nombre:"fa fa-battery-4", libreria:"Font Awesome 4" },
	{key:"fa fa-battery-empty",nombre:"fa fa-battery-empty", libreria:"Font Awesome 4" },
	{key:"fa fa-battery-full",nombre:"fa fa-battery-full", libreria:"Font Awesome 4" },
	{key:"fa fa-battery-half",nombre:"fa fa-battery-half", libreria:"Font Awesome 4" },
	{key:"fa fa-battery-quarter",nombre:"fa fa-battery-quarter", libreria:"Font Awesome 4" },
	{key:"fa fa-battery-three-quarters",nombre:"fa fa-battery-three-quarters", libreria:"Font Awesome 4" },
	{key:"fa fa-bed",nombre:"fa fa-bed", libreria:"Font Awesome 4" },
	{key:"fa fa-beer",nombre:"fa fa-beer", libreria:"Font Awesome 4" },
	{key:"fa fa-behance",nombre:"fa fa-behance", libreria:"Font Awesome 4" },
	{key:"fa fa-behance-square",nombre:"fa fa-behance-square", libreria:"Font Awesome 4" },
	{key:"fa fa-bell",nombre:"fa fa-bell", libreria:"Font Awesome 4" },
	{key:"fa fa-bell-o",nombre:"fa fa-bell-o", libreria:"Font Awesome 4" },
	{key:"fa fa-bell-slash",nombre:"fa fa-bell-slash", libreria:"Font Awesome 4" },
	{key:"fa fa-bell-slash-o",nombre:"fa fa-bell-slash-o", libreria:"Font Awesome 4" },
	{key:"fa fa-bicycle",nombre:"fa fa-bicycle", libreria:"Font Awesome 4" },
	{key:"fa fa-binoculars",nombre:"fa fa-binoculars", libreria:"Font Awesome 4" },
	{key:"fa fa-birthday-cake",nombre:"fa fa-birthday-cake", libreria:"Font Awesome 4" },
	{key:"fa fa-bitbucket",nombre:"fa fa-bitbucket", libreria:"Font Awesome 4" },
	{key:"fa fa-bitbucket-square",nombre:"fa fa-bitbucket-square", libreria:"Font Awesome 4" },
	{key:"fa fa-bitcoin",nombre:"fa fa-bitcoin", libreria:"Font Awesome 4" },
	{key:"fa fa-black-tie",nombre:"fa fa-black-tie", libreria:"Font Awesome 4" },
	{key:"fa fa-blind",nombre:"fa fa-blind", libreria:"Font Awesome 4" },
	{key:"fa fa-bluetooth",nombre:"fa fa-bluetooth", libreria:"Font Awesome 4" },
	{key:"fa fa-bluetooth-b",nombre:"fa fa-bluetooth-b", libreria:"Font Awesome 4" },
	{key:"fa fa-bold",nombre:"fa fa-bold", libreria:"Font Awesome 4" },
	{key:"fa fa-bolt",nombre:"fa fa-bolt", libreria:"Font Awesome 4" },
	{key:"fa fa-bomb",nombre:"fa fa-bomb", libreria:"Font Awesome 4" },
	{key:"fa fa-book",nombre:"fa fa-book", libreria:"Font Awesome 4" },
	{key:"fa fa-bookmark",nombre:"fa fa-bookmark", libreria:"Font Awesome 4" },
	{key:"fa fa-bookmark-o",nombre:"fa fa-bookmark-o", libreria:"Font Awesome 4" },
	{key:"fa fa-braille",nombre:"fa fa-braille", libreria:"Font Awesome 4" },
	{key:"fa fa-briefcase",nombre:"fa fa-briefcase", libreria:"Font Awesome 4" },
	{key:"fa fa-btc",nombre:"fa fa-btc", libreria:"Font Awesome 4" },
	{key:"fa fa-bug",nombre:"fa fa-bug", libreria:"Font Awesome 4" },
	{key:"fa fa-building",nombre:"fa fa-building", libreria:"Font Awesome 4" },
	{key:"fa fa-building-o",nombre:"fa fa-building-o", libreria:"Font Awesome 4" },
	{key:"fa fa-bullhorn",nombre:"fa fa-bullhorn", libreria:"Font Awesome 4" },
	{key:"fa fa-bullseye",nombre:"fa fa-bullseye", libreria:"Font Awesome 4" },
	{key:"fa fa-bus",nombre:"fa fa-bus", libreria:"Font Awesome 4" },
	{key:"fa fa-buysellads",nombre:"fa fa-buysellads", libreria:"Font Awesome 4" },
	{key:"fa fa-cab",nombre:"fa fa-cab", libreria:"Font Awesome 4" },
	{key:"fa fa-calculator",nombre:"fa fa-calculator", libreria:"Font Awesome 4" },
	{key:"fa fa-calendar",nombre:"fa fa-calendar", libreria:"Font Awesome 4" },
	{key:"fa fa-calendar-check-o",nombre:"fa fa-calendar-check-o", libreria:"Font Awesome 4" },
	{key:"fa fa-calendar-minus-o",nombre:"fa fa-calendar-minus-o", libreria:"Font Awesome 4" },
	{key:"fa fa-calendar-o",nombre:"fa fa-calendar-o", libreria:"Font Awesome 4" },
	{key:"fa fa-calendar-plus-o",nombre:"fa fa-calendar-plus-o", libreria:"Font Awesome 4" },
	{key:"fa fa-calendar-times-o",nombre:"fa fa-calendar-times-o", libreria:"Font Awesome 4" },
	{key:"fa fa-camera",nombre:"fa fa-camera", libreria:"Font Awesome 4" },
	{key:"fa fa-camera-retro",nombre:"fa fa-camera-retro", libreria:"Font Awesome 4" },
	{key:"fa fa-car",nombre:"fa fa-car", libreria:"Font Awesome 4" },
	{key:"fa fa-caret-down",nombre:"fa fa-caret-down", libreria:"Font Awesome 4" },
	{key:"fa fa-caret-left",nombre:"fa fa-caret-left", libreria:"Font Awesome 4" },
	{key:"fa fa-caret-right",nombre:"fa fa-caret-right", libreria:"Font Awesome 4" },
	{key:"fa fa-caret-square-o-down",nombre:"fa fa-caret-square-o-down", libreria:"Font Awesome 4" },
	{key:"fa fa-caret-square-o-left",nombre:"fa fa-caret-square-o-left", libreria:"Font Awesome 4" },
	{key:"fa fa-caret-square-o-right",nombre:"fa fa-caret-square-o-right", libreria:"Font Awesome 4" },
	{key:"fa fa-caret-square-o-up",nombre:"fa fa-caret-square-o-up", libreria:"Font Awesome 4" },
	{key:"fa fa-caret-up",nombre:"fa fa-caret-up", libreria:"Font Awesome 4" },
	{key:"fa fa-cart-arrow-down",nombre:"fa fa-cart-arrow-down", libreria:"Font Awesome 4" },
	{key:"fa fa-cart-plus",nombre:"fa fa-cart-plus", libreria:"Font Awesome 4" },
	{key:"fa fa-cc",nombre:"fa fa-cc", libreria:"Font Awesome 4" },
	{key:"fa fa-cc-amex",nombre:"fa fa-cc-amex", libreria:"Font Awesome 4" },
	{key:"fa fa-cc-diners-club",nombre:"fa fa-cc-diners-club", libreria:"Font Awesome 4" },
	{key:"fa fa-cc-discover",nombre:"fa fa-cc-discover", libreria:"Font Awesome 4" },
	{key:"fa fa-cc-jcb",nombre:"fa fa-cc-jcb", libreria:"Font Awesome 4" },
	{key:"fa fa-cc-mastercard",nombre:"fa fa-cc-mastercard", libreria:"Font Awesome 4" },
	{key:"fa fa-cc-paypal",nombre:"fa fa-cc-paypal", libreria:"Font Awesome 4" },
	{key:"fa fa-cc-stripe",nombre:"fa fa-cc-stripe", libreria:"Font Awesome 4" },
	{key:"fa fa-cc-visa",nombre:"fa fa-cc-visa", libreria:"Font Awesome 4" },
	{key:"fa fa-certificate",nombre:"fa fa-certificate", libreria:"Font Awesome 4" },
	{key:"fa fa-chain",nombre:"fa fa-chain", libreria:"Font Awesome 4" },
	{key:"fa fa-chain-broken",nombre:"fa fa-chain-broken", libreria:"Font Awesome 4" },
	{key:"fa fa-check",nombre:"fa fa-check", libreria:"Font Awesome 4" },
	{key:"fa fa-check-circle",nombre:"fa fa-check-circle", libreria:"Font Awesome 4" },
	{key:"fa fa-check-circle-o",nombre:"fa fa-check-circle-o", libreria:"Font Awesome 4" },
	{key:"fa fa-check-square",nombre:"fa fa-check-square", libreria:"Font Awesome 4" },
	{key:"fa fa-check-square-o",nombre:"fa fa-check-square-o", libreria:"Font Awesome 4" },
	{key:"fa fa-chevron-circle-down",nombre:"fa fa-chevron-circle-down", libreria:"Font Awesome 4" },
	{key:"fa fa-chevron-circle-left",nombre:"fa fa-chevron-circle-left", libreria:"Font Awesome 4" },
	{key:"fa fa-chevron-circle-right",nombre:"fa fa-chevron-circle-right", libreria:"Font Awesome 4" },
	{key:"fa fa-chevron-circle-up",nombre:"fa fa-chevron-circle-up", libreria:"Font Awesome 4" },
	{key:"fa fa-chevron-down",nombre:"fa fa-chevron-down", libreria:"Font Awesome 4" },
	{key:"fa fa-chevron-left",nombre:"fa fa-chevron-left", libreria:"Font Awesome 4" },
	{key:"fa fa-chevron-right",nombre:"fa fa-chevron-right", libreria:"Font Awesome 4" },
	{key:"fa fa-chevron-up",nombre:"fa fa-chevron-up", libreria:"Font Awesome 4" },
	{key:"fa fa-child",nombre:"fa fa-child", libreria:"Font Awesome 4" },
	{key:"fa fa-chrome",nombre:"fa fa-chrome", libreria:"Font Awesome 4" },
	{key:"fa fa-circle",nombre:"fa fa-circle", libreria:"Font Awesome 4" },
	{key:"fa fa-circle-o",nombre:"fa fa-circle-o", libreria:"Font Awesome 4" },
	{key:"fa fa-circle-o-notch",nombre:"fa fa-circle-o-notch", libreria:"Font Awesome 4" },
	{key:"fa fa-circle-thin",nombre:"fa fa-circle-thin", libreria:"Font Awesome 4" },
	{key:"fa fa-clipboard",nombre:"fa fa-clipboard", libreria:"Font Awesome 4" },
	{key:"fa fa-clock-o",nombre:"fa fa-clock-o", libreria:"Font Awesome 4" },
	{key:"fa fa-clone",nombre:"fa fa-clone", libreria:"Font Awesome 4" },
	{key:"fa fa-close",nombre:"fa fa-close", libreria:"Font Awesome 4" },
	{key:"fa fa-cloud",nombre:"fa fa-cloud", libreria:"Font Awesome 4" },
	{key:"fa fa-cloud-download",nombre:"fa fa-cloud-download", libreria:"Font Awesome 4" },
	{key:"fa fa-cloud-upload",nombre:"fa fa-cloud-upload", libreria:"Font Awesome 4" },
	{key:"fa fa-cny",nombre:"fa fa-cny", libreria:"Font Awesome 4" },
	{key:"fa fa-code",nombre:"fa fa-code", libreria:"Font Awesome 4" },
	{key:"fa fa-code-fork",nombre:"fa fa-code-fork", libreria:"Font Awesome 4" },
	{key:"fa fa-codepen",nombre:"fa fa-codepen", libreria:"Font Awesome 4" },
	{key:"fa fa-codiepie",nombre:"fa fa-codiepie", libreria:"Font Awesome 4" },
	{key:"fa fa-coffee",nombre:"fa fa-coffee", libreria:"Font Awesome 4" },
	{key:"fa fa-cog",nombre:"fa fa-cog", libreria:"Font Awesome 4" },
	{key:"fa fa-cogs",nombre:"fa fa-cogs", libreria:"Font Awesome 4" },
	{key:"fa fa-columns",nombre:"fa fa-columns", libreria:"Font Awesome 4" },
	{key:"fa fa-comment",nombre:"fa fa-comment", libreria:"Font Awesome 4" },
	{key:"fa fa-comment-o",nombre:"fa fa-comment-o", libreria:"Font Awesome 4" },
	{key:"fa fa-comments",nombre:"fa fa-comments", libreria:"Font Awesome 4" },
	{key:"fa fa-comments-o",nombre:"fa fa-comments-o", libreria:"Font Awesome 4" },
	{key:"fa fa-commenting",nombre:"fa fa-commenting", libreria:"Font Awesome 4" },
	{key:"fa fa-commenting-o",nombre:"fa fa-commenting-o", libreria:"Font Awesome 4" },
	{key:"fa fa-compass",nombre:"fa fa-compass", libreria:"Font Awesome 4" },
	{key:"fa fa-compress",nombre:"fa fa-compress", libreria:"Font Awesome 4" },
	{key:"fa fa-connectdevelop",nombre:"fa fa-connectdevelop", libreria:"Font Awesome 4" },
	{key:"fa fa-contao",nombre:"fa fa-contao", libreria:"Font Awesome 4" },
	{key:"fa fa-copy",nombre:"fa fa-copy", libreria:"Font Awesome 4" },
	{key:"fa fa-copyright",nombre:"fa fa-copyright", libreria:"Font Awesome 4" },
	{key:"fa fa-creative-commons",nombre:"fa fa-creative-commons", libreria:"Font Awesome 4" },
	{key:"fa fa-credit-card",nombre:"fa fa-credit-card", libreria:"Font Awesome 4" },
	{key:"fa fa-credit-card-alt",nombre:"fa fa-credit-card-alt", libreria:"Font Awesome 4" },
	{key:"fa fa-crop",nombre:"fa fa-crop", libreria:"Font Awesome 4" },
	{key:"fa fa-crosshairs",nombre:"fa fa-crosshairs", libreria:"Font Awesome 4" },
	{key:"fa fa-css3",nombre:"fa fa-css3", libreria:"Font Awesome 4" },
	{key:"fa fa-cube",nombre:"fa fa-cube", libreria:"Font Awesome 4" },
	{key:"fa fa-cubes",nombre:"fa fa-cubes", libreria:"Font Awesome 4" },
	{key:"fa fa-cut",nombre:"fa fa-cut", libreria:"Font Awesome 4" },
	{key:"fa fa-cutlery",nombre:"fa fa-cutlery", libreria:"Font Awesome 4" },
	{key:"fa fa-dashboard",nombre:"fa fa-dashboard", libreria:"Font Awesome 4" },
	{key:"fa fa-dashcube",nombre:"fa fa-dashcube", libreria:"Font Awesome 4" },
	{key:"fa fa-database",nombre:"fa fa-database", libreria:"Font Awesome 4" },
	{key:"fa fa-deaf",nombre:"fa fa-deaf", libreria:"Font Awesome 4" },
	{key:"fa fa-deafness",nombre:"fa fa-deafness", libreria:"Font Awesome 4" },
	{key:"fa fa-dedent",nombre:"fa fa-dedent", libreria:"Font Awesome 4" },
	{key:"fa fa-delicious",nombre:"fa fa-delicious", libreria:"Font Awesome 4" },
	{key:"fa fa-desktop",nombre:"fa fa-desktop", libreria:"Font Awesome 4" },
	{key:"fa fa-deviantart",nombre:"fa fa-deviantart", libreria:"Font Awesome 4" },
	{key:"fa fa-diamond",nombre:"fa fa-diamond", libreria:"Font Awesome 4" },
	{key:"fa fa-digg",nombre:"fa fa-digg", libreria:"Font Awesome 4" },
	{key:"fa fa-dollar",nombre:"fa fa-dollar", libreria:"Font Awesome 4" },
	{key:"fa fa-dot-circle-o",nombre:"fa fa-dot-circle-o", libreria:"Font Awesome 4" },
	{key:"fa fa-download",nombre:"fa fa-download", libreria:"Font Awesome 4" },
	{key:"fa fa-dribbble",nombre:"fa fa-dribbble", libreria:"Font Awesome 4" },
	{key:"fa fa-drivers-license",nombre:"fa fa-drivers-license", libreria:"Font Awesome 4" },
	{key:"fa fa-drivers-license-o",nombre:"fa fa-drivers-license-o", libreria:"Font Awesome 4" },
	{key:"fa fa-dropbox",nombre:"fa fa-dropbox", libreria:"Font Awesome 4" },
	{key:"fa fa-drupal",nombre:"fa fa-drupal", libreria:"Font Awesome 4" },
	{key:"fa fa-edge",nombre:"fa fa-edge", libreria:"Font Awesome 4" },
	{key:"fa fa-edit",nombre:"fa fa-edit", libreria:"Font Awesome 4" },
	{key:"fa fa-eercast",nombre:"fa fa-eercast", libreria:"Font Awesome 4" },
	{key:"fa fa-eject",nombre:"fa fa-eject", libreria:"Font Awesome 4" },
	{key:"fa fa-ellipsis-h",nombre:"fa fa-ellipsis-h", libreria:"Font Awesome 4" },
	{key:"fa fa-ellipsis-v",nombre:"fa fa-ellipsis-v", libreria:"Font Awesome 4" },
	{key:"fa fa-empire",nombre:"fa fa-empire", libreria:"Font Awesome 4" },
	{key:"fa fa-envelope",nombre:"fa fa-envelope", libreria:"Font Awesome 4" },
	{key:"fa fa-envelope-o",nombre:"fa fa-envelope-o", libreria:"Font Awesome 4" },
	{key:"fa fa-envelope-open",nombre:"fa fa-envelope-open", libreria:"Font Awesome 4" },
	{key:"fa fa-envelope-open-o",nombre:"fa fa-envelope-open-o", libreria:"Font Awesome 4" },
	{key:"fa fa-envelope-square",nombre:"fa fa-envelope-square", libreria:"Font Awesome 4" },
	{key:"fa fa-envira",nombre:"fa fa-envira", libreria:"Font Awesome 4" },
	{key:"fa fa-eraser",nombre:"fa fa-eraser", libreria:"Font Awesome 4" },
	{key:"fa fa-etsy",nombre:"fa fa-etsy", libreria:"Font Awesome 4" },
	{key:"fa fa-eur",nombre:"fa fa-eur", libreria:"Font Awesome 4" },
	{key:"fa fa-euro",nombre:"fa fa-euro", libreria:"Font Awesome 4" },
	{key:"fa fa-exchange",nombre:"fa fa-exchange", libreria:"Font Awesome 4" },
	{key:"fa fa-exclamation",nombre:"fa fa-exclamation", libreria:"Font Awesome 4" },
	{key:"fa fa-exclamation-circle",nombre:"fa fa-exclamation-circle", libreria:"Font Awesome 4" },
	{key:"fa fa-exclamation-triangle",nombre:"fa fa-exclamation-triangle", libreria:"Font Awesome 4" },
	{key:"fa fa-expand",nombre:"fa fa-expand", libreria:"Font Awesome 4" },
	{key:"fa fa-expeditedssl",nombre:"fa fa-expeditedssl", libreria:"Font Awesome 4" },
	{key:"fa fa-external-link",nombre:"fa fa-external-link", libreria:"Font Awesome 4" },
	{key:"fa fa-external-link-square",nombre:"fa fa-external-link-square", libreria:"Font Awesome 4" },
	{key:"fa fa-eye",nombre:"fa fa-eye", libreria:"Font Awesome 4" },
	{key:"fa fa-eye-slash",nombre:"fa fa-eye-slash", libreria:"Font Awesome 4" },
	{key:"fa fa-eyedropper",nombre:"fa fa-eyedropper", libreria:"Font Awesome 4" },
	{key:"fa fa-font-awesome",nombre:"fa fa-font-awesome", libreria:"Font Awesome 4" },
	{key:"fa fa-facebook",nombre:"fa fa-facebook", libreria:"Font Awesome 4" },
	{key:"fa fa-facebook-f",nombre:"fa fa-facebook-f", libreria:"Font Awesome 4" },
	{key:"fa fa-facebook-official",nombre:"fa fa-facebook-official", libreria:"Font Awesome 4" },
	{key:"fa fa-facebook-square",nombre:"fa fa-facebook-square", libreria:"Font Awesome 4" },
	{key:"fa fa-fast-backward",nombre:"fa fa-fast-backward", libreria:"Font Awesome 4" },
	{key:"fa fa-fast-forward",nombre:"fa fa-fast-forward", libreria:"Font Awesome 4" },
	{key:"fa fa-fax",nombre:"fa fa-fax", libreria:"Font Awesome 4" },
	{key:"fa fa-feed",nombre:"fa fa-feed", libreria:"Font Awesome 4" },
	{key:"fa fa-female",nombre:"fa fa-female", libreria:"Font Awesome 4" },
	{key:"fa fa-fighter-jet",nombre:"fa fa-fighter-jet", libreria:"Font Awesome 4" },
	{key:"fa fa-file",nombre:"fa fa-file", libreria:"Font Awesome 4" },
	{key:"fa fa-file-archive-o",nombre:"fa fa-file-archive-o", libreria:"Font Awesome 4" },
	{key:"fa fa-file-audio-o",nombre:"fa fa-file-audio-o", libreria:"Font Awesome 4" },
	{key:"fa fa-file-code-o",nombre:"fa fa-file-code-o", libreria:"Font Awesome 4" },
	{key:"fa fa-file-excel-o",nombre:"fa fa-file-excel-o", libreria:"Font Awesome 4" },
	{key:"fa fa-file-image-o",nombre:"fa fa-file-image-o", libreria:"Font Awesome 4" },
	{key:"fa fa-file-movie-o",nombre:"fa fa-file-movie-o", libreria:"Font Awesome 4" },
	{key:"fa fa-file-o",nombre:"fa fa-file-o", libreria:"Font Awesome 4" },
	{key:"fa fa-file-pdf-o",nombre:"fa fa-file-pdf-o", libreria:"Font Awesome 4" },
	{key:"fa fa-file-photo-o",nombre:"fa fa-file-photo-o", libreria:"Font Awesome 4" },
	{key:"fa fa-file-picture-o",nombre:"fa fa-file-picture-o", libreria:"Font Awesome 4" },
	{key:"fa fa-file-powerpoint-o",nombre:"fa fa-file-powerpoint-o", libreria:"Font Awesome 4" },
	{key:"fa fa-file-sound-o",nombre:"fa fa-file-sound-o", libreria:"Font Awesome 4" },
	{key:"fa fa-file-text",nombre:"fa fa-file-text", libreria:"Font Awesome 4" },
	{key:"fa fa-file-text-o",nombre:"fa fa-file-text-o", libreria:"Font Awesome 4" },
	{key:"fa fa-file-video-o",nombre:"fa fa-file-video-o", libreria:"Font Awesome 4" },
	{key:"fa fa-file-word-o",nombre:"fa fa-file-word-o", libreria:"Font Awesome 4" },
	{key:"fa fa-file-zip-o",nombre:"fa fa-file-zip-o", libreria:"Font Awesome 4" },
	{key:"fa fa-files-o",nombre:"fa fa-files-o", libreria:"Font Awesome 4" },
	{key:"fa fa-film",nombre:"fa fa-film", libreria:"Font Awesome 4" },
	{key:"fa fa-filter",nombre:"fa fa-filter", libreria:"Font Awesome 4" },
	{key:"fa fa-fire",nombre:"fa fa-fire", libreria:"Font Awesome 4" },
	{key:"fa fa-fire-extinguisher",nombre:"fa fa-fire-extinguisher", libreria:"Font Awesome 4" },
	{key:"fa fa-firefox",nombre:"fa fa-firefox", libreria:"Font Awesome 4" },
	{key:"fa fa-first-order",nombre:"fa fa-first-order", libreria:"Font Awesome 4" },
	{key:"fa fa-flag",nombre:"fa fa-flag", libreria:"Font Awesome 4" },
	{key:"fa fa-flag-checkered",nombre:"fa fa-flag-checkered", libreria:"Font Awesome 4" },
	{key:"fa fa-flag-o",nombre:"fa fa-flag-o", libreria:"Font Awesome 4" },
	{key:"fa fa-flash",nombre:"fa fa-flash", libreria:"Font Awesome 4" },
	{key:"fa fa-flask",nombre:"fa fa-flask", libreria:"Font Awesome 4" },
	{key:"fa fa-flickr",nombre:"fa fa-flickr", libreria:"Font Awesome 4" },
	{key:"fa fa-floppy-o",nombre:"fa fa-floppy-o", libreria:"Font Awesome 4" },
	{key:"fa fa-folder",nombre:"fa fa-folder", libreria:"Font Awesome 4" },
	{key:"fa fa-folder-o",nombre:"fa fa-folder-o", libreria:"Font Awesome 4" },
	{key:"fa fa-folder-open",nombre:"fa fa-folder-open", libreria:"Font Awesome 4" },
	{key:"fa fa-folder-open-o",nombre:"fa fa-folder-open-o", libreria:"Font Awesome 4" },
	{key:"fa fa-font",nombre:"fa fa-font", libreria:"Font Awesome 4" },
	{key:"fa fa-font-awesome",nombre:"fa fa-font-awesome", libreria:"Font Awesome 4" },
	{key:"fa fa-fonticons",nombre:"fa fa-fonticons", libreria:"Font Awesome 4" },
	{key:"fa fa-fort-awesome",nombre:"fa fa-fort-awesome", libreria:"Font Awesome 4" },
	{key:"fa fa-forumbee",nombre:"fa fa-forumbee", libreria:"Font Awesome 4" },
	{key:"fa fa-forward",nombre:"fa fa-forward", libreria:"Font Awesome 4" },
	{key:"fa fa-foursquare",nombre:"fa fa-foursquare", libreria:"Font Awesome 4" },
	{key:"fa fa-free-code-camp",nombre:"fa fa-free-code-camp", libreria:"Font Awesome 4" },
	{key:"fa fa-frown-o",nombre:"fa fa-frown-o", libreria:"Font Awesome 4" },
	{key:"fa fa-futbol-o",nombre:"fa fa-futbol-o", libreria:"Font Awesome 4" },
	{key:"fa fa-gamepad",nombre:"fa fa-gamepad", libreria:"Font Awesome 4" },
	{key:"fa fa-gavel",nombre:"fa fa-gavel", libreria:"Font Awesome 4" },
	{key:"fa fa-gbp",nombre:"fa fa-gbp", libreria:"Font Awesome 4" },
	{key:"fa fa-ge",nombre:"fa fa-ge", libreria:"Font Awesome 4" },
	{key:"fa fa-gear",nombre:"fa fa-gear", libreria:"Font Awesome 4" },
	{key:"fa fa-gears",nombre:"fa fa-gears", libreria:"Font Awesome 4" },
	{key:"fa fa-genderless",nombre:"fa fa-genderless", libreria:"Font Awesome 4" },
	{key:"fa fa-get-pocket",nombre:"fa fa-get-pocket", libreria:"Font Awesome 4" },
	{key:"fa fa-gg",nombre:"fa fa-gg", libreria:"Font Awesome 4" },
	{key:"fa fa-gg-circle",nombre:"fa fa-gg-circle", libreria:"Font Awesome 4" },
	{key:"fa fa-gift",nombre:"fa fa-gift", libreria:"Font Awesome 4" },
	{key:"fa fa-git",nombre:"fa fa-git", libreria:"Font Awesome 4" },
	{key:"fa fa-git-square",nombre:"fa fa-git-square", libreria:"Font Awesome 4" },
	{key:"fa fa-github",nombre:"fa fa-github", libreria:"Font Awesome 4" },
	{key:"fa fa-github-alt",nombre:"fa fa-github-alt", libreria:"Font Awesome 4" },
	{key:"fa fa-github-square",nombre:"fa fa-github-square", libreria:"Font Awesome 4" },
	{key:"fa fa-gitlab",nombre:"fa fa-gitlab", libreria:"Font Awesome 4" },
	{key:"fa fa-gittip",nombre:"fa fa-gittip", libreria:"Font Awesome 4" },
	{key:"fa fa-glass",nombre:"fa fa-glass", libreria:"Font Awesome 4" },
	{key:"fa fa-glide",nombre:"fa fa-glide", libreria:"Font Awesome 4" },
	{key:"fa fa-glide-g",nombre:"fa fa-glide-g", libreria:"Font Awesome 4" },
	{key:"fa fa-globe",nombre:"fa fa-globe", libreria:"Font Awesome 4" },
	{key:"fa fa-google",nombre:"fa fa-google", libreria:"Font Awesome 4" },
	{key:"fa fa-google-plus",nombre:"fa fa-google-plus", libreria:"Font Awesome 4" },
	{key:"fa fa-google-plus-circle",nombre:"fa fa-google-plus-circle", libreria:"Font Awesome 4" },
	{key:"fa fa-google-plus-official",nombre:"fa fa-google-plus-official", libreria:"Font Awesome 4" },
	{key:"fa fa-google-plus-square",nombre:"fa fa-google-plus-square", libreria:"Font Awesome 4" },
	{key:"fa fa-google-wallet",nombre:"fa fa-google-wallet", libreria:"Font Awesome 4" },
	{key:"fa fa-graduation-cap",nombre:"fa fa-graduation-cap", libreria:"Font Awesome 4" },
	{key:"fa fa-gratipay",nombre:"fa fa-gratipay", libreria:"Font Awesome 4" },
	{key:"fa fa-grav",nombre:"fa fa-grav", libreria:"Font Awesome 4" },
	{key:"fa fa-group",nombre:"fa fa-group", libreria:"Font Awesome 4" },
	{key:"fa fa-h-square",nombre:"fa fa-h-square", libreria:"Font Awesome 4" },
	{key:"fa fa-hacker-news",nombre:"fa fa-hacker-news", libreria:"Font Awesome 4" },
	{key:"fa fa-hand-grab-o",nombre:"fa fa-hand-grab-o", libreria:"Font Awesome 4" },
	{key:"fa fa-hand-lizard-o",nombre:"fa fa-hand-lizard-o", libreria:"Font Awesome 4" },
	{key:"fa fa-hand-o-down",nombre:"fa fa-hand-o-down", libreria:"Font Awesome 4" },
	{key:"fa fa-hand-o-left",nombre:"fa fa-hand-o-left", libreria:"Font Awesome 4" },
	{key:"fa fa-hand-o-right",nombre:"fa fa-hand-o-right", libreria:"Font Awesome 4" },
	{key:"fa fa-hand-o-up",nombre:"fa fa-hand-o-up", libreria:"Font Awesome 4" },
	{key:"fa fa-hand-paper-o",nombre:"fa fa-hand-paper-o", libreria:"Font Awesome 4" },
	{key:"fa fa-hand-peace-o",nombre:"fa fa-hand-peace-o", libreria:"Font Awesome 4" },
	{key:"fa fa-hand-pointer-o",nombre:"fa fa-hand-pointer-o", libreria:"Font Awesome 4" },
	{key:"fa fa-hand-rock-o",nombre:"fa fa-hand-rock-o", libreria:"Font Awesome 4" },
	{key:"fa fa-hand-scissors-o",nombre:"fa fa-hand-scissors-o", libreria:"Font Awesome 4" },
	{key:"fa fa-hand-spock-o",nombre:"fa fa-hand-spock-o", libreria:"Font Awesome 4" },
	{key:"fa fa-hand-stop-o",nombre:"fa fa-hand-stop-o", libreria:"Font Awesome 4" },
	{key:"fa fa-handshake-o",nombre:"fa fa-handshake-o", libreria:"Font Awesome 4" },
	{key:"fa fa-hard-of-hearing",nombre:"fa fa-hard-of-hearing", libreria:"Font Awesome 4" },
	{key:"fa fa-hashtag",nombre:"fa fa-hashtag", libreria:"Font Awesome 4" },
	{key:"fa fa-hdd-o",nombre:"fa fa-hdd-o", libreria:"Font Awesome 4" },
	{key:"fa fa-header",nombre:"fa fa-header", libreria:"Font Awesome 4" },
	{key:"fa fa-headphones",nombre:"fa fa-headphones", libreria:"Font Awesome 4" },
	{key:"fa fa-heart",nombre:"fa fa-heart", libreria:"Font Awesome 4" },
	{key:"fa fa-heart-o",nombre:"fa fa-heart-o", libreria:"Font Awesome 4" },
	{key:"fa fa-heartbeat",nombre:"fa fa-heartbeat", libreria:"Font Awesome 4" },
	{key:"fa fa-history",nombre:"fa fa-history", libreria:"Font Awesome 4" },
	{key:"fa fa-home",nombre:"fa fa-home", libreria:"Font Awesome 4" },
	{key:"fa fa-hospital-o",nombre:"fa fa-hospital-o", libreria:"Font Awesome 4" },
	{key:"fa fa-hotel",nombre:"fa fa-hotel", libreria:"Font Awesome 4" },
	{key:"fa fa-hourglass",nombre:"fa fa-hourglass", libreria:"Font Awesome 4" },
	{key:"fa fa-hourglass-1",nombre:"fa fa-hourglass-1", libreria:"Font Awesome 4" },
	{key:"fa fa-hourglass-2",nombre:"fa fa-hourglass-2", libreria:"Font Awesome 4" },
	{key:"fa fa-hourglass-3",nombre:"fa fa-hourglass-3", libreria:"Font Awesome 4" },
	{key:"fa fa-hourglass-end",nombre:"fa fa-hourglass-end", libreria:"Font Awesome 4" },
	{key:"fa fa-hourglass-half",nombre:"fa fa-hourglass-half", libreria:"Font Awesome 4" },
	{key:"fa fa-hourglass-o",nombre:"fa fa-hourglass-o", libreria:"Font Awesome 4" },
	{key:"fa fa-hourglass-start",nombre:"fa fa-hourglass-start", libreria:"Font Awesome 4" },
	{key:"fa fa-houzz",nombre:"fa fa-houzz", libreria:"Font Awesome 4" },
	{key:"fa fa-html5",nombre:"fa fa-html5", libreria:"Font Awesome 4" },
	{key:"fa fa-i-cursor",nombre:"fa fa-i-cursor", libreria:"Font Awesome 4" },
	{key:"fa fa-id-badge",nombre:"fa fa-id-badge", libreria:"Font Awesome 4" },
	{key:"fa fa-id-card",nombre:"fa fa-id-card", libreria:"Font Awesome 4" },
	{key:"fa fa-id-card-o",nombre:"fa fa-id-card-o", libreria:"Font Awesome 4" },
	{key:"fa fa-ils",nombre:"fa fa-ils", libreria:"Font Awesome 4" },
	{key:"fa fa-image",nombre:"fa fa-image", libreria:"Font Awesome 4" },
	{key:"fa fa-imdb",nombre:"fa fa-imdb", libreria:"Font Awesome 4" },
	{key:"fa fa-inbox",nombre:"fa fa-inbox", libreria:"Font Awesome 4" },
	{key:"fa fa-indent",nombre:"fa fa-indent", libreria:"Font Awesome 4" },
	{key:"fa fa-industry",nombre:"fa fa-industry", libreria:"Font Awesome 4" },
	{key:"fa fa-info",nombre:"fa fa-info", libreria:"Font Awesome 4" },
	{key:"fa fa-info-circle",nombre:"fa fa-info-circle", libreria:"Font Awesome 4" },
	{key:"fa fa-inr",nombre:"fa fa-inr", libreria:"Font Awesome 4" },
	{key:"fa fa-instagram",nombre:"fa fa-instagram", libreria:"Font Awesome 4" },
	{key:"fa fa-institution",nombre:"fa fa-institution", libreria:"Font Awesome 4" },
	{key:"fa fa-internet-explorer",nombre:"fa fa-internet-explorer", libreria:"Font Awesome 4" },
	{key:"fa fa-intersex",nombre:"fa fa-intersex", libreria:"Font Awesome 4" },
	{key:"fa fa-ioxhost",nombre:"fa fa-ioxhost", libreria:"Font Awesome 4" },
	{key:"fa fa-italic",nombre:"fa fa-italic", libreria:"Font Awesome 4" },
	{key:"fa fa-joomla",nombre:"fa fa-joomla", libreria:"Font Awesome 4" },
	{key:"fa fa-jpy",nombre:"fa fa-jpy", libreria:"Font Awesome 4" },
	{key:"fa fa-jsfiddle",nombre:"fa fa-jsfiddle", libreria:"Font Awesome 4" },
	{key:"fa fa-key",nombre:"fa fa-key", libreria:"Font Awesome 4" },
	{key:"fa fa-keyboard-o",nombre:"fa fa-keyboard-o", libreria:"Font Awesome 4" },
	{key:"fa fa-krw",nombre:"fa fa-krw", libreria:"Font Awesome 4" },
	{key:"fa fa-language",nombre:"fa fa-language", libreria:"Font Awesome 4" },
	{key:"fa fa-laptop",nombre:"fa fa-laptop", libreria:"Font Awesome 4" },
	{key:"fa fa-lastfm",nombre:"fa fa-lastfm", libreria:"Font Awesome 4" },
	{key:"fa fa-lastfm-square",nombre:"fa fa-lastfm-square", libreria:"Font Awesome 4" },
	{key:"fa fa-leaf",nombre:"fa fa-leaf", libreria:"Font Awesome 4" },
	{key:"fa fa-leanpub",nombre:"fa fa-leanpub", libreria:"Font Awesome 4" },
	{key:"fa fa-legal",nombre:"fa fa-legal", libreria:"Font Awesome 4" },
	{key:"fa fa-lemon-o",nombre:"fa fa-lemon-o", libreria:"Font Awesome 4" },
	{key:"fa fa-level-down",nombre:"fa fa-level-down", libreria:"Font Awesome 4" },
	{key:"fa fa-level-up",nombre:"fa fa-level-up", libreria:"Font Awesome 4" },
	{key:"fa fa-life-bouy",nombre:"fa fa-life-bouy", libreria:"Font Awesome 4" },
	{key:"fa fa-life-buoy",nombre:"fa fa-life-buoy", libreria:"Font Awesome 4" },
	{key:"fa fa-life-ring",nombre:"fa fa-life-ring", libreria:"Font Awesome 4" },
	{key:"fa fa-life-saver",nombre:"fa fa-life-saver", libreria:"Font Awesome 4" },
	{key:"fa fa-lightbulb-o",nombre:"fa fa-lightbulb-o", libreria:"Font Awesome 4" },
	{key:"fa fa-line-chart",nombre:"fa fa-line-chart", libreria:"Font Awesome 4" },
	{key:"fa fa-link",nombre:"fa fa-link", libreria:"Font Awesome 4" },
	{key:"fa fa-linkedin",nombre:"fa fa-linkedin", libreria:"Font Awesome 4" },
	{key:"fa fa-linkedin-square",nombre:"fa fa-linkedin-square", libreria:"Font Awesome 4" },
	{key:"fa fa-linode",nombre:"fa fa-linode", libreria:"Font Awesome 4" },
	{key:"fa fa-linux",nombre:"fa fa-linux", libreria:"Font Awesome 4" },
	{key:"fa fa-list",nombre:"fa fa-list", libreria:"Font Awesome 4" },
	{key:"fa fa-list-alt",nombre:"fa fa-list-alt", libreria:"Font Awesome 4" },
	{key:"fa fa-list-ol",nombre:"fa fa-list-ol", libreria:"Font Awesome 4" },
	{key:"fa fa-list-ul",nombre:"fa fa-list-ul", libreria:"Font Awesome 4" },
	{key:"fa fa-location-arrow",nombre:"fa fa-location-arrow", libreria:"Font Awesome 4" },
	{key:"fa fa-lock",nombre:"fa fa-lock", libreria:"Font Awesome 4" },
	{key:"fa fa-long-arrow-down",nombre:"fa fa-long-arrow-down", libreria:"Font Awesome 4" },
	{key:"fa fa-long-arrow-left",nombre:"fa fa-long-arrow-left", libreria:"Font Awesome 4" },
	{key:"fa fa-long-arrow-right",nombre:"fa fa-long-arrow-right", libreria:"Font Awesome 4" },
	{key:"fa fa-long-arrow-up",nombre:"fa fa-long-arrow-up", libreria:"Font Awesome 4" },
	{key:"fa fa-low-vision",nombre:"fa fa-low-vision", libreria:"Font Awesome 4" },
	{key:"fa fa-magic",nombre:"fa fa-magic", libreria:"Font Awesome 4" },
	{key:"fa fa-magnet",nombre:"fa fa-magnet", libreria:"Font Awesome 4" },
	{key:"fa fa-mail-forward",nombre:"fa fa-mail-forward", libreria:"Font Awesome 4" },
	{key:"fa fa-mail-reply",nombre:"fa fa-mail-reply", libreria:"Font Awesome 4" },
	{key:"fa fa-mail-reply-all",nombre:"fa fa-mail-reply-all", libreria:"Font Awesome 4" },
	{key:"fa fa-male",nombre:"fa fa-male", libreria:"Font Awesome 4" },
	{key:"fa fa-map",nombre:"fa fa-map", libreria:"Font Awesome 4" },
	{key:"fa fa-map-marker",nombre:"fa fa-map-marker", libreria:"Font Awesome 4" },
	{key:"fa fa-map-o",nombre:"fa fa-map-o", libreria:"Font Awesome 4" },
	{key:"fa fa-map-pin",nombre:"fa fa-map-pin", libreria:"Font Awesome 4" },
	{key:"fa fa-map-signs",nombre:"fa fa-map-signs", libreria:"Font Awesome 4" },
	{key:"fa fa-mars",nombre:"fa fa-mars", libreria:"Font Awesome 4" },
	{key:"fa fa-mars-double",nombre:"fa fa-mars-double", libreria:"Font Awesome 4" },
	{key:"fa fa-mars-stroke",nombre:"fa fa-mars-stroke", libreria:"Font Awesome 4" },
	{key:"fa fa-mars-stroke-h",nombre:"fa fa-mars-stroke-h", libreria:"Font Awesome 4" },
	{key:"fa fa-mars-stroke-v",nombre:"fa fa-mars-stroke-v", libreria:"Font Awesome 4" },
	{key:"fa fa-maxcdn",nombre:"fa fa-maxcdn", libreria:"Font Awesome 4" },
	{key:"fa fa-meanpath",nombre:"fa fa-meanpath", libreria:"Font Awesome 4" },
	{key:"fa fa-medium",nombre:"fa fa-medium", libreria:"Font Awesome 4" },
	{key:"fa fa-medkit",nombre:"fa fa-medkit", libreria:"Font Awesome 4" },
	{key:"fa fa-meetup",nombre:"fa fa-meetup", libreria:"Font Awesome 4" },
	{key:"fa fa-meh-o",nombre:"fa fa-meh-o", libreria:"Font Awesome 4" },
	{key:"fa fa-mercury",nombre:"fa fa-mercury", libreria:"Font Awesome 4" },
	{key:"fa fa-microchip",nombre:"fa fa-microchip", libreria:"Font Awesome 4" },
	{key:"fa fa-microphone",nombre:"fa fa-microphone", libreria:"Font Awesome 4" },
	{key:"fa fa-microphone-slash",nombre:"fa fa-microphone-slash", libreria:"Font Awesome 4" },
	{key:"fa fa-minus",nombre:"fa fa-minus", libreria:"Font Awesome 4" },
	{key:"fa fa-minus-circle",nombre:"fa fa-minus-circle", libreria:"Font Awesome 4" },
	{key:"fa fa-minus-square",nombre:"fa fa-minus-square", libreria:"Font Awesome 4" },
	{key:"fa fa-minus-square-o",nombre:"fa fa-minus-square-o", libreria:"Font Awesome 4" },
	{key:"fa fa-mixcloud",nombre:"fa fa-mixcloud", libreria:"Font Awesome 4" },
	{key:"fa fa-mobile",nombre:"fa fa-mobile", libreria:"Font Awesome 4" },
	{key:"fa fa-mobile-phone",nombre:"fa fa-mobile-phone", libreria:"Font Awesome 4" },
	{key:"fa fa-modx",nombre:"fa fa-modx", libreria:"Font Awesome 4" },
	{key:"fa fa-money",nombre:"fa fa-money", libreria:"Font Awesome 4" },
	{key:"fa fa-moon-o",nombre:"fa fa-moon-o", libreria:"Font Awesome 4" },
	{key:"fa fa-mortar-board",nombre:"fa fa-mortar-board", libreria:"Font Awesome 4" },
	{key:"fa fa-motorcycle",nombre:"fa fa-motorcycle", libreria:"Font Awesome 4" },
	{key:"fa fa-mouse-pointer",nombre:"fa fa-mouse-pointer", libreria:"Font Awesome 4" },
	{key:"fa fa-music",nombre:"fa fa-music", libreria:"Font Awesome 4" },
	{key:"fa fa-navicon",nombre:"fa fa-navicon", libreria:"Font Awesome 4" },
	{key:"fa fa-neuter",nombre:"fa fa-neuter", libreria:"Font Awesome 4" },
	{key:"fa fa-newspaper-o",nombre:"fa fa-newspaper-o", libreria:"Font Awesome 4" },
	{key:"fa fa-object-group",nombre:"fa fa-object-group", libreria:"Font Awesome 4" },
	{key:"fa fa-object-ungroup",nombre:"fa fa-object-ungroup", libreria:"Font Awesome 4" },
	{key:"fa fa-odnoklassniki",nombre:"fa fa-odnoklassniki", libreria:"Font Awesome 4" },
	{key:"fa fa-odnoklassniki-square",nombre:"fa fa-odnoklassniki-square", libreria:"Font Awesome 4" },
	{key:"fa fa-opencart",nombre:"fa fa-opencart", libreria:"Font Awesome 4" },
	{key:"fa fa-openid",nombre:"fa fa-openid", libreria:"Font Awesome 4" },
	{key:"fa fa-opera",nombre:"fa fa-opera", libreria:"Font Awesome 4" },
	{key:"fa fa-optin-monster",nombre:"fa fa-optin-monster", libreria:"Font Awesome 4" },
	{key:"fa fa-outdent",nombre:"fa fa-outdent", libreria:"Font Awesome 4" },
	{key:"fa fa-paint-brush",nombre:"fa fa-paint-brush", libreria:"Font Awesome 4" },
	{key:"fa fa-pagelines",nombre:"fa fa-pagelines", libreria:"Font Awesome 4" },
	{key:"fa fa-paper-plane",nombre:"fa fa-paper-plane", libreria:"Font Awesome 4" },
	{key:"fa fa-paper-plane-o",nombre:"fa fa-paper-plane-o", libreria:"Font Awesome 4" },
	{key:"fa fa-paperclip",nombre:"fa fa-paperclip", libreria:"Font Awesome 4" },
	{key:"fa fa-paragraph",nombre:"fa fa-paragraph", libreria:"Font Awesome 4" },
	{key:"fa fa-paste",nombre:"fa fa-paste", libreria:"Font Awesome 4" },
	{key:"fa fa-pause",nombre:"fa fa-pause", libreria:"Font Awesome 4" },
	{key:"fa fa-pause-circle",nombre:"fa fa-pause-circle", libreria:"Font Awesome 4" },
	{key:"fa fa-pause-circle-o",nombre:"fa fa-pause-circle-o", libreria:"Font Awesome 4" },
	{key:"fa fa-paw",nombre:"fa fa-paw", libreria:"Font Awesome 4" },
	{key:"fa fa-paypal",nombre:"fa fa-paypal", libreria:"Font Awesome 4" },
	{key:"fa fa-pencil",nombre:"fa fa-pencil", libreria:"Font Awesome 4" },
	{key:"fa fa-pencil-square",nombre:"fa fa-pencil-square", libreria:"Font Awesome 4" },
	{key:"fa fa-pencil-square-o",nombre:"fa fa-pencil-square-o", libreria:"Font Awesome 4" },
	{key:"fa fa-percent",nombre:"fa fa-percent", libreria:"Font Awesome 4" },
	{key:"fa fa-phone",nombre:"fa fa-phone", libreria:"Font Awesome 4" },
	{key:"fa fa-phone-square",nombre:"fa fa-phone-square", libreria:"Font Awesome 4" },
	{key:"fa fa-photo",nombre:"fa fa-photo", libreria:"Font Awesome 4" },
	{key:"fa fa-picture-o",nombre:"fa fa-picture-o", libreria:"Font Awesome 4" },
	{key:"fa fa-pie-chart",nombre:"fa fa-pie-chart", libreria:"Font Awesome 4" },
	{key:"fa fa-pied-piper",nombre:"fa fa-pied-piper", libreria:"Font Awesome 4" },
	{key:"fa fa-pied-piper-alt",nombre:"fa fa-pied-piper-alt", libreria:"Font Awesome 4" },
	{key:"fa fa-pied-piper-pp",nombre:"fa fa-pied-piper-pp", libreria:"Font Awesome 4" },
	{key:"fa fa-pinterest",nombre:"fa fa-pinterest", libreria:"Font Awesome 4" },
	{key:"fa fa-pinterest-p",nombre:"fa fa-pinterest-p", libreria:"Font Awesome 4" },
	{key:"fa fa-pinterest-square",nombre:"fa fa-pinterest-square", libreria:"Font Awesome 4" },
	{key:"fa fa-plane",nombre:"fa fa-plane", libreria:"Font Awesome 4" },
	{key:"fa fa-play",nombre:"fa fa-play", libreria:"Font Awesome 4" },
	{key:"fa fa-play-circle",nombre:"fa fa-play-circle", libreria:"Font Awesome 4" },
	{key:"fa fa-play-circle-o",nombre:"fa fa-play-circle-o", libreria:"Font Awesome 4" },
	{key:"fa fa-plug",nombre:"fa fa-plug", libreria:"Font Awesome 4" },
	{key:"fa fa-plus",nombre:"fa fa-plus", libreria:"Font Awesome 4" },
	{key:"fa fa-plus-circle",nombre:"fa fa-plus-circle", libreria:"Font Awesome 4" },
	{key:"fa fa-plus-square",nombre:"fa fa-plus-square", libreria:"Font Awesome 4" },
	{key:"fa fa-plus-square-o",nombre:"fa fa-plus-square-o", libreria:"Font Awesome 4" },
	{key:"fa fa-podcast",nombre:"fa fa-podcast", libreria:"Font Awesome 4" },
	{key:"fa fa-power-off",nombre:"fa fa-power-off", libreria:"Font Awesome 4" },
	{key:"fa fa-print",nombre:"fa fa-print", libreria:"Font Awesome 4" },
	{key:"fa fa-product-hunt",nombre:"fa fa-product-hunt", libreria:"Font Awesome 4" },
	{key:"fa fa-puzzle-piece",nombre:"fa fa-puzzle-piece", libreria:"Font Awesome 4" },
	{key:"fa fa-qq",nombre:"fa fa-qq", libreria:"Font Awesome 4" },
	{key:"fa fa-qrcode",nombre:"fa fa-qrcode", libreria:"Font Awesome 4" },
	{key:"fa fa-question",nombre:"fa fa-question", libreria:"Font Awesome 4" },
	{key:"fa fa-question-circle",nombre:"fa fa-question-circle", libreria:"Font Awesome 4" },
	{key:"fa fa-question-circle-o",nombre:"fa fa-question-circle-o", libreria:"Font Awesome 4" },
	{key:"fa fa-quora",nombre:"fa fa-quora", libreria:"Font Awesome 4" },
	{key:"fa fa-quote-left",nombre:"fa fa-quote-left", libreria:"Font Awesome 4" },
	{key:"fa fa-quote-right",nombre:"fa fa-quote-right", libreria:"Font Awesome 4" },
	{key:"fa fa-ra",nombre:"fa fa-ra", libreria:"Font Awesome 4" },
	{key:"fa fa-random",nombre:"fa fa-random", libreria:"Font Awesome 4" },
	{key:"fa fa-ravelry",nombre:"fa fa-ravelry", libreria:"Font Awesome 4" },
	{key:"fa fa-rebel",nombre:"fa fa-rebel", libreria:"Font Awesome 4" },
	{key:"fa fa-recycle",nombre:"fa fa-recycle", libreria:"Font Awesome 4" },
	{key:"fa fa-reddit",nombre:"fa fa-reddit", libreria:"Font Awesome 4" },
	{key:"fa fa-reddit-alien",nombre:"fa fa-reddit-alien", libreria:"Font Awesome 4" },
	{key:"fa fa-reddit-square",nombre:"fa fa-reddit-square", libreria:"Font Awesome 4" },
	{key:"fa fa-refresh",nombre:"fa fa-refresh", libreria:"Font Awesome 4" },
	{key:"fa fa-registered",nombre:"fa fa-registered", libreria:"Font Awesome 4" },
	{key:"fa fa-remove",nombre:"fa fa-remove", libreria:"Font Awesome 4" },
	{key:"fa fa-renren",nombre:"fa fa-renren", libreria:"Font Awesome 4" },
	{key:"fa fa-reorder",nombre:"fa fa-reorder", libreria:"Font Awesome 4" },
	{key:"fa fa-repeat",nombre:"fa fa-repeat", libreria:"Font Awesome 4" },
	{key:"fa fa-reply",nombre:"fa fa-reply", libreria:"Font Awesome 4" },
	{key:"fa fa-reply-all",nombre:"fa fa-reply-all", libreria:"Font Awesome 4" },
	{key:"fa fa-resistance",nombre:"fa fa-resistance", libreria:"Font Awesome 4" },
	{key:"fa fa-retweet",nombre:"fa fa-retweet", libreria:"Font Awesome 4" },
	{key:"fa fa-rmb",nombre:"fa fa-rmb", libreria:"Font Awesome 4" },
	{key:"fa fa-road",nombre:"fa fa-road", libreria:"Font Awesome 4" },
	{key:"fa fa-rocket",nombre:"fa fa-rocket", libreria:"Font Awesome 4" },
	{key:"fa fa-rotate-left",nombre:"fa fa-rotate-left", libreria:"Font Awesome 4" },
	{key:"fa fa-rotate-right",nombre:"fa fa-rotate-right", libreria:"Font Awesome 4" },
	{key:"fa fa-rouble",nombre:"fa fa-rouble", libreria:"Font Awesome 4" },
	{key:"fa fa-rss",nombre:"fa fa-rss", libreria:"Font Awesome 4" },
	{key:"fa fa-rss-square",nombre:"fa fa-rss-square", libreria:"Font Awesome 4" },
	{key:"fa fa-rub",nombre:"fa fa-rub", libreria:"Font Awesome 4" },
	{key:"fa fa-ruble",nombre:"fa fa-ruble", libreria:"Font Awesome 4" },
	{key:"fa fa-rupee",nombre:"fa fa-rupee", libreria:"Font Awesome 4" },
	{key:"fa fa-s15",nombre:"fa fa-s15", libreria:"Font Awesome 4" },
	{key:"fa fa-safari",nombre:"fa fa-safari", libreria:"Font Awesome 4" },
	{key:"fa fa-save",nombre:"fa fa-save", libreria:"Font Awesome 4" },
	{key:"fa fa-scissors",nombre:"fa fa-scissors", libreria:"Font Awesome 4" },
	{key:"fa fa-scribd",nombre:"fa fa-scribd", libreria:"Font Awesome 4" },
	{key:"fa fa-search",nombre:"fa fa-search", libreria:"Font Awesome 4" },
	{key:"fa fa-search-minus",nombre:"fa fa-search-minus", libreria:"Font Awesome 4" },
	{key:"fa fa-search-plus",nombre:"fa fa-search-plus", libreria:"Font Awesome 4" },
	{key:"fa fa-sellsy",nombre:"fa fa-sellsy", libreria:"Font Awesome 4" },
	{key:"fa fa-send",nombre:"fa fa-send", libreria:"Font Awesome 4" },
	{key:"fa fa-send-o",nombre:"fa fa-send-o", libreria:"Font Awesome 4" },
	{key:"fa fa-server",nombre:"fa fa-server", libreria:"Font Awesome 4" },
	{key:"fa fa-share",nombre:"fa fa-share", libreria:"Font Awesome 4" },
	{key:"fa fa-share-alt",nombre:"fa fa-share-alt", libreria:"Font Awesome 4" },
	{key:"fa fa-share-alt-square",nombre:"fa fa-share-alt-square", libreria:"Font Awesome 4" },
	{key:"fa fa-share-square",nombre:"fa fa-share-square", libreria:"Font Awesome 4" },
	{key:"fa fa-share-square-o",nombre:"fa fa-share-square-o", libreria:"Font Awesome 4" },
	{key:"fa fa-shekel",nombre:"fa fa-shekel", libreria:"Font Awesome 4" },
	{key:"fa fa-sheqel",nombre:"fa fa-sheqel", libreria:"Font Awesome 4" },
	{key:"fa fa-shield",nombre:"fa fa-shield", libreria:"Font Awesome 4" },
	{key:"fa fa-ship",nombre:"fa fa-ship", libreria:"Font Awesome 4" },
	{key:"fa fa-shirtsinbulk",nombre:"fa fa-shirtsinbulk", libreria:"Font Awesome 4" },
	{key:"fa fa-shopping-bag",nombre:"fa fa-shopping-bag", libreria:"Font Awesome 4" },
	{key:"fa fa-shopping-basket",nombre:"fa fa-shopping-basket", libreria:"Font Awesome 4" },
	{key:"fa fa-shopping-cart",nombre:"fa fa-shopping-cart", libreria:"Font Awesome 4" },
	{key:"fa fa-shower",nombre:"fa fa-shower", libreria:"Font Awesome 4" },
	{key:"fa fa-sign-in",nombre:"fa fa-sign-in", libreria:"Font Awesome 4" },
	{key:"fa fa-sign-language",nombre:"fa fa-sign-language", libreria:"Font Awesome 4" },
	{key:"fa fa-sign-out",nombre:"fa fa-sign-out", libreria:"Font Awesome 4" },
	{key:"fa fa-signal",nombre:"fa fa-signal", libreria:"Font Awesome 4" },
	{key:"fa fa-signing",nombre:"fa fa-signing", libreria:"Font Awesome 4" },
	{key:"fa fa-simplybuilt",nombre:"fa fa-simplybuilt", libreria:"Font Awesome 4" },
	{key:"fa fa-sitemap",nombre:"fa fa-sitemap", libreria:"Font Awesome 4" },
	{key:"fa fa-skyatlas",nombre:"fa fa-skyatlas", libreria:"Font Awesome 4" },
	{key:"fa fa-skype",nombre:"fa fa-skype", libreria:"Font Awesome 4" },
	{key:"fa fa-slack",nombre:"fa fa-slack", libreria:"Font Awesome 4" },
	{key:"fa fa-sliders",nombre:"fa fa-sliders", libreria:"Font Awesome 4" },
	{key:"fa fa-slideshare",nombre:"fa fa-slideshare", libreria:"Font Awesome 4" },
	{key:"fa fa-smile-o",nombre:"fa fa-smile-o", libreria:"Font Awesome 4" },
	{key:"fa fa-snapchat",nombre:"fa fa-snapchat", libreria:"Font Awesome 4" },
	{key:"fa fa-snapchat-ghost",nombre:"fa fa-snapchat-ghost", libreria:"Font Awesome 4" },
	{key:"fa fa-snapchat-square",nombre:"fa fa-snapchat-square", libreria:"Font Awesome 4" },
	{key:"fa fa-snowflake-o",nombre:"fa fa-snowflake-o", libreria:"Font Awesome 4" },
	{key:"fa fa-soccer-ball-o",nombre:"fa fa-soccer-ball-o", libreria:"Font Awesome 4" },
	{key:"fa fa-sort",nombre:"fa fa-sort", libreria:"Font Awesome 4" },
	{key:"fa fa-sort-alpha-asc",nombre:"fa fa-sort-alpha-asc", libreria:"Font Awesome 4" },
	{key:"fa fa-sort-alpha-desc",nombre:"fa fa-sort-alpha-desc", libreria:"Font Awesome 4" },
	{key:"fa fa-sort-amount-asc",nombre:"fa fa-sort-amount-asc", libreria:"Font Awesome 4" },
	{key:"fa fa-sort-amount-desc",nombre:"fa fa-sort-amount-desc", libreria:"Font Awesome 4" },
	{key:"fa fa-sort-asc",nombre:"fa fa-sort-asc", libreria:"Font Awesome 4" },
	{key:"fa fa-sort-desc",nombre:"fa fa-sort-desc", libreria:"Font Awesome 4" },
	{key:"fa fa-sort-down",nombre:"fa fa-sort-down", libreria:"Font Awesome 4" },
	{key:"fa fa-sort-numeric-asc",nombre:"fa fa-sort-numeric-asc", libreria:"Font Awesome 4" },
	{key:"fa fa-sort-numeric-desc",nombre:"fa fa-sort-numeric-desc", libreria:"Font Awesome 4" },
	{key:"fa fa-sort-up",nombre:"fa fa-sort-up", libreria:"Font Awesome 4" },
	{key:"fa fa-soundcloud",nombre:"fa fa-soundcloud", libreria:"Font Awesome 4" },
	{key:"fa fa-space-shuttle",nombre:"fa fa-space-shuttle", libreria:"Font Awesome 4" },
	{key:"fa fa-spinner",nombre:"fa fa-spinner", libreria:"Font Awesome 4" },
	{key:"fa fa-spoon",nombre:"fa fa-spoon", libreria:"Font Awesome 4" },
	{key:"fa fa-spotify",nombre:"fa fa-spotify", libreria:"Font Awesome 4" },
	{key:"fa fa-square",nombre:"fa fa-square", libreria:"Font Awesome 4" },
	{key:"fa fa-square-o",nombre:"fa fa-square-o", libreria:"Font Awesome 4" },
	{key:"fa fa-stack-exchange",nombre:"fa fa-stack-exchange", libreria:"Font Awesome 4" },
	{key:"fa fa-stack-overflow",nombre:"fa fa-stack-overflow", libreria:"Font Awesome 4" },
	{key:"fa fa-star",nombre:"fa fa-star", libreria:"Font Awesome 4" },
	{key:"fa fa-star-half",nombre:"fa fa-star-half", libreria:"Font Awesome 4" },
	{key:"fa fa-star-half-empty",nombre:"fa fa-star-half-empty", libreria:"Font Awesome 4" },
	{key:"fa fa-star-half-full",nombre:"fa fa-star-half-full", libreria:"Font Awesome 4" },
	{key:"fa fa-star-half-o",nombre:"fa fa-star-half-o", libreria:"Font Awesome 4" },
	{key:"fa fa-star-o",nombre:"fa fa-star-o", libreria:"Font Awesome 4" },
	{key:"fa fa-steam",nombre:"fa fa-steam", libreria:"Font Awesome 4" },
	{key:"fa fa-steam-square",nombre:"fa fa-steam-square", libreria:"Font Awesome 4" },
	{key:"fa fa-step-backward",nombre:"fa fa-step-backward", libreria:"Font Awesome 4" },
	{key:"fa fa-step-forward",nombre:"fa fa-step-forward", libreria:"Font Awesome 4" },
	{key:"fa fa-stethoscope",nombre:"fa fa-stethoscope", libreria:"Font Awesome 4" },
	{key:"fa fa-sticky-note",nombre:"fa fa-sticky-note", libreria:"Font Awesome 4" },
	{key:"fa fa-sticky-note-o",nombre:"fa fa-sticky-note-o", libreria:"Font Awesome 4" },
	{key:"fa fa-stop",nombre:"fa fa-stop", libreria:"Font Awesome 4" },
	{key:"fa fa-stop-circle",nombre:"fa fa-stop-circle", libreria:"Font Awesome 4" },
	{key:"fa fa-stop-circle-o",nombre:"fa fa-stop-circle-o", libreria:"Font Awesome 4" },
	{key:"fa fa-street-view",nombre:"fa fa-street-view", libreria:"Font Awesome 4" },
	{key:"fa fa-strikethrough",nombre:"fa fa-strikethrough", libreria:"Font Awesome 4" },
	{key:"fa fa-stumbleupon",nombre:"fa fa-stumbleupon", libreria:"Font Awesome 4" },
	{key:"fa fa-stumbleupon-circle",nombre:"fa fa-stumbleupon-circle", libreria:"Font Awesome 4" },
	{key:"fa fa-subscript",nombre:"fa fa-subscript", libreria:"Font Awesome 4" },
	{key:"fa fa-subway",nombre:"fa fa-subway", libreria:"Font Awesome 4" },
	{key:"fa fa-suitcase",nombre:"fa fa-suitcase", libreria:"Font Awesome 4" },
	{key:"fa fa-sun-o",nombre:"fa fa-sun-o", libreria:"Font Awesome 4" },
	{key:"fa fa-superpowers",nombre:"fa fa-superpowers", libreria:"Font Awesome 4" },
	{key:"fa fa-superscript",nombre:"fa fa-superscript", libreria:"Font Awesome 4" },
	{key:"fa fa-support",nombre:"fa fa-support", libreria:"Font Awesome 4" },
	{key:"fa fa-table",nombre:"fa fa-table", libreria:"Font Awesome 4" },
	{key:"fa fa-tablet",nombre:"fa fa-tablet", libreria:"Font Awesome 4" },
	{key:"fa fa-tachometer",nombre:"fa fa-tachometer", libreria:"Font Awesome 4" },
	{key:"fa fa-tag",nombre:"fa fa-tag", libreria:"Font Awesome 4" },
	{key:"fa fa-tags",nombre:"fa fa-tags", libreria:"Font Awesome 4" },
	{key:"fa fa-tasks",nombre:"fa fa-tasks", libreria:"Font Awesome 4" },
	{key:"fa fa-taxi",nombre:"fa fa-taxi", libreria:"Font Awesome 4" },
	{key:"fa fa-telegram",nombre:"fa fa-telegram", libreria:"Font Awesome 4" },
	{key:"fa fa-television",nombre:"fa fa-television", libreria:"Font Awesome 4" },
	{key:"fa fa-tencent-weibo",nombre:"fa fa-tencent-weibo", libreria:"Font Awesome 4" },
	{key:"fa fa-terminal",nombre:"fa fa-terminal", libreria:"Font Awesome 4" },
	{key:"fa fa-text-height",nombre:"fa fa-text-height", libreria:"Font Awesome 4" },
	{key:"fa fa-text-width",nombre:"fa fa-text-width", libreria:"Font Awesome 4" },
	{key:"fa fa-th",nombre:"fa fa-th", libreria:"Font Awesome 4" },
	{key:"fa fa-th-large",nombre:"fa fa-th-large", libreria:"Font Awesome 4" },
	{key:"fa fa-th-list",nombre:"fa fa-th-list", libreria:"Font Awesome 4" },
	{key:"fa fa-themeisle",nombre:"fa fa-themeisle", libreria:"Font Awesome 4" },
	{key:"fa fa-thermometer",nombre:"fa fa-thermometer", libreria:"Font Awesome 4" },
	{key:"fa fa-thermometer-0",nombre:"fa fa-thermometer-0", libreria:"Font Awesome 4" },
	{key:"fa fa-thermometer-1",nombre:"fa fa-thermometer-1", libreria:"Font Awesome 4" },
	{key:"fa fa-thermometer-2",nombre:"fa fa-thermometer-2", libreria:"Font Awesome 4" },
	{key:"fa fa-thermometer-3",nombre:"fa fa-thermometer-3", libreria:"Font Awesome 4" },
	{key:"fa fa-thermometer-4",nombre:"fa fa-thermometer-4", libreria:"Font Awesome 4" },
	{key:"fa fa-thermometer-empty",nombre:"fa fa-thermometer-empty", libreria:"Font Awesome 4" },
	{key:"fa fa-thermometer-full",nombre:"fa fa-thermometer-full", libreria:"Font Awesome 4" },
	{key:"fa fa-thermometer-half",nombre:"fa fa-thermometer-half", libreria:"Font Awesome 4" },
	{key:"fa fa-thermometer-quarter",nombre:"fa fa-thermometer-quarter", libreria:"Font Awesome 4" },
	{key:"fa fa-thermometer-three-quarters",nombre:"fa fa-thermometer-three-quarters", libreria:"Font Awesome 4" },
	{key:"fa fa-thumb-tack",nombre:"fa fa-thumb-tack", libreria:"Font Awesome 4" },
	{key:"fa fa-thumbs-down",nombre:"fa fa-thumbs-down", libreria:"Font Awesome 4" },
	{key:"fa fa-thumbs-o-down",nombre:"fa fa-thumbs-o-down", libreria:"Font Awesome 4" },
	{key:"fa fa-thumbs-o-up",nombre:"fa fa-thumbs-o-up", libreria:"Font Awesome 4" },
	{key:"fa fa-thumbs-up",nombre:"fa fa-thumbs-up", libreria:"Font Awesome 4" },
	{key:"fa fa-ticket",nombre:"fa fa-ticket", libreria:"Font Awesome 4" },
	{key:"fa fa-times",nombre:"fa fa-times", libreria:"Font Awesome 4" },
	{key:"fa fa-times-circle",nombre:"fa fa-times-circle", libreria:"Font Awesome 4" },
	{key:"fa fa-times-circle-o",nombre:"fa fa-times-circle-o", libreria:"Font Awesome 4" },
	{key:"fa fa-times-rectangle",nombre:"fa fa-times-rectangle", libreria:"Font Awesome 4" },
	{key:"fa fa-times-rectangle-o",nombre:"fa fa-times-rectangle-o", libreria:"Font Awesome 4" },
	{key:"fa fa-tint",nombre:"fa fa-tint", libreria:"Font Awesome 4" },
	{key:"fa fa-toggle-down",nombre:"fa fa-toggle-down", libreria:"Font Awesome 4" },
	{key:"fa fa-toggle-left",nombre:"fa fa-toggle-left", libreria:"Font Awesome 4" },
	{key:"fa fa-toggle-off",nombre:"fa fa-toggle-off", libreria:"Font Awesome 4" },
	{key:"fa fa-toggle-on",nombre:"fa fa-toggle-on", libreria:"Font Awesome 4" },
	{key:"fa fa-toggle-right",nombre:"fa fa-toggle-right", libreria:"Font Awesome 4" },
	{key:"fa fa-toggle-up",nombre:"fa fa-toggle-up", libreria:"Font Awesome 4" },
	{key:"fa fa-trademark",nombre:"fa fa-trademark", libreria:"Font Awesome 4" },
	{key:"fa fa-train",nombre:"fa fa-train", libreria:"Font Awesome 4" },
	{key:"fa fa-transgender",nombre:"fa fa-transgender", libreria:"Font Awesome 4" },
	{key:"fa fa-transgender-alt",nombre:"fa fa-transgender-alt", libreria:"Font Awesome 4" },
	{key:"fa fa-trash",nombre:"fa fa-trash", libreria:"Font Awesome 4" },
	{key:"fa fa-trash-o",nombre:"fa fa-trash-o", libreria:"Font Awesome 4" },
	{key:"fa fa-tree",nombre:"fa fa-tree", libreria:"Font Awesome 4" },
	{key:"fa fa-trello",nombre:"fa fa-trello", libreria:"Font Awesome 4" },
	{key:"fa fa-tripadvisor",nombre:"fa fa-tripadvisor", libreria:"Font Awesome 4" },
	{key:"fa fa-trophy",nombre:"fa fa-trophy", libreria:"Font Awesome 4" },
	{key:"fa fa-truck",nombre:"fa fa-truck", libreria:"Font Awesome 4" },
	{key:"fa fa-try",nombre:"fa fa-try", libreria:"Font Awesome 4" },
	{key:"fa fa-tty",nombre:"fa fa-tty", libreria:"Font Awesome 4" },
	{key:"fa fa-tumblr",nombre:"fa fa-tumblr", libreria:"Font Awesome 4" },
	{key:"fa fa-tumblr-square",nombre:"fa fa-tumblr-square", libreria:"Font Awesome 4" },
	{key:"fa fa-turkish-lira",nombre:"fa fa-turkish-lira", libreria:"Font Awesome 4" },
	{key:"fa fa-tv",nombre:"fa fa-tv", libreria:"Font Awesome 4" },
	{key:"fa fa-twitch",nombre:"fa fa-twitch", libreria:"Font Awesome 4" },
	{key:"fa fa-twitter",nombre:"fa fa-twitter", libreria:"Font Awesome 4" },
	{key:"fa fa-twitter-square",nombre:"fa fa-twitter-square", libreria:"Font Awesome 4" },
	{key:"fa fa-umbrella",nombre:"fa fa-umbrella", libreria:"Font Awesome 4" },
	{key:"fa fa-underline",nombre:"fa fa-underline", libreria:"Font Awesome 4" },
	{key:"fa fa-undo",nombre:"fa fa-undo", libreria:"Font Awesome 4" },
	{key:"fa fa-universal-access",nombre:"fa fa-universal-access", libreria:"Font Awesome 4" },
	{key:"fa fa-university",nombre:"fa fa-university", libreria:"Font Awesome 4" },
	{key:"fa fa-unlink",nombre:"fa fa-unlink", libreria:"Font Awesome 4" },
	{key:"fa fa-unlock",nombre:"fa fa-unlock", libreria:"Font Awesome 4" },
	{key:"fa fa-unlock-alt",nombre:"fa fa-unlock-alt", libreria:"Font Awesome 4" },
	{key:"fa fa-unsorted",nombre:"fa fa-unsorted", libreria:"Font Awesome 4" },
	{key:"fa fa-upload",nombre:"fa fa-upload", libreria:"Font Awesome 4" },
	{key:"fa fa-usb",nombre:"fa fa-usb", libreria:"Font Awesome 4" },
	{key:"fa fa-usd",nombre:"fa fa-usd", libreria:"Font Awesome 4" },
	{key:"fa fa-user",nombre:"fa fa-user", libreria:"Font Awesome 4" },
	{key:"fa fa-user-circle",nombre:"fa fa-user-circle", libreria:"Font Awesome 4" },
	{key:"fa fa-user-circle-o",nombre:"fa fa-user-circle-o", libreria:"Font Awesome 4" },
	{key:"fa fa-user-md",nombre:"fa fa-user-md", libreria:"Font Awesome 4" },
	{key:"fa fa-user-o",nombre:"fa fa-user-o", libreria:"Font Awesome 4" },
	{key:"fa fa-user-plus",nombre:"fa fa-user-plus", libreria:"Font Awesome 4" },
	{key:"fa fa-user-secret",nombre:"fa fa-user-secret", libreria:"Font Awesome 4" },
	{key:"fa fa-user-times",nombre:"fa fa-user-times", libreria:"Font Awesome 4" },
	{key:"fa fa-users",nombre:"fa fa-users", libreria:"Font Awesome 4" },
	{key:"fa fa-vcard",nombre:"fa fa-vcard", libreria:"Font Awesome 4" },
	{key:"fa fa-vcard-o",nombre:"fa fa-vcard-o", libreria:"Font Awesome 4" },
	{key:"fa fa-venus",nombre:"fa fa-venus", libreria:"Font Awesome 4" },
	{key:"fa fa-venus-double",nombre:"fa fa-venus-double", libreria:"Font Awesome 4" },
	{key:"fa fa-venus-mars",nombre:"fa fa-venus-mars", libreria:"Font Awesome 4" },
	{key:"fa fa-viacoin",nombre:"fa fa-viacoin", libreria:"Font Awesome 4" },
	{key:"fa fa-viadeo",nombre:"fa fa-viadeo", libreria:"Font Awesome 4" },
	{key:"fa fa-viadeo-square",nombre:"fa fa-viadeo-square", libreria:"Font Awesome 4" },
	{key:"fa fa-video-camera",nombre:"fa fa-video-camera", libreria:"Font Awesome 4" },
	{key:"fa fa-vimeo",nombre:"fa fa-vimeo", libreria:"Font Awesome 4" },
	{key:"fa fa-vimeo-square",nombre:"fa fa-vimeo-square", libreria:"Font Awesome 4" },
	{key:"fa fa-vine",nombre:"fa fa-vine", libreria:"Font Awesome 4" },
	{key:"fa fa-vk",nombre:"fa fa-vk", libreria:"Font Awesome 4" },
	{key:"fa fa-volume-control-phone",nombre:"fa fa-volume-control-phone", libreria:"Font Awesome 4" },
	{key:"fa fa-volume-down",nombre:"fa fa-volume-down", libreria:"Font Awesome 4" },
	{key:"fa fa-volume-off",nombre:"fa fa-volume-off", libreria:"Font Awesome 4" },
	{key:"fa fa-volume-up",nombre:"fa fa-volume-up", libreria:"Font Awesome 4" },
	{key:"fa fa-warning",nombre:"fa fa-warning", libreria:"Font Awesome 4" },
	{key:"fa fa-wechat",nombre:"fa fa-wechat", libreria:"Font Awesome 4" },
	{key:"fa fa-weibo",nombre:"fa fa-weibo", libreria:"Font Awesome 4" },
	{key:"fa fa-weixin",nombre:"fa fa-weixin", libreria:"Font Awesome 4" },
	{key:"fa fa-whatsapp",nombre:"fa fa-whatsapp", libreria:"Font Awesome 4" },
	{key:"fa fa-wheelchair",nombre:"fa fa-wheelchair", libreria:"Font Awesome 4" },
	{key:"fa fa-wheelchair-alt",nombre:"fa fa-wheelchair-alt", libreria:"Font Awesome 4" },
	{key:"fa fa-wifi",nombre:"fa fa-wifi", libreria:"Font Awesome 4" },
	{key:"fa fa-wikipedia-w",nombre:"fa fa-wikipedia-w", libreria:"Font Awesome 4" },
	{key:"fa fa-window-close",nombre:"fa fa-window-close", libreria:"Font Awesome 4" },
	{key:"fa fa-window-close-o",nombre:"fa fa-window-close-o", libreria:"Font Awesome 4" },
	{key:"fa fa-window-maximize",nombre:"fa fa-window-maximize", libreria:"Font Awesome 4" },
	{key:"fa fa-window-minimize",nombre:"fa fa-window-minimize", libreria:"Font Awesome 4" },
	{key:"fa fa-window-restore",nombre:"fa fa-window-restore", libreria:"Font Awesome 4" },
	{key:"fa fa-windows",nombre:"fa fa-windows", libreria:"Font Awesome 4" },
	{key:"fa fa-won",nombre:"fa fa-won", libreria:"Font Awesome 4" },
	{key:"fa fa-wordpress",nombre:"fa fa-wordpress", libreria:"Font Awesome 4" },
	{key:"fa fa-wpbeginner",nombre:"fa fa-wpbeginner", libreria:"Font Awesome 4" },
	{key:"fa fa-wpexplorer",nombre:"fa fa-wpexplorer", libreria:"Font Awesome 4" },
	{key:"fa fa-wpforms",nombre:"fa fa-wpforms", libreria:"Font Awesome 4" },
	{key:"fa fa-wrench",nombre:"fa fa-wrench", libreria:"Font Awesome 4" },
	{key:"fa fa-xing",nombre:"fa fa-xing", libreria:"Font Awesome 4" },
	{key:"fa fa-xing-square",nombre:"fa fa-xing-square", libreria:"Font Awesome 4" },
	{key:"fa fa-y-combinator",nombre:"fa fa-y-combinator", libreria:"Font Awesome 4" },
	{key:"fa fa-y-combinator-square",nombre:"fa fa-y-combinator-square", libreria:"Font Awesome 4" },
	{key:"fa fa-yahoo",nombre:"fa fa-yahoo", libreria:"Font Awesome 4" },
	{key:"fa fa-yc",nombre:"fa fa-yc", libreria:"Font Awesome 4" },
	{key:"fa fa-yc-square",nombre:"fa fa-yc-square", libreria:"Font Awesome 4" },
	{key:"fa fa-yelp",nombre:"fa fa-yelp", libreria:"Font Awesome 4" },
	{key:"fa fa-yen",nombre:"fa fa-yen", libreria:"Font Awesome 4" },
	{key:"fa fa-yoast",nombre:"fa fa-yoast", libreria:"Font Awesome 4" },
	{key:"fa fa-youtube",nombre:"fa fa-youtube", libreria:"Font Awesome 4" },
	{key:"fa fa-youtube-play",nombre:"fa fa-youtube-play", libreria:"Font Awesome 4" },
	{key:"fa fa-youtube-square",nombre:"fa fa-youtube-square", libreria:"Font Awesome 4" },

	{key:"mdi mdi-access-point",nombre:"mdi mdi-access-point", libreria:"Material Design Iconos" },
	{key:"mdi mdi-access-point-network",nombre:"mdi mdi-access-point-network", libreria:"Material Design Iconos" },
	{key:"mdi mdi-account",nombre:"mdi mdi-account", libreria:"Material Design Iconos" },
	{key:"mdi mdi-account-alert",nombre:"mdi mdi-account-alert", libreria:"Material Design Iconos" },
	{key:"mdi mdi-account-box",nombre:"mdi mdi-account-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-account-box-outline",nombre:"mdi mdi-account-box-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-account-card-details",nombre:"mdi mdi-account-card-details", libreria:"Material Design Iconos" },
	{key:"mdi mdi-account-check",nombre:"mdi mdi-account-check", libreria:"Material Design Iconos" },
	{key:"mdi mdi-account-circle",nombre:"mdi mdi-account-circle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-account-convert",nombre:"mdi mdi-account-convert", libreria:"Material Design Iconos" },
	{key:"mdi mdi-account-edit",nombre:"mdi mdi-account-edit", libreria:"Material Design Iconos" },
	{key:"mdi mdi-account-key",nombre:"mdi mdi-account-key", libreria:"Material Design Iconos" },	
	{key:"mdi mdi-account-minus",nombre:"mdi mdi-account-minus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-account-multiple",nombre:"mdi mdi-account-multiple", libreria:"Material Design Iconos" },
	{key:"mdi mdi-account-multiple-minus",nombre:"mdi mdi-account-multiple-minus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-account-multiple-outline",nombre:"mdi mdi-account-multiple-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-account-multiple-plus",nombre:"mdi mdi-account-multiple-plus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-account-network",nombre:"mdi mdi-account-network", libreria:"Material Design Iconos" },
	{key:"mdi mdi-account-off",nombre:"mdi mdi-account-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-account-outline",nombre:"mdi mdi-account-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-account-plus",nombre:"mdi mdi-account-plus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-account-remove",nombre:"mdi mdi-account-remove", libreria:"Material Design Iconos" },
	{key:"mdi mdi-account-search",nombre:"mdi mdi-account-search", libreria:"Material Design Iconos" },
	{key:"mdi mdi-account-settings",nombre:"mdi mdi-account-settings", libreria:"Material Design Iconos" },
	{key:"mdi mdi-account-star",nombre:"mdi mdi-account-star", libreria:"Material Design Iconos" },
	{key:"mdi mdi-account-switch",nombre:"mdi mdi-account-switch", libreria:"Material Design Iconos" },
	{key:"mdi mdi-adjust",nombre:"mdi mdi-adjust", libreria:"Material Design Iconos" },
	{key:"mdi mdi-air-conditioner",nombre:"mdi mdi-air-conditioner", libreria:"Material Design Iconos" },
	{key:"mdi mdi-airballoon",nombre:"mdi mdi-airballoon", libreria:"Material Design Iconos" },
	{key:"mdi mdi-airplane",nombre:"mdi mdi-airplane", libreria:"Material Design Iconos" },
	{key:"mdi mdi-airplane-landing",nombre:"mdi mdi-airplane-landing", libreria:"Material Design Iconos" },
	{key:"mdi mdi-airplane-off",nombre:"mdi mdi-airplane-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-airplane-takeoff",nombre:"mdi mdi-airplane-takeoff", libreria:"Material Design Iconos" },
	{key:"mdi mdi-airplay",nombre:"mdi mdi-airplay", libreria:"Material Design Iconos" },
	{key:"mdi mdi-alarm",nombre:"mdi mdi-alarm", libreria:"Material Design Iconos" },
	{key:"mdi mdi-alarm-bell",nombre:"mdi mdi-alarm-bell", libreria:"Material Design Iconos" },
	{key:"mdi mdi-alarm-check",nombre:"mdi mdi-alarm-check", libreria:"Material Design Iconos" },
	{key:"mdi mdi-alarm-light",nombre:"mdi mdi-alarm-light", libreria:"Material Design Iconos" },
	{key:"mdi mdi-alarm-multiple",nombre:"mdi mdi-alarm-multiple", libreria:"Material Design Iconos" },
	{key:"mdi mdi-alarm-off",nombre:"mdi mdi-alarm-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-alarm-plus",nombre:"mdi mdi-alarm-plus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-alarm-snooze",nombre:"mdi mdi-alarm-snooze", libreria:"Material Design Iconos" },
	{key:"mdi mdi-album",nombre:"mdi mdi-album", libreria:"Material Design Iconos" },
	{key:"mdi mdi-alert",nombre:"mdi mdi-alert", libreria:"Material Design Iconos" },
	{key:"mdi mdi-alert-box",nombre:"mdi mdi-alert-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-alert-circle",nombre:"mdi mdi-alert-circle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-alert-circle-outline",nombre:"mdi mdi-alert-circle-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-alert-decagram",nombre:"mdi mdi-alert-decagram", libreria:"Material Design Iconos" },
	{key:"mdi mdi-alert-octagon",nombre:"mdi mdi-alert-octagon", libreria:"Material Design Iconos" },
	{key:"mdi mdi-alert-octagram",nombre:"mdi mdi-alert-octagram", libreria:"Material Design Iconos" },
	{key:"mdi mdi-alert-outline",nombre:"mdi mdi-alert-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-all-inclusive",nombre:"mdi mdi-all-inclusive", libreria:"Material Design Iconos" },
	{key:"mdi mdi-alpha",nombre:"mdi mdi-alpha", libreria:"Material Design Iconos" },
	{key:"mdi mdi-alphabetical",nombre:"mdi mdi-alphabetical", libreria:"Material Design Iconos" },
	{key:"mdi mdi-altimeter",nombre:"mdi mdi-altimeter", libreria:"Material Design Iconos" },
	{key:"mdi mdi-amazon",nombre:"mdi mdi-amazon", libreria:"Material Design Iconos" },
	{key:"mdi mdi-ambulance",nombre:"mdi mdi-ambulance", libreria:"Material Design Iconos" },
	{key:"mdi mdi-amplifier",nombre:"mdi mdi-amplifier", libreria:"Material Design Iconos" },
	{key:"mdi mdi-anchor",nombre:"mdi mdi-anchor", libreria:"Material Design Iconos" },
	{key:"mdi mdi-android",nombre:"mdi mdi-android", libreria:"Material Design Iconos" },
	{key:"mdi mdi-android-debug-bridge",nombre:"mdi mdi-android-debug-bridge", libreria:"Material Design Iconos" },
	{key:"mdi mdi-android-head",nombre:"mdi mdi-android-head", libreria:"Material Design Iconos" },
	{key:"mdi mdi-android-studio",nombre:"mdi mdi-android-studio", libreria:"Material Design Iconos" },
	{key:"mdi mdi-angular",nombre:"mdi mdi-angular", libreria:"Material Design Iconos" },
	{key:"mdi mdi-angularjs",nombre:"mdi mdi-angularjs", libreria:"Material Design Iconos" },
	{key:"mdi mdi-animation",nombre:"mdi mdi-animation", libreria:"Material Design Iconos" },
	{key:"mdi mdi-apple",nombre:"mdi mdi-apple", libreria:"Material Design Iconos" },
	{key:"mdi mdi-apple-finder",nombre:"mdi mdi-apple-finder", libreria:"Material Design Iconos" },
	{key:"mdi mdi-apple-ios",nombre:"mdi mdi-apple-ios", libreria:"Material Design Iconos" },
	{key:"mdi mdi-apple-keyboard-caps",nombre:"mdi mdi-apple-keyboard-caps", libreria:"Material Design Iconos" },
	{key:"mdi mdi-apple-keyboard-command",nombre:"mdi mdi-apple-keyboard-command", libreria:"Material Design Iconos" },
	{key:"mdi mdi-apple-keyboard-control",nombre:"mdi mdi-apple-keyboard-control", libreria:"Material Design Iconos" },
	{key:"mdi mdi-apple-keyboard-option",nombre:"mdi mdi-apple-keyboard-option", libreria:"Material Design Iconos" },
	{key:"mdi mdi-apple-keyboard-shift",nombre:"mdi mdi-apple-keyboard-shift", libreria:"Material Design Iconos" },
	{key:"mdi mdi-apple-safari",nombre:"mdi mdi-apple-safari", libreria:"Material Design Iconos" },
	{key:"mdi mdi-application",nombre:"mdi mdi-application", libreria:"Material Design Iconos" },
	{key:"mdi mdi-apps",nombre:"mdi mdi-apps", libreria:"Material Design Iconos" },
	{key:"mdi mdi-archive",nombre:"mdi mdi-archive", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrange-bring-forward",nombre:"mdi mdi-arrange-bring-forward", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrange-bring-to-front",nombre:"mdi mdi-arrange-bring-to-front", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrange-send-backward",nombre:"mdi mdi-arrange-send-backward", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrange-send-to-back",nombre:"mdi mdi-arrange-send-to-back", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-all",nombre:"mdi mdi-arrow-all", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-bottom-left",nombre:"mdi mdi-arrow-bottom-left", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-bottom-right",nombre:"mdi mdi-arrow-bottom-right", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-collapse",nombre:"mdi mdi-arrow-collapse", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-collapse-all",nombre:"mdi mdi-arrow-collapse-all", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-collapse-down",nombre:"mdi mdi-arrow-collapse-down", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-collapse-left",nombre:"mdi mdi-arrow-collapse-left", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-collapse-right",nombre:"mdi mdi-arrow-collapse-right", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-collapse-up",nombre:"mdi mdi-arrow-collapse-up", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-down",nombre:"mdi mdi-arrow-down", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-down-bold",nombre:"mdi mdi-arrow-down-bold", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-down-bold-box",nombre:"mdi mdi-arrow-down-bold-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-down-bold-box-outline",nombre:"mdi mdi-arrow-down-bold-box-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-down-bold-circle",nombre:"mdi mdi-arrow-down-bold-circle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-down-bold-circle-outline",nombre:"mdi mdi-arrow-down-bold-circle-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-down-bold-hexagon-outline",nombre:"mdi mdi-arrow-down-bold-hexagon-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-down-box",nombre:"mdi mdi-arrow-down-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-down-drop-circle",nombre:"mdi mdi-arrow-down-drop-circle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-down-drop-circle-outline",nombre:"mdi mdi-arrow-down-drop-circle-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-down-thick",nombre:"mdi mdi-arrow-down-thick", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-expand",nombre:"mdi mdi-arrow-expand", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-expand-all",nombre:"mdi mdi-arrow-expand-all", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-expand-down",nombre:"mdi mdi-arrow-expand-down", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-expand-left",nombre:"mdi mdi-arrow-expand-left", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-expand-right",nombre:"mdi mdi-arrow-expand-right", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-expand-up",nombre:"mdi mdi-arrow-expand-up", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-left",nombre:"mdi mdi-arrow-left", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-left-bold",nombre:"mdi mdi-arrow-left-bold", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-left-bold-box",nombre:"mdi mdi-arrow-left-bold-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-left-bold-box-outline",nombre:"mdi mdi-arrow-left-bold-box-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-left-bold-circle",nombre:"mdi mdi-arrow-left-bold-circle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-left-bold-circle-outline",nombre:"mdi mdi-arrow-left-bold-circle-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-left-bold-hexagon-outline",nombre:"mdi mdi-arrow-left-bold-hexagon-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-left-box",nombre:"mdi mdi-arrow-left-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-left-drop-circle",nombre:"mdi mdi-arrow-left-drop-circle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-left-drop-circle-outline",nombre:"mdi mdi-arrow-left-drop-circle-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-left-thick",nombre:"mdi mdi-arrow-left-thick", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-right",nombre:"mdi mdi-arrow-right", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-right-bold",nombre:"mdi mdi-arrow-right-bold", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-right-bold-box",nombre:"mdi mdi-arrow-right-bold-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-right-bold-box-outline",nombre:"mdi mdi-arrow-right-bold-box-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-right-bold-circle",nombre:"mdi mdi-arrow-right-bold-circle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-right-bold-circle-outline",nombre:"mdi mdi-arrow-right-bold-circle-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-right-bold-hexagon-outline",nombre:"mdi mdi-arrow-right-bold-hexagon-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-right-box",nombre:"mdi mdi-arrow-right-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-right-drop-circle",nombre:"mdi mdi-arrow-right-drop-circle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-right-drop-circle-outline",nombre:"mdi mdi-arrow-right-drop-circle-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-right-thick",nombre:"mdi mdi-arrow-right-thick", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-top-left",nombre:"mdi mdi-arrow-top-left", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-top-right",nombre:"mdi mdi-arrow-top-right", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-up",nombre:"mdi mdi-arrow-up", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-up-bold",nombre:"mdi mdi-arrow-up-bold", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-up-bold-box",nombre:"mdi mdi-arrow-up-bold-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-up-bold-box-outline",nombre:"mdi mdi-arrow-up-bold-box-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-up-bold-circle",nombre:"mdi mdi-arrow-up-bold-circle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-up-bold-circle-outline",nombre:"mdi mdi-arrow-up-bold-circle-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-up-bold-hexagon-outline",nombre:"mdi mdi-arrow-up-bold-hexagon-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-up-box",nombre:"mdi mdi-arrow-up-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-up-drop-circle",nombre:"mdi mdi-arrow-up-drop-circle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-up-drop-circle-outline",nombre:"mdi mdi-arrow-up-drop-circle-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-arrow-up-thick",nombre:"mdi mdi-arrow-up-thick", libreria:"Material Design Iconos" },
	{key:"mdi mdi-assistant",nombre:"mdi mdi-assistant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-asterisk",nombre:"mdi mdi-asterisk", libreria:"Material Design Iconos" },
	{key:"mdi mdi-at",nombre:"mdi mdi-at", libreria:"Material Design Iconos" },
	{key:"mdi mdi-atom",nombre:"mdi mdi-atom", libreria:"Material Design Iconos" },
	{key:"mdi mdi-attachment",nombre:"mdi mdi-attachment", libreria:"Material Design Iconos" },
	{key:"mdi mdi-audiobook",nombre:"mdi mdi-audiobook", libreria:"Material Design Iconos" },
	{key:"mdi mdi-auto-fix",nombre:"mdi mdi-auto-fix", libreria:"Material Design Iconos" },
	{key:"mdi mdi-auto-upload",nombre:"mdi mdi-auto-upload", libreria:"Material Design Iconos" },
	{key:"mdi mdi-autorenew",nombre:"mdi mdi-autorenew", libreria:"Material Design Iconos" },
	{key:"mdi mdi-av-timer",nombre:"mdi mdi-av-timer", libreria:"Material Design Iconos" },
	{key:"mdi mdi-baby",nombre:"mdi mdi-baby", libreria:"Material Design Iconos" },
	{key:"mdi mdi-backburger",nombre:"mdi mdi-backburger", libreria:"Material Design Iconos" },
	{key:"mdi mdi-backspace",nombre:"mdi mdi-backspace", libreria:"Material Design Iconos" },
	{key:"mdi mdi-backup-restore",nombre:"mdi mdi-backup-restore", libreria:"Material Design Iconos" },
	{key:"mdi mdi-bandcamp",nombre:"mdi mdi-bandcamp", libreria:"Material Design Iconos" },
	{key:"mdi mdi-bank",nombre:"mdi mdi-bank", libreria:"Material Design Iconos" },
	{key:"mdi mdi-barcode",nombre:"mdi mdi-barcode", libreria:"Material Design Iconos" },
	{key:"mdi mdi-barcode-scan",nombre:"mdi mdi-barcode-scan", libreria:"Material Design Iconos" },
	{key:"mdi mdi-barley",nombre:"mdi mdi-barley", libreria:"Material Design Iconos" },
	{key:"mdi mdi-barrel",nombre:"mdi mdi-barrel", libreria:"Material Design Iconos" },
	{key:"mdi mdi-basecamp",nombre:"mdi mdi-basecamp", libreria:"Material Design Iconos" },
	{key:"mdi mdi-basket",nombre:"mdi mdi-basket", libreria:"Material Design Iconos" },
	{key:"mdi mdi-basket-fill",nombre:"mdi mdi-basket-fill", libreria:"Material Design Iconos" },
	{key:"mdi mdi-basket-unfill",nombre:"mdi mdi-basket-unfill", libreria:"Material Design Iconos" },
	{key:"mdi mdi-battery",nombre:"mdi mdi-battery", libreria:"Material Design Iconos" },
	{key:"mdi mdi-battery-10",nombre:"mdi mdi-battery-10", libreria:"Material Design Iconos" },
	{key:"mdi mdi-battery-20",nombre:"mdi mdi-battery-20", libreria:"Material Design Iconos" },
	{key:"mdi mdi-battery-30",nombre:"mdi mdi-battery-30", libreria:"Material Design Iconos" },
	{key:"mdi mdi-battery-40",nombre:"mdi mdi-battery-40", libreria:"Material Design Iconos" },
	{key:"mdi mdi-battery-50",nombre:"mdi mdi-battery-50", libreria:"Material Design Iconos" },
	{key:"mdi mdi-battery-60",nombre:"mdi mdi-battery-60", libreria:"Material Design Iconos" },
	{key:"mdi mdi-battery-70",nombre:"mdi mdi-battery-70", libreria:"Material Design Iconos" },
	{key:"mdi mdi-battery-80",nombre:"mdi mdi-battery-80", libreria:"Material Design Iconos" },
	{key:"mdi mdi-battery-90",nombre:"mdi mdi-battery-90", libreria:"Material Design Iconos" },
	{key:"mdi mdi-battery-alert",nombre:"mdi mdi-battery-alert", libreria:"Material Design Iconos" },
	{key:"mdi mdi-battery-charging",nombre:"mdi mdi-battery-charging", libreria:"Material Design Iconos" },
	{key:"mdi mdi-battery-charging-100",nombre:"mdi mdi-battery-charging-100", libreria:"Material Design Iconos" },
	{key:"mdi mdi-battery-charging-20",nombre:"mdi mdi-battery-charging-20", libreria:"Material Design Iconos" },
	{key:"mdi mdi-battery-charging-30",nombre:"mdi mdi-battery-charging-30", libreria:"Material Design Iconos" },
	{key:"mdi mdi-battery-charging-40",nombre:"mdi mdi-battery-charging-40", libreria:"Material Design Iconos" },
	{key:"mdi mdi-battery-charging-60",nombre:"mdi mdi-battery-charging-60", libreria:"Material Design Iconos" },
	{key:"mdi mdi-battery-charging-80",nombre:"mdi mdi-battery-charging-80", libreria:"Material Design Iconos" },
	{key:"mdi mdi-battery-charging-90",nombre:"mdi mdi-battery-charging-90", libreria:"Material Design Iconos" },
	{key:"mdi mdi-battery-minus",nombre:"mdi mdi-battery-minus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-battery-negative",nombre:"mdi mdi-battery-negative", libreria:"Material Design Iconos" },
	{key:"mdi mdi-battery-outline",nombre:"mdi mdi-battery-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-battery-plus",nombre:"mdi mdi-battery-plus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-battery-positive",nombre:"mdi mdi-battery-positive", libreria:"Material Design Iconos" },
	{key:"mdi mdi-battery-unknown",nombre:"mdi mdi-battery-unknown", libreria:"Material Design Iconos" },
	{key:"mdi mdi-beach",nombre:"mdi mdi-beach", libreria:"Material Design Iconos" },
	{key:"mdi mdi-beaker",nombre:"mdi mdi-beaker", libreria:"Material Design Iconos" },
	{key:"mdi mdi-beats",nombre:"mdi mdi-beats", libreria:"Material Design Iconos" },
	{key:"mdi mdi-beer",nombre:"mdi mdi-beer", libreria:"Material Design Iconos" },
	{key:"mdi mdi-behance",nombre:"mdi mdi-behance", libreria:"Material Design Iconos" },
	{key:"mdi mdi-bell",nombre:"mdi mdi-bell", libreria:"Material Design Iconos" },
	{key:"mdi mdi-bell-off",nombre:"mdi mdi-bell-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-bell-outline",nombre:"mdi mdi-bell-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-bell-plus",nombre:"mdi mdi-bell-plus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-bell-ring",nombre:"mdi mdi-bell-ring", libreria:"Material Design Iconos" },
	{key:"mdi mdi-bell-ring-outline",nombre:"mdi mdi-bell-ring-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-bell-sleep",nombre:"mdi mdi-bell-sleep", libreria:"Material Design Iconos" },
	{key:"mdi mdi-beta",nombre:"mdi mdi-beta", libreria:"Material Design Iconos" },
	{key:"mdi mdi-bible",nombre:"mdi mdi-bible", libreria:"Material Design Iconos" },
	{key:"mdi mdi-bike",nombre:"mdi mdi-bike", libreria:"Material Design Iconos" },
	{key:"mdi mdi-bing",nombre:"mdi mdi-bing", libreria:"Material Design Iconos" },
	{key:"mdi mdi-binoculars",nombre:"mdi mdi-binoculars", libreria:"Material Design Iconos" },
	{key:"mdi mdi-bio",nombre:"mdi mdi-bio", libreria:"Material Design Iconos" },
	{key:"mdi mdi-biohazard",nombre:"mdi mdi-biohazard", libreria:"Material Design Iconos" },
	{key:"mdi mdi-bitbucket",nombre:"mdi mdi-bitbucket", libreria:"Material Design Iconos" },
	{key:"mdi mdi-black-mesa",nombre:"mdi mdi-black-mesa", libreria:"Material Design Iconos" },
	{key:"mdi mdi-blackberry",nombre:"mdi mdi-blackberry", libreria:"Material Design Iconos" },
	{key:"mdi mdi-blender",nombre:"mdi mdi-blender", libreria:"Material Design Iconos" },
	{key:"mdi mdi-blinds",nombre:"mdi mdi-blinds", libreria:"Material Design Iconos" },
	{key:"mdi mdi-block-helper",nombre:"mdi mdi-block-helper", libreria:"Material Design Iconos" },
	{key:"mdi mdi-blogger",nombre:"mdi mdi-blogger", libreria:"Material Design Iconos" },
	{key:"mdi mdi-bluetooth",nombre:"mdi mdi-bluetooth", libreria:"Material Design Iconos" },
	{key:"mdi mdi-bluetooth-audio",nombre:"mdi mdi-bluetooth-audio", libreria:"Material Design Iconos" },
	{key:"mdi mdi-bluetooth-connect",nombre:"mdi mdi-bluetooth-connect", libreria:"Material Design Iconos" },
	{key:"mdi mdi-bluetooth-off",nombre:"mdi mdi-bluetooth-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-bluetooth-settings",nombre:"mdi mdi-bluetooth-settings", libreria:"Material Design Iconos" },
	{key:"mdi mdi-bluetooth-transfer",nombre:"mdi mdi-bluetooth-transfer", libreria:"Material Design Iconos" },
	{key:"mdi mdi-blur",nombre:"mdi mdi-blur", libreria:"Material Design Iconos" },
	{key:"mdi mdi-blur-linear",nombre:"mdi mdi-blur-linear", libreria:"Material Design Iconos" },
	{key:"mdi mdi-blur-off",nombre:"mdi mdi-blur-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-blur-radial",nombre:"mdi mdi-blur-radial", libreria:"Material Design Iconos" },
	{key:"mdi mdi-bomb",nombre:"mdi mdi-bomb", libreria:"Material Design Iconos" },
	{key:"mdi mdi-bomb-off",nombre:"mdi mdi-bomb-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-bone",nombre:"mdi mdi-bone", libreria:"Material Design Iconos" },
	{key:"mdi mdi-book",nombre:"mdi mdi-book", libreria:"Material Design Iconos" },
	{key:"mdi mdi-book-minus",nombre:"mdi mdi-book-minus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-book-multiple",nombre:"mdi mdi-book-multiple", libreria:"Material Design Iconos" },
	{key:"mdi mdi-book-open",nombre:"mdi mdi-book-open", libreria:"Material Design Iconos" },
	{key:"mdi mdi-book-open-page-variant",nombre:"mdi mdi-book-open-page-variant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-book-open-variant",nombre:"mdi mdi-book-open-variant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-book-plus",nombre:"mdi mdi-book-plus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-book-variant",nombre:"mdi mdi-book-variant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-bookmark",nombre:"mdi mdi-bookmark", libreria:"Material Design Iconos" },
	{key:"mdi mdi-bookmark-check",nombre:"mdi mdi-bookmark-check", libreria:"Material Design Iconos" },
	{key:"mdi mdi-bookmark-music",nombre:"mdi mdi-bookmark-music", libreria:"Material Design Iconos" },
	{key:"mdi mdi-bookmark-outline",nombre:"mdi mdi-bookmark-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-bookmark-plus",nombre:"mdi mdi-bookmark-plus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-bookmark-plus-outline",nombre:"mdi mdi-bookmark-plus-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-bookmark-remove",nombre:"mdi mdi-bookmark-remove", libreria:"Material Design Iconos" },
	{key:"mdi mdi-boombox",nombre:"mdi mdi-boombox", libreria:"Material Design Iconos" },
	{key:"mdi mdi-bootstrap",nombre:"mdi mdi-bootstrap", libreria:"Material Design Iconos" },
	{key:"mdi mdi-border-all",nombre:"mdi mdi-border-all", libreria:"Material Design Iconos" },
	{key:"mdi mdi-border-bottom",nombre:"mdi mdi-border-bottom", libreria:"Material Design Iconos" },
	{key:"mdi mdi-border-color",nombre:"mdi mdi-border-color", libreria:"Material Design Iconos" },
	{key:"mdi mdi-border-horizontal",nombre:"mdi mdi-border-horizontal", libreria:"Material Design Iconos" },
	{key:"mdi mdi-border-inside",nombre:"mdi mdi-border-inside", libreria:"Material Design Iconos" },
	{key:"mdi mdi-border-left",nombre:"mdi mdi-border-left", libreria:"Material Design Iconos" },
	{key:"mdi mdi-border-none",nombre:"mdi mdi-border-none", libreria:"Material Design Iconos" },
	{key:"mdi mdi-border-outside",nombre:"mdi mdi-border-outside", libreria:"Material Design Iconos" },
	{key:"mdi mdi-border-right",nombre:"mdi mdi-border-right", libreria:"Material Design Iconos" },
	{key:"mdi mdi-border-style",nombre:"mdi mdi-border-style", libreria:"Material Design Iconos" },
	{key:"mdi mdi-border-top",nombre:"mdi mdi-border-top", libreria:"Material Design Iconos" },
	{key:"mdi mdi-border-vertical",nombre:"mdi mdi-border-vertical", libreria:"Material Design Iconos" },
	{key:"mdi mdi-bow-tie",nombre:"mdi mdi-bow-tie", libreria:"Material Design Iconos" },
	{key:"mdi mdi-bowl",nombre:"mdi mdi-bowl", libreria:"Material Design Iconos" },
	{key:"mdi mdi-bowling",nombre:"mdi mdi-bowling", libreria:"Material Design Iconos" },
	{key:"mdi mdi-box",nombre:"mdi mdi-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-box-cutter",nombre:"mdi mdi-box-cutter", libreria:"Material Design Iconos" },
	{key:"mdi mdi-box-shadow",nombre:"mdi mdi-box-shadow", libreria:"Material Design Iconos" },
	{key:"mdi mdi-bridge",nombre:"mdi mdi-bridge", libreria:"Material Design Iconos" },
	{key:"mdi mdi-briefcase",nombre:"mdi mdi-briefcase", libreria:"Material Design Iconos" },
	{key:"mdi mdi-briefcase-check",nombre:"mdi mdi-briefcase-check", libreria:"Material Design Iconos" },
	{key:"mdi mdi-briefcase-download",nombre:"mdi mdi-briefcase-download", libreria:"Material Design Iconos" },
	{key:"mdi mdi-briefcase-upload",nombre:"mdi mdi-briefcase-upload", libreria:"Material Design Iconos" },
	{key:"mdi mdi-brightness-1",nombre:"mdi mdi-brightness-1", libreria:"Material Design Iconos" },
	{key:"mdi mdi-brightness-2",nombre:"mdi mdi-brightness-2", libreria:"Material Design Iconos" },
	{key:"mdi mdi-brightness-3",nombre:"mdi mdi-brightness-3", libreria:"Material Design Iconos" },
	{key:"mdi mdi-brightness-4",nombre:"mdi mdi-brightness-4", libreria:"Material Design Iconos" },
	{key:"mdi mdi-brightness-5",nombre:"mdi mdi-brightness-5", libreria:"Material Design Iconos" },
	{key:"mdi mdi-brightness-6",nombre:"mdi mdi-brightness-6", libreria:"Material Design Iconos" },
	{key:"mdi mdi-brightness-7",nombre:"mdi mdi-brightness-7", libreria:"Material Design Iconos" },
	{key:"mdi mdi-brightness-auto",nombre:"mdi mdi-brightness-auto", libreria:"Material Design Iconos" },
	{key:"mdi mdi-broom",nombre:"mdi mdi-broom", libreria:"Material Design Iconos" },
	{key:"mdi mdi-brush",nombre:"mdi mdi-brush", libreria:"Material Design Iconos" },
	{key:"mdi mdi-buffer",nombre:"mdi mdi-buffer", libreria:"Material Design Iconos" },
	{key:"mdi mdi-bug",nombre:"mdi mdi-bug", libreria:"Material Design Iconos" },
	{key:"mdi mdi-bulletin-board",nombre:"mdi mdi-bulletin-board", libreria:"Material Design Iconos" },
	{key:"mdi mdi-bullhorn",nombre:"mdi mdi-bullhorn", libreria:"Material Design Iconos" },
	{key:"mdi mdi-bullseye",nombre:"mdi mdi-bullseye", libreria:"Material Design Iconos" },
	{key:"mdi mdi-bus",nombre:"mdi mdi-bus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-bus-articulated-end",nombre:"mdi mdi-bus-articulated-end", libreria:"Material Design Iconos" },
	{key:"mdi mdi-bus-articulated-front",nombre:"mdi mdi-bus-articulated-front", libreria:"Material Design Iconos" },
	{key:"mdi mdi-bus-double-decker",nombre:"mdi mdi-bus-double-decker", libreria:"Material Design Iconos" },
	{key:"mdi mdi-bus-school",nombre:"mdi mdi-bus-school", libreria:"Material Design Iconos" },
	{key:"mdi mdi-bus-side",nombre:"mdi mdi-bus-side", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cached",nombre:"mdi mdi-cached", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cake",nombre:"mdi mdi-cake", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cake-layered",nombre:"mdi mdi-cake-layered", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cake-variant",nombre:"mdi mdi-cake-variant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-calculator",nombre:"mdi mdi-calculator", libreria:"Material Design Iconos" },
	{key:"mdi mdi-calendar",nombre:"mdi mdi-calendar", libreria:"Material Design Iconos" },
	{key:"mdi mdi-calendar-blank",nombre:"mdi mdi-calendar-blank", libreria:"Material Design Iconos" },
	{key:"mdi mdi-calendar-check",nombre:"mdi mdi-calendar-check", libreria:"Material Design Iconos" },
	{key:"mdi mdi-calendar-clock",nombre:"mdi mdi-calendar-clock", libreria:"Material Design Iconos" },
	{key:"mdi mdi-calendar-multiple",nombre:"mdi mdi-calendar-multiple", libreria:"Material Design Iconos" },
	{key:"mdi mdi-calendar-multiple-check",nombre:"mdi mdi-calendar-multiple-check", libreria:"Material Design Iconos" },
	{key:"mdi mdi-calendar-plus",nombre:"mdi mdi-calendar-plus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-calendar-question",nombre:"mdi mdi-calendar-question", libreria:"Material Design Iconos" },
	{key:"mdi mdi-calendar-range",nombre:"mdi mdi-calendar-range", libreria:"Material Design Iconos" },
	{key:"mdi mdi-calendar-remove",nombre:"mdi mdi-calendar-remove", libreria:"Material Design Iconos" },
	{key:"mdi mdi-calendar-text",nombre:"mdi mdi-calendar-text", libreria:"Material Design Iconos" },
	{key:"mdi mdi-calendar-today",nombre:"mdi mdi-calendar-today", libreria:"Material Design Iconos" },
	{key:"mdi mdi-call-made",nombre:"mdi mdi-call-made", libreria:"Material Design Iconos" },
	{key:"mdi mdi-call-merge",nombre:"mdi mdi-call-merge", libreria:"Material Design Iconos" },
	{key:"mdi mdi-call-missed",nombre:"mdi mdi-call-missed", libreria:"Material Design Iconos" },
	{key:"mdi mdi-call-received",nombre:"mdi mdi-call-received", libreria:"Material Design Iconos" },
	{key:"mdi mdi-call-split",nombre:"mdi mdi-call-split", libreria:"Material Design Iconos" },
	{key:"mdi mdi-camcorder",nombre:"mdi mdi-camcorder", libreria:"Material Design Iconos" },
	{key:"mdi mdi-camcorder-box",nombre:"mdi mdi-camcorder-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-camcorder-box-off",nombre:"mdi mdi-camcorder-box-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-camcorder-off",nombre:"mdi mdi-camcorder-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-camera",nombre:"mdi mdi-camera", libreria:"Material Design Iconos" },
	{key:"mdi mdi-camera-burst",nombre:"mdi mdi-camera-burst", libreria:"Material Design Iconos" },
	{key:"mdi mdi-camera-enhance",nombre:"mdi mdi-camera-enhance", libreria:"Material Design Iconos" },
	{key:"mdi mdi-camera-front",nombre:"mdi mdi-camera-front", libreria:"Material Design Iconos" },
	{key:"mdi mdi-camera-front-variant",nombre:"mdi mdi-camera-front-variant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-camera-gopro",nombre:"mdi mdi-camera-gopro", libreria:"Material Design Iconos" },
	{key:"mdi mdi-camera-iris",nombre:"mdi mdi-camera-iris", libreria:"Material Design Iconos" },
	{key:"mdi mdi-camera-metering-center",nombre:"mdi mdi-camera-metering-center", libreria:"Material Design Iconos" },
	{key:"mdi mdi-camera-metering-matrix",nombre:"mdi mdi-camera-metering-matrix", libreria:"Material Design Iconos" },
	{key:"mdi mdi-camera-metering-partial",nombre:"mdi mdi-camera-metering-partial", libreria:"Material Design Iconos" },
	{key:"mdi mdi-camera-metering-spot",nombre:"mdi mdi-camera-metering-spot", libreria:"Material Design Iconos" },
	{key:"mdi mdi-camera-off",nombre:"mdi mdi-camera-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-camera-party-mode",nombre:"mdi mdi-camera-party-mode", libreria:"Material Design Iconos" },
	{key:"mdi mdi-camera-rear",nombre:"mdi mdi-camera-rear", libreria:"Material Design Iconos" },
	{key:"mdi mdi-camera-rear-variant",nombre:"mdi mdi-camera-rear-variant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-camera-switch",nombre:"mdi mdi-camera-switch", libreria:"Material Design Iconos" },
	{key:"mdi mdi-camera-timer",nombre:"mdi mdi-camera-timer", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cancel",nombre:"mdi mdi-cancel", libreria:"Material Design Iconos" },
	{key:"mdi mdi-candle",nombre:"mdi mdi-candle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-candycane",nombre:"mdi mdi-candycane", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cannabis",nombre:"mdi mdi-cannabis", libreria:"Material Design Iconos" },
	{key:"mdi mdi-car",nombre:"mdi mdi-car", libreria:"Material Design Iconos" },
	{key:"mdi mdi-car-battery",nombre:"mdi mdi-car-battery", libreria:"Material Design Iconos" },
	{key:"mdi mdi-car-connected",nombre:"mdi mdi-car-connected", libreria:"Material Design Iconos" },
	{key:"mdi mdi-car-estate",nombre:"mdi mdi-car-estate", libreria:"Material Design Iconos" },
	{key:"mdi mdi-car-hatchback",nombre:"mdi mdi-car-hatchback", libreria:"Material Design Iconos" },
	{key:"mdi mdi-car-pickup",nombre:"mdi mdi-car-pickup", libreria:"Material Design Iconos" },
	{key:"mdi mdi-car-side",nombre:"mdi mdi-car-side", libreria:"Material Design Iconos" },
	{key:"mdi mdi-car-sports",nombre:"mdi mdi-car-sports", libreria:"Material Design Iconos" },
	{key:"mdi mdi-car-wash",nombre:"mdi mdi-car-wash", libreria:"Material Design Iconos" },
	{key:"mdi mdi-caravan",nombre:"mdi mdi-caravan", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cards",nombre:"mdi mdi-cards", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cards-outline",nombre:"mdi mdi-cards-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cards-playing-outline",nombre:"mdi mdi-cards-playing-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cards-variant",nombre:"mdi mdi-cards-variant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-carrot",nombre:"mdi mdi-carrot", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cart",nombre:"mdi mdi-cart", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cart-off",nombre:"mdi mdi-cart-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cart-outline",nombre:"mdi mdi-cart-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cart-plus",nombre:"mdi mdi-cart-plus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-case-sensitive-alt",nombre:"mdi mdi-case-sensitive-alt", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cash",nombre:"mdi mdi-cash", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cash-100",nombre:"mdi mdi-cash-100", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cash-multiple",nombre:"mdi mdi-cash-multiple", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cash-usd",nombre:"mdi mdi-cash-usd", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cast",nombre:"mdi mdi-cast", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cast-connected",nombre:"mdi mdi-cast-connected", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cast-off",nombre:"mdi mdi-cast-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-castle",nombre:"mdi mdi-castle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cat",nombre:"mdi mdi-cat", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cctv",nombre:"mdi mdi-cctv", libreria:"Material Design Iconos" },
	{key:"mdi mdi-ceiling-light",nombre:"mdi mdi-ceiling-light", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cellphone",nombre:"mdi mdi-cellphone", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cellphone-android",nombre:"mdi mdi-cellphone-android", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cellphone-basic",nombre:"mdi mdi-cellphone-basic", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cellphone-dock",nombre:"mdi mdi-cellphone-dock", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cellphone-iphone",nombre:"mdi mdi-cellphone-iphone", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cellphone-link",nombre:"mdi mdi-cellphone-link", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cellphone-link-off",nombre:"mdi mdi-cellphone-link-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cellphone-settings",nombre:"mdi mdi-cellphone-settings", libreria:"Material Design Iconos" },
	{key:"mdi mdi-certificate",nombre:"mdi mdi-certificate", libreria:"Material Design Iconos" },
	{key:"mdi mdi-chair-school",nombre:"mdi mdi-chair-school", libreria:"Material Design Iconos" },
	{key:"mdi mdi-chart-arc",nombre:"mdi mdi-chart-arc", libreria:"Material Design Iconos" },
	{key:"mdi mdi-chart-areaspline",nombre:"mdi mdi-chart-areaspline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-chart-bar",nombre:"mdi mdi-chart-bar", libreria:"Material Design Iconos" },
	{key:"mdi mdi-chart-bar-stacked",nombre:"mdi mdi-chart-bar-stacked", libreria:"Material Design Iconos" },
	{key:"mdi mdi-chart-bubble",nombre:"mdi mdi-chart-bubble", libreria:"Material Design Iconos" },
	{key:"mdi mdi-chart-donut",nombre:"mdi mdi-chart-donut", libreria:"Material Design Iconos" },
	{key:"mdi mdi-chart-donut-variant",nombre:"mdi mdi-chart-donut-variant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-chart-gantt",nombre:"mdi mdi-chart-gantt", libreria:"Material Design Iconos" },
	{key:"mdi mdi-chart-histogram",nombre:"mdi mdi-chart-histogram", libreria:"Material Design Iconos" },
	{key:"mdi mdi-chart-line",nombre:"mdi mdi-chart-line", libreria:"Material Design Iconos" },
	{key:"mdi mdi-chart-line-stacked",nombre:"mdi mdi-chart-line-stacked", libreria:"Material Design Iconos" },
	{key:"mdi mdi-chart-line-variant",nombre:"mdi mdi-chart-line-variant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-chart-pie",nombre:"mdi mdi-chart-pie", libreria:"Material Design Iconos" },
	{key:"mdi mdi-chart-timeline",nombre:"mdi mdi-chart-timeline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-check",nombre:"mdi mdi-check", libreria:"Material Design Iconos" },
	{key:"mdi mdi-check-all",nombre:"mdi mdi-check-all", libreria:"Material Design Iconos" },
	{key:"mdi mdi-check-circle",nombre:"mdi mdi-check-circle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-check-circle-outline",nombre:"mdi mdi-check-circle-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-checkbox-blank",nombre:"mdi mdi-checkbox-blank", libreria:"Material Design Iconos" },
	{key:"mdi mdi-checkbox-blank-circle",nombre:"mdi mdi-checkbox-blank-circle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-checkbox-blank-circle-outline",nombre:"mdi mdi-checkbox-blank-circle-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-checkbox-blank-outline",nombre:"mdi mdi-checkbox-blank-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-checkbox-marked",nombre:"mdi mdi-checkbox-marked", libreria:"Material Design Iconos" },
	{key:"mdi mdi-checkbox-marked-circle",nombre:"mdi mdi-checkbox-marked-circle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-checkbox-marked-circle-outline",nombre:"mdi mdi-checkbox-marked-circle-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-checkbox-marked-outline",nombre:"mdi mdi-checkbox-marked-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-checkbox-multiple-blank",nombre:"mdi mdi-checkbox-multiple-blank", libreria:"Material Design Iconos" },
	{key:"mdi mdi-checkbox-multiple-blank-circle",nombre:"mdi mdi-checkbox-multiple-blank-circle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-checkbox-multiple-blank-circle-outline",nombre:"mdi mdi-checkbox-multiple-blank-circle-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-checkbox-multiple-blank-outline",nombre:"mdi mdi-checkbox-multiple-blank-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-checkbox-multiple-marked",nombre:"mdi mdi-checkbox-multiple-marked", libreria:"Material Design Iconos" },
	{key:"mdi mdi-checkbox-multiple-marked-circle",nombre:"mdi mdi-checkbox-multiple-marked-circle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-checkbox-multiple-marked-circle-outline",nombre:"mdi mdi-checkbox-multiple-marked-circle-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-checkbox-multiple-marked-outline",nombre:"mdi mdi-checkbox-multiple-marked-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-checkerboard",nombre:"mdi mdi-checkerboard", libreria:"Material Design Iconos" },
	{key:"mdi mdi-chemical-weapon",nombre:"mdi mdi-chemical-weapon", libreria:"Material Design Iconos" },
	{key:"mdi mdi-chevron-double-down",nombre:"mdi mdi-chevron-double-down", libreria:"Material Design Iconos" },
	{key:"mdi mdi-chevron-double-left",nombre:"mdi mdi-chevron-double-left", libreria:"Material Design Iconos" },
	{key:"mdi mdi-chevron-double-right",nombre:"mdi mdi-chevron-double-right", libreria:"Material Design Iconos" },
	{key:"mdi mdi-chevron-double-up",nombre:"mdi mdi-chevron-double-up", libreria:"Material Design Iconos" },
	{key:"mdi mdi-chevron-down",nombre:"mdi mdi-chevron-down", libreria:"Material Design Iconos" },
	{key:"mdi mdi-chevron-left",nombre:"mdi mdi-chevron-left", libreria:"Material Design Iconos" },
	{key:"mdi mdi-chevron-right",nombre:"mdi mdi-chevron-right", libreria:"Material Design Iconos" },
	{key:"mdi mdi-chevron-up",nombre:"mdi mdi-chevron-up", libreria:"Material Design Iconos" },
	{key:"mdi mdi-chili-hot",nombre:"mdi mdi-chili-hot", libreria:"Material Design Iconos" },
	{key:"mdi mdi-chili-medium",nombre:"mdi mdi-chili-medium", libreria:"Material Design Iconos" },
	{key:"mdi mdi-chili-mild",nombre:"mdi mdi-chili-mild", libreria:"Material Design Iconos" },
	{key:"mdi mdi-chip",nombre:"mdi mdi-chip", libreria:"Material Design Iconos" },
	{key:"mdi mdi-church",nombre:"mdi mdi-church", libreria:"Material Design Iconos" },
	{key:"mdi mdi-circle",nombre:"mdi mdi-circle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-circle-outline",nombre:"mdi mdi-circle-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cisco-webex",nombre:"mdi mdi-cisco-webex", libreria:"Material Design Iconos" },
	{key:"mdi mdi-city",nombre:"mdi mdi-city", libreria:"Material Design Iconos" },
	{key:"mdi mdi-clipboard",nombre:"mdi mdi-clipboard", libreria:"Material Design Iconos" },
	{key:"mdi mdi-clipboard-account",nombre:"mdi mdi-clipboard-account", libreria:"Material Design Iconos" },
	{key:"mdi mdi-clipboard-alert",nombre:"mdi mdi-clipboard-alert", libreria:"Material Design Iconos" },
	{key:"mdi mdi-clipboard-arrow-down",nombre:"mdi mdi-clipboard-arrow-down", libreria:"Material Design Iconos" },
	{key:"mdi mdi-clipboard-arrow-left",nombre:"mdi mdi-clipboard-arrow-left", libreria:"Material Design Iconos" },
	{key:"mdi mdi-clipboard-check",nombre:"mdi mdi-clipboard-check", libreria:"Material Design Iconos" },
	{key:"mdi mdi-clipboard-flow",nombre:"mdi mdi-clipboard-flow", libreria:"Material Design Iconos" },
	{key:"mdi mdi-clipboard-outline",nombre:"mdi mdi-clipboard-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-clipboard-plus",nombre:"mdi mdi-clipboard-plus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-clipboard-text",nombre:"mdi mdi-clipboard-text", libreria:"Material Design Iconos" },
	{key:"mdi mdi-clippy",nombre:"mdi mdi-clippy", libreria:"Material Design Iconos" },
	{key:"mdi mdi-clock",nombre:"mdi mdi-clock", libreria:"Material Design Iconos" },
	{key:"mdi mdi-clock-alert",nombre:"mdi mdi-clock-alert", libreria:"Material Design Iconos" },
	{key:"mdi mdi-clock-end",nombre:"mdi mdi-clock-end", libreria:"Material Design Iconos" },
	{key:"mdi mdi-clock-fast",nombre:"mdi mdi-clock-fast", libreria:"Material Design Iconos" },
	{key:"mdi mdi-clock-in",nombre:"mdi mdi-clock-in", libreria:"Material Design Iconos" },
	{key:"mdi mdi-clock-out",nombre:"mdi mdi-clock-out", libreria:"Material Design Iconos" },
	{key:"mdi mdi-clock-start",nombre:"mdi mdi-clock-start", libreria:"Material Design Iconos" },
	{key:"mdi mdi-close",nombre:"mdi mdi-close", libreria:"Material Design Iconos" },
	{key:"mdi mdi-close-box",nombre:"mdi mdi-close-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-close-box-outline",nombre:"mdi mdi-close-box-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-close-circle",nombre:"mdi mdi-close-circle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-close-circle-outline",nombre:"mdi mdi-close-circle-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-close-network",nombre:"mdi mdi-close-network", libreria:"Material Design Iconos" },
	{key:"mdi mdi-close-octagon",nombre:"mdi mdi-close-octagon", libreria:"Material Design Iconos" },
	{key:"mdi mdi-close-octagon-outline",nombre:"mdi mdi-close-octagon-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-close-outline",nombre:"mdi mdi-close-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-closed-caption",nombre:"mdi mdi-closed-caption", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cloud",nombre:"mdi mdi-cloud", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cloud-braces",nombre:"mdi mdi-cloud-braces", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cloud-check",nombre:"mdi mdi-cloud-check", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cloud-circle",nombre:"mdi mdi-cloud-circle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cloud-download",nombre:"mdi mdi-cloud-download", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cloud-off-outline",nombre:"mdi mdi-cloud-off-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cloud-outline",nombre:"mdi mdi-cloud-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cloud-print",nombre:"mdi mdi-cloud-print", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cloud-print-outline",nombre:"mdi mdi-cloud-print-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cloud-sync",nombre:"mdi mdi-cloud-sync", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cloud-tags",nombre:"mdi mdi-cloud-tags", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cloud-upload",nombre:"mdi mdi-cloud-upload", libreria:"Material Design Iconos" },
	{key:"mdi mdi-code-array",nombre:"mdi mdi-code-array", libreria:"Material Design Iconos" },
	{key:"mdi mdi-code-braces",nombre:"mdi mdi-code-braces", libreria:"Material Design Iconos" },
	{key:"mdi mdi-code-brackets",nombre:"mdi mdi-code-brackets", libreria:"Material Design Iconos" },
	{key:"mdi mdi-code-equal",nombre:"mdi mdi-code-equal", libreria:"Material Design Iconos" },
	{key:"mdi mdi-code-greater-than",nombre:"mdi mdi-code-greater-than", libreria:"Material Design Iconos" },
	{key:"mdi mdi-code-greater-than-or-equal",nombre:"mdi mdi-code-greater-than-or-equal", libreria:"Material Design Iconos" },
	{key:"mdi mdi-code-less-than",nombre:"mdi mdi-code-less-than", libreria:"Material Design Iconos" },
	{key:"mdi mdi-code-less-than-or-equal",nombre:"mdi mdi-code-less-than-or-equal", libreria:"Material Design Iconos" },
	{key:"mdi mdi-code-not-equal",nombre:"mdi mdi-code-not-equal", libreria:"Material Design Iconos" },
	{key:"mdi mdi-code-not-equal-variant",nombre:"mdi mdi-code-not-equal-variant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-code-parentheses",nombre:"mdi mdi-code-parentheses", libreria:"Material Design Iconos" },
	{key:"mdi mdi-code-string",nombre:"mdi mdi-code-string", libreria:"Material Design Iconos" },
	{key:"mdi mdi-code-tags",nombre:"mdi mdi-code-tags", libreria:"Material Design Iconos" },
	{key:"mdi mdi-code-tags-check",nombre:"mdi mdi-code-tags-check", libreria:"Material Design Iconos" },
	{key:"mdi mdi-codepen",nombre:"mdi mdi-codepen", libreria:"Material Design Iconos" },
	{key:"mdi mdi-coffee",nombre:"mdi mdi-coffee", libreria:"Material Design Iconos" },
	{key:"mdi mdi-coffee-outline",nombre:"mdi mdi-coffee-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-coffee-to-go",nombre:"mdi mdi-coffee-to-go", libreria:"Material Design Iconos" },
	{key:"mdi mdi-coin",nombre:"mdi mdi-coin", libreria:"Material Design Iconos" },
	{key:"mdi mdi-coins",nombre:"mdi mdi-coins", libreria:"Material Design Iconos" },
	{key:"mdi mdi-collage",nombre:"mdi mdi-collage", libreria:"Material Design Iconos" },
	{key:"mdi mdi-color-helper",nombre:"mdi mdi-color-helper", libreria:"Material Design Iconos" },
	{key:"mdi mdi-comment",nombre:"mdi mdi-comment", libreria:"Material Design Iconos" },
	{key:"mdi mdi-comment-account",nombre:"mdi mdi-comment-account", libreria:"Material Design Iconos" },
	{key:"mdi mdi-comment-account-outline",nombre:"mdi mdi-comment-account-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-comment-alert",nombre:"mdi mdi-comment-alert", libreria:"Material Design Iconos" },
	{key:"mdi mdi-comment-alert-outline",nombre:"mdi mdi-comment-alert-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-comment-check",nombre:"mdi mdi-comment-check", libreria:"Material Design Iconos" },
	{key:"mdi mdi-comment-check-outline",nombre:"mdi mdi-comment-check-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-comment-multiple-outline",nombre:"mdi mdi-comment-multiple-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-comment-outline",nombre:"mdi mdi-comment-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-comment-plus-outline",nombre:"mdi mdi-comment-plus-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-comment-processing",nombre:"mdi mdi-comment-processing", libreria:"Material Design Iconos" },
	{key:"mdi mdi-comment-processing-outline",nombre:"mdi mdi-comment-processing-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-comment-question-outline",nombre:"mdi mdi-comment-question-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-comment-remove-outline",nombre:"mdi mdi-comment-remove-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-comment-text",nombre:"mdi mdi-comment-text", libreria:"Material Design Iconos" },
	{key:"mdi mdi-comment-text-outline",nombre:"mdi mdi-comment-text-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-compare",nombre:"mdi mdi-compare", libreria:"Material Design Iconos" },
	{key:"mdi mdi-compass",nombre:"mdi mdi-compass", libreria:"Material Design Iconos" },
	{key:"mdi mdi-compass-outline",nombre:"mdi mdi-compass-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-console",nombre:"mdi mdi-console", libreria:"Material Design Iconos" },
	{key:"mdi mdi-console-line",nombre:"mdi mdi-console-line", libreria:"Material Design Iconos" },
	{key:"mdi mdi-contact-mail",nombre:"mdi mdi-contact-mail", libreria:"Material Design Iconos" },
	{key:"mdi mdi-contacts",nombre:"mdi mdi-contacts", libreria:"Material Design Iconos" },
	{key:"mdi mdi-content-copy",nombre:"mdi mdi-content-copy", libreria:"Material Design Iconos" },
	{key:"mdi mdi-content-cut",nombre:"mdi mdi-content-cut", libreria:"Material Design Iconos" },
	{key:"mdi mdi-content-duplicate",nombre:"mdi mdi-content-duplicate", libreria:"Material Design Iconos" },
	{key:"mdi mdi-content-paste",nombre:"mdi mdi-content-paste", libreria:"Material Design Iconos" },
	{key:"mdi mdi-content-save",nombre:"mdi mdi-content-save", libreria:"Material Design Iconos" },
	{key:"mdi mdi-content-save-all",nombre:"mdi mdi-content-save-all", libreria:"Material Design Iconos" },
	{key:"mdi mdi-content-save-settings",nombre:"mdi mdi-content-save-settings", libreria:"Material Design Iconos" },
	{key:"mdi mdi-contrast",nombre:"mdi mdi-contrast", libreria:"Material Design Iconos" },
	{key:"mdi mdi-contrast-box",nombre:"mdi mdi-contrast-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-contrast-circle",nombre:"mdi mdi-contrast-circle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cookie",nombre:"mdi mdi-cookie", libreria:"Material Design Iconos" },
	{key:"mdi mdi-copyright",nombre:"mdi mdi-copyright", libreria:"Material Design Iconos" },
	{key:"mdi mdi-corn",nombre:"mdi mdi-corn", libreria:"Material Design Iconos" },
	{key:"mdi mdi-counter",nombre:"mdi mdi-counter", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cow",nombre:"mdi mdi-cow", libreria:"Material Design Iconos" },
	{key:"mdi mdi-creation",nombre:"mdi mdi-creation", libreria:"Material Design Iconos" },
	{key:"mdi mdi-credit-card",nombre:"mdi mdi-credit-card", libreria:"Material Design Iconos" },
	{key:"mdi mdi-credit-card-multiple",nombre:"mdi mdi-credit-card-multiple", libreria:"Material Design Iconos" },
	{key:"mdi mdi-credit-card-off",nombre:"mdi mdi-credit-card-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-credit-card-plus",nombre:"mdi mdi-credit-card-plus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-credit-card-scan",nombre:"mdi mdi-credit-card-scan", libreria:"Material Design Iconos" },
	{key:"mdi mdi-crop",nombre:"mdi mdi-crop", libreria:"Material Design Iconos" },
	{key:"mdi mdi-crop-free",nombre:"mdi mdi-crop-free", libreria:"Material Design Iconos" },
	{key:"mdi mdi-crop-landscape",nombre:"mdi mdi-crop-landscape", libreria:"Material Design Iconos" },
	{key:"mdi mdi-crop-portrait",nombre:"mdi mdi-crop-portrait", libreria:"Material Design Iconos" },
	{key:"mdi mdi-crop-rotate",nombre:"mdi mdi-crop-rotate", libreria:"Material Design Iconos" },
	{key:"mdi mdi-crop-square",nombre:"mdi mdi-crop-square", libreria:"Material Design Iconos" },
	{key:"mdi mdi-crosshairs",nombre:"mdi mdi-crosshairs", libreria:"Material Design Iconos" },
	{key:"mdi mdi-crosshairs-gps",nombre:"mdi mdi-crosshairs-gps", libreria:"Material Design Iconos" },
	{key:"mdi mdi-crown",nombre:"mdi mdi-crown", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cube",nombre:"mdi mdi-cube", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cube-outline",nombre:"mdi mdi-cube-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cube-send",nombre:"mdi mdi-cube-send", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cube-unfolded",nombre:"mdi mdi-cube-unfolded", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cup",nombre:"mdi mdi-cup", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cup-off",nombre:"mdi mdi-cup-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cup-water",nombre:"mdi mdi-cup-water", libreria:"Material Design Iconos" },
	{key:"mdi mdi-currency-btc",nombre:"mdi mdi-currency-btc", libreria:"Material Design Iconos" },
	{key:"mdi mdi-currency-cny",nombre:"mdi mdi-currency-cny", libreria:"Material Design Iconos" },
	{key:"mdi mdi-currency-eth",nombre:"mdi mdi-currency-eth", libreria:"Material Design Iconos" },
	{key:"mdi mdi-currency-eur",nombre:"mdi mdi-currency-eur", libreria:"Material Design Iconos" },
	{key:"mdi mdi-currency-gbp",nombre:"mdi mdi-currency-gbp", libreria:"Material Design Iconos" },
	{key:"mdi mdi-currency-inr",nombre:"mdi mdi-currency-inr", libreria:"Material Design Iconos" },
	{key:"mdi mdi-currency-jpy",nombre:"mdi mdi-currency-jpy", libreria:"Material Design Iconos" },
	{key:"mdi mdi-currency-krw",nombre:"mdi mdi-currency-krw", libreria:"Material Design Iconos" },
	{key:"mdi mdi-currency-ngn",nombre:"mdi mdi-currency-ngn", libreria:"Material Design Iconos" },
	{key:"mdi mdi-currency-rub",nombre:"mdi mdi-currency-rub", libreria:"Material Design Iconos" },
	{key:"mdi mdi-currency-sign",nombre:"mdi mdi-currency-sign", libreria:"Material Design Iconos" },
	{key:"mdi mdi-currency-try",nombre:"mdi mdi-currency-try", libreria:"Material Design Iconos" },
	{key:"mdi mdi-currency-twd",nombre:"mdi mdi-currency-twd", libreria:"Material Design Iconos" },
	{key:"mdi mdi-currency-usd",nombre:"mdi mdi-currency-usd", libreria:"Material Design Iconos" },
	{key:"mdi mdi-currency-usd-off",nombre:"mdi mdi-currency-usd-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cursor-default",nombre:"mdi mdi-cursor-default", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cursor-default-outline",nombre:"mdi mdi-cursor-default-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cursor-move",nombre:"mdi mdi-cursor-move", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cursor-pointer",nombre:"mdi mdi-cursor-pointer", libreria:"Material Design Iconos" },
	{key:"mdi mdi-cursor-text",nombre:"mdi mdi-cursor-text", libreria:"Material Design Iconos" },
	{key:"mdi mdi-database",nombre:"mdi mdi-database", libreria:"Material Design Iconos" },
	{key:"mdi mdi-database-minus",nombre:"mdi mdi-database-minus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-database-plus",nombre:"mdi mdi-database-plus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-debug-step-into",nombre:"mdi mdi-debug-step-into", libreria:"Material Design Iconos" },
	{key:"mdi mdi-debug-step-out",nombre:"mdi mdi-debug-step-out", libreria:"Material Design Iconos" },
	{key:"mdi mdi-debug-step-over",nombre:"mdi mdi-debug-step-over", libreria:"Material Design Iconos" },
	{key:"mdi mdi-decagram",nombre:"mdi mdi-decagram", libreria:"Material Design Iconos" },
	{key:"mdi mdi-decagram-outline",nombre:"mdi mdi-decagram-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-decimal-decrease",nombre:"mdi mdi-decimal-decrease", libreria:"Material Design Iconos" },
	{key:"mdi mdi-decimal-increase",nombre:"mdi mdi-decimal-increase", libreria:"Material Design Iconos" },
	{key:"mdi mdi-delete",nombre:"mdi mdi-delete", libreria:"Material Design Iconos" },
	{key:"mdi mdi-delete-circle",nombre:"mdi mdi-delete-circle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-delete-empty",nombre:"mdi mdi-delete-empty", libreria:"Material Design Iconos" },
	{key:"mdi mdi-delete-forever",nombre:"mdi mdi-delete-forever", libreria:"Material Design Iconos" },
	{key:"mdi mdi-delete-sweep",nombre:"mdi mdi-delete-sweep", libreria:"Material Design Iconos" },
	{key:"mdi mdi-delete-variant",nombre:"mdi mdi-delete-variant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-delta",nombre:"mdi mdi-delta", libreria:"Material Design Iconos" },
	{key:"mdi mdi-deskphone",nombre:"mdi mdi-deskphone", libreria:"Material Design Iconos" },
	{key:"mdi mdi-desktop-classic",nombre:"mdi mdi-desktop-classic", libreria:"Material Design Iconos" },
	{key:"mdi mdi-desktop-mac",nombre:"mdi mdi-desktop-mac", libreria:"Material Design Iconos" },
	{key:"mdi mdi-desktop-tower",nombre:"mdi mdi-desktop-tower", libreria:"Material Design Iconos" },
	{key:"mdi mdi-details",nombre:"mdi mdi-details", libreria:"Material Design Iconos" },
	{key:"mdi mdi-developer-board",nombre:"mdi mdi-developer-board", libreria:"Material Design Iconos" },
	{key:"mdi mdi-deviantart",nombre:"mdi mdi-deviantart", libreria:"Material Design Iconos" },
	{key:"mdi mdi-dialpad",nombre:"mdi mdi-dialpad", libreria:"Material Design Iconos" },
	{key:"mdi mdi-diamond",nombre:"mdi mdi-diamond", libreria:"Material Design Iconos" },
	{key:"mdi mdi-dice-1",nombre:"mdi mdi-dice-1", libreria:"Material Design Iconos" },
	{key:"mdi mdi-dice-2",nombre:"mdi mdi-dice-2", libreria:"Material Design Iconos" },
	{key:"mdi mdi-dice-3",nombre:"mdi mdi-dice-3", libreria:"Material Design Iconos" },
	{key:"mdi mdi-dice-4",nombre:"mdi mdi-dice-4", libreria:"Material Design Iconos" },
	{key:"mdi mdi-dice-5",nombre:"mdi mdi-dice-5", libreria:"Material Design Iconos" },
	{key:"mdi mdi-dice-6",nombre:"mdi mdi-dice-6", libreria:"Material Design Iconos" },
	{key:"mdi mdi-dice-d10",nombre:"mdi mdi-dice-d10", libreria:"Material Design Iconos" },
	{key:"mdi mdi-dice-d20",nombre:"mdi mdi-dice-d20", libreria:"Material Design Iconos" },
	{key:"mdi mdi-dice-d4",nombre:"mdi mdi-dice-d4", libreria:"Material Design Iconos" },
	{key:"mdi mdi-dice-d6",nombre:"mdi mdi-dice-d6", libreria:"Material Design Iconos" },
	{key:"mdi mdi-dice-d8",nombre:"mdi mdi-dice-d8", libreria:"Material Design Iconos" },
	{key:"mdi mdi-dice-multiple",nombre:"mdi mdi-dice-multiple", libreria:"Material Design Iconos" },
	{key:"mdi mdi-dictionary",nombre:"mdi mdi-dictionary", libreria:"Material Design Iconos" },
	{key:"mdi mdi-dip-switch",nombre:"mdi mdi-dip-switch", libreria:"Material Design Iconos" },
	{key:"mdi mdi-directions",nombre:"mdi mdi-directions", libreria:"Material Design Iconos" },
	{key:"mdi mdi-directions-fork",nombre:"mdi mdi-directions-fork", libreria:"Material Design Iconos" },
	{key:"mdi mdi-discord",nombre:"mdi mdi-discord", libreria:"Material Design Iconos" },
	{key:"mdi mdi-disk",nombre:"mdi mdi-disk", libreria:"Material Design Iconos" },
	{key:"mdi mdi-disk-alert",nombre:"mdi mdi-disk-alert", libreria:"Material Design Iconos" },
	{key:"mdi mdi-disqus",nombre:"mdi mdi-disqus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-disqus-outline",nombre:"mdi mdi-disqus-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-division",nombre:"mdi mdi-division", libreria:"Material Design Iconos" },
	{key:"mdi mdi-division-box",nombre:"mdi mdi-division-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-dna",nombre:"mdi mdi-dna", libreria:"Material Design Iconos" },
	{key:"mdi mdi-dns",nombre:"mdi mdi-dns", libreria:"Material Design Iconos" },
	{key:"mdi mdi-do-not-disturb",nombre:"mdi mdi-do-not-disturb", libreria:"Material Design Iconos" },
	{key:"mdi mdi-do-not-disturb-off",nombre:"mdi mdi-do-not-disturb-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-dolby",nombre:"mdi mdi-dolby", libreria:"Material Design Iconos" },
	{key:"mdi mdi-domain",nombre:"mdi mdi-domain", libreria:"Material Design Iconos" },
	{key:"mdi mdi-donkey",nombre:"mdi mdi-donkey", libreria:"Material Design Iconos" },
	{key:"mdi mdi-dots-horizontal",nombre:"mdi mdi-dots-horizontal", libreria:"Material Design Iconos" },
	{key:"mdi mdi-dots-horizontal-circle",nombre:"mdi mdi-dots-horizontal-circle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-dots-vertical",nombre:"mdi mdi-dots-vertical", libreria:"Material Design Iconos" },
	{key:"mdi mdi-dots-vertical-circle",nombre:"mdi mdi-dots-vertical-circle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-douban",nombre:"mdi mdi-douban", libreria:"Material Design Iconos" },
	{key:"mdi mdi-download",nombre:"mdi mdi-download", libreria:"Material Design Iconos" },
	{key:"mdi mdi-download-network",nombre:"mdi mdi-download-network", libreria:"Material Design Iconos" },
	{key:"mdi mdi-drag",nombre:"mdi mdi-drag", libreria:"Material Design Iconos" },
	{key:"mdi mdi-drag-horizontal",nombre:"mdi mdi-drag-horizontal", libreria:"Material Design Iconos" },
	{key:"mdi mdi-drag-vertical",nombre:"mdi mdi-drag-vertical", libreria:"Material Design Iconos" },
	{key:"mdi mdi-drawing",nombre:"mdi mdi-drawing", libreria:"Material Design Iconos" },
	{key:"mdi mdi-drawing-box",nombre:"mdi mdi-drawing-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-dribbble",nombre:"mdi mdi-dribbble", libreria:"Material Design Iconos" },
	{key:"mdi mdi-dribbble-box",nombre:"mdi mdi-dribbble-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-drone",nombre:"mdi mdi-drone", libreria:"Material Design Iconos" },
	{key:"mdi mdi-dropbox",nombre:"mdi mdi-dropbox", libreria:"Material Design Iconos" },
	{key:"mdi mdi-drupal",nombre:"mdi mdi-drupal", libreria:"Material Design Iconos" },
	{key:"mdi mdi-duck",nombre:"mdi mdi-duck", libreria:"Material Design Iconos" },
	{key:"mdi mdi-dumbbell",nombre:"mdi mdi-dumbbell", libreria:"Material Design Iconos" },
	{key:"mdi mdi-ear-hearing",nombre:"mdi mdi-ear-hearing", libreria:"Material Design Iconos" },
	{key:"mdi mdi-earth",nombre:"mdi mdi-earth", libreria:"Material Design Iconos" },
	{key:"mdi mdi-earth-box",nombre:"mdi mdi-earth-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-earth-box-off",nombre:"mdi mdi-earth-box-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-earth-off",nombre:"mdi mdi-earth-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-edge",nombre:"mdi mdi-edge", libreria:"Material Design Iconos" },
	{key:"mdi mdi-eject",nombre:"mdi mdi-eject", libreria:"Material Design Iconos" },
	{key:"mdi mdi-elephant",nombre:"mdi mdi-elephant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-elevation-decline",nombre:"mdi mdi-elevation-decline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-elevation-rise",nombre:"mdi mdi-elevation-rise", libreria:"Material Design Iconos" },
	{key:"mdi mdi-elevator",nombre:"mdi mdi-elevator", libreria:"Material Design Iconos" },
	{key:"mdi mdi-email",nombre:"mdi mdi-email", libreria:"Material Design Iconos" },
	{key:"mdi mdi-email-alert",nombre:"mdi mdi-email-alert", libreria:"Material Design Iconos" },
	{key:"mdi mdi-email-open",nombre:"mdi mdi-email-open", libreria:"Material Design Iconos" },
	{key:"mdi mdi-email-open-outline",nombre:"mdi mdi-email-open-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-email-outline",nombre:"mdi mdi-email-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-email-secure",nombre:"mdi mdi-email-secure", libreria:"Material Design Iconos" },
	{key:"mdi mdi-email-variant",nombre:"mdi mdi-email-variant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-emby",nombre:"mdi mdi-emby", libreria:"Material Design Iconos" },
	{key:"mdi mdi-emoticon",nombre:"mdi mdi-emoticon", libreria:"Material Design Iconos" },
	{key:"mdi mdi-emoticon-cool",nombre:"mdi mdi-emoticon-cool", libreria:"Material Design Iconos" },
	{key:"mdi mdi-emoticon-dead",nombre:"mdi mdi-emoticon-dead", libreria:"Material Design Iconos" },
	{key:"mdi mdi-emoticon-devil",nombre:"mdi mdi-emoticon-devil", libreria:"Material Design Iconos" },
	{key:"mdi mdi-emoticon-excited",nombre:"mdi mdi-emoticon-excited", libreria:"Material Design Iconos" },
	{key:"mdi mdi-emoticon-happy",nombre:"mdi mdi-emoticon-happy", libreria:"Material Design Iconos" },
	{key:"mdi mdi-emoticon-neutral",nombre:"mdi mdi-emoticon-neutral", libreria:"Material Design Iconos" },
	{key:"mdi mdi-emoticon-poop",nombre:"mdi mdi-emoticon-poop", libreria:"Material Design Iconos" },
	{key:"mdi mdi-emoticon-sad",nombre:"mdi mdi-emoticon-sad", libreria:"Material Design Iconos" },
	{key:"mdi mdi-emoticon-tongue",nombre:"mdi mdi-emoticon-tongue", libreria:"Material Design Iconos" },
	{key:"mdi mdi-engine",nombre:"mdi mdi-engine", libreria:"Material Design Iconos" },
	{key:"mdi mdi-engine-outline",nombre:"mdi mdi-engine-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-equal",nombre:"mdi mdi-equal", libreria:"Material Design Iconos" },
	{key:"mdi mdi-equal-box",nombre:"mdi mdi-equal-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-eraser",nombre:"mdi mdi-eraser", libreria:"Material Design Iconos" },
	{key:"mdi mdi-eraser-variant",nombre:"mdi mdi-eraser-variant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-escalator",nombre:"mdi mdi-escalator", libreria:"Material Design Iconos" },
	{key:"mdi mdi-ethernet",nombre:"mdi mdi-ethernet", libreria:"Material Design Iconos" },
	{key:"mdi mdi-ethernet-cable",nombre:"mdi mdi-ethernet-cable", libreria:"Material Design Iconos" },
	{key:"mdi mdi-ethernet-cable-off",nombre:"mdi mdi-ethernet-cable-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-etsy",nombre:"mdi mdi-etsy", libreria:"Material Design Iconos" },
	{key:"mdi mdi-ev-station",nombre:"mdi mdi-ev-station", libreria:"Material Design Iconos" },
	{key:"mdi mdi-eventbrite",nombre:"mdi mdi-eventbrite", libreria:"Material Design Iconos" },
	{key:"mdi mdi-evernote",nombre:"mdi mdi-evernote", libreria:"Material Design Iconos" },
	{key:"mdi mdi-exclamation",nombre:"mdi mdi-exclamation", libreria:"Material Design Iconos" },
	{key:"mdi mdi-exit-to-app",nombre:"mdi mdi-exit-to-app", libreria:"Material Design Iconos" },
	{key:"mdi mdi-export",nombre:"mdi mdi-export", libreria:"Material Design Iconos" },
	{key:"mdi mdi-eye",nombre:"mdi mdi-eye", libreria:"Material Design Iconos" },
	{key:"mdi mdi-eye-off",nombre:"mdi mdi-eye-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-eye-off-outline",nombre:"mdi mdi-eye-off-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-eye-outline",nombre:"mdi mdi-eye-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-eyedropper",nombre:"mdi mdi-eyedropper", libreria:"Material Design Iconos" },
	{key:"mdi mdi-eyedropper-variant",nombre:"mdi mdi-eyedropper-variant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-face",nombre:"mdi mdi-face", libreria:"Material Design Iconos" },
	{key:"mdi mdi-face-profile",nombre:"mdi mdi-face-profile", libreria:"Material Design Iconos" },
	{key:"mdi mdi-facebook",nombre:"mdi mdi-facebook", libreria:"Material Design Iconos" },
	{key:"mdi mdi-facebook-box",nombre:"mdi mdi-facebook-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-facebook-messenger",nombre:"mdi mdi-facebook-messenger", libreria:"Material Design Iconos" },
	{key:"mdi mdi-factory",nombre:"mdi mdi-factory", libreria:"Material Design Iconos" },
	{key:"mdi mdi-fan",nombre:"mdi mdi-fan", libreria:"Material Design Iconos" },
	{key:"mdi mdi-fast-forward",nombre:"mdi mdi-fast-forward", libreria:"Material Design Iconos" },
	{key:"mdi mdi-fast-forward-outline",nombre:"mdi mdi-fast-forward-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-fax",nombre:"mdi mdi-fax", libreria:"Material Design Iconos" },
	{key:"mdi mdi-feather",nombre:"mdi mdi-feather", libreria:"Material Design Iconos" },
	{key:"mdi mdi-ferry",nombre:"mdi mdi-ferry", libreria:"Material Design Iconos" },
	{key:"mdi mdi-file",nombre:"mdi mdi-file", libreria:"Material Design Iconos" },
	{key:"mdi mdi-file-account",nombre:"mdi mdi-file-account", libreria:"Material Design Iconos" },
	{key:"mdi mdi-file-chart",nombre:"mdi mdi-file-chart", libreria:"Material Design Iconos" },
	{key:"mdi mdi-file-check",nombre:"mdi mdi-file-check", libreria:"Material Design Iconos" },
	{key:"mdi mdi-file-cloud",nombre:"mdi mdi-file-cloud", libreria:"Material Design Iconos" },
	{key:"mdi mdi-file-delimited",nombre:"mdi mdi-file-delimited", libreria:"Material Design Iconos" },
	{key:"mdi mdi-file-document",nombre:"mdi mdi-file-document", libreria:"Material Design Iconos" },
	{key:"mdi mdi-file-document-box",nombre:"mdi mdi-file-document-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-file-excel",nombre:"mdi mdi-file-excel", libreria:"Material Design Iconos" },
	{key:"mdi mdi-file-excel-box",nombre:"mdi mdi-file-excel-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-file-export",nombre:"mdi mdi-file-export", libreria:"Material Design Iconos" },
	{key:"mdi mdi-file-find",nombre:"mdi mdi-file-find", libreria:"Material Design Iconos" },
	{key:"mdi mdi-file-hidden",nombre:"mdi mdi-file-hidden", libreria:"Material Design Iconos" },
	{key:"mdi mdi-file-image",nombre:"mdi mdi-file-image", libreria:"Material Design Iconos" },
	{key:"mdi mdi-file-import",nombre:"mdi mdi-file-import", libreria:"Material Design Iconos" },
	{key:"mdi mdi-file-lock",nombre:"mdi mdi-file-lock", libreria:"Material Design Iconos" },
	{key:"mdi mdi-file-multiple",nombre:"mdi mdi-file-multiple", libreria:"Material Design Iconos" },
	{key:"mdi mdi-file-music",nombre:"mdi mdi-file-music", libreria:"Material Design Iconos" },
	{key:"mdi mdi-file-outline",nombre:"mdi mdi-file-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-file-pdf",nombre:"mdi mdi-file-pdf", libreria:"Material Design Iconos" },
	{key:"mdi mdi-file-pdf-box",nombre:"mdi mdi-file-pdf-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-file-plus",nombre:"mdi mdi-file-plus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-file-powerpoint",nombre:"mdi mdi-file-powerpoint", libreria:"Material Design Iconos" },
	{key:"mdi mdi-file-powerpoint-box",nombre:"mdi mdi-file-powerpoint-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-file-presentation-box",nombre:"mdi mdi-file-presentation-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-file-restore",nombre:"mdi mdi-file-restore", libreria:"Material Design Iconos" },
	{key:"mdi mdi-file-send",nombre:"mdi mdi-file-send", libreria:"Material Design Iconos" },
	{key:"mdi mdi-file-tree",nombre:"mdi mdi-file-tree", libreria:"Material Design Iconos" },
	{key:"mdi mdi-file-video",nombre:"mdi mdi-file-video", libreria:"Material Design Iconos" },
	{key:"mdi mdi-file-word",nombre:"mdi mdi-file-word", libreria:"Material Design Iconos" },
	{key:"mdi mdi-file-word-box",nombre:"mdi mdi-file-word-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-file-xml",nombre:"mdi mdi-file-xml", libreria:"Material Design Iconos" },
	{key:"mdi mdi-film",nombre:"mdi mdi-film", libreria:"Material Design Iconos" },
	{key:"mdi mdi-filmstrip",nombre:"mdi mdi-filmstrip", libreria:"Material Design Iconos" },
	{key:"mdi mdi-filmstrip-off",nombre:"mdi mdi-filmstrip-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-filter",nombre:"mdi mdi-filter", libreria:"Material Design Iconos" },
	{key:"mdi mdi-filter-outline",nombre:"mdi mdi-filter-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-filter-remove",nombre:"mdi mdi-filter-remove", libreria:"Material Design Iconos" },
	{key:"mdi mdi-filter-remove-outline",nombre:"mdi mdi-filter-remove-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-filter-variant",nombre:"mdi mdi-filter-variant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-find-replace",nombre:"mdi mdi-find-replace", libreria:"Material Design Iconos" },
	{key:"mdi mdi-fingerprint",nombre:"mdi mdi-fingerprint", libreria:"Material Design Iconos" },
	{key:"mdi mdi-fire",nombre:"mdi mdi-fire", libreria:"Material Design Iconos" },
	{key:"mdi mdi-firefox",nombre:"mdi mdi-firefox", libreria:"Material Design Iconos" },
	{key:"mdi mdi-fish",nombre:"mdi mdi-fish", libreria:"Material Design Iconos" },
	{key:"mdi mdi-flag",nombre:"mdi mdi-flag", libreria:"Material Design Iconos" },
	{key:"mdi mdi-flag-checkered",nombre:"mdi mdi-flag-checkered", libreria:"Material Design Iconos" },
	{key:"mdi mdi-flag-outline",nombre:"mdi mdi-flag-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-flag-outline-variant",nombre:"mdi mdi-flag-outline-variant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-flag-triangle",nombre:"mdi mdi-flag-triangle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-flag-variant",nombre:"mdi mdi-flag-variant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-flash",nombre:"mdi mdi-flash", libreria:"Material Design Iconos" },
	{key:"mdi mdi-flash-auto",nombre:"mdi mdi-flash-auto", libreria:"Material Design Iconos" },
	{key:"mdi mdi-flash-off",nombre:"mdi mdi-flash-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-flash-outline",nombre:"mdi mdi-flash-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-flash-red-eye",nombre:"mdi mdi-flash-red-eye", libreria:"Material Design Iconos" },
	{key:"mdi mdi-flashlight",nombre:"mdi mdi-flashlight", libreria:"Material Design Iconos" },
	{key:"mdi mdi-flashlight-off",nombre:"mdi mdi-flashlight-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-flask",nombre:"mdi mdi-flask", libreria:"Material Design Iconos" },
	{key:"mdi mdi-flask-empty",nombre:"mdi mdi-flask-empty", libreria:"Material Design Iconos" },
	{key:"mdi mdi-flask-empty-outline",nombre:"mdi mdi-flask-empty-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-flask-outline",nombre:"mdi mdi-flask-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-flattr",nombre:"mdi mdi-flattr", libreria:"Material Design Iconos" },
	{key:"mdi mdi-flip-to-back",nombre:"mdi mdi-flip-to-back", libreria:"Material Design Iconos" },
	{key:"mdi mdi-flip-to-front",nombre:"mdi mdi-flip-to-front", libreria:"Material Design Iconos" },
	{key:"mdi mdi-floppy",nombre:"mdi mdi-floppy", libreria:"Material Design Iconos" },
	{key:"mdi mdi-flower",nombre:"mdi mdi-flower", libreria:"Material Design Iconos" },
	{key:"mdi mdi-folder",nombre:"mdi mdi-folder", libreria:"Material Design Iconos" },
	{key:"mdi mdi-folder-account",nombre:"mdi mdi-folder-account", libreria:"Material Design Iconos" },
	{key:"mdi mdi-folder-download",nombre:"mdi mdi-folder-download", libreria:"Material Design Iconos" },
	{key:"mdi mdi-folder-google-drive",nombre:"mdi mdi-folder-google-drive", libreria:"Material Design Iconos" },
	{key:"mdi mdi-folder-image",nombre:"mdi mdi-folder-image", libreria:"Material Design Iconos" },
	{key:"mdi mdi-folder-lock",nombre:"mdi mdi-folder-lock", libreria:"Material Design Iconos" },
	{key:"mdi mdi-folder-lock-open",nombre:"mdi mdi-folder-lock-open", libreria:"Material Design Iconos" },
	{key:"mdi mdi-folder-move",nombre:"mdi mdi-folder-move", libreria:"Material Design Iconos" },
	{key:"mdi mdi-folder-multiple",nombre:"mdi mdi-folder-multiple", libreria:"Material Design Iconos" },
	{key:"mdi mdi-folder-multiple-image",nombre:"mdi mdi-folder-multiple-image", libreria:"Material Design Iconos" },
	{key:"mdi mdi-folder-multiple-outline",nombre:"mdi mdi-folder-multiple-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-folder-open",nombre:"mdi mdi-folder-open", libreria:"Material Design Iconos" },
	{key:"mdi mdi-folder-outline",nombre:"mdi mdi-folder-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-folder-plus",nombre:"mdi mdi-folder-plus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-folder-remove",nombre:"mdi mdi-folder-remove", libreria:"Material Design Iconos" },
	{key:"mdi mdi-folder-star",nombre:"mdi mdi-folder-star", libreria:"Material Design Iconos" },
	{key:"mdi mdi-folder-upload",nombre:"mdi mdi-folder-upload", libreria:"Material Design Iconos" },
	{key:"mdi mdi-font-awesome",nombre:"mdi mdi-font-awesome", libreria:"Material Design Iconos" },
	{key:"mdi mdi-food",nombre:"mdi mdi-food", libreria:"Material Design Iconos" },
	{key:"mdi mdi-food-apple",nombre:"mdi mdi-food-apple", libreria:"Material Design Iconos" },
	{key:"mdi mdi-food-croissant",nombre:"mdi mdi-food-croissant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-food-fork-drink",nombre:"mdi mdi-food-fork-drink", libreria:"Material Design Iconos" },
	{key:"mdi mdi-food-off",nombre:"mdi mdi-food-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-food-variant",nombre:"mdi mdi-food-variant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-football",nombre:"mdi mdi-football", libreria:"Material Design Iconos" },
	{key:"mdi mdi-football-australian",nombre:"mdi mdi-football-australian", libreria:"Material Design Iconos" },
	{key:"mdi mdi-football-helmet",nombre:"mdi mdi-football-helmet", libreria:"Material Design Iconos" },
	{key:"mdi mdi-forklift",nombre:"mdi mdi-forklift", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-align-bottom",nombre:"mdi mdi-format-align-bottom", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-align-center",nombre:"mdi mdi-format-align-center", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-align-justify",nombre:"mdi mdi-format-align-justify", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-align-left",nombre:"mdi mdi-format-align-left", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-align-middle",nombre:"mdi mdi-format-align-middle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-align-right",nombre:"mdi mdi-format-align-right", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-align-top",nombre:"mdi mdi-format-align-top", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-annotation-plus",nombre:"mdi mdi-format-annotation-plus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-bold",nombre:"mdi mdi-format-bold", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-clear",nombre:"mdi mdi-format-clear", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-color-fill",nombre:"mdi mdi-format-color-fill", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-color-text",nombre:"mdi mdi-format-color-text", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-float-center",nombre:"mdi mdi-format-float-center", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-float-left",nombre:"mdi mdi-format-float-left", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-float-none",nombre:"mdi mdi-format-float-none", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-float-right",nombre:"mdi mdi-format-float-right", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-font",nombre:"mdi mdi-format-font", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-header-1",nombre:"mdi mdi-format-header-1", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-header-2",nombre:"mdi mdi-format-header-2", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-header-3",nombre:"mdi mdi-format-header-3", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-header-4",nombre:"mdi mdi-format-header-4", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-header-5",nombre:"mdi mdi-format-header-5", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-header-6",nombre:"mdi mdi-format-header-6", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-header-decrease",nombre:"mdi mdi-format-header-decrease", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-header-equal",nombre:"mdi mdi-format-header-equal", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-header-increase",nombre:"mdi mdi-format-header-increase", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-header-pound",nombre:"mdi mdi-format-header-pound", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-horizontal-align-center",nombre:"mdi mdi-format-horizontal-align-center", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-horizontal-align-left",nombre:"mdi mdi-format-horizontal-align-left", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-horizontal-align-right",nombre:"mdi mdi-format-horizontal-align-right", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-indent-decrease",nombre:"mdi mdi-format-indent-decrease", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-indent-increase",nombre:"mdi mdi-format-indent-increase", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-italic",nombre:"mdi mdi-format-italic", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-line-spacing",nombre:"mdi mdi-format-line-spacing", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-line-style",nombre:"mdi mdi-format-line-style", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-line-weight",nombre:"mdi mdi-format-line-weight", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-list-bulleted",nombre:"mdi mdi-format-list-bulleted", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-list-bulleted-type",nombre:"mdi mdi-format-list-bulleted-type", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-list-checks",nombre:"mdi mdi-format-list-checks", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-list-numbers",nombre:"mdi mdi-format-list-numbers", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-page-break",nombre:"mdi mdi-format-page-break", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-paint",nombre:"mdi mdi-format-paint", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-paragraph",nombre:"mdi mdi-format-paragraph", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-pilcrow",nombre:"mdi mdi-format-pilcrow", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-quote-close",nombre:"mdi mdi-format-quote-close", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-quote-open",nombre:"mdi mdi-format-quote-open", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-rotate-90",nombre:"mdi mdi-format-rotate-90", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-section",nombre:"mdi mdi-format-section", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-size",nombre:"mdi mdi-format-size", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-strikethrough",nombre:"mdi mdi-format-strikethrough", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-strikethrough-variant",nombre:"mdi mdi-format-strikethrough-variant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-subscript",nombre:"mdi mdi-format-subscript", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-superscript",nombre:"mdi mdi-format-superscript", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-text",nombre:"mdi mdi-format-text", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-textdirection-l-to-r",nombre:"mdi mdi-format-textdirection-l-to-r", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-textdirection-r-to-l",nombre:"mdi mdi-format-textdirection-r-to-l", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-title",nombre:"mdi mdi-format-title", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-underline",nombre:"mdi mdi-format-underline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-vertical-align-bottom",nombre:"mdi mdi-format-vertical-align-bottom", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-vertical-align-center",nombre:"mdi mdi-format-vertical-align-center", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-vertical-align-top",nombre:"mdi mdi-format-vertical-align-top", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-wrap-inline",nombre:"mdi mdi-format-wrap-inline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-wrap-square",nombre:"mdi mdi-format-wrap-square", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-wrap-tight",nombre:"mdi mdi-format-wrap-tight", libreria:"Material Design Iconos" },
	{key:"mdi mdi-format-wrap-top-bottom",nombre:"mdi mdi-format-wrap-top-bottom", libreria:"Material Design Iconos" },
	{key:"mdi mdi-forum",nombre:"mdi mdi-forum", libreria:"Material Design Iconos" },
	{key:"mdi mdi-forward",nombre:"mdi mdi-forward", libreria:"Material Design Iconos" },
	{key:"mdi mdi-foursquare",nombre:"mdi mdi-foursquare", libreria:"Material Design Iconos" },
	{key:"mdi mdi-fridge",nombre:"mdi mdi-fridge", libreria:"Material Design Iconos" },
	{key:"mdi mdi-fridge-filled",nombre:"mdi mdi-fridge-filled", libreria:"Material Design Iconos" },
	{key:"mdi mdi-fridge-filled-bottom",nombre:"mdi mdi-fridge-filled-bottom", libreria:"Material Design Iconos" },
	{key:"mdi mdi-fridge-filled-top",nombre:"mdi mdi-fridge-filled-top", libreria:"Material Design Iconos" },
	{key:"mdi mdi-fuel",nombre:"mdi mdi-fuel", libreria:"Material Design Iconos" },
	{key:"mdi mdi-fullscreen",nombre:"mdi mdi-fullscreen", libreria:"Material Design Iconos" },
	{key:"mdi mdi-fullscreen-exit",nombre:"mdi mdi-fullscreen-exit", libreria:"Material Design Iconos" },
	{key:"mdi mdi-function",nombre:"mdi mdi-function", libreria:"Material Design Iconos" },
	{key:"mdi mdi-gamepad",nombre:"mdi mdi-gamepad", libreria:"Material Design Iconos" },
	{key:"mdi mdi-gamepad-variant",nombre:"mdi mdi-gamepad-variant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-garage",nombre:"mdi mdi-garage", libreria:"Material Design Iconos" },
	{key:"mdi mdi-garage-open",nombre:"mdi mdi-garage-open", libreria:"Material Design Iconos" },
	{key:"mdi mdi-gas-cylinder",nombre:"mdi mdi-gas-cylinder", libreria:"Material Design Iconos" },
	{key:"mdi mdi-gas-station",nombre:"mdi mdi-gas-station", libreria:"Material Design Iconos" },
	{key:"mdi mdi-gate",nombre:"mdi mdi-gate", libreria:"Material Design Iconos" },
	{key:"mdi mdi-gauge",nombre:"mdi mdi-gauge", libreria:"Material Design Iconos" },
	{key:"mdi mdi-gavel",nombre:"mdi mdi-gavel", libreria:"Material Design Iconos" },
	{key:"mdi mdi-gender-female",nombre:"mdi mdi-gender-female", libreria:"Material Design Iconos" },
	{key:"mdi mdi-gender-male",nombre:"mdi mdi-gender-male", libreria:"Material Design Iconos" },
	{key:"mdi mdi-gender-male-female",nombre:"mdi mdi-gender-male-female", libreria:"Material Design Iconos" },
	{key:"mdi mdi-gender-transgender",nombre:"mdi mdi-gender-transgender", libreria:"Material Design Iconos" },
	{key:"mdi mdi-gesture",nombre:"mdi mdi-gesture", libreria:"Material Design Iconos" },
	{key:"mdi mdi-gesture-double-tap",nombre:"mdi mdi-gesture-double-tap", libreria:"Material Design Iconos" },
	{key:"mdi mdi-gesture-swipe-down",nombre:"mdi mdi-gesture-swipe-down", libreria:"Material Design Iconos" },
	{key:"mdi mdi-gesture-swipe-left",nombre:"mdi mdi-gesture-swipe-left", libreria:"Material Design Iconos" },
	{key:"mdi mdi-gesture-swipe-right",nombre:"mdi mdi-gesture-swipe-right", libreria:"Material Design Iconos" },
	{key:"mdi mdi-gesture-swipe-up",nombre:"mdi mdi-gesture-swipe-up", libreria:"Material Design Iconos" },
	{key:"mdi mdi-gesture-tap",nombre:"mdi mdi-gesture-tap", libreria:"Material Design Iconos" },
	{key:"mdi mdi-gesture-two-double-tap",nombre:"mdi mdi-gesture-two-double-tap", libreria:"Material Design Iconos" },
	{key:"mdi mdi-gesture-two-tap",nombre:"mdi mdi-gesture-two-tap", libreria:"Material Design Iconos" },
	{key:"mdi mdi-ghost",nombre:"mdi mdi-ghost", libreria:"Material Design Iconos" },
	{key:"mdi mdi-gift",nombre:"mdi mdi-gift", libreria:"Material Design Iconos" },
	{key:"mdi mdi-git",nombre:"mdi mdi-git", libreria:"Material Design Iconos" },
	{key:"mdi mdi-github-box",nombre:"mdi mdi-github-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-github-circle",nombre:"mdi mdi-github-circle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-github-face",nombre:"mdi mdi-github-face", libreria:"Material Design Iconos" },
	{key:"mdi mdi-glass-flute",nombre:"mdi mdi-glass-flute", libreria:"Material Design Iconos" },
	{key:"mdi mdi-glass-mug",nombre:"mdi mdi-glass-mug", libreria:"Material Design Iconos" },
	{key:"mdi mdi-glass-stange",nombre:"mdi mdi-glass-stange", libreria:"Material Design Iconos" },
	{key:"mdi mdi-glass-tulip",nombre:"mdi mdi-glass-tulip", libreria:"Material Design Iconos" },
	{key:"mdi mdi-glassdoor",nombre:"mdi mdi-glassdoor", libreria:"Material Design Iconos" },
	{key:"mdi mdi-glasses",nombre:"mdi mdi-glasses", libreria:"Material Design Iconos" },
	{key:"mdi mdi-gmail",nombre:"mdi mdi-gmail", libreria:"Material Design Iconos" },
	{key:"mdi mdi-gnome",nombre:"mdi mdi-gnome", libreria:"Material Design Iconos" },
	{key:"mdi mdi-gondola",nombre:"mdi mdi-gondola", libreria:"Material Design Iconos" },
	{key:"mdi mdi-google",nombre:"mdi mdi-google", libreria:"Material Design Iconos" },
	{key:"mdi mdi-google-analytics",nombre:"mdi mdi-google-analytics", libreria:"Material Design Iconos" },
	{key:"mdi mdi-google-assistant",nombre:"mdi mdi-google-assistant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-google-cardboard",nombre:"mdi mdi-google-cardboard", libreria:"Material Design Iconos" },
	{key:"mdi mdi-google-chrome",nombre:"mdi mdi-google-chrome", libreria:"Material Design Iconos" },
	{key:"mdi mdi-google-circles",nombre:"mdi mdi-google-circles", libreria:"Material Design Iconos" },
	{key:"mdi mdi-google-circles-communities",nombre:"mdi mdi-google-circles-communities", libreria:"Material Design Iconos" },
	{key:"mdi mdi-google-circles-extended",nombre:"mdi mdi-google-circles-extended", libreria:"Material Design Iconos" },
	{key:"mdi mdi-google-circles-group",nombre:"mdi mdi-google-circles-group", libreria:"Material Design Iconos" },
	{key:"mdi mdi-google-controller",nombre:"mdi mdi-google-controller", libreria:"Material Design Iconos" },
	{key:"mdi mdi-google-controller-off",nombre:"mdi mdi-google-controller-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-google-drive",nombre:"mdi mdi-google-drive", libreria:"Material Design Iconos" },
	{key:"mdi mdi-google-earth",nombre:"mdi mdi-google-earth", libreria:"Material Design Iconos" },
	{key:"mdi mdi-google-glass",nombre:"mdi mdi-google-glass", libreria:"Material Design Iconos" },
	{key:"mdi mdi-google-keep",nombre:"mdi mdi-google-keep", libreria:"Material Design Iconos" },
	{key:"mdi mdi-google-maps",nombre:"mdi mdi-google-maps", libreria:"Material Design Iconos" },
	{key:"mdi mdi-google-nearby",nombre:"mdi mdi-google-nearby", libreria:"Material Design Iconos" },
	{key:"mdi mdi-google-pages",nombre:"mdi mdi-google-pages", libreria:"Material Design Iconos" },
	{key:"mdi mdi-google-photos",nombre:"mdi mdi-google-photos", libreria:"Material Design Iconos" },
	{key:"mdi mdi-google-physical-web",nombre:"mdi mdi-google-physical-web", libreria:"Material Design Iconos" },
	{key:"mdi mdi-google-play",nombre:"mdi mdi-google-play", libreria:"Material Design Iconos" },
	{key:"mdi mdi-google-plus",nombre:"mdi mdi-google-plus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-google-plus-box",nombre:"mdi mdi-google-plus-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-google-translate",nombre:"mdi mdi-google-translate", libreria:"Material Design Iconos" },
	{key:"mdi mdi-google-wallet",nombre:"mdi mdi-google-wallet", libreria:"Material Design Iconos" },
	{key:"mdi mdi-gradient",nombre:"mdi mdi-gradient", libreria:"Material Design Iconos" },
	{key:"mdi mdi-grease-pencil",nombre:"mdi mdi-grease-pencil", libreria:"Material Design Iconos" },
	{key:"mdi mdi-grid",nombre:"mdi mdi-grid", libreria:"Material Design Iconos" },
	{key:"mdi mdi-grid-large",nombre:"mdi mdi-grid-large", libreria:"Material Design Iconos" },
	{key:"mdi mdi-grid-off",nombre:"mdi mdi-grid-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-group",nombre:"mdi mdi-group", libreria:"Material Design Iconos" },
	{key:"mdi mdi-guitar-acoustic",nombre:"mdi mdi-guitar-acoustic", libreria:"Material Design Iconos" },
	{key:"mdi mdi-guitar-electric",nombre:"mdi mdi-guitar-electric", libreria:"Material Design Iconos" },
	{key:"mdi mdi-guitar-pick",nombre:"mdi mdi-guitar-pick", libreria:"Material Design Iconos" },
	{key:"mdi mdi-guitar-pick-outline",nombre:"mdi mdi-guitar-pick-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-hackernews",nombre:"mdi mdi-hackernews", libreria:"Material Design Iconos" },
	{key:"mdi mdi-hamburger",nombre:"mdi mdi-hamburger", libreria:"Material Design Iconos" },
	{key:"mdi mdi-hand-pointing-right",nombre:"mdi mdi-hand-pointing-right", libreria:"Material Design Iconos" },
	{key:"mdi mdi-hanger",nombre:"mdi mdi-hanger", libreria:"Material Design Iconos" },
	{key:"mdi mdi-hangouts",nombre:"mdi mdi-hangouts", libreria:"Material Design Iconos" },
	{key:"mdi mdi-harddisk",nombre:"mdi mdi-harddisk", libreria:"Material Design Iconos" },
	{key:"mdi mdi-headphones",nombre:"mdi mdi-headphones", libreria:"Material Design Iconos" },
	{key:"mdi mdi-headphones-box",nombre:"mdi mdi-headphones-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-headphones-off",nombre:"mdi mdi-headphones-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-headphones-settings",nombre:"mdi mdi-headphones-settings", libreria:"Material Design Iconos" },
	{key:"mdi mdi-headset",nombre:"mdi mdi-headset", libreria:"Material Design Iconos" },
	{key:"mdi mdi-headset-dock",nombre:"mdi mdi-headset-dock", libreria:"Material Design Iconos" },
	{key:"mdi mdi-headset-off",nombre:"mdi mdi-headset-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-heart",nombre:"mdi mdi-heart", libreria:"Material Design Iconos" },
	{key:"mdi mdi-heart-box",nombre:"mdi mdi-heart-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-heart-box-outline",nombre:"mdi mdi-heart-box-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-heart-broken",nombre:"mdi mdi-heart-broken", libreria:"Material Design Iconos" },
	{key:"mdi mdi-heart-half",nombre:"mdi mdi-heart-half", libreria:"Material Design Iconos" },
	{key:"mdi mdi-heart-half-full",nombre:"mdi mdi-heart-half-full", libreria:"Material Design Iconos" },
	{key:"mdi mdi-heart-half-outline",nombre:"mdi mdi-heart-half-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-heart-off",nombre:"mdi mdi-heart-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-heart-outline",nombre:"mdi mdi-heart-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-heart-pulse",nombre:"mdi mdi-heart-pulse", libreria:"Material Design Iconos" },
	{key:"mdi mdi-help",nombre:"mdi mdi-help", libreria:"Material Design Iconos" },
	{key:"mdi mdi-help-box",nombre:"mdi mdi-help-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-help-circle",nombre:"mdi mdi-help-circle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-help-circle-outline",nombre:"mdi mdi-help-circle-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-help-network",nombre:"mdi mdi-help-network", libreria:"Material Design Iconos" },
	{key:"mdi mdi-hexagon",nombre:"mdi mdi-hexagon", libreria:"Material Design Iconos" },
	{key:"mdi mdi-hexagon-multiple",nombre:"mdi mdi-hexagon-multiple", libreria:"Material Design Iconos" },
	{key:"mdi mdi-hexagon-outline",nombre:"mdi mdi-hexagon-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-high-definition",nombre:"mdi mdi-high-definition", libreria:"Material Design Iconos" },
	{key:"mdi mdi-highway",nombre:"mdi mdi-highway", libreria:"Material Design Iconos" },
	{key:"mdi mdi-history",nombre:"mdi mdi-history", libreria:"Material Design Iconos" },
	{key:"mdi mdi-hololens",nombre:"mdi mdi-hololens", libreria:"Material Design Iconos" },
	{key:"mdi mdi-home",nombre:"mdi mdi-home", libreria:"Material Design Iconos" },
	{key:"mdi mdi-home-assistant",nombre:"mdi mdi-home-assistant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-home-automation",nombre:"mdi mdi-home-automation", libreria:"Material Design Iconos" },
	{key:"mdi mdi-home-circle",nombre:"mdi mdi-home-circle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-home-map-marker",nombre:"mdi mdi-home-map-marker", libreria:"Material Design Iconos" },
	{key:"mdi mdi-home-modern",nombre:"mdi mdi-home-modern", libreria:"Material Design Iconos" },
	{key:"mdi mdi-home-outline",nombre:"mdi mdi-home-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-home-variant",nombre:"mdi mdi-home-variant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-hook",nombre:"mdi mdi-hook", libreria:"Material Design Iconos" },
	{key:"mdi mdi-hook-off",nombre:"mdi mdi-hook-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-hops",nombre:"mdi mdi-hops", libreria:"Material Design Iconos" },
	{key:"mdi mdi-hospital",nombre:"mdi mdi-hospital", libreria:"Material Design Iconos" },
	{key:"mdi mdi-hospital-building",nombre:"mdi mdi-hospital-building", libreria:"Material Design Iconos" },
	{key:"mdi mdi-hospital-marker",nombre:"mdi mdi-hospital-marker", libreria:"Material Design Iconos" },
	{key:"mdi mdi-hotel",nombre:"mdi mdi-hotel", libreria:"Material Design Iconos" },
	{key:"mdi mdi-houzz",nombre:"mdi mdi-houzz", libreria:"Material Design Iconos" },
	{key:"mdi mdi-houzz-box",nombre:"mdi mdi-houzz-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-human",nombre:"mdi mdi-human", libreria:"Material Design Iconos" },
	{key:"mdi mdi-human-child",nombre:"mdi mdi-human-child", libreria:"Material Design Iconos" },
	{key:"mdi mdi-human-female",nombre:"mdi mdi-human-female", libreria:"Material Design Iconos" },
	{key:"mdi mdi-human-greeting",nombre:"mdi mdi-human-greeting", libreria:"Material Design Iconos" },
	{key:"mdi mdi-human-handsdown",nombre:"mdi mdi-human-handsdown", libreria:"Material Design Iconos" },
	{key:"mdi mdi-human-handsup",nombre:"mdi mdi-human-handsup", libreria:"Material Design Iconos" },
	{key:"mdi mdi-human-male",nombre:"mdi mdi-human-male", libreria:"Material Design Iconos" },
	{key:"mdi mdi-human-male-female",nombre:"mdi mdi-human-male-female", libreria:"Material Design Iconos" },
	{key:"mdi mdi-human-pregnant",nombre:"mdi mdi-human-pregnant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-humble-bundle",nombre:"mdi mdi-humble-bundle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-image",nombre:"mdi mdi-image", libreria:"Material Design Iconos" },
	{key:"mdi mdi-image-album",nombre:"mdi mdi-image-album", libreria:"Material Design Iconos" },
	{key:"mdi mdi-image-area",nombre:"mdi mdi-image-area", libreria:"Material Design Iconos" },
	{key:"mdi mdi-image-area-close",nombre:"mdi mdi-image-area-close", libreria:"Material Design Iconos" },
	{key:"mdi mdi-image-broken",nombre:"mdi mdi-image-broken", libreria:"Material Design Iconos" },
	{key:"mdi mdi-image-broken-variant",nombre:"mdi mdi-image-broken-variant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-image-filter",nombre:"mdi mdi-image-filter", libreria:"Material Design Iconos" },
	{key:"mdi mdi-image-filter-black-white",nombre:"mdi mdi-image-filter-black-white", libreria:"Material Design Iconos" },
	{key:"mdi mdi-image-filter-center-focus",nombre:"mdi mdi-image-filter-center-focus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-image-filter-center-focus-weak",nombre:"mdi mdi-image-filter-center-focus-weak", libreria:"Material Design Iconos" },
	{key:"mdi mdi-image-filter-drama",nombre:"mdi mdi-image-filter-drama", libreria:"Material Design Iconos" },
	{key:"mdi mdi-image-filter-frames",nombre:"mdi mdi-image-filter-frames", libreria:"Material Design Iconos" },
	{key:"mdi mdi-image-filter-hdr",nombre:"mdi mdi-image-filter-hdr", libreria:"Material Design Iconos" },
	{key:"mdi mdi-image-filter-none",nombre:"mdi mdi-image-filter-none", libreria:"Material Design Iconos" },
	{key:"mdi mdi-image-filter-tilt-shift",nombre:"mdi mdi-image-filter-tilt-shift", libreria:"Material Design Iconos" },
	{key:"mdi mdi-image-filter-vintage",nombre:"mdi mdi-image-filter-vintage", libreria:"Material Design Iconos" },
	{key:"mdi mdi-image-multiple",nombre:"mdi mdi-image-multiple", libreria:"Material Design Iconos" },
	{key:"mdi mdi-import",nombre:"mdi mdi-import", libreria:"Material Design Iconos" },
	{key:"mdi mdi-inbox",nombre:"mdi mdi-inbox", libreria:"Material Design Iconos" },
	{key:"mdi mdi-inbox-arrow-down",nombre:"mdi mdi-inbox-arrow-down", libreria:"Material Design Iconos" },
	{key:"mdi mdi-inbox-arrow-up",nombre:"mdi mdi-inbox-arrow-up", libreria:"Material Design Iconos" },
	{key:"mdi mdi-incognito",nombre:"mdi mdi-incognito", libreria:"Material Design Iconos" },
	{key:"mdi mdi-infinity",nombre:"mdi mdi-infinity", libreria:"Material Design Iconos" },
	{key:"mdi mdi-information",nombre:"mdi mdi-information", libreria:"Material Design Iconos" },
	{key:"mdi mdi-information-outline",nombre:"mdi mdi-information-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-information-variant",nombre:"mdi mdi-information-variant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-instagram",nombre:"mdi mdi-instagram", libreria:"Material Design Iconos" },
	{key:"mdi mdi-instapaper",nombre:"mdi mdi-instapaper", libreria:"Material Design Iconos" },
	{key:"mdi mdi-internet-explorer",nombre:"mdi mdi-internet-explorer", libreria:"Material Design Iconos" },
	{key:"mdi mdi-invert-colors",nombre:"mdi mdi-invert-colors", libreria:"Material Design Iconos" },
	{key:"mdi mdi-itunes",nombre:"mdi mdi-itunes", libreria:"Material Design Iconos" },
	{key:"mdi mdi-jeepney",nombre:"mdi mdi-jeepney", libreria:"Material Design Iconos" },
	{key:"mdi mdi-jira",nombre:"mdi mdi-jira", libreria:"Material Design Iconos" },
	{key:"mdi mdi-jsfiddle",nombre:"mdi mdi-jsfiddle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-json",nombre:"mdi mdi-json", libreria:"Material Design Iconos" },
	{key:"mdi mdi-keg",nombre:"mdi mdi-keg", libreria:"Material Design Iconos" },
	{key:"mdi mdi-kettle",nombre:"mdi mdi-kettle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-key",nombre:"mdi mdi-key", libreria:"Material Design Iconos" },
	{key:"mdi mdi-key-change",nombre:"mdi mdi-key-change", libreria:"Material Design Iconos" },
	{key:"mdi mdi-key-minus",nombre:"mdi mdi-key-minus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-key-plus",nombre:"mdi mdi-key-plus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-key-remove",nombre:"mdi mdi-key-remove", libreria:"Material Design Iconos" },
	{key:"mdi mdi-key-variant",nombre:"mdi mdi-key-variant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-keyboard",nombre:"mdi mdi-keyboard", libreria:"Material Design Iconos" },
	{key:"mdi mdi-keyboard-backspace",nombre:"mdi mdi-keyboard-backspace", libreria:"Material Design Iconos" },
	{key:"mdi mdi-keyboard-caps",nombre:"mdi mdi-keyboard-caps", libreria:"Material Design Iconos" },
	{key:"mdi mdi-keyboard-close",nombre:"mdi mdi-keyboard-close", libreria:"Material Design Iconos" },
	{key:"mdi mdi-keyboard-off",nombre:"mdi mdi-keyboard-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-keyboard-return",nombre:"mdi mdi-keyboard-return", libreria:"Material Design Iconos" },
	{key:"mdi mdi-keyboard-tab",nombre:"mdi mdi-keyboard-tab", libreria:"Material Design Iconos" },
	{key:"mdi mdi-keyboard-variant",nombre:"mdi mdi-keyboard-variant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-kickstarter",nombre:"mdi mdi-kickstarter", libreria:"Material Design Iconos" },
	{key:"mdi mdi-kodi",nombre:"mdi mdi-kodi", libreria:"Material Design Iconos" },
	{key:"mdi mdi-label",nombre:"mdi mdi-label", libreria:"Material Design Iconos" },
	{key:"mdi mdi-label-outline",nombre:"mdi mdi-label-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-lambda",nombre:"mdi mdi-lambda", libreria:"Material Design Iconos" },
	{key:"mdi mdi-lamp",nombre:"mdi mdi-lamp", libreria:"Material Design Iconos" },
	{key:"mdi mdi-lan",nombre:"mdi mdi-lan", libreria:"Material Design Iconos" },
	{key:"mdi mdi-lan-connect",nombre:"mdi mdi-lan-connect", libreria:"Material Design Iconos" },
	{key:"mdi mdi-lan-disconnect",nombre:"mdi mdi-lan-disconnect", libreria:"Material Design Iconos" },
	{key:"mdi mdi-lan-pending",nombre:"mdi mdi-lan-pending", libreria:"Material Design Iconos" },
	{key:"mdi mdi-language-c",nombre:"mdi mdi-language-c", libreria:"Material Design Iconos" },
	{key:"mdi mdi-language-cpp",nombre:"mdi mdi-language-cpp", libreria:"Material Design Iconos" },
	{key:"mdi mdi-language-csharp",nombre:"mdi mdi-language-csharp", libreria:"Material Design Iconos" },
	{key:"mdi mdi-language-css3",nombre:"mdi mdi-language-css3", libreria:"Material Design Iconos" },
	{key:"mdi mdi-language-go",nombre:"mdi mdi-language-go", libreria:"Material Design Iconos" },
	{key:"mdi mdi-language-html5",nombre:"mdi mdi-language-html5", libreria:"Material Design Iconos" },
	{key:"mdi mdi-language-javascript",nombre:"mdi mdi-language-javascript", libreria:"Material Design Iconos" },
	{key:"mdi mdi-language-php",nombre:"mdi mdi-language-php", libreria:"Material Design Iconos" },
	{key:"mdi mdi-language-python",nombre:"mdi mdi-language-python", libreria:"Material Design Iconos" },
	{key:"mdi mdi-language-python-text",nombre:"mdi mdi-language-python-text", libreria:"Material Design Iconos" },
	{key:"mdi mdi-language-r",nombre:"mdi mdi-language-r", libreria:"Material Design Iconos" },
	{key:"mdi mdi-language-swift",nombre:"mdi mdi-language-swift", libreria:"Material Design Iconos" },
	{key:"mdi mdi-language-typescript",nombre:"mdi mdi-language-typescript", libreria:"Material Design Iconos" },
	{key:"mdi mdi-laptop",nombre:"mdi mdi-laptop", libreria:"Material Design Iconos" },
	{key:"mdi mdi-laptop-chromebook",nombre:"mdi mdi-laptop-chromebook", libreria:"Material Design Iconos" },
	{key:"mdi mdi-laptop-mac",nombre:"mdi mdi-laptop-mac", libreria:"Material Design Iconos" },
	{key:"mdi mdi-laptop-off",nombre:"mdi mdi-laptop-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-laptop-windows",nombre:"mdi mdi-laptop-windows", libreria:"Material Design Iconos" },
	{key:"mdi mdi-lastfm",nombre:"mdi mdi-lastfm", libreria:"Material Design Iconos" },
	{key:"mdi mdi-launch",nombre:"mdi mdi-launch", libreria:"Material Design Iconos" },
	{key:"mdi mdi-lava-lamp",nombre:"mdi mdi-lava-lamp", libreria:"Material Design Iconos" },
	{key:"mdi mdi-layers",nombre:"mdi mdi-layers", libreria:"Material Design Iconos" },
	{key:"mdi mdi-layers-off",nombre:"mdi mdi-layers-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-lead-pencil",nombre:"mdi mdi-lead-pencil", libreria:"Material Design Iconos" },
	{key:"mdi mdi-leaf",nombre:"mdi mdi-leaf", libreria:"Material Design Iconos" },
	{key:"mdi mdi-led-off",nombre:"mdi mdi-led-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-led-on",nombre:"mdi mdi-led-on", libreria:"Material Design Iconos" },
	{key:"mdi mdi-led-outline",nombre:"mdi mdi-led-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-led-strip",nombre:"mdi mdi-led-strip", libreria:"Material Design Iconos" },
	{key:"mdi mdi-led-variant-off",nombre:"mdi mdi-led-variant-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-led-variant-on",nombre:"mdi mdi-led-variant-on", libreria:"Material Design Iconos" },
	{key:"mdi mdi-led-variant-outline",nombre:"mdi mdi-led-variant-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-library",nombre:"mdi mdi-library", libreria:"Material Design Iconos" },
	{key:"mdi mdi-library-books",nombre:"mdi mdi-library-books", libreria:"Material Design Iconos" },
	{key:"mdi mdi-library-music",nombre:"mdi mdi-library-music", libreria:"Material Design Iconos" },
	{key:"mdi mdi-library-plus",nombre:"mdi mdi-library-plus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-lightbulb",nombre:"mdi mdi-lightbulb", libreria:"Material Design Iconos" },
	{key:"mdi mdi-lightbulb-on",nombre:"mdi mdi-lightbulb-on", libreria:"Material Design Iconos" },
	{key:"mdi mdi-lightbulb-on-outline",nombre:"mdi mdi-lightbulb-on-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-lightbulb-outline",nombre:"mdi mdi-lightbulb-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-link",nombre:"mdi mdi-link", libreria:"Material Design Iconos" },
	{key:"mdi mdi-link-off",nombre:"mdi mdi-link-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-link-variant",nombre:"mdi mdi-link-variant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-link-variant-off",nombre:"mdi mdi-link-variant-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-linkedin",nombre:"mdi mdi-linkedin", libreria:"Material Design Iconos" },
	{key:"mdi mdi-linkedin-box",nombre:"mdi mdi-linkedin-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-linux",nombre:"mdi mdi-linux", libreria:"Material Design Iconos" },
	{key:"mdi mdi-loading",nombre:"mdi mdi-loading", libreria:"Material Design Iconos" },
	{key:"mdi mdi-lock",nombre:"mdi mdi-lock", libreria:"Material Design Iconos" },
	{key:"mdi mdi-lock-open",nombre:"mdi mdi-lock-open", libreria:"Material Design Iconos" },
	{key:"mdi mdi-lock-open-outline",nombre:"mdi mdi-lock-open-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-lock-outline",nombre:"mdi mdi-lock-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-lock-pattern",nombre:"mdi mdi-lock-pattern", libreria:"Material Design Iconos" },
	{key:"mdi mdi-lock-plus",nombre:"mdi mdi-lock-plus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-lock-reset",nombre:"mdi mdi-lock-reset", libreria:"Material Design Iconos" },
	{key:"mdi mdi-locker",nombre:"mdi mdi-locker", libreria:"Material Design Iconos" },
	{key:"mdi mdi-locker-multiple",nombre:"mdi mdi-locker-multiple", libreria:"Material Design Iconos" },
	{key:"mdi mdi-login",nombre:"mdi mdi-login", libreria:"Material Design Iconos" },
	{key:"mdi mdi-login-variant",nombre:"mdi mdi-login-variant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-logout",nombre:"mdi mdi-logout", libreria:"Material Design Iconos" },
	{key:"mdi mdi-logout-variant",nombre:"mdi mdi-logout-variant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-looks",nombre:"mdi mdi-looks", libreria:"Material Design Iconos" },
	{key:"mdi mdi-loop",nombre:"mdi mdi-loop", libreria:"Material Design Iconos" },
	{key:"mdi mdi-loupe",nombre:"mdi mdi-loupe", libreria:"Material Design Iconos" },
	{key:"mdi mdi-lumx",nombre:"mdi mdi-lumx", libreria:"Material Design Iconos" },
	{key:"mdi mdi-magnet",nombre:"mdi mdi-magnet", libreria:"Material Design Iconos" },
	{key:"mdi mdi-magnet-on",nombre:"mdi mdi-magnet-on", libreria:"Material Design Iconos" },
	{key:"mdi mdi-magnify",nombre:"mdi mdi-magnify", libreria:"Material Design Iconos" },
	{key:"mdi mdi-magnify-minus",nombre:"mdi mdi-magnify-minus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-magnify-minus-outline",nombre:"mdi mdi-magnify-minus-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-magnify-plus",nombre:"mdi mdi-magnify-plus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-magnify-plus-outline",nombre:"mdi mdi-magnify-plus-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-mail-ru",nombre:"mdi mdi-mail-ru", libreria:"Material Design Iconos" },
	{key:"mdi mdi-mailbox",nombre:"mdi mdi-mailbox", libreria:"Material Design Iconos" },
	{key:"mdi mdi-map",nombre:"mdi mdi-map", libreria:"Material Design Iconos" },
	{key:"mdi mdi-map-marker",nombre:"mdi mdi-map-marker", libreria:"Material Design Iconos" },
	{key:"mdi mdi-map-marker-circle",nombre:"mdi mdi-map-marker-circle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-map-marker-minus",nombre:"mdi mdi-map-marker-minus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-map-marker-multiple",nombre:"mdi mdi-map-marker-multiple", libreria:"Material Design Iconos" },
	{key:"mdi mdi-map-marker-off",nombre:"mdi mdi-map-marker-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-map-marker-outline",nombre:"mdi mdi-map-marker-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-map-marker-plus",nombre:"mdi mdi-map-marker-plus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-map-marker-radius",nombre:"mdi mdi-map-marker-radius", libreria:"Material Design Iconos" },
	{key:"mdi mdi-margin",nombre:"mdi mdi-margin", libreria:"Material Design Iconos" },
	{key:"mdi mdi-markdown",nombre:"mdi mdi-markdown", libreria:"Material Design Iconos" },
	{key:"mdi mdi-marker",nombre:"mdi mdi-marker", libreria:"Material Design Iconos" },
	{key:"mdi mdi-marker-check",nombre:"mdi mdi-marker-check", libreria:"Material Design Iconos" },
	{key:"mdi mdi-martini",nombre:"mdi mdi-martini", libreria:"Material Design Iconos" },
	{key:"mdi mdi-material-ui",nombre:"mdi mdi-material-ui", libreria:"Material Design Iconos" },
	{key:"mdi mdi-math-compass",nombre:"mdi mdi-math-compass", libreria:"Material Design Iconos" },
	{key:"mdi mdi-matrix",nombre:"mdi mdi-matrix", libreria:"Material Design Iconos" },
	{key:"mdi mdi-maxcdn",nombre:"mdi mdi-maxcdn", libreria:"Material Design Iconos" },
	{key:"mdi mdi-medical-bag",nombre:"mdi mdi-medical-bag", libreria:"Material Design Iconos" },
	{key:"mdi mdi-medium",nombre:"mdi mdi-medium", libreria:"Material Design Iconos" },
	{key:"mdi mdi-memory",nombre:"mdi mdi-memory", libreria:"Material Design Iconos" },
	{key:"mdi mdi-menu",nombre:"mdi mdi-menu", libreria:"Material Design Iconos" },
	{key:"mdi mdi-menu-down",nombre:"mdi mdi-menu-down", libreria:"Material Design Iconos" },
	{key:"mdi mdi-menu-down-outline",nombre:"mdi mdi-menu-down-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-menu-left",nombre:"mdi mdi-menu-left", libreria:"Material Design Iconos" },
	{key:"mdi mdi-menu-right",nombre:"mdi mdi-menu-right", libreria:"Material Design Iconos" },
	{key:"mdi mdi-menu-up",nombre:"mdi mdi-menu-up", libreria:"Material Design Iconos" },
	{key:"mdi mdi-menu-up-outline",nombre:"mdi mdi-menu-up-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-message",nombre:"mdi mdi-message", libreria:"Material Design Iconos" },
	{key:"mdi mdi-message-alert",nombre:"mdi mdi-message-alert", libreria:"Material Design Iconos" },
	{key:"mdi mdi-message-bulleted",nombre:"mdi mdi-message-bulleted", libreria:"Material Design Iconos" },
	{key:"mdi mdi-message-bulleted-off",nombre:"mdi mdi-message-bulleted-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-message-draw",nombre:"mdi mdi-message-draw", libreria:"Material Design Iconos" },
	{key:"mdi mdi-message-image",nombre:"mdi mdi-message-image", libreria:"Material Design Iconos" },
	{key:"mdi mdi-message-outline",nombre:"mdi mdi-message-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-message-plus",nombre:"mdi mdi-message-plus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-message-processing",nombre:"mdi mdi-message-processing", libreria:"Material Design Iconos" },
	{key:"mdi mdi-message-reply",nombre:"mdi mdi-message-reply", libreria:"Material Design Iconos" },
	{key:"mdi mdi-message-reply-text",nombre:"mdi mdi-message-reply-text", libreria:"Material Design Iconos" },
	{key:"mdi mdi-message-settings",nombre:"mdi mdi-message-settings", libreria:"Material Design Iconos" },
	{key:"mdi mdi-message-settings-variant",nombre:"mdi mdi-message-settings-variant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-message-text",nombre:"mdi mdi-message-text", libreria:"Material Design Iconos" },
	{key:"mdi mdi-message-text-outline",nombre:"mdi mdi-message-text-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-message-video",nombre:"mdi mdi-message-video", libreria:"Material Design Iconos" },
	{key:"mdi mdi-meteor",nombre:"mdi mdi-meteor", libreria:"Material Design Iconos" },
	{key:"mdi mdi-metronome",nombre:"mdi mdi-metronome", libreria:"Material Design Iconos" },
	{key:"mdi mdi-metronome-tick",nombre:"mdi mdi-metronome-tick", libreria:"Material Design Iconos" },
	{key:"mdi mdi-micro-sd",nombre:"mdi mdi-micro-sd", libreria:"Material Design Iconos" },
	{key:"mdi mdi-microphone",nombre:"mdi mdi-microphone", libreria:"Material Design Iconos" },
	{key:"mdi mdi-microphone-off",nombre:"mdi mdi-microphone-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-microphone-outline",nombre:"mdi mdi-microphone-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-microphone-settings",nombre:"mdi mdi-microphone-settings", libreria:"Material Design Iconos" },
	{key:"mdi mdi-microphone-variant",nombre:"mdi mdi-microphone-variant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-microphone-variant-off",nombre:"mdi mdi-microphone-variant-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-microscope",nombre:"mdi mdi-microscope", libreria:"Material Design Iconos" },
	{key:"mdi mdi-microsoft",nombre:"mdi mdi-microsoft", libreria:"Material Design Iconos" },
	{key:"mdi mdi-minecraft",nombre:"mdi mdi-minecraft", libreria:"Material Design Iconos" },
	{key:"mdi mdi-minus",nombre:"mdi mdi-minus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-minus-box",nombre:"mdi mdi-minus-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-minus-box-outline",nombre:"mdi mdi-minus-box-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-minus-circle",nombre:"mdi mdi-minus-circle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-minus-circle-outline",nombre:"mdi mdi-minus-circle-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-minus-network",nombre:"mdi mdi-minus-network", libreria:"Material Design Iconos" },
	{key:"mdi mdi-mixcloud",nombre:"mdi mdi-mixcloud", libreria:"Material Design Iconos" },
	{key:"mdi mdi-mixer",nombre:"mdi mdi-mixer", libreria:"Material Design Iconos" },
	{key:"mdi mdi-monitor",nombre:"mdi mdi-monitor", libreria:"Material Design Iconos" },
	{key:"mdi mdi-monitor-multiple",nombre:"mdi mdi-monitor-multiple", libreria:"Material Design Iconos" },
	{key:"mdi mdi-more",nombre:"mdi mdi-more", libreria:"Material Design Iconos" },
	{key:"mdi mdi-motorbike",nombre:"mdi mdi-motorbike", libreria:"Material Design Iconos" },
	{key:"mdi mdi-mouse",nombre:"mdi mdi-mouse", libreria:"Material Design Iconos" },
	{key:"mdi mdi-mouse-off",nombre:"mdi mdi-mouse-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-mouse-variant",nombre:"mdi mdi-mouse-variant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-mouse-variant-off",nombre:"mdi mdi-mouse-variant-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-move-resize",nombre:"mdi mdi-move-resize", libreria:"Material Design Iconos" },
	{key:"mdi mdi-move-resize-variant",nombre:"mdi mdi-move-resize-variant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-movie",nombre:"mdi mdi-movie", libreria:"Material Design Iconos" },
	{key:"mdi mdi-movie-roll",nombre:"mdi mdi-movie-roll", libreria:"Material Design Iconos" },
	{key:"mdi mdi-multiplication",nombre:"mdi mdi-multiplication", libreria:"Material Design Iconos" },
	{key:"mdi mdi-multiplication-box",nombre:"mdi mdi-multiplication-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-mushroom",nombre:"mdi mdi-mushroom", libreria:"Material Design Iconos" },
	{key:"mdi mdi-mushroom-outline",nombre:"mdi mdi-mushroom-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-music",nombre:"mdi mdi-music", libreria:"Material Design Iconos" },
	{key:"mdi mdi-music-box",nombre:"mdi mdi-music-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-music-box-outline",nombre:"mdi mdi-music-box-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-music-circle",nombre:"mdi mdi-music-circle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-music-note",nombre:"mdi mdi-music-note", libreria:"Material Design Iconos" },
	{key:"mdi mdi-music-note-bluetooth",nombre:"mdi mdi-music-note-bluetooth", libreria:"Material Design Iconos" },
	{key:"mdi mdi-music-note-bluetooth-off",nombre:"mdi mdi-music-note-bluetooth-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-music-note-eighth",nombre:"mdi mdi-music-note-eighth", libreria:"Material Design Iconos" },
	{key:"mdi mdi-music-note-half",nombre:"mdi mdi-music-note-half", libreria:"Material Design Iconos" },
	{key:"mdi mdi-music-note-off",nombre:"mdi mdi-music-note-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-music-note-quarter",nombre:"mdi mdi-music-note-quarter", libreria:"Material Design Iconos" },
	{key:"mdi mdi-music-note-sixteenth",nombre:"mdi mdi-music-note-sixteenth", libreria:"Material Design Iconos" },
	{key:"mdi mdi-music-note-whole",nombre:"mdi mdi-music-note-whole", libreria:"Material Design Iconos" },
	{key:"mdi mdi-music-off",nombre:"mdi mdi-music-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-nature",nombre:"mdi mdi-nature", libreria:"Material Design Iconos" },
	{key:"mdi mdi-nature-people",nombre:"mdi mdi-nature-people", libreria:"Material Design Iconos" },
	{key:"mdi mdi-navigation",nombre:"mdi mdi-navigation", libreria:"Material Design Iconos" },
	{key:"mdi mdi-near-me",nombre:"mdi mdi-near-me", libreria:"Material Design Iconos" },
	{key:"mdi mdi-needle",nombre:"mdi mdi-needle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-nest-protect",nombre:"mdi mdi-nest-protect", libreria:"Material Design Iconos" },
	{key:"mdi mdi-nest-thermostat",nombre:"mdi mdi-nest-thermostat", libreria:"Material Design Iconos" },
	{key:"mdi mdi-netflix",nombre:"mdi mdi-netflix", libreria:"Material Design Iconos" },
	{key:"mdi mdi-network",nombre:"mdi mdi-network", libreria:"Material Design Iconos" },
	{key:"mdi mdi-new-box",nombre:"mdi mdi-new-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-newspaper",nombre:"mdi mdi-newspaper", libreria:"Material Design Iconos" },
	{key:"mdi mdi-nfc",nombre:"mdi mdi-nfc", libreria:"Material Design Iconos" },
	{key:"mdi mdi-nfc-tap",nombre:"mdi mdi-nfc-tap", libreria:"Material Design Iconos" },
	{key:"mdi mdi-nfc-variant",nombre:"mdi mdi-nfc-variant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-ninja",nombre:"mdi mdi-ninja", libreria:"Material Design Iconos" },
	{key:"mdi mdi-nintendo-switch",nombre:"mdi mdi-nintendo-switch", libreria:"Material Design Iconos" },
	{key:"mdi mdi-nodejs",nombre:"mdi mdi-nodejs", libreria:"Material Design Iconos" },
	{key:"mdi mdi-note",nombre:"mdi mdi-note", libreria:"Material Design Iconos" },
	{key:"mdi mdi-note-multiple",nombre:"mdi mdi-note-multiple", libreria:"Material Design Iconos" },
	{key:"mdi mdi-note-multiple-outline",nombre:"mdi mdi-note-multiple-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-note-outline",nombre:"mdi mdi-note-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-note-plus",nombre:"mdi mdi-note-plus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-note-plus-outline",nombre:"mdi mdi-note-plus-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-note-text",nombre:"mdi mdi-note-text", libreria:"Material Design Iconos" },
	{key:"mdi mdi-notification-clear-all",nombre:"mdi mdi-notification-clear-all", libreria:"Material Design Iconos" },
	{key:"mdi mdi-npm",nombre:"mdi mdi-npm", libreria:"Material Design Iconos" },
	{key:"mdi mdi-nuke",nombre:"mdi mdi-nuke", libreria:"Material Design Iconos" },
	{key:"mdi mdi-null",nombre:"mdi mdi-null", libreria:"Material Design Iconos" },
	{key:"mdi mdi-numeric",nombre:"mdi mdi-numeric", libreria:"Material Design Iconos" },
	{key:"mdi mdi-numeric-0-box",nombre:"mdi mdi-numeric-0-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-numeric-0-box-multiple-outline",nombre:"mdi mdi-numeric-0-box-multiple-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-numeric-0-box-outline",nombre:"mdi mdi-numeric-0-box-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-numeric-1-box",nombre:"mdi mdi-numeric-1-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-numeric-1-box-multiple-outline",nombre:"mdi mdi-numeric-1-box-multiple-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-numeric-1-box-outline",nombre:"mdi mdi-numeric-1-box-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-numeric-2-box",nombre:"mdi mdi-numeric-2-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-numeric-2-box-multiple-outline",nombre:"mdi mdi-numeric-2-box-multiple-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-numeric-2-box-outline",nombre:"mdi mdi-numeric-2-box-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-numeric-3-box",nombre:"mdi mdi-numeric-3-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-numeric-3-box-multiple-outline",nombre:"mdi mdi-numeric-3-box-multiple-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-numeric-3-box-outline",nombre:"mdi mdi-numeric-3-box-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-numeric-4-box",nombre:"mdi mdi-numeric-4-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-numeric-4-box-multiple-outline",nombre:"mdi mdi-numeric-4-box-multiple-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-numeric-4-box-outline",nombre:"mdi mdi-numeric-4-box-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-numeric-5-box",nombre:"mdi mdi-numeric-5-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-numeric-5-box-multiple-outline",nombre:"mdi mdi-numeric-5-box-multiple-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-numeric-5-box-outline",nombre:"mdi mdi-numeric-5-box-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-numeric-6-box",nombre:"mdi mdi-numeric-6-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-numeric-6-box-multiple-outline",nombre:"mdi mdi-numeric-6-box-multiple-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-numeric-6-box-outline",nombre:"mdi mdi-numeric-6-box-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-numeric-7-box",nombre:"mdi mdi-numeric-7-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-numeric-7-box-multiple-outline",nombre:"mdi mdi-numeric-7-box-multiple-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-numeric-7-box-outline",nombre:"mdi mdi-numeric-7-box-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-numeric-8-box",nombre:"mdi mdi-numeric-8-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-numeric-8-box-multiple-outline",nombre:"mdi mdi-numeric-8-box-multiple-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-numeric-8-box-outline",nombre:"mdi mdi-numeric-8-box-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-numeric-9-box",nombre:"mdi mdi-numeric-9-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-numeric-9-box-multiple-outline",nombre:"mdi mdi-numeric-9-box-multiple-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-numeric-9-box-outline",nombre:"mdi mdi-numeric-9-box-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-numeric-9-plus-box",nombre:"mdi mdi-numeric-9-plus-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-numeric-9-plus-box-multiple-outline",nombre:"mdi mdi-numeric-9-plus-box-multiple-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-numeric-9-plus-box-outline",nombre:"mdi mdi-numeric-9-plus-box-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-nut",nombre:"mdi mdi-nut", libreria:"Material Design Iconos" },
	{key:"mdi mdi-nutrition",nombre:"mdi mdi-nutrition", libreria:"Material Design Iconos" },
	{key:"mdi mdi-oar",nombre:"mdi mdi-oar", libreria:"Material Design Iconos" },
	{key:"mdi mdi-octagon",nombre:"mdi mdi-octagon", libreria:"Material Design Iconos" },
	{key:"mdi mdi-octagon-outline",nombre:"mdi mdi-octagon-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-octagram",nombre:"mdi mdi-octagram", libreria:"Material Design Iconos" },
	{key:"mdi mdi-octagram-outline",nombre:"mdi mdi-octagram-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-odnoklassniki",nombre:"mdi mdi-odnoklassniki", libreria:"Material Design Iconos" },
	{key:"mdi mdi-office",nombre:"mdi mdi-office", libreria:"Material Design Iconos" },
	{key:"mdi mdi-oil",nombre:"mdi mdi-oil", libreria:"Material Design Iconos" },
	{key:"mdi mdi-oil-temperature",nombre:"mdi mdi-oil-temperature", libreria:"Material Design Iconos" },
	{key:"mdi mdi-omega",nombre:"mdi mdi-omega", libreria:"Material Design Iconos" },
	{key:"mdi mdi-onedrive",nombre:"mdi mdi-onedrive", libreria:"Material Design Iconos" },
	{key:"mdi mdi-onenote",nombre:"mdi mdi-onenote", libreria:"Material Design Iconos" },
	{key:"mdi mdi-opacity",nombre:"mdi mdi-opacity", libreria:"Material Design Iconos" },
	{key:"mdi mdi-open-in-app",nombre:"mdi mdi-open-in-app", libreria:"Material Design Iconos" },
	{key:"mdi mdi-open-in-new",nombre:"mdi mdi-open-in-new", libreria:"Material Design Iconos" },
	{key:"mdi mdi-openid",nombre:"mdi mdi-openid", libreria:"Material Design Iconos" },
	{key:"mdi mdi-opera",nombre:"mdi mdi-opera", libreria:"Material Design Iconos" },
	{key:"mdi mdi-orbit",nombre:"mdi mdi-orbit", libreria:"Material Design Iconos" },
	{key:"mdi mdi-ornament",nombre:"mdi mdi-ornament", libreria:"Material Design Iconos" },
	{key:"mdi mdi-ornament-variant",nombre:"mdi mdi-ornament-variant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-owl",nombre:"mdi mdi-owl", libreria:"Material Design Iconos" },
	{key:"mdi mdi-package",nombre:"mdi mdi-package", libreria:"Material Design Iconos" },
	{key:"mdi mdi-package-down",nombre:"mdi mdi-package-down", libreria:"Material Design Iconos" },
	{key:"mdi mdi-package-up",nombre:"mdi mdi-package-up", libreria:"Material Design Iconos" },
	{key:"mdi mdi-package-variant",nombre:"mdi mdi-package-variant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-package-variant-closed",nombre:"mdi mdi-package-variant-closed", libreria:"Material Design Iconos" },
	{key:"mdi mdi-page-first",nombre:"mdi mdi-page-first", libreria:"Material Design Iconos" },
	{key:"mdi mdi-page-last",nombre:"mdi mdi-page-last", libreria:"Material Design Iconos" },
	{key:"mdi mdi-page-layout-body",nombre:"mdi mdi-page-layout-body", libreria:"Material Design Iconos" },
	{key:"mdi mdi-page-layout-footer",nombre:"mdi mdi-page-layout-footer", libreria:"Material Design Iconos" },
	{key:"mdi mdi-page-layout-header",nombre:"mdi mdi-page-layout-header", libreria:"Material Design Iconos" },
	{key:"mdi mdi-page-layout-sidebar-left",nombre:"mdi mdi-page-layout-sidebar-left", libreria:"Material Design Iconos" },
	{key:"mdi mdi-page-layout-sidebar-right",nombre:"mdi mdi-page-layout-sidebar-right", libreria:"Material Design Iconos" },
	{key:"mdi mdi-palette",nombre:"mdi mdi-palette", libreria:"Material Design Iconos" },
	{key:"mdi mdi-palette-advanced",nombre:"mdi mdi-palette-advanced", libreria:"Material Design Iconos" },
	{key:"mdi mdi-panda",nombre:"mdi mdi-panda", libreria:"Material Design Iconos" },
	{key:"mdi mdi-pandora",nombre:"mdi mdi-pandora", libreria:"Material Design Iconos" },
	{key:"mdi mdi-panorama",nombre:"mdi mdi-panorama", libreria:"Material Design Iconos" },
	{key:"mdi mdi-panorama-fisheye",nombre:"mdi mdi-panorama-fisheye", libreria:"Material Design Iconos" },
	{key:"mdi mdi-panorama-horizontal",nombre:"mdi mdi-panorama-horizontal", libreria:"Material Design Iconos" },
	{key:"mdi mdi-panorama-vertical",nombre:"mdi mdi-panorama-vertical", libreria:"Material Design Iconos" },
	{key:"mdi mdi-panorama-wide-angle",nombre:"mdi mdi-panorama-wide-angle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-paper-cut-vertical",nombre:"mdi mdi-paper-cut-vertical", libreria:"Material Design Iconos" },
	{key:"mdi mdi-paperclip",nombre:"mdi mdi-paperclip", libreria:"Material Design Iconos" },
	{key:"mdi mdi-parking",nombre:"mdi mdi-parking", libreria:"Material Design Iconos" },
	{key:"mdi mdi-passport",nombre:"mdi mdi-passport", libreria:"Material Design Iconos" },
	{key:"mdi mdi-pause",nombre:"mdi mdi-pause", libreria:"Material Design Iconos" },
	{key:"mdi mdi-pause-circle",nombre:"mdi mdi-pause-circle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-pause-circle-outline",nombre:"mdi mdi-pause-circle-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-pause-octagon",nombre:"mdi mdi-pause-octagon", libreria:"Material Design Iconos" },
	{key:"mdi mdi-pause-octagon-outline",nombre:"mdi mdi-pause-octagon-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-paw",nombre:"mdi mdi-paw", libreria:"Material Design Iconos" },
	{key:"mdi mdi-paw-off",nombre:"mdi mdi-paw-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-pen",nombre:"mdi mdi-pen", libreria:"Material Design Iconos" },
	{key:"mdi mdi-pencil",nombre:"mdi mdi-pencil", libreria:"Material Design Iconos" },
	{key:"mdi mdi-pencil-box",nombre:"mdi mdi-pencil-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-pencil-box-outline",nombre:"mdi mdi-pencil-box-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-pencil-circle",nombre:"mdi mdi-pencil-circle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-pencil-circle-outline",nombre:"mdi mdi-pencil-circle-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-pencil-lock",nombre:"mdi mdi-pencil-lock", libreria:"Material Design Iconos" },
	{key:"mdi mdi-pencil-off",nombre:"mdi mdi-pencil-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-pentagon",nombre:"mdi mdi-pentagon", libreria:"Material Design Iconos" },
	{key:"mdi mdi-pentagon-outline",nombre:"mdi mdi-pentagon-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-percent",nombre:"mdi mdi-percent", libreria:"Material Design Iconos" },
	{key:"mdi mdi-periodic-table-co2",nombre:"mdi mdi-periodic-table-co2", libreria:"Material Design Iconos" },
	{key:"mdi mdi-periscope",nombre:"mdi mdi-periscope", libreria:"Material Design Iconos" },
	{key:"mdi mdi-pharmacy",nombre:"mdi mdi-pharmacy", libreria:"Material Design Iconos" },
	{key:"mdi mdi-phone",nombre:"mdi mdi-phone", libreria:"Material Design Iconos" },
	{key:"mdi mdi-phone-bluetooth",nombre:"mdi mdi-phone-bluetooth", libreria:"Material Design Iconos" },
	{key:"mdi mdi-phone-classic",nombre:"mdi mdi-phone-classic", libreria:"Material Design Iconos" },
	{key:"mdi mdi-phone-forward",nombre:"mdi mdi-phone-forward", libreria:"Material Design Iconos" },
	{key:"mdi mdi-phone-hangup",nombre:"mdi mdi-phone-hangup", libreria:"Material Design Iconos" },
	{key:"mdi mdi-phone-in-talk",nombre:"mdi mdi-phone-in-talk", libreria:"Material Design Iconos" },
	{key:"mdi mdi-phone-incoming",nombre:"mdi mdi-phone-incoming", libreria:"Material Design Iconos" },
	{key:"mdi mdi-phone-locked",nombre:"mdi mdi-phone-locked", libreria:"Material Design Iconos" },
	{key:"mdi mdi-phone-log",nombre:"mdi mdi-phone-log", libreria:"Material Design Iconos" },
	{key:"mdi mdi-phone-minus",nombre:"mdi mdi-phone-minus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-phone-missed",nombre:"mdi mdi-phone-missed", libreria:"Material Design Iconos" },
	{key:"mdi mdi-phone-outgoing",nombre:"mdi mdi-phone-outgoing", libreria:"Material Design Iconos" },
	{key:"mdi mdi-phone-paused",nombre:"mdi mdi-phone-paused", libreria:"Material Design Iconos" },
	{key:"mdi mdi-phone-plus",nombre:"mdi mdi-phone-plus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-phone-settings",nombre:"mdi mdi-phone-settings", libreria:"Material Design Iconos" },
	{key:"mdi mdi-phone-voip",nombre:"mdi mdi-phone-voip", libreria:"Material Design Iconos" },
	{key:"mdi mdi-pi",nombre:"mdi mdi-pi", libreria:"Material Design Iconos" },
	{key:"mdi mdi-pi-box",nombre:"mdi mdi-pi-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-piano",nombre:"mdi mdi-piano", libreria:"Material Design Iconos" },
	{key:"mdi mdi-pig",nombre:"mdi mdi-pig", libreria:"Material Design Iconos" },
	{key:"mdi mdi-pill",nombre:"mdi mdi-pill", libreria:"Material Design Iconos" },
	{key:"mdi mdi-pillar",nombre:"mdi mdi-pillar", libreria:"Material Design Iconos" },
	{key:"mdi mdi-pin",nombre:"mdi mdi-pin", libreria:"Material Design Iconos" },
	{key:"mdi mdi-pin-off",nombre:"mdi mdi-pin-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-pine-tree",nombre:"mdi mdi-pine-tree", libreria:"Material Design Iconos" },
	{key:"mdi mdi-pine-tree-box",nombre:"mdi mdi-pine-tree-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-pinterest",nombre:"mdi mdi-pinterest", libreria:"Material Design Iconos" },
	{key:"mdi mdi-pinterest-box",nombre:"mdi mdi-pinterest-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-pipe",nombre:"mdi mdi-pipe", libreria:"Material Design Iconos" },
	{key:"mdi mdi-pipe-disconnected",nombre:"mdi mdi-pipe-disconnected", libreria:"Material Design Iconos" },
	{key:"mdi mdi-pistol",nombre:"mdi mdi-pistol", libreria:"Material Design Iconos" },
	{key:"mdi mdi-pizza",nombre:"mdi mdi-pizza", libreria:"Material Design Iconos" },
	{key:"mdi mdi-plane-shield",nombre:"mdi mdi-plane-shield", libreria:"Material Design Iconos" },
	{key:"mdi mdi-play",nombre:"mdi mdi-play", libreria:"Material Design Iconos" },
	{key:"mdi mdi-play-box-outline",nombre:"mdi mdi-play-box-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-play-circle",nombre:"mdi mdi-play-circle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-play-circle-outline",nombre:"mdi mdi-play-circle-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-play-pause",nombre:"mdi mdi-play-pause", libreria:"Material Design Iconos" },
	{key:"mdi mdi-play-protected-content",nombre:"mdi mdi-play-protected-content", libreria:"Material Design Iconos" },
	{key:"mdi mdi-playlist-check",nombre:"mdi mdi-playlist-check", libreria:"Material Design Iconos" },
	{key:"mdi mdi-playlist-minus",nombre:"mdi mdi-playlist-minus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-playlist-play",nombre:"mdi mdi-playlist-play", libreria:"Material Design Iconos" },
	{key:"mdi mdi-playlist-plus",nombre:"mdi mdi-playlist-plus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-playlist-remove",nombre:"mdi mdi-playlist-remove", libreria:"Material Design Iconos" },
	{key:"mdi mdi-playstation",nombre:"mdi mdi-playstation", libreria:"Material Design Iconos" },
	{key:"mdi mdi-plex",nombre:"mdi mdi-plex", libreria:"Material Design Iconos" },
	{key:"mdi mdi-plus",nombre:"mdi mdi-plus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-plus-box",nombre:"mdi mdi-plus-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-plus-box-outline",nombre:"mdi mdi-plus-box-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-plus-circle",nombre:"mdi mdi-plus-circle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-plus-circle-multiple-outline",nombre:"mdi mdi-plus-circle-multiple-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-plus-circle-outline",nombre:"mdi mdi-plus-circle-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-plus-network",nombre:"mdi mdi-plus-network", libreria:"Material Design Iconos" },
	{key:"mdi mdi-plus-one",nombre:"mdi mdi-plus-one", libreria:"Material Design Iconos" },
	{key:"mdi mdi-plus-outline",nombre:"mdi mdi-plus-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-pocket",nombre:"mdi mdi-pocket", libreria:"Material Design Iconos" },
	{key:"mdi mdi-pokeball",nombre:"mdi mdi-pokeball", libreria:"Material Design Iconos" },
	{key:"mdi mdi-polaroid",nombre:"mdi mdi-polaroid", libreria:"Material Design Iconos" },
	{key:"mdi mdi-poll",nombre:"mdi mdi-poll", libreria:"Material Design Iconos" },
	{key:"mdi mdi-poll-box",nombre:"mdi mdi-poll-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-polymer",nombre:"mdi mdi-polymer", libreria:"Material Design Iconos" },
	{key:"mdi mdi-pool",nombre:"mdi mdi-pool", libreria:"Material Design Iconos" },
	{key:"mdi mdi-popcorn",nombre:"mdi mdi-popcorn", libreria:"Material Design Iconos" },
	{key:"mdi mdi-pot",nombre:"mdi mdi-pot", libreria:"Material Design Iconos" },
	{key:"mdi mdi-pot-mix",nombre:"mdi mdi-pot-mix", libreria:"Material Design Iconos" },
	{key:"mdi mdi-pound",nombre:"mdi mdi-pound", libreria:"Material Design Iconos" },
	{key:"mdi mdi-pound-box",nombre:"mdi mdi-pound-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-power",nombre:"mdi mdi-power", libreria:"Material Design Iconos" },
	{key:"mdi mdi-power-plug",nombre:"mdi mdi-power-plug", libreria:"Material Design Iconos" },
	{key:"mdi mdi-power-plug-off",nombre:"mdi mdi-power-plug-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-power-settings",nombre:"mdi mdi-power-settings", libreria:"Material Design Iconos" },
	{key:"mdi mdi-power-socket",nombre:"mdi mdi-power-socket", libreria:"Material Design Iconos" },
	{key:"mdi mdi-power-socket-eu",nombre:"mdi mdi-power-socket-eu", libreria:"Material Design Iconos" },
	{key:"mdi mdi-power-socket-uk",nombre:"mdi mdi-power-socket-uk", libreria:"Material Design Iconos" },
	{key:"mdi mdi-power-socket-us",nombre:"mdi mdi-power-socket-us", libreria:"Material Design Iconos" },
	{key:"mdi mdi-prescription",nombre:"mdi mdi-prescription", libreria:"Material Design Iconos" },
	{key:"mdi mdi-presentation",nombre:"mdi mdi-presentation", libreria:"Material Design Iconos" },
	{key:"mdi mdi-presentation-play",nombre:"mdi mdi-presentation-play", libreria:"Material Design Iconos" },
	{key:"mdi mdi-printer",nombre:"mdi mdi-printer", libreria:"Material Design Iconos" },
	{key:"mdi mdi-printer-3d",nombre:"mdi mdi-printer-3d", libreria:"Material Design Iconos" },
	{key:"mdi mdi-printer-alert",nombre:"mdi mdi-printer-alert", libreria:"Material Design Iconos" },
	{key:"mdi mdi-printer-settings",nombre:"mdi mdi-printer-settings", libreria:"Material Design Iconos" },
	{key:"mdi mdi-priority-high",nombre:"mdi mdi-priority-high", libreria:"Material Design Iconos" },
	{key:"mdi mdi-priority-low",nombre:"mdi mdi-priority-low", libreria:"Material Design Iconos" },
	{key:"mdi mdi-professional-hexagon",nombre:"mdi mdi-professional-hexagon", libreria:"Material Design Iconos" },
	{key:"mdi mdi-projector",nombre:"mdi mdi-projector", libreria:"Material Design Iconos" },
	{key:"mdi mdi-projector-screen",nombre:"mdi mdi-projector-screen", libreria:"Material Design Iconos" },
	{key:"mdi mdi-publish",nombre:"mdi mdi-publish", libreria:"Material Design Iconos" },
	{key:"mdi mdi-pulse",nombre:"mdi mdi-pulse", libreria:"Material Design Iconos" },
	{key:"mdi mdi-puzzle",nombre:"mdi mdi-puzzle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-qqchat",nombre:"mdi mdi-qqchat", libreria:"Material Design Iconos" },
	{key:"mdi mdi-qrcode",nombre:"mdi mdi-qrcode", libreria:"Material Design Iconos" },
	{key:"mdi mdi-qrcode-scan",nombre:"mdi mdi-qrcode-scan", libreria:"Material Design Iconos" },
	{key:"mdi mdi-quadcopter",nombre:"mdi mdi-quadcopter", libreria:"Material Design Iconos" },
	{key:"mdi mdi-quality-high",nombre:"mdi mdi-quality-high", libreria:"Material Design Iconos" },
	{key:"mdi mdi-quicktime",nombre:"mdi mdi-quicktime", libreria:"Material Design Iconos" },
	{key:"mdi mdi-radar",nombre:"mdi mdi-radar", libreria:"Material Design Iconos" },
	{key:"mdi mdi-radiator",nombre:"mdi mdi-radiator", libreria:"Material Design Iconos" },
	{key:"mdi mdi-radio",nombre:"mdi mdi-radio", libreria:"Material Design Iconos" },
	{key:"mdi mdi-radio-handheld",nombre:"mdi mdi-radio-handheld", libreria:"Material Design Iconos" },
	{key:"mdi mdi-radio-tower",nombre:"mdi mdi-radio-tower", libreria:"Material Design Iconos" },
	{key:"mdi mdi-radioactive",nombre:"mdi mdi-radioactive", libreria:"Material Design Iconos" },
	{key:"mdi mdi-radiobox-blank",nombre:"mdi mdi-radiobox-blank", libreria:"Material Design Iconos" },
	{key:"mdi mdi-radiobox-marked",nombre:"mdi mdi-radiobox-marked", libreria:"Material Design Iconos" },
	{key:"mdi mdi-raspberrypi",nombre:"mdi mdi-raspberrypi", libreria:"Material Design Iconos" },
	{key:"mdi mdi-ray-end",nombre:"mdi mdi-ray-end", libreria:"Material Design Iconos" },
	{key:"mdi mdi-ray-end-arrow",nombre:"mdi mdi-ray-end-arrow", libreria:"Material Design Iconos" },
	{key:"mdi mdi-ray-start",nombre:"mdi mdi-ray-start", libreria:"Material Design Iconos" },
	{key:"mdi mdi-ray-start-arrow",nombre:"mdi mdi-ray-start-arrow", libreria:"Material Design Iconos" },
	{key:"mdi mdi-ray-start-end",nombre:"mdi mdi-ray-start-end", libreria:"Material Design Iconos" },
	{key:"mdi mdi-ray-vertex",nombre:"mdi mdi-ray-vertex", libreria:"Material Design Iconos" },
	{key:"mdi mdi-rdio",nombre:"mdi mdi-rdio", libreria:"Material Design Iconos" },
	{key:"mdi mdi-react",nombre:"mdi mdi-react", libreria:"Material Design Iconos" },
	{key:"mdi mdi-read",nombre:"mdi mdi-read", libreria:"Material Design Iconos" },
	{key:"mdi mdi-readability",nombre:"mdi mdi-readability", libreria:"Material Design Iconos" },
	{key:"mdi mdi-receipt",nombre:"mdi mdi-receipt", libreria:"Material Design Iconos" },
	{key:"mdi mdi-record",nombre:"mdi mdi-record", libreria:"Material Design Iconos" },
	{key:"mdi mdi-record-rec",nombre:"mdi mdi-record-rec", libreria:"Material Design Iconos" },
	{key:"mdi mdi-recycle",nombre:"mdi mdi-recycle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-reddit",nombre:"mdi mdi-reddit", libreria:"Material Design Iconos" },
	{key:"mdi mdi-redo",nombre:"mdi mdi-redo", libreria:"Material Design Iconos" },
	{key:"mdi mdi-redo-variant",nombre:"mdi mdi-redo-variant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-refresh",nombre:"mdi mdi-refresh", libreria:"Material Design Iconos" },
	{key:"mdi mdi-regex",nombre:"mdi mdi-regex", libreria:"Material Design Iconos" },
	{key:"mdi mdi-relative-scale",nombre:"mdi mdi-relative-scale", libreria:"Material Design Iconos" },
	{key:"mdi mdi-reload",nombre:"mdi mdi-reload", libreria:"Material Design Iconos" },
	{key:"mdi mdi-remote",nombre:"mdi mdi-remote", libreria:"Material Design Iconos" },
	{key:"mdi mdi-rename-box",nombre:"mdi mdi-rename-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-reorder-horizontal",nombre:"mdi mdi-reorder-horizontal", libreria:"Material Design Iconos" },
	{key:"mdi mdi-reorder-vertical",nombre:"mdi mdi-reorder-vertical", libreria:"Material Design Iconos" },
	{key:"mdi mdi-repeat",nombre:"mdi mdi-repeat", libreria:"Material Design Iconos" },
	{key:"mdi mdi-repeat-off",nombre:"mdi mdi-repeat-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-repeat-once",nombre:"mdi mdi-repeat-once", libreria:"Material Design Iconos" },
	{key:"mdi mdi-replay",nombre:"mdi mdi-replay", libreria:"Material Design Iconos" },
	{key:"mdi mdi-reply",nombre:"mdi mdi-reply", libreria:"Material Design Iconos" },
	{key:"mdi mdi-reply-all",nombre:"mdi mdi-reply-all", libreria:"Material Design Iconos" },
	{key:"mdi mdi-reproduction",nombre:"mdi mdi-reproduction", libreria:"Material Design Iconos" },
	{key:"mdi mdi-resize-bottom-right",nombre:"mdi mdi-resize-bottom-right", libreria:"Material Design Iconos" },
	{key:"mdi mdi-responsive",nombre:"mdi mdi-responsive", libreria:"Material Design Iconos" },
	{key:"mdi mdi-restart",nombre:"mdi mdi-restart", libreria:"Material Design Iconos" },
	{key:"mdi mdi-restore",nombre:"mdi mdi-restore", libreria:"Material Design Iconos" },
	{key:"mdi mdi-rewind",nombre:"mdi mdi-rewind", libreria:"Material Design Iconos" },
	{key:"mdi mdi-rewind-outline",nombre:"mdi mdi-rewind-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-rhombus",nombre:"mdi mdi-rhombus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-rhombus-outline",nombre:"mdi mdi-rhombus-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-ribbon",nombre:"mdi mdi-ribbon", libreria:"Material Design Iconos" },
	{key:"mdi mdi-rice",nombre:"mdi mdi-rice", libreria:"Material Design Iconos" },
	{key:"mdi mdi-ring",nombre:"mdi mdi-ring", libreria:"Material Design Iconos" },
	{key:"mdi mdi-road",nombre:"mdi mdi-road", libreria:"Material Design Iconos" },
	{key:"mdi mdi-road-variant",nombre:"mdi mdi-road-variant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-robot",nombre:"mdi mdi-robot", libreria:"Material Design Iconos" },
	{key:"mdi mdi-rocket",nombre:"mdi mdi-rocket", libreria:"Material Design Iconos" },
	{key:"mdi mdi-roomba",nombre:"mdi mdi-roomba", libreria:"Material Design Iconos" },
	{key:"mdi mdi-rotate-3d",nombre:"mdi mdi-rotate-3d", libreria:"Material Design Iconos" },
	{key:"mdi mdi-rotate-left",nombre:"mdi mdi-rotate-left", libreria:"Material Design Iconos" },
	{key:"mdi mdi-rotate-left-variant",nombre:"mdi mdi-rotate-left-variant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-rotate-right",nombre:"mdi mdi-rotate-right", libreria:"Material Design Iconos" },
	{key:"mdi mdi-rotate-right-variant",nombre:"mdi mdi-rotate-right-variant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-rounded-corner",nombre:"mdi mdi-rounded-corner", libreria:"Material Design Iconos" },
	{key:"mdi mdi-router-wireless",nombre:"mdi mdi-router-wireless", libreria:"Material Design Iconos" },
	{key:"mdi mdi-routes",nombre:"mdi mdi-routes", libreria:"Material Design Iconos" },
	{key:"mdi mdi-rowing",nombre:"mdi mdi-rowing", libreria:"Material Design Iconos" },
	{key:"mdi mdi-rss",nombre:"mdi mdi-rss", libreria:"Material Design Iconos" },
	{key:"mdi mdi-rss-box",nombre:"mdi mdi-rss-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-ruler",nombre:"mdi mdi-ruler", libreria:"Material Design Iconos" },
	{key:"mdi mdi-run",nombre:"mdi mdi-run", libreria:"Material Design Iconos" },
	{key:"mdi mdi-run-fast",nombre:"mdi mdi-run-fast", libreria:"Material Design Iconos" },
	{key:"mdi mdi-sale",nombre:"mdi mdi-sale", libreria:"Material Design Iconos" },
	{key:"mdi mdi-sass",nombre:"mdi mdi-sass", libreria:"Material Design Iconos" },
	{key:"mdi mdi-satellite",nombre:"mdi mdi-satellite", libreria:"Material Design Iconos" },
	{key:"mdi mdi-satellite-variant",nombre:"mdi mdi-satellite-variant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-saxophone",nombre:"mdi mdi-saxophone", libreria:"Material Design Iconos" },
	{key:"mdi mdi-scale",nombre:"mdi mdi-scale", libreria:"Material Design Iconos" },
	{key:"mdi mdi-scale-balance",nombre:"mdi mdi-scale-balance", libreria:"Material Design Iconos" },
	{key:"mdi mdi-scale-bathroom",nombre:"mdi mdi-scale-bathroom", libreria:"Material Design Iconos" },
	{key:"mdi mdi-scanner",nombre:"mdi mdi-scanner", libreria:"Material Design Iconos" },
	{key:"mdi mdi-school",nombre:"mdi mdi-school", libreria:"Material Design Iconos" },
	{key:"mdi mdi-screen-rotation",nombre:"mdi mdi-screen-rotation", libreria:"Material Design Iconos" },
	{key:"mdi mdi-screen-rotation-lock",nombre:"mdi mdi-screen-rotation-lock", libreria:"Material Design Iconos" },
	{key:"mdi mdi-screwdriver",nombre:"mdi mdi-screwdriver", libreria:"Material Design Iconos" },
	{key:"mdi mdi-script",nombre:"mdi mdi-script", libreria:"Material Design Iconos" },
	{key:"mdi mdi-sd",nombre:"mdi mdi-sd", libreria:"Material Design Iconos" },
	{key:"mdi mdi-seal",nombre:"mdi mdi-seal", libreria:"Material Design Iconos" },
	{key:"mdi mdi-search-web",nombre:"mdi mdi-search-web", libreria:"Material Design Iconos" },
	{key:"mdi mdi-seat-flat",nombre:"mdi mdi-seat-flat", libreria:"Material Design Iconos" },
	{key:"mdi mdi-seat-flat-angled",nombre:"mdi mdi-seat-flat-angled", libreria:"Material Design Iconos" },
	{key:"mdi mdi-seat-individual-suite",nombre:"mdi mdi-seat-individual-suite", libreria:"Material Design Iconos" },
	{key:"mdi mdi-seat-legroom-extra",nombre:"mdi mdi-seat-legroom-extra", libreria:"Material Design Iconos" },
	{key:"mdi mdi-seat-legroom-normal",nombre:"mdi mdi-seat-legroom-normal", libreria:"Material Design Iconos" },
	{key:"mdi mdi-seat-legroom-reduced",nombre:"mdi mdi-seat-legroom-reduced", libreria:"Material Design Iconos" },
	{key:"mdi mdi-seat-recline-extra",nombre:"mdi mdi-seat-recline-extra", libreria:"Material Design Iconos" },
	{key:"mdi mdi-seat-recline-normal",nombre:"mdi mdi-seat-recline-normal", libreria:"Material Design Iconos" },
	{key:"mdi mdi-security",nombre:"mdi mdi-security", libreria:"Material Design Iconos" },
	{key:"mdi mdi-security-home",nombre:"mdi mdi-security-home", libreria:"Material Design Iconos" },
	{key:"mdi mdi-security-network",nombre:"mdi mdi-security-network", libreria:"Material Design Iconos" },
	{key:"mdi mdi-select",nombre:"mdi mdi-select", libreria:"Material Design Iconos" },
	{key:"mdi mdi-select-all",nombre:"mdi mdi-select-all", libreria:"Material Design Iconos" },
	{key:"mdi mdi-select-inverse",nombre:"mdi mdi-select-inverse", libreria:"Material Design Iconos" },
	{key:"mdi mdi-select-off",nombre:"mdi mdi-select-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-selection",nombre:"mdi mdi-selection", libreria:"Material Design Iconos" },
	{key:"mdi mdi-selection-off",nombre:"mdi mdi-selection-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-send",nombre:"mdi mdi-send", libreria:"Material Design Iconos" },
	{key:"mdi mdi-send-secure",nombre:"mdi mdi-send-secure", libreria:"Material Design Iconos" },
	{key:"mdi mdi-serial-port",nombre:"mdi mdi-serial-port", libreria:"Material Design Iconos" },
	{key:"mdi mdi-server",nombre:"mdi mdi-server", libreria:"Material Design Iconos" },
	{key:"mdi mdi-server-minus",nombre:"mdi mdi-server-minus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-server-network",nombre:"mdi mdi-server-network", libreria:"Material Design Iconos" },
	{key:"mdi mdi-server-network-off",nombre:"mdi mdi-server-network-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-server-off",nombre:"mdi mdi-server-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-server-plus",nombre:"mdi mdi-server-plus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-server-remove",nombre:"mdi mdi-server-remove", libreria:"Material Design Iconos" },
	{key:"mdi mdi-server-security",nombre:"mdi mdi-server-security", libreria:"Material Design Iconos" },
	{key:"mdi mdi-set-all",nombre:"mdi mdi-set-all", libreria:"Material Design Iconos" },
	{key:"mdi mdi-set-center",nombre:"mdi mdi-set-center", libreria:"Material Design Iconos" },
	{key:"mdi mdi-set-center-right",nombre:"mdi mdi-set-center-right", libreria:"Material Design Iconos" },
	{key:"mdi mdi-set-left",nombre:"mdi mdi-set-left", libreria:"Material Design Iconos" },
	{key:"mdi mdi-set-left-center",nombre:"mdi mdi-set-left-center", libreria:"Material Design Iconos" },
	{key:"mdi mdi-set-left-right",nombre:"mdi mdi-set-left-right", libreria:"Material Design Iconos" },
	{key:"mdi mdi-set-none",nombre:"mdi mdi-set-none", libreria:"Material Design Iconos" },
	{key:"mdi mdi-set-right",nombre:"mdi mdi-set-right", libreria:"Material Design Iconos" },
	{key:"mdi mdi-settings",nombre:"mdi mdi-settings", libreria:"Material Design Iconos" },
	{key:"mdi mdi-settings-box",nombre:"mdi mdi-settings-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-shape-circle-plus",nombre:"mdi mdi-shape-circle-plus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-shape-plus",nombre:"mdi mdi-shape-plus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-shape-polygon-plus",nombre:"mdi mdi-shape-polygon-plus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-shape-rectangle-plus",nombre:"mdi mdi-shape-rectangle-plus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-shape-square-plus",nombre:"mdi mdi-shape-square-plus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-share",nombre:"mdi mdi-share", libreria:"Material Design Iconos" },
	{key:"mdi mdi-share-variant",nombre:"mdi mdi-share-variant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-shield",nombre:"mdi mdi-shield", libreria:"Material Design Iconos" },
	{key:"mdi mdi-shield-half-full",nombre:"mdi mdi-shield-half-full", libreria:"Material Design Iconos" },
	{key:"mdi mdi-shield-outline",nombre:"mdi mdi-shield-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-shopping",nombre:"mdi mdi-shopping", libreria:"Material Design Iconos" },
	{key:"mdi mdi-shopping-music",nombre:"mdi mdi-shopping-music", libreria:"Material Design Iconos" },
	{key:"mdi mdi-shovel",nombre:"mdi mdi-shovel", libreria:"Material Design Iconos" },
	{key:"mdi mdi-shovel-off",nombre:"mdi mdi-shovel-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-shredder",nombre:"mdi mdi-shredder", libreria:"Material Design Iconos" },
	{key:"mdi mdi-shuffle",nombre:"mdi mdi-shuffle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-shuffle-disabled",nombre:"mdi mdi-shuffle-disabled", libreria:"Material Design Iconos" },
	{key:"mdi mdi-shuffle-variant",nombre:"mdi mdi-shuffle-variant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-sigma",nombre:"mdi mdi-sigma", libreria:"Material Design Iconos" },
	{key:"mdi mdi-sigma-lower",nombre:"mdi mdi-sigma-lower", libreria:"Material Design Iconos" },
	{key:"mdi mdi-sign-caution",nombre:"mdi mdi-sign-caution", libreria:"Material Design Iconos" },
	{key:"mdi mdi-sign-direction",nombre:"mdi mdi-sign-direction", libreria:"Material Design Iconos" },
	{key:"mdi mdi-sign-text",nombre:"mdi mdi-sign-text", libreria:"Material Design Iconos" },
	{key:"mdi mdi-signal",nombre:"mdi mdi-signal", libreria:"Material Design Iconos" },
	{key:"mdi mdi-signal-2g",nombre:"mdi mdi-signal-2g", libreria:"Material Design Iconos" },
	{key:"mdi mdi-signal-3g",nombre:"mdi mdi-signal-3g", libreria:"Material Design Iconos" },
	{key:"mdi mdi-signal-4g",nombre:"mdi mdi-signal-4g", libreria:"Material Design Iconos" },
	{key:"mdi mdi-signal-hspa",nombre:"mdi mdi-signal-hspa", libreria:"Material Design Iconos" },
	{key:"mdi mdi-signal-hspa-plus",nombre:"mdi mdi-signal-hspa-plus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-signal-off",nombre:"mdi mdi-signal-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-signal-variant",nombre:"mdi mdi-signal-variant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-silverware",nombre:"mdi mdi-silverware", libreria:"Material Design Iconos" },
	{key:"mdi mdi-silverware-fork",nombre:"mdi mdi-silverware-fork", libreria:"Material Design Iconos" },
	{key:"mdi mdi-silverware-spoon",nombre:"mdi mdi-silverware-spoon", libreria:"Material Design Iconos" },
	{key:"mdi mdi-silverware-variant",nombre:"mdi mdi-silverware-variant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-sim",nombre:"mdi mdi-sim", libreria:"Material Design Iconos" },
	{key:"mdi mdi-sim-alert",nombre:"mdi mdi-sim-alert", libreria:"Material Design Iconos" },
	{key:"mdi mdi-sim-off",nombre:"mdi mdi-sim-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-sitemap",nombre:"mdi mdi-sitemap", libreria:"Material Design Iconos" },
	{key:"mdi mdi-skip-backward",nombre:"mdi mdi-skip-backward", libreria:"Material Design Iconos" },
	{key:"mdi mdi-skip-forward",nombre:"mdi mdi-skip-forward", libreria:"Material Design Iconos" },
	{key:"mdi mdi-skip-next",nombre:"mdi mdi-skip-next", libreria:"Material Design Iconos" },
	{key:"mdi mdi-skip-next-circle",nombre:"mdi mdi-skip-next-circle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-skip-next-circle-outline",nombre:"mdi mdi-skip-next-circle-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-skip-previous",nombre:"mdi mdi-skip-previous", libreria:"Material Design Iconos" },
	{key:"mdi mdi-skip-previous-circle",nombre:"mdi mdi-skip-previous-circle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-skip-previous-circle-outline",nombre:"mdi mdi-skip-previous-circle-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-skull",nombre:"mdi mdi-skull", libreria:"Material Design Iconos" },
	{key:"mdi mdi-skype",nombre:"mdi mdi-skype", libreria:"Material Design Iconos" },
	{key:"mdi mdi-skype-business",nombre:"mdi mdi-skype-business", libreria:"Material Design Iconos" },
	{key:"mdi mdi-slack",nombre:"mdi mdi-slack", libreria:"Material Design Iconos" },
	{key:"mdi mdi-sleep",nombre:"mdi mdi-sleep", libreria:"Material Design Iconos" },
	{key:"mdi mdi-sleep-off",nombre:"mdi mdi-sleep-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-smoking",nombre:"mdi mdi-smoking", libreria:"Material Design Iconos" },
	{key:"mdi mdi-smoking-off",nombre:"mdi mdi-smoking-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-snapchat",nombre:"mdi mdi-snapchat", libreria:"Material Design Iconos" },
	{key:"mdi mdi-snowflake",nombre:"mdi mdi-snowflake", libreria:"Material Design Iconos" },
	{key:"mdi mdi-snowman",nombre:"mdi mdi-snowman", libreria:"Material Design Iconos" },
	{key:"mdi mdi-soccer",nombre:"mdi mdi-soccer", libreria:"Material Design Iconos" },
	{key:"mdi mdi-sofa",nombre:"mdi mdi-sofa", libreria:"Material Design Iconos" },
	{key:"mdi mdi-solid",nombre:"mdi mdi-solid", libreria:"Material Design Iconos" },
	{key:"mdi mdi-sort",nombre:"mdi mdi-sort", libreria:"Material Design Iconos" },
	{key:"mdi mdi-sort-alphabetical",nombre:"mdi mdi-sort-alphabetical", libreria:"Material Design Iconos" },
	{key:"mdi mdi-sort-ascending",nombre:"mdi mdi-sort-ascending", libreria:"Material Design Iconos" },
	{key:"mdi mdi-sort-descending",nombre:"mdi mdi-sort-descending", libreria:"Material Design Iconos" },
	{key:"mdi mdi-sort-numeric",nombre:"mdi mdi-sort-numeric", libreria:"Material Design Iconos" },
	{key:"mdi mdi-sort-variant",nombre:"mdi mdi-sort-variant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-soundcloud",nombre:"mdi mdi-soundcloud", libreria:"Material Design Iconos" },
	{key:"mdi mdi-source-branch",nombre:"mdi mdi-source-branch", libreria:"Material Design Iconos" },
	{key:"mdi mdi-source-commit",nombre:"mdi mdi-source-commit", libreria:"Material Design Iconos" },
	{key:"mdi mdi-source-commit-end",nombre:"mdi mdi-source-commit-end", libreria:"Material Design Iconos" },
	{key:"mdi mdi-source-commit-end-local",nombre:"mdi mdi-source-commit-end-local", libreria:"Material Design Iconos" },
	{key:"mdi mdi-source-commit-local",nombre:"mdi mdi-source-commit-local", libreria:"Material Design Iconos" },
	{key:"mdi mdi-source-commit-next-local",nombre:"mdi mdi-source-commit-next-local", libreria:"Material Design Iconos" },
	{key:"mdi mdi-source-commit-start",nombre:"mdi mdi-source-commit-start", libreria:"Material Design Iconos" },
	{key:"mdi mdi-source-commit-start-next-local",nombre:"mdi mdi-source-commit-start-next-local", libreria:"Material Design Iconos" },
	{key:"mdi mdi-source-fork",nombre:"mdi mdi-source-fork", libreria:"Material Design Iconos" },
	{key:"mdi mdi-source-merge",nombre:"mdi mdi-source-merge", libreria:"Material Design Iconos" },
	{key:"mdi mdi-source-pull",nombre:"mdi mdi-source-pull", libreria:"Material Design Iconos" },
	{key:"mdi mdi-soy-sauce",nombre:"mdi mdi-soy-sauce", libreria:"Material Design Iconos" },
	{key:"mdi mdi-speaker",nombre:"mdi mdi-speaker", libreria:"Material Design Iconos" },
	{key:"mdi mdi-speaker-off",nombre:"mdi mdi-speaker-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-speaker-wireless",nombre:"mdi mdi-speaker-wireless", libreria:"Material Design Iconos" },
	{key:"mdi mdi-speedometer",nombre:"mdi mdi-speedometer", libreria:"Material Design Iconos" },
	{key:"mdi mdi-spellcheck",nombre:"mdi mdi-spellcheck", libreria:"Material Design Iconos" },
	{key:"mdi mdi-spotify",nombre:"mdi mdi-spotify", libreria:"Material Design Iconos" },
	{key:"mdi mdi-spotlight",nombre:"mdi mdi-spotlight", libreria:"Material Design Iconos" },
	{key:"mdi mdi-spotlight-beam",nombre:"mdi mdi-spotlight-beam", libreria:"Material Design Iconos" },
	{key:"mdi mdi-spray",nombre:"mdi mdi-spray", libreria:"Material Design Iconos" },
	{key:"mdi mdi-square",nombre:"mdi mdi-square", libreria:"Material Design Iconos" },
	{key:"mdi mdi-square-inc",nombre:"mdi mdi-square-inc", libreria:"Material Design Iconos" },
	{key:"mdi mdi-square-inc-cash",nombre:"mdi mdi-square-inc-cash", libreria:"Material Design Iconos" },
	{key:"mdi mdi-square-outline",nombre:"mdi mdi-square-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-square-root",nombre:"mdi mdi-square-root", libreria:"Material Design Iconos" },
	{key:"mdi mdi-stackexchange",nombre:"mdi mdi-stackexchange", libreria:"Material Design Iconos" },
	{key:"mdi mdi-stackoverflow",nombre:"mdi mdi-stackoverflow", libreria:"Material Design Iconos" },
	{key:"mdi mdi-stadium",nombre:"mdi mdi-stadium", libreria:"Material Design Iconos" },
	{key:"mdi mdi-stairs",nombre:"mdi mdi-stairs", libreria:"Material Design Iconos" },
	{key:"mdi mdi-standard-definition",nombre:"mdi mdi-standard-definition", libreria:"Material Design Iconos" },
	{key:"mdi mdi-star",nombre:"mdi mdi-star", libreria:"Material Design Iconos" },
	{key:"mdi mdi-star-circle",nombre:"mdi mdi-star-circle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-star-half",nombre:"mdi mdi-star-half", libreria:"Material Design Iconos" },
	{key:"mdi mdi-star-off",nombre:"mdi mdi-star-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-star-outline",nombre:"mdi mdi-star-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-steam",nombre:"mdi mdi-steam", libreria:"Material Design Iconos" },
	{key:"mdi mdi-steering",nombre:"mdi mdi-steering", libreria:"Material Design Iconos" },
	{key:"mdi mdi-step-backward",nombre:"mdi mdi-step-backward", libreria:"Material Design Iconos" },
	{key:"mdi mdi-step-backward-2",nombre:"mdi mdi-step-backward-2", libreria:"Material Design Iconos" },
	{key:"mdi mdi-step-forward",nombre:"mdi mdi-step-forward", libreria:"Material Design Iconos" },
	{key:"mdi mdi-step-forward-2",nombre:"mdi mdi-step-forward-2", libreria:"Material Design Iconos" },
	{key:"mdi mdi-stethoscope",nombre:"mdi mdi-stethoscope", libreria:"Material Design Iconos" },
	{key:"mdi mdi-sticker",nombre:"mdi mdi-sticker", libreria:"Material Design Iconos" },
	{key:"mdi mdi-sticker-emoji",nombre:"mdi mdi-sticker-emoji", libreria:"Material Design Iconos" },
	{key:"mdi mdi-stocking",nombre:"mdi mdi-stocking", libreria:"Material Design Iconos" },
	{key:"mdi mdi-stop",nombre:"mdi mdi-stop", libreria:"Material Design Iconos" },
	{key:"mdi mdi-stop-circle",nombre:"mdi mdi-stop-circle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-stop-circle-outline",nombre:"mdi mdi-stop-circle-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-store",nombre:"mdi mdi-store", libreria:"Material Design Iconos" },
	{key:"mdi mdi-store-24-hour",nombre:"mdi mdi-store-24-hour", libreria:"Material Design Iconos" },
	{key:"mdi mdi-stove",nombre:"mdi mdi-stove", libreria:"Material Design Iconos" },
	{key:"mdi mdi-subdirectory-arrow-left",nombre:"mdi mdi-subdirectory-arrow-left", libreria:"Material Design Iconos" },
	{key:"mdi mdi-subdirectory-arrow-right",nombre:"mdi mdi-subdirectory-arrow-right", libreria:"Material Design Iconos" },
	{key:"mdi mdi-subway",nombre:"mdi mdi-subway", libreria:"Material Design Iconos" },
	{key:"mdi mdi-subway-variant",nombre:"mdi mdi-subway-variant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-summit",nombre:"mdi mdi-summit", libreria:"Material Design Iconos" },
	{key:"mdi mdi-sunglasses",nombre:"mdi mdi-sunglasses", libreria:"Material Design Iconos" },
	{key:"mdi mdi-surround-sound",nombre:"mdi mdi-surround-sound", libreria:"Material Design Iconos" },
	{key:"mdi mdi-surround-sound-2-0",nombre:"mdi mdi-surround-sound-2-0", libreria:"Material Design Iconos" },
	{key:"mdi mdi-surround-sound-3-1",nombre:"mdi mdi-surround-sound-3-1", libreria:"Material Design Iconos" },
	{key:"mdi mdi-surround-sound-5-1",nombre:"mdi mdi-surround-sound-5-1", libreria:"Material Design Iconos" },
	{key:"mdi mdi-surround-sound-7-1",nombre:"mdi mdi-surround-sound-7-1", libreria:"Material Design Iconos" },
	{key:"mdi mdi-svg",nombre:"mdi mdi-svg", libreria:"Material Design Iconos" },
	{key:"mdi mdi-swap-horizontal",nombre:"mdi mdi-swap-horizontal", libreria:"Material Design Iconos" },
	{key:"mdi mdi-swap-vertical",nombre:"mdi mdi-swap-vertical", libreria:"Material Design Iconos" },
	{key:"mdi mdi-swim",nombre:"mdi mdi-swim", libreria:"Material Design Iconos" },
	{key:"mdi mdi-switch",nombre:"mdi mdi-switch", libreria:"Material Design Iconos" },
	{key:"mdi mdi-sword",nombre:"mdi mdi-sword", libreria:"Material Design Iconos" },
	{key:"mdi mdi-sword-cross",nombre:"mdi mdi-sword-cross", libreria:"Material Design Iconos" },
	{key:"mdi mdi-sync",nombre:"mdi mdi-sync", libreria:"Material Design Iconos" },
	{key:"mdi mdi-sync-alert",nombre:"mdi mdi-sync-alert", libreria:"Material Design Iconos" },
	{key:"mdi mdi-sync-off",nombre:"mdi mdi-sync-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-tab",nombre:"mdi mdi-tab", libreria:"Material Design Iconos" },
	{key:"mdi mdi-tab-plus",nombre:"mdi mdi-tab-plus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-tab-unselected",nombre:"mdi mdi-tab-unselected", libreria:"Material Design Iconos" },
	{key:"mdi mdi-table",nombre:"mdi mdi-table", libreria:"Material Design Iconos" },
	{key:"mdi mdi-table-column-plus-after",nombre:"mdi mdi-table-column-plus-after", libreria:"Material Design Iconos" },
	{key:"mdi mdi-table-column-plus-before",nombre:"mdi mdi-table-column-plus-before", libreria:"Material Design Iconos" },
	{key:"mdi mdi-table-column-remove",nombre:"mdi mdi-table-column-remove", libreria:"Material Design Iconos" },
	{key:"mdi mdi-table-column-width",nombre:"mdi mdi-table-column-width", libreria:"Material Design Iconos" },
	{key:"mdi mdi-table-edit",nombre:"mdi mdi-table-edit", libreria:"Material Design Iconos" },
	{key:"mdi mdi-table-large",nombre:"mdi mdi-table-large", libreria:"Material Design Iconos" },
	{key:"mdi mdi-table-row-height",nombre:"mdi mdi-table-row-height", libreria:"Material Design Iconos" },
	{key:"mdi mdi-table-row-plus-after",nombre:"mdi mdi-table-row-plus-after", libreria:"Material Design Iconos" },
	{key:"mdi mdi-table-row-plus-before",nombre:"mdi mdi-table-row-plus-before", libreria:"Material Design Iconos" },
	{key:"mdi mdi-table-row-remove",nombre:"mdi mdi-table-row-remove", libreria:"Material Design Iconos" },
	{key:"mdi mdi-tablet",nombre:"mdi mdi-tablet", libreria:"Material Design Iconos" },
	{key:"mdi mdi-tablet-android",nombre:"mdi mdi-tablet-android", libreria:"Material Design Iconos" },
	{key:"mdi mdi-tablet-ipad",nombre:"mdi mdi-tablet-ipad", libreria:"Material Design Iconos" },
	{key:"mdi mdi-taco",nombre:"mdi mdi-taco", libreria:"Material Design Iconos" },
	{key:"mdi mdi-tag",nombre:"mdi mdi-tag", libreria:"Material Design Iconos" },
	{key:"mdi mdi-tag-faces",nombre:"mdi mdi-tag-faces", libreria:"Material Design Iconos" },
	{key:"mdi mdi-tag-heart",nombre:"mdi mdi-tag-heart", libreria:"Material Design Iconos" },
	{key:"mdi mdi-tag-multiple",nombre:"mdi mdi-tag-multiple", libreria:"Material Design Iconos" },
	{key:"mdi mdi-tag-outline",nombre:"mdi mdi-tag-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-tag-plus",nombre:"mdi mdi-tag-plus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-tag-remove",nombre:"mdi mdi-tag-remove", libreria:"Material Design Iconos" },
	{key:"mdi mdi-tag-text-outline",nombre:"mdi mdi-tag-text-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-target",nombre:"mdi mdi-target", libreria:"Material Design Iconos" },
	{key:"mdi mdi-taxi",nombre:"mdi mdi-taxi", libreria:"Material Design Iconos" },
	{key:"mdi mdi-teamviewer",nombre:"mdi mdi-teamviewer", libreria:"Material Design Iconos" },
	{key:"mdi mdi-telegram",nombre:"mdi mdi-telegram", libreria:"Material Design Iconos" },
	{key:"mdi mdi-television",nombre:"mdi mdi-television", libreria:"Material Design Iconos" },
	{key:"mdi mdi-television-classic",nombre:"mdi mdi-television-classic", libreria:"Material Design Iconos" },
	{key:"mdi mdi-television-guide",nombre:"mdi mdi-television-guide", libreria:"Material Design Iconos" },
	{key:"mdi mdi-temperature-celsius",nombre:"mdi mdi-temperature-celsius", libreria:"Material Design Iconos" },
	{key:"mdi mdi-temperature-fahrenheit",nombre:"mdi mdi-temperature-fahrenheit", libreria:"Material Design Iconos" },
	{key:"mdi mdi-temperature-kelvin",nombre:"mdi mdi-temperature-kelvin", libreria:"Material Design Iconos" },
	{key:"mdi mdi-tennis",nombre:"mdi mdi-tennis", libreria:"Material Design Iconos" },
	{key:"mdi mdi-tent",nombre:"mdi mdi-tent", libreria:"Material Design Iconos" },
	{key:"mdi mdi-terrain",nombre:"mdi mdi-terrain", libreria:"Material Design Iconos" },
	{key:"mdi mdi-test-tube",nombre:"mdi mdi-test-tube", libreria:"Material Design Iconos" },
	{key:"mdi mdi-text-shadow",nombre:"mdi mdi-text-shadow", libreria:"Material Design Iconos" },
	{key:"mdi mdi-text-to-speech",nombre:"mdi mdi-text-to-speech", libreria:"Material Design Iconos" },
	{key:"mdi mdi-text-to-speech-off",nombre:"mdi mdi-text-to-speech-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-textbox",nombre:"mdi mdi-textbox", libreria:"Material Design Iconos" },
	{key:"mdi mdi-textbox-password",nombre:"mdi mdi-textbox-password", libreria:"Material Design Iconos" },
	{key:"mdi mdi-texture",nombre:"mdi mdi-texture", libreria:"Material Design Iconos" },
	{key:"mdi mdi-theater",nombre:"mdi mdi-theater", libreria:"Material Design Iconos" },
	{key:"mdi mdi-theme-light-dark",nombre:"mdi mdi-theme-light-dark", libreria:"Material Design Iconos" },
	{key:"mdi mdi-thermometer",nombre:"mdi mdi-thermometer", libreria:"Material Design Iconos" },
	{key:"mdi mdi-thermometer-lines",nombre:"mdi mdi-thermometer-lines", libreria:"Material Design Iconos" },
	{key:"mdi mdi-thought-bubble",nombre:"mdi mdi-thought-bubble", libreria:"Material Design Iconos" },
	{key:"mdi mdi-thought-bubble-outline",nombre:"mdi mdi-thought-bubble-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-thumb-down",nombre:"mdi mdi-thumb-down", libreria:"Material Design Iconos" },
	{key:"mdi mdi-thumb-down-outline",nombre:"mdi mdi-thumb-down-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-thumb-up",nombre:"mdi mdi-thumb-up", libreria:"Material Design Iconos" },
	{key:"mdi mdi-thumb-up-outline",nombre:"mdi mdi-thumb-up-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-thumbs-up-down",nombre:"mdi mdi-thumbs-up-down", libreria:"Material Design Iconos" },
	{key:"mdi mdi-ticket",nombre:"mdi mdi-ticket", libreria:"Material Design Iconos" },
	{key:"mdi mdi-ticket-account",nombre:"mdi mdi-ticket-account", libreria:"Material Design Iconos" },
	{key:"mdi mdi-ticket-confirmation",nombre:"mdi mdi-ticket-confirmation", libreria:"Material Design Iconos" },
	{key:"mdi mdi-ticket-percent",nombre:"mdi mdi-ticket-percent", libreria:"Material Design Iconos" },
	{key:"mdi mdi-tie",nombre:"mdi mdi-tie", libreria:"Material Design Iconos" },
	{key:"mdi mdi-tilde",nombre:"mdi mdi-tilde", libreria:"Material Design Iconos" },
	{key:"mdi mdi-timelapse",nombre:"mdi mdi-timelapse", libreria:"Material Design Iconos" },
	{key:"mdi mdi-timer",nombre:"mdi mdi-timer", libreria:"Material Design Iconos" },
	{key:"mdi mdi-timer-10",nombre:"mdi mdi-timer-10", libreria:"Material Design Iconos" },
	{key:"mdi mdi-timer-3",nombre:"mdi mdi-timer-3", libreria:"Material Design Iconos" },
	{key:"mdi mdi-timer-off",nombre:"mdi mdi-timer-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-timer-sand",nombre:"mdi mdi-timer-sand", libreria:"Material Design Iconos" },
	{key:"mdi mdi-timer-sand-empty",nombre:"mdi mdi-timer-sand-empty", libreria:"Material Design Iconos" },
	{key:"mdi mdi-timer-sand-full",nombre:"mdi mdi-timer-sand-full", libreria:"Material Design Iconos" },
	{key:"mdi mdi-timetable",nombre:"mdi mdi-timetable", libreria:"Material Design Iconos" },
	{key:"mdi mdi-toggle-switch",nombre:"mdi mdi-toggle-switch", libreria:"Material Design Iconos" },
	{key:"mdi mdi-toggle-switch-off",nombre:"mdi mdi-toggle-switch-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-tooltip",nombre:"mdi mdi-tooltip", libreria:"Material Design Iconos" },
	{key:"mdi mdi-tooltip-edit",nombre:"mdi mdi-tooltip-edit", libreria:"Material Design Iconos" },
	{key:"mdi mdi-tooltip-image",nombre:"mdi mdi-tooltip-image", libreria:"Material Design Iconos" },
	{key:"mdi mdi-tooltip-outline",nombre:"mdi mdi-tooltip-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-tooltip-outline-plus",nombre:"mdi mdi-tooltip-outline-plus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-tooltip-text",nombre:"mdi mdi-tooltip-text", libreria:"Material Design Iconos" },
	{key:"mdi mdi-tooth",nombre:"mdi mdi-tooth", libreria:"Material Design Iconos" },
	{key:"mdi mdi-tor",nombre:"mdi mdi-tor", libreria:"Material Design Iconos" },
	{key:"mdi mdi-tower-beach",nombre:"mdi mdi-tower-beach", libreria:"Material Design Iconos" },
	{key:"mdi mdi-tower-fire",nombre:"mdi mdi-tower-fire", libreria:"Material Design Iconos" },
	{key:"mdi mdi-trackpad",nombre:"mdi mdi-trackpad", libreria:"Material Design Iconos" },
	{key:"mdi mdi-traffic-light",nombre:"mdi mdi-traffic-light", libreria:"Material Design Iconos" },
	{key:"mdi mdi-train",nombre:"mdi mdi-train", libreria:"Material Design Iconos" },
	{key:"mdi mdi-tram",nombre:"mdi mdi-tram", libreria:"Material Design Iconos" },
	{key:"mdi mdi-transcribe",nombre:"mdi mdi-transcribe", libreria:"Material Design Iconos" },
	{key:"mdi mdi-transcribe-close",nombre:"mdi mdi-transcribe-close", libreria:"Material Design Iconos" },
	{key:"mdi mdi-transfer",nombre:"mdi mdi-transfer", libreria:"Material Design Iconos" },
	{key:"mdi mdi-transit-transfer",nombre:"mdi mdi-transit-transfer", libreria:"Material Design Iconos" },
	{key:"mdi mdi-translate",nombre:"mdi mdi-translate", libreria:"Material Design Iconos" },
	{key:"mdi mdi-treasure-chest",nombre:"mdi mdi-treasure-chest", libreria:"Material Design Iconos" },
	{key:"mdi mdi-tree",nombre:"mdi mdi-tree", libreria:"Material Design Iconos" },
	{key:"mdi mdi-trello",nombre:"mdi mdi-trello", libreria:"Material Design Iconos" },
	{key:"mdi mdi-trending-down",nombre:"mdi mdi-trending-down", libreria:"Material Design Iconos" },
	{key:"mdi mdi-trending-neutral",nombre:"mdi mdi-trending-neutral", libreria:"Material Design Iconos" },
	{key:"mdi mdi-trending-up",nombre:"mdi mdi-trending-up", libreria:"Material Design Iconos" },
	{key:"mdi mdi-triangle",nombre:"mdi mdi-triangle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-triangle-outline",nombre:"mdi mdi-triangle-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-trophy",nombre:"mdi mdi-trophy", libreria:"Material Design Iconos" },
	{key:"mdi mdi-trophy-award",nombre:"mdi mdi-trophy-award", libreria:"Material Design Iconos" },
	{key:"mdi mdi-trophy-outline",nombre:"mdi mdi-trophy-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-trophy-variant",nombre:"mdi mdi-trophy-variant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-trophy-variant-outline",nombre:"mdi mdi-trophy-variant-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-truck",nombre:"mdi mdi-truck", libreria:"Material Design Iconos" },
	{key:"mdi mdi-truck-delivery",nombre:"mdi mdi-truck-delivery", libreria:"Material Design Iconos" },
	{key:"mdi mdi-truck-fast",nombre:"mdi mdi-truck-fast", libreria:"Material Design Iconos" },
	{key:"mdi mdi-truck-trailer",nombre:"mdi mdi-truck-trailer", libreria:"Material Design Iconos" },
	{key:"mdi mdi-tshirt-crew",nombre:"mdi mdi-tshirt-crew", libreria:"Material Design Iconos" },
	{key:"mdi mdi-tshirt-v",nombre:"mdi mdi-tshirt-v", libreria:"Material Design Iconos" },
	{key:"mdi mdi-tumblr",nombre:"mdi mdi-tumblr", libreria:"Material Design Iconos" },
	{key:"mdi mdi-tumblr-reblog",nombre:"mdi mdi-tumblr-reblog", libreria:"Material Design Iconos" },
	{key:"mdi mdi-tune",nombre:"mdi mdi-tune", libreria:"Material Design Iconos" },
	{key:"mdi mdi-tune-vertical",nombre:"mdi mdi-tune-vertical", libreria:"Material Design Iconos" },
	{key:"mdi mdi-twitch",nombre:"mdi mdi-twitch", libreria:"Material Design Iconos" },
	{key:"mdi mdi-twitter",nombre:"mdi mdi-twitter", libreria:"Material Design Iconos" },
	{key:"mdi mdi-twitter-box",nombre:"mdi mdi-twitter-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-twitter-circle",nombre:"mdi mdi-twitter-circle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-twitter-retweet",nombre:"mdi mdi-twitter-retweet", libreria:"Material Design Iconos" },
	{key:"mdi mdi-uber",nombre:"mdi mdi-uber", libreria:"Material Design Iconos" },
	{key:"mdi mdi-ubuntu",nombre:"mdi mdi-ubuntu", libreria:"Material Design Iconos" },
	{key:"mdi mdi-ultra-high-definition",nombre:"mdi mdi-ultra-high-definition", libreria:"Material Design Iconos" },
	{key:"mdi mdi-umbraco",nombre:"mdi mdi-umbraco", libreria:"Material Design Iconos" },
	{key:"mdi mdi-umbrella",nombre:"mdi mdi-umbrella", libreria:"Material Design Iconos" },
	{key:"mdi mdi-umbrella-outline",nombre:"mdi mdi-umbrella-outline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-undo",nombre:"mdi mdi-undo", libreria:"Material Design Iconos" },
	{key:"mdi mdi-undo-variant",nombre:"mdi mdi-undo-variant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-unfold-less-horizontal",nombre:"mdi mdi-unfold-less-horizontal", libreria:"Material Design Iconos" },
	{key:"mdi mdi-unfold-less-vertical",nombre:"mdi mdi-unfold-less-vertical", libreria:"Material Design Iconos" },
	{key:"mdi mdi-unfold-more-horizontal",nombre:"mdi mdi-unfold-more-horizontal", libreria:"Material Design Iconos" },
	{key:"mdi mdi-unfold-more-vertical",nombre:"mdi mdi-unfold-more-vertical", libreria:"Material Design Iconos" },
	{key:"mdi mdi-ungroup",nombre:"mdi mdi-ungroup", libreria:"Material Design Iconos" },
	{key:"mdi mdi-unity",nombre:"mdi mdi-unity", libreria:"Material Design Iconos" },
	{key:"mdi mdi-untappd",nombre:"mdi mdi-untappd", libreria:"Material Design Iconos" },
	{key:"mdi mdi-update",nombre:"mdi mdi-update", libreria:"Material Design Iconos" },
	{key:"mdi mdi-upload",nombre:"mdi mdi-upload", libreria:"Material Design Iconos" },
	{key:"mdi mdi-upload-network",nombre:"mdi mdi-upload-network", libreria:"Material Design Iconos" },
	{key:"mdi mdi-usb",nombre:"mdi mdi-usb", libreria:"Material Design Iconos" },
	{key:"mdi mdi-van-passenger",nombre:"mdi mdi-van-passenger", libreria:"Material Design Iconos" },
	{key:"mdi mdi-van-utility",nombre:"mdi mdi-van-utility", libreria:"Material Design Iconos" },
	{key:"mdi mdi-vanish",nombre:"mdi mdi-vanish", libreria:"Material Design Iconos" },
	{key:"mdi mdi-vector-arrange-above",nombre:"mdi mdi-vector-arrange-above", libreria:"Material Design Iconos" },
	{key:"mdi mdi-vector-arrange-below",nombre:"mdi mdi-vector-arrange-below", libreria:"Material Design Iconos" },
	{key:"mdi mdi-vector-circle",nombre:"mdi mdi-vector-circle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-vector-circle-variant",nombre:"mdi mdi-vector-circle-variant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-vector-combine",nombre:"mdi mdi-vector-combine", libreria:"Material Design Iconos" },
	{key:"mdi mdi-vector-curve",nombre:"mdi mdi-vector-curve", libreria:"Material Design Iconos" },
	{key:"mdi mdi-vector-difference",nombre:"mdi mdi-vector-difference", libreria:"Material Design Iconos" },
	{key:"mdi mdi-vector-difference-ab",nombre:"mdi mdi-vector-difference-ab", libreria:"Material Design Iconos" },
	{key:"mdi mdi-vector-difference-ba",nombre:"mdi mdi-vector-difference-ba", libreria:"Material Design Iconos" },
	{key:"mdi mdi-vector-intersection",nombre:"mdi mdi-vector-intersection", libreria:"Material Design Iconos" },
	{key:"mdi mdi-vector-line",nombre:"mdi mdi-vector-line", libreria:"Material Design Iconos" },
	{key:"mdi mdi-vector-point",nombre:"mdi mdi-vector-point", libreria:"Material Design Iconos" },
	{key:"mdi mdi-vector-polygon",nombre:"mdi mdi-vector-polygon", libreria:"Material Design Iconos" },
	{key:"mdi mdi-vector-polyline",nombre:"mdi mdi-vector-polyline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-vector-radius",nombre:"mdi mdi-vector-radius", libreria:"Material Design Iconos" },
	{key:"mdi mdi-vector-rectangle",nombre:"mdi mdi-vector-rectangle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-vector-selection",nombre:"mdi mdi-vector-selection", libreria:"Material Design Iconos" },
	{key:"mdi mdi-vector-square",nombre:"mdi mdi-vector-square", libreria:"Material Design Iconos" },
	{key:"mdi mdi-vector-triangle",nombre:"mdi mdi-vector-triangle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-vector-union",nombre:"mdi mdi-vector-union", libreria:"Material Design Iconos" },
	{key:"mdi mdi-verified",nombre:"mdi mdi-verified", libreria:"Material Design Iconos" },
	{key:"mdi mdi-vibrate",nombre:"mdi mdi-vibrate", libreria:"Material Design Iconos" },
	{key:"mdi mdi-video",nombre:"mdi mdi-video", libreria:"Material Design Iconos" },
	{key:"mdi mdi-video-3d",nombre:"mdi mdi-video-3d", libreria:"Material Design Iconos" },
	{key:"mdi mdi-video-off",nombre:"mdi mdi-video-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-video-switch",nombre:"mdi mdi-video-switch", libreria:"Material Design Iconos" },
	{key:"mdi mdi-view-agenda",nombre:"mdi mdi-view-agenda", libreria:"Material Design Iconos" },
	{key:"mdi mdi-view-array",nombre:"mdi mdi-view-array", libreria:"Material Design Iconos" },
	{key:"mdi mdi-view-carousel",nombre:"mdi mdi-view-carousel", libreria:"Material Design Iconos" },
	{key:"mdi mdi-view-column",nombre:"mdi mdi-view-column", libreria:"Material Design Iconos" },
	{key:"mdi mdi-view-dashboard",nombre:"mdi mdi-view-dashboard", libreria:"Material Design Iconos" },
	{key:"mdi mdi-view-day",nombre:"mdi mdi-view-day", libreria:"Material Design Iconos" },
	{key:"mdi mdi-view-grid",nombre:"mdi mdi-view-grid", libreria:"Material Design Iconos" },
	{key:"mdi mdi-view-headline",nombre:"mdi mdi-view-headline", libreria:"Material Design Iconos" },
	{key:"mdi mdi-view-list",nombre:"mdi mdi-view-list", libreria:"Material Design Iconos" },
	{key:"mdi mdi-view-module",nombre:"mdi mdi-view-module", libreria:"Material Design Iconos" },
	{key:"mdi mdi-view-parallel",nombre:"mdi mdi-view-parallel", libreria:"Material Design Iconos" },
	{key:"mdi mdi-view-quilt",nombre:"mdi mdi-view-quilt", libreria:"Material Design Iconos" },
	{key:"mdi mdi-view-sequential",nombre:"mdi mdi-view-sequential", libreria:"Material Design Iconos" },
	{key:"mdi mdi-view-stream",nombre:"mdi mdi-view-stream", libreria:"Material Design Iconos" },
	{key:"mdi mdi-view-week",nombre:"mdi mdi-view-week", libreria:"Material Design Iconos" },
	{key:"mdi mdi-vimeo",nombre:"mdi mdi-vimeo", libreria:"Material Design Iconos" },
	{key:"mdi mdi-vine",nombre:"mdi mdi-vine", libreria:"Material Design Iconos" },
	{key:"mdi mdi-violin",nombre:"mdi mdi-violin", libreria:"Material Design Iconos" },
	{key:"mdi mdi-visualstudio",nombre:"mdi mdi-visualstudio", libreria:"Material Design Iconos" },
	{key:"mdi mdi-vk",nombre:"mdi mdi-vk", libreria:"Material Design Iconos" },
	{key:"mdi mdi-vk-box",nombre:"mdi mdi-vk-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-vk-circle",nombre:"mdi mdi-vk-circle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-vlc",nombre:"mdi mdi-vlc", libreria:"Material Design Iconos" },
	{key:"mdi mdi-voice",nombre:"mdi mdi-voice", libreria:"Material Design Iconos" },
	{key:"mdi mdi-voicemail",nombre:"mdi mdi-voicemail", libreria:"Material Design Iconos" },
	{key:"mdi mdi-volume-high",nombre:"mdi mdi-volume-high", libreria:"Material Design Iconos" },
	{key:"mdi mdi-volume-low",nombre:"mdi mdi-volume-low", libreria:"Material Design Iconos" },
	{key:"mdi mdi-volume-medium",nombre:"mdi mdi-volume-medium", libreria:"Material Design Iconos" },
	{key:"mdi mdi-volume-minus",nombre:"mdi mdi-volume-minus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-volume-mute",nombre:"mdi mdi-volume-mute", libreria:"Material Design Iconos" },
	{key:"mdi mdi-volume-off",nombre:"mdi mdi-volume-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-volume-plus",nombre:"mdi mdi-volume-plus", libreria:"Material Design Iconos" },
	{key:"mdi mdi-vpn",nombre:"mdi mdi-vpn", libreria:"Material Design Iconos" },
	{key:"mdi mdi-walk",nombre:"mdi mdi-walk", libreria:"Material Design Iconos" },
	{key:"mdi mdi-wall",nombre:"mdi mdi-wall", libreria:"Material Design Iconos" },
	{key:"mdi mdi-wallet",nombre:"mdi mdi-wallet", libreria:"Material Design Iconos" },
	{key:"mdi mdi-wallet-giftcard",nombre:"mdi mdi-wallet-giftcard", libreria:"Material Design Iconos" },
	{key:"mdi mdi-wallet-membership",nombre:"mdi mdi-wallet-membership", libreria:"Material Design Iconos" },
	{key:"mdi mdi-wallet-travel",nombre:"mdi mdi-wallet-travel", libreria:"Material Design Iconos" },
	{key:"mdi mdi-wan",nombre:"mdi mdi-wan", libreria:"Material Design Iconos" },
	{key:"mdi mdi-washing-machine",nombre:"mdi mdi-washing-machine", libreria:"Material Design Iconos" },
	{key:"mdi mdi-watch",nombre:"mdi mdi-watch", libreria:"Material Design Iconos" },
	{key:"mdi mdi-watch-export",nombre:"mdi mdi-watch-export", libreria:"Material Design Iconos" },
	{key:"mdi mdi-watch-import",nombre:"mdi mdi-watch-import", libreria:"Material Design Iconos" },
	{key:"mdi mdi-watch-vibrate",nombre:"mdi mdi-watch-vibrate", libreria:"Material Design Iconos" },
	{key:"mdi mdi-water",nombre:"mdi mdi-water", libreria:"Material Design Iconos" },
	{key:"mdi mdi-water-off",nombre:"mdi mdi-water-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-water-percent",nombre:"mdi mdi-water-percent", libreria:"Material Design Iconos" },
	{key:"mdi mdi-water-pump",nombre:"mdi mdi-water-pump", libreria:"Material Design Iconos" },
	{key:"mdi mdi-watermark",nombre:"mdi mdi-watermark", libreria:"Material Design Iconos" },
	{key:"mdi mdi-waves",nombre:"mdi mdi-waves", libreria:"Material Design Iconos" },
	{key:"mdi mdi-weather-cloudy",nombre:"mdi mdi-weather-cloudy", libreria:"Material Design Iconos" },
	{key:"mdi mdi-weather-fog",nombre:"mdi mdi-weather-fog", libreria:"Material Design Iconos" },
	{key:"mdi mdi-weather-hail",nombre:"mdi mdi-weather-hail", libreria:"Material Design Iconos" },
	{key:"mdi mdi-weather-lightning",nombre:"mdi mdi-weather-lightning", libreria:"Material Design Iconos" },
	{key:"mdi mdi-weather-lightning-rainy",nombre:"mdi mdi-weather-lightning-rainy", libreria:"Material Design Iconos" },
	{key:"mdi mdi-weather-night",nombre:"mdi mdi-weather-night", libreria:"Material Design Iconos" },
	{key:"mdi mdi-weather-partlycloudy",nombre:"mdi mdi-weather-partlycloudy", libreria:"Material Design Iconos" },
	{key:"mdi mdi-weather-pouring",nombre:"mdi mdi-weather-pouring", libreria:"Material Design Iconos" },
	{key:"mdi mdi-weather-rainy",nombre:"mdi mdi-weather-rainy", libreria:"Material Design Iconos" },
	{key:"mdi mdi-weather-snowy",nombre:"mdi mdi-weather-snowy", libreria:"Material Design Iconos" },
	{key:"mdi mdi-weather-snowy-rainy",nombre:"mdi mdi-weather-snowy-rainy", libreria:"Material Design Iconos" },
	{key:"mdi mdi-weather-sunny",nombre:"mdi mdi-weather-sunny", libreria:"Material Design Iconos" },
	{key:"mdi mdi-weather-sunset",nombre:"mdi mdi-weather-sunset", libreria:"Material Design Iconos" },
	{key:"mdi mdi-weather-sunset-down",nombre:"mdi mdi-weather-sunset-down", libreria:"Material Design Iconos" },
	{key:"mdi mdi-weather-sunset-up",nombre:"mdi mdi-weather-sunset-up", libreria:"Material Design Iconos" },
	{key:"mdi mdi-weather-windy",nombre:"mdi mdi-weather-windy", libreria:"Material Design Iconos" },
	{key:"mdi mdi-weather-windy-variant",nombre:"mdi mdi-weather-windy-variant", libreria:"Material Design Iconos" },
	{key:"mdi mdi-web",nombre:"mdi mdi-web", libreria:"Material Design Iconos" },
	{key:"mdi mdi-webcam",nombre:"mdi mdi-webcam", libreria:"Material Design Iconos" },
	{key:"mdi mdi-webhook",nombre:"mdi mdi-webhook", libreria:"Material Design Iconos" },
	{key:"mdi mdi-webpack",nombre:"mdi mdi-webpack", libreria:"Material Design Iconos" },
	{key:"mdi mdi-wechat",nombre:"mdi mdi-wechat", libreria:"Material Design Iconos" },
	{key:"mdi mdi-weight",nombre:"mdi mdi-weight", libreria:"Material Design Iconos" },
	{key:"mdi mdi-weight-kilogram",nombre:"mdi mdi-weight-kilogram", libreria:"Material Design Iconos" },
	{key:"mdi mdi-whatsapp",nombre:"mdi mdi-whatsapp", libreria:"Material Design Iconos" },
	{key:"mdi mdi-wheelchair-accessibility",nombre:"mdi mdi-wheelchair-accessibility", libreria:"Material Design Iconos" },
	{key:"mdi mdi-white-balance-auto",nombre:"mdi mdi-white-balance-auto", libreria:"Material Design Iconos" },
	{key:"mdi mdi-white-balance-incandescent",nombre:"mdi mdi-white-balance-incandescent", libreria:"Material Design Iconos" },
	{key:"mdi mdi-white-balance-iridescent",nombre:"mdi mdi-white-balance-iridescent", libreria:"Material Design Iconos" },
	{key:"mdi mdi-white-balance-sunny",nombre:"mdi mdi-white-balance-sunny", libreria:"Material Design Iconos" },
	{key:"mdi mdi-widgets",nombre:"mdi mdi-widgets", libreria:"Material Design Iconos" },
	{key:"mdi mdi-wifi",nombre:"mdi mdi-wifi", libreria:"Material Design Iconos" },
	{key:"mdi mdi-wifi-off",nombre:"mdi mdi-wifi-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-wii",nombre:"mdi mdi-wii", libreria:"Material Design Iconos" },
	{key:"mdi mdi-wiiu",nombre:"mdi mdi-wiiu", libreria:"Material Design Iconos" },
	{key:"mdi mdi-wikipedia",nombre:"mdi mdi-wikipedia", libreria:"Material Design Iconos" },
	{key:"mdi mdi-window-close",nombre:"mdi mdi-window-close", libreria:"Material Design Iconos" },
	{key:"mdi mdi-window-closed",nombre:"mdi mdi-window-closed", libreria:"Material Design Iconos" },
	{key:"mdi mdi-window-maximize",nombre:"mdi mdi-window-maximize", libreria:"Material Design Iconos" },
	{key:"mdi mdi-window-minimize",nombre:"mdi mdi-window-minimize", libreria:"Material Design Iconos" },
	{key:"mdi mdi-window-open",nombre:"mdi mdi-window-open", libreria:"Material Design Iconos" },
	{key:"mdi mdi-window-restore",nombre:"mdi mdi-window-restore", libreria:"Material Design Iconos" },
	{key:"mdi mdi-windows",nombre:"mdi mdi-windows", libreria:"Material Design Iconos" },
	{key:"mdi mdi-wordpress",nombre:"mdi mdi-wordpress", libreria:"Material Design Iconos" },
	{key:"mdi mdi-worker",nombre:"mdi mdi-worker", libreria:"Material Design Iconos" },
	{key:"mdi mdi-wrap",nombre:"mdi mdi-wrap", libreria:"Material Design Iconos" },
	{key:"mdi mdi-wrench",nombre:"mdi mdi-wrench", libreria:"Material Design Iconos" },
	{key:"mdi mdi-wunderlist",nombre:"mdi mdi-wunderlist", libreria:"Material Design Iconos" },
	{key:"mdi mdi-xaml",nombre:"mdi mdi-xaml", libreria:"Material Design Iconos" },
	{key:"mdi mdi-xbox",nombre:"mdi mdi-xbox", libreria:"Material Design Iconos" },
	{key:"mdi mdi-xbox-controller",nombre:"mdi mdi-xbox-controller", libreria:"Material Design Iconos" },
	{key:"mdi mdi-xbox-controller-battery-alert",nombre:"mdi mdi-xbox-controller-battery-alert", libreria:"Material Design Iconos" },
	{key:"mdi mdi-xbox-controller-battery-empty",nombre:"mdi mdi-xbox-controller-battery-empty", libreria:"Material Design Iconos" },
	{key:"mdi mdi-xbox-controller-battery-full",nombre:"mdi mdi-xbox-controller-battery-full", libreria:"Material Design Iconos" },
	{key:"mdi mdi-xbox-controller-battery-low",nombre:"mdi mdi-xbox-controller-battery-low", libreria:"Material Design Iconos" },
	{key:"mdi mdi-xbox-controller-battery-medium",nombre:"mdi mdi-xbox-controller-battery-medium", libreria:"Material Design Iconos" },
	{key:"mdi mdi-xbox-controller-battery-unknown",nombre:"mdi mdi-xbox-controller-battery-unknown", libreria:"Material Design Iconos" },
	{key:"mdi mdi-xbox-controller-off",nombre:"mdi mdi-xbox-controller-off", libreria:"Material Design Iconos" },
	{key:"mdi mdi-xda",nombre:"mdi mdi-xda", libreria:"Material Design Iconos" },
	{key:"mdi mdi-xing",nombre:"mdi mdi-xing", libreria:"Material Design Iconos" },
	{key:"mdi mdi-xing-box",nombre:"mdi mdi-xing-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-xing-circle",nombre:"mdi mdi-xing-circle", libreria:"Material Design Iconos" },
	{key:"mdi mdi-xml",nombre:"mdi mdi-xml", libreria:"Material Design Iconos" },
	{key:"mdi mdi-xmpp",nombre:"mdi mdi-xmpp", libreria:"Material Design Iconos" },
	{key:"mdi mdi-yammer",nombre:"mdi mdi-yammer", libreria:"Material Design Iconos" },
	{key:"mdi mdi-yeast",nombre:"mdi mdi-yeast", libreria:"Material Design Iconos" },
	{key:"mdi mdi-yelp",nombre:"mdi mdi-yelp", libreria:"Material Design Iconos" },
	{key:"mdi mdi-yin-yang",nombre:"mdi mdi-yin-yang", libreria:"Material Design Iconos" },
	{key:"mdi mdi-youtube-play",nombre:"mdi mdi-youtube-play", libreria:"Material Design Iconos" },
	{key:"mdi mdi-zip-box",nombre:"mdi mdi-zip-box", libreria:"Material Design Iconos" },
	{key:"mdi mdi-blank",nombre:"mdi mdi-blank", libreria:"Material Design Iconos" },
	{key:"mdi mdi-home-plus-outline", nombre: "mdi mdi-home-plus-outline", libreria: "Material Design Iconos" },
];



