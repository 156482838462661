<div class="app-footer d-flex align-items-center justify-content-between"
*ngIf="settings" [class.fixed-bottom]="settings.panel.footerIsFixed">
	    <span class="social-icons">
    	 <a href={{settings.cliente.web}} target="_blank" *ngIf="settings.panel.footerTitle">{{settings.panel.footerTitle}}</a>
         <a href={{settings.cliente.redSocial.facebook}} target="_blank" *ngIf="settings.cliente.redSocial.facebook"> <i class="fa fa-facebook-square transition" aria-hidden="true"></i></a>
         <a href={{settings.cliente.redSocial.twitter}}   target="_blank" *ngIf="settings.cliente.redSocial.twitter">  <i class="fa fa-twitter-square transition" aria-hidden="true"></i></a>
         <a href={{settings.cliente.redSocialinstagram}}  target="_blank" *ngIf="settings.cliente.redSocial.instagram"><i class="fa fa-instagram transition" aria-hidden="true"></i></a>
         <a href={{settings.cliente.redSocial.pinterest}} target="_blank" *ngIf="settings.cliente.redSocial.pinterest"><i class="fa fa-pinterest-square transition" aria-hidden="true"></i></a>             
    </span>
    <span class="copyright">Copyright © {{settings.cliente.copyrightYear}} All Rights Reserved by {{settings.cliente.nombre}}</span>    
    <span class="copyright">version: {{version}}</span>    
</div>