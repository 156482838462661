export function getListadoPeriodos(desde:string, hasta:string, formato?:string, valorNull?:string):any[] {
	let listado=[];
	if(formato===undefined) {
		formato='AAAAMM';
	}	
	if(valorNull!==undefined && valorNull!=null) {
		listado.push({ 
			key    : null,
			nombre : valorNull
		});	  						 
	}	
	let anioDesde = parseInt(desde.substr(0,4));
	let mesDesde  = parseInt(desde.substr(5,2));
	let anioHasta = parseInt(hasta.substr(0,4));
	let mesHasta  = parseInt(hasta.substr(5,2));
	
	if(hasta.includes('NOW()')) {
		var fechaHora = new Date();
		anioHasta = fechaHora.getFullYear();
		mesHasta  = fechaHora.getMonth()+1;
	}
	
	for(let anio=anioHasta; anio>=anioDesde; anio--) {
		let mesInicial = (anio==anioDesde) ? mesDesde : 1;
		let mesFinal   = (anio==anioHasta) ? mesHasta : 12;
		
		for(let mes=mesFinal; mes>=mesInicial; mes--) {
				let mesString = (mes<12) ? '0'+mes.toString() : mes.toString(); 
				let key;
				if(formato=='AAAAMM') {
					key = anio.toString()+mesString;
				} else {
					key = anio.toString()+'-'+mesString;
				}
				listado.push({ 
					key    : key, 
					nombre : mesString+'-'+anio.toString()
				});	  						 
		}	
	}
	return listado;
};
 
export function getListadoFechas(desde:string, hasta:string, valorNull?:string):any[] {
	let listado=[];
	if(valorNull!==undefined && valorNull!=null) {
		listado.push({ 
			key    : null,
			nombre : valorNull
		});	  						 
	}	
	let anioDesde = parseInt(desde.substr(0,4));
	let mesDesde  = parseInt(desde.substr(5,2));
	let anioHasta = parseInt(hasta.substr(0,4));
	let mesHasta  = parseInt(hasta.substr(5,2));
	
	if(hasta.includes('NOW()')) {
		var fechaHora = new Date();
		anioHasta = fechaHora.getFullYear();
		mesHasta  = fechaHora.getMonth()+1;
	}
	
	for(let anio=anioHasta; anio>=anioDesde; anio--) {
		let mesInicial = (anio==anioDesde) ? mesDesde : 1;
		let mesFinal   = (anio==anioHasta) ? mesHasta : 12;
		
		for(let mes=mesFinal; mes>=mesInicial; mes--) {
				let mesString = (mes<12) ? '0'+mes.toString() : mes.toString(); 
				
				for(let dia=31; dia>=1; dia--) {
					let diaString = (dia<12) ? '0'+dia.toString() : dia.toString(); 
					let fecha = anio.toString()+'-'+mesString+'-'+diaString;
					let fechaDate = new Date(fecha);
					// console.log("fechaDate fecha:",fecha); 					
					// console.log("fechaDate convertida:",fechaDate); 					
					listado.push({ 
						key    : anio.toString()+'-'+mesString+'-'+diaString, 
						nombre : diaString+'-'+mesString+'-'+anio.toString()
					});	  						 
				}
		}	
	}
	return listado;
};

export const LISTA_DE_TIPOS_FOTOS:any[]=[
	{  key:'foto',  				nombre: 'claseFicha.foto' },      
	{  key:'fotoPanoramica',  	nombre: 'claseFicha.fotoPanoramica' },      
];

export const LISTA_DE_TIPOS_OPCION_MENU:any[]=[
      {  key:null,       nombre: '' },      
      {  key:'subMenu',  nombre: 'moduloDesarrolladores.subMenu' },      
      {  key:'path',  	 nombre: 'moduloDesarrolladores.path' },      
      {  key:'link',  	 nombre: 'moduloDesarrolladores.linkExterno' },      
];

export function getListadoOrden(numeroDesde:number, numeroHasta:number, 
								incluyeNoIndicado:boolean):any {
	
  let listado=[];	

  if(incluyeNoIndicado) {
	  listado.push({ 
			key    : null, 
			nombre : " No Indicado"
	  });	
  }	  

  for(let i=numeroDesde; i<=numeroHasta; i++) {
  	listado.push({ 
  		key    : i, 
  		nombre : i.toString()
  	});	  		
  }
  return listado;
};

export const LISTA_DE_APLICACIONES: any[]=[
      {id:  null,               nombre: ' No Indicado',        },
      {id: 'pkMobilityWeb',     nombre: 'PK Mobility Web',     },
      {id: 'pkMobilityApp',     nombre: 'PK Mobility APP',     },
      {id: 'nutralmixWeb',      nombre: 'NutralMix Web',     	},
      {id: 'nutrlamixApp',      nombre: 'NutralMix APP',   	},
      {id: 'planesahorroWeb',   nombre: 'Planes de Ahorro Web',},
      {id: 'planesahorroApp',   nombre: 'Planes de Ahorro APP',},
];

export function getAplicacion(id:string, cual:string):string {
  for(let i=0; i<LISTA_DE_APLICACIONES.length; i++) {
    if(LISTA_DE_APLICACIONES[i].id==id) {
      if(cual=='nombre') {  
        return LISTA_DE_APLICACIONES[i].val;
      }  
    }
  }
  return '';
};

export function getListadoSiNo(valorNull?:string):any[] {	
	let LISTA_DE_FILTROS_SI_NO:any[]=[
      {id:  null,  val: 'No Indicado', logic:null },
      {id: 'si', 	 val:'Sí',  logic:true },
      {id: 'no',   val:'No',  logic:false },
	];	
	if(valorNull!==undefined) {
		LISTA_DE_FILTROS_SI_NO[0].val=valorNull;
	}
	return LISTA_DE_FILTROS_SI_NO;
};
 
export const LISTA_DE_FILTROS_SI_NO:any[]=[
      {id:  null,  val: 'No Indicado', logic:null },
      {id: 'si', 	 val:'Sí',  logic:true },
      {id: 'no',   val:'No',  logic:false },
];

export const LISTA_DE_FORM_SI_NO:any[]=[
	{id: 'si', 	 val:'Sí',  logic:true },
	{id: 'no',   val:'No',  logic:false },
];

export const LISTA_DE_GENEROS:any[]=[
		{id: null,     val:'No Indicado' },
      {id: 'male',   val:'Masculino' },
      {id: 'female', val:'Femenino' },
];

export const LISTA_DE_VENTANAS_ATENCION:any[]=[
      { key: null,   
      	nombre: 'Personalizada',   
        codigoExterno:'CUSTOM',
        tipoCuentaKN:'xxx',
        horarioAtencion:{
		  lunes: {
		      horaDesde1: null,
		      horaHasta1: null,
		      horaDesde2: null,
		      horaHasta2: null,
		      horaDesde3: null,
		      horaHasta3: null,
		  },
		  martes:{
		      horaDesde1: null,
		      horaHasta1: null,
		      horaDesde2: null,
		      horaHasta2: null,
		      horaDesde3: null,
		      horaHasta3: null,
		  },
		  miercoles:{
		      horaDesde1: null,
		      horaHasta1: null,
		      horaDesde2: null,
		      horaHasta2: null,
		      horaDesde3: null,
		      horaHasta3: null,
		  },
		  jueves:{
		      horaDesde1: null,
		      horaHasta1: null,
		      horaDesde2: null,
		      horaHasta2: null,
		      horaDesde3: null,
		      horaHasta3: null,
		  },
		  viernes:{
		      horaDesde1: null,
		      horaHasta1: null,
		      horaDesde2: null,
		      horaHasta2: null,
		      horaDesde3: null,
		      horaHasta3: null,
		  },
		  sabado:{
		      horaDesde1: null,
		      horaHasta1: null,
		      horaDesde2: null,
		      horaHasta2: null,
		      horaDesde3: null,
		      horaHasta3: null,
		  },
		  domingo:{
		      horaDesde1: null,
		      horaHasta1: null,
		      horaDesde2: null,
		      horaHasta2: null,
		      horaDesde3: null,
		      horaHasta3: null,
		  },
		  visperaFeriado:{
		      horaDesde1: null,
		      horaHasta1: null,
		      horaDesde2: null,
		      horaHasta2: null,
		      horaDesde3: null,
		      horaHasta3: null,
		  },
		  feriado:{
		      horaDesde1: null,
		      horaHasta1: null,
		      horaDesde2: null,
		      horaHasta2: null,
		      horaDesde3: null,
		      horaHasta3: null,
		  }
       } 
  	  },

      { key: 'ventana1',   
      	nombre: 'Supermercado1',   
        codigoExterno:'SUPER',
        tipoCuentaKN:'xxx',
        horarioAtencion:{
		  lunes: {
		      horaDesde1:{
				hour: 8,
				minute: 0,
				second: 0,
			  },	
		      horaHasta1:{
				hour: 12,
				minute: 0,
				second: 0,
			  },	
		      horaDesde2:{
				hour: 8,
				minute: 0,
				second: 0,
			  },
		      horaHasta2:{
				hour: 12,
				minute: 0,
				second: 0,
			  },
		      horaDesde3: null,
		      horaHasta3: null,
		  },
		  martes:{
		      horaDesde1:{
				hour: 8,
				minute: 0,
				second: 0,
			  },	
		      horaHasta1:{
				hour: 12,
				minute: 0,
				second: 0,
			  },	
		      horaDesde2:{
				hour: 8,
				minute: 0,
				second: 0,
			  },
		      horaHasta2:{
				hour: 8,
				minute: 0,
				second: 0,
			  },
		      horaDesde3: null,
		      horaHasta3: null,
		  },
		  miercoles:{
		      horaDesde1:{
				hour: 8,
				minute: 0,
				second: 0,
			  },	
		      horaHasta1:{
				hour: 12,
				minute: 0,
				second: 0,
			  },	
		      horaDesde2:{
				hour: 8,
				minute: 0,
				second: 0,
			  },
		      horaHasta2:{
				hour: 12,
				minute: 0,
				second: 0,
			  },
		      horaDesde3: null,
		      horaHasta3: null,
		  },
		  jueves:{
		      horaDesde1:{
				hour: 8,
				minute: 0,
				second: 0,
			  },	
		      horaHasta1:{
				hour: 12,
				minute: 0,
				second: 0,
			  },	
		      horaDesde2:{
				hour: 8,
				minute: 0,
				second: 0,
			  },
		      horaHasta2:{
				hour: 12,
				minute: 0,
				second: 0,
			  },
		      horaDesde3: null,
		      horaHasta3: null,
		  },
		  viernes:{
		      horaDesde1:{
				hour: 8,
				minute: 0,
				second: 0,
			  },	
		      horaHasta1:{
				hour: 12,
				minute: 0,
				second: 0,
			  },	
		      horaDesde2:{
				hour: 8,
				minute: 0,
				second: 0,
			  },
		      horaHasta2:{
				hour: 12,
				minute: 0,
				second: 0,
			  },
		      horaDesde3: null,
		      horaHasta3: null,
		  },
		  sabado:{
		      horaDesde1:{
				hour: 8,
				minute: 0,
				second: 0,
			  },	
		      horaHasta1:{
				hour: 12,
				minute: 0,
				second: 0,
			  },	
		      horaDesde2:null,
		      horaHasta2:null,
		      horaDesde3: null,
		      horaHasta3: null,
		  },
		  domingo:{
		      horaDesde1: null,
		      horaHasta1: null,
		      horaDesde2: null,
		      horaHasta2: null,
		      horaDesde3: null,
		      horaHasta3: null,
		  },
		  visperaFeriado:{
		      horaDesde1:{
				hour: 8,
				minute: 0,
				second: 0,
			  },	
		      horaHasta1:{
				hour: 12,
				minute: 0,
				second: 0,
			  },	
		      horaDesde2:{
				hour: 8,
				minute: 0,
				second: 0,
			  },
		      horaHasta2:{
				hour: 12,
				minute: 0,
				second: 0,
			  },
		      horaDesde3: null,
		      horaHasta3: null,
		  },
		  feriado:{
		      horaDesde1: null,
		      horaHasta1: null,
		      horaDesde2: null,
		      horaHasta2: null,
		      horaDesde3: null,
		      horaHasta3: null,
		  }
       } 
  	  },

      { key: 'ventana2',   
      	nombre: 'Restaurante',   
        codigoExterno:'RESTAURANT',
        tipoCuentaKN:'xxx',
        horarioAtencion:{
		  lunes: {
		      horaDesde1:{
				hour: 18,
				minute: 0,
				second: 0,
			  },	
		      horaHasta1:{
				hour: 23,
				minute: 59,
				second: 0,
			  },	
		      horaDesde2:{
				hour: 0,
				minute: 0,
				second: 0,
			  },
		      horaHasta2:{
				hour: 2,
				minute: 0,
				second: 0,
			  },	
		      horaDesde3: null,
		      horaHasta3: null,
		  },
		  martes:{
		      horaDesde1:{
				hour: 18,
				minute: 0,
				second: 0,
			  },	
		      horaHasta1:{
				hour: 23,
				minute: 59,
				second: 0,
			  },	
		      horaDesde2:{
				hour: 0,
				minute: 0,
				second: 0,
			  },
		      horaHasta2:{
				hour: 2,
				minute: 0,
				second: 0,
			  },	
		      horaDesde3: null,
		      horaHasta3: null,
		  },
		  miercoles:{
		      horaDesde1:{
				hour: 18,
				minute: 0,
				second: 0,
			  },	
		      horaHasta1:{
				hour: 23,
				minute: 59,
				second: 0,
			  },	
		      horaDesde2:{
				hour: 0,
				minute: 0,
				second: 0,
			  },
		      horaHasta2:{
				hour: 2,
				minute: 0,
				second: 0,
			  },	
		      horaDesde3: null,
		      horaHasta3: null,
		  },
		  jueves:{
		      horaDesde1:{
				hour: 18,
				minute: 0,
				second: 0,
			  },	
		      horaHasta1:{
				hour: 23,
				minute: 59,
				second: 0,
			  },	
		      horaDesde2:{
				hour: 0,
				minute: 0,
				second: 0,
			  },
		      horaHasta2:{
				hour: 2,
				minute: 0,
				second: 0,
			  },	
		      horaDesde3: null,
		      horaHasta3: null,
		  },
		  viernes:{
		      horaDesde1:{
				hour: 18,
				minute: 0,
				second: 0,
			  },	
		      horaHasta1:{
				hour: 23,
				minute: 59,
				second: 0,
			  },	
		      horaDesde2:{
				hour: 0,
				minute: 0,
				second: 0,
			  },
		      horaHasta2:{
				hour: 2,
				minute: 0,
				second: 0,
			  },	
		      horaDesde3: null,
		      horaHasta3: null,
		  },
		  sabado:{
		      horaDesde1:{
				hour: 18,
				minute: 0,
				second: 0,
			  },	
		      horaHasta1:{
				hour: 23,
				minute: 59,
				second: 0,
			  },	
		      horaDesde2:{
				hour: 0,
				minute: 0,
				second: 0,
			  },
		      horaHasta2:{
				hour: 3,
				minute: 0,
				second: 0,
			  },	
		      horaDesde3: null,
		      horaHasta3: null,
		  },
		  domingo:{
		      horaDesde1:{
				hour: 18,
				minute: 0,
				second: 0,
			  },	
		      horaHasta1:{
				hour: 23,
				minute: 59,
				second: 0,
			  },	
		      horaDesde2: null,
		      horaHasta2: null,
		      horaDesde3: null,
		      horaHasta3: null,
		  },
		  visperaFeriado:{
		      horaDesde1:{
				hour: 18,
				minute: 0,
				second: 0,
			  },	
		      horaHasta1:{
				hour: 23,
				minute: 59,
				second: 0,
			  },	
		      horaDesde2:{
				hour: 0,
				minute: 0,
				second: 0,
			  },
		      horaHasta2:{
				hour: 3,
				minute: 0,
				second: 0,
			  },	
		      horaDesde3: null,
		      horaHasta3: null,
		  },
		  feriado:{
		      horaDesde1: null,
		      horaHasta1: null,
		      horaDesde2: null,
		      horaHasta2: null,
		      horaDesde3: null,
		      horaHasta3: null,
		  }
       } 
  	  },
];

