import { log, logIf, logTable, values } from '@maq-console';
import { reemplazaAcentos } from '@maq-helpers/stringsHelp';
import { getFormKeys } from '@maq-helpers/formHelp';
import { copiarArray } from '@maq-helpers/arrayHelp';


import { getBDModel, updateColeccionModel, postGETProcesaListadoModel, updateDocumentoModel,updateColeccionDataBatch,updateColeccionUserKeysBatch } from '@maq-models/bd/bd.model';
import { getBDModel2, getBDSQL, updateColeccion2Model} from '@maq-models/bd/bd.model';
import { WhereInterface , OPERADOR_ORDEN_BY,OrdeByInterface, OPERADOR_WHERE, TIPO_BASE_DATOS,}  from '@maq-models/bd/bdDefinicionesGenerales.model';
import { TIPO_PERFIL,PERFIL_DESARROLLADOR_SUPERV_DISTRIBUIDOR}  from '@maq-mocks/perfiles/perfiles';
import { getFiltroDateSQL } from '@settings/maqueta/helpers/fechaHoraHelp';
import { FORMATO } from '@settings/proyecto/mocks/columnaReporte/columnaReporte.mocks';

export class GrillaBase {

  paginadoTipo            : GRILLA_PAGINADO_TIPO   = GRILLA_PAGINADO_TIPO.SERVIDOR;   // local / servidor
  itemsPorPagina          : number   = 20;
  paginadoAutoHide        : boolean  = true;
  paginadoDropDown        : number[] = [1,2,5,10,20,30,50,100];

  paginaRequerida         : GRILLA_PAGINA_REQUERIDA   = GRILLA_PAGINA_REQUERIDA.TODAS;  // 'primera'|'siguiente'|GRILLA_PAGINA_REQUERIDA.ANTERIOR|'anteriorOverflow' (paginadoTipo = 'servidor'), 'todas' (local)
  paginaRequeridaAnterior : GRILLA_PAGINA_REQUERIDA | null  = null;     // Se compara con [paginaRequerida] para calcular [paginaActualNro]
  paginaActualNro         : number | null  = null;        // nro de pagina actual
  paginaActualNroAnterior : number | null  = null;     // nro de pagina actual
  paginaOverflow          : boolean  = false;    // Se pone en true, cuando te pasas de la última página con resultados
  
  resultKey1              : string | null  = null; // Se usa para Firestore
  resultKeyN              : string | null  = null; // Se usa para Firestore
  resultCantidad          : number | null  = null;
  coleccionCantidadDocs   : number | null  = null;

  itemSeleccionadoParaOrdenar              : string   = ''; //
  //ok
  orderReverse            : boolean  = false;
  orderServer             : string[] = [];  // Columnas por las cuales está disponible el ordenamiento

  
  filtros                 : any[]    = [];  // Array de ngModel del HTML. Ejemplo: [(ngModel)]="grilla.filtros['nombre']"
  
    
  
  whereArray              : WhereInterface[]    = [];  // Cada elemento tiene la estructura { key: xxx, operador: xxx, value: xxx } - Filtros iniciales + seteados en los filtros del cabezal de la grilla
  
    

  verColumnaOculta        : boolean = false;  //variable diponible para configurar Tabla, solo se guarda y lee el valor desde el html
  verFiltrosAdicionales   : boolean = false;  //variable diponible para configurar Tabla, solo se guarda y lee el valor desde el html



  keyHoverActivo           : string | null  = null;   // guarda un id de la fila de la tabla para marcar clase hover o no

  tipoPerfilUsuario       : TIPO_PERFIL  = TIPO_PERFIL.Desarrollador;

  constructor(init?:Partial<GrillaBase>) {

    Object.assign(this, init);

    log(...values('funcionComponente','constructor grilla'));

  }
  
  
  setValue( init?:Partial<GrillaBase>) {    
      // console.log("grilla.setValue",nombreColeccion);
      Object.assign(this, init);
  }

  colspanPaginado(cantCampos: number, cual:string):number {

    let izquierda = Math.trunc(cantCampos / 2);
    let derecha   = Math.trunc(cantCampos / 2);

    let mitad = cantCampos / 2;
    if(mitad != Math.trunc(cantCampos / 2)) {

      izquierda = Math.trunc(cantCampos / 2);
      derecha   = Math.trunc(cantCampos / 2)+1;
    }
    //console.log("colspanPaginado",cantCampos, izquierda, derecha);

    if(cual=='izquierda') {
      return izquierda;
    } else {
      return derecha;
    }

  }

  setColeccionCantidadDocs(cantidadDocumentosFisicos: number,
                           cantidadDocumentosLogicos:number, tipoPerfilUsuario:TIPO_PERFIL) {
      log(...values("valores","setColeccionCantidadDocs:",arguments));
      if(this.paginadoTipo == 'servidor') {
          if([TIPO_PERFIL.Desarrollador].indexOf(this.tipoPerfilUsuario)!=-1) {
              this.coleccionCantidadDocs = cantidadDocumentosFisicos;
          } else {
              this.coleccionCantidadDocs = cantidadDocumentosLogicos;
          }
      }
  }

  mostrarFiltro(cual:string) {
    if(this.paginadoTipo == GRILLA_PAGINADO_TIPO.LOCAL) {
      return true;
    } else if(this.filtros.indexOf(cual)!=-1) {
      return true;
    } else {
      return false;
    }
  }


  //ok
  setOrder(itemSeleccionadoParaOrdenar: string) {
    if (this.itemSeleccionadoParaOrdenar === itemSeleccionadoParaOrdenar) {
      this.orderReverse = !this.orderReverse;
    }
    this.itemSeleccionadoParaOrdenar = itemSeleccionadoParaOrdenar;
  }

  resetOrden(){
    this.itemSeleccionadoParaOrdenar='';
  }  
  //ok
  isOrderReverse():boolean{
    return this.orderReverse==true
  }

  

  //ok
  colorFilaSegunEstadoSettings(settings:any):any {
      //console.log("colorFilaSegunEstado", settings);
      if(settings==null || settings===undefined) {
        return {};
      } else if(settings.isBorrado) {
        return { 'color':'red' };
      } else if(settings.isActivo==false) {
        return { 'color':'#ccc' };
      } else {
        return {};
      }
    }

    // imporar como funcion de fn.funciones (no como servicio)
    ordenarXAtributo(key, order = 'asc', translateValue) {
      /*
       * Sirve para Ordena un Array de Objetos
       *
       * Ejemplo
       * this.listadoDistribuidores.sort(this.fn.ordenarXAtributo(orderBy[0].key, orderBy[0].ascDesc,false));
       *
       * const LISTADO_DE_PAISES = [
       *   {id:'Argentina', val:'Argentina',  'husosHorarios': ['UTC-3'] },
       *   {id:'Chile',     val:'Chile',      'husosHorarios': ['UTC-3','UTC-4'] },
       *   {id:'Brasil',    val:'Brasil',     'husosHorarios': ['UTC-3','UTC-4'] },
       *   {id:'Colombia',  val:'Colombia',   'husosHorarios': ['UTC-5'] },
       * ];
       *
       * LISTADO_DE_PAISES.sort(ordenarXAtributo('id'));
       * LISTADO_DE_PAISES.sort(ordenarXAtributo('val'));
       * LISTADO_DE_PAISES.sort(ordenarXAtributo('val', 'desc'));
       */

      return function innerSort(a, b) {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
          // property doesn't exist on either object
          return 0;
        }

        if(translateValue) {
          // console.log("translate a[key]",a[key]);
          // console.log("translate b[key]",b[key]);
          var varA = (typeof a[key] === 'string') ? this.translate.instant(a[key]) : a[key];
          var varB = (typeof b[key] === 'string') ? this.translate.instant(b[key]) : b[key];
        } else {
          var varA = (typeof a[key] === 'string') ? a[key].toUpperCase() : a[key];
          var varB = (typeof b[key] === 'string') ? b[key].toUpperCase() : b[key];
        }

        let comparison = 0;
        if (varA > varB) {
          comparison = 1;
        } else if (varA < varB) {
          comparison = -1;
        }
        return (
          (order === 'desc') ? (comparison * -1) : comparison
        );
      };
    }

    configGrilla(valores:any) {
      //   console.log("setGrilla",valores);

      let objValores = {};
      if(valores.paginadoTipo!==undefined)              objValores['paginadoTipo']              = valores.paginadoTipo;
      if(valores.itemSeleccionadoParaOrdenar!==undefined)    objValores['itemSeleccionadoParaOrdenar'] = valores.itemSeleccionadoParaOrdenar;
      if(valores.orderReverse!==undefined)              objValores['orderReverse']              = valores.orderReverse;
      if(valores.orderServer!==undefined)               objValores['orderServer']               = valores.orderServer;
      if(valores.itemsPorPagina!==undefined)            objValores['itemsPorPagina']            = valores.itemsPorPagina;
      if(valores.paginadoAutoHide!==undefined)          objValores['paginadoAutoHide']          = valores.paginadoAutoHide;
       if(valores.paginaActualNro!==undefined)          objValores['paginaActualNro']           = valores.paginaActualNro;
      

      if(valores.paginadoTipo==GRILLA_PAGINADO_TIPO.LOCAL) {
          objValores['paginaRequerida'] = GRILLA_PAGINA_REQUERIDA.TODAS;
          
      } else {
          objValores['paginaRequerida'] = GRILLA_PAGINA_REQUERIDA.PRIMERA;
      }    
      
      //this.grilla = new Grilla(objValores);
      this.setValue( objValores);
  

    }

    setGrillaFiltosFromForm(form:any):void{
      console.log('setGrillaFiltosFromForm form',form);
      console.log('setGrillaFiltosFromForm this.filtros',this.filtros);
      this.filtros=getFormKeys(form);
      console.log('setGrillaFiltosFromForm this.filtros',this.filtros);
      this.resetFiltros();
    
    } 
  
  


  hasPaginadoLocal():Boolean{
    return this.paginadoTipo ==GRILLA_PAGINADO_TIPO.LOCAL
  }
  


  getPaginadoFirebase():PaginadoFirebase  {
    return {
    paginaRequerida : this.paginaRequerida,
    resultKey1      : this.resultKey1,
    resultKeyN      : this.resultKeyN 
  }
}
  ordenAscDesc():string{
    return this.orderReverse == true ? 'desc' : 'asc'
  }

  hasOrdenPorEsteCampo(value:string):boolean{
    return this.orderServer.indexOf(value)!=-1
  }



  
  
  isPaginadoServidor():boolean{
    return this.paginadoTipo==GRILLA_PAGINADO_TIPO.SERVIDOR;
  }

  isPaginadoLocal():boolean{
    return this.paginadoTipo==GRILLA_PAGINADO_TIPO.LOCAL;
  }

  requierePaginaAnterior():Boolean{
    return this.paginaRequerida==GRILLA_PAGINA_REQUERIDA.ANTERIOR
  }

  actualizarNroPaginaActual():void{
      if(this.paginaRequerida==this.paginaRequeridaAnterior) {
         if(this.paginaRequerida==GRILLA_PAGINA_REQUERIDA.SIGUIENTE) {
              this.paginaActualNro++;
          } else {
              this.paginaActualNro--;
          }
      } else {
          if(this.paginaRequerida==GRILLA_PAGINA_REQUERIDA.PRIMERA)             this.paginaActualNro = 1;
          if(this.paginaRequerida==GRILLA_PAGINA_REQUERIDA.SIGUIENTE)           this.paginaActualNro++;
          if(this.paginaRequerida==GRILLA_PAGINA_REQUERIDA.ANTERIOR)            this.paginaActualNro--;
          if(this.paginaRequerida==GRILLA_PAGINA_REQUERIDA.ANTERIOR_OVER_FLOW)  this.paginaActualNro--;
      }
      if(this.paginaRequerida==GRILLA_PAGINA_REQUERIDA.PRIMERA) {
         this.paginaRequeridaAnterior = GRILLA_PAGINA_REQUERIDA.SIGUIENTE;
      } else {
         this.paginaRequeridaAnterior = this.paginaRequerida;
      }
  }

  setResultCantidad(resultCantidad:number){
    this.resultCantidad=resultCantidad
  }

  setResultKey1_KeyN(listado,fn){
      // Actualizo resultKey1, resultKeyN
      if(this.paginaActualNro>1 && this.resultCantidad==0) {
          // Avanzó luego de la última página con resultados y no le trajo nada obviamente
          this.paginaOverflow = true;
          this.resultKey1     = this.resultKey1;
          this.resultKeyN     = null;
      } else {
          this.paginaOverflow = false;
          this.resultKey1     = null;
          this.resultKeyN     = null;
          for(let i=0; i<listado.length; i++) {
             let documento=listado[i];
            //  if(i==0)                this.grilla.resultKey1 = documento[this.grilla.itemSeleccionadoParaOrdenar];
            //  if(i==listado.length-1) this.grilla.resultKeyN = documento[this.grilla.itemSeleccionadoParaOrdenar];

             if(i==0)                this.resultKey1 = fn.getDocField(documento,this.itemSeleccionadoParaOrdenar);
             if(i==listado.length-1) this.resultKeyN = fn.getDocField(documento,this.itemSeleccionadoParaOrdenar);
             
          }
        }       
  }

  setPaginaRequerida(paginaRequerida:GRILLA_PAGINA_REQUERIDA):void{
    this.paginaRequerida=paginaRequerida;
  }

  itemSeleccionadoParaOrdenarIncluyeHechaHora():boolean{
    return this.itemSeleccionadoParaOrdenar.includes('fechaHora')
  }
  
  setWhereArray(hasKeyWords:Boolean,campoModeloUsadoEnKeywords:string):void{
            
            // 1) Recorro todos los nombres de los filtros definidos en this.filtros
            // 2) busco los valores que se han escrito en this.filtros[ nombreFiltro ] (nombre anterior)
            // 3) determino el tipo de operador que se usa en funcion del tipo de dato
            // 4) los asigno a this.whereArray[]

            this.whereArray=[];

            for(let i=0; i< this.filtros.length; i++) {
                let nombreFiltro = this.filtros[i];
                let valueFiltro  = this.filtros[ nombreFiltro ];
            
                // Quito estilo resaltado a un grupo que haya dado error en ejecución previa

                
                // Me fijo si el input/select de la grilla tiene un valor asignado (null o undefined significa que no fue seleccionado)
                //console.log("typeof valueFiltro",nombreFiltro, valueFiltro, typeof valueFiltro);
                if(valueFiltro!==undefined && valueFiltro!=null && (typeof valueFiltro!=='string' || valueFiltro!='')) {
                    
                    let keyWhere = nombreFiltro
                    let operador;
                    let valueWhere = valueFiltro;
                    

                    if(nombreFiltro == campoModeloUsadoEnKeywords) {
                        console.log('test valueWhere ',valueWhere);
                        console.log('test typeof valueWhere ',typeof valueWhere);
                        if(!hasKeyWords) {
                          /* revisar Juan!!!!!!!*/ 
                            // if(this.isCampoDecimalEsteCampo(keyWhere)){
                            //     valueWhere= parseInt(valueWhere);
                            // }
                            operador = OPERADOR_WHERE.Igual;
                        } else {
                            keyWhere = 'keywords';
                            operador = OPERADOR_WHERE.Contiene;  
                            
                            valueWhere = reemplazaAcentos(valueWhere);
                            // valueWhere = this.fn.quitaCaracteresEspeciales(valueWhere);
                            valueWhere = valueWhere.toLowerCase();                                      
                        }
                    } else if(nombreFiltro.includes('ARRAY')) {
                            operador = OPERADOR_WHERE.Contiene ;  
    
                    } else if(typeof valueFiltro==='string') {
                        operador = OPERADOR_WHERE.Igual;
                        
                    } else if(typeof valueFiltro==='object') {
                        console.log('zzzz in setWhereArray valueFiltro desde ',valueFiltro);
                        let fechaDesde = valueFiltro.desde.substr(6,4)+'-'+valueFiltro.desde.substr(3,2)+'-'+valueFiltro.desde.substr(0,2)+ ' 00:00:00';
                        operador = OPERADOR_WHERE.MayorIgual;
                        valueWhere = new Date(fechaDesde);
                    } else {
                        operador = OPERADOR_WHERE.Igual;                    
                    }
                    
                    let newWhere:WhereInterface = { 
                        key      : keyWhere, 
                        operador : operador, 
                        value    : valueWhere 
                    }
                    
                    this.whereArray.push(newWhere);
                   
                    
                    if(operador==OPERADOR_WHERE.MayorIgual) {
                        let newWhere2:WhereInterface
                        if(typeof valueFiltro==='object') {     
                          console.log('zzzz in setWhereArray valueFiltro hasta ',valueFiltro);
               
                            let fechaHasta = valueFiltro.hasta.substr(6,4)+'-'+valueFiltro.hasta.substr(3,2)+'-'+valueFiltro.hasta.substr(0,2) + ' 23:59:59';
                            newWhere2 = { 
                                key      : nombreFiltro, 
                                operador : OPERADOR_WHERE.MenorIgual, 
                                value    : new Date(fechaHasta)
                            }
                        } else {
                            newWhere2 = { 
                                key: nombreFiltro, 
                                operador: OPERADOR_WHERE.MenorIgual, 
                                value: valueFiltro+'zzzzzz' 
                            }                                                
                        }    
                        this.whereArray.push(newWhere2);
                        
                    }      
                              
                } // fin for      
                
            }
  }

  getGrillaOrden():OrdeByInterface[]{

    let orderArray:OrdeByInterface[]=[];
    if(this.itemSeleccionadoParaOrdenar!=''){
      let  ascDesc= this.isOrderReverse() ? OPERADOR_ORDEN_BY.Descendente : OPERADOR_ORDEN_BY.Ascendente;
      let  key = this.itemSeleccionadoParaOrdenar;
      let orderBy:OrdeByInterface={key:key,ascDesc:ascDesc};
      // orderArray=[orderBy];
      orderArray.push(orderBy);

      //todo revisar cuando el filtro es de fecha/hora.
    }
    
    return orderArray;

  }

  onChangeGrillaOrdenServidor(itemSeleccionadoParaOrdenar:string,hasTipoBaseDatosListdoPrincipalSQL:boolean){
    this.setOrder(itemSeleccionadoParaOrdenar);
    if(hasTipoBaseDatosListdoPrincipalSQL) {
            this.paginaActualNro = 1;
    } else {
        this.paginaRequerida = GRILLA_PAGINA_REQUERIDA.PRIMERA;
    }      
  }

  //el formato de la fecha me determina que le paso a la url cuando uso el where. 
  // el active activeLangServidor por ahora no tiene mucho sentido, tendriamos que saber la cual es el formato de fecha donde esta alojado el servidor para darle utilidad.
  filtrarGrillaSQL(formatoFecha?:FORMATO, activeLang?:string, timezoneInNumber?:number){ 
      
    console.log("this.grilla.filtros",this.filtros);
    //console.log("this.grilla.whereOriginal",this.grilla.whereOriginal);
    
    this.whereArray = [];
    
    // Recorro todos los filtros definidos 
 
    for(let i=0; i< this.filtros.length; i++) {
        let nombreFiltro = this.filtros[i];
        let valueFiltro  = this.filtros[ nombreFiltro ];
    
        // Quito estilo resaltado a un grupo que haya dado error en ejecución previa

        
        // Me fijo si el input/select de la grilla tiene un valor asignado (null o undefined significa que no fue seleccionado)
        //console.log("typeof valueFiltro",nombreFiltro, valueFiltro, typeof valueFiltro);
        if(valueFiltro!==undefined && valueFiltro!=null && (typeof valueFiltro!=='string' || valueFiltro!='')) {
            
            let keyWhere:string = nombreFiltro
            let operador;
            let valueWhere  = null;
            if (typeof valueFiltro!== 'object') valueWhere  = valueFiltro;

            let newWhere:any=null;

            let valueWhereFechaDesde :string | Date = null;
            let valueWhereFechaHasta :string | Date = null;
            let newWhereFechaHasta : WhereInterface = null;
            let newWhereFechaDesde : WhereInterface = null;
            
            //console.log("nombreFiltro,valueFiltro",nombreFiltro,valueFiltro,typeof valueFiltro);

            if(typeof valueFiltro==='string') {
                operador = OPERADOR_WHERE.Contiene;    
            } else if(typeof valueFiltro==='object') {
              //CREO UNA COPIA DEL OBJETO PARA QUE NO MODIFIQUE EL VALOR DE ORIGINAL de this.filtros[ nombreFiltro ]
              let valueFiltroCopia = Object.assign({}, valueFiltro);
              let objectFiltroDesdeHasta:object = getFiltroDateSQL(valueFiltroCopia, formatoFecha, activeLang,timezoneInNumber);
              valueWhereFechaDesde = objectFiltroDesdeHasta['desde'];
              valueWhereFechaHasta = objectFiltroDesdeHasta['hasta'];
            } else {
                operador = OPERADOR_WHERE.Igual;                    
            }
            
            if (valueWhere != null && valueWhere != undefined){
              newWhere = { 
                key      : keyWhere, 
                operador : operador, 
                value    : valueWhere 
              }
              this.whereArray.push(newWhere);
            }

            if(valueWhereFechaDesde) {
              newWhereFechaDesde = { 
                    key      : keyWhere, 
                    operador : OPERADOR_WHERE.MayorIgual, 
                    value    : valueWhereFechaDesde 
                }
              this.whereArray.push(newWhereFechaDesde);
            }

            if(valueWhereFechaHasta) {
              newWhereFechaHasta = { 
                    key      : keyWhere, 
                    operador : OPERADOR_WHERE.MenorIgual, 
                    value    : valueWhereFechaHasta 
                }
              this.whereArray.push(newWhereFechaHasta);
            }
    
        } // fin for      
        
    }
    
    this.paginaActualNro = 1;
    log(...values("valores","this.grilla:",this));

  }  

  resetFiltros(vecFiltrosNoResetar?:string[]){
      // Inicializo Filtros
      for(let i=0; i<this.filtros.length; i++) {
        let nombreFiltro=this.filtros[i];
        
        if(vecFiltrosNoResetar===undefined) {
            vecFiltrosNoResetar=[];
        }
        
        if(vecFiltrosNoResetar.indexOf( nombreFiltro )==-1) {
  
          this.filtros[nombreFiltro] = null;
        }    
      } 
  }
  
  


 
}
export interface PaginadoFirebase{
  paginaRequerida : String,
  resultKey1      : string,
  resultKeyN      : string,
}
export interface ItemsAndCurrentPage{
  paginate:{
    itemsPerPage    : number, 
    currentPage     : number 
  }
}

export enum ONCHANGE_GRILLA{
  ORDER               = "order",
  PAGINADO_CANTIDAD   = "paginadoCantidad",
}
export enum GRILLA_PAGINADO_TIPO{
  LOCAL       = 'local',
  SERVIDOR    = 'servidor',
}


export enum GRILLA_PAGINA_REQUERIDA{
  PRIMERA               = 'primera',
  SIGUIENTE             = 'siguiente',
  ANTERIOR              = 'anterior',
  ANTERIOR_OVER_FLOW    = 'anteriorOverflow',
  TODAS                 = 'todas',
}
  