import { log, logIf, logTable, values } from '@maq-console';

import { Component, OnInit, OnDestroy, ViewEncapsulation, ChangeDetectorRef,ViewChild,ElementRef } from '@angular/core';

import { FormGroup, FormControl, FormBuilder, Validators, FormsModule } from '@angular/forms';

import { PageGenerica } from '@maq-modules/page-generica/page-generica.page';

import Inputmask from 'inputmask';


declare let $: any;
declare let jQuery: any;
import { TransferenciaArchivos ,TransferenciaBaseDatos} from '@maq-models/transferenciaArchivos/transferenciaArchivos.model';
// Translate
import { TranslateService }  from '@ngx-translate/core';
@Component({
  selector: 'app-footer-web',
  templateUrl: './footer-web.html',
  styleUrls: [
    './footer-web.scss'
  ],
  encapsulation: ViewEncapsulation.None
})

export class FooterWebComponent implements OnInit, OnDestroy {
 
  constructor (  private translate: TranslateService, ) {  }  

  public logComponente = log(...values('componente', 'FooterWebComponent'));  
  
   
  ngOnInit() {

     log(...values('funcionComponente', 'ngOnInit FooterWebComponent'));

     // Listener de Cambio de Idioma
     this.inicializarVariablesTraducibles();
     this.translate.onLangChange.subscribe(() => {
          this.inicializarVariablesTraducibles();
     });




     
  }

  ngOnDestroy() {


    log(...values('funcionComponente','ngOnDestroy FooterWebComponent'));
  }  
  


   inicializarVariablesTraducibles()  {
   
             
  }

   
  

   
  

}