import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'UserSearchPipe', pure: false })
export class UserSearchPipe implements PipeTransform {
  transform(value, args?): Array<any> {
    let searchText = new RegExp(args, 'ig');
    if (value) {
      return value.filter(user => {
        let fullName=user.datosPersonales.apellido+' '+user.datosPersonales.nombre;
        // console.log(fullName)  ;
        if (fullName) {
          return fullName.search(searchText) !== -1;
        }
        else{
          return (args) ? false : true;
        }
      });
    }
  }
}