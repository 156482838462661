import { log, logIf, logTable, values } from '@maq-console';
import { Component, OnInit, DoCheck, ViewEncapsulation,ChangeDetectorRef } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';

import { FuncionesService } from '@maq-funciones';
import { TranslateService } from '@ngx-translate/core';

// jQuery
declare let $: any;
declare let jQuery: any;

@Component({
  selector: 'lib-shared-filtro-periodo',
  templateUrl: './filtro-periodo.component.html',
  styleUrls: ['./filtro-periodo.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FiltroPeriodoComponent implements OnInit {
  
  @Input() public nameField: any;
  @Input() public value: any;
  @Input() public clearValue: boolean;
  @Output() public setearClearValue: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() cargoValor = new EventEmitter<any>();

  public valueAnterior:any=null;
  
  constructor(
                protected changeDetectorRef : ChangeDetectorRef,
                public fn:FuncionesService,
                private translate: TranslateService) { }

  ngOnInit() {
    if(this.valueAnterior==null && this.value) {
      this.valueAnterior=this.value;
    }
  }
  
  ngDoCheck() {
    //console.log("componente filtro-periodo ngDoCheck",this.nameField, this.value);
    // if (this.clearValue){
    //   this.limpiarValor();
    //   // this.clearValue=false;
    // }


    if(this.valueAnterior==null && this.value) {
        this.valueAnterior=this.value;
    }
    
    if(this.value==null && this.valueAnterior!=null) {
          // Filtro por Rango de Fechas o por Fecha
          
          let fieldSearch:any = "filtro_periodo"+this.nameField;
          $("#"+fieldSearch)[0].value=this.translate.instant('claseDateTime.periodo');
          $("#"+fieldSearch).removeClass('filtroConValor');
          $("#"+fieldSearch).addClass('filtroSinValor');                    
          
      
    }
  }  
 
  closeFiltroPeriodo(event) {

    //log(...values('valores','onChangeField - event:',event));
    
    let rango = $("#filtro_periodo"+this.nameField)[0].value;
    
    let fechas = rango.split('~')
    let fecha1 = this.fn.formatFecha2DigitosDiaMes( fechas[0] );
    let fecha2 = this.fn.formatFecha2DigitosDiaMes( fechas[1] );

    // Parche para detectar que luego de indicar filtros, abrió nuevamente el modal y canceló sin elegir nuevos valores
    // en ese caso, el rango va a mantener los valores viejos, formateados sin años dd/mm  ~ dd/mm con 5 dígitos de extensión
    if(fecha1!='' && fecha1.length==5) fecha1='';
    if(fecha2!='' && fecha2.length==5) fecha2='';

    let rangoFormateado = '';
    if(fecha1!='' && fecha2!='') {
      rangoFormateado = this.fn.quitaAnio(fecha1) + ' ~ ' + this.fn.quitaAnio(fecha2);
    }

    //console.log("closeFilterRangoFechas rangoFormateado",rangoFormateado);
    $("#filtro_periodo"+this.nameField)[0].value=rangoFormateado;
    $("#filtro_periodo"+this.nameField).removeClass('filtroSinValor');
    $("#filtro_periodo"+this.nameField).addClass('filtroConValor');
    
    if (!this.changeDetectorRef['destroyed']) {
       this.changeDetectorRef.detectChanges();
    }                
      
    //console.log("rango", rango);
    //console.log("fecha1, fecha2",fecha1, fecha2);
    
    this.cargoValor.emit({
      desde: fecha1,
      hasta: fecha2,
    });

  }
  
  limpiarValor(){
    $("#filtro_periodo"+this.nameField).removeClass('filtroConValor');
    $("#filtro_periodo"+this.nameField).addClass('filtroSinValor');
    $("#filtro_periodo"+this.nameField)[0].value=null;
    // this.setearClearValue.emit(false);
    this.cargoValor.emit({
      desde: '',
      hasta: '',
    });
  }
}

  
  

                
                   
                     
  
  


