import { MODULO_TRADUCCION, NOMBRE_TRADUCCION, TIPO_GRAFICO } from "@settings/proyecto/mocks/tableroControl/tableroControl";
import { Graphic } from "./graphic.model";
import { GraphicPoint } from "./graphicPoint.model";
import { IndicadorPorcentaje } from "./indicadorPorcentaje.model";
import { ChartColor, ChartPoint } from "chart.js";
import { getColorMotivoParada } from "@settings/proyecto/helpers/tableroControl/motivosParadas/completeDocumentsHelp";

export class GraphicFunction{

    private idGraphicFunction: number = 0;
    private name:string;
    private points: GraphicPoint[] = [];
    
    private listAxisX: string [] = [];
    private listAxisY1: number [] | null = null;
    private listAxisY2: number [] | null = null;
    private listAxisY3: number [] | null = null;
    private listAxisY4: number [] | null = null;
    private listAxisY5: number [] | null = null;
    private listAxisY6: number [] | null = null;
    private listAxisY7: number [] | null = null;

    private listAxisYN: number [][] | null = null;
    private listAxisYLabel: string[] = [];

    private indicadorPorcentaje: IndicadorPorcentaje | null = null;

    private listGraphic:Graphic[] = []; //por ahora solo existen 2 graficos para cada funcion el de valores y porcentaje 
  
    private visibleChartType:TIPO_GRAFICO = TIPO_GRAFICO.TODOS; //guarda el tipo de grafico que se visualiza
    private showGraphic:GRAFICOS_VISIBLES = GRAFICOS_VISIBLES.ALGUNOS; //guarda el valor de los graficos que se visualizan

    constructor(init?:Partial<GraphicFunction>) {
      Object.assign(this, init);
    }

    setShowGraphic(showGraphic:GRAFICOS_VISIBLES){
      this.showGraphic = showGraphic;
    }

    getShowGraphic():GRAFICOS_VISIBLES{
      return this.showGraphic;
    }

    changeShowGraphicWithChartType(typeGraphic:TIPO_GRAFICO){
      let someGraphicNotVisible:boolean= this.thereIsSomeInvisibleGraphic();
      if (typeGraphic==TIPO_GRAFICO.TODOS && someGraphicNotVisible){
        this.setShowGraphic(GRAFICOS_VISIBLES.TODOS);
      }
      else{
        let anyVisibleGraphics:boolean = this.isThereAnyVisibleGraph();
        if(anyVisibleGraphics){
          this.setShowGraphic(GRAFICOS_VISIBLES.ALGUNOS);
        }else{
          this.setShowGraphic(GRAFICOS_VISIBLES.NINGUNO);
        }
      }
    }

    isThereAnyVisibleGraph():boolean{
      let anyVisibleGraphics:boolean= false;
      for (let index = 0; index < this.listGraphic.length; index++) {
        if(this.listGraphic[index].getVisualizar()){
          anyVisibleGraphics = true;
          break;
        }
      }
      return anyVisibleGraphics;
    }

    thereIsSomeInvisibleGraphic():boolean{
      let someGraphicNotVisible:boolean= true;
      for (let index = 0; index < this.listGraphic.length; index++) {
        if(this.listGraphic[index].getVisualizar()==false){
          someGraphicNotVisible = false;
          break;
        }
      }
      return someGraphicNotVisible;
    }

    //visualizar grafico dependiendo del tipo de grafico GENERAL pasado por parametro
    viewChartByChartType(typeGraphic:TIPO_GRAFICO){
      for (let index = 0; index < this.listGraphic.length; index++) {
        let graphic:Graphic = this.listGraphic[index];
        if (typeGraphic==TIPO_GRAFICO.TODOS){
          graphic.setVisualizar(true);
        }
        else if(graphic.getTypePoint()==typeGraphic){
          graphic.setVisualizar(true);
        }else{
          graphic.setVisualizar(false);
        }
      }
      this.setVisibleChartType(typeGraphic);
      this.changeShowGraphicWithChartType(typeGraphic);
    }

    getKeysChartIsView():string[]{
      let keys:string[] = [];
      for (let index = 0; index < this.listGraphic.length; index++) {
        let graphic:Graphic = this.listGraphic[index];
        if(graphic.getVisualizar()){
          keys.push(graphic.getKey());
        }
      }
      return keys;
    }

    setDisplayGraphsWithKeys(keys:string[]){
      for (let index = 0; index < this.listGraphic.length; index++) {
        let graphic:Graphic = this.listGraphic[index];
        graphic.setVisualizar(false);
        if(keys.indexOf(graphic.getKey())!=-1 && (this.getVisibleChartType() == TIPO_GRAFICO.TODOS || this.getVisibleChartType() == graphic.getTypePoint())){ 
          graphic.setVisualizar(true);
        }
      }
    }

    //dependiendo el tipo de grafico GENERAL que se selecciono  
    setAllowShowTypeChart(typeGraphic:TIPO_GRAFICO){
      this.setVisibleChartType(typeGraphic);
      this.changeShowGraphicWithChartType(typeGraphic);

      if(this.listGraphic.length >1){
        for (let index = 0; index < this.listGraphic.length; index++) {
            let currentGraph:Graphic = this.listGraphic[index];
            if(this.getShowGraphic() == GRAFICOS_VISIBLES.TODOS  && currentGraph.getVisualizar()){
              currentGraph.setShowTypeChart(false);
            }
            else if(this.getShowGraphic() == GRAFICOS_VISIBLES.ALGUNOS  && currentGraph.getVisualizar()){
              currentGraph.setShowTypeChart(true);
            }else{
              currentGraph.setShowTypeChart(false);
            }
          }
      }
    }

    getArrayTypeGraphicTraduccion():string[]{
      let arrayTypeGraphicTraduccion: string [] = []
      for (let index = 0; index < this.listGraphic.length; index++) {
          if(this.listGraphic[index].getTypePoint()==TIPO_GRAFICO.VALORES ){
            arrayTypeGraphicTraduccion.push(MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.VALORES);
          }else if(this.listGraphic[index].getTypePoint()==TIPO_GRAFICO.PORCENTAJE){
            arrayTypeGraphicTraduccion.push(MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.PORCENTAJE);
          }
      }
      return arrayTypeGraphicTraduccion;
    }


    setValuesAxisInGraphicMotivos(){
      let graficoActual: Graphic;  
      let axisX:string[] = this.getListAxisX();
      let axisYLabel:string = '';

      let colorLineBackground:string = '#ffa400';
      let colorLineBackgroundHover:string = '#ff6900';

      graficoActual = this.getListGraphics()[0]; //en esta posicion siempre estan los graficos de valores
      graficoActual.setValuesAxisX(axisX);
      graficoActual.setChartData([]);

      for (let AxisYQuantity = 0;  AxisYQuantity < this.getListAxisYLabel().length; AxisYQuantity++) {
        axisYLabel = this.getListAxisYLabel()[AxisYQuantity];
        let colorAndHover = getColorMotivoParada(AxisYQuantity);
        colorLineBackground = colorAndHover['color'];
        colorLineBackgroundHover = colorAndHover['hover'];
        let axisY:number[] = this.getListAxisY(AxisYQuantity);
        if(graficoActual.getChartType() =='line'){
          graficoActual.setChartDataValuesLine(axisY, graficoActual.getChartType(), axisYLabel, colorLineBackground,colorLineBackgroundHover);
        }else if(graficoActual.getChartType() == 'horizontalBar'){
          graficoActual.setChartDataValuesBarAndBarHorizontal(axisY, graficoActual.getChartType(), axisYLabel, 0.75 ,colorLineBackground,colorLineBackgroundHover);
        }
      }

      if(this.getListAxisYLabel().length == 0){
        if(graficoActual.getChartType() =='line'){
          graficoActual.setChartDataValuesLine([], graficoActual.getChartType(), 'NO EXISTEN MOTIVOS',colorLineBackground,colorLineBackgroundHover);
        }else if(graficoActual.getChartType() == 'horizontalBar'){
          graficoActual.setChartDataValuesBarAndBarHorizontal([], graficoActual.getChartType(), 'NO EXISTEN MOTIVOS', 1, colorLineBackground,colorLineBackgroundHover);        }
      } 
     
    }

    setValuesAxisInGraphicChoferesAndUbicaciones(){
      let grafico: Graphic = this.getListGraphics()[0];  
      let axisX:string[] = this.getListAxisX();
      grafico.setChartData([]);
      for (let yValuesForXvalues = 0;  yValuesForXvalues < this.getHowManyYvaluesForXvalues(); yValuesForXvalues++) {
        let axisY:number[] = this.getListAxisY(yValuesForXvalues);
        let etiqueta:string = grafico.getLabels()[yValuesForXvalues];
        let hoverColor:ChartColor = grafico.getHoverBackgroundColor()[yValuesForXvalues];
        if(grafico.getTypePoint() == TIPO_GRAFICO.AGRUPADO_POR_CHOFER || grafico.getTypePoint() == TIPO_GRAFICO.AGRUPADO_POR_UBICACION){
          let backgroundColor:ChartColor = grafico.getBackgroundColor()[yValuesForXvalues];
          let chartPoints:ChartPoint[] = []
          for (let index = 0; index < axisY.length; index++) {
            let oneAxisX:number = axisY[index];
            let oneAxisY:string = etiqueta;
            let chartPoint:ChartPoint = {
              x: oneAxisX,
              y: oneAxisY,
              t: axisX[index]
            }
            chartPoints.push(chartPoint);
          }
          if(grafico.getChartType()=='horizontalBar'){
            grafico.updateChartDataValuesBarAndBarHorizontal(chartPoints,grafico.getChartType(),etiqueta,grafico.getBarPercentage(), backgroundColor.toString(), hoverColor.toString());
          }else if(grafico.getChartType() =='line'){
            let borderColor:ChartColor = grafico.getBorderColor()[yValuesForXvalues];
            grafico.updateChartDataValuesLine(axisY,grafico.getChartType(),etiqueta, borderColor.toString(), hoverColor.toString());
          }
        }
        grafico.setValuesAxisX(axisX);
      }
    }

    setValuesAxisInGraphic(){
      let grafico: Graphic;  
      let axisX:string[] = this.getListAxisX();

      for (let yValuesForXvalues = 0;  yValuesForXvalues < this.getHowManyYvaluesForXvalues(); yValuesForXvalues++) {
        let axisY:number[] = this.getListAxisY(yValuesForXvalues);
        //si tiene 3 valores de Y para un X entonces cuando tomo el grafico de porcentajes.
        if(this.getHowManyYvaluesForXvalues() == 3 && yValuesForXvalues == 2 && this.listAxisYN == null){
          grafico = this.getListGraphics()[1]; //obtengo el grafico de porcentajes
          grafico.getChartData()[0].data = axisY; //le cambios los valores del eje Y
          // grafico.getChartData()[yValuesForXvalues].label = axisYLabel;
        }else{
          grafico = this.getListGraphics()[0]; //en esta posicion siempre estan los graficos de valores
          grafico.getChartData()[yValuesForXvalues].data = axisY;
          // grafico.getChartData()[yValuesForXvalues].label = axisYLabel;
        }
        grafico.setValuesAxisX(axisX);
      }
    }

    getIdGraphicFunction():number{
      return this.idGraphicFunction;
    }

    setIdGraphicFunction(idGraphicFunction:number){
      this.idGraphicFunction = idGraphicFunction;
    }
    
    getVisibleChartType():TIPO_GRAFICO{
      return this.visibleChartType;
    }

    setVisibleChartType(visibleChartType:TIPO_GRAFICO){
      this.visibleChartType = visibleChartType;
    }

    getIndicadorPorcentaje():IndicadorPorcentaje | null{
      return this.indicadorPorcentaje;
    }

    setIndicadorPorcentaje(indicadorPorcentaje:IndicadorPorcentaje | null){
      this.indicadorPorcentaje = indicadorPorcentaje;
    }

    getListGraphics():Graphic[]{
      return this.listGraphic;
    }

    setListGraphics(listGraphic:Graphic[]){
      this.listGraphic = listGraphic;
    }

  
    getListAxisY(index:number):number[]{
      let retorno:number[];
      if(this.listAxisYN != null){
        retorno = this.listAxisYN[index];
      }else{
        switch (index) {
          case 0:
            retorno = this.listAxisY1;
          break;
          case 1:
            retorno = this.listAxisY2;      
          break;
          case 2:
            retorno = this.listAxisY3;
          break;
          case 3:
            retorno = this.listAxisY4;
          break;
          case 4:
            retorno = this.listAxisY5;
          break;
          case 5:
            retorno = this.listAxisY6;
          break;
          case 6:
            retorno = this.listAxisY7;
          break;
          default:
            // Lógica predeterminada si no coincide con ninguna opción
          break;
        }
      }
      return retorno;
    }

    hasMoreThanTwoYvaluesForX():boolean{
      return this.getHowManyYvaluesForXvalues() > 3;
    }
  
    getHowManyYvaluesForXvalues():number{
      let length: number = 0;
      
      if(this.listAxisYN != null){
          length = this.listAxisYN.length;
      }else{
        if(this.listAxisY1 != null){
          length = length + 1;
        }
    
        if(this.listAxisY2 != null){
          length = length + 1;
        }
    
        if(this.listAxisY3 != null){
          length = length + 1;
        }
    
        if(this.listAxisY4 != null){
          length = length + 1;
        }
        if(this.listAxisY5 != null){
          length = length + 1;
        }
        if(this.listAxisY6 != null){
          length = length + 1;
        }
        if(this.listAxisY7 != null){
          length = length + 1;
        }
      }
      return length;
    }

    resetPoints(){
      this.points = null;
    }
  
    getPoints():GraphicPoint[]{
      return this.points;
    }
  
    setPoints(points:GraphicPoint[]){
      this.points = points;
    }

    resetAxis(){
      this.listAxisX = [];
      // this.listAxisY1 = [];
      // this.listAxisY2 = [];
      // this.listAxisY3 = [];
      if (this.listAxisY1 != null) this.listAxisY1 = [];
      if (this.listAxisY2 != null) this.listAxisY2 = [];
      if (this.listAxisY3 != null) this.listAxisY3 = [];
      if (this.listAxisY4 != null) this.listAxisY4 = [];
      if (this.listAxisY5 != null) this.listAxisY5 = [];
      if (this.listAxisY6 != null) this.listAxisY6 = [];
      if (this.listAxisY7 != null) this.listAxisY7 = [];
      if (this.listAxisYLabel != null) this.listAxisYLabel = [];
      if (this.listAxisYN != null) this.listAxisYN = [];
    }
  
    addPointToListAxis(){
      this.resetAxis();
      let indiceLabelAnterior : number = -1;
      let indiceLabel : number = -1;

      for (let index = 0; index < this.points?.length; index++) {
        let pointGraphic = this.points[index];
        
        if(this.listAxisX == null) this.listAxisX = [];

        let indiceAxisX : number = this.listAxisX.indexOf(pointGraphic.getAxisX());
        if(indiceAxisX == -1) this.listAxisX.push(pointGraphic.getAxisX());

        if(pointGraphic.getAxisYLabel() != null && pointGraphic.getAxisYLabel()!=''){
          indiceLabel = this.listAxisYLabel.indexOf(pointGraphic.getAxisYLabel()); //me fijo si existe la etiqueta
          if(indiceLabel == -1) { //si no existe la etiqueta la agrego
            this.listAxisYLabel.push(pointGraphic.getAxisYLabel());
          }
        }
        if(this.listAxisYN != null){ //esto se usa para graficos de de motivos paradas
          
          if(indiceLabel != -1){ //si existe la etiqueta 
            if ((this.name==NOMBRE_TRADUCCION.MOTIVOS_PARADAS_AGRUPADO_POR_CHOFER || this.name == TIPO_GRAFICO.AGRUPADO_POR_UBICACION) && indiceLabelAnterior == -1){ //todos los valores anteriores son todos labels distintos
              for (let index = 0; index < indiceAxisX -1; index++) { //recorro la longitud del anterior 
                this.listAxisYN[0].push(0); //completo con 0 el actual
              }
            }
            this.listAxisYN[indiceLabel].push(pointGraphic.getAxisY1());
          }else{
              this.listAxisYN.push([]);
              if((this.name==NOMBRE_TRADUCCION.MOTIVOS_PARADAS_AGRUPADO_POR_CHOFER || this.name == TIPO_GRAFICO.AGRUPADO_POR_UBICACION) && indiceLabelAnterior != -1){ //solo completo el ultimo
                for (let index = 0; index < this.listAxisYN[indiceLabelAnterior].length -1; index++) { //recorro la longitud del anterior 
                  this.listAxisYN[this.listAxisYN.length - 1].push(0); //completo con 0 el actual
                }
              }
              this.listAxisYN[this.listAxisYN.length - 1].push(pointGraphic.getAxisY1());
          }
        }else{
          if(pointGraphic.getAxisY1() != null){
            if(this.listAxisY1 == null) this.listAxisY1 = [];
            this.listAxisY1.push(pointGraphic.getAxisY1());
          }

          if(pointGraphic.getAxisY2() != null){
            if(this.listAxisY2 == null) this.listAxisY2 = [];
            this.listAxisY2.push(pointGraphic.getAxisY2());
          }

          if(pointGraphic.getAxisY3() != null){
            if(this.listAxisY3 == null) this.listAxisY3 = [];
            this.listAxisY3.push(pointGraphic.getAxisY3());
          }

          if(pointGraphic.getAxisY4() != null){
            if(this.listAxisY4 == null) this.listAxisY4 = [];
            this.listAxisY4.push(pointGraphic.getAxisY4());
          }
          if(pointGraphic.getAxisY5() != null){
            if(this.listAxisY5 == null) this.listAxisY5 = [];
            this.listAxisY5.push(pointGraphic.getAxisY5());
          }
          if(pointGraphic.getAxisY6() != null){
            if(this.listAxisY6 == null) this.listAxisY6 = [];
            this.listAxisY6.push(pointGraphic.getAxisY6());
          }
          if(pointGraphic.getAxisY7() != null){
            if(this.listAxisY7 == null) this.listAxisY7 = [];
            this.listAxisY7.push(pointGraphic.getAxisY7());
          }
        }
        indiceLabelAnterior = indiceLabel;
      }
      if(this.listAxisYN != null) this.completarListAnixYN();

    }

    completarListAnixYN(){
      if(this.listAxisYN.length > 1){
        let longAnterior: number = this.listAxisYN[0].length;
        for (let index = 1; index < this.listAxisYN.length; index++) {
          if(longAnterior > this.listAxisYN[index].length){
            let arrayCompletoWithCero:number[] = [];
            for (let index2 = 0; index2 < longAnterior - this.listAxisYN[index].length; index2++) {
              arrayCompletoWithCero.push(0);
            }
            this.listAxisYN[index] = arrayCompletoWithCero.concat(this.listAxisYN[index]);
          }
          longAnterior = this.listAxisYN[index].length;
        }
      }
    }

    getName():string{
      return this.name;
    }

    setName(name:string){
      this.name = name;
    }

    getListAxisX():string[]{
      return this.listAxisX;
    }
  
    setListAxisX(listAxisX:string[]){
      this.listAxisX = listAxisX;
    }
  
    getListAxisY1():number[]{
      return this.listAxisY1;
    }
  
    setListAxisY1(listAxisY1:number[]){
      this.listAxisY1 = listAxisY1;
    }
  
    getListAxisY2():number[]{
      return this.listAxisY2;
    }
  
    setListAxisY2(listAxisY2:number[]){
      this.listAxisY2 = listAxisY2;
    }
  
    getListAxisY3():number[]{
      return this.listAxisY3;
    }
  
    setListAxisY3(listAxisY3:number[]){
      this.listAxisY3 = listAxisY3;
    }
  
    getListAxisY4():number[]{
      return this.listAxisY4;
    }
  
    setListAxisY4(listAxisY4:number[]){
      this.listAxisY4 = listAxisY4;
    }
  
    getListAxisY5():number[]{
      return this.listAxisY5;
    }
  
    setListAxisY5(listAxisY5:number[]){
      this.listAxisY5 = listAxisY5;
    }
  
    getListAxisY6():number[]{
      return this.listAxisY6;
    }
  
    setListAxisY6(listAxisY6:number[]){
      this.listAxisY6 = listAxisY6;
    }
    getListAxisY7():number[]{
      return this.listAxisY7;
    }
  
    setListAxisY7(listAxisY7:number[]){
      this.listAxisY7 = listAxisY7;
    }

    getListAxisYN():number[][]{
      return this.listAxisYN;
    }
  
    setListAxisYN(listAxisYN:number[][]){
      this.listAxisYN = listAxisYN;
    }

    getListAxisYLabel():string[]{
      return this.listAxisYLabel;
    }
  
    setListAxisYLabel(listAxisYLabel:string[]){
      this.listAxisYLabel = listAxisYLabel;
    }

    indexOfAxisXInGraphic(valueX:string):number{
      let posicionRepetida:number = -1;
      for (let index = 0; index < this.points.length; index++) {
        if (this.points[index].getAxisX() == valueX) {
          posicionRepetida = index;
          break;
        }
      }
      return posicionRepetida;
    }
    
    validateNullOrUndefinedToCompleteWithCero(value:number | null | undefined):number{
      if(value == undefined || value == null){
        value = 0;
      }
      return value;
    }

    validateOptionalIsNullToCompleteWithCero(value:number | null | undefined):number{
      if(value == null && value != undefined){
        value = 0;
      }
      return value;
    }

    isOptionalNotUndefined(value:number | undefined):boolean{
      let retorno:boolean=false;
      if(value != undefined){
        retorno = true;
      }
      return retorno;
    }

    addOnePointAxisY1(valueY:number, axisX:string, axisYLabel:string){
      valueY = this.validateNullOrUndefinedToCompleteWithCero(valueY);
      let graficPoint: GraphicPoint = new GraphicPoint();
      graficPoint.setAxisX(axisX);

      if(this.isOptionalNotUndefined(valueY)){
        graficPoint.setAxisY1(valueY);
      }
      
      graficPoint.setAxisYLabel(axisYLabel);
      if(this.points == null) this.points = [];
      this.points.push(graficPoint);
    }

    addOnePointAxisY2(valueY:number, axisX:string, axisYLabel:string){
      valueY = this.validateNullOrUndefinedToCompleteWithCero(valueY);
      let graficPoint: GraphicPoint = new GraphicPoint();
      graficPoint.setAxisX(axisX);

      if(this.isOptionalNotUndefined(valueY)){
        graficPoint.setAxisY2(valueY);
      }
      
      graficPoint.setAxisYLabel(axisYLabel);
      if(this.points == null) this.points = [];
      this.points.push(graficPoint);
    }

    addOnePointAxisY3(valueY:number, axisX:string, axisYLabel:string){
      valueY = this.validateNullOrUndefinedToCompleteWithCero(valueY);
      let graficPoint: GraphicPoint = new GraphicPoint();
      graficPoint.setAxisX(axisX);

      if(this.isOptionalNotUndefined(valueY)){
        graficPoint.setAxisY3(valueY);
      }
      
      graficPoint.setAxisYLabel(axisYLabel);
      if(this.points == null) this.points = [];
      this.points.push(graficPoint);
    }

    addOnePointAxisY4(valueY:number, axisX:string, axisYLabel:string){
      valueY = this.validateNullOrUndefinedToCompleteWithCero(valueY);
      let graficPoint: GraphicPoint = new GraphicPoint();
      graficPoint.setAxisX(axisX);

      if(this.isOptionalNotUndefined(valueY)){
        graficPoint.setAxisY4(valueY);
      }
      
      graficPoint.setAxisYLabel(axisYLabel);
      if(this.points == null) this.points = [];
      this.points.push(graficPoint);
    }

    addOnePointAxisY5(valueY:number, axisX:string, axisYLabel:string){
      valueY = this.validateNullOrUndefinedToCompleteWithCero(valueY);
      let graficPoint: GraphicPoint = new GraphicPoint();
      graficPoint.setAxisX(axisX);

      if(this.isOptionalNotUndefined(valueY)){
        graficPoint.setAxisY5(valueY);
      }
      
      graficPoint.setAxisYLabel(axisYLabel);
      if(this.points == null) this.points = [];
      this.points.push(graficPoint);
    }

    addOnePointAxisY6(valueY:number, axisX:string, axisYLabel:string){
      valueY = this.validateNullOrUndefinedToCompleteWithCero(valueY);
      let graficPoint: GraphicPoint = new GraphicPoint();
      graficPoint.setAxisX(axisX);

      if(this.isOptionalNotUndefined(valueY)){
        graficPoint.setAxisY6(valueY);
      }
      
      graficPoint.setAxisYLabel(axisYLabel);
      if(this.points == null) this.points = [];
      this.points.push(graficPoint);
    }

    addOnePointAxisY7(valueY:number, axisX:string, axisYLabel:string){
      valueY = this.validateNullOrUndefinedToCompleteWithCero(valueY);
      let graficPoint: GraphicPoint = new GraphicPoint();
      graficPoint.setAxisX(axisX);

      if(this.isOptionalNotUndefined(valueY)){
        graficPoint.setAxisY7(valueY);
      }
      
      graficPoint.setAxisYLabel(axisYLabel);
      if(this.points == null) this.points = [];
      this.points.push(graficPoint);
    }
  
  
    addFromOnePointToSeven(axisX:string, value1Y:number, value2Y?:number, value3Y?:number, value4Y?:number, value5Y?:number, value6Y?:number, value7Y?:number){
      value1Y = this.validateNullOrUndefinedToCompleteWithCero(value1Y);
      value2Y = this.validateNullOrUndefinedToCompleteWithCero(value2Y);
      value3Y = this.validateNullOrUndefinedToCompleteWithCero(value3Y);
      value4Y = this.validateOptionalIsNullToCompleteWithCero(value4Y);
      value5Y = this.validateOptionalIsNullToCompleteWithCero(value5Y);
      value6Y = this.validateOptionalIsNullToCompleteWithCero(value6Y);
      value7Y = this.validateOptionalIsNullToCompleteWithCero(value7Y);
    
      let graficPoint: GraphicPoint = new GraphicPoint();
      graficPoint.setAxisX(axisX);
      graficPoint.setAxisY1(value1Y);

      if(this.isOptionalNotUndefined(value2Y)){
        graficPoint.setAxisY2(value2Y);
      }
      if(this.isOptionalNotUndefined(value3Y)){
        graficPoint.setAxisY3(value3Y);
      }
      if(this.isOptionalNotUndefined(value4Y)){
        graficPoint.setAxisY4(value4Y);
      }
      if(this.isOptionalNotUndefined(value5Y)){
        graficPoint.setAxisY5(value5Y);
      }
      if(this.isOptionalNotUndefined(value6Y)){
        graficPoint.setAxisY6(value6Y);
      }
      if(this.isOptionalNotUndefined(value7Y)){
        graficPoint.setAxisY7(value7Y);
      }

      if(this.points == null) this.points = [];
      
      this.points.push(graficPoint);
    }
}

export enum GRAFICOS_VISIBLES{
  TODOS  = 'todos',
  NINGUNO = 'ninguno',
  ALGUNOS = 'algunos' 
}