// import { log, logIf, logTable, values } from '@maq-shared/util/console';

import { Component, OnInit, Input, Output, ViewEncapsulation, EventEmitter } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-shared-form-buttons',
  templateUrl: './form-buttons.component.html',
  styleUrls: [ './form-buttons.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class FormButtonsComponent implements OnInit {
    @Input() public accionForm: string;
    @Input() public permisos: any;

    @Output() setAccion= new EventEmitter<string>();
    @Output() onSubmitComponent= new EventEmitter<null>();
    public traduccion:any={};

    constructor(
        public translate: TranslateService
        ) {
    }

    ngOnInit() {
       // log(...values("valores","form-buttons - permisos:",this.permisos));

       this.inicializarVariablesTraducibles();
       this.translate.onLangChange.subscribe(() => {
          this.inicializarVariablesTraducibles();
       });
    }

    setAccionForm(accion:string):any {
        this.setAccion.emit(accion);
    }

    componentSubmit():any {
        this.onSubmitComponent.emit();
    }

    inicializarVariablesTraducibles(){

        this.traduccion['navegabilidad']={
           editar:                 this.translate.instant('navegabilidad.editar'),
           cancelar:               this.translate.instant('navegabilidad.cancelar'),
           agregar:                this.translate.instant('navegabilidad.agregar'),
           grabar:                 this.translate.instant('navegabilidad.grabar')
        }
    }



}

