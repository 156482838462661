<!-- ======= Header ======= -->
<header id="header" style="border-bottom:1px solid #ccc; height:105px;"
        [style.backgroundColor]="settingsWebsite.header.colorBackground">

   <div class="container">

      <nav class="navbar navbar-expand-lg bg-link" style="padding-top:0px; padding-left:0px; padding-right:0px;">

            <a class="navbar-brand" 
              style="margin-left:10px; padding-top:0px; font-weight:bold; font-size:24px;" href="javascript:;"
              (click)="linkRoute(settingsWebsite.homeRouterLink,settingsWebsite.homeFragment)"><img [src]="settingsWebsite.header.logo" style="height:85px;"></a>
            
           <!-- <a *ngIf="settings2.app.name!='AtlanticBridge'"
              class="navbar-brand" 
              style="padding-top:0px; font-weight:bold; font-size:24px;"
              href="index.html">{{settings2.app.title}}</a>

            <a *ngIf="settings2.app.name=='AtlanticBridge'"
              class="navbar-brand" 
              style="padding-top:0px; font-weight:bold; font-size:24px;"
              href="index.html"><img src="../assets/img/logo/atlantic_menu.png" style="height:45px;"></a> -->
              
           <button class="navbar-toggler navbar-toggler-right" type="button" id="buttonCollapse"
                   data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" 
                   aria-expanded="false" aria-label="Toggle navigation" style="margin-right:10px;">                
                 <!-- <span class="navbar-toggler-icon"></span> -->
                 <i class="fa fa-navicon" [style.color]="settingsWebsite.header.colorFont" style="font-size:32px;"></i>
           </button>
           
           <div class="collapse navbar-collapse flex-md-column borderTopMenu borderBottomMenu" id="navbarNav" *ngIf="listadoMenuWeb" 
                style="padding-left:0px; padding-bottom:7px; z-index:200;"
                [style.background]="settingsWebsite.header.colorBackground">
             
             <!-- https://codepen.io/_yatko/pen/ZJXQxy
                  https://medium.com/wdstack/bootstrap-4-hidden-visible-dd969a4c5854 -->
             <!-- d-block d-sm-none d-md-block           (hidden-sm only) -->
             <!-- d-block d-sm-none d-md-none d-lg-block (hidden-sm & md only) -->
             <ul class="navbar-nav ml-auto displayHeader1raFilaDesktop" style="margin-bottom:15px;" *ngIf="settingsWebsite.header.contacto1Fila">       
               
                    <!-- telefono --> 
                    <li *ngIf="['formato1'].indexOf(settingsWebsite.header.contacto1Fila)!=-1 && 
                               settingsWebsite.header.contactoTelefono"
                        style="padding-top:8px;">                  
                       <a (click)="linkRoute(settingsWebsite.contactoRouterLink,settingsWebsite.ContactoFragment)" 
                          href="javascript:;" [style.color]="settingsWebsite.header.colorFont"
                          [style.fontSize]="settingsWebsite.header.opcionesFontSize"
                          style="margin-right:22px;">{{settingsWebsite.header.contactoTelefono}}</a>
                    </li> 
               
                    <!-- email --> 
                    <li *ngIf="appSettings.settings2.cliente.email && ['formato1'].indexOf(settingsWebsite.header.contacto1Fila)!=-1">                  
                        <a href="mailto: {{appSettings.settings2.cliente.email}}" target="_blank"><i class="fa fa-envelope" 
                           [style.color]="settingsWebsite.header.colorFont" 
                           [style.fontSize]="settingsWebsite.header.iconosFontSize" style="margin-top:11px; margin-right:18px;"></i></a>
                    </li>

                    <li *ngIf="appSettings.settings2.cliente.email && ['formato2'].indexOf(settingsWebsite.header.contacto1Fila)!=-1">                  
                        <a href="javascript:;" (click)="linkRoute(settingsWebsite.contactoRouterLink,settingsWebsite.ContactoFragment)"><i class="fa fa-envelope" 
                           [style.color]="settingsWebsite.header.colorFont" 
                           [style.fontSize]="settingsWebsite.header.iconosFontSize" style="margin-top:11px; margin-right:18px;"></i></a>
                    </li>
                    
                    <!-- facebook --> 
                    <li *ngIf="appSettings.settings2.cliente.redSocial.facebook && ['formato1'].indexOf(settingsWebsite.header.contacto1Fila)!=-1">
                        <a href="{{appSettings.settings2.cliente.redSocial.facebook}}" target="_blank"><i class="fa fa-facebook-official" 
                           [style.color]="settingsWebsite.header.colorFont" 
                           [style.fontSize]="settingsWebsite.header.iconosFontSize" style="margin-top:11px; margin-right:15px;"></i></a>
                    </li>
                    
                    <!-- instagram --> 
                    <li *ngIf="appSettings.settings2.cliente.redSocial.instagram && ['formato1'].indexOf(settingsWebsite.header.contacto1Fila)!=-1">
                        <a href="{{appSettings.settings2.cliente.redSocial.instagram}}" target="_blank"><i class="fa fa-instagram" 
                           [style.color]="settingsWebsite.header.colorFont" 
                           [style.fontSize]="settingsWebsite.header.iconosFontSize" style="margin-top:11px; margin-right:15px;"></i></a>
                    </li>
                    
                    <!-- twitter --> 
                    <li *ngIf="appSettings.settings2.cliente.redSocial.twitter && ['formato1'].indexOf(settingsWebsite.header.contacto1Fila)!=-1">
                        <a href="{{appSettings.settings2.cliente.redSocial.twitter}}" target="_blank"><i class="fa fa-twitter" 
                           [style.color]="settingsWebsite.header.colorFont" 
                           [style.fontSize]="settingsWebsite.header.iconosFontSize" 
                           style="margin-top:11px; margin-right:15px;"></i></a>
                    </li>
                    
                    <!-- linkedin --> 
                    <li *ngIf="appSettings.settings2.cliente.redSocial.linkedin && ['formato1'].indexOf(settingsWebsite.header.contacto1Fila)!=-1">
                        <a  href="{{appSettings.settings2.cliente.redSocial.linkedin}}" target="_blank"><i class="fa fa-linkedin-square" 
                            [style.color]="settingsWebsite.header.colorFont" 
                            [style.fontSize]="settingsWebsite.header.iconosFontSize" style="margin-top:11px; margin-right:15px;"></i></a>
                    </li>
                    
                    <!-- pinterest --> 
                    <li *ngIf="appSettings.settings2.cliente.redSocial.pinterest && ['formato1'].indexOf(settingsWebsite.header.contacto1Fila)!=-1">
                      <a  href="{{appSettings.settings2.cliente.redSocial.pinterest}}" target="_blank"><i class="fa fa-pinterest" 
                          [style.color]="settingsWebsite.header.colorFont" 
                          [style.fontSize]="settingsWebsite.header.iconosFontSize" style="margin-top:11px; margin-right:15px;"></i></a>
                    </li>                                                

                    <!-- Selector de Idiomas -->
                    <li class="nav-item" style="padding-top:7px;" *ngIf="idiomasHabilitados.length>1">
                       <lib-shared-flags-menu></lib-shared-flags-menu>
                    </li>  
              
             </ul>
             
             <table class="table displayHeader1raFilaCelular" style="border-top:hidden;">
                <tbody>
                   <tr>
                       <td style="width:20px; padding:0px; margin:0px; border-top:hidden;">&nbsp;</td>  

                       <!-- telefono --> 
                       <td *ngIf="['formato1'].indexOf(settingsWebsite.header.contacto1Fila)!=-1 && 
                                  settingsWebsite.header.contactoTelefono"
                           style="padding-top:8px; border-top:hidden;">                  
                          <a (click)="linkRoute(settingsWebsite.contactoRouterLink,settingsWebsite.ContactoFragment)" 
                             href="javascript:;" [style.color]="settingsWebsite.header.colorFont"
                             [style.fontSize]="settingsWebsite.header.opcionesFontSize"
                             style="margin-right:22px;">{{settingsWebsite.header.contactoTelefono}}</a>
                       </td> 
                       
                       <!-- email --> 
                       <td *ngIf="appSettings.settings2.cliente.email && ['formato1'].indexOf(settingsWebsite.header.contacto1Fila)!=-1"
                           style="width:40px; border-top:hidden;" class="text-center">                  
                           <a href="mailto: {{appSettings.settings2.cliente.email}}" target="_blank"><i class="fa fa-envelope" 
                              [style.color]="settingsWebsite.header.colorFont" 
                              [style.fontSize]="settingsWebsite.header.iconosFontSize"></i></a>
                       </td>

                       <td *ngIf="appSettings.settings2.cliente.email && ['formato2'].indexOf(settingsWebsite.header.contacto1Fila)!=-1"
                           style="width:40px; border-top:hidden;" class="text-center">
                           <a href="javascript:;" (click)="linkRoute(settingsWebsite.contactoRouterLink,settingsWebsite.ContactoFragment)"><i class="fa fa-envelope" 
                              [style.color]="settingsWebsite.header.colorFont" 
                              [style.fontSize]="settingsWebsite.header.iconosFontSize"></i></a>
                       </td>

                       <!-- facebook --> 
                       <td *ngIf="appSettings.settings2.cliente.redSocial.facebook && ['formato1'].indexOf(settingsWebsite.header.contacto1Fila)!=-1"
                           style="width:40px; border-top:hidden;" class="text-center">
                           <a href="{{appSettings.settings2.cliente.redSocial.facebook}}" target="_blank"><i class="fa fa-facebook-official" 
                              [style.color]="settingsWebsite.header.colorFont" 
                              [style.fontSize]="settingsWebsite.header.iconosFontSize"></i></a>
                       </td>
                       
                       <!-- instagram --> 
                       <td *ngIf="appSettings.settings2.cliente.redSocial.instagram && ['formato1'].indexOf(settingsWebsite.header.contacto1Fila)!=-1"
                           style="width:40px; border-top:hidden;" class="text-center">
                           <a href="{{appSettings.settings2.cliente.redSocial.instagram}}" target="_blank"><i class="fa fa-instagram" 
                              [style.color]="settingsWebsite.header.colorFont" 
                              [style.fontSize]="settingsWebsite.header.iconosFontSize"></i></a>
                       </td>
                       
                       <!-- twitter --> 
                       <td *ngIf="appSettings.settings2.cliente.redSocial.twitter && ['formato1'].indexOf(settingsWebsite.header.contacto1Fila)!=-1"
                           style="width:40px; border-top:hidden;" class="text-center">
                           <a href="{{appSettings.settings2.cliente.redSocial.twitter}}" target="_blank"><i class="fa fa-twitter" 
                              [style.color]="settingsWebsite.header.colorFont" 
                              [style.fontSize]="settingsWebsite.header.iconosFontSize" 
                              style="margin-top:11px; margin-right:15px;"></i></a>
                       </td>
                       
                       <!-- linkedin --> 
                       <td *ngIf="appSettings.settings2.cliente.redSocial.linkedin && ['formato1'].indexOf(settingsWebsite.header.contacto1Fila)!=-1"
                           style="width:40px; border-top:hidden;" class="text-center">
                           <a  href="{{appSettings.settings2.cliente.redSocial.linkedin}}" target="_blank"><i class="fa fa-linkedin-square" 
                               [style.color]="settingsWebsite.header.colorFont" 
                               [style.fontSize]="settingsWebsite.header.iconosFontSize"></i></a>
                       </td>
                       
                       <!-- pinterest --> 
                       <td *ngIf="appSettings.settings2.cliente.redSocial.pinterest && ['formato1'].indexOf(settingsWebsite.header.contacto1Fila)!=-1"
                           style="width:40px; border-top:hidden;" class="text-center">
                            <a  href="{{appSettings.settings2.cliente.redSocial.pinterest}}" target="_blank"><i class="fa fa-pinterest" 
                                [style.color]="settingsWebsite.header.colorFont" 
                                [style.fontSize]="settingsWebsite.header.iconosFontSize"></i></a>
                       </td>                                                
                       
                       <!-- Selector de Idiomas -->
                       <td *ngIf="idiomasHabilitados.length>1"
                           style="width:40px; padding-top:12px; border-top:hidden;" class="text-center">
                          <lib-shared-flags-menu></lib-shared-flags-menu>
                       </td>  
                       
                       <td style="border-top:hidden;">&nbsp;</td>  <!-- Espacio derecho resto-->
                       
                   </tr>
                </tbody>
             </table>
                
             <ul class="navbar-nav ml-auto menuCelular2daFila backgroundMenu" style="margin-left:0px; padding-left:0px; "> 
               
                 <li *ngFor="let opcion of listadoMenuWeb; let index=index"
                    [ngClass]="getClassMenu(opcion)">

                     <!-- Submenúes Menú Principal -->                
                     <a *ngIf="opcion.nivel==2 && opcion.hasSubMenu==true && opcion.anchors==false && settingsWebsite.keyPadreInteriores!=opcion.key"
                        class="nav-link dropdown-toggle menu1BorderTop" 
                        href="#" id="navbarDropdownMenuLink" role="button"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"                         
                        [ngStyle]="getStyleMenuPrincial(opcion.routerLink,pathSeccion)"
                        [style.text-transform]="settingsWebsite.seccion.tituloMayuscula ? 'none' : 'none'"
                        [style.marginLeft]="settingsWebsite.header.opcionesMargin"
                        [style.fontSize]="settingsWebsite.header.opcionesFontSize">{{getTitleMenu(opcion)}}</a>
                        
                     <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" 
                          *ngIf="opcion.nivel==2 && opcion.hasSubMenu==true && opcion.anchors==false && settingsWebsite.keyPadreInteriores!=opcion.key">
                          
                         <ng-container *ngFor="let subOpcion of getSubmenu(opcion.id); let index = index">
                              <!-- Opción Individual Nivel 2 -->
                              <li *ngIf="subOpcion.hasSubMenu==false">
                                 <a class="dropdown-item" 
                                    [style.text-transform]="settingsWebsite.seccion.tituloMayuscula ? 'none' : 'none'"
                                    [routerLink]="fn.getRouterLinkPath(subOpcion.routerLink)" [fragment]="fn.getRouterLinkFragment(subOpcion.routerLink)"
                                    (click)="cerrarMenu()">{{getTitleMenu(subOpcion)}}</a>  
                              </li>

                              <!-- Opción Individual Nivel 2 con Separadores -->
                              <div *ngIf="subOpcion.hasSubMenu==true">
                                 <div class="dropdown-divider" *ngIf="index>0"></div>
                                 <h6 class="dropdown-header" style="font-weight:bold; color:#a29e9e;">{{getTitleMenu(subOpcion)}}</h6>
                                 <li *ngFor="let subOpcion2 of getSubmenu(subOpcion.id)">
                                    <a class="dropdown-item" 
                                       [style.text-transform]="settingsWebsite.seccion.tituloMayuscula ? 'none' : 'none'"
                                       [routerLink]="fn.getRouterLinkPath(subOpcion2.routerLink)" [fragment]="fn.getRouterLinkFragment(subOpcion2.routerLink)"
                                       (click)="cerrarMenu()">{{getTitleMenu(subOpcion2)}}</a>  
                                 </li>
                                 <div class="dropdown-divider"></div>
                              </div>
                              
                              <!--  Opción Submenú Nivel 2 -->   
                              <!--   https://bootstrap-menu.com/detail-multilevel.html -->
                              <!--
                              <li *ngIf="subOpcion.hasSubMenu==true">

                                    <a class="dropdown-item dropdown-toggle" 
                                       href="javascript:;">{{getTitleMenu(subOpcion)}}</a>
                                 
                                    <ul *ngIf="subOpcion.hasSubMenu==true"  
                                        class="submenu dropdown-menu">

                                      <li *ngFor="let subOpcion2 of getSubmenu(subOpcion.id)">
                                          <a class="dropdown-item" 
                                             [style.text-transform]="settingsWebsite.seccion.tituloMayuscula ? 'none' : 'none'"
                                             [routerLink]="subOpcion2.routerLink" (click)="cerrarMenu()">{{getTitleMenu(subOpcion2)}}</a>  
                                      </li>
                                    </ul>                                
                              </li>
                              -->
                                                                       
                         </ng-container>        
                     </ul>

                     <!-- Opciones Individuales Menú Principal -->                  
                     <a *ngIf="opcion.nivel==2 && (opcion.hasSubMenu==false || opcion.anchors) &&
                               opcion['title_'+idiomaDefault] && opcion.routerLink && opcion.routerLink.includes('#') &&
                               showHome(opcion)"  
                        class="nav-link menu1BorderTop menu1Individual"                                                                 
                        [routerLink]="fn.getRouterLinkPath(opcion.routerLink)" [fragment]="fn.getRouterLinkFragment(opcion.routerLink)"
                        (click)="cerrarMenu()" 
                        [ngStyle]="getStyleMenuPrincial(opcion.routerLink,pathSeccion)"
                        [style.text-transform]="settingsWebsite.seccion.tituloMayuscula ? 'none' : 'none'"
                        [style.marginLeft]="settingsWebsite.header.opcionesMargin"
                        [style.fontSize]="settingsWebsite.header.opcionesFontSize">{{getTitleMenu(opcion)}}</a> 
                        <!--(click)="linkRoute(fn.getRouterLinkPath(opcion.routerLink),fn.getRouterLinkFragment(opcion.routerLink))" -->
                        
                     <a *ngIf="opcion.nivel==2 && (opcion.hasSubMenu==false || opcion.anchors) &&
                               opcion['title_'+idiomaDefault] && opcion.routerLink && !opcion.routerLink.includes('#')"  
                        class="nav-link menu1BorderTop menu1Individual"  
                        [routerLink]="opcion.routerLink"
                        (click)="cerrarMenu()" 
                        [ngStyle]="getStyleMenuPrincial(opcion.routerLink,pathSeccion)"                        
                        [style.text-transform]="settingsWebsite.seccion.tituloMayuscula ? 'none' : 'none'"
                        [style.marginLeft]="settingsWebsite.header.opcionesMargin"
                        [style.fontSize]="settingsWebsite.header.opcionesFontSize">{{getTitleMenu(opcion)}}</a> 
                        <!-- (click)="linkRoute(opcion.routerLink,null)"  -->
                     
                 </li>               

                 <!-- Selector de Idiomas -->
                 <li *ngIf="settingsWebsite.header.contacto1Fila==false && idiomasHabilitados.length>1"
                     class="nav-item" style="padding-top:7px;">
                    <lib-shared-flags-menu></lib-shared-flags-menu>
                 </li>
                 
             </ul>
           </div>
           
      </nav>
      
   </div>
   
</header><!-- End Header -->