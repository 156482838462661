import { MODULO_TRADUCCION, NOMBRE_TRADUCCION, NUMERO_ASOCIADO_ESTADOS_PARADAS, NUMERO_ASOCIADO_NOMBRE_GRAFICO, NUMERO_ASOCIADO_TIPO_GRAFICO, RANGO_FECHAS, TABLERO_CONTROL, TIPO_GRAFICO } from '@settings/proyecto/mocks/tableroControl/tableroControl';
import { parse, format, startOfMonth, endOfMonth } from 'date-fns';
import { NgbCalendar, NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { ChartColor, ChartPoint, ChartScales, ChartType, LinearScale, LogarithmicScale, TimeScale } from 'chart.js';
import { GraphicFunction } from '@settings/proyecto/models/graficos/graphicFunction.model';
import { Graphic } from '@settings/proyecto/models/graficos/graphic.model';
import { TranslateService } from '@ngx-translate/core';
import { ESTADOS_PARADAS_ARRAY_GESTION_PARADAS } from '@settings/proyecto/mocks/rutas_y_paradas/rutasParadas.mocks';
import { TableroControl } from '@settings/proyecto/models/tableroControl/tableroControl.model';
import { getColorMotivoParada } from './motivosParadas/completeDocumentsHelp';

export function initGraphicsPlanVsRealAndPorcentage(arrayGraphicFunction: GraphicFunction[], translate:TranslateService):Promise<any>{
  return new Promise((resolve, reject)=>{
    try{
      let colorBarBackground:ChartColor = '#0095fe';
      let colorBarBackgroundHover:ChartColor = '#0074cc'

      let colorLineBackground:ChartColor = '#ffa400';
      let colorLineBackgroundHover:ChartColor = '#ff6900'

      let typeGraphic:ChartType;
      let typePointGraphic:TIPO_GRAFICO;

      let scales: ChartScales | LinearScale | LogarithmicScale | TimeScale = {
        yAxes: [
          {
            ticks: {
              beginAtZero: true, // Para asegurarte de que comience desde 0
            }
          }
        ],
        xAxes: [
          {
            ticks: {
              beginAtZero: true, // Para asegurarte de que comience desde 0
            }
          }
        ],
      };

      for (let indexGraphicFunction = 0; indexGraphicFunction < arrayGraphicFunction.length; indexGraphicFunction++) {
        let functionGraphic:GraphicFunction = arrayGraphicFunction[indexGraphicFunction];
        functionGraphic.addPointToListAxis();
        if(functionGraphic.getName()==NOMBRE_TRADUCCION.MOTIVOS_PARADAS){
          initGraphicsTiposMotivos(functionGraphic, translate, NOMBRE_TRADUCCION.MOTIVOS_PARADAS, TIPO_GRAFICO.VALORES, 'line');
        }else if(functionGraphic.getName()==NOMBRE_TRADUCCION.ESTADOS_PARADAS){
          initGraphicsEstadosParadas(functionGraphic, translate, NOMBRE_TRADUCCION.ESTADOS_PARADAS, TIPO_GRAFICO.VALORES, 'line');
        }else{
            let axisX:string[] = functionGraphic.getListAxisX();

            let grafico: Graphic = new Graphic();

            let visualizar:boolean = true;  //esto es por default

            for (let AxisYQuantity = 0; AxisYQuantity < functionGraphic.getHowManyYvaluesForXvalues(); AxisYQuantity++) {

              let axisY:number[] = functionGraphic.getListAxisY(AxisYQuantity);
              let nombreTituloAndKey:string[] = obtenerNombreTituloAndKeyGraphico(indexGraphicFunction, AxisYQuantity);

              if(AxisYQuantity==0 || AxisYQuantity==1){

                typePointGraphic = TIPO_GRAFICO.VALORES;
              }else{
                typePointGraphic = TIPO_GRAFICO.PORCENTAJE;
                grafico = new Graphic();
                visualizar = false; //esto es por default
                functionGraphic.getListGraphics().push(grafico);
              }

              if(AxisYQuantity==0){
                typeGraphic='line';
                grafico.setChartDataValuesLine(axisY, typeGraphic, translate.instant(nombreTituloAndKey[0]),colorLineBackground,colorLineBackgroundHover);
                functionGraphic.getListGraphics().push(grafico);
              }else{
                if(AxisYQuantity==1){
                  typeGraphic='bar';
                }else{
                  typeGraphic='horizontalBar';
                }

                let anchoColumna: number = 0.5; //ocupa el 50% del ancho
                grafico.setChartDataValuesBarAndBarHorizontal(axisY, typeGraphic, translate.instant(nombreTituloAndKey[0]), anchoColumna,colorBarBackground,colorBarBackgroundHover);
              }
              grafico.setScale(scales);
              grafico.setChartType(typeGraphic);
              grafico.setChartLegend(true);
              grafico.setTypePoint(typePointGraphic);
              grafico.setValuesAxisX(axisX);
              grafico.setVisualizar(visualizar);
              grafico.setTitulo(translate.instant(nombreTituloAndKey[1]));
              grafico.setShowTypeChart(false);
              grafico.setKey(nombreTituloAndKey[2]);
              // grafico.setSharedKeyWithValueAndPercentageGraph(nombreTituloAndKey[3]);
          
            }
          }
      }
      resolve();
    }catch(error){
      reject(error);
    }
  });
}

export function initGraphicsTiposMotivos(functionGraphic:GraphicFunction, translate:TranslateService, graphicKey:NOMBRE_TRADUCCION, typePointGraphic:TIPO_GRAFICO, typeGraphic: ChartType){
  let colorLineBackground:string = '#ffa400';
  let colorLineBackgroundHover:string = '#ff6900'

  let axisX:string[] = functionGraphic.getListAxisX();

  let grafico:Graphic = new Graphic();

  functionGraphic.getListGraphics().push(grafico);

  let axisY:number[] = [];
  let axisYLabel:string = '';

  let visualizar:boolean = true;  //esto es por default

  let scales: ChartScales | LinearScale | LogarithmicScale | TimeScale = {
    yAxes: [
      {
        ticks: {
          beginAtZero: true, // Para asegurarte de que comience desde 0
        }
      }
    ],
    xAxes: [
      {
        ticks: {
          beginAtZero: true, // Para asegurarte de que comience desde 0
        }
      }
    ],
  };
  let nombreTituloAndKey:string[] = [NOMBRE_TRADUCCION.NO_EXISTEN_MOTIVOS, MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.MOTIVOS_PARADAS];
    
  visualizar = true; //esto es por default

  for (let AxisYQuantity = 0;  AxisYQuantity < functionGraphic.getListAxisYLabel().length; AxisYQuantity++) {
    axisYLabel = functionGraphic.getListAxisYLabel()[AxisYQuantity];
    let colorAndHover = getColorMotivoParada(AxisYQuantity);
    colorLineBackground = colorAndHover['color'];
    colorLineBackgroundHover = colorAndHover['hover'];
    let axisY:number[] = functionGraphic.getListAxisY(AxisYQuantity);
    if(typeGraphic=='line'){
      grafico.setChartDataValuesLine(axisY, typeGraphic, axisYLabel,colorLineBackground,colorLineBackgroundHover);
    }else if (typeGraphic=='horizontalBar'){
      grafico.setChartDataValuesBarAndBarHorizontal(axisY, typeGraphic, axisYLabel, 0.75, colorLineBackground,colorLineBackgroundHover);
    }
  }

  if(functionGraphic.getListAxisYLabel().length == 0){
    if(typeGraphic=='line'){
      grafico.setChartDataValuesLine(axisY, typeGraphic, translate.instant(NOMBRE_TRADUCCION.NO_EXISTEN_MOTIVOS),colorLineBackground,colorLineBackgroundHover);
    }else if (typeGraphic=='horizontalBar'){
      grafico.setChartDataValuesBarAndBarHorizontal(axisY, typeGraphic, translate.instant(NOMBRE_TRADUCCION.NO_EXISTEN_MOTIVOS), 1, colorLineBackground,colorLineBackgroundHover);
    }
  }

  grafico.setScale(scales);
  grafico.setChartType(typeGraphic);
  grafico.setChartLegend(true);
  grafico.setTypePoint(typePointGraphic);
  grafico.setValuesAxisX(axisX);
  grafico.setVisualizar(visualizar);
  grafico.setTitulo(translate.instant(nombreTituloAndKey[1]));
  grafico.setShowTypeChart(false);
  grafico.setKey(graphicKey);
}


export function initGraphicsEstadosParadas(functionGraphic:GraphicFunction, translate:TranslateService, key:NOMBRE_TRADUCCION, typePointGraphic:TIPO_GRAFICO, typeGraphic:ChartType){
  let colorLineBackground:string = '#ffa400';
  let colorLineBackgroundHover:ChartColor = '#ff6900'

  let axisX:string[] = functionGraphic.getListAxisX();

  let grafico:Graphic = new Graphic();

  functionGraphic.getListGraphics().push(grafico);

  let scales: ChartScales | LinearScale | LogarithmicScale | TimeScale = {
    yAxes: [
      {
        ticks: {
          beginAtZero: true, // Para asegurarte de que comience desde 0
        }
      }
    ],
    xAxes: [
      {
        ticks: {
          beginAtZero: true, // Para asegurarte de que comience desde 0
        }
      }
    ],
  };

  for (let AxisYQuantity = 0;  AxisYQuantity < functionGraphic.getHowManyYvaluesForXvalues(); AxisYQuantity++) {

    let axisY:number[] = functionGraphic.getListAxisY(AxisYQuantity);

    let visualizar:boolean = true;  //esto es por default

    let nombreTituloAndKey:string[] = [];
        
    visualizar = true; //esto es por default
    

    let estadoParadaGestionParada = ESTADOS_PARADAS_ARRAY_GESTION_PARADAS[AxisYQuantity];

    switch (AxisYQuantity) {
      case NUMERO_ASOCIADO_ESTADOS_PARADAS.RECHAZADA:
        colorLineBackground = estadoParadaGestionParada['color'];
        colorLineBackgroundHover = "#FF8E50";
        nombreTituloAndKey =  [ MODULO_TRADUCCION.ESTADO_PARADA + '.' + NOMBRE_TRADUCCION.RECHAZADA, MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.ESTADOS_PARADAS];
      break;
      case NUMERO_ASOCIADO_ESTADOS_PARADAS.PARADA_PENDIENTE:
        colorLineBackground = estadoParadaGestionParada['color'];
        colorLineBackgroundHover = '#35AFFF';
        nombreTituloAndKey =  [ MODULO_TRADUCCION.ESTADO_PARADA + '.' + NOMBRE_TRADUCCION.PARADA_PENDIENTE, MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.ESTADOS_PARADAS];
      break;
      case NUMERO_ASOCIADO_ESTADOS_PARADAS.CANCELADA:
        colorLineBackground = estadoParadaGestionParada['color'];
        colorLineBackgroundHover = '#FF5A5A';
        nombreTituloAndKey =  [ MODULO_TRADUCCION.ESTADO_PARADA + '.' + NOMBRE_TRADUCCION.CANCELADA, MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.ESTADOS_PARADAS];
      break;
      case NUMERO_ASOCIADO_ESTADOS_PARADAS.PARADA_EN_CURSO:
        colorLineBackground = estadoParadaGestionParada['color'];
        colorLineBackgroundHover = '#FFEC00';
        nombreTituloAndKey =  [ MODULO_TRADUCCION.ESTADO_PARADA + '.' + NOMBRE_TRADUCCION.PARADA_EN_CURSO, MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.ESTADOS_PARADAS];
      break;
      case NUMERO_ASOCIADO_ESTADOS_PARADAS.ENTREGA_PARCIAL:
        colorLineBackground = estadoParadaGestionParada['color'];
        colorLineBackgroundHover = '#4AFF8D';
        nombreTituloAndKey =  [ MODULO_TRADUCCION.ESTADO_PARADA + '.' + NOMBRE_TRADUCCION.ENTREGA_PARCIAL, MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.ESTADOS_PARADAS];
      break;
      case NUMERO_ASOCIADO_ESTADOS_PARADAS.ENTREGA_COMPLETA:
        colorLineBackground = estadoParadaGestionParada['color'];
        colorLineBackgroundHover = '#35FF78';
        nombreTituloAndKey =  [ MODULO_TRADUCCION.ESTADO_PARADA + '.' + NOMBRE_TRADUCCION.ENTREGA_COMPLETA, MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.ESTADOS_PARADAS];
      break;
      case NUMERO_ASOCIADO_ESTADOS_PARADAS.REPLANIFICADA:
        colorLineBackground = estadoParadaGestionParada['color'];
        colorLineBackgroundHover = '#FFBE55';
        nombreTituloAndKey =  [ MODULO_TRADUCCION.ESTADO_PARADA + '.' + NOMBRE_TRADUCCION.REPLANIFICADA, MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.ESTADOS_PARADAS];
      break;
      default:
        // Lógica predeterminada si no coincide con ninguna opción
      break;
    }

    grafico.setChartDataValuesLine(axisY, typeGraphic, translate.instant(nombreTituloAndKey[0]),colorLineBackground,colorLineBackgroundHover);

    grafico.setScale(scales);
    grafico.setChartType(typeGraphic);
    grafico.setChartLegend(true);
    grafico.setTypePoint(typePointGraphic);
    grafico.setValuesAxisX(axisX);
    grafico.setVisualizar(visualizar);
    grafico.setTitulo(translate.instant(nombreTituloAndKey[1]));
    grafico.setShowTypeChart(false);
    grafico.setKey(key);
    
  }
}






//devuelve el numero de la semana 
export function obtenerSemanaDelAnio(fecha: Date): number {
    // Clonar la fecha para no modificar la original
    const fechaClonada = new Date(fecha.getTime());
    // Configurar la fecha para el 7 de enero, que es generalmente la primera semana del año
    fechaClonada.setMonth(0); // Enero
    fechaClonada.setDate(7); // 7 de enero
    // Calcular el número de semanas transcurridas desde el 7 de enero
    const diferenciaEnTiempo = fecha.getTime() - fechaClonada.getTime();
    const semanasTranscurridas = Math.ceil(diferenciaEnTiempo / (7 * 24 * 60 * 60 * 1000));
    return semanasTranscurridas + 1;
}


//devuelve el mes con fechas desde y hasta en un solo string
export function obtenerRangoMes(fecha: string): string {
    const fechaDate = parse(fecha, 'dd/MM/yyyy', new Date());
    const inicioMes = startOfMonth(fechaDate);
    const finMes = endOfMonth(fechaDate);
    return `${format(inicioMes, 'dd/MM/yyyy')} - ${format(finMes, 'dd/MM/yyyy')}`;
}


export function obtenerNombreTituloAndKeyGraphico(index:number, index2:number):string[]{
    let nombreTituloAndKey: string[]= ['','', '', ''];
    switch (index) {
      case NUMERO_ASOCIADO_NOMBRE_GRAFICO.RUTAS:
        if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.VALORES_PLAN){
          nombreTituloAndKey[0] =  MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.RUTAS_INICIADAS_PLAN;
          nombreTituloAndKey[1] =  MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.RUTAS_REAL_VS_PLAN;
          nombreTituloAndKey[2] =  NOMBRE_TRADUCCION.RUTAS_REAL_VS_PLAN;
          nombreTituloAndKey[3] =  NOMBRE_TRADUCCION.RUTAS_REAL_VS_PLAN;
        }else if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.VALORES_REAL){
          nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.RUTAS_INICIADAS_REAL;
          nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.RUTAS_REAL_VS_PLAN;
          nombreTituloAndKey[2] =  NOMBRE_TRADUCCION.RUTAS_REAL_VS_PLAN;
          nombreTituloAndKey[3] =  NOMBRE_TRADUCCION.RUTAS_REAL_VS_PLAN;
        }else if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.PORCENTAJE){
          nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.RUTAS_INICIADAS_PORCENTAJE;
          nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.RUTAS_PORCENTAJE_REAL_VS_PLAN;
          nombreTituloAndKey[2] =  NOMBRE_TRADUCCION.RUTAS_PORCENTAJE_REAL_VS_PLAN;
          nombreTituloAndKey[3] =  NOMBRE_TRADUCCION.RUTAS_REAL_VS_PLAN;
        }
      break;
      case NUMERO_ASOCIADO_NOMBRE_GRAFICO.PARADAS:
        if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.VALORES_PLAN){
          nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.PARADAS_INICIADAS_PLAN;
          nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.PARADAS_REAL_VS_PLAN;
          nombreTituloAndKey[2] =  NOMBRE_TRADUCCION.PARADAS_REAL_VS_PLAN;
          nombreTituloAndKey[3] =  NOMBRE_TRADUCCION.PARADAS_REAL_VS_PLAN;
        }else if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.VALORES_REAL){
          nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.PARADAS_INICIADAS_REAL;
          nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.PARADAS_REAL_VS_PLAN;
          nombreTituloAndKey[2] =  NOMBRE_TRADUCCION.PARADAS_REAL_VS_PLAN;
          nombreTituloAndKey[3] =  NOMBRE_TRADUCCION.PARADAS_REAL_VS_PLAN;
        }else if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.PORCENTAJE){
          nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.PARADAS_INICIADAS_PORCENTAJE;
          nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.PARADAS_PORCENTAJE_REAL_VS_PLAN;
          nombreTituloAndKey[2] = NOMBRE_TRADUCCION.PARADAS_PORCENTAJE_REAL_VS_PLAN;
          nombreTituloAndKey[3] =  NOMBRE_TRADUCCION.PARADAS_REAL_VS_PLAN;
        }  
      break;
      case NUMERO_ASOCIADO_NOMBRE_GRAFICO.ENTREGAR_UNIDAD1:
        if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.VALORES_PLAN){
          nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.ENTREGADO_UNIDAD1_PLAN;
          nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.ENTREGADO_UNIDAD1_REAL_VS_PLAN;
          nombreTituloAndKey[2] = NOMBRE_TRADUCCION.ENTREGADO_UNIDAD1_REAL_VS_PLAN;
          nombreTituloAndKey[3] = NOMBRE_TRADUCCION.ENTREGADO_UNIDAD1_REAL_VS_PLAN;
        }else if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.VALORES_REAL){
          nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.ENTREGADO_UNIDAD1_REAL;
          nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.ENTREGADO_UNIDAD1_REAL_VS_PLAN;
          nombreTituloAndKey[2] = NOMBRE_TRADUCCION.ENTREGADO_UNIDAD1_REAL_VS_PLAN;
          nombreTituloAndKey[3] = NOMBRE_TRADUCCION.ENTREGADO_UNIDAD1_REAL_VS_PLAN;
        }else if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.PORCENTAJE){
          nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.ENTREGADO_UNIDAD1_PORCENTAJE;
          nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.ENTREGADO_UNIDAD1_PORCENTAJE_REAL_VS_PLAN;
          nombreTituloAndKey[2] = NOMBRE_TRADUCCION.ENTREGADO_UNIDAD1_PORCENTAJE_REAL_VS_PLAN;
          nombreTituloAndKey[3] = NOMBRE_TRADUCCION.ENTREGADO_UNIDAD1_REAL_VS_PLAN;
        }
      break;
      case NUMERO_ASOCIADO_NOMBRE_GRAFICO.ENTREGAR_UNIDAD2:
        if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.VALORES_PLAN){
          nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.ENTREGADO_UNIDAD2_PLAN;
          nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.ENTREGADO_UNIDAD2_REAL_VS_PLAN;
          nombreTituloAndKey[2] = NOMBRE_TRADUCCION.ENTREGADO_UNIDAD2_REAL_VS_PLAN;
          nombreTituloAndKey[3] = NOMBRE_TRADUCCION.ENTREGADO_UNIDAD2_REAL_VS_PLAN;
        }else if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.VALORES_REAL){
          nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.ENTREGADO_UNIDAD2_REAL;
          nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.ENTREGADO_UNIDAD2_REAL_VS_PLAN;
          nombreTituloAndKey[2] = NOMBRE_TRADUCCION.ENTREGADO_UNIDAD2_REAL_VS_PLAN;
          nombreTituloAndKey[3] = NOMBRE_TRADUCCION.ENTREGADO_UNIDAD2_REAL_VS_PLAN;
        }else if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.PORCENTAJE){
          nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.ENTREGADO_UNIDAD2_PORCENTAJE;
          nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.ENTREGADO_UNIDAD2_PORCENTAJE_REAL_VS_PLAN;
          nombreTituloAndKey[2] = NOMBRE_TRADUCCION.ENTREGADO_UNIDAD2_PORCENTAJE_REAL_VS_PLAN;
          nombreTituloAndKey[3] = NOMBRE_TRADUCCION.ENTREGADO_UNIDAD2_REAL_VS_PLAN;
        }
      break;
      case NUMERO_ASOCIADO_NOMBRE_GRAFICO.ENTREGAR_UNIDAD3:
        if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.VALORES_PLAN){
          nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.ENTREGADO_UNIDAD3_PLAN;
          nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.ENTREGADO_UNIDAD3_REAL_VS_PLAN;
          nombreTituloAndKey[2] = NOMBRE_TRADUCCION.ENTREGADO_UNIDAD3_REAL_VS_PLAN;
          nombreTituloAndKey[3] = NOMBRE_TRADUCCION.ENTREGADO_UNIDAD3_REAL_VS_PLAN;
        }else if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.VALORES_REAL){
          nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.ENTREGADO_UNIDAD3_REAL;
          nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.ENTREGADO_UNIDAD3_REAL_VS_PLAN;
          nombreTituloAndKey[2] = NOMBRE_TRADUCCION.ENTREGADO_UNIDAD3_REAL_VS_PLAN;
          nombreTituloAndKey[3] = NOMBRE_TRADUCCION.ENTREGADO_UNIDAD3_REAL_VS_PLAN;
        }else if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.PORCENTAJE){
          nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.ENTREGADO_UNIDAD3_PORCENTAJE;
          nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.ENTREGADO_UNIDAD3_PORCENTAJE_REAL_VS_PLAN;
          nombreTituloAndKey[2] = NOMBRE_TRADUCCION.ENTREGADO_UNIDAD3_PORCENTAJE_REAL_VS_PLAN;
          nombreTituloAndKey[3] = NOMBRE_TRADUCCION.ENTREGADO_UNIDAD3_REAL_VS_PLAN;
        }      
        break;
        case NUMERO_ASOCIADO_NOMBRE_GRAFICO.RETIRAR_UNIDAD1:
          if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.VALORES_PLAN){
            nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.RETIRADO_UNIDAD1_PLAN;
            nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.RETIRADO_UNIDAD1_REAL_VS_PLAN;
            nombreTituloAndKey[2] = NOMBRE_TRADUCCION.RETIRADO_UNIDAD1_REAL_VS_PLAN;
            nombreTituloAndKey[3] = NOMBRE_TRADUCCION.RETIRADO_UNIDAD1_REAL_VS_PLAN;
          }else if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.VALORES_REAL){
            nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.RETIRADO_UNIDAD1_REAL;
            nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.RETIRADO_UNIDAD1_REAL_VS_PLAN;
            nombreTituloAndKey[2] = NOMBRE_TRADUCCION.RETIRADO_UNIDAD1_REAL_VS_PLAN;
            nombreTituloAndKey[3] = NOMBRE_TRADUCCION.RETIRADO_UNIDAD1_REAL_VS_PLAN;
          }else if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.PORCENTAJE){
            nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.RETIRADO_UNIDAD1_PORCENTAJE;
            nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.RETIRADO_UNIDAD1_PORCENTAJE_REAL_VS_PLAN;
            nombreTituloAndKey[2] = NOMBRE_TRADUCCION.RETIRADO_UNIDAD1_PORCENTAJE_REAL_VS_PLAN;
            nombreTituloAndKey[3] = NOMBRE_TRADUCCION.RETIRADO_UNIDAD1_REAL_VS_PLAN;
          }
        break;
        case NUMERO_ASOCIADO_NOMBRE_GRAFICO.RETIRAR_UNIDAD2:
          if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.VALORES_PLAN){
            nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.RETIRADO_UNIDAD2_PLAN;
            nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.RETIRADO_UNIDAD2_REAL_VS_PLAN;
            nombreTituloAndKey[2] = NOMBRE_TRADUCCION.RETIRADO_UNIDAD2_REAL_VS_PLAN;
            nombreTituloAndKey[3] = NOMBRE_TRADUCCION.RETIRADO_UNIDAD2_REAL_VS_PLAN;
          }else if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.VALORES_REAL){
            nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.RETIRADO_UNIDAD2_REAL;
            nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.RETIRADO_UNIDAD2_REAL_VS_PLAN;
            nombreTituloAndKey[2] = NOMBRE_TRADUCCION.RETIRADO_UNIDAD2_REAL_VS_PLAN;
            nombreTituloAndKey[3] = NOMBRE_TRADUCCION.RETIRADO_UNIDAD2_REAL_VS_PLAN;
          }else if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.PORCENTAJE){
            nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.RETIRADO_UNIDAD2_PORCENTAJE;
            nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.RETIRADO_UNIDAD2_PORCENTAJE_REAL_VS_PLAN;
            nombreTituloAndKey[2] = NOMBRE_TRADUCCION.RETIRADO_UNIDAD2_PORCENTAJE_REAL_VS_PLAN;
            nombreTituloAndKey[3] = NOMBRE_TRADUCCION.RETIRADO_UNIDAD2_REAL_VS_PLAN;
          }
        break;
        case NUMERO_ASOCIADO_NOMBRE_GRAFICO.RETIRAR_UNIDAD3:
          if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.VALORES_PLAN){
            nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.RETIRADO_UNIDAD3_PLAN;
            nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.RETIRADO_UNIDAD3_REAL_VS_PLAN;
            nombreTituloAndKey[2] = NOMBRE_TRADUCCION.RETIRADO_UNIDAD3_REAL_VS_PLAN;
            nombreTituloAndKey[3] = NOMBRE_TRADUCCION.RETIRADO_UNIDAD3_REAL_VS_PLAN;
          }else if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.VALORES_REAL){
            nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.RETIRADO_UNIDAD3_REAL;
            nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.RETIRADO_UNIDAD3_REAL_VS_PLAN;
            nombreTituloAndKey[2] = NOMBRE_TRADUCCION.RETIRADO_UNIDAD3_REAL_VS_PLAN;
            nombreTituloAndKey[3] = NOMBRE_TRADUCCION.RETIRADO_UNIDAD3_REAL_VS_PLAN;
          }else if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.PORCENTAJE){
            nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.RETIRADO_UNIDAD3_PORCENTAJE;
            nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.RETIRADO_UNIDAD3_PORCENTAJE_REAL_VS_PLAN;
            nombreTituloAndKey[2] = NOMBRE_TRADUCCION.RETIRADO_UNIDAD3_PORCENTAJE_REAL_VS_PLAN;
            nombreTituloAndKey[3] = NOMBRE_TRADUCCION.RETIRADO_UNIDAD3_REAL_VS_PLAN;
          }      
          break;
      case NUMERO_ASOCIADO_NOMBRE_GRAFICO.DISTANCIA:
        if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.VALORES_PLAN){
          nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.DISTANCIA_PLAN;
          nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.DISTANCIA_REAL_VS_PLAN;
          nombreTituloAndKey[2] = NOMBRE_TRADUCCION.DISTANCIA_REAL_VS_PLAN;
          nombreTituloAndKey[3] = NOMBRE_TRADUCCION.DISTANCIA_REAL_VS_PLAN;
        }else if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.VALORES_REAL){
          nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.DISTANCIA_REAL;
          nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.DISTANCIA_REAL_VS_PLAN;
          nombreTituloAndKey[2] = NOMBRE_TRADUCCION.DISTANCIA_REAL_VS_PLAN;
          nombreTituloAndKey[3] = NOMBRE_TRADUCCION.DISTANCIA_REAL_VS_PLAN;
        }else if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.PORCENTAJE){
          nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.DISTANCIA_PORCENTAJE;
          nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.DISTANCIA_PORCENTAJE_REAL_VS_PLAN;
          nombreTituloAndKey[2] = NOMBRE_TRADUCCION.DISTANCIA_PORCENTAJE_REAL_VS_PLAN;
          nombreTituloAndKey[3] = NOMBRE_TRADUCCION.DISTANCIA_REAL_VS_PLAN;
        }      
        break;
      case NUMERO_ASOCIADO_NOMBRE_GRAFICO.PARADAS_PROCESADAS:
        if(index2==0){
          nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.PARADAS_INICIADAS_REAL;
          nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.PARADAS_REAL_VS_PROCESADAS_EN_TIEMPO;
          nombreTituloAndKey[2] = NOMBRE_TRADUCCION.PARADAS_REAL_VS_PROCESADAS_EN_TIEMPO;
          nombreTituloAndKey[3] = NOMBRE_TRADUCCION.PARADAS_REAL_VS_PROCESADAS_EN_TIEMPO;
        }else if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.VALORES_REAL){
          nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.PARADAS_PROCESADAS_EN_TIEMPO;
          nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.PARADAS_REAL_VS_PROCESADAS_EN_TIEMPO;
          nombreTituloAndKey[2] = NOMBRE_TRADUCCION.PARADAS_REAL_VS_PROCESADAS_EN_TIEMPO;
          nombreTituloAndKey[3] = NOMBRE_TRADUCCION.PARADAS_REAL_VS_PROCESADAS_EN_TIEMPO;
        }else if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.PORCENTAJE){
          nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.PORCENTAJE_PARADAS_PROCESADAS_EN_TIEMPO;
          nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.PORCENTAJE_PARADAS_REAL_VS_PROCESADAS_EN_TIEMPO;
          nombreTituloAndKey[2] = NOMBRE_TRADUCCION.PORCENTAJE_PARADAS_REAL_VS_PROCESADAS_EN_TIEMPO;
          nombreTituloAndKey[3] = NOMBRE_TRADUCCION.PARADAS_REAL_VS_PROCESADAS_EN_TIEMPO;
        }      
        break;
      default:
        // Lógica predeterminada si no coincide con ninguna opción
      break;
    }
    return nombreTituloAndKey;
  }
  
  export function selectDateRangeTraduccion(range: RANGO_FECHAS):string {
    let traduccionRangeSeleccionado:string = '';
    switch (range) {
      case RANGO_FECHAS.AYER:
        traduccionRangeSeleccionado = 'agrupadorPeriodos.ayer';
        break;
      case RANGO_FECHAS.MES_ACTUAL:
        traduccionRangeSeleccionado = 'agrupadorPeriodos.mesActual';
        break;
      case RANGO_FECHAS.MES_PASADO:
        traduccionRangeSeleccionado = 'agrupadorPeriodos.mesPasado';
        break;
      case RANGO_FECHAS.ULTIMOS_7_DIAS:
        traduccionRangeSeleccionado = 'agrupadorPeriodos.ultimos7Dias';
        break;
      case RANGO_FECHAS.ULTIMOS_10_DIAS:
        traduccionRangeSeleccionado = 'agrupadorPeriodos.ultimos10Dias';
        break;
      case RANGO_FECHAS.ULTIMOS_15_DIAS:
          traduccionRangeSeleccionado = 'agrupadorPeriodos.ultimos15Dias';
        break;
      case RANGO_FECHAS.ULTIMOS_30_DIAS:
        traduccionRangeSeleccionado = 'agrupadorPeriodos.ultimos30Dias';
        break;
      case RANGO_FECHAS.ULTIMOS_90_DIAS:
        traduccionRangeSeleccionado = 'agrupadorPeriodos.ultimos90Dias';
        break;
      case RANGO_FECHAS.ULTIMOS_12_MESES:
        traduccionRangeSeleccionado = 'agrupadorPeriodos.ultimos12Meses';
        break;
      case RANGO_FECHAS.ESTE_ANIO:
        traduccionRangeSeleccionado = 'agrupadorPeriodos.esteAnio';
        break;
      case RANGO_FECHAS.EL_ANIO_PASADO:
        traduccionRangeSeleccionado = 'agrupadorPeriodos.elAnioPasado';
        break;
      default:
        traduccionRangeSeleccionado = 'agrupadorPeriodos.personalizado';
        // Lógica predeterminada si no coincide con ninguna opción
        break;
    }
    return traduccionRangeSeleccionado;
  }
  
  export function selectDateRangeHelper(range: RANGO_FECHAS, calendar: NgbCalendar):NgbDate[] {
    let fechaActual = new Date();
  
    let fromDate: NgbDate;
    let toDate: NgbDate;
    let today = calendar.getPrev(calendar.getToday(), 'd', 1);
  
    switch (range) {
      case RANGO_FECHAS.AYER:
        fromDate = calendar.getPrev(calendar.getToday(), 'd', 1);
        toDate = calendar.getPrev(calendar.getToday(), 'd', 1);
        break;
      
      case RANGO_FECHAS.MES_ACTUAL:
        let firstDayOfMonth = new NgbDate(today.year, today.month, 1);
        fromDate = firstDayOfMonth;
        toDate = today;
        break;
  
      case RANGO_FECHAS.MES_PASADO:
        let lastMonth = calendar.getPrev(calendar.getToday(), 'm', 1);
        let firstDayOfLastMonth = new NgbDate(lastMonth.year, lastMonth.month, 1);
        fromDate = firstDayOfLastMonth;
        // Establece la fecha en el mes anterior
        fechaActual.setMonth(fechaActual.getMonth());
        //estable el ultimo día del mes anterior
        fechaActual.setDate(0); 
        // La fecha ahora representa el último día del mes
        let ultimoDiaDelMesAnterior = fechaActual.getDate();
        toDate = new NgbDate(lastMonth.year, lastMonth.month, ultimoDiaDelMesAnterior);
        break;
      case RANGO_FECHAS.ULTIMOS_7_DIAS:
        let prev7Days = calendar.getPrev(today, 'd', 6);
        fromDate = prev7Days;
        toDate = today;
        break;
      case RANGO_FECHAS.ULTIMOS_10_DIAS:
        let prev10Days = calendar.getPrev(today, 'd', 9);
        fromDate = prev10Days;
        toDate = today;
        break;
      case RANGO_FECHAS.ULTIMOS_15_DIAS:
          let prev15Days = calendar.getPrev(today, 'd', 14);
          fromDate = prev15Days;
          toDate = today;
        break;
      case RANGO_FECHAS.ULTIMOS_30_DIAS:
        let prev30Days = calendar.getPrev(today, 'd', 29);
        fromDate = prev30Days;
        toDate = today;
        break;
      case RANGO_FECHAS.ULTIMOS_90_DIAS:
        let prev90Days = calendar.getPrev(today, 'd', 89);
        fromDate = prev90Days;
        toDate = today;
        break;
      case RANGO_FECHAS.ULTIMOS_12_MESES:
        let prevYear = today.year - 1;
        let firstDayOfPrevYear = new NgbDate(prevYear, today.month, today.day);
        fromDate = firstDayOfPrevYear;
        toDate = today;
        break;
      case RANGO_FECHAS.ESTE_ANIO:
        let firstDayOfCurrentYear = new NgbDate(today.year, 1, 1);
        // let lastDayOfCurrentYear = new NgbDate(today.year, 12, 31);
        fromDate = firstDayOfCurrentYear;
        toDate = today;
        break;
      case RANGO_FECHAS.EL_ANIO_PASADO:
        let firstDayOfLastYear = new NgbDate(today.year - 1, 1, 1);
        let lastDayOfLastYear = new NgbDate(today.year - 1, 12, 31);
        fromDate = firstDayOfLastYear;
        toDate = lastDayOfLastYear;
        break;
      default:
        // Lógica predeterminada si no coincide con ninguna opción
        break;
    }
  
    return [fromDate,toDate];
  }

export function getDateWithValueInDocument(fechaInDocument:Date,fecha:Date, isWeekDesde:boolean):Date{ 
  if(isWeekDesde && fecha != fechaInDocument){
    if(fecha >fechaInDocument){
      console.error('La Fecha desde no puede ser mayor a la Fecha desde del documento. Error en getDateWithValueInDocument. Fecha desde ', fecha, ' Fecha desde del documento ',fechaInDocument);
    }else if(fecha < fechaInDocument){
      fecha.setDate(fecha.getDate() - 1); //incluyo el dia anterior para completar los documentos
    }
  }

  if(!isWeekDesde && fecha != fechaInDocument){
    if(fecha < fechaInDocument){
      console.error('La Fecha hasta no puede ser menor a la Fecha hasta del documento. Error en getDateWithValueInDocument. Fecha hasta ', fecha, ' Fecha hasta del documento ',fechaInDocument);
    }else if(fecha > fechaInDocument){ //incluyo el dia posterior para completar los documentos que estan despues
      fecha.setDate(fecha.getDate() + 1);
    }
  }
  return fecha;
}





export function  addValuesInFunctionAndReturnArrayGraphicFunctionGroupByChoferesAndUbicaciones(listadoPrincipal:TableroControl[], tableroDeControl:TABLERO_CONTROL):GraphicFunction[]{

  let arrayGraphicFunction:GraphicFunction[] = [];

  let functionRutasIniciadasPlanReal = new GraphicFunction();
  functionRutasIniciadasPlanReal.setIdGraphicFunction(13);
  functionRutasIniciadasPlanReal.setName(NOMBRE_TRADUCCION.RUTAS);
  functionRutasIniciadasPlanReal.setListAxisY1([]);
  functionRutasIniciadasPlanReal.setListAxisY2([]);
  functionRutasIniciadasPlanReal.setListAxisY3([]);

  let functionParadasIniciadasPlanReal = new GraphicFunction();
  functionParadasIniciadasPlanReal.setIdGraphicFunction(14);
  functionParadasIniciadasPlanReal.setName(NOMBRE_TRADUCCION.PARADAS);
  functionParadasIniciadasPlanReal.setListAxisY1([]);
  functionParadasIniciadasPlanReal.setListAxisY2([]);
  functionParadasIniciadasPlanReal.setListAxisY3([]);

  let functionParadasIniciadasPlanProcesadasEnTiempo = new GraphicFunction();
  functionParadasIniciadasPlanProcesadasEnTiempo.setIdGraphicFunction(15);
  functionParadasIniciadasPlanProcesadasEnTiempo.setName(NOMBRE_TRADUCCION.PARADAS_PROCESADAS_EN_TIEMPO);
   functionParadasIniciadasPlanProcesadasEnTiempo.setListAxisY1([]);
  functionParadasIniciadasPlanProcesadasEnTiempo.setListAxisY2([]);
  functionParadasIniciadasPlanProcesadasEnTiempo.setListAxisY3([]);

  let functionEntregadoCantidad1PlanReal = new GraphicFunction();
  functionEntregadoCantidad1PlanReal.setIdGraphicFunction(16);
  functionEntregadoCantidad1PlanReal.setName(NOMBRE_TRADUCCION.ENTREGADO_UNIDAD_1);
  functionEntregadoCantidad1PlanReal.setListAxisY1([]);
  functionEntregadoCantidad1PlanReal.setListAxisY2([]);
  functionEntregadoCantidad1PlanReal.setListAxisY3([]);

  let functionEntregadoCantidad2PlanReal = new GraphicFunction();
  functionEntregadoCantidad2PlanReal.setIdGraphicFunction(17);
  functionEntregadoCantidad2PlanReal.setName(NOMBRE_TRADUCCION.ENTREGADO_UNIDAD_2);
  functionEntregadoCantidad2PlanReal.setListAxisY1([]);
  functionEntregadoCantidad2PlanReal.setListAxisY2([]);
  functionEntregadoCantidad2PlanReal.setListAxisY3([]);

  let functionEntregadoCantidad3PlanReal = new GraphicFunction();
  functionEntregadoCantidad3PlanReal.setIdGraphicFunction(18);
  functionEntregadoCantidad3PlanReal.setName(NOMBRE_TRADUCCION.ENTREGADO_UNIDAD_3);
  functionEntregadoCantidad3PlanReal.setListAxisY1([]);
  functionEntregadoCantidad3PlanReal.setListAxisY2([]);
  functionEntregadoCantidad3PlanReal.setListAxisY3([]);


  let functionRetiradoCantidad1PlanReal = new GraphicFunction();
  functionRetiradoCantidad1PlanReal.setIdGraphicFunction(19);
  functionRetiradoCantidad1PlanReal.setName(NOMBRE_TRADUCCION.RETIRADO_UNIDAD_1);
  functionRetiradoCantidad1PlanReal.setListAxisY1([]);
  functionRetiradoCantidad1PlanReal.setListAxisY2([]);
  functionRetiradoCantidad1PlanReal.setListAxisY3([]);

  let functionRetiradoCantidad2PlanReal = new GraphicFunction();
  functionRetiradoCantidad2PlanReal.setIdGraphicFunction(20);
  functionRetiradoCantidad2PlanReal.setName(NOMBRE_TRADUCCION.RETIRADO_UNIDAD_2);
  functionRetiradoCantidad2PlanReal.setListAxisY1([]);
  functionRetiradoCantidad2PlanReal.setListAxisY2([]);
  functionRetiradoCantidad2PlanReal.setListAxisY3([]);

  let functionRetiradoCantidad3PlanReal = new GraphicFunction();
  functionRetiradoCantidad3PlanReal.setIdGraphicFunction(21);
  functionRetiradoCantidad3PlanReal.setName(NOMBRE_TRADUCCION.RETIRADO_UNIDAD_3);
  functionRetiradoCantidad3PlanReal.setListAxisY1([]);
  functionRetiradoCantidad3PlanReal.setListAxisY2([]);
  functionRetiradoCantidad3PlanReal.setListAxisY3([]);


  let functionDistanciaPlanReal = new GraphicFunction();
  functionDistanciaPlanReal.setIdGraphicFunction(22);
  functionDistanciaPlanReal.setName(NOMBRE_TRADUCCION.DISTANCIA_RECORRIDA);
  functionDistanciaPlanReal.setListAxisY1([]);
  functionDistanciaPlanReal.setListAxisY2([]);
  functionDistanciaPlanReal.setListAxisY3([]);

  let functionEstadosParadas = new GraphicFunction();
  functionEstadosParadas.setIdGraphicFunction(23);
  functionEstadosParadas.setName(NOMBRE_TRADUCCION.ESTADOS_PARADAS_AGRUPADO_POR_UBICACION);
  functionEstadosParadas.setListAxisY1([]);
  functionEstadosParadas.setListAxisY2([]);
  functionEstadosParadas.setListAxisY3([]);
  functionEstadosParadas.setListAxisY4([]);
  functionEstadosParadas.setListAxisY5([]);
  functionEstadosParadas.setListAxisY6([]);
  functionEstadosParadas.setListAxisY7([]);

  for(let i=0; i<listadoPrincipal.length; i++){
    let axisY:string = '';
    if(tableroDeControl==TABLERO_CONTROL.UNIVERSO_UBICACIONES){
      axisY = listadoPrincipal[i].getUbicacionNombre().trim();
    }else if(tableroDeControl==TABLERO_CONTROL.UNIVERSO_CHOFERES){
      let nombreCompleto:string = listadoPrincipal[i].getIntegranteChoferNombre().trim();
      let partesNombreCompleto: string[] = nombreCompleto.split(',');
      axisY = nombreCompleto;
      if(partesNombreCompleto.length>0 && partesNombreCompleto[1] != null && partesNombreCompleto[1] != ''){
        axisY = partesNombreCompleto[1];
      }
    }else{
      axisY = 'SIN AXIS Y';
      console.error('No esta contemplado el tablero de control: '+tableroDeControl);
    }
    functionRutasIniciadasPlanReal.addFromOnePointToSeven(axisY, listadoPrincipal[i].getRutasIniciadasPlan(), listadoPrincipal[i].getRutasIniciadasReal(), listadoPrincipal[i].getPorcentajeRutasIniciadas());
    functionParadasIniciadasPlanReal.addFromOnePointToSeven(axisY, listadoPrincipal[i].getParadasIniciadasPlan(), listadoPrincipal[i].getParadasIniciadasReal(), listadoPrincipal[i].getPorcentajeParadasIniciadas());
    functionParadasIniciadasPlanProcesadasEnTiempo.addFromOnePointToSeven(axisY,listadoPrincipal[i].getParadasIniciadasReal(), listadoPrincipal[i].getParadasProcesadasEnTiempo(), listadoPrincipal[i].getPorcentajeParadasEntregadasEnTiempo());
    functionEntregadoCantidad1PlanReal.addFromOnePointToSeven(axisY, listadoPrincipal[i].getEntregarUnidad1Plan(), listadoPrincipal[i].getEntregarUnidad1Real(), listadoPrincipal[i].getPorcentajeEntregadoUnidad1());
    functionEntregadoCantidad2PlanReal.addFromOnePointToSeven(axisY,listadoPrincipal[i].getEntregarUnidad2Plan(), listadoPrincipal[i].getEntregarUnidad2Real(), listadoPrincipal[i].getPorcentajeEntregadoUnidad2());
    functionEntregadoCantidad3PlanReal.addFromOnePointToSeven(axisY,listadoPrincipal[i].getEntregarUnidad3Plan(), listadoPrincipal[i].getEntregarUnidad3Real(), listadoPrincipal[i].getPorcentajeEntregadoUnidad3());
    functionRetiradoCantidad1PlanReal.addFromOnePointToSeven(axisY, listadoPrincipal[i].getRetirarUnidad1Plan(), listadoPrincipal[i].getRetirarUnidad1Real(), listadoPrincipal[i].getPorcentajeRetiradoUnidad1());
    functionRetiradoCantidad2PlanReal.addFromOnePointToSeven(axisY,listadoPrincipal[i].getRetirarUnidad2Plan(), listadoPrincipal[i].getRetirarUnidad2Real(), listadoPrincipal[i].getPorcentajeRetiradoUnidad2());
    functionRetiradoCantidad3PlanReal.addFromOnePointToSeven(axisY,listadoPrincipal[i].getRetirarUnidad3Plan(), listadoPrincipal[i].getRetirarUnidad3Real(), listadoPrincipal[i].getPorcentajeRetiradoUnidad3());
    functionDistanciaPlanReal.addFromOnePointToSeven(axisY, listadoPrincipal[i].getDistanciaPlan(), listadoPrincipal[i].getDistanciaReal(), listadoPrincipal[i].getPorcentajeDistancia());
    functionEstadosParadas.addFromOnePointToSeven(
      axisY,
      listadoPrincipal[i].getParadasCancelada(),
      listadoPrincipal[i].getParadasRechazada(), 
      listadoPrincipal[i].getParadasPendiente(),  
      listadoPrincipal[i].getParadasEntregaCompleta(),
      listadoPrincipal[i].getParadasEntregaParcial(),  
      listadoPrincipal[i].getParadasEnCurso(), 
      listadoPrincipal[i].getParadasReplanificada()
    );
  }

  arrayGraphicFunction = [functionRutasIniciadasPlanReal, functionParadasIniciadasPlanReal, functionParadasIniciadasPlanProcesadasEnTiempo, 
                          functionEntregadoCantidad1PlanReal, functionEntregadoCantidad2PlanReal, functionEntregadoCantidad3PlanReal, 
                          functionRetiradoCantidad1PlanReal, functionRetiradoCantidad2PlanReal, functionRetiradoCantidad3PlanReal,
                          functionDistanciaPlanReal, functionEstadosParadas];
  return arrayGraphicFunction;
}

export function  updateValuesInFunctionAndReturnArrayGraphicFunctionsGroupByChoferesAndUbicaciones(listadoPrincipal:TableroControl[],
  functionRutasIniciadasPlanReal:GraphicFunction, functionParadasIniciadasPlanReal:GraphicFunction, functionParadasIniciadasPlanProcesadasEnTiempo:GraphicFunction,
  functionEntregadoCantidad1PlanReal:GraphicFunction, functionEntregadoCantidad2PlanReal:GraphicFunction, functionEntregadoCantidad3PlanReal:GraphicFunction, 
  functionRetiradoCantidad1PlanReal:GraphicFunction, functionRetiradoCantidad2PlanReal:GraphicFunction, functionRetiradoCantidad3PlanReal:GraphicFunction, 
  functionDistanciaPlanReal:GraphicFunction, functionEstadosParadas:GraphicFunction, tableroDeControl:TABLERO_CONTROL):GraphicFunction[]{
        
  let arrayGraphicFunction:GraphicFunction[] = [];

  functionRutasIniciadasPlanReal.resetPoints();
  functionParadasIniciadasPlanReal.resetPoints();
  functionParadasIniciadasPlanProcesadasEnTiempo.resetPoints();
  functionEntregadoCantidad1PlanReal.resetPoints();
  functionEntregadoCantidad2PlanReal.resetPoints();
  functionEntregadoCantidad3PlanReal.resetPoints();
  functionRetiradoCantidad1PlanReal.resetPoints();
  functionRetiradoCantidad2PlanReal.resetPoints();
  functionRetiradoCantidad3PlanReal.resetPoints();

  functionDistanciaPlanReal.resetPoints();
  functionEstadosParadas.resetPoints();
 
  for(let i=0; i<listadoPrincipal.length; i++){
    let axisY:string = '';
    if(tableroDeControl==TABLERO_CONTROL.UNIVERSO_UBICACIONES){
      axisY = listadoPrincipal[i].getUbicacionNombre().trim();
    }else if(tableroDeControl==TABLERO_CONTROL.UNIVERSO_CHOFERES){
      let nombreCompleto:string = listadoPrincipal[i].getIntegranteChoferNombre().trim();
      let partesNombreCompleto: string[] = nombreCompleto.split(',');
      axisY = nombreCompleto;
      if(partesNombreCompleto.length>0 && partesNombreCompleto[1] != null && partesNombreCompleto[1] != ''){
        axisY = partesNombreCompleto[1];
      }
    }else{
      axisY = 'SIN AXIS Y';
      console.error('No esta contemplado el tablero de control: '+tableroDeControl);
    }
    functionRutasIniciadasPlanReal.addFromOnePointToSeven(axisY, listadoPrincipal[i].getRutasIniciadasPlan(), listadoPrincipal[i].getRutasIniciadasReal(), listadoPrincipal[i].getPorcentajeRutasIniciadas());
    functionParadasIniciadasPlanReal.addFromOnePointToSeven(axisY, listadoPrincipal[i].getParadasIniciadasPlan(), listadoPrincipal[i].getParadasIniciadasReal(), listadoPrincipal[i].getPorcentajeParadasIniciadas());
    functionParadasIniciadasPlanProcesadasEnTiempo.addFromOnePointToSeven(axisY,listadoPrincipal[i].getParadasIniciadasReal(), listadoPrincipal[i].getParadasProcesadasEnTiempo(), listadoPrincipal[i].getPorcentajeParadasEntregadasEnTiempo());
    functionEntregadoCantidad1PlanReal.addFromOnePointToSeven(axisY, listadoPrincipal[i].getEntregarUnidad1Plan(), listadoPrincipal[i].getEntregarUnidad1Real(), listadoPrincipal[i].getPorcentajeEntregadoUnidad1());
    functionEntregadoCantidad2PlanReal.addFromOnePointToSeven(axisY,listadoPrincipal[i].getEntregarUnidad2Plan(), listadoPrincipal[i].getEntregarUnidad2Real(), listadoPrincipal[i].getPorcentajeEntregadoUnidad2());
    functionEntregadoCantidad3PlanReal.addFromOnePointToSeven(axisY,listadoPrincipal[i].getEntregarUnidad3Plan(), listadoPrincipal[i].getEntregarUnidad3Real(), listadoPrincipal[i].getPorcentajeEntregadoUnidad3());
    functionRetiradoCantidad1PlanReal.addFromOnePointToSeven(axisY, listadoPrincipal[i].getRetirarUnidad1Plan(), listadoPrincipal[i].getRetirarUnidad1Real(), listadoPrincipal[i].getPorcentajeRetiradoUnidad1());
    functionRetiradoCantidad2PlanReal.addFromOnePointToSeven(axisY,listadoPrincipal[i].getRetirarUnidad2Plan(), listadoPrincipal[i].getRetirarUnidad2Real(), listadoPrincipal[i].getPorcentajeRetiradoUnidad2());
    functionRetiradoCantidad3PlanReal.addFromOnePointToSeven(axisY,listadoPrincipal[i].getRetirarUnidad3Plan(), listadoPrincipal[i].getRetirarUnidad3Real(), listadoPrincipal[i].getPorcentajeRetiradoUnidad3());
    functionDistanciaPlanReal.addFromOnePointToSeven(axisY, listadoPrincipal[i].getDistanciaPlan(), listadoPrincipal[i].getDistanciaReal(), listadoPrincipal[i].getPorcentajeDistancia());
    functionEstadosParadas.addFromOnePointToSeven(
      axisY,
      listadoPrincipal[i].getParadasCancelada(),
      listadoPrincipal[i].getParadasRechazada(), 
      listadoPrincipal[i].getParadasPendiente(),  
      listadoPrincipal[i].getParadasEntregaCompleta(),
      listadoPrincipal[i].getParadasEntregaParcial(),  
      listadoPrincipal[i].getParadasEnCurso(), 
      listadoPrincipal[i].getParadasReplanificada()
    );
  }

  arrayGraphicFunction = [functionRutasIniciadasPlanReal, functionParadasIniciadasPlanReal, functionParadasIniciadasPlanProcesadasEnTiempo, 
                          functionEntregadoCantidad1PlanReal, functionEntregadoCantidad2PlanReal, functionEntregadoCantidad3PlanReal, 
                          functionRetiradoCantidad1PlanReal, functionRetiradoCantidad2PlanReal, functionRetiradoCantidad3PlanReal,
                          functionDistanciaPlanReal, functionEstadosParadas];
  return arrayGraphicFunction;
}



export function initGraphicsUniversoChoferesAndUbicaciones(arrayGraphicFunction: GraphicFunction[], translate:TranslateService, tableroDeControl:TABLERO_CONTROL):Promise<any>{
  return new Promise((resolve, reject)=>{
    try{
      let typeGraphic:ChartType;
      let typePointGraphic:TIPO_GRAFICO;
      let cantidadGraficosWithIdIsMayor11:number = -1;

      let scales: ChartScales | LinearScale | LogarithmicScale | TimeScale = {
        yAxes: [
          {
            ticks: {
              beginAtZero: true, // Para asegurarte de que comience desde 0
            }
          }
        ],
        xAxes: [
          {
            ticks: {
              beginAtZero: true, // Para asegurarte de que comience desde 0
            }
          }
        ],
      };

      for (let indexGraphicFunction = 0; indexGraphicFunction < arrayGraphicFunction.length; indexGraphicFunction++) {
        let functionGraphic:GraphicFunction = arrayGraphicFunction[indexGraphicFunction];
        if(functionGraphic.getIdGraphicFunction()>=13){
          functionGraphic.addPointToListAxis();
          
          if(functionGraphic.getName()==NOMBRE_TRADUCCION.MOTIVOS_PARADAS_AGRUPADO_POR_CHOFER || functionGraphic.getName()==NOMBRE_TRADUCCION.MOTIVOS_PARADAS_AGRUPADO_POR_UBICACION){
            if(tableroDeControl==TABLERO_CONTROL.UNIVERSO_CHOFERES){
              initGraphicsTiposMotivos(functionGraphic, translate, NOMBRE_TRADUCCION.MOTIVOS_PARADAS_AGRUPADO_POR_CHOFER, TIPO_GRAFICO.AGRUPADO_POR_CHOFER, 'horizontalBar');
            }else if(tableroDeControl==TABLERO_CONTROL.UNIVERSO_UBICACIONES){
              initGraphicsTiposMotivos(functionGraphic, translate, NOMBRE_TRADUCCION.MOTIVOS_PARADAS_AGRUPADO_POR_UBICACION, TIPO_GRAFICO.AGRUPADO_POR_UBICACION, 'horizontalBar');
            }else{
              console.error('No esta contemplado el tablero de control: '+tableroDeControl);
            }
          } 
          if(functionGraphic.getName()==NOMBRE_TRADUCCION.ESTADOS_PARADAS_AGRUPADO_POR_CHOFER || functionGraphic.getName()==NOMBRE_TRADUCCION.ESTADOS_PARADAS_AGRUPADO_POR_UBICACION){
            if(tableroDeControl==TABLERO_CONTROL.UNIVERSO_CHOFERES){
              initGraphicsEstadosParadas(functionGraphic, translate, NOMBRE_TRADUCCION.ESTADOS_PARADAS_AGRUPADO_POR_CHOFER, TIPO_GRAFICO.AGRUPADO_POR_CHOFER, 'line');
            }else if(tableroDeControl==TABLERO_CONTROL.UNIVERSO_UBICACIONES){
              initGraphicsEstadosParadas(functionGraphic, translate, NOMBRE_TRADUCCION.ESTADOS_PARADAS_AGRUPADO_POR_UBICACION, TIPO_GRAFICO.AGRUPADO_POR_UBICACION, 'line');
            }else{
              console.error('No esta contemplado el tablero de control: '+tableroDeControl);
            }
          }
          if(functionGraphic.getName()!=NOMBRE_TRADUCCION.ESTADOS_PARADAS_AGRUPADO_POR_CHOFER && functionGraphic.getName()!=NOMBRE_TRADUCCION.MOTIVOS_PARADAS_AGRUPADO_POR_CHOFER && functionGraphic.getName()!=NOMBRE_TRADUCCION.ESTADOS_PARADAS_AGRUPADO_POR_UBICACION && functionGraphic.getName()!=NOMBRE_TRADUCCION.MOTIVOS_PARADAS_AGRUPADO_POR_UBICACION){
            let axisX:string[] = functionGraphic.getListAxisX();
            let grafico: Graphic = new Graphic();
            grafico.setBackgroundColor([]);
            grafico.setHoverBackgroundColor([]);
            cantidadGraficosWithIdIsMayor11 += 1;
            for (let AxisYQuantity = 0; AxisYQuantity < functionGraphic.getHowManyYvaluesForXvalues(); AxisYQuantity++) {
              let axisY:number[] = functionGraphic.getListAxisY(AxisYQuantity);
              let nombreTituloAndKey:string[] = obtenerNombreTituloAndKeyGraphicoGroupedByChoferesAndUbicaciones(cantidadGraficosWithIdIsMayor11, AxisYQuantity);
              let colors:string[] = getColorsInGraphicWithGroupByChoferAndUbicacion(AxisYQuantity);
              
              if(tableroDeControl==TABLERO_CONTROL.UNIVERSO_CHOFERES){
                typePointGraphic = TIPO_GRAFICO.AGRUPADO_POR_CHOFER;
              }else if(tableroDeControl==TABLERO_CONTROL.UNIVERSO_UBICACIONES){
                typePointGraphic = TIPO_GRAFICO.AGRUPADO_POR_UBICACION;
              }else{
                console.error('No esta contemplado el tablero de control: '+tableroDeControl);
              }
              
              let anchoColumna: number = 0.5; //ocupa el 50% del ancho
              // let anchoColumna: number = 1; //ocupa el 100% del ancho
              typeGraphic='horizontalBar';

              let chartPoints:ChartPoint[] = []
              for (let index = 0; index < axisY.length; index++) {
                let oneAxisX:number = axisY[index];
                let oneAxisY:string = translate.instant(nombreTituloAndKey[0]);
                let chartPoint:ChartPoint = {
                  x: oneAxisX,
                  y: oneAxisY,
                  t: axisX[index]
                }
                chartPoints.push(chartPoint);
              }
              grafico.setChartDataValuesBarAndBarHorizontal(chartPoints, typeGraphic,  translate.instant(nombreTituloAndKey[0]), anchoColumna,colors[0],colors[1]);
              grafico.setTitulo(translate.instant(nombreTituloAndKey[1]));
              grafico.setShowTypeChart(false);
              grafico.setKey(nombreTituloAndKey[2]);
            }
            functionGraphic.getListGraphics().push(grafico);
            grafico.setScale(scales);
            grafico.setChartType(typeGraphic);
            grafico.setChartLegend(true);
            grafico.setTypePoint(typePointGraphic);
            grafico.setValuesAxisX(axisX);
            grafico.setVisualizar(true);
          }
          
        }
        else{
          //oculto los demas graficos
          for (let index = 0; index < functionGraphic.getListGraphics().length; index++) {
            let  graphic =  functionGraphic.getListGraphics()[index];
            graphic.setVisualizar(false);
          }
        }
      }
      resolve();
    }catch(error){
      reject(error);
    }
  });
}

export function getColorsInGraphicWithGroupByChoferAndUbicacion(numeroLinea:number):string[]{
  let colors:string[] = [];
  if(numeroLinea == 0){
    colors.push('#ffa400');
    colors.push('#ff6900');
  }else if(numeroLinea == 1){
    colors.push('#0095fe');
    colors.push('#0074cc');    
  }else{
    colors.push('#4AFF8D');
    colors.push('#008240');   
  }
  return colors;
}


export function obtenerNombreTituloAndKeyGraphicoGroupedByChoferesAndUbicaciones(index:number, index2:number):string[]{
  let nombreTituloAndKey: string[]= ['','', '', ''];
  switch (index) {
    case NUMERO_ASOCIADO_NOMBRE_GRAFICO.RUTAS:
      if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.VALORES_PLAN){
        nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.RUTAS_INICIADAS_PLAN;
        nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_RUTAS + '.' + NOMBRE_TRADUCCION.RUTAS;
        nombreTituloAndKey[2] = NOMBRE_TRADUCCION.RUTAS;
      }else if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.VALORES_REAL){
        nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.RUTAS_INICIADAS_REAL;
        nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_RUTAS + '.' + NOMBRE_TRADUCCION.RUTAS;
        nombreTituloAndKey[2] = NOMBRE_TRADUCCION.RUTAS;
      }else if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.PORCENTAJE){
        nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.RUTAS_INICIADAS_PORCENTAJE;
        nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_RUTAS + '.' + NOMBRE_TRADUCCION.RUTAS;
        nombreTituloAndKey[2] = NOMBRE_TRADUCCION.RUTAS;
      }
    break;
    case NUMERO_ASOCIADO_NOMBRE_GRAFICO.PARADAS:
      if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.VALORES_PLAN){
        nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.PARADAS_INICIADAS_PLAN;
        nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_RUTAS + '.' + NOMBRE_TRADUCCION.PARADAS;
        nombreTituloAndKey[2] = NOMBRE_TRADUCCION.PARADAS;
      }else if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.VALORES_REAL){
        nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.PARADAS_INICIADAS_REAL;
        nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_RUTAS + '.' + NOMBRE_TRADUCCION.PARADAS;
        nombreTituloAndKey[2] = NOMBRE_TRADUCCION.PARADAS;
      }else if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.PORCENTAJE){
        nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.PARADAS_INICIADAS_PORCENTAJE;
        nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_RUTAS + '.' + NOMBRE_TRADUCCION.PARADAS;
        nombreTituloAndKey[2] = NOMBRE_TRADUCCION.PARADAS;
      }  
    break;
    case NUMERO_ASOCIADO_NOMBRE_GRAFICO.ENTREGAR_UNIDAD1:
      if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.VALORES_PLAN){
        nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.ENTREGADO_UNIDAD1_PLAN;
        nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.ENTREGADO_UNIDAD_1;
        nombreTituloAndKey[2] = NOMBRE_TRADUCCION.ENTREGADO_UNIDAD_1;
      }else if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.VALORES_REAL){
        nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.ENTREGADO_UNIDAD1_REAL;
        nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.ENTREGADO_UNIDAD_1;
        nombreTituloAndKey[2] = NOMBRE_TRADUCCION.ENTREGADO_UNIDAD_1;
      }else if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.PORCENTAJE){
        nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.ENTREGADO_UNIDAD1_PORCENTAJE;
        nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.ENTREGADO_UNIDAD_1;
        nombreTituloAndKey[2] = NOMBRE_TRADUCCION.ENTREGADO_UNIDAD_1;
      }
    break;
    case NUMERO_ASOCIADO_NOMBRE_GRAFICO.ENTREGAR_UNIDAD2:
      if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.VALORES_PLAN){
        nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.ENTREGADO_UNIDAD2_PLAN;
        nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.ENTREGADO_UNIDAD_2;
        nombreTituloAndKey[2] = NOMBRE_TRADUCCION.ENTREGADO_UNIDAD_2;
      }else if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.VALORES_REAL){
        nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.ENTREGADO_UNIDAD2_REAL;
        nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.ENTREGADO_UNIDAD_2;
        nombreTituloAndKey[2] = NOMBRE_TRADUCCION.ENTREGADO_UNIDAD_2;
      }else if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.PORCENTAJE){
        nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.ENTREGADO_UNIDAD2_PORCENTAJE;
        nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.ENTREGADO_UNIDAD_2;
        nombreTituloAndKey[2] = NOMBRE_TRADUCCION.ENTREGADO_UNIDAD_2;
      }
    break;
    case NUMERO_ASOCIADO_NOMBRE_GRAFICO.ENTREGAR_UNIDAD3:
      if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.VALORES_PLAN){
        nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.ENTREGADO_UNIDAD3_PLAN;
        nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.ENTREGADO_UNIDAD_3;
        nombreTituloAndKey[2] = NOMBRE_TRADUCCION.ENTREGADO_UNIDAD_3;
      }else if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.VALORES_REAL){
        nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.ENTREGADO_UNIDAD3_REAL;
        nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.ENTREGADO_UNIDAD_3;
        nombreTituloAndKey[2] = NOMBRE_TRADUCCION.ENTREGADO_UNIDAD_3;
      }else if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.PORCENTAJE){
        nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.ENTREGADO_UNIDAD3_PORCENTAJE;
        nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.ENTREGADO_UNIDAD_3;
        nombreTituloAndKey[2] = NOMBRE_TRADUCCION.ENTREGADO_UNIDAD_3;
      }      
    break;
    case NUMERO_ASOCIADO_NOMBRE_GRAFICO.RETIRAR_UNIDAD1:
      if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.VALORES_PLAN){
        nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.RETIRADO_UNIDAD1_PLAN;
        nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.RETIRADO_UNIDAD_1;
        nombreTituloAndKey[2] = NOMBRE_TRADUCCION.RETIRADO_UNIDAD_1;
      }else if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.VALORES_REAL){
        nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.RETIRADO_UNIDAD1_REAL;
        nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.RETIRADO_UNIDAD_1;
        nombreTituloAndKey[2] = NOMBRE_TRADUCCION.RETIRADO_UNIDAD_1;
      }else if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.PORCENTAJE){
        nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.RETIRADO_UNIDAD1_PORCENTAJE;
        nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.RETIRADO_UNIDAD_1;
        nombreTituloAndKey[2] = NOMBRE_TRADUCCION.RETIRADO_UNIDAD_1;
      }
    break;
    case NUMERO_ASOCIADO_NOMBRE_GRAFICO.RETIRAR_UNIDAD2:
      if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.VALORES_PLAN){
        nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.RETIRADO_UNIDAD2_PLAN;
        nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.RETIRADO_UNIDAD_2;
        nombreTituloAndKey[2] = NOMBRE_TRADUCCION.RETIRADO_UNIDAD_2;
      }else if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.VALORES_REAL){
        nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.RETIRADO_UNIDAD2_REAL;
        nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.RETIRADO_UNIDAD_2;
        nombreTituloAndKey[2] = NOMBRE_TRADUCCION.RETIRADO_UNIDAD_2;
      }else if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.PORCENTAJE){
        nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.RETIRADO_UNIDAD2_PORCENTAJE;
        nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.RETIRADO_UNIDAD_2;
        nombreTituloAndKey[2] = NOMBRE_TRADUCCION.RETIRADO_UNIDAD_2;
      }
    break;
    case NUMERO_ASOCIADO_NOMBRE_GRAFICO.RETIRAR_UNIDAD3:
      if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.VALORES_PLAN){
        nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.RETIRADO_UNIDAD3_PLAN;
        nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.RETIRADO_UNIDAD_3;
        nombreTituloAndKey[2] = NOMBRE_TRADUCCION.RETIRADO_UNIDAD_3;
      }else if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.VALORES_REAL){
        nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.RETIRADO_UNIDAD3_REAL;
        nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.RETIRADO_UNIDAD_3;
        nombreTituloAndKey[2] = NOMBRE_TRADUCCION.RETIRADO_UNIDAD_3;
      }else if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.PORCENTAJE){
        nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.RETIRADO_UNIDAD3_PORCENTAJE;
        nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.RETIRADO_UNIDAD_3;
        nombreTituloAndKey[2] = NOMBRE_TRADUCCION.RETIRADO_UNIDAD_3;
      }      
    break;
    case NUMERO_ASOCIADO_NOMBRE_GRAFICO.DISTANCIA:
      if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.VALORES_PLAN){
        nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.DISTANCIA_PLAN;
        nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_INDICADOR_PORCENTAJE + '.' + NOMBRE_TRADUCCION.DISTANCIA_RECORRIDA;
        nombreTituloAndKey[2] = NOMBRE_TRADUCCION.DISTANCIA_RECORRIDA;
      }else if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.VALORES_REAL){
        nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.DISTANCIA_REAL;
        nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_INDICADOR_PORCENTAJE + '.' + NOMBRE_TRADUCCION.DISTANCIA_RECORRIDA;
        nombreTituloAndKey[2] = NOMBRE_TRADUCCION.DISTANCIA_RECORRIDA;
      }else if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.PORCENTAJE){
        nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.DISTANCIA_PORCENTAJE;
        nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_INDICADOR_PORCENTAJE + '.' + NOMBRE_TRADUCCION.DISTANCIA_RECORRIDA;
        nombreTituloAndKey[2] = NOMBRE_TRADUCCION.DISTANCIA_RECORRIDA;
      }      
      break;
    case NUMERO_ASOCIADO_NOMBRE_GRAFICO.PARADAS_PROCESADAS:
      if(index2==0){
        nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.PARADAS_INICIADAS_REAL;
        nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.PARADAS_PROCESADAS_EN_TIEMPO;
        nombreTituloAndKey[2] = NOMBRE_TRADUCCION.PARADAS_PROCESADAS_EN_TIEMPO;
      }else if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.VALORES_REAL){
        nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.PARADAS_PROCESADAS_EN_TIEMPO;
        nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.PARADAS_PROCESADAS_EN_TIEMPO;
        nombreTituloAndKey[2] = NOMBRE_TRADUCCION.PARADAS_PROCESADAS_EN_TIEMPO;
      }else if(index2==NUMERO_ASOCIADO_TIPO_GRAFICO.PORCENTAJE){
        nombreTituloAndKey[0] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.PORCENTAJE_PARADAS_PROCESADAS_EN_TIEMPO;
        nombreTituloAndKey[1] = MODULO_TRADUCCION.MODULO_GRAFICOS + '.' + NOMBRE_TRADUCCION.PARADAS_PROCESADAS_EN_TIEMPO;
        nombreTituloAndKey[2] = NOMBRE_TRADUCCION.PARADAS_PROCESADAS_EN_TIEMPO;
      }      
      break;
    default:
      // Lógica predeterminada si no coincide con ninguna opción
    break;
  }
  return nombreTituloAndKey;
}