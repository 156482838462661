import { Component, OnInit,  Input, Output, EventEmitter, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FuncionesService }     from '@maq-funciones';
declare let $: any;
@Component({
  selector: 'app-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.scss',
  '../../../base/page-base.page.scss']
})
export class SelectSearchComponent implements OnInit {

  @Output() public outputSetearFiltro = new EventEmitter<ResultadoDeFiltro>();
  @Input() public id: string = (Math.random() *1000000).toString();
  @Input() public filtroNombre: string; //es el nombre del filtro por el cual queremos filtrar.
  @Input() public filtroValue: string; //es el valor del filtro por el cual queremos filtrar dentro del listado.
  @Input() public visibleValueSelect:boolean = true; //TRUE: el valor mostrado en el HTML es el filtroValue, FALSE:el valor mostrado en el HTML es el filtroSelect.
  @Input() public filtroSelect: string; //es el valor que se va a mostrar en el HTML al usuario
  @Input() public filtroTraduccion: string; //es la traduccion del filtro.
  @Input() public accionForm: string;
  @Input() public isFiltro:boolean = true; //para determinar en que se usa, si es un filtro para la grilla o un select para el formulario
  @Input() public isUniqueValue:boolean = false; //TRUE: los valores de los filtros son únicos. FALSE: los valores de los filtros estan repetidos, agrega un contador al filtroSelect para identificadorlo

  //array real, puede ser de cualquier tipo de coleccion donde tiene que existir como KEY filtroValue y como VALUE filtroSelect
  //estructura soportada => [{filtroValue  : filtroSelect, key2:value2, etc...}, {filtroValue  : filtroSelect, key2:value2, etc...}]
  @Input() public listadoParaFiltrar: any[] =[];
  @Input() public listadoParaFiltrarIsArrayString: boolean =false; //determina si el listado para filtrar tiene un array de string o no

  @Input() public filtroSeleccionado: string=null
  // public disabledSearch:boolean=false;

  public cargarHTML :boolean = false;

  public idDataList:string = "datalistOptions";

  constructor(public translate: TranslateService,
              public fn       : FuncionesService) 
  { }

  ngOnInit(){
    // this.activarOrDesactivarComponente();
    this.idDataList = this.id + "datalistOptions";
    this.cargarHTML = true;
  }

  activarOrDesactivarComponente():boolean{
    let activarOrDesactivarFind:boolean = false;
    if (this.isFiltro){
      if(this.accionForm=='consultar' || this.accionForm=='listado' || this.listadoParaFiltrar!=null) {
        // this.disabledSearch=true;
        activarOrDesactivarFind =  true;
      } 
      // else {
      //   this.disabledSearch=false;
      // }
    }else{
      if(this.accionForm=='agregar' || this.accionForm=='modificar' || this.listadoParaFiltrar!=null) {
        // this.disabledSearch=true;
        activarOrDesactivarFind =  true;
      } 
      // else {
      //   this.disabledSearch=false;
      // }
    }
    return activarOrDesactivarFind;
  }


  propagarFiltro(){

    let filtroKey:string = '';
    if (this.filtroSeleccionado != null && this.filtroSeleccionado != '' && this.visibleValueSelect==false){

      let optionElement = document.querySelector('option[value="' + this.filtroSeleccionado + '"]');
      
      if(optionElement){
        filtroKey = optionElement.getAttribute('data-value');

        if(this.isUniqueValue==false){
          let posicionDesde:number = this.filtroSeleccionado.indexOf(':') + 2;
    
          this.filtroSeleccionado = this.filtroSeleccionado.substring(posicionDesde, this.filtroSeleccionado.length);  
        }
      }
      
    }

    let resultado: ResultadoDeFiltro =  {
      filtroNombre            : this.filtroNombre,
      filtroKey               : filtroKey,
      filtroValor             : this.filtroSeleccionado
     }

    this.outputSetearFiltro.emit(resultado);
  }

  setearResultado(filtroNombre:string, filtroKey:string, filtroValor:string): ResultadoDeFiltro{
    return {
      filtroNombre            : filtroNombre,
      filtroKey               : filtroKey,
      filtroValor             : filtroValor
     }
  }

  //funcion para sacar los filtros y lo buscado en el search 
  cleanFiltro(value:any): void{
      if (value.value != ""){
        this.filtroSeleccionado = null;
        this.propagarFiltro();
        value.value = '';
      }
  }
  
  valueFiltroSelectOpcion(opcion:any, valueSelect:string):string {
    let retorno : any = null;
    if(valueSelect===undefined) {
      retorno = this.fn.getDocField(opcion, 'key');  
    } else {
      retorno =  this.fn.getDocField(opcion, valueSelect);  
    }

    return retorno;
  }
  
  nameFiltroSelectOpcion(opcion:any, nombreSelect:string):string {

    return this.fn.getDocField(opcion, nombreSelect);
  }

}

export interface SelectSearchList {
  codigo           : string;
  text             : string;
}


export interface ResultadoDeFiltro {
  filtroNombre              : string;
  filtroKey                 : string; //por lo general se usa en setearFiltroSelectSearch cuando visibleValueSelect = FALSE 
  filtroValor               : string;
}

export interface SelectSearch {
   setearFiltroSelectSearch(valorSeleccionado: ResultadoDeFiltro):void;
}