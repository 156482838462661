<div class="dropdown d-inline-block" *ngIf="appSettings.settings2.panel.funcionalidadesDisponibles.mostrarOnLine">
    <a class="dropdown-toggle no-caret pl-2 pr-2" id="flags-menu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
  
        <span *ngIf="appSettings.settings2.equipo.isOnLine"> 
            <i class="fa fa-wifi text-success" aria-hidden="true"></i>
        </span>
        <span *ngIf="!appSettings.settings2.equipo.isOnLine"> 
            <i class="fa fa-wifi text-danger" aria-hidden="true"></i>
        </span>
    </a>

   
</div>

