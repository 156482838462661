import { KN } from "@settings/maqueta/models/typesKN/typesKN.model";

export enum NOMBRES_TIPOS_REPORTES{
    RUTA = 'Ruta',
    RUTA_PARADA = 'RutaParada',
    EVENTO = 'Evento',
}

export const NOMBRES_TIPOS_REPORTES_ARRAY:KN[]=[
    {key:'Ruta',        nombre:'reporte.reporteRuta'},
    {key:'RutaParada',  nombre:'reporte.reporteParada'},
    {key:'Evento',      nombre:'reporte.reporteEvento'},
  ];
  


export enum FORMATO{ 
      STRING = 'string',
      DATETIME = 'datetime',
      DATE = 'date',
      CALENDAR = 'calendar',
      TIME = 'time',
      DECIMAL ='decimal',
      INT = 'int',
      BOOLEAN = 'boolean',
}
export enum FILTRO {
    INPUT = 'input',
    INPUT_BOOLEAN = 'inputBoolean',
    INPUT_NUMBER = 'inputNumber',
    COLECCIONES_DEFINIDAS = 'coleccionesDefinidas',
    AUTOCOMPLETE = 'autoComplete',
    AUTOCOMPLETE_SELECT = 'autoCompleteSelect',
    AUTOCOMPLETE_SELECT_COLECCION_DEFINIDA = 'autoCompleteSelectColeccionDefinida',
    CACHE_COLECCIONES = 'cacheColecciones',
    CACHE_COLECCIONES_SQL = 'cacheColeccionesSQL',
    PERIODO_DATE = 'periodoDate',
    PERIODO = 'periodo',
    PERIODO_TIME = 'periodoTime'
}


export enum COLUMNAS_KEY{
    ID_RESULTADO_EVENTO = 'idResultadoEvento',
    CODIGO_REGLA = 'codigoRegla',
    DESCRIPCION_REGLA = 'descripcionRegla',
    DESCRIPCION_GRUPO_REGLAS_EVENTOS = 'descripcionGrupoReglasEventos',
    DESCRIPCION_CONFIGURACION_EVENTO = 'descripcionConfiguracionEvento',
    MENSAJE = 'mensaje',
    MODO_DE_EXHIBICION = 'modoDeExhibicion',
    FECHA_HORA_EVENTO = 'fechaHoraEvento',
    ORGANIZACION_NOMBRE = 'organizacionNombre',
    SUCURSAL_NOMBRE = 'sucursalNombre',
    AREA_NEGOCIO_NOMBRE = 'areaNegocioNombre',
    RUTA_NOMBRE = 'rutaNombre',
    PARADA_NOMBRE = 'paradaNombre',
    DISTRIBUIDOR_NOMBRE = 'distribuidorNombre',
    DISTRIBUIDOR_KEY = 'distribuidorKey',
}
  
export enum COLUMNAS_NOMBRES_FILTROS{
    MENSAJE = 'mensaje',
    ID_REGLA_EVENTO = 'idReglaEvento',
    ID_GRUPO_REGLAS_EVENTOS = 'idGrupoReglasEventos',
    ID_CONFIGURACION_EVENTOS_ORGANIZACION = 'idConfiguracionEventosOrganizacion',
    ID_CONFIGURACION_REGLAS_EVENTOS_ORGANIZACION = 'idConfiguracionReglasEventosOrganizacion',
    ORGANIZACION_KEY = 'organizacionKey',
    SUCURSAL_KEY = 'sucursalKey',
    SUCURSAL_NOMBRE = 'sucursalNombre',
    AREA_NEGOCIO_KEY = 'areaNegocioKey',
    AREA_NEGOCIO_NOMBRE = 'areaNegocioNombre',
    RUTA_KEY = 'rutaKey',
    RUTA_NOMBRE = 'rutaNombre',
    PARADA_KEY = 'paradaKey',
    PARADA_NOMBRE = 'paradaNombre',
    KEY = 'key',
    DISTRIBUIDOR_KEY = 'distribuidorKey',
    DISTRIBUIDOR_NOMBRE = 'distribuidorNombre',
    CODIGO_REGLA = 'codigoRegla',
    DESCRIPCION_CONFIGURACION_EVENTO = 'descripcionConfiguracionEvento',
    MODO_DE_EXHIBICION = 'modoDeExhibicion',
    FECHA_HORA_EVENTO = 'fechaHoraEvento',
}
  
  
export enum MODULO_TRADUCCION {
    MODULO_EVENTOS = 'moduloEventos',
    CLASE_DATE_TIME = 'claseDateTime',
    MODULO_ORGANIZACIONES = 'moduloOrganizaciones',
    MODULO_RUTAS = 'moduloRutas',
    MODULO_LOG_APIS = 'moduloLogApis'
}  
  
export enum NOMBRE_TRADUCCION {
    ID_RESULTADO_EVENTO = 'idResultadoEvento',
    CODIGO_REGLA = 'codigoRegla',
    DESCRIPCION_REGLA = 'descripcionRegla',
    DESCRIPCION_GRUPO = 'descripcionGrupo',
    DESCRIPCION_EVENTO = 'descripcionEvento',
    MENSAJE = 'MESSAGE',
    MODO_DE_EXHIBICION = 'modoDeExhibicion',
    FECHA_HORA = 'fechaHora',
    ORGANIZACION = 'organizacion',
    SUCURSAL = 'sucursal',
    AREA_NEGOCIO = 'areaNegocio',
    NOMBRE = 'nombre',
    NOMBRE_RUTA = 'nombreRuta',
    UBICACION = 'ubicacion',
    INTEGRANTE = 'integrante',
    INTEGRANTE_EMAIL = 'integranteEmail'
}  
  
  
export enum DATOS_SELECT_COLECCION {
    KEY = 'key',
    NOMBRE = 'nombre',
    ID_GRUPO_REGLAS_EVENTOS = 'idGrupoReglasEventos',
    ID_REGLA_EVENTO = 'idReglaEvento',
    ID_REGLA = 'idRegla',
    CODIGO_REGLA = 'codigoRegla',
    DESCRIPCION_REGLA = 'descripcionRegla',
    DATOS_PERSONALES_APELLIDO_NOMBRE = 'datosPersonales.apellidoNombre',
    DESCRIPCION_GRUPO_REGLAS_EVENTOS = 'descripcionGrupoReglasEventos',
}
  
export enum COLECCION {
    REGLAS = 'Reglas',
    GRUPOS_REGLAS = 'GruposReglas',
    SUCURSALES = 'Sucursales',
    ORGANIZACIONES = 'Organizaciones',
    AREAS_NEGOCIO = 'AreasNegocio',
    RUTAS = 'Rutas',
    UBICACIONES = 'Ubicaciones',
    USUARIOS = 'Usuarios',
    MODO_EXHIBICION_ARRAY = 'MODO_EXHIBICION_ARRAY',
}

//agregar las columnas para los desarrolladores a un array aparte.
export const COLUMNAS_VISIBLES_REPORTES_DESARROLLADORES:any= {
    Evento : [
        {key   : COLUMNAS_KEY.ID_RESULTADO_EVENTO,                 nombreTraduccion : MODULO_TRADUCCION.MODULO_EVENTOS + '.' + NOMBRE_TRADUCCION.ID_RESULTADO_EVENTO,      formato :FORMATO.STRING,       filtro :FILTRO.INPUT,                      nombreFiltro: COLUMNAS_KEY.ID_RESULTADO_EVENTO,                      arraySelect:null,                                    nombreSelect: null,                                                          valueSelect:null}, 
    ],
    Ruta : [
        {key    : 'distribuidorNombre'                      ,nombreTraduccion : 'moduloDistribuidores.distribuidor'     , formato:'string',         filtro:FILTRO.INPUT},
        {key    : 'organizacionNombre'                      ,nombreTraduccion : 'moduloOrganizaciones.organizacion'     , formato:'string',         filtro:FILTRO.INPUT},
        {key    : 'clave'				                    ,nombreTraduccion : 'moduloDesarrolladores.key'		      , formato:'string',         filtro:FILTRO.INPUT},
        {key    : 'sesionKey'			                    ,nombreTraduccion : 'soap.idSesion'	   	                  , formato:'string',         filtro:FILTRO.INPUT},
        {key    : 'ubicacionOrigenKey'			            ,nombreTraduccion : 'moduloRutas.ubicacionOrigenKey'		  , formato:'string',         filtro:FILTRO.INPUT},
        {key    : 'ubicacionDestinoKey'			            ,nombreTraduccion : 'moduloRutas.ubicacionDestinoKey'		  , formato:'string',         filtro:FILTRO.INPUT},
    ],
    RutaParada : [
        {key: 'clave'                       ,nombreTraduccion : 'moduloDesarrolladores.key',          formato:'string',       filtro:FILTRO.INPUT},
        {key: 'RutasClave'                  ,nombreTraduccion : 'moduloRutas.rutaKey',                formato:'string',       filtro:FILTRO.INPUT},
        {key: 'idMotivoAsociado'            ,nombreTraduccion : 'moduloEventos.idMotivoAsociado',     formato:'string',       filtro:FILTRO.INPUT},
        {key: 'RutasOrganizacionNombre'     ,nombreTraduccion : 'moduloOrganizaciones.organizacion' , formato:'string',       filtro:FILTRO.INPUT},
    ],
};

export const COLUMNAS_VISIBLES_REPORTES:any= {
    Evento : [
            {key    : COLUMNAS_KEY.CODIGO_REGLA,                       nombreTraduccion : MODULO_TRADUCCION.MODULO_EVENTOS + '.' + NOMBRE_TRADUCCION.CODIGO_REGLA,              formato :FORMATO.STRING,      filtro :FILTRO.CACHE_COLECCIONES,          nombreFiltro: COLUMNAS_NOMBRES_FILTROS.CODIGO_REGLA,                             arraySelect:COLECCION.REGLAS,                        nombreSelect:DATOS_SELECT_COLECCION.ID_REGLA,                                valueSelect:DATOS_SELECT_COLECCION.ID_REGLA},
            {key    : COLUMNAS_KEY.DESCRIPCION_REGLA,                  nombreTraduccion : MODULO_TRADUCCION.MODULO_EVENTOS + '.' + NOMBRE_TRADUCCION.DESCRIPCION_REGLA,         formato :FORMATO.STRING,      filtro :FILTRO.CACHE_COLECCIONES,          nombreFiltro: COLUMNAS_NOMBRES_FILTROS.ID_REGLA_EVENTO,                          arraySelect:COLECCION.REGLAS,                        nombreSelect:DATOS_SELECT_COLECCION.DESCRIPCION_REGLA,                       valueSelect:DATOS_SELECT_COLECCION.ID_REGLA_EVENTO},
            {key    : COLUMNAS_KEY.DESCRIPCION_GRUPO_REGLAS_EVENTOS,   nombreTraduccion : MODULO_TRADUCCION.MODULO_EVENTOS + '.' + NOMBRE_TRADUCCION.DESCRIPCION_GRUPO,         formato :FORMATO.STRING,      filtro :FILTRO.CACHE_COLECCIONES,          nombreFiltro: COLUMNAS_NOMBRES_FILTROS.ID_GRUPO_REGLAS_EVENTOS,                  arraySelect:COLECCION.GRUPOS_REGLAS,                 nombreSelect:DATOS_SELECT_COLECCION.DESCRIPCION_GRUPO_REGLAS_EVENTOS,        valueSelect:DATOS_SELECT_COLECCION.ID_GRUPO_REGLAS_EVENTOS},
            {key    : COLUMNAS_KEY.DESCRIPCION_CONFIGURACION_EVENTO,   nombreTraduccion : MODULO_TRADUCCION.MODULO_EVENTOS + '.' + NOMBRE_TRADUCCION.DESCRIPCION_EVENTO,        formato :FORMATO.STRING,      filtro :FILTRO.INPUT,                      nombreFiltro: COLUMNAS_NOMBRES_FILTROS.DESCRIPCION_CONFIGURACION_EVENTO,         arraySelect:null,                                    nombreSelect:null,                                                           valueSelect:null},
            {key    : COLUMNAS_KEY.MENSAJE,                            nombreTraduccion : NOMBRE_TRADUCCION.MENSAJE,                                                            formato :FORMATO.STRING,      filtro :FILTRO.INPUT,                      nombreFiltro: COLUMNAS_NOMBRES_FILTROS.MENSAJE,                                  arraySelect:null,                                    nombreSelect:null,                                                           valueSelect:null},
            {key    : COLUMNAS_KEY.MODO_DE_EXHIBICION,                 nombreTraduccion : MODULO_TRADUCCION.MODULO_EVENTOS + '.' + NOMBRE_TRADUCCION.MODO_DE_EXHIBICION,        formato :FORMATO.STRING,      filtro :FILTRO.COLECCIONES_DEFINIDAS,      nombreFiltro: COLUMNAS_NOMBRES_FILTROS.MODO_DE_EXHIBICION,                       arraySelect:COLECCION.MODO_EXHIBICION_ARRAY,         nombreSelect:DATOS_SELECT_COLECCION.NOMBRE,                                  valueSelect:DATOS_SELECT_COLECCION.KEY},
            {key    : COLUMNAS_KEY.FECHA_HORA_EVENTO,                  nombreTraduccion : MODULO_TRADUCCION.CLASE_DATE_TIME + '.' + NOMBRE_TRADUCCION.FECHA_HORA,               formato :FORMATO.DATETIME,    filtro :FILTRO.PERIODO,                    nombreFiltro: COLUMNAS_NOMBRES_FILTROS.FECHA_HORA_EVENTO,                        arraySelect:null,                                    nombreSelect:null,                                                           valueSelect:null},
            {key    : COLUMNAS_KEY.ORGANIZACION_NOMBRE,                nombreTraduccion : MODULO_TRADUCCION.MODULO_ORGANIZACIONES + '.' + NOMBRE_TRADUCCION.ORGANIZACION,       formato :FORMATO.STRING,      filtro :FILTRO.AUTOCOMPLETE,               nombreFiltro: COLUMNAS_NOMBRES_FILTROS.ORGANIZACION_KEY,                         arraySelect:COLECCION.ORGANIZACIONES,                nombreSelect:DATOS_SELECT_COLECCION.NOMBRE,                                  valueSelect:DATOS_SELECT_COLECCION.KEY},
            {key    : COLUMNAS_KEY.SUCURSAL_NOMBRE,                    nombreTraduccion : MODULO_TRADUCCION.MODULO_ORGANIZACIONES + '.' + NOMBRE_TRADUCCION.SUCURSAL,           formato :FORMATO.STRING,      filtro :FILTRO.INPUT,                      nombreFiltro: COLUMNAS_NOMBRES_FILTROS.SUCURSAL_NOMBRE,                          arraySelect:null,                                    nombreSelect:null,                                                           valueSelect:null},
            {key    : COLUMNAS_KEY.AREA_NEGOCIO_NOMBRE,                nombreTraduccion : MODULO_TRADUCCION.MODULO_ORGANIZACIONES + '.' + NOMBRE_TRADUCCION.AREA_NEGOCIO,       formato :FORMATO.STRING,      filtro :FILTRO.INPUT,                      nombreFiltro: COLUMNAS_NOMBRES_FILTROS.AREA_NEGOCIO_NOMBRE,                      arraySelect:null,                                    nombreSelect:null,                                                           valueSelect:null},
            {key    : COLUMNAS_KEY.RUTA_NOMBRE,                        nombreTraduccion : MODULO_TRADUCCION.MODULO_LOG_APIS + '.' + NOMBRE_TRADUCCION.NOMBRE_RUTA,              formato :FORMATO.STRING,      filtro :FILTRO.INPUT,                      nombreFiltro: COLUMNAS_NOMBRES_FILTROS.RUTA_NOMBRE,                              arraySelect:null,                                    nombreSelect:null,                                                           valueSelect:null},
            {key    : COLUMNAS_KEY.PARADA_NOMBRE,                      nombreTraduccion : MODULO_TRADUCCION.MODULO_RUTAS + '.' + NOMBRE_TRADUCCION.UBICACION,                   formato :FORMATO.STRING,      filtro :FILTRO.INPUT,                      nombreFiltro: COLUMNAS_NOMBRES_FILTROS.PARADA_NOMBRE,                            arraySelect:null,                                    nombreSelect:null,                                                           valueSelect:null},
            {key    : COLUMNAS_KEY.DISTRIBUIDOR_NOMBRE,                nombreTraduccion : MODULO_TRADUCCION.MODULO_RUTAS + '.' + NOMBRE_TRADUCCION.INTEGRANTE,                  formato :FORMATO.STRING,      filtro :FILTRO.INPUT,                      nombreFiltro: COLUMNAS_NOMBRES_FILTROS.DISTRIBUIDOR_NOMBRE,                      arraySelect:null,                                    nombreSelect:null,                                                           valueSelect:null},
            {key    : COLUMNAS_KEY.DISTRIBUIDOR_KEY,                   nombreTraduccion : MODULO_TRADUCCION.MODULO_RUTAS + '.' + NOMBRE_TRADUCCION.INTEGRANTE_EMAIL,            formato :FORMATO.STRING,      filtro :FILTRO.INPUT,                      nombreFiltro: COLUMNAS_NOMBRES_FILTROS.DISTRIBUIDOR_KEY,                         arraySelect:null,                                    nombreSelect:null,                                                           valueSelect:null},
    ],
    Ruta : [
            {key    : 'codigo'			                        ,nombreTraduccion : 'claseFicha.codigo'					                    , formato:'string',        filtro:FILTRO.INPUT},
            {key    : 'sesionNombre'		                     ,nombreTraduccion : 'soap.sesion'		   	                             , formato:'string',        filtro:FILTRO.INPUT},
            {key    : 'fechaSesion'		                        ,nombreTraduccion : 'moduloRutas.fechaSesion'		                       , formato:'datetime',      filtro:'periodoDate'},
            {key    : 'nombre'			                        ,nombreTraduccion : 'claseFicha.nombre'					                    , formato:'string',        filtro:FILTRO.INPUT},
            {key    : 'estadoRutaNombre'	                     ,nombreTraduccion : 'moduloRutas.estadoRuta'		                       , formato:'string',        filtro:FILTRO.COLECCIONES_DEFINIDAS, arraySelect: 'ESTADOS_RUTA_ARRAY', nombreSelect:'nombre' , valueSelect:'nombre'},
            {key    : 'areaNegocioNombre'	                     ,nombreTraduccion : 'moduloOrganizaciones.areaNegocio'	                 , formato:'string',        filtro:FILTRO.INPUT},
            {key    : 'sucursalNombre'	                        ,nombreTraduccion : 'moduloOrganizaciones.sucursal'                     , formato:'string',        filtro:FILTRO.INPUT},
            {key    : 'fechaHoraCarga'	                        ,nombreTraduccion : 'moduloRutas.carga'	                                , formato:'datetime',      filtro:'periodoDate'},
            {key    : 'fechaHoraInicioPlaneada'		            ,nombreTraduccion : 'moduloRutas.inicioPlanificado'	                    , formato:'datetime',      filtro:'periodoDate'},
            {key    : 'fechaHoraSalidaPlaneada'		            ,nombreTraduccion : 'moduloRutas.salidaPlanificada'	                    , formato:'datetime',      filtro:'periodoDate'},
            {key    : 'fechaHoraArriboPlaneada'		            ,nombreTraduccion : 'moduloRutas.arriboPlanificado'	                    , formato:'datetime',      filtro:'periodoDate'},
            {key    : 'fechaHoraFinalizacionPlaneada'	         ,nombreTraduccion : 'moduloRutas.finalizacionPlanificada'               , formato:'datetime',      filtro:'periodoDate'},
            {key    : 'fechaHoraInicioReal'			            ,nombreTraduccion : 'moduloRutas.inicioReal'				                 , formato:'datetime',      filtro:'periodoDate'},
            {key    : 'fechaHoraSalidaReal'			            ,nombreTraduccion : 'moduloRutas.salidaReal'				                 , formato:'datetime',      filtro:'periodoDate'},
            {key    : 'fechaHoraArriboReal'			            ,nombreTraduccion : 'moduloRutas.arriboReal'				                 , formato:'datetime',      filtro:'periodoDate'},
            {key    : 'fechaHoraFinalizacionReal'		         ,nombreTraduccion : 'moduloRutas.finalizacionReal'	                    , formato:'datetime',      filtro:'periodoDate'},
            {key    : 'vehiculoPrincipalOBJPatente'	         ,nombreTraduccion : 'moduloRutas.vehiculoPatente'	                    , formato:'string',        filtro:FILTRO.INPUT},
            {key    : 'vehiculoPrincipalOBJCodigo'	            ,nombreTraduccion : 'moduloRutas.vehiculoCodigo'	                       , formato:'string',        filtro:FILTRO.INPUT},
            {key    : 'vehiculoPrincipalOBJNombre'	            ,nombreTraduccion : 'moduloRutas.vehiculoNombre'	                       , formato:'string',        filtro:FILTRO.INPUT},
            {key    : 'vehiculoPrincipalOBJTipoVehiculoNombre' ,nombreTraduccion : 'moduloRutas.nombreTipoVehiculo'                    , formato:'string',        filtro:FILTRO.INPUT},
            {key    : 'vehiculoPrincipalOBJTipoVehiculoCodigo' ,nombreTraduccion : 'moduloRutas.codigoTipoVehiculo'                    , formato:'string',        filtro:FILTRO.INPUT},
            {key    : 'vehiculoPrincipalOBJTipoVehiculoKey'    ,nombreTraduccion : 'moduloRutas.keyTipoVehiculo'                       , formato:'string',        filtro:FILTRO.INPUT},
            {key    : 'vehiculoPrincipalOBJTipoVehiculoPropio' ,nombreTraduccion : 'moduloRutas.vehiculoPropio'                        , formato:'boolean',       filtro:'inputBoolean'},
            {key    : 'integranteChoferApellidoNombre'         ,nombreTraduccion : 'moduloRutas.integrante'                            , formato:'string',        filtro:FILTRO.INPUT},
            {key    : 'integranteChoferEmail'			         ,nombreTraduccion : 'moduloRutas.integranteEmail'                       , formato:'string',        filtro:FILTRO.INPUT},
            {key    : 'ubicacionOrigenCodigo'			         ,nombreTraduccion : 'moduloRutas.codigoUbicacionOrigen'	              , formato:'string',        filtro:FILTRO.INPUT}, 
            {key    : 'ubicacionOrigenNombre'			         ,nombreTraduccion : 'moduloRutas.nombreUbicacionOrigen'	              , formato:'string',        filtro:FILTRO.INPUT}, 
            {key    : 'ubicacionDestinoCodigo'			         ,nombreTraduccion : 'moduloRutas.codigoUbicacionDestino'	              , formato:'string',        filtro:FILTRO.INPUT},
            {key    : 'ubicacionDestinoNombre'		            ,nombreTraduccion : 'moduloRutas.nombreUbicacionDestino'		           , formato:'string',        filtro:FILTRO.INPUT},
            {key    : 'origenEsDestino'				            ,nombreTraduccion : 'importador.origenEsDestino'					           , formato:'boolean',       filtro:'inputBoolean'},
            {key    : 'formaCarga'					               ,nombreTraduccion : 'moduloRutas.formaCarga'		                       , formato:'string',        filtro:FILTRO.INPUT},
            {key    : 'distanciaPlanificada'			            ,nombreTraduccion : 'moduloRutas.distanciaPlanificada'	                 , formato:'distancia',     filtro:'inputNumber'},
            {key    : 'distanciaRealTrackeo'					      ,nombreTraduccion : 'moduloRutas.distanciaReal'				              , formato:'distancia',     filtro:'inputNumber'},
            {key    : 'fechaInicioPlaneada'		               ,nombreTraduccion : 'moduloRutas.inicioPlanificadoFecha'	              , formato:'date',          filtro:'periodoDate'},
            {key    : 'horaInicioPlaneada'		               ,nombreTraduccion : 'moduloRutas.inicioPlanificadoHora'	              , formato:'time',          filtro:'periodoTime'},
            {key    : 'fechaInicioReal'		                  ,nombreTraduccion : 'moduloRutas.inicioRealFecha'	                    , formato:'date',          filtro:'periodoDate'},
            {key    : 'horaInicioReal'		                     ,nombreTraduccion : 'moduloRutas.inicioRealHora'	                       , formato:'time',          filtro:'periodoTime'},
            {key    : 'fechaFinalizacionPlaneada'	            ,nombreTraduccion : 'moduloRutas.finalizacionPlanificadaFecha'          , formato:'date',          filtro:'periodoDate'},
            {key    : 'horaFinalizacionPlaneada'	            ,nombreTraduccion : 'moduloRutas.finalizacionPlanificadaHora'           , formato:'time',          filtro:'periodoTime'},
            {key    : 'fechaFinalizacionReal'		            ,nombreTraduccion : 'moduloRutas.finalizacionRealFecha'	              , formato:'date',          filtro:'periodoDate'},
            {key    : 'horaFinalizacionReal'		               ,nombreTraduccion : 'moduloRutas.finalizacionRealHora'	                 , formato:'time',          filtro:'periodoTime'},
            {key    : 'duracionHoraRutaPlan'	                  ,nombreTraduccion : 'moduloRutas.duracionPlanificadaHora'               , formato:'time',          filtro:'periodoTime'},
            {key    : 'duracionHoraRutaReal'	                  ,nombreTraduccion : 'moduloRutas.duracionRealHora'                      , formato:'time',          filtro:'periodoTime'},
            {key    : 'cantidadTotalParadas'			            ,nombreTraduccion : 'moduloRutas.totalParadas'	                       , formato:'int',           filtro:'inputNumberInt'}, 
            {key    : 'cantidadParadas'			               ,nombreTraduccion : 'moduloRutas.cantidadParadas'	                    , formato:'int',           filtro:'inputNumberInt'},
            {key    : 'cantidadParadasConcretadas'			      ,nombreTraduccion : 'tipoParada.concretada'	                          , formato:'int',           filtro:'inputNumberInt'}, 
            {key    : 'porcentajeParadasArriboCercano'			,nombreTraduccion : 'paradas.porcentajeArriboCercano'	                 , formato:'decimal',       filtro:'inputNumber'}, 
            {key    : 'porcentajeParadasSecuenciaValida'			,nombreTraduccion : 'paradas.porcentajeSecuenciaValida'	              , formato:'decimal',       filtro:'inputNumber'}, 
            {key    : 'totalParadaUnidad1EntregarPlanificada'	,nombreTraduccion : 'moduloRutas.unidadesEntregarPlanificadas1'	        , formato:'decimal',       filtro:'inputNumber'}, 
            {key    : 'totalParadaUnidad2EntregarPlanificada'	,nombreTraduccion : 'moduloRutas.unidadesEntregarPlanificadas2'	        , formato:'decimal',       filtro:'inputNumber'}, 
            {key    : 'totalParadaUnidad3EntregarPlanificada'	,nombreTraduccion : 'moduloRutas.unidadesEntregarPlanificadas3'	        , formato:'decimal',       filtro:'inputNumber'}, 
            {key    : 'totalParadaUnidad1EntregarReal'			,nombreTraduccion : 'moduloRutas.unidadesEntregarReales1'	              , formato:'decimal',       filtro:'inputNumber'}, 
            {key    : 'totalParadaUnidad2EntregarReal'			,nombreTraduccion : 'moduloRutas.unidadesEntregarReales2'	              , formato:'decimal',       filtro:'inputNumber'}, 
            {key    : 'totalParadaUnidad3EntregarReal'			,nombreTraduccion : 'moduloRutas.unidadesEntregarReales3'	              , formato:'decimal',       filtro:'inputNumber'}, 
            {key    : 'totalParadaUnidad1RetirarPlanificada'	,nombreTraduccion : 'moduloRutas.unidadesRetirarPlanificadas1'	        , formato:'decimal',       filtro:'inputNumber'}, 
            {key    : 'totalParadaUnidad2RetirarPlanificada'	,nombreTraduccion : 'moduloRutas.unidadesRetirarPlanificadas2'	        , formato:'decimal',       filtro:'inputNumber'}, 
            {key    : 'totalParadaUnidad3RetirarPlanificada'	,nombreTraduccion : 'moduloRutas.unidadesRetirarPlanificadas3'	        , formato:'decimal',       filtro:'inputNumber'}, 
            {key    : 'totalParadaUnidad1RetirarReales'			,nombreTraduccion : 'moduloRutas.unidadesRetirarReales1'	              , formato:'decimal',       filtro:'inputNumber'}, 
            {key    : 'totalParadaUnidad2RetirarReales'			,nombreTraduccion : 'moduloRutas.unidadesRetirarReales2'	              , formato:'decimal',       filtro:'inputNumber'}, 
            {key    : 'totalParadaUnidad3RetirarReales'			,nombreTraduccion : 'moduloRutas.unidadesRetirarReales3'	              , formato:'decimal',       filtro:'inputNumber'}, 
            {key    : 'cantidadParadasEscalaCliente'				,nombreTraduccion : 'tipoParada.escalaEnCliente'				              , formato:'int',           filtro:'inputNumber'}, 
            {key    : 'cantidadParadasEscalaHotel'				   ,nombreTraduccion : 'tipoParada.escalaEnHotel'		                    , formato:'int',           filtro:'inputNumberInt'}, 
            {key    : 'cantidadParadasEnEspera'				      ,nombreTraduccion : 'tipoParada.espera'		                             , formato:'int',           filtro:'inputNumberInt'}, 
            {key    : 'cantidadDescansos'				            ,nombreTraduccion : 'tipoParada.descanso'                               , formato:'int',           filtro:'inputNumberInt'},
            {key    : 'cantidadDepositos'				            ,nombreTraduccion : 'tipoParada.deposito'				                    , formato:'int',           filtro:'inputNumberInt'}, 
            {key    : 'cantidadParadas'			               ,nombreTraduccion : 'moduloRutas.cantidadParadas'	                    , formato:'int',           filtro:'inputNumberInt'}, 
            {key    : 'cantidadParadasEnCurso'				      ,nombreTraduccion : 'estadoParada.paradaEnCurso'				              , formato:'int',           filtro:'inputNumberInt'}, 
            {key    : 'cantidadParadasCompletas'				   ,nombreTraduccion : 'estadoParada.entregaCompleta'		                 , formato:'int',           filtro:'inputNumberInt'}, 
            {key    : 'cantidadParadasParciales'				   ,nombreTraduccion : 'estadoParada.entregaParcial'		                 , formato:'int',           filtro:'inputNumberInt'}, 
            {key    : 'cantidadParadasNoRealizadas'				,nombreTraduccion : 'estadoParada.entregaNoRealizada'                   , formato:'int',           filtro:'inputNumberInt'},
            {key    : 'cantidadParadasCanceladas'				   ,nombreTraduccion : 'estadoParada.cancelada'				                 , formato:'int',           filtro:'inputNumberInt'}, 
            {key    : 'cantidadParadasRechazadas'				   ,nombreTraduccion : 'estadoParada.rechazada'		                       , formato:'int',           filtro:'inputNumberInt'}, 
            {key    : 'cantidadParadasReplanificadas'				,nombreTraduccion : 'estadoParada.replanificada'			                 , formato:'int',           filtro:'inputNumberInt'},
            {key    : 'cantidadParadasPendientes'				   ,nombreTraduccion : 'estadoParada.paradaPendiente'		                 , formato:'int',           filtro:'inputNumberInt'},
            {key    : 'cantidadParadasIndicacionesSolicitadas' ,nombreTraduccion : 'estadoParada.indicacionesSolicitadas'              , formato:'int',           filtro:'inputNumberInt'},
            {key    : 'cantidadParadasRealizadas'				   ,nombreTraduccion : 'estadoParada.paradaRealizada'                      , formato:'int',           filtro:'inputNumberInt'},
            {key    : 'costoPlanificado'				            ,nombreTraduccion : 'moduloRutas.costoPlanificado'	                    , formato:'decimal',       filtro:'inputNumber'},
    ],

    RutaParada : [
            {key: 'RutasNombre'		      			            ,nombreTraduccion :	'moduloRutas.ruta',                             formato:'string',         filtro:FILTRO.INPUT},
            {key: 'RutasSucursalNombre'				            ,nombreTraduccion :	'moduloOrganizaciones.sucursal',                formato:'string',         filtro:FILTRO.INPUT},
            {key: 'RutasAreaNegocioNombre'				         ,nombreTraduccion :	'moduloOrganizaciones.areaNegocio',             formato:'string',         filtro:FILTRO.INPUT},
            {key: 'codigo'					                        ,nombreTraduccion :	'claseFicha.codigo',                            formato:'string',         filtro:FILTRO.INPUT},
            {key: 'codigoPedido'				                     ,nombreTraduccion :	'moduloRutas.codigoPedido',                     formato:'string',         filtro:FILTRO.INPUT},
            {key: 'tipoParadaEntregaMercaderia'                ,nombreTraduccion :   'moduloRutas.entregaMercaderia',                formato:'boolean',        filtro:'inputBoolean'},
            {key: 'tipoParadaNombre'			                  ,nombreTraduccion :	'moduloRutas.tipoParada',                       formato:'string',         filtro:FILTRO.COLECCIONES_DEFINIDAS, arraySelect: 'TIPOS_PARADAS_ARRAY', nombreSelect:'nombre', valueSelect:'nombre'	},
            {key: 'descripcionMotivoAsociado'			         ,nombreTraduccion :	'moduloEventos.motivoAsociado',                 formato:'string',         filtro:FILTRO.INPUT},        
            {key: 'ubicacionCodigo'			                     ,nombreTraduccion :	'moduloRutas.ubicacion',                        formato:'string',         filtro:FILTRO.INPUT},
            {key: 'ubicacionNombre'			                     ,nombreTraduccion :	'moduloRutas.nombreUbicacion',                  formato:'string',         filtro:FILTRO.INPUT},
            {key: 'ubicacionDirecion'			                  ,nombreTraduccion :	'claseDireccion.calle',                         formato:'string',         filtro:FILTRO.INPUT},
            {key: 'ubicacionDirecionNumero'			            ,nombreTraduccion :	'claseDireccion.numero',                        formato:'string',         filtro:FILTRO.INPUT},
            {key: 'ubicacionGeoPointLatitud'	                  ,nombreTraduccion :	'claseGeoPoint.latitud',                        formato:'string',         filtro:FILTRO.INPUT},
            {key: 'ubicacionGeoPointLongitud'	               ,nombreTraduccion :	'claseGeoPoint.longitud',                       formato:'string',         filtro:FILTRO.INPUT},
            {key: 'orden'						                     ,nombreTraduccion :	'moduloRutas.ordenPlanificado',                 formato:'orden',          filtro:'inputNumber'},
            {key: 'ordenReal'					                     ,nombreTraduccion :	'moduloRutas.ordenReal',                        formato:'orden',          filtro:'inputNumber'},
            {key: 'estadoParadaNombre'		                     ,nombreTraduccion :	'moduloRutas.estadoParada',                     formato:'string',         filtro:FILTRO.COLECCIONES_DEFINIDAS, arraySelect: 'ESTADOS_PARADAS_ARRAY', nombreSelect:'nombre', valueSelect:'nombre'	},
            {key: 'unidadesMedidaEntregarPlanificadasUnidad1'	,nombreTraduccion :	'moduloRutas.unidadesEntregarPlanificadas1',    formato:'decimal',        filtro:'inputNumber'},
            {key: 'unidadesMedidaEntregarPlanificadasUnidad2'	,nombreTraduccion :	'moduloRutas.unidadesEntregarPlanificadas2',    formato:'decimal',        filtro:'inputNumber'},
            {key: 'unidadesMedidaEntregarPlanificadasUnidad3'	,nombreTraduccion :	'moduloRutas.unidadesEntregarPlanificadas3',    formato:'decimal',        filtro:'inputNumber'},
            {key: 'unidadesMedidaEntregarRealesUnidad1'		   ,nombreTraduccion :	'moduloRutas.unidadesEntregarReales1',          formato:'decimal',        filtro:'inputNumber'},
            {key: 'unidadesMedidaEntregarRealesUnidad2'		   ,nombreTraduccion :	'moduloRutas.unidadesEntregarReales2',          formato:'decimal',        filtro:'inputNumber'},
            {key: 'unidadesMedidaEntregarRealesUnidad3'		   ,nombreTraduccion :	'moduloRutas.unidadesEntregarReales3',          formato:'decimal',        filtro:'inputNumber'},
            {key: 'unidadesMedidaRetirarPlanificadasUnidad1'	,nombreTraduccion :	'moduloRutas.unidadesRetirarPlanificadas1',     formato:'decimal',        filtro:'inputNumber'},
            {key: 'unidadesMedidaRetirarPlanificadasUnidad2'	,nombreTraduccion :	'moduloRutas.unidadesRetirarPlanificadas2',     formato:'decimal',        filtro:'inputNumber'},
            {key: 'unidadesMedidaRetirarPlanificadasUnidad3'	,nombreTraduccion :	'moduloRutas.unidadesRetirarPlanificadas3',     formato:'decimal',        filtro:'inputNumber'},
            {key: 'unidadesMedidaRetirarRealesUnidad1'		   ,nombreTraduccion :	'moduloRutas.unidadesRetirarReales1',           formato:'decimal',        filtro:'inputNumber'},
            {key: 'unidadesMedidaRetirarRealesUnidad2'		   ,nombreTraduccion :	'moduloRutas.unidadesRetirarReales2',           formato:'decimal',        filtro:'inputNumber'},
            {key: 'unidadesMedidaRetirarRealesUnidad3'		   ,nombreTraduccion :	'moduloRutas.unidadesRetirarReales3',           formato:'decimal',        filtro:'inputNumber'},
            {key: 'instruccionesParaConductor'				      ,nombreTraduccion :	'moduloRutas.instrucciones',                    formato:'string',         filtro:FILTRO.INPUT},
            {key: 'comentariosDelConductor'					      ,nombreTraduccion :	'moduloRutas.comentariosDelConductor',          formato:'string',         filtro:FILTRO.INPUT},
            {key: 'fechaHoraInicioPlaneada'					      ,nombreTraduccion :	'moduloRutas.inicioPlanificado',                formato:'datetime',       filtro:'periodo'},
            {key: 'fechaHoraFinalizacionPlaneada'				   ,nombreTraduccion :	'moduloRutas.finalizacionPlanificada',          formato:'datetime',       filtro:'periodo'},
            {key: 'fechaHoraInicioReal'						      ,nombreTraduccion :	'moduloRutas.inicioReal',                       formato:'datetime',       filtro:'periodo'},
            {key: 'fechaHoraFinalizacionReal'					   ,nombreTraduccion :	'moduloRutas.finalizacionReal',                 formato:'datetime',       filtro:'periodo'},
            {key: 'geoPointRealLatitud'						      ,nombreTraduccion :	'moduloRutas.geoPointRealLatitud',              formato:'string',         filtro:FILTRO.INPUT},
            {key: 'geoPointRealLongitud'						      ,nombreTraduccion :	'moduloRutas.geoPointRealLongitud',             formato:'string',         filtro:FILTRO.INPUT},
            {key: 'geoPointIndicacionesLatitud'				      ,nombreTraduccion :	'moduloRutas.geoPointIndicacionesLatitud',      formato:'string',         filtro:FILTRO.INPUT},
            {key: 'geoPointIndicacionesLongitud'				   ,nombreTraduccion :	'moduloRutas.geoPointIndicacionesLongitud',     formato:'string',         filtro:FILTRO.INPUT},
            {key: 'geoPointParadaRealizadaLatitud'			      ,nombreTraduccion :	'moduloRutas.geoPointParadaRealizadaLatitud',   formato:'string',         filtro:FILTRO.INPUT},
            {key: 'geoPointParadaRealizadaLongitud'			   ,nombreTraduccion :	'moduloRutas.geoPointParadaRealizadaLongitud',  formato:'string',         filtro:FILTRO.INPUT},
            {key: 'geoPointParadaEnCursoLatitud'				   ,nombreTraduccion :	'moduloRutas.geoPointParadaEnCursoLatitud',     formato:'string',         filtro:FILTRO.INPUT},
            {key: 'geoPointParadaEnCursoLongitud'				   ,nombreTraduccion :	'moduloRutas.geoPointParadaEnCursoLongitud',    formato:'string',         filtro:FILTRO.INPUT},
            {key: 'geoPointSalidaLatitud'						      ,nombreTraduccion :	'moduloRutas.geoPointSalidaLatitud',            formato:'string',         filtro:FILTRO.INPUT},
            {key: 'geoPointSalidaLongitud'					      ,nombreTraduccion :	'moduloRutas.geoPointSalidaLongitud',           formato:'string',         filtro:FILTRO.INPUT},
            {key: 'geoPointCanceladaLatitud'					      ,nombreTraduccion :	'moduloRutas.geoPointCanceladaLatitud',         formato:'string',         filtro:FILTRO.INPUT},
            {key: 'geoPointCanceladaLongitud'					   ,nombreTraduccion :	'moduloRutas.geoPointCanceladaLongitud',        formato:'string',         filtro:FILTRO.INPUT},
            {key: 'geoPointRechazadaLatitud'					      ,nombreTraduccion :	'moduloRutas.geoPointRechazadaLatitud',         formato:'string',         filtro:FILTRO.INPUT},
            {key: 'geoPointRechazadaLongitud'					   ,nombreTraduccion :	'moduloRutas.geoPointRechazadaLongitud',        formato:'string',         filtro:FILTRO.INPUT},
            {key: 'geoPointReplanificadaLatitud'				   ,nombreTraduccion :	'moduloRutas.geoPointReplanificadaLatitud',     formato:'string',         filtro:FILTRO.INPUT},
            {key: 'geoPointReplanificadaLongitud'				   ,nombreTraduccion :	'moduloRutas.geoPointReplanificadaLongitud',    formato:'string',         filtro:FILTRO.INPUT},
            {key: 'distanciaReal'				                  ,nombreTraduccion :	'moduloRutas.distanciaReal',                    formato:'distancia',      filtro:'inputNumber'},
            {key: 'distanciaIndicaciones'				            ,nombreTraduccion :	'moduloRutas.distanciaIndicaciones',            formato:'distancia',      filtro:'inputNumber'},
            {key: 'distanciaParadaRealizada'				         ,nombreTraduccion :	'moduloRutas.distanciaParadaRealizada',         formato:'distancia',      filtro:'inputNumber'},
            {key: 'distanciaParadaEnCurso'				         ,nombreTraduccion :	'moduloRutas.distanciaParadaEnCurso',           formato:'distancia',      filtro:'inputNumber'},
            {key: 'distanciaSalida'				                  ,nombreTraduccion :	'moduloRutas.distanciaSalida',                  formato:'distancia',      filtro:'inputNumber'},
            {key: 'distanciaCancelada'				               ,nombreTraduccion :	'moduloRutas.distanciaCancelada',               formato:'distancia',      filtro:'inputNumber'},
            {key: 'distanciaRechazada'				               ,nombreTraduccion :	'moduloRutas.distanciaRechazada',               formato:'distancia',      filtro:'inputNumber'},
            {key: 'distanciaReplanificada'				         ,nombreTraduccion :	'moduloRutas.distanciaReplanificada',           formato:'distancia',      filtro:'inputNumber'},
            {key: 'integranteChoferApellidoNombre'             ,nombreTraduccion :   'moduloRutas.integrante',                       formato:'string',         filtro:FILTRO.INPUT},
            {key: 'integranteChoferEmail'			               ,nombreTraduccion :   'moduloRutas.integranteEmail',                  formato:'string',         filtro:FILTRO.INPUT},
            {key: 'vehiculoPrincipalOBJPatente'	               ,nombreTraduccion :   'moduloRutas.vehiculoPatente',                  formato:'string',         filtro:FILTRO.INPUT},
            {key: 'vehiculoPrincipalOBJCodigo'	               ,nombreTraduccion :   'moduloRutas.vehiculoCodigo',                   formato:'string',         filtro:FILTRO.INPUT},
            {key: 'vehiculoPrincipalOBJNombre'	               ,nombreTraduccion :   'moduloRutas.vehiculoNombre',                   formato:'string',         filtro:FILTRO.INPUT},
            {key: 'vehiculoPrincipalOBJTipoVehiculoKey'        ,nombreTraduccion :   'moduloRutas.keyTipoVehiculo',                  formato:'string',         filtro:FILTRO.INPUT}, 
            {key: 'vehiculoPrincipalOBJTipoVehiculoNombre'     ,nombreTraduccion :   'moduloRutas.nombreTipoVehiculo',               formato:'string',         filtro:FILTRO.INPUT},
            {key: 'vehiculoPrincipalOBJTipoVehiculoCodigo'     ,nombreTraduccion :   'moduloRutas.codigoTipoVehiculo',               formato:'string',         filtro:FILTRO.INPUT}, 
            {key: 'FotosParadasJson'                           ,nombreTraduccion :   'claseFicha.foto',                              formato:'foto',           filtro:null}, 
            {key: 'comentariosDragAndDrop'                     ,nombreTraduccion :   'moduloRutas.comentarioIntercambioDeParada',    formato:'array',          filtro:null}, 

    ]


};
  
