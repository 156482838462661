import { log, logIf, logTable, values } from '@maq-console';

import { Injectable } from '@angular/core';
import { Observable, throwError, of, Subject } from 'rxjs';

import { FuncionesService }  from '@maq-funciones';
import { ApisService }       from '@maq-apis';

import { AngularFirestore,  Query } from '@angular/fire/firestore';

import { getListadoCacheModel,getListadoCacheModel2 }   from '@maq-models/mensajes/mensajes.model';
import { WhereObjet,OrderObjet }                        from '@maq-models/bd/bdDefinicionesGenerales.model';
import { StorageService }                               from '@maq-servicios/storageService/storage.service';
import { BDService }                                    from '@maq-servicios/bd/bd.service';
import { Usuario }                                      from '@maq-models/usuarios/usuarios.model';

import { environment }                                  from '../../../../environments/environment';
import { getBDModel2  }                                 from '@maq-models/bd/bd.model';
import { WhereInterface , OPERADOR_ORDEN_BY,OrdeByInterface, OPERADOR_WHERE, TIPO_BASE_DATOS,}  from '@maq-models/bd/bdDefinicionesGenerales.model';

@Injectable({
  providedIn: 'root'
})

export class MensajesService {

  public perfilUsuarioSeleccionado:Usuario= null;
  public perfilUsuario = new Subject<Usuario>();


  public cacheColecciones:any[] = [];
  public cacheColeccionesObs :Subject<any>[]=[];

  constructor(public fn               : FuncionesService,
              public apis             : ApisService,
              public storageService   : StorageService,
              public bdService        : BDService,
              private af              : AngularFirestore) { 
  }
  
  clearListadoCache() {
    this.cacheColecciones = [];
    this.cacheColeccionesObs =[];  
  }
  
  getListadoCache(argumentos:getListadoCacheModel):Observable<any>{
    /*      
      nombreColeccion            : string   = null;
      where                      : WhereObjet[]	= [];
      orderBy                    : OrderObjet[] = [];
      limit                      : number   = null; 
      organizacionKNAI           : KNAI     = null;  
      usuarioKANE                : KANE     = null;  
      nombreColeccionSolicitante : string   = null;
      nombreListado              : string   = null;
      grabaLocalStorage          : boolean  = true;
      ignoraValoresMemoria       : boolean  = false;
      datosPorOrganizacion       : boolean  = false;
      limpiaSettingUsuarioOrganizacion : boolean  = false;
    */

    // let nombreListado   = 'Subscripcion_'+argumentos.nombreListado.replace('listado','').replace('Listado','');
    let nombreListado   = argumentos.nombreListado.replace('listado','').replace('Listado','');
    
    let nombreColeccion = argumentos.nombreColeccion;


    let listado = this.cacheColecciones[nombreListado]; 
    console.log('cachejj this.perfilUsuarioSeleccionado',this.perfilUsuarioSeleccionado);
    console.log('cachejj argumentos',argumentos);

    if(!this.perfilUsuarioSeleccionado.organizacion) {
        argumentos.ignoraValoresMemoria=true;
    }
    
    // console.log("xx argumentos.ignoraValoresMemoria",argumentos.ignoraValoresMemoria, nombreListado);
    if(this.cacheColecciones[nombreListado] && !argumentos.ignoraValoresMemoria){
        //log(...values("funcionEnd","xx msg.getListadoCache, ya estaba en memoria "+nombreListado));
        //log(...values("valores",'Colección:',nombreColeccion));
        //log(...values({color:'valores'},"   Módulo Solicitante:" +argumentos.nombreColeccionSolicitante));                  
        // logTable(...values(listado,nombreListado));
        //log(...values("valores",nombreColeccion, listado));
        //return of (listado); 
        return of (true);

    } else {
        if(!this.cacheColeccionesObs[nombreListado]) {
          this.cacheColeccionesObs[nombreListado]=new Subject<any>();
        }

        let nombreLocalStorage = this.getNombreLocalStorage(argumentos, nombreListado);        
        let existeLocalStorage = localStorage.getItem(nombreLocalStorage);        
        if(existeLocalStorage && this.perfilUsuarioSeleccionado.organizacion) {
            //log(...values("funcionEnd","xx msg.getListadoCache, obtuvo del storageService "+nombreListado));
            //log(...values("valores",'Colección:',nombreColeccion));
            //log(...values("valores",'nombreListado:',nombreListado));
            //log(...values({color:'valores'},"   Módulo Solicitante:" +argumentos.nombreColeccionSolicitante));                  
            
            let listado = JSON.parse(localStorage.getItem(nombreLocalStorage));
            this.cacheColecciones[nombreListado] = listado;    
            
            //return of (listado);
            return of (true);
                  
        } else {          
            this.obtenerListadoBD(argumentos);
            return this.cacheColeccionesObs[nombreListado].asObservable();             
        }  
    }

  }

  getListadoCache2(argumentos:getListadoCacheModel2):Observable<any>{
    /*      
        nombreListado                       : string   = null;
        nombreColeccion                     : string   = null;
        campoClave                          : string   = null;
        where                               : WhereObjet[]	= [];
        orderBy                             : OrderObjet[] = [];
        limit                               : number   = null;
        organizacionKNAI                    : KNAI     = null;
        usuarioKANE                         : KANE     = null;
        nombreColeccionSolicitante          : string   = null;
        grabaLocalStorage                   : boolean  = true;
        ignoraValoresMemoria                : boolean  = false;
        datosPorOrganizacion                : boolean  = false;
        limpiaSettingUsuarioOrganizacion    : boolean  = false;
    */

    // let nombreListado   = 'Subscripcion_'+argumentos.nombreListado.replace('listado','').replace('Listado','');
    let nombreListado   = argumentos.nombreListado.replace('listado','').replace('Listado','');
    
    let nombreColeccion = argumentos.nombreColeccion;

    let listado = this.cacheColecciones[nombreListado]; 
    
    if(!this.perfilUsuarioSeleccionado.organizacion) {
        argumentos.ignoraValoresMemoria=true;
    }

    console.log("getListadoCache2 argumentos",argumentos);
    if(this.cacheColecciones[nombreListado] && !argumentos.ignoraValoresMemoria){
        console.log("getListadoCache2 argumentos no ignora memoria",argumentos.ignoraValoresMemoria);
        //log(...values("funcionEnd","xx msg.getListadoCache, ya estaba en memoria "+nombreListado));
        //log(...values("valores",'Colección:',nombreColeccion));
        //log(...values({color:'valores'},"   Módulo Solicitante:" +argumentos.nombreColeccionSolicitante));                  
        // logTable(...values(listado,nombreListado));
        //log(...values("valores",nombreColeccion, listado));
        //return of (listado); 
        return of (true);

    } else {
        console.log("getListadoCache2 argumentos ignora memoria",argumentos.ignoraValoresMemoria);
        if(!this.cacheColeccionesObs[nombreListado]) {
          console.log("getListadoCache2 argumentos no existe observable",nombreListado);
          this.cacheColeccionesObs[nombreListado]=new Subject<any>();
        }

        let nombreLocalStorage = this.getNombreLocalStorage(argumentos, nombreListado);        
        let existeLocalStorage = localStorage.getItem(nombreLocalStorage);        
        if(existeLocalStorage && this.perfilUsuarioSeleccionado.organizacion) {
          console.log("getListadoCache2 existeLocalStorage",existeLocalStorage);
            //log(...values("funcionEnd","xx msg.getListadoCache, obtuvo del storageService "+nombreListado));
            //log(...values("valores",'Colección:',nombreColeccion));
            //log(...values("valores",'nombreListado:',nombreListado));
            //log(...values({color:'valores'},"   Módulo Solicitante:" +argumentos.nombreColeccionSolicitante));                  
            
            let listado = JSON.parse(localStorage.getItem(nombreLocalStorage));
            this.cacheColecciones[nombreListado] = listado;    
            
            //return of (listado);
            return of (true);
                  
        } else {          
            console.log("getListadoCache2 NO existeLocalStorage",existeLocalStorage);
            this.obtenerListadoBD2(argumentos);
            return this.cacheColeccionesObs[nombreListado].asObservable();             
        }  
    }

  }

  obtenerListadoBD(argumentos:getListadoCacheModel) {
      /*      
      argumentos = {
            nombreColeccionAObtener    : string   = null;
            where                      : WhereObjet[]	= [];
            orderBy                    : OrderObjet[] = [];
            limit                      : number   = null; 
            organizacionKNAI           : KNAI     = null;  
            usuarioKANE                : KANE     = null;  
            nombreColeccionSolicitante : string   = null;
            nombreListado              : string   = null;
            grabaLocalStorage          : boolean  = false;
      }
      */
      //log(...values("funcionGoPromesa","xx msg.obtenerListadoBD: "+argumentos.nombreColeccion));
  
    
      let nombreListado              = argumentos.nombreListado.replace('listado','').replace('Listado','');
      //console.log('argumentos.nombreListado', argumentos.nombreListado);
      //console.log('nombreListado', nombreListado);
      let nombreColeccion            = argumentos.nombreColeccion;
      let where:    WhereObjet[]     = argumentos.where;
      let orderBy:  OrderObjet[]     = argumentos.orderBy;
      let limit                      = argumentos.limit;
      let organizacionKNAI           = argumentos.organizacionKNAI;
      let usuarioKANE                = argumentos.usuarioKANE;
      let nombreColeccionSolicitante = argumentos.nombreColeccionSolicitante;
      let grabaLocalStorage          = argumentos.grabaLocalStorage;


      this.af.collection<any>(nombreColeccion,
        ref =>{
              let query: Query = ref;
              let filtrosAplicados=[];
              
              if(where.length > 0) {
                  for (var i = 0; i < where.length; i++) {
                      //  console.log("i",i);
                      //log(...values('warning','agrego where:',where[i].key, where[i].operador, where[i].value));
                      query = query.where(where[i].key, where[i].operador, where[i].value);
                      filtrosAplicados.push(where[i].key);
                  }
              }
              // console.log('perfilUsuarioSeleccionado', this.perfilUsuarioSeleccionado);
              // console.log('perfil', this.perfilUsuario);
              // console.log('argumentos', argumentos);
              if(["Desarrollador","Supervisor","Distribuidor" ].indexOf(this.perfilUsuarioSeleccionado['perfilUsuario']['tipo'])==-1) {
                  filtrosAplicados.push(query.where( 'settings.isBorrado', '==',  false ));
              }  

              if(orderBy.length > 0) {
                  for (var i = 0; i < orderBy.length; i++) {
                      let key     = orderBy[i].key;
                      let ascDesc = orderBy[i].ascDesc;
                      // verifico que el campo orden no está incluido entre alguno de los filtros
                      if(filtrosAplicados.indexOf(key)!=-1) {
                          continue; // No aplico el Orden
                      }
        
                  query = query.orderBy(key, ascDesc);
                  //log(...values('warning','agrego orden:',key+':'+ascDesc));
                  }
              }

              if(limit) {
                  //log(...values('warning','agrego limit:',limit));
                  query = query.limit(limit);
              }
              //log(...values("valores",'msg.cacheColecciones '+nombreColeccion+' - query:',query));

              return query;

      }).valueChanges().subscribe((data:any)=>{
        
          let coleccionTotalSizeOf=0;
          let listado=[];
          for(let i=0; i<data.length; i++) {
              let documento=data[i];

          
              
              // Convierto valores timestamp a Date 
              documento=this.fn.corrigeTimestampDocumento(documento);


              if(documento?.settings?.sizeOfDocumento){
                documento.settings.sizeOfDocumento = 
                this.fn.sizeOfIfNotCalculado({ sizeOfActual  : documento.settings.sizeOfDocumento, 
                                            pathColeccion : nombreColeccion+documento.key,   
                                            object        : documento });
  
                coleccionTotalSizeOf += documento.settings.sizeOfDocumento; 
              }
   
                //    console.log('xxjj argumentos',argumentos);
                //    console.log('xxjj',documento);
                
                if(argumentos.limpiaSettingUsuarioOrganizacion){
                    delete documento.settings;
                    delete documento.usuarioKANE;
                    delete documento.organizacionKNAI;
                    // console.log('xxjj',documento);
                }
              //delete documento.settings;  // Explota porque se mira el de usuarios.perfilUsuario.settings para ver si el Menú está actualizado

              listado.push( documento );
          }  

          //  console.log('this.cacheColeccioes', this.cacheColecciones);
          //  console.log('argumentos', argumentos);
          //  console.log('nombreListado', nombreListado);

          this.cacheColecciones[nombreListado]=listado;
          //  let nombreListado = "listado['"+nombreColeccion+"']";


          // Guardo en el StorageService
          // console.log("fff",nombreListado, grabaLocalStorage, this.perfilUsuarioSeleccionado.organizacion);
          if(grabaLocalStorage && this.perfilUsuarioSeleccionado?.organizacion) {
              let nombreLocalStorage = this.getNombreLocalStorage(argumentos, nombreListado);                     
              this.storageService.setItem(nombreLocalStorage, JSON.stringify(listado));                 
              
              // Grabo Fecha Hora de Actualización
              let nombreLocalStorageFecha = nombreLocalStorage.replace("Subscripcion_","Coleccion_");
              this.storageService.setItem(nombreLocalStorageFecha, new Date().toString());
          }

          // Disponibilizo Listado en Memoria
          //this.cacheColeccionesObs[nombreListado].next( this.cacheColecciones[nombreListado] );  
          this.cacheColeccionesObs[nombreListado].next( true );  
          
        //    //log(...values("funcionEnd","xx msg.getListadoCache, obtuvo de Firestore "+nombreListado));
        //    //log(...values("valores",'Colección:',nombreColeccion));
        //    //log(...values({color:'valores'},"   Módulo Solicitante:" +nombreColeccionSolicitante));                  
          //  logTable(...values(listado,nombreListado));
        //    //log(...values("valores",'this.cacheColecciones['+nombreListado+']:',this.cacheColecciones[nombreListado]));
  
          //  //log(...values("warning","Disparó FirestoreDocumentRead desde mensaje: "+nombreColeccion));
          this.apis.LogApiFuncion({
                eventoQueDisparo : 'mensajeService.setListadoCache',
                apiFuncionKey    : 'FirestoreDocumentRead', 
                organizacionKNAI : organizacionKNAI,
                usuarioKANE      : usuarioKANE,
                nombreColeccion  : nombreColeccion,
                cloudFunction    : null,
                cantidad         : data.length, 
          });

          //  //log(...values("warning","Disparó FirestoreTransferencia desde mensaje: "+nombreColeccion));
          this.apis.LogApiFuncion({
                eventoQueDisparo : 'obtenerListadoBD',
                apiFuncionKey    : 'FirestoreTransferencia', 
                organizacionKNAI : organizacionKNAI,
                usuarioKANE      : usuarioKANE,
                nombreColeccion  : nombreColeccion,
                cloudFunction    : null,
                cantidad         : coleccionTotalSizeOf, 
          });

      })
   }
    

  obtenerListadoBD2(argumentos:getListadoCacheModel2) {
    /*      
    argumentos = {
          nombreColeccionAObtener    : string   = null;
          where                      : WhereObjet[]	= [];
          orderBy                    : OrderObjet[] = [];
          limit                      : number   = null; 
          organizacionKNAI           : KNAI     = null;  
          usuarioKANE                : KANE     = null;  
          nombreColeccionSolicitante : string   = null;
          nombreListado              : string   = null;
          grabaLocalStorage          : boolean  = false;
    }
    */
    //log(...values("funcionGoPromesa","xx msg.obtenerListadoBD2: "+argumentos.nombreColeccion));

    console.log("getListadoCache2 obtenerListadoBD",argumentos);
    let nombreListado              = argumentos.nombreListado.replace('listado','').replace('Listado','');
    //console.log('argumentos.nombreListado', argumentos.nombreListado);
    //console.log('nombreListado', nombreListado);
    let nombreColeccion            = argumentos.nombreColeccion;
    let where:    WhereObjet[]     = argumentos.where;
    let orderBy:  OrderObjet[]     = argumentos.orderBy;
    let limit                      = argumentos.limit;
    let organizacionKNAI           = argumentos.organizacionKNAI;
    let usuarioKANE                = argumentos.usuarioKANE;
    let nombreColeccionSolicitante = argumentos.nombreColeccionSolicitante;
    let grabaLocalStorage          = argumentos.grabaLocalStorage;


    if(environment.tipoServidor.baseDatos=="firestore") {
      this.af.collection<any>(nombreColeccion,
        ref =>{
              let query: Query = ref;
              let filtrosAplicados=[];
              
              if(where.length > 0) {
                  for (var i = 0; i < where.length; i++) {
                      //  console.log("i",i);
                      //log(...values('warning','agrego where:',where[i].key, where[i].operador, where[i].value));
                      query = query.where(where[i].key, where[i].operador, where[i].value);
                      filtrosAplicados.push(where[i].key);
                  }
              }
              // console.log('perfilUsuarioSeleccionado', this.perfilUsuarioSeleccionado);
              // console.log('perfil', this.perfilUsuario);
              // console.log('argumentos', argumentos);
              if(["Desarrollador","Supervisor","Distribuidor" ].indexOf(this.perfilUsuarioSeleccionado['perfilUsuario']['tipo'])==-1) {
                  filtrosAplicados.push(query.where( 'settings.isBorrado', '==',  false ));
              }  

              if(orderBy.length > 0) {
                  for (var i = 0; i < orderBy.length; i++) {
                      let key     = orderBy[i].key;
                      let ascDesc = orderBy[i].ascDesc;
                      // verifico que el campo orden no está incluido entre alguno de los filtros
                      if(filtrosAplicados.indexOf(key)!=-1) {
                          continue; // No aplico el Orden
                      }
        
                  query = query.orderBy(key, ascDesc);
                  //log(...values('warning','agrego orden:',key+':'+ascDesc));
                  }
              }

              if(limit) {
                  //log(...values('warning','agrego limit:',limit));
                  query = query.limit(limit);
              }
              //log(...values("valores",'msg.cacheColecciones '+nombreColeccion+' - query:',query));

              return query;

      }).valueChanges().subscribe((data:any)=>{
        
          let coleccionTotalSizeOf=0;
          let listado=[];
          for(let i=0; i<data.length; i++) {
              let documento=data[i];

          
              
              // Convierto valores timestamp a Date 
              documento=this.fn.corrigeTimestampDocumento(documento);

              if(documento?.settings?.sizeOfDocumento){
                documento.settings.sizeOfDocumento = 
                this.fn.sizeOfIfNotCalculado({ sizeOfActual  : documento.settings.sizeOfDocumento, 
                                            pathColeccion : nombreColeccion+documento.key,   
                                            object        : documento });
  
                coleccionTotalSizeOf += documento.settings.sizeOfDocumento; 
              }  
                //    console.log('xxjj argumentos',argumentos);
                //    console.log('xxjj',documento);
                
                if(argumentos.limpiaSettingUsuarioOrganizacion){
                    delete documento.settings;
                    delete documento.usuarioKANE;
                    delete documento.organizacionKNAI;
                    // console.log('xxjj',documento);
                }
              //delete documento.settings;  // Explota porque se mira el de usuarios.perfilUsuario.settings para ver si el Menú está actualizado

              listado.push( documento );
          }  

          //  console.log('this.cacheColeccioes', this.cacheColecciones);
          //  console.log('argumentos', argumentos);
          //  console.log('nombreListado', nombreListado);

          this.cacheColecciones[nombreListado]=listado;
          //  let nombreListado = "listado['"+nombreColeccion+"']";


          // Guardo en el StorageService
          if(grabaLocalStorage) {
              let nombreLocalStorage = this.getNombreLocalStorage(argumentos, nombreListado);                     
              this.storageService.setItem(nombreLocalStorage, JSON.stringify(listado));                 
              
              // Grabo Fecha Hora de Actualización
              let nombreLocalStorageFecha = nombreLocalStorage.replace("Subscripcion_","Coleccion_");
              this.storageService.setItem(nombreLocalStorageFecha, new Date().toString());
          }

          // Disponibilizo Listado en Memoria
          //this.cacheColeccionesObs[nombreListado].next( this.cacheColecciones[nombreListado] );  
          this.cacheColeccionesObs[nombreListado].next( true );  
          
        //    //log(...values("funcionEnd","xx msg.getListadoCache, obtuvo de Firestore "+nombreListado));
        //    //log(...values("valores",'Colección:',nombreColeccion));
        //    //log(...values({color:'valores'},"   Módulo Solicitante:" +nombreColeccionSolicitante));                  
          //  logTable(...values(listado,nombreListado));
        //    //log(...values("valores",'this.cacheColecciones['+nombreListado+']:',this.cacheColecciones[nombreListado]));
  
          //  //log(...values("warning","Disparó FirestoreDocumentRead desde mensaje: "+nombreColeccion));
          this.apis.LogApiFuncion({
                eventoQueDisparo : 'mensajeService.setListadoCache',
                apiFuncionKey    : 'FirestoreDocumentRead', 
                organizacionKNAI : organizacionKNAI,
                usuarioKANE      : usuarioKANE,
                nombreColeccion  : nombreColeccion,
                cloudFunction    : null,
                cantidad         : data.length, 
          });

          //  //log(...values("warning","Disparó FirestoreTransferencia desde mensaje: "+nombreColeccion));
          this.apis.LogApiFuncion({
                eventoQueDisparo : 'obtenerListadoBD',
                apiFuncionKey    : 'FirestoreTransferencia', 
                organizacionKNAI : organizacionKNAI,
                usuarioKANE      : usuarioKANE,
                nombreColeccion  : nombreColeccion,
                cloudFunction    : null,
                cantidad         : coleccionTotalSizeOf, 
          });

      })
    } else { //caso sql

      
        let arg: getBDModel2={
          nombreColeccion  : argumentos.nombreColeccion,       
          campoClave       : argumentos.campoClave,
          where            : argumentos.where,
          orderBy          : argumentos.orderBy,
          limit            : argumentos.limit,
          paginado         : null,
          organizacionKNAI : argumentos.organizacionKNAI,
          usuarioKANE      : argumentos.usuarioKANE
        }
        
      console.log("getListadoCache2 caso sql",arg);
        console.log('arg',arg);
        this.bdService.getBDPromesa2(arg).then(data=>{
          console.log("getListadoCache2 getBDPromesa2 sqldata",data);
          let listado=[];
          for(let i=0; i<data.length; i++) {
              let documento=data[i];
              // Convierto valores timestamp a Date 
              documento=this.fn.corrigeTimestampDocumento(documento);

                if(argumentos.limpiaSettingUsuarioOrganizacion){
                    delete documento.settings;
                    delete documento.usuarioKANE;
                    delete documento.organizacionKNAI;
            
                }
         
              listado.push( documento );
          }  

           this.cacheColecciones[nombreListado]=listado;

          // Guardo en el StorageService
          if(grabaLocalStorage) {
              let nombreLocalStorage = this.getNombreLocalStorage(argumentos, nombreListado);                     
              this.storageService.setItem(nombreLocalStorage, JSON.stringify(listado));                 
              
              // Grabo Fecha Hora de Actualización
              let nombreLocalStorageFecha = nombreLocalStorage.replace("Subscripcion_","Coleccion_");
              this.storageService.setItem(nombreLocalStorageFecha, new Date().toString());
          }

          // Disponibilizo Listado en Memoria
          this.cacheColeccionesObs[nombreListado].next( true );  
          
   
       
          
        }).catch(error=>{
          console.log('getBDPromesa2 error',error);
          // this.onResultGetSubscripcionPrincipal();
        })

    }     

 }


    setPerfilUsuarioObs(perfilUsuario:Usuario ) {
        //log(...values("funcionGo",'setPerfilUsuarioObs:'));
        //log(...values("valores",'perfilUsuario:',perfilUsuario));
        this.perfilUsuarioSeleccionado=perfilUsuario;
        this.perfilUsuario.next(perfilUsuario);
         // return of (this.perfilUsuario);
    }

// responde varias veces al ser llamado desde un componete
// al iniciar un componente responde nulo porque no lo he grabado
     getPerfilUsuarioSeleccionadoObs(): Observable<Usuario> {
           // return this.perfilUsuario.asObservable();
       return of (this.perfilUsuarioSeleccionado);
    }

// responde solo la primera vez con un valor no nulo
// cuando leo el perfil despues de tener el usuario logueado
    getPerfilUsuarioObs(): Observable<Usuario> {
           return this.perfilUsuario.asObservable();

       // return of (this.perfilUsuario);
    }


    getPerfil():Observable<Usuario>{
      //log(...values("funcionGo","msg.getPerfil"));
      if(this.perfilUsuarioSeleccionado){        // es nulo cuando no tengo el usuario porque no lo grabe
        // console.log('getPerfil(): por el if');   // lo grabo para no tener que ir a consultar la base todas las veces.
        return of (this.perfilUsuarioSeleccionado);
      } else {
        // console.log('getPerfil(): por el else');
        return this.perfilUsuario.asObservable(); // se activa cuando resibe los datos al loguer el usuario.
      }

    }



    
    getNombreLocalStorage(argumentos:any, nombreListado:string):string {      
        let nombreLocalStorage='Subscripcion_'+nombreListado;
        if(argumentos.datosPorOrganizacion && argumentos.organizacionKNAI) {
            // nombreLocalStorage += this.fn.mostrarKNAI(argumentos.organizacionKNAI,'autoIncrement');
        }
        return nombreLocalStorage;
    }    
}
