
import { log, logIf, logTable, values } from '@maq-console';
import { FormGroup, FormControl, FormBuilder, Validators, FormsModule } from '@angular/forms';
import { ConfigComponenteBaseInterface} from '@maq-models/pageBase/configComponenteBase.interface'
import { AtributosMensajes }   from '@proyecto/models/eventos/atributosMensajes';
import { ArgumentosGetListadoCacheBase }   from '@maq-models/mensajes/mensajesBase.model';
import { TIPO_BASE_DATOS,TIPO_ACTUALIZACION_DATOS, WhereInterface, OPERADOR_WHERE } from '@maq-models/bd/bdDefinicionesGenerales.model';
import { GRILLA_PAGINADO_TIPO } from '@maq-models/grilla/grillaBase.model';
import { ConfiguracionReporteSQL } from '@settings/proyecto/models/reportes/configuracionReporteSQL.model';
import { ConfigBase } from '@settings/maqueta/base/configBase';
import { ColumnaReporte } from '@settings/proyecto/models/reportes/columnasReporte';

import { FormArray } from '@angular/forms';
import { ReporteFavoritoSQL } from '@settings/proyecto/models/reportes/reporteFavoritoSQL.model';

export class ConfigComponente  extends ConfigBase {

   constructor(public fb:any) {         
      super(fb);      
   } 

   configuracionDelComponente(){
      // Colección Principal
      this.tipoBaseDatoListadoPrincipal             = TIPO_BASE_DATOS.SQL_SERVER;
      this.tipoActualizacionDatosListadoPrincipal   = TIPO_ACTUALIZACION_DATOS.PROMESA;
      this.nombreColeccion                          = 'ReporteFavorito';
      this.campoClave                               = 'idReporteFavorito';
      this.usaSettings                              = false;
      this.t                                        = new ReporteFavoritoSQL(); //Construir una clase con todos los campos.
      this.mostrarDiferenciaModeloFomulario         = false;
      this.columnasAdicionalesLogTable              = [];
      this.urlBaseDatos                             = 'reporteFavorito/'; //debe terminar en barra '/'
      this.campoModeloUsadoEnKeywords               = null;       //en general se usa nombre
      this.camposDecimal                            = [];
      this.instanciarT                              = true;

   }

   configuracionGrilla():void{
      // Seteo Grilla
      this.grilla = {
         paginadoTipo          : GRILLA_PAGINADO_TIPO.SERVIDOR,    // local / servidor
         orderField            : 'descripcionConfiguracionReporte',
         orderReverse          : false,
         orderServer           : ['idReporteFavorito','idConfiguracionReporte','descripcionConfiguracionReporte', 'esFavorito', 'usuarioKey' , 'usuarioNombre'], //orden de campos de la grilla
         paginadoCantidad      : 20,
         paginaActualNro       : 1  ,
         paginadoAutoHide      : false,
         verColumnaKey         : false,
      }
   }
   configuracionListadosAuxiliares():void{
       // Colecciones Auxiliares
       this.configListadosCache=[];
   }

   configuracionFormulario():void{
      this.form = this.fb.group({
         idReporteFavorito                   : [null],
         idConfiguracionReporte              : [null,  Validators.compose([Validators.required])],
         descripcionConfiguracionReporte     : [null], //este campo es solo para mostrar al usuario, no se impacta en la bd 
         tipoReporteKey                      : [null], //este campo es solo para mostrar al usuario, no se impacta en la bd
         organizacionKey                     : [null], //este campo es solo para mostrar al usuario, no se impacta en la bd
         organizacionNombre                  : [null], //este campo es solo para mostrar al usuario, no se impacta en la bd
         sucursalKey                         : [null], //este campo es solo para mostrar al usuario, no se impacta en la bd
         sucursalNombre                      : [null], //este campo es solo para mostrar al usuario, no se impacta en la bd
         areaNegocioKey                      : [null], //este campo es solo para mostrar al usuario, no se impacta en la bd
         areaNegocioNombre                   : [null], //este campo es solo para mostrar al usuario, no se impacta en la bd
         esFavorito                          : [true, Validators.compose([Validators.requiredTrue, Validators.required])],
         usuarioKey                          : [null, Validators.compose([Validators.required, Validators.maxLength(100)])], 
         usuarioNombre                       : [null, Validators.compose([Validators.required, Validators.maxLength(100)])], 
         columnasSeleccionadas               : 'Array' //este campo es solo para mostrar al usuario, no se impacta en la bd
      });
   }

   
}
