import { log, logIf, logTable, values } from '@maq-console';

import { Component, OnInit, OnDestroy, ViewEncapsulation    } from '@angular/core';
import { ChangeDetectorRef, Injector, ViewChild, ElementRef } from '@angular/core';
import { Router }                                             from '@angular/router';
import { DOCUMENT, formatDate }                                           from '@angular/common';
import { formatNumber }                                       from '@angular/common'

// SOAP Interceptor
import { HttpClient, HttpHeaders  } from '@angular/common/http';
// import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';s
// import 'rxjs/add/operator/map';

// Reactive Forms
import { FormGroup, FormControl, FormBuilder, Validators, FormsModule } from '@angular/forms';
import { AbstractControl, FormArray } from '@angular/forms';

// Servicios
import { ApisService }       from '@maq-apis';
import { AuthService }       from '@maq-autorizacion/services/auth.service';
import { BDService }         from '@maq-bd/bd.service';
import { BdBaseService }     from "@maq-servicios/bd/bd.serviceBase";
import { ConfirmService }    from '@maq-servicios/confirm/confirm-service';
import { AlertService }      from '@maq-servicios/alert/alert-service';
import { FuncionesService }  from '@maq-funciones';
import { MensajesService }   from '@maq-servicios/mensajes/mensajes.service';
import { AppSettingsService } from './../../../app.settings';
import { AppSettings2 }       from '@maq-models/appSettings/appSettings.model';
import { ExcelService }       from '@maq-servicios/excel/excel.service';

// environmet
import { environment }        from '../../../../environments/environment';

// Firebase
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';

// jQuery
declare let $: any;
declare let jQuery: any;

// Varios
import { Observable    } from 'rxjs';
import { map, take     } from 'rxjs/operators';
import { finalize, tap } from 'rxjs/operators';

// InputMask
import Inputmask from 'inputmask';
import moment from 'moment';
import format from 'date-fns/format'

// Toast
import { ToastrService, GlobalConfig } from 'ngx-toastr';

// Spinner
import { NgxSpinnerService } from "ngx-spinner";

// Ordenamiento en Memoria Grillas
import { OrderPipe } from 'ngx-order-pipe';

// Translate
import { TranslateService }  from '@ngx-translate/core';

// Pipes de Formato de Monedas
import { formatCurrency, getCurrencySymbol } from '@angular/common'

// ng-pick-datetime
// import { DateTimeAdapter } from 'ng-pick-datetime';
// import { OwlDateTimeIntl } from 'ng-pick-datetime';

// Models
import { Usuario, Distribuidor, Organizacion }          from '@maq-models/usuarios/usuarios.model';
import { DatosPersonales }                              from '@maq-models/datosPersonales/datosPersonales.model';
import { Telefono }                                     from '@maq-models/telefono/telefono.model';
import { RedSocial }                                    from '@maq-models/redSocial/redSocial.model';
import { Cotizacion }                                   from '@maq-models/monedasImportes/monedasImportes.model';
import { Settings }                                     from '@maq-models/settings/settings.model';
import { getListadoCacheModel }                         from '@maq-models/mensajes/mensajes.model';
import { WhereObjet,OrderObjet }                        from '@maq-models/bd/bdDefinicionesGenerales.model';
import { KN, KANE, KNAI }                               from '@maq-models/typesKN/typesKN.model';
import { Grilla }                                       from '@maq-models/grilla/grilla.model';
import { Soap }                                         from '@maq-models/soap/soap.model';

// Mocks y Funciones
import { LISTA_DE_FILTROS_SI_NO, getListadoSiNo } from '@maq-mocks/varios/varios';
import { LISTA_DE_GENEROS }                       from '@maq-mocks/varios/varios';
import { LISTA_DE_PAISES }                        from '@maq-mocks/usuarios/usuarios';

// Importador de Archivos
import { Importador, ConfigExcel }    from '@maq-models/importador/importador.model';
import { GeneradorImportadores }      from '@proyecto/importador/generadorImportadores';
import {ThrowStmt} from '@angular/compiler';
import { StringFormat } from '@angular/fire/storage/interfaces';

// Page Base agregados para conectarse a SQL PC Sistemas
import { ArgumentosLlamarBaseDeDatos}  from '@maq-models/bd/bdBase.model';
import { WhereInterface , OPERADOR_ORDEN_BY,OPERADOR_WHERE, TIPO_BASE_DATOS, TIPO_ACTUALIZACION_DATOS, OrdeByInterface}  from '@maq-models/bd/bdDefinicionesGenerales.model';
import { ordenarJSON } from '@settings/maqueta/helpers/documentosHelp';
import { formatDateToString, getFiltroDateSQL, getFromAndToWithReturnOfAnObject } from '@settings/maqueta/helpers/fechaHoraHelp';
import { DatePipe } from '@settings/maqueta/shared/pipes/date/date.pipe';
import { COLUMNAS_VISIBLES_REPORTES, COLUMNAS_VISIBLES_REPORTES_DESARROLLADORES, FILTRO, FORMATO, NOMBRES_TIPOS_REPORTES } from '@settings/proyecto/mocks/columnaReporte/columnaReporte.mocks';

@Component({
    template: ''
  })
export class PageGenerica implements Importador, OnInit, OnDestroy  {
  @ViewChild("modalContent") modalContent: any;
  @ViewChild('titulo') viewChildTitulo: ElementRef;
  
  // Settings
  public settings2: AppSettings2;

  // Colección Principal
  public tipoBD                         : string='Firestore';
  public servicioSQL                    : string=null;
  public nombreColeccion                : string;
  public subscripcionColeccion          : any;
  public listadoPrincipal               : any[]=[];
  public listadoTotales                 : any[]=[];
  public columnasAdicionalesLogTable    : string[]=[];
  public finalizoGETPrincipal           : boolean=false;
  public configComponente               : any=null;
  public documentoOriginal              : any;    
  public configurarColumnas:boolean=false;

  // Colecciones Secundarias
  public subscripcionCache                            : any[]=[];
  public configListadosCache                          : any[]=[];
  public cantidadSubscripcionesSecundariasLanzadas    : number=0;
  public finalizoGETSecundarias                       : boolean=false;
  public listadoSiNoActivo                            : any[] = getListadoSiNo('Estado');
  public listadoDetalle                               : any[]=[];
  
  // Formulario y Grilla
  public modalidadOpcion          : string='listado';
  public grilla                   : Grilla;
  public searchDateRango          : any[]=[]; //SE USA EN LOS LISTADOS DE FIREBASE se guarda en la posicion 0 la fecha desde y la fecha hasta en la posicion 1
  public form                     : FormGroup;
  public formInvalid              : boolean = false;
  public accionForm               : string = 'listado';
  public errorMensaje             : string;
  public pagePaginado             : any;
  public pipePaginado             : any;
  public listadoCamposNoDeseados  : string[]=[];
  
  // Cotización Monedas
  public cotizaciones: Cotizacion={ Dolares         : null,
                                    DolaresBlue     : null,
                                    DolaresDivisa   : null,
                                    Euros           : null,
                                    Reales          : null,
                                    fechaHora       : null
                                };

  // Variables Globales Usario Logeado y Organización asociada
  public usuario              : Usuario;
  public usuarioKANE          : KANE=null;
  public tipoPerfilUsuario    : string=null;
  public organizacionKNAI     : KNAI=null;
  public distribuidorKN       : KN=null;
  public organizacionUTC      : string=null;  

  // Permisos del Usuario Logeado
  public permisos={
    altasAutorizadas                    : true,
    bajasAutorizadas                    : true,
    modificacionesAutorizadas           : true,
    modificacionesBasicasAutorizadas    : true,
    deshabilitarGrabar                  : false,
  };

  // Manejo de Imágenes
  public vecImagenesDownloadeadas   = [];
  public arrayFILES                 : any[] = [];
  public arrayUpload                : any[] = [];
  
  // Mocks y Funciones Importadas  
  public LISTA_DE_FILTROS_SI_NO = LISTA_DE_FILTROS_SI_NO;
  public LISTA_DE_GENEROS       = LISTA_DE_GENEROS;
  public LISTA_DE_PAISES        = LISTA_DE_PAISES;
  public getListadoSiNo         = getListadoSiNo;
    
  // Traducción
  public activeLang:string;

//   public utcOrganizacion:string = '+0000';

  // Servicios
  public appSettings      : AppSettingsService;
  public fn               : FuncionesService;
  public bdService        : BDService;
  public bdBaseService   : BdBaseService;
  public apis             : ApisService;
  public msg              : MensajesService;
  public alertService     : AlertService;
  public authService      : AuthService;
  public toastrService    : ToastrService;
  public confirmService   : ConfirmService;
  public router           : Router;
  public af               : AngularFirestore;
  public fb               : FormBuilder;
  public translate        : TranslateService;
  public spinner          : NgxSpinnerService;
  public orderPipe        : OrderPipe;
  public document         : any;
  public http             : HttpClient;
  public excel            : ExcelService;
  
    
  // Transferencia de Archivos
  public existeImportadorExcel              : boolean=false;
  public existeSOAP_RoadNet                 : boolean=false;
  public configExcel                        : ConfigExcel=null;
  public generadorImportadores              : GeneradorImportadores;
  public soap                               : Soap=null;
  
  public arraySelectFiltroFechaDesdeHasta:any[]=[]; //SE USA EN LOS REPORTES DE SQL (falta probar en listados de firebase)se guarda en la posicion 0 la fecha desde y la fecha hasta en la posicion 1


  @ViewChild('fileInput1') fileInput1       : ElementRef;  


  constructor(protected changeDetectorRef: ChangeDetectorRef) {    

        const injector = AppInjector.getInjector();
        
        // console.log('AppInjector injector',injector); 
        
        // AppInjector.setInjector();
        this.appSettings      = injector.get(AppSettingsService); 
        this.fn               = injector.get(FuncionesService);
        this.bdService        = injector.get(BDService);
        this.bdBaseService    = injector.get(BdBaseService);
        this.apis             = injector.get(ApisService);
        this.msg              = injector.get(MensajesService);
        this.authService      = injector.get(AuthService);
        this.toastrService    = injector.get(ToastrService);
        this.confirmService   = injector.get(ConfirmService);
        this.alertService     = injector.get(AlertService);
        this.router           = injector.get(Router);
        this.af               = injector.get(AngularFirestore);
        this.fb               = injector.get(FormBuilder);
        this.translate        = injector.get(TranslateService);
        this.spinner          = injector.get(NgxSpinnerService);
        this.orderPipe        = injector.get(OrderPipe);
        this.document         = injector.get(DOCUMENT);
        this.http             = injector.get(HttpClient);
        this.excel            = injector.get(ExcelService);
        
        // @Inject(DOCUMENT) document
        // dateTimeAdapter: DateTimeAdapter<any> 
        // private owlDateTimeIntl: OwlDateTimeIntl       
        
    //   console.clear();

      log(...values('funcionComponente','constructor pageGenerica'));

  }

  setGrilla(valores:any) {
       console.log("setGrilla",valores);
    
      if(valores.columnasDefinidas===undefined)         valores.columnasDefinidas=[];
      if(valores.columnasDefinidasAdmin===undefined)    valores.columnasDefinidasAdmin=[];
      if(valores.columnasVisiblesDefault===undefined)   valores.columnasVisiblesDefault=[];
      if(valores.columnasVisibles===undefined)          valores.columnasVisibles=[];

      let objValores = {};
      if(valores.paginadoTipo!==undefined)              objValores['paginadoTipo']              = valores.paginadoTipo;
      if(valores.orderField!==undefined)                objValores['orderField']                = valores.orderField;
      if(valores.orderReverse!==undefined)              objValores['orderReverse']              = valores.orderReverse;
      if(valores.orderServer!==undefined)               objValores['orderServer']               = valores.orderServer;
      if(valores.filtroNombre!==undefined)              objValores['filtroNombre']              = valores.filtroNombre;
      if(valores.filtrosServer!==undefined)             objValores['filtrosServer']             = valores.filtrosServer;
      if(valores.filtrosGrupos!==undefined)             objValores['filtrosGrupos']             = valores.filtrosGrupos;
      if(valores.filtrosInicial!==undefined)            objValores['filtrosInicial']            = valores.filtrosInicial;
      if(valores.campoKeywords!==undefined)             objValores['campoKeywords']             = valores.campoKeywords;      
      if(valores.paginadoCantidad!==undefined)          objValores['paginadoCantidad']          = valores.paginadoCantidad;
      if(valores.paginadoAutoHide!==undefined)          objValores['paginadoAutoHide']          = valores.paginadoAutoHide;
      if(valores.verColumnaKey!==undefined)             objValores['verColumnaKey']             = valores.verColumnaKey;
      if(valores.whereArray !==undefined)               objValores['whereOriginal']             = this.fn.copiarArray(valores.whereArray);
      if(valores.whereArray !==undefined)               objValores['whereArray']                = this.fn.copiarArray(valores.whereArray);
      if(valores.columnasDefinidas !==undefined)        objValores['columnasDefinidas']         = valores.columnasDefinidas;
      if(valores.columnasDefinidasAdmin !==undefined)   objValores['columnasDefinidasAdmin']    = valores.columnasDefinidasAdmin;
      if(valores.columnasVisiblesDefault !==undefined)  objValores['columnasVisiblesDefault']   = valores.columnasVisiblesDefault;
      if(valores.columnasVisibles !==undefined)         objValores['columnasVisibles']          = valores.columnasVisibles;
      if(valores.camposDecimal!==undefined)             objValores['camposDecimal']             = valores.camposDecimal;
      if(valores.cotizacionesActivas!==undefined)       objValores['cotizacionesActivas']       = valores.cotizacionesActivas;

      // Traduzco columnas definidas
      for(let j=0; j<objValores['columnasDefinidas'].length; j++) {
        if(objValores['columnasDefinidas'][j]?.nombre){
            objValores['columnasDefinidas'][j].nombre = this.translate.instant(objValores['columnasDefinidas'][j].nombre);
        }else if(objValores['columnasDefinidas'][j]?.nombreTraduccion){
            objValores['columnasDefinidas'][j].nombreTraduccion = this.translate.instant(objValores['columnasDefinidas'][j].nombreTraduccion);
        }
      }
      
      objValores['columnasVisibles'] = []
      /* Seteo array de columnasVisibles con los valores default de arranque*/
      for(let i=0; i<objValores['columnasVisiblesDefault'].length; i++) {
        for(let j=0; j<objValores['columnasDefinidas'].length; j++) {
            if(objValores['columnasDefinidas'][j].key == objValores['columnasVisiblesDefault'][i]) {
                objValores['columnasVisibles'].push( objValores['columnasDefinidas'][j] );
                break;
            }
        }    
      }
      
      // En caso de estar definidas las columnas, habilito tantos filtros / indices como columnas e inicializo totales
      if(objValores['columnasDefinidas'].length > 0) {
        objValores['filtrosServer']=[];  
        objValores['orderServer']=[];
        objValores['columnasTotales']=[];
        for(let j=0; j<objValores['columnasDefinidas'].length; j++) {
            objValores['filtrosServer'].push( objValores['columnasDefinidas'][j].key );
            objValores['orderServer'].push( objValores['columnasDefinidas'][j].key );
            objValores['columnasTotales'].push( objValores['columnasDefinidas'][j].key );
            objValores['columnasTotales'][ objValores['columnasDefinidas'][j].key ] = 0;
        }    
      }
      
      if(valores.paginadoTipo=='local') {
          objValores['paginaRequerida'] = 'todas';
          this.pipePaginado = {  
            paginate: { 
              itemsPerPage: 20, 
              currentPage: this.pagePaginado 
            }
          }
      } else {
          objValores['paginaRequerida'] = 'primera';
      }    
      
      //this.grilla = new Grilla(objValores);
      this.grilla.setValue(this.configComponente.nombreColeccion, objValores);

      // Inicializo Filtros
      for(let i=0; i<this.grilla.filtrosServer.length; i++) {
        let nombreFiltro=this.grilla.filtrosServer[i];
        this.grilla.filtros[nombreFiltro] = null;
      }
      
      //cargo los filtros de fechas vacios 
      for (let index = 0; index < this.grilla.columnasDefinidas.length; index++) {
        let colDefinida = this.grilla.columnasDefinidas[index];
        if(colDefinida.filtro==FILTRO.PERIODO_DATE || colDefinida.filtro==FILTRO.PERIODO_TIME){
          let fechas:Date[] = [];
          this.arraySelectFiltroFechaDesdeHasta[colDefinida.key]= fechas;
        }      
      }

  }    

  setArrayFILES(fieldName:string, valores:any) {
      this.arrayFILES.push(fieldName);
      this.arrayFILES[fieldName] = valores;
  }

  ngOnDestroy() {
    log(...values('funcionComponente','ngOnDestroy pageGenerica'));

    if(this.subscripcionColeccion) this.subscripcionColeccion.unsubscribe();

    for (var i=0; i<this.subscripcionCache.length; i++) {
        if(this.subscripcionCache[i]) {
          this.subscripcionCache[i].unsubscribe();
        //   console.log("desubscripcion",i);
        }
    }

  }  
  
  ngOnInit() {

    log(...values('funcionComponente','ngOnInit pageGenerica'));

    this.settings2 = this.appSettings.settings2;  
    // console.log("this.settings2",this.settings2);
    
    // Inicializo Cotizaciones
    this.cotizaciones['Dolares']          = null;
    this.cotizaciones['DolaresBlue']      = null;
    this.cotizaciones['DolaresDivisa']    = null;
    this.cotizaciones['Euros']            = null;
    this.cotizaciones['Reales']           = null;
    this.cotizaciones['fechaHora']        = null;
    
    this.grilla = new Grilla({});

    this.msg.getPerfil().subscribe(usuario=>{
        log(...values('valores','usuario:',usuario));

        //this.usuario=usuario;
        this.usuario                  = usuario;
        this.usuarioKANE              = this.fn.setearKANE(this.usuario);
        this.tipoPerfilUsuario        = this.usuario.perfilUsuario.tipo;
        this.grilla.tipoPerfilUsuario = this.tipoPerfilUsuario;
        this.permisos                 = this.fn.setPermisosEdicion(usuario.perfilUsuario.permisosMenu, this.router.url, this.permisos);
        this.organizacionKNAI         = this.fn.setearOrganizacionKNAI(this.usuario.organizacion);
        this.distribuidorKN           = this.fn.setearKN(this.usuario.distribuidor);
        
        this.soap                     = (this.usuario.organizacion && this.usuario.organizacion.soap!==undefined) ? this.usuario.organizacion.soap : null;
        
        log(...values("valores","this.organizacionKNAI:",this.organizacionKNAI));
        log(...values("valores","this.distribuidorKN:",this.distribuidorKN));
        log(...values("valores"," this.permisos:", this.permisos));
        log(...values("valores"," this.soap:", this.soap));
        
        this.setBdBaseData();
        
        this.configuracionComponente();  
        this.configuracionFormulario();  

        log(...values("valores","nombreColeccion:",this.nombreColeccion));
        
        // --------------------------------------------------------------
        // Subscripción Colección Principal
        // --------------------------------------------------------------
        this.getSubscripcionPrincipal(); 
        this.getCantidadDocumentosColeccion();

        // --------------------------------------------------------------
        // Subscripción a Tablas Auxiliares
        // --------------------------------------------------------------
        this.getSubscripcionSecundarias();
        // this.getTimeZoneOrganizacion();

        this.setUTCInUser();
    }); 
    
    // Listener de Cambio de Idioma
    this.inicializarVariablesTraduciblesPageGenerica();
    this.translate.onLangChange.subscribe(() => {
        this.inicializarVariablesTraduciblesPageGenerica();
    });

  }

  setBdBaseData(){

        this.bdBaseService.setUrlServer(this.usuario.getUrlBaseDatos());
        this.bdBaseService.setDistribuidorKN(this.distribuidorKN);
        this.bdBaseService.setOrganizacionKNAI(this.organizacionKNAI);
        this.bdBaseService.setUsuarioKANE(this.usuarioKANE);
        
    }

  configuracionComponente() {
      
    log(...values('funcionComponente','configuracionComponente'));      
      
        // Seteo Grilla
        console.log("this.configComponente.grilla",this.configComponente.grilla);
        this.setGrilla(this.configComponente.grilla);

        // Colección Principal
        this.nombreColeccion             = this.configComponente.nombreColeccion;
        this.columnasAdicionalesLogTable = this.configComponente.columnasAdicionalesLogTable;

        // Colecciones Auxiliares
        this.configListadosCache=this.configComponente.configListadosCache;

        //Lista de Campos No Deseados
        this.listadoCamposNoDeseados=(this.configComponente.listadoCamposNoDeseados!==undefined) ? this.configComponente.listadoCamposNoDeseados : [];
        
        console.log("this.configComponente",this.configComponente);
      
  }  
  
  cloneAbstractControl<T extends AbstractControl>(control: T): T {
        let newControl: T;
      
        if (control instanceof FormGroup) {
          const formGroup = new FormGroup({}, control.validator, control.asyncValidator);
          const controls = control.controls;
      
          Object.keys(controls).forEach(key => {
            formGroup.addControl(key, this.cloneAbstractControl(controls[key]));
          })
      
          newControl = formGroup as any;
          
        } else if (control instanceof FormArray) {
          const formArray = new FormArray([], control.validator, control.asyncValidator);
      
          control.controls.forEach(formControl => formArray.push(this.cloneAbstractControl(formControl)))
      
          newControl = formArray as any;
          
        } else if (control instanceof FormControl) {
            newControl = new FormControl(control.value, control.validator, control.asyncValidator) as any;

        } else {
          throw new Error('Error: unexpected control value');
        }
      
        if (control.disabled) newControl.disable({emitEvent: false});
      
        return newControl;
  }  
  
  configuracionFormulario() {    
        log(...values('funcionComponente','configuracionFormulario'));      
        console.log('configuracionFormulario this.configComponente?.form ' , this.configComponente?.form)
        console.log('configuracionFormulario this.configComponente.arrayFiles.length ' , this.configComponente?.arrayFiles?.length)
        // Configuro el Formulario 
    if (this.configComponente?.form != undefined && this.configComponente?.arrayFiles?.length != undefined ){
        //this.form = this.configComponente.form;    // original
        this.form = this.cloneAbstractControl(this.configComponente.form);  // ok
        
        // console.log("this.configComponente.form",this.configComponente.form);
        // console.log("this.form",this.form);
        
        // Configuro FILES gestionados por el formulario   
        this.arrayFILES=[];
        for(let i=0; i<this.configComponente.arrayFiles.length; i++) {
            let objFile = this.configComponente.arrayFiles[i];
            this.setArrayFILES( objFile.nameField, {
                type             : objFile.type,
                link             : null,
                linkThumb        : null,
                nombreArchivo    : null,
                extensionArchivo : null,
                borrar           : false
            });              
        }
    }
        // console.log("this.arrayFILES",this.arrayFILES);
        
  }  

  resetFormulario() {
    this.configuracionFormulario();
  }
  
  inicializarVariablesTraduciblesPageGenerica(){
      // log(...values('funcionComponente','inicializarVariablesTraduciblesPageGenerica'));
      // log(...values("valores","this.translate:",this.translate));  
      // log(...values("valores","this.translate.store.currentLang:",this.translate.store.currentLang));  
      
      this.activeLang=this.translate.store.currentLang
  }
  
  postGETActualizaValores(listado:any):any {

      if(this.grilla.paginadoTipo == 'local') {
        // salgo, esta función se usa solamente para paginadoTipo 'servidor'
        return listado;
      }

      let ascDesc = (this.grilla.orderReverse==true) ? 'desc' : 'asc';

      if(this.grilla.paginaRequerida=='anterior') {  // Vino en Orden Invertido
          listado.sort(this.fn.ordenarXAtributo(this.grilla.orderField, ascDesc, false));
      }

      // Actualizo nro de página actual
      if(this.grilla.paginaRequerida==this.grilla.paginaRequeridaAnterior) {
         if(this.grilla.paginaRequerida=='siguiente') {
              this.grilla.paginaActualNro++;
          } else {
              this.grilla.paginaActualNro--;
          }
      } else {
          if(this.grilla.paginaRequerida=='primera')          this.grilla.paginaActualNro = 1;
          if(this.grilla.paginaRequerida=='siguiente')        this.grilla.paginaActualNro++;
          if(this.grilla.paginaRequerida=='anterior')         this.grilla.paginaActualNro--;
          if(this.grilla.paginaRequerida=='anteriorOverflow') this.grilla.paginaActualNro--;
      }
      if(this.grilla.paginaRequerida=='primera') {
         this.grilla.paginaRequeridaAnterior = 'siguiente';
      } else {
         this.grilla.paginaRequeridaAnterior = this.grilla.paginaRequerida;
      }

      // Actualizo Cantidad de Resultados
      this.grilla.resultCantidad = listado.length;

      // Actualizo resultKey1, resultKeyN
      if(this.grilla.paginaActualNro>1 && this.grilla.resultCantidad==0) {
          // Avanzó luego de la última página con resultados y no le trajo nada obviamente
          this.grilla.paginaOverflow = true;
          this.grilla.resultKey1     = this.grilla.resultKey1;
          this.grilla.resultKeyN     = null;
      } else {
          this.grilla.paginaOverflow = false;
          this.grilla.resultKey1     = null;
          this.grilla.resultKeyN     = null;
          for(let i=0; i<listado.length; i++) {
             let documento=listado[i];
            //  if(i==0)                this.grilla.resultKey1 = documento[this.grilla.orderField];
            //  if(i==listado.length-1) this.grilla.resultKeyN = documento[this.grilla.orderField];

             if(i==0)                this.grilla.resultKey1 = this.fn.getDocField(documento,this.grilla.orderField);
             if(i==listado.length-1) this.grilla.resultKeyN = this.fn.getDocField(documento,this.grilla.orderField);
             
          }
      }

      return listado;
  }

  onChangeGrilla(cual, value:string) {
      console.log("onChangeGrilla",cual, value);
      console.log("this.grilla.orderServer",this.grilla.orderServer);
      // Hizo click en alguna de las columnas para cambiar el orden
      if(cual=='order') {
          if(this.grilla.paginadoTipo=='servidor') { 
              if(this.grilla.orderServer.indexOf(value)!=-1) {  
                  // Sólo ejecuto el ordenamiento en modo servidor
                  // si la columana está incluída entre las disponibles para ordenar
                  this.grilla.setOrder(value);
                  if(this.tipoBD=='SQL') {
                        this.grilla.paginaActualNro = 1;
                  } else {
                        this.grilla.paginaRequerida = 'primera';    
                  }      
                  
                  this.getSubscripcionPrincipal();
              }    
          } else {
              this.grilla.setOrder(value);
          }           
      }

      // Hizo click en el dropDown de cantidad de elementos a mostrar en el paginado
      if(cual=='paginadoCantidad') {
          if(this.grilla.paginadoTipo=='servidor') { 
              this.grilla.paginaRequerida = 'primera';
              this.getSubscripcionPrincipal();
          }  
      }  
  }  
  
    resetFiltros(vecFiltrosNoResetar?:any[]) {
        if(vecFiltrosNoResetar===undefined) {
            vecFiltrosNoResetar=[];
        }
        for (let index = 0; index < this.grilla.columnasDefinidas.length; index++) {
            let colDefinida = this.grilla.columnasDefinidas[index];
            if((colDefinida.filtro=='periodoDate' || colDefinida.filtro=='periodoTime') && vecFiltrosNoResetar.indexOf(colDefinida.key)==-1){
                this.arraySelectFiltroFechaDesdeHasta[colDefinida.key] = null;
            }
            if(this.searchDateRango[colDefinida.key]){
                this.searchDateRango[colDefinida.key] = null;
            }
        }
        // Inicializo Filtros
        for(let i=0; i<this.grilla.filtrosServer.length; i++) {
            let nombreFiltro=this.grilla.filtrosServer[i];
            


            if(vecFiltrosNoResetar.indexOf(nombreFiltro)==-1) {
                if(this.grilla.filtros[nombreFiltro]!==undefined && this.grilla.filtros[nombreFiltro]!==null && 
                    this.grilla.filtros[nombreFiltro].desde !== undefined) {
                    // Filtro por Rango de Fechas o por Fecha
                    let fieldSearch:any = "filtro_"+nombreFiltro;
                    if($("#"+fieldSearch) && $("#"+fieldSearch)?.length==1 && $("#"+fieldSearch)[0]?.value){
                        // console.log('<<< entro al if ', $("#"+fieldSearch))
                        $("#"+fieldSearch)[0].value=this.translate.instant('claseDateTime.periodo');
                        $("#"+fieldSearch).removeClass('filtroConValor');
                        $("#"+fieldSearch).addClass('filtroSinValor');
                    }
                    if(this.searchDateRango[nombreFiltro]){
                        // console.log('<<< entro al if ',  this.searchDateRango[nombreFiltro])
                        this.searchDateRango[nombreFiltro] = null;
                    }
                }
                this.grilla.filtros[nombreFiltro] = null;
                if(this.arraySelectFiltroFechaDesdeHasta[nombreFiltro] != undefined){
                    this.arraySelectFiltroFechaDesdeHasta[nombreFiltro] = null;
                    // this.clearFiltroPeriodo(nombreFiltro);
                }
                if(this.searchDateRango[nombreFiltro] != undefined){
                    this.searchDateRango[nombreFiltro] = null;
                }
            }
        }       
        this.filtrarGrilla();
    }   

  filtrarGrilla(nameFiltro?:string) { 
      
        log(...values('funcionGo','filtrarGrilla',nameFiltro));
        console.log("this.grilla.filtros",this.grilla.filtros);
        
        if(this.grilla.paginadoTipo=='local') { 
            this.setAccionForm('listado');  
            this.spinner.hide();                    
            return;
        }    
        
        if(this.tipoBD=='SQL') { 
            this.filtrarGrillaSQL();  
            return;
        }    
        
        
        this.grilla.whereArray = this.fn.copiarArray( this.grilla.whereOriginal );
        
        // Verifico si está dentro de un grupo de filtros, y reseteo el resto si el grupo asi lo define
        //console.log("filtrarGrilla this.grilla.filtrosGrupos",this.grilla.filtrosGrupos);
        //console.log("filtrarGrilla this.grilla.filtrosGrupos.length",this.grilla.filtrosGrupos.length);
        if(nameFiltro!==undefined) {
            for(let i=0; i< this.grilla.filtrosGrupos.length; i++) {
                let agrupamiento = this.grilla.filtrosGrupos[i];
                if(agrupamiento.filtros.indexOf(nameFiltro)!=-1) {
                    if(agrupamiento.resetResto) {
                       this.resetFiltros(agrupamiento.filtros);
                    }    
                    
                    let cantFiltrosAgrupamiento  = agrupamiento.filtros.length;
                    let cantFiltrosSeleccionados = 0;
                    for(let j=0; j< agrupamiento.filtros.length; j++) {
                        let nombreFiltroGrupo = agrupamiento.filtros[j];
                        if(this.grilla.filtros[nombreFiltroGrupo]) {
                            cantFiltrosSeleccionados++;
                        }
                    }
                    if(cantFiltrosSeleccionados < cantFiltrosAgrupamiento) {
                        let filtrosIncompletos = '';
                        let cantIncompletos     = 0;
                        let mensajeError        = '';
                        let mensajeErrorUsuario = '';
                        for(let j=0; j< agrupamiento.filtros.length; j++) {
                            let nombreFiltroGrupo = agrupamiento.filtros[j];
                            if(nombreFiltroGrupo!=nameFiltro && !this.grilla.filtros[nombreFiltroGrupo]) {
                                filtrosIncompletos+=", "+nombreFiltroGrupo;
                                cantIncompletos++;
                            }
                        }
                        if(cantIncompletos>0) {
                            filtrosIncompletos=filtrosIncompletos.substr(2,1000);
                            mensajeErrorUsuario = this.translate.instant('mensajes.grupoFiltrosIncompleto', {
                                nameFiltro         : nameFiltro,
                                filtrosIncompletos : filtrosIncompletos
                            });
                            if(cantIncompletos==1) {
                                mensajeError = this.translate.instant('mensajes.grupoFiltrosIncompleto1', {
                                                    nameFiltro         : nameFiltro,
                                                    filtrosIncompletos : filtrosIncompletos
                                                });
                            } else {
                                mensajeError = this.translate.instant('mensajes.grupoFiltrosIncompletoN', {
                                                    nameFiltro         : nameFiltro,
                                                    filtrosIncompletos : filtrosIncompletos
                                                });
                            }
                            console.log("filtrarGrilla mensajeError:",mensajeError);
                            alert(mensajeErrorUsuario);
                            
                            // Coloreo Campos del Grupo
                            for(let j=0; j< agrupamiento.filtros.length; j++) {
                                let nombreFiltroGrupo = agrupamiento.filtros[j];
                                
                                this.grilla.filtrosStyle[nombreFiltroGrupo]={ 'border':'2px solid #444' }
                            }
                            for(let j=0; j< agrupamiento.filtros.length; j++) {
                                let nombreFiltroGrupo = agrupamiento.filtros[j];
                                if(nombreFiltroGrupo!=nameFiltro && !this.grilla.filtros[nombreFiltroGrupo]) {
                                    this.grilla.filtrosStyle[nombreFiltroGrupo]={ 'border':'2px solid #444', 'color':'red'}
                                }
                            }
                            
                            return;    
                        }
                    }   
                    break;
                }
            }    
        }    
        
        // Recorro todos los filtros definidos para el modo servidor
        let cant=this.grilla.whereOriginal.length;
        for(let i=0; i< this.grilla.filtrosServer.length; i++) {
            let nombreFiltro = this.grilla.filtrosServer[i];
            let valueFiltro  = this.grilla.filtros[ nombreFiltro ];
        
            // Quito estilo resaltado a un grupo que haya dado error en ejecución previa
            this.grilla.filtrosStyle[nombreFiltro]={}
            
            // Me fijo si el input/select de la grilla tiene un valor asignado (null o undefined significa que no fue seleccionado)
            //console.log("typeof valueFiltro",nombreFiltro, valueFiltro, typeof valueFiltro);
            if(valueFiltro!==undefined && valueFiltro!=null && (typeof valueFiltro!=='string' || valueFiltro!='')) {
                
                let keyWhere = nombreFiltro
                let operador;
                let valueWhere = valueFiltro;
                
                //console.log(nombreFiltro," == ",this.grilla.filtroNombre);
                if(nombreFiltro == this.grilla.filtroNombre) {
                    console.log('test valueWhere ',valueWhere);
                    console.log('test typeof valueWhere ',typeof valueWhere);
                    if(this.grilla.campoKeywords==false) {
                        if(this.grilla['camposDecimal'].indexOf(keyWhere)!=-1){
                            valueWhere= parseInt(valueWhere);
                        }
                        operador = '==';
                            
                    } else {
                        keyWhere = 'keywords';
                        operador = 'array-contains';  
                        
                        valueWhere = this.fn.reemplazaAcentos(valueWhere);
                        // valueWhere = this.fn.quitaCaracteresEspeciales(valueWhere);
                        valueWhere = valueWhere.toLowerCase();                                      
                    }
                } else if(nombreFiltro.includes('ARRAY')) {
                        operador = 'array-contains';  
                        if(nombreFiltro.includes('ARRAY -')){
                            nombreFiltro = nombreFiltro.replace('ARRAY - ','');
                            keyWhere = nombreFiltro;
                        }
                } else if(typeof valueFiltro==='string') {
                    operador = '==';
                    
                } else if(typeof valueFiltro==='object') {
                    let fechaDesde = valueFiltro.desde.substr(6,4)+'-'+valueFiltro.desde.substr(3,2)+'-'+valueFiltro.desde.substr(0,2)+ ' 00:00:00';
                    operador = '>=';
                    valueWhere = new Date(fechaDesde);
                    
                } else {
                    operador = '==';                    
                }
                
                let newWhere = { 
                    key      : keyWhere, 
                    operador : operador, 
                    value    : valueWhere 
                }
                
                this.grilla.whereArray[cant]=newWhere;
                cant++;
                
                if(operador=='>=') {
                    let newWhere2:any
                    if(typeof valueFiltro==='object') {                    
                        let fechaHasta = valueFiltro.hasta.substr(6,4)+'-'+valueFiltro.hasta.substr(3,2)+'-'+valueFiltro.hasta.substr(0,2) + ' 23:59:59';
                        newWhere2 = { 
                            key      : nombreFiltro, 
                            operador : '<=', 
                            value    : new Date(fechaHasta)
                        }
                            
                    } else {
                        newWhere2 = { 
                            key: nombreFiltro, 
                            operador: '<=', 
                            value: valueFiltro+'zzzzzz' 
                        }                                                
                    }    
                    this.grilla.whereArray[cant]=newWhere2;
                    cant++;
                }      
                          
            } // fin for      
            
        }
        
        this.grilla.paginaRequerida = 'primera';
        log(...values("valores","this.grilla:",this.grilla));
        this.getSubscripcionPrincipal();            
        
  }  

   filtrarGrillaSQL() { 
      
        console.log("this.grilla.filtros",this.grilla.filtros);
        console.log("this.grilla.filtrosServer",this.grilla.filtrosServer);
        console.log("this.grilla.whereOriginal",this.grilla.whereOriginal);
        
        this.grilla.whereArray = this.fn.copiarArray( this.grilla.whereOriginal );
        
        // Recorro todos los filtros definidos para el modo servidor
        let cant=this.grilla.whereOriginal.length;
        for(let i=0; i< this.grilla.filtrosServer.length; i++) {
            let nombreFiltro = this.grilla.filtrosServer[i];
            let valueFiltro  = this.grilla.filtros[ nombreFiltro ];
        
            // Quito estilo resaltado a un grupo que haya dado error en ejecución previa
            this.grilla.filtrosStyle[nombreFiltro]={}
            
            // Me fijo si el input/select de la grilla tiene un valor asignado (null o undefined significa que no fue seleccionado)
            //console.log("typeof valueFiltro",nombreFiltro, valueFiltro, typeof valueFiltro);
            if(valueFiltro!==undefined && valueFiltro!=null && (typeof valueFiltro!=='string' || valueFiltro!='')) {
                
                let keyWhere = nombreFiltro
                let operador;
                let valueWhere  = valueFiltro;
                let valueWhere2 = null;
                
                let newWhere:any=null;
                let newWhere2:any=null;
                
                //console.log("nombreFiltro,valueFiltro",nombreFiltro,valueFiltro,typeof valueFiltro);

                if(typeof valueFiltro==='string') {
                    operador = '==';
                    
                    if(this.grilla.columnasDefinidas.length>0) {
                        for(let z=0; z<this.grilla.columnasDefinidas.length;z++) {
                            if(this.grilla.columnasDefinidas[z].key==nombreFiltro) {
                                if(this.grilla.columnasDefinidas[z].formato=='string') {
                                    operador = 'contiene';
                                    break;                
                                }
                            }
                        }
                    }
                    
                } else if(typeof valueFiltro==='object') {
                    let fechaDesde:string = '';
                    let fechaHasta:string = '';
                    operador = '>=';
                    if(this.grilla.columnasDefinidas.length>0) {
                        for(let z=0; z<this.grilla.columnasDefinidas.length;z++) {
                            if(this.grilla.columnasDefinidas[z].key==nombreFiltro) {
                                console.log('zzz filtrarGrillaSQL valueFiltro ', valueFiltro);
                                //CREO UNA COPIA DEL OBJETO PARA QUE NO MODIFIQUE EL VALOR DE ORIGINAL de this.filtros[ nombreFiltro ]
                                let valueFiltroCopia = Object.assign({}, valueFiltro);
                                let objectFiltroDesdeHasta:object = getFiltroDateSQL(valueFiltroCopia, this.grilla.columnasDefinidas[z].formato, this.activeLang,this.usuario.getUTCNumber());

                                valueWhere = objectFiltroDesdeHasta['desde'];
                                valueWhere2 = objectFiltroDesdeHasta['hasta'];
                                // if(this.grilla.columnasDefinidas[z].formato=='datetime') {
                                //     fechaDesde = valueFiltro.desde.substr(6,4)+'-'+valueFiltro.desde.substr(3,2)+'-'+valueFiltro.desde.substr(0,2)+ valueFiltro.desde.substr(10,9);
                                //     fechaHasta = valueFiltro.hasta.substr(6,4)+'-'+valueFiltro.hasta.substr(3,2)+'-'+valueFiltro.hasta.substr(0,2) + valueFiltro.hasta.substr(10,9);
                                //     valueWhere  = fechaDesde;
                                //     valueWhere2 = fechaHasta;
                                //     break;                
                                // }else if(this.grilla.columnasDefinidas[z].formato=='date'){
                                //     fechaDesde = valueFiltro.desde;
                                //     fechaHasta = valueFiltro.hasta;
                                //     valueWhere  = fechaDesde;
                                //     valueWhere2 = fechaHasta;
                                //     break;
                                // }else if(this.grilla.columnasDefinidas[z].formato=='time'){
                                //     fechaDesde = valueFiltro.desde;
                                //     fechaHasta = valueFiltro.hasta;
                                //     valueWhere  = fechaDesde;
                                //     valueWhere2 = fechaHasta;
                                //     break;
                                // }
                            }
                        }
                    }
                } else {
                    operador = '==';                    
                }
                
                newWhere = { 
                    key      : keyWhere, 
                    operador : operador, 
                    value    : valueWhere 
                }
                this.grilla.whereArray[cant]=newWhere;
                cant++;

                if(valueWhere2) {
                    newWhere2 = { 
                        key      : keyWhere, 
                        operador : '<=', 
                        value    : valueWhere2 
                    }
                    this.grilla.whereArray[cant]=newWhere2;
                    cant++;
                }
        
            } // fin for      
            
        }
        
        this.grilla.paginaActualNro = 1;
        log(...values("valores","this.grilla:",this.grilla));
        this.getSubscripcionPrincipal();            
        
  }
  
  //ESTA FUNCION SE USA PARA LOS LISTADOS DE SQL por ahora solo probado en REPORTES de rutas y paradas
  titleFiltroPeriodoColumna(columna:any):string{
    // let retorno:string = this.translate.instant(columna.nombreTraduccion);
    let retorno:string = columna.nombreTraduccion;
    let fechaDesde:string = '';
    let fechaHasta:string = '';
    let datePipe =new DatePipe();
    let formato = '';

    if(columna.formato == 'datetime'){
        formato = 'DD/MM/AAAA HH:MM:SS'
    }else if(columna.formato == 'date' ){
        formato = 'DD/MM/AAAA';
    }else if(columna.formato == 'time' ){
        formato = 'HH:MM:SS'
    }

    if(this.arraySelectFiltroFechaDesdeHasta[columna.key]?.length == 2){
      fechaDesde =datePipe.transform(this.arraySelectFiltroFechaDesdeHasta[columna.key][0],formato, this.activeLang,this.usuario.getUTC());
      fechaHasta =datePipe.transform(this.arraySelectFiltroFechaDesdeHasta[columna.key][1],formato,this.activeLang,this.usuario.getUTC());
      retorno = fechaDesde + ' ~ ' + fechaHasta;
    }
    return retorno;
  }


  //ESTA FUNCION SE USA PARA LOS LISTADOS DE FIREBASE 
  titleFiltroPeriodo(nombreFiltroTraduccion:string, nombeFiltro:string, formatoFiltro:string):string{
    let retorno:string = this.translate.instant(nombreFiltroTraduccion);
    let fechaDesde:string = '';
    let fechaHasta:string = '';
    let datePipe =new DatePipe();
    let formato = '';

    if(formatoFiltro == 'datetime'){
        formato = 'DD/MM/AAAA HH:MM:SS'
    }else if(formatoFiltro == 'date' ){
        formato = 'DD/MM/AAAA';
    }else if(formatoFiltro == 'time' ){
        formato = 'HH:MM:SS'
    }

    if(this.searchDateRango[nombeFiltro]?.desde && this.searchDateRango[nombeFiltro]?.hasta){
      fechaDesde =datePipe.transform(this.searchDateRango[nombeFiltro]['desde'], formato, this.activeLang, this.usuario.getUTC());
      fechaHasta =datePipe.transform(this.searchDateRango[nombeFiltro]['hasta'], formato, this.activeLang, this.usuario.getUTC());
      retorno = fechaDesde + ' ~ ' + fechaHasta;
    }
    return retorno;
  }


  //funciona tanto para SQL como para Firebase
  clearFiltroPeriodo(key:string){
    this.grilla.filtros['key'] = null;
    if( this.arraySelectFiltroFechaDesdeHasta[key]!=undefined){
        this.arraySelectFiltroFechaDesdeHasta[key] = null;
    }
    if(this.searchDateRango[key]!=undefined){
        this.searchDateRango[key] = null;
    }
    this.grilla.whereArray = this.grilla.whereArray.filter(where => where.key!=key);
    this.resetFiltros(this.grilla.whereArray.map(where => where.key));
  }


  //funciona para los listados SQL
  seleccionFiltroFecha(selectedMoment:Date[], key:string, formatoColumna:FORMATO, filtrarGrilla:boolean){
    // console.log('zzz seleccionFiltroFecha selectedMoment ', selectedMoment, ' key ', key, ' formatoColumna ', formatoColumna, ' filtrarGrilla ', filtrarGrilla);
    this.grilla.filtros[key] = getFromAndToWithReturnOfAnObject(selectedMoment, formatoColumna, this.activeLang, this.usuario.getUTC());
    if(filtrarGrilla){
        this.filtrarGrilla(key);
    }
  }
 

  
  closeFilterRangoFechas(field:string, fieldSearch) {
    // console.log("closeFilterRangoFechas field",field);
    // console.log("closeFilterRangoFechas fieldSearch",fieldSearch);
    // console.log("closeDateTimePicker fechaHora.selecteds[0]", fechaHora.selecteds[0]);
    // console.log("closeDateTimePicker fechaHora.selecteds[1]", fechaHora.selecteds[1]);
    // console.log("closeFilterRangoFechas jQuery value",$("#"+fieldSearch)[0].value);

    let rango = $("#"+fieldSearch)[0].value;
    // 11/2/2020 ~ 27/2/2020

    let fechas = rango.split('~')
    let fecha1 = this.fn.formatFecha2DigitosDiaMes( fechas[0] );
    let fecha2 = this.fn.formatFecha2DigitosDiaMes( fechas[1] );

    // Parche para detectar que luego de indicar filtros, abrió nuevamente el modal y canceló sin elegir nuevos valores
    // en ese caso, el rango va a mantener los valores viejos, formateados sin años dd/mm  ~ dd/mm con 5 dígitos de extensión
    if(fecha1!='' && fecha1.length==5) fecha1='';
    if(fecha2!='' && fecha2.length==5) fecha2='';

    if(this.searchDateRango.indexOf(field)==-1) {
      this.searchDateRango.push(field);
    }    
    this.searchDateRango[field]={
      desde: fecha1,
      hasta: fecha2,
    };    
    this.grilla.filtros[field]={ 
      desde: fecha1,
      hasta: fecha2,
    }
    // console.log("this.searchDateRango",this.searchDateRango);
    // console.log("this.grilla.filtros",this.grilla.filtros);
    this.filtrarGrilla(field); 

    let rangoFormateado = '';
    if(fecha1!='' && fecha2!='') {
      rangoFormateado = this.fn.quitaAnio(fecha1) + ' ~ ' + this.fn.quitaAnio(fecha2);
    }  

    // console.log("closeFilterRangoFechas rangoFormateado",rangoFormateado);
    $("#"+fieldSearch)[0].value=rangoFormateado;

    if(rangoFormateado=='') {
        $("#"+fieldSearch).removeClass('filtroConValor');
        $("#"+fieldSearch).addClass('filtroSinValor');
    }else{
        $("#"+fieldSearch).removeClass('filtroSinValor');
        $("#"+fieldSearch).addClass('filtroConValor');
    }

    if (!this.changeDetectorRef['destroyed']) {
       this.changeDetectorRef.detectChanges();
    }                

  }
  
  closeFiltroPeriodo(field, rango) {
    //   console.log("closeFiltroPeriodo field",field);
    //   console.log("closeFiltroPeriodo rango",rango);
 
      let fecha1 = rango.desde;
      let fecha2 = rango.hasta;
      
      if(this.searchDateRango.indexOf(field)==-1) {
        this.searchDateRango.push(field);
      }    
      this.searchDateRango[field]={
        desde: fecha1,
        hasta: fecha2,
      };    
      this.grilla.filtros[field]={ 
        desde: fecha1,
        hasta: fecha2,
      }
    //   console.log("this.grilla.filtros",this.grilla.filtros);
      this.filtrarGrilla(field); 
      
  }  
    
  closeDateTimePicker(cual, fechaHora) {
    // console.log("closeDateTimePicker cual",cual);
    // console.log("closeDateTimePicker fechaHora", fechaHora);

  }
  
  
  compareFn(c1: any, c2:any): boolean {     
    // console.log("compareFn c1", c1);
    // console.log("compareFn c2", c2);
      return c1 && c2 ? c1.key === c2.key : c1 === c2; 
  }
  
  isIdiomaHabilitado(idiomaKey:string): boolean {
      if(this.settings2.app.idiomasHabilitados.indexOf(idiomaKey)!=-1) {
          return true;
      } else {
          return false;
      }
  }

  getCantidadDocumentosColeccion() {

      log(...values('funcionComponente','getCantidadDocumentosColeccion'));

      if(!this.nombreColeccion){
        this.grilla.setColeccionCantidadDocs(0, 0, this.tipoPerfilUsuario);
        return
      }
      console.log('jj-getCantidadDocumentosColeccion',this.nombreColeccion);
      let subscripcionCantidad;
      subscripcionCantidad=this.bdService  
          .getBDCantDocumentosPromesa(this.nombreColeccion)
          .then(data=>{

              log(...values('funcionEnd','bdService.getBDCantDocumentosPromesa')); 
              log(...values('valores','data:',data)); 

              this.grilla.setColeccionCantidadDocs(data.cantidadDocumentosFisicos, 
                                                   data.cantidadDocumentosLogicos, 
                                                   this.tipoPerfilUsuario);

          },(error:any)=>{
              log(...values("error",error));
          });         
  }

  getSubscripcionPrincipal() {

      log(...values('funcionComponente','getSubscripcionPrincipal'));
      
      this.spinner.show();

      if(this.tipoBD=='SQL') {
          this.getSQLPrincipal();
          return;
      }
      
      if(this.subscripcionColeccion) this.subscripcionColeccion.unsubscribe();

      log(...values("valores","this.grilla.whereArray:",this.grilla.whereArray)); 
      let where = this.grilla.whereArray;
      /* TODO: eliminar todas las bajas logicas de todos los ABMs
      if(["Desarrollador","Supervisor","Distribuidor"].indexOf(this.tipoPerfilUsuario)==-1) {
            where.push({ key: 'settings.isBorrado', operador: '==', value: false });
      } 
      */
      //where.push({ key: 'settings.isBorrado', operador: '==', value: false });
         
      
      // reemplazo el orden si este es de tipo fechaHora, y se uso un filtro con campo fechaHora diferente
      let filtroname=this.grilla.orderField;
      let filtroFechaHoraName=null;
      if(this.grilla.orderField.includes('fechaHora')) {
          for(let i=0; i<this.grilla.whereArray.length;i++) {
             if(this.grilla.whereArray[i].key.includes('fechaHora')) {
                filtroname =this.grilla.whereArray[i].key;
             } 
          }
      }    

      let orderBy=[];
      if(this.nombreColeccion!='TotalesApis') {
            orderBy.push({
                key     : filtroname,
                ascDesc : (this.grilla.orderReverse==true) ? 'desc' : 'asc',
            });
      }

      let paginado = {
          paginaRequerida : this.grilla.paginaRequerida,
          resultKey1      : this.grilla.resultKey1,
          resultKeyN      : this.grilla.resultKeyN 
      }
      log(...values('valores','where:',where, 'orderBy:',orderBy, 'paginado:',paginado));
      
      this.finalizoGETPrincipal=false;    
      
      console.time("Tiempo"+this.nombreColeccion);

      if(!this.nombreColeccion){
        this.listadoPrincipal          = [];
        this.grilla.resultCantidad     = 0;
        this.vecImagenesDownloadeadas  = [];
        
        this.onResultGetSubscripcionPrincipal(); 
        return   
      }
      
      this.subscripcionColeccion=this.bdService	
          .getBDSubscripcion({
              nombreColeccion  : this.nombreColeccion,
              where            : where,
              orderBy          : orderBy,
              limit            : this.grilla.paginadoCantidad,
              paginado         : paginado,
              organizacionKNAI : this.organizacionKNAI,
              usuarioKANE      : this.usuarioKANE                
          }).subscribe((data:any)=>{

            //   log(...values('funcionEnd','bdService.getBDSubscripcion')); 
            //   log(...values('valores','subscripcion principal data:',data)); 
              
            //   console.log("Tiempo Colección:",this.nombreColeccion);
            //   console.timeEnd("Tiempo"+this.nombreColeccion);    
              
              //this.form.reset();
              //this.resetFormulario();
              //listado                  : this.fn.copiarArray(data),
              
              let rta = this.bdService	
              .postGETProcesaListado({
                  listado                  : this.fn.copiarArray(data),
                  form                     : this.configComponente.form,
                  nombreCampoThumb         : 'logoIMG',
                  vecImagenesDownloadeadas : this.vecImagenesDownloadeadas,
                  organizacionKNAI         : this.organizacionKNAI,
                  usuarioKANE              : this.usuarioKANE,
                  nombreColeccion          : this.nombreColeccion
              });
              this.listadoPrincipal          = rta.listado;
              this.grilla.resultCantidad     = rta.listado.length;
              this.vecImagenesDownloadeadas  = rta.vecImagenesDownloadeadas;

              this.listadoPrincipal = this.postGETActualizaValores(this.listadoPrincipal);
              log(...values('valores','grilla',this.grilla));

              log(...values("valores","listadoPrincipal:",this.listadoPrincipal));

              log(...values("valores","Colección Subscripta:",this.nombreColeccion));
            //   logTable(...values(this.listadoPrincipal,'listadoPrincipal', this.columnasAdicionalesLogTable));
              
              this.finalizoGETPrincipal=true;              
              this.onResultGetSubscripcionPrincipal();

          },(error:any)=>{
              log(...values("error",error));
              
              this.listadoPrincipal          = [];
              this.grilla.resultCantidad     = 0;
              this.vecImagenesDownloadeadas  = [];
              
              this.onResultGetSubscripcionPrincipal();              
          });         
  }


  
  getSQLPrincipal() {

      log(...values('funcionComponente','getSQLPrincipal'));

      log(...values("valores","this.grilla:",this.grilla)); 
      log(...values("valores","this.grilla.whereArray:",this.grilla.whereArray)); 
      let where = this.grilla.whereArray;
      
      // reemplazo el orden si este es de tipo fechaHora, y se uso un filtro con campo fechaHora diferente
      let filtroname=this.grilla.orderField;
      let filtroFechaHoraName=null;
      if(this.grilla.orderField.includes('fechaHora')) {
          for(let i=0; i<this.grilla.whereArray.length;i++) {
             if(this.grilla.whereArray[i].key.includes('fechaHora')) {
                filtroname =this.grilla.whereArray[i].key;
             } 
          }
      }    

      let orderBy=[];
      orderBy.push({
            key     : filtroname,
            ascDesc : (this.grilla.orderReverse==true) ? 'desc' : 'asc',
      });

      let paginado = {
          paginaRequerida  : this.grilla.paginaActualNro,
          paginadoCantidad : this.grilla.paginadoCantidad
      }
      
      log(...values('valores','where:',where, 'orderBy:',orderBy, 'paginadoCantidad:',this.grilla.paginadoCantidad, 'this.grilla.paginaActualNro:',this.grilla.paginaActualNro));
      
      this.finalizoGETPrincipal=false;    
      
      console.time("Tiempo"+this.nombreColeccion);
      
      this.bdService	
          .getBDSQL({
              servicioSQL      : this.servicioSQL, 
              nombreColeccion  : this.nombreColeccion,
              where            : where,
              orderBy          : orderBy,
              paginadoCantidad : this.grilla.paginadoCantidad,
              paginaActualNro  : this.grilla.paginaActualNro,
              organizacionKNAI : this.organizacionKNAI,
              usuarioKANE      : this.usuarioKANE,
              campoClave       : 'clave'                 
          }).then((data:any)=>{

              log(...values('funcionEnd','bdService.getBDSQL')); 
              log(...values('valores','subscripcion principal data:',data)); 
              
              console.log("Tiempo Colección:",this.nombreColeccion);
              console.timeEnd("Tiempo"+this.nombreColeccion);    
              
              //this.form.reset();
              //this.resetFormulario();

              /*
              let rta = this.bdService	
              .postGETProcesaListado({
                  listado                  : this.fn.copiarArray(data),
                  form                     : this.configComponente.form,
                  nombreCampoThumb         : 'logoIMG',
                  vecImagenesDownloadeadas : this.vecImagenesDownloadeadas,
                  organizacionKNAI         : this.organizacionKNAI,
                  usuarioKANE              : this.usuarioKANE,
                  nombreColeccion          : this.nombreColeccion
              });
              */

              this.listadoPrincipal          = this.fn.copiarArray(data);
              this.grilla.resultCantidad     = (data.length==0) ? 0 : data[0].rows;
              this.vecImagenesDownloadeadas  = [];
              
              //this.listadoPrincipal = this.postGETActualizaValores(this.listadoPrincipal);
              log(...values('valores','grilla',this.grilla));

              log(...values("valores","listadoPrincipal:",this.listadoPrincipal));

              log(...values("valores","Colección Subscripta:",this.nombreColeccion));
              logTable(...values(this.listadoPrincipal,'listadoPrincipal', this.columnasAdicionalesLogTable));
              
              this.finalizoGETPrincipal=true;              
              this.onResultGetSubscripcionPrincipal();

          }).catch((error:any)=>{
              log(...values("error",error));
              
              this.listadoPrincipal          = [];
              this.grilla.resultCantidad     = 0;
              this.vecImagenesDownloadeadas  = [];              
              
              this.onResultGetSubscripcionPrincipal();              
          });         
  }
  
  getSubscripcionSecundarias() {

      log(...values('funcionComponente','getSubscripcionSecundarias'));

      /* Subscripción a Colecciones Secundarias */
      this.cantidadSubscripcionesSecundariasLanzadas=0;
      this.finalizoGETSecundarias=false;              
      
      if(this.grilla.cotizacionesActivas) {
          
          // Dolar Oficial
          this.configListadosCache.push({ 
                nombreListado   : 'listadoCotizacionesMonedasDolares',
                nombreColeccion : 'CotizacionesMonedas',
                orderBy         : [{key:'fechaHora',ascDesc:'desc'}],  
                where           : [
                  {key:'moneda1KMONEY.key',operador:'==',value:'Dolares'},
                  {key:'moneda2KMONEY.key',operador:'==',value:'Pesos'}
                ],
                limit           : 1,
                grabaLocalStorage:false        
          });

          // Dolar Oficial
          this.configListadosCache.push({ 
                nombreListado   : 'listadoCotizacionesMonedasDolaresBlue',
                nombreColeccion : 'CotizacionesMonedas',
                orderBy         : [{key:'fechaHora',ascDesc:'desc'}],  
                where           : [
                  {key:'moneda1KMONEY.key',operador:'==',value:'DolaresBlue'},
                  {key:'moneda2KMONEY.key',operador:'==',value:'Pesos'}
                ],
                limit           : 1,
                grabaLocalStorage:false        
          });
      
          // Dolar Divisa
          this.configListadosCache.push({ 
                nombreListado   : 'listadoCotizacionesMonedasDolaresDivisa',
                nombreColeccion : 'CotizacionesMonedas',
                orderBy         : [{key:'fechaHora',ascDesc:'desc'}],  
                where           : [
                  {key:'moneda1KMONEY.key',operador:'==',value:'DolaresDivisa'},
                  {key:'moneda2KMONEY.key',operador:'==',value:'Pesos'}
                ],
                limit           : 1,
                grabaLocalStorage:false        
          });     
      }
      
      this.cantidadSubscripcionesSecundariasLanzadas=this.configListadosCache.length;
      //console.log("this.configListadosCache ",this.configListadosCache);
      
      for(let i=0; i<this.configListadosCache.length; i++) {
          
          this.subscripcionCache.push( 
              this.msg.getListadoCache({
                  nombreListado              : this.configListadosCache[i].nombreListado,
                  nombreColeccion            : this.configListadosCache[i].nombreColeccion,
                  where                      : this.configListadosCache[i].where  !== undefined               ? this.configListadosCache[i].where  :[],
                  orderBy                    : this.configListadosCache[i].orderBy !== undefined              ? this.configListadosCache[i].orderBy:[],
                  limit                      : this.configListadosCache[i].limit !== undefined                ? this.configListadosCache[i].limit  :null,
                  grabaLocalStorage          : this.configListadosCache[i].grabaLocalStorage!== undefined     ? this.configListadosCache[i].grabaLocalStorage:true,   
                  ignoraValoresMemoria       : this.configListadosCache[i].ignoraValoresMemoria!== undefined  ? this.configListadosCache[i].ignoraValoresMemoria:false,   
                  datosPorOrganizacion       : this.configListadosCache[i].datosPorOrganizacion!== undefined  ? this.configListadosCache[i].datosPorOrganizacion:false,   
                  organizacionKNAI           : this.organizacionKNAI,                           
                  usuarioKANE                : this.usuarioKANE,
                  nombreColeccionSolicitante : this.nombreColeccion, 
                  limpiaSettingUsuarioOrganizacion : this.configListadosCache[i].limpiaSettingUsuarioOrganizacion!== undefined  ? this.configListadosCache[i].limpiaSettingUsuarioOrganizacion:false,    
              }).pipe(take(1)).subscribe(data=>{
                 
                  let nombreListado =this.configListadosCache[i].nombreListado.replace('listado','').replace('Listado','')
                  
                  log(...values("valores",
                            "msg.cacheColecciones[" + nombreListado + "]:", 
                            this.msg.cacheColecciones[nombreListado]));
                  
                  this.cantidadSubscripcionesSecundariasLanzadas--;
                  
                //   console.log("cantidadSubscripcionesSecundariasLanzadas",this.cantidadSubscripcionesSecundariasLanzadas, nombreListado);
                  if(this.cantidadSubscripcionesSecundariasLanzadas==0) {  // Devolvió resultados la última subscripción
                        this.finalizoGETSecundarias=true;
                        // cuando están todas las respestas me desiscribo
                        // for (var i=0; i<this.subscripcionCache.length; i++) {
                        //     if(this.subscripcionCache[i]) {
                        //       this.subscripcionCache[i].unsubscribe();
                            
                        //     }
                        // }              
                        this.onResultGetSubscripcionSecundarias();    
                  }

              },(error:any)=>{
                  log(...values("error",error));
                  this.onResultGetSubscripcionSecundarias();
              }) 
          );    
      }
      
      if(this.configListadosCache.length==0) {
        this.onResultGetSubscripcionSecundarias();    
      }  
  
  }

  onResultGetSubscripcionPrincipal() {
    log(...values('funcionComponente','pageGenerica.onResultGetSubscripcionPrincipal'));
    
    // if(this.modalidadOpcion=='listado' && 
    //    (this['accionInicial']===undefined || this['accionInicial']=='listado') ) {
    //     this.setAccionForm('listado');          
    // }
    
    // Sumo las columnas de Tipo numérico
    this.grilla['columnasTotales']=[];
    for(let j=0; j<this.grilla['columnasDefinidas'].length; j++) {
        this.grilla['columnasTotales'].push( this.grilla['columnasDefinidas'][j].key );
        this.grilla['columnasTotales'][ this.grilla['columnasDefinidas'][j].key ] = 0;
    }    

    for(let i=0; i<this.listadoPrincipal.length; i++) {
        let documento = this.listadoPrincipal[i];
        for(let key in documento) {
            //console.log("zzz columnasTotales",key);
            for(let j=0; j<this.grilla['columnasDefinidas'].length;j++) {
                if(this.grilla['columnasDefinidas'][j].key==key) {
                    // console.log("zzz2 columnasTotales",key);
                    this.grilla['columnasTotales'][key] += documento[key];
                    break;                        
                }
            }
        }
    }
    
    this.spinner.hide();            

    if(this.finalizoGETPrincipal && this.finalizoGETSecundarias) {
        this.onResultGetSubscripcionPrincipalYSecundarias();
    }

  }  

  onResultGetSubscripcionSecundarias() {
    log(...values('funcionComponente','pageGenerica.onResultGetSubscripcionSecundarias'));
    // console.log('this.msg.cacheColecciones',this.msg.cacheColecciones)

    if(this.grilla.cotizacionesActivas) {    
        
        // Dolar Oficial
        if(this.msg.cacheColecciones['CotizacionesMonedasDolares']){
            this.cotizaciones['Dolares']=this.msg.cacheColecciones['CotizacionesMonedasDolares'][0].cotizacion;
            this.cotizaciones['fechaHora']=this.msg.cacheColecciones['CotizacionesMonedasDolares'][0].fechaHora;
        } else{
            this.cotizaciones['Dolares']=0;
        }

        // Dolar Blue
        if(this.msg.cacheColecciones['CotizacionesMonedasDolaresBlue'] && this.msg.cacheColecciones['CotizacionesMonedasDolaresBlue'][0]){
            this.cotizaciones['DolaresBlue']=this.msg.cacheColecciones['CotizacionesMonedasDolaresBlue'][0].cotizacion;
            this.cotizaciones['fechaHora']=this.msg.cacheColecciones['CotizacionesMonedasDolaresBlue'][0].fechaHora;
        } else{
            this.cotizaciones['DolaresBlue']=0;
        }
        
        // Dolar Divisa
        if(this.msg.cacheColecciones['CotizacionesMonedasDolaresDivisa']){
            this.cotizaciones['DolaresDivisa']=this.msg.cacheColecciones['CotizacionesMonedasDolaresDivisa'][0].cotizacion;
            this.cotizaciones['fechaHora']=this.msg.cacheColecciones['CotizacionesMonedasDolaresDivisa'][0].fechaHora;
        } else{
            this.cotizaciones['DolaresDivisa']=0;
        }
        
        console.log("this.cotizaciones", this.cotizaciones);
    }       
    
    if(this.finalizoGETPrincipal && this.finalizoGETSecundarias) {
        this.onResultGetSubscripcionPrincipalYSecundarias();
    }
  }    

  onResultGetSubscripcionPrincipalYSecundarias() {
    log(...values('funcionComponente','pageGenerica.onResultGetSubscripcionPrincipalYSecundarias'));
    // verifico si existe transferencia la primera vez (this.configExcel ==null) cuando tengo todos los datos.
    // con el paginado no es necesario recalcularlo.
    if(this.existeImportadorExcel==true || this.existeSOAP_RoadNet==true){
        // Ya se disparó antes this.existeTransferenciaExcell()      
    } else {    
        this.existeTransferenciaExcell();
    }
  }    
  
  // -------------- Emits de Componentes Incluídos ---------------------------------------

  emitCargoVideoUrl(event) {
    log(...values('valores','emitCargoVideoUrl:',event));
    this.form.get(event.nameField).setValue( event.value );
  }
  
  emitClickPaginado(paginaRequerida:string):void{
     log(...values('valores','emitClickPaginado paginaRequerida:',paginaRequerida));
     this.grilla.paginaRequerida = paginaRequerida;
     this.getSubscripcionPrincipal();
  }    

  emitClickPaginadoSQL(paginaRequerida:number):void{
    log(...values('valores','emitClickPaginado paginaRequerida:',paginaRequerida));
    this.grilla.paginaActualNro = paginaRequerida;
    this.getSubscripcionPrincipal();
 }    
 
  EmitCargoSearchAutocompleteFiltro(fieldFiltro:string, bdFieldName:string, documento:any) {
      //console.log("EmitCargoSearchAutocompleteFiltro - fieldFiltro:",fieldFiltro,"bdFieldName:",bdFieldName,"documento:",documento);  

      this.grilla.filtros[fieldFiltro]= this.fn.getDocField(documento,bdFieldName);    
      // console.log("this.fn.getDocField(documento,"+bdFieldName+")",this.fn.getDocField(documento,bdFieldName));

      this.filtrarGrilla(bdFieldName);
  }

  EmitCargoSearchAutocompleteForm(fieldForm:string, bdFieldName:string, documento:any) {
        console.log("EmitCargoSearchAutocompleteForm - fieldForm:",fieldForm,"bdFieldName:",bdFieldName,"documento:",documento);  

        if(bdFieldName=='KNAI') {
            this.form.get(fieldForm).setValue( this.fn.setearOrganizacionKNAI(documento) );
        } else if(bdFieldName=='KANE') {
            this.form.get(fieldForm).setValue( this.fn.setearKANE(documento) );
        } else if(bdFieldName=='KN') {
            this.form.get(fieldForm).setValue( this.fn.setearKN(documento) );
        } else if(bdFieldName=='OBJ') {
            // if(documento.keywords!==undefined)         delete documento.keywords;
            // if(documento.settings!==undefined)         delete documento.settings;
            // if(documento.organizacionKNAI!==undefined) delete documento.organizacionKNAI;
            this.form.get(fieldForm).setValue( documento );
        } else {        
            this.form.get(fieldForm).setValue( this.fn.getDocField(documento,bdFieldName) );
        }
        // console.log("this.form.get("+fieldForm+").value",this.form.get(fieldForm).value);
        
        // En caso de que el formulario tenga seteado distribuidorKN, y venga el campo distribuidor lo seteo
        // Se utiliza en el módulo de Rutas, donde en caso de perfil Desarrollador, Supervisor, Distribuidor se ingresa a mano
        console.log("this.form",this.form);
        if(this.form.controls.distribuidorKN!==undefined && documento && documento['distribuidor']!=undefined) {
            this.distribuidorKN = this.fn.setearKN(documento['distribuidor']);            
            this.form.get('distribuidorKN').setValue( this.fn.setearKN(documento['distribuidor']) );            
        }
        
  }

  emitCargoSubEstructura(subestructura){
     log(...values('valores','emitCargoSubestructura subestructura:',subestructura));
    //  console.log("this.form.controls['ventanaAtencion.horarioAtencion']",this.form.controls['ventanaAtencion.horarioAtencion']);
    //  if(subestructura=='horarioAtencion') {
    //     if(this.form.controls['ventanaAtencion.horarioAtencion']!==undefined) {
    //         this.form.get('ventaAtencion.horarioAtencion').setValue(subestructura.value);              
    //     } else {
    //         this.form.get('horarioAtencion').setValue(subestructura.value);              
    //     }
    //  } else {
    //     this.form.get(subestructura.nameField).setValue(subestructura.value);          
    //  }

     this.form.get(subestructura.nameField).setValue(subestructura.value);          
     
  }

  emitCargoZona(zona:any){
    log(...values('valores','emitCargoZona zona:',zona));
    
    this.form.get('paisesDeOperacion').setValue(zona.paisesDeOperacion);      
    this.form.get('provinciasDeOperacion').setValue(zona.provinciasDeOperacion);      
    
  }

  emitDesabilitarGrabar(estado:boolean):void{
     log(...values('valores','emitDesabilitarGrabar estado:',estado));
     this.permisos.deshabilitarGrabar = estado;  
  }    

  emitCargoArchivo(imagenes:any){
     log(...values('valores','emitCargoArchivo imagenes:',imagenes));
     // this.uploadImagen=imagen;

     let fieldName = imagenes[0];
     this.arrayFILES[ fieldName ].nombreArchivo    = imagenes[1];
     this.arrayFILES[ fieldName ].extensionArchivo = imagenes[2];
     if(fieldName.includes('IMG')) {
        this.arrayFILES[ fieldName ].link          = imagenes[3];
        this.arrayFILES[ fieldName ].linkThumb     = imagenes[4];
     } else {
        this.arrayFILES[ fieldName ].link          = imagenes[3];
     }
     this.arrayFILES[ fieldName ].borrar           = false;

     if(fieldName.includes(".")) {
        let aux=fieldName.split('.') 
        this.form.value[ aux[0] ][ aux[1] ].link = this.arrayFILES[ fieldName ].link;    
     } else {
        this.form.value[fieldName].link = this.arrayFILES[ fieldName ].link;    
     }

     log(...values('valores',`arrayFiles[${fieldName}]`),this.arrayFILES[ fieldName ]);

  }

  emitBorroArchivo(fieldName:any){
     log(...values('valores','emitBorroArchivo fieldName:',fieldName));

     this.arrayFILES[ fieldName ].nombreArchivo    = null;
     this.arrayFILES[ fieldName ].extensionArchivo = null;
     this.arrayFILES[ fieldName ].link             = null;
     this.arrayFILES[ fieldName ].linkThumb        = null;
     this.arrayFILES[ fieldName ].borrar           = true;

     log(...values('valores',`arrayFILES[${fieldName}]`),this.arrayFILES[ fieldName ]);

  }
  
  ValidaDocumentoIsActivo(opcionKey:string, documentoIsActivo:boolean, value:any):boolean {
      //console.log("ValidaDocumentoIsActivo",opcionKey,documentoIsActivo, value)
      if(this.accionForm=='agregar') { 
          return documentoIsActivo;
      } else if(this.accionForm=='consultar') {           
          return true;
      } else if(this.accionForm=='modificar') { 
           let valueKey:any=null;
          if(value===undefined || value==null) {
              valueKey = documentoIsActivo;   
          } else {
              valueKey = ( value['key']===undefined) ? value : value['key'];
          }    
          if(documentoIsActivo==true) {
              return true;
          } else if(value==null) {
              return false;
          } else if( valueKey==opcionKey) {
              return true;
          } else {
              return false;
          }
      }    
      
  }  
  
  abrirFormulario(documentoOriginal) {    

    log(...values('funcionComponente','abrirFormulario'));
    log(...values('valores','documentoOriginal:',documentoOriginal));
    
    console.log("inicio ----------------");
    //let documento=documentoOriginal ? {...documentoOriginal} : null;
    let documento=this.fn.copiarObjetoNew(documentoOriginal);
    console.log("fin ----------------");
    
    if(documento){
        // log("valores","keywords:", this.generateKeywords(documento.nombre) );
        log(...values('valores','documento:',documento));

        // Formateo Campos Decimales
        for(let i=0; i<this.grilla.camposDecimal.length; i++) {
            let fieldName=this.grilla.camposDecimal[i];
            let value = this.fn.getDocField(documento,fieldName);
            //console.log("xx2 fieldName, value1",fieldName, value);
            if(value!=null) {
                let valueFormatNumber = formatNumber( value, 'es-Ar',"1.2-2");    
                documento = this.fn.setDocField(documento, fieldName, valueFormatNumber);
            }
        }
        log(...values('valores','documento:',documento));
        log(...values('valores','listadoPrincipal:',this.listadoPrincipal));
        
        this.resetFormulario();
        this.form.setValue( this.fn.igualarDocumentoConForm(this.form,documento,'destinoForm'));
        log(...values('valores','form:',this.form));

        this.setAccionForm('consultar');
    } else { 
        this.setAccionForm('agregar');
    }   

  }

  setAccionForm(accion) {
    log(...values('funcionComponente','setAccionForm pageGenerica', accion));

    this.accionForm=accion;
    
    if(accion=='listado') {
        this.resetFormulario();

    } else if(accion=='consultar') {
        this.form.disable();
        
    } else { // agregar o modificar
        
        this.form.enable();
        this.formInvalid=false;
        
        // log(...values('valores','form:',this.form));
        // if(!this.changeDetectorRef['destroyed']) {
        //     this.changeDetectorRef.detectChanges();
        // } 

        if(accion=='agregar') {
            this.resetFormulario();
            
            // Reemplazo 'Array' x []
            this.form.setValue( this.fn.inicializaArrays(this.form.value) );
            log(...values('valores','form:',this.form.value));
            
            // Inicializo Campos tipo IMG/FILE
            this.arrayFILES.forEach(fieldName => {
                // log(...values('valores','agregar arrayFiles fieldName:',fieldName));
                // log(...values('valores','agregar arrayFiles[fieldName]:',this.arrayFILES[fieldName]));
                // log(...values('valores','agregar form[fieldName]:',this.form.get(fieldName).value));
                this.form.get(fieldName).setValue({
                    link: null,
                    linkThumb: null,
                    bytes: 0,
                    bytesThumb: 0                
                });
                this.arrayFILES[fieldName] = {
                  input  : null,
                  borrar : null
                }  
            });  
        }  
        if(accion=='modificar') {
            this.form.get('key').disable();
            
            // if(this.form.get('settings.isBorrado').value==true) {
            //     this.form.disable();
            //     this.form.get('settings.isBorrado').enable();            
            // }    
        }    
    } 
    
    if(accion=='consultar') {
        
        log(...values("valores:","vecImagenesDownloadeadas",this.vecImagenesDownloadeadas));
        
        this.arrayFILES.forEach(fieldName => {
            
            let linkImage  =  (this.form.get(fieldName).value!=null) ? this.form.get(fieldName+'.link').value : null;
            let bytesImage =  (this.form.get(fieldName).value!=null) ? this.form.get(fieldName+'.bytes').value : 0;
            
            log(...values("valores","linkImage:",linkImage));
            log(...values("valores","this.arrayFILES[fieldName].type:",this.arrayFILES[fieldName].type));

            if(this.arrayFILES[fieldName].type=='IMG' && linkImage !=null &&
               this.vecImagenesDownloadeadas.indexOf(linkImage)==-1) {

                this.vecImagenesDownloadeadas.push( linkImage );

                this.apis.LogApiFuncion({
                    eventoQueDisparo : 'pageGenerica.setAccionForm - Consulta Documento - Imágen/es del Formulario',
                    apiFuncionKey    : 'FirebaseStorageOperationDownload', 
                    organizacionKNAI : this.organizacionKNAI,
                    usuarioKANE      : this.usuarioKANE,
                    nombreColeccion  : this.nombreColeccion,
                    cloudFunction    : null,
                    cantidad         : 1, 
                });

                this.apis.LogApiFuncion({
                    eventoQueDisparo : 'pageGenerica.setAccionForm - Consulta Documento - Imágen/es del Formulario',
                    apiFuncionKey    : 'FirebaseStorageDownloaded', 
                    organizacionKNAI : this.organizacionKNAI,
                    usuarioKANE      : this.usuarioKANE,
                    nombreColeccion  : this.nombreColeccion,
                    cloudFunction    : null,
                    cantidad         : bytesImage,
                });
            }
        }); // fin forEach           
    }
    
  }
  
  onSubmit(documento:any):void {

      log(...values('funcionComponente','pageGenerica.onSubmit'));
      log(...values('valores','form:',this.form));      
      log(...values('valores','documento:',documento));      
      
      let controls=this.form.controls;  
  
      // Agrego campos eliminados de value por los disable()
      documento = this.fn.agregarDisabledFields(documento,controls);
      
      log(...values('valores','documento:',documento));      

      if( JSON.stringify(this.documentoOriginal) != JSON.stringify(documento) ) {
            log(...values('valores','controls:',controls));      
            log(...values('valores','formatearDocumentoPostSubmit documento:',documento));
            this.documentoOriginal = documento;
      }

      this.spinner.show();

      if (this.form.invalid ) {
          
          for(let atributo in this.form.controls) {
            if(this.form.controls[atributo].status=="INVALID") {
                log(...values('error',"Error de validación en "+atributo));      
            }          
          }          

          this.formInvalid=true;
          this.form.markAllAsTouched();

          this.spinner.hide();
          this.toastrService.error('', this.translate.instant('componente-listado.erroresValidacion'),{
               timeOut: 2000,positionClass:'toast-center-center'});

      } else {

          if(this.accionForm=='agregar' && this.form.get('key').value != null) {
            
            let argumentos:any={
                nombreColeccion  : this.nombreColeccion,
                where            : [{key:'key',operador:'==',value:documento.key}],
                orderBy          : [],
                limit            : 1,
                paginado         : null,
                offset           : null,
                organizacionKNAI : this.organizacionKNAI,
                usuarioKANE      : this.usuarioKANE
              };
              
              this.bdService.getBDPromesa(argumentos) 
                .then((resultado:any[])=>{
                    console.log('bdService.getBDPromesa ok',resultado);                    
                    
                    if(resultado.length>0) {
                        this.spinner.hide();
                        
                        this.alertService.confirm({ 
                            title:   this.translate.instant('mensajes.errorGrabar'), 
                            message: this.translate.instant('mensajes.keyDuplicada') })                            
                    } else {
                        this.upload_files(documento);              
                    }
          
                }).catch((error:any)=>{
                    console.log('bdService.getBDPromesa error',error);
                    this.spinner.hide();
                });  
            
          } else {
                this.upload_files(documento);      
          } 
          

      } // fin if form.invalid  
  } 
  
  upload_files(documento) {

          // console.log("this.arrayFILES",this.arrayFILES);   

          this.arrayUpload = [];

          this.arrayFILES.forEach(fieldName => {

              log(...values("valores","fieldName:",fieldName));
              log(...values("valores",`arrayFiLES[${fieldName}]:`,this.arrayFILES[fieldName]));

              let estructura       = null;
              let campoEstructura  = null;
              let campoSave        = fieldName;                  
            
              if(fieldName.includes('.')) {
                estructura      = fieldName.split('.')[0];
                campoEstructura = fieldName.split('.')[1];
                campoSave       = campoEstructura;
              }  

              if(this.arrayFILES[fieldName].link !=null) {

                  log(...values("valores","documento[fieldName]:",documento[fieldName]));
                  log(...values("valores","this.arrayFILES[fieldName]:",this.arrayFILES[fieldName]));
                  if(fieldName.includes('.')) {
                        documento[estructura][campoEstructura].link = this.arrayFILES[fieldName].link;
                  } else {
                        documento[fieldName].link = this.arrayFILES[fieldName].link;
                  }  

                  // log(...values("imagen",`${fieldName}.link:`,documento[fieldName].link));
                  var path = this.fn.rutaUpload({
                      extension        : this.arrayFILES[fieldName].extensionArchivo,
                      nombreColeccion  : this.nombreColeccion,
                      organizacionKNAI : this.organizacionKNAI,
                      adicionalNombre  : campoSave
                  });

                  this.arrayUpload.push({
                      fieldName : fieldName,
                      linkName  : 'link',
                      path      : path,
                      image     : this.arrayFILES[fieldName].link
                  });

              }    
                  
              // log(...values("lineaVariables"));

              if(this.arrayFILES[fieldName].linkThumb !=null) {

                  if(fieldName.includes('.')) {
                        documento[estructura][campoEstructura].linkThumb = this.arrayFILES[fieldName].linkThumb;
                  } else {
                        documento[fieldName].linkThumb = this.arrayFILES[fieldName].linkThumb;
                  }  
                  
                  // log(...values("imagen",`${fieldName}.linkThumb:`,documento[fieldName].linkThumb));
                  var pathThumb = this.fn.rutaUpload({
                      extension        : this.arrayFILES[fieldName].extensionArchivo,
                      nombreColeccion  : this.nombreColeccion,
                      organizacionKNAI : this.organizacionKNAI,
                      adicionalNombre  : campoSave+'Thumb'
                  });

                  this.arrayUpload.push({
                      fieldName : fieldName,
                      linkName  : 'linkThumb',
                      path      : pathThumb,
                      image     : this.arrayFILES[fieldName].linkThumb
                  });

              }    

          }); // fin For

          log(...values("valores","arrayUpload:",this.arrayUpload));

          if(this.arrayUpload.length > 0) {

              let vecPromesas = [];
              for(let j=0; j<this.arrayUpload.length; j++) {
                  vecPromesas.push(
                    this.bdService.uploadFirestoreImage(
                      this.arrayUpload[j].fieldName,
                      this.arrayUpload[j].linkName,
                      this.arrayUpload[j].path,
                      this.arrayUpload[j].image
                    )
                  );
              }

              Promise.all( vecPromesas).then(vecUploadResult=>{
                  log(...values('funcionEnd','bdService.uploadFirestoreImage'));
                  log(...values('valores','vecUploadResult:',vecUploadResult));

                  for(let i=0; i<vecUploadResult.length; i++) {
                      let uploadResult = vecUploadResult[i];
                      //console.log("i",i,vecUploadResult[i]);

                      if(uploadResult.fieldImage.includes('.')) {
                            let estructura2      = uploadResult.fieldImage.split('.')[0];
                            let campoEstructura2 = uploadResult.fieldImage.split('.')[1];                        
                            documento[estructura2][campoEstructura2][uploadResult.fieldLink] = uploadResult.downloadURL;                        
                      } else {                               
                            documento[uploadResult.fieldImage][uploadResult.fieldLink] = uploadResult.downloadURL;
                      }      

                      let nameBytes = uploadResult.fieldLink.replace('link','bytes');
                      if(uploadResult.fieldImage.includes('.')) {                      
                            let estructura3      = uploadResult.fieldImage.split('.')[0];
                            let campoEstructura3 = uploadResult.fieldImage.split('.')[1];                        
                            documento[estructura3][campoEstructura3][nameBytes] = uploadResult.bytes;
                      } else {  
                            documento[uploadResult.fieldImage][nameBytes] = uploadResult.bytes;
                      }      
                      
                      this.apis.LogApiFuncion({
                            eventoQueDisparo : 'pageGenerica.onSubmit - Upload File',
                            apiFuncionKey    : 'FirebaseStorageUploadOperation', 
                            organizacionKNAI : this.organizacionKNAI,
                            usuarioKANE      : this.usuarioKANE,
                            nombreColeccion  : this.nombreColeccion,
                            cloudFunction    : null,
                            cantidad         : 1, 
                        });
        
                        this.apis.LogApiFuncion({
                            eventoQueDisparo : 'pageGenerica.onSubmit - Upload File',
                            apiFuncionKey    : 'FirebaseStorageStoredData', 
                            organizacionKNAI : this.organizacionKNAI,
                            usuarioKANE      : this.usuarioKANE,
                            nombreColeccion  : this.nombreColeccion,
                            cloudFunction    : null,
                            cantidad         : uploadResult.bytes,
                        });
                          
                  }
                  // log(...values('valores','documento:',documento));

                  this.grabar_coleccion(documento);

              }).catch(error=>{
                  log(...values("error","Error de Upload:",error));
              });

          } else {
              this.grabar_coleccion(documento);
          }    

  }
  
  grabar_coleccion(documento) {
      
        log(...values("valores","grabar colección - documento",documento));
        log(...values("valores","grabar colección - this.arrayFILES",this.arrayFILES));
        
      
        /* ----- Seteo Campos KN, KANE, KNAI ------------------------- */      
        let aux_sucursalKN = (documento.sucursalKN!==undefined) ? documento.sucursalKN : null;  // campos KN que en realidad graban el objeto completo
        
        documento = this.fn.seteaCamposKN(documento);
        
        if(this.nombreColeccion=='Ubicaciones') documento.sucursalKN = aux_sucursalKN;
        
        // Formateo campos de tipo decimal
        for(let i=0; i<this.grilla.camposDecimal.length;i++) {
              let fieldName = this.grilla.camposDecimal[i];
              let value = this.fn.getDocField(documento,fieldName);
              //console.log("xx1 fieldName, value1",fieldName, value);
              if(value!=null) {              
                  let valueFloat = this.fn.convertMaskDecimalelToFloat( value, 2);    
                  documento = this.fn.setDocField(documento, fieldName, valueFloat);
              }          
        }

        /* ----- Seteo Keywords ------------------------- */      
        if(this.grilla.campoKeywords==true) {
            let stringKeywords = documento[ this.grilla.filtroNombre ];
            if(this.grilla.filtroNombre=='datosPersonales.apellidoNombre') {
                stringKeywords = documento.datosPersonales.apellido+' '+documento.datosPersonales.nombre;
            }
            documento['keywords'] = this.fn.generateKeywords( stringKeywords );
            log(...values('valores',"documento['keywords']:", documento['keywords']));                    
        }

        /* ----- Seteo Archivos para Grabar ------------------------- */      
        this.arrayFILES.forEach(fieldName => {

            if(this.arrayFILES[fieldName].borrar==true) {
                // Lo blanqueo para que se borre en la base de Datos
                documento[fieldName]=null;       

            } else if(this.arrayFILES[fieldName].link !=null) {
                // documento[fieldName] va a pisar el valor grabado anteriormente

            } else if(documento[fieldName]!=null) {
                // Quito el campo del objeto, para que no se borre al grabar con set({merge:true})
                if(documento['copyKey']!==undefined && documento['copyKey']) {
                    // nada mantenga el valor para que se grabe    
                } else {
                    delete documento[fieldName];        
                }
            } 

        });
            
        // Seteo el Usuario que realiza la operación
        documento.settings.usuarioKANE = this.usuarioKANE;

        /* ------ Seteo el campo organizacionKN en caso de que la tabla lo utilice ---------- */
        let argumentoOrganizacionKNAI = this.organizacionKNAI;
        if(documento.organizacionKNAI!==undefined) {
            argumentoOrganizacionKNAI = documento.organizacionKNAI;
        }    

        // Eliminar campos no desados del formulario

        for (let i=0; i< this.listadoCamposNoDeseados.length; i++) {
            let campo=this.listadoCamposNoDeseados[i];
            // if(documento[campo] ){
                delete documento[campo]
            // }

        }

        this.form.disable();  // Deshabilito el Formulario, para que no explote cuando asigno centinela a settings.FechaHoraModificacion

        this.bdService.updateColeccion({
              operacion        : this.accionForm,
              nombreColeccion  : this.nombreColeccion,
              documento        : documento,
              distribuidorKN   : this.distribuidorKN,
              organizacionKNAI : argumentoOrganizacionKNAI,                           
              usuarioKANE      : this.usuarioKANE
          }).then(dato=>{
                let keyForm = dato.replace('|mensajes.grabacionOk', '');
                let mensajeServicio = dato.replace(keyForm+'|', '');

                log(...values('success','resupuesta Promesa ok',mensajeServicio,keyForm));

                this.toastrService.success('', this.translate.instant(mensajeServicio),{
                    timeOut: 2000, positionClass:'toast-top-center'});
                    
                this.postGrabarColeccion(documento);    

                if(this.accionForm=='agregar') {
                    // Asigno el key obtenido al Formulario
                    this.form.get('key').setValue(keyForm);

                    // Fuerzo el Filtro por el Nombre del Documento que se acaba de agregar
                    if(this.grilla.filtroNombre=='datosPersonales.apellidoNombre') {
                        this.grilla.filtros[ this.grilla.filtroNombre ] = documento.datosPersonales.apellidoNombre;
                    } else {    
                        this.grilla.filtros[ this.grilla.filtroNombre ] = documento[ this.grilla.filtroNombre ];  
                    }    
                    this.filtrarGrilla();                    
                    
                    this.setAccionForm('listado');  
                    this.spinner.hide();                          
                    
                } else {
                    this.setAccionForm('listado');  
                    this.spinner.hide();                          
                }   
                
                // if(this.modalidadOpcion=='listado' && 
                //    (this['accionInicial']===undefined || this['accionInicial']=='listado') ) {
                //     this.setAccionForm('listado');          
                // }
                // this.spinner.hide();            

          })
          .catch(error=>{
                log(...values("error","Error Promesa Update Coleccion:",error));

                this.spinner.hide();
                this.toastrService.error('', this.translate.instant('mensajes.errorGrabar'),{
                           timeOut: 2000, positionClass:'toast-top-center'});
          });


  }
  
  postGrabarColeccion(documento:any)  {
      // Nada, la actividad se realiza en el componente de ser necesaria
  }  

  deleteDocumento(operacion: string, documento:any){

    log(...values('funcionComponente','deleteDocumento',operacion));
    this.confirmService.confirm({ 
        title:   this.translate.instant('navegabilidad.eliminar'), 
        message: this.translate.instant('mensajes.confirmaEliminar') })
    .then((resultado) => {
        if (resultado == 'close') return;

        this.spinner.show();

        this.bdService.updateColeccion({
            operacion        : operacion,
            nombreColeccion  : this.nombreColeccion,
            documento        : documento,
            distribuidorKN   : this.distribuidorKN,
            organizacionKNAI : this.organizacionKNAI,                           
            usuarioKANE      : this.usuarioKANE,
          }).then(dato=>{
                log(...values("success","OK Promesa Update Coleccion:", dato));
                this.spinner.hide();
                this.toastrService.success('', this.translate.instant(dato),{
                            timeOut: 2000, positionClass:'toast-top-center'});
                            
                // this.postGrabarColeccion(documento);                
          })
          .catch(error=>{
                log(...values("error","Error Promesa Update Coleccion:",error));

                this.spinner.hide();
                this.toastrService.error('', this.translate.instant('mensajes.errorEliminar'),{
                           timeOut: 2000, positionClass:'toast-top-center'});
          });

    }).catch(error=>{
          console.log(error);
    }); 

  }

  // Funciones de Reporte ******************************
  
  actualizarColumnasVisibles(event) {
    // Acualiza columnas con la selección del component multi-select  
    console.log("actualizarColumnasVisibles",event);
    
    this.grilla.columnasVisibles = event.value;
    
  }

    // reemplazarColumnasVisibles(columnasVisiblesSeleccionadas:ColumnaReporte[]) {
    //     console.log('<<< columnasVisiblesSeleccionadas ', columnasVisiblesSeleccionadas);
    //     // Actualiza columnas visibles con el reporte favorito seleccionado (ver componente)  
    //     this.grilla.columnasVisibles = [];
    //     for (let index = 0; index < columnasVisiblesSeleccionadas?.length; index++) {
    //       let colReporte = new ColumnaReporte(columnasVisiblesSeleccionadas[index]);
    //       if(colReporte.getFiltro()==FILTRO.PERIODO_DATE || colReporte.getFiltro()==FILTRO.PERIODO_TIME){
    //         let fechas:Date[] = [];
    //         this.arraySelectFiltroFechaDesdeHasta[colReporte.getNombreFiltro()]= fechas;
    //       }
    //       this.grilla.columnasVisibles.push(colReporte);
    //     }
    
    //     if(!this.changeDetectorRef['destroyed']) {
    //       this.changeDetectorRef.detectChanges();
    //     } 
    // }

    reemplazarColumnasVisibles(columnasVisibles) {
        // Actualiza columnas visibles con el reporte favorito seleccionado (ver componente)  
        this.grilla.columnasVisibles = columnasVisibles;
        if(!this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        } 
    }

    reporteExportarExcel(nombreReporte:string, useFileName?:boolean):Promise<any> {
        return new Promise((resolve, reject) => {
            try{
                let jsonExportar = [];
                let isDate: boolean = false; 
                let formatoFecha: string = '';
                let colVisible = null;
                let keyColumnaVisible: string = '';
                let keyVisible : string = '';
                let arrayKeyVisibles: string[] = [];
                let nombresTraducidosVisibles:string[]= [];

                let copiaListadoPrincipalVisible: object[] = []; //guardo solamente documentos con aquellas keys visibles
                let documentPrincipal = {}; //documento solo con las keys visibles

                //obtengo todas las keys las cargo en un array para luego borrar aquellas key que no estan visibles
                arrayKeyVisibles = this.grilla.getKeysVisibles();
                //obtengo todas los nombres (traducidos) visibles y las cargo en un array para luego ordenar los documentos a exportar
                nombresTraducidosVisibles = this.grilla.getNombresVisibles(this.translate);

                for (let index = 0; index < this.listadoPrincipal.length; index++) {

                    documentPrincipal = this.listadoPrincipal[index];
                    
                    for(let key in documentPrincipal) {
                        if(arrayKeyVisibles.indexOf(key)==-1){
                            delete documentPrincipal[key]; //borro las key que no estan visibles
                        }
                    }
                    copiaListadoPrincipalVisible.push(documentPrincipal);
                }


                for(let i=0; i<copiaListadoPrincipalVisible.length;i++) {
                    
                    let documentoExcel={};
                    let valorInListPrincipal = null;

                    documentPrincipal = copiaListadoPrincipalVisible[i];

                    for(let key in documentPrincipal) {

                        valorInListPrincipal = documentPrincipal[key];
                        
                        let z:number = 0; //hago esto aproposito para acortar la iteracion de las columnas visibles y no volver a pasar por las mismas

                        for(z; z<this.grilla.columnasVisibles.length; z++) {

                            colVisible = this.grilla.columnasVisibles[z];
                            keyColumnaVisible = colVisible.key;
                            keyVisible = keyColumnaVisible;
                            if(keyColumnaVisible.includes('.')) {
                                let aux=keyColumnaVisible.split('.');
                                keyVisible = aux[1];
                            }
                            
                            let formatoFechaPipe ='';
                            if(keyVisible==key) {
                                if(colVisible.formato=='datetime' || colVisible.formato=='date' || colVisible.formato == 'time'){
                                    if(colVisible.formato=='datetime'){
                                        formatoFecha = 'dd/MM/YYYY HH:mm:ss';
                                        formatoFechaPipe = 'DD/MM/AAAA HH:MM:SS';
                                    }else if (colVisible.formato=='date'){
                                        formatoFecha = 'dd/MM/YYYY';
                                        formatoFechaPipe = 'DD/MM/AAAA';

                                    }else{
                                        formatoFecha = 'HH:mm:ss';
                                        formatoFechaPipe = 'HH:MM:SS';
                                    };

                                    isDate = true;
                                }else{
                                    isDate = false;
                                }
                                let nombreTraducido:string = this.translate.instant(colVisible.nombre);

                                if(isDate == true){
                                    if(valorInListPrincipal){
                                        documentoExcel[nombreTraducido] = new DatePipe().transform(valorInListPrincipal, formatoFechaPipe, this.activeLang, this.usuario.getUTC());
                                    }else{
                                        documentoExcel[nombreTraducido] = null;
                                    }
                                }else{
                                    if(typeof (valorInListPrincipal) != "string") {
                                        documentoExcel[nombreTraducido] = valorInListPrincipal;  
                                    }else{
                                        documentoExcel[nombreTraducido] = valorInListPrincipal != undefined && valorInListPrincipal != null ? this.translate.instant( valorInListPrincipal ) : valorInListPrincipal;  
                                    }
                                }

                                break;
                            }

                        }
                    }
                    documentoExcel = ordenarJSON(documentoExcel, nombresTraducidosVisibles);//ordeno el documento que le envio al excel para que siga el mismo orden que el listado mostrado
                    jsonExportar.push(documentoExcel);
                }
            
                let hojaExcel = {
                'hoja1':jsonExportar
                }

                resolve(hojaExcel);
                
                this.excel.exportJsonAsExcelFile(hojaExcel, nombreReporte, useFileName);

            }catch(error){
                reject(error); 
            }
        });
    }
    
    public onChangeConfigurarColumnas(){
        this.configurarColumnas = !this.configurarColumnas;
    }

    setUTCInUser(){
        this.usuario.getUTCDefault(this.bdBaseService);
    }
 
    reporteExportarExcelTotales(nombreReporte:string, useFileName?:boolean) { 
        let jsonExportar = [];
        
        for(let i=0; i<this.listadoTotales.length;i++) {
            let documento={};
            
            for(let key in this.listadoTotales[i]) {
              
                let value = this.listadoTotales[i][key];
                documento[key] = value;  
            }
            jsonExportar.push(documento);
            
        }
      
        let hojaExcel = {
          'hoja1':jsonExportar
        }
        
        this.excel.exportJsonAsExcelFile(hojaExcel, nombreReporte, useFileName);
      
    } 
  
   /*********************************************************
     *********                                        ********
     ********* Inicio Funciones Transferencias Excel  ********
     *********                                        ********
     *********************************************************/

    existeTransferenciaExcell(){

        this.generadorImportadores=  new GeneradorImportadores(this.nombreColeccion);
        //Verifico si existe un importador
        this.existeImportadorExcel = this.generadorImportadores.existeImportador();
        this.existeSOAP_RoadNet    = this.generadorImportadores.existeSOAP_RoadNet();
        
        if (!this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        }                
       
    }
      
    configurarTransferenciaExcel(event:any) {
        // console.log('event',event);
        this.configExcel =null;
        
        let argumentos:any = {
          distribuidorKN:           this.distribuidorKN,  
          organizacionKNAI:         this.organizacionKNAI,
          usuarioKANE:              this.usuarioKANE,
          listadoPrincipalCompleto: null,
          listadosCache:            this.msg.cacheColecciones
          }
        console.log('importadores argumentos', argumentos);

        this.generadorImportadores.getImportador(this.bdService,this.msg,argumentos)
        .then((configExcel:ConfigExcel)=>{
        //   console.log('importadores',configExcel)
          this.configExcel=configExcel;
          this.configExcel['eventArchivoExcel'] = event;
          this.configExcel.iniciarTransferencia = true;
        }).catch(error=>{
          this.configExcel=null;
          console.log('importadores error',error);      
        });
   
  
    }
   
    emit_finalizoTransferenciaExcel() {
      
        // console.log('emit_finalizoTransferenciaExcel');
        // Reset Componente de Transferencia para que permita que se ingres un nuevo archivo luego de procesarlo.
        this.fileInput1.nativeElement.value = "";
    
        
        // Ejecuto proceso posterior en la BD cuando finaliza la importación del Excel
        if(this.configExcel!==undefined) {
             this.configExcel.ejcutarAccionPosterior();    
        }
        
    }

    public profileRequiredSelectingOrganization():boolean{
        return ['Desarrollador','Supervisor','Distribuidor'].indexOf(this.tipoPerfilUsuario)!=-1;
    }

    public cargarColumnasDefinidas(NOMBRE_TIPO_REPORTE:NOMBRES_TIPOS_REPORTES){
        this.configComponente.grilla.columnasDefinidas = COLUMNAS_VISIBLES_REPORTES[NOMBRE_TIPO_REPORTE];
    }

    public cargarColumnasDefinidasAdmin(NOMBRE_TIPO_REPORTE:NOMBRES_TIPOS_REPORTES){
        this.configComponente.grilla.columnasDefinidasAdmin = COLUMNAS_VISIBLES_REPORTES_DESARROLLADORES[NOMBRE_TIPO_REPORTE];
    }

}


export class AppInjector {

    private static injector: Injector;

    static setInjector(injector: Injector) {
            AppInjector.injector = injector;
    }

    static getInjector(): Injector {
            return AppInjector.injector;
    }

}       
