<div class="d-flex justify-content-center align-items-center w-100 h-100 error-container">
    <div class="col-xl-5 col-md-6 col-10">
        <div class="card border-0 box-shadow rounded-0">
            <div class="card-header d-flex justify-content-center align-items-center border-0 box-shadow">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            </div>
            <div class="card-body text-center">

                <h1 class="display-1" style="font-size: 60px;" translate>enConstruccion.titulo</h1>

                <img src="./../../../../../../assets/imagenes/enconstruccion2.gif"  style="width:25%;"> 
                <h6 class="card-title"translate >enConstruccion.tituloComentario</h6>
                <p class="card-text"translate>enConstruccion.detalleComentario</p>

                <span class="d-none d-sm-inline-block" *ngIf="appSettings.settings2.panel.funcionalidadesDisponibles.traductions">
                    <lib-shared-flags-menu></lib-shared-flags-menu>
                </span> 
             
            </div>
        </div>
    </div>
</div>