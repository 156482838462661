import { log, logIf, logTable, values } from '@maq-console';

import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { CanActivate,ActivatedRouteSnapshot,RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@maq-autorizacion/services/auth.service'
import { MensajesService }  from '@maq-servicios/mensajes/mensajes.service';
import { Usuario } from '@maq-models/usuarios/usuarios.model';
import { map } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { KN } from '@settings/maqueta/models/typesKN/typesKN.model';

@Injectable({
  providedIn: 'root'
})
export class CanActivateViaAuthGuard  implements CanActivate{
  public perfilUsuario = new Observable<boolean>();
  
    constructor( private authService: AuthService,
                 private router: Router,
                 private msg:MensajesService,) { }

    canActivate(route:ActivatedRouteSnapshot, state:RouterStateSnapshot) :Observable<boolean>|boolean {

        let jsonQueryParams:object = {};
        jsonQueryParams['queryParams'] =  route.queryParams;

        let partesURL = state.url.split('?');

        // Obtener la URL sin los parámetros
        let urlSinParametros = partesURL[0];

        log(...values("funcionGo",'guard can-activate-via-auth-guard'));
        log(...values("valoresDebug",'route:',route));
        log(...values("valoresDebug",'state:',state));
        // If the user is not logged in we'll send them back to the home page
        sessionStorage.setItem('urlAnterior', urlSinParametros);
        if (!this.authService.authenticated) {
            console.log('POR EL IF',!this.authService.authenticated)
            log(...values("warning","El Usuario no estás logeado"));
            log(...values("valores",'state:',state));
            log(...values("valores",'state.url:',state.url));
            // sessionStorage.setItem('urlAnterior', state.url);
            this.router.navigate(['/login/login']);
            return false;
        } 
        else {
        //sessionStorage.setItem('urlAnterior', state.url);
        this.perfilUsuario = this.msg.getPerfil().pipe(map((u:Usuario)=>{
        //return this.msg.getPerfil().pipe(map((u:Usuario)=>{
          //return this.msg.getPerfil().pipe(map((u=>{
              //let u:Usuario = JSON.parse(localStorage.getItem('usuarioLogueadoUltimaSession'));
              log(...values("funcionGo","msg.getPerfil()"));

              log(...values("valoresDebug","usuario:",u));
              log(...values("valoresDebug","this.router.url:",this.router.url));

              let url=urlSinParametros;
              let habilitado=false;
              
              if(url=='/administracion' || url=='/administracion/') {
                console.log("finalizó guardian ok EN ADMNISITRACION")
                  this.router.navigate(['/secciones']);
                  return true;
              }

              // if(url=='/login/login'){
              //   console.log('listaPermisos','/login/login');
              //   habilitado= true;
              // }

              // for (var i = u.perfilUsuario.permisosMenu.length - 1; i >= 0; i--) {
              
              for (var i in u.perfilUsuario.permisosMenu) {
                 if(u.perfilUsuario.permisosMenu[i].routerLink==url && u.perfilUsuario.permisosMenu[i].check){
                     log(...values("success","El Usuario está habilitado para la ruta"));
                     habilitado= true;
                     break;
                 }
              }
              if(habilitado){
                console.log("finalizó guardian ok");
                let navigationExtras:NavigationExtras = jsonQueryParams
                if(this.authService.getUseLocalStorage()){
                  localStorage.setItem("navigationExtrasAnterior",  JSON.stringify(jsonQueryParams));
                }else{  
                  sessionStorage.setItem("navigationExtrasAnterior", JSON.stringify(jsonQueryParams));
                }
                this.authService.setNavigationExtrasAnterior(navigationExtras);
   
                return true;

              } else {
                log(...values("error","Usuario No Autorizado para la ruta enviada al Guard"));
                // console.log("finalizó guardian por el else usuario no autorizado")
                this.router.navigate(['/usuarioNoAutorizado']);
                return false;
              }

              
               

      
              // en usuario, tenemos todas las rutas y en state todas las url por lo que podemos 
              // usar esos datos para definir los perfile rama por rama del árbol del menu
              // 'Desarrollador', 'Supervisor', 'Distribuidor', 'Organizacion', 'usuario móvil (chofer/acompanate)'
            
               // switch (u.perfilUsuario.tipo) {
               //     case "Desarrollador":
               //         return true;
               //         break;

               //      case "Supervisor":
               //         return true;
               //         break;
                   
               //      case "Distribuidor":
               //         return true;
               //         break;

               //       case "Organizacion":
               //         return true;
               //         break;   
                      
                   
               //     default:
               //         this.router.navigate(['/usuarioNoAutorizado']);
               //         return false;
               //         break;
               // }
              
              
         }));

         console.log('dato del guardian', this.perfilUsuario);
         return this.perfilUsuario;
        }
    
    }
}
