import { log, logIf, logTable, values } from '@maq-console';

import { Component, EventEmitter, OnInit, ViewChild, Output, Input, ViewEncapsulation } from '@angular/core';
import { FormControl } from "@angular/forms";
import { ChangeDetectorRef } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { FuncionesService }  from '@maq-funciones';

// Resize de Imágenes
import { NgxPicaService } from 'ngx-pica';  
import * as EXIF from 'exif-js';
export interface AspectRatioOptions {
    keepAspectRatio: boolean;
    forceDimensions?: boolean;
}
export interface NgxPicaResizeOptionsInterface {
    aspectRatio?: AspectRatioOptions;
    quality?: number;
    alpha?: boolean;
    unsharpAmount?: number;
    unsharpRadius?: number;
    unsharpThreshold?: number;
}
export enum NgxPicaErrorType {
    NO_FILES_RECEIVED = 'NO_FILES_RECEIVED',
    CANVAS_CONTEXT_IDENTIFIER_NOT_SUPPORTED = 'CANVAS_CONTEXT_IDENTIFIER_NOT_SUPPORTED',
    NOT_BE_ABLE_TO_COMPRESS_ENOUGH = 'NOT_BE_ABLE_TO_COMPRESS_ENOUGH'
}

export interface NgxPicaErrorInterface {
    err: NgxPicaErrorType;
    file?: File;
}

@Component({
  selector: 'lib-shared-input-image',
  templateUrl: './input-image.component.html',
  styleUrls: [ './input-image.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class InputImageComponent implements OnInit {
    @Input() public imageField: string; 
    @Input() public image: any; 
    @Input() public accionForm: string; 
    @Input() public thumbWidth: number; 

    @Output() cargoArchivo= new EventEmitter<any[]>();
    @Output() borroArchivo= new EventEmitter<string>();
    @Output() desabilitarGrabar= new EventEmitter<boolean>();

    @ViewChild("modalContent") modalContent: any;

    public nombreArchivo:string=null;
    public extensionArchivo:string=null;
    public extensionIcono:string=null;
    public imageAnterior:string=null;

    constructor(public fn:FuncionesService,
                private translate: TranslateService,
                private _ngxPicaService: NgxPicaService) { 

    }
    
    ngDoCheck() {      
        if(this.image!=this.imageAnterior) {
          
          log(...values("valores", "input-image - ngDocheck - nameField:",this.imageField));
          log(...values("valores", "input-image - ngDocheck - image:",this.image));        
                
          this.imageAnterior=this.image;

          this.nombreArchivo = this.fn.getFileNameSinVars(this.image);
          this.extensionArchivo = this.fn.getExtensionFile( this.nombreArchivo );
          this.extensionIcono = 'assets/iconos/extensiones-archivos64x64/'+this.extensionArchivo+'.png';
        }  
    }
    
    ngOnInit() {

        // log(...values("valores", "input-image - imageField:",this.imageField));
        // log(...values("valores", "input-image - image:",this.image));

        this.nombreArchivo = this.fn.getFileNameSinVars(this.image);
        this.extensionArchivo = this.fn.getExtensionFile( this.nombreArchivo );
        this.extensionIcono = 'assets/iconos/extensiones-archivos64x64/'+this.extensionArchivo+'.png';
        // log(...values("valores","input-file - nombreArchivo:",this.nombreArchivo));
        // log(...values("valores","input-file - extensionArchivo:",this.extensionArchivo));
        
        if(this.thumbWidth===undefined) {
            this.thumbWidth=150;
        }

    }  

    inputBorrar(): void {
      this.image            = null;
      this.nombreArchivo    = null;
      this.extensionArchivo = null;
      this.extensionIcono   = null;

      this.borroArchivo.emit(this.imageField);
    }  

    inputChange(input: any): void {

      // console.clear();

      // log(...values('funcionComponente',`inputChange(${this.imageField})`));

      if(input.files.length==0) return;  // Presiono Cancelar en la ventana de Selección de Archivos

      const file = input.files[0];
      const typeFile = input.files[0].type;
      // log(...values("valores","input:",input));
      // log(...values("valores","typeFile:",typeFile));

      this.nombreArchivo = this.fn.getFileNameFromArchivo(file);
      this.extensionArchivo = this.fn.getExtensionFile( this.nombreArchivo );
      this.extensionIcono = 'assets/iconos/extensiones-archivos64x64/'+this.extensionArchivo+'.png';
      // log(...values("valores","nombreArchivo:",this.nombreArchivo));
      // log(...values("valores","extension:",this.extensionArchivo));

      this.desabilitarGrabar.emit(true);

      var reader = new FileReader(); 
      reader.readAsDataURL(file);           

      reader.onload = async() => {

            let imageReader = reader.result;

            // log(...values("imagen","imageReader:",imageReader));

            this.image = imageReader;

            // Obtengo Medidas de la Imagen
            let imgMedidas:any;
            imgMedidas = await this.fn.getIMGMedidas( imageReader as string );
            let width     = imgMedidas.width;
            let height    = imgMedidas.height;
            let newWidth  = this.thumbWidth;
            let newHeight = Math.round( height/width * newWidth );
            // log(...values("valores","imgMedidas width:",width, "height:", height));
            // log(...values("valores","imgMedidas newWidth:",newWidth, "newHeight:", newHeight));

            this._ngxPicaService.resizeImages( [file], newWidth, newHeight)
            .subscribe((fileResized: File) => {
                    let reader: FileReader = new FileReader();
                    
                    reader.addEventListener('load', async(event: any) => {

                        let imageResized = event.target.result;
                        // log(...values("valores","event.target.result:",event.target.result));

                        // log(...values("imagen","imageResized:",imageResized));
                        this.desabilitarGrabar.emit(false);
                        this.cargoArchivo.emit([
                          this.imageField,
                          this.nombreArchivo,
                          this.extensionArchivo,                          
                          this.image,
                          imageResized]);

                    }, false);
                    
                    reader.readAsDataURL(fileResized);

                    // reader.readAsBinaryString(Blob|File)        -> result blob como cadena binaria
                    // reader.readAsText(Blob|File, opt_encoding)  -> result blob como cadena de texto
                    // reader.readAsDataURL(Blob|File)             -> result URL de datos
                    // reader.readAsArrayBuffer(Blob|File)         -> result objeto ArrayBuffer 
                    
            }, (error: NgxPicaErrorInterface) => {
                log(...values("error","Error al generarar Thumb de "+this.imageField, error));
                this.desabilitarGrabar.emit(false);
                throw error.err;
            });

      }    

    }

}  
  
