import { Component, OnInit, ViewEncapsulation, HostListener, ViewChild, ElementRef } from '@angular/core';
import { Router }                   from '@angular/router';
import { AppSettingsService }       from '@settings/app.settings';

import { AuthService }              from '@maq-autorizacion/services/auth.service';
import { boolean } from 'mathjs';
import { StorageService } from '@maq-servicios/storageService/storage.service';

import { TranslateService }  from '@ngx-translate/core';
import { AlertService }         from '@maq-servicios/alert/alert-service';

declare let $: any;
declare let jQuery: any;

@Component({
  selector: 'app-principal',
  templateUrl: './principal.component.html',
  styleUrls: ['./principal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PrincipalComponent implements OnInit {
    public showMenu:boolean = false;
    public showSetting:boolean = false;
    public menus = ['vertical', 'horizontal'];
    public menuOption:string;
    public menuTypes = ['default', 'compact', 'mini'];
    public menuTypeOption:string;
    
    public settings: any;

    constructor(public  appSettings:AppSettingsService,
                public storageService:StorageService,
                public alertService     : AlertService,
                public translate        : TranslateService

                // public  router:Router,
                // private authService: AuthService
                ){        

      
   
        if(sessionStorage["skin"]) {
            this.appSettings.settings2.panel.skin = sessionStorage["skin"];
        }     
    }

    ngOnInit() {
        
        if(window.innerWidth <= 768){
            this.appSettings.settings2.panel.showMenu = false;
            this.appSettings.settings2.panel.sideChatIsHoverable = false;
        }
        this.chooseMenuSession();
        this.chooseMenuTypeSession();
        this.showMenuStateChangeSession();
    }

    private chooseMenuSession(){
        if (sessionStorage.getItem('menuSeleccionado') != undefined && sessionStorage.getItem('menuSeleccionado') != this.appSettings.settings2.panel.menu){
            this.menuOption= sessionStorage.getItem('menuSeleccionado');
            this.chooseMenu(this.menuOption);
        }else{
            this.menuOption= this.appSettings.settings2.panel.menu;
        } 
    }

    public chooseMenu(menu){
        this.appSettings.settings2.panel.menu = menu;
        this.menuOption= menu;
        sessionStorage.setItem('menuSeleccionado',menu); 
        if (window.innerWidth <= 768 && menu == 'horizontal' && this.appSettings.settings2.panel.menuType != 'default'){
            // console.log('entro al chooseMenu para poner el menu default (if)');
            this.chooseMenuType('default');
        }
        else{
            // console.log('entro al chooseMenu para poner el menu anterior (else)', sessionStorage.getItem('tipoDeMenuSeleccionado'));
            this.chooseMenuType(sessionStorage.getItem('tipoDeMenuSeleccionado'));
        }
        // let usuarioLogeado = this.authService.getPerfilUsuario();
        // this.authService.seleccionPaginaInicio(usuarioLogeado);
        //this.router.navigate(['/']);      
    }

    private chooseMenuTypeSession(){
        if (sessionStorage.getItem('tipoDeMenuSeleccionado') && sessionStorage.getItem('tipoDeMenuSeleccionado') != this.appSettings.settings2.panel.menuType){
            this.menuTypeOption= sessionStorage.getItem('tipoDeMenuSeleccionado');
        }else{
            this.menuTypeOption= this.appSettings.settings2.panel.menuType ? this.appSettings.settings2.panel.menuType : 'default';
        }
        this.chooseMenuType(this.menuTypeOption);    
    }

    public chooseMenuType(menuType){
            this.appSettings.settings2.panel.menuType = menuType;
            this.menuTypeOption = menuType;
            if (!(window.innerWidth <= 768 && this.appSettings.settings2.panel.menu == 'horizontal' && menuType == 'default')){
                // console.log('chooseMenuType entro al IF this.appSettings.settings2.panel.menu=', this.appSettings.settings2.panel.menu);
                // console.log('chooseMenuType entro al IF window.innerWidth=', window.innerWidth);
                // console.log('chooseMenuType entro al IF menuType=', menuType);
                sessionStorage.setItem('tipoDeMenuSeleccionado',menuType)
            };
            jQuery('.menu-item-link').tooltip({
                sanitize: false,
                sanitizeFn: function (content) {
                    return null;
                }
            });
            if(menuType=='mini'){
                jQuery('.menu-item-link').tooltip('enable');
            }else{
                jQuery('.menu-item-link').tooltip('disable');
            }
    }
        


    /** 
    public changeTheme(theme){
        sessionStorage.setItem('tipoTemaMenuSeleccionado',theme) 
        // console.log('changeTheme',theme);
        this.appSettings.settings2.panel.skin = theme;
        sessionStorage["skin"] = theme;        
    }**/
 
    ngAfterViewInit(){
        // console.log('ngAfterViewInit');
        document.getElementById('preloader').classList.add('hide');
    }


    @HostListener('window:resize')
    public onWindowResize():void {

        let showMenu= !this._showMenu();

        // console.log('onWindowResize !this._showMenu()  =', !this._showMenu());
        // console.log('onWindowResize this.showMenu()  =', this.showMenu);

        if (this.showMenu !== showMenu) {   
            // console.log('onWindowResize entro al IF');
            this.showMenuStateChange(showMenu);
        }
        /*
        if (this._showMenu() && this.appSettings.settings2.panel.menuType != 'default' && this.appSettings.settings2.panel.menu=='horizontal'){
            // console.log('chooseMenuType captura el evento de la pantalla')
            //si la pantalla es menor que 768, pongo el menu default siempre
            this.chooseMenuType('default');
        }*/
        
        this.showMenu = showMenu;
        sessionStorage.setItem('verMenu',String(showMenu)); 
    }

    public showMenuStateChange(showMenu:boolean):void {
        this.appSettings.settings2.panel.showMenu = showMenu;
        sessionStorage.setItem('verMenu',String(showMenu)); 
    }

    private showMenuStateChangeSession(){
        if (sessionStorage.getItem('verMenu') != undefined && JSON.parse(sessionStorage.getItem('verMenu')) != this.appSettings.settings2.panel.showMenu){
            // console.log('show menu verMenu entro al if');
            this.showMenu= JSON.parse(sessionStorage.getItem('verMenu'));
            this.showMenuStateChange(this.showMenu);
        }else{
            // console.log('show menu verMenu entro al else', this.appSettings.settings2.panel.showMenu);
            this.showMenu= this.appSettings.settings2.panel.showMenu;
        }
    }

    private _showMenu():boolean {
        return window.innerWidth <= 768;
    }

    public showMenuType():boolean{
        // console.log('showMenuType this.menuOption', this.menuOption);
        // console.log('showMenuType window.innerWidth', window.innerWidth);
        // console.log('showMenuType window.innerHeight', window.innerHeight);
        return true;
        //return !((window.innerWidth <= 768 || window.innerHeight <=624) && this.menuOption=='horizontal');
    }

    public changeFooter(){
        this.appSettings.settings2.panel.footerIsFixed = ! this.appSettings.settings2.panel.footerIsFixed;
        sessionStorage.setItem('verFooter',String(this.appSettings.settings2.panel.footerIsFixed)); 
    }

    public changeHeader(){
        this.appSettings.settings2.panel.navbarIsFixed = ! this.appSettings.settings2.panel.navbarIsFixed;
        sessionStorage.setItem('verHeader',String(this.appSettings.settings2.panel.navbarIsFixed)); 
    }
    
    
    public changeMenuLateralFijo(){
        this.appSettings.settings2.panel.sidebarIsFixed = ! this.appSettings.settings2.panel.sidebarIsFixed;
        sessionStorage.setItem('verMenuLetaralFijo',String(this.appSettings.settings2.panel.sidebarIsFixed)); 
    }

    public clearCache(){
        this.alertService.confirm({ 
            title:   this.translate.instant('settings.clearCache'), 
            message:  this.translate.instant('confirma limpliar los datos?'),
        }).then(data=>{
            this.storageService.clear();
            sessionStorage.clear();
        }).catch(error=>{  
            console.log("Error al intentar borrar la cache ",error);
        });
    }

}
