import { log, logIf, logTable, values } from '@maq-console';

import { Injectable } from '@angular/core';
import { Observable, throwError, of, Subject } from 'rxjs';

import { FuncionesService }  from '@maq-funciones';
import { ApisService }       from '@maq-apis';

import { AngularFirestore,  Query } from '@angular/fire/firestore';

import { getListadoCacheModel,getListadoCacheModel2, } from '@maq-models/mensajes/mensajes.model';
import { ArgumentosGetListadoCacheBase } from '@maq-models/mensajes/mensajesBase.model';

import { StorageService } from '@maq-servicios/storageService/storage.service';
import { BdBaseService } from '@maq-servicios/bd/bd.serviceBase';
import { Usuario } from '@maq-models/usuarios/usuarios.model';
import {  getBDModel2, getBDSQL, DatosUpdateBase} from '@maq-models/bd/bd.model';
import { ArgumentosLlamarBaseDeDatos} from '@maq-models/bd/bdBase.model';
import { WhereInterface , OPERADOR_ORDEN_BY,OPERADOR_WHERE, TIPO_BASE_DATOS, WhereObjet,OrderObjet }  from '@maq-models/bd/bdDefinicionesGenerales.model';

import { KN, KANE, KNAI }                               from '@maq-models/typesKN/typesKN.model';


@Injectable({
  providedIn: 'root'
})

export class MensajesServiceBase {

  public usuario                      : Usuario= null;
  public usuarioObs                   : Subject<Usuario> = new Subject<Usuario>();
  
  public usuarioKANE                  : KANE;
  public organizacionKNAI             : KNAI;

  public cacheColecciones             : any[] = [];
  public cacheColeccionesObs          : Subject<any>[]=[];

  constructor(public fn               : FuncionesService,
              public apis             : ApisService,
              public storageService   : StorageService,
              public bdBaseService    : BdBaseService,
              private af              : AngularFirestore) { 
  }
  
  clearListadoCache() {
    this.cacheColecciones = [];
    this.cacheColeccionesObs =[];  
  }
  
 

  getListadoCacheBase(argumentos:ArgumentosGetListadoCacheBase):Observable<any>{

    let nombreListado   = argumentos.getNombreListado();
    
       
    if(this.existeListadoEnElServcio(nombreListado)==true && !argumentos.ignoraValoresMemoria){
 
        return of (true);

    } else {
        // console.log("getListadoCacheBase argumentos.ignoraValoresMemoria",argumentos.ignoraValoresMemoria);
        if(!this.existeListadoObservable(nombreListado)) {
          // console.log("getListadoCacheBase argumentos no existe observable",nombreListado);
          this.cacheColeccionesObs[nombreListado]=new Subject<any>();
        }

        let nombreLocalStorage = argumentos.getNombreLocalStorage();        
        let existeLocalStorage = localStorage.getItem(nombreLocalStorage);   
        
        // console.log("getListadoCacheBase nombreLocalStorage",nombreLocalStorage);
        // console.log("getListadoCacheBase existeLocalStorage",existeLocalStorage);
        // console.log("getListadoCacheBase this.usuario.hasOrganizacion()",this.usuario.hasOrganizacion());

        if(existeLocalStorage && this.usuario.hasOrganizacion()==true) {                         
            
            let listado = JSON.parse(localStorage.getItem(nombreLocalStorage));
            this.cacheColecciones[nombreListado] = listado;    
    
   
            return of (true);
                  
        } else {          
            // console.log("getListadoCacheBase NO existeLocalStorage",existeLocalStorage);
            this.leerListadoCacheBase(argumentos);
            return this.cacheColeccionesObs[nombreListado].asObservable();             
        }  
    }

  }

  
  leerListadoCacheBase(argumentosCache:ArgumentosGetListadoCacheBase) {
  
    log(...values("funcionGoPromesa","leerListadoCacheBase "+argumentosCache.nombreListado));

    // console.log("leerListadoCacheBase argumentosCache",argumentosCache);

    let nombreListado=argumentosCache.getNombreListado();
  

    let argumentoBase:ArgumentosLlamarBaseDeDatos=ArgumentosLlamarBaseDeDatos.fromArgumentosGetListadoCacheBase(argumentosCache);
    // console.log("leerListadoCacheBase argumentoBase",argumentosCache);  
    // console.log("leerListadoCacheBase nombreListado",nombreListado);  

    this.bdBaseService.getPromesaBase(argumentoBase).then(listado=>{
      // console.log('leerListadoCacheBase listado',listado);

      if(argumentosCache.limpiaSettingUsuarioOrganizacion){
        listado=this.limpiarSettingUsuarioOrganizacion(listado);

       
      }
      
      this.almacenarListadoEnElServicio(nombreListado,listado);
      // Guardo en el StorageService
      if(argumentosCache.hasGrabaLocalStorage()==true) {
          this.grabaListado_Fecha_EnLocalStorage(argumentosCache,listado) 
      }

      // Disponibilizo Listado en Memoria
      //this.cacheColeccionesObs[nombreListado].next( this.cacheColecciones[nombreListado] );  
      this.cacheColeccionesObs[nombreListado].next( true );  
         
      
     
    }).catch(error=>{
      console.log('leerListadoCacheBase error',error);
 
    })
  

  }     
    
    
  almacenarListadoEnElServicio(nombreListado,listado){
    this.cacheColecciones[nombreListado]=listado;
  }
    
        

  limpiarSettingUsuarioOrganizacion(listado:any[]):any[]{
    // console.log('leerListadoCacheBase limpiarSettingUsuarioOrganizacion',listado);
    let rta=[];
    for(let i=0; i<listado.length; i++) {
        let documento=listado[i];
        delete documento.settings;
        delete documento.usuarioKANE;
        delete documento.organizacionKNAI;        
      
        rta.push( documento );
    }  
    return rta;
  }

  setUsuarioObs(usuario:Usuario ) {
      log(...values("funcionGo",'setPerfilUsuarioObs:'));
      log(...values("valores",'usuario:',usuario));
      this.usuario=usuario;
      this.usuarioKANE=usuario?.getKANE();
      this.organizacionKNAI=usuario?.getOrganizacionKNAI();
      this.usuarioObs.next(usuario);
        // return of (this.usuarioObs);
  }




  getUsuario():Observable<Usuario>{
    log(...values("funcionGo","msg.getUsuario"));
    if(this.usuario){        // es nulo cuando no tengo el usuario porque no lo grabe
                                              // lo grabo para no tener que ir a consultar la base todas las veces.
      return of (this.usuario);
    } else {
      return this.usuarioObs.asObservable(); // se activa cuando resibe los datos al loguer el usuario.
    }

  }


  grabaListado_Fecha_EnLocalStorage(argumentosCache:ArgumentosGetListadoCacheBase,listado:any[]){
    // console.log('leerListadoCacheBase grabaListado_Fecha_EnLocalStorage',listado);
  
    let nombreLocalStorage = argumentosCache.getNombreLocalStorage();                     
    this.storageService.setItem(nombreLocalStorage, JSON.stringify(listado));                 

    // Grabo Fecha Hora de Actualización
    let nombreLocalStorageFecha =argumentosCache.getNombreLocalStorageFechaHora();
    // console.log('leerListadoCacheBase nombreLocalStorage',nombreLocalStorage);
    // console.log('leerListadoCacheBase nombreLocalStorageFecha',nombreLocalStorageFecha);
    this.storageService.setItem(nombreLocalStorageFecha, new Date().toString());
  }

  existeListadoEnElServcio(nombreListado:string):boolean{
      return this.cacheColecciones[nombreListado]!= null || this.cacheColecciones[nombreListado]!= undefined
  }

  existeListadoObservable(nombreListado:string):boolean{
      return this.cacheColeccionesObs[nombreListado]!= null ||  this.cacheColeccionesObs[nombreListado]!= undefined
  }

    LogApiMensajeLeerListadoCacheBase(nombreColeccion:string,dataLength:number){

        this.apis.LogApiFuncion({
            eventoQueDisparo : 'mensajeService.leerListadoCacheBase',
            apiFuncionKey    : 'FirestoreDocumentRead', 
            organizacionKNAI : this.organizacionKNAI,
            usuarioKANE      : this.usuarioKANE,
            nombreColeccion  : nombreColeccion,
            cloudFunction    : null,
            cantidad         : dataLength, 
        });
    }

    //  log(...values("warning","Disparó FirestoreTransferencia desde mensaje: "+nombreColeccion));
    LogApiFirestoreLeerListadoCacheBase(nombreColeccion:string,coleccionTotalSizeOf:number){
    
        this.apis.LogApiFuncion({
          eventoQueDisparo : 'leerListadoCacheBase',
          apiFuncionKey    : 'FirestoreTransferencia', 
          organizacionKNAI : this.organizacionKNAI,
          usuarioKANE      : this.usuarioKANE,
          nombreColeccion  : nombreColeccion,
          cloudFunction    : null,
          cantidad         : coleccionTotalSizeOf, 
    });
    }
   
}
