<!-- [hidden]="!finalizoConsultaReportesFavoritos"  [compareWith]="compareFn"-->
<div class="row mb-1">
  <div [ngClass]="selectReportFavorito != null ? 'col-11' : 'col-12'" >
    <select [(ngModel)]="selectReportFavorito"
      (change)="onChangeReporte(selectReportFavorito)"
      class="form-control selectpicker"
      [compareWith]="compareFn">                                       
      <ng-container *ngFor="let opcion of listadoPrincipal">    
        <option [ngValue]="opcion">{{opcion.descripcionConfiguracionReporte}}</option>
      </ng-container>            
    </select>   
  </div>
  
  <ng-container *ngIf="selectReportFavorito !=null && selectReportFavorito !=undefined" class="col-1">
      <a class="btn btn-link btn-lg pr-0 pl-0" title="{{'navegabilidad.editar' | translate}} {{'reporte.configuracionReporte' | translate}}"
        [id]="selectReportFavorito.getIdConfiguracionReporte().toString()+'/reportes/configuracion'" (click)="navigateConfiguracionReporte($event, selectReportFavorito.getIdConfiguracionReporte().toString()+'/reportes/configuracion', '/reportes/configuracion')" >
        <i class="fa fa-file-text-o mr-3 text-primary"></i>
      </a>

      <button title="{{'reportes.actualizarReporteFavorito' | translate}}"
        class="btn btn-link btn-lg"  style="padding-left:0px; padding-right:0px;"
        (click)="modificarFavorito(modalSelectReporteFavorito)">
        <i class="mdi mdi-content-save mr-3 text-dark"></i>
      </button>
  </ng-container>

  <div class="col-12" *ngIf="selectReportFavorito !=null && selectReportFavorito !=undefined">
    <angular2-multiselect
      [(ngModel)]="columnasSeleccionadas"
      [data]="selectReportFavorito.getColumnasVisbles(translate)"
      [settings]="dropDownSettings">
    </angular2-multiselect>
  </div>
</div>

<ng-template #modalSelectReporteFavorito let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{'reportes.nuevoReporteFavorito' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form [formGroup]="form">

    <div class="modal-body row">

      <div class="col-4 form-group" *ngIf="form.get('organizacionNombre').value">
        <label class="text-gray">{{'moduloOrganizaciones.organizacion' | translate}}:<span *ngIf="form.controls.organizacionNombre.errors?.required" class="required">*</span></label>
        <input  formControlName="organizacionNombre" 
              value="{{form.get('organizacionNombre').value}}" 
              class="form-control validation-field" 
              style="height:35px; font-weight:bold; font-size:11px; color:black;"
              type="text">
      </div>

      <div class="col-4 form-group" *ngIf="form.get('sucursalNombre').value">
        <label class="text-gray">{{'moduloOrganizaciones.sucursal' | translate}}:</label>
        <input  formControlName="sucursalNombre"  
              value="{{form.get('sucursalNombre').value}}" 
              class="form-control validation-field" 
              style="height:35px; font-weight:bold; font-size:11px; color:black;"
              type="text">
      </div>

      <div class="col-4 form-group" *ngIf="form.get('areaNegocioNombre').value">
        <label class="text-gray">{{'moduloOrganizaciones.areaNegocio' | translate}}:</label>
        <input formControlName="areaNegocioNombre" 
              value="{{form.get('areaNegocioNombre').value}}" 
              class="form-control validation-field" 
              style="height:35px; font-weight:bold; font-size:11px; color:black;"
              type="text">
      </div>

      <div class="form-group col-8">
        <label class="text-gray">{{'reporte.reporte' | translate}}:</label>
        <select class="form-control selectpicker validation-field" 
                id="idConfiguracionReporte" formControlName="idConfiguracionReporte">
          <option [ngValue]="null">{{'reporte.reporte' | translate}}</option>                 
          <ng-container *ngFor="let reporte of listadoConfiguracionReporte">    
            <option [ngValue]="reporte.idConfiguracionReporte">{{reporte.descripcionConfiguracionReporte}}</option>
          </ng-container>            
        </select>
        <small class="text-danger" *ngIf="form.controls.idConfiguracionReporte.touched && form.controls.idConfiguracionReporte.errors?.required"><span>{{'reporte.reporte' | translate}}</span>&nbsp;<span translate>claseValidaciones.esRequerido</span></small>
      </div>

      <div class="form-group col-3 mb-0 ml-2 custom-control custom-checkbox d-flex align-items-center">
        <input type="checkbox" class="custom-control-input checkbox-dark-gray" 
                id="esFavorito" formControlName="esFavorito"
                [value]="form.get('esFavorito').value" readonly>
        <label class="custom-control-label" for="esFavorito" title="{{'reporte.favorito' | translate}}" translate>reporte.favorito</label>
      </div>

    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modal.close('cancelar')">{{'navegabilidad.cancelar' | translate}}</button>
        <button  
                type="button" class="btn btn-primary" (click)="modal.close('submit')">
                {{'navegabilidad.grabar' | translate}}
        </button>
    </div>   

  </form>
</ng-template>

