import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

import { FuncionesService }  from '@maq-funciones';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor(public fn:FuncionesService) { }
  
  public exportAsExcelFile(json: any[], excelFileName: string): void {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
      const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public exportJsonAsExcelFile(json: any, excelFileName: string, useFileName?:boolean): void {
    let sheetNames=[];
    let sheets={};
    Object.keys(json).forEach(function(key) {
            sheetNames.push(key);
            sheets[key]=XLSX.utils.json_to_sheet(json[key]);
            return;
         
        });  
    const workbook: XLSX.WorkBook = { Sheets:  sheets , SheetNames: sheetNames};
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName, useFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string, useFileName?:boolean): void {
     const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
     if(useFileName){
      FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
     }else{
      FileSaver.saveAs(data, fileName + '_export_' + new  Date().getTime() + EXCEL_EXTENSION);
     }
  }

  public convertExcelToJson(evt: any, formato:any)  {
     	console.log(evt);
      let reader = new FileReader();
      let workbookkk;
      let XL_row_object={};
      let json_object={};
     
      return new Promise((resolve, reject) => {
          if (evt.target.files.length !== 1) {
          	reject({error:"no se puede leer mas de un archivo"});
          };			
          
          let self=this;
         
          reader.onload = function(){
              //  alert(reader.result);
              let data = reader.result;
              workbookkk= XLSX.read(data,{type: 'binary'});
              console.log("convertExcelToJson workbookkk",workbookkk);
              workbookkk.SheetNames.forEach(function(sheetName) {
              // Here is your object
                
              let encabezado          = true; 
              let fieldsEncabezado    = []; 
              let header              = []
        
              if(formato) {
                    encabezado          = formato.encabezado; 
                    fieldsEncabezado    = formato.fieldsEncabezado; 
                    for(let i=0; i<fieldsEncabezado.length;i++){
                      header.push('field'+self.fn.str0(i,3));
                    }
              }
              
              console.log("encabezado",encabezado);
              console.log("header",header);
              console.log("sheetName ",sheetName);
              console.log("workbookkk.Sheets[sheetName] ",workbookkk.Sheets[sheetName]);

              let opts: XLSX.Sheet2JSONOpts = {
                header: header,
                range: undefined,
                blankrows: undefined,
                defval: null,
                raw: encabezado
              }

              if(encabezado) {
                opts.header = undefined;
                XL_row_object[sheetName] = XLSX.utils.sheet_to_json(workbookkk.Sheets[sheetName], opts);
              } else {
                XL_row_object[sheetName] = XLSX.utils.sheet_to_json(workbookkk.Sheets[sheetName], opts);  
              }  
              
              // json_object = JSON.stringify(XL_row_object);
              // console.log(json_object);
              console.log("convertExcelToJson XL_row_object",XL_row_object);
                  
              });

              resolve(XL_row_object);
              
          };

          reader.readAsBinaryString(evt.target.files[0]);	   
          
      });
  }

  // /* <input type="file" (change)="onFileChange($event)" multiple="false" /> */
  // /* ... (within the component class definition) ... */
  //   onFileChange(evt: any) {
  //     /* wire up file reader */
  //     const target: DataTransfer = <DataTransfer>(evt.target);
  //     if (target.files.length !== 1) throw new Error('Cannot use multiple files');
  //     const reader: FileReader = new FileReader();
  //     reader.onload = (e: any) => {
  //       /* read workbook */
  //       const bstr: string = e.target.result;
  //       const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

  //       /* grab first sheet */
  //       const wsname: string = wb.SheetNames[0];
  //       const ws: XLSX.WorkSheet = wb.Sheets[wsname];

  //       /* save data */
  //       this.data = <AOA>(XLSX.utils.sheet_to_json(ws, {header: 1}));
  //     };
  //     reader.readAsBinaryString(target.files[0]);
  //   }

  public convertExcelSheetToJson(evt: any,sheet:string)  {
       console.log(evt);
      let reader = new FileReader();
      let workbookkk;
      let XL_row_object={};
      let json_object={};
   
      return new Promise((resolve, reject) => {
        
          if (evt.target.files.length !== 1) {
            reject({error:"no se puede leer mas de un archivo"});
          };      
     
          reader.onload = function(){
              //  alert(reader.result);
              let data = reader.result;
              workbookkk= XLSX.read(data,{type: 'binary'});
              console.log(workbookkk);
              workbookkk.SheetNames.forEach(function(sheetName) {
                   if(sheet!=sheetName){
                     console.log(sheetName, "distinto de: " +sheet);
                     return;
                   }
                  // Here is your object
                   XL_row_object[sheetName] = XLSX.utils.sheet_to_json(workbookkk.Sheets[sheetName]);
                   // json_object = JSON.stringify(XL_row_object[sheetName] );
                  // console.log(json_object);
                   console.log(XL_row_object);
                  
              });

              
               resolve(XL_row_object);
            };

            reader.readAsBinaryString(evt.target.files[0]);     
      });
  }

  getFile(filePath) {
    return filePath.substr(filePath.lastIndexOf('\\') + 1).split('.')[0];
  }
  
  procesarArchivo(evt:any, fileInput1:any, formato:any):Promise<any> {
      console.log("onProcesarArchivo evt, fileInput1, formato",evt, fileInput1, formato);

      /*
      formato = {
        delimitador           : '|' / ',' / ';' / '.' / 'tab' / 'otro' / 'fijo',
        otroDelimitador       : caracter (ej '@),
        encabezado            : true/false,
        fieldsEncabezado      : [],
        mismaCantidadColumnas : false,
        encoding              : 'ISO-8859-2'/'UTF8'
      }
      */
     
      if(formato!=null && formato.encoding===undefined) {
          formato.encoding = 'ISO-8859-2';
      }  
    
      // let outputfile = this.getFile(fileInput1.value);
      // let extension  = fileInput1.value.split('.')[1];

      let extension : string = fileInput1.value.substring(fileInput1.value.length - 5);
      let indicePunto = extension.indexOf('.');
      if(indicePunto !=-1){
        extension = extension.substring(indicePunto+1,extension.length);
      }

      // console.log("outputfile",outputfile);
      // console.log("procesarArchivo extension",extension);

      if(extension=='xls' || extension=='xlsx') {
          return this.procesarExcel(evt, formato); 
          
      } else if(extension=='csv' || extension=='txt' || extension=='prn') {
          return this.procesarCsv(evt, formato); 
      } else if(extension=='json' ) {
        return this.procesarJson(evt, formato);     
      } else {        
          //alert("Extensión de Archivo no reconocida");
          console.log("Extensión de Archivo no reconocida");
          //return new Promise(null)
          return this.procesarExtensionINvalida();
      }
  }    

  procesarExtensionINvalida():Promise<any> {    

    return new Promise((resolve:any,reject:any) => {
    
          reject("Error formato archivo inválido");
          
      });
          
  }  
  

  procesarJson(evt:any, formato:any):Promise<any> {    

    return new Promise((resolve:any,reject:any) => {
    
          let delimitador             = ';'; 
          let otroDelimitador         = ''; 
          let encabezado              = true; 
          let fieldsEncabezado        = []; 
          let mismaCantidadColumnas   = true;
          let encoding                = 'ISO-8859-2';

          if(formato) {
              delimitador           = formato.delimitador; 
              otroDelimitador       = formato.otroDelimitador; 
              encabezado            = formato.encabezado; 
              fieldsEncabezado      = formato.fieldsEncabezado; 
              mismaCantidadColumnas = formato.mismaCantidadColumnas; 
              encoding              = formato.encoding; 
          }
  
          let fileReaded = evt.target.files[0];  

          let reader: FileReader = new FileReader();  
          // reader.readAsText(fileReaded);  
          // reader.readAsText(fileReaded, "UTF-8");
          reader.readAsBinaryString(fileReaded);  // era el anterior que estaba
          
          // if(encoding == 'ISO-8859-2') {
          //   reader.readAsText(fileReaded, 'ISO-8859-1');
          // } else {
          //     reader.readAsText(fileReaded, 'UTF-8')
          // }  
          
          reader.onerror = (e) => {  
              reject("Error al procesar json",e);
          }  
          
          reader.onload = (e) => {  
            let json: any = reader.result;  
            console.log("procesar json listado", json);
            let jsonSinComillas=JSON.parse(json);
            console.log("procesar json jsonSinComillas", jsonSinComillas);
            resolve(jsonSinComillas);
            
          }  
          
      });
          
  }  


  procesarCsv(evt:any, formato:any):Promise<any> {    

    return new Promise((resolve:any,reject:any) => {

          let delimitador             = ';'; 
          let otroDelimitador         = ''; 
          let encabezado              = true; 
          let fieldsEncabezado        = []; 
          let mismaCantidadColumnas   = true;
          let encoding                = 'ISO-8859-2';
  
          if(formato) {
              delimitador           = formato.delimitador; 
              otroDelimitador       = formato.otroDelimitador; 
              encabezado            = formato.encabezado; 
              fieldsEncabezado      = formato.fieldsEncabezado; 
              mismaCantidadColumnas = formato.mismaCantidadColumnas; 
              encoding              = formato.encoding; 
          }
          
          console.log("procesarCsv formato",formato);
          
          if(delimitador=='tab')  delimitador='\t';
          if(delimitador=='otro') delimitador=otroDelimitador;
          
          console.log("procesarCsv delimitador", delimitador);
          console.log("procesarCsv tiene encabezado", encabezado);
      
          let fileReaded = evt.target.files[0];  
          console.log("fileReaded", fileReaded);           

          let reader: FileReader = new FileReader();  
          // reader.readAsText(fileReaded);  
          //reader.readAsBinaryString(fileReaded);            
          if(encoding == 'ISO-8859-2') {
              reader.readAsText(fileReaded, 'ISO-8859-1');
          } else {
              reader.readAsText(fileReaded, 'UTF-8')
          }  
          
          reader.onerror = (e) => {  
              reject("Error al procesar csv",e);
          }  
          
          reader.onload = (e) => {  
            let csv: any = reader.result;     
            console.log("csv", csv);           
            let allTextLines = csv.split(/\r|\n|\r/);  
            let headers = allTextLines[0].split( delimitador );
            console.log("headers",headers);  
            let lines = [];  
            
            console.log("allTextLines",allTextLines);
            
            let listado:any[]=[];

            let filaInicial = encabezado ? 1 : 0;
            
            if(delimitador=='fijo') {  // prn Roadnet con posiciones fijas
                for (let i = filaInicial; i < allTextLines.length; i++) {  
                    if(allTextLines[i]!='') {
                        listado.push({
                            campoFijo : allTextLines[i]  
                        });  
                    }
                }                
            } else {
            
                for (let i = filaInicial; i < allTextLines.length; i++) {  
                  // split content based on comma  
                  let data = allTextLines[i].split( delimitador );  
                  console.log("data",i,data);  
                  console.log("data.length === headers.length",data.length,headers.length);  
                  if (data.length === headers.length) {  
                    let obj = {};
                    
                    for (let j = 0; j < headers.length; j++) {  
                        if(data[j]!='') {
                            if(encabezado) {
                                obj[ headers[j] ] = data[j];                    
                            } else {
                                obj[ 'field'+this.fn.str0(j,3) ] = data[j];                    
                            }                        
                        }
                    }  
                    
                    if(Object.keys(obj).length > 0) listado.push(obj);
                  }  
                }  
            }    
            
            // all rows in the csv file  
            console.log("procesarCsv listado", listado);
            
            resolve(listado);
            
          }  
          
      });
          
  }  

  procesarExcel(evt:any, formato:any):Promise<any> {
    
    return new Promise((resolve:any,reject:any) => {

        this.convertExcelToJson(evt, formato)
        .then(
            (data:any)=>{ 
              console.log("onProcesarArchivo Excel",data);
              
              Object.keys(data).forEach(function(key) {
                
                resolve(data[key]);
                return;
            
              });
        
        }).catch((error:any)=> {
              console.log("onProcesarArchivo error procesarExcel",error)
              reject('No pudo procesar Excel',error);
        });
        
    });
    
    
  }
  
  
  
}




