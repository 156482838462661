import { Pipe, PipeTransform } from '@angular/core';
import { FuncionesService }  from '@maq-funciones';

@Pipe({ 
  name: 'RangoFechasSearchPipe', 
  pure: false 
})
export class RangoFechasSearchPipe implements PipeTransform {
    constructor(public fn:FuncionesService) { 
    }   
    transform(value, fieldName, vecRango): Array<any> {

        let showConsole=false;
        if(showConsole) console.log("RangoFechasSearchPipe", value, fieldName, vecRango);

        let valorGrabado:any;
        let rta:boolean;

        if (value) {
            return value.filter(valor => {
                if(fieldName.indexOf('.')==-1) {
                  valorGrabado=valor[fieldName];  
                } else {
                  let partes = fieldName.split('.');
                  // console.log("valor",valor);
                  if((valor[ partes[0] ]!==undefined && valor[ partes[0] ]) && 
                       (valor[ partes[0] ][ partes[1] ]!==undefined && valor[ partes[0] ][ partes[1] ]) &&
                       (valor[ partes[0] ][ partes[1] ][ partes[2] ]!==undefined && valor[ partes[0] ][ partes[1] ][ partes[2] ]) ) {
                         
                       valorGrabado=valor[ partes[0] ][ partes[1] ][ partes[2] ];
                      
                  } else if((valor[ partes[0] ]!==undefined && valor[ partes[0] ]) && 
                      (valor[ partes[0] ][ partes[1] ]!==undefined && valor[ partes[0] ][ partes[1] ])  ) {
                        
                       valorGrabado=valor[ partes[0] ][ partes[1] ];
                  } else {
                      valorGrabado=null;
                  }
                }
                if(showConsole) console.log("RangoFechasSearchPipe valorGrabado",valorGrabado);

                if(vecRango[fieldName]===undefined || vecRango[fieldName]===null) {
                    return true;
                } else {    
                    let rango = vecRango[fieldName];  
                    if(showConsole) console.log("RangoFechasSearchPipe rango", rango);
                    let desde=rango.desde;
                    let hasta=rango.hasta;


                    // 29/12/2023,17:20
                    let partesFechaDesde:string[] = desde.split(",");
                    if(partesFechaDesde.length == 2) {
                        desde = partesFechaDesde[0];
                        let horaDesde:string[] = partesFechaDesde[1].split(":");
                        desde = this.fn.stringToDate(desde,"dd/MM/yyyy","/", horaDesde[0],horaDesde[1],0);
                    }else{
                        desde = this.fn.stringToDate(desde,"dd/MM/yyyy","/", 0,0,0);
                    }
                    
                    let partesFechaHasta:string[] = hasta.split(",");
                    if(partesFechaHasta.length == 2) {
                        hasta = partesFechaHasta[0];
                        let horaHasta:string[] = partesFechaHasta[1].split(":");
                        hasta = this.fn.stringToDate(hasta,"dd/MM/yyyy","/", horaHasta[0],horaHasta[1],0);
                    }else{
                        hasta = this.fn.stringToDate(hasta,"dd/MM/yyyy","/", 0,0,0);
                    }

                    // desde = this.fn.stringToDate(desde,"dd/MM/yyyy","/", 0,0,0);
                    // hasta = this.fn.stringToDate(hasta,"dd/MM/yyyy","/", 23,59,50);

                    if(showConsole) console.log("RangoFechasSearchPipe typeof desde.toMillis", typeof desde.toMillis);
                    if(showConsole) console.log("RangoFechasSearchPipe typeof hasta.toMillis", typeof hasta.toMillis);

                    if(showConsole) console.log("RangoFechasSearchPipe desde", desde);
                    if(showConsole) console.log("RangoFechasSearchPipe hasta", hasta);

                    let rta=true;

                    if(showConsole) console.log("RangoFechasSearchPipe compareFechas ->desde",valorGrabado, this.fn.compareFechas(valorGrabado,desde));
                    if(showConsole) console.log("RangoFechasSearchPipe compareFechas ->hasta",valorGrabado, this.fn.compareFechas(valorGrabado,hasta));

                    if(desde!='' && hasta!='') {
                        if(showConsole) console.log("RangoFechasSearchPipe desde y hasta");
                        if (['1mayor2','iguales'].indexOf(this.fn.compareFechas(valorGrabado,desde))!=-1 && 
                            ['1menor2','iguales'].indexOf(this.fn.compareFechas(valorGrabado,hasta))!=-1) {
                           rta=true;
                        } else {
                           rta=false;
                        }  
                    } else if(desde!='') {
                        if(showConsole) console.log("RangoFechasSearchPipe desde");
                        if (['1mayor2','iguales'].indexOf(this.fn.compareFechas(valorGrabado,desde))!=-1) {
                           rta=true;
                        } else {
                           rta=false;
                        }  
                    } else if(hasta!='') {
                        if(showConsole) console.log("RangoFechasSearchPipe hasta");
                        if(['1menor2','iguales'].indexOf(this.fn.compareFechas(valorGrabado,hasta))!=-1) {
                           rta=true;
                        } else {
                           rta=false;
                        }  
                    } else {
                        if(showConsole) console.log("RangoFechasSearchPipe NO FILTRAR");
                        rta=true;
                    }

                    if(showConsole) if(showConsole) console.log("RangoFechasSearchPipe rta",rta);

                    return rta;
                }    

            });
        }
    }
}