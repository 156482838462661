<form [formGroup]="form">
	<div class="form-group" style="margin-bottom:0px; padding-bottom:0px;">
		<!-- <input placeholder="{{placeHolderAutocomplete}}" autocorrect="off" autocapitalize="off" spellcheck="off" 
				type="text" class="form-control" 
				#search formControlName="inputAutoComplete"> -->
				<!--[formControl]="searchControl"-->
				
				<input type="text" class="form-control" formControlName="inputAutoComplete"
						 #search (keyup)="onSearch(search.value)"
						 placeholder="{{placeHolderAutocomplete}}"
						 autocomplete="off" autocapitalize="off" autofocus>			 
				  
	</div>
</form>	
<agm-map [latitude]="ubicacion.geoPoint.latitud" [longitude]="ubicacion.geoPoint.longitud" 
		 [scrollwheel]="false" [zoom]="zoom" id="idAgmMap">
	<agm-marker [latitude]="ubicacion.geoPoint.latitud" [longitude]="ubicacion.geoPoint.longitud"></agm-marker>
</agm-map>
