import { log, logIf, logTable, values } from '@maq-console';

import { Component, ElementRef, NgZone, OnInit, ViewChild, EventEmitter, Output, Input, DoCheck  } from '@angular/core';
import { FormGroup, FormBuilder} from '@angular/forms';
import { ChangeDetectorRef }      from '@angular/core';
import { MapsAPILoader }          from '@agm/core';
import { PlacePredictionService } from './google-map-autocomplete.service';
import { GoogleResult } from './google-result.model';
import { Observable } from 'rxjs/Observable';

import { MensajesService }   from '@maq-servicios/mensajes/mensajes.service';
import { FuncionesService } from '@maq-funciones';
import { TranslateService }       from '@ngx-translate/core';
import { ApisService }            from '@maq-apis';
import {PAISES}             from '@maq-mocks/paises/paises';
import { GeoPoint }      from '@maq-models/geopoint/geopoint.model';
import { Direccion }     from '@maq-models/direccion/direccion.model';

import { take } from 'rxjs/operators';

declare var google:any;

@Component({
  selector: 'lib-shared-google-map-autocomplete',
  templateUrl: './google-map-autocomplete.component.html',
  styleUrls: [ './google-map-autocomplete.component.css']
})

export class GoogleMapAutocompleteComponent implements DoCheck, OnInit {

  // Colecciones Secundarias
  public subscripcionCache                            : any[]=[];
  public configListadosCache                          : any[]=[];
  public cantidadSubscripcionesSecundariasLanzadas    : number=0;
  public finalizoGETSecundarias                       : boolean=false;

  // Variables Mapa
  public ubicacion                                    : Direccion;
  public zoom                                         : number = 15;
  public form                                         : FormGroup;
  public placeHolderAutocomplete                      : string;
  
  public autocompleteService                          : any;
  private searchTerm                                  : string;
  private googlePlacesResults                         : GoogleResult[] = [];
  public sessionToken                                 : any;
  public cantAutocomplete                             : number = 0;

  @ViewChild("search") public searchElementRef: ElementRef;

  @Input() geoPointFormulario   : GeoPoint;
  @Input() accionForm           : string;  
  @Input() organizacionKNAI     : any;
  @Input() usuarioKANE          : any;
  @Input() nombreColeccion      : any;

  @Output() escribioDireccion= new EventEmitter<Direccion>();

  constructor(private mapsAPILoader             : MapsAPILoader,
              private placePredictionService    : PlacePredictionService,
              private ngZone                    : NgZone,
              private changeDetectorRef         : ChangeDetectorRef,
              private translate                 : TranslateService,
              public fn                         : FuncionesService,
              public msg                        : MensajesService,
              public apis                       : ApisService,
              public fb                         : FormBuilder) {

      this.ubicacion = new Direccion();      

      this.cargaVariablesTraducibles();      

  }  

  ngDoCheck() {
      if(!this.geoPointFormulario){
          this.setCurrentPosition(); 
      } else{
        this.ubicacion.geoPoint.latitud = this.geoPointFormulario.latitud;
        this.ubicacion.geoPoint.longitud =  this.geoPointFormulario.longitud;  
      }

      if(this.accionForm=='consultar') {
        this.form.disable();
      } else {
        this.form.enable();
      }         
  }
  
  cargaVariablesTraducibles(){
      this.placeHolderAutocomplete    = this.translate.instant('claseDireccion.placeHolderAutocomplete');
  }    

  getData() {
    this.placePredictionService.currentData.subscribe((response: GoogleResult[]) => {
        console.log("placePredictionService results",response);
        this.googlePlacesResults = response;
        
        this.apis.LogApiFuncion({
          eventoQueDisparo : '<google-map-autocomplete>.getData() - Cuento cada vez que se obtienen sugerencias del Autocomplete',
          apiFuncionKey    : 'GoogleMapsAutocomplete', 
          organizacionKNAI : this.organizacionKNAI,
          usuarioKANE      : this.usuarioKANE,
          nombreColeccion  : this.nombreColeccion,
          cloudFunction    : null,
          cantidad         : 1, 
        });
        
        this.cantAutocomplete++;  // Los cuento xq si luego selecciona un resultado tengo que restarlo

    });
  }

  onSearch(term: string) {
    this.searchTerm = term;
    if (this.searchTerm === '') {
      return;
    }
    this.placePredictionService.getPlacePredictions(term, this.sessionToken);
  }
  
  ngOnInit() {

    console.log("ngOnInit")

    this.translate.onLangChange.subscribe(() => {
        console.log('langChange');
        this.cargaVariablesTraducibles();
    });

    this.form = this.fb.group({
        inputAutoComplete:null
    });
    
    // Colecciones Auxiliares
    this.configListadosCache=[];

    this.configListadosCache.push({ 
        nombreListado   : 'listadoAuxProvincias',
        nombreColeccion : 'AuxProvincias',
        orderBy         : [{key:'nombre',ascDesc:'asc'}]      
    });     

    this.configListadosCache.push({ 
        nombreListado   : 'listadoAuxTimeZones',
        nombreColeccion : 'AuxTimeZones',
        orderBy         : [{key:'nombre',ascDesc:'asc'}]          
    });     
  
    this.getSubscripcionSecundarias();

    // Contabilizo la visualización Inicial del Mapa
    this.apis.LogApiFuncion({
      eventoQueDisparo : '<google-map-autocomplete>.autocomplete.onInit() - Visualización Inicial del Mapa al Abrir el Componente',
      apiFuncionKey    : 'GoogleMapsViewDynamic', 
      organizacionKNAI : this.organizacionKNAI,
      usuarioKANE      : this.usuarioKANE,
      nombreColeccion  : this.nombreColeccion,
      cloudFunction    : null,
      cantidad         : 1, 
    });
    
    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {

      this.sessionToken = new google.maps.places.AutocompleteSessionToken();
    
      this.getData();
        
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, { types: ["address"] });
            
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          console.log("place",place);

          var self = this;

          var request = {
              placeId         : place.place_id,
              sessionToken    : this.sessionToken
          };
          let placesService = new google.maps.places.PlacesService(this.searchElementRef.nativeElement);
          placesService.getDetails(request, callback);
          
          function callback(place, status) {
              if (status == google.maps.places.PlacesServiceStatus.OK) {
                  console.log('page > getPlaceDetail > place > ', place);
                  console.log("place.geometry",place.geometry);

                  self.apis.LogApiFuncion({
                    eventoQueDisparo : '<google-map-autocomplete>.autocomplete.AddListener() - Seleccionó Dirección en AutoComplete',
                    apiFuncionKey    : 'GoogleMapsPlaces', 
                    organizacionKNAI : self.organizacionKNAI,
                    usuarioKANE      : self.usuarioKANE,
                    nombreColeccion  : self.nombreColeccion,
                    cloudFunction    : null,
                    cantidad         : 1, 
                  });

                  self.apis.LogApiFuncion({
                    eventoQueDisparo : '<google-map-autocomplete>.autocomplete.AddListener() - Borro cantidad de sugerencias de Autocomplete totalizadas previamente, dado que se cobra sólo el Place',
                    apiFuncionKey    : 'GoogleMapsAutocomplete', 
                    organizacionKNAI : self.organizacionKNAI,
                    usuarioKANE      : self.usuarioKANE,
                    nombreColeccion  : self.nombreColeccion,
                    cloudFunction    : null,
                    cantidad         : self.cantAutocomplete * -1, 
                  });
                  
                  // Hay que analizar si la actualización de la dirección en el mapa que ya se está visualizando cuenta como un nuevo View del mapa
                  self.apis.LogApiFuncion({
                    eventoQueDisparo : '<google-map-autocomplete>.autocomplete.AddListener() - Refrescar Mapa con la dirección Geolocalizada',
                    apiFuncionKey    : 'GoogleMapsViewDynamic', 
                    organizacionKNAI : self.organizacionKNAI,
                    usuarioKANE      : self.usuarioKANE,
                    nombreColeccion  : self.nombreColeccion,
                    cloudFunction    : null,
                    cantidad         : 1, 
                  });
                  
                  //verify result
                  if (place.geometry === undefined || place.geometry === null) {
                    return;
                  }
                  
                  //set latitud, longitud 
                  self.ubicacion.geoPoint.latitud = place.geometry.location.lat();
                  self.ubicacion.geoPoint.longitud = place.geometry.location.lng();
                  self.asignarGeoPoint(self.ubicacion);

                  // set datos geolocalizados
                  self.ubicacion.paisKN       = null;
                  self.ubicacion.provinciaKN  = null;
                  self.ubicacion.ciudad       = null;
                  self.ubicacion.partido      = null;
                  self.ubicacion.ciudad       = null;
                  self.ubicacion.calle        = null;
                  self.ubicacion.numero       = null;
                  self.ubicacion.codigoPostal = null;
                  self.ubicacion.timeZone     = null; 
                  
                  for (var i = 0; i < place.address_components.length; i++) {

                      let addressType    = place.address_components[i].types[0];                
                      let val_short_name = place.address_components[i].short_name;
                      let val_long_name  = place.address_components[i].long_name;

                      //console.log(addressType, val_short_name, val_long_name);

                      if(addressType=='country')                     self.ubicacion.paisKN      = self.fn.getAtributoFromListado( PAISES, val_long_name, 'nombre', 'KN');
                      if(addressType=='administrative_area_level_1') self.ubicacion.provinciaKN = self.fn.getAtributoFromListado( self.msg.cacheColecciones['AuxProvincias'], val_short_name, 'nombre', 'KN');
                      if(addressType=='locality')                    self.ubicacion.ciudad      = val_long_name;
                      if(addressType=='administrative_area_level_2') self.ubicacion.partido     = val_long_name;
                      if(addressType=='sublocality_level_1')         self.ubicacion.ciudad      = val_long_name;
                      if(addressType=='route')                       self.ubicacion.calle       = val_short_name;
                      if(addressType=='street_number')               self.ubicacion.numero      = val_short_name;  // a veces no viene
                      if(addressType=='postal_code')                 self.ubicacion.codigoPostal= val_short_name;  // a veces no viene
                  }

                  // Quito letras al Código Postal
                  if(self.ubicacion.codigoPostal!='' && self.ubicacion.codigoPostal!=null) {
                      self.ubicacion.codigoPostal= self.ubicacion.codigoPostal.replace(/[^0-9\\.]+/g, '');
                  } else {
                      // console.log("no se obtuvo codigo postal");                    
                  }    

                  if( self.fn.mostrarKN(self.ubicacion.paisKN,'nombre')=='Brazil') {
                      self.ubicacion.paisKN={
                        key:    'BR',
                        nombre: 'Brasil'
                      }  
                  }  

                  if(self.fn.mostrarKN(self.ubicacion.paisKN,'nombre')=='Argentina') {
                    self.ubicacion.timeZone='America/Argentina/Buenos_Aires';
                    
                  } else if(self.fn.mostrarKN(self.ubicacion.paisKN,'nombre')=='Brasil') {
                    self.ubicacion.timeZone='America/Sao_Paulo';
                    
                  } else if(self.fn.mostrarKN(self.ubicacion.paisKN,'nombre')=='Chile') {
                    self.ubicacion.timeZone='America/Santiago';
                    
                  } else if(self.fn.mostrarKN(self.ubicacion.paisKN,'nombre')=='Bolivia') {
                    self.ubicacion.timeZone='America/La_Paz';
                    
                  } else if(self.fn.mostrarKN(self.ubicacion.paisKN,'nombre')=='Uruguay') {
                    self.ubicacion.timeZone='America/Montevideo';

                  } else if(self.fn.mostrarKN(self.ubicacion.paisKN,'nombre')=='Paraguay') {
                    self.ubicacion.timeZone='America/Asuncion';
                    
                  }  
                
                  // if (place.utc_offset_minutes === undefined) {
                  //    // parche, quitar más adelante cuando tengamos bien configurados los mocks de países y husos horarios
                  //   if(self.fn.mostrarKN(self.ubicacion.paisKN,'nombre')=='Argentina') {
                  //     self.ubicacion.timeZone='America/Argentina/Buenos_Aires';
                      
                  //   } else if(self.fn.mostrarKN(self.ubicacion.paisKN,'nombre')=='Brasil') {
                  //     self.ubicacion.timeZone='America/Sao_Paulo';
                      
                  //   } else if(self.fn.mostrarKN(self.ubicacion.paisKN,'nombre')=='Chile') {
                  //     self.ubicacion.timeZone='America/Santiago';
                      
                  //   } else if(self.fn.mostrarKN(self.ubicacion.paisKN,'nombre')=='Bolivia') {
                  //     self.ubicacion.timeZone='America/La_Paz';
                      
                  //   } else if(self.fn.mostrarKN(self.ubicacion.paisKN,'nombre')=='Uruguay') {
                  //     self.ubicacion.timeZone='America/Montevideo';

                  //   } else if(self.fn.mostrarKN(self.ubicacion.paisKN,'nombre')=='Paraguay') {
                  //     self.ubicacion.timeZone='America/Asuncion';
                      
                  //   }  
                  // } else {
                  //   var utc=place.utc_offset_minutes/60;
                  //   console.log("place.formatted_address.utc_offset_minutes",place.utc_offset_minutes,utc);
                  //   if(utc<0) {
                  //     self.ubicacion.timeZone='UTC'+utc.toString();
                  //   } else {
                  //     self.ubicacion.timeZone='UTC+'+utc.toString();
                  //   }  
                  // } 

                  console.log("self.ubicacion",self.ubicacion);
                  
                  self.escribioDireccion.emit(self.ubicacion);

              }else{
                  console.log('page > getPlaceDetail > status > ', status);
              }
          }
            
        });

      });
    });

  }
  
  private setCurrentPosition() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.ubicacion.geoPoint.latitud = position.coords.latitude;
        this.ubicacion.geoPoint.longitud = position.coords.longitude;
      });
    }
  }

  private asignarGeoPoint(ubicacion) {
    console.log("asignarGeoPoint ubicacion",ubicacion);
    this.ubicacion.geoPoint.latitud = ubicacion.geoPoint.latitud;
    this.ubicacion.geoPoint.longitud =  ubicacion.geoPoint.longitud; 
    this.changeDetectorRef.detectChanges();
  }
  
  getSubscripcionSecundarias() {

      log(...values('funcionComponente','getSubscripcionSecundarias'));

      /* Subscripción a Colecciones Secundarias */
      this.cantidadSubscripcionesSecundariasLanzadas=0;
      this.finalizoGETSecundarias=false;              
      
      this.cantidadSubscripcionesSecundariasLanzadas=this.configListadosCache.length;
      
      for(let i=0; i<this.configListadosCache.length; i++) {
          
          this.subscripcionCache.push( 
              this.msg.getListadoCache({

                    nombreListado              : this.configListadosCache[i].nombreListado,
                    nombreColeccion            : this.configListadosCache[i].nombreColeccion,
                    where                      : [],
                    orderBy                    : this.configListadosCache[i].orderBy,
                    limit                      : null,
                    grabaLocalStorage          : true,   
                    ignoraValoresMemoria       : false, 
                    datosPorOrganizacion       : false,
                    organizacionKNAI           : this.organizacionKNAI,                           
                    usuarioKANE                : this.usuarioKANE,
                    nombreColeccionSolicitante : this.configListadosCache[i].nombreColeccion,
                    limpiaSettingUsuarioOrganizacion:false     
 
             }).pipe(take(1)).subscribe(data=>{
                  //console.log("data",data);

                  log(...values("valores",
                            "msg.cacheColecciones[" + this.configListadosCache[i].nombreColeccion + "]:", 
                            this.msg.cacheColecciones[this.configListadosCache[i].nombreColeccion]));
                  
                  // logTable(...values( this.msg.cacheColecciones[configListadosCache[i]],configListadosCache[i].nombreListado) );              
                  
                  this.cantidadSubscripcionesSecundariasLanzadas--;
                  
                  console.log("cantidadSubscripcionesSecundariasLanzadas",this.cantidadSubscripcionesSecundariasLanzadas);
                  if(this.cantidadSubscripcionesSecundariasLanzadas==0) {  // Devolvió resultados la última subscripción
                        this.finalizoGETSecundarias=true;              
                  }

              },(error:any)=>{
                  log(...values("error",error));
              }) 
          );    
      }
  }
  

}


