import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';

@Pipe({
  name: 'fechaHora'
})

 export class FechaHoraPipe implements PipeTransform {
    transform(
        value: any,
        language: string,  /* es, en */
    ): string | null {
        // console.log("fechaHoraPipe",value, language);
        let rta='';
        if(value==null || value===undefined) {
          //console.log("fechaHoraPipe Null");
          rta='';
        } else {

          let isEmptyJson = (Object.entries(value).length === 0 && value.constructor === Object);

          if(isEmptyJson) {
              // console.error("fechaHoraPipe {} !!!", value);
              rta='';
          } else {

              let fecha;

              let typeMillis = typeof value.toMillis;
              let isTimestamp = (typeMillis == "function");

              let formatoFechaHora=Object.prototype.toString.call(value);
              // console.log("fechaHoraPipe type",formatoFechaHora, value);

              if(formatoFechaHora=="[object String]") { // Wed Feb 05 2020 17:32:13 GMT-0300 (hora estándar de Argentina)
                fecha=new Date(value);

              // } else if(formatoFechaHora=='[object Object]') { // // Timestamp { seconds: 1580936640, nanoseconds: 0 }
              } else if(isTimestamp) { // // Timestamp { seconds: 1580936640, nanoseconds: 0 }
                  // console.error("fechahoraPipe recibió timestamp !!!",value, typeMillis);
                  let milisegundos = value.toMillis();
                  fecha = new Date(milisegundos);

              } else if(formatoFechaHora=="[object Date]") {     // Ya vino en formato fecha
                  fecha=value;

              } else {
                  // otro formato
                  fecha=value;
              }

              if(language=='en') {
                rta=formatDate(fecha, 'mm/dd/yyyy HH:mm', 'en')+" hs";
              } else {
                rta=formatDate(fecha, 'dd/MM/yyyy HH:mm', 'es')+" hs";
              }
          }
        }
        return rta;
    }
}
