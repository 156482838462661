<table class="table" style="border:hidden;">         
    <tr>
        <td class="text-right" style="padding-top:0px; padding-right:5px;">

                <!-- Anterior -->
                <button *ngIf="hayPaginaAnterior"
                        class="btn btn-white" style="color:#444; float:right;"
                        (click)="onClickPaginado('anterior')">« <span >{{traduccion.componentePaginado.anterior}}</span></button>

                <button *ngIf="hayPaginaAnterior==false"
                        class="btn btn-white" style="color:#ccc; float:right;">« <span >{{traduccion.componentePaginado.anterior}}</span></button>
        </td>

        <td style="width:70px; white-space:nowrap; padding-top:0px;  padding-left:0px; padding-right:0px;" 
            class="text-center">
            <button class="btn btn-dark" style="color:white;">{{grilla.paginaActualNro}}</button>

        </td>    

        <td class="text-left" style="padding-top:0px; padding-left:5px;">

            <!-- Siguiente -->
            <button *ngIf="hayPaginaSiguiente"
                    class="btn btn-white" style="color:#444; float:left;"
                    (click)="onClickPaginado('siguiente')"><span >{{traduccion.componentePaginado.siguiente}}</span> »</button>

            <button *ngIf="hayPaginaSiguiente==false"
                    class="btn btn-white" style="color:#ccc; float:left;"><span >{{traduccion.componentePaginado.siguiente}}</span> »</button>

        </td>    

        <td *ngIf="grilla.paginadoTipo=='servidor' && grilla.whereArray.length==0 && grilla.coleccionCantidadDocs"
            style="width:70px; white-space:nowrap; padding-top:0px;  padding-left:0px; padding-right:0px;" 
            class="text-center">
            <button class="btn btn-white" style="color:#444; border:1px solid #ccc; border-radius: 5px;"><span >{{traduccion.componentePaginado.cantDocumentos}}</span><b> {{grilla.coleccionCantidadDocs}}</b></button>

        </td>            

    </tr>
</table>
