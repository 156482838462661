import {Component,OnInit,OnDestroy,ViewEncapsulation,ElementRef,Input,} from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { Menu } from "./../../../../models/menu/menu.model";
import { MenuService } from "@maq-servicios/menu/menu.service";
import { MenuColeccionService } from "@maq-servicios/menu/menu-coleccion.service";
import { AppSettingsService } from "@settings/app.settings";
import { TranslateService } from "@ngx-translate/core";

declare let $: any;
declare let jQuery: any;

@Component({
  selector: "lib-shared-horizontal-menu",
  templateUrl: "./horizontal-menu.component.html",
  styleUrls: ["./horizontal-menu.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [MenuService],
})
export class HorizontalMenuComponent implements OnInit {
  //@Input("menuItems") menuItems:Menu[]=[];

  public menuItems:Menu[]=[];
  public refMenuItemsSubjectObservable: any;
  constructor(
    public appSettings: AppSettingsService,
    private menuService: MenuService,
    private menuColeccionService: MenuColeccionService,
    private router: Router,
    private elementRef: ElementRef,
    private translate: TranslateService
  ) {}

  ngOnInit() {
   
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
        let activeLink = this.menuService.getActiveLink(this.menuItems);
        this.menuService.setActiveLink(this.menuItems, activeLink);
        jQuery(".tooltip").tooltip({
          sanitize: false,
          sanitizeFn: function (content) {
            return null;
          },
        });
        jQuery(".tooltip").tooltip("hide");
      }
    });

    this.translate.onLangChange.subscribe(() => {
      console.log("langChange");
      console.log("currentLang", this.translate.store.currentLang);
      if (this.menuItems === undefined) return;

      this.menuItems.forEach((menuItem) => {
        let obj = document.getElementById(
          "menuTranslateHorizontal" + menuItem.id
        );
        if (obj) obj.innerHTML = this.translate.instant(menuItem.title);
      });
    });

    this.refMenuItemsSubjectObservable = this.menuColeccionService.menuItemsSubjectObservable.subscribe(
          (listaDeMenuesPermitidos) => {
            this.menuItems = listaDeMenuesPermitidos;
            this.menuService.generarMenu(this.elementRef.nativeElement.children[0],'horizontal',this.menuItems);
            this.menuColeccionService.desubscribeMenuService();
          }
      );

    if (this.appSettings.settings2.panel.menuType == "mini") {
      jQuery(".menu-item-link").tooltip({
        sanitize: false,
        sanitizeFn: function (content) {
          return null;
        },
      });
      jQuery(".menu-item-link").tooltip();
    }
  }
  
  ngAfterViewInit() {
    //console.log("ngAfterViewInit");
    // let activeLink = this.menuService.getActiveLink(this.menuItems);
    // this.menuService.setActiveLink(this.menuItems, activeLink); 
  }
}
