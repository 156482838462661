import { log, logIf, logTable, values } from '@maq-console';
import { Component, OnInit, ViewEncapsulation,ChangeDetectorRef } from '@angular/core';

// https://medium.com/@balramchavan/detecting-internet-connection-status-in-angular-application-ng-connection-service-1fa8add3b975
import { ConnectionService } from 'ng-connection-service';


import { AppSettingsService } from '@settings/app.settings';
import { AppSettings2 }       from '@maq-models/appSettings/appSettings.model';

@Component({
  selector: 'lib-shared-is-on-line',
  templateUrl: './is-on-line.component.html',
  styleUrls: ['./is-on-line.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class IsOnLineComponent implements OnInit {
  


  constructor(
                protected changeDetectorRef : ChangeDetectorRef,
                public appSettings          : AppSettingsService,
                private connectionService: ConnectionService) { }

  ngOnInit() {

    if(!this.appSettings.settings2.panel.funcionalidadesDisponibles.mostrarOnLine){
      console.log('no usa mostrarOnLine');
      
    }
    this.createOnlineListener();
    
  
    

  }
 

  createOnlineListener() {

    this.connectionService.monitor().subscribe(isConnected => {

      if (isConnected) {
        this.appSettings.settings2.equipo.isOnLine=true;
        if (!this.changeDetectorRef['destroyed']) {
          this.changeDetectorRef.detectChanges();
        }   
    
      }
      else {
        this.appSettings.settings2.equipo.isOnLine=false;
        if (!this.changeDetectorRef['destroyed']) {
          this.changeDetectorRef.detectChanges();
        }   
  
      }
    })

    //   Solo funciona en Firefox- Funciona la primera vez pero no atiende los eventos.
    //   
    // document.body.addEventListener("offline", function () {
    //   this.appSettings.settings2.equipo.isOnLine=false;
    //   if (!this.changeDetectorRef['destroyed']) {
    //     this.changeDetectorRef.detectChanges();
    //   }   
    // }, false);

    // document.body.addEventListener("online", function () {
    //   if (!this.changeDetectorRef['destroyed']) {
    //     this.changeDetectorRef.detectChanges();
    //   }   
    //   this.appSettings.settings2.equipo.isOnLine=true
    // }, false);

    
  }

  
}

  
  

                
                   
                     
  
  


