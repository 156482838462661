import { Injectable } 		  from '@angular/core';
import { Usuario } 			  from '@maq-models/usuarios/usuarios.model';
import { FuncionesService }  from '@maq-funciones';

import { TransferenciaArchivos,TransferenciaBaseDatos,CRITERIOS_DE_ACEPTACION } from '@maq-models/transferenciaArchivos/transferenciaArchivos.model';
import { Observable, Subject } from 'rxjs';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';

import { BDService }         from '@maq-bd/bd.service';
import { date } from 'ngx-custom-validators/src/app/date/validator';
@Injectable({
  providedIn: 'root'
})
export class ValidaTraduceCreaDocumentoService {
private excelProgress={
    cantidadPreparada:0,
    cantidadCargada: 0,
    cantidadError:0,
    cantidadTotal: 0,
    percentage: 0,
    cargandoExcell: true,
    estadosCarga: null,
    repetidos:{
    	cantidadTotal:0,
    	cantidadOK:0,
    	cantidadRepetidos:0,
    	campoEnAnalisis:'',
    },
    validandoDatos:{
    	cantidadTotal:0,
    	cantidadOK:0,
    	cantidadErrores:0,
    	canitidadEnProceso:0,
    },
 	verificandoEnBaseDatos:{
    	cantidadTotal:0,
    	cantidadOK:0,
    	cantidadErrores:0,
    	canitidadEnProceso:0,
    },

    grabandoDatos:{
    	cantidadTotal:0,
    	cantidadOK:0,
    	cantidadErrores:0,
    	canitidadEnProceso:0,
    }
    
  };
public estadoTransferencia= new Subject<any>();
public criteriosTransferencia: TransferenciaArchivos[]=[];   
public transferenciaBaseDatos:TransferenciaBaseDatos

  
constructor(  private readonly af: AngularFirestore,
			  private readonly bdService: BDService,
			  public fn:FuncionesService,
			  ) { }


public setCriteriosDeTransferencia(criteriosDeTransferencia){
	this.criteriosTransferencia=criteriosDeTransferencia;
}

public setTransferenciaBaseDatos(transferenciaBaseDatos:TransferenciaBaseDatos){
	this.transferenciaBaseDatos=transferenciaBaseDatos;
	if(transferenciaBaseDatos){
		//Verificar criterios de aceptacion
	}
}

public resetContadores(){
	this.excelProgress={
		cantidadPreparada:0,
		cantidadCargada: 0,
		cantidadError:0,
		cantidadTotal: 0,
		percentage: 0,
		cargandoExcell: true,
		estadosCarga: null,
		repetidos:{
			cantidadTotal:0,
			cantidadOK:0,
			cantidadRepetidos:0,
			campoEnAnalisis:'',
		},
		validandoDatos:{
			cantidadTotal:0,
			cantidadOK:0,
			cantidadErrores:0,
			canitidadEnProceso:0,
		},
		 verificandoEnBaseDatos:{
			cantidadTotal:0,
			cantidadOK:0,
			cantidadErrores:0,
			canitidadEnProceso:0,
		},
	
		grabandoDatos:{
			cantidadTotal:0,
			cantidadOK:0,
			cantidadErrores:0,
			canitidadEnProceso:0,
		}
	}
}



// public setCriteriosAceptacion(criteriosAceptacion){
// 	let exiteElCriterio=false;
// 	for (var i = 0; i < CRITERIOS_DE_ACEPTACION.length; i++) {
// 		if(CRITERIOS_DE_ACEPTACION[i]==criteriosAceptacion[i]){
// 			exiteElCriterio=true;
// 		}
// 	}
// 	if(!exiteElCriterio){
	
// 			console.error('El criterio de aceptacion no esta en la lista');
// 		} else {
// 			console.log('El criterio de aceptacion ok');
// 		}
// 	this.transferenciaBaseDatos.citerioParaAceptarUnCampoExistente=criteriosAceptacion;
// }



public getCriteriosDeTransferencia(criteriosDeTransferencia){
	return this.criteriosTransferencia;
}

public getEstadoTransferencia():Observable<any>{
	return this.estadoTransferencia.asObservable();
}



public analisisCamposClavesRepetidos(datosExcel) :Promise<any>{

	
    
    return new Promise((resolve,reject)=>{
	

		let lineasConClaveRepetidas:Array<any>=[];
		

		let camposObligatorios=this.criteriosTransferencia.filter(criterio=>criterio.claveUnica);

		this.excelProgress.repetidos.cantidadTotal=datosExcel.length *camposObligatorios.length ;
		this.excelProgress.validandoDatos.cantidadTotal=datosExcel.length;
		this.excelProgress.verificandoEnBaseDatos.cantidadTotal=datosExcel.length;
		this.excelProgress.grabandoDatos.cantidadTotal=datosExcel.length;
    	this.excelProgress.repetidos.cantidadOK=0;
    	this.excelProgress.repetidos.cantidadRepetidos=0;
			
		for (var camposObligatorio of camposObligatorios) {

   			 this.excelProgress.repetidos.campoEnAnalisis= this.excelProgress.repetidos.campoEnAnalisis+ camposObligatorio['nombreColumnaExcel']+' ';

		  	var object = {};
        	

        	for (var item of datosExcel) {

         		 if(!object[item[camposObligatorio['nombreColumnaExcel']]]){
              		object[item[camposObligatorio['nombreColumnaExcel']]] =1;
              		this.excelProgress.repetidos.cantidadOK+= 1;
            		
            	} 
        	 	else {
	        		object[item[camposObligatorio['nombreColumnaExcel']]]+= 1;
	        		item['campoRepetido']=camposObligatorio['nombreColumnaExcel'];
	        		item['cantidadRepetido']= object[item[camposObligatorio['nombreColumnaExcel']]]  ;
	        		item['valorRepetido']= item[camposObligatorio['nombreColumnaExcel']];
	        		item['MotivoRechazo']= 'Repetido';

					
    			    this.excelProgress.repetidos.cantidadRepetidos+= 1;
	        		
	        		lineasConClaveRepetidas.push(item);
	           		
        		}

        	 this.estadoTransferencia.next(this.excelProgress);		
        	}

    	
  		
 
  	  	 }  // fin for campos obligatorios

  	  	if(lineasConClaveRepetidas.length>0) {

  	  		resolve({hayRepetidas:{lineaExcel:lineasConClaveRepetidas} });

  	  	} else{
  	  		resolve({noHayRepetidas:{lineaExcel:lineasConClaveRepetidas} });

  	  	}
  	  	
		
			
	 
 
	});
}




nuevoDocumentoFromLineaExcel(lineaExcell:TransferenciaArchivos):Promise<any>{

	
	return new Promise((resolve,reject)=>{
   
	  	if(!this.criteriosTransferencia){
  	  		reject({error: 'NoHayCriteriosDeTransferencia'});
  	  		this.estadoTransferencia.next(this.excelProgress);
		}

	
		// nombreCampoDocumento:   'key',
		// nombreColumnaExcel:     'codigo+NOW',   
		// tipoValidacion:         'calculado',         
		// datos:                   [any], 			            
		// datosClaveBusqueda:     'key',   
		// claveUnica:             false,           
		// mensajeError:           'No existe el código en la tabla de Materias Primas. ' 

		var hayErrores = false;
		let result=[];
		lineaExcell['MotivoRechazo']='';
		let documento={settings:{}};
		let nombreColumna:string=null;
	
		console.log('documentoxx-inicial',documento);
		console.log('documentoxx-lineaExcell',lineaExcell);
		

		for (var criterio of this.criteriosTransferencia) {

			console.log('documentoxxT-getParte -criterio',criterio);
			let partesColumnaExell=[];
			
			if(	(criterio.nombreColumnaExcel.includes('|') 
				|| criterio.nombreColumnaExcel.includes('+')
				|| criterio.nombreColumnaExcel.includes('-')
				|| criterio.nombreColumnaExcel.includes('*')
				|| criterio.nombreColumnaExcel.includes('/'))
				//&& criterio.tipoValidacion=="Calculado"	
				){

					let p0=[];
					p0=criterio.nombreColumnaExcel.split('|');

					console.log('test p0',p0);
					let p1=[];
					for (let index = 0; index < p0.length; index++) {
						let spt=p0[index].toString().split('+');
						for (let i = 0; i < spt.length; i++) {
							p1.push(spt[i]);
						}
					}
					console.log('test p1',p1);
					let p2=[];
					for (let index = 0; index < p1.length; index++) {
						let spt=p1[index].toString().split('-');
						for (let i = 0; i < spt.length; i++) {
						 	p2.push(spt[i]);
						}
					}
					console.log('test p2',p2);
					let p3=[];
					for (let index = 0; index < p2.length; index++) {
						 let spt=p2[index].toString().split('/');
						 for (let i = 0; i < spt.length; i++) {
							  p3.push(spt[i]);
						 }
					}
					console.log('test p3',p3);	
					let p4 :string[]=[];
					for (let index = 0; index < p3.length; index++) {
						let spt=p3[index].toString().split('*');
						for (let i = 0; i < spt.length; i++) {
						 p4.push(spt[i]);
						}
					}
					console.log('test p4',p4);
					partesColumnaExell=p4;


					console.log('test partesColumnaExell',partesColumnaExell);

				}else{
				nombreColumna=criterio.nombreColumnaExcel;
			}
			
			let mensajeError			=criterio.mensajeError;
			let datos					=criterio.datos;
			let datosClaveBusqueda		=criterio.datosClaveBusqueda;
			let nombreCampoDocumento	=criterio.nombreCampoDocumento;
			console.log('documentoxxTT-nombreColumna',nombreColumna);
			console.log('documentoxxTT-criterio',criterio);
			console.log('documentoxxTT-lineaExcell',lineaExcell);
			switch (criterio.tipoValidacion) {
				
				case "obligatorio":	
					console.log("lineaExcell[nombreColumna]",lineaExcell[nombreColumna], typeof lineaExcell[nombreColumna]);
					if(lineaExcell[nombreColumna]==null||lineaExcell[nombreColumna]==undefined){
						hayErrores=true;
						lineaExcell['MotivoRechazo']=lineaExcell['MotivoRechazo']+mensajeError;
					} else {
						documento = this.fn.setDocField(documento, nombreCampoDocumento, lineaExcell[nombreColumna]);
					}
					break;

				case "numero":	
					console.log("lineaExcell[nombreColumna]",lineaExcell[nombreColumna], typeof lineaExcell[nombreColumna]);
					if(typeof lineaExcell[nombreColumna]!=='number' || lineaExcell[nombreColumna]==null){
						hayErrores=true;
						lineaExcell['MotivoRechazo']=lineaExcell['MotivoRechazo']+mensajeError;
					} else {
						documento = this.fn.setDocField(documento, nombreCampoDocumento, lineaExcell[nombreColumna]);
					}
					break;
					
				case "minimaCantidadCaracteres":
					console.log('documentoxxTT1-criterio',criterio);
					console.log('documentoxxTT1-lineaExcell',lineaExcell);
					console.log('documentoxxTT1-nombreColumna',nombreColumna);
					console.log('documentoxxTT1-datos',datos);
					if(!lineaExcell[nombreColumna] || lineaExcell[nombreColumna].length<datos[0]){					
						hayErrores=true;
						lineaExcell['MotivoRechazo']=lineaExcell['MotivoRechazo']+mensajeError;
					} else {
						documento = this.fn.setDocField(documento, nombreCampoDocumento, lineaExcell[nombreColumna]);
					}
					break;	
					
				case "mayorque":
					if(lineaExcell[nombreColumna]<=datos[0]){
						hayErrores=true;
						lineaExcell['MotivoRechazo']=lineaExcell['MotivoRechazo']+mensajeError;
					} else {
						documento = this.fn.setDocField(documento, nombreCampoDocumento, lineaExcell[nombreColumna]);;
					}
					break;	

				case "emailValido":
					console.log('valido<> email');
					var emailRegexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
					if (lineaExcell[nombreColumna] && !emailRegexp.test(lineaExcell[nombreColumna])) {
						hayErrores=true;
						lineaExcell['MotivoRechazo']=lineaExcell['MotivoRechazo']+mensajeError;
					} else {
						documento = this.fn.setDocField(documento, nombreCampoDocumento, lineaExcell[nombreColumna]);;
					}
					break;
				case "setDate":
					console.log('valido<> setDate');
					documento = this.fn.setDocField(documento, nombreCampoDocumento, new Date());
					
					break;	
	
				case "keywords":
					console.log('valido<> keywords');
					documento = this.fn.setDocField(documento, nombreCampoDocumento,this.fn.generateKeywords( lineaExcell[nombreColumna] ));
					
					break;	
					
				case "constante": 	// copia el valor que se pasa en datos[0]
					console.log('valido<> constante');
					if(!datos[0]){
						hayErrores=true;
						lineaExcell['MotivoRechazo']=lineaExcell['MotivoRechazo']+ "No existe la constante para  "+ nombreCampoDocumento+'. ';

					}else{
						documento = this.fn.setDocField(documento, nombreCampoDocumento,datos[0]);				
					}
					
					break;
				
				case "SetNull": 	// coloca un Null en el documento
					console.log('valido<> SetNull');
					{
						documento = this.fn.setDocField(documento, nombreCampoDocumento,null);				
					}
					
					break;				

				case "getText": 	// Se queda con el texto de la comulmna excel
					console.log('valido<> getText');
					console.log('valido<> lineaExcell[nombreColumna]',lineaExcell[nombreColumna]);
					console.log('valido<> nombreColumna',nombreColumna);
					let sinNumenro=''
					if(lineaExcell[nombreColumna]){
						let t= lineaExcell[nombreColumna].split(/(\d+)/g);
						sinNumenro= lineaExcell[nombreColumna].replace(t[1],"");
					}
					
					documento = this.fn.setDocField(documento, nombreCampoDocumento,sinNumenro);						
					break;

				case "getNumber": 	// Se queda con el texto de la comulmna excel
					console.log('valido<> getNumber');
					let n= lineaExcell[nombreColumna].split(/(\d+)/g);
					documento = this.fn.setDocField(documento, nombreCampoDocumento,n[1]);
					break;	
				
				case "hh:mm:ss_OBJ": 	// transforma HH:mmss en un objeto {hours:0,minute:0,second:0}
					console.log('valido<> getNumber');
					let HHMMSS: any[]= lineaExcell[nombreColumna].split(':');
					let tiempo={hour:0,minute:0,second:0};
					if(HHMMSS.length!=3){
						hayErrores=true;
						lineaExcell['MotivoRechazo']=lineaExcell['MotivoRechazo']+  nombreColumna+ "No tiene formato HH:mm:ss ";
					}else{
						tiempo={
							hour:parseInt(HHMMSS[0]),
							minute:parseInt(HHMMSS[1]),
							second:parseInt(HHMMSS[2])
						}
						if(tiempo.hour>24||tiempo.hour<0 ||tiempo.minute>60||tiempo.minute<0||tiempo.second>60||tiempo.second<0){
							hayErrores=true;
							lineaExcell['MotivoRechazo']=lineaExcell['MotivoRechazo']+  nombreColumna+ "No tiene horario inválido ";
						} else{
							documento = this.fn.setDocField(documento, nombreCampoDocumento,tiempo);
						}
					}
										
					break;	
					
				case "dd/mm/aaaa_OBJ": 	// transforma dd/mm/aa en un objeto {day:0,month:0,year:0}
					console.log('valido<> getNumber');
					let ddmmaa: any[]= lineaExcell[nombreColumna].split('/');
					let Obj_day={day:0,month:0,year:0};
					if(ddmmaa.length!=3){
						hayErrores=true;
						lineaExcell['MotivoRechazo']=lineaExcell['MotivoRechazo']+  nombreColumna+ "No tiene formato dd/mm/aaaa ";
					}else{
						Obj_day={
							day:parseInt(ddmmaa[0]),
							month:parseInt(ddmmaa[1]),
							year:parseInt(ddmmaa[2])
						}
						// revisar validacioens
						if(Obj_day.day>31||Obj_day.day<0 ||Obj_day.month>12||Obj_day.month<0||Obj_day.year>2020||Obj_day.year<1950){
							hayErrores=true;
							lineaExcell['MotivoRechazo']=lineaExcell['MotivoRechazo']+  nombreColumna+ "No tiene fecha ";
						} else{
							documento = this.fn.setDocField(documento, nombreCampoDocumento,Obj_day);
						}
					}
										
					break;		
		

					case "buscarEnArrayDeStrings":
						if(!datos || datos.length==0 ){
							hayErrores=true;
							lineaExcell['MotivoRechazo']=lineaExcell['MotivoRechazo']+"No hay un array para buscar los datos "+ nombreColumna+'. ';
	
						} else {
							result = datos.filter(d=>{
								if(d){
									return d.indexOf(lineaExcell[nombreColumna])!=-1
								} else {
									return false;
								}
							});
							if (result.length==0) {
								hayErrores=true;
								lineaExcell['MotivoRechazo']=lineaExcell['MotivoRechazo']+mensajeError;
							} else{
								documento = this.fn.setDocField(documento, nombreCampoDocumento, result[0]);
							}
						}
						break;
	

				case "estaEnArrayBuscoEnArray":
					if(!datos || datos.length==0 ){
						hayErrores=true;
						lineaExcell['MotivoRechazo']=lineaExcell['MotivoRechazo']+"No hay un array para buscar los datos "+ nombreColumna+'. ';

					} else {
						result = datos.filter(d=>{
							if(d[datosClaveBusqueda]){
								return d[datosClaveBusqueda].indexOf(lineaExcell[nombreColumna])!=-1
							} else {
								return false;
							}
						});
						if (result.length==0) {
							hayErrores=true;
							lineaExcell['MotivoRechazo']=lineaExcell['MotivoRechazo']+mensajeError;
						} else{
							documento = this.fn.setDocField(documento, nombreCampoDocumento, result[0]);
						}
					}
					break;


				case "estaEnArrayBuscoEnString":

					if(!datos || datos.length==0 ){
						hayErrores=true;
						lineaExcell['MotivoRechazo']=lineaExcell['MotivoRechazo']+"No hay un array para buscar los datos "+ nombreColumna+'. ';
					} else {
						console.log('jj datos',datos);
						console.log('jj lineaExcell',lineaExcell);
						console.log('jjnombreColumna',nombreColumna);
						console.log('jj lineaExcell[nombreColumna]',lineaExcell[nombreColumna]);
						result = datos.filter(d=>d[datosClaveBusqueda].toUpperCase()==lineaExcell[nombreColumna].toUpperCase());
						if (result.length==0) {
							hayErrores=true;
							lineaExcell['MotivoRechazo']=lineaExcell['MotivoRechazo']+mensajeError;
						}else{
							documento = this.fn.setDocField(documento, nombreCampoDocumento, result[0]);
						}
					}
					break;

					case "BuscoEnArray_RetornoNombre":
						console.log('documentoxxTT1-criterio',criterio);
						console.log('documentoxxTT1-lineaExcell',lineaExcell);
						console.log('documentoxxTT1-nombreColumna',nombreColumna);
						console.log('documentoxxTT1-datos',datos);
						if(!datos || datos.length==0 ){
							hayErrores=true;
							lineaExcell['MotivoRechazo']=lineaExcell['MotivoRechazo']+"No hay un array para buscar los datos "+ nombreColumna+'. ';
						} else {
							result = datos.filter(d=>{
								if(d[datosClaveBusqueda]){
									return d[datosClaveBusqueda].indexOf(lineaExcell[nombreColumna])!=-1
								} else {
									return false;
								}
							});
							console.log('documentoxx-RetornoKNresult',result);
							if (result.length==0 || !result[0]['nombre']) {
								hayErrores=true;
								lineaExcell['MotivoRechazo']=lineaExcell['MotivoRechazo']+mensajeError;
							} else {
								documento = this.fn.setDocField(documento, nombreCampoDocumento, result[0]['nombre']);
							}		
						}
						console.log('documentoxx-RetornoKN',documento);
						break;	
						case "BuscoEnArray_RetornoClave":
							console.log('documentoxxTT1-criterio',criterio);
							console.log('documentoxxTT1-lineaExcell',lineaExcell);
							console.log('documentoxxTT1-nombreColumna',nombreColumna);
							console.log('documentoxxTT1-datos',datos);
							if(!datos || datos.length==0 ){
								hayErrores=true;
								lineaExcell['MotivoRechazo']=lineaExcell['MotivoRechazo']+"No hay un array para buscar los datos "+ nombreColumna+'. ';
							} else {
								result = datos.filter(d=>{
									if(d[datosClaveBusqueda]){
										return d[datosClaveBusqueda].indexOf(lineaExcell[nombreColumna])!=-1
									} else {
										return false;
									}
								});
								console.log('documentoxx-RetornoKNresult',result);
								if (result.length==0) {
									hayErrores=true;
									lineaExcell['MotivoRechazo']=lineaExcell['MotivoRechazo']+mensajeError;
								} else {
									documento = this.fn.setDocField(documento, nombreCampoDocumento, result[0][datosClaveBusqueda]);
								}		
							}
							console.log('documentoxx-RetornoKN',documento);
							break;	
						
				case "BuscoEnArray_RetornoKN":
					console.log('documentoxxTT1-criterio',criterio);
					console.log('documentoxxTT1-lineaExcell',lineaExcell);
					console.log('documentoxxTT1-nombreColumna',nombreColumna);
					console.log('documentoxxTT1-datos',datos);
					if(!datos || datos.length==0 ){
						hayErrores=true;
						lineaExcell['MotivoRechazo']=lineaExcell['MotivoRechazo']+"No hay un array para buscar los datos "+ nombreColumna+'. ';
					} else {
						result = datos.filter(d=>{
							if(d[datosClaveBusqueda]){
								return d[datosClaveBusqueda].indexOf(lineaExcell[nombreColumna])!=-1
							} else {
								return false;
							}
						});
						console.log('documentoxx-RetornoKNresult',result);
						if (result.length==0) {
							hayErrores=true;
							lineaExcell['MotivoRechazo']=lineaExcell['MotivoRechazo']+mensajeError;
						} else {
							documento = this.fn.setDocField(documento, nombreCampoDocumento, {key :result[0]['key'], nombre:result[0]['nombre']});
						}		
					}
					console.log('documentoxx-RetornoKN',documento);
					break;

				case "BuscoEnArray_RetornoKNAI":
					if(!datos || datos.length==0 ){
						hayErrores=true;
						lineaExcell['MotivoRechazo']=lineaExcell['MotivoRechazo']+"No hay un array para buscar los datos "+ nombreColumna+'. ';
					} else {
						result = datos.filter(d=>{
							if(d[datosClaveBusqueda]){
								return d[datosClaveBusqueda].indexOf(lineaExcell[nombreColumna])!=-1
							} else {
								return false;
							}
						});
						console.log('documentoxx-RetornoKNresult',result);
						if (result.length==0) {
							hayErrores=true;
							lineaExcell['MotivoRechazo']=lineaExcell['MotivoRechazo']+mensajeError;
						} else {
							let knai={
								key :result[0]['key'], 
								nombre:result[0]['nombre'],
								autoIncrement:result[0]['autoIncrement']
							};
							console.log('documentoxx-RetornoKNresult knai',knai);
							documento = this.fn.setDocField(documento, nombreCampoDocumento,knai);
							console.log('documentoxx-RetornoKNresult knai',documento);
						}		
					}
				break;				
					
				case "Calculado":
					console.log('documentoxxT-Calculado partesColumnaExell ',partesColumnaExell);
					if(partesColumnaExell.length==0 ){
						hayErrores=true;
						lineaExcell['MotivoRechazo']=lineaExcell['MotivoRechazo']+"El formato del campo calculado es incorrecto";
					}else{
						let cadena=criterio.nombreColumnaExcel;
						for(let i=0;i<partesColumnaExell.length;i++){
							let parte=partesColumnaExell[i]
							let r:string=null;
							console.log('respDate parte', parte);
							switch (parte.toString()) {
								case "NEW_DATE":
									cadena=cadena.replace(parte,new Date().toDateString);
									break;	
								case "DATE":
									r=this.fn.getFechaActual('AAAAMMDD');
									console.log('respDate r', r);
									cadena=cadena.replace(parte,r);
									break;
						
								case "DATETIME":
									r=this.fn.getFechaActual('AAAAMMDD')+this.fn.getHoraActual('HHMMSS');
									cadena=cadena.replace(parte,r);
									break;
				
								case "TIME":
									r=this.fn.getHoraActual('HHMMSS');
									cadena=cadena.replace(parte,r);
									break;

								case "@@@":
									r="@@@";
									cadena=cadena.replace(parte,r);
									break;

								case "PUSH":
									r="PUSH";
									cadena=cadena.replace(parte,r);
									break;
									
								case "100":
									r="100";
									cadena=cadena.replace(parte,r);
									break;			

								case "NULL":
								cadena=cadena.replace(parte,'');
								break;									
					
								default:
									r= this.fn.getDocField(documento,parte.toString());
									console.log('respDate rx',parte.toString(), r);
									cadena=cadena.replace(parte,r);
									break;
							}
						}
						console.log('documentoxxT-cadena',cadena);
						if(criterio.nombreColumnaExcel.includes('|')){
						
							console.log('documentoxxT-includes',criterio.nombreColumnaExcel);
							result[0]=cadena.replace(/\|/g, '');

							result[0]=result[0].replace('PUSH','');
							console.log('documentoxxT-includes',criterio.nombreColumnaExcel);
						} else{
							try {
								result[0]=this.fn.MatEvaluate(cadena);
							} catch (error) {
								result[0]=0;
							}
							
						}
						if(criterio.nombreColumnaExcel.includes('PUSH')){

							let valDoc=this.fn.getDocField(documento, nombreCampoDocumento);
							if(valDoc==undefined || valDoc==null ){
								valDoc=[];
							}
							
							valDoc.push(result[0]);
							documento = this.fn.setDocField(documento, nombreCampoDocumento,valDoc);	

						}else{
						documento = this.fn.setDocField(documento, nombreCampoDocumento,result[0]);
						} 
					}	

					console.log('documentoxxT-Calculado',documento);
					break;

				default:
					break;

			}  // fin del switch

		}	//Cierre del for

		console.log('documentoxx validando Datos doc',documento);
		console.log('documentoxx validando Datos hayErrores',hayErrores);
		if (hayErrores)  {
			this.excelProgress.validandoDatos.cantidadErrores+=1;
			this.estadoTransferencia.next(this.excelProgress);
			resolve({error:lineaExcell}); 
			return;

		} else{
			this.excelProgress.validandoDatos.cantidadOK+=1;
			this.estadoTransferencia.next(this.excelProgress);		
			resolve({correcto:{lineaExcell:lineaExcell,documento:documento} });
		}

	});		// Cierre de la promesa

}


buscarDatosExistentes(resultadoAnalisis,nombreColeccion, campoVerificarEnBase, campoClaveExcelParaVerificarEnBase):Promise<any>{
  console.log('buscarDatosExistentes resultadoAnalisis',resultadoAnalisis);
  let lineaExcel=	resultadoAnalisis['lineaExcell'];
  console.log('buscarDatosExistentes lineaExcell',lineaExcel);
  console.log('buscarDatosExistentes nombreColeccion',nombreColeccion);
  console.log('buscarDatosExistentes campoVerificarEnBase',campoVerificarEnBase);
  console.log('buscarDatosExistentes campoClaveExcelParaVerificarEnBase',campoClaveExcelParaVerificarEnBase);
  console.log('buscarDatosExistentes where',campoVerificarEnBase,'==' ,lineaExcel[campoClaveExcelParaVerificarEnBase]);
  
  this.excelProgress.verificandoEnBaseDatos.canitidadEnProceso+=(1/this.transferenciaBaseDatos.camposaVerificarEnBase.length);
  this.estadoTransferencia.next(this.excelProgress);  
                    
  return new Promise((resolve,reject)=>{
     
        
  		//const verificoCodigo= this.af.collection<Usuario>(nombreColeccion,
        //  ref => ref.where(campoVerificarEnBase,'==' ,lineaExcel[campoClaveExcelParaVerificarEnBase])).snapshotChanges().pipe(


        console.log("1excel collection where", campoVerificarEnBase,'==' ,lineaExcel[campoClaveExcelParaVerificarEnBase]);
        const verificoCodigo= this.af.collection<Usuario>(nombreColeccion,
          ref => ref.where(campoVerificarEnBase,'==' ,lineaExcel[campoClaveExcelParaVerificarEnBase])).snapshotChanges().subscribe(
          	respuesta=>{               	

				console.log('1Excel buscarDatosExistentes where respuesta',respuesta);

                if (respuesta.length>0) {

                   	this.excelProgress.verificandoEnBaseDatos.cantidadErrores+=(1/this.transferenciaBaseDatos.camposaVerificarEnBase.length);
                	console.log("1Excel suma error",this.excelProgress.verificandoEnBaseDatos.cantidadErrores);	
  					this.estadoTransferencia.next(this.excelProgress);
                  	lineaExcel['error']="existe el campo "+ campoClaveExcelParaVerificarEnBase + " en la base de datos "+respuesta.length+ "  veces.";	
                  	console.log("1excel","existe el campo "+ campoClaveExcelParaVerificarEnBase + " en la base de datos "+respuesta.length+ "  veces.");	
                    const doc0 = respuesta[0].payload.doc.data() ;
                    verificoCodigo.unsubscribe();
                  	resolve({error:resultadoAnalisis,doc0:doc0}); 
                
                } else {

                    this.excelProgress.verificandoEnBaseDatos.cantidadOK+=(1/this.transferenciaBaseDatos.camposaVerificarEnBase.length);
					console.log("1Excel suma ok",this.excelProgress.verificandoEnBaseDatos.cantidadOK);	
  					this.estadoTransferencia.next(this.excelProgress);
               		console.log("1Excel buscarDatosExistentes where","No existe el documento");	
               		verificoCodigo.unsubscribe();
                  	resolve({ok:resultadoAnalisis}); 
                }
          
             });
         
 });
 }
 
 verificarSiPuedeHacerUpdate(resultadoBusqueda):any{
 	console.log('verificarSiPuedeHacerUpdate resultadoBusqueda',resultadoBusqueda);
 	console.log('verificarSiPuedeHacerUpdate resultadoBusqueda base',this.transferenciaBaseDatos.camposaVerificarEnBase);
 	console.log('verificarSiPuedeHacerUpdate resultadoBusqueda excell',this.transferenciaBaseDatos.camposClaveExcelParaVerificarEnBase);


 	
 	let returnValidacion={};
 	let resultadoComparacion:string[]=[];


 	if(	this.transferenciaBaseDatos.camposaVerificarEnBase.length != this.transferenciaBaseDatos.camposClaveExcelParaVerificarEnBase.length)
 		{
		console.error('transferenciaBaseDatos', 'Debene ser de igual longitud')	;

 	}
 	if(	this.transferenciaBaseDatos.camposaVerificarEnBase.length != CRITERIOS_DE_ACEPTACION.length)
 		{
		console.error('transferenciaBaseDatos', 'Debene ser de igual longitud')	;

 	}

 	for (var i = 0; i < this.transferenciaBaseDatos.camposaVerificarEnBase.length; i++) {
 		console.log('verificarSiPuedeHacerUpdate resultadoBusqueda base',this.transferenciaBaseDatos.camposaVerificarEnBase[i]);
 		console.log('verificarSiPuedeHacerUpdate resultadoBusqueda excell',this.transferenciaBaseDatos.camposClaveExcelParaVerificarEnBase[i]);
 	
 		if(resultadoBusqueda['error']['lineaExcell'][this.transferenciaBaseDatos.camposaVerificarEnBase[i]]
 			!=
 			resultadoBusqueda['error']['documento'][this.transferenciaBaseDatos.camposClaveExcelParaVerificarEnBase[i]]
 			){

			

			resultadoComparacion[i]="distintos";
 		}else {
 			resultadoComparacion[i]="iguales";
 		}

 	}

console.log('verificarSiPuedeHacerUpdate resultadoBusqueda base',this.transferenciaBaseDatos.camposaVerificarEnBase);
console.log('verificarSiPuedeHacerUpdate resultadoBusqueda excell',this.transferenciaBaseDatos.camposClaveExcelParaVerificarEnBase);




 	for (var i = 0; i < this.transferenciaBaseDatos.camposaVerificarEnBase.length; i++) {


	 	switch (this.transferenciaBaseDatos.citerioParaAceptarUnCampoExistente[i]) {
	 		case "merge":
	 		console.log('verificarSiPuedeHacerUpdate resultadoBusqueda base',this.transferenciaBaseDatos.camposaVerificarEnBase[i]);
			console.log('verificarSiPuedeHacerUpdate resultadoBusqueda excell',this.transferenciaBaseDatos.camposClaveExcelParaVerificarEnBase[i]);
	 			if(resultadoComparacion[i]=="iguales"){
	 				returnValidacion['aptoParagrabar']=true;
	 				resultadoBusqueda['error']['lineaExcell']['MotivoRechazo']=
						'El campo '
						+ this.transferenciaBaseDatos.camposaVerificarEnBase[i]
						+' con valor '
						+  resultadoBusqueda['error']['lineaExcell'][this.transferenciaBaseDatos.camposaVerificarEnBase[i]]
						+' pero se pueden escribir nuevamente.'; 


	 			}	


	 			break;
	 		case "todosIguales":
	 		console.log('verificarSiPuedeHacerUpdate resultadoBusqueda base',this.transferenciaBaseDatos.camposaVerificarEnBase[i]);
			console.log('verificarSiPuedeHacerUpdate resultadoBusqueda excell',this.transferenciaBaseDatos.camposClaveExcelParaVerificarEnBase[i]);

	 			let todosIguales=true;

	 			for (var j = 0; j < resultadoComparacion.length; j++) {
	 			
	 				if(resultadoComparacion[j]=="iguales"){
	 					todosIguales=false;	

	 				}
	 			}

	 			if(todosIguales){
	 				returnValidacion['aptoParagrabar']=true;
	 				resultadoBusqueda['error']['lineaExcell']['MotivoRechazo']=
						'El campo '
						+ this.transferenciaBaseDatos.camposClaveExcelParaVerificarEnBase[i]
						+' con valor '
						+  resultadoBusqueda['error']['lineaExcell'][this.transferenciaBaseDatos.camposClaveExcelParaVerificarEnBase[i]]
						+' pero se pueden escribir nuevamente.'; 
	 			} else {
	 	console.log('verificarSiPuedeHacerUpdate resultadoBusqueda base',this.transferenciaBaseDatos.camposaVerificarEnBase[i]);
		console.log('verificarSiPuedeHacerUpdate resultadoBusqueda excell',this.transferenciaBaseDatos.camposClaveExcelParaVerificarEnBase[i]);


	 				returnValidacion['aptoParagrabar']=false;
	 				resultadoBusqueda['error']['lineaExcell']['MotivoRechazo']=
						'El campo '
						+ this.transferenciaBaseDatos.camposClaveExcelParaVerificarEnBase[i]
						+' con valor '
						+  resultadoBusqueda['error']['lineaExcell'][this.transferenciaBaseDatos.camposClaveExcelParaVerificarEnBase[i]]
						+' ya existe en la base y no se puede generar uno nuevo.'; 
	 				
	 			}	
	 			
						
				returnValidacion['datos']=resultadoBusqueda['error']['lineaExcell'];
	 		
	 			break;
	 		default:
	 			// code...
	 			break;
	 	}
 	}


 	console.log('verificarSiPuedeHacerUpdate returnValidacion',returnValidacion);

 	return returnValidacion;
 }



 grabaDocumentoEnBases(documento){
	this.excelProgress.grabandoDatos.canitidadEnProceso+=1;
  	this.estadoTransferencia.next(this.excelProgress);
	
	return new Promise((resolve,reject)=>{
	   console.log('documento',documento);
	   console.log('documento Object.assign({}, documento)',Object.assign({}, documento));
	   console.log('documento JSON.stringify(obj))',JSON.parse(JSON.stringify(documento)));
	   console.log('documento.key',documento.key);
	   
	   documento['distribuidorKN']=this.transferenciaBaseDatos.distribuidorKN;
	   console.log('argumentos: ',documento);

	   
	
	   this.bdService.updateColeccion({
				operacion        : 'agregar',
				nombreColeccion  : this.transferenciaBaseDatos.nombreColeccion,
				documento        : documento,
				distribuidorKN   : this.transferenciaBaseDatos.distribuidorKN,
				organizacionKNAI : documento.organizacionKNAI ? documento.organizacionKNAI : this.transferenciaBaseDatos.organizacionKNAI,                           
				usuarioKANE      : this.transferenciaBaseDatos.usuarioKANE
				
		}).then(resultado=>{
	    	this.excelProgress.grabandoDatos.cantidadOK+=1;
  			this.estadoTransferencia.next(this.excelProgress);
  			resolve({grabadoOK:resultado});

	   }).catch(error=>{
	    	console.error('error algrabar',error);
	    	this.excelProgress.grabandoDatos.cantidadErrores+=1;
  			this.estadoTransferencia.next(this.excelProgress);
  			resolve({error:error});
		});
		
   
	})

}

grabaDocumentoEnBasesOLD(documento){
	this.excelProgress.grabandoDatos.canitidadEnProceso+=1;
  	this.estadoTransferencia.next(this.excelProgress);
	
	return new Promise((resolve,reject)=>{
	   console.log('documento',documento);
	   console.log('documento Object.assign({}, documento)',Object.assign({}, documento));
	   console.log('documento JSON.stringify(obj))',JSON.parse(JSON.stringify(documento)));
	   console.log('documento.key',documento.key);
	    this.af.collection<any>(this.transferenciaBaseDatos.nombreColeccion).doc(documento.key).set(JSON.parse(JSON.stringify(documento)),{merge:true})
	    .then(resultado=>{
	    	this.excelProgress.grabandoDatos.cantidadOK+=1;
  			this.estadoTransferencia.next(this.excelProgress);
  			resolve({grabadoOK:resultado});

	    })
	    .catch(error=>{
	    	console.error('error algrabar',error);
	    	this.excelProgress.grabandoDatos.cantidadErrores+=1;
  			this.estadoTransferencia.next(this.excelProgress);
  			resolve({error:error});
	    });



	   // resolve( this.af.collection<any>(this.transferenciaBaseDatos.nombreColeccion).doc(documento.key).set(JSON.parse(JSON.stringify(documento)),{merge:true}));
	   // resolve( this.af.collection<any>(this.transferenciaBaseDatos.nombreColeccion).doc(documento.key).set(documento,{merge:true}));
	})

}

 





} // fin de la clase







/*********************************************************************************************************************/
/*********************************************************************************************************************/
/*********************************************************************************************************************/
/******************************        Manejo de promesas                *********************************************/
/*********************************************************************************************************************/
/*********************************************************************************************************************/
/*********************************************************************************************************************/


   // promesas en paralelo a lazo abierto (si una falla se continua)

      // data[nombreDeHojaExell].map((lineaExcell:any, index:any)=>{
      //       console.log(lineaExcell);
      //       console.log(index);
      //       this.excelProgress.cantidadPreparada++;
      //        console.log(this.cantidadPreparada);
      //       this.excelProgress.cargandoExcell="Enviando al servidor ";
           
      //       this.addLineaExcell(lineaExcell).then(resultado=>{
      //             this.excelProgress.cantidadCargada++;
      //             this.excelProgress.percentage=(this.excelProgress.cantidadCargada+this.excelProgress.cantidadError)/this.excelProgress.cantidadTotal*100;
      //             console.log('percentage',this.excelProgress.percentage);
      //             console.log('resultado interno ok',lineaExcell);
      //             console.log(index);
      //             console.log(resultado);
      //             this.lineasSinErrores.push(lineaExcell);
      //             console.log('this.lineasSinErrores',this.lineasSinErrores);
      //       }).catch(error=>{ 
      //               this.excelProgress.cantidadError++;
      //               this.excelProgress.percentage=(this.excelProgress.cantidadCargada+this.excelProgress.cantidadError)/this.excelProgress.cantidadTotal*100;
      //               console.log('error interno error',error);
      //               console.log('error interno linea',lineaExcell);
      //               console.log(index);
      //       })
      //   })

      // promesas en paralelo a lazo con control de finalización (si una falla se suspende la ejecucion)

         // Promise.all(data[nombreDeHojaExell].map((lineaExcell:any, index:any)=>{
         //     console.log(lineaExcell);
         //     console.log(index);
             
         //     this.addLineaExcell(lineaExcell).then(resultado=>{
         //            console.log('resultado interno ok',lineaExcell);
         //             console.log(index);
         //            console.log(resultado);
         //          }).catch(error=>{
         //            console.log('error interno error',error);
         //            console.log('error interno linea',lineaExcell);
         //             console.log(index);
         //          });

         //     }))
         //    .then(resultado=>{
         //      console.log('resultado externo ok',resultado);
         //    }).catch(error=>{
         //      console.log('error final error',error);
              
         //    });
            





       // promesas en serie

          // data[nombreDeHojaExell].reduce((total, currentValue, currentIndex, arr)=>{
              
          //     console.log('total',total);
          //     console.log('currentValue',currentValue);
          //     console.log('currentIndex',currentIndex);
          //     console.log('arr',arr);

          //    return total.then(result=>{
          //     console.log('result',result);
          //     console.log('currentValue ok',currentValue);
          //     console.log('currentIndex ok',currentIndex);
          //      this.addLineaExcell(currentValue);
          //    }).catch(error=>{
          //      console.log(error)
          //      console.log('currentValue error',currentValue);
          //      console.log('currentIndex error',currentIndex);
          //    })
             
          

          //   },Promise.resolve());
