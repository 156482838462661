<div *ngIf="!documentoSeleccionado[bdFieldName] && disabledSearch==false" style="background:white; height:36px; padding:0px; ">

    <mat-form-field style="height:0px; border-bottom:none; 
                           padding-top:0px; margin-top:0px; padding-bottom:0px; margin-bottom:0px;">
        <!-- input-->      
        <mat-select ngModel [(ngModel)]="searchText" id="textSearch"              
                    (ngModelChange)="seleccionoDocumento($event)"
                    style="background:white; padding-top:0px; margin-top:0px; height:20px; line-height:20px;  ">        
            
        <mat-option>
          <ngx-mat-select-search               
              [formControl]="onKeyUpSearch" [searching]="searching" id="textSearch2"              
              [disableInitialFocus]=false
              [disableScrollToActiveOnOptionsChanged]=true 
              [preventHomeEndKeyPropagation]=true 
              [placeholderLabel]="placeholder|translate"    
              [noEntriesFoundLabel]="traduccion.multiSelectTexts.noEncontroResultados"></ngx-mat-select-search>  
        </mat-option>
        <mat-option [ngValue]="null">{{placeholder|translate }}</mat-option>
        <mat-option *ngFor="let documento of listadoResultadosBD | async" [value]="documento">
              
              {{getOptionValue(documento)}}
        </mat-option>
      </mat-select>
    </mat-form-field>
</div>

<div *ngIf="documentoSeleccionado[bdFieldName] && corrigeValor() && disabledSearch==false" 
     class="alert alert-light alert-dismissible fade show" role="alert" 
     [ngStyle]="inputStyle"      
     style="height:37px; margin-bottom:0px; padding-left:6px; padding-top:7px; padding-right:6px;
            border:1px solid #d4d8dd; "
            (click)="borroDocumentoSeleccionado()">

    <input type="text" value="{{documentoSeleccionado[bdFieldName]}}" [value]="traducirSeleccionado(documentoSeleccionado[bdFieldName])" 
           (focus)="borroDocumentoSeleccionado()"
           [ngStyle]="inputColorStyle"      
           [placeholder]="placeholder"
           [ngClass]="documentoSeleccionado[bdFieldName]==this.placeholder ? 'filtroSinValor' : 'filtroConValor'"
           style="border:hidden; white-space:nowrap; padding-left:5px; padding-top:1px;"
           readonly>
</div>
<!--  background: #e9ecef; -->
<div *ngIf="disabledSearch==true" 
     class="alert alert-light alert-dismissible fade show" role="alert" 
     [ngStyle]="inputStyle"      
     style="height:37px; margin-bottom:0px; padding-left:6px; padding-top:7px; padding-right:6px;
            border:1px solid #d4d8dd; ">

    <input type="text" value="{{documentoSeleccionado[bdFieldName]}}" [value]="traducirSeleccionado(documentoSeleccionado[bdFieldName])" 
           [ngStyle]="inputColorStyle"      
           [ngClass]="documentoSeleccionado[bdFieldName]==this.placeholder ? 'filtroSinValor' : 'filtroConValor'"
           style="border:hidden; white-space:nowrap; padding-left:5px; padding-top:1px; background:#e9ecef;"
           readonly>
</div>
