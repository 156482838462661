import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivate } from '@angular/router';
import { AuthService } from '@maq-autorizacion/services/auth.service'

@Injectable({
  providedIn: 'root'
})
export class CanActivateViaNoAuthGuard  implements CanActivate{

  
    constructor(private authService: AuthService, private router: Router) { }

    canActivate() {
        // If the user is logged in we'll send them back to the home page
        if (this.authService.authenticated) {
            console.log('estás logueado');
               this.router.navigate(['/usuarios/listadoUsuarios']);
            return false;
        }

        return true;
    }
}
