import { Component, OnInit, ElementRef, EventEmitter ,Output} from '@angular/core';
import { fromEvent } from 'rxjs/observable/fromEvent';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/takeUntil';
import { switchMap } from 'rxjs/operator/switchMap';
import { takeUntil } from 'rxjs/operator/takeUntil';
@Component({
  selector: 'app-mouse-events',
  templateUrl: './mouseEvent.component.html',
  styleUrls: ['./mouseEvent.component.scss']
})
export class MouseEventsComponent implements OnInit {
  mouseup$: any;
  mousedown$: any;
  mouseleave$: any; //https://developer.mozilla.org/en-US/docs/Web/API/Element/mouseleave_event
  mousemove$: any;
  mousehold$: any; 
  x: number;
  y: number;
  _sub: any;
  @Output() deltaX= new EventEmitter<number>();
 
  posicionAnterior:number=null;
  windowsInnerWith:number=window.innerWidth;

  constructor(private _el: ElementRef) { }

  ngOnInit() {
    this.mousedown$ = fromEvent(this._el.nativeElement, 'mousedown');
    this.mousedown$.subscribe((e) => {

      this.posicionAnterior=e.x;

      this.x = e.x;
      this.y = e.y;
    })
    this.mousemove$ = fromEvent(this._el.nativeElement, 'mousemove');
    this.mouseup$ = fromEvent(this._el.nativeElement, 'mouseup');
    this.mouseleave$ = fromEvent(this._el.nativeElement, 'mouseleave');

    this.mouseup$.subscribe(()=>{
        this.posicionAnterior=null;
      this.unsub();
      this.register();
    });


    this.mouseleave$.subscribe(()=>{
      this.posicionAnterior=null;
      this.unsub();
      this.register();
    });
    // switchMap is extremely helpful
    // map source observable to inner observable. remember it as switch to new observable.
    this.mousehold$ = this.mousedown$.switchMap(()=> this.mousemove$).takeUntil(this.mouseup$||this.mouseleave$);

    this._sub = this.mousehold$.subscribe((e) => {
      this.x = e.x;
      this.y = e.y;
      this.emitDelta(this.x);
    })
  }

  unsub() {
    if(this._sub) {
      this._sub.unsubscribe();
    }
  }

  register() {
    this.mousehold$ = this.mousedown$.switchMap(()=> this.mousemove$).takeUntil(this.mouseup$||this.mouseleave$);

    this._sub = this.mousehold$.subscribe((e) => {

      this.x = e.x;
      this.y = e.y;

      this.emitDelta(this.x);
      
    })
  }

  emitDelta(xPosition:number){

    if(this.posicionAnterior==null)  {
      this.posicionAnterior=xPosition;
    }

    let delta:number=(this.x-this.posicionAnterior)/this.windowsInnerWith*100;
    this.deltaX.emit(delta);


  }

}