import { KN, KANE, KNAI }   from '../typesKN/typesKN.model';
import { IMG }              from '../typesIMG/typesIMG.model';

export class DatosPersonales {
  apellido          : string;
  nombre            : string;
  apellidoNombre    : string;
  fechaNacimiento   : Object;
  genero            : string;
  fotoIMG           : IMG;
  idioma            : string;

  // constructor(){
  //   this.apellido=null;
  //   this.nombre=null;
  //   this.apellidoNombre=null;
  //   this.fechaNacimiento=null;
  //   this.genero=null;
  //   this.fotoIMG=null;
  //   this.idioma=null;
  // }
  constructor(init?:Partial<DatosPersonales>) {
    Object.assign(this, init);
   
  }
}