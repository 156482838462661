import { Component, ViewEncapsulation, Input, Output, OnInit, AfterContentChecked, DoCheck, AfterViewInit, AfterContentInit, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FuncionesService }  from '@maq-funciones';
import { ColumnaReporte} from '@settings/proyecto/models/reportes/columnasReporte';
@Component({
  selector: 'lib-shared-form-multiselect',
  templateUrl: './form-multiselect.component.html',
  styleUrls: ['./form-multiselect.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FormMultiSelectComponent implements OnInit, AfterContentChecked, DoCheck {

    @Input() public dropdownListOriginal: any[]; //se debe sacar mas adelante, cambia el nombre en la letra D mayuscula drop'D'own
    @Input() public dropDownListOriginal: ColumnaReporte[] = [];
    @Input() public nameField: string;
    @Input() public controlsField: any;
    @Input() public value: any; //se debe sacar mas adelante
    @Input() public columnasVisiblesMultiSelect: ColumnaReporte[];
    @Input() public formInvalid: boolean;
    @Input() public accionForm: string;

    @Output() public cargoSubEstructura= new EventEmitter<any>();

    public disabledDropDown:boolean=false;
    public finalizoCarga:boolean=true;

    public dropDownList:any[] = [];
    public dropDownSettings:any = {};    
    public traducciones:object={
        "multiSelectTexts.allSelected"            : "Seleccionar todo",
        "multiSelectTexts.checkAll"               : "Seleccionar todo",
        "multiSelectTexts.checked"                : "Item seleccionado",
        "multiSelectTexts.checkedPlural"          : "ítems seleccionados",
        "multiSelectTexts.defaultTitle"           : "Seleccione ítem",
        "multiSelectTexts.noEncontroResultados"   : "No se encontraron resultados",
        "multiSelectTexts.searchPlaceholder"      : "Buscar ítem...",
        "multiSelectTexts.uncheckAll"             : "Cancelar Selección",
        "navegabilidad.agregar"                   : "Agregar",
        "navegabilidad.buscar"                    : "Buscar"
    };

    public variblesATraducir:string[]=[
                         'multiSelectTexts.allSelected',
                         'multiSelectTexts.checkAll',
                         'multiSelectTexts.checked',
                         'multiSelectTexts.checkedPlural',
                         'multiSelectTexts.defaultTitle',
                         'multiSelectTexts.noEncontroResultados',
                         'multiSelectTexts.searchPlaceholder', 
                         'multiSelectTexts.uncheckAll',
                         'navegabilidad.agregar',
                         'navegabilidad.buscar'
                        ];

    constructor(private translate: TranslateService,
                public fn: FuncionesService,
                private changeDetectorRef: ChangeDetectorRef)  { 
                  
      this.inicializarVariablesTraducibles();
    }

    ngAfterContentChecked() { 
        if (!this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        }                
        
    }
      
    inicializarVariablesTraducibles()  {
      this.translate.get(this.variblesATraducir).subscribe((traducciones) => {
           this.traducciones=traducciones;
           this.cargaVariablesTraducibles();
      });
    
      this.translate.onLangChange.subscribe(() => {
          this.translate.get(this.variblesATraducir).subscribe((traducciones) => {
             this.traducciones=traducciones;
             this.cargaVariablesTraducibles();
          });
      });
    }
    
    cargaVariablesTraducibles(){
      this.setearPlugin();
      this.setearDropdownList();
    }
    
    ngDoCheck() {
        if(this.accionForm=='consultar') {
            this.disabledDropDown=true;
            this.setearPlugin();
          
        } else {          
            if(this.controlsField['status']!='DISABLED') {        
                this.disabledDropDown=false;
            } else {
                this.disabledDropDown=true;
            } 
            this.setearPlugin();
        }
    }
    
    
    ngOnInit() {
      this.setearPlugin();
      
      this.setearDropdownList();
      
    }
    
    setearDropdownList() {
      
        this.dropDownList=[];
        if (this.dropdownListOriginal){
            if(Array.isArray( this.dropdownListOriginal )) {
                // if (this.dropdownListOriginal instanceof ColumnaReporte)
                for(let i=0; i<this.dropdownListOriginal.length; i++) {            
                    let obj={};
                    for(let key in this.dropdownListOriginal[i]) {
                        if(key!='settings' && key!='organizacionKNAI' && key!='distribuidorKN') {
                            obj[key] = this.dropdownListOriginal[i][key];
                        }
                        if(key=='nombre') {
                            obj[key] = this.translate.instant( obj[key] );      
                        }
                    }
                    this.dropDownList.push(obj);                
                }  
            }
        }
        else{
            let colReport:ColumnaReporte = null;
            let traduccion:string = '';
            for (let index = 0; index < this.dropDownListOriginal.length; index++) {
                let obj:object={};
                colReport =  new ColumnaReporte(this.dropDownListOriginal[index]);
                traduccion = this.translate.instant(colReport.getNombreTraduccion());
                obj['key'] = colReport.getKey();
                obj['nombreTraduccion'] = traduccion;
                obj['formato'] = colReport.getFormato();
                obj['filtro'] = colReport.getFiltro();
                obj['nombreFiltro'] = colReport.getNombreFiltro();
                obj['arraySelect'] = colReport.getArraySelect();
                obj['nombreSelect'] = colReport.getNombreSelect();
                obj['valueSelect'] = colReport.getValueSelect();
                this.dropDownList.push(obj);
            }
        }      
    }
    
    setearPlugin() {
        if (this.dropdownListOriginal){
            this.dropDownSettings = { 
                singleSelection       : false,
                enableSearchFilter    : true,
                addNewItemOnFilter    : true,
                escapeToClose         : true,
                disabled              : this.disabledDropDown,
                classes               : 'myclass custom-class-example',
                primaryKey            : 'key',
                labelKey              : 'nombre',
                text                  : this.traducciones['multiSelectTexts.searchPlaceholder'],
                selectAllText         : this.traducciones['multiSelectTexts.checkAll'],
                unSelectAllText       : this.traducciones['multiSelectTexts.uncheckAll'],
                noDataLabel           : this.traducciones['multiSelectTexts.noEncontroResultados'],
                searchPlaceholderText : this.traducciones['navegabilidad.buscar'],
                addNewButtonText      : this.traducciones['navegabilidad.agregar']
            };
        }
        else{
            this.dropDownSettings = { 
                singleSelection       : false,
                enableSearchFilter    : true,
                addNewItemOnFilter    : true,
                escapeToClose         : true,
                disabled              : this.disabledDropDown,
                classes               : 'myclass custom-class-example',
                primaryKey            : 'key',
                labelKey              : 'nombreTraduccion',
                text                  : this.traducciones['multiSelectTexts.searchPlaceholder'],
                selectAllText         : this.traducciones['multiSelectTexts.checkAll'],
                unSelectAllText       : this.traducciones['multiSelectTexts.uncheckAll'],
                noDataLabel           : this.traducciones['multiSelectTexts.noEncontroResultados'],
                searchPlaceholderText : this.traducciones['navegabilidad.buscar'],
                addNewButtonText      : this.traducciones['navegabilidad.agregar']
            };
        }
           
        
    }
    
    onItemSelect(item:any){
        if (this.columnasVisiblesMultiSelect){
            this.addItemInArrayMultiSelect(item);
            this.emitArrayMultiSelect();  
        }
        else{
            this.emitValue();
        }     
    }

    addItemInArrayMultiSelect(item:any){
        let boolReglaEncontrada = false;
        for (let index = 0; index < this.columnasVisiblesMultiSelect.length; index++) {
            let columnaReporte: ColumnaReporte = new ColumnaReporte(this.columnasVisiblesMultiSelect[index]);
            if (columnaReporte.getKey()==item.key) {
                boolReglaEncontrada=true;
                break;
            }
        }
        if (!boolReglaEncontrada) this.columnasVisiblesMultiSelect.push(new ColumnaReporte(item));

    }
    
    OnItemDeSelect(item:any){
        if (this.columnasVisiblesMultiSelect){
            this.removeItemInArrayMultiSelect(item);
            this.emitArrayMultiSelect();  
        }
        else{
            this.emitValue();
        }
    }

    removeItemInArrayMultiSelect(item:any){
        this.columnasVisiblesMultiSelect=this.columnasVisiblesMultiSelect.filter(colReport => new ColumnaReporte(colReport).getKey()!=item.key);
    }
    
    onSelectAll(items: any){
        if (this.columnasVisiblesMultiSelect){
            this.columnasVisiblesMultiSelect=[...this.dropDownListOriginal];
            this.emitArrayMultiSelect(); 
        }
        else{
            this.emitValue();
        }
    }
    
    onDeSelectAll(items: any){
        if (this.columnasVisiblesMultiSelect){
            this.columnasVisiblesMultiSelect=[];
            this.emitArrayMultiSelect();
        }
        else{
            this.emitValue();
        }
    }    
    
    emitValue() {
        if (this.value){
            let enviarValores=[];
            if(Array.isArray( this.value )) {
                for(let i=0; i<this.value.length; i++) {            
                    let obj={};
                    for(let key in this.value[i]) {
                        if(key!='settings' && key!='organizacionKNAI' && key!='distribuidorKN') {
                            obj[key] = this.value[i][key];
                        }
                        if(key=='nombre') {
                            obj[key] = this.translate.instant( obj[key] );      
                        }
                    }
                    enviarValores.push(obj);                
                }  
            }
            this.cargoSubEstructura.emit({
                nameField : this.nameField,
                value    :  enviarValores
            });  
        }
    }

    emitArrayMultiSelect(){
        this.cargoSubEstructura.emit({
            valoresSeleccionados    :  this.columnasVisiblesMultiSelect
        });
    }

}
