export class GraphicPoint {
    private axisX: string = '';
    private axisY1: number = 0;
    private axisY2: number = 0;
    private axisY3: number = 0;
    /*los valores de los ejes X 4 hasta 7 son optativos y no todos los graficos tienen estos valores */
    private axisY4: number | null = null;
    private axisY5: number | null = null;
    private axisY6: number | null = null;
    private axisY7: number | null = null;

    private axisYLabel: string = '';

    constructor(init?:Partial<GraphicPoint>) {
      Object.assign(this, init);
    }

    reset(){
      this.axisX = '';
      this.axisY1 = 0;
      this.axisY2 = 0;
      this.axisY3 = 0;
      this.axisY4 = null;
      this.axisY5 = null;
      this.axisY6 = null;
      this.axisY7 = null;
      this.axisYLabel = '';
    }

    sumValueInAxisY1(axisY:number){
      this.axisY1 += axisY;
    }

    sumValueInAxisY2(axisY:number){
      this.axisY2 += axisY;
    }

    sumValueInAxisY3(axisY:number){
      this.axisY3 += axisY;
    }

    sumValueInAxisY4(axisY:number){
      this.axisY4 += axisY;
    }

    sumValueInAxisY5(axisY:number){
      this.axisY5 += axisY;
    }

    sumValueInAxisY6(axisY:number){
      this.axisY6 += axisY;
    }

    sumValueInAxisY7(axisY:number){
      this.axisY7 += axisY;
    }
  
    getAxisX():string{
      return this.axisX;
    }
  
    setAxisX(axisX:string){
      this.axisX = axisX;
    }

    getAxisY1():number{
      return this.axisY1;
    }
  
    setAxisY1(axisY:number){
      this.axisY1 = axisY;
    }
  
    getAxisY2():number{
      return this.axisY2;
    }
  
    setAxisY2(axisY:number){
      this.axisY2 = axisY;
    }
  
    getAxisY3():number{
      return this.axisY3;
    }
  
    setAxisY3(axisY:number){
      this.axisY3 = axisY;
    }
  
    getAxisY4():number | null {
      return this.axisY4;
    }
  
    setAxisY4(axisY:number | null){
      this.axisY4 = axisY;
    }
  
    getAxisY5():number | null{
      return this.axisY5;
    }
  
    setAxisY5(axisY:number | null){
      this.axisY5 = axisY;
    }
  
    getAxisY6():number | null{
      return this.axisY6;
    }
  
    setAxisY6(axisY:number | null){
      this.axisY6 = axisY;
    }
  
    getAxisY7():number | null{
      return this.axisY7;
    }
  
    setAxisY7(axisY:number | null){
      this.axisY7 = axisY;
    }

    getAxisYLabel():string{
      return this.axisYLabel;
    }
  
    setAxisYLabel(axisYLabel:string){
      this.axisYLabel = axisYLabel;
    }
  }