<div class="row p-0 m-0">
        <!-- <div class="col-6 p-1 border-bottom" [hidden]="!showContent">
                <button type="button"
                        class="p-0 btn btn-primary btn-outline-primary"
                        (click)="ocultarFiltroFechas()"
                        [attr.aria-expanded]="!isCollapsedFiltroFechas"
                        aria-controls="collapseExample"
                        title="{{traduccionshowContentFiltroFecha | translate}}">
                        <i class="mdi mr-1 text-white" [ngClass]="isCollapsedFiltroFechas ? 'mdi-arrow-collapse-down' : 'mdi-arrow-collapse-up'"></i>
                </button>
        </div>

        <div  [ngClass]="showContent ? 'col-6' : 'col-12'" class="p-1 border-bottom d-flex justify-content-end">
                <button id="toggleButton" class="btn btn-secondary p-0" (click)="ocultarMostrar()" title="{{traduccionshowContent | translate}}">
                        <ng-container *ngIf="showContent; else showContentResume">
                                <i class="mdi mdi-arrow-collapse-horizontal mr-1 text-white"></i>
                        </ng-container>
                        <ng-template #showContentResume>
                                <i class="mdi mdi-arrow-split-vertical mr-1 text-white"></i>
                        </ng-template>
                </button>
        </div> -->
        
        <!-- <div class="col-12 p-1" [hidden]="showContent">
                <h6>{{'agrupadorPeriodos.filtroDeFechas' | translate}}:</h6>

                <div class="row pl-3 pt-1 pb-1">
                        <div class="col-12 p-0">
                                <button class="btn btn-primary" [disabled]="!showContent">{{traduccionRangeSeleccionado | translate}}</button>
                        </div>
                </div>
        </div> -->

        <!-- [(ngModel)]="agrupadorDeFecha"	                                          -->
        <!-- <div class="col-12 p-1 border-top"  [hidden]="showContent">
                <h6>{{'agrupadorPeriodos.agrupadorDeFechas' | translate}}:</h6>
                <div class="bg-white text-center">
                        <select class="form-control selectpicker" 
                                id="grilla.paginadoCantidad"
                                [(ngModel)]="agrupadorDeFecha"
                                (change)="onChangeAgrupadorFechas($event)"
                                [disabled]="!showContent"> 
                        <ng-container *ngFor="let opcion of agrupadoresDeFechas">
                                <option [ngValue]="opcion">{{opcion.nombre | translate}}</option>
                        </ng-container>
                        </select>
                </div> 
        </div> -->

        <!-- #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsedFiltroFechas" [horizontal]="true"  -->
        <div [ngClass]="showMenu ? 'col-3' : 'col-4'" class="m-0 p-1" *ngIf="showContent" style="max-width: 300px">

                <div class="row m-0 p-1 border rounded">

                

                        <h5>{{'agrupadorPeriodos.filtroDeFechas' | translate}}:</h5>

                        <div class="row m-0 border-top pt-1">
                                <div class="col-12 pr-0">
                                        <button class="btn p-0 btn-filtro-fechas"
                                                [class.btn-primary]="selectedDateRange === 'Personalizado'"
                                                (click)="selectDateRange('Personalizado')" translate>agrupadorPeriodos.personalizado
                                        </button>
                                </div>

                                <div class="col-12 pr-0">
                                        <button class="btn p-0 btn-filtro-fechas"
                                                [class.btn-primary]="selectedDateRange === 'Ayer'"
                                                (click)="selectDateRange('Ayer')" translate>agrupadorPeriodos.ayer
                                        </button>
                                </div>
                                <div class="col-12 pr-0">
                                        <button class="btn p-0 btn-filtro-fechas"
                                                [class.btn-primary]="selectedDateRange === 'MesActual'"
                                                (click)="selectDateRange('MesActual')" translate>agrupadorPeriodos.mesActual
                                        </button>
                                </div>
                                <div class="col-12 pr-0">
                                        <button class="btn p-0 btn-filtro-fechas"
                                                [class.btn-primary]="selectedDateRange === 'MesPasado'"
                                                (click)="selectDateRange('MesPasado')" translate>agrupadorPeriodos.mesPasado
                                        </button>
                                </div>
                                <div class="col-12 pr-0">
                                        <button class="btn p-0 btn-filtro-fechas"
                                                [class.btn-primary]="selectedDateRange === 'Ultimos7Dias'"
                                                (click)="selectDateRange('Ultimos7Dias')" translate>agrupadorPeriodos.ultimos7Dias
                                        </button>
                                </div>
                                <div class="col-12 pr-0">
                                        <button class="btn p-0 btn-filtro-fechas"
                                                [class.btn-primary]="selectedDateRange === 'Ultimos10Dias'"
                                                (click)="selectDateRange('Ultimos10Dias')" translate>agrupadorPeriodos.ultimos10Dias
                                        </button>
                                </div>
                                <div class="col-12 pr-0">
                                        <button class="btn p-0 btn-filtro-fechas"
                                                [class.btn-primary]="selectedDateRange === 'Ultimos15Dias'"
                                                (click)="selectDateRange('Ultimos15Dias')" translate>agrupadorPeriodos.ultimos15Dias
                                        </button>
                                </div>
                                <div class="col-12 pr-0">
                                        <button class="btn p-0 btn-filtro-fechas"
                                                [class.btn-primary]="selectedDateRange === 'Ultimos30Dias'"
                                                (click)="selectDateRange('Ultimos30Dias')" translate>agrupadorPeriodos.ultimos30Dias
                                        </button>
                                </div>
                                <div class="col-12 pr-0">
                                        <button class="btn p-0 btn-filtro-fechas"
                                                [class.btn-primary]="selectedDateRange === 'Ultimos90Dias'"
                                                (click)="selectDateRange('Ultimos90Dias')" translate>agrupadorPeriodos.ultimos90Dias
                                        </button>
                                </div>
                                <div class="col-12 pr-0">
                                        <button class="btn p-0 btn-filtro-fechas"
                                                [class.btn-primary]="selectedDateRange === 'Ultimos12Meses'"
                                                (click)="selectDateRange('Ultimos12Meses')" translate>agrupadorPeriodos.ultimos12Meses
                                        </button>
                                </div>
                                <div class="col-12 pr-0">
                                        <button class="btn p-0 btn-filtro-fechas"
                                                [class.btn-primary]="selectedDateRange === 'EsteAnio'"
                                                (click)="selectDateRange('EsteAnio')" translate>agrupadorPeriodos.esteAnio
                                        </button>
                                </div>
                                <div class="col-12 pr-0">
                                        <button class="btn p-0 btn-filtro-fechas"
                                                [class.btn-primary]="selectedDateRange === 'ElAnioPasado'"
                                                (click)="selectDateRange('ElAnioPasado')" translate>agrupadorPeriodos.elAnioPasado
                                        </button>
                                </div>
                                <div class="col-12 border-top">
                                        <button title="{{'agrupadorPeriodos.limpiarFecha' | translate}}"
                                                class="btn btn-link btn-lg p-0"
                                                (click)="limpiarValor()">
                                                <i class="fa fa-eraser mr-1 text-secondary"></i>{{'agrupadorPeriodos.limpiarFecha' | translate}}
                                        </button>
                                </div>        
                        </div>
                </div>
        </div>
        <div [ngClass]="showMenu ? 'col-5' : 'col-4'" class="m-0 p-1 text-center" [hidden]="!showContent">

                <!-- <div class="col-12 border rounded mb-1 p-2" *ngIf="isCollapsedFiltroFechas">
                        <h5>{{'agrupadorPeriodos.filtroDeFechas' | translate}}:</h5>
                        <div class="row m-0 pl-0 pt-1 pb-1">
                                <div class="col-12 p-0">
                                        <button class="btn btn-primary" [disabled]="true">{{traduccionRangeSeleccionado | translate}}</button>
                                </div>
                        </div>
                </div> -->

                <ngb-datepicker #dp (dateSelect)="onDateSelection($event)"
                                [displayMonths]="1" 
                                [dayTemplate]="t" 
                                outsideDays="hidden"
                                [maxDate]="maxDate">
                </ngb-datepicker>

                <ng-template #t let-date let-focused="focused">
                        <span class="custom-day"
                                [class.focused]="focused"
                                [class.range]="isRange(date)"
                                [class.faded]="isHovered(date) || isInside(date)"
                                [class.custom-day-disabled]="isDateDisabled(date)"
                                (mouseenter)="hoveredDate = date"
                                (mouseleave)="hoveredDate = null">
                                {{date.day}}
                        </span>
                </ng-template>
        </div>
        <div class="col-4 m-0 p-1" [hidden]="!showContent">
                <div class="col-12 border border-1 rounded" style="padding:10px;">
                        <h5>{{'agrupadorPeriodos.agrupadorDeFechas' | translate}}:</h5>
                        <div class="bg-white text-center">
                                <select class="form-control selectpicker" 
                                        id="grilla.paginadoCantidad"
                                        [(ngModel)]="agrupadorDeFecha"                                         
                                        (change)="onChangeAgrupadorFechas()"> 
                                <ng-container *ngFor="let opcion of agrupadoresDeFechas">
                                        <option [ngValue]="opcion.key">{{opcion.nombre | translate}}</option>
                                </ng-container>
                                </select>
                        </div>    
                </div>

                <div class="col-12 border border-1 rounded mt-1" style="padding:10px;">
                        <h5>{{'agrupadorPeriodos.tipoDeGrafico' | translate}}:</h5>
                        <div class="bg-white text-center">
                                <select class="form-control selectpicker" 
                                        id="grilla.paginadoCantidad"
                                        [(ngModel)]="typeChartDefault"
                                        (change)="onChangeTypeChart()"> 
                                <ng-container *ngFor="let opcion of arrayTypeCharts">
                                        <option [ngValue]="opcion.key">{{opcion.nombre | translate}}</option>
                                </ng-container>
                                </select>
                        </div>    
                </div>

                <div *ngIf="typeChartDefault =='Agrupado por Chofer' || typeChartDefault == 'Agrupado por Ubicacion'" class="col-12 border border-1 rounded mt-1" style="padding:10px;">
                        <h5>{{'moduloDesarrolladores.orden' | translate}}:</h5>
                        <div class="bg-white text-center">
                                <select class="form-control selectpicker" 
                                        id="grilla.paginadoCantidad"
                                        [(ngModel)]="typeOrderInCharts"
                                        (change)="onChangeTypeOrderChart()"> 
                                <ng-container *ngFor="let opcion of arrayTypeOrderCharts">
                                        <option [ngValue]="opcion.key">{{opcion.nombre | translate}}</option>
                                </ng-container>
                                </select>
                        </div>    
                </div>

        </div>
</div>