<div class="row">
    <div *ngFor="let indicadorPorcentaje of listadoIndicadorPorcentajes; let index = index" [ngClass]="index < 4 ? 'col-3' : 'col-4'">
        <h4 class="text-center font-weight-bold">{{indicadorPorcentaje.getTitulo() | translate}}</h4>
        <lib-shared-indicador-porcentaje 
            [titulo]=""
            [porcentaje]="indicadorPorcentaje.getPorcentaje()"
            [color]="indicadorPorcentaje.getColor()"
            [bgColor]="indicadorPorcentaje.getBackgoundColor()">
        </lib-shared-indicador-porcentaje>
    </div>
</div>
