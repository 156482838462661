import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';
import { FuncionesService }  from '@maq-funciones';

@Pipe({
  name: 'hora'
})

export class HoraPipe implements PipeTransform {
    constructor(public fn:FuncionesService) { 
    }   
    transform(
        value: any,
        formato: string,
    ): string | null {
        //console.log("horaPipe",value,formato);
        let rta='';
        if(value==null || value===undefined) {
            //console.log("horaPipe Null");
            rta='';
        } else if(formato=='HHMMSS' && value.hour!==null && value.minute!==null && value.second!==null) {
              rta=this.fn.str0(value.hour.toString(),2)+':'+this.fn.str0(value.minute.toString(),2)+':'+this.fn.str0(value.second.toString(),2)+" hs";
        } else if(formato=='HHMM' && value.hour!==null && value.minute!==null) {
              rta=this.fn.str0(value.hour.toString(),2)+':'+this.fn.str0(value.minute.toString(),2)+" hs";
        } else {
              console.error("horaPipe {} !!!", value);
              rta='';
        }
        return rta;
    }
}