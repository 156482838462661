import { GeoPoint }       from '../geopoint/geopoint.model';
import { KN, KANE, KNAI } from '../typesKN/typesKN.model';

export class Direccion {  
  calle: string;
  numero: string;  
  bloque: string;  
  piso: string;  
  departamento: string;  
  codigoPostal: string;  
  ciudad: string;  
  partido: string;  
  provinciaKN: KN;  
  paisKN: KN; 
  geoPoint:GeoPoint;
  timeZone:string;
  idiomaPais:string;

  // constructor(init?:Partial<Direccion>) {    
  //   Object.assign(this, init);    
  // }
  
 constructor() {
    this.calle= null;
    this.numero= null; 
    this.bloque= null;
    this.piso= null;
    this.departamento= null;
    this.codigoPostal= null;
    this.ciudad= null;  
    this.partido= null;  
    this.provinciaKN= null;  
    this.paisKN= null; 
    this.geoPoint=new GeoPoint(null,null);
    this.timeZone = null;
    this.idiomaPais = null;
  }

  constructor2(init?:Partial<Direccion>):Direccion{
    Object.assign(this, init);
    if(this.geoPoint) this.geoPoint = new GeoPoint(this.geoPoint.latitud, this.geoPoint.longitud);
    return this;
  }

  getJson():object{
    return {
      calle: this.calle,
      numero: this.numero,
      bloque: this.bloque,
      piso: this.piso,
      departamento: this.departamento,
      codigoPostal: this.codigoPostal,
      ciudad: this.ciudad,
      partido: this.partido,
      geoPoint: this.geoPoint?.getJson() == undefined ? null : this.geoPoint?.getJson(),
      timeZone: this.timeZone,
      idiomaPais: this.idiomaPais
    }
  }
}
