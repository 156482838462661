import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Routes, RouterModule, PreloadAllModules  } from '@angular/router';

/* Librerias Externas */
import { TranslateModule }                from '@ngx-translate/core';

/* Modulos Internos */
import { SharedModule }                   from '@maq-shared/shared.module';

/* Scroll Menú */
import { PerfectScrollbarModule }    from 'ngx-perfect-scrollbar';

/* Componentes */
import { PrincipalComponent }             from './componentes/principal/principal.component';
import { FooterComponent }                from './componentes/footer/footer.component';
import { HeaderComponent }                from './componentes/header/header.component';
import { NotFoundComponent }               from './componentes/errors/not-found/not-found.component';
import { EnConstruccionComponent }        from './componentes/errors/en-construccion/en-construccion.component';
import { SidebarComponent }               from './componentes/sidebar/sidebar.component';
import { HorizontalMenuComponent }        from './componentes/menu/horizontal-menu/horizontal-menu.component';
import { VerticalMenuComponent }          from './componentes/menu/vertical-menu/vertical-menu.component';
import { UsuarioNoAutorizadoComponent }   from './componentes/usuario-no-autorizado/usuario-no-autorizado.page';
import { UserMenuComponent }              from './componentes/user-menu/user-menu.component'
// const routes: Routes = [
//   { path: '', redirectTo: 'login', pathMatch: 'full'},
//   { path: 'user-info',              component: UserInfoComponent,    data: { breadcrumb: 'Información del usuario' } },
//   { path: 'login',                  component: LoginComponent,       pathMatch: 'full' },
//   { path: 'registrarse',            component: RegisterComponent,    pathMatch: 'full' },
//   { path: 'registrarse/:userKey',   component: RegisterComponent,    pathMatch: 'full' },
//   { path: 'cambioClave',            component: CambioClaveComponent, pathMatch: 'full' },

// ];


@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    PrincipalComponent,
    NotFoundComponent,
    EnConstruccionComponent,
    SidebarComponent,
    VerticalMenuComponent,
    HorizontalMenuComponent,      
    UsuarioNoAutorizadoComponent,
    UserMenuComponent 
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    PerfectScrollbarModule,
    TranslateModule,
    PerfectScrollbarModule
  ],   
  exports: [
  

  ],
  providers: [ 

    
  ],

})
export class PanelModule { }
