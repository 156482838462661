import { log, logIf, logTable, values } from '@maq-console';
import { Component, OnInit, ViewEncapsulation,ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BDService        } from '@maq-bd/bd.service';
import { MensajesService  } from '@maq-servicios/mensajes/mensajes.service';
import { FuncionesService }         from '@maq-servicios/funciones/funciones.service';
import {  take     }                from 'rxjs/operators';
// Models
import { Usuario }                  from '@maq-models/usuarios/usuarios.model';
import { KN, KANE, KNAI }           from '@maq-models/typesKN/typesKN.model';

import { environment }            from '@environments/environment';
import { AppSettingsService }       from '@settings/app.settings';
import { AppSettings2 }             from '@maq-models/appSettings/appSettings.model';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { NgxSpinnerService }        from "ngx-spinner";
// Toast
import { ToastrService }            from 'ngx-toastr';

@Component({
  selector: 'lib-shared-cotizaciones',
  templateUrl: './cotizaciones.component.html',
  styleUrls: ['./cotizaciones.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CotizacionesComponent implements OnInit {
   languageList = [ // <--- add this
    { code: 'en', label: 'English' },
    { code: 'ru', label: 'Русский' },
    { code: 'es', label: 'Spanish' },
    { code: 'br', label: 'Portugues' }
  ];	
  claseIconoHeader="flag-icon flag-icon-es";
  public traduccion:any={};

  
  // Colecciones Secundarias
  public subscripcionCache:any[]=[];
  public configListadosCache:any[]=[];
  public cantidadSubscripcionesSecundariasLanzadas:number=0;
  public finalizoGETSecundarias:boolean=false;
  // Variables Globales Usario Logeado y Organización asociada
  public usuario              : Usuario;
  public usuarioKANE          : KANE=null;
  public tipoPerfilUsuario    : string=null;
  public organizacionKNAI     : KNAI=null;
  public distribuidorKN       : KN=null;
  public datosDisponibles     : boolean=false;

  public cotizacion           : any=null;
  public monedaSeleccionada   : string='USD';
  public cotizacionMercado    : any={
            dolarCompra       : null,
            dolarVenta        : null,
            dolarDivisaCompra : null,
            dolarDivisaVenta  : null,
            dolarBlueCompra   : null,
            dolarBlueVenta    : null,
  };

  constructor(  public translate           : TranslateService,
                public bdService            : BDService,
                protected changeDetectorRef : ChangeDetectorRef,
                public appSettings          : AppSettingsService,
                public msg                  : MensajesService,
                public http                 : HttpClient,
                public toastrService        : ToastrService,
                public spinner              : NgxSpinnerService,
                public fn                   : FuncionesService) { }
                

  ngOnInit() {

    if(!this.appSettings.settings2.panel.funcionalidadesDisponibles.cotizaciones){
      console.log('no usa Cotizacion');
      return;
    }

    this.inicializarVariablesTraducibles();

    this.translate.onLangChange.subscribe((data) => { 
      this.inicializarVariablesTraducibles();
    });

    this.msg.getPerfil().subscribe(usuario=>{
        this.usuario=usuario;
        this.usuarioKANE              = this.fn.setearKANE(this.usuario);
        this.tipoPerfilUsuario        = this.usuario.perfilUsuario.tipo;
        this.organizacionKNAI         = this.fn.setearOrganizacionKNAI(this.usuario.organizacion);
        this.distribuidorKN           = this.fn.setearKN(this.usuario.distribuidor);
        

    this.inicializoCotizaciones();    
    this.actualizarDolarBCRA();    
    this.actualizarDolarSi();
    
    setInterval(() => {
      
        this.actualizarDolarSi();
        
        this.actualizarDolarBCRA();
        
         // actualizo cada media hora 30*60*1000 
         }, 1800000); // 1000->set it every one seconds}
        
    }); 
    
  }

  inicializarVariablesTraducibles(){
        
    this.traduccion['idioma']={
      espanol     : this.translate.instant('idioma.espanol'),
      portugues   : this.translate.instant('idioma.portugues'),
      ingles      : this.translate.instant('idioma.ingles'),
           
       
    }
    // console.log('cambio idioma en libreria B, variable Traducibles', this.traduccion);
  }

  inicializoCotizaciones(){
      
    this.configListadosCache.push({ 
          nombreListado   : 'listadoCotizacionesMonedasDolares',
          nombreColeccion : 'CotizacionesMonedas',
          orderBy         : [{key:'fechaHora',ascDesc:'desc'}],  
          where           : [
            {key:'moneda1KMONEY.key',operador:'==',value:'Dolares'},
            {key:'moneda2KMONEY.key',operador:'==',value:'Pesos'}
          ],
          limit           : 1,
          grabaLocalStorage:false        
    });

    this.configListadosCache.push({ 
          nombreListado   : 'listadoCotizacionesMonedasDolaresDivisa',
          nombreColeccion : 'CotizacionesMonedas',
          orderBy         : [{key:'fechaHora',ascDesc:'desc'}],  
          where           : [
            {key:'moneda1KMONEY.key',operador:'==',value:'DolaresDivisa'},
            {key:'moneda2KMONEY.key',operador:'==',value:'Pesos'}
          ],
          limit           : 1,
          grabaLocalStorage:false        
    });
    
    this.configListadosCache.push({ 
        nombreListado   : 'listadoCotizacionesMonedasDolaresBlue',
        nombreColeccion : 'CotizacionesMonedas',
        orderBy         : [{key:'fechaHora',ascDesc:'desc'}],  
        where           : [
          {key:'moneda1KMONEY.key',operador:'==',value:'DolaresBlue'},
          {key:'moneda2KMONEY.key',operador:'==',value:'Pesos'}
        ],
        limit           : 1,
        grabaLocalStorage:false        
    });
    
    this.cantidadSubscripcionesSecundariasLanzadas=this.configListadosCache.length;
    
    for(let i=0; i<this.configListadosCache.length; i++) {
          
      this.subscripcionCache.push( 
            this.msg.getListadoCache({
                nombreListado              : this.configListadosCache[i].nombreListado,
                nombreColeccion            : this.configListadosCache[i].nombreColeccion,
                where                      : this.configListadosCache[i].where  !== undefined               ? this.configListadosCache[i].where  :[],
                orderBy                    : this.configListadosCache[i].orderBy !== undefined              ? this.configListadosCache[i].orderBy:[],
                limit                      : this.configListadosCache[i].limit !== undefined                ? this.configListadosCache[i].limit  :null,
                grabaLocalStorage          : this.configListadosCache[i].grabaLocalStorage!== undefined     ? this.configListadosCache[i].grabaLocalStorage:true,   
                ignoraValoresMemoria       : this.configListadosCache[i].ignoraValoresMemoria!== undefined  ? this.configListadosCache[i].ignoraValoresMemoria:false,   
                datosPorOrganizacion       : this.configListadosCache[i].datosPorOrganizacion!== undefined  ? this.configListadosCache[i].datosPorOrganizacion:false,   
                organizacionKNAI           : this.organizacionKNAI,                           
                usuarioKANE                : this.usuarioKANE,
                nombreColeccionSolicitante : 'cotizacionesComponente', 
                limpiaSettingUsuarioOrganizacion:true      
            }).subscribe(data=>{
                
                let nombreListado =this.configListadosCache[i].nombreListado.replace('listado','').replace('Listado','')

                log(...values("valores",
                          "msg.cacheColecciones[" + nombreListado + "]:", 
                          this.msg.cacheColecciones[nombreListado]));
                
                this.cantidadSubscripcionesSecundariasLanzadas--;
                
                if(this.monedaSeleccionada=='USD' && this.msg.cacheColecciones['CotizacionesMonedasDolares'] && this.msg.cacheColecciones['CotizacionesMonedasDolares'][0]){
                  this.cotizacion=this.msg.cacheColecciones['CotizacionesMonedasDolares'][0];       
                }else if(this.monedaSeleccionada=='Divisa' && this.msg.cacheColecciones['CotizacionesMonedasDolares'] && this.msg.cacheColecciones['CotizacionesMonedasDolaresDivisa'][0]){
                  this.cotizacion=this.msg.cacheColecciones['CotizacionesMonedasDolaresDivisa'][0];       
                }else{
                  this.cotizacion=null;
                }
                
                
            
                if(this.cantidadSubscripcionesSecundariasLanzadas==0) {  // Devolvió resultados la última subscripción
               
                      this.finalizoGETSecundarias=true;
                      
                      this.datosDisponibles=true;  
                      if (!this.changeDetectorRef['destroyed']) {
                        this.changeDetectorRef.detectChanges();
                      }   
                     
                }

            },(error:any)=>{
  
              this.datosDisponibles=false;    
            }) 
        );    
    }
  }
  
  
  


  actualizarCotizacionesOnLine(){
    //  let url = 'https://api.estadisticasbcra.com/usd';
    //  let url = 'https://api.estadisticasbcra.com/reservas';
     let url = 'https://www.dolarsi.com/api/api.php?type=valoresprincipales';
    //  https://www.bna.com.ar/Personas
    // https://www.bna.com.ar/Cotizador/MonedasHistorico
     
     let  headers= new HttpHeaders({    
             'Content-Type': 'application/json', 
             'Access-Control-Allow-Origin': '*',  
             'Authorization': 'bearear eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2MzMwMzY0MDksInR5cGUiOiJleHRlcm5hbCIsInVzZXIiOiJwZXJlei5qdWFuLmpvc2VAZ21haWwuY29tIn0.Bjsi7wZH29rVYWH8sPOHeSrTyALZnuFoTBi_b3GJYmK5Mf4nBl8yJQM-pZJdRD73vms97G0UJGzu0oJ-zF73Jw'
         })
   
     const  tocken='eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2MzMwMzY0MDksInR5cGUiOiJleHRlcm5hbCIsInVzZXIiOiJwZXJlei5qdWFuLmpvc2VAZ21haWwuY29tIn0.Bjsi7wZH29rVYWH8sPOHeSrTyALZnuFoTBi_b3GJYmK5Mf4nBl8yJQM-pZJdRD73vms97G0UJGzu0oJ-zF73Jw'
     const bearear='eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2MzMwMzY0MDksInR5cGUiOiJleHRlcm5hbCIsInVzZXIiOiJwZXJlei5qdWFuLmpvc2VAZ21haWwuY29tIn0.Bjsi7wZH29rVYWH8sPOHeSrTyALZnuFoTBi_b3GJYmK5Mf4nBl8yJQM-pZJdRD73vms97G0UJGzu0oJ-zF73Jw';
     
    //  httpOptions.headers.append("Authorization", 'BEARER eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2MzMwMzY0MDksInR5cGUiOiJleHRlcm5hbCIsInVzZXIiOiJwZXJlei5qdWFuLmpvc2VAZ21haWwuY29tIn0.Bjsi7wZH29rVYWH8sPOHeSrTyALZnuFoTBi_b3GJYmK5Mf4nBl8yJQM-pZJdRD73vms97G0UJGzu0oJ-zF73Jw');
    //  console.log(' - recibió respuesta de Cloud Function subscripGet:');
    //  let subscripGet=this.http.get(url, {headers:headers})
     let subscripGet=this.http.get(url)
       .subscribe((jsonResponse:any) => {
          //  console.log(' - recibió respuesta de Cloud Function subscripGet:',jsonResponse);
           subscripGet.unsubscribe();
        //    if(jsonResponse){
        //        for(let key in jsonResponse){
        //            console.log('subscripGet',key,jsonResponse[key]);
        //            let cot=jsonResponse[key]['casa'];
        //            if(cot.nombre=='Dolar Oficial'){
        //                 console.log('subscripGet oficial',cot);

        //                 let documento={
        //                     cotizacion:cot.venta,
        //                     fechaHora:new Date(),
        //                     moneda1KMONEY:{codigoDivisa:'USD',key:'Dolares',nombre:'Dólares',simbolo:'U$sD'},
        //                     moneda2KMONEY:{codigoDivisa:'ARS',key:'Pesos',nombre:'Pesos',simbolo:'AR$'},
                            
        //                 }
        //                 this.bdService.updateColeccion({
        //                     operacion        : 'agregar',
        //                     nombreColeccion  : 'CotizacionesMonedas',
        //                     documento        : documento,
        //                     distribuidorKN   : this.distribuidorKN,
        //                     organizacionKNAI : this.organizacionKNAI,                           
        //                     usuarioKANE      : this.usuarioKANE
        //                 }).then(dato=>{
        //                     console.log('grabo ok',dato);
        //                 }).catch(error=>{
        //                     console.log('grabo error',error);
        //                 })
        //            }
        //        }
        //    }
        
           
       }, error => {
            // console.log('error al conectarse al Cloud Function subscripGet:',error);
            subscripGet.unsubscribe();
                          
       });        

    //    var url = "https://api.estadisticasbcra.com/usd";

       var xhr = new XMLHttpRequest();
       xhr.open("GET", url);
       
       xhr.setRequestHeader("Authorization", "Bearer eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2MzMwMzY0MDksInR5cGUiOiJleHRlcm5hbCIsInVzZXIiOiJwZXJlei5qdWFuLmpvc2VAZ21haWwuY29tIn0.Bjsi7wZH29rVYWH8sPOHeSrTyALZnuFoTBi_b3GJYmK5Mf4nBl8yJQM-pZJdRD73vms97G0UJGzu0oJ-zF73Jw");
       
       xhr.onreadystatechange = function () {
        // console.log('subscripGet xhr',xhr);
          if (xhr.readyState === 4) {
      
            //  console.log('subscripGet',xhr.status);
            //  console.log('subscripGet',xhr.responseText);
          }};
       
       xhr.send();
       

  }

  actualizarDolarSi(){
   
     let url = 'https://www.dolarsi.com/api/api.php?type=valoresprincipales';
   
    //  console.log(' - recibió respuesta de Cloud Function subscripGet:');
   
     let subscripGet=this.http.get(url)
       .subscribe((jsonResponse:any) => {
           console.log(' - DolarSi - recibió respuesta de Cloud Function subscripGet:',jsonResponse);
           subscripGet.unsubscribe();
           
           for(let i=0; i<jsonResponse.length; i++) {
             
               if(jsonResponse[i].casa.nombre=='Dolar Oficial' && this.appSettings.settings2.cotizaciones.dolarOficial.api=='DolarSi') {
                  this.cotizacionMercado.dolarCompra =  parseFloat(jsonResponse[i].casa.compra.replace(',','.'));
                  this.cotizacionMercado.dolarVenta  =  parseFloat(jsonResponse[i].casa.venta.replace(',','.'));
               }

               if(jsonResponse[i].casa.nombre=='Dolar Blue' && this.appSettings.settings2.cotizaciones.dolarBlue.api=='DolarSi') {
                this.cotizacionMercado.dolarBlueCompra =  parseFloat(jsonResponse[i].casa.compra.replace(',','.'));
                this.cotizacionMercado.dolarBlueVenta  =  parseFloat(jsonResponse[i].casa.venta.replace(',','.'));
               }
             
           }
           
           console.log("DolarSi this.cotizacionMercado",this.cotizacionMercado);
           
       }, error => {
            // console.log('- DolarSi - error al conectarse al Cloud Function subscripGet:',error);
            subscripGet.unsubscribe();
       });        

  }

  actualizarDolarBCRA(){
   
    //  let url = 'https://www.bna.com.ar/Cotizador/MonedasHistorico';
    //  let url = 'https://www.bna.com.ar/Personas';
    //  let url = 'https://www.google.com';
    
    let url = environment.cloudFunction.url+'getPage?url=https://www.bna.com.ar/Personas#divisas';
     
    let  headers= new HttpHeaders({    
      'Content-Type': 'application/json', 
      'Access-Control-Allow-Origin': '*',        
    })
    
    // console.log(' - recibió respuesta de Cloud bcra subscripGet: actualizarDolarBCRA');
    let subscripGetBcra=this.http.get(url,{headers: headers})
       .subscribe((jsonResponse:any) => {
          //  console.log(' - recibió respuesta de Cloud bcra subscripGet:',jsonResponse);
           let textoInicio=" <td class=\"tit\">Dolar U.S.A</td>\r\n                            <td>";
           let textoAdicional="</td>\r\n                            <td>";

           let n=jsonResponse.search(textoInicio)+textoInicio.length;
          //  console.log('bcra n',n);
           var dolarCompra = jsonResponse.substring(n, n+7).replace(',','.');
           console.log('bcra n dolarCompra',dolarCompra);
           let m=n+7+textoAdicional.length;
           var dolarVenta = jsonResponse.substring(m, m+7).replace(',','.');
          //  console.log('bcra n dolarVenta',dolarVenta);

           let n_div=jsonResponse.lastIndexOf(textoInicio)+textoInicio.length;
          //  console.log('bcra n_div',n_div);

           var dolarDivCompra = jsonResponse.substring(n_div, n_div+7).replace(',','.');
          //  console.log('bcra n dolarDivCompra',dolarDivCompra);
           let m_div=n_div+7+textoAdicional.length;
           var dolarDivVenta = jsonResponse.substring(m_div, m_div+7).replace(',','.');
          //  console.log('bcra n dolarVenta',dolarDivVenta);

          if(this.appSettings.settings2.cotizaciones.dolarOficial.api=='BancoNacion') {          
              this.cotizacionMercado.dolarCompra = parseFloat(dolarCompra);
              this.cotizacionMercado.dolarVenta  = parseFloat(dolarVenta);
          }

          if(this.appSettings.settings2.cotizaciones.dolarDivisa.api=='BancoNacion') {          
            this.cotizacionMercado.dolarDivisaCompra = parseFloat(dolarDivCompra);
            this.cotizacionMercado.dolarDivisaVenta  = parseFloat(dolarDivVenta);
          }
        
           subscripGetBcra.unsubscribe();
        }, error => {
            // console.log('error al conectarse al Cloud bcra subscripGet:',error);
            subscripGetBcra.unsubscribe();
       });        
  }


  definirDolarVigente(){
    // console.log('definirDolarVigente');

    let  documentoDolar={
      key:null,
      cotizacion:this.cotizacionMercado.dolarVenta,
      moneda1KMONEY:{
        codigoDivisa:'USD',
        key:'Dolares',
        nombre:'Dólares',
        simbolo:'U$s'
      },
      moneda2KMONEY:{
        codigoDivisa:'ARS',
        key:'Pesos',
        nombre:'Pesos',
        simbolo:'AR$'
      },
      fechaHora:this.bdService.timestamp()

    }

    let documentoDolarDivisa={
      key:null,
      cotizacion:this.cotizacionMercado.dolarDivisaVenta,
      moneda1KMONEY:{
        codigoDivisa:'USD',
        key:'DolaresDivisa',
        nombre:'Dólares Divisa',
        simbolo:'U$sD'
      },
      moneda2KMONEY:{
        codigoDivisa:'ARS',
        key:'Pesos',
        nombre:'Pesos',
        simbolo:'AR$'
      },
      fechaHora:this.bdService.timestamp()
    }

    let documentoDolarBlue={
      key:null,
      cotizacion:this.cotizacionMercado.dolarBlueVenta,
      moneda1KMONEY:{
        codigoDivisa:'USD',
        key:'DolaresBlue',
        nombre:'Dólares Blue',
        simbolo:'U$sB'
      },
      moneda2KMONEY:{
        codigoDivisa:'ARS',
        key:'Pesos',
        nombre:'Pesos',
        simbolo:'AR$'
      },
      fechaHora:this.bdService.timestamp()
    }
    
    if(this.cotizacionMercado.dolarVenta)       this.grabarDoc(documentoDolar);
    if(this.cotizacionMercado.dolarDivisaVenta) this.grabarDoc(documentoDolarDivisa);
    if(this.cotizacionMercado.dolarBlueVenta)   this.grabarDoc(documentoDolarBlue);
    
  }
  

  grabarDoc(documento){

    this.bdService.updateColeccion({
      operacion        : 'agregar',
      nombreColeccion  : 'CotizacionesMonedas',
      documento        : documento,
      distribuidorKN   : this.distribuidorKN,
      organizacionKNAI : this.organizacionKNAI,                           
      usuarioKANE      : this.usuarioKANE,
  }).then(dato=>{
        let keyForm = dato.replace('|mensajes.grabacionOk', '');
        let mensajeServicio = dato.replace(keyForm+'|', '');
        this.spinner.hide();
        this.toastrService.success('', this.translate.instant(mensajeServicio),{
                    timeOut: 2000, positionClass:'toast-top-center'});
  })
  .catch(error=>{
        // console.log('resupuesta Promesa error',error);
        this.spinner.hide();
        this.toastrService.error('', this.translate.instant('mensajes.errorGrabar'),{
                   timeOut: 2000, positionClass:'toast-top-center'});
  });
  }

}
