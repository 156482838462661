import { log, logIf, logTable, values } from '@maq-console';

import { Component, OnInit, OnDestroy, ViewEncapsulation, ChangeDetectorRef,ViewChild,ElementRef ,AfterViewInit} from '@angular/core';

import { settingsWebsite }    from '@settings/app.settings.website';
import { AppSettingsService } from '@settings/app.settings';
import { AppSettings2 }       from '@maq-models/appSettings/appSettings.model';

import { BDService }          from '@maq-bd/bd.service';
import { FuncionesService }   from '@maq-funciones';
import { TranslateService }   from '@ngx-translate/core';
import { MenuWebSiteService } from '@maq-servicios/menu/menu-webSite.service';

import { Router, NavigationEnd } from '@angular/router';

import { map, take     } from 'rxjs/operators';

declare let $: any;
declare let jQuery: any;

@Component({
  selector: 'app-panel-web',
  templateUrl: './panel-web.page.html',
  styleUrls: ['./panel-web.page.scss'],
  encapsulation: ViewEncapsulation.None
})

export class PanelWebPage implements OnInit, OnDestroy, AfterViewInit {
 
  constructor (  private translate: TranslateService, 
                 private router:Router,
                 public bdService : BDService,
                 public fn : FuncionesService,                 
                 public menuWebSiteService: MenuWebSiteService,
                 public appSettings : AppSettingsService,
                 protected changeDetectorRef: ChangeDetectorRef) {  
                   
      // Ejecuta el Servicio del Menú de Website
      this.menuWebSiteService.getMenuWebSiteCache().pipe(take(1)).subscribe(data=>{
          console.log("Ejecutó this.menuWebSiteService.getMenuWebSiteCache");
      },(error:any)=>{
          log(...values("error al Ejecutar this.menuWebSiteService.getMenuWebSiteCache",error));
      }); 

      // Ejecuta el Servicio de los Bloques del Menú de Website
      this.menuWebSiteService.getBloquesCache().pipe(take(1)).subscribe(data=>{
          console.log("Ejecutó this.menuWebSiteService.getBloquesCache");
      },(error:any)=>{
          log(...values("error al Ejecutar this.menuWebSiteService.getBloquesCache",error));
      });                   
                   
  }  

  public logComponente = log(...values('componente', 'PanelWebPage'));  
  
  public subscripcionBanners;
  public listadoBannersCabezal=[];

  public settings2:AppSettings2;  
  public activeLang:any='es';
  public idiomaDefault:any='es';
  
  public settingsWebsite=settingsWebsite;
  public showCarouselHeader:boolean=false;
  
  ngOnInit() {

    log(...values('funcionComponente', 'ngOnInit PanelWebPage'));
    
    this.getBanners();
    
    let routerLinkActual = this.router.url;
    const tree = this.router.parseUrl(this.router.url);
    if (tree.fragment) {
      routerLinkActual = this.fn.str_replace('#'+tree.fragment,'',routerLinkActual);
    }  
    console.log("gg routerLinkActual==settingsWebsite.homeRouterLink",routerLinkActual,"==",settingsWebsite.homeRouterLink)
    this.showCarouselHeader = (routerLinkActual==settingsWebsite.homeRouterLink) ? true : false;
    
    this.router.events.subscribe((e: any) => {
        if (e instanceof NavigationEnd) {
            let routerLinkActual = this.router.url;
            const tree = this.router.parseUrl(this.router.url);
            if (tree.fragment) {
              routerLinkActual = this.fn.str_replace('#'+tree.fragment,'',routerLinkActual);
            }  
            //console.log("gg routerLinkActual==settingsWebsite.homeRouterLink",routerLinkActual,"==",settingsWebsite.homeRouterLink)
            this.showCarouselHeader = (routerLinkActual==settingsWebsite.homeRouterLink) ? true : false;            
            
            //console.log("gg showCarouselHeader",this.showCarouselHeader)
            if(this.showCarouselHeader) this.activarBanners();            
        }
    });    
    


    // Listener de Cambio de Idioma
    this.inicializarVariablesTraducibles();
    this.translate.onLangChange.subscribe(() => {
          this.inicializarVariablesTraducibles();
    });
        
    this.settings2 = this.appSettings.settings2;  
    this.idiomaDefault = this.appSettings.settings2.app.idiomaDefault;
   
    // Back to top button
    $(window).scroll(function() {
      if ($(this).scrollTop() > 100) {
        //console.log('barra','scrollTop1');
        $('.back-to-top').fadeIn('slow');
      } else {
        // console.log('barra','scrollTop2');
        $('.back-to-top').fadeOut('slow');
      }
    });
    $('.back-to-top').click(function() {
      $('html, body').animate({
        scrollTop: 0
      }, 1500, 'easeInOutExpo');
      return false;
    });
  
  }

  ngOnDestroy() {
    log(...values('funcionComponente','ngOnDestroy PanelWebPage'));
  }    

  getBanners() {
    
    this.subscripcionBanners=this.bdService	
        .getBDSubscripcion({
            nombreColeccion  : 'Banners',
            where            : [{key:'fechaHoraDesde', operador:'<=', value:new Date()},
                                {key:'ubicacionKN.key', operador:'==', value:'CabezalHome'}],
            orderBy          : [],
            limit            : 100,
            paginado         : 'primera',
            organizacionKNAI : null,
            usuarioKANE      : null                
        }).subscribe((data:any)=>{
            log(...values('funcionEnd','bdService.getBDSubscripcion Banners Cabezal')); 
            log(...values('valores','subscripcion Banners Cabezal data:',data)); 
            
            this.subscripcionBanners.unsubscribe();
            
            for(let i=0; i<data.length; i++) {                
                let documento=data[i];
                let fechaHoraHasta = this.fn.timestampToDate(documento.fechaHoraHasta);
                let ahora          = new Date();

                if(documento.settings && (documento.settings.isActivo===undefined || documento.settings.isActivo!=false)) {
                    if(fechaHoraHasta==null) {
                        this.listadoBannersCabezal.push(data [i]);                  
                    } else if( ['1mayor2','iguales'].indexOf( this.fn.compareFechas(fechaHoraHasta, ahora))!=-1 ) {
                        this.listadoBannersCabezal.push(data[i]);                  
                    }
                }    
                  
            }

            // Ordeno por campo Orden
            this.listadoBannersCabezal.sort(this.fn.ordenarXAtributo('orden', 'asc',false));
            
            console.log("this.listadoBannersCabezal",this.listadoBannersCabezal);
            
            if (!this.changeDetectorRef['destroyed']) {
               this.changeDetectorRef.detectChanges();
            }                
            
            this.activarBanners()

        },(error:any)=>{
            log(...values("error al obtener listadoBannersCabezal",error));
        });         
    
  }
  
  activarBanners() {
      //console.log("gg activarBanners");
      
      setTimeout(function () {
        
          $('.main-carousel').owlCarousel({
            loop: false,
            margin: 0,
            nav: false,
            dots: false,
            autoplayHoverPause: false,
            autoplay: true, //5000
            smartSpeed: 700,
            navText: ['<span class="fas fa-angle-left"></span>', '<span class="fas fa-angle-right"></span>'],
            responsive: {
                0:    { items: 1, nav: false  },
                600:  { items: 1, nav: false  },
                760:  { items: 1, nav: false  }, 
                1024: { items: 1, nav: false  }, 
                1100: { items: 1, nav: false  }
            }
          });
        
      }, 500);                  
    
  }  


  inicializarVariablesTraducibles()  {
      this.activeLang=this.translate.store.currentLang;
      console.log("this.activeLang",this.activeLang);                      
  }

   
  ngAfterViewInit(){
    document.getElementById('preloader').classList.add('hide');
}

linkMultiIdioma(method: string, documento:any, prefijoField:string, postFijo:string, cual:string):string {
    
  let field   = null;
  let rtaLink = null;
  
  if(method=='get') {
      rtaLink = (postFijo=='IMG') ? 'assets/img/app/no-image.png' : null;
  } else if(method=='exist') {
      rtaLink = null;
  }   
  
  // console.log("getLinkMultiIdioma documento",documento);    
  // console.log("getLinkMultiIdioma documento["+prefijoField+'es'+postFijo+"]",documento[prefijoField+'es'+postFijo]);
  // console.log("getLinkMultiIdioma documento["+prefijoField+'en'+postFijo+"]",documento[prefijoField+'en'+postFijo]);
  // console.log("getLinkMultiIdioma documento["+prefijoField+'pt'+postFijo+"]",documento[prefijoField+'pt'+postFijo]);
  
  // console.log("linkMultiIdioma documento.imagenMultiIdioma",documento.imagenMultiIdioma);
  // console.log("linkMultiIdioma this.idiomaDefault",this.idiomaDefault);
  // console.log("linkMultiIdioma this.activeLang+",this.activeLang);

  if(documento.imagenMultiIdioma==true && 
     documento[prefijoField+this.idiomaDefault+postFijo] && documento[prefijoField+this.idiomaDefault+postFijo][cual]) {
         
         field = prefijoField+this.idiomaDefault+postFijo;
             
         rtaLink = documento[field][cual];
     
  } else if(documento.imagenMultiIdioma!=true && 
      documento[prefijoField+this.activeLang+postFijo] && documento[prefijoField+this.activeLang+postFijo][cual]) {
          
          field = prefijoField+this.activeLang+postFijo;
              
          rtaLink = documento[field][cual];     
  }        
  
  //console.log("getLinkMultiIdioma rtaLink",method, field, cual, rtaLink);

  return rtaLink;
}

// @HostListener('window:resize')
// public onWindowResize():void {
//     let showMenu= !this._showMenu();

//     if (this.showMenu !== showMenu) {
//         this.showMenuStateChange(showMenu);
//     }
//     this.showMenu = showMenu;
// }

// public showMenuStateChange(showMenu:boolean):void {
//     this.settings.theme.showMenu = showMenu;
// }

// private _showMenu():boolean {
//     return window.innerWidth <= 768;
// }

   
  

}