import { log, logIf, logTable, values } from '@maq-console';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCALE_ID, NgModule, TRANSLATIONS } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ToastrModule } from 'ngx-toastr';
import {NgxMaskModule,IConfig } from 'ngx-mask' // mascaras para inputs  https://www.npmjs.com/package/ngx-mask
export const options: Partial<IConfig> | (() => Partial<IConfig>)={}; 

import { routing } from './app.routing';
import { AppSettingsService } from './app.settings';

import { AppComponent } from './app.component';

// environmet
import { environment }             from '../environments/environment';

// librerias de angular Fire
import { AngularFireAnalyticsModule, CONFIG, DEBUG_MODE } from '@angular/fire/analytics';
import { AngularFireModule }                  from '@angular/fire';
import { AngularFireAuthModule }              from '@angular/fire/auth';
import { AngularFireDatabaseModule }          from '@angular/fire/database';  //Firedatabase
import { AngularFirestoreModule   }           from '@angular/fire/firestore/'; //Cloud Firestore
import { AngularFireStorageModule }           from '@angular/fire/storage';

// Modulos Internos
import { SharedModule }             from '@maq-shared/shared.module';
import { PanelModule }              from './maqueta/panel/panel.module';
import { panelWebModule }           from './maqueta/panelWeb/panelWeb.module';

import { ConfirmService, ConfirmState, ConfirmTemplateDirective, ConfirmModalComponent } from '@maq-servicios/confirm/confirm-service';
import { AlertService, AlertState, AlertTemplateDirective, AlertModalComponent } from '@maq-servicios/alert/alert-service';

import { LibBdModule }              from '@maq-bd/bd.module';
import { MensajesService}           from '@maq-servicios/mensajes/mensajes.service';
import { MenuService }              from '@maq-servicios/menu/menu.service'; 
import { MenuColeccionService }     from '@maq-servicios/menu/menu-coleccion.service';
import { registerLocaleData }       from '@angular/common';

// importar locales
import localePy                     from '@angular/common/locales/es-PY';
import localePt                     from '@angular/common/locales/pt';
import localeEn                     from '@angular/common/locales/en';
import localeEsAr                   from '@angular/common/locales/es-AR';

// registrar los locales con el nombre que quieras utilizar a la hora de proveer
registerLocaleData(localePy,  'es'   );
registerLocaleData(localePt,  'pt'   );
registerLocaleData(localeEn,  'en'   )
registerLocaleData(localeEsAr,'es-Ar');

// import ngx-translate and the http loader
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader}                                from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule}                       from '@angular/common/http';

// Interceptors
// import { HTTP_INTERCEPTORS } from '@angular/common';
// import { InterceptorService }   from './maqueta/servicios/soap/interceptor.service';

// Spinner
import { NgxSpinnerModule }                                 from "ngx-spinner";

// Pipe Order
import { OrderModule }                                      from 'ngx-order-pipe';

// ngx-json-viewer
import { NgxJsonViewerModule }                              from 'ngx-json-viewer';

// ngx-moment (reemplazó el moment que venía en la maqueta de js)
import { MomentModule }                                     from 'ngx-moment';

// NgxPica
import { NgxPicaModule }                                    from 'ngx-pica';

// Mapas 
import { AgmCoreModule }                                    from '@agm/core';

// Croppie Imágenes
import { ImageCropperModule }                               from 'ngx-image-cropper';

const googleMapsParams = {
  apiKey: environment.apis.googleMaps.key,
  libraries: ['places'],
  language: 'es',
  // region: 'en',
};

import { CanActivateViaAuthGuard }        from '@maq-autorizacion/guards/can-activate-via-auth-guard';
import { CanActivateViaNoAuthGuard }      from '@maq-autorizacion/guards/can-activate-via-no-auth-guard';
import { CanActivateChildViaAuthGuard }   from '@maq-autorizacion/guards/can-activate-child-via-auth-guard';
import { CanActivateChildViaNoAuthGuard } from '@maq-autorizacion/guards/can-activate-child-via-no-auth-guard';


export function createTranslateLoader(http: HttpClient) {

    // funcion que lee todos los json y crea un json para ese idioma
    // tiene que sumar en es.json={json repositorio+ json projecto}
    
  return new TranslateHttpLoader(http, './assets/i18n/', '.json'); // por default levanta el lenguaje del momento.
}


@NgModule({  
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    PerfectScrollbarModule,
    NgbModule,
    MultiselectDropdownModule,
    AgmCoreModule.forRoot(googleMapsParams),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    ToastrModule.forRoot(), 
    NgxMaskModule.forRoot(options), //mascaras
   
    PanelModule,
    panelWebModule,
    routing,

    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebase),  
    AngularFireAnalyticsModule,
    // firebase.firestore().settings({
    //     cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
    // }),
    AngularFirestoreModule.enablePersistence(),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    NgxPicaModule,
    SharedModule,
    HttpClientModule,
    TranslateModule.forRoot({
          loader: {
            provide: TranslateLoader,
            useFactory: (createTranslateLoader),
            deps: [HttpClient]
          }
        }),    
    
    ImageCropperModule,
    NgxSpinnerModule,
    OrderModule,
    NgxJsonViewerModule,
    MomentModule,
    LibBdModule
  ],
  declarations: [
    AppComponent,
  
    // componentes del servicio de confirmación
    ConfirmTemplateDirective, ConfirmModalComponent ,
    AlertTemplateDirective, AlertModalComponent ,
  ],
  entryComponents: [ ],
  exports: [
    TranslateModule
  ],
  providers: [ 
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: InterceptorService,
    //   multi: true
    // },    
    AppSettingsService,  //servicio de confuración de la aplicación.

    //Gurds
    CanActivateViaAuthGuard, CanActivateViaNoAuthGuard, CanActivateChildViaAuthGuard, CanActivateChildViaNoAuthGuard,
    
    // Analytics
    { provide: DEBUG_MODE, useValue: true },
    { provide: CONFIG, useValue: {
      send_page_view: true,
      allow_ad_personalization_signals: true,
      anonymize_ip: true
    } },    
    
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
         // componentes del servicio de confirmación
    // ConfirmTemplateDirective, ConfirmModalComponent  ,  
    { provide: LOCALE_ID, useValue: 'es-Ar' },
    // {provide: TRANSLATIONS, useValue: translations},
    // I18n,
    
    ConfirmService,
    ConfirmState,
    AlertService,
    AlertState,
    
    MensajesService,
    MenuService,
    MenuColeccionService
  ], 
  
  bootstrap: [ AppComponent ]
})
export class AppModule { }