import { Component, EventEmitter, OnInit, ViewChild, Output, Input, ViewEncapsulation } from '@angular/core';
import { FormControl } from "@angular/forms";
import { ChangeDetectorRef } from '@angular/core';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

import { LISTA_DE_LIBRERIAS_ICONOS } from '@maq-mocks/iconos/iconos';
import { LISTA_DE_ICONOS }           from '@maq-mocks/iconos/iconos';

@Component({
  selector: 'lib-shared-pick-icono',
  templateUrl: './pick-icono.component.html',
  styleUrls: [ './pick-icono.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class PickIconoComponent implements OnInit {
    @Input() public field: string; 
    @Input() public accionForm: string; 

    @Output() seleccionoIcono= new EventEmitter<string>();

    @ViewChild("modalContent") modalContent: any;

    public modalRef: NgbModalRef;
    public hover:any[]=[];
    public libreriaIconos:string='Font Awesome 4';
    public searchText:string='';

    public LISTA_DE_LIBRERIAS_ICONOS=LISTA_DE_LIBRERIAS_ICONOS;
    public LISTA_DE_ICONOS=LISTA_DE_ICONOS;

    public traduccion:any={};

    constructor(public modalService: NgbModal,
                private translate: TranslateService) { 
    }

    ngOnInit() {

      console.log("LISTA_DE_ICONOS",LISTA_DE_ICONOS);
      this.inicializarVariablesTraducibles();
       this.translate.onLangChange.subscribe(() => {
        console.log('cambio idioma en libreriaB');
        this.inicializarVariablesTraducibles();
       });

    }  

    public abrirFormulario() {
      console.log("abrirFormulario");
      this.modalRef = this.modalService.open(this.modalContent, {size: 'lg', backdrop : 'static', windowClass: 'custom-class', container: '.app' });
      
      this.modalRef.result.then((result) => {
          // ok
          //console.log("modal result", result);

      }, (reason) => {
          // cancel
      });
    }

    public closeModal(){
      this.modalRef.close();
    }

    public seleccionarIcono(icono) {
      console.log("seleccionarIcono", icono);
      this.modalRef.close();
      this.seleccionoIcono.emit(icono);

    }
    inicializarVariablesTraducibles(){
        
      this.traduccion['navegabilidad']={
         editar:                 this.translate.instant('navegabilidad.editar'),
         cancelar:               this.translate.instant('navegabilidad.cancelar'),
         agregar:                this.translate.instant('navegabilidad.agregar'),
         grabar:                 this.translate.instant('navegabilidad.grabar'),
         seleccionar:            this.translate.instant('navegabilidad.seleccionar')
         
      }
      this.traduccion['claseFicha']={
        icono:                 this.translate.instant('claseFicha.icono')
              
      }
    }
  
}  