import { log, logIf, logTable, values } from '@maq-console';

import { Component, OnInit, DoCheck, Input, Output, ViewEncapsulation, EventEmitter } from '@angular/core';

import { FuncionesService } from '@maq-funciones';
import { TranslateService } from '@ngx-translate/core';

import { FormGroup, FormControl, FormBuilder, Validators, FormsModule} from '@angular/forms';

import { Grilla } from '@maq-models/grilla/grilla.model';

@Component({
  selector: 'lib-shared-form-settings',
  templateUrl: './form-settings.component.html',
  styleUrls: [ './form-settings.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class FormSettingsComponent implements OnInit, DoCheck {

    @Input() public nameField: string;
    @Input() public controlsField: any;
    @Input() public value: any;
    @Input() public formInvalid: boolean;
    @Input() public accionForm: string;
    @Input() public tipoPerfilUsuario: string;

    @Output() cargoSubEstructura= new EventEmitter<any>();

    public form:FormGroup;
    public grilla:Grilla = new Grilla({});
    public traduccion:any={};
    public dif:number=null;

    constructor(public fn:FuncionesService,
                public translate: TranslateService,
                public fb:FormBuilder, ) {

    }

  ngOnInit() {

    log(...values("valores","form-settings nameField:",this.nameField));
    log(...values("valores","form-settings controlsField:",this.controlsField));
    log(...values("valores","form-settings value:",this.value));

    this.inicializarVariablesTraducibles();
    this.translate.onLangChange.subscribe(() => {
       this.inicializarVariablesTraducibles();
    });
    
    // Defino Dif
    this.dif = Math.floor(Math.random() * (99999 - 1 + 1)) + 1;

    // Defino Formulario
    this.form = this.fb.group({

        settings: this.fb.group({
          isActivo                : true,
          isBorrado               : false,
          borradoFisico           : false,
          triggersIgnorar         : false,
          triggersProcesarTarde   : false,
          documentosAsociados     : 0,
          sizeOfDocumento         : 0,
          fechaHoraCreacion       : null,
          fechaHoraModificacion   : null,
          usuarioKANE             : null,
      }),

    });

    this.form.controls['settings'].setValue(this.value);
    console.log("this.form.controls['settings']",this.form.controls['settings']);

  }

  inicializarVariablesTraducibles(){

      this.traduccion['claseValidaciones']={
        esRequerido:             this.translate.instant('claseValidaciones.esRequerido'),
      }
      this.traduccion['claseTabs']={
        settings:                 this.translate.instant('claseTabs.settings'),
      }
      this.traduccion['claseSettings']={
        activo:                 this.translate.instant('claseSettings.activo'),
        borrado:                this.translate.instant('claseSettings.borrado'),
        documentosAsociados:    this.translate.instant('claseSettings.documentosAsociados'),
        sizeOfDocumento:        this.translate.instant('claseSettings.sizeOfDocumento'),
        fechaCreacion:          this.translate.instant('claseSettings.fechaCreacion'),
        fechaModificacion:      this.translate.instant('claseSettings.fechaModificacion'),
        usuarioUltimaOperacion: this.translate.instant('claseSettings.usuarioUltimaOperacion'),
      }
  }


  ngDoCheck() {
      //console.log("ngDoCheck - this.accionForm",this.accionForm)
      // log(...values("valores","form-settings controlsField:",this.controlsField));
      
      let statusIsBorrado = this.controlsField['controls']['isBorrado'].status;
      if(this.accionForm=='consultar') {
        this.form.disable();
      } else {   
          this.form.enable();     
          // if(statusIsBorrado=='VALID') {        
          //     this.form.disable();
          //     this.form.get('settings.isBorrado').enable();
          //   } else {
          //     this.form.enable();
          // } 
      }

      if(this.formInvalid) {
        this.form.markAllAsTouched();
      }
  }
  
  habilitadoParaRevertirBorrado() {
    let isBorrado = this.form.get('settings.isBorrado').value;
    if(['Desarrollador','Supervisor','Distribuidor'].indexOf(this.tipoPerfilUsuario)!=-1 && isBorrado==true) {
      return true;
    } else {
      return false;
    }
  }

  onChangeField() {

      log(...values('valores','onChangeField - event:',event));

      let valueSubestructura = this.form.get('settings').value;

      this.cargoSubEstructura.emit({
        nameField : this.nameField,
        value    :  valueSubestructura
      });
  }
  
  convierteToKB(bytes) {
    return bytes/1000;
  }

}
