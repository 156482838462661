<form [formGroup]="form" class="mb-2">
	<input class="form-control input-wide" type="text" placeholder="{{'claseDireccion.ingreseDireccion' | translate}}" id="search{{dif}}"
			 formControlName="inputAutoComplete">
			 
</form>	
<div id="mapContainer{{dif}}" style="width:100%; height:200px;"></div>


<!-- <select id="location" data-placeholder="Enter your address" data-width="100%"></select>

<div id="map" style="width:100%; height:200px;"></div> -->


<!-- <div id="map" style="width:300px; height:400px; background:grey" ></div>
<div id="panel" style="width:300px; height:100px;">

	<strong style="font-size: large;">Geocoding Autocomplete</strong><br>
           
	<br>
	<input type="text" id="auto-complete" 
	   	 style="margin-left:5%; margin-right:5%; min-width:90%"  
			 (keyup)="autoCompleteListener(this, $event);"><br>
	<br><strong>Response:</strong><br/><br/>
	<div style="margin-left:5%; margin-right:5%;">
		<pre style="height:200px"><code  id="suggestions" style="font-size: small;">Null</code></pre>
	</div>

</div> -->
