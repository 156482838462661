import { TranslateService } from "@ngx-translate/core";
import { ColumnaReporte } from "./columnasReporte";

export class ReporteFavoritoSQL{
    private idReporteFavorito                   : number = 0;

    private esFavorito                          : boolean = false;
    private usuarioKey                          : string = '';
    private usuarioNombre                       : string = '';
    
    /*datos de la configuracion del reporte */
    private idConfiguracionReporte              : number = 0;
    private descripcionConfiguracionReporte     : string = '';
    private tipoReporteKey                      : string = '';
    // private idOrganizacion                   : number | null = null;
    private organizacionKey                     : string | null = null;
    private organizacionNombre                  : string | null = null;
    private sucursalKey                         : string | null = null;
    // private idSucursal                       : number | null = null;
    private sucursalNombre                      : string | null = null;
    private areaNegocioKey                      : string | null = null;
    // private idAreaNegocio                    : number | null = null;
    private areaNegocioNombre                   : string | null = null;
    private columnasSeleccionadas               : ColumnaReporte [] = [];


    constructor(obj?: Partial<ReporteFavoritoSQL>) {    
        Object.assign(this, obj);
        for(let i=0;i<this.columnasSeleccionadas?.length;i++){
            this.columnasSeleccionadas[i] = new ColumnaReporte(this.columnasSeleccionadas[i]);
        }
    }

    getJson():object{
        let json={
            "idReporteFavorito"             : this.idReporteFavorito,
            "idConfiguracionReporte"        : this.idConfiguracionReporte,
            "esFavorito"                    : this.esFavorito,
            "usuarioKey"                    : this.usuarioKey,
            "usuarioNombre"                 : this.usuarioNombre
        };

        return json;
    }

    getColumnasVisbles(translate: TranslateService):any{
        let json = {};
        json['columnasSeleccionadas'] = [];
    
        for(let i=0;i<this.columnasSeleccionadas?.length;i++){
            let col = this.columnasSeleccionadas[i].getJson();
            col['nombreTraduccion']= translate.instant(col['nombreTraduccion']);
            json['columnasSeleccionadas'].push(col);
        }

        return json['columnasSeleccionadas'];
    }

    public getIdReporteFavorito():number | null{
        return this.idReporteFavorito;
    }

    public setIdReporteFavorito(idReporteFavorito:number){
        this.idReporteFavorito = idReporteFavorito;
    }

    public getIdConfiguracionReporte():number | null{
        return this.idConfiguracionReporte;
    }

    public setIdConfiguracionReporte(idConfiguracionReporte:number){
        this.idConfiguracionReporte = idConfiguracionReporte;
    }

    public getDescripcionConfiguracionReporte():string{
        return this.descripcionConfiguracionReporte;
    }

    public setDescripcionConfiguracionReporte(descripcionConfiguracionReporte:string){
        this.descripcionConfiguracionReporte = descripcionConfiguracionReporte;
    }

    public getTipoReporteKey():string{
        return this.tipoReporteKey;
    }

    public setTipoReporteKey(tipoReporteKey:string){
        this.tipoReporteKey = tipoReporteKey;
    }

    public getEsFavorito():boolean | null{
        return this.esFavorito;
    }

    public setEsFavorito(esFavorito:boolean){
        this.esFavorito = esFavorito;
    }

    public getUsuarioKey():string | null{
        return this.usuarioKey;
    }

    public setUsuarioKey(usuarioKey:string){
        this.usuarioKey = usuarioKey;
    }

    public getUsuarioNombre():string | null{
        return this.usuarioNombre;
    }

    public setUsuarioNombre(usuarioNombre:string){
        this.usuarioNombre = usuarioNombre;
    }

     // public getIdOrganizacion():number{
    //     return this.idOrganizacion;
    // }

    // public setIdOrganizacion(idOrganizacion:number){
    //     this.idOrganizacion = idOrganizacion;
    // }

    public getOrganizacionKey():string{
        return this.organizacionKey;
    }

    public setOrganizacionKey(organizacionKey:string | null){
        this.organizacionKey = organizacionKey;
    }

    public getOrganizacionNombre():string{
        return this.organizacionNombre;
    }

    public setOrganizacionNombre(organizacionNombre:string){
        this.organizacionNombre = organizacionNombre;
    }

    public getSucursalKey():string{
        return this.sucursalKey;
    }
    
    public setSucursalKey(sucursalKey:string | null){
        this.sucursalKey = sucursalKey;
    }

    // public getIdSucursal():number{
    //     return this.idSucursal;
    // }

    // public setIdSucursal(idSucursal:number){
    //     this.idSucursal = idSucursal;
    // }

    public getSucursalNombre():string{
        return this.sucursalNombre;
    }
    
    public setSucursalNombre(sucursalNombre:string){
        this.sucursalNombre = sucursalNombre;
    }
    public getAreaNegocioKey():string{
        return this.areaNegocioKey;
    }
    
    public setAreaNegocioKey(areaNegocioKey:string | null){
        this.areaNegocioKey = areaNegocioKey;
    }

    // public getIdAreaNegocio():number{
    //     return this.idAreaNegocio;
    // }

    // public setIdAreaNegocio(idAreaNegocio:number){
    //     this.idAreaNegocio = idAreaNegocio;
    // }

    public getAreaNegocioNombre():string{
        return this.areaNegocioNombre;
    }

    public setAreaNegocioNombre(areaNegocioNombre:string){
        this.areaNegocioNombre = areaNegocioNombre;
    }

    public getColumnasSeleccionadasTraducidas(translate: TranslateService):ColumnaReporte [] {
        let columnasSeleccionadasTraducidas :ColumnaReporte [] = []
        for(let i=0;i<this.columnasSeleccionadas?.length;i++){
            let columna = this.columnasSeleccionadas[i];
            columna.setNombreTraduccion(translate.instant(columna.getNombreTraduccion()));
            columnasSeleccionadasTraducidas.push(columna);
        }
        return columnasSeleccionadasTraducidas;
    }

    public getColumnasSeleccionadas():ColumnaReporte [] {
        return this.columnasSeleccionadas;
    }

    public setColumnasSeleccionadas(value:ColumnaReporte []) {
        this.columnasSeleccionadas = value;
    }

   
}