<div class="wrapper-inner" id="wrapper-inner" [ngClass]="{ 'vertical-menu-tooltip-place':   (appSettings.settings2.panel.menuType == 'mini' && appSettings.settings2.panel.menu == 'vertical'),
                                        'horizontal-menu-tooltip-place': (appSettings.settings2.panel.menuType == 'mini' && appSettings.settings2.panel.menu == 'horizontal') }">
    
    <app-header></app-header>
    <div id="divisor"></div>
    <div class="d-flex h-100" [ngClass]="{'flex-row': appSettings.settings2.panel.menu == 'vertical', 'flex-column': appSettings.settings2.panel.menu != 'vertical'}">
        <app-sidebar *ngIf="appSettings.settings2.panel.menu == 'vertical'"></app-sidebar> 
        <div class="main-content"> 
            <lib-shared-side-chat></lib-shared-side-chat>           
            <!-- <lib-shared-breadcrumbA *ngIf="appSettings"  ></lib-shared-breadcrumbA>        /     -->
            <router-outlet></router-outlet>              
        </div>
    </div>   

</div>
<lib-shared-footer ></lib-shared-footer>
<lib-shared-back-top position="200"></lib-shared-back-top>

<div class="settings" [ngClass]="{'show': showSetting}">
    <button class="btn btn-default toggle" (click)="showSetting=!showSetting"><i class="fa fa-spin fa-gear"></i></button>
    <div class="card">
        <div class="card-header text-center">
            <span translate>settings.settings</span>
        </div>      
        <div class="card-body mt-2">
            <h6 translate>settings.chooseMenu</h6>  
            <div class="form-group">
                <div *ngFor="let menu of menus; let i = index;" class="custom-control custom-radio">
                    <input [attr.id]="'radio-'+i" type="radio" name="menus" class="custom-control-input radio-dark-gray"
                        (change)="chooseMenu(menu)" [checked]="menu === menuOption" [value]="menu">
                    <label [attr.for]="'radio-'+i" class="custom-control-label">{{menu  | translate}}</label>
                </div>
            </div>
        </div> 
        <div *ngIf=" showMenuType()==true">
            <div class="card-body">
                <h6 translate>settings.chooseMenuType</h6>
                <div class="form-group">
                    <div *ngFor="let menuType of menuTypes; let i = index;" class="custom-control custom-radio">
                        
                            <!--!( && this.appSettings.settings2.panel.menu == 'hozintal' && this.appSettings.settings2.panel.menuType != 'default')-->
                            <input [attr.id]="'menuType-'+i" type="radio" name="menuTypes" class="custom-control-input radio-dark-gray"
                                (change)="chooseMenuType(menuType)" [checked]="menuType === menuTypeOption" [value]="menuType">
                            <label [attr.for]="'menuType-'+i" class="custom-control-label" >{{menuType | translate}}</label>
                    </div>
                </div> 
            </div>
        </div> 
        <!-- 
        <div class="card-body mb-3" *ngIf="appSettings.settings2.panel.showSkin">
            <h6 translate>settings.chooseThemeSkin</h6>          
            <div class="theme light" (click)="changeTheme('light')"></div>
            <div class="theme dark" (click)="changeTheme('dark')"></div>
            <div class="theme blue" (click)="changeTheme('blue')"></div>
            <div class="theme green" (click)="changeTheme('green')"></div>
            <div class="theme combined" (click)="changeTheme('combined')"></div>
            <div class="theme purple" (click)="changeTheme('purple')"></div>
            <div class="theme orange" (click)="changeTheme('orange')"></div>
            <div class="theme brown" (click)="changeTheme('brown')"></div>
            <div class="theme grey" (click)="changeTheme('grey')"></div>
            <div class="theme pink" (click)="changeTheme('pink')"></div>
            <div class="theme kipbip" (click)="changeTheme('kipbip')"></div>
        </div>
        -->
        <div class="card-body mb-2">
            <h6 translate>settings.otherThemeOptions</h6> 
            <div class="custom-control custom-checkbox">
                <!--
                <input type="checkbox" class="custom-control-input checkbox-dark-gray" id="navbarIsFixed" [checked]="appSettings.settings2.panel.navbarIsFixed" (change)="changeHeader()">
                <label class="custom-control-label" for="navbarIsFixed" translate >settings.navbarIsFixed</label>
                 -->
                <input type="checkbox" class="custom-control-input checkbox-dark-gray" id="navbarIsFixed" [checked]="appSettings.settings2.panel.navbarIsFixed" (change)="changeHeader()">
                <label class="custom-control-label" for="navbarIsFixed" translate >settings.fixedHeader</label>
               
            </div>
            <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input checkbox-dark-gray" id="sidebarIsFixed" [checked]="appSettings.settings2.panel.sidebarIsFixed" (change)="changeMenuLateralFijo()">
                <label class="custom-control-label" for="sidebarIsFixed" translate>settings.fixedSidebar </label>
            </div>
            <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input checkbox-dark-gray" id="footerIsFixed" [checked]="appSettings.settings2.panel.footerIsFixed" (change)="changeFooter()">
                <label class="custom-control-label" for="footerIsFixed" translate>settings.fixedFooter </label>
            </div>


            <div class="custom-control custom-checkbox pl-0">
                <i style="width: 14px;" (click)="clearCache()" title="{{'settings.clearCache' | translate}}" class="fa fa-sharp fa-solid fa-trash p-0 mr-2 text-primary clear-cache"></i>
                <a [routerLink]="" (click)="clearCache()" class="card-link" title="{{'settings.clearCache' | translate}}" translate>settings.clearCache</a>
            </div>
    
        </div>
    </div>
</div>