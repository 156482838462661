// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// import {url} from "inspector";

export const environment = {
  production            : false,
  enConstruccion        : false,
  version               : '1.3.7',
  consoleLog            : { header:true, funciones: true, variables:true },  
  cliente               : 'planifik',
  tipoServidor: {  
      hosting           : 'firebase',       // firebase  o externo
      login             : 'firebase',       // firebase  o sql
      baseDatos         : 'firestore',      // firestore o sql
      storage           : 'firebase',       // firebase  o externo
  },  

  // Demos
  firebase:  {
    apiKey: "AIzaSyD143bUagwQJnJlq_KqCBDJZoMPr3dbAd0",
    authDomain: "demokipbip.firebaseapp.com",
    projectId: "demokipbip",
    storageBucket: "demokipbip.appspot.com",
    messagingSenderId: "182994540640",
    appId: "1:182994540640:web:fbb74d5166981074cd8b05",
    measurementId: "G-WVXVZYQNV7"
  },
  // fin Demos

  httpSoapGenerica:'https://nodedemo.kipbip.com/soapRoadNet', 
  // httpSoapGenerica:'http://localhost:4000/soapRoadNet', 

  cloudFunction: {
      url: 'https://us-central1-pktest-ad982.cloudfunctions.net/'
  },  

  // fin DEMOSs 

  serviciosExternos: {
    hosting: {
        documentoRoot   : 'planifi-k/www',
    },
    sql: {
          // apiURL           : 'http://localhost:4000/',      //LocalHost
      apiURL          : 'https://nodedemo.kipbip.com/',           //demo

    },
    storage: {
        serverURL       : 'https://www.lincolnonline.com.ar/archivos/',        //Desarrollo
        user            : '',
        password        : '',
    },  
  },
  apis: {
    reCAPTCHA: {
      version       : 'v2',            
      claveSitioWeb : null,
      claveSecreta  : null
    },        
    googleMaps: { 
       key              : 'AIzaSyC3kGKOR7hsNhR6JjZFMbZnj8tmYcX5klI'
    },
    hereMaps: {
      apiKey      : '0nu_i9yyTVCac8u78h8hvocNK2BgrxkPqpu1Iwp0G0c', // Javier
      // apiKey   : 'mZeC5JXRMPDpsq7EbaM4ZZEgluoM-I4W8bPrET2LY9Q', // Santiago
      app_id      : '4nYLZClXQgWpOEMKScrE',  // Javier
      app_code    : 'H-zbneNbZ5LfWj8CAjGjHw', // Javier
      url         : {
        autocomplete          : 'http://autocomplete.geocoder.cit.api.here.com/6.2/suggest.json',
        geocode               : 'https://geocode.search.hereapi.com/v1/geocode',
        reverseGeocode        : 'https://revgeocode.search.hereapi.com/v1/revgeocode',
        batchGeocode          : 'https://batch.geocoder.ls.hereapi.com/6.2/jobs',  
        //batchGeocode          : 'https://batch.geocoder.api.here.com/6.2/jobs',   
        batchReverseGeocode   : 'https://reverse.geocoder.api.here.com/6.2/multi-reversegeocode.json',
      }
    },
    facebook: {
       app_id     : '',   // APP ID de la Fun Page de Facebook
    }
  }
        
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
