<main id="main" style="background:white;">  

   <app-header-web></app-header-web>    
   
   <!-- <div style="border-bottom:1px solid #8f9eb3;"
        *ngIf="showCarouselHeader && listadoBannersCabezal.length==1">  
        
            <a *ngIf="listadoBannersCabezal[0].path && listadoBannersCabezal[0].boton==null" href="{{listadoBannersCabezal[0].path}}">
                
               <img [src]="linkMultiIdioma('get',listadoBannersCabezal[0], 'fotoWeb_','IMG','link')"
                    [alt]="listadoBannersCabezal[0]['titulo_'+activeLang]">
            </a>
            
            <img *ngIf="linkMultiIdioma('exist',listadoBannersCabezal[0], 'fotoWeb_','IMG','link')"
                 [src]="linkMultiIdioma('get',listadoBannersCabezal[0], 'fotoWeb_','IMG','link')"
                 [alt]="listadoBannersCabezal[0]['titulo_'+activeLang]">
   </div> -->
   
   <div class="owl-carousel owl-theme main-carousel animated fadeInUp wow"
        style="border-bottom:1px solid #8f9eb3;"
        *ngIf="showCarouselHeader">  
        
        <div *ngFor="let banner of listadoBannersCabezal"
            class="slide-item">
            
            <a *ngIf="banner.path && banner.boton==null" href="{{banner.path}}">
                
               <img *ngIf="linkMultiIdioma('exist',banner, 'fotoWeb_','IMG','link')"
                    [src]="linkMultiIdioma('get',banner, 'fotoWeb_','IMG','link')"
                    [alt]="banner['titulo_'+activeLang]">

            </a>

            <img *ngIf="!(banner.path && banner.boton==null) && linkMultiIdioma('exist',banner, 'fotoWeb_','IMG','link')"
                 [src]="linkMultiIdioma('get',banner, 'fotoWeb_','IMG','link')"
                 [alt]="banner['titulo_'+activeLang]">
                 
            <div class="slide-text">
                <p *ngIf="banner['titulo_'+activeLang]" class="tituloBanner">{{banner['titulo_'+activeLang]}}</p>
                <p *ngIf="banner['descripcion_'+activeLang]" style="color:#ccc;">{{banner['descripcion_'+activeLang]}}</p>
                
                <a *ngIf="banner.path && banner.boton=='ir'" 
                   href="{{banner.path}}" class="btn btn-header">{{'navegabilidad.ir' | translate}}</a>
                <a *ngIf="banner.path && banner.boton=='verMas'" 
                   href="{{banner.path}}" class="btn btn-header">{{'navegabilidad.verMas' | translate}}</a>
                <a *ngIf="banner.path && banner.boton=='seguirLeyendo'" 
                   href="{{banner.path}}" class="btn btn-header">{{'navegabilidad.seguirLeyendo' | translate}}</a>                   
            </div>
        </div>

    </div>

    <router-outlet></router-outlet>

</main><!-- End #main -->

<app-footer-web></app-footer-web>  

<a href="#" class="back-to-top"><i class="icofont-simple-up"></i></a>
     