import { KMONEY } from './../../models/monedasImportes/monedasImportes.model';
import { log, logIf, logTable, values } from '@maq-console';

import { Injectable } from '@angular/core';
import * as firebase from 'firebase';


import { FuncionesService }  from '@maq-funciones';
import { getApisFunciones }   from '@maq-mocks/apis/apis';
import { LogApiFuncionModel, LogSessionApi } from '@maq-models/apis/apis.model';
import { StorageService } from '@maq-servicios/storageService/storage.service';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApisService {

  public listadoApisFunciones=getApisFunciones();
  
  public environment:any=environment;

  // public apiTotal:LogSessionApi = new LogSessionApi();
  public apiTotal:any[] = [];

  constructor(public fn:FuncionesService, 
              public storageService: StorageService,
              private _http: HttpClient) {

  }

  // *****************************************************************************
  // *****************************************************************************

  get timestamp() {
    return firebase.firestore.FieldValue.serverTimestamp;
  }

  LogApiFuncion(argumentos:LogApiFuncionModel) {
      /*
      argumentos = {
          eventoQueDisparo : string,
          apiFuncionKey    : string,
          organizacionKNAI : KNAI,
          usuarioKANE      : KANE
          nombreColeccion  : string,
          cloudFunction    : string,
          cantidad         : number,
      }
      */

      //log(...values("LogApiFuncion","LogApiFuncion",argumentos.apiFuncionKey));
      //   log(...values("valoresDebug","eventoQueDisparo",argumentos.eventoQueDisparo,"argumentos:",argumentos));
      //log(...values("valoresDebug","argumentos:",argumentos));

      if(!argumentos) {
        return;
      }

      let sesionApi = {
          apiFuncionKey    : argumentos.apiFuncionKey,
          organizacionKNAI : argumentos.organizacionKNAI,
          usuarioKANE      : argumentos.usuarioKANE,
          nombreColeccion  : argumentos.nombreColeccion,
          cloudFunction    : argumentos.cloudFunction,
          cantidad         : argumentos.cantidad,
      }

      /*
       * Profundiad
       * = 1  -> Totales por Periodo
       * = 2  -> Totales por Periodo/Usuario | Coleccion
       * = 3  -> Totales por Dia
       * = 4  -> Totales por Dia/Usuario | Coleccion
       * */
      let profundidad="1234";  // Incluir todos los que se graban. Ej. 1, 12, 123, 1234, 3, 34

      this.grabaApisTotales(sesionApi,'TotalVIDAP');

      if(profundidad.includes("1")) {
          this.grabaApisTotales(sesionApi,'TotalPeriodo');
          if( this.fn.setearOrganizacionAI(sesionApi.organizacionKNAI) !='MAQUE') {
                this.grabaApisTotales(sesionApi,'TotalPeriodoRESTO');
          }
          this.grabaApisTotales(sesionApi,'TotalPeriodoTOTAL');
      }

      if(profundidad.includes("2")) {
            // Periodo / Usuario
            this.grabaApisTotales(sesionApi,'TotalPeriodoUsuario');
            if( this.fn.setearOrganizacionAI(sesionApi.organizacionKNAI) !='MAQUE') {
                this.grabaApisTotales(sesionApi,'TotalPeriodoUsuarioRESTO');
            }
            this.grabaApisTotales(sesionApi,'TotalPeriodoUsuarioTOTAL');

            // Periodo / Colección
            this.grabaApisTotales(sesionApi,'TotalPeriodoColeccion');
            if( this.fn.setearOrganizacionAI(sesionApi.organizacionKNAI) !='MAQUE') {
                this.grabaApisTotales(sesionApi,'TotalPeriodoColeccionRESTO');
            }
            this.grabaApisTotales(sesionApi,'TotalPeriodoColeccionTOTAL');

            // Periodo / Cloud Function
            this.grabaApisTotales(sesionApi,'TotalPeriodoCloudFunction');
            if( this.fn.setearOrganizacionAI(sesionApi.organizacionKNAI) !='MAQUE') {
                this.grabaApisTotales(sesionApi,'TotalPeriodoCloudFunctionRESTO');
            }
            this.grabaApisTotales(sesionApi,'TotalPeriodoCloudFunctionTOTAL');
      }

      if(profundidad.includes("3")) {
            this.grabaApisTotales(sesionApi,'TotalPeriodoDia');
            if( this.fn.setearOrganizacionAI(sesionApi.organizacionKNAI) !='MAQUE') {
               this.grabaApisTotales(sesionApi,'TotalPeriodoDiaRESTO');
            }
            this.grabaApisTotales(sesionApi,'TotalPeriodoDiaTOTAL');
      }

      if(profundidad.includes("4")) {
            // Día / Usuario
            this.grabaApisTotales(sesionApi,'TotalPeriodoDiaUsuario');
            if( this.fn.setearOrganizacionAI(sesionApi.organizacionKNAI) !='MAQUE') {
                this.grabaApisTotales(sesionApi,'TotalPeriodoDiaUsuarioRESTO');
            }
            this.grabaApisTotales(sesionApi,'TotalPeriodoDiaUsuarioTOTAL');

            // Día / Colección
            this.grabaApisTotales(sesionApi,'TotalPeriodoDiaColeccion');
            if( this.fn.setearOrganizacionAI(sesionApi.organizacionKNAI) !='MAQUE') {
                this.grabaApisTotales(sesionApi,'TotalPeriodoDiaColeccionRESTO');
            }
            this.grabaApisTotales(sesionApi,'TotalPeriodoDiaColeccionTOTAL');

            // Día / Cloud Function
            this.grabaApisTotales(sesionApi,'TotalPeriodoDiaCloudFunction');
            if( this.fn.setearOrganizacionAI(sesionApi.organizacionKNAI) !='MAQUE') {
                this.grabaApisTotales(sesionApi,'TotalPeriodoDiaCloudFunctionRESTO');
            }
            this.grabaApisTotales(sesionApi,'TotalPeriodoDiaCloudFunctionTOTAL');
      }

  }

  grabaApisTotales(sesionApi:any, tipoTotal:string): Promise<any> {

      // log(...values("info","grabaApisTotales",tipoTotal));

      // Ignoro llamadas vinculadas a funcionesApi que no se actualizan por la VIDA del Proyecto
      if(tipoTotal.includes("VIDAP")) {
          if(['FirebaseHostingStoredData',
              'FirebaseStorageStoredData',
              'FirestoreBDStoredData'].indexOf(sesionApi.apiFuncionKey)==-1) {  // No contiene

            return;
          }
      }

      // Ignoro llamadas vinculadas a Colección=null (Ejemplo login)
      if(tipoTotal.includes('Coleccion') && (sesionApi.nombreColeccion==null || sesionApi.nombreColeccion==undefined)) {
        return;
      }

      // Ignoro llamadas vinculadas a Triggers
      if(tipoTotal.includes('CloudFunction') ||
        (this.getApiFuncionNombreColeccion( sesionApi.apiFuncionKey)=='CloudFunctionInvocacion' &&
         sesionApi.cloudFunction==null) ) {

         /* No Grabo dado que esa API no graba información asociada a Cloud Function.
            De todas maneras está de gusto, dado que todas las invocaciones de trigger,
            se realizan desde Cloud Function. */

         //  log(...values("log","No se grabo total"));
         return;
      }

      // Ignoro llamadas vinculadas a Push
      if(this.getApiFuncionNombreColeccion( sesionApi.apiFuncionKey)=='FirebasePushNotification') {

         // No Grabo dado que esa API está asociada a Notificaciones Push y aún no está implementada
         //  log(...values("log","No se grabo total"));
         return;
      }

      let documento;

      documento ={
           key                : this.generarKey(sesionApi, tipoTotal),
           tipoTotal          : this.getTipoTotalGrabar(tipoTotal),
           cincoLetras        : this.getCincoLetras(sesionApi, tipoTotal),
           periodo            : this.fn.getPeriodoActual('AAAAMM'),
           fecha              : this.getFechaTotal(tipoTotal),
           organizacionKNAI   : sesionApi.organizacionKNAI,
           documentoColeccion : this.getDocumentoAtributo(sesionApi, tipoTotal, 'nombreColeccion' ),
           documentoKey       : this.getDocumentoAtributo(sesionApi, tipoTotal, 'key' ),
           settings           : this.generarSettingsTotales( sesionApi.usuarioKANE ),
      }

      // Asigno a todos los contadores un cero
    //   this.listadoApisFunciones.forEach(apiFuncionKey => {
    //     console.log('<< documento . key ', documento[apiFuncionKey.key]);
    //     console.log('<< documento ', documento);
    //     documento[apiFuncionKey] = 0;
    //   });

      documento[ sesionApi.apiFuncionKey ] = sesionApi.cantidad;

      documento.settings.sizeOfDocumento = this.fn.sizeOfFirestoreDocument('TotalesApis',documento);

      // Blanqueo campos que no aplican al TipoTotal Actualizado
      if(tipoTotal.includes("VIDAP")) {
           documento.periodo = null;
           documento.fecha   = null;
      }
      if(tipoTotal.includes("VIDAP") || tipoTotal.includes("MAQUE") || tipoTotal.includes("TOTAL")) {
            documento.organizacionKNAI   = null;
      }
      if(!tipoTotal.includes("Usuario") && !tipoTotal.includes("Coleccion") && !tipoTotal.includes("CloudFunction")) {
            documento.documentoColeccion = null;
            documento.documentoKey       = null;
      }

    //   // Leo y Actualizo LocalStorage de Totales aún no grabados en Firestore
    //   let TotalesApis = JSON.parse(this.storageService.getItem("TotalesApis"));
    //   if(TotalesApis) {
    //       if(TotalesApis[documento.key]===undefined) {
    //         TotalesApis[documento.key]=documento;
    //       } else {
    //         TotalesApis[documento.key][ sesionApi.apiFuncionKey ] += sesionApi.cantidad;
    //       }
    //   } else {
    //       TotalesApis = {};
    //       TotalesApis[documento.key]=documento;
    //   }
    //   this.storageService.setItem('TotalesApis', JSON.stringify(TotalesApis));

      this.storageService.getItem("TotalesApis").then((data: any) => {

        let TotalesApis=JSON.parse( data.value);
          
        if(TotalesApis) {
            if(TotalesApis[documento.key]===undefined) {
              TotalesApis[documento.key]=documento;
            } else {
              TotalesApis[documento.key][ sesionApi.apiFuncionKey ] += sesionApi.cantidad;
            }
        } else {
            TotalesApis = {};
            TotalesApis[documento.key]=documento;
        }
        this.storageService.setItem('TotalesApis', JSON.stringify(TotalesApis));

      })

  }

  /* *********************************************************************************
   * *******  FUNCIONES ASOCIADAS A LAS APIS *****************************************
   * ********************************************************************************* */

  getTipoTotalGrabar(tipoTotal:string):string {

    tipoTotal = tipoTotal.replace('TOTAL','');
    tipoTotal = tipoTotal.replace('RESTO','');

    return tipoTotal;
  }

  getCincoLetras(sesionApi:any, tipoTotal:string):string {
    let key = this.generarKey(sesionApi, tipoTotal);
    return key.substr(8,5);
  }

  generarKey(sesionApi:any, tipoTotal:string):string {
      let periodoAAAAMM  = this.fn.getPeriodoActual('AAAAMM');
      let diaDD          = this.fn.getFechaActual('DD')
      let organizacionAI = this.fn.setearOrganizacionAI( sesionApi.organizacionKNAI )  ? this.fn.setearOrganizacionAI( sesionApi.organizacionKNAI ) : 'ERROR(organizacionAI)';

      let usuarioKey       = sesionApi.usuarioKANE.key ? sesionApi.usuarioKANE.key : 'ERROR(usuarioKey)';
      let coleccionKey     = sesionApi.nombreColeccion ? sesionApi.nombreColeccion : 'ERROR(coleccionKey)';
      let cloudFunctionKey = sesionApi.cloudFunction   ? sesionApi.cloudFunction   : 'ERROR(cloudFunctionKey)';

      let rta=null;

      if(tipoTotal=='TotalVIDAP') {
        rta = '00000000VIDAP';

      } else if(tipoTotal=='TotalPeriodo') {
          rta = periodoAAAAMM + 'XX' + organizacionAI;

      } else if(tipoTotal=='TotalPeriodoRESTO') {
          rta = periodoAAAAMM + 'XX' + 'RESTO';

      } else if(tipoTotal=='TotalPeriodoTOTAL') {
          rta = periodoAAAAMM + 'XX' + 'TOTAL';

      } else if(tipoTotal=='TotalPeriodoUsuario') {
          rta = periodoAAAAMM + 'XX' + organizacionAI + usuarioKey;

      } else if(tipoTotal=='TotalPeriodoUsuarioRESTO') {
          rta = periodoAAAAMM + 'XX' + 'RESTO' + usuarioKey;

      } else if(tipoTotal=='TotalPeriodoUsuarioTOTAL') {
          rta = periodoAAAAMM + 'XX' + 'TOTAL' + usuarioKey;

      } else if(tipoTotal=='TotalPeriodoColeccion') {
          rta = periodoAAAAMM + 'XX' + organizacionAI + coleccionKey;

      } else if(tipoTotal=='TotalPeriodoColeccionRESTO') {
          rta = periodoAAAAMM + 'XX' + 'RESTO' + coleccionKey;

      } else if(tipoTotal=='TotalPeriodoColeccionTOTAL') {
          rta = periodoAAAAMM + 'XX' + 'TOTAL' + coleccionKey;

      } else if(tipoTotal=='TotalPeriodoCloudFunction') {
          rta = periodoAAAAMM + 'XX' + organizacionAI + cloudFunctionKey;

      } else if(tipoTotal=='TotalPeriodoCloudFunctionRESTO') {
          rta = periodoAAAAMM + 'XX' + 'RESTO' + cloudFunctionKey;

      } else if(tipoTotal=='TotalPeriodoCloudFunctionTOTAL') {
          rta = periodoAAAAMM + 'XX' + 'TOTAL' + cloudFunctionKey;

      } else if(tipoTotal=='TotalPeriodoDia') {
          rta = periodoAAAAMM + diaDD + organizacionAI;

      } else if(tipoTotal=='TotalPeriodoDiaRESTO') {
          rta = periodoAAAAMM + diaDD + 'RESTO';

      } else if(tipoTotal=='TotalPeriodoDiaTOTAL') {
            rta = periodoAAAAMM + diaDD + 'TOTAL';

      } else if(tipoTotal=='TotalPeriodoDiaUsuario') {
          rta = periodoAAAAMM + diaDD + organizacionAI + usuarioKey;

      } else if(tipoTotal=='TotalPeriodoDiaUsuarioRESTO') {
          rta = periodoAAAAMM + diaDD + 'RESTO' + usuarioKey;

      } else if(tipoTotal=='TotalPeriodoDiaUsuarioTOTAL') {
          rta = periodoAAAAMM + diaDD + 'TOTAL' + usuarioKey;

      } else if(tipoTotal=='TotalPeriodoDiaColeccion') {
          rta = periodoAAAAMM + diaDD + organizacionAI + coleccionKey;

      } else if(tipoTotal=='TotalPeriodoDiaColeccionRESTO') {
          rta = periodoAAAAMM + diaDD + 'RESTO' + coleccionKey;

      } else if(tipoTotal=='TotalPeriodoDiaColeccionTOTAL') {
          rta = periodoAAAAMM + diaDD + 'TOTAL' + coleccionKey;

      } else if(tipoTotal=='TotalPeriodoDiaCloudFunction') {
          rta = periodoAAAAMM + diaDD + organizacionAI + cloudFunctionKey;

      } else if(tipoTotal=='TotalPeriodoDiaCloudFunctionRESTO') {
          rta = periodoAAAAMM + diaDD + 'RESTO' + cloudFunctionKey;

      } else if(tipoTotal=='TotalPeriodoDiaCloudFunctionTOTAL') {
          rta = periodoAAAAMM + diaDD + 'TOTAL' + cloudFunctionKey;

      }

      // console.log("generarKey rta",rta);
      return rta;
  }


  getDocumentoAtributo(sesionApi:any, tipoTotal:string, cual:string):string {
      let utilizaDocumento=false;

      if(tipoTotal.includes('Usuario') ||
         tipoTotal.includes('Coleccion') ||
         tipoTotal.includes('CloudFunction')) {

        utilizaDocumento=true;

      } else {
        utilizaDocumento=false;
      }

      let rta=null;

      if(utilizaDocumento) {

          if(tipoTotal.includes('Usuario')) {
              if(cual=='nombreColeccion') {
                  rta='Usuarios';
              } else {
                  rta=sesionApi.usuarioKANE.key;
              }

          } else if(tipoTotal.includes('Coleccion')) {
              if(cual=='nombreColeccion') {
                  // let nombreColeccionDocumentoKey = this.getApiFuncionNombreColeccion( this.apiTotal.apiFuncionKey );
                  // rta=nombreColeccionDocumentoKey;
                  rta='Colecciones';
              } else {
                  rta=sesionApi.nombreColeccion;
              }

          } else if(tipoTotal.includes('CloudFunction')) {
              if(cual=='nombreColeccion') {
                  rta='AuxCloudFunctions';
              } else {
                  rta=sesionApi.cloudFunction;
              }
          }
      }

      // console.log("getDocumentoAtributo rta",tipoTotal, cual, rta);
      return rta;


  }

  generarSettingsTotales( usuarioKANE:any ):any {

    let rta = {
        isActivo                : true,
        isBorrado               : false,
        borradoFisico           : false,
        triggersIgnorar         : true,
        triggersProcesarTarde   : false,
        documentosAsociados     : 0,
        sizeOfDocumento         : 0,
        fechaHoraCreacion       : this.timestamp(),
        fechaHoraModificacion   : this.timestamp(),
        usuarioKANE             : usuarioKANE
    };

    return rta;
  }

  getFechaTotal(tipoTotal:string) {
    if(!tipoTotal.includes('Dia')) {
        return null;
    } else {
        return this.fn.getFechaActual('AAAA-MM-DD')
    }
  }

  getapiKN(apiFuncionKey:string):any {
    let api = this.listadoApisFunciones.find(api => api.key == apiFuncionKey);
    return {
        key:    api.apiKN.key,
        nombre: api.apiKN.nombre
    }
  }

  getApiFuncionKN(apiFuncionKey:string):any {
    let api = this.listadoApisFunciones.find(api => api.key == apiFuncionKey);

    return {
        key :api.key,
        nombre : api.nombre
    }
  }

  getApiFuncionNombreColeccion(apiFuncionKey:string):any {
    // console.log("getApiFuncionNombreColeccion(",apiFuncionKey,")  rta",      this.listadoApisFunciones.find(api => api.key == apiFuncionKey).nombreColeccion);
    return this.listadoApisFunciones.find(api => api.key == apiFuncionKey).nombreColeccion;
  }
  
  
  LogApiSQL(argumentos:any) {
        /*
        argumentos = {
            id_modulo        : int,
            id_metodo        : int,
            organizacionKNAI : KNAI,
            usuarioKANE      : KANE
            cant             : number,
        }
        */

        log(...values("LogApiSQL","LogApiFuncion"));
        log(...values("valoresDebug","argumentos:",argumentos));
        
        if(!argumentos) {
          return;
        }
        
        let servicioSQL = 'dwApisGrabar';
        let parametros  = '?id_metodo='+argumentos.id_metodo.toString()+'&id_modulo='+argumentos.id_modulo.toString()+
                          '&fecha='+this.fn.getFechaActual('AAAA-MM-DD')+
                          '&organizacionAutoIncrement='+this.fn.setearOrganizacionAI(argumentos.organizacionKNAI)+
                          '&cant='+argumentos.cant.toString();
        
        // apiURL : 'https://us-central1-pktest-ad982.cloudfunctions.net/webApi/api/v1/',           //Desarrollo
        let url:string=this.environment.serviciosExternos.sql.apiURL+servicioSQL+parametros;    

        console.log('LogApiSQL url',url);

        this._http.get(url).subscribe((data:any)=> {
              console.log('LogApiSQL data',data);
              return;
        },(error:any)=>{
              console.error("LogApiSQL error",error);
              return;
        })
      
    }

}
