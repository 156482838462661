import { KN, KANE, KNAI } from '../typesKN/typesKN.model';

export class LogApiFuncionModel {
  eventoQueDisparo      : string   = '';
  apiFuncionKey         : string   = null;
  organizacionKNAI      : KNAI     = null;   // cambiar por KNAI
  usuarioKANE           : KANE     = null;   // cambiar por KN
  nombreColeccion       : string   = null;
  cloudFunction         : string   = null;
  cantidad              : number   = 0;
       
  constructor(init?:Partial<LogApiFuncionModel>) {
    
    Object.assign(this, init);
    
  }

}

export class LogSessionApi {
  eventoQueDisparo      : string   = '';
  apiFuncionKey         : string   = null;
  organizacionKNAI      : KNAI     = null;   // cambiar por KNAI
  usuarioKANE           : KANE     = null;   // cambiar por KN
  nombreColeccion       : string   = null;
  cloudFunction         : string   = null;
  cantidad              : number   = 0;  

  constructor(init?:Partial<LogSessionApi>) {
    
    Object.assign(this, init);
    
  }

}
