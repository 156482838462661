import { log, logIf, logTable, values } from '@maq-console';

import { Component, EventEmitter, OnInit, ViewChild, Output, Input, ViewEncapsulation } from '@angular/core';
import { FormControl } from "@angular/forms";
import { ChangeDetectorRef } from '@angular/core';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService }  from '@ngx-translate/core';
import { FuncionesService }  from '@maq-funciones';

import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
// https://stackoverflow.com/questions/54529667/how-to-access-the-view-child-of-plugin-inside-modal-to-get-instance-of-that-plug

// Resize de Imágenes
import { NgxPicaService } from 'ngx-pica';  
import * as EXIF from 'exif-js';
export interface AspectRatioOptions {
    keepAspectRatio: boolean;
    forceDimensions?: boolean;
}
export interface NgxPicaResizeOptionsInterface {
    aspectRatio?: AspectRatioOptions;
    quality?: number;
    alpha?: boolean;
    unsharpAmount?: number;
    unsharpRadius?: number;
    unsharpThreshold?: number;
}
export enum NgxPicaErrorType {
    NO_FILES_RECEIVED = 'NO_FILES_RECEIVED',
    CANVAS_CONTEXT_IDENTIFIER_NOT_SUPPORTED = 'CANVAS_CONTEXT_IDENTIFIER_NOT_SUPPORTED',
    NOT_BE_ABLE_TO_COMPRESS_ENOUGH = 'NOT_BE_ABLE_TO_COMPRESS_ENOUGH'
}

export interface NgxPicaErrorInterface {
    err: NgxPicaErrorType;
    file?: File;
}

@Component({
  selector: 'lib-shared-input-croppie',
  templateUrl: './input-croppie.component.html',
  styleUrls: [ './input-croppie.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class InputCroppieComponent implements OnInit {
    @Input() public aspectRatio: number; // 4 / 2 | 4 / 3 | 3 / 4 | ...
    @Input() public imageField: string; 
    @Input() public image: string; 
    @Input() public accionForm: string; 
    @Input() public thumbWidth: number; 

    @Output() cargoArchivo= new EventEmitter<any[]>();
    @Output() borroArchivo= new EventEmitter<string>();
    @Output() desabilitarGrabar= new EventEmitter<boolean>();

    imageChangedEvent: any = '';
    croppedImage: any = '';
    showCropper = false;
    containWithinAspectRatio = false;

    @ViewChild(ImageCropperComponent, {static: true}) imageCropper: ImageCropperComponent;
    //@ViewChild('cropper', {static: true}) imageCropper: ImageCropperComponent;    

    @ViewChild("modalContent") modalContent: any;

    public modalRef: NgbModalRef;

    public nombreArchivo:string=null;
    public extensionArchivo:string=null;
    public extensionIcono:string=null;
    public imageAnterior:string=null;
    
    constructor(public fn:FuncionesService,
                public modalService: NgbModal,
                private translate: TranslateService,
                private _ngxPicaService: NgxPicaService) { 

    }

    ngDoCheck() {      
        if(this.image!=this.imageAnterior) {
          
        //   log(...values("valores", "input-croppie - ngDocheck - nameField:",this.imageField));
        //   log(...values("valores", "input-croppie - ngDocheck - image:",this.image));        
                
          this.imageAnterior=this.image;

          this.nombreArchivo = this.fn.getFileNameSinVars(this.image);
          this.extensionArchivo = this.fn.getExtensionFile( this.nombreArchivo );
          this.extensionIcono = 'assets/iconos/extensiones-archivos64x64/'+this.extensionArchivo+'.png';
        }  
    }
    
    ngOnInit() {

        this.croppedImage = this.image;

        // log(...values("valores", "input-croppie - imageField:",this.imageField));
        // log(...values("valores", "input-croppie - image:",this.image));
        // log(...values("valores", "input-croppie - aspectRatio:",this.aspectRatio));

        this.nombreArchivo = this.fn.getFileNameSinVars(this.image);
        this.extensionArchivo = this.fn.getExtensionFile( this.nombreArchivo );
        this.extensionIcono = 'assets/iconos/extensiones-archivos64x64/'+this.extensionArchivo+'.png';
        // log(...values("valores","input-file - nombreArchivo:",this.nombreArchivo));
        // log(...values("valores","input-file - extensionArchivo:",this.extensionArchivo));

        if(this.thumbWidth===undefined) {
            this.thumbWidth=150;
        }
        
    }  

    inputBorrar(): void {
      this.image            = null;
      this.nombreArchivo    = null;
      this.extensionArchivo = null;
      this.extensionIcono   = null;
      this.croppedImage     = '';

      this.borroArchivo.emit(this.imageField);
    }  

    fileChangeEvent(event: any): void {
        
        // log(...values("valores","fileChangeEvent - event:",event));
        
        // if (event === null || event === undefined){
        //     return null
        // }        
        
        this.openModal();

        this.imageChangedEvent = event;
        // log(...values("valores","event:",event));

        const file = event.target.files[0];
        const typeFile = event.target.files[0].type;

        // log(...values("valores","file:",file));
        // log(...values("valores","typeFile:",typeFile));

        this.nombreArchivo = this.fn.getFileNameFromArchivo(file);
        this.extensionArchivo = this.fn.getExtensionFile( this.nombreArchivo );
        this.extensionIcono = 'assets/iconos/extensiones-archivos64x64/'+this.extensionArchivo+'.png';
        // log(...values("valores","nombreArchivo:",this.nombreArchivo));
        // log(...values("valores","extension:",this.extensionArchivo));

    }

    public openModal() {
    //   console.log("openModal");  
      this.modalRef = this.modalService.open(this.modalContent, {size: 'lg', backdrop : 'static', windowClass: 'custom-class', container: '.app' });
      
      this.modalRef.result.then((result) => {
          // ok
          //   console.log("modal result", result);
      }, (reason) => {
          // cancel
      });
    }

    public closeModal(){
      this.modalRef.close();
    }

    async imageCropped(event: any) {  // ImageCroppedEvent
        // Generó el Crop !!!!, se dispara cada vez que saltas el selecto de area
        // log(...values("valores","imageCropped - event:",event));

        this.croppedImage = event.base64;
        // log(...values("imagen","croppedImage:",this.croppedImage));

        let file = this.fn.convertBase64ToFile(this.croppedImage, this.nombreArchivo);
        
        // log(...values("imagen","file:",file));

        // Obtengo Medidas de la Imagen
        let imgMedidas:any;
        imgMedidas = await this.fn.getIMGMedidas( this.croppedImage as string );
        let width     = imgMedidas.width;
        let height    = imgMedidas.height;
        let newWidth  = this.thumbWidth;
        let newHeight = Math.round( height/width * newWidth );
        // log(...values("valores","imgMedidas width:",width, "height:", height));

        this._ngxPicaService.resizeImages( [file], newWidth, newHeight)
        .subscribe((fileResized: File) => {
            
                let reader: FileReader = new FileReader();
                
                reader.addEventListener('load', async(event: any) => {

                    let imageResized = event.target.result;
                    // log(...values("valores","event.target.result:",event.target.result));

                    // log(...values("imagen","imageResized:",imageResized));
                    this.desabilitarGrabar.emit(false);
                    this.cargoArchivo.emit([
                      this.imageField,
                      this.nombreArchivo,
                      this.extensionArchivo,                          
                      this.croppedImage,
                      imageResized]);

                }, false);
                
                reader.readAsDataURL(fileResized);

                // reader.readAsBinaryString(Blob|File)        -> result blob como cadena binaria
                // reader.readAsText(Blob|File, opt_encoding)  -> result blob como cadena de texto
                // reader.readAsDataURL(Blob|File)             -> result URL de datos
                // reader.readAsArrayBuffer(Blob|File)         -> result objeto ArrayBuffer 
                
        }, (error: NgxPicaErrorInterface) => {
            log(...values("error","Error al generarar Thumb de "+this.imageField, error));
            this.desabilitarGrabar.emit(false);
            throw error.err;
        });

    }

    imageLoaded() {
        this.showCropper = true;
        // console.log('Image loaded');
    }

    cropperReady() {
        // console.log('Cropper ready');
    }

    loadImageFailed() {
        // console.log('Load failed');
    }

    rotateLeft() {
        // console.log("this.modalContent._element",this.modalContent._element);
        // console.log("this.modalContent.nativeElement",this.modalContent.nativeElement);
        // console.log("this.modalContent.imageCropper",this.modalContent.imageCropper);
        // console.log("this.imageCropper",this.imageCropper);
        // this.imageCropper.rotateLeft();
    }


    rotateRight() {
        // this.imageCropper.rotateRight();
    }

    flipHorizontal() {
        // this.imageCropper.flipHorizontal();
    }

    flipVertical() {
        // this.imageCropper.flipVertical();
    }

    resetImage() {
        // this.imageCropper.resetImage();
    }

    toggleContainWithinAspectRatio(){
        this.containWithinAspectRatio = !this.containWithinAspectRatio;
    }

}